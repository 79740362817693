import { IMAGES } from "assets/images/imagePath";

export const pageSizeList = [
	{
		title: "Mẫu giấy cuộn 3 nhãn (Khổ giấy in nhãn 104x22mm/4.2x0.9 Inch)",
		image: IMAGES.page102_22,
		type: "104_22",
	},
	{
		title: "Mẫu giấy cuộn 2 nhãn (Khổ giấy in nhãn 72x22mm)",
		image: IMAGES.page72_22,
		type: "72_22",
	},
];
