import { DriveFileMoveOutlined, SimCardDownload } from "@mui/icons-material";
import { Box, Button, Pagination, Stack, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "components/noRows";
import { activityOrderTranslator, formatVND, paymentTypeOrderTranslator } from "func";
import moment from "moment";
import { LoadingContext } from "providers/LoadingProvider";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HeaderActions from "ui-component/Header-actions";
import { excelExportHelper } from "utils/ExportExcel";

function InvoicesInternalTable({
	rows,
	setPagination,
	pagination,
	filterData,
	loading,
	type,
	getData,
}) {
	const { branchList } = useSelector((state) => state?.branchReducer);
	const handleChangePage = (e, page) => {
		setPagination((prev) => ({
			...prev,
			page,
		}));
	};
	const columns = [
		{
			field: "order_code",
			headerName: "Mã hóa đơn",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							<Link to={`/order-detail/${params.row?.id}`} target="_blank">
								{params.value}
							</Link>
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee",
			headerName: "Nhân viên xuất hàng",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value?.fullname || params?.value?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value?.fullname || params?.value?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee_internal",
			headerName: "Nhân viên nhận",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value?.fullname || params?.value?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value?.fullname || params?.value?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "branch",
			headerName: "Chi nhánh",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.branch?.name}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.branch?.name}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "total_cost",
			headerName: "Tổng tiền hàng",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian tạo",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
	];

	const handleExportExcel = async (data, date) => {
		const startEndDate = {
			start: moment(date[0]).format("DD/MM/YYYY"),
			end: moment(date[1]).format("DD/MM/YYYY"),
		};

		const res = await getData();
		const fullData = res?.results?.objects?.rows;

		const title = [
			{
				A: `Báo cáo xuất hàng nội bộ theo thời gian
						Từ ngày ${startEndDate.start} đến ngày ${startEndDate.end}
						Chi nhánh: ${branchList
							.map((item) => {
								if (filterData.branch.includes(item.id)) {
									return item.name;
								} else {
									return undefined;
								}
							})
							.filter((it) => it)
							.join(", ")}`,
			},
			{},
		];

		let table1 = [
			{
				A: "Mã hóa đơn",
				B: "Thời gian",
				C: "Nhân viên xuất hàng",
				D: "Nhân viên nhận hàng",
				E: "Tổng tiền",
				F: "Tổng giảm",
				G: "Thành tiền",
				H: "Trạng thái",
				I: "Chi nhánh",
				J: "Ghi chú",
			},
		];
		// console.log("data export: ", data);
		fullData.forEach((row) => {
			console.log("row: ", row);
			table1.push({
				A: row?.order_code,
				B: moment(+row?.created_at_unix_timestamp).format("DD/MM/YYYY HH:mm"),
				C: row?.employee?.fullname || row?.employee?.username,
				D: row?.employee_internal?.fullname || row?.employee_internal?.username,
				E: +row?.fee_total,
				F: +row?.discount,
				G: +row?.total_cost,
				H: activityOrderTranslator(row?.activity).value,
				I: row?.branch?.name,
				J: row?.note,
			});
		});

		table1 = [{ A: `Ngày lập ${moment().format("DD/MM/YYYY HH:mm")}` }].concat(table1).concat([""]);

		const finalData = [...title, ...table1];

		excelExportHelper(fullData, finalData);
	};

	return (
		<Box minHeight={"100vh"}>
			<Stack flexDirection={"row"} justifyContent="space-between">
				<HeaderActions title={type === "PRODUCT" ? "Đơn hàng" : "Xuất hàng nội bộ"} />
				<Stack flexDirection={"row"} gap={2} alignItems="center">
					<Button
						sx={{ p: 1, height: "fit-content" }}
						size="small"
						variant="contained"
						startIcon={<SimCardDownload />}
						onClick={() => handleExportExcel(rows, filterData?.date)}
					>
						Xuất file
					</Button>
				</Stack>
			</Stack>
			<Box>
				<DataGrid
					rows={rows}
					columns={columns}
					autoHeight
					autoWidth
					disableColumnMenu
					disableVirtualization={false}
					disableSelectionOnClick={true}
					disableDensitySelector={false}
					hideFooter={true}
					rowHeight={70}
					sortingMode="server"
					loading={loading}
					// checkboxSelection={ROLE.TRANSACTION_DELETE === role?.TRANSACTION_DELETE}
					// onSelectionModelChange={(ids) => {
					// 	setSelectList(ids);
					// }}
					components={{
						NoRowsOverlay: CustomNoRowsOverlay,
					}}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					marginTop: "20px",
				}}
			>
				<Pagination
					page={pagination.page}
					count={Math.ceil(pagination.total / pagination.limit)}
					onChange={handleChangePage}
					color="primary"
					variant="outlined"
					shape="rounded"
				/>
			</Box>
		</Box>
	);
}

export default InvoicesInternalTable;
