import { Add } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { IconMinus } from "@tabler/icons";
import { InputNumber } from "antd";
import React, { createRef, forwardRef, memo, useEffect, useRef, useState } from "react";

import classnames from "classnames/bind";
import styles from "./InputNumberCustom.module.scss";
const cx = classnames.bind(styles);
const InputNumberCustom = forwardRef((propss, ref) => {
	const { val, handleSetValue, min = null, max = 99999, step = 1, styleItem, ...props } = propss;
	const stepUp = () => {
		let newValue = Number(val) + Number(step);
		if (newValue > max) {
			newValue = max;
		}
		handleSetValue(newValue);
	};

	const stepDown = () => {
		let newValue = Number(val) - Number(step);

		if (newValue < min) {
			newValue = min;
		}
		handleSetValue(newValue);
	};
	const handleChangeValue = (e) => {
		let result = e;
		if (!!result === false) {
			result = min;
		}

		handleSetValue(result);
	};

	return (
		<Box
			className={cx("wrapper")}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				...styleItem,
			}}
		>
			<IconButton className={cx("input__item")} disabled={val === min} onClick={stepDown}>
				<IconMinus />
			</IconButton>

			<InputNumber
				className={cx("input__item")}
				sx={{ textAlign: "center" }}
				value={val}
				onChange={handleChangeValue}
				controls={false}
				min={min}
				max={max}
				bordered={false}
				ref={ref}
				{...props}
			/>
			<IconButton className={cx("input__item")} disabled={val === max} onClick={stepUp}>
				<Add />
			</IconButton>
		</Box>
	);
});

export default InputNumberCustom;
