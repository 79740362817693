import { Grid, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "antd";
import { CustomNoRowsOverlay } from "components/noRows";
import {
	activityOrderTranslator,
	formatVND,
	paymentTypeOrderTranslator,
	transactionTranslator,
} from "func";
import _debounce from "lodash/debounce";
import moment from "moment";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import { getNumberWithCommas } from "utils";
import { showSortStyle } from "views/utilities/CustomStyle";
import transactionAPI from "../../api/TransactionAPI";
import { SearchComponent } from "../../components/search";
import Text from "antd/lib/typography/Text";

// import { useSnackbar } from "notistack";
// import { ModalContext } from "providers/ModalProvider";

// const { Option } = SelectAntd;

// const actionList = [
// 	{
// 		value: "edit",
// 		title: "edit",
// 	},
// 	{
// 		value: "delete",
// 		title: "delete",
// 	},
// ];

const searchList = [
	{
		value: "$user.username$",
		title: "phone_number",
	},
	// {
	// 	value: "$user.name_admin_note$",
	// 	title: "Tên thân mật",
	// },
];

const DiscountHis = () => {
	// const { enqueueSnackbar } = useSnackbar();
	// const modalContext = useContext(ModalContext);

	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState([]);
	const [information, setInformation] = useState({
		visible: false,
		data: {},
	});
	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	// const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const columns = [
		{
			field: "employeeName",
			headerName: "Nhân viên thực hiện",
			flex: 1,

			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.employee?.fullname}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.employee?.fullname}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "phone_number",
			headerName: t(`phone_number`),
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			// flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.user?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.user?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "name_admin_note",
		// 	headerName: "Tên thân mật",
		// 	sortable: false,
		// 	flex: 1,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	// flex: 1,
		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={params?.row?.user?.name_admin_note}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.row?.user?.name_admin_note}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		{
			field: "original_value",
			headerName: "Giá trị thẻ",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			// flex: 1,
			renderCell: (params) => <div>{`${getNumberWithCommas(params?.value)} đ`}</div>,
		},
		{
			field: "change_amount",
			headerName: "Giảm",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			// flex: 1,
			renderCell: (params) => <div>{`${getNumberWithCommas(params?.value)} đ`}</div>,
		},
		{
			field: "note_admin",
			headerName: t(`note_admin`),
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 2,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "info",
			headerName: "Chi tiết",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 2,
			renderCell: (params) => {
				return (
					(params.row?.order_id || params.row?.pack_id || params.row?.discount_for_client_id) && (
						<Tooltip
							title={"Xem chi tiết"}
							overlayStyle={{ maxWidth: "500px" }}
							placement="right"
							zIndex="10000"
						>
							<Box
								sx={{
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									cursor: "pointer",
									fontWeight: "bold",
								}}
								onClick={() => {
									const data = params.row?.order_id
										? params.row?.orders
										: params.row?.pack_id
										? params.row?.packs
										: params.row?.contributor_transaction;
									const dataType = params.row?.order_id
										? "order"
										: params.row?.pack_id
										? "pack"
										: "withdraw";

									handleOpenInforModal(data, dataType);
								}}
							>
								Xem chi tiết
							</Box>
						</Tooltip>
					)
				);
			},
		},
		// {
		// 	field: "type",
		// 	headerName: t(`type`),
		// 	sortable: false,
		// 	flex: 1,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	// flex: 1,
		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={transactionTranslator(params.value)}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{transactionTranslator(params.value)}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },

		// {
		// 	field: "created_at",
		// 	headerName: t(`created_at`),
		// 	sortable: false,
		// 	minWidth: 180,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	// flex: 1,
		// },
		// {
		// 	field: "actions",
		// 	headerName: t(`actions`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	minWidth: 180,
		// 	renderCell: (params) => (
		// 		<>
		// 			<SelectCustom
		// 				value={t(`select_actions`)}
		// 				onChange={(value) => onChangeAction(value, params.id)}
		// 				style={{ width: 150 }}
		// 			>
		// 				{actionList?.map((item) => (
		// 					<Option key={item?.value}>{t(`${item.title}`)}</Option>
		// 				))}
		// 			</SelectCustom>
		// 		</>
		// 	),
		// },
	];

	const getListUser = async () => {
		setIsLoading(true);
		let filterValue = {
			type: "DISCOUNT_FOR_CLIENT_HIDDEN",
		};

		let order = '[["created_at", "desc"]]';
		// if (queryParams?.phone_number?.trim()) {
		// 	filter = `{"$user.phone$":{"$iLike":"%${queryParams?.phone_number.trim()}%"}}`;
		// }
		// if (queryParams?.username) {
		// 	filter = `{"username":{"$iLike":"%${queryParams?.username}%"}}`;
		// }
		// if (queryParams?.email) {
		// 	filter = `{"email":{"$iLike":"%${queryParams?.email}%"}}`;
		// }
		// if (queryParams?.type) {
		// 	filter = `{"type":{"$iLike":"%${queryParams?.type}%"}}`;
		// }

		if (queryParams?.[searchBy]) {
			filterValue = {
				...filterValue,
				[searchBy]: {
					$iLike: `%${searchValue.trim()}%`,
				},
			};
		}

		if (queryParams?.sortBy) {
			order = `[["${queryParams?.sortBy}", "${queryParams?.sort}"]]`;
		}
		try {
			const res = await transactionAPI.getList({
				fields:
					'["$all",{"employee": ["fullname"]}  ,{"user":["username","name_admin_note","$paranoid"]}, {"contributor_transaction" : ["$all", {"employee": ["fullname"]}]}, {"orders" : ["$all"]},{"packs" : ["$all", {"user":["phone"]}, {"owner": ["phone"]}, {"buyer": ["phone", "fullname", "name_admin_note"]}]}]',
				order,
				filter: JSON.stringify(filterValue),
				limit: pagination.limit,
				page: queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};
	const handleOpenInforModal = (data, dataType) => {
		//	Example DATA
		let dataParams = [];
		if (dataType === "order") {
			dataParams = [
				{
					groupId: 1,
					groupTitle: "Thông tin đơn hàng",
					gridProps: {},
					children: [
						{
							id: "user",
							title: "Tên khách hàng",
							value: data?.user?.fullname,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "fullname",
							title: "Tên khách nhận",
							value: data?.fullname,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "phone",
							title: "SĐT nhận",
							value: data?.phone,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "order_code",
							title: "Mã đơn hàng",
							value: data.order_code,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "activity",
							title: "Trạng thái",
							value: activityOrderTranslator(data.activity)?.value,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "payment_type",
							title: "Hình thức thanh toán",
							value: paymentTypeOrderTranslator(data.payment_type),
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "fee_total",
							title: "Tổng tiền hàng",
							value: formatVND(data.fee_total),
							component: Text,
							childrenProps: {
								span: 6,
								color: "error",
							},
						},
						{
							id: "discount",
							title: "Tổng giảm",
							value: formatVND(data.discount),
							component: Text,
							childrenProps: {
								span: 6,
								color: "error",
							},
						},
						{
							id: "total_cost",
							title: "Tổng cộng",
							value: formatVND(data.total_cost),
							component: Text,
							childrenProps: {
								span: 6,
							},
						},
						{
							id: "created_at_unix_timestamp",
							title: "Thời gian tạo",
							value: moment(data.created_at_unix_timestamp, "x").format("DD/MM/YYYY HH:mm"),
							component: Text,
							childrenProps: {
								span: 6,
							},
						},
						{
							id: "address",
							title: "Địa chỉ",
							value: data.address,
							component: Text,
							childrenProps: {
								span: 6,
							},
						},
						{
							id: "ward",
							title: "Xã/Phường",
							value: data.ward,
							component: Text,
							childrenProps: {
								span: 6,
							},
						},
						{
							id: "district",
							title: "Quận/Huyện",
							value: data.district,
							component: Text,
							childrenProps: {
								span: 6,
							},
						},
						{
							id: "city",
							title: "Tỉnh/TP",
							value: data.city,
							component: Text,
							childrenProps: {
								span: 6,
							},
						},
					],
				},
				{
					groupId: 2,
					groupTitle: "Thông tin khác",
					children: [
						{
							id: "reason",
							title: "Lý do hủy đơn",
							value: data.reason,
							component: Text,
							childrenProps: {
								span: 24,
							},
						},
					],
				},
			];
		} else if (dataType === "pack") {
			dataParams = [
				{
					groupId: 1,
					groupTitle: "Thông tin thẻ",
					gridProps: {},
					children: [
						{
							id: "phone",
							title: "Người sử dụng thẻ",
							value: data?.user?.phone,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "owner",
							title: "Chủ sở hữu",
							value: data?.owner?.phone,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "buyer",
							title: "Người mua thẻ",
							value: data?.buyer?.phone,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "value",
							title: "Giá trị thẻ",
							value: formatVND(data?.value),
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "endow",
							title: "Ưu đãi",
							value: `${data?.endow}%`,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "value_real",
							title: "Giá thực",
							value: formatVND((data?.value * data?.endow) / 100),
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
					],
				},
			];
		} else if (dataType === "withdraw") {
			dataParams = [
				{
					groupId: 1,
					groupTitle: "Thông tin yêu cầu nạp",
					gridProps: {},
					children: [
						{
							id: "phone",
							title: "Số điện thoại yêu cầu",
							value: data?.phone,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "change_amount",
							title: "Giá trị giao dịch",
							value: data?.change_amount,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "type",
							title: "Loại",
							value: transactionTranslator(data.type),
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "employee",
							title: "Nhân viên thực hiện",
							value: data.employee?.fullname,
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "created_at",
							title: "Ngày gửi yêu cầu",
							value: moment(data.created_at).format("DD/MM/YYY HH:mm"),
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "updated_at",
							title: "Ngày hoàn thành yêu cầu",
							value: moment(data.updated_at).format("DD/MM/YYY HH:mm"),
							component: Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "note",
							title: "Ghi chú khách hàng",
							value: data.note,
							component: Text,
							childrenProps: {
								span: 24,
							},
						},
						{
							id: "note_admin",
							title: "Ghi chú nhân viên",
							value: data.note_admin,
							component: Text,
							childrenProps: {
								span: 24,
							},
						},
					],
				},
			];
		}
		setInformation((prev) => ({
			data: dataParams,
			visible: true,
		}));
	};

	// const handleOpenRemoveModal = (id) => {
	// 	modalContext.showDeleteConfirm(id, () => handleDelete(id));
	// };

	// const handleDelete = async (id) => {
	// 	try {
	// 		await transactionAPI.remove(id);

	// 		enqueueSnackbar(t(`success`), {
	// 			variant: "success",
	// 			autoHideDuration: 2000,
	// 		});
	// 		getListUser();
	// 	} catch (error) {
	// 		enqueueSnackbar(error?.message || t(`error`), {
	// 			variant: "error",
	// 			autoHideDuration: 2000,
	// 		});
	// 	}
	// };

	// const onChangeAction = (value, id) => {
	// 	// value === "edit" && navigate(`/users/edit/${id}`);
	// 	value === "delete" && handleOpenRemoveModal(id);
	// };

	// const handleRemoveMulti = () => {
	// 	modalContext.showDeleteConfirm(
	// 		selectList,
	// 		async () => {
	// 			try {
	// 				await transactionAPI.removeMulti({
	// 					items: JSON.stringify(selectList),
	// 				});
	// 				enqueueSnackbar(t(`success`), {
	// 					variant: "success",
	// 					autoHideDuration: 2000,
	// 				});
	// 				getListUser();
	// 			} catch (error) {
	// 				enqueueSnackbar(error?.message || t(`error`), {
	// 					variant: "error",
	// 					autoHideDuration: 2000,
	// 				});
	// 			}
	// 		},
	// 		t("do_you_want_to_delete_selected_items"),
	// 		t(`confirm`)
	// 	);
	// };

	useEffect(() => {
		if (queryParams.hasOwnProperty("username")) {
			setSearchBy("username");
			setSearchValue(queryParams?.username);
		}
		if (queryParams.hasOwnProperty("email")) {
			setSearchBy("email");
			setSearchValue(queryParams?.email);
		}
		if (queryParams.hasOwnProperty("phone_number")) {
			setSearchBy("phone_number");
			setSearchValue(queryParams?.phone_number);
		}
		if (queryParams.hasOwnProperty("type")) {
			setSearchBy("type");
			setSearchValue(queryParams?.type);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		getListUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams]);

	return (
		<>
			<MainCard>
				<HeaderActions title={"Lịch sử giảm giá"} onCreate={false} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{/* <Button
						variant="outlined"
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							// checkboxSelection
							// onSelectionModelChange={(ids) => {
							// 	setSelectList(ids);
							// }}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
			<ModalAntd
				title={"Chi tiết giảm giá"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>
		</>
	);
};

export default DiscountHis;
