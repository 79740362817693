import axiosClient from "api/axiosClient";

const notificationAPI = {
	async getList(params) {
		const url = `/push_notification`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},
	async getListNotificationHistory(params) {
		const url = `/notification_history/cms_send`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async findOne(id, params) {
		const url = `push_notification/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async create(data) {
		const url = `/push_notification`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async update(data, id) {
		const url = `/push_notification/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async pushNoti(id) {
		const url = `/push_notification/send_fcm_to_group/${id}`;
		const resp = await axiosClient.post(url);
		return resp;
	},

	async remove(id) {
		const url = `/push_notification/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},

	async removeMulti(params) {
		const url = `/push_notification`;
		const resp = await axiosClient.delete(url, { params });
		return resp;
	},
};

export default notificationAPI;
