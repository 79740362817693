import {
	DELETE_EMPLOYEE_SALE,
	RESET_EMPLOYEE_SALE,
	SELECT_EMPLOYEE_SALE,
	UPDATE_EMPLOYEE_SALE,
	UPDATE_VALUE_EMPLOYEE_SALE,
} from "store/actions/EmployeeSale/type";

const initialState = {
	employeeSaleList: [],
};

const EmployeeSaleReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SELECT_EMPLOYEE_SALE: {
			const employeeSaleListUpdate = [...state.employeeSaleList];
			const initData = {
				id: payload.id,
				employee: payload,
				work: 1,
			};
			if (employeeSaleListUpdate.every((employee) => employee.id !== payload.id)) {
				employeeSaleListUpdate.push(initData);
			}

			return { ...state, employeeSaleList: employeeSaleListUpdate };
		}
		case UPDATE_EMPLOYEE_SALE: {
			//payload is booking_consultants
			const employeeSaleListUpdate = [...state.employeeSaleList];
			payload.map((item) => {
				const { employee } = item;
				let initData = {
					id: employee.id,
					employee: employee,
				};
				employeeSaleListUpdate.push(initData);
			});

			return { ...state, employeeSaleList: employeeSaleListUpdate };
		}

		case DELETE_EMPLOYEE_SALE: {
			let employeeSaleListUpdate = [...state.employeeSaleList];
			employeeSaleListUpdate = employeeSaleListUpdate.filter(
				(employee) => employee.id !== payload.id
			);
			return { ...state, employeeSaleList: employeeSaleListUpdate };
		}

		case UPDATE_VALUE_EMPLOYEE_SALE: {
			let employeeSaleListUpdate = [...state.employeeSaleList];

			const { data, val, name } = payload;
			//INCREASE COUNT
			employeeSaleListUpdate = employeeSaleListUpdate.map((item) => {
				if (item.id === data.id) {
					return {
						...item,
						[name]: val,
					};
				} else {
					return item;
				}
			});
			return { ...state, employeeSaleList: employeeSaleListUpdate };
		}

		case RESET_EMPLOYEE_SALE: {
			return initialState;
		}

		default:
			return state;
	}
};
export default EmployeeSaleReducer;
