import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Checkbox,
	FormControl,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Tab,
	Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { memo, useEffect, useLayoutEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import serviceAPI from "api/ServiceAPI";
import UploadImage from "ui-component/UploadImage";
import uploadAPI from "api/UploadAPI";
import { Close, Label, Watch } from "@mui/icons-material";
import { getStringWithCommasFromNumber } from "utils";
import { Radio } from "antd";
import CreateComboItem from "./CreateComboItem";
import comboServiceAPI from "api/ComboServiceAPI";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DevTool } from "@hookform/devtools";
import OrderModal from "../OrderModal";
import { PATTERN } from "constants/common";
const defaultTypeList = [
	{
		value: "NOTIFICATION",
		title: "Notification",
	},
	{
		value: "TERMS_CONDITIONS",
		title: "Terms & Conditions",
	},
	{
		value: "LOCATION",
		title: "Location",
	},
];
const TextValidation = styled("span")({
	color: "red",
});
const Required = styled("span")({
	color: "red",
});
function ComboServiceModal({ ...props }) {
	const { isOpen, isClose, isLoading, formData, isUpdate, listSelect, getListTable } = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));

	const { register, handleSubmit, errors, control, reset, setValue, setError, getValues, watch } =
		useForm({
			mode: "onChange",
			reValidateMode: "onChange",
			shouldUnregister: false,
		});
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [editor, setEditor] = useState(null);
	const [type, setType] = useState("NOTIFICATION");
	// const [image, setImage] = useState(formData.image);
	// useEffect(() => {
	// 	setImage(formData.image);
	// }, [formData]);
	const defaultImageList = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
	const [imageList, setImageList] = useState(defaultImageList);

	const setImageInList = (file, idxImgInList) => {
		const imageListCopy = [...imageList];
		imageListCopy.splice(idxImgInList, 1, file);
		setImageList(imageListCopy);
	};

	//Multi upload
	// useEffect(() => {
	// 	if (isUpdate) {
	// 		const copyArrayUndefined = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
	// 		copyArrayUndefined.splice(0, formData.image_list.length, ...formData.image_list);
	// 		setImageList(formData.image_list.length === 0 ? defaultImageList : copyArrayUndefined);
	// 	}
	// }, [formData]);

	//Create Combo Items List

	const [comboItems, setComboItems] = useState([]);

	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleClose = () => {
		isClose(false);
	};
	const rx_live = /^\d*$/;
	const handleMaskValue = (value) => {
		const temp = value.split(",").join("");
		if (rx_live.test(temp)) {
			return temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return temp;
		}
	};
	const removeComma = (numberString) => {
		return numberString.toString().split(",").join("");
	};
	const onSubmit = async (data) => {
		if (!img || comboItems.length === 0) {
			return;
		}
		try {
			setLoading(true);

			if (!isUpdate) {
				// const urlImgList = [];

				// for (let i = 0; i < imageList.length; i++) {
				// 	if (imageList[i]) {
				// 		const res = await handleUpload(i);
				// 		urlImgList.push(res);
				// 	}
				// }
				const urlSingle = await handleUploadSingle(img);
				data.implementation_process = ckData;
				data.image = urlSingle;
				data.thumbnail = urlSingle;
				data.price = parseInt(data.price.split(",").join(""));
				data.discount = Number(data.discount.split(",").join(""));

				data.service_list = comboItems.map((item) => ({
					service_id: item.id,
					amount: item.amount,
				}));
				// data.discount = parseInt(Number(data.discount.split(",").join("")));
				// data.execution_time = parseInt(data.execution_time.split(",").join(""));

				const response = await comboServiceAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
					setLoading(false);
				}
			} else {
				let imgUrl;

				if (typeof img === "string") {
					//Img là một File
					imgUrl = img;
				} else {
					imgUrl = await handleUploadSingle(img);
				}
				data.image = imgUrl;
				data.implementation_process = ckData;
				data.price = parseInt(data.price.split(",").join(""));
				data.discount = Number(data.discount.split(",").join(""));
				data.thumbnail = imgUrl;

				// data.discount = parseInt(Number(data.discount.split(",").join("")));
				data.service_list = comboItems.map((item) => ({
					service_id: item.id,
					amount: item.amount,
				}));
				const response = await comboServiceAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};

	useLayoutEffect(() => {
		if (isUpdate) {
			setImg(formData.image);
			setComboItems(
				formData.combo_service_item.map((combo) => ({
					amount: combo.amount,
					...combo.service,
				}))
			);
			setCkData(formData?.note || "");
		} else {
			setCkData("");
			setType(selectList && selectList[0].value);
		}

		return () => {
			// setImageList(defaultImageList);
			reset();
			setImg();

			setComboItems([]);
			setValueTab("1");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const translator = (text) => {
		return `abeauty.service.dialog_form.${text}`;
	};
	const handleUpload = async (idx) => {
		const formData = new FormData();
		formData.append("image", imageList[idx]);

		try {
			const response = await uploadAPI.uploadImage(formData);
			return response.results.object.url;
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};

	const [img, setImg] = useState();
	const handleUploadSingle = async (img) => {
		const formData = new FormData();
		formData.append("image", img);

		try {
			const response = await uploadAPI.uploadImage(formData);
			return response.results.object.url;
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};

	const [valueTab, setValueTab] = useState("1");

	const handleChangeTab = (event, newValue) => {
		setValueTab(newValue);
	};

	return (
		<Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
			<DialogTitle>
				<Typography variant="h3" marginBottom={"20px"}>
					{isUpdate ? "Cập nhật gói dịch vụ" : "Tạo gói dịch vụ"}
				</Typography>
			</DialogTitle>
			<form style={{ width: "100%", overflow: "auto" }}>
				<DialogContent sx={{ width: "100%", overflowX: "hidden", height: "500px" }}>
					<TabContext value={valueTab}>
						<Box>
							<TabList onChange={handleChangeTab} aria-label="lab API tabs example">
								<Tab label="Thông tin gói" value="1" />
								<Tab
									label="Chọn dịch vụ trong gói"
									value="2"
									sx={{ color: comboItems.length === 0 && "red" }}
								/>
							</TabList>
						</Box>
						<TabPanel value="1">
							<>
								<Grid
									container
									spacing={3}
									style={{
										marginBottom: 20,
									}}
								>
									<Grid item xs={12}>
										<TextField
											margin="dense"
											name="title"
											label={"Tên gói dịch vụ *"}
											type="text"
											fullWidth
											defaultValue={isUpdate ? formData?.title : ""}
											variant="standard"
											inputRef={register({
												validate: {
													stringSpace: (v) =>
														!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
												},
												required: {
													value: true,
													message: "Vui lòng tên gói",
												},
											})}
										/>
										{errors.title && <TextValidation>{errors.title.message}</TextValidation>}
									</Grid>

									{/* Category - Loại combo  */}
									{/* <Grid item xs={6}>
								<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="demo-simple-select-autowidth-label">{t(`type`)}</InputLabel>
									<Select
										labelId="demo-simple-select-autowidth-label"
										id="demo-simple-select-autowidth"
										onChange={handleChange}
										defaultValue={isUpdate ? formData?.category_service_id : type}
										label={t(translator("category_service_id"))}
										name="category_service_id"
									>
										{Array.isArray(selectList) &&
											selectList.map((item) => (
												<MenuItem key={item.value} value={item.value}>
													{t(item.title)}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid> */}

									<Grid item xs={6}>
										<TextField
											margin="dense"
											name="price"
											label={t(translator("price"))}
											type="text"
											fullWidth
											required
											inputMode="numeric"
											defaultValue={isUpdate ? getStringWithCommasFromNumber(formData?.price) : ""}
											variant="standard"
											onChange={(e) => {
												e.target.value = handleMaskValue(e.target.value);
											}}
											inputRef={register({
												required: {
													value: true,
													message: "Vui lòng điền giá dịch vụ",
												},
												pattern: {
													value: PATTERN.NUMBER_POSITIVE,
													message: "Giá trị phải là một số",
												},
												validate: {
													minValue: (v) =>
														Number(removeComma(v)) >= 100000 || "Giá dịch vụ nhỏ nhất là 100.000",
												},
											})}
										/>
										{errors.price && <TextValidation>{errors.price.message}</TextValidation>}
									</Grid>
									<Grid item xs={6}>
										<TextField
											margin="dense"
											name="discount"
											label={t(translator("discount"))}
											type="text"
											fullWidth
											inputMode="numeric"
											defaultValue={
												isUpdate ? getStringWithCommasFromNumber(formData?.discount) : ""
											}
											variant="standard"
											onChange={(e) => {
												e.target.value = handleMaskValue(e.target.value);
											}}
											disabled={!watch("price")}
											inputRef={register({
												pattern: {
													value: PATTERN.NUMBER_POSITIVE,
													message: "Giá trị phải là một số dương",
												},
												// min: {
												// 	value: 0,
												// 	message: "Giá giảm phải lớn hơn 0",
												// },
												validate: {
													lessThanPrice: (v) => {
														const priceValue = getValues("price");
														return (
															Number(removeComma(v)) < Number(removeComma(priceValue)) ||
															"Giá giảm phải nhỏ hơn giá gốc"
														);
													},
													moreThanZero: (v) => {
														return Number(removeComma(v)) >= 0 || "Giá trị phải lớn hơn 0";
													},
												},
											})}
										/>
										{errors.discount && <TextValidation>{errors.discount.message}</TextValidation>}
									</Grid>
									{/* <Grid item xs={4}>
								<TextField
									margin="dense"
									name="execution_time"
									label={t(translator("execution_time"))}
									type="text"
									fullWidth
									inputMode="numeric"
									required
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									defaultValue={
										isUpdate ? getStringWithCommasFromNumber(formData?.execution_time) : ""
									}
									variant="standard"
									inputRef={register({
										required: {
											value: true,
											message: "Vui lòng điền thời gian thực hiện",
										},
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số",
										},
									})}
								/>
								{errors.execution_time && (
									<TextValidation>{errors.execution_time.message}</TextValidation>
								)}
							</Grid> */}
									<Grid item xs={12}>
										<FormLabel component="legend">{t(translator("hot"))}</FormLabel>
										<Switch
											name="hot"
											inputRef={register()}
											defaultChecked={isUpdate ? formData.hot : false}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "start",
											mb: "20px",
											flexDirection: "row",
										}}
									>
										<Typography variant="h5" sx={{ color: "gray", fontWeight: 400 }}>
											Hình ảnh đại diện gói
										</Typography>
									</Grid>

									<Grid
										item
										xs={12}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											mb: "20px",
											flexDirection: "column",
										}}
									>
										<UploadImage
											image={img}
											setImage={(file) => {
												return setImg(file);
											}}
											aspectRatio="1:1"
											levelAspectRatio={8}
											id={1}
										/>
									</Grid>
									{!img && <TextValidation>Vui lòng chọn hình ảnh</TextValidation>}

									{/* {Array.isArray(imageList) &&
								imageList.map((image, idx) => {
									return (
										<Grid
											item
											xs={6}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												mb: "20px",
												flexDirection: "column",
											}}
										>
											<UploadImage
												image={image}
												setImage={(file) => {
													return setImageInList(file, idx);
												}}
												aspectRatio="16:9"
												levelAspectRatio={2}
												id={idx}
											/>
										</Grid>
									);
								})}
							{imageList?.length === 0 && <TextValidation> Vui lòng chọn hình ảnh</TextValidation>} */}
									<Grid item xs={12}>
										<TextField
											margin="dense"
											name="description"
											label={t(translator("description"))}
											type="text"
											defaultValue={isUpdate ? formData.description : ""}
											fullWidth
											multiline
											minRows={4}
											variant="standard"
											inputRef={register()}
										/>
									</Grid>
								</Grid>
								<Box>
									<label
										style={{
											display: "block",
											marginBottom: "20px",
										}}
									>
										Quy trình
									</label>
									<div className="App">
										<CKEditor
											onReady={(editor) => {
												editor?.editing?.view?.change((writer) => {
													writer.setStyle(
														"height",
														"200px",

														editor?.editing?.view?.document?.getRoot()
													);
												});
												// Insert the toolbar before the editable area.
												editor.ui
													.getEditableElement()
													.parentElement.insertBefore(
														editor.ui?.view?.toolbar?.element,
														editor.ui?.getEditableElement()
													);
												setEditor(editor);
											}}
											onError={({ willEditorRestart }) => {
												// If the editor is restarted, the toolbar element will be created once again.
												// The `onReady` callback will be called again and the new toolbar will be added.
												// This is why you need to remove the older toolbar.
												if (willEditorRestart) {
													editor.ui?.view?.toolbar?.element?.remove();
												}
											}}
											onChange={(event, editor) => {
												const data = editor?.getData();
												console.log("🚀 ~ file: PostCRUD.js ~ line 193 ~ FormAdd ~ data", data);
												setCkData(data);
											}}
											editor={DecoupledEditor}
											data={ckData}
											config={{
												ckfinder: {
													uploadUrl: `https://hitek-02.hitek.com.vn:6061/api/v1/image/upload_ckeditor`,
												},

												mediaEmbed: {
													previewsInData: true,
													elementName: "iframe",
												},
											}}
										/>
									</div>
								</Box>
								{comboItems.length === 0 && (
									<Typography color="error" m={"20px 0"}>
										Gói dịch vụ này hiện tại chưa có dịch vụ nào, vui lòng thêm dịch vụ trước khi
										tạo
									</Typography>
								)}
							</>
						</TabPanel>
						<TabPanel value="2">
							<CreateComboItem comboItems={comboItems} setComboItems={setComboItems} />
						</TabPanel>
					</TabContext>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose()} variant="contained" color="secondary">
						{t(`cancel`)}
					</Button>
					<LoadingButton
						onClick={handleSubmit(onSubmit)}
						// endIcon={<SaveIcon />}
						loading={loading}
						loadingPosition="end"
						variant="contained"
					>
						{isUpdate ? t(translator("update")) : t(translator("create"))}
					</LoadingButton>
				</DialogActions>

				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={showErr.state}
					onClose={() => setShowErr({ state: false })}
					// action={action}
					autoHideDuration={5000}
					severity="error"
				>
					<Alert severity="error">{showErr.message}</Alert>
				</Snackbar>
			</form>
			<Close
				sx={{
					position: "absolute",
					top: "10px",
					right: "10px",
					cursor: "pointer",
				}}
				onClick={() => handleClose()}
			/>
		</Dialog>
	);
}

export default ComboServiceModal;
