import { Grid, IconButton, Pagination, Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "components/noRows";
import { ModalContext } from "providers/ModalProvider";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { SearchComponent } from "../../components/search";
import _debounce from "lodash/debounce";
import { Select as SelectAntd, Tooltip } from "antd";

import { SelectCustom } from "views/utilities/CustomStyle";
import ProductCategoryModal from "ui-component/modal-form/ProductCategoryModal";
import productCategoryAPI from "api/ProductCategoryAPI";
import { Delete, Edit, LocalFireDepartment, MoveToInbox } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";
import { ALERT_PERMISSION } from "constants/constant";

const { Option } = SelectAntd;

const searchList = [
	{
		value: "title",
		title: "abeauty.categoryService.table_title.title",
	},
];

const ProductCategory = () => {
	const { enqueueSnackbar } = useSnackbar();
	const modalContext = useContext(ModalContext);
	const { role } = useCustomAuth();
	const translator = (text) => {
		return t(`abeauty.categoryService.table_title.${text}`);
	};
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	const actionList = [
		{
			value: "delete",
			title: "Xoá",
			component: Delete,
			visible: role?.CATEGORY_PRODUCT_DELETE === ROLE?.CATEGORY_PRODUCT_DELETE,
		},
		{
			value: "edit",
			title: "Chỉnh sửa",
			component: Edit,
			visible: role?.CATEGORY_PRODUCT_UPDATE === ROLE?.CATEGORY_PRODUCT_UPDATE,
		},
		{
			value: "filterService",
			title: "Xem các sản phẩm",
			component: MoveToInbox,
			visible: role?.PRODUCT_VIEW === ROLE.PRODUCT_VIEW,
		},
	];
	// data
	const [rows, setRows] = useState([]);

	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);

	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const columns = [
		// {
		// 	field: "order",
		// 	headerName: t(`order`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },

		// {
		// 	field: "icon",
		// 	headerName: translator("icon"),
		// 	minWidth: 250,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	renderCell: (params) => (
		// 		<img
		// 			height={"80%"}
		// 			src={params.value || IMAGES.avatar_default}
		// 			alt="avatar-img"
		// 			onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
		// 		/>
		// 	),
		// },
		{
			field: "title",
			headerName: translator("title"),
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "description",
		// 	headerName: translator("description"),
		// 	flex: 1,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={params?.value}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.value}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		{
			field: "hot",
			headerName: "Hot",
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => params.value && <LocalFireDepartment color="error" />,
		},

		{
			field: "sort",
			headerName: "Thứ tự",
			minWidth: 250,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
		},

		{
			field: "actions",
			headerName: t(`actions`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			minWidth: 200,
			hide: !actionList.some((item) => item.visible),
			renderCell: (params) => {
				return (
					<>
						{actionList?.map((item) => {
							const Icon = item.component;

							return (
								item.visible && (
									<Tooltip title={item.title}>
										<IconButton onClick={() => onChangeAction(item.value, params.id, params.row)}>
											<Icon />
										</IconButton>
									</Tooltip>
								)
							);
						})}
					</>
				);
			},
		},
	];

	const getListServiceCategory = async (isLastDeletedItem) => {
		setIsLoading(true);
		let filter = "{}";
		let order = '[["sort", "asc"]]';
		if (queryParams?.title) {
			filter = `{"title":{"$iLike":"%${queryParams?.title}%"}}`;
		}
		if (queryParams?.email) {
			filter = `{"email":{"$iLike":"%${queryParams?.email}%"}}`;
		}
		if (queryParams?.phone?.trim()) {
			filter = `{"phone":{"$iLike":"%${queryParams?.phone.trim()}%"}}`;
		}
		if (queryParams?.activity) {
			filter = `{"activity":{"$iLike":"%${queryParams?.activity}%"}}`;
		}

		if (queryParams?.sortBy) {
			order = `[["${queryParams?.sortBy}", "${queryParams?.sort}"]]`;
		}
		try {
			const res = await productCategoryAPI.getList({
				fields: '["$all"]',
				order,
				filter,
				limit: pagination.limit,
				page: isLastDeletedItem ? queryParams.page - 1 : queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			if (isLastDeletedItem) {
				navigate({
					pathname: location.pathname,
					search: `?${queryString.stringify({ ...queryParams, page: paginationRes.page })}`,
				});
			}
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};
	const handleOpenModalEdit = (id, data) => {
		setData(data);
		setOpen(true);
		setIsUpdate(true);
	};
	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(
			id,
			() => handleDelete(id),
			"Khi xóa danh mục này tất cả dịch vụ liên quan sẽ bị xóa, bạn có chắc chắn không ?"
		);
	};

	const handleOpenCancelConfirm = (id, data) => {
		modalContext.showDeleteConfirm(
			id,
			() =>
				handleCancel(id, {
					activity: "CANCELED",
				}),
			"Bạn có chắc muốn hủy lịch hẹn này ?",
			"Xác nhận"
		);
	};

	const handleOpenActivityConfirm = (id, data) => {
		modalContext.showDeleteConfirm(
			id,
			() =>
				handleCancel(id, {
					activity: "WAITING",
				}),
			"Bạn có chắc muốn khôi phục lịch hẹn này ?",
			"Xác nhận"
		);
	};
	const handleCancel = async (id, data) => {
		try {
			await productCategoryAPI.update(id, data);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			await getListServiceCategory();
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};
	const handleDelete = async (id) => {
		const isLast = rows.length === 1 && pagination.page !== 1;

		try {
			await productCategoryAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListServiceCategory(isLast);
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const onChangeAction = (value, id, data) => {
		value === "edit" && handleOpenModalEdit(id, data);
		value === "delete" && handleOpenRemoveModal(id);
		value === "canceled" && handleOpenCancelConfirm(id);
		value === "activity" && handleOpenActivityConfirm(id);
		value === "filterService" &&
			navigate("/product", {
				state: {
					searchBy: "category_id",
					id: data.id,
					value: data.id,
					isFromCategory: true,
				},
			});
	};

	const handleRemoveMulti = () => {
		const isLast = rows.length === selectList.length && pagination.page !== 1;

		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await productCategoryAPI.removeMulti({
						items: JSON.stringify(selectList),
					});
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListServiceCategory(isLast);
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	useEffect(() => {
		if (!role) return;
		if (role?.PRODUCT_VIEW !== ROLE.PRODUCT_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty("title")) {
			setSearchBy("title");
			setSearchValue(queryParams?.title);
		}
		if (queryParams.hasOwnProperty("email")) {
			setSearchBy("email");
			setSearchValue(queryParams?.email);
		}
		if (queryParams.hasOwnProperty("phone_number")) {
			setSearchBy("phone_number");
			setSearchValue(queryParams?.phone_number);
		}
		if (queryParams.hasOwnProperty("type")) {
			setSearchBy("type");
			setSearchValue(queryParams?.type);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		getListServiceCategory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, role]);

	return (
		<>
			<MainCard>
				<HeaderActions
					title={"Danh mục sản phẩm"}
					onCreate={ROLE?.CATEGORY_PRODUCT_CREATE === role?.CATEGORY_PRODUCT_CREATE}
					setOpen={setOpen}
					setIsUpdate={setIsUpdate}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					<Box>
						{role?.PRODUCT_VIEW === ROLE.PRODUCT_VIEW && (
							<Link to={"/product"} style={{ marginRight: "5px" }} state={{ isAll: true }}>
								<Button variant={"outlined"}>Xem tất cả sản phẩm</Button>
							</Link>
						)}
						{role?.CATEGORY_PRODUCT_DELETE === ROLE?.CATEGORY_PRODUCT_DELETE && (
							<Button
								variant={selectList.length > 0 ? "contained" : "outlined"}
								color="error"
								disabled={selectList?.length === 0}
								onClick={handleRemoveMulti}
							>
								{t(`remove`)}
							</Button>
						)}
					</Box>
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection={role?.CATEGORY_PRODUCT_DELETE === ROLE?.CATEGORY_PRODUCT_DELETE}
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<ProductCategoryModal
				getListTable={getListServiceCategory}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
			/>
		</>
	);
};

export default ProductCategory;
