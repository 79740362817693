import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { createContext } from "react";
import { useTranslation } from "react-i18next";

const ModalContext = createContext();
const { confirm } = Modal;

const ModalProvider = ({ children }) => {
	const { t } = useTranslation();

	const showDeleteConfirm = (data, handleOk, title, okText) => {
		console.log(Content);
		confirm({
			title: title || t(`do_you_want_to_remove_it`),
			icon: <ExclamationCircleOutlined />,

			okText: okText || t(`remove`),
			okType: "danger",
			cancelText: t(`cancel`),
			centered: true,
			zIndex: 10000,
			maskClosable: true,
			onOk() {
				handleOk(data);
			},
			// onCancel() {
			// 	console.log("Cancel");
			// },
		});
	};
	const showConfirmModal = (handleOk, title, okText) => {
		confirm({
			title: title || t(`do_you_want_to_remove_it`),
			icon: <ExclamationCircleOutlined />,
			okText: okText || t(`remove`),
			okType: "danger",
			cancelText: t(`cancel`),
			centered: true,
			zIndex: 10000,
			maskClosable: true,
			onOk() {
				handleOk();
			},
			// onCancel() {
			// 	console.log("Cancel");
			// },
		});
	};

	const value = {
		showDeleteConfirm,
		showConfirmModal,
	};

	return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export { ModalContext, ModalProvider };
