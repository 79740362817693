import orderAPI from "api/OrderAPI";
import { activityOrderTranslator, formatVND, paymentTypeOrderTranslator } from "func";
import { LoadingContext } from "providers/LoadingProvider";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import OrderItem from "views/general/OrderItem";
import moment from "moment";
import MainCard from "ui-component/cards/MainCard";

function OrderDetailPage() {
	const { orderId } = useParams();
	const { state } = useLocation();
	const { loading, showLoading, hideLoading } = useContext(LoadingContext);

	const [data, setData] = useState([]);

	const fetchOrderById = async () => {
		showLoading();
		const fields = [
			"$all",
			{ user: ["$all", "$paranoid"] },
			{ order_items: ["$all"] },
			{ employee: ["$all", "$paranoid"] },
			{ employee_internal: ["$all", "$paranoid"] },
		];
		try {
			const res = await orderAPI.findOne(orderId, {
				fields: JSON.stringify(fields),
			});
			const data = res?.results.object;
			const dataParams = [
				{
					groupId: 1,
					groupTitle: "Thông tin đơn hàng",
					gridProps: {},
					children: [
						{
							id: "user",
							title: "Tên khách hàng",
							value: data?.user?.fullname,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "fullname",
							title: "Tên khách nhận",
							value: data?.fullname,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "phone",
							title: "SĐT đặt",
							value: data?.user?.username,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "phone",
							title: "SĐT nhận",
							value: data?.phone,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "order_code",
							title: "Mã đơn hàng",
							value: data.order_code,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "activity",
							title: "Trạng thái",
							value: activityOrderTranslator(data.activity)?.value,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "payment_type",
							title: "Hình thức thanh toán",
							value: paymentTypeOrderTranslator(data.payment_type),

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "created_at_unix_timestamp",
							title: "Thời gian tạo",
							value: moment(data.created_at_unix_timestamp, "x").format("DD/MM/YYYY HH:mm"),

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "fee_total",
							title: "Tổng tiền hàng",
							value: formatVND(data.fee_total),

							childrenProps: {
								span: 6,
								color: "error",
							},
						},
						{
							id: "discount",
							title: "Tổng giảm",
							value: formatVND(data.discount),

							childrenProps: {
								span: 6,
								color: "error",
							},
						},
						{
							id: "total_cost",
							title: "Tổng cộng",
							value: formatVND(data.total_cost),

							childrenProps: {
								span: 6,
							},
						},
						{
							id: " ",
							title: "",
							value: "",

							childrenProps: {
								span: 6,
							},
						},

						{
							id: "address",
							title: "Địa chỉ",
							value: data.address,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "ward",
							title: "Xã/Phường",
							value: data.ward,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "district",
							title: "Quận/Huyện",
							value: data.district,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "city",
							title: "Tỉnh/TP",
							value: data.city,

							childrenProps: {
								span: 6,
							},
						},
					],
				},
				{
					groupId: 2,
					groupTitle: "Thông tin khác",
					children: [
						{
							id: "reason",
							title: "Lý do hủy đơn",
							value: data.reason,

							childrenProps: {
								span: 24,
							},
						},
						{
							id: "note",
							title: "Ghi chú đơn hàng",
							value: data.note,

							childrenProps: {
								span: 24,
							},
						},
					],
				},
			];
			const dataInternal = [
				{
					groupId: 1,
					groupTitle: "Thông tin đơn hàng",
					gridProps: {},
					children: [
						{
							id: "employee_creator",
							title: "Nhân viên tạo",
							value: data?.employee?.fullname,

							childrenProps: {
								span: 6,
								className: data?.employee?.deleted_at && "line__though",
							},
						},
						{
							id: "employee_internal",
							title: "Nhân viên nhận",
							value: data?.employee_internal?.fullname,

							childrenProps: {
								span: 6,
								className: data?.employee_internal?.deleted_at && "line__though",
							},
						},

						{
							id: "order_code",
							title: "Mã đơn hàng",
							value: data.order_code,

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "activity",
							title: "Trạng thái",
							value: activityOrderTranslator(data.activity)?.value,

							childrenProps: {
								span: 6,
							},
						},

						{
							id: "created_at_unix_timestamp",
							title: "Thời gian tạo",
							value: moment(data.created_at_unix_timestamp, "x").format("DD/MM/YYYY HH:mm"),

							childrenProps: {
								span: 6,
							},
						},
						{
							id: "fee_total",
							title: "Tổng tiền hàng",
							value: formatVND(data.fee_total),

							childrenProps: {
								span: 6,
								color: "error",
							},
						},
						{
							id: "discount",
							title: "Tổng giảm",
							value: formatVND(data.discount),

							childrenProps: {
								span: 6,
								color: "error",
							},
						},
						{
							id: "total_cost",
							title: "Tổng cộng",
							value: formatVND(data.total_cost),

							childrenProps: {
								span: 6,
							},
						},
						{
							id: " ",
							title: "",
							value: "",

							childrenProps: {
								span: 6,
							},
						},
					],
				},
				{
					groupId: 2,
					groupTitle: "Thông tin khác",
					children: [
						{
							id: "reason",
							title: "Lý do hủy đơn",
							value: data.reason,

							childrenProps: {
								span: 24,
							},
						},
						{
							id: "note",
							title: "Ghi chú đơn hàng",
							value: data.note,

							childrenProps: {
								span: 24,
							},
						},
					],
				},
			];
			setData(state?.isOrder ? dataParams : dataInternal);
			hideLoading();
		} catch (error) {
			console.log(error);
			hideLoading();
		}
	};
	useEffect(() => {
		fetchOrderById();
	}, []);

	return (
		<MainCard>
			<DetailInformation data={data} />
			<OrderItem orderId={orderId} />
		</MainCard>
	);
}

export default OrderDetailPage;
