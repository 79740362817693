export const productTree = [
	{
		title: "Thẻ hạn mức dịch vụ",
		key: "PACK",
		children: [
			{
				title: "Xem DS",
				key: "PACK_VIEW", // id role
			},
			{
				title: "Thêm mới",
				key: "PACK_CREATE",
			},
			{
				title: "Cập nhật",
				key: "PACK_UPDATE",
			},
			// {
			// 	title: "Xóa",
			// 	key: "PACK_DELETE",
			// },
		],
	},
	// {
	// 	title: "Danh mục dịch vụ",
	// 	key: "CATEGORY_SERVICE",
	// 	children: [
	// 		{
	// 			title: "Xem DS",
	// 			key: "CATEGORY_SERVICE_VIEW", // id role
	// 		},
	// 		{
	// 			title: "Thêm mới",
	// 			key: "CATEGORY_SERVICE_CREATE",
	// 		},
	// 		{
	// 			title: "Cập nhật",
	// 			key: "CATEGORY_SERVICE_UPDATE",
	// 		},
	// 		{
	// 			title: "Xóa",
	// 			key: "CATEGORY_SERVICE_DELETE",
	// 		},
	// 	],
	// },
	{
		title: "Dịch vụ",
		key: "SERVICE",
		children: [
			{
				title: "Xem DS",
				key: "SERVICE_VIEW", // id role
			},
			{
				title: "Thêm mới",
				key: "SERVICE_CREATE",
			},
			{
				title: "Cập nhật",
				key: "SERVICE_UPDATE",
			},
			{
				title: "Xóa",
				key: "SERVICE_DELETE",
			},
			// {
			// 	title: "Giá bán",
			// 	key: "SERVICE_PRICE",
			// },
			{
				title: "Giá vốn",
				key: "SERVICE_IMPORT_PRICE",
			},
		],
	},
	// {
	// 	title: "Danh mục sản phẩm",
	// 	key: "CATEGORY_PRODUCT",
	// 	children: [
	// 		{
	// 			title: "Xem DS",
	// 			key: "CATEGORY_PRODUCT_VIEW", // id role
	// 		},
	// 		{
	// 			title: "Thêm mới",
	// 			key: "CATEGORY_PRODUCT_CREATE",
	// 		},
	// 		{
	// 			title: "Cập nhật",
	// 			key: "CATEGORY_PRODUCT_UPDATE",
	// 		},
	// 		{
	// 			title: "Xóa",
	// 			key: "CATEGORY_PRODUCT_DELETE",
	// 		},
	// 	],
	// },
	{
		title: "Sản phẩm",
		key: "PRODUCT",
		children: [
			{
				title: "Xem DS",
				key: "PRODUCT_VIEW", // id role
			},
			{
				title: "Thêm mới",
				key: "PRODUCT_CREATE",
			},
			{
				title: "Cập nhật",
				key: "PRODUCT_UPDATE",
			},
			{
				title: "Xóa",
				key: "PRODUCT_DELETE",
			},
			// {
			// 	title: "Giá bán",
			// 	key: "PRODUCT_PRICE",
			// },
			{
				title: "Giá vốn",
				key: "PRODUCT_IMPORT_PRICE",
			},
		],
	},
	{
		title: "Mã giảm giá",
		key: "VOUCHER",
		children: [
			{
				title: "Xem DS",
				key: "VOUCHER_VIEW", // id role
			},
			{
				title: "Thêm mới",
				key: "VOUCHER_CREATE",
			},
			{
				title: "Cập nhật",
				key: "VOUCHER_UPDATE",
			},
			{
				title: "Xóa",
				key: "VOUCHER_DELETE",
			},
		],
	},
];
