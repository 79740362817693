import axiosClient from "api/axiosClient";

const table = "category"
const productCategoryAPI = {
	async getList(params) {
		const url = `/${table}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async findOne(id, params) {
		const url = `${table}/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async create(data) {
		const url = `/${table}`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async update(id, data) {
		const url = `/${table}/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async remove(id) {
		const url = `/${table}/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},

	async removeMulti(params) {
		const url = `/${table}`;
		const resp = await axiosClient.delete(url, { params });
		return resp;
	},
};

export default productCategoryAPI;
