import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { IMAGES } from "assets/images/imagePath";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
function UploadImage({
	image,
	setImage,
	aspectRatio = "16:9",
	levelAspectRatio = 2,
	id = 1,
	children = "Chọn ảnh",
}) {
	const handleUpload = (e) => {
		const file = e.target.files[0];
		file.preview = URL.createObjectURL(file);
		setImage(file);
	};

	const handleRemoveImage = () => {
		URL.revokeObjectURL(image?.preview);
		setImage();
	};

	const Input = styled("input")({
		display: "none",
	});
	let widthHeightImg;

	switch (aspectRatio) {
		case "16:9":
			widthHeightImg = {
				width: `calc(160px * ${levelAspectRatio})`,
				height: `calc(90px * ${levelAspectRatio})`,
			};
			break;
		case "9:16":
			widthHeightImg = {
				height: `calc(160px * ${levelAspectRatio})`,
				width: `calc(90px * ${levelAspectRatio})`,
			};
			break;
		case "1:1":
			widthHeightImg = {
				height: `calc(40px * ${levelAspectRatio})`,
				width: `calc(40px * ${levelAspectRatio})`,
			};
			break;
		default:
			widthHeightImg = {
				width: `calc(160px * ${levelAspectRatio})`,
				height: `calc(90px * ${levelAspectRatio})`,
			};
	}

	const UploadFile = styled("div")({
		...widthHeightImg,
		borderRadius: "8px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: "1px solid gray",
		cursor: "pointer",
	});
	const RemoveImg = styled("div")({
		fontWeight: 500,
		color: "blue",
		textAlign: "left",
		cursor: "pointer",
	});

	const PreviewImg = styled("img")({
		...widthHeightImg,
		borderRadius: "8px",
		border: "1px solid #000",
		objectFit: "cover",
		objectPosition: "center",
	});

	// useEffect(() => {
	// 	return () => {
	// 		handleRemoveImage();
	// 	};
	// }, []);

	return (
		<>
			{!image ? (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<label htmlFor={`contained-button-file-${id}`}>
						<Input
							onChange={(e) => {
								handleUpload(e);
							}}
							accept="image/*"
							id={`contained-button-file-${id}`}
							multiple
							type="file"
						/>
						<UploadFile>{children}</UploadFile>
					</label>
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<PreviewImg
						src={image.preview || image}
						alt="Avatar"
						onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.img_default))}
					/>
					<RemoveImg
						sx={{
							mt: "20px",
						}}
						onClick={() => {
							handleRemoveImage();
						}}
					>
						Xóa
					</RemoveImg>
				</Box>
			)}
		</>
	);
}

export default UploadImage;
