import React, { useEffect } from "react";
import classnames from "classnames/bind";
import styles from "../Scheduler.module.scss";
import { Box, IconButton, Typography } from "@mui/material";
import { Close, DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import useCustomAuth from "hooks/useCustomAuth";
import { ROLE } from "constants/employeeRole";

const cx = classnames.bind(styles);
function AppointmentHead({ onEdit, onDeleteConfirm, data, onHide }) {
	///propsTooltip là bắt buộc

	const { role } = useCustomAuth();

	return (
		<div className={cx("wrapper")}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "20px",
				}}
			>
				<Typography
					sx={{
						padding: "20px 0",
					}}
					variant="h4"
				>
					Thông tin lịch hẹn
				</Typography>
				<Box
					sx={{
						display: "flex",
						flex: 0.3,
						gap: "8px",
					}}
				>
					{ROLE.BOOKING_UPDATE === role?.BOOKING_UPDATE && (
						<IconButton
							sx={{
								cursor: "pointer",
								"&.MuiButtonBase-root:hover": {
									bgcolor: "transparent",
								},
							}}
							disableFocusRipple
							disabled={data.activity === "CANCELED" || data.activity === "COMPLETED"}
							onClick={() => {
								onEdit(data);

								onHide();
							}}
						>
							<EditOutlined />
						</IconButton>
					)}

					{/* <IconButton
						sx={{
							cursor: "pointer",
							"&.MuiButtonBase-root:hover": {
								bgcolor: "transparent",
							},
						}}
						onClick={() => onDeleteConfirm(data.id, onHide)}
					>
						<DeleteOutlineOutlined sx={{ cursor: "pointer" }} />
					</IconButton> */}

					<IconButton
						sx={{
							cursor: "pointer",
							"&.MuiButtonBase-root:hover": {
								bgcolor: "transparent",
							},
						}}
						onClick={() => {
							onHide();
						}}
					>
						<Close sx={{ cursor: "pointer" }} />
					</IconButton>
				</Box>
			</Box>
		</div>
	);
}

export default AppointmentHead;
