import { FormControl, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { IconSearch } from "@tabler/icons";
import { Input } from "antd";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export const SearchComponent = ({
	isDisable = false,
	handleSearchBy = null,
	searchBy,
	searchList = [],
	searchValue,
	handleChangeSearch,
	dropdown = true,
	placeholder = "search",
	styledSearchBox = null,
	setIsFocus,

	propsMenuSearch,
	...props
}) => {
	const { t } = useTranslation();
	const theme = useTheme();

	console.log(searchList.length);

	return (
		<Box className="search-container" sx={{ mb: 2 }} {...props}>
			{dropdown && (
				<FormControl sx={{ minWidth: 150, mr: 2 }}>
					<InputLabel id="demo-simple-select-outlined-label">{t(`search_by`)}</InputLabel>
					<Select
						defaultValue={searchList[0].value}
						onChange={handleSearchBy}
						labelId="demo-simple-select-outlined-label"
						label={t(`search_by`)}
						value={searchBy}
						sx={(isDisable || searchList.length === 1) && { pointerEvents: "none" }}
						MenuProps={propsMenuSearch}
						IconComponent={!(isDisable || searchList.length === 1) && undefined}
					>
						{searchList?.map((item) => (
							<MenuItem
								sx={{
									display: item.isHide ? "none" : "block",
								}}
								key={item.value}
								value={item.value}
							>
								{t(`${item.title}`)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			<Box className="search-box-input" sx={{ width: "100%", ...styledSearchBox }}>
				<Input
					value={searchValue}
					onChange={(e) => {
						if (e.target.value.startsWith(" ")) return;
						else {
							handleChangeSearch(e.target.value);
						}
					}}
					onFocus={() => typeof setIsFocus === "function" && setIsFocus(true)}
					placeholder={t(placeholder)}
					className="search-input-antd"
					prefix={
						<InputAdornment position="start">
							<IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
						</InputAdornment>
					}
				/>
			</Box>
		</Box>
	);
};
