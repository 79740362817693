import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import bookingAPI from "api/BookingAPI";
import collabAPI from "api/CollabAPI";
import { BOOKING_ACTIVITY } from "constants/common";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";

function UserVerifyModal({ open, setOpen, data, getListTable }) {
	const handleClose = () => {
		setOpen(false);
	};
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [activity, setActivity] = useState(data.activity || "PROCESSING");
	const [noteAdmin, setNoteAdmin] = useState();
	const bodyRequest = useMemo(
		() => ({
			collab_status: activity,
			note: noteAdmin,
		}),
		[activity, noteAdmin]
	);

	const handleSetActivity = (event) => {
		setActivity(event.target.value);
	};

	const [loading, setLoading] = useState(false);
	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const dispatch = useDispatch();
	const handleSubmit = async () => {
		setLoading(true);

		try {
			if (bodyRequest.activity === "CANCELED") {
				const res = await bookingAPI.cancelBooking(data.id, bodyRequest);
			} else {
				const res = await collabAPI.update(data.id, bodyRequest);
			}

			setLoading(false);
			await getListTable();
			dispatch(actGetWaitingRequest(branchActiveId));

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			handleClose();
		} catch (err) {
			enqueueSnackbar(err?.message || t("error"), {
				variant: "error",
				autoHideDuration: 2000,
			});
			await getListTable();

			setLoading(false);
			handleClose();
		}
	};
	useEffect(() => {
		if (open) {
			if (data.collab_status === "WAITING") {
				setActivity("WAITING");
			} else {
				setActivity(data.collab_status);
			}
		}
		return () => setNoteAdmin("");
	}, [open]);
	// @ts-expect-error autofill of arbitrary value is not handled.
	return (
		<React.Fragment>
			<Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
				<DialogTitle sx={{ fontSize: "18px" }}>Chỉnh sửa trạng thái xác thực</DialogTitle>
				<DialogContent>
					<Box
						noValidate
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
							m: "auto",
						}}
					>
						<FormControl sx={{ mt: 2 }}>
							<InputLabel htmlFor="activity">Trạng thái xác thực</InputLabel>
							<Select
								fullWidth
								autoFocus
								defaultValue={data.collab_status}
								value={activity}
								onChange={handleSetActivity}
								label="Trạng thái xác thực"
								inputProps={{
									name: "collab_status",
									id: "collab_status",
								}}
							>
								<MenuItem value="WAITING">Chờ xử lý</MenuItem>

								<MenuItem value="CONFIRMED">Hoàn thành</MenuItem>
								{/* <MenuItem value="ASSIGNED" disabled>
									{ASSIGNED}
								</MenuItem> */}

								<MenuItem value="BLOCKED">Từ chối</MenuItem>
							</Select>
						</FormControl>
					</Box>

					<Box
						noValidate
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
							m: "auto",
						}}
					>
						<FormControl sx={{ mt: 2 }}>
							<TextField
								margin="dense"
								name="note"
								label={"Ghi chú"}
								type="text"
								fullWidth
								multiline
								minRows={4}
								value={noteAdmin}
								onChange={(e) => setNoteAdmin(e.target.value)}
							/>
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						onClick={() => handleSubmit()}
						// endIcon={<SaveIcon />}
						loading={loading}
						loadingPosition="end"
						variant="contained"
					>
						Chỉnh sửa
					</LoadingButton>
				</DialogActions>

				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</React.Fragment>
	);
}

export default UserVerifyModal;
