export const COLOR = {
	MAIN_COLOR: "#311d4f",
	BG_COLOR: "#ede7f6",
	SUCCESS: "#28a745",
	ERROR: "#dc3545",
	WARNING: "#ffc107",
	SECONDARY: "#007bff",
	NAVI: "#536dfe",
};

export const PATTERN = {
	EMAIL:
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
	PASSWORD_SIX_NUMBER: /^[0-9]{6}$/,
	IS_NUMBER: /^[0-9]*$/,
	PHONE_NUMBER_VN: /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,
	NUMBER_POSITIVE: /^[0-9,]*$/,
	NO_SPACES: /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){2,50}[a-zA-Z0-9]$/, //pattern of username
};

export const ALERT_MESSAGE = {
	CHANGE_WALLET:
		"Bạn cam kết việc thay đổi ví này là chính xác với mức tiền của khách hàng này ở hồ sơ bên ngoài. Mọi sai sót của bạn sẽ phải chịu trách nhiệm với Quản lý của VBeauty & Spa. Tất cả hoạt động thay đổi ví của bạn sẽ được hệ thống ghi nhận lại và báo về cho quản lý",
};

export const STATUS_TRANSACTION = {
	PENDING: "Chờ xử lý",
	CANCEL: "Từ chối",
	DONE: "Hoàn thành",
};

export const BOOKING_ACTIVITY = {
	WAITING: "Đợi xác nhận",
	PROCESSING: "Đã xác nhận lịch",
	CONFIRMED: "Đã xác nhận với khách hàng",
	ASSIGNED: "Đã gán nhân viên",
	COMPLETED: "Hoàn thành",
	CANCELED: "Hủy bỏ",
};

export const PAYMENT_TYPE = {
	CASH: "Tiền mặt",
	WALLET: "Hạn mức dịch vụ",
	TRANSFER: "Chuyển khoản",
	CARD: "Quẹt thẻ",
};

export const PRODUCT_SCAN_SKIN_TYPE = [
	{
		id: 1,
		value: "K5",
		text: "Hỗ trợ giảm Lão Hóa Da",
	},
	{
		id: 2,

		value: "K6",
		text: "Hỗ trợ điều Trị Mụn",
	},
	{
		id: 3,

		value: "K7",
		text: "Hỗ trợ giảm quầng thâm mắt",
	},
	{
		id: 4,

		value: "K8",
		text: "Hỗ trợ giảm lỗ chân lông",
	},
	{
		id: 5,

		value: "K9",
		text: "Hỗ trợ giảm thâm nám da",
	},
];
export const SKIN_LEVEL = [
	{
		value: "1",
		text: "Nhẹ",
	},
	{
		value: "2",
		text: "Trung bình",
	},
	{
		value: "3",
		text: "Nặng",
	},
];
export const PAGINATION_SETTING = {
	PAGE_SIZE: 5,
};
