import { Badge, Divider, List, Typography } from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// project imports
import NavItem from "../NavItem";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const useStyles = makeStyles((theme) => ({
	myBadge: {
		top: "50%",
		right: "10px",
	},
}));
const NavGroup = ({ item, ...props }) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const classes = useStyles();

	// menu list collapse & items
	const items = item.children?.map((menu) => {
		return (
			menu.visible && (
				<Badge
					sx={{ width: "100%" }}
					invisible={!menu.badge}
					badgeContent={menu?.count || 0}
					max={9999999}
					color="error"
					classes={{ badge: classes.myBadge }}
				>
					<NavItem key={menu.id} item={menu} level={1} />
				</Badge>
			)
		);
	});

	return (
		<div {...props}>
			<List
				subheader={
					item.title && (
						<Badge
							sx={{ width: "100%" }}
							invisible={!item.badge}
							badgeContent={item?.count || 0}
							color="error"
							max={9999999}
							classes={{ badge: classes.myBadge }}
						>
							<Typography
								variant="caption"
								sx={{ ...theme.typography.menuCaption }}
								display="block"
								gutterBottom
							>
								{t(item.title)}
								{item.caption && (
									<Typography
										variant="caption"
										sx={{ ...theme.typography.subMenuCaption }}
										display="block"
										gutterBottom
									>
										{item.caption}
									</Typography>
								)}
							</Typography>
						</Badge>
					)
				}
			>
				{items}
			</List>

			{/* group divider */}
			<Divider sx={{ mt: 0.25, mb: 1.25 }} />
		</div>
	);
};

NavGroup.propTypes = {
	item: PropTypes.object,
};

export default NavGroup;
