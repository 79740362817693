import { Box, Typography } from "@mui/material";
import employeeAPI from "api/EmployeeAPI";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";
import useDebounce from "hooks/useDebounce";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EmployeeModal from "ui-component/modal-form/employee-modal-create-update/index";
import NoData from "ui-component/NoData";
import { actResetUser, actSetInfoCustomer } from "../../store/action";
import Cart from "../Cart";
import CustomerItem from "../CustomerItem";
import OrderSearchComponent from "../OrderSearchComponent";
import EmployeeInfo from "./EmployeeInfo";

function InternalSide() {
	const { role } = useCustomAuth();
	const { orderList, infoCustomer } = useSelector((state) => state.createOrder);
	const [searchValue, setSearchValue] = useState("");
	const debounceValue = useDebounce(searchValue);

	const [searchResults, setSearchsResults] = useState([]);

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const fetchUserBySearch = async (searchVal) => {
		setLoading(true);
		const filter = {
			$or: [
				{
					username: {
						$iLike: `%${searchVal.trim()}%`,
					},
				},
				{
					fullname: {
						$iLike: `%${searchVal.trim()}%`,
					},
				},
			],
		};

		const params = {
			fields: '["$all"]',
			filter: JSON.stringify(filter),
		};
		try {
			const res = await employeeAPI.getList(params);
			if (searchVal.trim() === "") {
				setSearchsResults([]);
			} else {
				setSearchsResults(res.results.objects.rows);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);

			console.log(err);
		}
	};

	const [errCheckLengthVal, setErrCheckLengthVal] = useState("");

	const isValid = useRef(false);

	const validateSearchValue = (value) => {
		isValid.current = false;
		let regexp = /^[0]{1}[0-9]{2}([0-9]{3}|[0-9]{4})[0-9]{4}$/gm;
		if (value.trim().length === 0) {
			isValid.current = false;
			setErrCheckLengthVal("");
			return;
		}
		if (value.trim().length < 10) {
			isValid.current = true;

			setErrCheckLengthVal("Vui lòng nhập đúng 10 số");
		} else if (!regexp?.test(value?.toString())) {
			isValid.current = true;
			setErrCheckLengthVal("Số điện thoại không đúng định dạng");
		} else {
			isValid.current = false;
			setErrCheckLengthVal("");
		}
	};

	// useEffect(() => {
	// 	validateSearchValue(searchValue);
	// }, [searchValue]);

	useEffect(() => {
		if (debounceValue.trim() !== "") {
			fetchUserBySearch(debounceValue);
		} else {
			dispatch(actResetUser());
			setSearchsResults([]);
		}
	}, [debounceValue]);

	// useEffect(() => {
	// 	if (searchResults.length === 0 && debounceValue.trim().length === 10) {
	// 		dispatch(
	// 			actSetInfoCustomer({
	// 				phone: debounceValue.trim(),
	// 			})
	// 		);
	// 	} else if (searchResults.length !== 0 && debounceValue.trim().length === 10) {
	// 		dispatch(actSetInfoCustomer(searchResults[0]));
	// 	}
	// }, [searchResults]);

	const handleOnPressCustomer = (user) => {
		dispatch(actSetInfoCustomer(user));
	};

	const [openUserModal, setOpenUserModal] = useState(false);
	const [isFocusOutSide, setIsFocusOutSide] = useState(true);

	const onAdd = () => {
		setIsFocusOutSide(false);
		setOpenUserModal({
			open: true,
			username: searchValue,
		});
	};

	useEffect(() => {
		if (!infoCustomer.userId) {
			setSearchValue("");
		}
	}, [infoCustomer.userId]);

	const refSearchInput = useRef(null);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-start",

					gap: "20px",
				}}
			>
				{!infoCustomer.userId ? (
					<OrderSearchComponent
						ref={refSearchInput}
						isFocusOutSide={isFocusOutSide}
						searchValue={searchValue}
						handleChangeSearch={(value) => setSearchValue(value)}
						placeholderText={"Tìm theo Tên/Tài khoản nhân viên"}
						searchResult={searchResults}
						ResultComponent={CustomerItem}
						widthBoxSearch={"300px"}
						propsResultComponent={(item) => ({
							customer: item,
							onPressCustomer: () => handleOnPressCustomer(item),
							isActive: infoCustomer.userId === item.id,
						})}
						styledSearchBox={{
							width: "40%",
						}}
						hasAdd={role?.CUSTOMER_CREATE === ROLE?.CUSTOMER_CREATE}
						onAdd={onAdd}
						loading={loading}
					/>
				) : (
					<EmployeeInfo />
				)}
			</Box>
			<Box sx={{ marginBottom: "30px", marginTop: "10px" }}>
				<Typography variant="h5" color={"error"}>
					{errCheckLengthVal}
				</Typography>
			</Box>
			{/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
				{infoCustomer.order_type && (
					<Typography variant="h3" sx={{ margin: "20px 5px" }}>
						Loại sản phẩm: {infoCustomer.order_type}
					</Typography>
				)}
			</Box> */}

			{orderList.length > 0 ? (
				<Cart orderList={orderList} />
			) : (
				<NoData text={"Vui lòng chọn sản phẩm"} />
			)}

			<EmployeeModal
				employee={{}}
				valuePhone={openUserModal.username}
				getListTable={() => {
					return;
				}}
				isLoading={() => {
					return;
				}}
				isOpen={openUserModal.open}
				isUpdate={false}
				isClose={(value) => {
					setOpenUserModal({
						open: value,
						username: "",
					});
					setIsFocusOutSide(true);
				}}
			/>
		</>
	);
}

export default InternalSide;
