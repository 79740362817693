import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Divider } from "antd";
import { HOTLINE } from "constants/constant";
import useCustomAuth from "hooks/useCustomAuth";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

function HeaderInfo({ branch_name }) {
	const today = new Date();
	const { branchActive } = useSelector((state) => state.branchReducer);

	return (
		<Stack spacing={1}>
			<Typography variant="h5">Tên cửa hàng: VBeauty & Spa</Typography>
			<Typography variant="h5">Chi nhánh: {branch_name}</Typography>
			<Typography variant="h5">Điện thoại: {HOTLINE}</Typography>
			<Divider style={{ borderColor: "#000" }} dashed />
			<Typography variant="h5">Ngày bán: {moment(today).format("DD/MM/YYYY HH:mm")}</Typography>
		</Stack>
	);
}

export default HeaderInfo;
