import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployeeSearchComponent from "views/pages/CreateOrder/components/OrderSearchComponent";
import EmployeeItem from "./EmployeeItem";
import EmployeeSearchItem from "../../AssignBookingModal/EmployeeItem";
import { actSelectEmployee } from "store/actions/EmployeeBooking";
import useDebounce from "hooks/useDebounce";
import employeeAPI from "api/EmployeeAPI";
import { useEffect } from "react";
import NoData from "ui-component/NoData";

function SelectedEmployee({ isUpdate = false, ...props }) {
	const employeeList = useSelector((state) => state.employeeBooking.employeeList);
	const dispatch = useDispatch();
	const [searchValue, setSearchValue] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const debounceValue = useDebounce(searchValue);
	const [loading, setLoading] = useState(false);
	const fetchEmployee = async () => {
		const params = {
			fields: '["$all"]',
			filter: `{"fullname":{"$iLike":"%${debounceValue.trim()}%"}}`,
		};
		const res = await employeeAPI.getList(params);
		// const notAdmin = res.results.objects.rows.filter((item) => item.role !== "SUPERADMIN");
		if (searchValue.trim() === "") {
			setSearchResults([]);
		} else {
			setSearchResults(res.results.objects.rows);
		}
	};
	const handleSelectEmployee = (item) => {
		dispatch(actSelectEmployee(item));
		setSearchValue("");
	};
	useEffect(() => {
		fetchEmployee();
	}, [debounceValue]);
	return (
		<Box
			sx={{
				padding: "16px 24px",
				width: "100%",
			}}
			{...props}
		>
			<EmployeeSearchComponent
				searchValue={searchValue}
				handleChangeSearch={(value) => setSearchValue(value)}
				placeholderText={"Tìm theo tên nhân viên"}
				searchResult={searchResults}
				ResultComponent={EmployeeSearchItem}
				propsResultComponent={(item) => ({
					employee: item,
					active: employeeList.some((employee) => employee.id === item.id),
					onSelectEmployee: () => handleSelectEmployee(item),
				})}
			/>
			<Box my={3}>
				{employeeList.length === 0 ? (
					<NoData text={"Vui lòng chọn nhân viên"} />
				) : (
					<>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Typography variant="h5" sx={{ flex: 2, textAlign: "left" }}>
								Tên nhân viên
							</Typography>
							<Typography variant="h5" sx={{ flex: 1.5, textAlign: "center" }}>
								Số công
							</Typography>

							<Typography variant="h5" sx={{ flex: 0.5, textAlign: "center" }}>
								Xóa
							</Typography>
						</Box>
						<Divider sx={{ mt: 2 }} />
						{employeeList.map((employee) => (
							<EmployeeItem key={employee.id} employee={employee} isUpdate={isUpdate} />
						))}
					</>
				)}
			</Box>
		</Box>
	);
}

export default SelectedEmployee;
