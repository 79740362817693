import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import employeeAPI from "api/EmployeeAPI";
import uploadAPI from "api/UploadAPI";
import md5 from "md5";
import { useSnackbar } from "notistack";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateAvatar } from "store/actions/EmployeeAction";
import { useSelector } from "react-redux";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import userAPI from "api/UserAPI";
import authAPI from "api/AuthAPI";
import { handleMaskValue, handleParseMask } from "func";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { data } from "ui-component/MuiScheduler/fakeData";
import { ALERT_MESSAGE, PATTERN } from "constants/common";
import { ModalContext } from "providers/ModalProvider";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";

const Input = styled("input")({
	display: "none",
});

const UploadFile = styled("div")({
	width: "100px",
	height: "100px",
	borderRadius: "8px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	border: "1px solid gray",
	cursor: "pointer",
});

const PreviewImg = styled("img")({
	width: "100%",
	borderRadius: "8px",
	maxWidth: "100px",
});
const RemoveImg = styled("div")({
	fontWeight: 500,
	color: "blue",
	textAlign: "center",
	cursor: "pointer",
});
const TextValidation = styled("span")({
	marginLeft: "15px",
	fontSize: "12px",
	color: "red",
});

function UserModal(props) {
	const {
		isOpen,
		isClose,
		isLoading,
		employee,
		isUpdate,
		getListTable,
		updateInfo,
		valuePhone,
		onSuccessRegister,
	} = props;
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const { register, handleSubmit, errors, control } = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
		validationResolver: undefined,
		validationContext: undefined,
		validateCriteriaMode: "firstErrorDetected",
		submitFocusError: true,
	});
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState();
	const [type, setType] = useState(employee?.role || "ADMIN");

	const [messageErr, setMessageErr] = useState("");

	// useEffect(() => {
	// 	if (employee?.avatar && isUpdate) {
	// 		setImage(employee.avatar);
	// 	} else {
	// 		setImage();
	// 	}
	// 	if (employee?.role && isUpdate) {
	// 		setType(employee.role);
	// 	} else {
	// 		setType("ADMIN");
	// 	}
	// 	// eslint-disable-next-line
	// }, [employee.avatar, isOpen]);

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const onSubmit = async (data) => {
		setLoading(true);

		if (!isUpdate) {
			try {
				data = {
					...data,
					password: md5(data.password),
					birthday: data.birthday ? moment(data.birthday).valueOf("x") : null,
					gender: null || data.gender,
				};

				Object.keys(data).forEach((key) => {
					if (!!data[key] === false) {
						delete data[key];
					}
				});

				const res = await authAPI.registerUser(data);

				onSuccessRegister && onSuccessRegister(res?.results.object);
				handleClose();
				setLoading(false);
				isLoading(1);
				setMessageErr("");
				getListTable();
				enqueueSnackbar(t(`success`), {
					variant: "success",
					autoHideDuration: 2000,
				});
			} catch (error) {
				setLoading(false);
				if (error?.message && error?.code === 402) {
					setMessageErr(error?.message);
				}
				enqueueSnackbar(t(error?.message) || t(`error`), {
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		} else {
			try {
				data = {
					...data,
					birthday: data.birthday ? moment(data.birthday, "YYYY/MM/DD").valueOf("x") : null,
					gender: null || data.gender,
					wallet: handleParseMask(data.wallet || employee.wallet || 0),
					wallet_rose: handleParseMask(data.wallet_rose || employee.wallet_rose || 0),
				};
				const regexExp = /^[a-f0-9]{32}$/gi;
				const checkIsMd5 = regexExp.test(data.password);
				// data.avatar = image ? image : "";
				// data.role = type;
				// Nếu có thay đổi password thì mới md5
				if (!checkIsMd5 && data.password) {
					data.password = md5(data.password);
				}
				if (!data.password) {
					data.password = null;
				}
				// const params = {
				// 	phone: data.phone,
				// };

				// if (data.password.length) {
				// 	params.password = md5(data.password);
				// }

				Object.keys(data).forEach((key) => {
					if (data[key] === undefined && key !== "wallet") {
						delete data[key];
					}
				});
				if (data.wallet == employee.wallet) {
					delete data.wallet;
				}
				if (data.wallet_rose == employee.wallet_rose) {
					delete data.wallet_rose;
				}
				const res = await userAPI.update(employee.id, data);

				// if (employee.id === employeeInfo.id) {
				// 	dispatch(updateAvatar(image));
				// }
				updateInfo && updateInfo(res?.results?.object);

				handleClose();

				setLoading(false);
				isLoading(1);
				setMessageErr("");
				getListTable();

				// if (updateInfo) {
				// 	updateInfo(res.results.object);
				// }
				dispatch(actGetWaitingRequest(branchActiveId));

				enqueueSnackbar(t(`success`), {
					variant: "success",
					autoHideDuration: 2000,
				});
			} catch (error) {
				setLoading(false);
				if (error?.message && error?.code === 402) {
					setMessageErr(error?.message);
				}
				enqueueSnackbar(t(error?.message) || " t(`error`)", {
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		}
	};

	const handleClose = () => {
		isChangeWallet.current = false;
		isClose(false);
		setMessageErr("");
	};

	const handleChange = (event) => {
		setType(event.target.value);
	};

	const { role } = useCustomAuth();
	console.log(role);
	const handleUpload = async (e) => {
		const formData = new FormData();
		formData.append("image", e.target.files[0]);

		try {
			const response = await uploadAPI.uploadImage(formData);
			setImage(response?.results?.object?.url);
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};

	const isChangeWallet = useRef(false);

	const modalContext = useContext(ModalContext);

	const onSubmitConfirm = () => {
		if (isUpdate) {
			if (isChangeWallet.current) {
				modalContext.showDeleteConfirm(
					null,
					handleSubmit(onSubmit),
					ALERT_MESSAGE.CHANGE_WALLET,
					"Đồng ý"
				);
			} else {
				handleSubmit(onSubmit)();
			}
		} else {
			handleSubmit(onSubmit)();
		}
	};

	return (
		<>
			<div>
				<Dialog fullWidth open={isOpen} onClose={handleClose}>
					<DialogTitle style={{ fontSize: "22px", fontWeight: "500" }}>
						{isUpdate ? "Cập nhật khách hàng" : "Thêm khách hàng mới"}
					</DialogTitle>
					<form>
						<DialogContent>
							<div style={{ color: "red", marginBottom: "20px" }}>{messageErr}</div>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField
										defaultValue={isUpdate ? employee?.phone : "" || valuePhone ? valuePhone : ""}
										margin="dense"
										disabled={isUpdate}
										name="phone"
										label={t(`phone_number`) + "*"}
										type="text"
										fullWidth
										variant="outlined"
										inputRef={register(
											!isUpdate
												? {
														required: t("phone_number_is_required"),
														pattern: {
															value:
																/^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,

															message: "Số điện thoại không đúng định dạng",
														},
														// minLength: {
														// 	value: 10,
														// 	message: "Số điện thoại chỉ có 10 kí tự",
														// },
												  }
												: {}
										)}
									/>
									{errors.phone && (
										<Typography variant="h5" color="error">
											{errors.phone?.message}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12}>
									<Box sx={{ width: "100%", position: "relative" }}>
										<TextField
											margin="dense"
											name="password"
											label={t(`password`) + (isUpdate ? "" : "*")}
											type={!showPassword ? "password" : "text"}
											fullWidth
											variant="outlined"
											sx={{ position: "relative" }}
											inputRef={
												isUpdate
													? register({
															pattern: {
																value: /^[0-9]{6}$/,
																message: "Mật khẩu phải là một dãy số 6 ký tự",
															},
															// minLength: {
															// 	value: 6,
															// 	message: t("password_must_be_at_least_6_characters"),
															// },
													  })
													: register({
															pattern: {
																value: /^[0-9]{6}$/,
																message: "Mật khẩu phải là một dãy số 6 ký tự",
															},
															required: t("password_is_required"),
															// minLength: {
															// 	value: 6,
															// 	message: t("password_must_be_at_least_6_characters"),
															// },
													  })
											}
											inputProps={{
												autoComplete: "new-password",
												onPaste: (e) => e.preventDefault(),
											}}
											autoComplete="new-password"
										/>
										<Box
											sx={{
												position: "absolute",
												right: 20,
												top: "50%",
											}}
										>
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end"
													size="large"
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										</Box>
									</Box>

									{errors.password && <TextValidation>{errors.password?.message}</TextValidation>}
								</Grid>
								{isUpdate && (
									<Grid item xs={12}>
										<Box sx={{ width: "100%", position: "relative" }}>
											<TextField
												defaultValue={isUpdate ? handleMaskValue(employee?.wallet) : ""}
												margin="dense"
												disabled={!(ROLE?.CUSTOMER_UPDATE_WALLET === role?.CUSTOMER_UPDATE_WALLET)}
												name="wallet"
												label={"Hạn mức dịch vụ"}
												fullWidth
												variant="outlined"
												onChange={(e) => {
													const value = e.target.value;
													if (handleParseMask(value) == employee?.wallet) {
														isChangeWallet.current = false;
													} else {
														isChangeWallet.current = true;
													}
													e.target.value = handleMaskValue(value);
													return value;
												}}
												sx={{ position: "relative" }}
												inputRef={register({
													pattern: {
														value: /^[0-9,]*$/,
														message: "Vui lòng nhập số",
													},
													required: {
														value: true,
														message: "Giá trị không được để trống",
													},

													// minLength: {
													// 	value: 6,
													// 	message: t("password_must_be_at_least_6_characters"),
													// },
												})}
											/>
										</Box>

										{errors.wallet && <TextValidation>{errors.wallet?.message}</TextValidation>}
									</Grid>
								)}
								{isUpdate && (
									<Grid item xs={12}>
										<Box sx={{ width: "100%", position: "relative" }}>
											<TextField
												defaultValue={isUpdate ? handleMaskValue(employee?.wallet_rose) : ""}
												margin="dense"
												disabled={!(ROLE?.UPDATE_WALLET_ROSE === role?.UPDATE_WALLET_ROSE)}
												name="wallet_rose"
												label={"Ví hoa hồng"}
												fullWidth
												variant="outlined"
												onChange={(e) => {
													const value = e.target.value;
													if (handleParseMask(value) == employee?.wallet_rose) {
														isChangeWallet.current = false;
													} else {
														isChangeWallet.current = true;
													}
													e.target.value = handleMaskValue(value);
													return value;
												}}
												sx={{ position: "relative" }}
												inputRef={register({
													pattern: {
														value: /^[0-9,]*$/,
														message: "Vui lòng nhập số",
													},
													required: {
														value: true,
														message: "Giá trị không được để trống",
													},

													// minLength: {
													// 	value: 6,
													// 	message: t("password_must_be_at_least_6_characters"),
													// },
												})}
											/>
										</Box>

										{errors.wallet_rose && (
											<TextValidation>{errors.wallet_rose?.message}</TextValidation>
										)}
									</Grid>
								)}
								<Grid item xs={6}>
									<Box sx={{ width: "100%", position: "relative" }}>
										<TextField
											defaultValue={isUpdate ? employee?.fullname : ""}
											margin="dense"
											name="fullname"
											label={"Họ và tên"}
											fullWidth
											variant="outlined"
											inputRef={register()}
										/>
									</Box>

									{errors.fullname && <TextValidation>{errors.wallet?.description}</TextValidation>}
								</Grid>
								<Grid item xs={6}>
									<Box sx={{ width: "100%", position: "relative" }}>
										<TextField
											defaultValue={isUpdate ? employee?.name_admin_note : ""}
											margin="dense"
											name="name_admin_note"
											label={"Tên thân mật"}
											fullWidth
											variant="outlined"
											inputRef={register()}
										/>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<TextField
										defaultValue={isUpdate ? employee?.email : ""}
										margin="dense"
										name="email"
										label={"Email"}
										fullWidth
										variant="outlined"
										inputRef={register({
											pattern: {
												value: PATTERN.EMAIL,
												message: "Email không đúng định dạng",
											},
										})}
									/>
									{errors.email && (
										<Typography variant="h5" color={"error"}>
											{errors.email.message}
										</Typography>
									)}
								</Grid>

								<Grid
									item
									xs={6}
									// sx={{
									// 	display: "flex",
									// 	alignItems: "center",
									// }}
								>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<Controller
											as={
												<DesktopDatePicker
													label="Ngày sinh"
													inputFormat="dd/MM/yyyy"
													renderInput={(params) => (
														<TextField
															margin="dense"
															placeholder="Ngày sinh"
															sx={{ height: "100%" }}
															fullWidth
															{...params}
														/>
													)}
												/>
											}
											defaultValue={
												isUpdate
													? employee?.birthday
														? moment(parseInt(employee?.birthday)).format()
														: null
													: null
											}
											control={control}
											name="birthday"
											placeholderText="Vui lòng chọn ngày"
										/>
									</LocalizationProvider>
								</Grid>
								<Grid item xs={12}>
									<Box sx={{ width: "100%" }}>
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">Giới tính</InputLabel>
											<Controller
												as={
													<Select
														labelId="demo-simple-select-label"
														label="Giới tính"
														id="demo-simple-select"
														fullWidth
													>
														<MenuItem value={"OTHER"}>Khác</MenuItem>
														<MenuItem value={"MALE"}>Nam</MenuItem>
														<MenuItem value={"FEMALE"}>Nữ</MenuItem>
													</Select>
												}
												defaultValue={isUpdate ? employee?.gender : null}
												name="gender"
												control={control}
											/>
										</FormControl>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Box sx={{ width: "100%", position: "relative" }}>
										<TextField
											multiline
											minRows={3}
											defaultValue={isUpdate ? employee?.description : ""}
											margin="dense"
											name="description"
											label={"Ghi chú"}
											fullWidth
											variant="outlined"
											inputRef={register()}
										/>
									</Box>

									{errors.description && (
										<TextValidation>{errors.wallet?.description}</TextValidation>
									)}
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions style={{ margin: "0px 20px 20px" }}>
							<Button onClick={handleClose} variant="outlined" style={{ marginRight: "20px" }}>
								{t(`cancel`)}
							</Button>
							<LoadingButton
								type="button"
								loading={loading}
								// endIcon={<SaveIcon />}
								// loadingPosition="end"
								variant="outlined"
								onClick={onSubmitConfirm}
							>
								{isUpdate ? t(`update`) : t(`add`)}
							</LoadingButton>
						</DialogActions>
					</form>
					<Close
						sx={{
							position: "absolute",
							top: "10px",
							right: "10px",
							cursor: "pointer",
						}}
						onClick={() => handleClose()}
					/>
				</Dialog>
			</div>
		</>
	);
}

export default memo(UserModal);
