import { CheckBox } from "@mui/icons-material";
import { Grid, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import orderItemAPI from "api/OrderItemAPI";
import { CustomNoRowsOverlay } from "components/noRows";
import { activityOrderTranslator, formatVND, paymentTypeOrderTranslator } from "func";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import ComboServiceInfo from "ui-component/modal-form/ComboService";
import ComboServiceModal from "ui-component/modal-form/ComboServiceModal";
import DialogMain from "ui-component/modal-form/DialogMui";
import ImageModal from "ui-component/modal-form/ImageModal";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";

const searchList = [
	{
		value: "$user.fullname$",
		title: "Tên khách hàng",
	},
	{
		value: "order_code",
		title: "Mã đơn hàng",
	},
];

const OrderItem = ({ orderId, openModal }) => {
	const location = useLocation();
	const stateFromCategory = location?.state;
	const filterWithCategory = {
		searchBy: stateFromCategory?.searchBy,
		value: stateFromCategory?.value,
	};

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = 1;
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [filterQuery, setFilterQuery] = useState({
		fields: ["$all"],
		filter: { order_id: orderId },
		order: [["updated_at", "desc"]],
	});
	const [searchBy, setSearchBy] = useState(filterWithCategory.searchBy || searchList[0].value);
	const [searchValue, setSearchValue] = useState(filterWithCategory.value || "");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	const [inforCombo, setInforCombo] = useState({
		open: false,
		data: null,
	});
	// Table

	const columns = [
		{
			field: "title",
			headerName: t(`abeauty.order_item.table_title.title`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							<Link to={`/product-detail/${params.row?.product?.id}`} target="_blank">
								{params?.value}
							</Link>
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "price",
			headerName: t(`abeauty.order_item.table_title.price`), //Giá gốc
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "discount_price",
			headerName: t(`abeauty.order_item.table_title.discount_price`), // Giá giảm
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "deducted_price",
			headerName: t(`abeauty.order_item.table_title.price_sale`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params?.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params?.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "quantity",
			headerName: t(`abeauty.order_item.table_title.quantity`), // Số lượng
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "total_price",
			headerName: t(`abeauty.order_item.table_title.price_total`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "sharer_id",
			headerName: "Sản phẩm được ưu đãi",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return params.value ? <CheckBox /> : <></>;
			},
		},
	];

	const [information, setInformation] = useState({
		visible: false,
	});
	const handleOpenInfoModal = (data) => {
		//	Example DATA
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin đơn hàng",
				gridProps: {},
				children: [
					{
						id: "fullname",
						title: "Tên khách hàng",
						value: data?.user?.fullname,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "order_code",
						title: "Mã đơn hàng",
						value: data.order_code,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "activity",
						title: "Trạng thái",
						value: activityOrderTranslator(data.activity).value,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "fee_total",
						title: "Tổng tiền hàng1111",
						value: formatVND(data.fee_total),
						component: Text,
						childrenProps: {
							span: 8,
							color: "error",
						},
					},
					{
						id: "discount",
						title: "Tổng giảm",
						value: formatVND(data.discount),
						component: Text,
						childrenProps: {
							span: 8,
							color: "error",
						},
					},
					{
						id: "total_cost",
						title: "Tổng cộng",
						value: formatVND(data.total_cost),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "payment_type",
						title: "Hình thức thanh toán",
						value: paymentTypeOrderTranslator(data.payment_type),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "address",
						title: "Địa chỉ",
						value: data.address,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "created_at_unix_timestamp",
						title: "Thời gian tạo",
						value: moment(data.created_at_unix_timestamp, "x").format("DD/MM/YYYY HH:mm"),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
				],
			},
		];

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
		}));
	};

	const getListComboService = async () => {
		setIsLoading(true);
		if (queryParams?.[searchBy]) {
			if (searchBy == "$user.fullname$") {
				filterQuery.fields = [
					"$all",
					{ user: ["$all", { $filter: { fullname: { $iLike: `%${searchValue?.trim()}%` } } }] },
				];
			} else {
				filterQuery.filter = {
					...filterQuery.filter,
					[searchBy]: { $iLike: `%${searchValue?.trim()}%` },
				};
			}
		} else {
			filterQuery.filter = { order_id: orderId };
			filterQuery.fields = ["$all", { product: ["$all"] }];
		}

		filterQuery.filter = {
			...filterQuery.filter,
		};

		try {
			const res = await orderItemAPI.getList({
				fields: JSON.stringify(filterQuery.fields),
				order: JSON.stringify(filterQuery.order),
				filter: JSON.stringify(filterQuery.filter),
				limit: pagination.limit,
				page: pagination.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));

			setFilterQuery(filterQuery);
			setSelectList([]);
			setRows(resList);

			setPagination(paginationRes);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);

	const handleRemoveMulti = async () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await orderItemAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListComboService();
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		setPagination({ ...pagination, page: page });
	};

	const handleDelete = useCallback(async (id) => {
		try {
			await orderItemAPI.remove(id);

			await getListComboService();
			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	}, []);

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useEffect(() => {
		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}

		getListComboService();
	}, [pagination.page, loading]);
	useEffect(() => {
		getListComboService();
	}, [orderId]);

	return (
		<>
			<MainCard>
				<HeaderActions
					title={"Danh sách sản phẩm trong đơn hàng"}
					setIsUpdate={setIsUpdate}
					setOpen={setOpen}
					onCreate={false}
					// onCreate={employeeInfo?.role === "SUPERADMIN" ? true : false}
				/>
				{/* <Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{employeeInfo?.role === "SUPERADMIN" && (
						<Button
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box> */}
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							// checkboxSelection
							// onSelectionModelChange={(ids) => {
							// 	setSelectList(ids);
							// }}

							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<ComboServiceModal
				getListTable={getListComboService}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>
			<ImageModal
				title={"Ảnh dịch vụ"}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>

			<DialogMain
				maxWidth={"lg"}
				title={"Dịch vụ có trong gói"}
				fullWidth
				open={inforCombo.open}
				onClose={() => {
					setInforCombo((prev) => ({
						...prev,
						open: false,
					}));
				}}
			>
				<ComboServiceInfo data={inforCombo.data} />
			</DialogMain>

			<ModalAntd
				title={"Chi tiết đơn hàng"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>
		</>
	);
};

export default OrderItem;
