import { useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

function SideFilter({ children }) {
	const theme = useTheme();

	const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<PerfectScrollbar
			component="div"
			style={{
				// height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
				height: "100%",
				paddingLeft: "16px",
				paddingRight: "16px",
			}}
		>
			{children}
		</PerfectScrollbar>
	);
}

export default SideFilter;
