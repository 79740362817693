// assets
import { IconBasket, IconBox } from "@tabler/icons";
import { ROLE } from "constants/employeeRole";
import { storeRedux } from "store";

// constant
const icons = {
	IconBasket,
	IconBox,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const transaction = (waitingRequest, role) => ({
	id: "transaction",
	title: "Giao dịch",
	type: "group",
	visible: role?.TRANSACTION_VIEW === ROLE.TRANSACTION_VIEW,
	children: [
		{
			id: "Order",
			title: "order",
			type: "item",
			url: "order",
			icon: icons.IconBasket,
			breadcrumbs: false,
			badge: true,
			count: waitingRequest?.count_order,
			visible: true,
		},
		{
			id: "Internal",
			title: "internalOrder",
			type: "item",
			url: "internal-order",
			icon: icons.IconBox,
			breadcrumbs: false,
			badge: false,
			visible: true,
		},
	],
});

export default transaction;
