// material-ui
import { Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMenuItem from "hooks/useMenuItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// project imports
import NavGroup from "./NavGroup";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const useStyles = makeStyles((theme) => ({
	myBadge: {
		top: "50%",
		right: "10px",
	},
}));
const MenuList = () => {
	const classes = useStyles();
	const menuItem = useMenuItem();

	const [state, setState] = useState(0);

	const waitingRequest = useSelector((state) => state.waitingRequest.waitingRequest);

	useEffect(() => {
		setState(state + 1);
	}, [waitingRequest]);

	return (
		<>
			{menuItem.items.map((item) => {
				return (
					<NavGroup
						style={{ display: item.visible ? "block" : "none" }}
						key={item.id}
						item={item}
					/>
				);
			})}
		</>
	);
};

export default MenuList;
