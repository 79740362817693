import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, Pagination, Button, FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import productAPI from "api/ProductAPI";
import { IMAGES } from "assets/images/imagePath";
import { CustomNoRowsOverlay } from "components/noRows";
import { ModalContext } from "providers/ModalProvider";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import _debounce from "lodash/debounce";
import employeeAPI from "../../api/EmployeeAPI";
import ProductModal from "ui-component/modal-form/ProductModal";
import ImageModal from "ui-component/modal-form/ImageModal";
import { Check, LocalFireDepartment } from "@mui/icons-material";
import { Image, Tooltip } from "antd";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import DetailProductInformation from "ui-component/modal-form/ModalAntd/DetailProductInformation";
import { IconNotification } from "@tabler/icons";
import Text from "antd/lib/typography/Text";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Markup } from "interweave";
import { SearchComponent } from "../../components/search";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { formatVND, productTranslator } from "func";
import { IOSSwitch } from "ui-component/switch";
import { useReactToPrint } from "react-to-print";
import ProductQr from "components/PrintTemplate/ProductQr";
import PageSizeSelect from "components/PrintTemplate/components/PageSizeSelect";
import supplierAPI from "api/SupplierAPI";
import SupplierModal from "ui-component/modal-form/SupplierModal";
const searchList = [
	{
		value: "title",
		title: "title",
	},
	{
		value: "$category.title$",
		title: "Danh mục",
	},
	// {
	// 	value: "execution_time",
	// 	title: "Thời gian thực hiện",
	// },
	// {
	// 	value: "price",
	// 	title: "Giá dịch vụ",
	// },
];

const Supplier = () => {
	const location = useLocation();

	const stateFromCategory = location?.state;
	const filterWithCategory = {
		searchBy: stateFromCategory?.searchBy,
		value: stateFromCategory?.value,
	};

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [searchBy, setSearchBy] = useState(filterWithCategory.searchBy || searchList[0].value);
	const [searchValue, setSearchValue] = useState(filterWithCategory.value || "");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const [openModalImage, setOpenModalImage] = useState(false);
	const [printQr, setPrintQr] = useState({
		image: "",
		productName: "",
		priceProduct: 0,
		data,
	});

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleOpenChangeAccept = (e, params, isWithClient = false) => {
		let checked = e.target.checked;
		modalContext.showDeleteConfirm(
			params.id,
			() => handleChangeAccept(checked, params.id, isWithClient),
			`${
				checked
					? `Bạn có chắc chắn xác nhận  ${
							!isWithClient ? "sản phẩm này soi da?" : "đã nhận tiền?"
					  } `
					: `Bạn có chắc chắn xác nhận ${
							!isWithClient ? "sản phẩm này không soi da?" : "chưa nhận tiền?"
					  }`
			}`,
			"Chắc chắn"
		);
	};

	const handleChangeAccept = async (checked, id, isWithClient) => {
		try {
			await productAPI.update(
				id,

				!isWithClient
					? {
							is_scan_skin: checked,
					  }
					: {
							is_confirm_with_client: checked,
					  }
			);
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListSupplier();
		} catch (error) {
			enqueueSnackbar(error?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	//Print Func

	// Table

	const columns = [
		{
			field: "supplier_code",
			headerName: "Mã nhà cung cấp",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
		},
		{
			field: "name",
			headerName: "Tên nhà cung cấp",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "phone",
			headerName: "Điện thoại",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "email",
			headerName: "Email",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "total_purchase",
			headerName: "Tổng tiền mua",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "actions",
			headerName: t(`actions`),
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<>
					{employeeInfo?.role === "SUPERADMIN" ? (
						<Tooltip title={t(`edit`)}>
							<IconButton
								aria-label="edit"
								onClick={() => {
									setData(params.row);
									setOpen(true);
									setIsUpdate(true);
								}}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
					) : (
						employeeInfo?.id === params.id && (
							<>
								<Tooltip title={t(`edit`)}>
									<IconButton
										aria-label="edit"
										onClick={() => {
											setData(params.row);
											setOpen(true);
											setIsUpdate(true);
										}}
									>
										<EditIcon />
									</IconButton>
								</Tooltip>
							</>
						)
					)}

					{employeeInfo?.id === params.id
						? null
						: employeeInfo?.role === "SUPERADMIN" && (
								<>
									<Tooltip title={t(`delete`)}>
										<IconButton
											aria-label="delete"
											onClick={() => handleOpenRemoveModal(params.id)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title={"Thông tin chi tiết"}>
										<IconButton aria-label="edit" onClick={() => handleOpenInforModal(params.row)}>
											<IconNotification />
										</IconButton>
									</Tooltip>
								</>
						  )}
				</>
			),
		},
	];

	const [information, setInformation] = useState({
		visible: false,
	});
	const [printData, setPrintData] = useState({
		visible: false,
		data: {},
	});
	const handleOpenInforModal = (data) => {
		//	Example DATA
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin nhà cung cấp",
				gridProps: {},
				children: [
					{
						id: "company",

						title: "Công ty",
						value: data.company,
						component: Text,
						childrenProps: {
							//all Props component here
							span: 8,
						},
					},
					{
						id: "tax_code",
						title: "Mã số thuế",
						value: data.tax_code,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "name",
						title: "Tên nhà cung cấp",
						value: data.name,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "supplier_code",
						title: "Mã nhà cung cấp",
						value: data.supplier_code,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "email",
						title: "Email",
						value: data.email,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "phone",
						title: "Số điện thoại",
						value: data.phone,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "region",
						title: "Khu vực",
						value: data.region,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "ward",
						title: "Quận",
						value: data.ward,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "current_liabilities",
						title: "Nợ",
						value: formatVND(data?.current_liabilities || 0),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "total_purchase",
						title: "Tổng tiền mua",
						value: formatVND(data?.total_purchase || 0),

						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "note",
						title: "Ghi chú",
						value: data.note,

						component: Text,
						childrenProps: {
							span: 24,
						},
					},
				],
			},
		];

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
		}));
	};
	const handleOpenPrintModal = (data) => {
		setPrintData({
			visible: true,
			data,
		});
	};
	const [filterCategory, setFilterCategory] = useState();
	useEffect(() => {
		setFilterCategory(location?.state);
	}, []);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const getListSupplier = async () => {
		setIsLoading(true);
		// const hotFilter = `{"hot" : "false"}`;
		let filter = `{}`;
		let order = '[["created_at", "desc"]]';
		// if (queryParams?.title?.trim()) {
		// 	filter = `{"title":{"$iLike":"%${queryParams?.title.trim()}%"}}`;
		// }
		// if (queryParams?.$category?.title$) {
		// 	filter = `{"$category.title$":{"$iLike":"%${searchValue.trim()}%"}}`;
		// }
		// if (queryParams?.execution_time?.trim()) {
		// 	filter = `{"execution_time":{"$iLike":"%${queryParams?.execution_time.trim()}%"}}`;
		// }
		// if (queryParams?.price?.trim()) {
		// 	filter = `{"price":{"$iLike":"%${queryParams?.price.trim()}%"}}`;
		// }
		// if (queryParams?.[searchBy]) {
		// 	filter = `{"${searchBy}": "${searchValue?.trim()}"}`;
		// }
		// if (filterCategory) {
		// 	filter = `{"${filterCategory.searchBy}": "${filterCategory.value}"}`;
		// }

		if (queryParams?.[searchBy]) {
			filter = `{"${searchBy}":{"$iLike":"%${searchValue?.trim()}%"}, "branch_id": "${branchActiveId}"}`;
		}
		if (filterCategory) {
			filter = `{"${searchBy}":{"$iLike":"%${searchValue?.trim()}%"}, "${
				filterCategory.searchBy
			}": "${filterCategory.value}", "branch_id": "${branchActiveId}"}`;
		}
		try {
			const res = await supplierAPI.getList({
				fields: '["$all"]',
				order,
				filter,
				limit: pagination.limit,
				page: queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));

			setSelectList([]);
			setRows(resList);

			setPagination(paginationRes);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//Get Services Categories
	const [serviceCategories, setServiceCategories] = useState(null);
	const getServicesCategories = async () => {
		setIsLoading(true);

		const filter = {
			status: "true",
		};
		const params = {
			fields: '["$all"]',
			filter: JSON.stringify(filter),
		};
		try {
			const res = await productAPI.getCategory(params);
			setServiceCategories(res.results.objects.rows);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);

	const handleRemoveMulti = async () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await productAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListSupplier();
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleDelete = useCallback(async (id) => {
		try {
			await productAPI.remove(id);

			await getListSupplier();
			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	}, []);

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useEffect(() => {
		if (!branchActiveId) return;
		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}

		// if (location.state) {
		// 	setSearchBy(filterWithCategory.searchBy);
		// 	setSearchValue(filterWithCategory.value);
		// }
		getListSupplier();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading, branchActiveId]);
	useEffect(() => {
		getServicesCategories();
	}, []);

	return (
		<>
			<MainCard>
				{filterCategory?.isFromCategory && (
					<Button
						variant="contained"
						onClick={() => navigate("/product-category")}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về
					</Button>
				)}
				<HeaderActions
					title={"Danh sách Nhà cung cấp"}
					setIsUpdate={setIsUpdate}
					setOpen={setOpen}
					onCreate={employeeInfo?.role === "SUPERADMIN" ? true : false}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{employeeInfo?.role === "SUPERADMIN" && (
						<Button
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<SupplierModal
				stateLocation={location.state}
				listSelect={serviceCategories}
				getListTable={getListSupplier}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>
			<ImageModal
				title={"Ảnh sản phẩm"}
				multi
				classNameImg={"aspect1-1"}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>
			<ModalAntd
				title={"Chi tiết nhà cung cấp"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailProductInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>
			<ImageModal
				hasFooter
				title="Qr Code sản phẩm"
				openModalZoom={openModalImage}
				handleCloseModal={() => setOpenModalImage(false)}
				imageZoom={printQr.image}
				onOpenPageSelect={() => handleOpenPrintModal(printQr.data)}
				PrintComponent={
					<ProductQr
						qrCode={printQr.image}
						productName={printQr.productName}
						price={printQr.priceProduct}
					/>
				}
			/>

			<ModalAntd
				title={"Chọn mẫu giấy in"}
				visible={printData.visible}
				setVisible={(value) => {
					setPrintData((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<PageSizeSelect data={printData.data} />
			</ModalAntd>
		</>
	);
};

export default Supplier;
