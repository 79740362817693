import DateFnsUtils from "@date-io/date-fns";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Col, Row } from "antd";
import dashboardAPI from "api/DashboardAPI";
import LoadingComponent from "components/loading";
import { ROLE } from "constants/employeeRole";
// import dashboardAPI from "api/DashboardAPI";
import enLocale from "date-fns/locale/en-US";
import useCustomAuth from "hooks/useCustomAuth";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { gridSpacing } from "store/constant";
import { getLanguage } from "views/utilities/CustomStyle";
import ColLineChart from "../AntdChart/ChartMixColLine";
import DonutChart from "../AntdChart/DonutChart";
import DonutPaymentType from "../AntdChart/DonutPaymentType";
import LineChart from "../AntdChart/LineChart";
import ServiceBestSeller from "../AntdChart/ServiceBestSeller";
import FilterBox from "../components/FilterBox";
import FilterTop10 from "../components/FilterTop10";
import StaticCard from "../components/StaticCard";
import { TOP_10_COLLAB_LIST } from "../data/filterBoxList";

// ==============================|| DEFAULT DASHBOARD ||============================== //

const localeMap = {
	en: enLocale,
};

const Dashboard = () => {
	const theme = useTheme();
	var momentDate = new Date();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const start = moment(momentDate);
	const end = moment(momentDate);

	const [date, setDate] = useState([start, end]);
	const [startAt, setStartAt] = useState(start.format("DD/MM/YYYY"));
	const [endAt, setEndAt] = useState(end.format("DD/MM/YYYY"));
	const [dashboardData, setDashboardData] = useState({});
	const [dataChart, setDataChart] = useState({});
	const [dataTop10, setDataTop10] = useState({});

	const [locale, setLocale] = useState(localStorage.getItem("i18nextLng") || "en");
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	// const [branchActive, setBranchActive] = useState("ALL");

	const handleSetDate = (timeRange) => {
		setDate(timeRange);
		const x = timeRange[0]?.getTime();
		const y = timeRange[1]?.getTime();

		const start = moment(x).format("DD/MM/YYYY");
		const end = moment(y).format("DD/MM/YYYY");
		setStartAt(start);
		setEndAt(end);
	};

	const getChartDownloaded = async (start, end) => {
		setLoading(true);
		try {
			const startAt = `${moment(start, "DD/MM/YYYY").utc(true).startOf("day").valueOf()}`;
			const endAt = `${moment(end, "DD/MM/YYYY").utc(true).endOf("day").valueOf()}`;
			let filter =
				branchActiveId === "ALL"
					? undefined
					: {
							branch_id: branchActiveId,
					  };

			const params = {
				start_at: timeFilterVal === 6 ? "all" : startAt, //6 = Tất cả
				end_at: timeFilterVal === 6 ? "all" : endAt,
				type_chart_top_10: "revenue",
				filter: JSON.stringify(filter),
			};

			const res = await dashboardAPI.getChartsTotal(params);
			const dataChart = res.results.objects;

			let amount_user = dataChart.user;

			let amount_booking = dataChart.booking;
			let amount_revenue_from_booking = Number(dataChart.revenue_from_booking);

			let amount_revenue_from_pack = Number(dataChart.revenue_from_pack);

			let amount_booking_completed = dataChart.total_booking_completed;
			let total_request_withdraw = dataChart.total_request_withdraw;
			let total_request_deposit = dataChart.total_request_deposit;
			let amount_pack_sell = dataChart.amount_pack_sell;

			// dataChart.forEach((item) => {
			// 	amount_user += item.user;
			// 	amount_post += item.booking;
			// 	amount_workout_program += item.service;
			// 	amount_exercise += item.amount_exercise;
			// });
			setDashboardData({
				...dataChart,

				amount_user,
				amount_booking,
				amount_revenue_from_pack,
				amount_revenue_from_booking,
				amount_booking_completed,
				total_request_withdraw,
				total_request_deposit,
				amount_pack_sell,
			});
			setDataChart(res.results.objects);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const fetchChartData = async (start, end) => {
		setLoading(true);
		console.log(end);
		try {
			const startAt = `${moment(start, "DD/MM/YYYY").utc(true).startOf("day").valueOf()}`;
			const endAt = `${moment(end, "DD/MM/YYYY").utc(true).endOf("day").valueOf()}`;
			console.log(endAt);
			let filter =
				branchActiveId === "ALL"
					? undefined
					: {
							branch_id: branchActiveId,
					  };

			const params = {
				start_at: timeFilterVal === 6 ? "all" : startAt, //6 = Tất cả
				end_at: timeFilterVal === 6 ? "all" : endAt,
				type_chart_top_10: "revenue",
				filter: JSON.stringify(filter),
			};

			const res = await dashboardAPI.getChartsV2(params);
			const dataChart = res.results.objects;

			// dataChart.forEach((item) => {
			// 	amount_user += item.user;
			// 	amount_post += item.booking;
			// 	amount_workout_program += item.service;
			// 	amount_exercise += item.amount_exercise;
			// });
			setLoading(false);

			setDataTop10(dataChart);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { role } = useCustomAuth();
	useEffect(() => {
		setLocale(localStorage.getItem("i18nextLng") || "en");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getLanguage()]);

	useEffect(() => {
		if (!branchActiveId || !role) return;
		if (role?.DASHBOARD !== ROLE.DASHBOARD) {
			navigate("/not-permission", { replace: true });

			return;
		}

		if (moment(startAt, "DD/MM/YYYY").isValid() && moment(endAt, "DD/MM/YYYY").isValid()) {
			getChartDownloaded(startAt, endAt);
			fetchChartData(startAt, endAt);
		}
	}, [startAt, endAt, branchActiveId, role]);

	// useEffect(() => {
	// 	getChartDownloaded();
	// }, []);
	const convertDaysMoment = (number = 0, time = "days") => {
		const data = moment(momentDate).add(number, time); // Today
		return data;
	};
	const changeValueToDate = (value) => {
		switch (value) {
			case 0:
				return [convertDaysMoment().toDate(), convertDaysMoment().toDate()];
			case 1:
				return [convertDaysMoment(-1, "days").toDate(), convertDaysMoment(-1, "days").toDate()];
			case 2:
				return [
					convertDaysMoment().startOf("month").toDate(),
					convertDaysMoment().endOf("month").toDate(),
				];
			case 3:
				return [
					convertDaysMoment(-1, "months").startOf("month").toDate(),
					convertDaysMoment(-1, "months").endOf("month").toDate(),
				];
			case 4:
				return [
					convertDaysMoment(0, "years").startOf("year").toDate(),
					convertDaysMoment().endOf("year").toDate(),
				];
			case 5:
				return [convertDaysMoment(-1, "months").toDate(), convertDaysMoment().toDate()];
			case 6:
				return [moment("01/01/2022", "DD/MM/YYYY").toDate(), convertDaysMoment().toDate()];

			default:
				break;
		}
	};
	const timeFilters = [
		{ value: 5, title: "30 ngày qua" },

		{ value: 0, title: "Hôm nay" },
		{ value: 1, title: "Hôm qua" },
		{ value: 2, title: "Tháng này" },
		{
			value: 3,
			title: "Tháng trước",
		},
		{ value: 4, title: "Năm nay" },
		{ value: 6, title: "Tất cả" },
	];
	const [timeFilterVal, setTimeFilterVal] = useState(0);

	return loading ? (
		<LoadingComponent text={"Đang tải..."} />
	) : (
		<>
			<Grid container spacing={gridSpacing}>
				<Grid item xs={12}>
					<Grid container spacing={gridSpacing}>
						{/* <Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<FormControl>
								<InputLabel id="branchLabel">Chọn chi nhánh</InputLabel>
								<Select
									sx={{ width: "250px" }}
									labelId="branchLabel"
									label={"Chọn chi nhánh"}
									id="branch-select"
									size="large"
									onChange={(e) => {
										setBranchActive(e.target.value);
									}}
									value={branchActive}
								>
									<MenuItem value={"ALL"} title={""}>
										Tất cả chi nhánh
									</MenuItem>
									{branchList?.map((item) => (
										<MenuItem key={item.id} value={item.id} title={""}>
											{item.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid> */}
						<Divider />
						<Grid item xs={6}>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								utils={DateFnsUtils}
								// locale={localeMap[locale]}
							>
								<DateRangePicker
									startText={"Từ ngày"}
									endText={"Đến ngày"}
									views={["year", "month", "day"]}
									inputFormat="dd/MM/yyyy"
									value={date}
									locale="es"
									onChange={(newValue) => handleSetDate(newValue)}
									renderInput={(startProps, endProps) => (
										<>
											<TextField {...startProps} />
											<Box sx={{ mx: 2 }}> ~ </Box>
											<TextField {...endProps} />
										</>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid
							item
							xs={6}
							sx={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<FormControl>
								<InputLabel id="demo-simple-select-label">Lọc</InputLabel>
								<Select
									sx={{ width: "150px" }}
									labelId="demo-simple-select-label"
									label={"Lọc"}
									id="demo-simple-select"
									size="large"
									onChange={(e) => {
										setTimeFilterVal(e.target.value);
										handleSetDate(changeValueToDate(e.target.value));
									}}
									value={timeFilterVal}
								>
									{timeFilters?.map((item) => (
										<MenuItem key={item.value} value={item.value} title={""}>
											{item.title}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<StaticCard
						dashboardData={dashboardData}
						data2={dataTop10}
						filterProps={{
							filterDate: [startAt, endAt],
						}}
						date={date}
					/>
				</Grid>
				<Grid item xs={12}>
					<Row>
						<Col
							span={12}
							style={{
								background: "#fff",
								padding: "24px",
								borderRadius: "4px",
								margin: "24px 0",
							}}
						>
							<DonutChart
								data={dataChart?.activity_booking}
								chartType={"BOOKING"}
								chartText={{ title: "Thống kê tình trạng lịch hẹn" }}
								linkPath={"/booking-scheduler"}
							/>
						</Col>
						<Col
							span={12}
							style={{
								background: "#fff",
								padding: "24px",
								borderRadius: "4px",
								margin: "24px 0",
							}}
						>
							<DonutChart
								data={dataChart?.activity_order}
								chartType={"ORDER"}
								linkPath={"/order"}
							/>
						</Col>
					</Row>
				</Grid>

				<Grid item xs={12}>
					<Row>
						<Col
							span={12}
							style={{
								background: "#fff",
								padding: "24px",
								borderRadius: "4px",
								margin: "24px 0",
							}}
						>
							<DonutPaymentType
								data={dataChart?.revenue?.bookings}
								chartType={"BOOKING"}
								chartText={{
									title: "Thống kê doanh thu  lịch hẹn theo từng phương thức thanh toán",
								}}
								chartConfig={{
									angleField: "sum_booking",
									colorField: "booking_type",
								}}
								linkPath={"/booking-scheduler"}
							/>
						</Col>
						<Col
							span={12}
							style={{
								background: "#fff",
								padding: "24px",
								borderRadius: "4px",
								margin: "24px 0",
							}}
						>
							<DonutPaymentType
								data={dataChart?.revenue?.orders}
								chartText={{
									title: "Thống kê doanh thu đơn hàng theo từng phương thức thanh toán",
								}}
								chartConfig={{
									angleField: "sum_order",
									colorField: "payment_type",
								}}
								chartType={"ORDER"}
								linkPath={"/order"}
							/>
						</Col>
					</Row>
				</Grid>

				{/* <ChartComponentPayment data={dataChart} /> */}
			</Grid>

			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",

					margin: "24px 0",
				}}
			>
				<ColLineChart
					chartText={{
						title: "Thống kê doanh thu lịch hẹn",
						column: "Doanh thu",
						line: "Số lượng lịch hẹn",
					}}
					linkPath={"/booking-scheduler"}
					dataProps={dataChart?.data}
				/>
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",

					margin: "24px 0",
				}}
			>
				<ColLineChart
					dataProps={dataChart?.data_order}
					chartText={{
						title: "Thống kê doanh thu đơn hàng",
						column: "Doanh thu",
						line: "Số lượng đơn hàng",
					}}
					linkPath={"/order"}
				/>
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",

					margin: "24px 0",
				}}
			>
				<ColLineChart
					dataProps={dataChart?.data_pack}
					chartText={{
						title: "Thống kê doanh thu thẻ",
						column: "Doanh thu",
						line: "Số lượng thẻ đã bán",
					}}
					linkPath={"/qrcode"}
				/>
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",

					margin: "24px 0",
				}}
			>
				<ColLineChart
					dataProps={dataChart?.data_pack_request}
					chartText={{
						title: "Thống kê doanh thu yêu cầu nạp tiền",
						column: "Doanh thu",
						line: "Số lượng yêu cầu",
					}}
					linkPath={"/recharge-withdraw"}
				/>
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",
					margin: "24px 0",
				}}
			>
				<ServiceBestSeller data={dataChart?.service_best_seller} linkPath={"/service"} />
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",
					margin: "24px 0",
				}}
			>
				<ServiceBestSeller
					data={dataChart?.product_best_seller}
					chartText={{
						title: "Top 10 sản phẩm",
						revenue: "Doanh thu",
						count: "Số lần đặt hàng",
					}}
					linkPath={"/product"}
				/>
			</Box>

			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",
					margin: "24px 0",
				}}
			>
				<FilterBox dataChart={dataChart} filterList={TOP_10_COLLAB_LIST} />
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",
					margin: "24px 0",
				}}
			>
				<FilterTop10
					dataChart={dataTop10}
					chart={{
						name: "top10Product",
						type: "payment_type",
						title: "Top 10 sản phẩm theo phương thức thanh toán",
						path: "/product",
					}}
				/>
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",
					margin: "24px 0",
				}}
			>
				<FilterTop10
					dataChart={dataTop10}
					chart={{
						name: "top10Service",
						type: "booking_type",
						title: "Top 10 dịch vụ theo phương thức thanh toán",
						path: "/service",
					}}
				/>
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",
					margin: "24px 0",
				}}
			>
				<LineChart
					data={dataTop10.withDraw}
					chartText={{
						title: "Thống kê số tiền hoa hồng được rút",
						metaTitle: "Số tiền rút",
					}}
					configChart={{
						xField: "date_withdraw",
						yField: "change_amount",
					}}
					linkPath={"/recharge-withdraw"}
					propsLink={{
						state: {
							status: "DONE",
							type: "REQUEST_WITHDRAW",
							fromDashboard: true,
							filterDate: [startAt, endAt],
						},
						replace: false,
					}}
				/>
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",
					margin: "24px 0",
				}}
			>
				<LineChart
					data={dataTop10.orderInternal}
					chartText={{
						title: "Thống kê số tiền xuất hàng nội bộ",
						metaTitle: "Số tiền",
					}}
					configChart={{
						xField: "date_interval",
						yField: "total",
					}}
					linkPath={"/order"}
					propsLink={{
						state: {
							activity: "COMPLETED",
							fromDashboard: true,
							filterDate: [startAt, endAt],
						},
						replace: false,
					}}
				/>
			</Box>
			<Box
				sx={{
					background: "#fff",
					padding: "24px",
					borderRadius: "4px",
					margin: "24px 0",
				}}
			>
				<LineChart
					data={dataTop10.debt}
					chartText={{
						title: "Thống kê thu nợ",
						metaTitle: "Số tiền",
					}}
					configChart={{
						xField: "date_interval",
						yField: "total",
					}}
					// linkPath={"/order"}
					propsLink={{
						state: {
							// activity: "COMPLETED",
							fromDashboard: true,
							filterDate: [startAt, endAt],
						},
						replace: false,
					}}
				/>
			</Box>
		</>
	);
};

export default Dashboard;
