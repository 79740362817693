import {
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import classnames from "classnames/bind";
import styles from "./CreateOrder.module.scss";
import { useSelector } from "react-redux";

const cx = classnames.bind(styles);

function DeliveryCustomer({ control, errors, setIsUseAddress, clearErrors, setValueForm }) {
	const { orderList, infoCustomer } = useSelector((state) => state.createOrder);

	useEffect(() => {
		return () => {
			setAddressInfo();
			clearErrors("address_id");
		};
	}, []);

	const [addressInfo, setAddressInfo] = useState();
	return (
		<Grid item xs={12}>
			<label className={cx("form__label")}>Địa chỉ</label>
			<FormControl sx={{ m: 1, width: "100%" }}>
				<InputLabel id="cityLabel">Địa chỉ</InputLabel>
				<Controller
					render={(props) => (
						<Select
							fullWidth
							labelId="cityLabel"
							id="demo-simple-select-disabled"
							label="Địa chỉ"
							onChange={(e) => {
								props.onChange(e);
							}}
						>
							{infoCustomer.userInfo?.addresses?.map((address) => (
								<MenuItem
									key={address.id}
									value={address.id}
									onClick={() => setAddressInfo(address)}
								>
									{address.address +
										", " +
										address?.district +
										", " +
										address?.ward +
										", " +
										address?.city}
								</MenuItem>
							))}
							<MenuItem
								onClick={() => {
									setAddressInfo();
									setIsUseAddress(false);
									clearErrors("address_id");
								}}
							>
								Sử dụng địa chỉ khác
							</MenuItem>
						</Select>
					)}
					rules={{ required: "Vui lòng chọn địa chỉ của bạn" }}
					control={control}
					name="address_id"
				/>
			</FormControl>
			<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
				{errors?.address_id?.message}
			</Typography>
			{addressInfo && (
				<Paper elevation={3} sx={{ padding: "20px", margin: "20px 0" }}>
					<Typography mb={2} variant="h4">
						Thông tin người nhận
					</Typography>
					<Divider />
					<Grid container spacing={4} sx={{ margin: "15px 10px" }}>
						<Grid item xs={6}>
							<Typography variant="h5">Tên người nhận: {addressInfo.fullname}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="h5">Số điện thoại người nhận: {addressInfo.phone}</Typography>
						</Grid>
					</Grid>
				</Paper>
			)}
		</Grid>
	);
}

export default DeliveryCustomer;
