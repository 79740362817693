import { Box, Grid } from "@mui/material";
import bookingAPI from "api/BookingAPI";
import orderAPI from "api/OrderAPI";
import BookingInvoicesFilter from "components/FilterSide/BookingInvoicesFilter";
import InvoicesOrderTable from "components/InvoicesOrderTable";
import InvoicesBookingTable from "components/InvoicesTable/InvoicesBookingTable";
import TransactionFilter from "components/TransactionFilter";
import { PAGINATION } from "constants/pagination";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import MainCard from "ui-component/cards/MainCard";

function InvoicesBookingPage() {
	// const { showLoading, hideLoading } = useContext(LoadingContext);
	const [loadingTable, setLoadingTable] = useState(false);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const mainBranchId = branchList.find((item) => item.is_main)?.id;
	const defaultsFilter = {
		branch: [mainBranchId],
		activity: ["COMPLETED"],
		booking_type: ["CASH", "TRANSFER", "CARD", "WALLET"],
		date: [new Date(), new Date()],
	};
	const [filterData, setFilterData] = useState(defaultsFilter);

	const filterQuery = {
		branch_id: {
			$in: filterData?.branch && filterData?.branch?.length !== 0 ? filterData?.branch : [],
		},
		activity: {
			$in: filterData?.activity && filterData?.activity?.length !== 0 ? filterData?.activity : [],
		},
		booking_type: {
			$in:
				filterData?.booking_type && filterData?.booking_type?.length !== 0
					? filterData?.booking_type
					: [],
		},
		// eslint-disable-next-line no-useless-computed-key
		$or:
			filterData.employee_confirm || filterData.user
				? [
						filterData.employee_confirm
							? {
									["$employee_confirm.fullname$"]: {
										$iLike: `%${filterData?.employee_confirm.trim()}%`,
									},
							  }
							: undefined,
						filterData.employee_confirm
							? {
									["$employee_confirm.username$"]: {
										$iLike: `%${filterData?.employee_confirm.trim()}%`,
									},
							  }
							: undefined,
						filterData.user
							? {
									["$user.username$"]: {
										$iLike: `%${filterData?.user.trim()}%`,
									},
							  }
							: undefined,
						filterData.user
							? {
									["$user.fullname$"]: {
										$iLike: `%${filterData?.user.trim()}%`,
									},
							  }
							: undefined,
				  ].filter((item) => item)
				: undefined,

		booking_code: filterData?.booking_code
			? {
					$iLike: `%${filterData?.booking_code.trim()}%`,
			  }
			: undefined,

		note_admin: filterData?.note
			? {
					$iLike: `%${filterData?.note.trim()}%`,
			  }
			: undefined,

		created_at_unix_timestamp:
			filterData.date[0] && filterData.date[1]
				? {
						$between: [
							+moment(filterData.date[0]).utc(true).startOf("days").format("x"),
							+moment(filterData.date[1]).utc(true).endOf("days").format("x"),
						],
				  }
				: undefined,
	};

	const [pagination, setPagination] = useState({
		limit: PAGINATION.LIMIT,
		page: PAGINATION.PAGE,
	});

	const [rows, setRows] = useState([]);

	const query = {
		fields: [
			"$all",
			{ user: ["$all", "$paranoid", "deleted_at"] },
			{ booking_consultants: ["$all", { employee: ["$all"] }] },
			{ employee_sales: ["$all", { employee: ["$all"] }] },
			{ branch: ["name"] },
			{
				booking_details: [
					"$all",

					filterData?.service
						? {
								$filter: {
									$or: [
										{
											title: {
												$iLike: `%${filterData?.service?.trim()}%`,
											},
										},
									],
								},
						  }
						: undefined,
					{ service: ["$all", { unit: ["$all"] }] },
					{ order_item: ["$all", { combo_using: ["$all"] }] },
				],
			},
			{ booking_activities: ["$all"] },
			{ voucher: ["$all"] },
			{ employee_confirm: ["fullname", "username", "deleted_at", "$paranoid"] },
		],
		filter: filterQuery,
		// report: true,
		order: [["created_at", "desc"]],
	};

	const fetchOrderData = async (customQuery = undefined) => {
		// showLoading();
		setLoadingTable(true);
		try {
			const payload = {
				...query,
				...pagination,
				fields: JSON.stringify(query.fields),
				filter: JSON.stringify(query.filter),
				order: JSON.stringify(query.order),
				...customQuery,
				total: undefined,
			};

			const res = await bookingAPI.getList(payload);

			if (customQuery) {
				setLoadingTable(false);
				return res;
			} else {
				const paginationRes = {
					...pagination,
					page: res?.pagination?.current_page,
					total: res?.results?.objects?.count,
				};
				setLoadingTable(false);
				setPagination(paginationRes);
				setRows(res.results?.objects?.rows);
			}
			// hideLoading();
		} catch (error) {
			console.log(error);
			// hideLoading();
			setLoadingTable(false);
		}
	};

	useEffect(() => {
		if (!branchActiveId) return;
		fetchOrderData();
	}, [filterData, pagination.page, pagination.limit]);
	useEffect(() => {
		if (!branchActiveId) return;
		setFilterData({ ...defaultsFilter });
	}, [branchActiveId]);

	return !branchActiveId ? (
		<></>
	) : (
		<Box width={"100%"}>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<BookingInvoicesFilter filterData={filterData} setFilterData={setFilterData} />
				</Grid>
				<Grid item xs={9}>
					<MainCard>
						<InvoicesBookingTable
							rows={rows}
							setPagination={setPagination}
							pagination={pagination}
							filterData={filterData}
							loading={loadingTable}
							getData={() =>
								fetchOrderData({
									page: 1,
									limit: 99999,
								})
							}
						/>
					</MainCard>
				</Grid>
			</Grid>
		</Box>
	);
}

export default InvoicesBookingPage;
