import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";
import React from "react";
import { useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import UserInformation from "ui-component/modal-form/ModalAntd/DetailInformation/UserInformation";

import TabsCustomize from "ui-component/TabsCustomize";
import UserBooking from "views/general/UserBooking";
import Debt from "../Debt";

function UserDetailPage() {
	const { role } = useCustomAuth();
	const { id } = useParams();
	const renderPage = (tab) => {
		switch (tab) {
			case "INFO":
				return <UserInformation />;
			case "DEBT":
				return <Debt userId={id} />;
			case "BOOKING_HISTORY":
				return <UserBooking userId={id} />;
			default:
				break;
		}
	};
	const tabList = [
		{
			label: "Chi tiết",
			value: "INFO",
			visible: role?.CUSTOMER_VIEW === ROLE?.CUSTOMER_VIEW,
		},
		{
			label: "Thu nợ",
			value: "DEBT",
			visible: role?.DEBT === ROLE?.DEBT,
		},
		{
			label: "Lịch sử đặt lịch",
			value: "BOOKING_HISTORY",
			visible: role?.BOOKING_VIEW === ROLE?.BOOKING_VIEW,
		},
	];
	return (
		<MainCard>
			<TabsCustomize tabList={tabList} renderPage={renderPage} />
		</MainCard>
	);
}

export default UserDetailPage;
