import { Stack, Typography } from "@mui/material";
import React from "react";

function Title({ title, code }) {
	return (
		<Stack alignItems={"center"} justifyContent={"center"}>
			<Typography textTransform={"uppercase"} variant="h4">
				{title}
			</Typography>
			<Typography variant="h5" textTransform={"uppercase"} mt={1}>
				{code}
			</Typography>
		</Stack>
	);
}

export default Title;
