import CashierPage from "views/pages/CashierPage";
import CreateOrder from "views/pages/CreateOrder";

export const HeaderOnlyRoutes = {
	children: [
		{
			path: "/create-order",
			element: <CashierPage />,
		},
		{
			path: "/internal-order",
			element: <CreateOrder />,
		},
	],
};
