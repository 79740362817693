import unitAPI from "api/UnitAPI";
import { GET_LIST_UNIT, SELECT_UNIT } from "./type";

export const actGetListUnit = (
	onSuccess = (success = false) => {},
	onError = (error = "") => {}
) => {
	const params = {
		fields: '["$all"]',
	};

	return async (dispatch) => {
		try {
			const res = await unitAPI.getList(params);
			dispatch({
				type: GET_LIST_UNIT,
				payload: res?.results.objects.rows,
			});
			onSuccess(true);
		} catch (error) {
			onError(error?.message || "Lỗi");
		}
	};
};

export const actSelectUnit = (unitId) => ({
	type: SELECT_UNIT,
	payload: unitId,
});
