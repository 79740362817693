import { useTheme } from "@emotion/react";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, Button, InputAdornment, Paper } from "@mui/material";
import { IconSearch } from "@tabler/icons";
import Tippy from "@tippyjs/react/headless";
import { Input, Spin } from "antd";
import React, { forwardRef, useLayoutEffect, useRef, useState } from "react";
import Loader from "ui-component/Loader";
import NoData from "ui-component/NoData";

function OrderSearchComponent(
	{
		searchValue,
		handleChangeSearch,
		placeholderText,
		searchResult,
		ResultComponent,
		propsResultComponent,
		styledSearchBox,
		widthBoxSearch,
		hasAdd = false,
		onAdd,
		isFocusOutSide = true,
		noDataText = "Không tìm thấy khách hàng",
		loading,
		styleSearchInput,
		customVis = false,
	},
	ref
) {
	const theme = useTheme();
	const [isFocus, setIsFocus] = useState(false);

	//Cái này để set width cho thằng modal search = với width của input search
	const inputSearchRef = useRef(null);
	const [widthInput, setWidthInput] = useState();
	useLayoutEffect(() => {
		setWidthInput(inputSearchRef.current?.offsetWidth);
	}, [inputSearchRef.current?.offsetWidth]);
	return (
		<Tippy
			interactive
			visible={
				(isFocus && customVis) || (isFocus && isFocusOutSide && searchValue?.trim().length > 0)
			}
			onClickOutside={() => setIsFocus(false)}
			placement={"bottom"}
			render={(attrs) => (
				<Paper
					tabIndex="-1"
					elevation={3}
					sx={{
						overflow: "auto",
						overflowX: "hidden",
						maxHeight: "250px",
						width: widthBoxSearch || widthInput,
					}}
					{...attrs}
				>
					{loading ? (
						<div
							style={{
								margin: "20px 0",
								marginBottom: "20px",
								padding: "30px 50px",
								textAlign: "center",
								borderRadius: "4px",
							}}
						>
							<Spin />
						</div>
					) : searchResult?.length !== 0 ? (
						searchResult.map((item) => {
							//Result Component có thể là bất cứ component nào

							//PropsResultComponent là một callback nhận vào giá trị item(value) và trả về props là một Object chứa tất cả các props của ResultCompopnent

							const props = propsResultComponent(item);

							return <ResultComponent {...props} />;
						})
					) : (
						<Box p={2}>
							<NoData text={noDataText} />
							{hasAdd && (
								<Box textAlign={"center"}>
									<Button
										sx={{ my: 2 }}
										variant="contained"
										onClick={() => {
											setIsFocus(false);
											onAdd();
										}}
									>
										Thêm khách hàng mới
									</Button>
								</Box>
							)}
						</Box>
					)}
				</Paper>
			)}
		>
			<Box
				ref={inputSearchRef}
				className="search-box-input"
				sx={{ position: "relative", ...styledSearchBox }}
			>
				<Input
					size="large"
					value={searchValue}
					onChange={(e) => {
						if (e.target.value.startsWith(" ")) return;
						else {
							handleChangeSearch(e.target.value, e);
						}
					}}
					onKeyPress={(e) => {
						handleChangeSearch(e.target.value, e);
					}}
					style={{
						borderRadius: "6px",
						borderColor: "#311D4F",
						...styleSearchInput,
					}}
					ref={ref}
					color={theme.palette.grey[500]}
					onFocus={() => setIsFocus(true)}
					placeholder={placeholderText}
					className="search-input-antd"
					prefix={
						<InputAdornment position="start">
							<IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
						</InputAdornment>
					}
				/>

				{hasAdd && (
					<AddCircleOutline
						onClick={hasAdd && (() => onAdd())}
						sx={{
							position: "absolute",
							right: "10px",
							top: "50%",
							transform: "translate(0, -50%)",
							zIndex: 100,
							cursor: "pointer",
							":hover": {
								background: "#f2f2f2",
								borderRadius: "50%",
							},
						}}
					/>
				)}
			</Box>
		</Tippy>
	);
}

export default forwardRef(OrderSearchComponent);
