import { Box, Grid } from "@mui/material";
import packAPI from "api/PackAPI";
import transactionAPI from "api/TransactionAPI";
import DepositInvoicesFilter from "components/FilterSide/DepositInvoicesFilter";
import QrInvoicesFilter from "components/FilterSide/QrInvoicesFilter";
import InvoicesDepositTable from "components/InvoicesTable/InvoicesDepositTable";
import InvoicesQrTable from "components/InvoicesTable/InvoicesQrTable";
import { PAGINATION } from "constants/pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainCard from "ui-component/cards/MainCard";

function InvoicesDepositPage() {
	// const { showLoading, hideLoading } = useContext(LoadingContext);
	const [loadingTable, setLoadingTable] = useState(false);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const mainBranchId = branchList.find((item) => item.is_main)?.id;
	const defaultsFilter = {
		branch: [mainBranchId],
		date: [new Date(), new Date()],
		status_enum: ["DONE"],
		type: ["REQUEST_DEPOSIT", "REQUEST_DEPOSIT_FOR_FIRST_TIME"],
	};
	const [filterData, setFilterData] = useState(defaultsFilter);
	const filterQuery = {
		status_enum: {
			$in:
				filterData?.status_enum && filterData?.status_enum?.length !== 0
					? filterData?.status_enum
					: [],
		},
		type: {
			$in: filterData?.type && filterData?.type?.length !== 0 ? filterData?.type : [],
		},
		note_admin: filterData?.note_admin
			? {
					$iLike: `%${filterData?.note_admin.trim()}%`,
			  }
			: undefined,
		note: filterData?.note
			? {
					$iLike: `%${filterData?.note.trim()}%`,
			  }
			: undefined,
		$or:
			filterData.employee_confirm || filterData.user || filterData.employee
				? [
						filterData.employee_confirm
							? {
									["$employee_confirm.fullname$"]: {
										$iLike: `%${filterData?.employee_confirm.trim()}%`,
									},
							  }
							: undefined,
						filterData.employee_confirm
							? {
									["$employee_confirm.username$"]: {
										$iLike: `%${filterData?.employee_confirm.trim()}%`,
									},
							  }
							: undefined,
						filterData.user
							? {
									["$user.username$"]: {
										$iLike: `%${filterData?.user.trim()}%`,
									},
							  }
							: undefined,
						filterData.user
							? {
									["$user.fullname$"]: {
										$iLike: `%${filterData?.user.trim()}%`,
									},
							  }
							: undefined,
						filterData.employee
							? {
									["$employee.username$"]: {
										$iLike: `%${filterData?.employee.trim()}%`,
									},
							  }
							: undefined,
						filterData.employee
							? {
									["$employee.fullname$"]: {
										$iLike: `%${filterData?.employee.trim()}%`,
									},
							  }
							: undefined,
				  ].filter((item) => item)
				: undefined,

		created_at_unix_timestamp:
			filterData.date[0] && filterData.date[1]
				? {
						$between: [
							+moment(filterData.date[0]).utc(true).startOf("days").format("x"),
							+moment(filterData.date[1]).utc(true).endOf("days").format("x"),
						],
				  }
				: undefined,
	};

	const [pagination, setPagination] = useState({
		limit: PAGINATION.LIMIT,
		page: PAGINATION.PAGE,
	});

	const [rows, setRows] = useState([]);

	const query = {
		fields: [
			"$all",
			"$paranoid",
			{
				user: [
					"name_admin_note",
					"phone",
					"is_collab",
					"amount_of_buy_card",
					"deleted_at",
					"$paranoid",
				],
			},
			{
				employee_confirm: ["fullname", "username", "deleted_at", "$paranoid"],
			},
			{
				employee: ["fullname", "username", "deleted_at", "$paranoid"],
			},
		],
		filter: filterQuery,
		order: [["created_at", "desc"]],
	};

	const fetchOrderData = async (customQuery = undefined) => {
		// showLoading();
		setLoadingTable(true);
		try {
			const payload = {
				...query,
				...pagination,
				fields: JSON.stringify(query.fields),
				filter: JSON.stringify(query.filter),
				order: JSON.stringify(query.order),
				...customQuery,
				total: undefined,
			};

			const res = await transactionAPI.getList(payload);

			if (customQuery) {
				setLoadingTable(false);

				return res?.results?.objects.rows;
			} else {
				const paginationRes = {
					...pagination,
					page: res?.pagination?.current_page,
					total: res?.results?.objects?.count,
				};

				setPagination(paginationRes);
				setRows(res.results?.objects?.rows);

				setLoadingTable(false);
			}
			// hideLoading();
		} catch (error) {
			console.log(error);
			// hideLoading();
			setLoadingTable(false);
		}
	};

	useEffect(() => {
		if (!branchActiveId) return;
		fetchOrderData();
	}, [filterData, pagination.page, pagination.limit]);
	useEffect(() => {
		if (!branchActiveId) return;
		setFilterData({ ...defaultsFilter });
	}, [branchActiveId]);

	return !branchActiveId ? (
		<></>
	) : (
		<Box width={"100%"}>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<DepositInvoicesFilter filterData={filterData} setFilterData={setFilterData} />
				</Grid>
				<Grid item xs={9}>
					<MainCard>
						<InvoicesDepositTable
							rows={rows}
							setPagination={setPagination}
							pagination={pagination}
							filterData={filterData}
							loading={loadingTable}
							getData={() => fetchOrderData({ limit: 99999, page: 1 })}
						/>
					</MainCard>
				</Grid>
			</Grid>
		</Box>
	);
}

export default InvoicesDepositPage;
