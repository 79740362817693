import { CancelPresentation } from "@mui/icons-material";
import { Grid, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Select as SelectAntd } from "antd";
import userAPI from "api/UserAPI";
import { CustomNoRowsOverlay } from "components/noRows";
import { SearchComponent } from "components/search";
import useDebounce from "hooks/useDebounce";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import { showSortStyle } from "views/utilities/CustomStyle";

function TableModal({ columnProps, getListTable, open, groupScanSkin = "K5" }) {
	const actionList = [
		{
			value: "delete",
			title: "delete",
			Icon: CancelPresentation,
		},
	];
	const { Option } = SelectAntd;

	const searchList = [
		// {
		// 	value: "username",
		// 	title: "username",
		// },
		{
			value: "fullname",
			title: "fullname",
		},
		{
			value: "name_admin_note",
			title: "Tên thân mật",
		},
		{
			value: "username",
			title: "SĐT",
		},
	];
	const [deleteUser, setDeleteUser] = useState({
		open: false,
		username: "",
	});
	const handleOpenRemoveModal = (id, row) => {
		setDeleteUser({
			id: id,
			open: true,
			username: row.username,
		});
	};
	const onChangeAction = (value, id, params) => {
		value === "delete" && handleConfirmCancelParentCollab(id, params.row?.username);
	};
	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();
	const handleConfirmCancelParentCollab = (id, username) => {
		modalContext.showDeleteConfirm(
			id,

			() => handleCancelParentCollab(id),
			`Bạn có chắc chắn muốn loại ${username} ra khỏi danh sách cộng tác viên`,
			"Chắc chắn"
		);
	};

	const handleCancelParentCollab = async (id) => {
		try {
			const res = await userAPI.update(id, {
				is_collab_of_system: null,
				parent_id: null,
			});
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListTable();
		} catch (err) {
			enqueueSnackbar(err.message || "Có lỗi xảy ra", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const [addMember, setAddMember] = useState({
		open: false,
	});

	const [openModalImage, setOpenModalImage] = useState(false);
	const [imgQr, setImgQr] = useState("");
	const columns = columnProps;
	//Search

	const [searchBy, setSearchBy] = useState(searchList[0].value);
	const [searchValue, setSearchValue] = useState("");

	const debounceVal = useDebounce(searchValue);

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
	};

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});
	const handleOnChangePage = (e, number) => {
		setPagination({
			...pagination,
			page: number,
		});
	};
	const queryParams = useMemo(() => {
		const params = {};

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, []);
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const res = await getListTable(groupScanSkin, pagination);
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		};
		fetchData();

		if (open) {
		}
	}, [open, pagination.page, debounceVal]);

	return (
		<>
			<MainCard>
				<HeaderActions
					setOpen={(value) =>
						setAddMember((prev) => ({
							...prev,
							open: value,
						}))
					}
					textButton={"Thêm tuyến dưới"}
					title={" "}
					onCreate={true}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							loading={isLoading}
							sortingMode="server"
							// sortModel={sortModel}
							// onSortModelChange={handleSortModelChange}

							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>

				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleOnChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
		</>
	);
}

export default TableModal;
