import React from "react";

import classnames from "classnames/bind";
import styles from "./component.module.scss";

const cx = classnames.bind(styles);

function IconBox({
	children,
	customStyle,
	classCustom,
	size = "medium", //medium | large | small
	styleIcon = "circle", //circle | square
	...props
}) {
	//handle className style into component.module.scss
	return (
		<div
			style={{
				background: "#0058FF1A",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				...customStyle,
			}}
			className={cx("style__icon", {
				[size]: size,
				[styleIcon]: styleIcon,
				...classCustom,
			})}
			{...props}
		>
			{children}
		</div>
	);
}

export default IconBox;
