import { Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { FormLabel, Grid, IconButton, Stack, Switch } from "@mui/material";
import { Box } from "@mui/system";
import employeeAPI from "api/EmployeeAPI";
import useCustomAuth from "hooks/useCustomAuth";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_EMPLOYEE, UPDATE_SETTING_EMPLOYEE } from "store/actions/EmployeeAction/type";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";

function SettingCMS() {
	const { employeeInfo } = useCustomAuth();
	const [loading, setLoading] = useState(false);
	const { control, handleSubmit, register } = useForm({
		mode: "onChange",
	});
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const onSubmit = async (data) => {
		setLoading(true);
		try {
			const res = await employeeAPI.edit(employeeInfo.id, data);
			dispatch({
				type: UPDATE_SETTING_EMPLOYEE,
				payload: res?.results,
			});
			setLoading(false);
			enqueueSnackbar("Cập nhật thành công", {
				autoHideDuration: 2000,
				variant: "success",
			});
		} catch (err) {
			enqueueSnackbar(err?.message || "Lỗi", {
				autoHideDuration: 2000,
				variant: "error",
			});
			setLoading(false);
		}
	};
	return (
		<MainCard>
			<Stack alignItems={"center"} flexDirection="row" gap={2}>
				<HeaderActions title={"Cài đặt cá nhân"} textButton={"Đặt lịch"} />
				{/* <IconButton>
					<Edit />
				</IconButton> */}
			</Stack>
			<Box sx={{ padding: "10px 20px" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={1}>
						<Grid xs={6} item>
							<FormLabel
								sx={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
								component="legend"
							>
								In hóa đơn:
							</FormLabel>
							<Switch
								name="auto_export"
								inputRef={register()}
								defaultChecked={employeeInfo.auto_export}
							/>
						</Grid>
					</Grid>

					<Box py={2} textAlign={"right"}>
						<LoadingButton loading={loading} variant={"contained"} type={"submit"}>
							Cập nhật
						</LoadingButton>
					</Box>
				</form>
				<Grid xs={12} item></Grid>
			</Box>
		</MainCard>
	);
}

export default SettingCMS;
