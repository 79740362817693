import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	TextField,
} from "@mui/material";
import React from "react";

function CancelModal({ open, setOpen, reason, setReason, loading, handleSubmit, text }) {
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<React.Fragment>
			<Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
				<DialogTitle sx={{ fontSize: "18px" }}>{text.title}</DialogTitle>
				<DialogContent>
					<Box
						noValidate
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
							m: "auto",
						}}
					>
						<FormControl sx={{ mt: 2 }}>
							<TextField
								margin="dense"
								name="reason"
								label={text.label}
								type="text"
								fullWidth
								multiline
								minRows={4}
								value={reason}
								onChange={(e) => setReason(e.target.value)}
							/>
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						onClick={handleSubmit}
						// endIcon={<SaveIcon />}
						loading={loading}
						loadingPosition="end"
						variant="contained"
					>
						{text.submit}
					</LoadingButton>
				</DialogActions>

				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</React.Fragment>
	);
}

export default CancelModal;
