import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column, Line } from "@ant-design/plots";
import { Box, Typography } from "@mui/material";
import LinkIcon from "components/LinkIcon";

const LineChart = ({ data = [], chartText, linkPath, propsLink, configChart }) => {
	const config = {
		data,
		padding: "auto",
		xField: configChart.xField,
		yField: configChart.yField,
		xAxis: {
			tickCount: 5,
		},
		yAxis: {
			min: 0,
			[configChart.yField]: {
				label: {
					formatter: (v) => `${v}`.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
				},
			},
		},
		meta: {
			[configChart.yField]: {
				formatter: (v) => `${v}`.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
				alias: chartText?.metaTitle || "Số tiền được rút",
			},
		},
		slider: {
			start: 0,
			end: 1,
		},
		columnStyle: {
			radius: [100000, 100000],
		},
		geometryOptions: [
			{
				geometry: "column",

				seriesField: chartText?.metaTitle,
				smooth: true,
			},
		],
		...configChart,
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					gap: "10px",
				}}
			>
				<Typography variant="h2" id="header_title" sx={{ margin: "20px 0" }}>
					{chartText.title}
				</Typography>
				{linkPath && <LinkIcon to={linkPath} {...propsLink} />}
			</Box>
			<Column {...config} />
		</>
	);
};

export default LineChart;
