import React from "react";

import classnames from "classnames/bind";
import styles from "./BookingDetail.module.scss";
import { Button } from "@mui/material";
import { IMAGES } from "assets/images/imagePath";

const cx = classnames.bind(styles);
function ServiceItem({ service, onSetStateAssign }) {
	return (
		<div className={cx("wrapper")}>
			<div className={cx("image")}>
				<img src={service.image || IMAGES.avatar_default} alt="serviceImg" />
			</div>
			<div className={cx("content")}>
				<h2>{service.title}</h2>
				<div className={cx("content_row")}>
					<p>
						Danh mục: <span>{service.category_name}</span>
					</p>

					<p>
						Nhân viên: <span>{service?.employee_info?.fullname || "Trống"}</span>
					</p>
				</div>
				<p>
					Thời gian: <span>{service.execution_time} Phút</span>
				</p>
			</div>
			<Button
				className={cx("btn__assign")}
				sx={{
					textTransform: "initial",
				}}
				onClick={() =>
					onSetStateAssign({
						data: service,
						openModal: true,
						isUpdate: service.employee_id ? true : false,
					})
				}
			>
				{service.employee_info ? "Giao việc lại" : "Giao việc"}
			</Button>
		</div>
	);
}

export default ServiceItem;
