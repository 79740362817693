import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Popover,
	Radio,
	RadioGroup,
	Select,
	Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { InputNumber, TimePicker } from "antd";
import bookingAPI from "api/BookingAPI";
import classnames from "classnames/bind";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import styles from "./BookingModal.module.scss";
import ServiceArea from "./ServiceArea";
//Firebase
import { Close } from "@mui/icons-material";
import viVN from "antd/es/date-picker/locale/vi_VN";
import authAPI from "api/AuthAPI";
import { signInWithPhoneNumber } from "firebase/auth";
import { authentication } from "firebase/firebase-config";
import { switchErrorMessage } from "firebase/firebase-function";
import { generateRecaptcha } from "firebase/firebase-otp";
import "moment/locale/vi";
import { getNumberWithCommas } from "utils";
//DatePicker Antd

import { DatePicker } from "antd";
import comboServiceAPI from "api/ComboServiceAPI";
import schedulerAPI from "api/SchedulerAPI";
import userAPI from "api/UserAPI";
import { ROLE } from "constants/employeeRole";
import { activityList, formatVND } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import useDebounce from "hooks/useDebounce";
import { ModalContext } from "providers/ModalProvider";
import { actUpdateCheckList } from "store/actions/BookingActions/action";
import { actResetEmployeeBooking, actUpdateEmployeeBooking } from "store/actions/EmployeeBooking";
import { actResetEmployeeSale, actUpdateEmployeeSale } from "store/actions/EmployeeSale";
import {
	actGetIsUpdateComboUsing,
	actResetUserComboUsing,
	actSetCountUsing,
} from "store/actions/UserComboServiceAction";
import NoData from "ui-component/NoData";
import CustomerInfo from "views/pages/CreateOrder/components/CartSide/CustomerInfo";
import CustomerItem from "views/pages/CreateOrder/components/CustomerItem";
import OrderSearchComponent from "views/pages/CreateOrder/components/OrderSearchComponent";
import DialogMain from "../DialogMui";
import UserModal from "../UserModal";
import EmployeeSale from "./EmployeeSale";
import SelectedEmployee from "./SelectedEmployee";
import SelectedService from "./SelectedService";
import UserComboService from "./UserComboService";
import UserWallet from "./UserWallet";
import DiscountByType from "ui-component/DiscountByType";
const { RangePicker } = DatePicker;
const cx = classnames.bind(styles);

const defaultTypeList = [
	{
		value: "NOTIFICATION",
		title: "Notification",
	},
	{
		value: "TERMS_CONDITIONS",
		title: "Terms & Conditions",
	},
	{
		value: "LOCATION",
		title: "Location",
	},
];
const TextValidation = styled("span")({
	color: "red",
});

function BookingModal({ ...props }) {
	const { isOpen, isClose, isLoading, formData, isUpdate, listSelect, getListTable } = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));
	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const {
		register,
		handleSubmit,
		errors,
		control,
		reset,
		getValues,
		watch,
		setError,
		setValue,
		clearErrors,
	} = useForm({
		mode: "onChange",
	});

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [errorServiceList, setErrorServiceList] = useState({ status: false, message: "" });

	const [ckData, setCkData] = useState("");
	const [type, setType] = useState("NOTIFICATION");
	const [image, setImage] = useState(formData.image);
	useEffect(() => {
		setImage(formData.image);
	}, [formData]);

	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleClose = () => {
		isClose(false);
		// setStepOTP(1);
		setStepOTP(3);
	};

	//Popoover
	const [anchorEl, setAnchorEl] = useState(null);
	const [childTypePopover, setChildTypePopover] = useState("");

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const closeDiscountPop = () => {
		document.removeEventListener("keypress", window.scannerFnc);
		setAnchorEl(null);
		document.addEventListener("keypress", window.scannerFnc);
	};

	const setDiscount = (value) => {
		handleOnChangePrice("discount", value);
		handleOnChangePrice("customerPay", getTotalPrice() - (value || 0));
	};

	//
	const comboUsing = useSelector((state) => state.userComboService.comboServices);

	const dispatch = useDispatch();

	const [errorMount, setErrorMount] = useState(false);
	const onSubmit = async (data, e, isPaid) => {
		e.preventDefault();

		let dateIO = moment(data.date).format("YYYY-MM-DD");
		let timeIO = moment(data.time).format("HH:mm");

		data.time_start = moment(`${dateIO}T${timeIO}`).valueOf();
		if (!isUser) {
			setErrorMount(true);
			return;
		}
		try {
			if (!isUpdate) {
				if (checkList.length === 0 && comboUsing.length === 0) {
					setLoading(false);
					setErrorServiceList({ status: true, message: "Hãy chọn ít nhất một dịch vụ" });
					return;
				} else {
					const noneComboArr = checkList.map((item) => {
						console.log(item);
						if (item.combo_id === null) {
							return {
								service_id: item.service_id,
								count: item.count,
								note_admin: item.note_admin,
								price: item.deducted_price, //Lấy giá sau  khi đổi ở checkList hoặc lấy giá gốc
							};
						}
					});
					const inCombo = comboUsing.map((item) => ({
						service_id: item.service_id,
						count: item.count,
						combo_id: item.combo_id,
						order_item_id: item.order_item_id,
					}));

					const employee_items = employeeList.map((employee) => ({
						employee_id: employee.id,
						work: employee.work,
					}));
					const employee_sale = employeeSaleList.map((employee) => ({
						employee_id: employee.id,
					}));

					const mergeArr = [...noneComboArr, ...inCombo];
					// data.name = ""; //Đợi Backend xóa cột này thì xóa dòng này
					data.phone = userInfor.phone;
					data.items = mergeArr;
					data.employee_items = employee_items;
					data.employee_sale = employee_sale;
					data.discount = inputVal.discount;
					data.booking_type = checkList.length === 0 ? null : bookingType;
					data.is_paid = bookingType === "WALLET" ? true : isPaid; //  WALLET => Đặt lịch isPaid = true : tùy vào ispaid
					data.is_confirm = bookingType === "WALLET" ? true : false; //isOTPView để check người dùng bấm vào Ví dịch vụ hay tiền mặt
					data.slot_id = data.slot_id === "noneBed" ? null : data.slot_id;
					data.branch_id = branchActiveId;
					if (data.employee_sale.length === 0) {
						delete data.employee_sale;
					}
					const response = await bookingAPI.create(data);
					if (response.code === 200) {
						isClose(false);
						setLoading(false);
						enqueueSnackbar(t(`success`), {
							variant: "success",
							autoHideDuration: 2000,
						});
						getListTable();
					} else {
						enqueueSnackbar(t(`error`), {
							variant: "error",
							autoHideDuration: 2000,
						});
					}
				}
			} else {
				const noneComboArr = checkList.map((item) => {
					if (item.combo_id === null) {
						return {
							service_id: item.service_id,
							count: item.count,
							note_admin: item.note_admin,
							price: item.deducted_price,
						};
					}
				});
				const inCombo = comboUsing.map((item) => ({
					service_id: item.service_id,
					count: item.count,
					combo_id: item.combo_id,
					order_item_id: item.order_item_id,
				}));

				const mergeArr = [...noneComboArr, ...inCombo];
				const employee_items = employeeList.map((employee) => ({
					employee_id: employee.id,
					work: employee.work,
				}));
				const employee_sale = employeeSaleList.map((employee) => ({
					employee_id: employee.id,
				}));

				data.items = mergeArr;
				data.employee_items = employee_items;
				data.employee_sale = employee_sale;
				data.branch_id = branchActiveId;
				data.discount = inputVal.discount;
				data.phone = userInfor.phone;

				data.employee_sale = employee_sale;
				data.booking_type = checkList.length === 0 ? null : bookingType;
				data.is_paid = bookingType === "WALLET" ? true : isPaid; //  WALLET => Đặt lịch isPaid = true : tùy vào ispaid
				data.is_confirm = bookingType === "WALLET" ? true : false; //isOTPView để check người dùng bấm vào Ví dịch vụ hay tiền mặt
				data.slot_id = data.slot_id === "noneBed" ? null : data.slot_id;

				const response = await bookingAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({
				state: true,
				message: error?.message || "Lỗi không xác định, vui lòng thử lại",
			});
			setLoading(false);
		}
	};
	const resetModal = () => {
		setIsOtpView(false);
		setIsUser(false);
		setOtpValue("");
		setUserInfor();
		setSearchValue("");
		setSearchsResults([]);

		setBookingType("CASH");
		setTotalPriceUpdate(0);
		dispatch(actResetUserComboUsing());

		isUpdateMount.current = true;

		//OtpView
		// setStepOTP(1);
		setStepOTP(3);
		setSendedOTP(false);
		setOtpValue("");
		setComboServices([]);
	};
	useEffect(() => {
		if (isUpdate) {
			let countState = {};

			//Xử lý comboUsing nếu có
			//Fill the combo Using if have
			const comboUsing = formData.booking_details
				.filter((item) => !!item.combo_service_id === true) //Filter Array combo
				.map((bookingService) => {
					const comboUsing = bookingService.order_item.combo_using.find(
						(comboUsing) => comboUsing.service_id === bookingService.service_id
					);
					const lastCountState = { ...countState };
					countState = {
						...lastCountState,
						[`count-${comboUsing.order_item_id}-${comboUsing.id}`]: bookingService.count,
					};
					console.log(bookingService);
					return {
						id: comboUsing.id,
						combo_title: bookingService.order_item?.title,
						combo_id: bookingService.combo_service_id,
						service_id: bookingService.service_id,
						service: {
							price_deducted: bookingService?.service?.price_deducted,
							id: bookingService.service_id,
							quantity: bookingService?.quantity,
							title: bookingService.title,
						},
						order_item_id: bookingService.order_item_id,
						count: bookingService.count,
					};
				});

			dispatch(actSetCountUsing(countState));

			dispatch(actGetIsUpdateComboUsing(comboUsing));

			dispatch(actUpdateCheckList(formData));
			dispatch(actUpdateEmployeeBooking(formData.booking_consultants));

			dispatch(actUpdateEmployeeSale(formData.employee_sales));

			setCkData(formData?.implementation_process);
			setBookingType(formData?.booking_type);
			setUserInfor(formData.user);
			setSearchValue(formData.user?.phone);
			setIsUser(true);
			setType(formData?.category_service_id);
			setInputVal({
				discount: Number(formData.discount),
				customerPay: 0,
			});
		} else {
			setCkData("");
			setType(selectList && selectList[0].value);
			setSearchValue("");
			setErrorMount(false);
		}
		return () => {
			resetModal();
			dispatch(actResetEmployeeBooking());
			dispatch(actResetEmployeeSale());
			setInputVal({
				discount: 0,
				customerPay: 0,
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const checkList = useSelector((state) => state.booking.checkBoxList);
	const isUpdateMount = useRef(true);
	useEffect(() => {
		const totalIsPaid = getTotalPrice();

		if (formData.is_paid && isUpdateMount.current) {
			setTotalPriceUpdate(totalIsPaid);
			isUpdateMount.current = false;
		}
		setInputVal((state) => ({
			...state,
			customerPay: totalIsPaid - state.discount,
		}));
	}, [checkList]);

	const translator = (text) => {
		return `abeauty.booking.dialog_form.${text}`;
	};

	const [totalPriceUpdate, setTotalPriceUpdate] = useState(0);

	const getTotalPrice = (discountPrice) => {
		const noneComboArr = checkList.map((item) => {
			console.log(item);
			if (item.combo_id === null) {
				return {
					service_id: item.service_id,
					count: item.count,
					note_admin: item.note_admin,
					price: item.deducted_price >= 0 ? item.deducted_price : item.service.price_deducted, //Lấy giá sau  khi đổi ở checkList hoặc lấy giá gốc
				};
			}
		});

		let totaLPrice = noneComboArr.reduce(
			(prev, current) => Number(prev * 1 + current.price * current.count),
			0
		);

		return totaLPrice;
		// if (discountPrice) {
		// 	return checkList.reduce((prev, current) => {
		// 		return Number(prev * 1 + current.price * current.count);
		// 	}, 0);
		// } else {
		// 	return checkList.reduce(
		// 		(prev, current) => Number(prev * 1 + 1 * current.service.price * current.count),
		// 		0
		// 	);
		// }
	};

	const totalPrice = getTotalPrice() - totalPriceUpdate;

	//Phương thức thanh Toán

	const [isOtpView, setIsOtpView] = useState(false);
	const [otpValue, setOtpValue] = useState("");
	const handleChangeOtp = (otpValue) => {
		setOtpValue(otpValue);
	};
	//Check số diện thoại có phải user không

	const [isUser, setIsUser] = useState(isUpdate ? true : false);
	const [userInfor, setUserInfor] = useState();
	const fetchCheckUser = async (phoneNum) => {
		const res = await authAPI.checkFullDetailsUser(phoneNum);
		return res;
	};
	const handleCheckIsUser = async () => {
		//Call API check pone

		const phoneNum = getValues("phone");
		try {
			const res = await fetchCheckUser(phoneNum);
			await getUserComboService(phoneNum);

			if (res.code === 200) {
				//Code 200 : Số điện thoại chưa được đăng ký => showError not user
				setError("phone", {
					type: "checkPhone",
					message: "Tài khoản đã được đăng ký",
					isCanUse: true,
				});
				setUserInfor(res.results.object);
				setIsUser(true);
			}
		} catch (err) {
			if (err.code === 501) {
				//code === 501 số điện thoại này đã có trong hệ thống
				setError("phone", {
					type: "checkPhone",
					message: "Tài khoản không tồn tại, sẽ được tạo mới",
				});
				setIsUser(false);
			} else {
				setError("phone", {
					type: "checkPhone",
					message: "Tài khoản không tồn tại, sẽ được tạo mới",
				});
				setIsUser(false);
			}
		}
	};
	useEffect(() => {
		if (isUser === false) {
			// setError("phone", {
			// 	type: "checkPhone",
			// 	message: "",
			// });
		}
	}, [isUser]);

	///=======+++++++=======FIREBASE OTP CODE HERE ++++++++========+++++++++++

	const [errorFirebase, setErrorFirebase] = useState({ state: false, message: "" });
	const [stepOTP, setStepOTP] = useState(3); //Nếu mở lại OTP thì để cái này là 1 (comment 1)
	const [sendedOTP, setSendedOTP] = useState(false);
	const requestOTP = (e) => {
		e.preventDefault();
		setSendedOTP(true);
		setErrorFirebase({ state: false, message: "" });

		generateRecaptcha();
		const appVerifier = window.recaptchaVerifier;
		const phoneNum = getValues("phone");
		signInWithPhoneNumber(authentication, `+84${phoneNum}`, appVerifier)
			.then((confirmationResult) => {
				setStepOTP(2);
				// SMS sent. Prompt user to type the code from the message, then sign the
				// user in with confirmationResult.confirm(code).
				window.confirmationResult = confirmationResult;
				// ...
			})
			.catch((error) => {
				const errMessage = switchErrorMessage(error);

				setErrorFirebase({ state: true, message: errMessage });
				// Error; SMS not sent
				// ...
			});
	};

	const verifyOTP = () => {
		setErrorFirebase({ state: false, message: "" });

		if (otpValue.length < 6) return;
		let confirmationResult = window.confirmationResult;
		confirmationResult
			.confirm(otpValue)
			.then((result) => {
				setStepOTP(3);
				enqueueSnackbar("Xác thực thành công", {
					variant: "success",
					autoHideDuration: 2000,
				});
			})
			.catch((err) => {
				const errMessage = switchErrorMessage(err);
				//SetShowErrorFireBase
				setErrorFirebase({ state: true, message: errMessage });
			});
	};

	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		//Sử  dụng để tránh lần đầu component mount sử dụng function
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		} else {
			if (checkList.length === 0 && comboUsing.length === 0) {
				setErrorServiceList({ status: true, message: "Hãy chọn ít nhất một dịch vụ" });
			} else {
				setErrorServiceList({ status: false, message: "" });
			}
		}
	}, [checkList, comboUsing]);

	//Get Bed Group

	const [bedGroup, setBedGroup] = useState([]);
	const getBedGroup = async () => {
		setLoading(true);

		const params = {
			fields: '["$all"]',
		};
		try {
			const res = await schedulerAPI.getBedList(params);
			const bedGroupConvert = res.results?.objects?.rows?.map((item) => ({
				id: item.id,
				text: item.name,
			}));
			const dataBed = [
				//Thêm giường chưa xác định

				...bedGroupConvert,
				{
					id: "noneBed",
					text: "Chọn giường sau",
				},
			];

			setBedGroup(dataBed);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};
	const modalContext = useContext(ModalContext);
	const handleConfirmModal = (data, e, isPaid) => {
		modalContext.showDeleteConfirm(
			null,
			() => onSubmit(data, e, isPaid),
			isPaid
				? "Chắc chắn lich hẹn này đã nhận được tiền?"
				: "Chắc chắn lịch hẹn này chưa được trả tiền?",
			"Chắc chắn"
		);
	};
	const [comboServices, setComboServices] = useState([]);
	const getUserComboService = async (userPhoneNum) => {
		setLoading(true);

		try {
			const res = await comboServiceAPI.getUserCombo(userPhoneNum);

			setComboServices(res.results.objects.rows);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};
	const [bookingType, setBookingType] = useState("CASH");
	const handleChangeBookingType = (e) => {
		setBookingType(e.target.value);
	};
	const renderFooterAction = () => {
		if (!isUpdate) {
			switch (bookingType) {
				case "CASH":
					return (
						<>
							{/* <Box sx={{ width: "100%" }}>
								<DialogTitle
									sx={{
										fontSize: "12px",
									}}
								>
									Thanh toán trực tiếp tại cửa hàng
								</DialogTitle>
							</Box> */}
							<Box
								sx={{
									display: "flex",
									width: "100%",
									gap: "10px",
								}}
							>
								<LoadingButton
									// endIcon={<SaveIcon />}
									loading={loading}
									loadingPosition="end"
									variant="contained"
									className={cx("button__action")}
									onClick={handleSubmit((data, e) => {
										handleConfirmModal(data, e, true);
									})}
								>
									THANH TOÁN
								</LoadingButton>{" "}
								<LoadingButton
									// endIcon={<SaveIcon />}
									loading={loading}
									loadingPosition="end"
									variant="contained"
									className={cx("button__action")}
									onClick={handleSubmit((data, e) => handleConfirmModal(data, e, false))}
								>
									ĐẶT LỊCH
								</LoadingButton>
							</Box>
						</>
					);
				case "WALLET":
					return (
						<>
							<Box
								sx={{
									width: "100%",
									display: "none", // Cần OTP thì bật lên
								}}
							>
								{/* <DialogTitle
									sx={{
										fontSize: "12px",
									}}
								>
									{`Mã OTP sẽ được gửi đến số điện thoại ${watch("phone") || ""}`}
								</DialogTitle> */}
								<Box sx={{ padding: "20px", width: "100%" }}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "20px",
										}}
									>
										<OtpInput
											value={otpValue}
											inputStyle={{
												width: "40px",
												height: "40px",
												borderRadius: "3px",
												marginRight: "5px",
												outline: "none",
												border: "none",
												background: "#F2F2F2",
											}}
											onChange={handleChangeOtp}
											numInputs={6}
											// isDisabled={stepOTP === 3}
										/>
										<div id="recaptcha-button"></div>
										{stepOTP == 1 ? (
											<LoadingButton
												// endIcon={<SaveIcon />}
												loading={loading}
												loadingPosition="end"
												variant="outlined"
												disabled={!watch("phone") || (stepOTP === 1 && sendedOTP)}
												onClick={requestOTP}
												sx={{
													display: stepOTP != 1 ? "none" : "block",
												}}
											>
												GỬI MÃ OTP
											</LoadingButton>
										) : (
											stepOTP == 2 && (
												<LoadingButton
													// endIcon={<SaveIcon />}
													loading={loading}
													loadingPosition="end"
													variant="text"
													disabled={otpValue.length < 6}
													onClick={verifyOTP}
													sx={{
														display: stepOTP != 2 ? "none" : "block",
													}}
												>
													XÁC THỰC OTP
												</LoadingButton>
											)
										)}
									</Box>

									{errorFirebase.state && <TextValidation>{errorFirebase.message}</TextValidation>}
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									width: "100%",
									margin: "20px 0",
								}}
							>
								<LoadingButton
									// endIcon={<SaveIcon />}
									//type Submit là mặc định sử dụng submit của form
									type="submit"
									loading={loading}
									loadingPosition="end"
									variant="contained"
									className={cx("button__action")}
									// disabled={otpValue.length < 6}
								>
									ĐẶT LỊCH
								</LoadingButton>
							</Box>
						</>
					);
				case "TRANSFER":
				case "CARD": {
					return (
						<>
							{/* <Box sx={{ width: "100%" }}>
								<DialogTitle
									sx={{
										fontSize: "12px",
									}}
								>
									{bookingType === "CARD" ? "Quẹt thẻ" : "Chuyển khoản"}
								</DialogTitle>
							</Box> */}
							<Box
								sx={{
									display: "flex",
									width: "100%",
									gap: "10px",
									justifyContent: "center",
								}}
							>
								<LoadingButton
									// endIcon={<SaveIcon />}
									loading={loading}
									loadingPosition="end"
									variant="contained"
									className={cx("button__action")}
									onClick={handleSubmit((data, e) => handleConfirmModal(data, e, true))}
								>
									THANH TOÁN
								</LoadingButton>
								<LoadingButton
									// endIcon={<SaveIcon />}
									loading={loading}
									loadingPosition="end"
									variant="contained"
									className={cx("button__action")}
									onClick={handleSubmit((data, e) => handleConfirmModal(data, e, false))}
								>
									ĐẶT LỊCH
								</LoadingButton>
							</Box>
						</>
					);
				}
				default:
					return;
			}
		} else {
			switch (bookingType) {
				case "CASH":
					return (
						<>
							{/* <Box sx={{ width: "100%" }}>
								<DialogTitle
									sx={{
										fontSize: "12px",
									}}
								>
									Thanh toán trực tiếp tại cửa hàng
								</DialogTitle>
							</Box> */}
							<Box
								sx={{
									display: "flex",
									width: "100%",
									gap: "10px",
								}}
							>
								<LoadingButton
									// endIcon={<SaveIcon />}
									loading={loading}
									loadingPosition="end"
									type="submit"
									variant="contained"
									className={cx("button__action")}
									onClick={handleSubmit((data, e) => {
										handleConfirmModal(data, e, true);
									})}
								>
									THANH TOÁN
								</LoadingButton>{" "}
								<LoadingButton
									// endIcon={<SaveIcon />}
									loading={loading}
									loadingPosition="end"
									variant="contained"
									type="submit"
									className={cx("button__action")}
									onClick={handleSubmit((data, e) => handleConfirmModal(data, e, false))}
								>
									ĐẶT LỊCH
								</LoadingButton>
							</Box>
						</>
					);
				case "WALLET":
					return (
						<>
							<Box
								sx={{
									width: "100%",
									display: "none", //Cần OTP thì bật lên
								}}
							>
								{/* <DialogTitle
									sx={{
										fontSize: "12px",
									}}
								>
									{`Mã OTP sẽ được gửi đến số điện thoại ${watch("phone") || ""}`}
								</DialogTitle> */}
								<Box sx={{ padding: "20px", width: "100%" }}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "20px",
										}}
									>
										<OtpInput
											value={otpValue}
											inputStyle={{
												width: "40px",
												height: "40px",
												borderRadius: "3px",
												marginRight: "5px",
												outline: "none",
												border: "none",
												background: "#F2F2F2",
												// Tắt nếu cần OTP
											}}
											onChange={handleChangeOtp}
											numInputs={6}
											isDisabled={stepOTP === 3}
										/>

										<div id="recaptcha-button"></div>
										{stepOTP == 1 ? (
											<LoadingButton
												// endIcon={<SaveIcon />}
												loading={loading}
												loadingPosition="end"
												variant="outlined"
												disabled={!watch("phone") || (stepOTP === 1 && sendedOTP)}
												onClick={requestOTP}
											>
												GỬI MÃ OTP
											</LoadingButton>
										) : (
											stepOTP == 2 && (
												<LoadingButton
													// endIcon={<SaveIcon />}
													loading={loading}
													loadingPosition="end"
													variant="text"
													disabled={otpValue.length < 6}
													onClick={verifyOTP}
												>
													XÁC THỰC OTP
												</LoadingButton>
											)
										)}
									</Box>

									{errorFirebase.state && <TextValidation>{errorFirebase.message}</TextValidation>}
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									width: "100%",
									margin: "20px 0",
								}}
							>
								<LoadingButton
									// endIcon={<SaveIcon />}
									//type Submit là mặc định sử dụng submit của form
									type="submit"
									loading={loading}
									loadingPosition="end"
									variant="contained"
									className={cx("button__action")}
									// disabled={otpValue.length < 6 || stepOTP !== 3}
								>
									ĐẶT LỊCH
								</LoadingButton>
							</Box>
						</>
					);
				case "TRANSFER":
				case "CARD": {
					return (
						<>
							{/* <Box sx={{ width: "100%" }}>
								<DialogTitle
									sx={{
										fontSize: "12px",
									}}
								>
									{bookingType === "CARD" ? "Quẹt thẻ" : "Chuyển khoản"}
								</DialogTitle>
							</Box> */}
							<Box
								sx={{
									display: "flex",
									width: "100%",
									gap: "10px",
									justifyContent: "center",
								}}
							>
								<LoadingButton
									// endIcon={<SaveIcon />}
									loading={loading}
									loadingPosition="end"
									variant="contained"
									className={cx("button__action")}
									onClick={handleSubmit((data, e) => handleConfirmModal(data, e, true))}
								>
									THANH TOÁN
								</LoadingButton>
								<LoadingButton
									// endIcon={<SaveIcon />}
									loading={loading}
									loadingPosition="end"
									variant="contained"
									className={cx("button__action")}
									onClick={handleSubmit((data, e) => handleConfirmModal(data, e, false))}
								>
									ĐẶT LỊCH
								</LoadingButton>
							</Box>
						</>
					);
				}
				default:
					return;
			}
		}
	};

	//Get list bed group
	useEffect(() => {
		getBedGroup();
	}, []);

	// Modal Combo dịch vụ

	const [comboService, setComboService] = useState({
		visible: false,
		data: null,
	});

	const handleOpenComboService = () => {
		setComboService((prev) => ({
			...prev,
			visible: true,
		}));
	};

	const isCanBook = useMemo(() => {
		const total = totalPrice;
		return Number(userInfor?.wallet) >= total;
	}, [totalPrice, userInfor]);

	//Employee Select
	const employeeList = useSelector((state) => state.employeeBooking.employeeList);
	const { employeeSaleList } = useSelector((state) => state.employeeSale);

	const [inputVal, setInputVal] = useState({
		discount: 0,
		customerPay: 0,
	});

	const handleOnChangePrice = (name, e) => {
		setInputVal((prev) => ({
			...prev,
			[name]: e,
		}));
	};

	const refund = useMemo(
		() => inputVal?.customerPay - (getTotalPrice() - inputVal?.discount),
		[inputVal]
	);
	const refSearchInput = useRef(null);
	const [openUserModal, setOpenUserModal] = useState(false);
	const [isFocusOutSide, setIsFocusOutSide] = useState(true);
	const [searchValue, setSearchValue] = useState("");
	const debounceValue = useDebounce(searchValue);
	const [searchResults, setSearchsResults] = useState([]);
	const handleOnPressCustomer = (user) => {
		setIsUser(true);
		setUserInfor(user);
	};

	const onAdd = () => {
		setIsFocusOutSide(false);
		setOpenUserModal({
			open: true,
			username: searchValue,
		});
	};

	const fetchUserBySearch = async (searchVal) => {
		setLoading(true);
		const filter = {
			$or: [
				{
					username: {
						$iLike: `%${searchVal.trim()}%`,
					},
				},
				{
					fullname: {
						$iLike: `%${searchVal.trim()}%`,
					},
				},
			],
		};

		const params = {
			fields: '["$all", {"addresses": ["$all"]}]',
			filter: JSON.stringify(filter),
		};
		try {
			const res = await userAPI.getList(params);
			if (searchVal.trim() === "") {
				setSearchsResults([]);
			} else {
				setSearchsResults(res.results.objects.rows);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};
	const resetUser = () => {
		setUserInfor({});
		setIsUser(false);
		setErrorMount(true);
	};
	useEffect(() => {
		if (debounceValue.trim() !== "") {
			fetchUserBySearch(debounceValue);
		} else {
			resetUser();
		}
	}, [debounceValue]);
	const { role } = useCustomAuth();

	return (
		<>
			<div>
				<Dialog maxWidth="xl" fullWidth open={isOpen} onClose={handleClose}>
					<DialogTitle
						sx={{
							fontSize: "18px",
							textAlign: "center",
						}}
						variant={"h3"}
					>
						{isUpdate ? t(translator("header_edit")) : t(translator("header_create"))}
					</DialogTitle>
					<form onSubmit={handleSubmit(onSubmit)}>
						<DialogContent>
							<Grid
								container
								spacing={1}
								alignItems={"center"}
								style={{
									marginBottom: 20,
								}}
							>
								{/* Đừng xóa những dòng comment này  */}
								{/* 
								<Grid xs={12} item sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Controller
											name="phone"
											control={control}
											rules={{
												required: true,
												minLength: 10,
												pattern: PATTERN.PHONE_NUMBER_VN,
											}}
											defaultValue={isUpdate ? formData?.phone : ""}
											as={
												<TextField
													sx={{ borderRadius: "none", flex: 3 }}
													margin="dense"
													size="small"
													label={t(translator("phone")) + " *"}
													type="text"
													fullWidth
													variant="filled"
													disabled={isUser || isUpdate}
												/>
											}
										/>
										{!isUser ? (
											<LoadingButton
												// endIcon={<SaveIcon />}
												sx={{
													height: "100%!important",
													padding: "11px!important",
													borderRadius: "0",
													marginTop: "4px",
													width: "20%!important",
													flex: 1,
													zIndex: 200,
												}}
												disabled={!isUpdate && (!watch("phone") || errors.phone)}
												loading={loading}
												loadingPosition="end"
												variant="contained"
												className={cx("button__action")}
												onClick={handleCheckIsUser}
											>
												Xác thực
											</LoadingButton>
										) : (
											<LoadingButton
												// endIcon={<SaveIcon />}
												sx={{
													height: "100%!important",
													padding: "11px!important",
													borderRadius: "0",
													marginTop: "4px",
													width: "20%!important",
													flex: 1,
													zIndex: 200,
												}}
												disabled={!watch("phone")}
												loading={loading}
												loadingPosition="end"
												variant="contained"
												className={cx("button__action")}
												onClick={() => {
													setIsUser(false);
													resetModal();
												}}
											>
												Thay đổi SĐT
											</LoadingButton>
										)}
									</Box>
								</Grid> */}

								{/* <Grid xs={3} item>
									<LoadingButton
										// endIcon={<SaveIcon />}
										sx={{
											display: "block",
											width: "100%",
											height: "100%!important",
										}}
										size="large"
										disabled={!isUser || comboServices.length === 0}
										loading={loading}
										loadingPosition="end"
										variant="outlined"
										onClick={handleOpenComboService}
									>
										Gói dịch vụ đã mua
									</LoadingButton>
								</Grid> */}

								{/* 
								Error text phone */}
								{/* <Grid xs={9} item>
									{errors.phone && errors.phone.type === "required" && (
										<TextValidation>{t(translator("required_phone"))}</TextValidation>
									)}
									{errors.phone && errors.phone.type === "minLength" && (
										<TextValidation>Số điện thoại có ít nhất 10 số</TextValidation>
									)}
									{errors.phone && errors.phone.type === "pattern" && (
										<TextValidation>Số điện thoại này không đúng định dạng</TextValidation>
									)}
									{errors.phone && errors.phone.type === "checkPhone" && (
										<TextValidation sx={{ color: errors.phone.isCanUse && "green" }}>
											{errors.phone.message}
										</TextValidation>
									)}
								</Grid> */}
								{/* <Grid xs={3} item>
									{comboServices.length === 0 && isUser && (
										<Typography variant="h5" sx={{ color: "goldenrod" }}>
											Người dùng chưa có gói dịch vụ nào
										</Typography>
									)}
								</Grid> */}
								<Grid item xs={12} sx={{ paddingBottom: 1 }}>
									{!isUser ? (
										<>
											<OrderSearchComponent
												ref={refSearchInput}
												isFocusOutSide={isFocusOutSide}
												searchValue={searchValue}
												handleChangeSearch={(value) => setSearchValue(value)}
												placeholderText={"Tìm theo Tên/SĐT khách hàng"}
												searchResult={searchResults}
												ResultComponent={CustomerItem}
												widthBoxSearch={"300px"}
												propsResultComponent={(item) => ({
													customer: item,
													onPressCustomer: () => {
														handleOnPressCustomer(item);
													},

													// isActive: infoCustomer.userId === item.id,
												})}
												styledSearchBox={{
													width: "100%",
												}}
												hasAdd={role?.CUSTOMER_CREATE === ROLE?.CUSTOMER_CREATE}
												onAdd={onAdd}
												loading={loading}
											/>
											{errorMount && <TextValidation>Thiếu thông tin khách hàng</TextValidation>}
										</>
									) : (
										<CustomerInfo
											styleBox={{ width: "100%" }}
											user={userInfor}
											setUserInfor={setUserInfor}
											resetUser={resetUser}
										/>
									)}
								</Grid>

								<Grid item xs={6}>
									<Controller
										name="date"
										control={control}
										defaultValue={isUpdate ? moment(parseInt(formData.time_start)) : moment()}
										rules={{
											required: "Vui lòng chọn ngày thực hiện",
											// validate: {
											// 	lessThan: (v) =>
											// 		moment(v).format("YYYY-MM-DD").valueOf() >=
											// 			moment().format("YYYY-MM-DD").valueOf() ||
											// 		"Bạn không thể đặt lịch trong quá khứ",
											// },
										}}
										onChange={(e) => {}}
										as={
											<DatePicker
												className={cx("booking_modal_timePicker")}
												placeholder="Ngày thực hiện"
												style={{
													width: "100%",
												}}
												format={"DD/MM/YYYY"}
												showNow
												locale={viVN}
												size={"large"}
											/>
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<Controller
										name="time"
										control={control}
										defaultValue={isUpdate && moment(parseInt(formData.time_start))}
										rules={{
											required: "Vui lòng chọn giờ thực hiện",
											validate: {
												minutes: (v) =>
													moment(v).minute() === 0 ||
													moment(v).minute() === 30 ||
													"Chỉ có thể chọn số phút là 00 hoặc 30",
												// lessThan: (v) => {
												// 	if (
												// 		moment(getValues("date")).format("YYYY-MM-DD").valueOf() ===
												// 		moment().format("YYYY-MM-DD").valueOf()
												// 	) {
												// 		return (
												// 			moment(v).format("HH:mm").valueOf() >
												// 				moment().format("HH:mm").valueOf() ||
												// 			"Bạn không thể đặt lịch trong quá khứ"
												// 		);
												// 	}
												// },
											},
										}}
										as={
											<TimePicker
												onSelect={(e) => {
													setValue("time", e);
													clearErrors("time");
												}}
												className={cx("booking_modal_timePicker")}
												placeholder="Giờ thực hiện"
												style={{
													width: "100%",
												}}
												format={"HH:mm"}
												locale={viVN}
												minuteStep={30}
												size={"large"}
											/>
										}
									/>
								</Grid>
								{/* 
								Error text date end time */}
								<Grid xs={6} item>
									{errors.date && <TextValidation>{errors.date.message}</TextValidation>}
								</Grid>
								<Grid xs={6} item>
									{errors.time && <TextValidation>{errors.time.message}</TextValidation>}
								</Grid>

								{/* <Grid item xs={12}>
								<Controller
									as={
										<TextField
											margin="dense"
											label={t(translator("email"))}
											type="text"
											fullWidth
											size="small"
											defaultValue={isUpdate ? formData?.email : ""}
											variant="filled"
										/>
									}
									name="email"
									control={control}
									// rules={{
									// 	required: true,
									// 	pattern:
									// 		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									// }}
								/>
								{/* {errors.email && errors.email.type === "required" && (
									<TextValidation>{t(translator("required_email"))}</TextValidation>
								)}
								{errors.email && errors.email.type === "pattern" && (
									<TextValidation>Email chưa đúng định dạng</TextValidation>
								)} 
							</Grid> */}

								<Grid item xs={12}>
									<Controller
										as={
											<TextField
												margin="dense"
												multiline
												label={t(translator("message"))}
												rows={2}
												type="text"
												fullWidth
												defaultValue={isUpdate ? formData?.message : ""}
												variant="filled"
											/>
										}
										defaultValue={isUpdate ? formData.message : ""}
										name="message"
										control={control}
									/>
								</Grid>
							</Grid>
							{/* {isUser && (
								<Grid item xs={12}>
									<Box>
										<UserInformationBooking userInfo={userInfor} setUserInfo={setUserInfor} />
									</Box>
								</Grid>
							)} */}
							<Divider />

							<DialogTitle
								sx={{
									fontSize: "16px",
								}}
								variant={"h4"}
							>
								Chọn dịch vụ
							</DialogTitle>
							<Grid item xs={12}>
								<Box
									sx={{
										padding: "10px",
									}}
								>
									<ServiceArea
										sx={{
											margin: "10px 0px",
										}}
										control={control}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sx={{ margin: "12px 0" }}>
								<DialogTitle
									sx={{
										fontSize: "16px",
									}}
									variant={"h4"}
								>
									Dịch vụ đã chọn
								</DialogTitle>

								{/* SELECTED AREA */}

								{errorServiceList.status ? (
									<NoData text={"Vui lòng chọn ít nhất một dịch vụ"} />
								) : (
									<SelectedService isUpdate={isUpdate} />
								)}
							</Grid>
							<Divider />
							{true ? (
								<>
									<Grid item xs={12}>
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<DialogTitle
												sx={{
													fontSize: "16px",
												}}
												variant={"h4"}
											>
												Tổng tiền thanh toán
											</DialogTitle>

											<DialogTitle
												sx={{
													fontSize: "16px",
												}}
												variant={"h4"}
											>
												{getNumberWithCommas(getTotalPrice())} đ
											</DialogTitle>
										</Box>
									</Grid>
								</>
							) : (
								<Grid item xs={12}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<DialogTitle
											variant={"h4"}
											sx={{
												fontSize: "16px",
											}}
										>
											{totalPrice < 0 ? "Trả lại khách:" : "Khách phải trả thêm: "}
										</DialogTitle>

										<DialogTitle
											sx={{
												fontSize: "16px",
												color: (totalPrice == 0 && "black") || totalPrice < 0 ? "red" : "green",
											}}
											variant={"h4"}
										>
											{getNumberWithCommas(Math.abs(totalPrice))} đ
										</DialogTitle>
									</Box>
								</Grid>
							)}

							<Grid item xs={12}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
										variant={"h4"}
									>
										Giảm giá
									</DialogTitle>

									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
										variant={"h4"}
									>
										<InputNumber
											className={cx("input__number")}
											min={0}
											max={getTotalPrice()}
											value={inputVal.discount}
											defaultValue={0}
											style={{
												width: "100%",
												textAlign: "right",
											}}
											addonAfter={"đ"}
											formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
											parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
											onChange={(e) => {
												handleOnChangePrice("discount", e);
												handleOnChangePrice("customerPay", getTotalPrice() - (e || 0));
											}}
											onClick={(e) => {
												setAnchorEl(e.currentTarget);
												e.currentTarget.blur();
											}}
											controls={false}
										/>
									</DialogTitle>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
										variant={"h4"}
									>
										Khách cần trả
									</DialogTitle>

									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
										variant={"h4"}
									>
										{formatVND(getTotalPrice() - (inputVal?.discount || 0))}
									</DialogTitle>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
										variant={"h4"}
									>
										Khách thanh toán
									</DialogTitle>

									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
										variant={"h4"}
									>
										<InputNumber
											className={cx("input__number")}
											value={inputVal.customerPay}
											onChange={(e) => handleOnChangePrice("customerPay", e)}
											min={getTotalPrice() - (inputVal?.discount || 0)}
											formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
											parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
											defaultValue={getTotalPrice()}
											style={{
												width: "100%",
												textAlign: "right",
											}}
											addonAfter={"đ"}
											controls={false}
										/>
									</DialogTitle>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
										variant={"h4"}
									>
										Tiền thừa trả khách
									</DialogTitle>

									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
										variant={"h4"}
									>
										{refund ? formatVND(refund) : 0}
									</DialogTitle>
								</Box>
							</Grid>

							<Divider />

							{userInfor && isUser && (
								<>
									<Grid item xs={12}>
										<UserWallet
											bookingType={bookingType}
											setBookingType={setBookingType}
											userInfor={userInfor}
											setUserInfor={setUserInfor}
											isCanBook={isCanBook}
										/>
									</Grid>
									<Divider />
								</>
							)}

							<DialogTitle
								sx={{
									fontSize: "16px",
								}}
								variant={"h4"}
							>
								Chọn nhân viên kỹ thuật
							</DialogTitle>
							<Grid item xs={12}>
								<Box
									sx={{
										padding: "10px",
									}}
								>
									<SelectedEmployee isUpdate={isUpdate} />
								</Box>
							</Grid>
							<Divider />

							<DialogTitle
								sx={{
									fontSize: "16px",
								}}
								variant={"h4"}
							>
								Chọn nhân viên tư vấn
							</DialogTitle>
							<Grid item xs={12}>
								<Box
									sx={{
										padding: "10px",
									}}
								>
									<EmployeeSale isUpdate={isUpdate} />
								</Box>
							</Grid>
							<Divider />

							<Grid item xs={12} sx={{ margin: "12px 0" }}>
								<DialogTitle
									sx={{
										fontSize: "16px",
									}}
									variant={"h4"}
								>
									Chọn giường
								</DialogTitle>
								<Controller
									as={
										<Select
											// labelId="demo-simple-select-autowidth-label"
											// id="form"
											// autoWidth
											label={" "}
											sx={{ margin: "24px", width: "30%" }}
										>
											{bedGroup.map((item) => (
												<MenuItem key={item.id} value={item.id}>
													{item.text}
												</MenuItem>
											))}
										</Select>
									}
									defaultValue={
										//Nếu update thì lấy data.slot_id -> slot_id = null thì lấy noneBed => Mục đích cuối cùng là chuyển formData.slot_id = null về noneBed để lấy giá trị chọn giường sau
										isUpdate ? (formData.slot_id ? formData.slot_id : "noneBed") : "noneBed"
									}
									name="slot_id"
									control={control}
								/>
							</Grid>
							<Divider />

							<Grid item xs={12} sx={{ margin: "12px 0" }}>
								<DialogTitle
									sx={{
										fontSize: "16px",
									}}
									variant={"h4"}
								>
									Chọn trạng thái lịch
								</DialogTitle>
								<Controller
									as={
										<Select
											// labelId="demo-simple-select-autowidth-label"
											// id="form"
											// autoWidth
											label={" "}
											sx={{ margin: "24px", width: "30%" }}
										>
											{activityList.map((item) => (
												<MenuItem
													key={item.activity}
													value={item.activity}
													sx={{
														display:
															(item.activity === "WAITING" ||
																item.activity === "PROCESSING" ||
																item.activity === "CANCELED") &&
															"none",
													}}
													disabled={item.activity === "ASSIGNED"}
												>
													{item.title}
												</MenuItem>
											))}
										</Select>
									}
									defaultValue={isUpdate ? formData.activity : "CONFIRMED"}
									name="activity"
									control={control}
								/>
							</Grid>
							<Divider />

							<Grid
								item
								xs={12}
								container
								spacing={1}
								style={{
									marginBottom: 20,
								}}
								className={cx(
									checkList.length === 0 && comboUsing.length !== 0 && "disabled__area"
								)}
							>
								<Grid item xs={12}>
									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
										variant={"h4"}
									>
										Phương thức thanh toán
									</DialogTitle>
								</Grid>
								<Grid item xs={12} sx={{ padding: "0 30px!important" }}>
									{checkList.length === 0 && comboUsing.length !== 0 && (
										<Typography sx={{ color: "goldenrod" }}>
											Bạn đang chọn các dịch vụ trong gói, không thể chọn phương thức thanh toán
										</Typography>
									)}
									<FormControl>
										<RadioGroup
											aria-labelledby="demo-controlled-radio-buttons-group"
											name="controlled-radio-buttons-group"
											value={bookingType}
											onChange={handleChangeBookingType}
											row
										>
											<FormControlLabel value="CASH" control={<Radio />} label="TIỀN MẶT" />

											<FormControlLabel value="TRANSFER" control={<Radio />} label="CHUYỂN KHOẢN" />
											<FormControlLabel value="CARD" control={<Radio />} label="QUẸT THẺ" />
											<FormControlLabel
												disabled={!isUser || !isCanBook}
												value="WALLET"
												control={<Radio />}
												label="HẠN MỨC DỊCH VỤ"
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
							</Grid>
							<Divider />
						</DialogContent>
						<DialogActions
							sx={{
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							{renderFooterAction()}
						</DialogActions>

						<Snackbar
							anchorOrigin={{ vertical: "top", horizontal: "center" }}
							open={showErr.state}
							onClose={() => setShowErr({ state: false, message: "" })}
							// action={action}
							autoHideDuration={5000}
							severity="error"
						>
							<Alert severity="error">{showErr.message}</Alert>
						</Snackbar>
					</form>
					<Close
						sx={{
							position: "absolute",
							top: "10px",
							right: "10px",
							cursor: "pointer",
						}}
						onClick={() => handleClose()}
					/>
				</Dialog>
			</div>
			<DialogMain
				maxWidth={"md"}
				title={"Combo dịch vụ đã mua"}
				fullWidth
				open={comboService.visible}
				onClose={() => {
					setComboService((prev) => ({
						...prev,
						visible: false,
					}));
				}}
			>
				<UserComboService
					onCloseModal={() => {
						setComboService((prev) => ({
							...prev,
							visible: false,
						}));
					}}
					isUpdate={isUpdate}
					userPhoneNum={userInfor?.phone}
				/>
			</DialogMain>
			<UserModal
				valuePhone={openUserModal.username}
				getListTable={() => {
					return;
				}}
				isLoading={() => {
					return;
				}}
				isOpen={openUserModal.open}
				isUpdate={false}
				onSuccessRegister={handleOnPressCustomer}
				isClose={(value) => {
					setOpenUserModal({
						open: value,
						username: "",
					});
					setIsFocusOutSide(true);
				}}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={closeDiscountPop}
				onKeyPress={(e) => {
					if (e.code === "Enter" || e.keyCode === 13 || e.charCode === 13) {
						e.preventDefault();
						e.stopPropagation();
						closeDiscountPop();
					}
				}}
				anchorOrigin={{
					vertical: -40,
					horizontal: "left",
				}}
			>
				<Box p={2}>
					<Typography variant="h5">Giảm giá</Typography>
					<DiscountByType
						openPopover={open}
						onPopoverHide={setDiscount}
						total={getTotalPrice()}
						defaultDiscount={inputVal.discount}
					/>
				</Box>
			</Popover>
		</>
	);
}

export default BookingModal;
