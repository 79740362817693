import { Add, Remove } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { InputNumber } from "antd";
import comboServiceAPI from "api/ComboServiceAPI";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actGetUserCombo, actSetUserCombo } from "store/actions/UserComboServiceAction";
import { v4 as uuidv4 } from "uuid";
function UserComboService({ userPhoneNum, onCloseModal }) {
	// const comboServices = useSelector((state) => state.userComboService.comboServices);
	const [comboServices, setComboServices] = useState([]);
	//Return initState change amount

	// const [amountState, setAmountState] = useState(comboServices);

	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const getUserComboService = async () => {
		setLoading(true);

		try {
			const res = await comboServiceAPI.getUserCombo(userPhoneNum);

			setComboServices(res.results.objects);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	useEffect(() => {
		getUserComboService();
	}, []);

	const [countState, setCountState] = useState({});

	const [selectedUserService, setSelectedUserService] = useState([]);
	const handleOnChange = (name, combo, combo_using) => (e) => {
		setCountState((prev) => ({
			...prev,
			[name]: e,
		}));
		const data = {
			id: combo_using.id,
			combo_title: combo.title,
			combo_id: combo.combo_service_id, //combo.id chỗ này là order id
			service_id: combo_using.service_id,
			service: combo_using.service,
			count: e,
		};
		if (selectedUserService.length === 0) {
			setSelectedUserService((prev) => [...prev, data]);
		} else {
			if (selectedUserService.some((item) => item?.id === combo_using.id)) {
				const temp = selectedUserService.map((item) => {
					if (item.id === combo_using.id) {
						return data;
					} else {
						return item;
					}
				});
				setSelectedUserService(temp);
			} else {
				setSelectedUserService((prev) => {
					return [...prev, { ...data }];
				});
			}
		}
	};

	const handleSubmit = () => {
		dispatch(actSetUserCombo(selectedUserService));
		onCloseModal();
	};
	return loading ? (
		<Backdrop sx={{ color: "#fff", zIndex: 10000 }} open={loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : (
		<Box>
			{comboServices.map((combo, idxCombo) => {
				return (
					<Box
						sx={{
							borderRadius: "4px",
							border: "1px solid black",
							marginBottom: "20px",
						}}
						key={combo.id}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "flex-start",
								background: "#311D4F",
								color: "#fff",
								padding: "10px",
							}}
						>
							<Typography
								variant="h4"
								sx={{
									flex: 2,
									color: "#fff",
								}}
							>
								{combo.combo?.title}
							</Typography>
							<Typography variant="h4" sx={{ flex: 1, color: "#fff", textAlign: "center" }}>
								Còn lại
							</Typography>
							<Typography variant="h4" sx={{ flex: 1, color: "#fff", textAlign: "center" }}>
								Chọn
							</Typography>
						</Box>
						{Array.isArray(combo.combo_using) &&
							combo?.combo_using?.map((combo_using, idxComboItem) => (
								<Box
									key={combo_using.id}
									sx={{
										display: "flex",
										flexDirection: "flex-start",
										alignItems: "center",
										background: "#fff",
										color: "#000",
										padding: "10px",
									}}
								>
									<Typography
										variant="h4"
										sx={{
											flex: 2,
											color: "#000",
										}}
									>
										{combo_using.service?.title}
									</Typography>
									<Typography variant="h4" sx={{ flex: 1, color: "#000", textAlign: "center" }}>
										{combo_using.available_times -
											(countState?.[`count-${idxCombo}-${idxComboItem}`] || 0)}
									</Typography>

									<Typography variant="h4" sx={{ flex: 1, color: "#000", textAlign: "center" }}>
										<InputNumber
											onChange={handleOnChange(
												`count-${idxCombo}-${idxComboItem}`,
												combo,
												combo_using
											)}
											defaultValue={0}
											min={0}
											max={combo_using.available_times}
										/>
									</Typography>
								</Box>
							))}
					</Box>
				);
			})}

			<Button
				variant="contained"
				sx={{
					marginLeft: "auto",
				}}
				onClick={handleSubmit}
			>
				Xác nhận
			</Button>
		</Box>
	);
}

export default UserComboService;
