import { Close } from "@mui/icons-material";
import {
	Box,
	Chip,
	Divider,
	IconButton,
	Input,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { InputNumber, Tag } from "antd";
import { formatVND } from "func";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { actSetCheckList, actUpdateValueCheckList } from "store/actions/BookingActions/action";
import InputNumberCustom from "ui-component/InputNumberCustom";

import classnames from "classnames/bind";
import styles from "../BookingModal.module.scss";
import { actDeleteEmployee, actUpdateValueEmployee } from "store/actions/EmployeeBooking";

const cx = classnames.bind(styles);

function EmployeeItem({ employee, isCombo, isUpdate = false }) {
	const dispatch = useDispatch();
	const handleDeleteEmployee = (employee) => {
		dispatch(actDeleteEmployee(employee));
	};
	const handleSetValue = (data, val, name) => {
		dispatch(actUpdateValueEmployee({ data, val, name }));
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				margin: "20px 0px",
			}}
		>
			<Typography
				sx={{
					flex: 2,
				}}
			>
				{employee.employee.fullname}
			</Typography>

			<InputNumberCustom
				val={employee.work}
				handleSetValue={(value) => handleSetValue(employee, value, "work")}
				defaultValue={employee.work}
				min="1"
				max="100000"
				step="0.5"
				styleItem={{
					flex: 1.5,
					width: "100%",
				}}
			/>
			<Box sx={{ flex: 0.5, textAlign: "center" }}>
				<IconButton sx={{ width: "fit-content" }} onClick={() => handleDeleteEmployee(employee)}>
					<Close />
				</IconButton>
			</Box>
		</Box>
	);
}

export default EmployeeItem;
