import { Expand, ExpandMoreOutlined } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Collapse,
	IconButton,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useForm } from "react-hook-form";

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));
function CardLayout({
	title,
	hasExpanded = false,
	defaultExpanded = true,
	defaultContent,
	mainContent,
}) {
	const [expanded, setExpanded] = React.useState(defaultExpanded);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	//Get render Content

	const DefaultContent = defaultContent?.Component;
	const MainContent = mainContent?.Component;

	return (
		<Card>
			<CardActions sx={{ p: 2 }}>
				<Typography variant="h5" component={"h1"}>
					{title}
				</Typography>

				{hasExpanded && (
					<ExpandMore
						expand={expanded}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreOutlined />
					</ExpandMore>
				)}
			</CardActions>
			<Box
				component={"div"}

				// onSubmit={handleSubmit(onSubmit)}
			>
				{defaultContent && (
					<CardContent sx={{ width: "100%", p: 2, py: 1 }}>
						<DefaultContent {...defaultContent?.props} />
					</CardContent>
				)}
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<CardContent sx={{ width: "100%", p: 2, py: 1 }}>
						<MainContent {...mainContent?.props} />
					</CardContent>
				</Collapse>
				{/* <Button type="submit">Submit</Button> */}
			</Box>
		</Card>
	);
}

export default CardLayout;
