export const bookingTree = [
	{
		title: "Lịch hẹn",
		key: "BOOKING",
		children: [
			{
				title: "Xem DS",
				key: "BOOKING_VIEW",
			},
			{
				title: "Thêm mới",
				key: "BOOKING_CREATE",
			},
			{
				title: "Cập nhật",
				key: "BOOKING_UPDATE",
			},
			{
				title: "Xóa",
				key: "BOOKING_DELETE",
			},
		],
	},
];
