export const NOTIFICATION_TYPE = [
	{
		value: "GENERAL",
	},
	{
		value: "SALE",
	},
];
export const TARGET_USER = [
	// {
	// 	value: "COLLAB",
	// },

	// {
	// 	value: "NORMAL",
	// },
	{
		value: "ALL",
	},
	{
		value: "CUSTOMER",
	},
	{
		value: "STAFF",
	},
];
