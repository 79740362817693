import productAPI from "api/ProductAPI";
import { Markup } from "interweave";
import { LoadingContext } from "providers/LoadingProvider";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { LocalFireDepartment } from "@mui/icons-material";
import { formatVND } from "func";
import { Typography } from "antd";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import MultipleImage from "components/MultipleImage";
import { Grid } from "@mui/material";
const fieldParams = ["$all", { category: ["id", "title"] }];

function ProductInfo({ productId }) {
	const [data, setData] = useState({});
	const [imageZoom, setImageZoom] = useState([]);
	const { loading, showLoading, hideLoading } = useContext(LoadingContext);
	const fetchProductById = async () => {
		showLoading();
		const payload = {
			fields: JSON.stringify(fieldParams),
		};
		try {
			const res = await productAPI.findOne(productId, payload);
			const data = res?.results?.object;
			setData(data);
			setImageZoom(data?.image);
			hideLoading();
		} catch (error) {
			console.log(error);
			hideLoading();
		}
	};

	useEffect(() => {
		fetchProductById();
	}, []);

	const dataParams = [
		{
			groupId: 1,
			groupTitle: "Thông tin sản phẩm",
			gridProps: {},
			children: [
				{
					id: "title",

					title: "Tên sản phẩm",
					value: data.title,
					component: Typography.Text,
					childrenProps: {
						//all Props component here
						span: 8,
					},
				},
				{
					id: "category_name",
					title: "Danh mục sản phẩm",
					value: data.category?.title,
					component: Typography.Text,
					childrenProps: {
						span: 8,
					},
				},

				{
					id: "hot",
					title: "Hot deal",
					value: "",
					component: data.best_sale && LocalFireDepartment,
					childrenProps: {
						span: 8,
						color: "error",
					},
				},
				{
					id: "price",
					title: "Giá gốc",
					value: formatVND(data.price),
					component: Typography.Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "discount",
					title: "Giá giảm",
					value: formatVND(data.sale_price),
					component: Typography.Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "real_price",
					title: "Giá cuối",
					value: formatVND(data.price - data.sale_price),
					component: Typography.Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "inventory_amount_branch",
					title: "Tồn kho",
					value: data?.inventory_amount_branch,
					component: Typography.Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "type",
					title: "Loại",
					value: data?.type === "PRODUCT" ? "Sản phẩm" : "Quà tặng",
					component: Typography.Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "barcode",
					title: "Mã vạch",
					value: data?.barcode,
					component: Typography.Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "created_at",
					title: "Thời gian tạo",
					value: moment(data?.created_at).format("DD/MM/YYYY HH:mm"),
					component: Typography.Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "updated_at",
					title: "Thời gian cập nhật",
					value: moment(data?.updated_at).format("DD/MM/YYYY HH:mm"),
					component: Typography.Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "description",
					title: "Mô tả sản phẩm",
					value: data.description || "Không có dữ liệu",
					component: Typography.Text,
					childrenProps: {
						span: 24,
					},
				},

				{
					id: "sub_description",
					title: "Chi tiết sản phẩm",
					value: "",
					component: Markup,
					childrenProps: {
						span: 24,
						content: data.sub_description || "Không có dữ liệu",
					},
				},
			],
		},
	];
	return loading ? (
		<></>
	) : (
		<>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<MultipleImage imageZoom={imageZoom} />
				</Grid>
				<Grid item xs={8}>
					<DetailInformation data={dataParams} />
				</Grid>
			</Grid>
		</>
	);
}

export default ProductInfo;
