import styled from "@emotion/styled";
import { Edit, Info } from "@mui/icons-material";
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { IconDownload } from "@tabler/icons";
import { Modal, Select as SelectAntd, Tooltip } from "antd";
import packAPI from "api/PackAPI";
import registerTestAPI from "api/RegisterTestAPI";
import { CustomNoRowsOverlay } from "components/noRows";
import { ROLE } from "constants/employeeRole";
import { saveAs } from "file-saver";
import { formatVND, statusTransactionTranslator, transactionTranslator } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { FacebookIcon, FacebookShareButton } from "react-share";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import TransactionStatusModal from "ui-component/modal-form/DepositWithdraw/StatusModal";
import { showSortStyle } from "views/utilities/CustomStyle";
import transactionAPI from "../../api/TransactionAPI";
import { SearchComponent } from "../../components/search";

const { Option } = SelectAntd;

const actionList = [
	// {
	// 	value: "preview_pack",
	// 	title: "Xem lại thẻ",
	// 	component: QrCode,
	// },
	{
		value: "update_status",
		title: "Chỉnh sửa trạng thái yêu cầu",
		component: Edit,
	},
];

const filterStatusList = [
	{
		value: "ALL",
		title: "Tất cả",
		color: "#000",
	},
	{
		value: "DONE",
		title: "Hoàn thành",
		color: "#00c853",
	},
	{
		value: "PENDING",
		title: "Chờ xử lý",
		color: "#ffc107",
	},
	{
		value: "CANCEL",
		title: "Hủy bỏ",
		color: "#f44336",
	},
];

const RechargeWithdraw = () => {
	const location = useLocation();
	const { role } = useCustomAuth();
	const [openInfo, setOpenInfo] = useState(false);
	let filterTypeList = [
		// {
		// 	value: "ALL",
		// 	title: "Tất cả",
		// },
		{
			value: "REQUEST_DEPOSIT",
			title: transactionTranslator("REQUEST_DEPOSIT"),
			visible: role?.RECHARGE === ROLE?.RECHARGE,
		},
		{
			value: "REQUEST_WITHDRAW",
			title: transactionTranslator("REQUEST_WITHDRAW"),
			visible: role?.WITHDRAW === ROLE?.WITHDRAW,
		},
	];

	const [filterType, setFilterType] = useState(
		(location.state?.fromDashboard && location.state?.type) || filterTypeList[0]?.value
	);
	const [filterStatus, setFilterStatus] = useState(
		(location.state?.fromDashboard && location.state?.status) || filterStatusList[0].value
	);

	useLayoutEffect(() => {
		if (!role) return;
		if (role?.RECHARGE === ROLE?.RECHARGE && role?.WITHDRAW === ROLE?.WITHDRAW) {
			return;
		} else if (role?.RECHARGE !== ROLE?.RECHARGE && role?.WITHDRAW !== ROLE?.WITHDRAW) {
			navigate("/not-permission", { replace: true });

			return;
		} else {
			if (role?.RECHARGE === ROLE?.RECHARGE) {
				setFilterType("REQUEST_DEPOSIT");
			} else if (role?.WITHDRAW === ROLE?.WITHDRAW) {
				setFilterType("REQUEST_WITHDRAW");
			}
		}
	}, [role]);

	const searchList = [
		// {
		// 	value: "username",
		// 	title: "username",
		// },
		{
			value: "$user.phone$",
			title: "SĐT",
		},
		// {
		// 	value: "$user.name_admin_note$",
		// 	title: "Tên thân mật",
		// },
		{
			value: "bank_number",
			title: "Số tài khoản ngân hàng",
			isHide: filterType !== "REQUEST_WITHDRAW",
		},
		{
			value: "bank_type",
			title: "Ngân hàng",
			isHide: filterType !== "REQUEST_WITHDRAW",
		},
		// {
		// 	value: "login_type",
		// 	title: "login_type",
		// },
	];
	const { enqueueSnackbar } = useSnackbar();
	const modalContext = useContext(ModalContext);

	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState([]);

	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const [statusModal, setStatusModal] = useState(false);
	const [data, setData] = useState("");

	const columns = [
		{
			field: "order",
			headerName: t(`order`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
		},

		{
			field: "phone",
			headerName: "SĐT",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.user?.phone}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							className={params?.row?.user?.deleted_at && "line__though"}
						>
							{params?.row?.user?.phone}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee",
			headerName: "Nhân viên gửi yêu cầu",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.employee?.fullname || params?.row?.employee?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							className={params?.row?.employee?.deleted_at && "line__though"}
						>
							{params?.row?.employee?.fullname || params?.row?.employee?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee_confirm",
			headerName: "Nhân viên xác nhận",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={
							params?.row?.employee_confirm?.fullname || params?.row?.employee_confirm?.username
						}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							className={params?.row?.employee_confirm?.deleted_at && "line__though"}
						>
							{params?.row?.employee_confirm?.fullname || params?.row?.employee_confirm?.username}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "transaction_code",
			headerName: "Mã định danh",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			hide: filterType !== "REQUEST_WITHDRAW",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "bank_type",
		// 	headerName: "Ngân hàng",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// 	hide: filterType !== "REQUEST_WITHDRAW",
		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={params?.value}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.value}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		// {
		// 	field: "bank_number",
		// 	headerName: "Số tài khoản ngân hàng",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	hide: filterType !== "REQUEST_WITHDRAW",
		// 	flex: 1,
		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={params?.value}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.value}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		// {
		// 	field: "bank_name",
		// 	headerName: "Tên chủ tài khoản ngân hàng",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	hide: filterType !== "REQUEST_WITHDRAW",

		// 	flex: 1,

		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={params?.value}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.value}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },

		{
			field: "original_value",
			headerName: "Giá trị giao dịch",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",

			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "endow_value",
			headerName: "Giá trị ưu đãi",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			hide: filterType !== "REQUEST_DEPOSIT",

			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={!!params.value && `${params.value}%`}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{!!params.value && `${params.value}%`}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "change_amount",
			headerName: filterType !== "REQUEST_DEPOSIT" ? "Số tiền cần chuyển" : "Giá trị nhận được",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			// hide: filterType !== "REQUEST_DEPOSIT",

			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},

		// {
		// 	field: "note_admin",
		// 	headerName: "Nội dung",
		// 	flex: 1,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	hide: filterType !== "REQUEST_DEPOSIT",

		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={params?.value}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.value}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		// {
		// 	field: "note",
		// 	headerName: "Ghi chú",
		// 	flex: 1,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	hide: filterType !== "REQUEST_DEPOSIT",

		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={params?.value}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.value}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },

		{
			field: "status_enum",
			headerName: "Tình trạng",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				const { title, color } = statusTransactionTranslator(params.value);
				return (
					<Tooltip
						title={title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								color: color,
								fontWeight: 700,
							}}
						>
							{title}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "created_at",
			headerName: "Thời gian tạo",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "updated_at",
		// 	headerName: "Ngày cập nhật",
		// 	flex: 1,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",

		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={moment(params.value, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm")}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{moment(params.value, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm")}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		// {
		// 	field: "is_checked",
		// 	headerName: "Tình trạng tư vấn",
		// 	flex: 0.5,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	renderCell: (params) => {
		// 		// params.value = false;

		// 		return (
		// 			<Tooltip
		// 				title={params.value ? "Đã tư vấn" : "Chưa tư vấn"}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<FormControlLabel
		// 					control={
		// 						<IOSSwitch
		// 							sx={{ ml: 4 }}
		// 							checked={params?.value}
		// 							onChange={(e) => handleChangeAccept(e, params?.row)}
		// 						/>
		// 					}
		// 					label=""
		// 				/>
		// 			</Tooltip>
		// 		);
		// 	},
		// },

		// {
		// 	field: "login_type",
		// 	headerName: "Nền tảng đăng nhập",
		// 	minWidth: 250,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		// {
		// 	field: "created_at",
		// 	headerName: t(`created_at`),
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// },
		{
			field: "actions",
			headerName: t(`actions`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<>
						{actionList?.map((item) => {
							const Icon = item.component;

							return (
								<>
									<Tooltip
										title={params.row?.status_enum !== "DONE" ? item.title : "Chỉnh sửa ghi chú"}
									>
										<IconButton
											// disabled={
											// 	(item.value === "update_status" && params.row.status_enum !== "PENDING") ||
											// 	(item.value === "preview_pack" && params.row.status_enum !== "DONE")
											// }
											onClick={() => onChangeAction(item.value, params.row)}
											sx={{
												display:
													item.value === "preview_pack" &&
													filterType === "REQUEST_WITHDRAW" &&
													"none",
											}}
										>
											<Icon />
										</IconButton>
									</Tooltip>

									<Tooltip title={"Chi tiết"}>
										<IconButton
											// disabled={
											// 	(item.value === "update_status" && params.row.status_enum !== "PENDING") ||
											// 	(item.value === "preview_pack" && params.row.status_enum !== "DONE")
											// }
											onClick={() =>
												navigate(`/recharge-withdraw-detail/${params.row?.id}`, {
													state: {
														type: params.row?.type,
													},
												})
											}
											sx={{
												display:
													item.value === "preview_pack" &&
													filterType === "REQUEST_WITHDRAW" &&
													"none",
											}}
										>
											<Info />
										</IconButton>
									</Tooltip>
								</>
							);
						})}
					</>
				);
			},
		},
	];
	console.log(location);
	const handleChangeAccept = async (e, params) => {
		let checked = e.target.checked;
		try {
			await registerTestAPI.update(params?.id, {
				is_checked: checked,
			});
			getListRegisterTest();
		} catch (error) {}
	};
	const getListRegisterTest = async () => {
		setIsLoading(true);

		let filter = {
			$or: [
				filterType === "REQUEST_DEPOSIT" ? { type: "REQUEST_DEPOSIT" } : undefined,
				filterType === "REQUEST_DEPOSIT" ? { type: "REQUEST_DEPOSIT_FOR_FIRST_TIME" } : undefined,
				filterType === "REQUEST_DEPOSIT" ? { type: "DEPOSIT_ROSES" } : undefined,
				filterType === "REQUEST_WITHDRAW" ? { type: "REQUEST_WITHDRAW" } : undefined,
			].filter((item) => {
				return !!item !== false;
			}),
			[searchBy]:
				searchValue.trim().length === 0 ? undefined : { $iLike: `%${searchValue.trim()}%` },
			status_enum: filterStatus === "ALL" ? undefined : filterStatus,
			created_at_unix_timestamp: location.state?.filterDate
				? {
						$between: [
							moment(location.state?.filterDate[0], "DD/MM/YYYY").startOf("day").valueOf(),
							moment(location.state?.filterDate[1], "DD/MM/YYYY").endOf("day").valueOf(),
						],
				  }
				: undefined,
		};

		// filter.$or = filter?.$or?.;

		let order = '[["created_at", "desc"]]';

		if (queryParams?.sortBy) {
			order = `[["${queryParams?.sortBy}", "${queryParams?.sort}"]]`;
		}
		try {
			const res = await transactionAPI.getList({
				fields:
					'["$all", "$paranoid" ,{"user":["name_admin_note", "phone", "is_collab", "amount_of_buy_card", "deleted_at" ,"$paranoid"]}, {"employee_confirm": ["fullname","username", "deleted_at", "$paranoid"]},{"employee": ["fullname","username","deleted_at", "$paranoid"]}]',
				order,
				filter: JSON.stringify(filter),
				limit: pagination.limit,
				page: queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	const handleDelete = async (id) => {
		try {
			await registerTestAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			await getListRegisterTest();
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenUpdateStatus = (value) => {
		setData(value);
		setStatusModal(true);
	};

	const onChangeAction = (value, data) => {
		value === "edit" && navigate(`/users/edit/${data.id}`);
		value === "delete" && handleOpenRemoveModal(data);
		value === "update_status" && handleOpenUpdateStatus(data);
		value === "preview_pack" && handleOpenShare(data.pack_id);
	};

	const handleRemoveMulti = () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await registerTestAPI.removeMulti({
						items: JSON.stringify(selectList),
					});
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListRegisterTest();
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};
	const PreviewImg = styled("img")({
		width: "100%",
		borderRadius: "8px",
		maxWidth: "500px",
		padding: "20px",
	});

	useLayoutEffect(() => {
		if (!role) return;
		if (queryParams.hasOwnProperty(searchBy)) {
			setSearchBy(searchBy);
			setSearchValue(searchValue);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}

		getListRegisterTest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, filterType, filterStatus, role, location.state]);

	const [shareModal, setShareModal] = useState({
		open: false,
		image: "",
	});

	const handleCloseModal = () => {
		setShareModal({
			open: false,
			image: "",
		});
	};

	const handleOpenShare = async (packId) => {
		try {
			const res = await packAPI.findOne(packId, { fields: '["$all"]' });
			console.log(res);
			setShareModal({
				open: true,
				image: res.results.object?.qr_code_link,
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleSave = (image) => {
		saveAs(image, "qrCodeImage.png");
	};
	const mountRef = useRef(true);

	useEffect(() => {
		//Add zalo Script when open modal

		if (mountRef.current) {
			mountRef.current = false;
			return;
		}
		const script = document.createElement("script");

		script.src = "https://sp.zalo.me/plugins/sdk.js";

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [shareModal.open]);

	return (
		<>
			<MainCard>
				{location?.state?.fromDashboard && (
					<Button
						variant="contained"
						onClick={() => {
							navigate(location.pathname, { replace: true });

							// window.history.replaceState({}, document.title);
						}}
					>
						Hủy lọc
					</Button>
				)}
				<Stack flexDirection={"row"} alignItems="center">
					<HeaderActions title={"Danh sách yêu cầu"} onCreate={false} />
					<Box mt={0.5} ml={1}>
						<Tooltip title={"Xem thông tin giảm"}>
							<Info sx={{ cursor: "pointer" }} color="primary" onClick={() => setOpenInfo(true)} />
						</Tooltip>
					</Box>
				</Stack>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Box display={"flex"} alignItems="center" gap={2}>
						<SearchComponent
							isDisable={true}
							searchBy={searchBy}
							searchList={searchList}
							searchValue={searchValue}
							handleSearchBy={handleSearchBy}
							handleChangeSearch={handleChangeSearch}
						/>
						{/* <Box mb={2}>
							<Tooltip title={"Xem thông tin giảm"}>
								<Info
									sx={{ cursor: "pointer" }}
									color="primary"
									onClick={() => setOpenInfo(true)}
								/>
							</Tooltip>
						</Box> */}
					</Box>

					<Box>
						<FormControl sx={{ minWidth: 150, ml: 2, mb: 2 }}>
							<InputLabel id="demo-simple-select-outlined-label">{"Yêu cầu"}</InputLabel>
							<Select
								defaultValue={filterTypeList[0].value}
								onChange={(e) => {
									setFilterType(e.target.value);
									handleChangePage(null, 1);
								}}
								labelId="demo-simple-select-outlined-label"
								label={"Yêu cầu"}
								value={filterType}
							>
								{filterTypeList?.map(
									(item) =>
										item.visible && (
											<MenuItem key={item.value} value={item.value}>
												{item.title}
											</MenuItem>
										)
								)}
							</Select>
						</FormControl>
						<FormControl sx={{ minWidth: 150, ml: 2 }}>
							<InputLabel id="demo-simple-select-outlined-label">{"Tình trạng"}</InputLabel>
							<Select
								defaultValue={filterStatusList[0].value}
								onChange={(e) => {
									setFilterStatus(e.target.value);
									handleChangePage(null, 1);
								}}
								labelId="demo-simple-select-outlined-label"
								label={"Tình trạng"}
								value={filterStatus}
							>
								{filterStatusList?.map((item) => (
									<MenuItem
										key={item.value}
										value={item.value}
										sx={{ color: statusTransactionTranslator(item.value)?.color, fontWeight: 700 }}
									>
										{statusTransactionTranslator(item.value)?.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>

					{/* <Button
						variant={selectList.length > 0 ? "contained" : "outlined"}
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							// checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
			<TransactionStatusModal
				getListTable={getListRegisterTest}
				data={data}
				open={statusModal}
				setOpen={(value) => setStatusModal(value)}
				handleOpenShare={handleOpenShare}
				filterType={filterType}
			/>

			<Modal
				centered
				visible={shareModal.open}
				onCancel={handleCloseModal}
				footer={null}
				zIndex="10000"
			>
				<Typography id="modal-modal-title" variant="h3" component="h1">
					Chia sẻ
				</Typography>
				<Box
					sx={{
						"& .MuiTextField-root": {
							mt: 3,
						},
					}}
				>
					<Box
						sx={{
							textAlign: "center",
						}}
					>
						<PreviewImg src={shareModal.image} alt="QR Code" />
						{/* <RemoveImg onClick={() => setImageZoom("")}>{t(`remove`)}</RemoveImg> */}
					</Box>
				</Box>
				<Box
					sx={{
						"& .MuiTextField-root": {
							mt: 3,
						},
						mb: 3,
						display: "flex",
						justifyContent: "center",
						gap: "20px",
						alignItems: "center",
					}}
				>
					<div
						class="zalo-share-button"
						data-href={shareModal.image}
						data-oaid="579745863508352884"
						data-layout="4"
						data-color="blue"
						data-share-type="2"
						data-customize="true"
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								background: "#03a5fa",
								borderRadius: "3px",
								width: "50px",
								height: "50px",
								cursor: "pointer",
							}}
						>
							<img
								style={{
									width: "30px",
									height: "30px",
								}}
								src="https://stc.sp.zdn.vn/share/logo_white_s_2x_40.png"
								alt="zaloImg"
							/>
						</Box>
					</div>

					<FacebookShareButton url={shareModal.image} className="Demo__some-network__share-button">
						<FacebookIcon
							size={52}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								background: "#03a5fa",
								borderRadius: "3px",
								width: "50px",
								height: "50px",
							}}
						/>
					</FacebookShareButton>
					<Button
						style={{
							cursor: "pointer",
							color: "black",
						}}
						onClick={() =>
							// saveImg(shareModal.image)
							handleSave(shareModal.image)
						}
					>
						<IconDownload size={50} />
					</Button>
				</Box>
			</Modal>

			<Modal
				centered
				visible={openInfo}
				onCancel={() => setOpenInfo(false)}
				footer={null}
				zIndex="10000"
				title={<Typography variant="h4">Thông tin giảm:</Typography>}
			>
				<Box>
					<Typography variant="h4">Yêu cầu nạp tiền:</Typography>
					<ul>
						<li>Giảm 5% đối với CTV</li>
						<li>Giảm 2% đối với mua thẻ qua link share lần đầu</li>
						<li>Giảm 1% đối với mua thẻ qua link share từ lần thứ 2</li>
					</ul>

					<Typography variant="h4">Yêu cầu nạp tiền lần đầu không giảm</Typography>
				</Box>
			</Modal>
		</>
	);
};

export default RechargeWithdraw;
