// assets
import {
	IconNotification,
	IconSettings,
	IconLetterCase,
	IconUser,
	IconBusinessplan,
	IconHistory,
	IconUserCheck,
} from "@tabler/icons";
import { GroupOutlined } from "@mui/icons-material";
import { storeRedux } from "store";
import { ROLE } from "constants/employeeRole";

// constant
const icons = {
	IconHistory,
	IconBusinessplan,
	IconUser,
	IconNotification,
	IconSettings,
	IconLetterCase,
	GroupOutlined,
	IconUserCheck,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const partner = (waitingRequest, role) => ({
	id: "partner",
	title: "Đối tác",
	type: "group",
	visible:
		role?.CUSTOMER_VIEW === ROLE.CUSTOMER_VIEW ||
		role?.CONFIRM_CUSTOMER_VIEW === ROLE.CONFIRM_CUSTOMER_VIEW ||
		role?.CONFIRM_CHANGE_WALLET_VIEW === ROLE.CONFIRM_CHANGE_WALLET_VIEW,
	children: [
		{
			id: "Users",
			title: "user",
			type: "item",
			url: "users",
			icon: icons.IconUser,
			breadcrumbs: false,
			badge: false,
			visible: role?.CUSTOMER_VIEW === ROLE.CUSTOMER_VIEW,
		},
		{
			id: "UserVerify",
			title: "userVerify",
			type: "item",
			url: "user-verify",
			icon: icons.IconUserCheck,
			breadcrumbs: false,
			badge: true,
			count: waitingRequest?.count_collab,
			visible: role?.CONFIRM_CUSTOMER_VIEW === ROLE.CONFIRM_CUSTOMER_VIEW,
		},
		{
			id: "ChangeWallet",
			title: "change_wallet",
			type: "item",
			url: "confirm-change-wallet",
			icon: icons.IconBusinessplan,
			breadcrumbs: false,
			badge: true,
			count: waitingRequest?.count_confirm_edit_wallet,
			visible: role?.CONFIRM_CHANGE_WALLET_VIEW === ROLE.CONFIRM_CHANGE_WALLET_VIEW,
		},
	],
});

export default partner;
