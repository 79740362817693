import InvoicesBookingPage from "views/pages/InvoicesBookingPage";
import InvoicesDepositPage from "views/pages/InvoicesDepositPage";
import InvoicesInternalPage from "views/pages/InvoicesInternalPage";
import InvoicesPage from "views/pages/InvoicesPage";
import InvoicesQrCardPage from "views/pages/InvoicesQrCardPage";

export const FilterRoutes = {
	children: [
		{
			path: "/order",
			element: <InvoicesPage type={"PRODUCT"} />,
		},
		{
			path: "/internal-order",
			element: <InvoicesInternalPage type={"INTERNAL"} />,
		},
		{
			path: "/booking",
			element: <InvoicesBookingPage />,
		},
		{
			path: "/qr-code",
			element: <InvoicesQrCardPage />,
		},
		{
			path: "/deposit-request",
			element: <InvoicesDepositPage />,
		},
	],
};
