import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import bookingAPI from "api/BookingAPI";
import { BOOKING_ACTIVITY } from "constants/common";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";

function BookingActivityModal({ open, setOpen, data, getListTable }) {
	const handleClose = () => {
		setOpen(false);
	};
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [activity, setActivity] = useState(data.activity || "PROCESSING");
	const [noteAdmin, setNoteAdmin] = useState();
	const bodyRequest = useMemo(
		() => ({
			activity,
			reason: noteAdmin,
		}),
		[activity, noteAdmin]
	);

	const handleSetActivity = (event) => {
		setActivity(event.target.value);
		if (event.target.value !== "CANCELED") {
			setNoteAdmin("");
		}
	};
	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const handleSubmit = async () => {
		setLoading(true);

		try {
			if (bodyRequest.activity === "CANCELED") {
				const res = await bookingAPI.cancelBooking(data.id, bodyRequest);
			} else {
				const res = await bookingAPI.update(data.id, bodyRequest);
			}

			setLoading(false);
			await getListTable();

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			dispatch(actGetWaitingRequest(branchActiveId));

			handleClose();
		} catch (err) {
			enqueueSnackbar(err?.message || t("error"), {
				variant: "error",
				autoHideDuration: 2000,
			});
			await getListTable();

			setLoading(false);
			handleClose();
		}
	};
	useEffect(() => {
		if (open) {
			if (data.activity === "WAITING") {
				setActivity("PROCESSING");
			} else {
				setActivity(data.activity);
			}
		}
		return () => setNoteAdmin("");
	}, [open]);
	// @ts-expect-error autofill of arbitrary value is not handled.
	const { CONFIRMED, ASSIGNED, PROCESSING, CANCELED, COMPLETED } = BOOKING_ACTIVITY;
	return (
		<React.Fragment>
			<Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
				<DialogTitle sx={{ fontSize: "18px" }}>Chỉnh sửa trạng thái lịch hẹn</DialogTitle>
				<DialogContent>
					<Box
						noValidate
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
							m: "auto",
						}}
					>
						<FormControl sx={{ mt: 2 }}>
							<InputLabel htmlFor="activity">Trạng thái lịch hẹn</InputLabel>
							<Select
								fullWidth
								autoFocus
								defaultValue={data.activity}
								value={activity}
								onChange={handleSetActivity}
								label="Trạng thái lịch hẹn"
								inputProps={{
									name: "activity",
									id: "activity",
								}}
							>
								<MenuItem value="PROCESSING">{PROCESSING}</MenuItem>

								<MenuItem value="CONFIRMED">{CONFIRMED}</MenuItem>
								{/* <MenuItem value="ASSIGNED" disabled>
									{ASSIGNED}
								</MenuItem> */}

								<MenuItem value="COMPLETED">{COMPLETED}</MenuItem>
								<MenuItem value="CANCELED">{CANCELED}</MenuItem>
							</Select>
						</FormControl>
					</Box>

					{activity === "CANCELED" && (
						<Box
							noValidate
							component="form"
							sx={{
								display: "flex",
								flexDirection: "column",
								m: "auto",
							}}
						>
							<FormControl sx={{ mt: 2 }}>
								<TextField
									margin="dense"
									name="note_admin"
									label={"Lý do hủy"}
									type="text"
									fullWidth
									multiline
									minRows={4}
									value={noteAdmin}
									onChange={(e) => setNoteAdmin(e.target.value)}
								/>
							</FormControl>
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<LoadingButton
						onClick={() => handleSubmit()}
						// endIcon={<SaveIcon />}
						loading={loading}
						loadingPosition="end"
						variant="contained"
					>
						Chỉnh sửa
					</LoadingButton>
				</DialogActions>

				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</React.Fragment>
	);
}

export default BookingActivityModal;
