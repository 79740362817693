import { Typography } from "@mui/material";
import inventoryAPI from "api/InventoryAPI";
import { setDate } from "date-fns";
import { LoadingContext } from "providers/LoadingProvider";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import moment from "moment";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import MainCard from "ui-component/cards/MainCard";
function CheckInventoryDetail() {
	const { checkId } = useParams();
	const [data, setData] = useState({});
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const fetchCheckInventoryById = async () => {
		showLoading();
		const fields = [
			"$all",
			{ product: ["inventory_amount", "title", "barcode"] },
			{ inventory: ["$all", { branch: ["name"] }] },
		];
		const payload = {
			fields: JSON.stringify(fields),
			// filter: JSON.stringify(filterA),
		};
		try {
			const res = await inventoryAPI.getCheckId(checkId, payload);
			console.log(res);
			setData(res?.results?.object);
			hideLoading();
		} catch (error) {
			console.log(error);
			hideLoading();
		}
	};

	useEffect(() => {
		fetchCheckInventoryById();
	}, []);

	const dataParams = [
		{
			groupId: 1,
			groupTitle: "Thông tin kiểm",
			gridProps: {},
			children: [
				{
					id: "code",

					title: "Mã kiểm kho",
					value: data.code,
					component: Typography.Text,
					childrenProps: {
						//all Props component here
						span: 12,
					},
				},
				{
					id: "status",
					title: "Trạng thái",
					value: data.status && "Đã cân bằng kho",
					component: Typography.Text,
					childrenProps: {
						span: 12,
					},
				},

				{
					id: "created_at",
					title: "Thời gian",
					value: moment(Number(data?.created_at_unix_timestamp)).format("DD/MM/YYYY HH:mm"),
					childrenProps: {
						span: 12,
					},
				},
				{
					id: "bracnh",
					title: "Chi nhánh",
					value: data?.inventory?.branch?.name,
					component: Typography.Text,
					childrenProps: {
						span: 12,
					},
				},
				{
					id: "updated_at",
					title: "Ngày cân bằng",
					value: moment(data?.updated_at).format("DD/MM/YYYY HH:mm"),

					childrenProps: {
						span: 12,
					},
				},
			],
		},
		{
			groupId: 2,
			groupTitle: "Sản phẩm cân bằng",
			gridProps: {},
			children: [
				{
					id: "barcode",

					title: "Mã vạch hàng hóa",
					value: data?.product?.barcode,
					component: Typography.Text,
					childrenProps: {
						//all Props component here
						span: 12,
					},
				},
				{
					id: "name",
					title: "Tên hàng",
					value: data?.product?.title,
					component: Typography.Text,
					childrenProps: {
						span: 12,
					},
				},

				{
					id: "inventory_amount",
					title: "Tồn kho",
					value: data?.inventory?.inventory_number,
					childrenProps: {
						span: 12,
					},
				},
			],
		},
	];

	return (
		<MainCard>
			<DetailInformation data={dataParams} />
		</MainCard>
	);
}

export default CheckInventoryDetail;
