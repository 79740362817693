const {
	SET_CHECK_LIST,
	RELOAD_BOOKING_PAGE,
	SET_BOOKING_DETAILS,
	GET_EMPLOYEE_INFO,
	SET_ASSIGN_DATA,
	UPDATE_CHECK_LIST,
	UPDATE_COUNT_CHECK_LIST,
	UPDATE_NOTE_ADMIN_CHECK_LIST,
	UPDATE_VALUE_CHECK_LIST,
} = require("./types");

export const actSetCheckList = ({ checked, service }) => ({
	payload: { checked, service },
	type: SET_CHECK_LIST,
});

export const actUpdateCheckList = (data) => ({
	payload: data,
	type: UPDATE_CHECK_LIST,
});

export const actUpdateValueCheckList = ({ data, val, name }) => ({
	payload: { data, val, name },
	type: UPDATE_VALUE_CHECK_LIST,
});

export const actSetBookingDetails = (data) => ({
	payload: data,
	type: SET_BOOKING_DETAILS,
});
export const actReloadBookingPage = () => ({
	type: RELOAD_BOOKING_PAGE,
});

export const actGetEmployeeInfo = (data) => ({
	type: GET_EMPLOYEE_INFO,
	payload: data,
});

export const actSetAssignData = (data) => ({
	type: SET_ASSIGN_DATA,
	payload: data,
});
