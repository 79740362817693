import {
	Paper,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CartItem from "./CartItem";
import { useTheme } from "@mui/material/styles";
import { COLOR } from "constants/common";

function Cart({ orderList }) {
	console.log(orderList);
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.common.white,
		},
	}));

	const theme = useTheme();

	return (
		<TableContainer sx={{ border: "1px solid black", borderRadius: 0 }} component={Paper}>
			<Table aria-label="customized table">
				<TableHead
					sx={{
						".MuiTableHead-root": {
							backgroundColor: COLOR.MAIN_COLOR,
						},
					}}
				>
					<TableRow sx={{ backgroundColor: COLOR.MAIN_COLOR }}>
						<StyledTableCell></StyledTableCell>

						<StyledTableCell>STT</StyledTableCell>

						<StyledTableCell>Tên sản phẩm</StyledTableCell>
						<StyledTableCell align="center">Số lượng</StyledTableCell>
						<StyledTableCell align="center">Giá sản phẩm</StyledTableCell>
						<StyledTableCell align="center">Thành tiền</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{orderList.map((row, idx) => (
						<CartItem key={row.id} row={row} idx={idx} StyledTableCell={StyledTableCell} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default Cart;
