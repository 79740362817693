import axiosClient from "api/axiosClient";

const URL = `/order`;

const orderAPI = {
	async getList(params) {
		const url = `${URL}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async findOne(id, params) {
		const url = `${URL}/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async create(data) {
		const url = `${URL}`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async update(id, data) {
		const url = `${URL}/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async remove(id) {
		const url = `${URL}/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},

	async removeMulti(params) {
		const url = `${URL}`;
		const resp = await axiosClient.delete(url, { params });
		return resp;
	},
	async cancelOrder(id, data) {
		const url = `${URL}/${id}/cancel`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},
	async assignBooking(bookingId, data) {
		const url = `${URL}/${bookingId}/assign_employee`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},
};

export default orderAPI;
