import DateRangePickerField from "components/modules/form/DateField/DateRangePickerField";
import React from "react";

function DateFilter({ name, control, defaultDate, inputProps }) {
	return (
		<>
			<DateRangePickerField
				name={name}
				control={control}
				defaultDate={defaultDate}
				{...inputProps}
			/>
		</>
	);
}

export default DateFilter;
