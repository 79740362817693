import { Close, Title } from "@mui/icons-material";
import {
	Alert,
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Snackbar,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames/bind";
import styles from "./BookingDetail.module.scss";
import ServiceItem from "./ServiceItem";
import AssignBookingModal from "../AssignBookingModal";
import { useDispatch, useSelector } from "react-redux";
import AssignSingle from "../AssignBookingModal/AsssignSingle";
import { actReloadBookingPage, actSetAssignData } from "store/actions/BookingActions/action";
import bookingAPI from "api/BookingAPI";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

const cx = classnames.bind(styles);
function BookingDetailModal({ isOpen, isClose }) {
	const handleClose = () => {
		isClose(false);
	};
	const { t } = useTranslation();

	const serviceList = useSelector((state) => state.booking.bookingDetails);
	const data = useSelector((state) => state.booking.assignData);

	const booking_id = serviceList[0]?.booking_id;

	const bodyData = useMemo(
		() => ({
			action: "ASSIGN",
			data: data,
		}),
		[data]
	);

	///SUBMIT
	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmitAssign = async () => {
		if (bodyData.data.length < serviceList.length) {
			//ERROR
			enqueueSnackbar("Bạn chưa giao việc xong, vui lòng kiểm tra lại", {
				variant: "error",
				autoHideDuration: 2000,
			});
		} else {
			//SUBMIT CALL API

			setLoading(true);
			//isUpdate true => action = "CHANGE" else "ASSIGN"
			try {
				const res = await bookingAPI.assignBooking(booking_id, bodyData);

				dispatch(actReloadBookingPage());

				setLoading(false);
				handleClose();
				enqueueSnackbar(t(`success`), {
					variant: "success",
					autoHideDuration: 2000,
				});
			} catch (error) {
				enqueueSnackbar(error.message, {
					variant: "error",
					autoHideDuration: 2000,
				});
				setLoading(false);
			}
		}
	};

	const dispatch = useDispatch();
	useEffect(() => {
		if (!isOpen) {
			//Nếu truyền "reset" thì Data sẽ = [], bookingReducer
			dispatch(actSetAssignData("reset"));
		}
	}, [isOpen]);

	const [stateAssign, setStateAssign] = useState({ data: "", openModal: false });
	return (
		<>
			<Dialog maxWidth="lg" open={isOpen} onClose={handleClose}>
				<DialogTitle
					sx={{
						fontSize: "18px",
						textAlign: "center",
					}}
				>
					Dịch vụ được đặt
				</DialogTitle>
				<Grid item xs={12}></Grid>
				<DialogContent>
					<Grid
						container
						spacing={1}
						style={{
							marginBottom: 20,
						}}
					>
						<Grid item xs={12}>
							<Box>
								<h3>DỊCH VỤ CHÍNH</h3>
								<Box sx={{ padding: 2 }}>
									{Array.isArray(serviceList) &&
										serviceList.map((service, idx) => {
											return (
												service.is_main_service && (
													<ServiceItem
														service={service}
														key={service.id}
														onSetStateAssign={setStateAssign}
													/>
												)
											);
										})}
								</Box>
							</Box>
							<Box>
								<h3>DỊCH VỤ CHỌN THÊM</h3>
								<Box sx={{ padding: 2 }}>
									{Array.isArray(serviceList) &&
										serviceList.map((service, idx) => {
											return (
												!service.is_main_service && (
													<>
														<ServiceItem
															onSetStateAssign={setStateAssign}
															service={service}
															key={service.id}
														/>
														{idx !== serviceList.length - 1 && <Divider />}
													</>
												)
											);
										})}
								</Box>
							</Box>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						justifyContent: "center",
					}}
				>
					<LoadingButton
						sx={{
							margin: 2,
						}}
						// endIcon={<SaveIcon />}
						loading={loading}
						loadingPosition="end"
						variant="contained"
						className={cx("button__action")}
						onClick={() => handleSubmitAssign()}
					>
						{"Xác nhận giao việc"}
					</LoadingButton>
				</DialogActions>
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={showErr.state}
					onClose={() => setShowErr({ state: false })}
					// action={action}
					autoHideDuration={5000}
					severity="error"
				>
					<Alert severity="error">{showErr.message}</Alert>
				</Snackbar>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
			<AssignBookingModal
				serviceDetail={stateAssign.data}
				isOpen={stateAssign.openModal}
				isUpdate={stateAssign?.isUpdate}
				isClose={(value) => setStateAssign({ ...stateAssign, openModal: value })}
			/>
		</>
	);
}

export default BookingDetailModal;
