import axiosClient from "api/axiosClient";

const inventoryAPI = {
	async getList(params) {
		const url = `inventory`;

		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async getListCheck(params) {
		const url = `check_inventory`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},
	async getCheckId(id, params) {
		const url = `check_inventory/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},
	async updateInventory(data) {
		const url = `inventory/update-inventory`;

		const resp = await axiosClient.put(url, data);
		return resp;
	},
};

export default inventoryAPI;
