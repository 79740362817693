import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pie, measureTextWidth } from "@ant-design/plots";
import { activityOrderTranslator, activityTranslator } from "func";
import { Box, Typography } from "@mui/material";
import LinkIcon from "components/LinkIcon";

const DonutChart = ({
	data = [],
	chartType = "ORDER",
	chartText = {
		title: "Thống kê tình trạng đơn hàng",
	},
	linkPath,
	propsLink,
}) => {
	function renderStatistic(containerWidth, text, style) {
		const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
		const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

		let scale = 1;

		if (containerWidth < textWidth) {
			scale = Math.min(
				Math.sqrt(
					Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))
				),
				1
			);
		}
		const textStyleStr = `width:${containerWidth}px;`;
		return `<div style="${textStyleStr};font-size:${scale}rem;line-height:${
			scale < 1 ? 1 : "inherit"
		};">${text}</div>`;
	}

	const config = {
		appendPadding: 10,
		data: data.map((item) => ({
			...item,
			count: Number(item.count),
			activity:
				chartType === "ORDER"
					? activityOrderTranslator(item.activity)?.value
					: activityTranslator(item.activity)?.title,
		})),
		angleField: "count",
		colorField: "activity",
		radius: 1,
		innerRadius: 0.64,
		meta: {
			value: {
				formatter: (v) => `${v} ¥`,
			},
		},
		label: {
			type: "inner",
			offset: "-50%",
			style: {
				textAlign: "center",
			},
			autoRotate: false,
		},
		statistic: {
			title: {
				offset: -10,
				style: {
					fontSize: "16px",
					overflow: "none",
					textAlign: "center",
					whiteSpace: "wrap",
				},
				customHtml: (container, view, datum) => {
					const { width, height } = container.getBoundingClientRect();
					const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
					const text = datum ? datum.activity : "Tổng";
					return renderStatistic(d, text, {
						fontSize: 16,
						textAlign: "center",
						whiteSpace: "wrap",
					});
				},
			},
			content: {
				offset: 4,

				style: {
					fontSize: "25px",
					overflow: "none",
				},
				customHtml: (container, view, datum, data) => {
					const { width } = container.getBoundingClientRect();
					const text = datum
						? `${datum.count} ${chartType === "ORDER" ? "đơn hàng" : "lịch hẹn"}`
						: `${data.reduce((r, d) => r + d.count, 0)} ${
								chartType === "ORDER" ? "đơn hàng" : "lịch hẹn"
						  }`;
					return renderStatistic(width, text, {
						fontSize: 32,
					});
				},
			},
		},
		interactions: [
			{
				type: "element-selected",
			},
			{
				type: "element-active",
			},
			{
				type: "pie-statistic-active",
			},
		],
	};
	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "10px",
				}}
			>
				<Typography variant="h3" id="header_title" sx={{ margin: "20px 0" }}>
					{chartText.title}
				</Typography>
				{linkPath && <LinkIcon to={linkPath} {...propsLink} />}
			</Box>
			<Pie {...config} />
		</Box>
	);
};

export default DonutChart;
