import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import i18n from "translation/i18n";

export const SelectStyle = styled(Select)(({ theme }) => ({
	width: 200,
	marginLeft: 16,
	paddingLeft: 16,
	paddingRight: 16,
}));

const MultiLanguage = () => {
	const theme = useTheme();

	const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "en");

	const handleChangeLanguage = (e) => {
		localStorage.setItem("i18nextLng", e.target.value);
		setLanguage(e.target.value);
		i18n.changeLanguage(e.target.value);
	};

	useEffect(() => {
		if (!localStorage.getItem("i18nextLng")) {
			localStorage.setItem("i18nextLng", "en");
		}
	}, []);

	return (
		<>
			<Box
				sx={{
					ml: 2,
					mr: 3,
					[theme.breakpoints.down("md")]: {
						mr: 2,
					},
				}}
			>
				<FormControl sx={{ minWidth: 120 }}>
					<InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>
					<Select
						value={language}
						onChange={handleChangeLanguage}
						label="Language"
						displayEmpty
						inputProps={{ "aria-label": "Without label" }}
					>
						<MenuItem value="en">English</MenuItem>
						<MenuItem value="kr">Korea</MenuItem>
					</Select>
				</FormControl>
			</Box>
		</>
	);
};

export default MultiLanguage;
