export const generalTree = [
	{
		title: "Nhân viên",
		key: "EMPLOYEE",
		children: [
			{
				title: "Xem DS",
				key: "EMPLOYEE_VIEW",
			},
			{
				title: "Thêm mới",
				key: "EMPLOYEE_CREATE",
			},
			{
				title: "Cập nhật",
				key: "EMPLOYEE_UPDATE",
			},
			{
				title: "Xóa",
				key: "EMPLOYEE_DELETE",
			},
		],
	},
	{
		title: "Thu nợ",
		key: "DEBT",
	},
	{
		title: "Báo cáo",
		key: "REPORT",
	},
	{
		title: "Yêu cầu nạp/rút",
		key: "RECHARGE_WITHDRAW",
		children: [
			{
				title: "Yêu cầu nạp",
				key: "RECHARGE",
			},

			{
				title: "Yêu cầu rút",
				key: "WITHDRAW",
			},
		],
	},
	// {
	// 	title: "Danh mục bài viết",
	// 	key: "CATEGORY_POST",
	// 	children: [
	// 		{
	// 			title: "Xem DS",
	// 			key: "POST_VIEW",
	// 		},
	// 		// {
	// 		// 	title: "Thêm mới",
	// 		// 	key: "POST_CREATE",
	// 		// },
	// 		// {
	// 		// 	title: "Cập nhật",
	// 		// 	key: "POST_UPDATE",
	// 		// },
	// 		// {
	// 		// 	title: "Xóa",
	// 		// 	key: "POST_DELETE",
	// 		// },
	// 	],
	// },

	{
		title: "Bài viết",
		key: "POST",
		children: [
			{
				title: "Xem DS",
				key: "POST_VIEW",
			},
			{
				title: "Thêm mới",
				key: "POST_CREATE",
			},
			{
				title: "Cập nhật",
				key: "POST_UPDATE",
			},
			{
				title: "Xóa",
				key: "POST_DELETE",
			},
		],
	},
	{
		title: "Đăng ký tư vấn",
		key: "ADVISE",
	},
];
