import { Box, Chip } from "@mui/material";
import React from "react";
import { useMatch, useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";
import {
	IconLogout,
	IconPlant2,
	IconBookmarks,
	IconBuildingStore,
	IconCalendarTime,
} from "@tabler/icons";
import useCustomAuth from "hooks/useCustomAuth";

function CashierSection() {
	const match = useMatch("/cashier/*");

	const isCashier = match?.pathname === "/cashier";

	const navigate = useNavigate();

	const theme = useTheme();

	const { role } = useCustomAuth();

	return !isCashier ? (
		<>
			<Chip
				sx={{
					height: "48px",
					marginRight: "20px",
					alignItems: "center",
					borderRadius: "27px",
					padding: "5px",

					transition: "all .2s ease-in-out",
					borderColor: theme.palette.primary.light,
					backgroundColor: theme.palette.primary.light,
					'&[aria-controls="menu-list-grow"], &:hover': {
						borderColor: theme.palette.primary.main,
						background: `${theme.palette.primary.main}!important`,
						color: theme.palette.primary.light,
						"& svg": {
							stroke: theme.palette.primary.light,
						},
						"& .MuiChip-label": {
							color: theme.palette.primary.light,
							fontWeight: "bold",
						},
					},
					"& .MuiChip-label": {
						fontWeight: "bold",
					},
				}}
				icon={
					<IconPlant2
						style={{
							fontSize: "1.5rem",
							color: theme.palette.primary.main,
						}}
					/>
				}
				label={"Thu ngân"}
				variant="outlined"
				aria-haspopup="true"
				id="profile-btn"
				onClick={() => navigate("/cashier/create-order")}
				color="primary"
			/>

			{/* <Chip
				sx={{
					height: "48px",
					marginRight: "20px",
					alignItems: "center",
					borderRadius: "27px",
					padding: "5px",

					transition: "all .2s ease-in-out",
					borderColor: theme.palette.primary.light,
					backgroundColor: theme.palette.primary.light,
					'&[aria-controls="menu-list-grow"], &:hover': {
						borderColor: theme.palette.primary.main,
						background: `${theme.palette.primary.main}!important`,
						color: theme.palette.primary.light,
						"& svg": {
							stroke: theme.palette.primary.light,
						},
						"& .MuiChip-label": {
							color: theme.palette.primary.light,
							fontWeight: "bold",
						},
					},
					"& .MuiChip-label": {
						fontWeight: "bold",
					},
				}}
				icon={
					<IconCalendarTime
						style={{
							fontSize: "1.5rem",
							color: theme.palette.primary.main,
						}}
					/>
				}
				label={"Đặt lịch"}
				variant="outlined"
				aria-haspopup="true"
				id="profile-btn"
				onClick={() => navigate("/cashier/create-order")}
				color="primary"
			/> */}
		</>
	) : role === "ADMIN" ? (
		<></>
	) : (
		<Chip
			sx={{
				height: "48px",
				marginRight: "20px",
				padding: "5px",
				alignItems: "center",
				borderRadius: "27px",
				transition: "all .2s ease-in-out",
				borderColor: theme.palette.primary.light,
				backgroundColor: theme.palette.primary.light,
				'&[aria-controls="menu-list-grow"], &:hover': {
					borderColor: theme.palette.primary.main,
					background: `${theme.palette.primary.main}!important`,
					color: theme.palette.primary.light,
					"& svg": {
						stroke: theme.palette.primary.light,
					},
					"& .MuiChip-label": {
						color: theme.palette.primary.light,
						fontWeight: "bold",
					},
				},
				"& .MuiChip-label": {
					fontWeight: "bold",
				},
			}}
			icon={
				<IconBuildingStore
					style={{
						fontSize: "1rem",
						color: theme.palette.primary.main,
					}}
				/>
			}
			label={"Quản lý"}
			variant="outlined"
			aria-haspopup="true"
			id="profile-btn"
			onClick={() => navigate("/")}
			color="primary"
		/>
	);
}

export default CashierSection;
