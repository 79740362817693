import { Box, Tooltip, Typography } from "@mui/material";
import { Col, Row } from "antd";
import React from "react";
import CountUp from "react-countup";
import { useNavigate } from "react-router";
import IconBox from "./IconBox";
import styles from "./component.module.scss";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);
function VBeautySmallCard({ title, icon, customIcon, propsNavigate, propsCountUp, ...props }) {
	const navigate = useNavigate();
	return (
		<Tooltip arrow title={propsNavigate?.tooltipTitle || ""}>
			<Row
				style={{ gap: "5px", width: "100%" }}
				className={cx(propsNavigate && "link")}
				wrap={false}
				onClick={(e) => propsNavigate && navigate(propsNavigate.to, propsNavigate.props)}
				{...props}
			>
				<Col
					span={5}
					style={{
						display: "flex",
						justifyContent: "start",
					}}
				>
					{/* ICON */}
					<IconBox customStyle={customIcon}>{icon}</IconBox>
				</Col>

				<Col flex={1} sx={{ width: "100%" }}>
					<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
						<Typography variant="h3" component={"div"}>
							<CountUp style={{ width: "100%", display: "block" }} {...propsCountUp} />
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{title}
						</Typography>
					</Box>
				</Col>
			</Row>
		</Tooltip>
	);
}

export default VBeautySmallCard;
