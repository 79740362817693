import { CardGiftcard } from "@mui/icons-material";
import { Grid, IconButton, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "antd";
import shareLinkAPI from "api/ShareLink";
import AccountDeleteConfirm from "components/AccountDeleteConfirm";
import { CustomNoRowsOverlay } from "components/noRows";
import { ALERT_PERMISSION, DELETED_USER } from "constants/constant";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { actSetUserInformation } from "store/actions/UserAction";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import DialogMain from "ui-component/modal-form/DialogMui";
import ImageModal from "ui-component/modal-form/ImageModal";
import UserInformation from "ui-component/modal-form/ModalAntd/DetailInformation/UserInformation";
import UserModal from "ui-component/modal-form/UserModal";
import { showSortStyle } from "views/utilities/CustomStyle";
import userAPI from "../../api/UserAPI";
import { SearchComponent } from "../../components/search";
import UserBooking from "./UserBooking";

const searchList = [
	{
		value: "$user.username$",
		title: "SĐT người chia sẻ",
	},
	{
		value: "$product.title$",
		title: "Sản phẩm chia sẻ",
	},

	{
		value: "$offer.title$",
		title: "Thẻ chia sẻ",
	},
];

const ShareLink = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { role } = useCustomAuth();
	const modalContext = useContext(ModalContext);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(0);
	const [data, setData] = useState();
	const [isUpdate, setIsUpdate] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState([]);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});
	const [openModalImage, setOpenModalImage] = useState(false);
	const [imgQr, setImgQr] = useState("");
	//user booking

	const [isOpenUserBooking, setIsOpenUserBooking] = useState(false);

	const handleUpdateCollab = async (id) => {
		try {
			const res = await userAPI.update(id, {
				is_collab: true,
			});
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListShareLink();
		} catch (err) {
			enqueueSnackbar(err.message || "Có lỗi xảy ra", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};
	const handleOpenChangeAccept = (id) => {
		modalContext.showDeleteConfirm(
			id,

			() => handleUpdateCollab(id),
			"Bạn có chắc muốn khách hàng này trở thành cộng tác viên",
			"Chắc chắn"
		);
	};

	const getListShareLink = async () => {
		setIsLoading(true);
		let filter = {
			[searchBy]:
				searchValue.trim().length === 0
					? undefined
					: {
							$iLike: `%${searchValue.trim()}%`,
					  },
		};
		let order = '[["created_at", "desc"]]';
		let fields = [
			"$all",
			{
				user: ["username", "deleted_at", "$paranoid"],
			},
			{
				offer: ["title"],
			},
			{
				product: ["title"],
			},
			{
				bonus: ["$all"],
			},
		];
		// if (queryParams?.[searchBy]) {
		// 	filter = `{"${searchBy}":{"$iLike":"%${searchValue.trim()}%"}}`;
		// }

		if (queryParams?.sortBy) {
			order = `[["created_at_unix_timestamp","desc"]]`;
		}

		try {
			const res = await shareLinkAPI.getList({
				fields: JSON.stringify(fields),
				order,
				filter: JSON.stringify(filter),
				limit: pagination.limit,
				page: queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};

	const [deleteUser, setDeleteUser] = useState({
		open: false,
		username: "",
	});
	const handleOpenRemoveModal = (id, row) => {
		setDeleteUser({
			id: id,
			open: true,
			username: row.username,
		});
	};

	const handleDelete = async (id, username) => {
		if (deleteUser.username !== username) {
			return;
		} else {
			try {
				await userAPI.remove(id);
				enqueueSnackbar(t(`success`), {
					variant: "success",
					autoHideDuration: 2000,
				});
				setDeleteUser("");
				await getListShareLink();
			} catch (error) {
				enqueueSnackbar(error?.message || t(`error`), {
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		}
	};

	const handleUpdate = (data) => {
		setData(data);
		setOpen(true);
		setIsUpdate(true);
	};

	const [information, setInformation] = useState({
		visible: false,
	});
	const dispatch = useDispatch();
	const handleOpenInforModal = (data) => {
		//	Example DATA
		dispatch(actSetUserInformation(data));
		setInformation((prev) => ({
			data: data,
			visible: true,
			userId: data.id,
		}));

		setIsOpenUserBooking(false);
	};

	const refPage = useRef(1);

	const handleOpenUserBooking = (data) => {
		setSearchBy("");
		setSearchValue("");
		refPage.current = pagination.page;
		handleChangePage(null, 1);
		setInformation((prev) => ({
			...prev,
			visible: true,
			userId: data.id,
		}));

		setIsOpenUserBooking(true);
	};

	useEffect(() => {
		if (information.visible) {
		} else {
			handleChangePage(null, refPage.current);
			refPage.current = 1;
		}
	}, [information.visible]);

	const handleRemoveMulti = () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await userAPI.removeMulti({
						items: JSON.stringify(selectList),
					});
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListShareLink();
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	useEffect(() => {
		if (!role) return;

		if (role?.LINK !== ROLE.LINK) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty("username")) {
			setSearchBy("username");
			setSearchValue(queryParams?.username);
		}
		if (queryParams.hasOwnProperty("email")) {
			setSearchBy("email");
			setSearchValue(queryParams?.email);
		}
		if (queryParams.hasOwnProperty("phone")) {
			setSearchBy("phone");
			setSearchValue(queryParams?.phone);
		}
		if (queryParams.hasOwnProperty("fullname")) {
			setSearchBy("fullname");
			setSearchValue(queryParams?.fullname);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		getListShareLink();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, role]);

	const handleNavigate = (row) => {
		navigate("/link-share/preferential-user", {
			state: {
				linkId: row.id,
			},
		});
	};

	const actionList = [
		{
			id: "preferential_user",
			title: "Xem người hưởng ưu đãi từ liên kết",
			icon: <CardGiftcard />,
			// handleClick:
		},
	];
	const onChangeAction = (value, row) => {
		value === "preferential_user" && handleNavigate(row);
	};

	const columns = [
		{
			field: "username",
			headerName: "SĐT người chia sẻ",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.row?.user?.username || DELETED_USER}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
						className={params?.row?.user?.deleted_at && "line__though"}
					>
						{params.row?.user?.username || DELETED_USER}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "link",
			headerName: "Liên kết",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.value}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.value}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "bonus_title",
			headerName: "Tên sản phẩm",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.row?.product?.title || params.row?.offer?.title}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.row?.product?.title || params.row?.offer?.title}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "quantity",
			headerName: "Số lượt nhấn vào liên kết",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={`${params.value} lượt`}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{`${params.value} lượt`}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian tạo",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<Tooltip
					title={moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "actions",
			headerName: t(`actions`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			minWidth: 200,
			renderCell: (params) => (
				<>
					{actionList.map((action, idx) => (
						<Tooltip title={action.title}>
							<IconButton
								aria-label={action.title}
								onClick={() => {
									onChangeAction(action.id, params.row);
								}}
							>
								{action.icon}
							</IconButton>
						</Tooltip>
					))}
				</>
			),
		},
	];

	return (
		<>
			<MainCard>
				<HeaderActions title={"Liên kết chia sẻ"} setIsUpdate={setIsUpdate} setOpen={setOpen} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>
					{/* 
					<Button
						variant={selectList.length > 0 ? "contained" : "outlined"}
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							// checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<DialogMain
				maxWidth={"lg"}
				title={isOpenUserBooking ? "Lịch sử lịch hẹn" : "Chi tiết khách hàng"}
				fullWidth
				open={information.visible}
				onClose={() => {
					setInformation((prev) => ({
						...prev,
						visible: false,
					}));
				}}
			>
				{isOpenUserBooking ? (
					<UserBooking userId={information.userId} openStatus={information.visible} />
				) : (
					<UserInformation
						getListTable={getListShareLink}
						setInformation={(value) => {
							setInformation((prev) => ({
								...prev,
								data: value,
							}));
						}}
					/>
				)}
			</DialogMain>
			<DialogMain
				maxWidth={"md"}
				title={"Xác nhận xóa khách hàng"}
				fullWidth
				open={deleteUser.open}
				onClose={() => {
					setDeleteUser((prev) => ({
						...prev,
						open: false,
					}));
				}}
			>
				<AccountDeleteConfirm
					account={deleteUser.username}
					handleDelete={(username) => handleDelete(deleteUser?.id, username)}
				/>
			</DialogMain>
			<UserModal
				employee={data}
				getListTable={getListShareLink}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>

			<ImageModal
				title="Qr Code khách hàng"
				openModalZoom={openModalImage}
				handleCloseModal={() => setOpenModalImage(false)}
				imageZoom={imgQr}
			/>
		</>
	);
};

export default ShareLink;
