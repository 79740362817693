import { DriveFileMoveOutlined, SimCardDownload } from "@mui/icons-material";
import { Box, Button, Pagination, Stack, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "components/noRows";
import {
	activityOrderTranslator,
	activityTranslator,
	formatVND,
	paymentTypeOrderTranslator,
} from "func";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HeaderActions from "ui-component/Header-actions";
import { excelExportHelper } from "utils/ExportExcel";

function InvoicesBookingTable({ rows, setPagination, pagination, filterData, loading, getData }) {
	const { branchList } = useSelector((state) => state?.branchReducer);
	const handleChangePage = (e, page) => {
		setPagination((prev) => ({
			...prev,
			page,
		}));
	};
	const columns = [
		{
			field: "booking_code",
			headerName: "Mã lịch đặt",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							<Link to={`/booking-detail/${params.row?.id}`} target="_blank">
								{params.value}
							</Link>
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "phone",
			headerName: "SĐT",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "branch",
			headerName: "Chi nhánh",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.branch?.name}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.branch?.name}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "total_cost",
			headerName: "Tổng tiền",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(Number(params?.row?.total_price))}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(Number(params?.row?.total_price))}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "booking_type",
			headerName: "Phương thức thanh toán",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={paymentTypeOrderTranslator(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{paymentTypeOrderTranslator(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "order_online",
			headerName: "Hình thức mua hàng",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value ? "Đặt hàng Online" : "Tại cửa hàng"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value ? "Đặt hàng Online" : "Tại cửa hàng"}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "activity",
			headerName: "Trạng thái",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				const convertData = activityTranslator(params.value);
				return (
					<Tooltip
						title={convertData?.title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								fontWeight: "bold",
								color: convertData?.color,
							}}
						>
							{convertData?.title}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "time_start",
			headerName: "Ngày giờ hẹn",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian tạo",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
	];

	const handleExportExcel = async (data, date) => {
		const response = await getData();
		const orderData = response?.results?.objects?.rows;

		const startEndDate = {
			start: moment(date[0]).format("DD/MM/YYYY"),
			end: moment(date[1]).format("DD/MM/YYYY"),
		};

		const title = [
			{
				A: `Báo cáo lịch hẹn theo thời gian
						Từ ngày ${startEndDate.start} đến ngày ${startEndDate.end}
						Chi nhánh: ${branchList
							.map((item) => {
								if (filterData.branch.includes(item.id)) {
									return item.name;
								} else {
									return "";
								}
							})
							.filter((it) => it)
							.join(", ")}`,
			},
			{},
		];

		let table1 = [
			{
				A: "Mã lịch hẹn",
				B: "Ngày giờ hẹn",
				C: "Khách hàng",
				D: "Điện thoại",
				E: "Chi nhánh",
				F: "Nhân viên xác nhận",
				G: "Ghi chú",
				H: "Tổng tiền",
				I: "Tỏng giảm",
				J: "Thành tiền",
				K: "Khách đã thanh toán",
				L: "Phương thức thanh toán",
				M: "Trạng thái",
				N: "Thời gian tạo lịch",
			},
		];
		// console.log("data export: ", data);
		orderData.forEach((row) => {
			console.log("row: ", row);
			table1.push({
				A: row?.booking_code,
				B: moment(+row?.time_start).format("DD/MM/YYYY HH:mm"),
				C: row?.user?.fullname,
				D: row?.ussr?.username,
				E: row?.branch?.name,
				F: row?.employee_confirm?.fullname || row?.employee_confirm?.username,
				G: row?.note,
				H: +row?.total_price + Number(row?.discount),
				I: +row?.discount,
				J: +row?.total_price,
				K: +row?.total_price,
				L: paymentTypeOrderTranslator(row?.booking_type),
				M: activityTranslator(row?.activity).title,
				N: moment(+row?.created_at_unix_timestamp).format("DD/MM/YYYY HH:mm"),
			});
		});

		table1 = [{ A: `Ngày lập ${moment().format("DD/MM/YYYY HH:mm")}` }].concat(table1).concat([""]);

		const finalData = [...title, ...table1];

		excelExportHelper(orderData, finalData);
	};

	return (
		<Box minHeight={"100vh"}>
			<Stack flexDirection={"row"} justifyContent="space-between">
				<HeaderActions title="Lịch đặt" />
				<Stack flexDirection={"row"} gap={2} alignItems="center">
					<Button
						sx={{ p: 1, height: "fit-content" }}
						size="small"
						variant="contained"
						startIcon={<SimCardDownload />}
						onClick={() => handleExportExcel(rows, filterData?.date)}
					>
						Xuất file
					</Button>
				</Stack>
			</Stack>
			<Box>
				<DataGrid
					rows={rows}
					columns={columns}
					autoHeight
					autoWidth
					disableColumnMenu
					disableVirtualization={false}
					disableSelectionOnClick={true}
					disableDensitySelector={false}
					hideFooter={true}
					rowHeight={70}
					sortingMode="server"
					loading={loading}
					// checkboxSelection={ROLE.TRANSACTION_DELETE === role?.TRANSACTION_DELETE}
					// onSelectionModelChange={(ids) => {
					// 	setSelectList(ids);
					// }}
					components={{
						NoRowsOverlay: CustomNoRowsOverlay,
					}}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					marginTop: "20px",
				}}
			>
				<Pagination
					page={pagination.page}
					count={Math.ceil(pagination.total / pagination.limit)}
					onChange={handleChangePage}
					color="primary"
					variant="outlined"
					shape="rounded"
				/>
			</Box>
		</Box>
	);
}

export default InvoicesBookingTable;
