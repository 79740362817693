import { LocalFireDepartment } from "@mui/icons-material";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Button, Grid, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Select, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import productAPI from "api/ProductAPI";
import { IMAGES } from "assets/images/imagePath";
import { CustomNoRowsOverlay } from "components/noRows";
import PageSizeSelect from "components/PrintTemplate/components/PageSizeSelect";
import ProductQr from "components/PrintTemplate/ProductQr";
import { ROLE } from "constants/employeeRole";
import { formatVND, productTranslator } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import { Markup } from "interweave";
import _debounce from "lodash/debounce";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import ImageModal from "ui-component/modal-form/ImageModal";
import DetailProductInformation from "ui-component/modal-form/ModalAntd/DetailProductInformation";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import ProductModal from "ui-component/modal-form/ProductModal";
import { SelectCustom } from "views/utilities/CustomStyle";
import { SearchComponent } from "../../components/search";
import moment from "moment";
const { Option } = Select;

const searchList = [
	{
		value: "title",
		title: "title",
	},
	{
		value: "$category.title$",
		title: "Danh mục",
	},
	{
		value: "barcode",
		title: "Mã vạch",
	},
	// {
	// 	value: "price",
	// 	title: "Giá dịch vụ",
	// },
];

const Product = () => {
	// const role = useSelector((state) => state.employee?.role);
	const { role } = useCustomAuth();
	const location = useLocation();

	const stateLoc = location?.state;

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const actionList = [
		{
			value: "delete",
			title: "delete",
			visible: role?.PRODUCT_DELETE === ROLE.PRODUCT_DELETE,
		},
		{
			value: "edit",
			title: "Chỉnh sửa sản phẩm",
			visible: role?.PRODUCT_UPDATE === ROLE.PRODUCT_UPDATE,
		},
		{
			value: "information",
			title: "Xem chi tiết sản phẩm",
			visible: role?.PRODUCT_VIEW === ROLE.PRODUCT_VIEW,
		},
		{
			value: "print_barcode",
			title: "In mã vạch",
			visible: role?.PRODUCT_VIEW === ROLE.PRODUCT_VIEW,
		},
	];
	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [searchBy, setSearchBy] = useState(searchList[0].value);
	const [searchValue, setSearchValue] = useState("");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const [openModalImage, setOpenModalImage] = useState(false);
	const [printQr, setPrintQr] = useState({
		image: "",
		productName: "",
		priceProduct: 0,
		data,
	});

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate(
			{
				pathname: location.pathname,
			},
			{
				state: stateLoc,
			}
		);
	};

	const handleOpenChangeAccept = (e, params, isWithClient = false) => {
		let checked = e.target.checked;
		modalContext.showDeleteConfirm(
			params.id,
			() => handleChangeAccept(checked, params.id, isWithClient),
			`${
				checked
					? `Bạn có chắc chắn xác nhận  ${
							!isWithClient ? "sản phẩm này soi da?" : "đã nhận tiền?"
					  } `
					: `Bạn có chắc chắn xác nhận ${
							!isWithClient ? "sản phẩm này không soi da?" : "chưa nhận tiền?"
					  }`
			}`,
			"Chắc chắn"
		);
	};

	const handleChangeAccept = async (checked, id, isWithClient) => {
		try {
			await productAPI.update(
				id,

				!isWithClient
					? {
							is_scan_skin: checked,
					  }
					: {
							is_confirm_with_client: checked,
					  }
			);
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListService();
		} catch (error) {
			enqueueSnackbar(error?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate(
			{
				pathname: location.pathname,
				search: `?${queryString.stringify(filters)}`,
			},
			{
				state: stateLoc,
			}
		);
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	//Print Func
	const handleUpdate = (params) => {
		setData(params.row);
		setOpen(true);
		setIsUpdate(true);
	};
	// Table
	const onChangeAction = (value, id, params) => {
		value === "edit" && handleUpdate(params);
		value === "delete" && handleOpenRemoveModal(id, params.row);
		value === "information" && navigate(`/product-detail/${id}`);
		value === "print_barcode" && handleOpenPrintModal(params.row, "BARCODE");
	};

	const columns = [
		{
			field: "image",
			headerName: "Danh sách ảnh",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<img
					height={"80%"}
					className={"aspect1-1"}
					src={params.row.image[0] || IMAGES.img_default}
					alt="avatar-img"
					onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.img_default))}
					onClick={() => setImageZoom({ open: true, image: params.row.image })}
				/>
			),
		},
		{
			field: "title",
			headerName: "Tên sản phẩm",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "qr_code_product",
			headerName: "Mã Qr",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<img
					height={"80%"}
					src={params.value || IMAGES.avatar_default}
					alt="avatar-img"
					onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
					onClick={() => {
						setPrintQr({
							image: params.value,
							productName: params.row?.title,
							priceProduct: params.row?.price,
							data: params.row,
						});
						setOpenModalImage(true);
					}}
				/>
			),
		},
		{
			field: "barcode",
			headerName: "Mã vạch",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params?.value}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params?.value}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "price",
			headerName: t(`abeauty.product.table_title.price`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "sale_price",
		// 	headerName: t(`abeauty.product.table_title.sale_price`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={formatVND(params.value)}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{formatVND(params.value)}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		{
			field: "realPrice",
			headerName: t(`abeauty.product.table_title.real_price`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				const result = params.row.price - params.row.sale_price;
				return formatVND(result);
			},
		},
		{
			field: "inventory_amount_branch",
			headerName: t(`abeauty.product.table_title.inventory_amount`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.5,
			// renderCell: (params) => params.value + " phút",
		},
		// {
		// 	field: "amount_sale",
		// 	headerName: "Số lượng sản phẩm đã bán",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 0.5,
		// 	// renderCell: (params) => params.value + " phút",
		// },
		// {
		// 	field: "unit",
		// 	headerName: "Đơn vị",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 0.5,
		// 	renderCell: (params) => params.row?.unit?.value,
		// },

		{
			field: "type",
			headerName: "Loại",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.5,
			renderCell: (params) => {
				// params.value = false;

				return (
					<Tooltip
						title={productTranslator(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{productTranslator(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "created_at",
			headerName: "Thời gian tạo",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.5,
			renderCell: (params) => {
				// params.value = false;

				return (
					<Tooltip
						title={moment(params?.value).format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(params?.value).format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "best_sale",
		// 	headerName: "Hot deal",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 0.5,
		// 	renderCell: (params) => {
		// 		return params.value && <LocalFireDepartment color="error" />;
		// 	},
		// },

		{
			field: "sort",
			headerName: "Thứ tự",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.5,
		},
		{
			field: "actions",
			headerName: t(`actions`),
			flex: 1.5,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<>
					<SelectCustom
						value={t(`select_actions`)}
						onChange={(value) => onChangeAction(value, params.id, params)}
					>
						{actionList?.map((item) => {
							let arg = null;
							switch (item.value) {
								case "cancel_parent":
									arg = params?.row?.is_collab_of_system || params?.row?.parent_id;
									break;
								case "user_members":
									arg = params?.row?.is_collab && !params?.row?.is_virtual_account;
									break;

								default:
									arg = null;
									break;
							}
							const isHide = item?.isHide === undefined ? false : item.isHide(arg);
							return (
								item.visible && (
									<Option disabled={isHide} key={item?.value}>
										{t(`${item.title}`)}
									</Option>
								)
							);
						})}
					</SelectCustom>
				</>
			),
		},
	];

	const [information, setInformation] = useState({
		visible: false,
	});
	const [printData, setPrintData] = useState({
		visible: false,
		data: {},
	});
	const handleOpenInforModal = (data) => {
		//	Example DATA
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin sản phẩm",
				gridProps: {},
				children: [
					{
						id: "title",

						title: "Tên sản phẩm",
						value: data.title,
						component: Text,
						childrenProps: {
							//all Props component here
							span: 8,
						},
					},
					{
						id: "category_name",
						title: "Danh mục sản phẩm",
						value: data.category?.title,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},

					{
						id: "hot",
						title: "Hot deal",
						value: "",
						component: data.best_sale && LocalFireDepartment,
						childrenProps: {
							span: 8,
							color: "error",
						},
					},
					{
						id: "price",
						title: "Giá gốc",
						value: formatVND(data.price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "discount",
						title: "Giá giảm",
						value: formatVND(data.sale_price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "real_price",
						title: "Giá cuối",
						value: formatVND(data.price - data.sale_price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "inventory_amount_branch",
						title: "Tồn kho",
						value: data?.inventory_amount_branch,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "type",
						title: "Loại",
						value: data?.type === "PRODUCT" ? "Sản phẩm" : "Quà tặng",
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "barcode",
						title: "Mã vạch",
						value: data?.barcode,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "created_at",
						title: "Thời gian tạo",
						value: moment(data?.created_at).format("DD/MM/YYYY HH:mm"),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "updated_at",
						title: "Thời gian cập nhật",
						value: moment(data?.updated_at).format("DD/MM/YYYY HH:mm"),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "description",
						title: "Mô tả sản phẩm",
						value: data.description || "Không có dữ liệu",
						component: Text,
						childrenProps: {
							span: 24,
						},
					},

					{
						id: "sub_description",
						title: "Chi tiết sản phẩm",
						value: "",
						component: Markup,
						childrenProps: {
							span: 24,
							content: data.sub_description || "Không có dữ liệu",
						},
					},
				],
			},
		];

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
		}));
	};
	const handleOpenPrintModal = (data, templateType = "QR_CODE") => {
		setPrintData({
			visible: true,
			data,
			templateType,
		});
	};

	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const getListService = async (isLastDeletedItem) => {
		setIsLoading(true);
		let filter = {
			// branch_id: branchActiveId,

			//Filter category
			[location?.state?.searchBy]: location?.state ? location?.state.value : undefined,
			//filter group
			group: location?.state?.isFromGroup ? { $contains: [location?.state?.group] } : undefined,

			//Search fea
			[searchBy]: !queryParams?.[searchBy]
				? undefined
				: {
						$iLike: `%${queryParams?.[searchBy]?.trim()}%`,
				  },
		};

		let order = '[["sort", "desc"]]';

		try {
			const res = await productAPI.getList({
				fields: '["$all", {"category": ["$all"]}, {"unit": ["value"]}]',
				order,
				filter: JSON.stringify(filter),
				limit: pagination.limit,
				page: isLastDeletedItem ? queryParams.page - 1 : queryParams.page,
				branch_id: branchActiveId,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			if (isLastDeletedItem) {
				navigate(
					{
						pathname: location.pathname,
						search: `?${queryString.stringify({ ...queryParams, page: paginationRes.page })}`,
					},
					{
						state: stateLoc,
					}
				);
			}
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));

			setSelectList([]);
			setRows(resList);

			setPagination(paginationRes);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//Get Services Categories
	const [serviceCategories, setServiceCategories] = useState(null);
	const getServicesCategories = async () => {
		setIsLoading(true);

		const filter = {
			status: "true",
		};
		const params = {
			fields: '["$all"]',
			filter: JSON.stringify(filter),
		};
		try {
			const res = await productAPI.getCategory(params);
			setServiceCategories(res.results.objects.rows);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);

	const handleRemoveMulti = async () => {
		let isLast = rows.length === selectList.length && pagination.page !== 1;

		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await productAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListService(isLast);
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate(
			{
				pathname: location.pathname,
				search: `?${queryString.stringify(filters)}`,
			},
			{ state: location.state }
		);
	};

	const handleDelete = async (id) => {
		let isLast = rows.length === 1 && pagination.page !== 1;
		try {
			await productAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListService(isLast);
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useLayoutEffect(() => {
		if (!branchActiveId || !role) return;

		if (role?.PRODUCT_VIEW !== ROLE.PRODUCT_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}

		// if (location.state) {
		// 	setSearchBy(filterWithCategory.searchBy);
		// 	setSearchValue(filterWithCategory.value);
		// }
		getListService();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading, branchActiveId, role]);

	useEffect(() => {
		getServicesCategories();
	}, []);

	return (
		<>
			<MainCard>
				{(location?.state?.isFromCategory || location?.state?.isAll) && (
					<Button
						variant="contained"
						onClick={() =>
							navigate("/product-category", {
								replace: true,
							})
						}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về
					</Button>
				)}
				{stateLoc?.isFromGroup && (
					<Button
						variant="contained"
						onClick={() => navigate("/group-product")}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về
					</Button>
				)}
				<HeaderActions
					title={t(`list_product`)}
					setIsUpdate={setIsUpdate}
					setOpen={setOpen}
					onCreate={
						role?.PRODUCT_CREATE === ROLE.PRODUCT_CREATE && !stateLoc?.isFromGroup ? true : false
					}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{employeeInfo?.role === "SUPERADMIN" && (
						<Button
							sx={{
								display: stateLoc?.isFromGroup ? "none" : "block",
							}}
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<ProductModal
				stateLocation={location.state}
				listSelect={serviceCategories}
				getListTable={getListService}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>
			<ImageModal
				title={"Ảnh sản phẩm"}
				multi
				classNameImg={"aspect1-1"}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>
			<ModalAntd
				title={"Chi tiết sản phẩm"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailProductInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>
			<ImageModal
				hasFooter
				title="Qr Code sản phẩm"
				openModalZoom={openModalImage}
				handleCloseModal={() => setOpenModalImage(false)}
				imageZoom={printQr.image}
				onOpenPageSelect={() => handleOpenPrintModal(printQr.data)}
				PrintComponent={
					<ProductQr
						qrCode={printQr.image}
						productName={printQr.productName}
						price={printQr.priceProduct}
						barCode={printQr?.data?.barcode}
					/>
				}
			/>

			<ModalAntd
				title={"Chọn mẫu giấy in"}
				visible={printData.visible}
				setVisible={(value) => {
					setPrintData((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<PageSizeSelect data={printData.data} templateType={printData.templateType} />
			</ModalAntd>
		</>
	);
};

export default Product;
