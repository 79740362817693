export const MENU_TABS = [
	{
		id: "invoicesOrder",
		url: "/invoices/order",
		title: "Đơn hàng",
		state: {
			type: "ORDER",
		},
		default: true,
	},
	{
		id: "invoicesInternal",
		state: {
			type: "INTERNAL",
		},
		url: "/invoices/internal-order",
		title: "Xuất hàng nội bộ",
		default: false,
	},
	{
		id: "invoicesBooking",

		url: "/invoices/booking",
		title: "Lịch đặt",
		default: false,
	},
	{
		id: "invoicesQr",

		url: "/invoices/qr-code",
		title: "Thẻ nạp",
		default: false,
	},
	{
		id: "invoicesDeposit",

		url: "/invoices/deposit-request",
		title: "Yêu cầu nạp thẻ",
		default: false,
	},
];
