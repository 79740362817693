import { Box, TextField } from "@mui/material";
import React from "react";

function NotePopover({ inputName, value, setValue }) {
	const handleChange = (event) => {
		setValue(event.target.value);
	};
	return (
		<Box p={2} width="250px">
			<TextField
				id="outlined-multiline-flexible"
				label="Ghi chú đơn hàng"
				multiline
				fullWidth
				name={inputName}
				rows={4}
				value={value}
				onChange={handleChange}
			/>
		</Box>
	);
}

export default NotePopover;
