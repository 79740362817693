import { LoadingButton } from "@mui/lab";
import { Box, Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import orderAPI from "api/OrderAPI";

import useCustomAuth from "hooks/useCustomAuth";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { actReset } from "views/pages/CreateOrder/store/action";
import StepInfoCustomer from "./StepInfoCustomer";
import StepInfoOrder from "./StepInfoOrder";

function CreateOrderModal({ onCloseModal, setDataPrint, setIsPrint, order_tab }) {
	const steps = ["Thông tin đơn hàng", "Thông tin khách hàng"];
	const { infoCustomer, orderList } = useSelector((state) => state.createOrder);

	const [payType, setPayType] = useState("CASH");
	const [activity, setActivity] = useState("COMPLETED"); // Vì trạng thái init của orderType  là OFFLINE | ONLINE là WAITING
	const [orderType, setOrderType] = useState("OFFLINE");

	const [customerPay, setCustomerPay] = useState(infoCustomer.totalPrice);
	const [totalDiscount, setTotalDiscount] = useState(0);

	const { handleSubmit, control, errors, clearErrors, setValue } = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
		shouldUnregister: false,
		defaultValues: {
			...infoCustomer.userInfo,
		},
	});
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { settingCMS } = useCustomAuth();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { branchActiveId } = useSelector((state) => state.branchReducer);
	const modalContext = useContext(ModalContext);
	const confirmCreateInternalOrder = (data) => {
		modalContext.showConfirmModal(
			() => {
				onSubmit(data, "INTERNAL");
			},
			"Đơn hàng sẽ được tạo dưới dạng xuất hàng nội bộ và được ghi nhận lại bởi hệ thống, bạn có chắc chắn thực hiện?",
			"Đồng ý"
		);
	};

	console.log(infoCustomer);
	const onSubmit = async (data) => {
		setLoading(true);
		const orderItems = orderList.map((item) => ({
			item_id: item.id,
			quantity: Number(item.count),
			price: Number(item.price),
			discount: Number(item.sale_price),
		}));
		const body = {
			// user_id: infoCustomer.userId || null,
			// phone: infoCustomer.order_type === "COMBO" ? infoCustomer.phone : data?.phone,
			[infoCustomer.order_type === "INTERNAL" ? "employee_internal_id" : "phone"]:
				infoCustomer.order_type === "INTERNAL"
					? infoCustomer.userId
					: data?.phone || infoCustomer.phone,
			branch_id: branchActiveId,
			payment_type: payType,
			order_type: infoCustomer.order_type,
			order_item: orderItems,
			city: data.city?.name,
			ward: data.ward?.name,
			district: data.district?.name,
			fullname: data.fullname,
			address: data?.address,
			address_id: data.address_id,
			activity: activity === "WAITING" ? null : activity,
			discount: Number(totalDiscount),
			note: noteVal,
			total_cost_origin: customerPay,
		};

		//Lọc dữ liệu null
		Object.keys(body).forEach((key) => {
			if (!!body[key] === false) {
				delete body[key];
			}
		});

		try {
			const res = await orderAPI.create(body);
			const data = res.results.object;
			if (settingCMS.auto_print) {
				setIsPrint(true);
				setDataPrint({
					order_items: data.order_items,
					customer_info: {
						name: data.fullname,
						address: data.address,
						ward: data.ward,
						district: data.district,
						city: data.city,
						phone: data.phone,
					},
					code: data.order_code,
					data,
				});
			}

			setLoading(false);
			dispatch(actReset());
			onCloseModal();
			enqueueSnackbar("Tạo đơn mới thành công", {
				variant: "success",
				autoHideDuration: 1000,
			});
		} catch (err) {
			enqueueSnackbar(err?.message || "Lỗii", {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);
		}
	};

	const [activeStep, setActiveStep] = React.useState(0);
	const isStepOptional = (step) => {
		return step === 1;
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const [noteVal, setNoteVal] = useState("");
	const renderBodyModal = () => {
		switch (activeStep) {
			case 0:
				return (
					<StepInfoOrder
						payType={payType}
						setPayType={setPayType}
						orderType={orderType}
						setOrderType={setOrderType}
						activity={activity}
						setActivity={setActivity}
						control={control}
						errors={errors}
						totalDiscount={totalDiscount}
						setTotalDiscount={setTotalDiscount}
						noteVal={noteVal}
						setNoteVal={setNoteVal}
						setCustomerPay={setCustomerPay}
					/>
				);
			case 1:
				return (
					<StepInfoCustomer
						setValueForm={setValue}
						payType={payType}
						control={control}
						errors={errors}
						clearErrors={clearErrors}
					/>
				);

			default:
				return <StepInfoOrder payType={payType} setPayType={setPayType} />;
		}
	};
	const renderFooterAction = () => {
		switch (activeStep) {
			case 0:
				if (infoCustomer.order_type === "COMBO") {
					return (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-end",
								margin: "20px 0",
							}}
						>
							<LoadingButton
								onClick={handleSubmit(onSubmit)}
								// endIcon={<SaveIcon />}
								loading={loading}
								loadingPosition="end"
								variant="contained"
							>
								Tạo đơn
							</LoadingButton>
						</Box>
					);
				} else {
					return orderType === "OFFLINE" ? (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-end",
								margin: "20px 0",
								gap: 1,
							}}
						>
							<LoadingButton
								onClick={
									infoCustomer.order_type === "INTERNAL"
										? (data) => confirmCreateInternalOrder(data)
										: onSubmit
								}
								loading={loading}
								loadingPosition="end"
								variant="contained"
							>
								Hoàn thành
							</LoadingButton>
						</Box>
					) : (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-end",
								margin: "20px 0",
							}}
						>
							<Button
								onClick={handleNext}
								// endIcon={<SaveIcon />}
								loading={loading}
								loadingPosition="end"
								variant="contained"
							>
								Bước tiếp theo
							</Button>
						</Box>
					);
				}

			case 1:
				return (
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
							margin: "20px 0",
						}}
					>
						<Button
							onClick={handleBack}
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="outlined"
						>
							Trở lại bước trước
						</Button>
						<Box>
							{/* <LoadingButton
								sx={{
									marginRight: "20px",
								}}
								onClick={onSubmit}
								// endIcon={<SaveIcon />}
								loading={loading}
								loadingPosition="end"
								variant="outlined"
							>
								Bỏ qua điền thông tin
							</LoadingButton> */}

							<LoadingButton
								onClick={handleSubmit(onSubmit)}
								// endIcon={<SaveIcon />}
								loading={loading}
								loadingPosition="end"
								variant="contained"
							>
								Hoàn thành
							</LoadingButton>
						</Box>
					</Box>
				);
			default:
				return <StepInfoOrder payType={payType} setPayType={setPayType} />;
		}
	};
	return (
		<Box
			sx={{
				borderTop: "1px dashed black",
			}}
		>
			{infoCustomer.order_type !== "INTERNAL" && (
				<Box sx={{ margin: "20px 100px", display: infoCustomer.order_type === "COMBO" && "none" }}>
					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							if (isStepOptional(index)) {
								labelProps.optional = <Typography variant="caption">Có thể bỏ qua</Typography>;
							}

							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				</Box>
			)}

			<Box>{renderBodyModal()}</Box>

			<Box sx={{ margin: "20px 0" }}>{renderFooterAction()}</Box>
		</Box>
	);
}

export default CreateOrderModal;
