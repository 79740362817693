import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Checkbox,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { memo, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import UploadImage from "ui-component/UploadImage";
import uploadAPI from "api/UploadAPI";
import bookingAPI from "api/BookingAPI";
import { ConfigProvider, Input, message, TimePicker } from "antd";
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import koLocale from "date-fns/locale/ko";
import ServiceArea from "./ProductArea";
import { useDispatch, useSelector } from "react-redux";
import styles from "./BookingModal.module.scss";
import classnames from "classnames/bind";
import { remove } from "lodash";
import OTPView from "./OTPView";
import OtpInput from "react-otp-input";
import moment from "moment";
//Firebase
import { generateRecaptcha } from "firebase/firebase-otp";
import { authentication, grecaptcha } from "firebase/firebase-config";
import { signInWithPhoneNumber } from "firebase/auth";
import FirebaseLogin from "views/pages/authentication/auth-forms/AuthLogin";
import { switchErrorMessage } from "firebase/firebase-function";
import authAPI from "api/AuthAPI";
import { getNumberWithCommas } from "utils";
import { getValue } from "@amcharts/amcharts4/.internal/core/utils/Type";
import { Close } from "@mui/icons-material";
import { convertDatePicker } from "convert";
import { width } from "@mui/system";
import setting from "menu-items/system_setting";
import "moment/locale/vi";
import viVN from "antd/es/date-picker/locale/vi_VN";
//DatePicker Antd

import { DatePicker, Space } from "antd";
import schedulerAPI from "api/SchedulerAPI";
import { ModalContext } from "providers/ModalProvider";
import SelectedService from "./SelectedService";
import DialogMain from "../DialogMui";
import UserComboService from "./UserComboService";
import { actSetUserCombo } from "store/actions/UserComboServiceAction";
import NoData from "ui-component/NoData";
import ProductArea from "./ProductArea";
import { PATTERN } from "constants/common";
const { RangePicker } = DatePicker;
const cx = classnames.bind(styles);

const defaultTypeList = [
	{
		value: "NOTIFICATION",
		title: "Notification",
	},
	{
		value: "TERMS_CONDITIONS",
		title: "Terms & Conditions",
	},
	{
		value: "LOCATION",
		title: "Location",
	},
];
const TextValidation = styled("span")({
	color: "red",
});

function OrderModal({ ...props }) {
	const { isOpen, isClose, isLoading, formData, isUpdate, listSelect, getListTable } = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));

	const { register, handleSubmit, errors, control, reset, getValues, watch, setError, setValue } =
		useForm({
			mode: "onChange",
		});

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [errorServiceList, setErrorServiceList] = useState({ status: false, message: "" });

	const [ckData, setCkData] = useState("");
	const [type, setType] = useState("NOTIFICATION");

	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleClose = () => {
		isClose(false);
		setStepOTP(1);
	};
	const comboUsing = useSelector((state) => state.userComboService.comboServices);

	const dispatch = useDispatch();
	const onSubmit = async (data, e, isPaid) => {
		e.preventDefault();

		let dateIO = moment(data.date).format("YYYY-MM-DD");
		let timeIO = moment(data.time).format("HH:mm");

		data.time_start = moment(`${dateIO}T${timeIO}`).valueOf();
		try {
			if (!isUpdate) {
				if (checkList.length === 0 && comboUsing.length === 0) {
					setLoading(false);
					setErrorServiceList({ status: true, message: "Hãy chọn ít nhất một dịch vụ" });
					return;
				} else {
					const noneComboArr = checkList.map((item) => {
						if (item.combo_id === null) {
							return {
								service_id: item.service_id,
								count: 1,
							};
						}
					});
					const inCombo = comboUsing.map((item) => ({
						service_id: item.service_id,
						count: item.count,
						combo_id: item.combo_id,
					}));

					const mergeArr = [...noneComboArr, ...inCombo];
					data.name = ""; //Đợi Backend xóa cột này thì xóa dòng này
					data.items = mergeArr;
					data.main_service = data.items[0].service_id;
					data.booking_type = isOtpView ? "WALLET" : "CASH";
					data.is_paid = isPaid ? true : false; //  CASH => Đặt lịch isPaid = false
					data.is_confirm = isOtpView ? true : false; //isOTPView để check người dùng bấm vào Ví dịch vụ hay tiền mặt
					data.slot_id = data.slot_id === "noneBed" ? null : data.slot_id;
					// data.activities = isPaid ? "CONFIRMED" : "WAITING";
					const response = await bookingAPI.create(data);
					if (response.code === 200) {
						isClose(false);
						setLoading(false);
						enqueueSnackbar(t(`success`), {
							variant: "success",
							autoHideDuration: 2000,
						});
						getListTable();
					} else {
						enqueueSnackbar(t(`error`), {
							variant: "error",
							autoHideDuration: 2000,
						});
					}
				}
			} else {
				data.items = checkList.map((item) => item.id);

				const response = await bookingAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};
	const resetModal = () => {
		setIsOtpView(false);
		setIsUser(false);
		setOtpValue("");
	};
	useEffect(() => {
		if (isUpdate) {
			setCkData(formData?.implementation_process);
			setType(formData?.category_service_id);
		} else {
			setCkData("");
			setType(selectList && selectList[0].value);
		}
		return () => {
			resetModal();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const translator = (text) => {
		return `abeauty.booking.dialog_form.${text}`;
	};

	const checkList = useSelector((state) => state.booking.checkBoxList);
	const totalPrice = checkList.reduce(
		(prev, current) => Number(prev * 1 + (1 * current.service.price - current.service.discount)),
		0
	);
	//Phương thức thanh Toán

	const [isOtpView, setIsOtpView] = useState(false);
	const [otpValue, setOtpValue] = useState("");
	const handleChangeOtp = (otpValue) => {
		setOtpValue(otpValue);
	};
	//Check số diện thoại có phải user không

	const [isUser, setIsUser] = useState(false);
	const [userInfor, setUserInfor] = useState();
	const fetchCheckUser = async (phoneNum) => {
		const res = await authAPI.checkPhoneRegistered(phoneNum);
		return res;
	};
	const handleCheckIsUser = async () => {
		//Call API check pone

		const phoneNum = getValues("phone");
		try {
			const res = await fetchCheckUser(phoneNum);

			if (res.code === 200) {
				//Code 200 : Số điện thoại chưa được đăng ký => showError not user
				setError("phone", {
					type: "checkPhone",
					message: "Bạn có thể thanh toán bằng hạng mức dịch vụ với số điện thoại này",
					isCanUse: true,
				});
				setIsUser(true);
				setUserInfor(res.results.object);
			}
		} catch (err) {
			if (err.code === 501) {
				//code === 501 số điện thoại này đã có trong hệ thống
				setError("phone", {
					type: "checkPhone",
					message: "Số điện thoại này chưa được đăng ký",
				});
				setIsUser(false);
			} else {
				setError("phone", {
					type: "checkPhone",
					message: err.message,
				});
				setIsUser(false);
			}
		}
	};
	useEffect(() => {
		if (isUser === false) {
			setError("phone", {
				type: "checkPhone",
				message: "",
			});
		}
	}, [isUser]);

	///=======+++++++=======FIREBASE OTP CODE HERE ++++++++========+++++++++++

	const [errorFirebase, setErrorFirebase] = useState({ state: false, message: "" });
	const [stepOTP, setStepOTP] = useState(1);
	const [sendedOTP, setSendedOTP] = useState(false);
	const requestOTP = (e) => {
		e.preventDefault();
		setSendedOTP(true);
		setErrorFirebase({ state: false, message: "" });

		generateRecaptcha();
		const appVerifier = window.recaptchaVerifier;
		const phoneNum = getValues("phone");
		signInWithPhoneNumber(authentication, `+84${phoneNum}`, appVerifier)
			.then((confirmationResult) => {
				setStepOTP(2);
				// SMS sent. Prompt user to type the code from the message, then sign the
				// user in with confirmationResult.confirm(code).
				window.confirmationResult = confirmationResult;
				// ...
			})
			.catch((error) => {
				const errMessage = switchErrorMessage(error);

				setErrorFirebase({ state: true, message: errMessage });
				// Error; SMS not sent
				// ...
			});
	};

	const verifyOTP = () => {
		setErrorFirebase({ state: false, message: "" });

		if (otpValue.length < 6) return;
		let confirmationResult = window.confirmationResult;
		confirmationResult
			.confirm(otpValue)
			.then((result) => {
				setStepOTP(3);
				enqueueSnackbar("Xác thực thành công", {
					variant: "success",
					autoHideDuration: 2000,
				});
			})
			.catch((err) => {
				const errMessage = switchErrorMessage(err);
				//SetShowErrorFireBase
				setErrorFirebase({ state: true, message: errMessage });
			});
	};

	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		//Sử  dụng để tránh lần đầu component mount sử dụng function
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		} else {
			if (checkList.length === 0 && comboUsing.length === 0) {
				setErrorServiceList({ status: true, message: "Hãy chọn ít nhất một dịch vụ" });
			} else {
				setErrorServiceList({ status: false, message: "" });
			}
		}
	}, [checkList, comboUsing]);

	const payByMoneyBtn = useRef();

	const minDateTime = new Date();
	minDateTime.setHours(17);
	const maxDateTime = new Date();
	maxDateTime.setHours(20);
	const date = new Date();

	//Get Bed Group

	const [bedGroup, setBedGroup] = useState([]);
	const getBedGroup = async () => {
		setLoading(true);

		const params = {
			fields: '["$all"]',
		};
		try {
			const res = await schedulerAPI.getBedList(params);
			const bedGroupConvert = res.results?.objects?.rows?.map((item) => ({
				id: item.id,
				text: item.name,
			}));
			const dataBed = [
				//Thêm giường chưa xác định

				...bedGroupConvert,
				{
					id: "noneBed",
					text: "Chọn giường sau",
				},
			];

			setBedGroup(dataBed);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};
	const modalContext = useContext(ModalContext);
	const handleConfirmModal = (data, e, isPaid) => {
		modalContext.showDeleteConfirm(
			null,
			() => onSubmit(data, e, isPaid),
			isPaid
				? "Chắc chắn lich hẹn này đã nhận được tiền?"
				: "Chắc chắn lịch hẹn này chưa được trả tiền?",
			"Chắc chắn"
		);
	};

	//Get list bed group
	useEffect(() => {
		getBedGroup();
	}, []);

	// Modal Combo dịch vụ

	const [comboService, setComboService] = useState({
		visible: false,
		data: null,
	});

	const handleOpenComboService = () => {
		setComboService((prev) => ({
			...prev,
			visible: true,
		}));
	};
	return (
		<>
			<div>
				<Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
					<DialogTitle
						sx={{
							fontSize: "18px",
							textAlign: "center",
						}}
					>
						{isUpdate ? t(translator("header_edit")) : t(translator("header_create"))}
					</DialogTitle>
					<form onSubmit={handleSubmit(onSubmit)}>
						<DialogContent>
							<Grid
								container
								spacing={1}
								alignItems={"center"}
								style={{
									marginBottom: 20,
								}}
							>
								{/* <Grid item xs={12}>
								<Controller
									name="name"
									control={control}
									rules={{ required: true }}
									as={
										<TextField
											margin="dense"
											label={t(translator("name")) + " *"}
											type="text"
											fullWidth
											size="small"
											defaultValue={isUpdate ? formData?.name : ""}
											variant="filled"
										/>
									}
								/>

								{errors.name && <TextValidation>{t(translator("required_name"))}</TextValidation>}
							</Grid> */}

								<Grid xs={8} item>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Controller
											name="phone"
											control={control}
											rules={{
												required: true,
												minLength: 10,
												pattern: PATTERN.PHONE_NUMBER_VN,
											}}
											as={
												<TextField
													sx={{ borderRadius: "none", flex: 3 }}
													margin="dense"
													size="small"
													label={t(translator("phone")) + " *"}
													type="text"
													fullWidth
													defaultValue={isUpdate ? formData?.phone : ""}
													variant="filled"
													disabled={isUser}
												/>
											}
										/>
										{!isUser ? (
											<LoadingButton
												// endIcon={<SaveIcon />}
												sx={{
													height: "100%!important",
													padding: "11px!important",
													borderRadius: "0",
													marginTop: "4px",
													width: "20%!important",
													flex: 1,
													zIndex: 200,
												}}
												disabled={!watch("phone") || errors.phone}
												loading={loading}
												loadingPosition="end"
												variant="contained"
												className={cx("button__action")}
												onClick={handleCheckIsUser}
											>
												Xác thực
											</LoadingButton>
										) : (
											<LoadingButton
												// endIcon={<SaveIcon />}
												sx={{
													height: "100%!important",
													padding: "11px!important",
													borderRadius: "0",
													marginTop: "4px",
													width: "20%!important",
													flex: 1,
													zIndex: 200,
												}}
												disabled={!watch("phone")}
												loading={loading}
												loadingPosition="end"
												variant="contained"
												className={cx("button__action")}
												onClick={() => {
													setIsUser(false);
													dispatch(actSetUserCombo([]));
													payByMoneyBtn.current.click();
												}}
											>
												Thay đổi SĐT
											</LoadingButton>
										)}
									</Box>

									{errors.phone && errors.phone.type === "required" && (
										<TextValidation>{t(translator("required_phone"))}</TextValidation>
									)}
									{errors.phone && errors.phone.type === "minLength" && (
										<TextValidation>Số điện thoại có ít nhất 10 số</TextValidation>
									)}
									{errors.phone && errors.phone.type === "pattern" && (
										<TextValidation>Số điện thoại này không đúng định dạng</TextValidation>
									)}
									{errors.phone && errors.phone.type === "checkPhone" && (
										<TextValidation sx={{ color: errors.phone.isCanUse && "green" }}>
											{errors.phone.message}
										</TextValidation>
									)}
								</Grid>

								<Grid xs={4} item>
									<LoadingButton
										// endIcon={<SaveIcon />}
										sx={{
											width: "100%",
											height: "100%!important",
										}}
										size="large"
										disabled={!isUser}
										loading={loading}
										loadingPosition="end"
										variant="contained"
										onClick={handleOpenComboService}
									>
										Gói dịch vụ đã mua
									</LoadingButton>
								</Grid>

								<Grid item xs={6}>
									<Controller
										name="date"
										control={control}
										defaultValue={moment()}
										rules={{
											required: "Vui lòng chọn ngày thực hiện",
											validate: {
												lessThan: (v) =>
													moment(v).format("YYYY-MM-DD").valueOf() >=
														moment().format("YYYY-MM-DD").valueOf() ||
													"Bạn không thể đặt lịch trong quá khứ",
											},
										}}
										onChange={(e) => {
											console.log(e.target.value);
										}}
										as={
											<DatePicker
												className={cx("booking_modal_timePicker")}
												placeholder="Ngày thực hiện"
												style={{
													width: "100%",
												}}
												format={"DD/MM/YYYY"}
												showNow
												locale={viVN}
												size={"large"}
											/>
										}
									/>
									{errors.date && <TextValidation>{errors.date.message}</TextValidation>}
								</Grid>
								<Grid item xs={6}>
									<Controller
										name="time"
										control={control}
										rules={{
											required: "Vui lòng giờ thực hiện",
											validate: {
												minutes: (v) =>
													moment(v).minute() === 0 ||
													moment(v).minute() === 30 ||
													"Chỉ có thể chọn số phút là 00 hoặc 30",
												lessThan: (v) => {
													if (
														moment(getValues("date")).format("YYYY-MM-DD").valueOf() ===
														moment().format("YYYY-MM-DD").valueOf()
													) {
														return (
															moment(v).format("HH:mm").valueOf() >
																moment().format("HH:mm").valueOf() ||
															"Bạn không thể đặt lịch trong quá khứ"
														);
													}
												},
											},
										}}
										as={
											<TimePicker
												className={cx("booking_modal_timePicker")}
												placeholder="Giờ thực hiện"
												style={{
													width: "100%",
												}}
												format={"HH:mm"}
												locale={viVN}
												minuteStep={30}
												size={"large"}
											/>
										}
									/>
									{errors.time && <TextValidation>{errors.time.message}</TextValidation>}
								</Grid>
								{/* <Grid item xs={12}>
								<Controller
									as={
										<TextField
											margin="dense"
											label={t(translator("email"))}
											type="text"
											fullWidth
											size="small"
											defaultValue={isUpdate ? formData?.email : ""}
											variant="filled"
										/>
									}
									name="email"
									control={control}
									// rules={{
									// 	required: true,
									// 	pattern:
									// 		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									// }}
								/>
								{/* {errors.email && errors.email.type === "required" && (
									<TextValidation>{t(translator("required_email"))}</TextValidation>
								)}
								{errors.email && errors.email.type === "pattern" && (
									<TextValidation>Email chưa đúng định dạng</TextValidation>
								)} 
							</Grid> */}

								<Grid item xs={12}>
									<Controller
										as={
											<TextField
												margin="dense"
												multiline
												label={t(translator("message"))}
												rows={2}
												type="text"
												fullWidth
												defaultValue={isUpdate ? formData?.message : ""}
												variant="filled"
											/>
										}
										name="message"
										control={control}
									/>
								</Grid>
							</Grid>
							<Divider />
							<DialogTitle
								sx={{
									fontSize: "16px",
								}}
							>
								Chọn sản phẩm
							</DialogTitle>
							<Grid item xs={12}>
								<Box
									sx={{
										padding: "10px",
									}}
								>
									<ProductArea
										sx={{
											margin: "10px 0px",
										}}
										control={control}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sx={{ margin: "12px 0" }}>
								<DialogTitle
									sx={{
										fontSize: "16px",
									}}
								>
									Dịch vụ đã chọn
								</DialogTitle>

								{/* SELECTED AREA */}

								{errorServiceList.status ? (
									<NoData text={"Vui lòng chọn ít nhất một dịch vụ"} />
								) : (
									<SelectedService />
								)}
							</Grid>

							<Divider />
							<Grid item xs={12} sx={{ margin: "12px 0" }}>
								<DialogTitle
									sx={{
										fontSize: "16px",
									}}
								>
									Chọn giường
								</DialogTitle>
								<Controller
									as={
										<Select
											// labelId="demo-simple-select-autowidth-label"
											// id="form"
											// autoWidth
											label={" "}
											sx={{ margin: "24px", width: "30%" }}
										>
											{bedGroup.map((item) => (
												<MenuItem key={item.id} value={item.id}>
													{item.text}
												</MenuItem>
											))}
										</Select>
									}
									defaultValue={"noneBed"}
									name="slot_id"
									control={control}
								/>
							</Grid>

							<Divider />

							<Grid
								item
								xs={12}
								container
								spacing={1}
								style={{
									marginBottom: 20,
								}}
							>
								<Grid item xs={5}>
									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
									>
										Phương thức thanh toán
									</DialogTitle>
								</Grid>
								<Grid item xs={7} sx={{ padding: "30px!important" }}>
									<Button
										variant="contained"
										className={cx("button__pay", isOtpView ? "" : "active")}
										sx={{ marginRight: "20px", background: "#000" }}
										onClick={() => setIsOtpView(false)}
										ref={payByMoneyBtn}
									>
										TIỀN MẶT
									</Button>

									<Button
										variant="contained"
										className={cx("button__pay", isOtpView ? "active" : "")}
										sx={{ background: "#000" }}
										onClick={() => setIsOtpView(true)}
										disabled={!isUser}
									>
										HẠN MỨC DỊCH VỤ
									</Button>
								</Grid>
								<Grid item xs={12}>
									<DialogTitle
										sx={{
											fontSize: "12px",
										}}
									>
										{isOtpView
											? `Mã OTP sẽ được gửi đến số điện thoại ${watch("phone") || ""}`
											: "Thanh toán trực tiếp tại cửa hàng"}
									</DialogTitle>
									<Box sx={{ padding: "20px", width: "100%" }}>
										{isOtpView && (
											<Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
												<OtpInput
													value={otpValue}
													inputStyle={{
														width: "40px",
														height: "40px",
														borderRadius: "3px",
														marginRight: "5px",
														outline: "none",
														border: "none",
														background: "#F2F2F2",
													}}
													onChange={handleChangeOtp}
													numInputs={6}
												/>
												<div id="recaptcha-button"></div>
											</Box>
										)}

										{errorFirebase.state && (
											<TextValidation>{errorFirebase.message}</TextValidation>
										)}
									</Box>
								</Grid>
							</Grid>
							<Divider />
							<Grid item xs={12}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
									>
										Tổng tiền thanh toán
									</DialogTitle>
									<DialogTitle
										sx={{
											fontSize: "16px",
										}}
									>
										{getNumberWithCommas(totalPrice)} đ
									</DialogTitle>
								</Box>
							</Grid>
						</DialogContent>
						<DialogActions
							sx={{
								justifyContent: "center",
							}}
						>
							{isOtpView ? (
								stepOTP == 1 ? (
									<LoadingButton
										// endIcon={<SaveIcon />}
										loading={loading}
										loadingPosition="end"
										variant="contained"
										className={cx("button__action")}
										disabled={!watch("phone") || (stepOTP === 1 && sendedOTP)}
										onClick={requestOTP}
									>
										GỬI MÃ OTP
									</LoadingButton>
								) : stepOTP == 2 ? (
									<LoadingButton
										// endIcon={<SaveIcon />}
										loading={loading}
										loadingPosition="end"
										variant="contained"
										className={cx("button__action")}
										disabled={otpValue.length < 6}
										onClick={verifyOTP}
									>
										XÁC THỰC OTP
									</LoadingButton>
								) : (
									stepOTP == 3 && (
										<LoadingButton
											// endIcon={<SaveIcon />}
											//type Submit là mặc định sử dụng submit của form
											type="submit"
											loading={loading}
											loadingPosition="end"
											variant="contained"
											className={cx("button__action")}
											disabled={otpValue.length < 6}
										>
											ĐẶT LỊCH
										</LoadingButton>
									)
								)
							) : (
								<>
									<LoadingButton
										// endIcon={<SaveIcon />}
										loading={loading}
										loadingPosition="end"
										variant="contained"
										className={cx("button__action")}
										onClick={handleSubmit((data, e) => handleConfirmModal(data, e, true))}
									>
										THANH TOÁN
									</LoadingButton>{" "}
									<LoadingButton
										// endIcon={<SaveIcon />}
										loading={loading}
										loadingPosition="end"
										variant="contained"
										className={cx("button__action")}
										onClick={handleSubmit((data, e) => handleConfirmModal(data, e, false))}
									>
										ĐẶT LỊCH
									</LoadingButton>
								</>
							)}
						</DialogActions>

						<Snackbar
							anchorOrigin={{ vertical: "top", horizontal: "center" }}
							open={showErr.state}
							onClose={() => setShowErr({ state: false })}
							// action={action}
							autoHideDuration={5000}
							severity="error"
						>
							<Alert severity="error">{showErr.message}</Alert>
						</Snackbar>
					</form>
					<Close
						sx={{
							position: "absolute",
							top: "10px",
							right: "10px",
							cursor: "pointer",
						}}
						onClick={() => handleClose()}
					/>
				</Dialog>
			</div>
			<DialogMain
				maxWidth={"md"}
				title={"Combo dịch vụ đã mua"}
				fullWidth
				open={comboService.visible}
				onClose={() => {
					setComboService((prev) => ({
						...prev,
						visible: false,
					}));
				}}
			>
				<UserComboService
					onCloseModal={() => {
						setComboService((prev) => ({
							...prev,
							visible: false,
						}));
					}}
					userPhoneNum={userInfor?.phone || "0788781801"}
				/>
			</DialogMain>
		</>
	);
}

export default OrderModal;
