import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Avatar, Chip, Popper, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// third-party

// project imports
// import User1 from "../../../../assets/images/users/user-round.svg";

// assets
import { IconSettings } from "@tabler/icons";
import { IMAGES } from "assets/images/imagePath";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useMatch, useNavigate, useParams } from "react-router";
import { logout } from "store/actions/EmployeeAction";
import ProfileTransitions from "./ProfileTransitions";
import { Print, PrintOutlined } from "@mui/icons-material";
import PrintTransition from "./PrintTransition";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
	const theme = useTheme();
	const employeeInfo = useSelector((state) => state?.employee.employeeInfo);

	const [open, setOpen] = useState(false);
	const [idElm, setIdElm] = useState("");

	/**
	 * anchorRef is used on different componets and specifying one type leads to other components throwing an error
	 * */
	const anchorRef = useRef(null);
	const anchorRefPrint = useRef(null);

	const { t } = useTranslation();

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		if (anchorRefPrint.current && anchorRefPrint.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};
	const [anchorEl, setAnchorEl] = useState(null);
	const handleToggle = (id) => (e) => {
		const DOM = e.currentTarget;
		setAnchorEl(DOM);
		setOpen((prevOpen) => DOM !== anchorEl || !prevOpen);
		setIdElm(id);
	};
	useEffect(() => {
		// if (prevOpen.current === true && open === false) {
		// 	anchorRef.current.focus();
		// }
		if (!open) {
			setIdElm("");
		}
	}, [open]);

	const [renderPopper, setRenderPopper] = useState({
		component: ProfileTransitions,
		anchorEl: anchorRef,
	});

	useEffect(() => {
		switch (idElm) {
			case "profile-btn":
				setRenderPopper({
					component: ProfileTransitions,
					anchorEl: anchorRef,
				});
				return;
			case "print-btn":
				setRenderPopper({
					component: PrintTransition,
					anchorEl: anchorRefPrint,
				});
				return;
			default:
				setRenderPopper({
					component: ProfileTransitions,
					anchorEl: anchorRef,
				});
				return;
		}
	}, [idElm]);

	const location = useLocation();

	var isCreateOrder = location.pathname === "/cashier/create-order";
	return (
		<Stack flexDirection={"row"} gap={"20px"}>
			{isCreateOrder && (
				<Chip
					sx={{
						height: "48px",
						alignItems: "center",
						borderRadius: "27px",
						transition: "all .2s ease-in-out",
						borderColor: theme.palette.primary.light,
						backgroundColor: theme.palette.primary.light,
						'&[aria-controls="menu-list-grow"], &:hover': {
							borderColor: theme.palette.primary.main,
							background: `${theme.palette.primary.main}!important`,
							color: theme.palette.primary.light,
							"& svg": {
								stroke: theme.palette.primary.light,
							},
						},
						"& .MuiChip-label": {
							lineHeight: 0,
						},
						cursor: "pointer",
					}}
					label={<PrintOutlined stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
					variant="outlined"
					ref={anchorRefPrint}
					aria-controls={idElm === anchorRefPrint.current?.id ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					id="print-btn"
					onClick={handleToggle("print-btn")}
					color="primary"
				/>
			)}
			<Chip
				sx={{
					height: "48px",
					alignItems: "center",
					borderRadius: "27px",
					transition: "all .2s ease-in-out",
					borderColor: theme.palette.primary.light,
					backgroundColor: theme.palette.primary.light,
					'&[aria-controls="menu-list-grow"], &:hover': {
						borderColor: theme.palette.primary.main,
						background: `${theme.palette.primary.main}!important`,
						color: theme.palette.primary.light,
						"& svg": {
							stroke: theme.palette.primary.light,
						},
					},
					"& .MuiChip-label": {
						lineHeight: 0,
					},
				}}
				icon={
					<Avatar
						src={employeeInfo?.object?.avatar || IMAGES.avatar_default}
						sx={{
							...theme.typography.mediumAvatar,
							margin: "8px 0 8px 8px !important",
							cursor: "pointer",
							backgroundColor: "#fff",
							"& img": {
								width: "100%",
								height: "auto",
								objectFit: "contain",
							},
						}}
						aria-haspopup="true"
						color="inherit"
					/>
				}
				label={
					<Stack flexDirection={"row"} gap={"5px"} alignItems="center">
						<Typography fontWeight={"bold"}>
							{employeeInfo?.object?.fullname || employeeInfo?.object?.username}
						</Typography>
						<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />
					</Stack>
				}
				variant="outlined"
				ref={anchorRef}
				aria-controls={idElm === anchorRef.current?.id ? "menu-list-grow" : undefined}
				aria-haspopup="true"
				id="profile-btn"
				onClick={handleToggle("profile-btn")}
				color="primary"
			/>

			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorEl}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [0, 14],
							},
						},
					],
				}}
			>
				{({ TransitionProps }) => {
					const Component = renderPopper.component;
					return <Component open={open} handleClose={handleClose} {...TransitionProps} />;
				}}
			</Popper>
		</Stack>
	);
};

export default ProfileSection;
