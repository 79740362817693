import styled from "@emotion/styled";
import { Print } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Modal, Typography } from "antd";
import { IMAGES } from "assets/images/imagePath";
import { COLOR } from "constants/common";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

function ImageModal({
	openModalZoom,
	handleCloseModal,
	imageZoom,
	title = "Qr code",
	multi,
	hasFooter = false,
	classNameImg,
	PrintComponent,
	onOpenPageSelect,
	modalProps = {},
	customImageStyle = {},
}) {
	const PreviewImg = styled("img")({
		width: "100%",
		borderRadius: "8px",
		maxWidth: "600px",
		padding: "20px",
		objectFit: "cover",
		objectPosition: "center",
	});

	const [image, setImage] = useState(multi ? imageZoom[0] : imageZoom); //Nếu multi imageZoom là một []
	const [imageActive, setImageActive] = useState(0);
	useEffect(() => {
		if (openModalZoom) {
			setImage(multi ? imageZoom[0] : imageZoom);
			setImageActive(0);
		}
	}, [openModalZoom]);

	//Print Func
	const componentRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: true,
	});
	return (
		<Modal
			centered
			visible={openModalZoom}
			onCancel={handleCloseModal}
			footer={null}
			zIndex="10000"
			{...modalProps}
		>
			<Typography id="modal-modal-title" variant="h3" component="h1">
				{title}
			</Typography>
			<Box
				sx={{
					"& .MuiTextField-root": {
						mt: 3,
					},
				}}
			>
				<Box
					sx={{
						textAlign: "center",
					}}
				>
					{multi ? (
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Box>
								<PreviewImg
									src={image || IMAGES.img_default}
									onError={(e) => (e.target.src = IMAGES.img_default)}
									alt="zoom"
									className={classNameImg}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									overflow: "auto",
								}}
							>
								{Array.isArray(imageZoom) &&
									imageZoom.map((image, idx) => {
										const activeStyle =
											imageActive === idx
												? {
														border: "1px solid black",
												  }
												: {};
										return (
											<PreviewImg
												onClick={() => {
													setImageActive(idx);
													setImage(image);
												}}
												style={{
													width: "40%",
													cursor: "pointer",
													margin: "20px 10px",
													padding: "0",
													...activeStyle,
												}}
												className={classNameImg}
												src={image || IMAGES.img_default}
												onError={(e) => (e.target.src = IMAGES.img_default)}
												alt="imageSmall"
											/>
										);
									})}
							</Box>
						</Box>
					) : (
						<>
							<div ref={componentRef} className="content-print">
								{PrintComponent}
							</div>
							<Box display={"flex"} justifyContent="center" alignItems={"center"}>
								<PreviewImg
									className={classNameImg}
									src={imageZoom || IMAGES.img_default}
									style={{
										...customImageStyle,
									}}
									alt="imageModal"
									onError={(e) => (e.target.src = IMAGES.img_default)}
								/>
							</Box>
						</>
					)}

					{hasFooter && (
						<Box>
							<Print
								onClick={onOpenPageSelect}
								sx={{
									width: "50px",
									height: "50px",
									color: COLOR.MAIN_COLOR,
									cursor: "pointer",
								}}
								fontSize={"large"}
							/>
						</Box>
					)}

					{/* <RemoveImg onClick={() => setImageZoom("")}>{t(`remove`)}</RemoveImg> */}
				</Box>
			</Box>
		</Modal>
	);
}

export default ImageModal;
