const ICON = {
	no_service: require(`./icons/icon-no-service.svg`).default,
};

const IMAGES = {
	appQr: require(`./imgs/qr_app.jpeg`).default,
	logo: require(`./imgs/new_logo_abeauty.png`).default,
	logo_rec: require(`./imgs/logo_rec.png`).default,
	_logo: require(`./imgs/_logo.png`).default,
	avatar_default: require(`./users/metaImg1.png`).default,
	img_default: require(`./users/imgDefault.jpeg`).default,
	sold_out: require(`./imgs/soldout.jpeg`).default,
	page72_22: require(`./imgs/printPage72_22.jpeg`).default,
	page102_22: require(`./imgs/printPage102_22.jpeg`).default,
};

//   export { ICON };
export { IMAGES, ICON };
