import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Stack,
	Tab,
	Tabs,
} from "@mui/material";
import serviceAPI from "api/ServiceAPI";
import NoProduct from "components/NoProduct";
import { SearchComponent } from "components/search";
import { COLOR } from "constants/common";
import { formatVND } from "func";
import useDebounce from "hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actSetCheckList } from "store/actions/BookingActions/action";

function ServiceArea({ control, ...props }) {
	const tabListDefault = [
		{
			id: null,
			title: "Tất cả",
		},
	];
	const [tabList, setTabList] = useState([]);
	const [activeTab, setActiveTab] = useState(0); //ImpTabortant
	//categoryId set Filter

	const [categoryId, setCategoryId] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const debounceValue = useDebounce(searchValue);
	const [serviceList, setServiceList] = useState([]);

	const handleChange = (e, newValue) => {
		setActiveTab(newValue);
	};
	const handleChangeId = (id) => {
		setCategoryId(id);
	};

	const fetchCategory = async () => {
		try {
			const res = await serviceAPI.getCategory({
				fields: '["$all"]',
				order: '[["order", "asc"]]',
			});
			setTabList([...tabListDefault, ...res.results.objects.rows]);
		} catch (err) {
			console.log(err);
		}
	};
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const fetchServiceList = async () => {
		const filter = {
			category_service_id: categoryId,
			branch_id: branchActiveId,
		};
		Object.keys(filter).forEach(function (key, index) {
			if (!!filter[key] === false) {
				delete filter[key];
			}
		});

		// if (!categoryId) {
		// 	delete filter.category_service_id;
		// }
		try {
			const res = await serviceAPI.searchService({
				fields: '["$all", {"unit": ["value"]}]',
				service: debounceValue,
				filter: JSON.stringify(filter),
				limit: 10000,
			});
			setServiceList(res.results.objects.rows);
		} catch (err) {
			console.log(err);
		}
	};
	//Logic check to push
	const dispatch = useDispatch();
	const checkList = useSelector((state) => state.booking.checkBoxList);
	const handleCheck = (e, service) => {
		let checked = e.target.checked;

		dispatch(actSetCheckList({ checked, service }));

		// if (e.target.checked) {
		// 	dispatch(actSetCheckList([...checkList, service]));
		// } else dispatch(actSetCheckList(checkList.filter((item) => item.id !== service.id)));
	};
	const selectedServices = useSelector((state) => state.booking.checkBoxList);

	useEffect(() => {
		fetchCategory();
		fetchServiceList();
		return () => {
			dispatch(actSetCheckList({ checked: "reset", service: [] }));
		};
	}, []);
	const [force, setForce] = useState(0);
	useEffect(() => {
		//Bắt buộc component re-render
		setForce(force + 1);
	}, [selectedServices]);
	useEffect(() => {
		fetchServiceList();
	}, [categoryId, debounceValue]);
	return (
		<Box {...props}>
			<Tabs
				value={activeTab}
				onChange={handleChange}
				variant="standard"
				sx={{
					"& .MuiTabs-flexContainer": {
						flexWrap: "wrap",
						gap: 1,
					},
				}}
				TabIndicatorProps={{ sx: { display: "none" } }}
				scrollButtons
				allowScrollButtonsMobile
				aria-label="scrollable force tabs example"
			>
				{Array.isArray(tabList) &&
					tabList.map((tab) => (
						<Tab
							sx={{
								border: "1px solid black",
								borderRadius: "5000px",
								fontSize: "12px",
								minHeight: "20px",
								"&.Mui-selected": {
									color: "#fff",
									background: COLOR.MAIN_COLOR,
								},
							}}
							key={tab.id}
							label={tab.title}
							onClick={() => handleChangeId(tab.id)}
						/>
					))}
			</Tabs>
			<Box sx={{ p: 3 }}>
				<SearchComponent
					searchValue={searchValue}
					handleChangeSearch={(value) => setSearchValue(value)}
					dropdown={false}
					placeholder={"Tìm kiếm dịch vụ"}
				/>
			</Box>
			<Box
				sx={{
					maxHeight: "300px",
					overflow: "auto",
				}}
			>
				{serviceList.length === 0 ? (
					<Stack
						width={"100%"}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "300px",
						}}
					>
						<NoProduct text={"Không có dịch vụ nào"} />
					</Stack>
				) : (
					<FormControl required component="fieldset" sx={{ m: 3 }} variant="standard">
						<FormGroup>
							{serviceList.map((service, idx) => (
								<FormControlLabel
									key={service.id}
									control={
										<Checkbox
											onChange={(e) => handleCheck(e, service)}
											defaultChecked={checkList.some((item) => item.service_id === service.id)}
											checked={checkList.some((item) => item.service_id === service.id)}
										/>
									}
									label={
										<Box>
											{service.title} - {formatVND(service.price_deducted)}
											{service.unit_id && `/${service.unit?.value}`}
										</Box>
									}
								/>
							))}
						</FormGroup>
					</FormControl>
				)}
			</Box>
		</Box>
	);
}

export default ServiceArea;
