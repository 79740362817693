import moment from "moment";

export const exportResults = (res) => res;

export const convertParams = (params) => {
	return Object.fromEntries(
		Object.entries(params).map(([key, value]) => [key, JSON.stringify(value)])
	);
};

export const convertDatePicker = (date, time) => {
	const dateFormat = moment(date).format("YYYY-MM-DD");
	const result = moment(dateFormat)
		.add(time?.split(":")[0], "hours")
		.add(time?.split(":")[1], "minutes")
		.utc()
		.valueOf();
	return result;
};
