import { LinkOutlined } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";

function LinkIcon({ ...props }) {
	return (
		<Link
			{...props}
			style={{
				display: "flex",
				alignItems: "center",
			}}
		>
			<LinkOutlined />
		</Link>
	);
}

export default LinkIcon;
