import axiosClient from "api/axiosClient";

const uploadAPI = {
	async uploadImage(data, size = 1000) {
		const url = `/image/upload/${size}`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},
};

export default uploadAPI;
