// assets
import { IconNotification, IconSettings, IconLetterCase, IconChartArcs } from "@tabler/icons";
import { ROLE } from "constants/employeeRole";

// constant
const icons = { IconNotification, IconSettings, IconLetterCase, IconChartArcs };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const dashboard = (role) => ({
	id: "dashboard",
	title: "Thống kê",
	type: "group",
	visible: role?.DASHBOARD === ROLE.DASHBOARD,

	children: [
		{
			id: "dashboard",
			title: "Dashboard",
			type: "item",
			url: "dashboard",
			icon: icons.IconChartArcs,
			breadcrumbs: false,
			badge: false,
			visible: role?.DASHBOARD === ROLE.DASHBOARD,
		},
	],
});

export default dashboard;
