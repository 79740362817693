import { lazy } from "react";
import MuiScheduler from "ui-component/MuiScheduler";
import Dashboard from "views/dashboard/Default";
import Banner from "views/general/Banner";
import Booking from "views/general/Booking";
import Offer from "views/general/Offer";
import Product from "views/general/Product";
import ProductCategory from "views/general/ProductCategory";
import RegisterTest from "views/general/RegisterTest";
import ServiceCategory from "views/general/ServiceCategory";
import ServiceCombo from "views/general/ServiceCombo";
import Order from "views/general/Order";
import OrderItem from "views/general/OrderItem";
import Loadable from "../ui-component/Loadable";
import CreateOrder from "views/pages/CreateOrder";
import RechargeWithdraw from "views/general/RechargeWithdraw";
import DiscountHis from "views/general/DiscountHis";
import UserVerify from "views/general/UserVerify";
import WalletHis from "views/general/WalletHis";
import SettingCMS from "views/Setting/SettingCMS";
import ScanHis from "views/scanskin/ScanHis";
import ContentScanSkin from "views/scanskin/ContentScanSkin";
import OrderPrintTemplate from "components/PrintTemplate/OrderPrintTemplate";
import ProductQr from "components/PrintTemplate/ProductQr";
import GiftRegister from "views/scanskin/GiftRegister";
import Supplier from "views/Partner/Supplier";
import GroupScanSkinProduct from "views/scanskin/GroupScanSkinProduct";
import Voucher from "views/general/Voucher";
import Permission from "views/pages/Permission";
import ShareLink from "views/general/ShareLink";
import PreferentialUser from "components/NestedComponent/PreferentialUser";
import ConfirmChangeWallet from "views/Partner/ConfirmChangeWallet";
import Branch from "views/general/Branch";
import PostCategory from "views/general/PostCategory";
import Post from "views/general/Post";
import NotPermission from "components/NotPermission";
import NotificationSended from "views/notification/NotifiicationSended";
import PostInfo from "views/pages/PostInfo";
import JoinCollab from "views/general/JoinCollab";
import UserInformation from "ui-component/modal-form/ModalAntd/DetailInformation/UserInformation";
import MainCard from "ui-component/cards/MainCard";
import ProductDetailPage from "views/pages/ProductDetailPage";
import CheckInventory from "views/goods/CheckInventory";
import CheckInventoryDetail from "views/pages/CheckInventoryDetail";
import RechargeWithDrawPage from "views/pages/RechargeWithDrawDetail";
import InternalOrder from "views/general/InternalOrder";
import UserDetailPage from "views/pages/UserDetailPage";
import BookingDetail from "views/pages/BookingDetail";
import ServiceDetail from "views/pages/ServiceDetail";
import OrderDetailPage from "views/pages/OrderDetailPage";
import ConfirmChangeWalletModal from "ui-component/modal-form/ConfirmChangeWalletModal/ConfirmChangeWalletModal";
import ConfirmChangeWalletDetail from "views/pages/ConfirmChangeWalletDetail";

// dashboard routing

// utilities routing
const User = Loadable(lazy(() => import("views/general/Users")));
const UserForm = Loadable(lazy(() => import("views/general/userForm")));

//Route
const Qrcode = Loadable(lazy(() => import("views/general/qrcode")));

//Transaction
const Transaction = Loadable(lazy(() => import("views/general/transaction")));

//Noti
const NotificationList = Loadable(lazy(() => import("views/notification/index")));
const NotificationCRUD = Loadable(lazy(() => import("views/notification/create")));

//Setting
const SystemSetting = Loadable(lazy(() => import("views/Setting/index")));

const Employee = Loadable(lazy(() => import("views/general/Employee")));
const Service = Loadable(lazy(() => import("views/general/Service")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
	children: [
		{
			path: "/employee",
			element: <Employee />,
		},
		{
			path: "/permission",
			element: <Permission />,
		},
		{
			path: "/users",
			element: <User />,
		},
		{
			path: "/qrcode",
			element: <Qrcode />,
		},
		{
			path: "/transaction",
			element: <Transaction />,
		},
		{
			path: "/service",
			element: <Service />,
		},
		{
			path: "/offer",
			element: <Offer />,
		},
		{
			path: "/booking",
			element: <Booking />,
		},
		{
			path: "/booking-scheduler",
			element: <MuiScheduler />,
		},
		{
			path: "/users/edit/:userId",
			element: <UserForm />,
		},
		{
			path: "/user/:id",
			element: <UserDetailPage />,
		},
		{
			path: "/notification",
			element: <NotificationList />,
		},
		{
			path: "/notification-sended",
			element: <NotificationSended />,
		},
		{
			path: "/service-category",
			element: <ServiceCategory />,
		},
		{
			path: "/notification/create",
			element: <NotificationCRUD />,
		},
		{
			path: "/notification/update/:id",
			element: <NotificationCRUD />,
		},
		{
			path: "/system-setting",
			element: <SystemSetting />,
		},
		{
			path: "/join-collab-list",
			element: <JoinCollab />,
		},
		{
			path: "/dashboard",
			element: <Dashboard />,
		},
		{
			path: "/banner",
			element: <Banner />,
		},
		{
			path: "/register-test",
			element: <RegisterTest />,
		},
		{
			path: "/service-combo",
			element: <ServiceCombo />,
		},
		{
			path: "/product-category",
			element: <ProductCategory />,
		},
		{
			path: "/post-category",
			element: <PostCategory />,
		},
		{
			path: "/post",
			element: <Post />,
		},
		{
			path: "/post/:id",
			element: <PostInfo />,
		},
		{
			path: "/product",
			element: <Product />,
		},
		{
			path: "/order",
			element: <Order />,
		},
		{
			path: "/internal-order",
			element: <InternalOrder />,
		},
		{
			path: "/order-item",
			element: <OrderItem />,
		},
		{
			path: "/branch",
			element: <Branch />,
		},
		{
			path: "/recharge-withdraw",
			element: <RechargeWithdraw />,
		},
		{
			path: "/discount-his",
			element: <DiscountHis />,
		},
		{
			path: "/user-verify",
			element: <UserVerify />,
		},
		{
			path: "/wallet-his",
			element: <WalletHis />,
		},
		//Setting
		{
			path: "/setting-cms",
			element: <SettingCMS />,
		},
		//Scan skin
		{
			path: "/history-scan-skin",
			element: <ScanHis />,
		},
		{
			path: "/content-scan-skin",
			element: <ContentScanSkin />,
		},
		{
			path: "/scan-skin-gift",
			element: <GiftRegister />,
		},
		{
			path: "/voucher",
			element: <Voucher />,
		},
		{
			path: "/link-share",
			element: <ShareLink />,
		},
		{
			path: "/link-share/preferential-user",
			element: <PreferentialUser />,
		},
		{
			path: "/all-check-inventory",
			element: <CheckInventory />,
		},
		{
			path: "/group-product",
			element: <GroupScanSkinProduct />,
		},

		//Partner
		{
			path: "/supplier",
			element: <Supplier />,
		},
		{
			path: "/confirm-change-wallet",
			element: <ConfirmChangeWallet />,
		},

		//DetailPage
		{
			path: "/check-inventory-detail/:checkId",
			element: <CheckInventoryDetail />,
		},
		{
			path: "/recharge-withdraw-detail/:transactionId",
			element: <RechargeWithDrawPage />,
		},
		{
			path: "/product-detail/:productId",
			element: <ProductDetailPage />,
		},
		{
			path: "/booking-detail/:bookingId",
			element: <BookingDetail />,
		},
		{
			path: "/service-detail/:serviceId",
			element: <ServiceDetail />,
		},
		{
			path: "/order-detail/:orderId",
			element: <OrderDetailPage />,
		},
		{
			path: "/confirm-change-wallet-detail/:changeWalletId",
			element: <ConfirmChangeWalletDetail />,
		},

		//Not permission
		{
			path: "/not-permission",
			element: <NotPermission />,
		},

		//
		// {
		// 	path: "/test-page",
		// 	element: <ProductQr />,
		// },
	],
};

export default MainRoutes;
