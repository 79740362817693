import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MENU_OPEN, SET_MENU } from "store/actions/CustomizationAction/types";
import { logout } from "store/actions/EmployeeAction";
import config from "../../../../../config";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
	const customization = useSelector((state) => state.customization);
	const exp = useSelector((state) => state.employee.employeeInfo?.exp);

	const expFormat = moment.utc(exp).format();
	const now = moment.utc().format();

	const Icon = item.icon;
	const itemIcon = item?.icon ? (
		<Icon stroke={1.5} size="1.3rem" />
	) : (
		<FiberManualRecordIcon
			sx={{
				width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
				height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
			}}
			fontSize={level > 0 ? "inherit" : "medium"}
		/>
	);

	let itemTarget = "_self";
	if (item.target) {
		itemTarget = "_blank";
	}

	let listItemProps = {
		component: forwardRef((props, ref) => (
			<Link ref={ref} {...props} to={`${config.basename}${item.url}`} target={itemTarget} />
		)),
	};
	if (item?.external) {
		listItemProps = { component: "a", href: item.url, target: itemTarget };
	}

	const itemHandler = (id) => {
		dispatch({ type: MENU_OPEN, id });
		if (matchesSM) dispatch({ type: SET_MENU, opened: false });
	};

	// active menu item on page load
	useEffect(() => {
		const currentIndex = document.location.pathname
			.toString()
			.split("/")
			.findIndex((id) => id === item.url);

		if (currentIndex > -1) {
			dispatch({ type: MENU_OPEN, id: item.id });
			return;
		}
		if (document.location.pathname === "/") {
			dispatch({ type: MENU_OPEN, id: "default" });
		}
		// eslint-disable-next-line
	}, [document.location.pathname]);

	useEffect(() => {
		if (now > expFormat) {
			dispatch(
				logout((isSuccess) => {
					if (isSuccess) {
						navigate("/");
					} else {
						enqueueSnackbar(t("logout_error"), {
							variant: "error",
							autoHideDuration: 2000,
						});
					}
				})
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document.location.pathname]);

	return (
		<ListItemButton
			{...listItemProps}
			disabled={item.disabled}
			sx={{
				display: item.visible ? undefined : "none",
				borderRadius: `${customization.borderRadius}px`,
				mb: 0.5,
				alignItems: "flex-start",
				backgroundColor: level > 1 ? "transparent !important" : "inherit",
				py: level > 1 ? 1 : 1.25,
				pl: `${level * 24}px`,
			}}
			selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
			onClick={() => itemHandler(item.id)}
		>
			<ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
			<ListItemText
				primary={
					<Typography
						variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? "h5" : "body1"}
						color="inherit"
					>
						{t(`sideBar.${item.title}`)}
					</Typography>
				}
			/>
		</ListItemButton>
	);
};

NavItem.propTypes = {
	item: PropTypes.object,
	level: PropTypes.number,
};

export default NavItem;
