export const transactionTree = [
	{
		title: "Đơn hàng",
		key: "TRANSACTION",
		children: [
			{
				title: "Xem DS",
				key: "TRANSACTION_VIEW", // id role
			},
			{
				title: "Thêm mới",
				key: "TRANSACTION_CREATE",
			},
			{
				title: "Cập nhật",
				key: "TRANSACTION_UPDATE",
			},
			{
				title: "Xóa",
				key: "TRANSACTION_DELETE",
			},
		],
	},
];
