import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import classnames from "classnames/bind";
import { actDeleteEmployeeSale, actUpdateValueEmployeeSale } from "store/actions/EmployeeSale";
import styles from "../BookingModal.module.scss";

const cx = classnames.bind(styles);

function EmployeeSaleItem({ employee, isCombo, isUpdate = false }) {
	const dispatch = useDispatch();
	const handleDeleteEmployee = (employee) => {
		dispatch(actDeleteEmployeeSale(employee));
	};
	const handleSetValue = (data, val, name) => {
		dispatch(actUpdateValueEmployeeSale({ data, val, name }));
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				margin: "20px 0px",
			}}
		>
			<Typography
				sx={{
					flex: 2,
				}}
			>
				{employee.employee.fullname}
			</Typography>

			{/* <InputNumberCustom
				val={employee.work}
				handleSetValue={(value) => handleSetValue(employee, value, "work")}
				defaultValue={employee.work}
				min="1"
				max="100000"
				step="0.5"
				styleItem={{
					flex: 1.5,
					width: "100%",
				}}
			/> */}
			<Box sx={{ flex: 0.5, textAlign: "right" }}>
				<IconButton sx={{ width: "fit-content" }} onClick={() => handleDeleteEmployee(employee)}>
					<Close />
				</IconButton>
			</Box>
		</Box>
	);
}

export default EmployeeSaleItem;
