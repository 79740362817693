import { Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import classnames from "classnames/bind";
import styles from "./CreateOrder.module.scss";
import { useSelector } from "react-redux";
import DeliveryOverwrite from "./DeliveryOverwrite";
import DeliveryCustomer from "./DeliveryCustomer";

const cx = classnames.bind(styles);
function StepInfoCustomer({ control, errors, payType, clearErrors, setValueForm }) {
	const { orderList, infoCustomer } = useSelector((state) => state.createOrder);

	const [isUseAddress, setIsUseAddress] = useState(!!infoCustomer.userId);
	return (
		<form>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<label className={cx("form__label")}>Họ và tên</label>
					<Controller
						as={<TextField fullWidth id="outlined-basic" label="Họ và tên" variant="outlined" />}
						control={control}
						name="fullname"
					/>
					<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
						{errors?.fullname?.message}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<label className={cx("form__label")}>Số điện thoại</label>
					<Controller
						as={
							<TextField
								disabled
								type={"number"}
								max={10}
								fullWidth
								id="outlined-basic"
								label="Số điện thoại"
								variant="outlined"
							/>
						}
						rules={{ required: "Số điện thoại không được để trống" }}
						control={control}
						name="phone"
					/>
					{payType === "WALLET" && (
						<Typography variant="h5" color="goldenrod" sx={{ margin: "5px" }}>
							Bạn đang đặt bằng hạn mức dịch vụ, số điện thoại không thể thay đổi
						</Typography>
					)}
					<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
						{errors?.phone?.message}
					</Typography>
				</Grid>
				<Grid item xs={6} sx={{ display: !infoCustomer.userId && "none" }}>
					<label className={cx("form__label")}>Sử dụng địa chỉ hiện tại </label>
					<Switch
						checked={isUseAddress}
						onChange={(e) => {
							setIsUseAddress(e.target.checked);
						}}
					/>
				</Grid>
				{isUseAddress ? (
					<DeliveryCustomer
						control={control}
						errors={errors}
						setIsUseAddress={setIsUseAddress}
						clearErrors={clearErrors}
						setValueForm={setValueForm}
					/>
				) : (
					<DeliveryOverwrite control={control} errors={errors} setValueForm={setValueForm} />
				)}
			</Grid>
		</form>
	);
}

export default StepInfoCustomer;
