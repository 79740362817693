import { useSnackbar } from "notistack";
import { useState } from "react";

function useCopyToClipboard() {
	const [copiedText, setCopiedText] = useState();

	const { enqueueSnackbar } = useSnackbar();
	const copy = async (text) => {
		if (!navigator?.clipboard) {
			console.warn("Clipboard not supported");
			return false;
		}

		// Try to save to clipboard then save it in the state if worked
		try {
			await navigator.clipboard.writeText(text);
			setCopiedText(text);
			enqueueSnackbar("Sao chép vào bộ nhớ tạm thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			return true;
		} catch (error) {
			console.warn("Copy failed", error);
			setCopiedText(null);
			enqueueSnackbar(error || "Lỗi xảy ra, vui lòng thử lại", {
				variant: "error",
				autoHideDuration: 2000,
			});
			return false;
		}
	};

	return [copiedText, copy];
}

export default useCopyToClipboard;
