import { AddCircleOutline, Print, RemoveCircleOutline } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { IconNotification } from "@tabler/icons";
import { Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import orderAPI from "api/OrderAPI";
import { CustomNoRowsOverlay } from "components/noRows";
import OrderPrintTemplate from "components/PrintTemplate/OrderPrintTemplate";
import { PAYMENT_TYPE } from "constants/common";
import { ROLE } from "constants/employeeRole";
import { activityOrderTranslator, formatVND, paymentTypeOrderTranslator } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { SET_MENU } from "store/actions/CustomizationAction/types";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import CollapseContent from "ui-component/CollapseContent";
import HeaderNavigate from "ui-component/Header-actions/HeaderNavigate";
import ComboServiceInfo from "ui-component/modal-form/ComboService";
import DialogMain from "ui-component/modal-form/DialogMui";
import ImageModal from "ui-component/modal-form/ImageModal";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import OrderModal from "ui-component/modal-form/OrderModal";
import OrderActivityModal from "ui-component/modal-form/OrderModal/OrderActivityModal";
import DateRange from "ui-component/SearchAvanced/DateRange";
import { IOSSwitch } from "ui-component/switch";
import { SearchComponent } from "../../components/search";
import OrderItem from "./OrderItem";

const searchList = [
	{
		value: "phone",
		title: "SĐT nhận",
	},
	{
		value: "$user.username$",
		title: "SĐT đặt",
	},
	{
		value: "fullname",
		title: "Tên khách nhận",
	},

	{
		value: "order_code",
		title: "Mã đơn hàng",
	},
];
const { CASH, WALLET, TRANSFER, CARD } = PAYMENT_TYPE;

const paymentList = [
	{
		value: "ALL",
		title: "Tất cả",
	},
	{
		value: "CASH",
		title: CASH,
	},
	// {
	// 	value: "WALLET",
	// 	title: WALLET,
	// },

	{
		value: "TRANSFER",
		title: TRANSFER,
	},
	// {
	// 	value: "ASSIGNED",
	// 	title: ASSIGNED,
	// },
	{
		value: "CARD",
		title: CARD,
	},
];

const filterToActivity = [
	{
		value: "ALL",
		title: "Tất cả",
	},
	{
		value: "WAITING",
		title: activityOrderTranslator("WAITING")?.value,
	},
	{
		value: "CONFIRMED",
		title: activityOrderTranslator("CONFIRMED")?.value,
	},
	{
		value: "PROCESSING",
		title: activityOrderTranslator("PROCESSING")?.value,
	},
	{
		value: "COMPLETED",
		title: activityOrderTranslator("COMPLETED")?.value,
	},
	{
		value: "CANCELED",
		title: activityOrderTranslator("CANCELED")?.value,
	},
];

const ServiceCombo = () => {
	const location = useLocation();
	const { role } = useCustomAuth();

	const stateFromCategory = location?.state;
	const filterWithCategory = {
		searchBy: stateFromCategory?.searchBy,
		value: stateFromCategory?.value,
	};

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [filterQuery, setFilterQuery] = useState({
		fields: ["$all", { user: ["$all", "$paranoid"] }, { order_items: ["$all"] }],
		filter: {},
		order: [["created_at", "desc"]],
	});
	const [filterOption, setFilterOption] = useState(
		location?.state?.activity || filterToActivity[0].value
	);
	const [paymentType, setPaymentType] = useState(paymentList[0].value);

	const [searchBy, setSearchBy] = useState(filterWithCategory.searchBy || searchList[0].value);
	const [searchValue, setSearchValue] = useState(filterWithCategory.value || "");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const filterOptionFunc = (e) => {
		const newSearchBy = e.target.value;
		setFilterOption(newSearchBy);
		handleChangePage(null, 1);
		// navigate({
		// 	pathname: location.pathname,
		// });
	};
	const filterByPayment = (e) => {
		const newSearchBy = e.target.value;
		setPaymentType(newSearchBy);
		handleChangePage(null, 1);
		// navigate({
		// 	pathname: location.pathname,
		// });
	};
	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};

		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters[searchBy] = value;
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	const [inforCombo, setInforCombo] = useState({
		open: false,
		data: null,
	});
	const [activityModal, setActivityModal] = useState(false);
	const handleOpenActivity = (data) => {
		setActivityModal(true);
		setData(data.row);
	};
	// Table
	const handleChangeAccept = async (checked, id, isWithClient) => {
		try {
			await orderAPI.update(
				id,

				!isWithClient
					? {
							is_paid: checked,
					  }
					: {
							is_confirm_with_client: checked,
					  }
			);
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListComboService();
		} catch (error) {
			enqueueSnackbar(error?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenChangeAccept = (e, params, isWithClient = false) => {
		let checked = e.target.checked;
		modalContext.showDeleteConfirm(
			params.id,
			() => handleChangeAccept(checked, params.id, isWithClient),
			`${
				checked
					? `Bạn có chắc chắn xác nhận  ${
							isWithClient ? "đã gọi điện  khách hàng?" : "đã thanh toán?"
					  } `
					: `Bạn có chắc chắn xác nhận ${
							isWithClient ? "chưa gọi điện  khách hàng?" : "chưa thanh toán?"
					  }`
			}`,
			"Chắc chắn"
		);
	};
	//Print Func
	const componentRef = useRef(null);

	const [dataToPrint, setDataPrint] = useState({});

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: true,
	});
	const componentMount = useRef(true);
	useEffect(() => {
		if (componentMount.current) {
			componentMount.current = false;
			return;
		}
		handlePrint();
	}, [dataToPrint]);

	const columns = [
		{
			field: "user",
			headerName: "SĐT người mua",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.user?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							className={params?.row?.user?.deleted_at && "line__though"}
						>
							{params?.row?.user?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "fullname",
			headerName: t(`abeauty.order.table_title.fullname_address`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "phone",
			headerName: t(`abeauty.order.table_title.phone`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "order_code",
			headerName: t(`abeauty.order.table_title.order_code`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "total_cost",
			headerName: t(`abeauty.order.table_title.total_cost`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "payment_type",
			headerName: t(`abeauty.order.table_title.payment_type`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={paymentTypeOrderTranslator(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{paymentTypeOrderTranslator(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "order_online",
			headerName: "Hình thức mua hàng",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value ? "Đặt hàng Online" : "Tại cửa hàng"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value ? "Đặt hàng Online" : "Tại cửa hàng"}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "activity",
			headerName: t(`abeauty.order.table_title.activity`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				const convertData = activityOrderTranslator(params.value);
				return (
					<Tooltip
						title={convertData?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								fontWeight: "bold",
								color: convertData?.color,
							}}
						>
							{convertData?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "is_paid",
			headerName: "Trạng thái thanh toán",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				// params.value = false;

				return (
					<Tooltip
						title={params.value ? "Đã thanh toán" : "Chưa thanh toán"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<FormControlLabel
							control={
								<IOSSwitch
									disabled={params.row.payment_type === "WALLET" && params.row?.is_paid === true}
									sx={{ ml: 4 }}
									checked={params?.value}
									onChange={(e) => handleOpenChangeAccept(e, params?.row)}
								/>
							}
							label=""
						/>
					</Tooltip>
				);
			},
		},
		{
			field: "created_at_unix_timestamp",
			headerName: t(`abeauty.order.table_title.created_at_unix_timestamp`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "actions",
			headerName: t(`actions`),
			flex: 2,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<>
					{role?.TRANSACTION_DELETE === ROLE.TRANSACTION_DELETE && (
						<Tooltip title={t(`delete`)}>
							<IconButton aria-label="delete" onClick={() => handleOpenRemoveModal(params.id)}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					)}

					<Tooltip title={"Thông tin chi tiết"}>
						<Link to={`/order-detail/${params?.row?.id}`} target="_blank" state={{ isOrder: true }}>
							<IconButton aria-label="edit">
								<IconNotification />
							</IconButton>
						</Link>
					</Tooltip>
					{role?.TRANSACTION_UPDATE === ROLE.TRANSACTION_UPDATE && (
						<Tooltip title={"Chỉnh sửa trạng thái đơn hàng"}>
							<IconButton
								disabled={params.row.activity === "CANCELED" || params.row.activity === "COMPLETED"}
								aria-label="edit"
								onClick={() => handleOpenActivity(params)}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
					)}
					<Tooltip title={"In lại hóa đơn"}>
						<IconButton
							aria-label="edit"
							onClick={() => {
								setDataPrint({
									order_items: params.row.order_items,
									customer_info: {
										name: params.row.fullname,
										address: params.row.address,
										ward: params.row.ward,
										district: params.row.district,
										city: params.row.city,
										phone: params.row.phone,
									},
									code: params.row.order_code,
									data: params.row,
								});
							}}
						>
							<Print />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];

	const [information, setInformation] = useState({
		visible: false,
	});
	const handleOpenInfoModal = (data) => {
		//	Example DATA
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin đơn hàng",
				gridProps: {},
				children: [
					{
						id: "user",
						title: "Tên khách hàng",
						value: data?.user?.fullname,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "fullname",
						title: "Tên khách nhận",
						value: data?.fullname,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "phone",
						title: "SĐT đặt",
						value: data?.user?.username,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "phone",
						title: "SĐT nhận",
						value: data?.phone,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "order_code",
						title: "Mã đơn hàng",
						value: data.order_code,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "activity",
						title: "Trạng thái",
						value: activityOrderTranslator(data.activity)?.value,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "payment_type",
						title: "Hình thức thanh toán",
						value: paymentTypeOrderTranslator(data.payment_type),
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "created_at_unix_timestamp",
						title: "Thời gian tạo",
						value: moment(data.created_at_unix_timestamp, "x").format("DD/MM/YYYY HH:mm"),
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "fee_total",
						title: "Tổng tiền hàng",
						value: formatVND(data.fee_total),
						component: Text,
						childrenProps: {
							span: 6,
							color: "error",
						},
					},
					{
						id: "discount",
						title: "Tổng giảm",
						value: formatVND(data.discount),
						component: Text,
						childrenProps: {
							span: 6,
							color: "error",
						},
					},
					{
						id: "total_cost",
						title: "Tổng cộng",
						value: formatVND(data.total_cost),
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: " ",
						title: "",
						value: "",
						component: Text,
						childrenProps: {
							span: 6,
						},
					},

					{
						id: "address",
						title: "Địa chỉ",
						value: data.address,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "ward",
						title: "Xã/Phường",
						value: data.ward,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "district",
						title: "Quận/Huyện",
						value: data.district,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
					{
						id: "city",
						title: "Tỉnh/TP",
						value: data.city,
						component: Text,
						childrenProps: {
							span: 6,
						},
					},
				],
			},
			{
				groupId: 2,
				groupTitle: "Thông tin khác",
				children: [
					{
						id: "reason",
						title: "Lý do hủy đơn",
						value: data.reason,
						component: Text,
						childrenProps: {
							span: 24,
						},
					},
					{
						id: "note",
						title: "Ghi chú đơn hàng",
						value: data.note,
						component: Text,
						childrenProps: {
							span: 24,
						},
					},
				],
			},
		];

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
			orderId: data.id,
		}));
	};
	//Date Advance Search
	const [date, setDate] = useState(
		location.state?.filterDate
			? [
					Number(moment(location.state?.filterDate[0], "DD/MM/YYYY").startOf("day").format("x")),
					Number(moment(location.state?.filterDate[1], "DD/MM/YYYY").endOf("day").format("x")),
			  ]
			: [null, null]
	);
	//
	const getListComboService = async (isLastDeletedItem) => {
		if (queryParams?.[searchBy]) {
			if (searchBy == "$user.fullname$") {
				filterQuery.fields = [
					"$all",
					{ user: ["$all", { $filter: { fullname: { $iLike: `%${searchValue?.trim()}%` } } }] },
					{ branch: ["name"] },
					{ order_items: ["$all", { product: ["title"] }] },
				];
			} else {
				filterQuery.filter = {
					...filterQuery.filter,
					branch_id: branchActiveId,
					[searchBy]: { $iLike: `%${searchValue?.trim()}%` },
				};
			}
		} else {
			filterQuery.filter = {
				branch_id: branchActiveId,
			};
			filterQuery.fields = [
				"$all",
				{ user: ["$all", "$paranoid"] },
				{ branch: ["name"] },
				{ order_items: ["$all", { product: ["title"] }] },
			];
		}

		filterQuery.filter = {
			...filterQuery.filter,
			branch_id: branchActiveId,

			created_at_unix_timestamp: {
				$between: [+moment(date[0]).utc(true).format("x"), +moment(date[1]).utc(true).format("x")],
			},
			activity: filterOption,
			payment_type: paymentType,
			order_type: "PRODUCT",
		};

		if (!filterOption || filterOption === "ALL") {
			delete filterQuery.filter.activity;
		}
		if (!paymentType || paymentType === "ALL") {
			delete filterQuery.filter.payment_type;
		}
		if (!collapse || !date[0] || !date[1]) {
			delete filterQuery.filter.created_at_unix_timestamp;
		}
		try {
			const res = await orderAPI.getList({
				fields: JSON.stringify(filterQuery.fields),
				order: JSON.stringify(filterQuery.order),
				filter: JSON.stringify(filterQuery.filter),
				limit: pagination.limit,
				page: isLastDeletedItem ? queryParams.page - 1 : queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			if (isLastDeletedItem) {
				navigate({
					pathname: location.pathname,
					search: `?${queryString.stringify({ ...queryParams, page: paginationRes.page })}`,
				});
			}
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));

			setFilterQuery(filterQuery);
			setSelectList([]);
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);
	const handleRemoveMulti = async () => {
		const isLast = rows.length === selectList.length && pagination.page !== 1;

		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await orderAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListComboService(isLast);
					dispatch(actGetWaitingRequest(branchActiveId));
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleDelete = async (id) => {
		const isLast = rows.length === 1 && pagination.page !== 1;

		try {
			await orderAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			dispatch(actGetWaitingRequest(branchActiveId));
			getListComboService(isLast);
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useEffect(() => {
		if (!branchActiveId || !role) return;

		if (role?.TRANSACTION_VIEW !== ROLE.TRANSACTION_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}
		getListComboService();

		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}
	}, [queryParams, loading, filterOption, date, branchActiveId, paymentType, role, location.state]);

	const dispatch = useDispatch();

	const [collapse, setCollapse] = useState(location?.state?.filterDate ? true : false);
	return (
		<>
			<MainCard>
				{/* {filterCategory?.isFromCategory && (
					<Button
						variant="contained"
						onClick={() => navigate("/service-category")}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về trang dịch vụ
					</Button>
				)} */}

				<HeaderNavigate
					title={"Danh sách đơn hàng"}
					onCreate={false}
					onNavigate={() => {
						navigate("/create-order", {
							state: {
								path: "/order",
								fromOrder: true,
							},
						});
						dispatch({ type: SET_MENU, opened: false });
					}}
					// onCreate={employeeInfo?.role === "SUPERADMIN" ? true : false}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
						}}
					>
						<SearchComponent
							searchBy={searchBy}
							searchList={searchList}
							searchValue={searchValue}
							handleSearchBy={handleSearchBy}
							handleChangeSearch={handleChangeSearch}
						/>

						<FormControl sx={{ minWidth: 150, ml: 5 }}>
							<InputLabel id="demo-simple-select-outlined-label">{"Trạng thái"}</InputLabel>
							<Select
								defaultValue={filterToActivity[0].value}
								onChange={filterOptionFunc}
								labelId="demo-simple-select-outlined-label"
								label={"Tìm kiếm"}
								value={filterOption}
							>
								{filterToActivity?.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl sx={{ minWidth: 250, ml: 5 }}>
							<InputLabel id="payment-type-label">{"Phương thức thanh toán"}</InputLabel>
							<Select
								defaultValue={filterToActivity[0].value}
								onChange={filterByPayment}
								labelId="payment-type-label"
								label={"Phương thức thanh toán"}
								value={paymentType}
							>
								{paymentList?.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>

					{ROLE.TRANSACTION_DELETE === role?.TRANSACTION_DELETE && (
						<Button
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Stack flexDirection={"row"} justifyContent="flex-end">
					<Button
						onClick={() => setCollapse(!collapse)}
						sx={{
							width: "200px",
						}}
						variant="outlined"
						startIcon={collapse ? <RemoveCircleOutline /> : <AddCircleOutline />}
					>
						Tìm kiếm nâng cao
					</Button>
				</Stack>
				<Box my={2}>
					<CollapseContent collapse={collapse}>
						<DateRange
							date={date}
							collapse={collapse}
							setDate={(value) => {
								handleChangePage(null, 1);
								setDate(value);
							}}
						/>
					</CollapseContent>
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection={ROLE.TRANSACTION_DELETE === role?.TRANSACTION_DELETE}
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<OrderModal
				getListTable={getListComboService}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
			/>

			<ImageModal
				title={"Ảnh dịch vụ"}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>

			<DialogMain
				maxWidth={"lg"}
				title={"Dịch vụ có trong gói"}
				fullWidth
				open={inforCombo.open}
				onClose={() => {
					setInforCombo((prev) => ({
						...prev,
						open: false,
					}));
				}}
			>
				<ComboServiceInfo data={inforCombo.data} />
			</DialogMain>

			<ModalAntd
				title={"Chi tiết đơn hàng"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
				<OrderItem orderId={information.orderId} openModal={information.visible} />
			</ModalAntd>
			<OrderActivityModal
				getListTable={getListComboService}
				data={data}
				open={activityModal}
				setOpen={(value) => setActivityModal(value)}
			/>
			<OrderPrintTemplate className="content-print" dataToPrint={dataToPrint} ref={componentRef} />
		</>
	);
};

export default ServiceCombo;
