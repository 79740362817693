import { Col } from "antd";
import React from "react";
import TreeSelect from "ui-component/TreeSelect";
import { rowData } from "../data/rowData";
import { getDefaultOfTree } from "../func";

function PermissionList({ defaultValue = [], handleSetData }) {
	return (
		<>
			{rowData.map((it, idx) => {
				let treeValue = getDefaultOfTree(it.treeData, defaultValue);
				return (
					<Col key={it.id} span={8}>
						<TreeSelect
							handleSetData={handleSetData}
							treeData={it.treeData}
							title={it.title}
							data={it}
							defaultValue={defaultValue}
							treeValue={treeValue}
						/>
					</Col>
				);
			})}
		</>
	);
}

export default PermissionList;
