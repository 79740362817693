import { combineReducers } from "redux";
import createOrderReducer from "views/pages/CreateOrder/store/reducer";
import bookingReducer from "./reducers/BookingReducer";
import { BranchReducer } from "./reducers/BranchReducer";

// reducer import
import customizationReducer from "./reducers/CustomizationReducer";
import EmployeeBookingReducer from "./reducers/EmployeeBookingReducer";
import EmployeeReducer from "./reducers/EmployeeReducer";
import EmployeeSaleReducer from "./reducers/EmployeeSaleReducer";
import UnitReducer from "./reducers/UnitReducer";
import UserComboServiceReducer from "./reducers/UserComboService";
import UserReducer from "./reducers/UserReducer";
import { WaitingRequestReducer } from "./reducers/WaitingRequestReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
	customization: customizationReducer,
	employee: EmployeeReducer,
	booking: bookingReducer,
	employeeBooking: EmployeeBookingReducer,
	employeeSale: EmployeeSaleReducer,
	waitingRequest: WaitingRequestReducer,
	user: UserReducer,
	userComboService: UserComboServiceReducer,
	createOrder: createOrderReducer,
	branchReducer: BranchReducer,
	unitReducer: UnitReducer,
});

export default reducer;
