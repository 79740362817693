import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Controller, useController } from "react-hook-form";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function SelectMultipleField({
	titleOption = "name",
	name,
	control,
	optionData = [],
	propsInput,
	...props
}) {
	const {
		field: { onChange, onBlur, value, ref },
	} = useController({
		name,
		control,
	});

	return (
		<Autocomplete
			multiple
			options={optionData.map((item) => item.id)}
			disableCloseOnSelect
			getOptionLabel={(option) => {
				const result = optionData.find((item) => item.id === option);
				return result?.[titleOption];
			}}
			renderOption={(props, option, { selected }) => {
				const result = optionData.find((item) => item.id === option);
				const name = result?.[titleOption];
				return (
					<li {...props}>
						<Checkbox
							icon={icon}
							checkedIcon={checkedIcon}
							style={{ marginRight: 8 }}
							checked={selected}
						/>
						{name}
					</li>
				);
			}}
			renderInput={(params) => <TextField {...params} variant={"standard"} {...propsInput} />}
			{...props}
			onChange={(_, data) => {
				onChange(data);
				return data;
			}}
			ref={ref}
			onBlur={onBlur}
			value={value}
			name={name}
			control={control}
		/>
	);
}

export default SelectMultipleField;
