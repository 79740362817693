import { Button, Col, Input, Row, Typography } from "antd";
import roleAPI from "api/RoleAPI";
import { ROLE_DETAILS } from "constants/employeeRole";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { checkEmptyData } from "views/pages/Permission/func";
import PermissionList from "views/pages/Permission/PermissionList";

function PermissionModal({
	defaultValue = [],
	titleTemplate = "",
	isUpdate,
	open,
	getRoleTemplate,
	closeModal,
	templateId,
}) {
	const [settingData, setSettingData] = useState({});

	const [templateName, setTemplateName] = useState(isUpdate ? titleTemplate : "");

	const handleSetData = useCallback((data, name) => {
		setSettingData((prev) => ({
			...prev,
			[name]: data,
		}));
	}, []);

	const [defaultModal, setDefaultModal] = useState([]);

	useEffect(() => {
		if (open) {
			if (isUpdate) {
				setTemplateName(titleTemplate);
				setDefaultModal(defaultValue);
			} else {
				setTemplateName("");
				setDefaultModal([]);
			}
		}
	}, [open]);

	const isEmpty = checkEmptyData(settingData);
	const [loading, setLoading] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const handleOnSubmit = async () => {
		setLoading(true);

		const arrRole = Object.values(settingData).flatMap((it) => it);
		const rolesId = ROLE_DETAILS.map((role) => {
			if (arrRole.includes(role.type)) {
				return role.id;
			} else {
				return null;
			}
		}).filter((it) => it);
		const body = {
			role_detail_id: rolesId,
			title: templateName,
		};
		if (isUpdate) {
			try {
				const res = await roleAPI.createTemplate({
					...body,
					id: templateId,
				});
				enqueueSnackbar("Tạo vai trò mới thành công", {
					variant: "success",
					autoHideDuration: 2000,
				});

				setLoading(false);
				closeModal(templateId);
			} catch (error) {
				console.log(error);
				enqueueSnackbar(error?.message || "Có lỗi xảy ra, vui lòng thử lại", {
					variant: "error",
					autoHideDuration: 2000,
				});
				setLoading(false);
			}
		} else {
			try {
				const res = await roleAPI.createTemplate(body);
				enqueueSnackbar("Tạo vai trò mới thành công", {
					variant: "success",
					autoHideDuration: 2000,
				});
				// getRoleTemplate(templateId);
				setLoading(false);
				closeModal();
			} catch (error) {
				console.log(error);
				enqueueSnackbar(error?.message || "Có lỗi xảy ra, vui lòng thử lại", {
					variant: "error",
					autoHideDuration: 2000,
				});
				setLoading(false);
			}
		}
	};

	const handleDeleteTemplate = async () => {
		try {
			const res = await roleAPI.createTemplate({
				id: templateId,
				isDelete: true,
			});
			enqueueSnackbar("Tạo vai trò mới thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			// getRoleTemplate(templateId);
			setLoading(false);
			closeModal(undefined, true);
		} catch (error) {
			console.log(error);
			enqueueSnackbar(error?.message || "Có lỗi xảy ra, vui lòng thử lại", {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);
		}
	};
	return (
		<>
			<Row gutter={[16, 32]} style={{ marginBottom: "5px" }}>
				<Col span={3} style={{ display: "flex", alignItems: "center" }}>
					<Typography.Title style={{ marginBottom: 0 }} level={5}>
						Vai trò:{" "}
					</Typography.Title>
				</Col>

				<Col span={9}>
					<Input
						bordered={false}
						style={{ borderBottom: "1px solid black" }}
						defaultValue={templateName}
						onChange={(e) => setTemplateName(e.target.value)}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 32]}>
				<PermissionList defaultValue={defaultModal} handleSetData={handleSetData} />
			</Row>

			<Row justify="end">
				<Col span={8}>
					<Row justify="end">
						<Col span={12}>
							<Button
								disabled={isEmpty}
								style={{ width: "100%" }}
								loading={loading}
								onClick={handleOnSubmit}
							>
								{isUpdate ? "Cập nhật" : "Tạo"}
							</Button>
						</Col>
						{isUpdate && (
							<Col span={12}>
								<Button
									disabled={isEmpty}
									style={{ width: "100%" }}
									loading={loading}
									onClick={handleDeleteTemplate}
								>
									Xóa
								</Button>
							</Col>
						)}
					</Row>
				</Col>
			</Row>
		</>
	);
}

export default PermissionModal;
