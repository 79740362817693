import {
	Box,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Stack,
	Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import inventoryAPI from "api/InventoryAPI";
import { CustomNoRowsOverlay } from "components/noRows";
import { checkInventoryTranslator, getNumberWithCommas } from "func";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { gridSpacing } from "store/constant";
import moment from "moment";
import { showSortStyle } from "views/utilities/CustomStyle";
import { SearchComponent } from "components/search";

const typeList = [
	{
		value: "ALL",
		title: "Tất cả",
		color: "#000",
	},
	{
		value: "CHECK_INVENTORY",
		title: checkInventoryTranslator("CHECK_INVENTORY").title,
		color: checkInventoryTranslator("CHECK_INVENTORY").color,
	},
	{
		value: "ORDER",
		title: checkInventoryTranslator("ORDER").title,
		color: checkInventoryTranslator("ORDER").color,
	},
	{
		value: "INTERNAL",
		title: checkInventoryTranslator("INTERNAL").title,
		color: checkInventoryTranslator("INTERNAL").color,
	},
];
// const inventoryList = [
// 	{
// 		value: "ALL",
// 		title: "Tất cả",
// 		color: "#000",
// 	},
// 	{
// 		value: "CHECK_INVENTORY",
// 		title: checkInventoryTranslator("CHECK_INVENTORY").title,
// 		color: checkInventoryTranslator("CHECK_INVENTORY").color,
// 	},
// 	{
// 		value: "ORDER",
// 		title: checkInventoryTranslator("ORDER").title,
// 		color: checkInventoryTranslator("ORDER").color,
// 	},
// ];
function ProductCheckInventory({ productId }) {
	const location = useLocation();
	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const [typeStatus, setTypeStatus] = useState(typeList[0].value);
	const [inventoryList, setInventoryList] = useState([]);
	const [inventoryName, setInventoryName] = useState("");

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const [loading, setLoading] = useState(false);
	const fetchInventoryByProduct = async (id) => {
		setLoading(true);
		const fields = ["$all", { product: ["inventory_amount"] }, { branch: ["name"] }];

		const filterA = {
			product_id: id,
		};

		const order = [["created_at_unix_timestamp", "asc"]];

		const payload = {
			fields: JSON.stringify(fields),
			filter: JSON.stringify(filterA),
			order: JSON.stringify(order),
			page: 1,
			limit: 999999,
		};
		try {
			const res = await inventoryAPI.getList(payload);
			const inventories = res.results.objects.rows;
			setInventoryList(inventories);
			setInventoryName(inventories[0].id);

			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	const fetchCheckInventoryByProduct = async (id) => {
		setLoading(true);
		const fields = [
			"$all",
			{ product: ["inventory_amount"] },
			{ inventory: ["id", { branch: ["name"] }] },
			{ employee: ["fullname", "username"] },
		];

		const filterA = {
			product_id: id,
			type: typeStatus === "ALL" ? undefined : typeStatus,
			inventory_id: inventoryName,
		};

		const order = [["created_at_unix_timestamp", "desc"]];

		const payload = {
			fields: JSON.stringify(fields),
			filter: JSON.stringify(filterA),
			order: JSON.stringify(order),
			page: pagination.page,
			limit: pagination.limit,
		};
		try {
			const res = await inventoryAPI.getListCheck(payload);
			setRows(res.results.objects.rows);
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			setPagination((prev) => ({
				...prev,
				...paginationRes,
			}));
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		setPagination((prev) => ({
			...prev,
			page,
		}));
		navigate({
			pathname: location.pathname,
			// search: `?${queryString.stringify(filters)}`,
		});
	};

	useEffect(() => {
		fetchInventoryByProduct(productId);
	}, []);

	useEffect(() => {
		if (inventoryName) {
			fetchCheckInventoryByProduct(productId);
		}
	}, [pagination.page, typeStatus, inventoryName]);

	const columns = [
		{
			field: "code",
			headerName: "Mã tự động",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee",
			headerName: "Nhân viên cập nhật",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.employee?.fullname || params?.row?.employee?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.employee?.fullname || params?.row?.employee?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "type",
			headerName: "Phương thức",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				const { title, color } = checkInventoryTranslator(params.value);
				return (
					<Tooltip
						title={title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								color: color,
								fontWeight: "bold",
							}}
						>
							{title}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "number_change",
			headerName: "Số lượng thay đổi",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				const amount = getNumberWithCommas(params.value);
				return (
					<Tooltip
						title={amount}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								fontWeight: "bold",
							}}
						>
							{amount}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "total_amount_actual",
			headerName: "Tồn cuối",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				const amount = getNumberWithCommas(params.value);
				return (
					<Tooltip
						title={amount}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",

								fontWeight: "bold",
							}}
						>
							{amount}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
	];

	return (
		<Box my={5}>
			<Grid container spacing={gridSpacing}>
				<Grid item xs={12}>
					<Stack flexDirection={"row"} justifyContent="flex-end">
						<Stack flexDirection={"row"}>
							<FormControl sx={{ minWidth: 150, ml: 5 }}>
								<InputLabel id="demo-simple-select-outlined-label">Kho</InputLabel>
								<Select
									defaultValue={inventoryList[0]?.id}
									onChange={(e) => {
										setInventoryName(e.target.value);
										handleChangePage(null, 1);
									}}
									labelId="demo-simple-select-outlined-label"
									label={"Kho"}
									value={inventoryName}
								>
									{inventoryList?.map((item) => (
										<MenuItem
											key={item.id}
											value={item.id}
											sx={{ color: item.color, fontWeight: 700 }}
										>
											{item?.branch?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl sx={{ minWidth: 150, ml: 5 }}>
								<InputLabel id="demo-simple-select-outlined-label">Phương thức</InputLabel>
								<Select
									defaultValue={typeList[0].value}
									onChange={(e) => {
										setTypeStatus(e.target.value);
										handleChangePage(null, 1);
									}}
									labelId="demo-simple-select-outlined-label"
									label={"Phương thức"}
								>
									{typeList?.map((item) => (
										<MenuItem
											key={item.value}
											value={item.value}
											sx={{ color: item.color, fontWeight: 700 }}
										>
											{item.title}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Stack>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={12} sx={showSortStyle}>
					<DataGrid
						rows={rows}
						columns={columns}
						autoHeight
						autoWidth
						disableColumnMenu
						disableVirtualization={false}
						disableSelectionOnClick={true}
						disableDensitySelector={false}
						hideFooter={true}
						hideFooterPagination={true}
						hideFooterSelectedRowCount={true}
						rowHeight={70}
						sortingMode="server"
						// sortModel={sortModel}
						// onSortModelChange={handleSortModelChange}
						loading={loading}
						// checkboxSelection

						components={{
							NoRowsOverlay: CustomNoRowsOverlay,
						}}
					/>
				</Grid>
			</Grid>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					marginTop: "20px",
				}}
			>
				<Pagination
					page={pagination.page}
					count={Math.ceil(pagination.total / pagination.limit)}
					onChange={handleChangePage}
					color="primary"
					variant="outlined"
					shape="rounded"
				/>
			</Box>
		</Box>
	);
}

export default ProductCheckInventory;
