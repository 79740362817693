const delta = 15;

const billion = 1000000000;
const million = 1000000;
const strings = {
	billion: "Tỷ",
	million: "Triệu",
	commas: ".",
	unit: "đ",
};
export const numbersOnly = (txt) => {
	return /^-?[0-9,\.]+$/?.test(txt?.toString()) ? true : txt === "" ? true : false;
};
export const getNumberWithCommas = (x) =>
	x && `${x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, strings.commas)}`;
export const getPriceWithCommas = (x = 0) =>
	`${x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, strings.commas)}${strings.unit}`;

export const getNumberFromString = (x) => {
	const y = x?.replace(/,/g, "");
	if (y.length === 0) return 0;
	return parseInt(y, 10);
};
export const getNumberWithCommasFromString = (x) => {
	if (x?.length >= 21) return x?.slice(0, 20);
	const y = x?.replace(/\,/g, "");
	let res = "";
	let count = 0;
	for (let i = y.length - 1; i >= 0; i--) {
		res = y.charAt(i) + res;
		count++;
		if (count % 3 === 0 && i !== 0) res = `,${res}`;
	}
	return res;
};

export const getSizeForLongText = (text) => delta - ((text.length + 1) * (delta / 2)) / 11;

export const getNumberString = (value) => {
	const x = +value;
	if (x / billion >= 1) {
		if (x % billion === 0) return `${(x / billion).toFixed(0)} ${strings.billion}`;
		return `${(x / billion).toFixed(3)} ${strings.billion}`;
	} else if (x / million >= 1) {
		if (x % million === 0) return `${(x / million).toFixed(0)} ${strings.million}`;
		return `${(x / 1000000).toFixed(3)} ${strings.million}`;
	}
	return `${getNumberWithCommas(x || 0)}${strings.unit}`;
};

// Chỉ cho phép nhập số
export const handleInputNumber = (txt) => {
	txt = Math.max(0, parseInt(txt)).toString().slice(0, 12);
};

export const convertHourToHHMM = (hour) => {
	if (hour && hour !== 0 && hour !== 24) {
		if (!Number.isInteger(hour)) {
			hour = (hour * 10) / 10;
			const h = parseInt(hour);
			const m = Math.abs(Math.round(60 * (hour - h)));

			return `${h < 10 ? "0" : ""}${h}:${m < 10 ? "0" : ""}${m}`;
		} else {
			return hour < 10 ? `0${hour}:00` : `${hour}:00`;
		}
	} else {
		return "00:00";
	}
};

export const timeToDecimal = (t) => {
	var arr = t.split(":");
	var dec = parseInt((arr[1] / 6) * 10, 10);

	return parseFloat(parseInt(arr[0], 10) + "." + (dec < 10 ? "0" : "") + dec);
};

export const getStringWithCommasFromNumber = (temp) => {
	return temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
