import { CancelPresentation } from "@mui/icons-material";
import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Select as SelectAntd } from "antd";
import { IMAGES } from "assets/images/imagePath";
import { CustomNoRowsOverlay } from "components/noRows";
import { SearchComponent } from "components/search";
import { formatVND, getNumberWithCommas } from "func";
import useDebounce from "hooks/useDebounce";
import { useEffect, useRef, useState } from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import { showSortStyle } from "views/utilities/CustomStyle";

function VoucherUsersModal({ rows = [], open = false, propsMenuSearch }) {
	const [rowsUpdate, setRowsUpdate] = useState([]);
	const fullArr = useRef();
	const actionList = [
		{
			value: "delete",
			title: "delete",
			Icon: CancelPresentation,
		},
	];
	const { Option } = SelectAntd;

	const searchList = [
		// {
		// 	value: "username",
		// 	title: "username",
		// },
		{
			value: "fullname",
			title: "fullname",
		},
		{
			value: "name_admin_note",
			title: "Tên thân mật",
		},
		{
			value: "username",
			title: "SĐT",
		},
	];

	const [deleteUser, setDeleteUser] = useState({
		open: false,
		username: "",
	});
	const handleOpenRemoveModal = (id, row) => {
		setDeleteUser({
			id: id,
			open: true,
			username: row.username,
		});
	};

	//Search

	const [searchBy, setSearchBy] = useState(searchList[0].value);
	const [searchValue, setSearchValue] = useState("");

	const debounceVal = useDebounce(searchValue);
	const filterBySearch = (value) => {
		if (value) {
			const filterArr = fullArr.current?.filter((it) => {
				return it?.[searchBy].toLowerCase().includes(value.toLowerCase());
			});
			return filterArr;
		} else {
			return fullArr.current;
		}
	};

	useEffect(() => {
		const filterArr = filterBySearch(debounceVal);
		setRowsUpdate(filterArr);
	}, [debounceVal]);
	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
	};

	const columns = [
		// {
		// 	field: "order",
		// 	headerName: t(`order`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },

		// {
		// 	field: "username",
		// 	headerName: t(`username`),
		// 	minWidth: 250,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		{
			field: "qr_code",
			headerName: "Mã Qr",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<img
					height={"80%"}
					src={params.value || IMAGES.avatar_default}
					alt="avatar-img"
					onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
					// onClick={() => {
					// 	setOpenModalImage(true);
					// 	setImgQr(params.value);
					// }}
				/>
			),
		},
		{
			field: "username",
			headerName: "SĐT",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.value}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					componentsProps={{
						popper: {
							sx: {
								zIndex: 1000000,
							},
						},
					}}
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.value}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "fullname",
			headerName: "Tên khách hàng",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.value}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.value}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "name_admin_note",
			headerName: "Tên thân mật",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.value}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.value}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "is_collab",
			headerName: "Cộng tác viên",
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) =>
				params.value ? (
					<Chip color="primary" size="small" label={"Cộng tác viên"} />
				) : (
					<Chip
						sx={{
							bgcolor: "#f2f2f2",
							padding: "5px",
						}}
						size="small"
						label={"Không là CTV"}
					/>
				),
		},
		{
			field: "parent_collab",
			headerName: "Tuyến trên",
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.row?.user?.username}
					componentsProps={{
						popper: {
							sx: {
								zIndex: 1000000,
							},
						},
					}}
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.row?.user?.username}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "wallet",
			headerName: "Hạn mức dịch vụ",
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",

			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={`${formatVND(params?.value)}`}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					componentsProps={{
						popper: {
							sx: {
								zIndex: 1000000,
							},
						},
					}}
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{formatVND(params?.value)}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "wallet_rose",
			headerName: "Ví hoa hồng",
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={formatVND(params.value)}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					componentsProps={{
						popper: {
							sx: {
								zIndex: 1000000,
							},
						},
					}}
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{formatVND(params.value)}
					</Box>
				</Tooltip>
			),
		},
	];

	useEffect(() => {
		const temp = rows
			.filter((it) => {
				if (it.user) {
					return it.user;
				}
			})
			.map((it) => it.user);

		setRowsUpdate(temp);
		fullArr.current = temp;
		return () => {
			setRowsUpdate([]);
			fullArr.current = [];
		};
	}, [rows]);

	return (
		<>
			<MainCard>
				<Typography my={4} variant={"h3"}>
					Danh sách khách hàng sử dụng mã giảm giá
				</Typography>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
						propsMenuSearch={{
							sx: {
								zIndex: 1000000,
							},
						}}
					/>
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rowsUpdate}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							pagination
							sortingMode="server"
							// sortModel={sortModel}
							// onSortModelChange={handleSortModelChange}

							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
			</MainCard>
		</>
	);
}

export default VoucherUsersModal;
