import {
	DELETE_EMPLOYEE_SALE,
	RESET_EMPLOYEE_SALE,
	SELECT_EMPLOYEE_SALE,
	UPDATE_EMPLOYEE_SALE,
	UPDATE_VALUE_EMPLOYEE_SALE,
} from "./type";

export const actSelectEmployeeSale = (data) => ({
	payload: data,
	type: SELECT_EMPLOYEE_SALE,
});

export const actDeleteEmployeeSale = (data) => ({
	payload: data,
	type: DELETE_EMPLOYEE_SALE,
});
export const actUpdateEmployeeSale = (data) => ({
	payload: data,
	type: UPDATE_EMPLOYEE_SALE,
});

export const actUpdateValueEmployeeSale = ({ data, val, name }) => ({
	payload: { data, val, name },
	type: UPDATE_VALUE_EMPLOYEE_SALE,
});
export const actResetEmployeeSale = () => ({
	type: RESET_EMPLOYEE_SALE,
});
