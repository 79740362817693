import {
	GET_UPDATE_COMBO_USING,
	GET_USER_COMBO,
	RESET_USER_COMBO_USING,
	SET_COUNT_USING,
	SET_USER_COMBO,
} from "store/actions/UserComboServiceAction/type";

const initialState = {
	comboServices: [],
	countUsing: {},
};

const UserComboServiceReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_USER_COMBO:
			return { ...state, comboServices: payload };
		case SET_COUNT_USING:
			return { ...state, countUsing: payload };
		case GET_UPDATE_COMBO_USING:
			return { ...state, comboServices: payload };
		case RESET_USER_COMBO_USING:
			return initialState;
		default:
			return state;
	}
};
export default UserComboServiceReducer;
