import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import classnames from "classnames/bind";
import styles from "./CustomerItem.module.scss";

const cx = classnames.bind(styles);
function CustomerItem({ customer, onPressCustomer, isActive }) {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				padding: "20px",
			}}
			className={cx("wrapper", "box", isActive && "active")}
			onClick={onPressCustomer}
		>
			<Stack width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
				<Typography variant="h5" color="primary">
					{customer?.username}
				</Typography>
				{customer?.fullname && <Typography variant="h5">{customer?.fullname}</Typography>}
			</Stack>
		</Box>
	);
}

export default CustomerItem;
