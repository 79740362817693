import { QrCode2 } from "@mui/icons-material";
import { Button, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IMAGES } from "assets/images/imagePath";
import React from "react";

function PageSizeItem({
	image,
	title = "Mẫu giấy cuộn 3 nhãn (Khổ giấy in nhãn 104x22mm/4.2x0.9 Inch)",
	onSelectTemplate,
	data,
}) {
	return (
		<Card sx={{ display: "flex", border: "1px solid #f2f2f2" }}>
			<CardMedia
				component="img"
				sx={{ width: 151 }}
				image={image || IMAGES.avatar_default}
				alt="Live from space album cover"
			/>
			<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
				<CardContent sx={{ flex: "1 0 auto" }}>
					<Typography component="p" variant="h5">
						{title}
					</Typography>
				</CardContent>
				<Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
					<Button onClick={onSelectTemplate} variant="contained" startIcon={<QrCode2 />}>
						Xem mẫu in
					</Button>
				</Box>
			</Box>
		</Card>
	);
}

export default PageSizeItem;
