import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormLabel, Grid, Switch } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import postCategoryAPI from "api/PostCatogeryAPI";
import classnames from "classnames/bind";
import { PATTERN } from "constants/common";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./ServiceCategoryModal.module.scss";

const cx = classnames.bind(styles);

const TextValidation = styled("span")({
	color: "red",
});
function PostCategoryModal({ ...props }) {
	const { isOpen, isClose, isLoading, formData, isUpdate, listSelect, getListTable } = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));

	const { register, handleSubmit, errors, control, reset, getValues, watch, setValue } = useForm({
		defaultValues: isUpdate && formData,
		mode: "onChange",
	});
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [type, setType] = useState("NOTIFICATION");
	//ICON

	// const [image, setImage] = useState(formData.icon);
	// useEffect(() => {
	// 	setImage(formData.icon);
	// }, [formData]);

	//END ICON

	const handleClose = () => {
		isClose(false);
	};
	const onSubmit = async (data) => {
		try {
			setLoading(true);
			if (!isUpdate) {
				data.sort = !!data.sort === false ? undefined : data.sort;
				// data.icon = urlImg;
				const response = await postCategoryAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			} else {
				data.sort = !!data.sort === false ? undefined : data.sort;

				const response = await postCategoryAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			console.log("===> AQ 🥕  => onSubmit => error", error);
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};
	useEffect(() => {
		if (isUpdate) {
			setCkData(formData?.implementation_process);
			setType(formData?.category_service_id);
		} else {
			setCkData("");
			setType(selectList && selectList[0].value);
		}
		return () => {
			reset();
		};
	}, [isOpen]);
	const translator = (text) => {
		return `abeauty.categoryService.dialog_form.${text}`;
	};

	return (
		<div>
			<Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle
					variant="h3"
					sx={{
						fontSize: "18px",
						textAlign: "center",
					}}
				>
					{isUpdate ? "Cập nhật danh mục bài viết" : "Tạo mới danh mục bài viết"}
				</DialogTitle>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogContent>
						<Grid
							container
							spacing={1}
							style={{
								marginBottom: 20,
							}}
						>
							<Grid item xs={12}>
								<FormLabel component="legend">{t(translator("title"))}</FormLabel>

								<Controller
									name="title"
									control={control}
									rules={{
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Vui lòng điền tên danh mục",
										},
									}}
									defaultValue={isUpdate ? formData.title : ""}
									as={
										<TextField
											hiddenLabel
											margin="dense"
											type="text"
											fullWidth
											size="small"
											variant="filled"
										/>
									}
								/>

								{errors.title && <TextValidation>{errors.title.message}</TextValidation>}
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions
						sx={{
							justifyContent: "center",
						}}
					>
						<LoadingButton
							type="submit"
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="contained"
							className={cx("button__action")}
						>
							{isUpdate ? t(translator("update")) : t(translator("create"))}
						</LoadingButton>
					</DialogActions>

					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showErr.state}
						onClose={() => setShowErr({ state: false })}
						// action={action}
						autoHideDuration={5000}
						severity="error"
					>
						<Alert severity="error">{showErr.message}</Alert>
					</Snackbar>
				</form>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default PostCategoryModal;
