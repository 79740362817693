import { Stack } from "@mui/material";
import CardLayout from "components/FilterAdvance/CardLayout";
import SearchFilter from "components/FilterAdvance/SearchFilter";
import InputField from "components/modules/form/InputField";
import SideFilter from "components/SideFilter";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
	internalOrderSearchFilter,
	orderInvoicesSearchFilter,
} from "components/FilterAdvance/SearchFilter/data";
import BranchFilter from "components/FilterAdvance/BranchFIlter";
import MultipleCheckboxField from "components/modules/form/MultipleCheckboxField";
import { orderStatus, paymentStatus } from "components/FilterAdvance/StatusFilter/data";
import { useSelector } from "react-redux";
import DateFilter from "components/FilterAdvance/DateFilter";

function InternalFilter({ setFilterData, filterData }) {
	const { control, handleSubmit, reset } = useForm({
		defaultValues: filterData,
	});
	const onSubmit = (data) => {
		setFilterData(data);
	};

	useEffect(() => {
		reset(filterData);
	}, [filterData]);

	return (
		<SideFilter>
			<Stack
				minHeight={"100vh"}
				gap={2}
				component={"form"}
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						handleSubmit(onSubmit)();
					}
				}}
			>
				<CardLayout
					title={"Tìm kiếm"}
					defaultContent={{
						Component: InputField,
						props: {
							name: "order_code",
							label: "Theo mã đơn hàng",
							control, //control from userForm
						},
					}}
					mainContent={{
						Component: SearchFilter,
						props: {
							data: internalOrderSearchFilter,
							control,
						},
					}}
				/>

				<CardLayout
					title={"Chi nhánh"}
					hasExpanded={false}
					mainContent={{
						Component: BranchFilter,
						props: {
							name: "branch",
							control,
						},
					}}
				/>
				<CardLayout
					title={"Thời gian"}
					hasExpanded={false}
					mainContent={{
						Component: DateFilter,
						props: {
							name: "date",
							control,
						},
					}}
				/>
				{/* <CardLayout
					title={"Trạng thái"}
					mainContent={{
						Component: MultipleCheckboxField,
						props: {
							name: "activity",
							control,
							checkArray: orderStatus,
						},
					}}
				/> */}
				{/* <CardLayout
					title={"Phương thức thanh toán"}
					mainContent={{
						Component: MultipleCheckboxField,
						props: {
							name: "payment_type",
							control,
							checkArray: paymentStatus,
						},
					}}
				/> */}
			</Stack>
		</SideFilter>
	);
}

export default InternalFilter;
