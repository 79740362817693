import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	Input,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
} from "@mui/material";
import bookingAPI from "api/BookingAPI";
import orderAPI from "api/OrderAPI";
import { activityOrderTranslator } from "func";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";

function OrderActivityModal({ open, setOpen, data, getListTable }) {
	const handleClose = () => {
		setOpen(false);
	};
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [activity, setActivity] = useState(data.activity || "CONFIRMED");
	const [noteAdmin, setNoteAdmin] = useState();
	const bodyRequest = useMemo(
		() => ({
			activity,
			reason: noteAdmin,
		}),
		[activity, noteAdmin]
	);

	const handleSetActivity = (event) => {
		setActivity(event.target.value);
		if (event.target.value !== "CANCELED") {
			setNoteAdmin("");
		}
	};

	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const handleSubmit = async () => {
		setLoading(true);

		try {
			if (bodyRequest.activity === "CANCELED") {
				const res = await orderAPI.cancelOrder(data.id, bodyRequest);
			} else {
				const res = await orderAPI.update(data.id, bodyRequest);
			}

			setLoading(false);
			await getListTable();

			dispatch(actGetWaitingRequest(branchActiveId));

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			handleClose();
		} catch (err) {
			enqueueSnackbar(err?.message || t("error"), {
				variant: "error",
				autoHideDuration: 2000,
			});
			await getListTable();

			setLoading(false);
		}
	};
	const selectList = [
		{
			value: "WAITING",
			text: "Chờ xác nhận",
			step: 0,
		},

		{
			value: "CONFIRMED",
			text: "Chờ lấy hàng",
			step: 1,
			isHide: data.order_type === "COMBO",
		},
		{
			value: "PROCESSING",
			text: "Đang giao",
			step: 2,
			isHide: data.order_type === "COMBO",
		},
		{
			value: "COMPLETED",
			text: "Đã giao",
			step: 3,
		},
		{
			value: "CANCELED",
			text: "Đã hủy",
			step: 99,
		},
	];
	useEffect(() => {
		if (open) {
			if (data.activity === "WAITING") {
				setActivity(selectList[0].value);
			} else {
				setActivity(data.activity);
			}
		}
		return () => setNoteAdmin("");
	}, [open]);

	const dataStep = activityOrderTranslator(data.activity);
	// @ts-expect-error autofill of arbitrary value is not handled.
	return (
		<React.Fragment>
			<Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
				<DialogTitle sx={{ fontSize: "18px" }}>Chỉnh sửa trạng thái đơn hàng</DialogTitle>
				<DialogContent>
					<Box
						noValidate
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
							m: "auto",
						}}
					>
						<FormControl sx={{ mt: 2 }}>
							<InputLabel htmlFor="activity">Trạng thái lịch hẹn</InputLabel>
							<Select
								fullWidth
								autoFocus
								defaultValue={data.activity}
								value={activity}
								onChange={handleSetActivity}
								label="Trạng thái lịch hẹn"
								inputProps={{
									name: "activity",
									id: "activity",
								}}
							>
								{selectList.map((select) => {
									const step = select.step;
									return (
										<MenuItem
											sx={{
												display: select.isHide && "none",
											}}
											key={select.value}
											value={select.value}
											disabled={step < dataStep?.step}
										>
											{select.text}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Box>

					{activity === "CANCELED" && (
						<Box
							noValidate
							component="form"
							sx={{
								display: "flex",
								flexDirection: "column",
								m: "auto",
							}}
						>
							<FormControl sx={{ mt: 2 }}>
								<TextField
									margin="dense"
									name="note_admin"
									label={"Lý do hủy"}
									type="text"
									fullWidth
									multiline
									minRows={4}
									value={noteAdmin}
									onChange={(e) => setNoteAdmin(e.target.value)}
								/>
							</FormControl>
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<LoadingButton
						onClick={() => handleSubmit()}
						// endIcon={<SaveIcon />}
						loading={loading}
						loadingPosition="end"
						variant="contained"
					>
						Chỉnh sửa
					</LoadingButton>
				</DialogActions>

				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</React.Fragment>
	);
}

export default OrderActivityModal;
