import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { DualAxes } from "@ant-design/plots";
import { Box, Typography } from "@mui/material";
import LinkIcon from "components/LinkIcon";

const ColLineChart = ({
	dataProps = [],
	chartText = {
		title: "Thống kê lịch hẹn",
		column: "Doanh thu",
		line: "Số lần đặt lịch",
	},
	propsLink,
	linkPath,
}) => {
	const dataResults = dataProps.map((item) => ({
		...item,

		seriesColumn: chartText.column,
		seriesLine: chartText.line,
	}));
	const config = {
		data: [dataResults, dataResults],
		xField: "time",
		yField: ["revenue", "count"],
		yAxis: {
			min: 0,
			revenue: {
				label: {
					formatter: (v) => `${v}`.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
				},
			},
			count: {
				label: {
					formatter: (v) => `${v}`.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
				},
			},
		},
		meta: {
			revenue: {
				formatter: (v) => `${v}`.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
			},
			count: {
				formatter: (v) => `${v}`.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
			},
		},
		geometryOptions: [
			{
				geometry: "column",
				seriesField: "seriesColumn",
				columnStyle: {
					radius: [100000, 100000],
				},
			},
			{
				geometry: "line",
				lineStyle: {
					lineWidth: 2,
				},
				seriesField: "seriesLine",
				smooth: true,
			},
		],
		slider: {
			start: 0,
			end: 1,
		},
		legend: {
			position: "top-left",
		},
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "10px",
				}}
			>
				<Typography variant="h3" id="header_title" sx={{ margin: "20px 0" }}>
					{chartText.title}
				</Typography>
				{linkPath && <LinkIcon to={linkPath} {...propsLink} />}
			</Box>
			<DualAxes {...config} />
		</>
	);
};

export default ColLineChart;
