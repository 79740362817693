import React, { useContext, useEffect, useState } from "react";
import postAPI from "api/PostAPI";
import { useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import { Markup } from "interweave";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import { LoadingContext } from "providers/LoadingProvider";

function PostInfo() {
	const params = useParams();
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const [data, setData] = useState([]);
	const findPost = async () => {
		showLoading();
		const field =
			'["$all", {"category_post" : ["$all"]}, {"employee" : ["fullname", "username", "deleted_at","$paranoid"]}]';
		const payload = {
			fields: field,
		};
		try {
			const res = await postAPI.findOne(params.id, payload);
			setData(res.results?.object);
			hideLoading();
		} catch (error) {
			console.log(error);
			hideLoading();
		}
	};
	useEffect(() => {
		findPost();
	}, []);

	const dataParams = [
		{
			groupId: 1,
			groupTitle: "Thông tin bài viết",
			gridProps: {},
			children: [
				{
					id: "employee",
					title: "Người tạo",
					value: data?.employee?.fullname || data?.employee?.username || "Không có dữ liệu",
					component: Text,
					childrenProps: {
						//all Props component here
						span: 8,
						style: {
							textDecoration: !!data?.employee?.deleted_at ? "line-through" : "undefined",
						},
					},
				},
				{
					id: "title",
					title: "Tiêu đề bài viết",
					value: data.title,
					component: Text,
					childrenProps: {
						//all Props component here
						span: 8,
					},
				},
				{
					id: "category_post",
					title: "Danh mục bài viết",
					value: data.category_post?.title,
					component: Text,
					childrenProps: {
						span: 8,
					},
				},

				// {
				// 	id: "description",
				// 	title: "Mô tả sản phẩm",
				// 	value: data.description || "Không có dữ liệu",
				// 	component: Text,
				// 	childrenProps: {
				// 		span: 24,
				// 	},
				// },

				{
					id: "updated_at",
					title: "Thời gian chỉnh sửa",
					value: moment.utc(data.updated_at).format("DD/MM/YYYY HH:mm"),
					component: Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "created_at_unix_timestamp",
					title: "Thời gian tạo",
					value: moment(data.created_at_unix_timestamp, "x").format("DD/MM/YYYY HH:mm"),
					component: Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "description_cms",
					title: "Chi tiết bài viết",
					value: "",
					component: Markup,
					childrenProps: {
						span: 24,
						className: "ck-content",
						content: data.description_cms || "Không có dữ liệu",
					},
				},
			],
		},
	];

	return (
		<MainCard>
			<DetailInformation data={dataParams} />
		</MainCard>
	);
}

export default PostInfo;
