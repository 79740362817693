import { Box, Stack, Typography } from "@mui/material";
import { IMAGES } from "assets/images/imagePath";
import useCustomAuth from "hooks/useCustomAuth";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import MainCard from "ui-component/cards/MainCard";

function NotPermission() {
	// const navigate = useNavigate();

	// const { employeeInfo, role } = useCustomAuth();
	// console.log(role);

	// const firstMount = useRef(true);
	// useEffect(() => {
	// 	if (firstMount.current) {
	// 		firstMount.current = false;
	// 		return;
	// 	}

	// 	navigate(-1);
	// }, [role]);

	return (
		<MainCard>
			<Box
				height={"100%"}
				width={"100%"}
				display="flex"
				justifyContent={"center"}
				alignItems="center"
			>
				<Stack direction={"column"} alignItems="center">
					<img
						style={{
							width: "500px",
							objectFit: "contain",
							objectPosition: "center",
						}}
						src={IMAGES.logo}
						alt="logo"
					/>
					<Typography variant="h3">Bạn không có quyền vào trang này!</Typography>
					<Typography variant="h3" my={2}>
						Vui lòng chọn trang khác hoặc liên hệ quản trị viên để được hỗ trợ!
					</Typography>
				</Stack>
			</Box>
		</MainCard>
	);
}

export default NotPermission;
