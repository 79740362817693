import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Box, Button, Grid, Typography } from "@mui/material";
import productAPI from "api/ProductAPI";
import LoadingComponent from "components/loading";
import OrderPrintTemplate from "components/PrintTemplate/OrderPrintTemplate";
import { formatVND } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import useDebounce from "hooks/useDebounce";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import { SET_MENU } from "store/actions/CustomizationAction/types";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import CreateOrderModal from "ui-component/modal-form/CreateOrderModal";
import DialogMain from "ui-component/modal-form/DialogMui";
import CartSide from "./components/CartSide";
import LeftSide from "./components/LeftSide";
import { actReset, actSetOrderList, actUpdateOrderType } from "./store/action";
import styles from "./CreateOrder.module.scss";
import classnames from "classnames/bind";
import { QrCodeScannerSharp } from "@mui/icons-material";
import ImageModal from "ui-component/modal-form/ImageModal";
import { IMAGES } from "assets/images/imagePath";
import { ROLE } from "constants/employeeRole";
import { ALERT_PERMISSION } from "constants/constant";
import orderAPI from "api/OrderAPI";
import InternalSide from "./components/InternalSide";

const cx = classnames.bind(styles);
function CreateOrder({ tab = "ORDER" }) {
	const navigate = useNavigate();
	const location = useLocation();
	const modalContext = useContext(ModalContext);
	const dispatch = useDispatch();
	const [openModalImage, setOpenModalImage] = useState(false);
	const handleConfirmBack = () => {
		modalContext.showConfirmModal(
			() => {
				navigate("/order");
				dispatch({ type: SET_MENU, opened: true });
			},
			"Toàn bộ quá trình sẽ bị hủy bỏ, bạn có chắc chắn không?",
			"Đồng ý"
		);
	};

	const { infoCustomer, orderList } = useSelector((state) => state.createOrder);

	const [loadingCreate, setLoadingCreate] = useState(false);
	const createInternalOrder = async () => {
		setLoadingCreate(true);
		const orderItems = orderList.map((item) => ({
			item_id: item.id,
			quantity: item.count,
			price: Number(item.price),
			discount: Number(item.sale_price),
		}));
		const body = {
			// user_id: infoCustomer.userId || null,
			// phone: infoCustomer.order_type === "COMBO" ? infoCustomer.phone : data?.phone,
			phone: infoCustomer.phone,
			branch_id: branchActiveId,
			payment_type: "CASH",
			order_type: "INTERNAL",
			order_item: orderItems,
			activity: "COMPLETED",
		};
		try {
			const res = await orderAPI.create(body);
			setLoadingCreate(false);
			dispatch(actReset());
			enqueueSnackbar("Xuất hàng thành công", {
				variant: "success",
				autoHideDuration: 1000,
			});
		} catch (error) {
			console.log(error);
			setLoadingCreate(false);

			enqueueSnackbar(error?.message || "Có lỗi, vui lòng thử lại", {
				variant: "error",
				autoHideDuration: 1000,
			});
		}
	};
	const confirmCreateInternalOrder = () => {
		modalContext.showConfirmModal(
			() => {
				createInternalOrder();
			},
			"Đơn hàng sẽ được tạo dưới dạng xuất hàng nội bộ và được ghi nhận lại bởi hệ thống, bạn có chắc chắn thực hiện?",
			"Đồng ý"
		);
	};

	const [orderInfo, setOrderInfo] = useState({
		open: false,
		data: {
			infoCustomer: { ...infoCustomer },
			orderList: [...orderList],
		},
	});

	const { role } = useCustomAuth();

	useEffect(() => {
		if (orderInfo.open) {
			document.removeEventListener("keypress");
		} else {
			document.addEventListener("keypress");
		}
	}, [orderInfo.open]);
	const checkDisabled = () => {
		if (infoCustomer.totalPrice < 0) {
			return true;
		} else if (orderList.length === 0) {
			return true;
		} else if (!infoCustomer.userId) {
			return true;
		} else {
			if (infoCustomer.order_type === "PRODUCT") {
				return false;
			} else if (infoCustomer.order_type === "COMBO") {
				if (!infoCustomer.phone) {
					return true;
				} else {
					return false;
				}
			}
		}
	};

	//Print Func
	const componentRef = useRef(null);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: true,
	});
	const { enqueueSnackbar } = useSnackbar();
	//Print logic
	const [dataToPrint, setDataPrint] = useState({});
	const { settingCMS } = useCustomAuth();
	const [isPrint, setIsPrint] = useState(false); // Tránh lần đầu mount gọi hàm Print
	useEffect(() => {
		if (settingCMS.auto_print && isPrint) {
			handlePrint();
		}
	}, [dataToPrint, isPrint]);

	const [barCode, setBarCode] = useState("");
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const resultsCode = useDebounce(barCode);
	const [loading, setLoading] = useState(false);
	const refInput = useRef(null);
	const [productScan, setProductScan] = useState({});

	const checkOutStockProduct = (product) => {
		const productInCart = orderList.find((item) => item.id === product.id);
		if (productInCart) {
			if (productInCart.count >= product.inventory_amount_branch) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const findProductScan = async (code) => {
		setLoading(true);
		let filterScan = {};
		if (Number.isNaN(Number(code))) {
			filterScan = {
				dynamic_link_code_firebase: code,
				branch_id: branchActiveId,
				type: "PRODUCT",
			};
		} else {
			filterScan = {
				barcode: code,
				branch_id: branchActiveId,
				type: "PRODUCT",
			};
		}
		const params = {
			fields: '["$all"]',
			filter: JSON.stringify(filterScan),
		};

		try {
			const res = await productAPI.getList(params);
			const products = res.results.objects.rows;
			const isOutStock = checkOutStockProduct(products[0]);

			if (products.length === 0) {
				enqueueSnackbar("Không tìm thấy sản phẩm, vui lòng thử lại", {
					autoHideDuration: 2000,
					variant: "error",
				});
			} else if (products[0].branch_id !== branchActiveId) {
				enqueueSnackbar("Sản phẩm không thuộc chi nhánh đang chọn, vui lòng kiểm tra lại", {
					autoHideDuration: 2000,
					variant: "error",
				});
			} else if (isOutStock) {
				enqueueSnackbar("Sản phẩm này không đủ số lượng, vui lòng kiểm tra lại", {
					autoHideDuration: 2000,
					variant: "error",
				});
			} else if (products[0].inventory_amount_branch === 0) {
				enqueueSnackbar("Sản phẩm này đã hết hàng, vui lòng kiểm tra lại", {
					autoHideDuration: 2000,
					variant: "error",
				});
			} else {
				// Phải dispatch orderType trước
				await dispatch(actUpdateOrderType("PRODUCT"));

				await dispatch(actSetOrderList(products[0]));
				setProductScan(products[0]);
			}
			setLoading(false);
		} catch (err) {
			enqueueSnackbar("Không tìm thấy sản phẩm, vui lòng thử lại", {
				autoHideDuration: 2000,
				variant: "error",
			});
			console.log(err);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (resultsCode?.trim() === "") {
			//Khi nào mở lại Scanner Comp thì uncomment dòng này

			// if (refInput.current) {
			// 	refInput.current.value = "";
			// }
			setBarCode("");
			return;
		} else if (!resultsCode) {
			return;
		} else {
			let firebaseCode = resultsCode;
			if (resultsCode.includes("http")) {
				const splitArr = resultsCode?.split("/");
				firebaseCode = splitArr[splitArr?.length - 1];
			}
			findProductScan(firebaseCode);
			//Khi nào mở lại Scanner Comp thì uncomment dòng này

			// if (refInput.current) {
			// 	refInput.current.value = "";
			// }
			setBarCode("");
		}
	}, [resultsCode]);

	useEffect(() => {
		if (orderInfo.open) {
			setRenderScanner(false);
		} else {
			setRenderScanner(true);
		}
	}, [orderInfo.open]);

	const [renderScanner, setRenderScanner] = useState(true);

	useEffect(() => {
		if (!role || !branchActiveId) return;
		if (role?.TRANSACTION_CREATE !== ROLE.TRANSACTION_CREATE) {
			navigate("/not-permission", { replace: true });
			dispatch(actReset());

			return;
		}
		return () => {
			dispatch(actReset());
		};
	}, [role, branchActiveId]);

	return (
		<>
			{loading && <LoadingComponent text="Đang tải..." />}
			{location.state?.fromOrder && (
				<Button
					variant="contained"
					onClick={() => handleConfirmBack()}
					startIcon={<SettingsBackupRestoreIcon />}
				>
					Trở về
				</Button>
			)}
			{/* {renderScanner && <Scanner barCode={barCode} setBarCode={setBarCode} ref={refInput} />} */}
			<MainCard
				sx={{
					minHeight: "100vh",
				}}
			>
				<div onClick={() => setOpenModalImage(true)}>
					<span className={cx("button-85")}>
						<QrCodeScannerSharp />
					</span>
				</div>
				{/* <HeaderActions title={"Tạo đơn hàng mới"} /> */}
				<Grid
					container
					sx={{
						flexWrap: {
							xs: "wrap",
							md: "nowrap",
						},
						justifyContent: "center",
						gap: "10px",
						minHeight: "100vh",
					}}
				>
					<Grid item xs={12} md={4}>
						<Box sx={{ padding: "20px" }}>
							<LeftSide productScan={productScan} setBarCode={setBarCode} />
						</Box>
					</Grid>

					<Grid
						item
						xs={12}
						md={8}
						sx={{
							borderLeft: "1px dashed #311d4f",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
						}}
					>
						<Box sx={{ padding: "20px" }}>
							{tab === "INTERNAL" ? <InternalSide /> : <CartSide />}
						</Box>

						<Grid
							container
							sx={{
								justifyContent: "flex-start",
								marginLeft: "20px",
								marginBottom: "20px",
								gap: 1,
							}}
						>
							<Grid item xs={12}>
								{!infoCustomer.userId && (
									<Typography color={"error"} variant="h5">
										{tab === "INTERNAL"
											? "Thiếu thông tin nhân viên"
											: "Thiếu thông tin khách hàng"}
									</Typography>
								)}
								{orderList.length === 0 && (
									<Typography color={"error"} variant="h5">
										Chưa có sản phẩm trong giỏ hàng
									</Typography>
								)}
							</Grid>
							<Grid item xs={8}>
								{tab === "INTERNAL" ? (
									<Button
										disabled={checkDisabled()}
										variant="outlined"
										onClick={() => {
											setOrderInfo((prev) => ({
												...prev,

												open: true,
											}));
											dispatch(actUpdateOrderType("INTERNAL"));
										}}
									>
										Xuất hàng nội bộ
									</Button>
								) : (
									<Button
										disabled={checkDisabled()}
										variant="outlined"
										sx={{ mr: 1 }}
										onClick={() => {
											setOrderInfo((prev) => ({
												...prev,
												open: true,
											}));
											dispatch(actUpdateOrderType("PRODUCT"));
										}}
									>
										Thanh toán ({formatVND(infoCustomer.totalPrice)})
									</Button>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</MainCard>
			<DialogMain
				maxWidth={"lg"}
				title={"Thông tin đơn hàng"}
				fullWidth
				open={orderInfo.open}
				onClose={() => {
					setOrderInfo((prev) => ({
						...prev,
						open: false,
					}));
				}}
			>
				<CreateOrderModal
					data={orderInfo.data}
					onCloseModal={() => {
						setOrderInfo((prev) => ({
							...prev,
							open: false,
						}));
					}}
					setDataPrint={setDataPrint}
					setIsPrint={setIsPrint}
					order_tab={tab}
				/>
			</DialogMain>
			<OrderPrintTemplate className="content-print" dataToPrint={dataToPrint} ref={componentRef} />

			<ImageModal
				title="Quét mã để tải ứng dụng"
				openModalZoom={openModalImage}
				handleCloseModal={() => setOpenModalImage(false)}
				imageZoom={IMAGES.appQr}
			/>
		</>
	);
}

export default CreateOrder;
