import authAPI from "api/AuthAPI";
import { GET_ROLE_EMPLOYEE, LOGIN_EMPLOYEE, LOGOUT_EMPLOYEE, UPLOAD_AVATAR } from "./type";

export const logout = (onSuccess = (success = false) => {}) => {
	return async (dispatch) => {
		try {
			localStorage.removeItem("employeeInfo");
			dispatch({
				type: LOGOUT_EMPLOYEE,
			});

			onSuccess(true);
		} catch (err) {
			onSuccess(false);
			console.log(err);
		}
	};
};

export const loginEmployee = (
	data,
	onSuccess = (success = false) => {},
	onError = (error = "") => {}
) => {
	return async (dispatch, getState) => {
		try {
			const res = await authAPI.loginCMS(data);
			dispatch({
				type: LOGIN_EMPLOYEE,
				payload: res?.results,
			});

			const employeeInfo = res?.results;
			localStorage.setItem("employeeInfo", JSON.stringify(employeeInfo));
			onSuccess(true);
		} catch (err) {
			onSuccess(false);
			onError(err);
		}
	};
};

export const getRoleEmployee = (
	data,
	onSuccess = (success = false) => {},
	onError = (error = "") => {}
) => {
	return async (dispatch, getState) => {
		try {
			const params = {
				branch_id: data,
			};
			const res = await authAPI.getRoleEmployee(params);
			dispatch({
				type: GET_ROLE_EMPLOYEE,
				payload: res?.results?.objects,
			});

			onSuccess(true);
		} catch (err) {
			onSuccess(false);
			onError(err);
		}
	};
};

export const updateAvatar = (data) => {
	return (dispatch, getState) => {
		dispatch({
			type: UPLOAD_AVATAR,
			payload: data,
		});

		const employeeInfo = JSON.parse(localStorage.getItem("employeeInfo"));
		employeeInfo.object = data;

		localStorage.setItem("employeeInfo", JSON.stringify(employeeInfo));
	};
};
