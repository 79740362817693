import { Box, Typography, Tooltip } from "@mui/material";
import { InputNumber } from "antd";
import React from "react";
import classnames from "classnames/bind";
import styles from "./ComboServiceModal.module.scss";
import { Close } from "@mui/icons-material";
import NoData from "ui-component/NoData";
import { formatVND } from "func";

const cx = classnames.bind(styles);
function ComboItemsTable({ comboItems, setComboItems, isUpdate }) {
	const handleDelete = (service) => {
		setComboItems((prev) => prev.filter((item) => service.id !== item.id));
	};
	const handleChangeAmount = (e, service) => {
		setComboItems((prev) => {
			return prev.map((item) => {
				if (item.id === service.id) {
					return {
						...item,
						amount: e,
					};
				}
				return item;
			});
		});
	};
	return (
		<Box>
			<Box
				sx={{
					borderRadius: "4px",
					border: "1px solid black",
					marginBottom: "20px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "flex-start",
						background: "#311D4F",
						color: "#fff",
						padding: "10px",
						gap: "15px",
					}}
				>
					<Typography
						variant="h4"
						sx={{
							flex: 0.2,
							color: "#fff",
						}}
					>
						STT
					</Typography>

					<Typography variant="h4" sx={{ flex: 2, color: "#fff", textAlign: "left" }}>
						Tên dịch vụ
					</Typography>
					<Typography variant="h4" sx={{ flex: 1, color: "#fff", textAlign: "center" }}>
						Số lượng
					</Typography>
					<Typography variant="h4" sx={{ flex: 1, color: "#fff", textAlign: "center" }}>
						Giá vốn
					</Typography>
					<Typography variant="h4" sx={{ flex: 1, color: "#fff", textAlign: "center" }}>
						Thành tiền
					</Typography>
					<Typography
						variant="h4"
						color={"error"}
						sx={{
							flex: 0.2,
						}}
					></Typography>
				</Box>
				{Array.isArray(comboItems) && comboItems.length === 0 ? (
					<NoData
						text={"Hãy chọn sản phẩm"}
						sx={{
							padding: "20px",
							width: "100%",
							maxHeight: "100%",
							color: "red",
						}}
					/>
				) : (
					comboItems.map((service, idxComboItem) => {
						return (
							<Box
								key={service.id}
								sx={{
									display: "flex",
									flexDirection: "flex-start",
									alignItems: "center",
									background: "#fff",
									color: "#000",
									padding: "10px",
									gap: "15px",
								}}
							>
								<Typography
									variant="h4"
									sx={{
										flex: 0.2,
										color: "#000",
										textAlign: "center",
									}}
								>
									{idxComboItem + 1}
								</Typography>

								<Typography
									variant="h4"
									className={cx("item__text")}
									sx={{ flex: 2, color: "#000", textAlign: "left" }}
								>
									<Tooltip title={service.title} sx={{ zIndex: "100000" }}>
										<span>{service.title}</span>
									</Tooltip>
								</Typography>
								<Typography variant="h4" sx={{ flex: 1, color: "#000", textAlign: "center" }}>
									<InputNumber
										min={1}
										value={service.amount}
										onChange={(e) => handleChangeAmount(e, service)}
									/>
								</Typography>
								<Typography variant="h4" sx={{ flex: 1, color: "#000", textAlign: "center" }}>
									{formatVND(service.price)}
								</Typography>
								<Typography variant="h4" sx={{ flex: 1, color: "#000", textAlign: "center" }}>
									{formatVND((service.price - service.discount) * service.amount)}
								</Typography>
								<Typography
									variant="h4"
									color={"error"}
									sx={{
										flex: 0.2,
										cursor: "pointer",
									}}
									onClick={() => handleDelete(service)}
								>
									<Close />
								</Typography>
							</Box>
						);
					})
				)}
			</Box>
		</Box>
	);
}

export default ComboItemsTable;
