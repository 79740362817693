import axiosClient from "../axiosClient";

const authAPI = {
	async loginCMS(data) {
		const url = `auth/superadmin_login`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},
	async refreshToken(data) {
		const url = `auth/refresh`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},
	async getRoleEmployee(params) {
		const url = `employee_role/my_role`;

		const resp = await axiosClient.get(url, { params });
		return resp;
	},
	async registerUser(data) {
		const url = `auth/register`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async checkPhoneRegistered(phoneNum) {
		//phoneNum is String
		const url = "auth/find_user_by_phone";
		const resp = await axiosClient.post(url, {
			phone: phoneNum,
		});
		return resp;
	},
	async checkFullDetailsUser(phoneNum) {
		//phoneNum is String
		const url = "auth/find_user_by_phone_and_create_if_needed";
		const resp = await axiosClient.post(url, {
			phone: phoneNum,
		});
		return resp;
	},
};

export default authAPI;
