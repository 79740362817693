import { TryRounded } from "@mui/icons-material";
import { Box, Grid, Pagination, Stack, Tab, Tabs } from "@mui/material";
import comboServiceAPI from "api/ComboServiceAPI";
import productAPI from "api/ProductAPI";
import LoadingComponent from "components/loading";
import useDebounce from "hooks/useDebounce";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoData from "ui-component/NoData";
import { actSetOrderList, actUpdateOrderType } from "../../store/action";
import OrderSearchComponent from "../OrderSearchComponent";
import ProductItem from "../ProductItem/ProductItem";
import ProductSearchItem from "../ProductItem/ProductSearchItem";

function LeftSide({ productScan, setBarCode }) {
	const [searchValue, setSearchValue] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	const debounceValue = useDebounce(searchValue);
	const [loading, setLoading] = useState(false);

	const [searchResults, setSearchsResults] = useState([]);
	const [productList, setProductList] = useState([]);

	const initPagination = {
		page: 1,
		limit: 10,
		total: 10,
	};
	const [pagination, setPagination] = useState(initPagination);
	//Tabs
	const [tabsValue, setTabsValue] = useState("PRODUCT");

	const handleChangeTabsValue = (event, newValue) => {
		setTabsValue(newValue);
		setPagination(initPagination);
	};
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const fetchProductList = async () => {
		setLoading(true);
		const params = {
			fields: '["$all", {"category": ["$all"]},{"unit": ["value"]}]',
			filter: `{"type" : "PRODUCT"}`,
			order: '[["updated_at", "desc"]]',
			limit: pagination.limit,
			page: pagination.page,
			branch_id: branchActiveId,
		};
		try {
			const res = await productAPI.getList(params);
			const paginationRes = {
				...pagination,
				total: res?.results?.objects?.count,
			};
			setPagination(paginationRes);
			setProductList(res?.results?.objects?.rows);
			setLoading(false);
		} catch (err) {
			enqueueSnackbar("Có lỗi xảy ra", {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);
		}
	};

	const [loadingSearchBox, setLoadingSearchBox] = useState(false);
	const fetchComboList = async () => {
		setLoading(true);
		const params = {
			fields: '["$all",{"combo_service_item":["$all", {"service": ["$all"]}]}]',
			order: '[["updated_at", "desc"]]',
			limit: pagination.limit,
			page: pagination.page,
		};
		try {
			const res = await comboServiceAPI.getList(params);
			const paginationRes = {
				...pagination,
				total: res?.results?.objects?.count,
			};
			setPagination(paginationRes);
			setProductList(res?.results?.objects?.rows);
			setLoading(false);
		} catch (err) {
			enqueueSnackbar("Có lỗi xảy ra", {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);
		}
	};

	const searchProduct = async (searchVal) => {
		setLoadingSearchBox(true);
		const params = {
			fields: `["$all", {"category": ["$all"]}, {"unit": ["value"]}, {"inventory":["branch_id", "inventory_number",{"branch":["id",{"$filter":{"id":"${branchActiveId}"}}]}]}]`,
			filter: `{"type" : "PRODUCT"}`,
			title: searchVal,
			limit: 20,
			page: 1,
		};
		try {
			const res = await productAPI.searchProduct(params);
			setSearchsResults(res.results?.objects?.rows);
			setLoadingSearchBox(false);
		} catch (err) {
			enqueueSnackbar("Có lỗi xảy ra", {
				autoHideDuration: 2000,
				variant: "error",
			});
			setLoadingSearchBox(false);
		}
	};
	useEffect(() => {
		if (!debounceValue.includes("http")) {
			searchProduct(debounceValue);
		}
	}, [debounceValue, branchActiveId]);
	const searchRef = useRef(null);

	// useEffect(() => {
	// 	setSearchValue(productScan?.title);
	// 	searchRef.current?.current?.focus();
	// 	searchRef.current?.focus();

	// 	searchRef.current?.current?.select();
	// 	searchRef.current?.select();
	// }, [productScan]);
	useEffect(() => {
		if (!branchActiveId) return;
		switch (tabsValue) {
			case "PRODUCT":
				fetchProductList();
				break;
			case "COMBO":
				// fetchComboList();
				break;
			default:
				fetchProductList();

				break;
		}
	}, [pagination.page, tabsValue, branchActiveId]);

	//Reducer
	const { orderList, infoCustomer } = useSelector((state) => state.createOrder);
	const dispatch = useDispatch();

	const modalContext = useContext(ModalContext);
	const modifyCart = (orderItemType, order) => {
		dispatch(actUpdateOrderType(orderItemType)); //Cập nhật order type mới và xóa giỏ hàng => orderList = []
		dispatch(actSetOrderList(order));
		setSearchValue("");
	};
	const handleAddOrder = (order) => {
		let orderItemType = order.combo_service_item ? "COMBO" : "PRODUCT";

		//Check order Item truyền vào có cùng type với các order trong giỏ hàng k
		if (infoCustomer.order_type === "PRODUCT" || infoCustomer.order_type === "INTERNAL") {
			dispatch(actSetOrderList(order));
			setSearchValue("");
		} else {
			if (infoCustomer.order_type === null) {
				//Lần đầu add sản phẩm không hiển thị thông báo
				modifyCart(orderItemType, order);
			} else {
				modalContext.showConfirmModal(
					() => {
						modifyCart(orderItemType, order);
					},
					"Bạn đang chọn một loại sản phẩm khác với các sản phẩm có trong giỏ hàng, điều này sẽ xóa tất cả các sản phẩm có trong giỏ hàng!",
					"Đồng ý"
				);
			}
		}
	};

	const handleChangePage = (event, newPage) => {
		setPagination({
			...pagination,
			page: newPage,
		});
	};
	return (
		<div>
			<OrderSearchComponent
				ref={searchRef}
				searchValue={searchValue}
				handleChangeSearch={(value, e) => {
					if (e.code === "Enter" || e.keyCode === 13) {
						e.preventDefault();
						e.target.select();
						setBarCode(value);
					} else {
						setSearchValue(value);
					}
				}}
				placeholderText={"Tìm theo tên hàng hóa"}
				searchResult={searchResults}
				ResultComponent={ProductItem}
				noDataText={"Không tìm thấy sản phẩm"}
				loading={loadingSearchBox}
				propsResultComponent={(item) => ({
					product: item,
					isActive: orderList.some((order) => order.id === item.id),
					onAddOrder: () => handleAddOrder(item),
				})}
			/>
			<Box
				sx={{
					flexGrow: 1,
					margin: "20px 0px",
					bgcolor: "background.paper",
				}}
			>
				{/* <Tabs
					value={tabsValue}
					onChange={handleChangeTabsValue}
					variant="scrollable"
					scrollButtons
					allowScrollButtonsMobile
				>
					<Tab label="Sản phẩm" value={"PRODUCT"} />

					<Tab label="Gói combo" value={"COMBO"} />
				</Tabs> */}
			</Box>

			<Stack spacing={2} sx={{ margin: "20px 0" }}>
				<Pagination
					count={Math.ceil(pagination.total / pagination.limit)}
					page={pagination.page}
					size="small"
					onChange={handleChangePage}
				/>
			</Stack>
			{/* ProductList Render */}
			<Box
				sx={{
					margin: "20px 0",
				}}
			>
				{loading ? (
					<LoadingComponent text={"Đang tải...."} />
				) : productList.length === 0 ? (
					<NoData text={"Không có sản phẩm nào"} />
				) : (
					<Grid container spacing={1}>
						{productList.map((item, idx) => (
							<Grid key={item.id} item xs={6} sm={4} md={12} xl={6}>
								<ProductItem
									product={item}
									isActive={orderList.some((order) => order.id === item.id)}
									onAddOrder={() => handleAddOrder(item)}
								/>
							</Grid>
						))}
					</Grid>
				)}
			</Box>
		</div>
	);
}

export default LeftSide;
