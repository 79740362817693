// assets
import { IconNotification, IconBell, IconLetterCase } from "@tabler/icons";
import { ROLE } from "constants/employeeRole";

// constant
const icons = { IconNotification, IconBell, IconLetterCase };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = (waitingRequest, role) => ({
	id: "notification",
	title: "Thông báo",
	type: "group",
	visible: role?.NOTIFICATION === ROLE.NOTIFICATION,

	children: [
		{
			id: "Notification",
			title: "Notification",
			type: "item",
			url: "notification",
			icon: icons.IconNotification,
			breadcrumbs: false,
			badge: false,
			visible: role?.NOTIFICATION === ROLE.NOTIFICATION,
		},
		{
			id: "Notisended",
			title: "noti-sended",
			type: "item",
			url: "notification-sended",
			icon: icons.IconBell,
			breadcrumbs: false,
			badge: false,
			visible: role?.NOTIFICATION === ROLE.NOTIFICATION,
		},
	],
});

export default other;
