export const orderInvoicesSearchFilter = [
	{
		name: "product",
		placeholder: "Theo mã, tên sản phẩm",
		label: "Theo mã, tên sản phẩm",
	},
	{
		name: "user",
		placeholder: "Theo tên, số điện thoại khách hàng",
		label: "Theo tên, số điện thoại khách hàng",
	},
	{
		name: "employee",
		placeholder: "Theo nhân viên bán hàng",
		label: "Theo nhân viên bán hàng",
	},
	{
		name: "note",
		placeholder: "Theo ghi chú",
		label: "Theo ghi chú",
	},
];
export const internalOrderSearchFilter = [
	{
		name: "product",
		placeholder: "Theo mã, tên sản phẩm",
		label: "Theo mã, tên sản phẩm",
	},

	{
		name: "employee",
		placeholder: "Theo nhân viên xuất hàng",
		label: "Theo nhân viên xuất hàng",
	},
	{
		name: "employee_internal",
		placeholder: "Theo nhân viên nhận hàng",
		label: "Theo nhân viên nhận hàng",
	},
	{
		name: "note",
		placeholder: "Theo ghi chú",
		label: "Theo ghi chú",
	},
];

export const bookingInvoicesSearchFilter = [
	{
		name: "service",
		placeholder: "Theo tên dịch vụ",
		label: "Theo tên dịch vụ",
	},
	{
		name: "user",
		placeholder: "Theo tên, số điện thoại khách hàng",
		label: "Theo tên, số điện thoại khách hàng",
	},
	{
		name: "employee_confirm",
		placeholder: "Theo nhân viên xác nhận",
		label: "Theo nhân viên xác nhận",
	},
	{
		name: "note",
		placeholder: "Theo ghi chú",
		label: "Theo ghi chú",
	},
];

export const packInvoicesFilter = [
	{
		name: "owner",
		placeholder: "Theo tên, số điện thoại chủ sở hữu",
		label: "Theo tên, số điện thoại chủ sở hữu",
	},
	{
		name: "user",
		placeholder: "Theo tên, số điện thoại người mua thẻ",
		label: "Theo tên, số điện thoại người mua thẻ",
	},
];
export const transactionInvoicesFilter = [
	{
		name: "employee",
		placeholder: "Theo tên, tài khoản nhân viên yêu cầu",
		label: "Theo tên, tài khoản nhân viên yêu cầu",
	},
	{
		name: "employee_confirm",
		placeholder: "Theo tên, tài khoản nhân viên xác nhận",
		label: "Theo tên, tài khoản nhân viên xác nhận",
	},
	{
		name: "user",
		placeholder: "Theo tên, số điện thoại khách hàng",
		label: "Theo tên, số điện thoại khách hàng",
	},
	// {
	// 	name: "note",
	// 	placeholder: "Ghi chú từ khách hàng",
	// 	label: "Ghi chú từ khách hàng",
	// },
	{
		name: "note_admin",
		placeholder: "Ghi chú từ nhân viên",
		label: "Ghi chú từ nhân viên",
	},
];
