import { Add, Remove } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import comboServiceAPI from "api/ComboServiceAPI";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actGetUserCombo, actSetUserCombo } from "store/actions/UserComboServiceAction";

function ComboServiceInfo({ data }) {
	// const comboServices = useSelector((state) => state.userComboService.comboServices);
	return (
		<Box>
			{data.map((combo, idxCombo) => {
				return (
					<Box
						sx={{
							borderRadius: "4px",
							border: "1px solid black",
							marginBottom: "20px",
						}}
						key={combo.id}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "flex-start",
								background: "#311D4F",
								color: "#fff",
								padding: "10px",
							}}
						>
							<Typography
								variant="h4"
								sx={{
									flex: 2,
									color: "#fff",
								}}
							>
								{combo.title}
							</Typography>

							<Typography variant="h4" sx={{ flex: 1, color: "#fff", textAlign: "center" }}>
								Số lượng
							</Typography>
						</Box>
						{Array.isArray(combo.combo_service_item) &&
							combo?.combo_service_item?.map((service, idxComboItem) => (
								<Box
									key={service.id}
									sx={{
										display: "flex",
										flexDirection: "flex-start",
										alignItems: "center",
										background: "#fff",
										color: "#000",
										padding: "10px",
									}}
								>
									<Typography
										variant="h4"
										sx={{
											flex: 2,
											color: "#000",
										}}
									>
										{service.service?.title}
									</Typography>
									<Typography variant="h4" sx={{ flex: 1, color: "#000", textAlign: "center" }}>
										{service?.amount}
									</Typography>
								</Box>
							))}
					</Box>
				);
			})}
		</Box>
	);
}

export default ComboServiceInfo;
