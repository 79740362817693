import { Box, Stack, Typography } from "@mui/material";
import { InputNumber } from "antd";
import { handleMaskValue } from "func";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DiscountByType from "ui-component/DiscountByType";

function CartPriceDiscount({ row, onPopoverHide, priceDeductedState, resultState, discountState }) {
	const [priceDeducted, setPriceDeducted] = priceDeductedState;
	const [discountItem, setDiscountItem] = discountState;
	const [resultPrice, setResultPrice] = resultState;
	const finalPrice = useRef({});
	finalPrice.current = {
		price: priceDeducted,
		discount: discountItem,
		deducted_price: resultPrice,
	};

	useEffect(() => {
		setResultPrice(Number(priceDeducted) - Number(discountItem));
	}, [discountItem]);

	useEffect(() => {
		return () => {
			if (onPopoverHide) {
				onPopoverHide(
					finalPrice.current?.price,
					finalPrice.current?.discount,
					finalPrice.current?.deducted_price
				);
			}
		};
	}, []);

	return (
		<Stack p={2} width={"400px"} gap={1}>
			<Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
				<Typography variant="h5">Đơn giá: </Typography>

				<InputNumber
					className={"custom__inputNum right"}
					min={0}
					formatter={(value) => handleMaskValue(value)}
					parser={(value) => Number(value.toString().split(",").join(""))}
					defaultValue={priceDeducted}
					controls={false}
					style={{
						textAlign: "right",
						borderBottom: "1px solid #000",
						width: "70%",
					}}
					bordered={false}
					onChange={(e) => {
						setPriceDeducted(e);
						setResultPrice(e);
					}}
				/>
			</Stack>
			<Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
				<Typography variant="h5">Giảm giá: </Typography>

				<Box width={"70%"}>
					<DiscountByType
						total={priceDeducted}
						resultsPrice={resultPrice}
						defaultDiscount={discountItem}
						setDefaultDiscount={setDiscountItem}
						setResultPrice={setResultPrice}
					/>
				</Box>
			</Stack>
			<Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
				<Typography variant="h5">Giá bán: </Typography>

				<InputNumber
					className={"custom__inputNum right"}
					min={0}
					value={resultPrice}
					max={priceDeducted}
					formatter={(value) => handleMaskValue(value)}
					parser={(value) => value.toString().split(",").join("")}
					defaultValue={row.deducted_price}
					controls={false}
					style={{
						textAlign: "right",
						borderBottom: "1px solid #000",
						width: "70%",
					}}
					bordered={false}
					onChange={(e) => {
						setResultPrice(e);
					}}
				/>
			</Stack>
		</Stack>
	);
}

export default CartPriceDiscount;
