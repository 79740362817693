import { measureTextWidth, Pie } from "@ant-design/plots";
import { Box, Typography } from "@mui/material";
import LinkIcon from "components/LinkIcon";
import { formatCash, paymentTypeOrderTranslator } from "func";

const DonutPaymentType = ({
	data = [],
	chartType = "ORDER",
	chartText = {
		title: "Thống kê tình trạng đơn hàng",
	},
	linkPath,
	propsLink,
	chartConfig = {
		angleField: "count",
		colorField: "activity",
	},
}) => {
	function renderStatistic(containerWidth, text, style) {
		const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
		const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

		let scale = 1;

		if (containerWidth < textWidth) {
			scale = Math.min(
				Math.sqrt(
					Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))
				),
				1
			);
		}
		const textStyleStr = `width:${containerWidth}px;`;
		return `<div style="${textStyleStr};font-size:${scale}rem;line-height:${
			scale < 1 ? 1 : "inherit"
		};">${text}</div>`;
	}

	const config = {
		appendPadding: 10,
		data: data.map((item) => {
			if (item?.[chartConfig.angleField] == 0) {
				return {};
			} else {
				return {
					...item,
					[chartConfig.angleField]: Number(item?.[chartConfig?.angleField]),
					[chartConfig.colorField]: paymentTypeOrderTranslator(item?.[chartConfig?.colorField]),
				};
			}
		}),
		label: null,
		angleField: chartConfig?.angleField,
		colorField: chartConfig?.colorField,
		radius: 1,
		innerRadius: 0.64,

		statistic: {
			title: {
				offset: -10,
				style: {
					fontSize: "20px",
					overflow: "none",
				},
				customHtml: (container, view, datum) => {
					const { width, height } = container.getBoundingClientRect();
					const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
					const text = datum ? datum?.[chartConfig?.colorField] : "Tổng";
					return renderStatistic(d, text, {
						fontSize: 20,
					});
				},
			},
			content: {
				offset: 4,

				style: {
					fontSize: "25px",
					overflow: "none",
				},
				customHtml: (container, view, datum, data) => {
					const { width } = container.getBoundingClientRect();
					const text = datum
						? `${formatCash(datum?.[chartConfig.angleField])} đ`
						: `${formatCash(data.reduce((r, d) => r + d?.[chartConfig.angleField], 0))} đ`;
					return renderStatistic(width, text, {
						fontSize: 32,
					});
				},
			},
		},
		interactions: [
			{
				type: "element-selected",
			},
			{
				type: "element-active",
			},
			{
				type: "pie-statistic-active",
			},
		],
	};

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "10px",
				}}
			>
				<Typography variant="h3" id="header_title" sx={{ margin: "20px 0", textAlign: "center" }}>
					{chartText.title}
				</Typography>
				{linkPath && <LinkIcon to={linkPath} {...propsLink} />}
			</Box>
			<Pie {...config} />
		</Box>
	);
};

export default DonutPaymentType;
