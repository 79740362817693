import { Avatar, Box, ButtonBase } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconMenu2 } from "@tabler/icons";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";
import CashierSection from "layout/components/CashierSection";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { actGetListBranch } from "store/actions/BranchAction";
import LogoSection from "../LogoSection";
import MultiLanguage from "./MultiLanguage";
// import MultiLanguage from "./MultiLanguage";
import ProfileSection from "./ProfileSection";
import SearchSection from "./SearchSection";
import SelectWithSearch from "./SelectWithSearch";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
	// const throwErr = () => {
	// 	throw Error("test sentry");
	// };
	const location = useLocation();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			actGetListBranch(
				(isSuccess) => {
					if (isSuccess) {
						// enqueueSnackbar(t("success"), {
						// 	variant: "success",
						// 	autoHideDuration: 2000,
						// });
					} else {
						// enqueueSnackbar("Error", {
						// 	variant: "error",
						// 	autoHideDuration: 2000,
						// });
					}
				},
				(err) => {
					enqueueSnackbar(err?.message || "Lỗi lấy chi nhánh", {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			)
		);
	}, []);

	const { employeeInfo, role } = useCustomAuth();

	// const isAdmin = employeeInfo.role === "ADMIN";

	return (
		<>
			{/* logo & toggler button */}
			<Box
				sx={{
					width: 228,
					display: "flex",
					[theme.breakpoints.down("md")]: {
						width: "auto",
					},
					alignItems: "center",
				}}
			>
				<Box
					component="div"
					sx={{
						display: { xs: "none", md: "block" },
						justifyContent: "center",
						flex: 1,
					}}
				>
					<LogoSection />
				</Box>
				<ButtonBase sx={{ borderRadius: "12px", overflow: "hidden", height: "fit-content" }}>
					<Avatar
						variant="rounded"
						sx={{
							...theme.typography.commonAvatar,
							...theme.typography.mediumAvatar,
							transition: "all .2s ease-in-out",
							background: theme.palette.secondary.light,
							color: theme.palette.secondary.dark,
							"&:hover": {
								background: theme.palette.secondary.dark,
								color: theme.palette.secondary.light,
							},
						}}
						onClick={handleLeftDrawerToggle}
						color="inherit"
					>
						<IconMenu2 stroke={1.5} size="1.3rem" />
					</Avatar>
				</ButtonBase>
			</Box>

			{/* header search */}
			{/* <SearchSection /> */}
			<Box sx={{ flexGrow: 1 }} />
			<Box sx={{ flexGrow: 1 }} />
			{/* <button onClick={throwErr}>Test</button> */}
			{/* notification & profile */}
			{/* {location.pathname !== "/dashboard" && <SelectWithSearch />} */}
			<SelectWithSearch />
			{ROLE.TRANSACTION_CREATE === role?.TRANSACTION_CREATE && <CashierSection />}

			<ProfileSection />
		</>
	);
};

Header.propTypes = {
	handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
