import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

function DialogMain({ title, children, propsContent, ...props }) {
	return (
		<Dialog {...props}>
			<DialogTitle variant="h3">{title}</DialogTitle>
			<DialogContent sx={{ width: "100%" }}>{children}</DialogContent>
			<Close
				onClick={props?.onClose}
				sx={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
			/>
		</Dialog>
	);
}

export default DialogMain;
