import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classnames from "classnames/bind";
import styles from "./CreateOrder.module.scss";
import { Controller, useForm } from "react-hook-form";
import { addressVN } from "constants/addressVN";
import { useSelector } from "react-redux";

const cx = classnames.bind(styles);

function DeliveryOverwrite({ control, errors, setValueForm }) {
	const [cityList, setCityList] = useState(addressVN);
	const [wardList, setWardList] = useState([]);
	const [districtList, setDistrictList] = useState([]);
	const { orderList, infoCustomer } = useSelector((state) => state.createOrder);

	useEffect(() => {
		return () => {
			setCityList(addressVN);
		};
	}, []);

	return (
		<>
			<Grid item xs={12}>
				<label className={cx("form__label")}>Số nhà</label>
				<Controller
					as={<TextField fullWidth id="outlined-basic" label="Số nhà" variant="outlined" />}
					control={control}
					name="address"
					rules={{ required: "Vui lòng điền địa chỉ nhà" }}
				/>
				<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
					{errors?.address?.message}
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<label className={cx("form__label")}>Thành phố/Tỉnh</label>
				<FormControl sx={{ m: 1, width: "100%" }}>
					<InputLabel id="cityLabel">Thành phố/Tỉnh</InputLabel>
					<Controller
						render={(props) => (
							<Select
								fullWidth
								labelId="cityLabel"
								id="demo-simple-select-disabled"
								label="Thành phố"
								onChange={(e) => {
									setDistrictList(e.target.value.level2s);
									setWardList([]);
									setValueForm("ward", undefined);
									setValueForm("district", undefined);
									props.onChange(e);
								}}
							>
								{cityList.map((city) => (
									<MenuItem key={city.level1_id} value={city}>
										{city.name}
									</MenuItem>
								))}
							</Select>
						)}
						rules={{ required: "Vui lòng chọn thành phố/tỉnh" }}
						control={control}
						name="city"
					/>
				</FormControl>
				<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
					{errors?.city?.message}
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<label className={cx("form__label")}>Quận/Huyện</label>
				<FormControl sx={{ m: 1, width: "100%" }} disabled={districtList.length === 0}>
					<InputLabel id="districtLabel">Quận/Huyện</InputLabel>
					<Controller
						render={(props) => (
							<Select
								fullWidth
								labelId="districtLabel"
								id="districtId"
								label="Quận/Huyện"
								onChange={(e) => {
									setWardList(e.target.value.level3s);
									setValueForm("ward", undefined);

									props.onChange(e);
								}}
							>
								{districtList.map((district) => (
									<MenuItem key={district.level1_id} value={district}>
										{district.name}
									</MenuItem>
								))}
							</Select>
						)}
						control={control}
						name="district"
						rules={{ required: "Vui lòng chọn quận/huyện" }}
					/>
				</FormControl>
				<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
					{errors?.district?.message}
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<label className={cx("form__label")}>Phường/Xã</label>
				<FormControl sx={{ m: 1, width: "100%" }} disabled={wardList.length === 0}>
					<InputLabel id="wardLabel">Phường/Xã</InputLabel>
					<Controller
						as={
							<Select fullWidth labelId="wardLabel" id="wardId" label="Phường/Xã">
								{wardList.map((ward) => (
									<MenuItem key={ward.level1_id} value={ward}>
										{ward.name}
									</MenuItem>
								))}
							</Select>
						}
						rules={{ required: "Vui lòng chọn phường/xã" }}
						control={control}
						name="ward"
					/>
				</FormControl>
				<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
					{errors?.ward?.message}
				</Typography>
			</Grid>
		</>
	);
}

export default DeliveryOverwrite;
