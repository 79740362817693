import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import {
	UndoRounded,
	RedoRounded,
	RedoOutlined,
	ArrowForwardIos,
	ArrowBackIos,
	CalendarToday,
	Today,
} from "@mui/icons-material";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";
function TimeLineArea({ onOpenCreateBooking, date = new Date(), setDate }) {
	let today = new Date();
	const { role } = useCustomAuth();

	const handleNextPrevDay = (isNext) => {
		if (isNext) {
			setDate(moment(date).add(1, "days"));
		} else {
			setDate(moment(date).add(-1, "days"));
		}
	};
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flex: 1,
					justifyContent: {
						xs: "flex-end",
					},
					gap: "10px",
					marginBottom: "20px",
				}}
			>
				<Tooltip title={"Hôm nay"}>
					<Button
						sx={{
							"&.MuiButtonBase-root:hover": {
								bgcolor: "transparent",
							},
						}}
						variant={"outlined"}
						size="small"
						onClick={() => setDate(today)}
					>
						<Today fontSize="small" sx={{ marginRight: "5px" }} /> <span> Hôm nay</span>
					</Button>
				</Tooltip>
				<Box
					sx={{
						display: "flex",
						gap: "5px",
					}}
				>
					<IconButton
						sx={{
							"&.MuiButtonBase-root:hover": {
								bgcolor: "transparent",
							},
						}}
						size="small"
						variant={"outlined"}
					>
						<ArrowBackIos onClick={() => handleNextPrevDay(false)} />
					</IconButton>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<Stack spacing={3}>
							<DesktopDatePicker
								label="Ngày"
								value={date}
								inputFormat={"dd/MM/yyyy"}
								onChange={(newValue) => {
									setDate(newValue);
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
						</Stack>
					</LocalizationProvider>
					<IconButton
						sx={{
							"&.MuiButtonBase-root:hover": {
								bgcolor: "transparent",
							},
						}}
						onClick={() => handleNextPrevDay(true)}
					>
						<ArrowForwardIos />
					</IconButton>
				</Box>
				{ROLE.BOOKING_CREATE === role?.BOOKING_CREATE && (
					<Button
						size="small"
						variant="contained"
						onClick={onOpenCreateBooking}
						// sx={{
						// 	color: "#fff",

						// 	backgroundColor: "#311D4F",
						// 	"&:hover": {
						// 		backgroundColor: "#311D4F",
						// 	},
						// }}
					>
						Đặt lịch
					</Button>
				)}
			</Box>
		</Box>
	);
}

export default TimeLineArea;
