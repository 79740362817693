import { Collapse, List } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { TransitionGroup } from "react-transition-group";

function CollapseContent({ collapse, setCollapse, children }) {
	return (
		<Box sx={{ mt: 1 }}>
			<TransitionGroup>
				<Collapse>{collapse ? children : <></>}</Collapse>
			</TransitionGroup>
		</Box>
	);
}

export default CollapseContent;
