import { Box } from "@mui/system";
import React, { forwardRef } from "react";
import CustomerInfo from "../components/CustomerInfo";
import CommonInfo from "../components/HeaderInfo";
import Title from "../components/Title";
import OrderContainer from "./OrderContainer";

function OrderPrintTemplate({ dataToPrint, ...props }, ref) {
	const { code, customer_info, order_items, data } = dataToPrint;
	return (
		<Box
			p={{
				xs: "20px 5px",
				sm: "20px 35px",
			}}
			ref={ref}
			{...props}
		>
			<Box>
				<CommonInfo branch_name={data?.branch?.name} />
			</Box>
			<Box sx={{ py: 2 }}>
				<Title title={"Hóa đơn bán hàng"} code={code} />
			</Box>
			<Box>
				<CustomerInfo customer={customer_info} />
			</Box>

			<Box>
				<OrderContainer orderList={order_items} data={data} />
			</Box>
		</Box>
	);
}

export default forwardRef(OrderPrintTemplate);
