import { ICON } from "assets/images/imagePath";
import classNames from "classnames/bind";
import styles from "./NoProduct.module.scss";

const cx = classNames.bind(styles);

function NoProduct({ text, ...props }) {
	return (
		<div className={cx("no_service")} {...props}>
			<img src={ICON.no_service} alt="" />
			<span>{text}</span>
		</div>
	);
}

export default NoProduct;
