import { Select } from "antd";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import _debounce from "lodash/debounce";
import { IMAGES } from "assets/images/imagePath";
import { Box, Button, Checkbox, Grid, Pagination, Tooltip } from "@mui/material";
import { formatVND, productTranslator } from "func";
import { SelectCustom } from "views/utilities/CustomStyle";
import { Markup } from "interweave";
import bonusAPI from "api/BonusAPI";
import MainCard from "ui-component/cards/MainCard";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import HeaderActions from "ui-component/Header-actions";
import { SearchComponent } from "components/search";
import { gridSpacing } from "store/constant";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "components/noRows";
import moment from "moment";

const { Option } = Select;

const searchList = [
	{
		value: "$user.username$",
		title: "SĐT người xem",
	},
	// {
	// 	value: "$category.title$",
	// 	title: "Danh mục",
	// },
	// {
	// 	value: "barcode",
	// 	title: "Mã vạch",
	// },
	// {
	// 	value: "price",
	// 	title: "Giá dịch vụ",
	// },
];
const actionList = [
	{
		value: "delete",
		title: "delete",
	},
	{
		value: "edit",
		title: "Chỉnh sửa sản phẩm",
	},
	{
		value: "information",
		title: "Xem chi tiết sản phẩm",
	},
	{
		value: "print_barcode",
		title: "In mã vạch",
	},
];

const PreferentialUser = () => {
	const location = useLocation();

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const [rows, setRows] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [searchBy, setSearchBy] = useState(searchList[0].value);
	const [searchValue, setSearchValue] = useState("");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate(
			{
				pathname: location.pathname,
			},
			{
				state: location.state,
			}
		);
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate(
			{
				pathname: location.pathname,
				search: `?${queryString.stringify(filters)}`,
			},
			{
				state: location.state,
			}
		);
	}

	//Print Func

	// Table
	const onChangeAction = (value, id, params) => {
		value === "delete" && handleOpenRemoveModal(id, params.row);
		value === "information" && handleOpenInforModal(params.row);
		value === "print_barcode" && handleOpenPrintModal(params.row, "BARCODE");
	};

	const columns = [
		{
			field: "username",
			headerName: "SĐT người xem",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.user?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.user?.username}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "discount_for_buyer",
			headerName: "Giảm giá cho người mua",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value ? formatVND(params.value) : "Chưa mua sản phẩm"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value ? formatVND(params.value) : "Chưa mua sản phẩm"}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "bonus_for_sharer",
			headerName: "Tiền ưu đãi cho người chia sẻ",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value ? formatVND(params.value) : "Chưa mua sản phẩm"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value ? formatVND(params.value) : "Chưa mua sản phẩm"}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "platform",
			headerName: "Thiết bị",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value === "client" ? "Mobile" : "Web"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value === "client" ? "Mobile" : "Web"}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian tạo",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "actions",
		// 	headerName: t(`actions`),
		// 	flex: 1.5,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	renderCell: (params) => (
		// 		<>
		// 			<SelectCustom
		// 				value={t(`select_actions`)}
		// 				onChange={(value) => onChangeAction(value, params.id, params)}
		// 			>
		// 				{actionList?.map((item) => {
		// 					let arg = null;
		// 					switch (item.value) {
		// 						case "cancel_parent":
		// 							arg = params?.row?.is_collab_of_system || params?.row?.parent_id;
		// 							break;
		// 						case "user_members":
		// 							arg = params?.row?.is_collab && !params?.row?.is_virtual_account;
		// 							break;

		// 						default:
		// 							arg = null;
		// 							break;
		// 					}
		// 					const isHide = item?.isHide === undefined ? false : item.isHide(arg);
		// 					return (
		// 						<Option disabled={isHide} key={item?.value}>
		// 							{t(`${item.title}`)}
		// 						</Option>
		// 					);
		// 				})}
		// 			</SelectCustom>
		// 		</>
		// 	),
		// },
	];

	const [information, setInformation] = useState({
		visible: false,
	});
	const [printData, setPrintData] = useState({
		visible: false,
		data: {},
	});
	const handleOpenInforModal = (data) => {
		//	Example DATA
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin sản phẩm",
				gridProps: {},
				children: [
					{
						id: "title",

						title: "Tên sản phẩm",
						value: data.title,
						component: Text,
						childrenProps: {
							//all Props component here
							span: 8,
						},
					},
					{
						id: "category_name",
						title: "Danh mục sản phẩm",
						value: data.category?.title,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},

					{
						id: "discount_for_buyer",
						title: "Giá gốc",
						value: formatVND(data.price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "discount",
						title: "Giá giảm",
						value: formatVND(data.sale_price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "real_price",
						title: "Giá thực",
						value: formatVND(data.price - data.sale_price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "description",
						title: "Mô tả sản phẩm",
						value: data.description || "Không có dữ liệu",
						component: Text,
						childrenProps: {
							span: 24,
						},
					},

					{
						id: "sub_description",
						title: "Chi tiết sản phẩm",
						value: "",
						component: Markup,
						childrenProps: {
							span: 24,
							content: data.sub_description || "Không có dữ liệu",
						},
					},
				],
			},
		];

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
		}));
	};
	const handleOpenPrintModal = (data, templateType = "QR_CODE") => {
		setPrintData({
			visible: true,
			data,
			templateType,
		});
	};
	const [filterCategory, setFilterCategory] = useState();
	useEffect(() => {
		setFilterCategory(location?.state);
	}, []);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const getListService = async (isLastDeletedItem) => {
		setIsLoading(true);
		// const hotFilter = `{"hot" : "false"}`;
		let order = '[["created_at", "desc"]]';
		const filter = {
			link_share_id: location.state?.linkId,
			[searchBy]:
				searchValue.trim().length === 0 ? undefined : { $iLike: `%${searchValue.trim()}%` },
		};

		const fields = [
			"$all",
			{
				user: ["fullname", "username"],
			},
		];
		try {
			const res = await bonusAPI.getList({
				fields: JSON.stringify(fields),
				order,
				filter: JSON.stringify(filter),
				limit: pagination.limit,
				page: isLastDeletedItem ? queryParams.page - 1 : queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			if (isLastDeletedItem) {
				navigate(
					{
						pathname: location.pathname,
						search: `?${queryString.stringify({ ...queryParams, page: paginationRes.page })}`,
					},
					{
						state: location.state,
					}
				);
			}
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));

			setSelectList([]);
			setRows(resList);

			setPagination(paginationRes);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//Get Services Categories

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);

	const handleRemoveMulti = async () => {
		let isLast = rows.length === selectList.length && pagination.page !== 1;

		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await bonusAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListService(isLast);
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate(
			{
				pathname: location.pathname,
				search: `?${queryString.stringify(filters)}`,
			},
			{ state: location.state }
		);
	};

	const handleDelete = async (id) => {
		let isLast = rows.length === 1 && pagination.page !== 1;
		try {
			await bonusAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListService(isLast);
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useEffect(() => {
		if (!branchActiveId) return;
		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}

		// if (location.state) {
		// 	setSearchBy(filterWithCategory.searchBy);
		// 	setSearchValue(filterWithCategory.value);
		// }
		getListService();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading, branchActiveId]);

	return location.state ? (
		<>
			<MainCard>
				<Button
					variant="contained"
					onClick={() =>
						navigate("/link-share", {
							replace: true,
						})
					}
					startIcon={<SettingsBackupRestoreIcon />}
				>
					Trở về
				</Button>

				{location.state?.isFromGroup && (
					<Button
						variant="contained"
						onClick={() => navigate("/group-product")}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về
					</Button>
				)}
				<HeaderActions
					title={"Danh sách khách hàng ấn vào liên kết này"}
					setIsUpdate={setIsUpdate}
					setOpen={setOpen}
					onCreate={false}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{employeeInfo?.role === "SUPERADMIN" && (
						<Button
							sx={{
								display: location.state?.isFromGroup ? "none" : "block",
							}}
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
		</>
	) : (
		<Navigate to={"/"} replace={true} />
	);
};

export default PreferentialUser;
