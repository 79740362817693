import React from "react";

import { Box, Card, CardContent, CardMedia, Grid, Tooltip, Typography } from "@mui/material";
import { IMAGES } from "assets/images/imagePath";

import classnames from "classnames/bind";
import styles from "./ProductSearchItem.module.scss";
import { formatVND } from "func";
const cx = classnames.bind(styles);
function ProductItem({ product, isActive, onAddOrder }) {
	const isSoldOut = product?.inventory && product?.inventory[0]?.inventory_number <= 0 || product?.inventory_amount_branch <= 0;
	return (
		<Box
			className={cx("wrapper", isSoldOut && "sold__out")}
			sx={{ width: "100%", height: "100%", position: "relative" }}
			onClick={onAddOrder}
		>
			<Card
				className={cx("box", isActive && "active", isSoldOut && "sold__out")}
				sx={{ display: "flex", width: "100%", flexDirection: "row", height: "100%", pr: 1, pl: 1 }}
			>
				<Box sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
					<CardMedia
						component="img"
						sx={{ width: "60px", height: "60px" }}
						image={product?.thumbnail || product?.image[0] || IMAGES.img_default}
					/>
				</Box>

				{isSoldOut && (
					<CardMedia
						component="img"
						sx={{
							position: "absolute",
							width: "30px",
							height: "30px",
							objectFit: "contain",
							right: 10,
							top: 10,
						}}
						image={IMAGES.sold_out || IMAGES.img_default}
						alt="Live from space album cover"
					/>
				)}
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "space-between",
						flex: 2,
						width: "70%",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							p: 1,
						}}
					>
						<Tooltip title={product?.title} placement={"right"}>
							<Typography component="div" variant="h5" className="text-ellipsis">
								{product?.title}
							</Typography>
						</Tooltip>
						<Box
							sx={{
								display: "flex",
								marginTop: "5px",
								flexWrap: "wrap",
								justifyContent: "space-between",
							}}
						>
							{!product?.combo_service_item && product?.sale_price != 0 && (
								<Typography
									variant="h5"
									component="div"
									sx={{ textDecorationLine: "line-through", color: "red" }}
								>
									{formatVND(product?.price || 0)}
								</Typography>
							)}

							<Typography variant="h5" component="div" fontWeight={"bold"} color="primary">
								{formatVND(product?.price - (product?.sale_price || 0))}
								{product?.unit && `/${product.unit?.value}`}
							</Typography>
						</Box>

						<Typography variant="h6" component="div">
							Tồn: {product?.inventory && product?.inventory[0]?.inventory_number || product?.inventory_amount_branch }
						</Typography>
					</Box>
				</Box>
			</Card>
		</Box>
	);
}

export default ProductItem;
