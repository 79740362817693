import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Checkbox,
	FormControl,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import serviceAPI from "api/ServiceAPI";
import UploadImage from "ui-component/UploadImage";
import uploadAPI from "api/UploadAPI";
import { Close, Watch } from "@mui/icons-material";
import { getStringWithCommasFromNumber } from "utils";
import { PATTERN } from "constants/common";
import UnitSelect from "ui-component/UnitSelct";
import { useSelector } from "react-redux";
const defaultTypeList = [
	{
		value: "NOTIFICATION",
		title: "Notification",
	},
	{
		value: "TERMS_CONDITIONS",
		title: "Terms & Conditions",
	},
	{
		value: "LOCATION",
		title: "Location",
	},
];
const TextValidation = styled("span")({
	color: "red",
});
const Required = styled("span")({
	color: "red",
});
function ServiceModal({ ...props }) {
	const {
		isOpen,
		isClose,
		isLoading,
		formData,
		isUpdate,
		listSelect,
		getListTable,
		stateLocation,
	} = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));

	const { register, handleSubmit, errors, control, reset, setValue, setError, getValues, watch } =
		useForm({
			mode: "onChange",
			reValidateMode: "onChange",
		});
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [editor, setEditor] = useState(null);
	const [type, setType] = useState("NOTIFICATION");
	// const [image, setImage] = useState(formData.image);
	// useEffect(() => {
	// 	setImage(formData.image);
	// }, [formData]);
	const defaultImageList = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
	const [imageList, setImageList] = useState(defaultImageList);

	const setImageInList = (file, idxImgInList) => {
		const imageListCopy = [...imageList];
		imageListCopy.splice(idxImgInList, 1, file);
		setImageList(imageListCopy);
	};
	useEffect(() => {
		if (isUpdate) {
			const copyArrayUndefined = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
			copyArrayUndefined.splice(0, formData.image_list.length, ...formData.image_list);
			setImageList(formData.image_list.length === 0 ? defaultImageList : copyArrayUndefined);
		} else {
		}
	}, [isOpen]);
	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleClose = () => {
		isClose(false);
	};
	const rx_live = /^\d*$/;
	const handleMaskValue = (value) => {
		const temp = value.split(",").join("");
		if (rx_live.test(temp)) {
			return temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return temp;
		}
	};
	const removeComma = (numberString) => {
		return numberString.toString().split(",").join("");
	};
	const { unitId } = useSelector((state) => state.unitReducer);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const onSubmit = async (data) => {
		try {
			setLoading(true);

			if (!isUpdate) {
				const urlImgList = [];

				for (let i = 0; i < imageList.length; i++) {
					if (imageList[i]) {
						const res = await handleUpload(i);
						urlImgList.push(res);
					}
				}
				data.implementation_process = ckData;
				data.category_service_id = type;
				data.image_list = urlImgList;
				data.price = parseInt(data.price.split(",").join(""));
				data.discount = parseInt(Number(data.discount.split(",").join("")));
				data.execution_time = parseInt(data.execution_time.split(",").join(""));
				data.unit_id = unitId === "none" ? null : unitId;
				data.branch_id = branchActiveId;
				data.sort = !!data.sort === false ? null : data.sort;

				const response = await serviceAPI.create(data);

				if (response.code === 200) {
					getListTable();

					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					isClose(false);
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			} else {
				const urlImgList = [];

				for (let i = 0; i < 4; i++) {
					if (imageList[i]) {
						if (typeof imageList[i].name === "string") {
							// Check imageList có phải là một file
							const res = await handleUpload(i);
							urlImgList.push(res);
						} else {
							//Không phải là File => là url hình ảnh, push lại vào mảng truyền lên
							urlImgList.push(imageList[i]);
						}
					}
				}
				data.implementation_process = ckData;
				data.category_service_id = type;
				data.image_list = urlImgList;
				data.price = parseInt(data.price.split(",").join(""));
				data.discount = parseInt(Number(data.discount.split(",").join("")));
				data.unit_id = unitId === "none" ? null : unitId;
				data.branch_id = branchActiveId;
				data.execution_time = parseInt(data.execution_time.split(",").join(""));
				data.sort = !!data.sort === false ? null : data.sort;

				const response = await serviceAPI.update(formData.id, data);
				if (response.code === 200) {
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					setLoading(false);

					await getListTable();

					isLoading(1);
					isClose(false);
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};
	// useEffect(() => {
	// 	if (image) {
	// 		setError({ type: "image", message: "" });
	// 	}
	// }, [image]);
	useEffect(() => {
		if (isUpdate) {
			setCkData(formData?.implementation_process || "");
			setType(formData?.category_service_id);
		} else {
			setCkData("");
			setType(selectList && selectList[0].value);
			setType(
				stateLocation?.isFromCategory ? stateLocation?.id : selectList && selectList[0].value
			);
		}
		return () => {
			setImageList(defaultImageList);
			reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const translator = (text) => {
		return `abeauty.service.dialog_form.${text}`;
	};
	const handleUpload = async (idx) => {
		const formData = new FormData();
		formData.append("image", imageList[idx]);

		try {
			const response = await uploadAPI.uploadImage(formData);
			return response.results.object.url;
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};
	return (
		<div>
			<Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle>
					{isUpdate ? t(translator("header_edit")) : t(translator("header_create"))}
				</DialogTitle>
				<form style={{ width: "100%", overflow: "auto" }}>
					<DialogContent sx={{ width: "100%", overflowX: "hidden" }}>
						<Grid
							container
							spacing={3}
							style={{
								marginBottom: 20,
							}}
						>
							<Grid item xs={6}>
								<TextField
									margin="dense"
									name="title"
									label={t(translator("title")) + "*"}
									type="text"
									fullWidth
									defaultValue={isUpdate ? formData?.title : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Vui lòng điền tên dịch vụ",
										},
									})}
								/>
								{errors.title && <TextValidation>{errors.title.message}</TextValidation>}
							</Grid>
							<Grid item xs={6}>
								<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="demo-simple-select-autowidth-label">{t(`type`)}</InputLabel>
									<Select
										labelId="demo-simple-select-autowidth-label"
										id="demo-simple-select-autowidth"
										onChange={handleChange}
										defaultValue={isUpdate ? formData?.category_service_id : type}
										label={t(translator("category_service_id"))}
										name="category_service_id"
									>
										{Array.isArray(selectList) &&
											selectList.map((item) => (
												<MenuItem key={item.value} value={item.value}>
													{t(item.title)}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="price"
									label={t(translator("price"))}
									type="text"
									fullWidth
									required
									inputMode="numeric"
									defaultValue={isUpdate ? getStringWithCommasFromNumber(formData?.price) : ""}
									variant="standard"
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									inputRef={register({
										required: {
											value: true,
											message: "Vui lòng điền giá dịch vụ",
										},
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số",
										},
										validate: {
											minValue: (v) =>
												Number(removeComma(v)) > 0 || "Giá dịch phải lớn hơn 0đ",
										},
									})}
								/>
								{errors.price && <TextValidation>{errors.price.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="discount"
									label={t(translator("discount"))}
									type="text"
									fullWidth
									inputMode="numeric"
									defaultValue={isUpdate ? getStringWithCommasFromNumber(formData?.discount) : ""}
									variant="standard"
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									disabled={!watch("price")}
									inputRef={register({
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số dương",
										},
										// min: {
										// 	value: 0,
										// 	message: "Giá giảm phải lớn hơn 0",
										// },
										validate: {
											lessThanPrice: (v) => {
												const priceValue = getValues("price");
												return (
													Number(removeComma(v)) < Number(removeComma(priceValue)) ||
													"Giá giảm phải nhỏ hơn giá gốc"
												);
											},
											moreThanZero: (v) => {
												return Number(removeComma(v)) >= 0 || "Giá trị phải lớn hơn 0";
											},
										},
									})}
								/>
								{errors.discount && <TextValidation>{errors.discount.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="execution_time"
									label={t(translator("execution_time"))}
									type="text"
									fullWidth
									inputMode="numeric"
									required
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									defaultValue={
										isUpdate ? getStringWithCommasFromNumber(formData?.execution_time) : ""
									}
									variant="standard"
									inputRef={register({
										required: {
											value: true,
											message: "Vui lòng điền thời gian thực hiện",
										},
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số",
										},
									})}
								/>
								{errors.execution_time && (
									<TextValidation>{errors.execution_time.message}</TextValidation>
								)}
							</Grid>
							<Grid item xs={4}>
								<Controller
									name="sort"
									control={control}
									rules={{
										validate: {
											stringSpace: (v) =>
												!v?.toString()?.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
											// biggerZero: (v) => v > 0 || "Số thứ tự phải lớn hơn 0",
										},
										pattern: {
											value: PATTERN.IS_NUMBER,
											message: "Giá trị phải là một số dương",
										},
										// required: {
										// 	value: true,
										// 	message: "Vui lòng điền thứ tự",
										// },
									}}
									defaultValue={isUpdate ? formData.sort : ""}
									as={
										<TextField
											margin="dense"
											label={"Thứ tự"}
											type="text"
											fullWidth
											required
											variant="standard"
										/>
									}
								/>

								{errors.sort && <TextValidation>{errors.sort.message}</TextValidation>}
							</Grid>

							<Grid item xs={4}>
								<UnitSelect
									isUpdate={isUpdate}
									formData={formData}
									nameField={"unit_id"}
									label={"Đơn vị"}
									labelId={"unit_id"}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormLabel component="legend">{t(translator("hot"))}</FormLabel>
								<Switch
									name="hot"
									inputRef={register()}
									defaultChecked={isUpdate ? formData.hot : false}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="ul" color="warning">
									Ảnh dịch vụ
								</Typography>
							</Grid>
							{Array.isArray(imageList) &&
								imageList.map((image, idx) => {
									return (
										<Grid
											item
											xs={6}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												mb: "20px",
												flexDirection: "column",
											}}
										>
											<UploadImage
												image={image}
												setImage={(file) => {
													return setImageInList(file, idx);
												}}
												aspectRatio="1:1"
												levelAspectRatio={6}
												id={idx}
											>
												<ul>
													<li>Định dạng file: [.JPEG, .JPG, .PNG]</li>
													<li>Tỉ lệ hình ảnh: 600x600</li>
												</ul>
											</UploadImage>
										</Grid>
									);
								})}
							{imageList?.length === 0 && <TextValidation> Vui lòng chọn hình ảnh</TextValidation>}
							<Grid item xs={12}>
								<TextField
									margin="dense"
									name="description"
									label={t(translator("description"))}
									type="text"
									defaultValue={isUpdate ? formData.description : ""}
									fullWidth
									multiline
									minRows={4}
									variant="standard"
									inputRef={register()}
								/>
							</Grid>
						</Grid>
						<Box>
							<div className="App">
								<label
									style={{
										marginBottom: 20,
									}}
								>
									{t(translator("implementation_process"))}
								</label>
								<CKEditor
									onReady={(editor) => {
										// Insert the toolbar before the editable area.
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										setEditor(editor);
									}}
									onError={({ willEditorRestart }) => {
										// If the editor is restarted, the toolbar element will be created once again.
										// The `onReady` callback will be called again and the new toolbar will be added.
										// This is why you need to remove the older toolbar.
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setCkData(data);
									}}
									editor={DecoupledEditor}
									data={ckData}
									config={{
										ckfinder: {
											uploadUrl: `https://hitek-02.hitek.com.vn:6061/api/v1/image/upload_ckeditor`,
										},

										mediaEmbed: {
											previewsInData: true,
											elementName: "iframe",
										},
									}}
								/>
							</div>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => isClose(false)} variant="contained" color="secondary">
							{t(`cancel`)}
						</Button>
						<LoadingButton
							onClick={handleSubmit(onSubmit)}
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="contained"
						>
							{isUpdate ? t(translator("update")) : t(translator("create"))}
						</LoadingButton>
					</DialogActions>

					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showErr.state}
						onClose={() => setShowErr({ state: false })}
						// action={action}
						autoHideDuration={5000}
						severity="error"
					>
						<Alert severity="error">{showErr.message}</Alert>
					</Snackbar>
				</form>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default ServiceModal;
