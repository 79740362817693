import { GET_LIST_UNIT, SELECT_UNIT } from "store/actions/UnitAction/type";

const initialState = {
	unitList: [],
	unitId: "none",
};

const UnitReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_LIST_UNIT:
			return { ...state, unitList: payload };
		case SELECT_UNIT: {
			return { ...state, unitId: payload };
		}
		default:
			return state;
	}
};
export default UnitReducer;
