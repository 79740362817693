import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";

// project imports
import Breadcrumbs from "../../ui-component/extended/Breadcrumbs";
import navigation from "../../menu-items";
import { drawerWidth } from "../../store/constant";

// assets
import { IconChevronRight } from "@tabler/icons";
import { SET_MENU } from "store/actions/CustomizationAction/types";
import useCustomAuth from "hooks/useCustomAuth";
import Header from "./Header";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
	({ theme, open }) => ({
		...theme.typography.mainContent,
		...(!open && {
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			[theme.breakpoints.up("md")]: {
				marginLeft: -(drawerWidth - 20),
				width: `calc(100% - ${drawerWidth}px)`,
			},
			[theme.breakpoints.down("md")]: {
				marginLeft: "20px",
				width: `calc(100% - ${drawerWidth}px)`,
				padding: "16px",
			},
			[theme.breakpoints.down("sm")]: {
				marginLeft: "10px",
				width: `calc(100% - ${drawerWidth}px)`,
				padding: "16px",
				marginRight: "10px",
			},
		}),
		...(open && {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			width: `calc(100% - ${drawerWidth}px)`,
			[theme.breakpoints.down("md")]: {
				marginLeft: "20px",
			},
			[theme.breakpoints.down("sm")]: {
				marginLeft: "10px",
			},
		}),
	})
);

// ==============================|| MAIN LAYOUT ||============================== //

const HeaderOnly = () => {
	const theme = useTheme();
	const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

	// Handle left drawer
	const leftDrawerOpened = useSelector((state) => state.customization.opened);

	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const handleLeftDrawerToggle = () => {
		dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
	};
	const { employeeInfo } = useCustomAuth();
	useEffect(() => {
		// if (employeeInfo.role === "ADMIN") {
		// 	navigate("/cashier/create-order");

		// 	return;
		// }

		if (location.pathname === "/") {
			navigate("/dashboard");
		}

		dispatch({ type: SET_MENU, opened: !matchDownMd });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matchDownMd, location.pathname]);

	return (
		<Box sx={{ display: "flex", zIndex: "10" }}>
			<CssBaseline />
			{/* header */}
			<AppBar
				enableColorOnDark
				position="fixed"
				color="inherit"
				elevation={0}
				sx={{
					bgcolor: theme.palette.background.default,
					transition: leftDrawerOpened ? theme.transitions.create("width") : "none",
				}}
			>
				<Toolbar>
					<Header />
				</Toolbar>
			</AppBar>

			{/* drawer */}
			{/* <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} /> */}

			{/* main content */}
			<Main theme={theme} open={leftDrawerOpened}>
				{/* breadcrumb */}

				<Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
				<Outlet />
			</Main>
		</Box>
	);
};

export default HeaderOnly;
