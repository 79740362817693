import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useCustomAuth from "hooks/useCustomAuth";
import CashierSection from "layout/components/CashierSection";
import ProfileSection from "layout/MainLayout/Header/ProfileSection";
import SelectWithSearch from "layout/MainLayout/Header/SelectWithSearch";
import LogoSection from "layout/MainLayout/LogoSection";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { actGetListBranch } from "store/actions/BranchAction";
// import MultiLanguage from "./MultiLanguage";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
	// const throwErr = () => {
	// 	throw Error("test sentry");
	// };
	const location = useLocation();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			actGetListBranch(
				(isSuccess) => {
					if (isSuccess) {
						// enqueueSnackbar(t("success"), {
						// 	variant: "success",
						// 	autoHideDuration: 2000,
						// });
					} else {
						// enqueueSnackbar("Error", {
						// 	variant: "error",
						// 	autoHideDuration: 2000,
						// });
					}
				},
				(err) => {
					enqueueSnackbar(err?.message || "Lỗi lấy chi nhánh", {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			)
		);
	}, []);

	const { employeeInfo } = useCustomAuth();

	const isAdmin = employeeInfo.role === "ADMIN";

	return (
		<>
			{/* logo & toggler button */}
			<Box
				sx={{
					width: 228,
					display: "flex",
					[theme.breakpoints.down("md")]: {
						width: "auto",
					},
					alignItems: "center",
				}}
			>
				<Box
					component="div"
					sx={{
						display: { xs: "none", md: "block" },
						justifyContent: "center",
						flex: 1,
					}}
				>
					<LogoSection />
				</Box>
			</Box>

			{/* header search */}
			{/* <SearchSection /> */}
			<Box sx={{ flexGrow: 1 }} />
			<Box sx={{ flexGrow: 1 }} />
			{/* <button onClick={throwErr}>Test</button> */}
			{/* notification & profile */}
			{location.pathname !== "/dashboard" && <SelectWithSearch />}
			<CashierSection />

			<ProfileSection />
		</>
	);
};

export default Header;
