import React from "react";
import classnames from "classnames/bind";
import styles from "./SearchWithAPI.module.scss";

const cx = classnames.bind(styles);
function ResultsComponent({ item, onClick, ...props }) {
	return (
		<div className={cx("result__box")} style={{ padding: "10px" }} onClick={onClick}>
			{item.title}
		</div>
	);
}

export default ResultsComponent;
