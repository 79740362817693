import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Divider } from "antd";
import { formatVND } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import React from "react";
import styles from "./OrderPrint.module.scss";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

function OrderContainer({ orderList = [], data }) {
	const { employeeInfo } = useCustomAuth();
	// let totalPriceUpdate = data?.order_items?.reduce(
	// 	(prev, current) => Number(prev * 1 + 1 * current.price * current.quantity),
	// 	0
	// );
	return (
		<Box>
			<Typography variant="h5" component={"span"}>
				Nhân viên bán hàng:{" "}
			</Typography>
			<Typography variant="h5" component={"span"}>
				{/* EMPLOYEE name */}
				{employeeInfo.fullname}
			</Typography>
			<Divider dashed style={{ borderColor: "black", margin: "5px 0" }} />
			<Box>
				<Stack flexDirection={"row"} justifyContent="space-between">
					<Typography variant="h5" flex={1}>
						Đơn giá
					</Typography>
					<Typography variant="h5" flex={1} textAlign={"center"}>
						SL
					</Typography>
					<Typography variant="h5" flex={1} textAlign={"right"}>
						Thành tiền
					</Typography>
				</Stack>
				{orderList.map((item, idx) => (
					<React.Fragment key={idx}>
						<Stack mt={1} flexDirection={"column"} justifyContent="space-between">
							<Typography variant="h5" className={cx("text__overflow")}>
								{item?.product?.title}
							</Typography>
							<Stack flexDirection={"row"} justifyContent="space-between">
								<Typography variant="h5" flex={1}>
									{formatVND(Number(item?.deducted_price))}
								</Typography>
								<Typography variant="h5" flex={1} textAlign={"center"}>
									{item.quantity}
								</Typography>
								<Typography variant="h5" flex={1} textAlign={"right"}>
									{formatVND(Number(item?.deducted_price) * item.quantity)}
								</Typography>
							</Stack>
						</Stack>
						<Divider dashed style={{ borderColor: "black", margin: "5px 0" }} />
					</React.Fragment>
				))}
			</Box>
			<Box>
				<Stack flexDirection={"row"} justifyContent="flex-end">
					<Stack
						width={{
							xs: "75%",
							md: "40%",
						}}
						gap={1}
					>
						<Stack flexDirection={"row"} justifyContent={"space-between"}>
							<Typography>Tổng tiền hàng</Typography>
							<Typography variant="h5">{formatVND(data?.fee_total)}</Typography>
						</Stack>
						<Stack flexDirection={"row"} justifyContent={"space-between"}>
							<Typography>Chiết khấu</Typography>
							<Typography>{formatVND(data?.discount)}</Typography>
						</Stack>
						<Stack flexDirection={"row"} justifyContent={"space-between"}>
							<Typography variant="h5">Tổng cộng</Typography>
							<Typography variant="h5">{formatVND(data?.total_cost)}</Typography>
						</Stack>
						<Stack flexDirection={"row"} justifyContent={"space-between"}>
							<Typography variant="h5">Khách thanh toán</Typography>
							<Typography variant="h5">{formatVND(data?.total_cost_origin)}</Typography>
						</Stack>
						<Stack flexDirection={"row"} justifyContent={"space-between"}>
							<Typography variant="h5">Tiền thừa</Typography>
							<Typography variant="h5">{formatVND(data?.money_return)}</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Box>
		</Box>
	);
}

export default OrderContainer;
