import { Grid, Pagination, Button, FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "components/noRows";
import _debounce from "lodash/debounce";
import moment from "moment";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import { showSortStyle, SelectCustom } from "views/utilities/CustomStyle";
import userAPI from "../../api/UserAPI";
import { SearchComponent } from "../../components/search";
import { getNumberWithCommas } from "../../utils";
import { Select as SelectAntd, Tooltip } from "antd";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import registerTestAPI from "api/RegisterTestAPI";
import { IOSSwitch } from "ui-component/switch";
import useCustomAuth from "hooks/useCustomAuth";
import { ROLE } from "constants/employeeRole";
import { ALERT_PERMISSION } from "constants/constant";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";
import { useDispatch } from "react-redux";

const { Option } = SelectAntd;

const actionList = [
	{
		value: "delete",
		title: "delete",
	},
];

const searchList = [
	// {
	// 	value: "username",
	// 	title: "username",
	// },
	{
		value: "phone",
		title: "SĐT",
	},
	{
		value: "fullname",
		title: "fullname",
	},
	{
		value: "email",
		title: "email",
	},
	// {
	// 	value: "login_type",
	// 	title: "login_type",
	// },
];

const RegisterTest = () => {
	const { enqueueSnackbar } = useSnackbar();
	const modalContext = useContext(ModalContext);

	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState([]);

	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const columns = [
		// {
		// 	field: "order",
		// 	headerName: t(`order`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },

		// {
		// 	field: "username",
		// 	headerName: t(`username`),
		// 	minWidth: 250,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		{
			field: "phone",
			headerName: "SĐT",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
		},
		{
			field: "fullname",
			headerName: "Tên khách hàng",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "service",
			headerName: "Dịch vụ đăng ký",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.service?.title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.service?.title}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "email",
			headerName: "Email",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "note",
			headerName: "Ghi chú",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "time",
			headerName: "Thời gian khách đến",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				return (
					<Tooltip
						title={
							params.value &&
							params.value != 0 &&
							moment(params.value, "x").format("DD-MM-YYYY HH:mm")
						}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value &&
								params.value != 0 &&
								moment(params.value, "x").format("DD-MM-YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "is_checked",
			headerName: "Tình trạng tư vấn",
			flex: 0.5,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				// params.value = false;

				return (
					<Tooltip
						title={params.value ? "Đã tư vấn" : "Chưa tư vấn"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<FormControlLabel
							control={
								<IOSSwitch
									sx={{ ml: 4 }}
									checked={params?.value}
									onChange={(e) => handleChangeAccept(e, params?.row)}
								/>
							}
							label=""
						/>
					</Tooltip>
				);
			},
		},

		// {
		// 	field: "login_type",
		// 	headerName: "Nền tảng đăng nhập",
		// 	minWidth: 250,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		// {
		// 	field: "created_at",
		// 	headerName: t(`created_at`),
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// },
		// {
		// 	field: "actions",
		// 	headerName: t(`actions`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	renderCell: (params) => (
		// 		<>
		// 			<SelectCustom
		// 				value={t(`select_actions`)}
		// 				onChange={(value) => onChangeAction(value, params.id)}
		// 				style={{ width: "80%" }}
		// 			>
		// 				{actionList?.map((item) => (
		// 					<Option key={item?.value}>{t(`${item.title}`)}</Option>
		// 				))}
		// 			</SelectCustom>
		// 		</>
		// 	),
		// },
	];
	const dispatch = useDispatch();
	const handleChangeAccept = async (e, params) => {
		let checked = e.target.checked;
		try {
			await registerTestAPI.update(params?.id, {
				is_checked: checked,
			});
			dispatch(actGetWaitingRequest());

			getListRegisterTest();
		} catch (error) {}
	};
	const getListRegisterTest = async () => {
		setIsLoading(true);
		let filter = "{}";
		let order = '[["created_at", "desc"]]';
		if (queryParams?.username) {
			filter = `{"username":{"$iLike":"%${queryParams?.username}%"}}`;
		}
		if (queryParams?.fullname) {
			filter = `{"fullname":{"$iLike":"%${queryParams?.fullname}%"}}`;
		}
		if (queryParams?.phone?.trim()) {
			filter = `{"phone":{"$iLike":"%${queryParams?.phone.trim()}%"}}`;
		}
		if (queryParams?.email) {
			filter = `{"email":{"$iLike":"%${queryParams?.email}%"}}`;
		}

		if (queryParams?.sortBy) {
			order = `[["${queryParams?.sortBy}", "${queryParams?.sort}"]]`;
		}
		try {
			const res = await registerTestAPI.getList({
				fields: '["$all",{"service":["$all"]}]',
				order,
				filter,
				limit: pagination.limit,
				page: queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	const handleDelete = async (id) => {
		try {
			await registerTestAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			await getListRegisterTest();
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const onChangeAction = (value, id) => {
		value === "edit" && navigate(`/users/edit/${id}`);
		value === "delete" && handleOpenRemoveModal(id);
	};

	const handleRemoveMulti = () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await registerTestAPI.removeMulti({
						items: JSON.stringify(selectList),
					});
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListRegisterTest();
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};
	const { role } = useCustomAuth();

	useEffect(() => {
		if (!role) return;
		if (role?.ADVISE !== ROLE?.ADVISE) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty("username")) {
			setSearchBy("username");
			setSearchValue(queryParams?.username);
		}
		if (queryParams.hasOwnProperty("email")) {
			setSearchBy("email");
			setSearchValue(queryParams?.email);
		}
		if (queryParams.hasOwnProperty("phone")) {
			setSearchBy("phone");
			setSearchValue(queryParams?.phone);
		}
		if (queryParams.hasOwnProperty("fullname")) {
			setSearchBy("fullname");
			setSearchValue(queryParams?.fullname);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		getListRegisterTest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, role]);

	return (
		<>
			<MainCard>
				<HeaderActions title={t(`register_test`)} onCreate={false} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{/* <Button
						variant={selectList.length > 0 ? "contained" : "outlined"}
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							// checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
		</>
	);
};

export default RegisterTest;
