import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import ServiceBestSeller from "views/dashboard/AntdChart/ServiceBestSeller";

function FilterBox({ filterList, dataChart }) {
	const [filVal, setFilVal] = useState(filterList[0].value);

	const renderChart = (value) => {
		switch (value) {
			case "count": {
				return (
					<ServiceBestSeller
						data={dataChart?.data_top_10_amount_member}
						chartText={{
							title: "Top 10 đại sứ thương hiệu",
							revenue: "Cộng tác viên",
							count: "Số lần đặt hàng",
						}}
						seriesFieldCustom={"username"}
						defaultFilterVal={"count"}
						object={"parent"}
						filter={false}
					/>
				);
			}
			case "amount": {
				return (
					<ServiceBestSeller
						data={dataChart?.data_top_10_revenue_member}
						chartText={{
							title: "Top 10 đại sứ thương hiệu",
							revenue: "Doanh thu",
							count: "Số lần đặt hàng",
						}}
						defaultFilterVal={"revenue"}
						seriesFieldCustom={"parent"}
						filter={false}
					/>
				);
			}

			default:
				break;
		}
	};
	return (
		<>
			<Box display="flex" justifyContent={"flex-end"}>
				<FormControl>
					<InputLabel id="demo-simple-select-label">Lọc</InputLabel>
					<Select
						sx={{ width: "auto" }}
						labelId="demo-simple-select-label"
						label={"Lọc"}
						id="demo-simple-select"
						size="large"
						onChange={(e) => {
							setFilVal(e.target.value);
						}}
						value={filVal}
					>
						{filterList.map((item) => (
							<MenuItem key={item.value} value={item.value}>
								{item.title}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box>{renderChart(filVal)}</Box>
		</>
	);
}

export default FilterBox;
