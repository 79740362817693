import React from "react";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import { Box, TextField } from "@mui/material";
import { useController } from "react-hook-form";
function DateRangePickerField({
	text = {
		start: "Từ ngày",
		end: "Đến ngày",
	},
	defaultDate = [null, null],
	name,
	control,
	...inputProps
}) {
	const {
		field: { onChange, onBlur, value = defaultDate, ref },
		meta: { invalid },
	} = useController({
		name,
		control,
	});
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateRangePicker
				name={name}
				control={control}
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				startText={text.start}
				endText={text.end}
				inputFormat="dd/MM/yyyy"
				renderInput={(startProps, endProps) => (
					<React.Fragment>
						<TextField variant="standard" {...inputProps} {...startProps} />
						<Box sx={{ mx: 2 }}>-</Box>
						<TextField variant="standard" {...inputProps} {...endProps} />
					</React.Fragment>
				)}
				inputRef={ref}
			/>
		</LocalizationProvider>
	);
}

export default DateRangePickerField;
