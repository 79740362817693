import ReactDOM from "react-dom";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// project imports
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { store } from "./store";
// import { ToastProvider } from "providers/ToastProvider";

// style + assets
import "./assets/scss/style.scss";
import { ThemeProvider } from "@emotion/react";
import theme from "./Theme";
import "antd/dist/antd.css";

// multi language
import i18n from "./translation/i18n";
import { I18nextProvider } from "react-i18next";
import { ModalProvider } from "providers/ModalProvider";
import { LoadingProvider } from "providers/LoadingProvider";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ENV } from "config/enviroment";
// ==============================|| SENTRY TRACKING  ||============================== //
//REACT_APP_DSN_SENTRY_PRODUCT_URL
//REACT_APP_DSN_SENTRY_DEV_URL

const getBaseURL = (str = "production") => {
	if (str === "production") {
		return process.env.REACT_APP_DSN_SENTRY_PRODUCT_URL;
	} else {
		return process.env.REACT_APP_DSN_SENTRY_DEV_URL;
	}
};

Sentry.init({
	dsn: getBaseURL(ENV),
	integrations: [new BrowserTracing()],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
});

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<BrowserRouter>
					<ModalProvider>
						<LoadingProvider>
							<App />
						</LoadingProvider>
					</ModalProvider>
				</BrowserRouter>
			</Provider>
		</ThemeProvider>
	</I18nextProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
