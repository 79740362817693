import { Grid, Pagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import NotificationAPI from "api/NotificationAPI";
import { CustomNoRowsOverlay } from "components/noRows";
import { SearchComponent } from "components/search";
import { ROLE } from "constants/employeeRole";
import { notificationTranslator } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import Modal from "ui-component/modal-form/model-notification";
import { Root } from "views/utilities/CustomStyle";

const NotificationSended = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const modalContext = useContext(ModalContext);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	const [rows, setRows] = useState([]);
	const [searchBy, setSearchBy] = useState([]);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const columns = [
		{
			field: "sender",
			headerName: "Người gửi thông báo",
			headerAlign: "center",
			flex: 1,
			align: "center",
			sortable: false,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.row?.sender?.fullname || "Tự đọng"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.row?.sender?.fullname || "Tự động"}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "message_title",
			headerName: "Tiêu đề thông báo",
			headerAlign: "center",
			flex: 1.5,
			align: "center",
			sortable: false,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "message_content",
			headerName: "Nội dung thông báo",
			headerAlign: "center",
			align: "center",
			sortable: false,
			flex: 1.5,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "target_user",
			headerName: "Loại người dùng",
			headerAlign: "center",
			align: "center",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={notificationTranslator(null, params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{notificationTranslator(null, params.value)}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "post",
			headerName: "Bài viết",
			headerAlign: "center",
			align: "center",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.post?.title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							<Link to={`/post/${params.row?.post_id}`}>{params?.row?.post?.title}</Link>
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian gửi",
			headerAlign: "center",
			align: "center",
			flex: 1,
			sortable: false,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(Number(params.value)).format("DD-MM-YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(Number(params.value)).format("DD-MM-YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
	];

	const getNotiFication = async () => {
		setIsLoading(true);
		try {
			const res = await NotificationAPI.getListNotificationHistory({
				fields: `["$all", {"post": ["$all"]}, {"sender": ["$all"]}]`,
				order: `[["created_at_unix_timestamp", "desc"]]`,
				limit: pagination.limit,
				page: queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	const handleDelete = async (id) => {
		try {
			await NotificationAPI.remove(id);
			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			getNotiFication();
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenPushNotiModal = (id) => {
		modalContext.showDeleteConfirm(
			id,
			() => handlePushNoti(id),
			"Bạn có muốn gửi thông báo",
			"Gửi"
		);
	};

	const handlePushNoti = async (id) => {
		try {
			await NotificationAPI.pushNoti(id);
			setLoading(loading + 1);
			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
		} catch (error) {
			enqueueSnackbar(error?.message || error, {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleChangeAccept = async (e, notification) => {
		let checked = e.target.checked;
		try {
			await NotificationAPI.update(
				{
					status: checked,
				},
				notification?.id
			);
			getNotiFication();
		} catch (error) {}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};
	const { role } = useCustomAuth();

	useEffect(() => {
		if (!role) return;
		if (role?.NOTIFICATION !== ROLE.NOTIFICATION) {
			navigate("/not-permission", { replace: true });

			return;
		}
		getNotiFication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading, role]);

	return (
		<>
			<Root>
				<MainCard>
					<HeaderActions
						title={"Danh sách thông báo đã gửi"}
						setIsUpdate={setIsUpdate}
						onCreate={false}
						setOpen={setOpen}
					/>
					<Box>
						{/* <SearchComponent
							searchBy={searchBy}
							searchList={searchList}
							searchValue={searchValue}
							handleSearchBy={handleSearchBy}
							handleChangeSearch={handleChangeSearch}
						/> */}
					</Box>
					<Grid container spacing={gridSpacing}>
						<Grid item xs={12} sm={12}>
							<DataGrid
								rows={rows}
								columns={columns}
								autoHeight
								autoWidth
								disableColumnMenu
								disableVirtualization={false}
								disableSelectionOnClick={true}
								disableDensitySelector={false}
								hideFooter={true}
								rowHeight={75}
								loading={isLoading}
								components={{
									NoRowsOverlay: CustomNoRowsOverlay,
								}}
							/>
						</Grid>
					</Grid>
					{pagination.total !== rows?.length && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								marginTop: "20px",
							}}
						>
							<Pagination
								page={pagination.page}
								count={Math.ceil(pagination.total / pagination.limit)}
								onChange={handleChangePage}
								color="primary"
								variant="outlined"
								shape="rounded"
							/>
						</Box>
					)}
				</MainCard>

				<Modal
					data={data}
					isOpen={open}
					isUpdate={isUpdate}
					isClose={(value) => {
						setOpen(value);
						getNotiFication();
					}}
					isLoading={(value) => {
						setLoading(loading + 1);
						setState({ ...state, openState: true });
					}}
				/>
			</Root>
		</>
	);
};

export default NotificationSended;
