import { Tree, Typography } from "antd";
import React, { memo, useEffect, useLayoutEffect, useState } from "react";

function TreeSelect({
	treeData,
	title,
	handleSetData,
	treeValue,
	defaultValue = [],
	data,
	...props
}) {
	const [expandedKeys, setExpandedKeys] = useState(defaultValue);
	const [checkedKeys, setCheckedKeys] = useState(defaultValue);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [autoExpandParent, setAutoExpandParent] = useState(true);
	const onExpand = (expandedKeysValue) => {
		// if not set autoExpandParent to false, if children expanded, parent can not collapse.
		// or, you can remove all expanded children keys.
		setExpandedKeys(expandedKeysValue);
		setAutoExpandParent(false);
	};

	const onCheck = (checkedKeysValue, info) => {
		// settingData(info)
		setCheckedKeys(checkedKeysValue);
	};
	const onSelect = (selectedKeysValue, info) => {
		setSelectedKeys(selectedKeysValue);
	};
	useLayoutEffect(() => {
		handleSetData(checkedKeys, data.id);
	}, [checkedKeys]);
	useLayoutEffect(() => {
		setCheckedKeys(treeValue);
	}, [defaultValue]);

	return (
		<>
			<Typography.Title color={"main"} level={5}>
				{title}
			</Typography.Title>
			<Tree
				checkable
				onExpand={onExpand}
				expandedKeys={expandedKeys}
				autoExpandParent={autoExpandParent}
				onCheck={onCheck}
				checkedKeys={checkedKeys}
				onSelect={onSelect}
				selectedKeys={selectedKeys}
				treeData={treeData}
				defaultCheckedKeys={defaultValue}
				{...props}
			/>
		</>
	);
}

export default memo(TreeSelect);
