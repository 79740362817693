import React from "react";
import classnames from "classnames/bind";
import styles from "./AssignBooking.module.scss";
import { IconCheck } from "@tabler/icons";
import { IMAGES } from "assets/images/imagePath";

const cx = classnames.bind(styles);

function EmployeeItem({ employee, active, onSelectEmployee, ...props }) {
	return (
		<div className={cx("wrapper", active && "active")} onClick={onSelectEmployee} {...props}>
			<div className={cx("image")}>
				<img src={employee.avatar || IMAGES.avatar_default} alt="avatar" />
			</div>
			<div className={cx("information")}>
				<h3>{employee.fullname}</h3>
				<h5>{employee.role === "ADMIN" && "Nhân viên"}</h5>
			</div>
			{active && (
				<div className={cx("icon-check")}>
					<IconCheck />
				</div>
			)}
		</div>
	);
}

export default EmployeeItem;
