import {
	DELETE_EMPLOYEE,
	RESET_EMPLOYEE_BOOKING,
	SELECT_EMPLOYEE,
	UPDATE_EMPLOYEE_BOOKING,
	UPDATE_VALUE_EMPLOYEE,
} from "./type";

export const actSelectEmployee = (data) => ({
	payload: data,
	type: SELECT_EMPLOYEE,
});

export const actDeleteEmployee = (data) => ({
	payload: data,
	type: DELETE_EMPLOYEE,
});
export const actUpdateEmployeeBooking = (data) => ({
	payload: data,
	type: UPDATE_EMPLOYEE_BOOKING,
});

export const actUpdateValueEmployee = ({ data, val, name }) => ({
	payload: { data, val, name },
	type: UPDATE_VALUE_EMPLOYEE,
});
export const actResetEmployeeBooking = () => ({
	type: RESET_EMPLOYEE_BOOKING,
});
