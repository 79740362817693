import { LocalFireDepartment } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import serviceAPI from "api/ServiceAPI";
import { formatVND } from "func";
import { Markup } from "interweave";
import moment from "moment";
import { LoadingContext } from "providers/LoadingProvider";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import styled from "@emotion/styled";
import { IMAGES } from "assets/images/imagePath";
import MultipleImage from "components/MultipleImage";

function ServiceDetail() {
	const PreviewImg = styled("img")({
		width: "100%",
		borderRadius: "8px",
		maxWidth: "600px",
		padding: "20px",
		objectFit: "cover",
		objectPosition: "center",
	});
	const { serviceId } = useParams();

	const { showLoading, hideLoading } = useContext(LoadingContext);

	const [dataInfo, setDataInfo] = useState([]);

	const fetchServiceById = async () => {
		showLoading();
		try {
			const res = await serviceAPI.findOne(serviceId, {
				fields: '["$all", {"category": ["$all"]}, {"unit": ["value"]}]',
			});

			const data = res?.results?.object;
			const dataParams = [
				{
					groupId: 1,
					groupTitle: "Thông tin dịch vụ",
					gridProps: {},
					children: [
						{
							id: "title",

							title: "Tên dịch vụ",
							value: data.title,

							childrenProps: {
								//all Props component here
								span: 8,
							},
						},
						{
							id: "category_name",
							title: "Danh mục dịch vụ",
							value: data?.category?.title,

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "execution_time",
							title: "Thời gian thực hiện",
							value: data.execution_time + " phút" || "Không có dữ liệu",

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "hot",
							title: "Dịch vụ Hot?",
							value: "",
							component: data.hot && LocalFireDepartment,
							childrenProps: {
								span: 8,
								color: "error",
							},
						},
						{
							id: "price",
							title: "Giá gốc",
							value: formatVND(data.price),

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "discount",
							title: "Giá giảm",
							value: formatVND(data.discount),

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "real_price",
							title: "Giá thực",
							value: formatVND(data.price - data.discount),

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "unit",
							title: "Đơn vị",
							value: data?.unit?.value,

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "created_at",
							title: "Thời gian tạo",
							value: moment(data?.created_at).format("DD/MM/YYYY HH:mm"),

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "updated_at",
							title: "Thời gian cập nhật",
							value: moment(data?.updated_at).format("DD/MM/YYYY HH:mm"),

							childrenProps: {
								span: 8,
							},
						},

						{
							id: "description",
							title: "Chi tiết dịch vụ",
							value: data.description || "Không có dữ liệu",

							childrenProps: {
								span: 24,
							},
						},
						{
							id: "implementation_process",
							title: "Quá trình thực hiện",
							value: "",
							component: Markup,
							childrenProps: {
								span: 24,
								content: data.implementation_process || "Không có dữ liệu",
							},
						},
					],
				},
			];

			setDataInfo(dataParams);
			setImageZoom(data?.image_list);
			hideLoading();
		} catch (error) {
			console.log(error);
			hideLoading();
		}
	};

	useEffect(() => {
		fetchServiceById();
	}, []);
	const [imageZoom, setImageZoom] = useState([]);
	return (
		<MainCard>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<MultipleImage imageZoom={imageZoom} />
				</Grid>
				<Grid item xs={9}>
					<DetailInformation data={dataInfo} />
				</Grid>
			</Grid>
		</MainCard>
	);
}

export default ServiceDetail;
