import { Box, Paper, Typography } from "@mui/material";
import Tippy from "@tippyjs/react/headless";
import serviceAPI from "api/ServiceAPI";
import { SearchComponent } from "components/search";
import useDebounce from "hooks/useDebounce";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames/bind";
import styles from "./ComboServiceModal.module.scss";
import { Close } from "@mui/icons-material";
import ComboItemsTable from "./ComboItemsTable";
import NoData from "ui-component/NoData";
import { formatVND } from "func";

const cx = classnames.bind(styles);

function CreateComboItem({ comboItems = [], setComboItems }) {
	const [searchResult, setSearchResults] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	//sử dụng để khi user nhập vào ô tìm kiếm không  gọi API ngay
	const valueDebounce = useDebounce(searchValue);
	const [isFocus, setIsFocus] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);

	const handleSetComboItems = (service) => {
		if (!comboItems.some((item) => item?.id === service.id)) {
			setComboItems((prev) => {
				return [...prev, { ...service, amount: 1 }];
			});
		} else {
			return;
		}
	};

	const fetchDataSearch = async (valueDebounce) => {
		setLoading(true);
		const params = {
			fields: '["$all"]',
			filter: valueDebounce && `{"title":{"$iLike":"%${valueDebounce.trim()}%"}}`,
			limit: 999,
		};
		try {
			const res = await serviceAPI.getList(params);

			setSearchResults(res.results.objects.rows);
		} catch (err) {
			setLoading(false);
			enqueueSnackbar("Có lỗi xảy ra, vui lòng thử lại", {
				autoHideDuration: 2000,
				variant: "error",
			});
		}
	};

	useEffect(() => {
		fetchDataSearch(valueDebounce);
	}, [valueDebounce]);
	useEffect(() => {
		return () => {
			setSearchResults([]);
			setSearchValue("");
		};
	}, []);

	return (
		<>
			<Tippy
				interactive
				visible={isFocus && searchResult.length > 0}
				onClickOutside={() => setIsFocus(false)}
				placement={"bottom"}
				render={(attrs) => (
					<Paper
						tabIndex="-1"
						elevation={3}
						sx={{
							overflow: "auto",
							maxHeight: "250px",
							width: "750px",
						}}
						{...attrs}
					>
						{searchResult.length === 0 ? (
							<NoData
								text={"Không có dữ liệu"}
								sx={{
									padding: "20px",
									width: "100%",
									maxHeight: "100%",
								}}
							/>
						) : (
							searchResult.map((service, idx) => {
								return (
									<Box
										key={service.id}
										className={cx("search__box")}
										onClick={() => handleSetComboItems(service)}
									>
										<Box
											className={cx(
												"search__item",
												comboItems.some((item) => item.id === service.id) && "active"
											)}
										>
											<Typography className={cx("item__text")}>{service.title}</Typography>

											<Box display={"flex"} flexDirection="column">
												{Number(service.discount) !== 0 && (
													<Typography className={cx("item__text", "text__line-through")}>
														{formatVND(service.price)}
													</Typography>
												)}
												<Typography className={cx("item__text")}>
													{formatVND(service.price_deducted)}
												</Typography>
											</Box>
											{/* {comboItems.some((item) => item.id === result.id) && <Close />} */}
										</Box>
									</Box>
								);
							})
						)}
					</Paper>
				)}
			>
				<div style={{ width: "100%" }}>
					<SearchComponent
						setIsFocus={setIsFocus}
						handleChangeSearch={setSearchValue}
						searchValue={searchValue}
						dropdown={false}
					/>
				</div>
			</Tippy>

			<ComboItemsTable comboItems={comboItems} setComboItems={setComboItems} />
		</>
	);
}

export default CreateComboItem;
