import axiosClient from "api/axiosClient";

const roleAPI = {
	async getTemplateList(params) {
		const url = `/template`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async findTemplate(id, params) {
		const url = `template/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},
	async createTemplate(data) {
		const url = `template_detail/create_template`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},
	async create(data) {
		const url = `/user_regsiter_testing`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async update(id, data) {
		const url = `/user_regsiter_testing/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async remove(id) {
		const url = `/user_regsiter_testing/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},

	async removeMulti(params) {
		const url = `/user_regsiter_testing`;
		const resp = await axiosClient.delete(url, { params });
		return resp;
	},
};

export default roleAPI;
