export const TOP_10_COLLAB_LIST = [
	{
		value: "amount",
		title: "Doanh thu nhiều nhất",
	},
	{
		value: "count",
		title: "Cộng tác viên nhiều nhất",
	},
];
