import axiosClient from "api/axiosClient";

const URL = `/history_scan_skin/general_advice_information`;

const scanSkinAPI = {
	async getList(params) {
		const url = `/history_scan_skin`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async getListContent(params) {
		const url = `${URL}`;
		const resp = await axiosClient.post(url, { params });
		return resp;
	},

	async getListGift(params) {
		const url = `user_request_voucher`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},
	async updateStatusGift(id, data) {
		const url = `user_request_voucher/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async findOne(id, params) {
		const url = `advices/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async create(data) {
		const url = `${URL}`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async update(id, data) {
		const url = `advices/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async remove(id) {
		const url = `${URL}/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},

	async removeMulti(params) {
		const url = `${URL}`;
		const resp = await axiosClient.delete(url, { params });
		return resp;
	},
};

export default scanSkinAPI;
