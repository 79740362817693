import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Checkbox,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import UploadImage from "ui-component/UploadImage";
import uploadAPI from "api/UploadAPI";
import bookingAPI from "api/BookingAPI";
import { Input } from "antd";
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AssignBooking.module.scss";
import classnames from "classnames/bind";
import { remove } from "lodash";
import OtpInput from "react-otp-input";
import moment from "moment";
//Firebase
import { generateRecaptcha } from "firebase/firebase-otp";
import { authentication } from "firebase/firebase-config";
import { signInWithPhoneNumber } from "firebase/auth";
import FirebaseLogin from "views/pages/authentication/auth-forms/AuthLogin";
import { switchErrorMessage } from "firebase/firebase-function";
import authAPI from "api/AuthAPI";
import { getNumberWithCommas } from "utils";
import { getValue } from "@amcharts/amcharts4/.internal/core/utils/Type";
import { SearchComponent } from "components/search";
import { useLocation } from "react-router";
import queryString from "query-string";
import employeeAPI from "api/EmployeeAPI";
import useDebounce from "hooks/useDebounce";
import EmployeeItem from "./EmployeeItem";
import { Close } from "@mui/icons-material";
import {
	actGetEmployeeInfo,
	actReloadBookingPage,
	actSetAssignData,
	actSetBookingDetails,
} from "store/actions/BookingActions/action";
const cx = classnames.bind(styles);

const defaultTypeList = [
	{
		value: "NOTIFICATION",
		title: "Notification",
	},
	{
		value: "TERMS_CONDITIONS",
		title: "Terms & Conditions",
	},
	{
		value: "LOCATION",
		title: "Location",
	},
];
const TextValidation = styled("span")({
	color: "red",
});
const Required = styled("span")({
	color: "red",
});
function AssignBookingModal({ ...props }) {
	// 	_____ASSIGN API DATA BODY____
	//booking_id:
	// {
	//     "action": "ASSIGN",
	//     "data": [
	//         {
	//             "booking_detail_id": "ccbe33c0-f3da-11ec-8e30-1f7f681e0234",
	//             "employee_id": "82663fd0-f18b-11ec-aff7-fd7e616cc71f"
	//     ]
	// }

	//++++++++++++++++++
	const { isOpen, isClose, isUpdate, serviceDetail } = props;
	const { booking_id, id: booking_detail_id } = serviceDetail;
	const [employee, setEmployee] = useState("");
	//BodyData
	// const bodyData = useMemo(
	// 	() => ({
	// 		action: isUpdate ? "CHANGE" : "ASSIGN",
	// 		data: [
	// 			{
	// 				booking_detail_id,
	// 				employee_id: employeeId,
	// 			},
	// 		],
	// 	}),
	// 	[employeeId, booking_detail_id]
	// );
	const getEmployee = (employee, idx) => {
		setEmployee(employee);
		setActiveId(idx);
	};

	//Xử lý listSelect thành dạng object Array
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const handleClose = () => {
		isClose(false);
	};

	const translator = (text) => {
		return `abeauty.booking.dialog_form.${text}`;
	};
	const [employeeList, setEmployeeList] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [activeId, setActiveId] = useState(0);
	const debounceSearchValue = useDebounce(searchValue);

	const fetchEmployee = async () => {
		const params = {
			fields: '["$all"]',
			filter: `{"fullname":{"$iLike":"%${debounceSearchValue.trim()}%"}}`,
		};
		const res = await employeeAPI.getList(params);
		const notAdmin = res.results.objects.rows.filter((item) => item.role !== "SUPERADMIN");
		setEmployeeList(notAdmin);
		setEmployee(notAdmin[0]);
	};
	const mountRef = useRef(true);
	useEffect(() => {
		// if (mountRef.current) {
		//Sử dụng để không fetch dữ liệu khi mở modal lần đầu
		// 	mountRef.current = false;
		// 	return;
		// }
		try {
			fetchEmployee();
		} catch (err) {
			console.log(err);
		}
	}, [debounceSearchValue]);

	const dispatch = useDispatch();

	//Submit Giao việc
	const handleSubmit = async () => {
		const assignData = {
			//Data cho assign API

			booking_detail_id,
			employee_id: employee.id,
		};
		const employeeInfoData = {
			//Data cập nhật lại tên trong bookingDetails (bookingReducer)
			booking_detail_id,
			employee_info: employee,
		};

		await dispatch(actGetEmployeeInfo(employeeInfoData));
		await dispatch(actSetAssignData(assignData));
		handleClose();
	};

	return (
		<div>
			<Dialog maxWidth="md" minWidth="md" open={isOpen} onClose={handleClose}>
				<DialogTitle
					sx={{
						fontSize: "18px",
						textAlign: "center",
					}}
					variant={"h3"}
				>
					Giao việc
				</DialogTitle>
				<Grid item xs={12}>
					<SearchComponent
						handleChangeSearch={setSearchValue}
						searchValue={searchValue}
						searchBy={"name"}
						dropdown={false}
						styledSearchBox={{ width: "100%" }}
						sx={{
							width: "100%",
							padding: "20px",
						}}
					/>
				</Grid>
				<DialogContent
					sx={{
						width: "400px",
						height: "300px",
					}}
				>
					<Grid
						container
						spacing={1}
						style={{
							marginBottom: 20,
						}}
					>
						<Grid item xs={12}>
							<Box>
								{Array.isArray(employeeList) &&
									employeeList.map((employee, idx) => {
										return (
											<EmployeeItem
												onClick={() => {
													getEmployee(employee, idx);
												}}
												key={employee.id}
												employee={employee}
												active={idx === activeId ? true : false}
											/>
										);
									})}
							</Box>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						justifyContent: "center",
					}}
				>
					<LoadingButton
						sx={{
							margin: 2,
						}}
						// endIcon={<SaveIcon />}
						loading={loading}
						loadingPosition="end"
						variant="contained"
						className={cx("button__action")}
						onClick={() => handleSubmit()}
					>
						{isUpdate ? t(translator("update")) : "Giao việc"}
					</LoadingButton>
				</DialogActions>

				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={showErr.state}
					onClose={() => setShowErr({ state: false })}
					// action={action}
					autoHideDuration={5000}
					severity="error"
				>
					<Alert severity="error">{showErr.message}</Alert>
				</Snackbar>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default AssignBookingModal;
