import { COLOR } from "constants/common";
import moment from "moment";

export const getNumberWithCommas = (number) => {
	if (typeof number !== number) parseInt(number);
	return number.toLocaleString();
};

export const paymentTypeOrderTranslator = (paymentType) => {
	switch (paymentType) {
		case "CASH":
			return "Tiền mặt";
		case "WALLET":
			return "Hạn mức dịch vụ";
		case "TRANSFER":
			return "Chuyển khoản";
		case "CARD":
			return "Quẹt thẻ";
		default:
			return paymentType;
			break;
	}
};

export const checkInventoryTranslator = (checkType) => {
	switch (checkType) {
		case "CHECK_INVENTORY":
			return {
				title: "Kiểm kho",
				color: COLOR.WARNING,
			};
		case "ORDER":
			return {
				title: "Bán hàng",
				color: COLOR.SUCCESS,
			};
		case "INTERNAL":
			return {
				title: "Xuất hàng nội bộ",
				color: COLOR.SECONDARY,
			};
		default:
			return {
				title: checkType,
				color: COLOR.MAIN_COLOR,
			};
	}
};

export const transactionTranslator = (type, report = false) => {
	switch (type) {
		case "WITHDRAW":
			return "Thanh toán";
		case "DEPOSIT":
			return "Nạp tiền";
		case "ROSES":
			return "Tiền hoa hồng";
		case "ROSES_MONTHLY":
			return "Tiền hoa hồng theo tháng";
		case "REFUND":
			return "Tiền hoàn lại";
		case "REQUEST_DEPOSIT":
			return report ? "Yêu cầu nạp tiền từ nhân viên" : "Nạp tiền";
		case "REQUEST_WITHDRAW":
			return "Rút tiền";
		case "DISCOUNT_FOR_CLIENT_HIDDEN":
			return "Ưu đãi cộng tác viên";
		case "REQUEST_DEPOSIT_FOR_FIRST_TIME":
			return "Yêu cầu nạp tiền từ nhân viên";
		case "DEPOSIT_ROSES":
			return "Nạp tiền hoa hồng";
		default:
			return type;
	}
};
export const notificationTranslator = (typeNoti, target) => {
	if (target) {
		switch (target) {
			case "COLLAB":
				return "Cộng tác viên";
			case "NORMAL":
				return "Thông thường";
			case "ALL":
				return "Tất cả";
			case "CUSTOMER":
				return "Khách hàng";
			case "STAFF":
				return "Nhân viên";

			default:
				return typeNoti;
		}
	}
	if (typeNoti) {
		switch (typeNoti) {
			case "GENERAL":
				return "Chung";

			case "SALE":
				return "Giảm giá";

			default:
				return target;
		}
	}
};
export const activityOrderTranslator = (activityName) => {
	switch (activityName) {
		case "WAITING":
			return {
				value: "Chờ xác nhận",
				color: "#ffc107",
				step: 0,
			};
		case "CONFIRMED":
			return {
				value: "Chờ lấy hàng",
				color: "#007bff",
				step: 1,
			};
		case "PROCESSING":
			return {
				value: "Đang giao",
				color: "#17a2b8",
				step: 2,
			};

		case "COMPLETED":
			return {
				value: "Đã giao",
				color: "#28a745",
				step: 3,
			};
		case "CANCELED":
			return {
				value: "Đã hủy",
				color: "#dc3545",
				step: 99,
			};
		default:
			break;
	}
};
export const activityList = [
	{
		activity: "WAITING",
		title: "Đang chờ",
		color: "#f2f2f2",
	},
	{
		activity: "PROCESSING",
		title: "Đã xác nhận lịch",
		color: "#ffc107",
	},
	{
		activity: "CONFIRMED",
		title: "Đã xác nhận với khách hàng",
		color: "#90caf9",
	},
	// {
	// 	activity: "ASSIGNED",
	// 	title: "Đã gán nhân viên",
	// 	color: "#536dfe",
	// 	isDark: true,
	// },
	{
		activity: "COMPLETED",
		title: "Hoàn thành",
		color: "#00c853",
		isDark: true,
	},
	{
		activity: "CANCELED",
		title: "Hủy bỏ",
		color: "#f44336",
		isDark: true,
	},
];

export const productTranslator = (productType) => {
	switch (productType) {
		case "GIFT":
			return "Quà tặng";
		case "PRODUCT":
			return "Sản phẩm";

		default:
			return productType;
	}
};
export const timeTypeTrans = (timeType) => {
	switch (timeType) {
		case "DAY":
			return "ngày";
		case "WEEK":
			return "tuần";
		case "MONTH":
			return "tháng";
		case "YEAR":
			return "năm";

		default:
			return timeType;
	}
};
export const bookingTypeTranslator = (bookingType) => {
	switch (bookingType) {
		case "":
			return "Quà tặng";
		case "PRODUCT":
			return "Sản phẩm";

		default:
			return bookingType;
	}
};
export const activityTranslator = (activityName) => {
	switch (activityName) {
		case "WAITING":
			return {
				title: "Đợi xác nhận",
				color: "#000",
			};
		case "PROCESSING":
			return {
				title: "Đã xác nhận lịch",
				color: "#ffc107",
			};
		case "ASSIGNED":
			return {
				title: "Đã gán nhân viên",
				color: "#536dfe",
			};
		case "CONFIRMED":
			return {
				title: "Đã xác nhận với khách hàng",
				color: "#90caf9",
			};
		case "COMPLETED":
			return {
				title: "Hoàn thành",
				color: "#00c853",
			};
		case "CANCELED":
			return {
				title: "Hủy bỏ",
				color: "#f44336",
			};

		default:
			break;
	}
};

export const joinCollabByTypeTranslator = (type) => {
	switch (type) {
		case "QR500":
			return {
				title: "Mã QR 500K",
				color: "#00c853",
			};
		case "CMS":
			return {
				title: "Nhân viên thêm",

				color: "#f44336",
			};
		case "QR_PERSONAL":
			return {
				color: "#ffc107",

				title: "Mã QR cá nhân",
			};

		case "QR_THE_HAN_MUC_DICH_VU":
			return {
				color: COLOR.SECONDARY,
				title: "Mã QR thẻ hạn mức dịch vụ",
			};

		case "QR_THE_TACH":
			return {
				color: COLOR.NAVI,
				title: "Thẻ cá nhân tách",
			};

		default:
			return {
				title: type,
				color: "black",
			};
			break;
	}
};
export const debtTypeTranslator = (type) => {
	switch (type) {
		case "DEBT":
			return {
				title: "Điều chỉnh",
				color: COLOR.ERROR,
			};

		case "PAYMENT":
			return {
				color: COLOR.SUCCESS,

				title: "Thanh toán",
			};

		case "ORDER":
			return {
				color: COLOR.SECONDARY,
				title: "Đơn hàng",
			};

		default:
			return {
				title: type,
				color: COLOR.ERROR,
			};
	}
};

export const statusTransactionTranslator = (stt) => {
	switch (stt) {
		case "PENDING":
			return {
				title: "Chờ xử lý",
				color: "#ffc107",
			};

		case "DONE":
			return {
				title: "Hoàn thành",
				color: "#00c853",
			};
		case "CANCEL":
			return {
				title: "Từ chối",
				color: "#f44336",
			};

		default:
			return {
				title: "Tất cả",
				color: "#000",
			};
	}
};
export const statusConfirmTrans = (stt) => {
	switch (stt) {
		case "WAITING":
			return {
				title: "Chờ xử lý",
				color: "#ffc107",
			};

		case "DONE":
			return {
				title: "Đồng ý",
				color: "#00c853",
			};
		case "CANCEL":
			return {
				title: "Từ chối",
				color: "#f44336",
			};

		default:
			return {
				title: "Tất cả",
				color: "#000",
			};
	}
};
export const statusUserVerifyTranslator = (stt) => {
	switch (stt) {
		case "WAITING":
			return {
				title: "Chờ xử lý",
				color: "#ffc107",
			};

		case "CONFIRMED":
			return {
				title: "Hoàn thành",
				color: "#00c853",
			};
		case "BLOCKED":
			return {
				title: "Từ chối",
				color: "#f44336",
			};

		default:
			return {
				title: "Tất cả",
				color: "#000",
			};
	}
};

export const typeSettingTranslator = (type) => {
	switch (type) {
		case "HOTLINE":
			return "Hotline";
		case "WORK_TIME":
			return "Thời gian làm việc";
		case "ADDRESS":
			return "Địa chỉ";
		case "EMAIL":
			return "Email";

		default:
			break;
	}
};

export const getWeeksDayFromTimeStamp = (timestamp) => {
	const weekDaysNum = moment(parseInt(timestamp)).isoWeekday();

	switch (weekDaysNum) {
		case 1:
			return "Thứ 2";
		case 2:
			return "Thứ 3";
		case 3:
			return "Thứ 4";
		case 4:
			return "Thứ 5";
		case 5:
			return "Thứ 6";
		case 6:
			return "Thứ 7";
		case 7:
			return "Chủ nhật";
		default:
			return "Không xác định";
	}
};

export const formatVND = (value) => {
	const result = value * 1;
	return result.toLocaleString("vi-VN", { style: "currency", currency: "VND" });
};

export const formatCash = (n) => {
	if (n < 1e3) return n;
	if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + " nghìn";
	if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + " triệu";
	if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + " tỉ";
	if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
};

export const removeAccents = (str) => {
	return str
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/đ/g, "d")
		.replace(/Đ/g, "D");
};

export const rx_live = /^\d*$/;
export const handleMaskValue = (valueMask) => {
	const temp = valueMask?.toString()?.split(",").join("");
	if (rx_live.test(temp)) {
		return temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	} else {
		return temp;
	}
};
export const handleParseMask = (valueMask) => {
	return parseInt(Number(valueMask.toString().split(",").join("")));
};

export function isValidHttpUrl(string) {
	let url;

	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
}

export const convertToMiliTimeStamp = (timestamp) => {
	const date = new Date(timestamp);

	if (Math.abs(Date.now() - date) < Math.abs(Date.now() - date * 1000)) {
		return timestamp;
	} else {
		return timestamp * 1000;
	}
};
