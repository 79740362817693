// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

let saveTimer;
let debounceTime = 10000; // 10 seconds

export const saveDebounce = (actRedux) => {
	if (saveTimer) {
		clearTimeout(saveTimer);
	}

	saveTimer = setTimeout(() => {
		// Use request library of choice here
		actRedux();
	}, debounceTime);
};
