import { Close, Title } from "@mui/icons-material";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classnames from "classnames/bind";
import styles from "./BookingDetail.module.scss";
import ServiceItem from "./ServiceItem";
import AssignBookingModal from "../AssignBookingModal";
import { useDispatch, useSelector } from "react-redux";
import AssignSingle from "../AssignBookingModal/AsssignSingle";
import { actSetAssignData } from "store/actions/BookingActions/action";

const cx = classnames.bind(styles);
function BookingDetailModalSingle({ isOpen, isClose }) {
	const handleClose = () => {
		isClose(false);
	};
	const serviceList = useSelector((state) => state.booking.bookingDetails);
	const data = useSelector((state) => state.booking.assignData);

	const dispatch = useDispatch();
	useEffect(() => {
		if (!isOpen) {
			//Nếu truyền "reset" thì Data sẽ = [], bookingReducer
			dispatch(actSetAssignData("reset"));
		}
	}, [isOpen]);

	const [stateAssign, setStateAssign] = useState({ data: "", openModal: false });
	return (
		<>
			<Dialog maxWidth="lg" open={isOpen} onClose={handleClose}>
				<DialogTitle
					sx={{
						fontSize: "18px",
						textAlign: "center",
					}}
				>
					Dịch vụ được đặt
				</DialogTitle>
				<Grid item xs={12}></Grid>
				<DialogContent>
					<Grid
						container
						spacing={1}
						style={{
							marginBottom: 20,
						}}
					>
						<Grid item xs={12}>
							{/* <Box>
								<h3>DỊCH VỤ CHÍNH</h3>
								<Box sx={{ padding: 2 }}>
									{Array.isArray(serviceList) &&
										serviceList.map((service, idx) => {
											return (
												service.is_main_service && (
													<ServiceItem
														service={service}
														key={service.id}
														onSetStateAssign={setStateAssign}
													/>
												)
											);
										})}
								</Box>
							</Box> */}
							<Box>
								<h3>DỊCH VỤ: </h3>
								<Box sx={{ padding: 2 }}>
									{Array.isArray(serviceList) &&
										serviceList.map((service, idx) => {
											return (
												!service.is_main_service && (
													<>
														<ServiceItem
															onSetStateAssign={setStateAssign}
															service={service}
															key={service.id}
														/>
														{idx !== serviceList.length - 1 && <Divider />}
													</>
												)
											);
										})}
								</Box>
							</Box>
						</Grid>
					</Grid>
				</DialogContent>

				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
			<AssignSingle
				serviceDetail={stateAssign.data}
				isOpen={stateAssign.openModal}
				isUpdate={stateAssign?.isUpdate}
				isClose={(value) => setStateAssign({ ...stateAssign, openModal: value })}
			/>
		</>
	);
}

export default BookingDetailModalSingle;
