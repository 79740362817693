import { Box, Tab, Tabs } from "@mui/material";
import { COLOR } from "constants/common";
import React from "react";
import { useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import ProductCheckInventory from "./ProductCheckInventory";
import ProductInfo from "./ProductInfo";
import ProductInventory from "./ProductInventory";

function ProductDetailPage() {
	const [value, setValue] = React.useState("info");

	const { productId } = useParams();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const renderPage = () => {
		switch (value) {
			case "info":
				return <ProductInfo productId={productId} />;
			case "inventory":
				return <ProductInventory productId={productId} />;
			case "check_inventory":
				return <ProductCheckInventory productId={productId} />;
			default:
				break;
		}
	};
	return (
		<MainCard>
			<Tabs
				centered
				value={value}
				variant="fullWidth"
				onChange={handleChange}
				TabIndicatorProps={{ sx: { display: "none" } }}
				aria-label="basic tabs example"
			>
				<Tab
					sx={{
						fontSize: "12px",
						minHeight: "20px",
						borderBottom: `2px solid ${COLOR.MAIN_COLOR}`,

						"&.Mui-selected": {
							border: `2px solid ${COLOR.MAIN_COLOR}`,
							borderTopLeftRadius: "5px",
							borderTopRightRadius: "5px",
							borderBottom: "0px",
						},
					}}
					label="Thông tin chi tiết"
					value={"info"}
				/>
				<Tab
					sx={{
						fontSize: "12px",
						minHeight: "20px",
						borderBottom: `2px solid ${COLOR.MAIN_COLOR}`,

						"&.Mui-selected": {
							border: `2px solid ${COLOR.MAIN_COLOR}`,
							borderTopLeftRadius: "5px",
							borderTopRightRadius: "5px",
							borderBottom: "0px",
						},
					}}
					label="Thẻ kho"
					value={"check_inventory"}
				/>
				<Tab
					sx={{
						fontSize: "12px",
						minHeight: "20px",
						borderBottom: `2px solid ${COLOR.MAIN_COLOR}`,

						"&.Mui-selected": {
							border: `2px solid ${COLOR.MAIN_COLOR}`,
							borderTopLeftRadius: "5px",
							borderTopRightRadius: "5px",

							borderBottom: "0px",
						},
					}}
					label="Tồn kho"
					value="inventory"
				/>
			</Tabs>

			<Box>{renderPage()}</Box>
		</MainCard>
	);
}

export default ProductDetailPage;
