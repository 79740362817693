// assets
import {
	IconNotification,
	IconSettings,
	IconLetterCase,
	IconPolaroid,
	IconLine,
	IconTool,
	IconReportMoney,
	IconMapPin,
} from "@tabler/icons";
import { ROLE } from "constants/employeeRole";

// constant
const icons = {
	IconReportMoney,
	IconLine,
	IconNotification,
	IconSettings,
	IconLetterCase,
	IconPolaroid,
	IconTool,
	IconMapPin,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const setting = (waitingRequest, role) => ({
	id: "system-setting",
	title: "Cài đặt",
	type: "group",
	visible:
		role?.SETTING === ROLE.SETTING ||
		role?.OFFER === ROLE.OFFER ||
		role?.BANNER_VIEW === ROLE.BANNER_VIEW ||
		role?.BRANCH_VIEW === ROLE.BRANCH_VIEW,

	children: [
		{
			id: "Setting",
			title: "Setting",
			type: "item",
			url: "system-setting",
			icon: icons.IconTool,
			breadcrumbs: false,
			visible: role?.SETTING === ROLE.SETTING,
		},
		{
			id: "Offer",
			title: "offer",
			type: "item",
			url: "offer",
			icon: icons.IconReportMoney,
			breadcrumbs: false,
			badge: false,
			visible: role?.OFFER === ROLE.OFFER,
		},

		// {
		// 	id: "SettingCMS",
		// 	title: "SettingCMS",
		// 	type: "item",
		// 	url: "setting-cms",
		// 	icon: icons.IconSettings,
		// 	breadcrumbs: false,
		// },
		{
			id: "Banner",
			title: "banner",
			type: "item",
			url: "banner",
			icon: icons.IconPolaroid,
			breadcrumbs: false,
			visible: role?.BANNER_VIEW === ROLE.BANNER_VIEW,
		},
		{
			id: "Branch",
			title: "branch",
			type: "item",
			url: "branch",
			icon: icons.IconMapPin,
			breadcrumbs: false,
			visible: role?.BRANCH_VIEW === ROLE.BRANCH_VIEW,
		},
	],
});

export default setting;
