export const getDefaultOfTree = (rowTree, employeeRoles) => {
	const treeData = rowTree
		.flatMap((row) => {
			if (!row.children || row?.children?.length === 0) {
				if (employeeRoles?.includes(row?.key)) {
					return row.key;
				} else {
					return null;
				}
			} else {
				return row.children.map((item) => {
					if (employeeRoles.includes(item?.key)) {
						return item.key;
					} else {
						return null;
					}
				});
			}
		})
		.filter((item) => !!item !== false);

	return treeData;
};

export const checkEmptyData = (data) => {
	const isEmpty = Object.values(data).every((item) => {
		return item.length === 0;
	});
	return isEmpty;
};
