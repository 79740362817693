import { Stack } from "@mui/material";
import CardLayout from "components/FilterAdvance/CardLayout";
import DateFilter from "components/FilterAdvance/DateFilter";
import SearchFilter from "components/FilterAdvance/SearchFilter";
import { packInvoicesFilter } from "components/FilterAdvance/SearchFilter/data";
import SideFilter from "components/SideFilter";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

function QrInvoicesFilter({ setFilterData, filterData }) {
	const { control, handleSubmit, reset } = useForm({
		defaultValues: filterData,
	});
	const onSubmit = (data) => {
		setFilterData(data);
	};

	useEffect(() => {
		reset(filterData);
	}, [filterData]);

	return (
		<SideFilter>
			<Stack
				height={"100%"}
				gap={2}
				component={"form"}
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						handleSubmit(onSubmit)();
					}
				}}
			>
				<CardLayout
					title={"Tìm kiếm"}
					mainContent={{
						Component: SearchFilter,
						props: {
							data: packInvoicesFilter,
							control,
						},
					}}
				/>

				<CardLayout
					title={"Thời gian bán thẻ"}
					hasExpanded={false}
					mainContent={{
						Component: DateFilter,
						props: {
							name: "date",
							control,
						},
					}}
				/>
				{/* <CardLayout
					title={"Thẻ"}
					mainContent={{
						Component: MultipleCheckboxField,
						props: {
							name: "owner",
							control,
							checkArray: ownerStatus,
						},
					}}
				/> */}
			</Stack>
		</SideFilter>
	);
}

export default QrInvoicesFilter;
