import React from "react";

import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { IMAGES } from "assets/images/imagePath";

import classnames from "classnames/bind";
import styles from "./ProductSearchItem.module.scss";
import { formatVND } from "func";
import { Tooltip } from "antd";
const cx = classnames.bind(styles);
function ProductSearchItem({ product, isActive, onAddOrder }) {
	const isSoldOut = product.inventory_amount <= 0;

	return (
		<Box
			className={cx("wrapper", isSoldOut && "sold__out")}
			sx={{ width: "100%", height: "100%" }}
			onClick={onAddOrder}
		>
			<Card
				className={cx("box", isActive && "active", isSoldOut && "sold__out")}
				sx={{ display: "flex", width: "100%", flexDirection: "row", height: "100%" }}
			>
				<CardContent
					sx={{
						padding: "15px",
						width: "100%",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						position: "relative",
					}}
				>
					<Tooltip title={product?.title} zIndex={1000000000}>
						<Typography
							sx={{ width: "50%" }}
							className="text-ellipsis"
							component="div"
							variant="h5"
						>
							{product?.title}
						</Typography>
					</Tooltip>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "5px",
							flex: 1,
							alignItems: "flex-end",
						}}
					>
						{!product?.combo_service_item && product?.sale_price !== 0 && (
							<Typography
								variant="subtitle2"
								color="text.secondary"
								component="div"
								sx={{ textDecorationLine: "line-through" }}
							>
								{formatVND(product?.price || 0)}
							</Typography>
						)}

						<Typography variant="subtitle2" color="text.secondary" component="div">
							{formatVND(product?.price - (product?.sale_price || 0))}
						</Typography>
					</Box>

					{isSoldOut && (
						<CardMedia
							component="img"
							sx={{
								position: "absolute",
								width: "20px",
								height: "20px",
								objectFit: "contain",
								right: 10,
								top: 10,
							}}
							image={IMAGES.sold_out || IMAGES.avatar_default}
							alt="Live from space album cover"
						/>
					)}
				</CardContent>
			</Card>
		</Box>
	);
}

export default ProductSearchItem;
