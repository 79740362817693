import { Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Popover,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { InputNumber } from "antd";

import { formatVND } from "func";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames/bind";
import styles from "./CreateOrder.module.scss";
import { Controller } from "react-hook-form";
import NotePopover from "ui-component/NotePopover";
import DiscountByType from "ui-component/DiscountByType";

const cx = classnames.bind(styles);

function StepInfoOrder({
	payType,
	setPayType,
	orderType,
	setOrderType,
	activity,
	setActivity,
	setTotalDiscount,
	noteVal,
	setNoteVal,
	setCustomerPay,
	control,
	errors,
}) {
	const { infoCustomer, orderList } = useSelector((state) => state.createOrder);
	const columns = [
		{
			field: "STT",
			headerName: "STT",
			align: "center",
			editable: false,
			sortable: false,
			headerAlign: "center",
			headerClassName: "custom__header",
			flex: 0.5,
			valueGetter: (params) => {
				const index = orderList.findIndex((item) => item.id === params.row.id);
				return index + 1;
			},
		},
		{
			field: "title",
			headerName: "Tên sản phẩm",
			align: "center",
			editable: false,
			sortable: false,
			headerClassName: "custom__header",

			headerAlign: "center",
			flex: 2,
			renderCell: (params) => {
				return (
					<Tooltip title={params.value}>
						<Typography
							sx={{
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: "count",
			headerName: "Số lượng",
			flex: 0.5,
			headerClassName: "custom__header",

			align: "center",
			editable: false,
			sortable: false,
			headerAlign: "center",
		},
		{
			field: "deducted_price",
			headerName: "Giá bán",
			editable: false,
			sortable: false,
			headerClassName: "custom__header",

			align: "center",
			headerAlign: "center",

			type: "number",
			flex: 1,
			valueGetter: (params) => {
				return formatVND(params.row.deducted_price);
			},
		},
		{
			field: "realPrice",
			headerName: "Thành tiền",
			headerClassName: "custom__header",

			editable: false,
			sortable: false,
			headerAlign: "center",
			type: "number",
			align: "center",

			flex: 1,
			valueGetter: (params) => formatVND(params.row.deducted_price * params.row.count),
		},
	];

	const [inputVal, setInputVal] = useState({
		discount: 0,
		customerPay: infoCustomer.totalPrice,
	});

	const handleOnChange = (name, e) => {
		setInputVal((prev) => ({
			...prev,
			[name]: e,
		}));
		setCustomerPay(e);
	};

	const refund = useMemo(
		() => inputVal?.customerPay - (infoCustomer.totalPrice - inputVal?.discount),
		[inputVal]
	);

	const handleChangePayType = (e) => {
		setPayType(e.target.value);
	};

	const selectList = [
		{
			value: "WAITING",
			text: "Chờ xác nhận",
			step: 0,
		},

		{
			value: "CONFIRMED",
			text: "Chờ lấy hàng",
			step: 1,
			isHide: infoCustomer.order_type === "COMBO",
		},
		{
			value: "PROCESSING",
			text: "Đang giao",
			step: 2,
			isHide: infoCustomer.order_type === "COMBO",
		},
		{
			value: "COMPLETED",
			text: "Đã giao",
			step: 3,
		},
		// {
		// 	value: "CANCELED",
		// 	text: "Đã hủy",
		// 	step: 99,
		// },
	];

	//Popover note
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [childTypePopover, setChildTypePopover] = React.useState("");

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClose = () => {
		document.removeEventListener("keypress", window.scannerFnc);
		setAnchorEl(null);
		document.addEventListener("keypress", window.scannerFnc);
	};

	const handleClick = (event, type) => {
		setChildTypePopover(type);
		setAnchorEl(event.currentTarget);
		event.currentTarget.blur();
	};

	const setDiscount = (value) => {
		setTotalDiscount(value);
		handleOnChange("discount", value);
		handleOnChange("customerPay", infoCustomer.totalPrice - (value || 0));
	};
	const renderPopover = useCallback(() => {
		switch (childTypePopover) {
			case "note":
				return <NotePopover name="note" value={noteVal} setValue={setNoteVal} />;
			case "discount":
				return (
					<Box p={2}>
						<Typography variant="h5">Giảm giá</Typography>
						<DiscountByType
							openPopover={open}
							onPopoverHide={setDiscount}
							total={infoCustomer.totalPrice}
							defaultDiscount={inputVal.discount}
						/>
					</Box>
				);
			default:
				break;
		}
	}, [childTypePopover, noteVal, inputVal.discount]);

	return (
		<form>
			<Box className={cx("wrapper")}>
				<Grid
					container
					spacing={2}
					noWrap
					sx={{
						marginTop: "20px",
						display: "flex",
					}}
				>
					<Grid item xs={12} md={8}>
						<Box
							sx={{
								padding: "20px",
								width: "100%",
								"& .custom__header": {
									backgroundColor: "#311D4F",
									color: "#fff",
									fontWeight: 700,
								},
							}}
						>
							<DataGrid
								autoHeight
								autoWidth
								disableColumnMenu
								disableVirtualization={false}
								disableSelectionOnClick={true}
								disableDensitySelector={false}
								hideFooter={true}
								rowHeight={70}
								sortingMode="server"
								rows={orderList}
								columns={columns}
							/>
						</Box>
					</Grid>
					{/* <Divider
						orientation="vertical"
						flexItem
						sx={{ borderStyle: "dashed", borderWidth: "1px", borderColor: "black" }}
					/> */}
					<Grid item xs={12} md={4}>
						<Box mt={"20px"}>
							<Grid container spacing={4} justifyContent={"space-between"} sx={{ mb: 3 }}>
								<Grid item xs={6}>
									Tổng tiền thanh toán
								</Grid>
								<Grid item xs={6} sx={{ textAlign: "right" }}>
									{formatVND(infoCustomer.totalPrice)}
								</Grid>
							</Grid>

							{infoCustomer.order_type !== "INTERNAL" && (
								<Grid container spacing={4} justifyContent={"space-between"}>
									<Grid item xs={6}>
										Giảm giá
									</Grid>
									<Grid item xs={6} sx={{ textAlign: "right" }}>
										<div
											onClick={(e) => {
												handleClick(e, "discount");
											}}
										>
											<InputNumber
												className={cx("input__number")}
												min={0}
												max={infoCustomer.totalPrice}
												defaultValue={0}
												style={{
													width: "100%",
													textAlign: "right",
													pointerEvents: "none",
												}}
												addonAfter={"đ"}
												value={inputVal.discount}
												formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
												parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
												// onChange={(e) => {
												// 	setTotalDiscount(e);
												// 	handleOnChange("discount", e);
												// 	handleOnChange("customerPay", infoCustomer.totalPrice - (e || 0));
												// }}
												controls={false}
											/>
										</div>
									</Grid>

									<Grid item xs={6}>
										Khách cần trả
									</Grid>
									<Grid item xs={6} sx={{ textAlign: "right" }}>
										{formatVND(infoCustomer.totalPrice - (inputVal?.discount || 0))}
									</Grid>
									{payType === "CASH" && (
										<>
											<Grid item xs={6}>
												Khách thanh toán
											</Grid>
											<Grid item xs={6} sx={{ textAlign: "right" }}>
												<InputNumber
													value={inputVal.customerPay}
													className={cx("input__number")}
													onChange={(e) => {
														handleOnChange("customerPay", e);
													}}
													min={infoCustomer.totalPrice - (inputVal?.discount || 0)}
													formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
													parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
													defaultValue={infoCustomer.totalPrice}
													style={{
														width: "100%",
														textAlign: "right",
													}}
													addonAfter={"đ"}
													controls={false}
												/>
											</Grid>

											<Grid item xs={6}>
												Tiền thừa trả khách
											</Grid>
											<Grid item xs={6} sx={{ textAlign: "right" }}>
												{refund ? formatVND(refund) : 0}
											</Grid>
										</>
									)}
									<Grid item xs={12}>
										<FormControl>
											<FormLabel id="demo-controlled-radio-buttons-group">
												Thanh toán bằng:
											</FormLabel>
											<RadioGroup
												aria-labelledby="demo-controlled-radio-buttons-group"
												name="controlled-radio-buttons-group"
												value={payType}
												onChange={handleChangePayType}
												row
											>
												<FormControlLabel value="CASH" control={<Radio />} label="Tiền mặt" />
												<FormControlLabel
													sx={{
														display: infoCustomer.order_type === "PRODUCT" && "none",
													}}
													value="WALLET"
													disabled={!infoCustomer.userId}
													control={<Radio />}
													label="Hạn mức dịch vụ"
												/>
												<FormControlLabel
													value="TRANSFER"
													control={<Radio />}
													label="Chuyển khoản"
												/>
												<FormControlLabel value="CARD" control={<Radio />} label="Quẹt thẻ" />
											</RadioGroup>
										</FormControl>
									</Grid>

									<Grid item xs={12}>
										<FormControl>
											<FormLabel id="demo-controlled-radio-buttons-group-1">
												Hình thức mua hàng:
											</FormLabel>
											<RadioGroup
												aria-labelledby="demo-controlled-radio-buttons-group-1"
												name="orderType"
												value={orderType}
												onChange={(e) => {
													setOrderType(e.target.value);
													if (e.target.value === "OFFLINE") {
														setActivity("COMPLETED");
													} else {
														setActivity("WAITING");
													}
												}}
												row
											>
												<FormControlLabel
													value="OFFLINE"
													control={<Radio />}
													label="Tại cửa hàng"
												/>

												<FormControlLabel
													value="ONLINE"
													control={<Radio />}
													label="Đặt hàng Online"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>

									<Grid
										sx={{
											display: orderType === "OFFLINE" ? "none" : "block",
										}}
										item
										xs={12}
									>
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<FormLabel id="demo-controlled-radio-buttons-group">
												Chọn trạng thái đơn:
											</FormLabel>
											<FormControl sx={{ ml: 2, flex: 1 }}>
												<InputLabel htmlFor="activity">Trạng thái đơn hàng</InputLabel>
												<Select
													fullWidth
													autoFocus
													defaultValue={selectList[0].value}
													value={activity}
													onChange={(e) => setActivity(e.target.value)}
													label="Trạng thái đơn hàng"
													MenuProps={{
														anchorOrigin: {
															vertical: "top",
															horizontal: "left",
														},
														transformOrigin: {
															vertical: "top",
															horizontal: "left",
														},
														getContentAnchorEl: null,
													}}
													inputProps={{
														name: "activity",
														id: "activity",
													}}
												>
													{selectList.map((select) => {
														const step = select.step;
														return (
															<MenuItem
																sx={{
																	display: select.isHide && "none",
																}}
																key={select.value}
																value={select.value}
															>
																{select.text}
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										</Box>
									</Grid>
								</Grid>
							)}

							<Grid container spacing={4} justifyContent={"space-between"} sx={{ mt: 1 }}>
								<Grid item xs={12}>
									<Tooltip title={noteVal || "Ghi chú đơn hàng..."} arrow>
										<Button
											sx={{
												width: "100%",
												justifyContent: "flex-start",
												textTransform: "none",
												overflow: "hidden",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
											}}
											variant="text"
											startIcon={<Edit />}
											aria-describedby={id}
											onClick={(e) => handleClick(e, "note")}
										>
											{noteVal || "Ghi chú đơn hàng..."}
										</Button>
									</Tooltip>
									<Popover
										id={id}
										open={open}
										anchorEl={anchorEl}
										onClose={handleClose}
										// onKeyPress={(e) => {
										// 	if (e.code === "Enter" || e.keyCode === 13 || e.charCode === 13) {
										// 		e.preventDefault();
										// 		e.stopPropagation();
										// 		handleClose();
										// 	}
										// }}
										anchorOrigin={{
											vertical: -40,
											horizontal: "left",
										}}
									>
										{renderPopover()}
									</Popover>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
}

export default StepInfoOrder;
