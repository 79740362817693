import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import useScriptRef from "hooks/useScriptRef";
import md5 from "md5";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginEmployee } from "store/actions/EmployeeAction";
import AnimateButton from "ui-component/extended/AnimateButton";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ ...others }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const scriptedRef = useScriptRef();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<>
			<Formik
				initialValues={{
					username: "",
					password: "",
					submit: null,
				}}
				validationSchema={Yup.object().shape({
					username: Yup.string().max(255).required(t("username_is_required")),
					password: Yup.string().max(255).required(t("password_is_required")),
				})}
				onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
					try {
						const data = {
							username: values.username?.trim(),
							password: md5(values.password?.trim()),
						};
						dispatch(
							loginEmployee(
								data,
								(isSuccess) => {
									if (isSuccess) {
										enqueueSnackbar(t("success"), {
											variant: "success",
											autoHideDuration: 2000,
										});
										navigate({ pathname: "/users" });
									} else {
										// enqueueSnackbar("Error", {
										// 	variant: "error",
										// 	autoHideDuration: 2000,
										// });
									}
								},
								(err) => {
									enqueueSnackbar(err?.message || t(`error`), {
										variant: "error",
										autoHideDuration: 2000,
									});
								}
							)
						);

						if (scriptedRef.current) {
							setStatus({ success: true });
							setSubmitting(false);
						}
					} catch (err) {
						if (scriptedRef.current) {
							setStatus({ success: false });
							setErrors({ submit: err.message });
							setSubmitting(false);
						}
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
					<form noValidate onSubmit={handleSubmit} {...others}>
						<FormControl
							fullWidth
							error={Boolean(touched.username && errors.username)}
							sx={{ ...theme.typography.customInput }}
						>
							<InputLabel htmlFor="outlined-adornment-email-login">{t("username")}</InputLabel>
							<OutlinedInput
								id="outlined-adornment-email-login"
								type="text"
								value={values?.username?.trim()}
								name="username"
								onBlur={handleBlur}
								onChange={handleChange}
								label="Username"
								inputProps={{}}
							/>
							{touched.username && errors.username && (
								<FormHelperText error id="standard-weight-helper-text-email-login">
									{errors.username}
								</FormHelperText>
							)}
						</FormControl>

						<FormControl
							fullWidth
							error={Boolean(touched.password && errors.password)}
							sx={{ ...theme.typography.customInput, position: "relative" }}
						>
							<InputLabel htmlFor="outlined-adornment-password-login">{t("password")}</InputLabel>
							<OutlinedInput
								id="outlined-adornment-password-login"
								type={showPassword ? "text" : "password"}
								value={values.password}
								name="password"
								onBlur={handleBlur}
								onChange={handleChange}
								// endAdornment={
								// }
								label="Password"
								inputProps={{}}
							/>
							{touched.password && errors.password && (
								<FormHelperText error id="standard-weight-helper-text-password-login">
									{errors.password}
								</FormHelperText>
							)}

							<Box sx={{ position: "absolute", right: 20, top: 30 }}>
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
										size="large"
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							</Box>
						</FormControl>

						{errors.submit && (
							<Box sx={{ mt: 3 }}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}

						<Box sx={{ mt: 2 }}>
							<AnimateButton>
								<Button
									disableElevation
									disabled={isSubmitting}
									fullWidth
									size="large"
									type="submit"
									variant="contained"
									color="secondary"
								>
									{t("sign_in")}
								</Button>
							</AnimateButton>
						</Box>
					</form>
				)}
			</Formik>
		</>
	);
};

export default FirebaseLogin;
