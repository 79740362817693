import SnackbarUtilsConfigurator from "providers/SnackbarUtilsConfigurator";
import {
	DELETE_ORDER,
	RESET,
	RESET_USER,
	SET_INFO_CUSTOMER,
	SET_ORDER_LIST,
	UPDATE_ORDER_LIST,
	UPDATE_ORDER_TYPE,
} from "./types";

const initialState = {
	orderList: [],
	infoCustomer: {
		totalPrice: 0,
		userId: null,
		phone: null,
		order_type: null,
		userInfo: null,
	},
};

const createOrderReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_ORDER_TYPE: {
			//payload = order_type của orderItem
			let infoCustomerUpdate = { ...state.infoCustomer };
			if (infoCustomerUpdate.order_type === null) {
				infoCustomerUpdate = { ...infoCustomerUpdate, order_type: payload };
				return { ...state, infoCustomer: infoCustomerUpdate };
			}
			if (infoCustomerUpdate.order_type === payload) {
				return { ...state };
			} else {
				infoCustomerUpdate = { ...infoCustomerUpdate, order_type: payload };

				return { ...state, infoCustomer: infoCustomerUpdate };
			}
		}
		case SET_ORDER_LIST: {
			let orderListUpdate = [...state.orderList];
			if (orderListUpdate.some((item) => item.id === payload.id)) {
				//INCREASE COUNT
				orderListUpdate = orderListUpdate.map((item) => {
					if (item.id === payload.id) {
						if (payload.inventory_amount_branch == item.count) {
							SnackbarUtilsConfigurator.error("Sản phẩm vượt quá số lượng tồn kho");
							return item;
						} else {
							return {
								...item,
								count: item.count + 1,
							};
						}
					} else {
						return item;
					}
				});
			} else {
				orderListUpdate.push({ ...payload, count: 1 });
			}
			let infoCustomerUpdate = state.infoCustomer;

			//Tính totalPrice
			let totalPriceUpdate = orderListUpdate.reduce(
				(prev, current) => Number(prev * 1 + 1 * current.deducted_price * current.count),
				0
			);
			infoCustomerUpdate = {
				...infoCustomerUpdate,
				totalPrice: totalPriceUpdate,
			};

			return { ...state, orderList: orderListUpdate, infoCustomer: infoCustomerUpdate };
		}

		case UPDATE_ORDER_LIST: {
			let orderListUpdate = [...state.orderList];
			const { data, val, name } = payload;
			//INCREASE COUNT
			orderListUpdate = orderListUpdate.map((item) => {
				if (item.id === data.id) {
					return {
						...item,
						[name]: val,
					};
				} else {
					return item;
				}
			});

			//Tính totalPrice
			let infoCustomerUpdate = { ...state.infoCustomer };
			let totalPriceUpdate = orderListUpdate.reduce(
				(prev, current) => Number(prev * 1 + 1 * current.deducted_price * current.count),
				0
			);
			infoCustomerUpdate = {
				...infoCustomerUpdate,
				totalPrice: totalPriceUpdate,
			};

			return { ...state, orderList: orderListUpdate, infoCustomer: infoCustomerUpdate };
		}

		case DELETE_ORDER: {
			let orderListUpdate = [...state.orderList];
			orderListUpdate = orderListUpdate.filter((item) => item.id !== payload);
			//Tính totalPrice
			let infoCustomerUpdate = { ...state.infoCustomer };
			let totalPriceUpdate = orderListUpdate.reduce(
				(prev, current) => Number(prev * 1 + 1 * current.deducted_price * current.count),
				0
			);
			infoCustomerUpdate = {
				...infoCustomerUpdate,
				totalPrice: totalPriceUpdate,
			};

			return { ...state, orderList: orderListUpdate, infoCustomer: infoCustomerUpdate };
		}

		case SET_INFO_CUSTOMER: {
			let infoCustomerUpdate = {
				...state.infoCustomer,
				userId: payload.id,
				phone: payload.phone,
				userInfo: payload,
			};
			return { ...state, infoCustomer: infoCustomerUpdate };
		}
		case RESET_USER: {
			return {
				...state,
				orderList: state.orderList,
				infoCustomer: {
					totalPrice: state.infoCustomer.totalPrice,
					order_type: state.infoCustomer.order_type,

					userId: null,
					phone: null,
					userInfo: null,
				},
			};
		}

		case RESET:
			return initialState;
		default:
			return state;
	}
};
export default createOrderReducer;
