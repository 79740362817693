import { SET_USER_INFORMATION, UPDATE_LINK_SHARE } from "store/actions/UserAction/type";

const initialState = {
	userInformation: [],
};

const UserReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_USER_INFORMATION: {
			return { ...state, userInformation: payload };
		}
		case UPDATE_LINK_SHARE: {
			let userInfoUpdate = { ...state.userInformation, qr_link_share_for_shop_owner: payload };

			return { ...state, userInformation: userInfoUpdate };
		}

		default:
			return state;
	}
};

export default UserReducer;
