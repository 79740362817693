import {
	Box,
	ClickAwayListener,
	Grid,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import PerfectScrollbar from "react-perfect-scrollbar";

import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import { useDispatch, useSelector } from "react-redux";
import { IconLogout } from "@tabler/icons";
import { useNavigate } from "react-router";
import { logout } from "store/actions/EmployeeAction";
import { useSnackbar } from "notistack";
import useCustomAuth from "hooks/useCustomAuth";
import employeeAPI from "api/EmployeeAPI";
import { UPDATE_SETTING_EMPLOYEE } from "store/actions/EmployeeAction/type";

function PrintTransition({ open, handleClose, ...props }) {
	const theme = useTheme();
	const customization = useSelector((state) => state.customization);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const handleLogout = async () => {
		dispatch(
			logout((isSuccess) => {
				if (isSuccess) {
					navigate("/");
				} else {
					enqueueSnackbar("Logout error", {
						variant: "error",
						autoHideDuration: 3000,
					});
				}
			})
		);
	};
	const [loading, setLoading] = useState(false);
	const { employeeInfo } = useCustomAuth();

	const handleOnChange = async (e) => {
		setLoading(true);
		try {
			const res = await employeeAPI.edit(employeeInfo.id, {
				auto_export: e.target.checked,
			});
			dispatch({
				type: UPDATE_SETTING_EMPLOYEE,
				payload: res?.results,
			});
			setLoading(false);
			enqueueSnackbar("Cập nhật thành công", {
				autoHideDuration: 2000,
				variant: "success",
			});
		} catch (err) {
			enqueueSnackbar(err?.message || "Lỗi", {
				autoHideDuration: 2000,
				variant: "error",
			});
			setLoading(false);
		}
	};
	return (
		<Transitions in={open} {...props}>
			<Paper>
				<ClickAwayListener onClickAway={handleClose}>
					<MainCard
						border={false}
						elevation={16}
						content={false}
						boxShadow
						shadow={theme.shadows[16]}
					>
						<Box
							sx={{
								"& .ps__rail-x, & .ps__rail-y": {
									display: "none !important",
								},
							}}
						>
							<PerfectScrollbar
								style={{
									height: "100%",
									maxHeight: "calc(100vh - 250px)",
									overflowX: "hidden",
								}}
							>
								<Box
									sx={{
										width: "100%",
										maxWidth: 350,
										minWidth: 300,

										backgroundColor: theme.palette.background.paper,
										borderRadius: "10px",
										[theme.breakpoints.down("md")]: {
											minWidth: "100%",
										},
										"& .MuiListItemButton-root": {
											mt: 0.5,
										},
									}}
								>
									<Stack
										alignItems={"center"}
										flexDirection={"row"}
										justifyContent="space-between"
										p={2}
										sx={{ width: "100%" }}
									>
										<Typography variant="h5">Tự động in hóa đơn</Typography>
										<Box>
											<Switch
												onChange={handleOnChange}
												name="auto_export"
												defaultChecked={employeeInfo.auto_export}
											/>
										</Box>
									</Stack>
								</Box>
							</PerfectScrollbar>
						</Box>
					</MainCard>
				</ClickAwayListener>
			</Paper>
		</Transitions>
	);
}

export default PrintTransition;
