import { SET_USER_INFORMATION, UPDATE_LINK_SHARE } from "./type";

export const actSetUserInformation = (data) => ({
	type: SET_USER_INFORMATION,
	payload: data,
});
export const actUpdateQrShare = (link) => ({
	type: UPDATE_LINK_SHARE,
	payload: link,
});
