import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import config from "../../../config";
// import Logo from "../../../ui-component/Logo";
import { IMAGES } from "assets/images/imagePath";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
	<ButtonBase disableRipple component={Link} to={config.defaultPath}>
		{/* <Logo /> */}
		<img
			src={IMAGES.logo_rec}
			width="120px"
			height={"100%"}
			alt="logo"
			style={{ objectFit: "contain", objectCover: "center" }}
		/>
	</ButtonBase>
);

export default LogoSection;
