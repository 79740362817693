import { Close } from "@mui/icons-material";
import { Box, Chip, IconButton, Paper, Typography } from "@mui/material";
import { Tag } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { actSetCheckList } from "store/actions/BookingActions/action";

function SelectedItem({ service: combo_service, isCombo }) {
	const dispatch = useDispatch();
	const handleDeleteService = (service) => {
		dispatch(actSetCheckList({ checked: false, service }));
	};
	//service hiện tại đang là combo_service, nếu tích chọn không sử dụng combo sẽ tạo ra một combo_service ảo với amount : 0 , count 1 , id random
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				margin: "15px 5px",
			}}
		>
			<Typography
				sx={{
					flex: 2,
				}}
			>
				<span>{combo_service.service?.title}</span>
				{isCombo && (
					<Tag color={"success"} style={{ marginLeft: "5px" }}>
						{combo_service.count} lần
					</Tag>
				)}
			</Typography>

			<Box sx={{}}>
				{isCombo ? (
					<Tag color={"purple"}>{combo_service.combo_title}</Tag>
				) : (
					<IconButton onClick={() => handleDeleteService(combo_service.service)}>
						<Close />
					</IconButton>
				)}
			</Box>
		</Box>
	);
}

export default SelectedItem;
