import branchAPI from "api/branchAPI";
import { GET_LIST_BRANCH, SELECT_BRANCH } from "./types";

export const actGetListBranch = (
	onSuccess = (success = false) => {},
	onError = (error = "") => {}
) => {
	const params = {
		fields: '["$all"]',
	};

	return async (dispatch) => {
		try {
			const res = await branchAPI.getList(params);
			dispatch({
				type: GET_LIST_BRANCH,
				payload: res?.results.objects.rows,
			});
			onSuccess(true);
		} catch (error) {
			onError(error?.message || "Lỗi");
		}
	};
};
export const actSelectBranch = (branchId) => ({
	type: SELECT_BRANCH,
	payload: branchId,
});
