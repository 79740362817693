import LoadingComponent from "components/loading";
import React, { createContext, useState } from "react";

const LoadingContext = createContext();

function LoadingProvider({ children }) {
	const [loading, setLoading] = useState(false);

	const value = {
		loading: loading,
		showLoading: () => setLoading(true),
		hideLoading: () => setLoading(false),
	};

	return (
		<LoadingContext.Provider value={value}>
			<>
				{loading && <LoadingComponent text={"Đang tải..."} />}
				{children}
			</>
		</LoadingContext.Provider>
	);
}

export { LoadingContext, LoadingProvider };
