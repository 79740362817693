// assets
import {
	IconUser,
	IconUsers,
	IconShieldCheck,
	IconBuildingStore,
	IconQrcode,
	IconCoin,
	IconMassage,
	IconReportMoney,
	IconBookmarks,
	IconMenu,
	IconPolaroid,
	IconNotebook,
	IconStack,
	IconBoxPadding,
	IconBasket,
	IconReplace,
	IconDisc,
	IconUserCheck,
	IconHistory,
	IconTicket,
	IconLine,
	IconBuildingWarehouse,
	IconLink,
} from "@tabler/icons";
import { ROLE } from "constants/employeeRole";
import { storeRedux } from "store";

// constant
const icons = {
	IconLine,
	IconUser,
	IconShieldCheck,
	IconBuildingStore,
	IconQrcode,
	IconTicket,
	IconCoin,
	IconUsers,
	IconMassage,
	IconReportMoney,
	IconBookmarks,
	IconMenu,
	IconPolaroid,
	IconNotebook,
	IconStack,
	IconBoxPadding,
	IconBasket,
	IconReplace,
	IconDisc,
	IconUserCheck,
	IconHistory,
	IconBuildingWarehouse,
	IconLink,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const goods = (waitingRequest, role) => ({
	id: "goods",
	title: "Hàng hóa",
	type: "group",
	visible:
		role?.PACK_VIEW === ROLE.PACK_VIEW ||
		role?.SERVICE_VIEW === ROLE?.SERVICE_VIEW ||
		role?.PRODUCT_VIEW === ROLE?.PRODUCT_VIEW ||
		role?.VOUCHER_VIEW === ROLE.VOUCHER_VIEW,

	children: [
		{
			id: "Qrcode",
			title: "qrcode",
			type: "item",
			url: "qrcode",
			icon: icons.IconQrcode,
			breadcrumbs: false,
			badge: false,
			visible: role?.PACK_VIEW === ROLE.PACK_VIEW,
		},

		{
			id: "ServieCategory",
			title: "service",
			type: "item",
			url: "service-category",
			icon: icons.IconMenu,
			breadcrumbs: false,
			badge: false,
			visible: role?.SERVICE_VIEW === ROLE?.SERVICE_VIEW,
		},

		{
			id: "ProductCategory",
			title: "product",
			type: "item",
			url: "product-category",
			icon: icons.IconBoxPadding,
			breadcrumbs: false,
			badge: false,
			visible: role?.PRODUCT_VIEW === ROLE?.PRODUCT_VIEW,
		},

		{
			id: "Inventory",
			title: "inventory",
			type: "item",
			url: "all-check-inventory",
			icon: icons.IconBuildingWarehouse,
			breadcrumbs: false,
			badge: false,
			visible: role?.PRODUCT_VIEW === ROLE?.PRODUCT_VIEW,
		},
		{
			id: "Voucher",
			title: "voucher",
			type: "item",
			url: "voucher",
			icon: icons.IconTicket,
			breadcrumbs: false,
			badge: false,
			visible: role?.VOUCHER_VIEW === ROLE.VOUCHER_VIEW,
		},
	],
});

export default goods;
