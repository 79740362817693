import { Print, QrCode } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import Barcode from "components/PrintTemplate/Barcode";
import ProductQr from "components/PrintTemplate/ProductQr";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { gridSpacing } from "store/constant";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import { pageSizeList } from "./data";
import PageSizeItem from "./PageSizeItem";

function PageSizeSelect({ data, templateType = "QR_CODE" }) {
	const componentRef = useRef(null);
	const [typePage, setTypePage] = useState("104_22");
	let Component = <></>;

	if (templateType === "QR_CODE") {
		Component = (
			<ProductQr
				qrCode={data.qr_code_product}
				productName={data.title}
				price={data.price}
				barCode={data?.barcode}
			/>
		);
	}
	if (templateType === "BARCODE") {
		Component = (
			<Barcode
				qrCode={data.qr_code_product}
				productName={data.title}
				price={data.price}
				barCode={data?.barcode}
			/>
		);
	}
	const renderPrintTemplate = (type) => {
		switch (type) {
			case "72_22":
				return Component;
			case "104_22":
				return (
					<Grid container>
						<Grid item xs={4}>
							{Component}
						</Grid>
						<Grid item xs={4}>
							{Component}
						</Grid>
						<Grid item xs={4}>
							{Component}
						</Grid>
					</Grid>
				);

			default:
				break;
		}
	};
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: true,
	});

	const [screenPrint, setScreenPrint] = useState({
		open: false,
	});

	return (
		<>
			<Box>
				<Grid container spacing={gridSpacing}>
					{pageSizeList.map((page, idx) => (
						<Grid item xs={12} sm={6}>
							<PageSizeItem
								key={idx}
								title={page.title}
								image={page.image}
								data={data}
								onSelectTemplate={(value) => {
									setTypePage(page.type);
									setScreenPrint({
										open: true,
									});
								}}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
			<div ref={componentRef} className="content-print">
				{renderPrintTemplate(typePage)}
			</div>

			<ModalAntd
				title={"Mẫu in"}
				visible={screenPrint.open}
				width={typePage === "72_22" ? "189px" : "378px"}
				setVisible={(value) => {
					setScreenPrint((prev) => ({
						...prev,
						open: value,
					}));
				}}
			>
				{renderPrintTemplate(typePage)}
				<Stack justifyContent={"center"} alignItems={"center"} mt={1}>
					<Button variant="outlined" startIcon={<Print />} onClick={handlePrint}>
						In mẫu này
					</Button>
				</Stack>
			</ModalAntd>
		</>
	);
}

export default PageSizeSelect;
