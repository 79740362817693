import { CalculateOutlined, ChangeCircleOutlined } from "@mui/icons-material";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "antd";
import debtAPI from "api/DebtAPI";
import productAPI from "api/ProductAPI";
import DebtForm from "components/forms/DebtForm";
import DebtPaymentForm from "components/forms/DebtPaymentForm";
import { CustomNoRowsOverlay } from "components/noRows";
import { SearchComponent } from "components/search";
import { ROLE } from "constants/employeeRole";
import { debtTypeTranslator, formatVND, paymentTypeOrderTranslator } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import DialogMain from "ui-component/modal-form/DialogMui";
const { Option } = Select;

const searchList = [
	{
		value: "debt_code",
		title: "Mã phiếu",
	},
];

const typeList = [
	{
		value: "ALL",
		title: "Tất cả",
	},
	{
		value: "DEBT",
		title: debtTypeTranslator("DEBT").title,
		color: debtTypeTranslator("DEBT").color,
	},
	{
		value: "PAYMENT",
		title: debtTypeTranslator("PAYMENT").title,
		color: debtTypeTranslator("PAYMENT").color,
	},
];

const paymentTypeList = [
	{
		value: "ALL",
		title: "Tất cả",
	},
	{
		value: "CASH",
		title: paymentTypeOrderTranslator("CASH"),
	},
	{
		value: "CARD",
		title: paymentTypeOrderTranslator("CARD"),
	},
	{
		value: "TRANSFER",
		title: paymentTypeOrderTranslator("TRANSFER"),
	},
];
const Debt = ({ userId = undefined }) => {
	// const role = useSelector((state) => state.employee?.role);
	const { role } = useCustomAuth();
	const location = useLocation();

	const stateLoc = location?.state;

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const actionList = [
		{
			value: "delete",
			title: "delete",
		},
		{
			value: "edit",
			title: "Chỉnh sửa",
		},
	];
	const [rows, setRows] = useState([]);
	console.log(rows);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [filterType, setFilterType] = useState(typeList[0].value);
	const [paymentType, setPaymentType] = useState(paymentTypeList[0].value);

	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});

	const [modalDebt, setModalDebt] = useState({
		open: false,
		type: "DEBT",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [searchBy, setSearchBy] = useState(searchList[0].value);
	const [searchValue, setSearchValue] = useState("");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const closeModal = () => {
		setModalDebt((prev) => ({
			...prev,
			open: false,
		}));
	};
	const updateSuccess = () => {
		closeModal();
		getListService();
	};

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate(
			{
				pathname: location.pathname,
			},
			{
				state: stateLoc,
			}
		);
	};

	const handleOpenChangeAccept = (e, params, isWithClient = false) => {
		let checked = e.target.checked;
		modalContext.showDeleteConfirm(
			params.id,
			() => handleChangeAccept(checked, params.id, isWithClient),
			`${
				checked
					? `Bạn có chắc chắn xác nhận  ${
							!isWithClient ? "sản phẩm này soi da?" : "đã nhận tiền?"
					  } `
					: `Bạn có chắc chắn xác nhận ${
							!isWithClient ? "sản phẩm này không soi da?" : "chưa nhận tiền?"
					  }`
			}`,
			"Chắc chắn"
		);
	};

	const handleChangeAccept = async (checked, id, isWithClient) => {
		try {
			await productAPI.update(
				id,

				!isWithClient
					? {
							is_scan_skin: checked,
					  }
					: {
							is_confirm_with_client: checked,
					  }
			);
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListService();
		} catch (error) {
			enqueueSnackbar(error?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate(
			{
				pathname: location.pathname,
				search: `?${queryString.stringify(filters)}`,
			},
			{
				state: stateLoc,
			}
		);
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	//Print Func
	const handleUpdate = (params) => {
		setData(params.row);
		setOpen(true);
		setIsUpdate(true);
	};
	// Table
	const onChangeAction = (value, id, params) => {
		value === "edit" && handleUpdate(params);
		value === "delete" && handleOpenRemoveModal(id, params.row);
		value === "information" && navigate(`/product-detail/${id}`);
		value === "print_barcode" && handleOpenPrintModal(params.row, "BARCODE");
	};

	const columns = [
		{
			field: "debt_code",
			headerName: "Mã phiếu",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee",
			headerName: "Nhân viên",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value?.fullname || params?.value?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							classNames={params?.value?.deleted_at && "line__though"}
						>
							{params?.value?.fullname || params?.value?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "type",
			headerName: "Loại",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={debtTypeTranslator(params?.value)?.title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								color: debtTypeTranslator(params?.value)?.color,
								fontWeight: "bold",
							}}
						>
							{debtTypeTranslator(params?.value)?.title}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "payment_type",
			headerName: "Phương thức thanh toán",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={paymentTypeOrderTranslator(params?.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								// color: debtTypeTranslator(params?.value)?.color,
								fontWeight: "bold",
							}}
						>
							{paymentTypeOrderTranslator(params?.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "value",
			headerName: "Giá trị",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "debt",
			headerName: "Dư nợ khách hàng",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "note",
			headerName: "Mô tả",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				// params.value = false;

				return (
					<Tooltip
						title={moment(+params?.value).format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(+params?.value).format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
	];

	const [information, setInformation] = useState({
		visible: false,
	});
	const [printData, setPrintData] = useState({
		visible: false,
		data: {},
	});

	const handleOpenPrintModal = (data, templateType = "QR_CODE") => {
		setPrintData({
			visible: true,
			data,
			templateType,
		});
	};

	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const [newestRecord, setNewestRecord] = useState();

	const getNewestRecord = async () => {
		setIsLoading(true);
		let order = '[["created_at", "desc"]]';
		let filter = {
			user_id: userId,
		};

		try {
			const res = await debtAPI.getList({
				fields: '["$all"]',
				order,
				filter: JSON.stringify(filter),
				limit: 1,
				page: 1,
			});
			const newest = res?.results?.objects?.rows[0];
			setNewestRecord(newest);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	};

	const getListService = async (isLastDeletedItem) => {
		setIsLoading(true);
		let filter = {
			user_id: userId,
			payment_type: paymentType === "ALL" ? undefined : paymentType,
			type: filterType === "ALL" ? undefined : filterType,
			[searchBy]:
				searchValue.trim().length === 0
					? undefined
					: {
							$iLike: `%${searchValue.trim()}%`,
					  },
		};

		let order = '[["created_at", "desc"]]';

		try {
			const res = await debtAPI.getList({
				fields: '["$all", {"employee" : ["fullname" ,"username", "deleted_at" ,"$paranoid"]}]',
				order,
				filter: JSON.stringify(filter),
				limit: pagination.limit,
				page: isLastDeletedItem ? queryParams.page - 1 : queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			if (isLastDeletedItem) {
				navigate(
					{
						pathname: location.pathname,
						search: `?${queryString.stringify({ ...queryParams, page: paginationRes.page })}`,
					},
					{
						state: stateLoc,
					}
				);
			}
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));
			const newestDebt = res?.results?.objects?.debt;
			setNewestRecord(+newestDebt);
			// await getNewestRecord();

			setSelectList([]);
			setRows(resList);

			setPagination(paginationRes);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//Get Services Categories
	const [serviceCategories, setServiceCategories] = useState(null);
	const getServicesCategories = async () => {
		setIsLoading(true);

		const filter = {
			status: "true",
		};
		const params = {
			fields: '["$all"]',
			filter: JSON.stringify(filter),
		};
		try {
			const res = await productAPI.getCategory(params);
			setServiceCategories(res.results.objects.rows);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);

	const handleRemoveMulti = async () => {
		let isLast = rows.length === selectList.length && pagination.page !== 1;

		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await productAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListService(isLast);
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate(
			{
				pathname: location.pathname,
				search: `?${queryString.stringify(filters)}`,
			},
			{ state: location.state }
		);
	};

	const handleDelete = async (id) => {
		let isLast = rows.length === 1 && pagination.page !== 1;
		try {
			await productAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListService(isLast);
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useLayoutEffect(() => {
		if (!branchActiveId || !role) return;

		if (role?.PRODUCT_VIEW !== ROLE.PRODUCT_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}

		// if (location.state) {
		// 	setSearchBy(filterWithCategory.searchBy);
		// 	setSearchValue(filterWithCategory.value);
		// }
		getListService();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading, branchActiveId, role, filterType, paymentType]);

	useEffect(() => {
		getServicesCategories();
	}, []);

	return (
		<>
			<MainCard>
				{(location?.state?.isFromCategory || location?.state?.isAll) && (
					<Button
						variant="contained"
						onClick={() =>
							navigate("/product-category", {
								replace: true,
							})
						}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về
					</Button>
				)}
				{stateLoc?.isFromGroup && (
					<Button
						variant="contained"
						onClick={() => navigate("/group-product")}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về
					</Button>
				)}
				<HeaderActions title={"Quản lý nợ"} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Stack flexDirection={"row"}>
						<SearchComponent
							searchBy={searchBy}
							searchList={searchList}
							searchValue={searchValue}
							handleSearchBy={handleSearchBy}
							handleChangeSearch={handleChangeSearch}
						/>
						<FormControl sx={{ minWidth: 150, ml: 5 }}>
							<InputLabel id="demo-simple-select-outlined-label">{"Loại"}</InputLabel>
							<Select
								defaultValue={typeList[0].value}
								onChange={(e) => {
									setFilterType(e.target.value);
								}}
								labelId="demo-simple-select-outlined-label"
								label={"Loại"}
								value={filterType}
							>
								{typeList?.map((item) => {
									console.log(item);
									return (
										<MenuItem key={item.value} value={item.value}>
											{item.title}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl sx={{ minWidth: 150, ml: 5 }}>
							<InputLabel id="demo-simple-select-outlined-label">{"Phương thức"}</InputLabel>
							<Select
								defaultValue={paymentTypeList[0].value}
								onChange={(e) => {
									setPaymentType(e.target.value);
								}}
								labelId="demo-simple-select-outlined-label"
								label={"Phương thức"}
								value={paymentType}
							>
								{paymentTypeList?.map((item) => {
									return (
										<MenuItem key={item.value} value={item.value}>
											{item.title}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Stack>

					{employeeInfo?.role === "SUPERADMIN" && (
						<Button
							sx={{
								display: stateLoc?.isFromGroup ? "none" : "block",
							}}
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Stack flexDirection={"row"} justifyContent="flex-end" my={2} gap={2}>
					<Button
						onClick={() => {
							setModalDebt((prev) => ({
								...prev,
								open: true,

								type: "DEBT",
							}));
						}}
						startIcon={<ChangeCircleOutlined />}
						variant="contained"
					>
						Điều chỉnh
					</Button>
					<Button
						disabled={!newestRecord || +newestRecord <= 0}
						onClick={() => {
							setModalDebt((prev) => ({
								...prev,
								open: true,
								type: "PAYMENT",
							}));
						}}
						startIcon={<CalculateOutlined />}
						variant="contained"
					>
						Thanh toán
					</Button>
				</Stack>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<DialogMain
				maxWidth={"md"}
				title={modalDebt.type === "DEBT" ? "Điều chỉnh" : "Thanh toán"}
				fullWidth
				open={modalDebt.open}
				onClose={() => {
					closeModal();
				}}
			>
				{modalDebt.type === "DEBT" ? (
					<DebtForm formData={newestRecord || 0} userId={userId} onSuccess={updateSuccess} />
				) : (
					<DebtPaymentForm formData={newestRecord || 0} userId={userId} onSuccess={updateSuccess} />
				)}
			</DialogMain>
		</>
	);
};

export default Debt;
