import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames/bind";
import styles from "../Scheduler.module.scss";
import { Box, Button, Chip, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { EditOutlined, FmdBad, Person, Schedule } from "@mui/icons-material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { activityList } from "../fakeData";
import bookingAPI from "api/BookingAPI";
import { useSnackbar } from "notistack";
import { timeConvert } from "../func";
import moment from "moment";
import { formatVND, getWeeksDayFromTimeStamp } from "func";
import AppointmentHead from "../AppointmentHead";
import CancelModal from "ui-component/modal-form/CancelModal";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";
const cx = classnames.bind(styles);
function AppointmentContent({ bedGroup, getSchedulerList, onDeleteConfirm, onEdit, propsTooltip }) {
	const { role } = useCustomAuth();

	const { appointmentData, onDeleteButtonClick, onHide } = propsTooltip;
	// const propsData = contentComponent?.defaultProps?.appointmentData;
	// console.log(propsData);
	const [data, setData] = useState(appointmentData);
	const dataChange = useRef(false); // Dùng để check component có thay đổi data hay không  (1)
	const { enqueueSnackbar } = useSnackbar();
	const [reasonCancel, setReasonCancel] = useState({
		openModal: false,
		reason: "",
	});
	const handleCancelReason = async () => {
		try {
			const res = await bookingAPI.cancelBooking(data.id, {
				reason: reasonCancel.reason,
			});
			setData((prev) => {
				//Cập nhật lại trạng thái của giường sau thay đổi
				return { ...prev, ...res.results.object };
			});
			enqueueSnackbar("Hủy lịch hẹn thành công", {
				autoHideDuration: 2000,
				variant: "success",
			});
			setReasonCancel({
				openModal: false,
				reason: "",
			});
			dataChange.current = true; //(1)
		} catch (err) {
			enqueueSnackbar(err.message || "Có lỗi xảy ra, vui lòng thử lại", {
				autoHideDuration: 2000,
				variant: "error",
			});
		}
	};
	const handleUpdateActivity = async (activity) => {
		try {
			let res;
			if (activity === "CANCELED") {
				//Open Modal
				setReasonCancel((prev) => ({
					...prev,
					openModal: true,
				}));
				return;
			} else {
				res = await bookingAPI.update(data?.id, {
					activity,
				});
			}

			setData((prev) => {
				//Cập nhật lại trạng thái của giường sau thay đổi
				return { ...prev, ...res.results.object };
			});
			enqueueSnackbar("Cập nhật trạng thái lịch hẹn thành công", {
				autoHideDuration: 2000,
				variant: "success",
			});
			dataChange.current = true; //(1)
		} catch (err) {
			enqueueSnackbar(err.message || "Có lỗi xảy ra, vui lòng thử lại", {
				autoHideDuration: 2000,
				variant: "error",
			});
		}
	};
	const handleSelectBed = async (bookingId, slotId) => {
		try {
			const res = await bookingAPI.update(bookingId, {
				slot_id: slotId,
			});

			setData((prev) => {
				return { ...prev, ...res.results.object };
			});
			enqueueSnackbar("Cập nhật trạng thái lịch hẹn thành công", {
				autoHideDuration: 2000,
				variant: "success",
			});
			dataChange.current = true; //(1)
		} catch (err) {
			enqueueSnackbar(err.message || "Có lỗi xảy ra, vui lòng thử lại", {
				autoHideDuration: 2000,
				variant: "error",
			});
		}
	};
	const handlePayBooking = async (bookingId) => {
		try {
			const res = await bookingAPI.update(bookingId, {
				is_paid: true,
			});

			setData((prev) => {
				return { ...prev, ...res.results.object };
			});
			enqueueSnackbar("Thanh toán thành công", {
				autoHideDuration: 2000,
				variant: "success",
			});
			dataChange.current = true; //(1)
		} catch (err) {
			enqueueSnackbar(err.message || "Có lỗi xảy ra, vui lòng thử lại", {
				autoHideDuration: 2000,
				variant: "error",
			});
		}
	};
	useEffect(() => {
		return () => {
			//Component unmount  mới gọi hàm này (close modal)
			if (dataChange.current) {
				//dataChange(true) mới getList
				getSchedulerList();
			}
		};
	}, []);
	console.log(data);
	const totalPrice = data.booking_details?.reduce(
		(prev, current) =>
			Number(
				prev * 1 + 1 * (current.combo_service_id ? 0 : current.price_after_discount) * current.count
			),
		0
	);
	return (
		<div className="wrapper__appointment" style={{ width: "100%" }} {...propsTooltip}>
			<AppointmentHead
				data={data}
				onHide={onHide}
				onDeleteConfirm={onDeleteConfirm}
				onEdit={onEdit}
			/>
			<Box
				sx={{
					padding: "25px",
					paddingTop: "10px",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
				}}
			>
				<Box>
					<Typography
						variant="h4"
						component="div"
						sx={{
							width: "100%",
						}}
						gutterBottom
					>
						<p>Số điện thoại: {data?.phone}</p>
						<p>Mã lịch đặt: {data?.booking_code}</p>
					</Typography>

					<Typography
						variant="h4"
						component="div"
						sx={{
							width: "100%",
							padding: "10px 5px",
						}}
						gutterBottom
					>
						Dịch vụ:
					</Typography>
					{data?.booking_details?.map((booking) => (
						<Typography
							key={booking.id}
							component={"div"}
							className={cx("content__item")}
							sx={{
								display: "flex",
								justifyContent: "space-between",
							}}
							gutterBottom
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
								}}
							>
								<BookmarkBorderIcon />
								<span>
									{booking.title} x {booking.count}
								</span>
							</Box>

							{!booking.combo_service_id && (
								<Box
									sx={{
										display: "flex",
										justifyContent: "flex-start",
										alignItems: "center",
									}}
								>
									<Typography variant="h5">{formatVND(booking.price_after_discount)}</Typography>

									<Tooltip
										title={booking.note_admin || "Không có ghi chú"}
										overlayStyle={{ maxWidth: "500px" }}
										placement="right"
										zIndex="10000"
									>
										<Box
											sx={{
												overflow: "hidden",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
											}}
										>
											<FmdBad fontSize="small" sx={{ ml: 1 }} />
										</Box>
									</Tooltip>
								</Box>
							)}

							{booking.combo_service_id && (
								<Chip
									sx={{ marginLeft: "5px" }}
									color="primary"
									label={"Combo"}
									size={"small"}
									component={"span"}
								/>
							)}
						</Typography>
					))}

					{data.booking_consultants &&
						data?.booking_consultants?.map((booking) => (
							<>
								<Typography
									variant="h4"
									component="div"
									sx={{
										width: "100%",
										padding: "10px 5px",
									}}
									gutterBottom
								>
									Nhân viên phục vụ:
								</Typography>
								<Typography
									key={booking.id}
									component={"div"}
									className={cx("content__item")}
									sx={{
										display: "flex",
										justifyContent: "space-between",
									}}
									gutterBottom
								>
									<Box
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "5px",
										}}
									>
										<Person />
										<span>{booking.employee.fullname}</span>
										<span> - {booking.work} công</span>
									</Box>
								</Typography>
							</>
						))}
					<Divider variant="middle" sx={{ margin: "10px 0" }} />
					<Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
						<Typography variant="h5">Tổng tiền dịch vụ</Typography>

						<Typography variant="h5" mr={3}>
							{formatVND(Number(data.total_price) + Number(data?.discount))}
						</Typography>
					</Box>
					<Divider variant="middle" sx={{ margin: "10px 0" }} />
					<Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
						<Typography variant="h5">Tổng giảm</Typography>

						<Typography variant="h5" mr={3}>
							{formatVND(data?.discount)}
						</Typography>
					</Box>

					<Divider variant="middle" sx={{ margin: "10px 0" }} />
					<Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
						<Typography variant="h5">Tổng tiền thanh toán</Typography>

						<Typography variant="h5" mr={3}>
							{formatVND(data?.total_price)}
						</Typography>
					</Box>

					<Typography component={"span"} className={cx("content__item")} gutterBottom>
						<Schedule />
						<span>
							{" "}
							{getWeeksDayFromTimeStamp(parseInt(data?.time_start))},{" "}
							{moment(parseInt(data?.time_start)).format("DD/MM/YYYY")}
						</span>
						<span>
							{moment(parseInt(data?.time_start)).format("HH:mm")} -{" "}
							{moment(parseInt(data?.time_start))
								.add(data?.execution_time, "minutes")
								.format("HH:mm")}
						</span>
						<Button
							size="small"
							color="success"
							variant="outlined"
							className={cx("button__confirm")}
							sx={{ cursor: "default", color: "#311D4F", borderColor: "#311D4F" }}
						>
							{timeConvert(data?.execution_time)}
						</Button>
					</Typography>
				</Box>

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "8px",
						marginBottom: "40px",
					}}
				>
					<Typography
						sx={{
							padding: "10px 5px",
						}}
						variant={"h4"}
					>
						Trạng thái lịch:
					</Typography>
					<Box>
						<Stack direction="row" justifyContent={"flex-start"} flexWrap={"wrap"} gap="15px">
							{activityList.map((item) => (
								<Tooltip title={item.title}>
									<Chip
										key={item.activity}
										label={item.title}
										size="small"
										sx={{
											width: "100px",
											display: item.isHide && "none",
										}}
										disabled={item.activity === "ASSIGNED"}
										className={cx(data?.activity === item.activity && "tag__color")}
										variant={"outlined"}
										onClick={() => {
											if (role?.BOOKING_UPDATE === ROLE?.BOOKING_UPDATE) {
												handleUpdateActivity(item.activity);
											} else {
												enqueueSnackbar("Bạn không có quyền thực hiện tác vụ này", {
													variant: "warning",
													autoHideDuration: 2000,
												});
											}
										}}
									/>
								</Tooltip>
							))}
						</Stack>
					</Box>

					<Typography
						sx={{
							padding: "10px 5px",
						}}
						variant={"h4"}
					>
						Chọn giường:
					</Typography>
					<Box>
						<Stack direction="row" justifyContent={"flex-start"} flexWrap={"wrap"} gap="15px">
							{bedGroup.map((item) => (
								<Tooltip title={item.text}>
									<Chip
										key={item.id}
										label={item.text}
										size="small"
										sx={{
											width: "100px",
										}}
										className={cx(data?.slot_id === item.id && "tag__color")}
										variant={"outlined"}
										onClick={() => {
											if (role?.BOOKING_UPDATE === ROLE?.BOOKING_UPDATE) {
												handleSelectBed(data.id, item.id);
											} else {
												enqueueSnackbar("Bạn không có quyền thực hiện tác vụ này", {
													variant: "warning",
													autoHideDuration: 2000,
												});
											}
										}}
									/>
								</Tooltip>
							))}
						</Stack>
					</Box>

					{data.message && (
						<>
							<Typography
								sx={{
									padding: "10px 5px",
								}}
								variant={"h4"}
							>
								Yêu cầu đặc biệt:
							</Typography>
							<Typography component={"span"} className={cx("content__item")}>
								{data.message}
							</Typography>
						</>
					)}
					{data.reason && (
						<>
							<Typography
								sx={{
									padding: "10px 5px",
								}}
								variant={"h4"}
							>
								Lý do hủy lịch
							</Typography>
							<Typography component={"span"} className={cx("content__item")}>
								{data.reason}
							</Typography>
						</>
					)}
					{data.employee_confirm_id && (
						<>
							<Typography
								sx={{
									padding: "10px 5px",
								}}
								variant={"h4"}
							>
								Nhân viên xác nhận
							</Typography>
							<Typography component={"span"} className={cx("content__item")}>
								{data?.employee_confirm?.fullname || data?.employee_confirm?.username}
							</Typography>
						</>
					)}
				</Box>

				{data.activity !== "CANCELED" ||
					(role?.BOOKING_UPDATE !== ROLE?.BOOKING_UPDATE && (
						<Box sx={{ alignSelf: "flex-end" }}>
							<Button
								className={cx("button__submit")}
								variant="contained"
								sx={{ color: "#fff", fontWeight: 700 }}
								disabled={data.is_paid}
								onClick={() => handlePayBooking(data.id)}
							>
								{!data.is_paid ? "Xác nhận thanh toán" : "Đã thanh toán"}
							</Button>
						</Box>
					))}
			</Box>
			<CancelModal
				reason={reasonCancel.reason}
				setReason={(value) =>
					setReasonCancel((prev) => ({
						...prev,
						reason: value,
					}))
				}
				open={reasonCancel.openModal}
				setOpen={(value) =>
					setReasonCancel((prev) => ({
						...prev,
						openModal: value,
					}))
				}
				loading={false}
				handleSubmit={() => handleCancelReason()}
				text={{
					title: "Lý do hủy lịch",
					label: "Lý do",
					submit: "Hủy lịch",
				}}
			/>
		</div>
	);
}

export default AppointmentContent;
