import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, Pagination, Tooltip, Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import offerAPI from "api/OfferAPI";
import { IMAGES } from "assets/images/imagePath";

import Text from "antd/lib/typography/Text";
import { CustomNoRowsOverlay } from "components/noRows";
import { ModalContext } from "providers/ModalProvider";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import Modal from "ui-component/modal-form/employee-modal-create-update/index";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { SearchComponent } from "../../components/search";
import _debounce from "lodash/debounce";
import OfferModal from "ui-component/modal-form/OfferModal";
import { formatVND, timeTypeTrans } from "func";
import ImageModal from "ui-component/modal-form/ImageModal";
import { Info } from "@mui/icons-material";
import { Markup } from "interweave";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import useCustomAuth from "hooks/useCustomAuth";
import { ROLE } from "constants/employeeRole";
import { ALERT_PERMISSION } from "constants/constant";

const searchList = [
	{
		value: "title",
		title: "abeauty.offer.table_title.title",
	},
	{
		value: "from",
		title: "abeauty.offer.table_title.from",
	},
	{
		value: "offer",
		title: "abeauty.offer.table_title.offer",
	},

	{
		value: "medal",
		title: "abeauty.offer.table_title.medal",
	},
];

const Offer = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const translator = (text) => {
		return t(`abeauty.offer.table_title.${text}`);
	};

	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [searchBy, setSearchBy] = useState(searchList[0].value);
	const [searchValue, setSearchValue] = useState("");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const [imageZoom, setImageZoom] = useState({
		open: false,
		image: "",
	});

	// Table
	const [information, setInformation] = useState({
		visible: false,
		dataInfo: [],
	});
	const handleOpenInforModal = (data) => {
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin thẻ",
				gridProps: {},
				children: [
					{
						id: "title",

						title: "Giá trị thẻ(chữ)",
						value: data.title,
						component: Text,
						childrenProps: {
							//all Props component here
							span: 8,
						},
					},
					{
						id: "from",
						title: "Giá trị thẻ(số)",
						value: formatVND(data.from),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},

					{
						id: "offer",
						title: "Ưu dãi",
						value: `${data.offer}%`,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "medal",
						title: "Hạng thẻ",
						value: data.medal,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "expired",
						title: "Thời hạn sử dụng",
						value:
							data?.expired && data.time_type
								? `${data.expired} ${timeTypeTrans(data.time_type)}`
								: "",
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "detail",
						title: "Mô tả thẻ",
						value: data.detail || "Không có dữ liệu",
						component: Text,
						childrenProps: {
							span: 24,
						},
					},

					{
						id: "description",
						title: "Chi tiết thẻ",
						value: "",
						component: Markup,
						childrenProps: {
							span: 24,
							content: data.description || "Không có dữ liệu",
						},
					},
				],
			},
		];
		setInformation((prev) => ({
			dataInfo: dataParams,
			visible: true,
		}));
	};

	const columns = [
		// {
		// 	field: "order",
		// 	headerName: t(`order`),
		// 	minWidth: 100,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		{
			field: "image",
			headerName: "Danh sách ảnh",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<img
					height={"80%"}
					className={"aspect1-1"}
					src={params.value || IMAGES.img_default}
					alt="avatar-img"
					onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.img_default))}
					onClick={() => setImageZoom({ open: true, image: params.value })}
				/>
			),
		},
		{
			field: "title",
			headerName: translator("title"),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
		},
		{
			field: "from",
			headerName: translator("from"),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => formatVND(params.value),
		},
		{
			field: "offer",
			headerName: translator("offer"),
			editable: false,
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => `${params.value}%`,
		},

		{
			field: "medal",
			flex: 1,
			headerName: translator("medal"),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
		},
		{
			field: "expired",
			flex: 1,
			headerName: "Thời hạn sử dụng thẻ",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) =>
				params?.value && params.row?.time_type
					? `${params.value} ${timeTypeTrans(params.row?.time_type)}`
					: "",
		},

		{
			field: "created_at",
			flex: 1,
			headerName: t(`created_at`),
			sortable: false,
			headerAlign: "center",
			align: "center",
			// flex: 1,
			renderCell: (data) => <div>{moment.utc(data).format("DD/MM/YYYY HH:mm")}</div>,
		},
		{
			field: "actions",
			flex: 1,
			headerName: t(`actions`),
			editable: false,
			sortable: false,
			align: "center",
			headerAlign: "center",
			// align: "center",
			renderCell: (params) => (
				<>
					<Tooltip title={t(`edit`)}>
						<IconButton
							aria-label="edit"
							onClick={() => {
								setData(params.row);
								setOpen(true);
								setIsUpdate(true);
							}}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>

					<Tooltip title={t(`delete`)}>
						<IconButton aria-label="delete" onClick={() => handleOpenRemoveModal(params.id)}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>

					<Tooltip title={"Chi tiết thẻ"}>
						<IconButton aria-label="delete" onClick={() => handleOpenInforModal(params.row)}>
							<Info />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];

	const getListOffer = async () => {
		setIsLoading(true);
		let filter = "{}";
		let order = '[["from", "asc"]]';
		if (queryParams?.title?.trim()) {
			filter = `{"title":{"$iLike":"%${queryParams?.title.trim()}%"}}`;
		}
		if (queryParams?.from?.trim()) {
			filter = `{"from":"${queryParams?.from.trim()}"}`;
		}
		if (queryParams?.medal?.trim()) {
			filter = `{"medal":{"$iLike":"%${queryParams?.medal.trim()}%"}}`;
		}
		if (queryParams?.offer?.trim()) {
			filter = `{"offer":"${queryParams?.offer.trim()}"}`;
		}
		try {
			const res = await offerAPI.getList({
				fields: '["$all"]',
				order,
				filter,
				limit: pagination.limit,
				page: queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	// Remove multi
	const [selectList, setSelectList] = useState([]);
	const handleRemoveMulti = async () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					const res = await offerAPI.removeMulti({
						items: JSON.stringify(selectList),
					});
					await getListOffer();
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	const handleDelete = async (id) => {
		try {
			await offerAPI.remove(id);

			await getListOffer();
			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};
	const { role } = useCustomAuth();

	useEffect(() => {
		if (!role) return;
		if (role?.OFFER !== ROLE?.OFFER) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty("title")) {
			setSearchBy("title");
			setSearchValue(queryParams?.title);
		}
		if (queryParams.hasOwnProperty("offer")) {
			setSearchBy("offer");
			setSearchValue(queryParams?.offer);
		}
		if (queryParams.hasOwnProperty("from")) {
			setSearchBy("from");
			setSearchValue(queryParams?.from);
		}
		if (queryParams.hasOwnProperty("medal")) {
			setSearchBy("medal");
			setSearchValue(queryParams?.medal);
		}
		getListOffer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading, role]);

	return (
		<>
			<MainCard>
				<HeaderActions
					title={t(`list_service_limit`)}
					setIsUpdate={setIsUpdate}
					setOpen={setOpen}
					onCreate={true}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{employeeInfo?.role === "SUPERADMIN" && (
						<Button
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={60}
							loading={isLoading}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
							checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
			<ImageModal
				title={"Ảnh chi tiết thẻ"}
				classNameImg={"aspect16-9"}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				customImageStyle={{
					maxWidth: "100%",
				}}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>

			<OfferModal
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				getListTable={getListOffer}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>
			<ModalAntd
				title={"Chi tiết thẻ"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailInformation
					data={information.dataInfo}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>
		</>
	);
};

export default Offer;
