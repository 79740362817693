import * as React from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";

export default function DateRange({ date, setDate, collapse }) {
	let dateLocal = [new Date(date[0]), new Date(date[1])];

	if (!date[0] || !date[1]) {
		dateLocal = [null, null];
	}

	const [value, setValue] = React.useState(dateLocal);
	const handleSetDate = (date) => {
		setValue(date);
		const x = date[0]?.getTime();
		const y = date[1]?.getTime();

		const start = moment(new Date(x)).startOf("day").valueOf("x");
		const end = moment(new Date(y)).endOf("day").valueOf("x");
		if (x && y) {
			setDate([start, end]);
		}
	};

	React.useEffect(() => {
		if (!collapse) {
			setDate([null, null]);
		}
	}, [collapse]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateRangePicker
				value={value}
				startText={"Từ ngày"}
				endText={"Đến ngày"}
				inputFormat="dd/MM/yyyy"
				onChange={(newValue) => {
					handleSetDate(newValue);
				}}
				renderInput={(startProps, endProps) => (
					<React.Fragment>
						<TextField {...startProps} />
						<Box sx={{ mx: 2 }}> ~ </Box>
						<TextField {...endProps} />
					</React.Fragment>
				)}
			/>
		</LocalizationProvider>
	);
}
