import { Edit, InfoOutlined } from "@mui/icons-material";
import Text from "antd/lib/typography/Text";

import {
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Select as SelectAntd, Tooltip, Typography } from "antd";
import collabAPI from "api/CollabAPI";
import registerTestAPI from "api/RegisterTestAPI";
import { IMAGES } from "assets/images/imagePath";
import { CustomNoRowsOverlay } from "components/noRows";
import { DELETED_USER } from "constants/constant";
import { ROLE } from "constants/employeeRole";
import { statusUserVerifyTranslator } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import ImageModal from "ui-component/modal-form/ImageModal";
import UserVerifyModal from "ui-component/modal-form/UserVerifyModal";
import { showSortStyle } from "views/utilities/CustomStyle";
import { SearchComponent } from "../../components/search";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import { Markup } from "interweave";

const { Option } = SelectAntd;

const filterStatusList = [
	{
		value: "ALL",
		title: "Tất cả",
		color: "#000",
	},
	{
		value: "CONFIRMED",
		title: "Hoàn thành",
		color: "#00c853",
	},
	{
		value: "WAITING",
		title: "Chờ xử lý",
		color: "#ffc107",
	},
	{
		value: "BLOCKED",
		title: "Hủy bỏ",
		color: "#f44336",
	},
];

const searchList = [
	{
		value: "phone",
		title: "SĐT",
	},
	{
		value: "card_id",
		title: "CCCD/CMND",
	},
	{
		value: "fullname",
		title: "Họ và tên",
	},
];

const UserVerify = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { role } = useCustomAuth();

	const modalContext = useContext(ModalContext);

	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState([]);

	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const actionList = [
		{
			value: "update_status",
			title: "Chỉnh sửa trạng thái yêu cầu",
			component: Edit,
			visible: ROLE.CONFIRM_CUSTOMER_UPDATE === role?.CONFIRM_CUSTOMER_UPDATE,
		},
		{
			value: "full_info",
			title: "Chi tiết",
			component: InfoOutlined,
			visible: ROLE.CONFIRM_CUSTOMER_VIEW === role?.CONFIRM_CUSTOMER_VIEW,
		},
	];

	const [filterType, setFilterType] = useState(
		(location.state?.fromDashboard && location.state?.type) || "REQUEST_DEPOSIT"
	);
	const [filterStatus, setFilterStatus] = useState(
		(location.state?.fromDashboard && location.state?.status) || filterStatusList[0].value
	);
	const [statusModal, setStatusModal] = useState(false);
	const [data, setData] = useState("");
	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	const columns = [
		{
			field: "order",
			headerName: t(`order`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
		},
		{
			field: "username",
			headerName: "Người gửi yêu cầu",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={
							<p>
								{params?.row?.user?.username || DELETED_USER}
								{(params.row?.user?.name_admin_note && `(${params.row?.user?.name_admin_note})`) ||
									(params.row?.user?.fullname && `(${params.row?.user?.fullname})`)}
							</p>
						}
						overlayStyle={{
							maxWidth: "500px",
							textDecoration: params?.row?.user?.deleted_at ? "line-through" : "undefined",
						}}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								textDecoration: params?.row?.user?.deleted_at ? "line-through" : "undefined",
							}}
						>
							{params?.row?.user?.username || DELETED_USER}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee_confirm",
			headerName: "Nhân viên xác nhận",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.row?.employee?.fullname || params.row?.employee?.username}
						overlayStyle={{
							maxWidth: "500px",
							textDecoration: params?.row?.employee?.deleted_at ? "line-through" : "undefined",
						}}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								textDecoration: params?.row?.employee?.deleted_at ? "line-through" : "undefined",
							}}
						>
							{params.row?.employee?.fullname || params.row?.employee?.username}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "phone",
			headerName: "SĐT",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.value}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.value}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "fullname",
			headerName: "Họ và tên",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "card_id",
			headerName: "CCCD/CMND",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "address",
			headerName: "Địa chỉ",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "note",
			headerName: "Ghi chú",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "img_identification",
			headerName: "Hình CCCD/CMND",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",

			flex: 1,

			renderCell: (params) => (
				<Tooltip title={"Xem 2 mặt CCCD"} placement="right" zIndex="10000">
					<Box
						display={"flex"}
						gap={"10px"}
						width={"100%"}
						sx={{ cursor: "pointer" }}
						onClick={() =>
							setImageZoom({
								open: true,
								image: [params.row.front_identification, params.row.back_identification],
							})
						}
					>
						<img
							style={{
								objectFit: "cover",
								width: "50%",
								objectPosition: "center",
							}}
							src={params.row.front_identification || IMAGES.avatar_default}
							alt="avatar-img"
							onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
						/>

						<img
							style={{
								objectFit: "cover",
								width: "50%",
								objectPosition: "center",
							}}
							src={params.row.back_identification || IMAGES.avatar_default}
							alt="avatar-img"
							onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
						/>
					</Box>
				</Tooltip>
			),
		},

		{
			field: "collab_status",
			headerName: "Tình trạng",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				const { title, color } = statusUserVerifyTranslator(params.value);
				return (
					<Tooltip
						title={title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								color: color,
								fontWeight: 700,
							}}
						>
							{title}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian tạo",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(params.value, "x").format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "actions",
			headerName: t(`actions`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			hide: !actionList.some((item) => item.visible),
			renderCell: (params) => {
				return (
					<>
						{actionList?.map((item) => {
							const Icon = item.component;

							return (
								item.visible && (
									<Tooltip title={item.title}>
										<IconButton
											disabled={
												(item.value === "update_status" &&
													params.row.collab_status !== "WAITING") ||
												(item.value === "preview_pack" && params.row.collab_status !== "CONFIRMED")
											}
											onClick={() => onChangeAction(item.value, params.row)}
											sx={{
												display:
													item.value === "preview_pack" &&
													filterType === "REQUEST_WITHDRAW" &&
													"none",
											}}
										>
											<Icon />
										</IconButton>
									</Tooltip>
								)
							);
						})}
					</>
				);
			},
		},
	];
	const handleChangeAccept = async (e, params) => {
		let checked = e.target.checked;
		try {
			await registerTestAPI.update(params?.id, {
				is_checked: checked,
			});
			getListCollab();
		} catch (error) {}
	};
	const getListCollab = async () => {
		setIsLoading(true);
		let filter = `{}`;

		if (filterStatus === "ALL") {
			if (queryParams?.[searchBy]) {
				filter = `{"${searchBy}":{"$iLike":"%${searchValue.trim()}%"}}`;
			}
		} else {
			if (queryParams?.[searchBy]) {
				filter = `{"collab_status": "${filterStatus}" ,"${searchBy}":{"$iLike":"%${searchValue.trim()}%"}}`;
			} else {
				filter = `{"collab_status": "${filterStatus}"}`;
			}
		}

		let order = '[["created_at", "desc"]]';

		if (queryParams?.sortBy) {
			order = `[["${queryParams?.sortBy}", "${queryParams?.sort}"]]`;
		}
		try {
			const res = await collabAPI.getList({
				fields:
					'["$all", {"user": ["fullname", "username", "name_admin_note","deleted_at","$paranoid"]}, {"employee": ["fullname","username","deleted_at","$paranoid"]}]',
				order,
				filter,
				limit: pagination.limit,
				page: queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	const handleDelete = async (id) => {
		try {
			await registerTestAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			await getListCollab();
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenUpdateStatus = (value) => {
		setData(value);
		setStatusModal(true);
	};
	const [information, setInformation] = useState({
		visible: false,
	});
	const handleOpenInfo = (data) => {
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin xác thực",
				gridProps: {},
				children: [
					{
						id: "username",
						title: "Tài khoản",
						value: data?.user?.username,
						component: Text,
						childrenProps: {
							//all Props component here
							span: 8,
							style: {
								textDecoration: !!data?.user?.deleted_at ? "line-through" : "undefined",
							},
						},
					},
					{
						id: "fullname",
						title: "Tên người gửi",
						value: data?.user?.fullname,
						component: Text,
						childrenProps: {
							span: 8,
							style: {
								textDecoration: !!data?.user?.deleted_at ? "line-through" : "undefined",
							},
						},
					},
					{
						id: "employeee",
						title: "Nhân viên xác nhận",
						value: data?.employee?.fullname || data?.employee?.username || "Chưa xác nhận",
						component: Text,
						childrenProps: {
							span: 8,
							style: {
								textDecoration: !!data?.employee?.deleted_at ? "line-through" : "undefined",
							},
						},
					},
					{
						id: "phone",
						title: "SĐT yêu cầu xác thực",
						value: data?.phone,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "verifyFullname",
						title: "Họ và tên",
						value: data?.fullname,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "card_id",
						title: "CCCD/CMND",
						value: data?.card_id,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "address",
						title: "Địa chỉ",
						value: data?.card_id,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "updated_at",
						title: "Thời gian chỉnh sửa",
						value: moment.utc(data.updated_at).format("DD/MM/YYYY HH:mm"),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "created_at_unix_timestamp",
						title: "Thời gian tạo",
						value: moment(data.created_at_unix_timestamp, "x").format("DD/MM/YYYY HH:mm"),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "note",
						title: "Ghi chú",
						value: data?.note,
						component: Markup,
						childrenProps: {
							span: 24,
						},
					},
					{
						id: "front_identification",
						title: "Ảnh CMND",
						value: (() => (
							<div style={{ display: "flex", width: "100%", gap: "5px", flexDirection: "column" }}>
								<img src={data.front_identification} alt="card" style={{ width: "100%" }} />
								<img src={data.back_identification} alt="card" style={{ width: "100%" }} />
							</div>
						))(),
						component: Fragment,
						childrenProps: {
							span: 24,
						},
					},
				],
			},
		];

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
		}));
	};

	const onChangeAction = (value, data) => {
		value === "edit" && navigate(`/users/edit/${data.id}`);
		value === "delete" && handleOpenRemoveModal(data);
		value === "update_status" && handleOpenUpdateStatus(data);
		value === "full_info" && handleOpenInfo(data);
	};

	useEffect(() => {
		if (!role) return;

		if (role?.CONFIRM_CUSTOMER_VIEW !== ROLE.CONFIRM_CUSTOMER_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty(searchBy)) {
			setSearchBy(searchBy);
			setSearchValue(searchValue);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		getListCollab();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, filterType, filterStatus, role]);

	return (
		<>
			<MainCard>
				<HeaderActions title={"Danh sách yêu cầu"} onCreate={false} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					<Box mb={"16px"}>
						<FormControl sx={{ minWidth: 150, ml: 5 }}>
							<InputLabel id="demo-simple-select-outlined-label">{"Tình trạng"}</InputLabel>
							<Select
								defaultValue={filterStatusList[0].value}
								onChange={(e) => {
									setFilterStatus(e.target.value);
									handleChangePage(null, 1);
								}}
								labelId="demo-simple-select-outlined-label"
								label={"Tình trạng"}
								value={filterStatus}
							>
								{filterStatusList?.map((item) => (
									<MenuItem
										key={item.value}
										value={item.value}
										sx={{ color: statusUserVerifyTranslator(item.value)?.color, fontWeight: 700 }}
									>
										{statusUserVerifyTranslator(item.value)?.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>

					{/* <Button
						variant={selectList.length > 0 ? "contained" : "outlined"}
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							// checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
			<UserVerifyModal
				getListTable={getListCollab}
				data={data}
				open={statusModal}
				setOpen={(value) => setStatusModal(value)}
			/>
			<ModalAntd
				title={"Chi tiết"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>
			<ImageModal
				title={"Ảnh CCCD/CMND"}
				multi
				classNameImg="aspect16-9"
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>
		</>
	);
};

export default UserVerify;
