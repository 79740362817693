import { AccountCircle, Close, PersonOutlineOutlined } from "@mui/icons-material";
import { Box, Icon, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserModal from "ui-component/modal-form/UserModal";
import { actResetUser, actSetInfoCustomer } from "../../store/action";
import EmployeeModal from "ui-component/modal-form/employee-modal-create-update/index";

function EmployeeInfo({ user, resetUser, styleBox }) {
	const { orderList, infoCustomer } = useSelector((state) => state.createOrder);
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	return (
		<>
			<Stack
				p={1}
				onClick={() => setOpen(true)}
				sx={{
					borderRadius: "4px",
					border: "1px solid black",
					width: "40%",
					pointerEvents: "none",
					...styleBox,

					":hover": {
						opacity: 0.8,
						borderColor: "#311d4f",
						".custom__p": {
							opacity: "0.8",
						},
					},
				}}
				flexDirection="row"
				gap={1}
				alignItems={"center"}
				justifyContent="space-between"
			>
				<Stack
					sx={{
						cursor: "pointer",
						pointerEvents: "auto",
					}}
					flexDirection="row"
					gap={1}
					alignItems={"center"}
					flex={1}
				>
					<PersonOutlineOutlined />

					<Typography className="custom__p" variant="h5" mr={4}>
						{user
							? user.fullname || user.username
							: infoCustomer?.userInfo?.fullname || infoCustomer?.userInfo?.username}
					</Typography>
				</Stack>

				<Close
					sx={{ cursor: "pointer", pointerEvents: "auto" }}
					onClick={(e) => {
						e.stopPropagation();
						dispatch(actResetUser());
						resetUser && resetUser();
					}}
				/>
			</Stack>
			<EmployeeModal
				employee={user || infoCustomer.userInfo}
				isOpen={open}
				isUpdate={true}
				getListTable={() => {
					return;
				}}
				updateInfo={(value) => dispatch(actSetInfoCustomer(value))}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					return;
				}}
			/>
		</>
	);
}

export default EmployeeInfo;
