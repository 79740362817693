import { Delete } from "@mui/icons-material";
import { IconButton, Popover, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { InputNumber } from "antd";
import { formatVND, handleMaskValue } from "func";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CartPriceDiscount from "ui-component/CartPriceDiscount";
import InputNumberCustom from "ui-component/InputNumberCustom";
import { actDeleteOrder, actUpdateOrderList } from "../../store/action";
function CartItem({ StyledTableCell, row, idx }) {
	const dispatch = useDispatch();

	const handldeDeleteOrder = (data) => {
		dispatch(actDeleteOrder(data.id));
	};
	const handleSetValue = (data, val, name) => {
		dispatch(actUpdateOrderList({ data, val, name }));
	};
	const [priceDiscount, setPriceDiscount] = useState(row.deducted_price);
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const id = open ? `simple-popover-${row.id}` : undefined;
	const popoverRef = useRef(null);

	const handleClose = () => {
		document.removeEventListener("keypress", window.scannerFnc);
		setAnchorEl(null);
		document.addEventListener("keypress", window.scannerFnc);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		event.currentTarget.blur();
	};

	const onPopoverHide = (price, discountPrice, deductedPrice) => {
		setPriceDiscount(deductedPrice);
		handleSetValue(row, price, "price");
		handleSetValue(row, discountPrice, "sale_price");
		handleSetValue(row, deductedPrice, "deducted_price");
	};
	const [priceDeducted, setPriceDeducted] = useState(row.price);
	const [discountItem, setDiscountItem] = useState(row.sale_price);
	const [resultPrice, setResultPrice] = useState(row.deducted_price);
	return (
		<>
			<TableRow key={row.id}>
				<StyledTableCell align="center" scope="row">
					<IconButton onClick={() => handldeDeleteOrder(row)}>
						<Delete />
					</IconButton>
				</StyledTableCell>
				<StyledTableCell align="center" scope="row">
					{idx + 1}
				</StyledTableCell>
				<StyledTableCell component="th" scope="row" width={"300px"}>
					<Tooltip title={row.title}>
						<Typography variant="h5" className="text-ellipsis">
							{row.title}
						</Typography>
					</Tooltip>
					{row?.combo_service_item?.length > 0 && (
						<ul
							style={{
								listStyleType: "circle",
							}}
						>
							{row.combo_service_item.map((item) => (
								<li
									style={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
										width: "300px",
										listStyleType: "circle",
									}}
								>
									{item.amount} x {item.service.title}
								</li>
							))}
						</ul>
					)}
				</StyledTableCell>
				<TableCell>
					<InputNumberCustom
						val={row.count}
						handleSetValue={(value) => handleSetValue(row, value, "count")}
						min={1}
						max={row.inventory_amount_branch}
					/>
				</TableCell>

				{/* row.decuted_price là product - row.price là combo */}
				{/* <StyledTableCell align="center" scope="row">
										{formatVND(row.deducted_price || row.price)}
									</StyledTableCell> */}
				{Array.isArray(row?.combo_service_item) ? (
					<TableCell sx={{ textAlign: "center" }}>
						<InputNumber
							className={"custom__inputNum  center"}
							min={0}
							formatter={(value) => handleMaskValue(value)}
							parser={(value) => Number(value.toString().split(",").join(""))}
							style={{
								textAlign: "center",
								borderBottom: "1px solid #000",
							}}
							bordered={false}
							defaultValue={row.deducted_price}
							value={row.deducted_price}
							controls={false}
							onChange={(e) => {
								setPriceDiscount(e);
								handleSetValue(row, e, "deducted_price");
							}}
						/>
					</TableCell>
				) : (
					<TableCell onClick={handleClick} sx={{ textAlign: "center" }}>
						<InputNumber
							className={"custom__inputNum  center"}
							min={0}
							formatter={(value) => handleMaskValue(value)}
							parser={(value) => Number(value.toString().split(",").join(""))}
							style={{
								textAlign: "center",
								borderBottom: "1px solid #000",
								pointerEvents: "none",
							}}
							bordered={false}
							defaultValue={row.price}
							value={row.price}
							controls={false}
						/>
						{row.sale_price && row.sale_price > 0 ? (
							<Typography color="error">
								{" "}
								- {row.sale_price ? formatVND(row.sale_price) : ""}
							</Typography>
						) : (
							""
						)}
					</TableCell>
				)}
				<StyledTableCell align="center" scope="row" sx={{ width: "200px" }}>
					{formatVND(Number(priceDiscount) * row.count)}
				</StyledTableCell>
			</TableRow>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				ref={popoverRef}
				onClose={handleClose}
				onKeyPress={(e) => {
					if (e.code === "Enter" || e.keyCode === 13 || e.charCode === 13) {
						e.preventDefault();
						e.stopPropagation();
						handleClose();
					}
				}}
				anchorOrigin={{
					vertical: -40,
					horizontal: "left",
				}}
			>
				<CartPriceDiscount
					row={row}
					onPopoverHide={onPopoverHide}
					priceDeductedState={[priceDeducted, setPriceDeducted]}
					discountState={[discountItem, setDiscountItem]}
					resultState={[resultPrice, setResultPrice]}
				/>
			</Popover>
		</>
	);
}

export default CartItem;
