import {
	DELETE_ORDER,
	RESET,
	RESET_USER,
	SET_INFO_CUSTOMER,
	SET_ORDER_LIST,
	UPDATE_ORDER_LIST,
	UPDATE_ORDER_TYPE,
} from "./types";

export const actSetOrderList = (data) => ({
	type: SET_ORDER_LIST,
	payload: data,
});

export const actUpdateOrderList = ({ data, val, name }) => ({
	type: UPDATE_ORDER_LIST,
	payload: { data, val, name },
});

export const actUpdateOrderType = (dataType) => ({
	type: UPDATE_ORDER_TYPE,
	payload: dataType,
});

export const actDeleteOrder = (id) => ({
	type: DELETE_ORDER,
	payload: id,
});

export const actSetInfoCustomer = (user) => ({
	type: SET_INFO_CUSTOMER,
	payload: user,
});

export const actResetUser = () => ({
	type: RESET_USER,
});

export const actReset = () => ({
	type: RESET,
});
