import { bookingTree } from "./booking";
import { dashboardTree } from "./dashboard";
import { generalTree } from "./general";
import { linkTree } from "./link";
import { notiTree } from "./notification";
import { partnerTree } from "./partner";
import { productTree } from "./product";
import { roleTree } from "./role";
import { scanSkinTree } from "./scanskin";
import { settingTree } from "./setting";
import { transactionTree } from "./transaction";

export const rowData = [
	{
		id: "DASHBOARD",
		title: "Thống kê",
		treeData: dashboardTree,
	},
	{
		id: "BOOKING",
		title: "Lịch hẹn",
		treeData: bookingTree,
	},
	{
		id: "TRANSACTION",
		title: "Giao dịch",
		treeData: transactionTree,
	},
	{
		id: "PARTNER",
		title: "Đối tác",
		treeData: partnerTree,
	},
	{
		id: "GENERAL",
		title: "Quản lý",
		treeData: generalTree,
	},
	{
		id: "PRODUCT",
		title: "Hàng hóa",
		treeData: productTree,
	},
	{
		id: "LINK",
		title: "Liên kết",
		treeData: linkTree,
	},
	{
		id: "SCANSKIN",
		title: "Soi da",
		treeData: scanSkinTree,
	},
	{
		id: "NOTIFICATION",
		title: "Thông báo",
		treeData: notiTree,
	},
	{
		id: "SETTING",
		title: "Cài đặt",
		treeData: settingTree,
	},
	{
		id: "ROLE",
		title: "Phân quyền",
		treeData: roleTree,
	},
];
