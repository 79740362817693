import waitingRequestAPI from "api/WaitingRequestAPI";

import { GET_WAITING_REQUEST } from "./types";

export const actGetWaitingRequest = (
	branchActiveId,
	onSuccess = (success = false) => {},
	onError = (error = "") => {}
) => {
	const filter = {
		branch_id: branchActiveId,
	};
	const params = {
		fields: '["$all"]',
		filter: JSON.stringify(filter),
	};

	return async (dispatch) => {
		try {
			const res = await waitingRequestAPI.getList(params);

			dispatch({
				type: GET_WAITING_REQUEST,
				payload: res?.results.objects,
			});
			onSuccess(true);
		} catch (error) {
			onError(error?.message || "Lỗi");
		}
	};
};
// export const actGetWaitingRequest = (data) => ({
// 	type: GET_WAITING_REQUEST,
// 	payload: data,
// });
