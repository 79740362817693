import { Grid, Icon, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "antd";
import scanSkinAPI from "api/ScanSkinAPI";
import { IMAGES } from "assets/images/imagePath";
import { CustomNoRowsOverlay } from "components/noRows";
import _debounce from "lodash/debounce";
import moment from "moment";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import ImageModal from "ui-component/modal-form/ImageModal";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import { getNumberWithCommas } from "utils";
import { showSortStyle } from "views/utilities/CustomStyle";
import { SearchComponent } from "../../components/search";
import Text from "antd/lib/typography/Text";
import { PRODUCT_SCAN_SKIN_TYPE } from "constants/common";
import TableModal from "ui-component/TableModal";
import { columnTable } from "./data/dataTable";
import { getListGroupProduct } from "./func";
import { MoveToInbox } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import useCustomAuth from "hooks/useCustomAuth";
import { ROLE } from "constants/employeeRole";
import { ALERT_PERMISSION } from "constants/constant";

// import { useSnackbar } from "notistack";
// import { ModalContext } from "providers/ModalProvider";

// const { Option } = SelectAntd;

// const actionList = [
// 	{
// 		value: "edit",
// 		title: "edit",
// 	},
// 	{
// 		value: "delete",
// 		title: "delete",
// 	},
// ];

const searchList = [
	{
		value: "$user.username$",
		title: "phone_number",
	},
	// {
	// 	value: "$user.name_admin_note$",
	// 	title: "Tên thân mật",
	// },
];

const GroupScanSkinProduct = () => {
	// const { enqueueSnackbar } = useSnackbar();
	// const modalContext = useContext(ModalContext);

	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState(PRODUCT_SCAN_SKIN_TYPE);

	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	// const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});
	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });
	const [information, setInformation] = useState({
		visible: false,
	});
	const handleOpenInforModal = (data) => {
		//	Example DATA

		const dataNew = data?.data?.map((item, idx) => {
			const children = item?.data?.map((childData) => ({
				id: childData.key,
				title: childData.valueVI,
				value: "",
				component: Text,
				childrenProps: {
					span: 12,
				},
			}));
			return {
				groupId: idx,
				groupTitle: item.title?.vi,
				gridProps: {},
				children: children,
			};
		});
		const dataParams = dataNew;

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
		}));
	};
	const columns = [
		// {
		// 	field: "value",
		// 	headerName: "Mã nhóm",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// },

		{
			field: "text",
			headerName: "Tên nhóm",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
			// flex: 1,
		},
		{
			field: "specialResult",
			headerName: "Sản phẩm trong nhóm",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={"Xem các sản phẩm trong nhóm "}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Link
							style={{
								color: "black",
							}}
							to="/product"
							state={{
								group: params.row?.value,
								isFromGroup: true,
							}}
						>
							<Box
								sx={{
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									cursor: "pointer",
									display: "flex",
									alignItems: "center",
									gap: 2,
								}}
							>
								<MoveToInbox />
								Xem các sản phẩm trong nhóm
							</Box>
						</Link>
					</Tooltip>
				);
			},
		},

		// {
		// 	field: "actions",
		// 	headerName: t(`actions`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	minWidth: 180,
		// 	renderCell: (params) => (
		// 		<>
		// 			<SelectCustom
		// 				value={t(`select_actions`)}
		// 				onChange={(value) => onChangeAction(value, params.id)}
		// 				style={{ width: 150 }}
		// 			>
		// 				{actionList?.map((item) => (
		// 					<Option key={item?.value}>{t(`${item.title}`)}</Option>
		// 				))}
		// 			</SelectCustom>
		// 		</>
		// 	),
		// },
	];

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};

	// const handleOpenRemoveModal = (id) => {
	// 	modalContext.showDeleteConfirm(id, () => handleDelete(id));
	// };

	// const handleDelete = async (id) => {
	// 	try {
	// 		await transactionAPI.remove(id);

	// 		enqueueSnackbar(t(`success`), {
	// 			variant: "success",
	// 			autoHideDuration: 2000,
	// 		});
	// 		getListScanHis();
	// 	} catch (error) {
	// 		enqueueSnackbar(error?.message || t(`error`), {
	// 			variant: "error",
	// 			autoHideDuration: 2000,
	// 		});
	// 	}
	// };

	// const onChangeAction = (value, id) => {
	// 	// value === "edit" && navigate(`/users/edit/${id}`);
	// 	value === "delete" && handleOpenRemoveModal(id);
	// };

	// const handleRemoveMulti = () => {
	// 	modalContext.showDeleteConfirm(
	// 		selectList,
	// 		async () => {
	// 			try {
	// 				await transactionAPI.removeMulti({
	// 					items: JSON.stringify(selectList),
	// 				});
	// 				enqueueSnackbar(t(`success`), {
	// 					variant: "success",
	// 					autoHideDuration: 2000,
	// 				});
	// 				getListScanHis();
	// 			} catch (error) {
	// 				enqueueSnackbar(error?.message || t(`error`), {
	// 					variant: "error",
	// 					autoHideDuration: 2000,
	// 				});
	// 			}
	// 		},
	// 		t("do_you_want_to_delete_selected_items"),
	// 		t(`confirm`)
	// 	);
	// };
	const { enqueueSnackbar } = useSnackbar();

	const { role } = useCustomAuth();

	useEffect(() => {
		if (!role) return;
		if (role?.SCAN_SKIN !== ROLE?.SCAN_SKIN) {
			navigate("/not-permission", { replace: true });

			return;
		}

		if (queryParams.hasOwnProperty("username")) {
			setSearchBy("username");
			setSearchValue(queryParams?.username);
		}
		if (queryParams.hasOwnProperty("email")) {
			setSearchBy("email");
			setSearchValue(queryParams?.email);
		}
		if (queryParams.hasOwnProperty("phone_number")) {
			setSearchBy("phone_number");
			setSearchValue(queryParams?.phone_number);
		}
		if (queryParams.hasOwnProperty("type")) {
			setSearchBy("type");
			setSearchValue(queryParams?.type);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams,role]);

	return (
		<>
			<MainCard>
				<HeaderActions title={"Sản phẩm gợi ý"} onCreate={false} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					{/* <Button
						variant="outlined"
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							// checkboxSelection
							// onSelectionModelChange={(ids) => {
							// 	setSelectList(ids);
							// }}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
			<ModalAntd
				title={"Sản phẩm trong nhóm"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<TableModal columnProps={columnTable} getListTable={getListGroupProduct} />
			</ModalAntd>

			<ImageModal
				title={"Ảnh"}
				classNameImg="aspect1-1"
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>
		</>
	);
};

export default GroupScanSkinProduct;
