import { productTranslator } from "func";

export const typeList = [
	{
		value: "PRODUCT",
	},
	{
		value: "GIFT",
	},
];

export const fieldsCanUpdate = [
	"name",
	"description",
	"thumbnail",
	"images",
	"expiry",
	"discount",
	"minimum_price",
	"amount",
	"voucher_detail",
	"max_discount",
];
