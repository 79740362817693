import { LoadingButton } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

function AccountDeleteConfirm({ account, deleteUser, handleDelete }) {
	const [err, setErr] = useState({});
	const [initialVal, setInitValue] = useState({});
	const isValid = useRef(true);
	const mount = useRef(true);

	const handleSetErr = () => {
		if (initialVal.delete_user !== account) {
			isValid.current = false;
			setErr({
				...err,
				delete_user: {
					message:
						"Số điện thoại này không đúng với số điện thoại bạn đã chọn, vui lòng kiểm tra lại",
				},
			});
		} else {
			isValid.current = true;
			setErr({});
		}
	};
	const handleOnChange = (e) => {
		setInitValue({
			...initialVal,
			[e.target.name]: e.target.value,
		});
	};
	useEffect(() => {
		if (mount.current) {
			mount.current = false;
			return;
		}
		handleSetErr();
	}, [initialVal]);

	return (
		<Box py={2}>
			<TextField
				onChange={(e) => handleOnChange(e)}
				name="delete_user"
				id="outlined-basic"
				sx={{
					mb: 2,

					"& .MuiInputLabel-root, fieldset": {
						fontSize: "16px",
					},
					"& .MuiList-root": {
						maxHeight: "250px",
						overflow: "auto",
					},
				}}
				fullWidth
				label={`Vui lòng nhập ${account} để xác nhận xóa`}
				variant="outlined"
			/>

			{err.delete_user && (
				<Typography p={0.5} variant="h6" color={"error"} fontSize={"14px"}>
					{err.delete_user.message}
				</Typography>
			)}

			<Box textAlign={"right"}>
				<LoadingButton
					sx={{
						ml: "auto",
					}}
					onClick={() => handleDelete(initialVal.delete_user)}
					variant="contained"
				>
					Xóa
				</LoadingButton>
			</Box>
		</Box>
	);
}

export default AccountDeleteConfirm;
