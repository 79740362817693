import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function CustomerInfo({ customer }) {
	return (
		<Box spacing={1}>
			<Typography variant="h5" component={"span"} mt={1}>
				Tên khách hàng:{" "}
			</Typography>
			<Typography variant="h5" component={"span"} mt={1}>
				{customer?.name || null}
			</Typography>
			<br />
			<Typography variant="h5" component={"span"} mt={1}>
				Địa chỉ:{" "}
			</Typography>
			<Typography variant="h5" component={"span"} mt={1}>
				{customer?.address ? `${customer?.address}` : ""}
				{customer?.district ? `, ${customer?.district}` : ""}
				{customer?.ward ? `, ${customer?.ward}` : ""}
				{customer?.city ? `, ${customer?.city}` : ""}
			</Typography>
			<br />
			{/* <Typography variant="h5" component={"span"} mt={1}>
				Khu vực:{" "}
			</Typography>
			<Typography variant="h5" component={"span"} mt={1}>
				{customer?.area || null}
			</Typography> */}
			{/* <br /> */}
			<Typography variant="h5" component={"span"} mt={1}>
				Số điện thoại:{" "}
			</Typography>
			<Typography variant="h5" component={"span"} mt={1}>
				{customer?.phone || null}
			</Typography>
		</Box>
	);
}

export default CustomerInfo;
