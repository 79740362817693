import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { memo, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import notificationAPI from "api/NotificationAPI";
import { convertDatePicker } from "../../convert";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import koLocale from "date-fns/locale/ko";
import { NOTIFICATION_TYPE, TARGET_USER } from "constants/notification";
import { notificationTranslator } from "func";
import postAPI from "api/PostAPI";
import SearchWithAPI from "views/pages/CreateOrder/components/OrderSearchComponent";
import useDebounce from "hooks/useDebounce";
import ResultsComponent from "components/SearchWithAPI/ResultsComponent";
import { Close } from "@mui/icons-material";

const TextValidation = styled("span")({
	color: "red",
});

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const localeMap = {
	en: enLocale,
	kr: koLocale,
};

function FormDialog(props) {
	const { isOpen, isClose, isLoading, data, isUpdate } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { register, handleSubmit, errors } = useForm({
		mode: "onChange",
	});
	const id_notification = props.data.id;

	//data đang thiếu fullname, phone, email (ở list không hiển thị các thông tin đó )
	const [loading, setLoading] = useState(false);
	const [frequency, setFrequency] = useState("ONE_TIME");
	const [typeNotification, setTypeNotification] = useState("GENERAL");
	const [targetUser, setTargetUser] = useState("ALL");
	const [sending_unix_timestamp, setSending_unix_timestamp] = useState(data.sending_unix_timestamp);
	const [showErr, setShowErr] = useState({ status: false, message: "" });

	const [locale, setLocale] = useState("kr");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [postId, setPostId] = useState(undefined);

	useEffect(() => {
		if (isOpen) {
			setFrequency(data.frequency);
			setTypeNotification(data.type_notification);
			setTargetUser(data.target_user);
			setValueSearch(data?.post?.title || "");
			setPostId(data?.post_id || undefined);
		}

		if (!isOpen) {
			setFrequency("ONE_TIME");
			setTypeNotification("GENERAL");
			setTargetUser("ALL");
			setValueSearch("");
			setPostId(undefined);
		}
	}, [isOpen]);

	const handleClosSnackbare = () => {
		setOpenSnackbar(false);
	};

	const tam1 = moment(data.sending_unix_timestamp, "x").format("YYYY-MM-DD HH:mm");
	const arrTam2 = tam1.split(" ");
	const tam3 = arrTam2[0] + "T" + arrTam2[1];
	const handleChange = (event) => {
		setFrequency(event.target.value);
	};

	const handleChangeTime = async (event) => {
		const arrTime = event.target.value?.split("T");
		const temp = await convertDatePicker(arrTime[0], arrTime[1]);
		setSending_unix_timestamp(temp);
	};

	const onSubmit = async (data) => {
		if (frequency) data.frequency = frequency;
		data.type_notification = "GENERAL";
		if (targetUser) data.target_user = targetUser;

		if (sending_unix_timestamp) {
			const now = moment().valueOf();
			if (sending_unix_timestamp < now) return setOpenSnackbar(true);
			else {
				data.sending_unix_timestamp = sending_unix_timestamp;
				setOpenSnackbar(false);
			}
		}
		try {
			setLoading(true);

			if (!isUpdate) {
				data.post_id = postId;

				const response = await notificationAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar("Cập nhật thành công", {
						variant: "success",
						autoHideDuration: 2000,
					});
				}
			} else {
				data.post_id = postId === undefined ? null : postId;

				const response = await notificationAPI.update(data, id_notification);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar("Tạo thành công", {
						variant: "success",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			enqueueSnackbar(error?.code || error, {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);
		}
	};

	const handleClose = () => {
		isClose(false);
	};

	const [loadingSearch, setLoadingSearch] = useState(false);
	const isClickChoose = useRef(false);

	const fetchPost = async (val, customParams = {}) => {
		setLoadingSearch(true);
		isClickChoose.current = true;
		const filter = {
			title:
				val.trim().length === 0
					? undefined
					: {
							$iLike: `%${val.trim()}%`,
					  },
			category_post_id: "fe59b0b0-6f24-11ed-a399-75e5a5f0126d",
		};
		const params = {
			fields: '["$all"]',
			filter: JSON.stringify(filter),
			...customParams,
		};
		try {
			const res = await postAPI.getList(params);
			setSearchResults(res.results?.objects?.rows);

			setLoadingSearch(false);
		} catch (error) {
			setLoadingSearch(false);
		}
	};

	const [valueSearch, setValueSearch] = React.useState("");
	const debounceVal = useDebounce(valueSearch);

	const [searchResults, setSearchResults] = useState([]);

	React.useEffect(() => {
		if (debounceVal.trim().length === 0 || isClickChoose.current) {
			// setSearchResults([]);
			fetchPost(debounceVal, { limit: 5, page: 1, order: '[["created_at", "desc"]]' });

			isClickChoose.current = false;
			return;
		} else {
			fetchPost(debounceVal);
		}
	}, [debounceVal]);

	const searchRef = useRef(null);

	return (
		<div>
			<Dialog fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle style={{ fontSize: "20px" }} variant={"h3"}>
					{isUpdate ? "Cập nhật thông báo" : "Thêm thông báo"}
				</DialogTitle>
				<form>
					<DialogContent>
						{/* ============================================ */}

						<Grid item xs={12}>
							<TextField
								variant="standard"
								sx={{ width: 550 }}
								id="outlined-helperText"
								name="message_title"
								label={"Tiêu đề thông báo"}
								type="text"
								inputRef={register({
									required: "Tiêu đề không được trống",
									validate: {
										stringSpace: (v) => !v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
									},
								})}
								defaultValue={isUpdate ? data.message_title : ""}
							/>
							{errors.message_title && (
								<TextValidation>{errors.message_title.message}</TextValidation>
							)}
						</Grid>
						<br />
						<Grid item xs={12}>
							<TextField
								variant="standard"
								sx={{ width: 550 }}
								name="message_content"
								label="Nội dung thông báo"
								multiline
								rows={4}
								type="text"
								inputRef={register({
									required: "Nội dung không được trống",
									validate: {
										stringSpace: (v) => !v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
									},
								})}
								defaultValue={isUpdate ? data.message_content : ""}
							/>
							{errors.message_content && (
								<TextValidation>{errors.message_content.message}</TextValidation>
							)}
						</Grid>
						<Grid item xs={12} sx={{ my: 1 }}>
							<InputLabel id="demo-simple-select-autowidth-label">Chọn bài viết</InputLabel>

							{postId ? (
								<Box
									sx={{
										borderBottom: "1px solid black",
										display: "flex",
										justifyContent: "space-between",
									}}
									my={2}
								>
									<Box>{valueSearch}</Box>
									<Box
										onClick={() => {
											setValueSearch("");
											setPostId(undefined);
										}}
									>
										<Close />
									</Box>
								</Box>
							) : (
								<SearchWithAPI
									ref={searchRef}
									searchValue={valueSearch}
									handleChangeSearch={(value, e) => {
										setValueSearch(value);
									}}
									styleSearchInput={{
										borderTop: 0,
										borderRight: 0,
										borderLeft: 0,
										borderRadius: 0,
									}}
									customVis={true}
									placeholderText={"Tìm theo tên bài viết"}
									searchResult={searchResults}
									ResultComponent={ResultsComponent}
									noDataText={"Không tìm thấy bài viết"}
									loading={loadingSearch}
									// isFocusOutSide={isClickChoose.current}
									propsResultComponent={(item) => ({
										item,
										onClick: () => {
											setValueSearch(item.title);
											isClickChoose.current = true;
											setPostId(item.id);
										},
									})}
								/>
							)}
						</Grid>
						<br />
						<Grid item xs={12}>
							<FormControl variant="standard" sx={{ width: 550, marginLeft: "-1px" }}>
								<InputLabel id="demo-simple-select-autowidth-label">{"Tần suất"}</InputLabel>
								<Select
									labelId="demo-simple-select-autowidth-label"
									id="demo-simple-select-autowidth"
									value={frequency}
									onChange={handleChange}
									// autoWidth
									label="Tần suất"
									name="frequency"
								>
									<MenuItem value={"ONE_TIME"}>{"1 Lần"}</MenuItem>
									<MenuItem value={"REGULAR_EVERY_7_DAYS"}>{"7 ngày"}</MenuItem>
									<MenuItem value={"REGULAR_EVERY_30_DAYS"}>{"30 ngày"}</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<br />
						{/* <Grid item xs={12}>
							<FormControl variant="standard" sx={{ width: 550, marginLeft: "-1px" }}>
								<InputLabel id="demo-simple-select-autowidth-label">{"Loại thông báo"}</InputLabel>
								<Select
									labelId="demo-simple-select-autowidth-label"
									id="demo-simple-select-autowidth"
									defaultValue={isUpdate ? data.type_notification : "GENERAL"}
									onChange={(event) => setTypeNotification(event.target.value)}
									// autoWidth
									label="Loại thông báo"
									name="frequency"
								>
									{NOTIFICATION_TYPE.map((item) => (
										<MenuItem value={item.value}>{notificationTranslator(item.value)}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid> */}
						<br />
						<Grid item xs={12}>
							<FormControl variant="standard" sx={{ width: 550, marginLeft: "-1px" }}>
								<InputLabel id="demo-simple-select-autowidth-label">{"Loại người dùng"}</InputLabel>
								<Select
									labelId="demo-simple-select-autowidth-label"
									id="demo-simple-select-autowidth"
									defaultValue={isUpdate ? data.target_user : "ALL"}
									onChange={(event) => setTargetUser(event.target.value)}
									// autoWidth
									label="Loại người dùng"
									name="frequency"
								>
									{TARGET_USER.map((item) => (
										<MenuItem value={item.value}>
											{notificationTranslator(null, item.value)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<br />

						<br />
						{isUpdate ? (
							<div>
								<TextField
									name="datetime"
									id="datetime-local"
									label="Thời gian gửi"
									type="datetime-local"
									FormatTime
									defaultValue={tam3}
									onChange={handleChangeTime}
									variant="standard"
									sx={{ width: 550 }}
									InputLabelProps={{
										shrink: true,
									}}
									inputRef={register({ required: true })}
								/>
								{errors.datetime && <TextValidation>{"Thời gian không được trống"}</TextValidation>}
								<br />
							</div>
						) : (
							<div>
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									utils={DateFnsUtils}
									locale={localeMap[locale]}
								>
									<TextField
										name="datetime"
										id="datetime-local"
										label="Thời gian gửi"
										type="datetime-local"
										locale="fr-en"
										FormatTime
										defaultValue=""
										onChange={handleChangeTime}
										variant="standard"
										sx={{ width: 550 }}
										InputLabelProps={{
											shrink: true,
										}}
										inputRef={register({ required: true })}
									/>
								</LocalizationProvider>

								{errors.datetime && <TextValidation>{"Thời gian không được trống"}</TextValidation>}
								<br />
							</div>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => isClose(false)} variant="contained" color="secondary">
							{"Hủy bỏ"}
						</Button>
						<LoadingButton
							onClick={handleSubmit(onSubmit)}
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="contained"
						>
							{isUpdate ? "Cập nhật" : "Tạo"}
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openSnackbar}
				autoHideDuration={6000}
			>
				<Alert
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => handleClosSnackbare()}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					severity="error"
					sx={{ width: "100%" }}
				>
					{"Bạn không thể chọn ngày trong quá khứ"}
				</Alert>
			</Snackbar>
		</div>
	);
}

export default memo(FormDialog);
