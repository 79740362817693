import { AddCircleOutline, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	FormControl,
	Icon,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import unitAPI from "api/UnitAPI";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { actGetListUnit, actSelectUnit } from "store/actions/UnitAction";
import DialogMain from "ui-component/modal-form/DialogMui";

function UnitSelect({
	isUpdate,
	formData,
	nameField = "unit_id",
	labelId,
	label,
	openParentModal,
}) {
	const { unitList } = useSelector((state) => state.unitReducer);
	const dispatch = useDispatch();
	useEffect(() => {
		if (unitList.length === 0) {
			dispatch(actGetListUnit());
		}
		if (isUpdate) {
			const unitFind = unitList.find((it) => it.id === formData?.[nameField]);
			const unitId = formData?.[nameField] ? formData?.[nameField] : "none";
			setSelectText(unitFind?.value || "Không chọn");
			dispatch(actSelectUnit(unitId));
		}
		return () => {
			dispatch(actSelectUnit("none"));
		};
	}, []);

	const [openModal, setOpenModal] = useState(false);
	const handleOpenModalCreateUnit = () => {
		setOpenModal(true);
	};

	const {
		register,
		handleSubmit,
		errors,
		reset,
		setValue: setValueHookForm,

		getValues,
		clearErrors,

		watch,
	} = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
	});

	const [lading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [selectText, setSelectText] = useState("Không chọn");

	const onSubmit = async (data, e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const res = await unitAPI.create(data);
			enqueueSnackbar("Thêm đơn vị thành công", {
				autoHideDuration: 2000,
				variant: "success",
			});
			dispatch(actGetListUnit());
			setLoading(false);
			setOpenModal(false);
		} catch (err) {
			enqueueSnackbar(err?.message || "Lỗi", {
				autoHideDuration: 2000,
				variant: "error",
			});
			setLoading(false);
		}
	};

	const handleDeleteUnit = async (id) => {
		try {
			const res = await unitAPI.remove(id);
			enqueueSnackbar("Xóa đơn vị thành công", {
				autoHideDuration: 2000,
				variant: "success",
			});
			dispatch(actGetListUnit());

			setLoading(false);
		} catch (err) {
			enqueueSnackbar(err?.message || "Lỗi", {
				autoHideDuration: 2000,
				variant: "error",
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!isUpdate) {
			dispatch(actSelectUnit("none"));

			setSelectText("Không chọn");
		}
	}, [unitList]);

	const modalContext = useContext(ModalContext);

	const handleConfirmDeleteModal = (id) => {
		modalContext.showDeleteConfirm(
			id,
			() => handleDeleteUnit(id),
			"Bạn có chắc chắn muốn xóa đơn vị này",
			"Chắc chắn"
		);
	};

	const handleSelectUnit = (e) => {
		dispatch(actSelectUnit(e.target.value));
	};

	return (
		<>
			<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
				<InputLabel id={`label-${labelId}`}>{label}</InputLabel>
				<Select
					labelId={`label-${labelId}`}
					defaultValue={
						isUpdate ? (formData?.[nameField] ? formData?.[nameField] : "none") : "none"
					}
					label={label}
					onChange={(e) => {
						handleSelectUnit(e);
					}}
					renderValue={(selected) => {
						return selectText;
					}}
				>
					<MenuItem onClick={() => setSelectText("Không chọn")} value={"none"}>
						Không chọn
					</MenuItem>
					{Array.isArray(unitList) &&
						unitList.map((item) => (
							<MenuItem
								sx={{ position: "relative", display: "flex", justifyContent: "space-between" }}
								key={item.id}
								value={item.id}
								onClick={() => setSelectText(item.value)}
							>
								{item.value}
								<Close
									fontSize="12"
									sx={{
										zIndex: 100000,
										cursor: "pointer",
										":hover": {
											background: "#f6f6f6",
											width: "14px",
											height: "14px",
											borderRadius: "50%",
										},
									}}
									onClick={(e) => {
										e.stopPropagation();
										handleConfirmDeleteModal(item.id);
									}}
								/>
							</MenuItem>
						))}
				</Select>
				<Tooltip title={"Thêm đơn vị"}>
					<AddCircleOutline
						onClick={handleOpenModalCreateUnit}
						fontSize="12px"
						sx={{
							position: "absolute",
							right: "20px",
							top: "calc(50%)",
							zIndex: "10000px",
							cursor: "pointer",
						}}
					/>
				</Tooltip>
			</FormControl>
			<DialogMain
				maxWidth="sm"
				title={"Thêm đơn vị mới"}
				fullWidth
				open={openModal}
				onClose={() => {
					setOpenModal(false);
				}}
			>
				<form>
					<TextField
						margin="dense"
						name="value"
						label={"Đơn vị"}
						type="text"
						fullWidth
						variant="standard"
						inputRef={register({
							validate: {
								stringSpace: (v) => !v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
							},
							required: {
								value: true,
								message: "Vui lòng điền đơn vị",
							},
						})}
					/>
					{errors.value && <Typography color="error">{errors.value.message}</Typography>}

					<Stack flexDirection={"row"} justifyContent="flex-end" mt={2}>
						<Button
							type="button"
							onClick={() => setOpenModal(false)}
							variant={"outlined"}
							sx={{
								mr: 1,
							}}
						>
							Hủy
						</Button>
						<LoadingButton
							type="submit"
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							loading={lading}
						>
							Tạo
						</LoadingButton>
					</Stack>
				</form>
			</DialogMain>
		</>
	);
}

export default UnitSelect;
