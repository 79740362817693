import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Tab, Tabs } from "@mui/material";
import serviceAPI from "api/ServiceAPI";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actSetCheckList } from "store/actions/BookingActions/action";

function ProductArea({ control, ...props }) {
	const tabListDefault = [
		{
			id: null,
			title: "Tất cả",
		},
	];
	const [tabList, setTabList] = useState([]);
	const [activeTab, setActiveTab] = useState(0); //Important
	//categoryId set Filter

	const [categoryId, setCategoryId] = useState(null);
	const [serviceList, setServiceList] = useState([]);

	const handleChange = (e, newValue) => {
		setActiveTab(newValue);
	};
	const handleChangeId = (id) => {
		setCategoryId(id);
	};
	const fetchCategory = async () => {
		try {
			const res = await serviceAPI.getCategory({
				fields: '["$all"]',
			});
			setTabList([...tabListDefault, ...res.results.objects.rows]);
		} catch (err) {
			console.log(err);
		}
	};
	const fetchServiceList = async () => {
		try {
			const res = await serviceAPI.getList({
				fields: '["$all"]',
				filter: categoryId
					? JSON.stringify({
							category_service_id: categoryId,
					  })
					: null,
			});
			setServiceList(res.results.objects.rows);
		} catch (err) {
			console.log(err);
		}
	};
	//Logic check to push
	const dispatch = useDispatch();
	const checkList = useSelector((state) => state.booking.checkBoxList);
	const handleCheck = (e, service) => {
		let checked = e.target.checked;

		dispatch(actSetCheckList({ checked, service }));

		// if (e.target.checked) {
		// 	dispatch(actSetCheckList([...checkList, service]));
		// } else dispatch(actSetCheckList(checkList.filter((item) => item.id !== service.id)));
	};
	const selectedServices = useSelector((state) => state.booking.checkBoxList);

	useEffect(() => {
		fetchCategory();
		fetchServiceList();
		return () => {
			dispatch(actSetCheckList({ checked: "reset", service: [] }));
		};
	}, []);

	const [force, setForce] = useState(0);
	useEffect(() => {
		//Bắt buộc component re-render
		setForce(force + 1);
	}, [selectedServices]);
	useEffect(() => {
		fetchServiceList();
	}, [categoryId]);
	return (
		<Box {...props}>
			<Tabs
				value={activeTab}
				onChange={handleChange}
				variant="scrollable"
				scrollButtons
				allowScrollButtonsMobile
				aria-label="scrollable force tabs example"
			>
				{Array.isArray(tabList) &&
					tabList.map((tab) => (
						<Tab key={tab.id} label={tab.title} onClick={() => handleChangeId(tab.id)} />
					))}
			</Tabs>
			<Box
				sx={{
					maxHeight: "300px",
					overflow: "auto",
				}}
			>
				<FormControl required component="fieldset" sx={{ m: 3 }} variant="standard">
					<FormGroup>
						{serviceList.map((service, idx) => (
							<FormControlLabel
								key={service.id}
								control={
									<Checkbox
										onChange={(e) => handleCheck(e, service)}
										checked={checkList.some((item) => item.service?.id === service.id)}
									/>
								}
								label={service.title}
							/>
						))}
					</FormGroup>
				</FormControl>
			</Box>
		</Box>
	);
}

export default ProductArea;
