import { Close } from "@mui/icons-material";
import {
	Box,
	Chip,
	Divider,
	IconButton,
	Input,
	Paper,
	Popover,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { InputNumber, Tag } from "antd";
import { formatVND } from "func";
import React, { useEffect, useRef, useState } from "react";
import { batch, useDispatch } from "react-redux";
import { actSetCheckList, actUpdateValueCheckList } from "store/actions/BookingActions/action";
import InputNumberCustom from "ui-component/InputNumberCustom";

import classnames from "classnames/bind";
import styles from "../BookingModal.module.scss";
import CartPriceDiscount from "ui-component/CartPriceDiscount";
import { getNumberWithCommas, getPriceWithCommas } from "utils";

const cx = classnames.bind(styles);

function SelectedItem({ service: combo_service, isCombo, isUpdate = false }) {
	console.log(combo_service);
	const dispatch = useDispatch();
	const handleDeleteService = (service) => {
		dispatch(actSetCheckList({ checked: false, service }));
	};
	const handleSetValue = (data, val, name) => {
		dispatch(actUpdateValueCheckList({ data, val, name }));
	};
	const [priceDiscount, setPriceDiscount] = useState(
		isUpdate ? combo_service.service.price_after_discount : combo_service.service.price_deducted
	);
	const rx_live = /^\d*$/;
	const handleMaskValue = (value) => {
		const temp = value.split(",").join("");
		if (rx_live.test(temp)) {
			return temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return temp;
		}
	};
	useEffect(() => {
		if (isUpdate) {
			handleSetValue(combo_service, combo_service.service.price_after_discount, "price");
			handleSetValue(combo_service, combo_service.service.note_admin, "note_admin");
		}
	}, []);

	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const id = open ? `simple-popover-${combo_service.id}` : undefined;
	const popoverRef = useRef(null);

	const handleClose = () => {
		document.removeEventListener("keypress", window.scannerFnc);
		setAnchorEl(null);
		document.addEventListener("keypress", window.scannerFnc);
	};

	const onPopoverHide = (price, discountPrice, deductedPrice) => {
		setPriceDiscount(deductedPrice);
		handleSetValue(combo_service, price, "price");
		handleSetValue(combo_service, discountPrice, "sale_price");
		handleSetValue(combo_service, deductedPrice, "deducted_price");
	};

	const [priceDeducted, setPriceDeducted] = useState(+combo_service.service.price);
	const [discountItem, setDiscountItem] = useState(+combo_service.service.discount);
	const [resultPrice, setResultPrice] = useState(+combo_service.service.price_deducted);
	const hanldeOpenDiscount = (event) => {
		setAnchorEl(event.currentTarget);
		event.currentTarget.blur();
	};

	//service hiện tại đang là combo_service, nếu tích chọn không sử dụng combo sẽ tạo ra một combo_service ảo với amount : 0 , count 1 , id random
	return (
		<>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					margin: "15px 0px",
				}}
			>
				<Typography
					sx={{
						flex: 2,
					}}
				>
					<span>{combo_service.service?.title}</span>
					{isCombo && <span> x{combo_service.count}</span>}
				</Typography>

				{isCombo ? (
					<Tag color={"purple"}>{combo_service.combo_title}</Tag>
				) : (
					<>
						<InputNumberCustom
							val={combo_service.count}
							handleSetValue={(value) => handleSetValue(combo_service, value, "count")}
							min={1}
							styleItem={{
								flex: 1,
								width: "100%",
							}}
						/>
						<Typography
							sx={{
								flex: 1,
								textAlign: "center",
							}}
						>
							<Box onClick={(e) => hanldeOpenDiscount(e)}>
								<InputNumber
									className={cx("custom__inputNum", "center")}
									style={{ width: "100%", pointerEvents: "none" }}
									min={0}
									formatter={(value) => handleMaskValue(value)}
									parser={(value) => parseInt(value.toString().split(",").join(""))}
									defaultValue={
										isUpdate
											? combo_service.service.price_after_discount
											: combo_service.service.price_deducted
									}
									controls={false}
									value={priceDeducted}
									onChange={(e) => {
										setPriceDiscount(e);
										handleSetValue(combo_service, e, "price");
									}}
								/>
							</Box>
							{!!+discountItem && (
								<Typography color={"error"}>-{getNumberWithCommas(discountItem)}</Typography>
							)}
						</Typography>
						<Tooltip title={formatVND(resultPrice * combo_service.count)}>
							<Typography
								sx={{
									flex: 1,
									textAlign: "center",
									overflow: "hidden",
									textOverflow: "ellipsis",
									width: "100%",
									ml: 1,
								}}
							>
								{formatVND(resultPrice * combo_service.count)}
							</Typography>
						</Tooltip>

						<Typography
							sx={{
								flex: 1,
								textAlign: "center",
							}}
						>
							<Input
								sx={{ textAlign: "center", width: "90%" }}
								placeholder="Thêm ghi chú"
								name="note_admin"
								size="small"
								defaultValue={combo_service.service?.note_admin}
								onChange={(e) => handleSetValue(combo_service, e.target.value, e.target.name)}
							/>
						</Typography>
						<Box sx={{ flex: 0.5, textAlign: "center" }}>
							<IconButton
								sx={{ width: "fit-content" }}
								onClick={() => handleDeleteService(combo_service.service)}
							>
								<Close />
							</IconButton>
						</Box>
					</>
				)}
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				ref={popoverRef}
				onClose={handleClose}
				onKeyPress={(e) => {
					if (e.code === "Enter" || e.keyCode === 13 || e.charCode === 13) {
						e.preventDefault();
						e.stopPropagation();
						handleClose();
					}
				}}
				anchorOrigin={{
					vertical: -40,
					horizontal: "left",
				}}
			>
				<CartPriceDiscount
					row={combo_service.service}
					onPopoverHide={onPopoverHide}
					priceDeductedState={[priceDeducted, setPriceDeducted]}
					discountState={[discountItem, setDiscountItem]}
					resultState={[resultPrice, setResultPrice]}
				/>
			</Popover>
		</>
	);
}

export default SelectedItem;
