import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { IconCheckbox } from "@tabler/icons";
import { Image } from "antd";
import bookingAPI from "api/BookingAPI";
import { activityTranslator, formatVND, paymentTypeOrderTranslator } from "func";
import { LoadingContext } from "providers/LoadingProvider";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import moment from "moment";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import { Link } from "react-router-dom";

function BookingDetail() {
	const { bookingId } = useParams();

	const { showLoading, hideLoading } = useContext(LoadingContext);

	const [dataInfo, setDataInfo] = useState([]);

	const fetchBookingById = async () => {
		const fields = [
			"$all",
			{ user: ["$all", "$paranoid", "deleted_at"] },
			{ booking_consultants: ["$all", { employee: ["$all"] }] },
			{ employee_sales: ["$all", { employee: ["$all"] }] },

			{
				booking_details: [
					"$all",
					{ service: ["$all", { unit: ["$all"] }] },
					{ order_item: ["$all", { combo_using: ["$all"] }] },
				],
			},
			{ booking_activities: ["$all"] },
			{ voucher: ["$all"] },
			{ employee_confirm: ["fullname", "username", "deleted_at", "$paranoid"] },
		];
		showLoading();
		try {
			const res = await bookingAPI.findOne(bookingId, {
				fields: JSON.stringify(fields),
			});

			const data = res?.results?.object;
			const dataParams = [
				{
					groupId: 1,
					groupTitle: "Thông tin khách hàng",
					gridProps: {},
					children: [
						{
							id: "name",

							title: "Tên khách hàng",
							value: data.name,

							childrenProps: {
								//all Props component here
								span: 8,
							},
						},
						{
							id: "phone",
							title: "Số điện thoại",
							value: data.phone,

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "name_admin_note",
							title: "Tên thân mật",
							value: data.name_admin_note,

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "email",
							title: "Email",
							value: data.email,

							childrenProps: {
								span: 8,
							},
						},
					],
				},
				{
					groupId: 2,
					groupTitle: "Thông tin dịch vụ",
					gridProps: {},
					children: [
						{
							id: "qrCode",
							isHide: false,
							title: "QrCode",
							value: "",
							component: Image,
							childrenProps: {
								span: 24,
								preview: false,
								src: `${data.qr_code_image}`,
								//all Props component here
							},
						},
						{
							id: "serviceList",
							title: "Danh sách dịch vụ được đặt",
							value: (() => {
								return (
									<ul>
										{data.booking_details.map((item) => (
											<li>
												<Link to={`/service-detail/${item.service?.id}`} target="_blank">
													{item.title}
												</Link>{" "}
												x {item.count} lần
												{item.combo_service_id && (
													<Chip
														sx={{ marginLeft: "5px" }}
														color="primary"
														label={"Combo"}
														size={"small"}
														component={"span"}
													/>
												)}{" "}
												- {formatVND(+item.price_after_discount)}{" "}
												{item.note_admin && (
													<Tooltip
														title={item.note_admin}
														overlayStyle={{ maxWidth: "500px" }}
														placement="right"
														zIndex="10000"
													>
														<Box
															sx={{
																display: "inline",
																overflow: "hidden",
																whiteSpace: "nowrap",
																textOverflow: "ellipsis",
															}}
														>
															- <span style={{ fontWeight: "bold" }}>Ghi chú: </span>{" "}
															{item.note_admin}
														</Box>
													</Tooltip>
												)}
											</li>
										))}
									</ul>
								);
							})(),
							component: Box,
							childrenProps: {
								span: 24,
							},
						},
						{
							id: "employeeList",
							title: "Danh sách nhân viên",
							value: (() => {
								return data.booking_consultants.length !== 0 ? (
									<ul>
										{data.booking_consultants.map((item) => (
											<li>
												{item.employee.fullname} - {item.work} công
											</li>
										))}
									</ul>
								) : (
									<Typography>Không có dữ liệu</Typography>
								);
							})(),
							component: Box,
							childrenProps: {
								span: 24,
							},
						},
						{
							id: "activity",
							title: "Trạng thái hiện tại",
							value: activityTranslator(data.activity).title,

							childrenProps: {
								span: 8,
								//all Props component here
							},
						},
						{
							id: "booking_code",
							title: "Mã lịch đặt",
							value: data.booking_code,

							childrenProps: {
								span: 8,
							},
						},

						{
							id: "booking_type",
							title: "Phương thức thanh toán",
							value: paymentTypeOrderTranslator(data.booking_type),

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "totalCost",
							title: "Tổng tiền dịch vụ",
							value: formatVND(Number(data.total_price) + Number(data?.discount)),

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "discount",
							title: "Tổng giảm",
							value: formatVND(Number(data.discount)),

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "totalPrice",
							title: "Tổng tiền thanh toán",
							value: formatVND(data.total_price),

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "voucher",
							title: "Mã giảm giá",
							value: data.voucher?.code,

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "time_start",
							title: "Ngày giờ hẹn",
							value: moment(data.time_start, "x").format("DD/MM/YYYY HH:mm"),

							childrenProps: {
								span: 8,
							},
						},
						{
							id: "is_paid",
							title: "Xác nhận đã nhận tiền",
							component: data.is_paid && IconCheckbox,
							childrenProps: {
								span: 8,
								color: "green",
							},
						},

						// {
						// 	id: "is_sent_notification",
						// 	title: "Gửi thông báo đến khách hàng",
						// 	component: data.is_sent_notification && IconCheckbox,

						// 	childrenProps: {
						// 		span: 8,
						// 		color: "green",
						// 	},
						// },
					],
				},
				{
					groupId: 3,
					groupTitle: "Thông tin khác",
					children: [
						{
							id: "message",
							title: "Yêu cầu đặc biệt",
							value: data.message,

							childrenProps: {
								span: 24,
							},
						},
						{
							id: "reason",
							title: "Lý do hủy lịch",
							value: data.reason,

							childrenProps: {
								span: 24,
							},
						},
					],
				},
			];

			setDataInfo(dataParams);
			hideLoading();
		} catch (error) {
			console.log(error);
			hideLoading();
		}
	};

	useEffect(() => {
		fetchBookingById();
	}, []);

	return (
		<MainCard>
			<DetailInformation data={dataInfo} />
		</MainCard>
	);
}

export default BookingDetail;
