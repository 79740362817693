import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormLabel, Grid, MenuItem, Select, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { InputNumber, Select as SelectAntd } from "antd";
import offerAPI from "api/OfferAPI";
import uploadAPI from "api/UploadAPI";
import classnames from "classnames/bind";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UploadImage from "ui-component/UploadImage";
import styles from "./OfferModal.module.scss";

import { handleMaskValue, handleParseMask } from "func";

const cx = classnames.bind(styles);

const { Option } = SelectAntd;
const defaultTypeList = [
	{
		value: "CLASSIC",
		title: "CLASSIC",
	},
	{
		value: "SILVER",
		title: "SILVER",
	},
	{
		value: "GOLD",
		title: "GOLD",
	},
	{
		value: "PLATINUM",
		title: "PLATINUM",
	},
	{
		value: "DIAMOND",
		title: "DIAMOND",
	},
	{
		value: "SIGNATURE",
		title: "SIGNATURE",
	},
];

const TextValidation = styled("span")({
	color: "red",
});
const Required = styled("span")({
	color: "red",
});
// const schema = yup.object().shape({
// 	from: yup
// 		.number({ message: "Trường này bắt buộc là số" })
// 		.required("Trường này không được bỏ trống"),
// });

function OfferModal({ ...props }) {
	const { isOpen, isClose, isLoading, formData, isUpdate, listSelect, getListTable } = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));

	const { register, handleSubmit, errors, control, reset, getValues, watch, setValue } = useForm({
		defaultValues: isUpdate && formData,
		mode: "onChange",
		reValidateMode: "onChange",

		// resolver: yupResolver(schema),
	});
	const selectAfter = (
		<Controller
			name="time_type"
			defaultValue={isUpdate ? formData?.time_type : "DAY"}
			control={control}
			as={
				<SelectAntd
					defaultValue="DAY"
					style={{
						width: 150,
						"&:focus": {
							color: "black",
						},
					}}
				>
					<Option value="DAY">ngày</Option>
					<Option value="WEEK">tuần</Option>
					<Option value="MONTH">tháng</Option>
					<Option value="YEAR">năm</Option>
				</SelectAntd>
			}
		/>
	);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [type, setType] = useState("NOTIFICATION");
	const [editor, setEditor] = useState(null);

	const [image, setImage] = useState(formData?.image || "");

	const handleClose = () => {
		isClose(false);
	};
	const onSubmit = async (data) => {
		let imgLink = "";
		if (typeof image === "string") {
			imgLink = image;
		} else if (image) {
			const imageData = new FormData();
			imageData.append("image", image);
			try {
				const res = await uploadAPI.uploadImage(imageData);
				imgLink = res.results.object.url;
			} catch (err) {
				console.log(err);
				return;
			}
		}
		try {
			setLoading(true);
			if (!isUpdate) {
				data.from = Number(data.from.split(",").join(""));
				data.offer = Number(data.offer.split(",").join(""));
				data.image = imgLink || "";
				data.description = ckData || "";
				const response = await offerAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			} else {
				data.offer = Number(data.offer.toString().split(",").join(""));
				data.from = Number(data.from.toString().split(",").join(""));
				data.description = ckData || "";
				data.image = imgLink || "";

				const response = await offerAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};
	useEffect(() => {
		if (isUpdate) {
			setCkData(formData?.description || "");
			setImage(formData.image);
		}
		return () => {
			reset();
			setCkData("");
			setImage("");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const translator = (text) => {
		return `abeauty.offer.dialog_form.${text}`;
	};
	return (
		<div
			style={{
				position: "relative",
			}}
		>
			<Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle
					sx={{
						fontSize: "18px",
						textAlign: "center",
					}}
				>
					{isUpdate ? t(translator("header_edit")) : t(translator("header_create"))}
				</DialogTitle>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogContent>
						<Grid
							container
							spacing={1}
							style={{
								marginBottom: 20,
							}}
						>
							<Grid item xs={12}>
								<FormLabel component="legend">{t(translator("title"))}*</FormLabel>

								<Controller
									name="title"
									control={control}
									rules={{
										required: "Vui lòng điền giá trị",
										validate: {
											startWithSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu với khoảng trắng",
										},
									}}
									defaultValue={isUpdate ? formData.title : ""}
									as={
										<TextField
											hiddenLabel
											margin="dense"
											type="text"
											fullWidth
											size="small"
											variant="filled"
										/>
									}
								/>

								{errors.title && <TextValidation>{errors.title.message}</TextValidation>}
							</Grid>
							<Grid item xs={12}>
								<FormLabel component="legend">{t(translator("from"))}*</FormLabel>

								<Controller
									render={({ onChange, value }) => (
										<TextField
											onChange={onChange}
											value={handleMaskValue(value)}
											hiddenLabel
											margin="dense"
											size="small"
											type="text"
											fullWidth
											variant="filled"
										/>
									)}
									defaultValue={isUpdate ? formData?.from : ""}
									name="from"
									control={control}
									rules={{
										required: {
											message: "Vui lòng điền giá trị",
											value: true,
										},
										// pattern: {
										// 	message: "Giá trị này phải là một số",
										// 	value: /^(0|[1-9][0-9]*|[1-9][0-9]{0,2}(,[0-9]{3,3})*)$/,
										// },
										validate: {
											NaN: (value) => {
												let pattern = /^(0|[1-9][0-9]*|[1-9][0-9]{0,2}(,[0-9]{3,3})*)$/;
												return pattern.test(handleParseMask(value)) || "Giá trị này phải là một số";
											},
											lessThan: (value) => {
												return (
													parseInt(handleParseMask(value)) >= 1000000 ||
													"Mã phải có giá trị thấp nhât là 1.000.000"
												);
											},

											// you can do asynchronous validation as well
										},
										// min: {
										// 	message: "Mã có giá trị thấp nhất là 10.000.000",
										// 	value: 10000000,
										// },
									}}
								/>
								{errors.from && <TextValidation>{errors.from.message}</TextValidation>}
							</Grid>
							<Grid item xs={12}>
								<FormLabel component="legend">{t(translator("offer"))}*</FormLabel>

								<Controller
									render={({ onChange, value }) => (
										<TextField
											onChange={onChange}
											value={handleMaskValue(value)}
											hiddenLabel
											margin="dense"
											type="text"
											size="small"
											fullWidth
											variant="filled"
										/>
									)}
									defaultValue={isUpdate ? formData?.offer : ""}
									name="offer"
									control={control}
									rules={{
										required: {
											value: true,
											message: "Ưu đãi không được để trống",
										},
										validate: {
											NaN: (value) => {
												let pattern = /^(0|[1-9][0-9]*|[1-9][0-9]{0,2}(,[0-9]{3,3})*)$/;
												return pattern.test(handleParseMask(value)) || "Giá trị này phải là một số";
											},
											moreThan: (value) => {
												return (
													parseInt(handleParseMask(value)) >= 100 || "Ưu đãi tối thiểu là 100(%)"
												);
											},
											lessThan: (value) => {
												return (
													parseInt(handleParseMask(value)) <= 1000 || "Ưu đãi tối đa là 1000(%)"
												);
											},

											// you can do asynchronous validation as well
										},
										// min: {
										// 	message: "Ưu đãi tối thiểu là 100(%)",
										// 	value: 100,
										// },
										// max: {
										// 	message: "Ưu đãi tối đa là 1000(%)",
										// 	value: 1000,
										// },
										// pattern: {
										// 	value: /^(0|[1-9][0-9]*|[1-9][0-9]{0,2}(,[0-9]{3,3})*)$/,

										// 	message: "Giá trị này phải là một số",
										// },
									}}
								/>
								{errors.offer && <TextValidation>{errors.offer.message}</TextValidation>}
							</Grid>

							<Grid item xs={6}>
								<FormLabel component="legend">{t(translator("medal"))}</FormLabel>

								<Controller
									defaultValue={isUpdate ? formData?.medal : defaultTypeList[0].value}
									as={
										<Select
											margin="dense"
											label={t(translator("medal"))}
											type="text"
											fullWidth
											size="small"
											hiddenLabel
											variant="filled"
										>
											{defaultTypeList.map((item) => (
												<MenuItem key={item.value} value={item.value}>
													{item.title}
												</MenuItem>
											))}
										</Select>
									}
									name="medal"
									control={control}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									mb: "20px",
									flexDirection: "column",
									overflow: "hidden",
								}}
							>
								<FormLabel component="legend">Thời hạn sử dụng</FormLabel>

								<Controller
									defaultValue={isUpdate ? formData?.expired : 0}
									name="expired"
									control={control}
									as={
										<InputNumber
											size="large"
											bordered={false}
											style={{
												width: "100%",
												borderBottom: "1px solid black",
												backgroundColor: "rgba(0, 0, 0, 0.06)",
											}}
											addonAfter={selectAfter}
											controls={false}
										/>
									}
								/>
							</Grid>

							<Grid
								item
								xs={12}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									mb: "20px",
									flexDirection: "column",
								}}
							>
								<FormLabel component="legend">Hình ảnh trang chi tiết</FormLabel>

								<UploadImage
									image={image}
									setImage={(file) => {
										return setImage(file, 1);
									}}
									aspectRatio="16:9"
									levelAspectRatio={3}
									id={1}
								>
									<ul>
										<li>Định dạng file: [.JPEG, .JPG, .PNG]</li>
										<li>Tỉ lệ hình ảnh: 1029 × 480</li>
									</ul>
								</UploadImage>
							</Grid>

							<Grid item xs={12}>
								<FormLabel component="legend">Mô tả thẻ</FormLabel>

								<Controller
									name="detail"
									control={control}
									// rules={{
									// 	required: "Vui lòng điền giá trị",
									// 	validate: {
									// 		startWithSpace: (v) =>
									// 			!v.startsWith(" ") || "Không thể bắt đầu với khoảng trắng",
									// 	},
									// }}
									defaultValue={isUpdate ? formData.detail : ""}
									as={
										<TextField
											hiddenLabel
											margin="dense"
											type="text"
											fullWidth
											size="small"
											multiline
											variant="filled"
											minRows={4}
										/>
									}
								/>

								{errors.detail && <TextValidation>{errors.detail.message}</TextValidation>}
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									mb: "20px",
									flexDirection: "column",
									overflow: "hidden",
								}}
							>
								<FormLabel component="legend">Mô tả chi tiết</FormLabel>

								<CKEditor
									onReady={(editor) => {
										// Insert the toolbar before the editable area.
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										setEditor(editor);
									}}
									onError={({ willEditorRestart }) => {
										// If the editor is restarted, the toolbar element will be created once again.
										// The `onReady` callback will be called again and the new toolbar will be added.
										// This is why you need to remove the older toolbar.
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setCkData(data);
									}}
									editor={DecoupledEditor}
									data={ckData}
									config={{
										ckfinder: {
											uploadUrl: `https://hitek-02.hitek.com.vn:6061/api/v1/image/upload_ckeditor`,
										},

										mediaEmbed: {
											previewsInData: true,
											elementName: "iframe",
										},
									}}
								/>
							</Grid>

							{/* <Grid item xs={6}>
								<FormLabel component="legend">{t(translator("is_active"))}</FormLabel>
								<Switch
									name="is_active"
									inputRef={register()}
									defaultChecked={isUpdate ? formData.is_active : false}
								/>
							</Grid> */}
						</Grid>
					</DialogContent>
					<DialogActions
						sx={{
							justifyContent: "center",
						}}
					>
						<LoadingButton
							type="submit"
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="contained"
							className={cx("button__action")}
						>
							{isUpdate ? t(translator("update")) : t(translator("create"))}
						</LoadingButton>
					</DialogActions>

					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showErr.state}
						onClose={() => setShowErr({ state: false })}
						// action={action}
						autoHideDuration={5000}
						severity="error"
					>
						<Alert severity="error">{showErr.message}</Alert>
					</Snackbar>
				</form>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default OfferModal;
