import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { authentication } from "./firebase-config";

authentication.languageCode = "it";
export const generateRecaptcha = () => {
	window.recaptchaVerifier = new RecaptchaVerifier(
		"recaptcha-button",
		{
			size: "invisible",

			callback: (response) => {
				// reCAPTCHA solved, allow signInWithPhoneNumber.
			},
		},
		authentication
	);
};
