import { GET_LIST_BRANCH, SELECT_BRANCH } from "store/actions/BranchAction/types";
import { GET_WAITING_REQUEST } from "store/actions/WaitingRequestActions/types";

const initialState = {
	waitingRequest: {
		count_booking: 0,
		count_confirm_edit_wallet: 0,
		count_collab: 0,
		count_transaction: 0,
		count_order: 0,
	},
};

export const WaitingRequestReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_WAITING_REQUEST: {
			return {
				...state,
				waitingRequest: payload,
			};
		}

		default:
			return state;
	}
};
