import { useSelector } from "react-redux";

function useCustomAuth() {
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);
	const role = useSelector((state) => state.employee?.role);
	const settingCMS = {
		auto_print: !!employeeInfo?.auto_export,
	};
	return { settingCMS, employeeInfo, role };
}

export default useCustomAuth;
