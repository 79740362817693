import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { gridSpacing } from "store/constant";
import { getNumberWithCommas } from "utils";
import VBeautySmallCard from "./VBeautySmallCard";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import {
	AttachMoney,
	CreditCard,
	CreditScore,
	PriceChange,
	HourglassBottom,
	HourglassTop,
	RequestQuote,
	LocalAtm,
} from "@mui/icons-material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { formatCash } from "func";

function StaticCard({ dashboardData, filterProps, data2 }) {
	const responsiveGrid = {
		lg: 3,
		md: 6,
		sm: 6,
		xs: 12,
	};
	return (
		<Box
			sx={{
				background: "#fff",
				padding: "24px",
				borderRadius: "4px",

				margin: "24px 0",
			}}
		>
			<Box sx={{ display: "flex", justifyContent: "space-between", margin: "0 20px 20px 20px" }}>
				<Typography variant={"h3"}>Thống kê</Typography>
			</Box>
			<Grid container columns={12} spacing={4}>
				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Lịch hẹn"}
						propsCountUp={{
							start: 0,
							end: dashboardData?.amount_booking,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgba(115,103,240,.12)",
						}}
						icon={<CreateOutlinedIcon style={{ color: "#7367f0", fontSize: "25px" }} />}
						propsNavigate={{
							to: "/booking-scheduler",
							tooltipTitle: "Đến trang lịch hẹn",
						}}
					/>
				</Grid>

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Doanh thu từ lịch hẹn"}
						propsCountUp={{
							start: 0,
							end: dashboardData?.amount_revenue_from_booking,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						propsNavigate={{
							to: "/booking",
							tooltipTitle: "Đến trang lịch hẹn",
							props: {
								state: {
									activity: "COMPLETED",
									fromDashboard: true,
									...filterProps,
								},
							},
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgba(234,84,85,.12)",
						}}
						icon={<AttachMoney style={{ color: "#ea5455", fontSize: "25px" }} />}
					/>
				</Grid>

				{/* <Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Lịch hẹn đã hoàn thành"}
						propsCountUp={{
							start: 0,
							end: dashboardData?.amount_booking_completed,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgba(255, 180, 0, 0.12)",
						}}
						propsNavigate={{
							to: "/booking",
							tooltipTitle: "Đến trang lịch hẹn",
							props: {
								state: {
									activity: "COMPLETED",
									fromDashboard: true,
									...filterProps,
								},
								replace: false,
							},
						}}
						icon={<FactCheck style={{ color: "#FFB400", fontSize: "25px" }} />}
					/>
				</Grid> */}

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Khách hàng"}
						propsCountUp={{
							start: 0,
							end: dashboardData?.amount_user,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						propsNavigate={{
							to: "/users",
							props: {
								state: {
									fromDashboard: true,
									...filterProps,
								},
							},
							tooltipTitle: "Đến trang khách hàng",
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgba(0,207,232,.12)",
						}}
						icon={<AccountCircleOutlinedIcon style={{ color: "#00cfe8", fontSize: "25px" }} />}
					/>
				</Grid>

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Tổng số tiền xuất hàng nội bộ"}
						propsNavigate={{
							to: "/order",
							tooltipTitle: "Đến trang đơn hàng",
							// props: {
							// 	state: {
							// 		status: "PENDING",
							// 		type: "REQUEST_DEPOSIT",
							// 		fromDashboard: true,
							// 	},
							// 	replace: false,
							// },
						}}
						propsCountUp={{
							start: 0,
							end: data2?.totalOrderInternal,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgb(130, 170, 227, .12)",
						}}
						icon={<RequestQuote style={{ color: "#82AAE3", fontSize: "25px" }} />}
					/>
				</Grid>

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Số lượng thẻ đã bán"}
						propsCountUp={{
							start: 0,
							end: dashboardData?.amount_pack_sell,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						propsNavigate={{
							to: "/qrcode",
							tooltipTitle: "Đến trang thẻ hạn mức dịch vụ",
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgba(141, 141, 170, .12)",
						}}
						icon={<CreditScore style={{ color: "#8D8DAA", fontSize: "25px" }} />}
					/>
				</Grid>

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Doanh thu từ thẻ"}
						propsCountUp={{
							start: 0,
							end: dashboardData?.amount_revenue_from_pack,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						propsNavigate={{
							to: "/transaction",
							tooltipTitle: "Đến trang giao dịch",
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgba(40,199,111,.12)",
						}}
						icon={<CreditCard style={{ color: "#28c76f", fontSize: "25px" }} />}
					/>
				</Grid>

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Yêu cầu rút tiền đang chờ xử lý"}
						propsCountUp={{
							start: 0,
							end: dashboardData?.total_request_withdraw,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						propsNavigate={{
							to: "/recharge-withdraw",
							tooltipTitle: "Đến trang yêu cầu nạp/rút",
							props: {
								state: {
									status: "PENDING",
									type: "REQUEST_WITHDRAW",
									fromDashboard: true,
								},
								replace: false,
							},
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgba(153, 0, 0, .12)",
						}}
						icon={<HourglassTop style={{ color: "#990000", fontSize: "25px" }} />}
					/>
				</Grid>

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Doanh thu từ yêu cầu nạp tiền"}
						propsCountUp={{
							start: 0,
							end: dashboardData?.revenue_request_deposit,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						propsNavigate={{
							to: "/recharge-withdraw",
							tooltipTitle: "Đến trang yêu cầu nạp/rút",

							props: {
								state: {
									status: "DONE",
									type: "REQUEST_DEPOSIT",
									fromDashboard: true,
									...filterProps,
								},
								replace: false,
							},
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgba(255, 180, 0, 0.12)",
						}}
						icon={<PriceChange style={{ color: "#ffb400", fontSize: "25px" }} />}
					/>
				</Grid>

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Yêu cầu nạp tiền đang chờ xử lý"}
						propsNavigate={{
							to: "/recharge-withdraw",
							tooltipTitle: "Đến trang yêu cầu nạp/rút",
							props: {
								state: {
									status: "PENDING",
									type: "REQUEST_DEPOSIT",
									fromDashboard: true,
									...filterProps,
								},
								replace: false,
							},
						}}
						propsCountUp={{
							start: 0,
							end: dashboardData?.total_request_deposit,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgb(246, 117, 168, .12)",
						}}
						icon={<HourglassBottom style={{ color: "#F675A8", fontSize: "25px" }} />}
					/>
				</Grid>

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Tổng số tiền rút tiền hoa hồng"}
						propsNavigate={{
							to: "/recharge-withdraw",
							tooltipTitle: "Đến trang rút tiền",
							props: {
								state: {
									status: "DONE",
									type: "REQUEST_WITHDRAW",
									fromDashboard: true,
									...filterProps,
								},
								replace: false,
							},
						}}
						propsCountUp={{
							start: 0,
							end: data2?.totalWithDraw,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgb(111, 105, 172, .12)",
						}}
						icon={<LocalAtm style={{ color: "#6F69AC", fontSize: "25px" }} />}
					/>
				</Grid>

				<Grid item {...responsiveGrid}>
					<VBeautySmallCard
						title={"Tổng số dư nợ"}
						propsCountUp={{
							start: 0,
							end: data2?.totalDebt,
							suffix: "",
							formattingFn: (value) => {
								return getNumberWithCommas(value);
							},
						}}
						customIcon={{
							height: "48px",
							width: "48px",
							background: "rgb(130, 170, 227, .12)",
						}}
						icon={<AttachMoney style={{ color: "#03cee8", fontSize: "25px" }} />}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

export default StaticCard;
