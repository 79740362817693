import {
	DELETE_EMPLOYEE,
	RESET_EMPLOYEE_BOOKING,
	SELECT_EMPLOYEE,
	UPDATE_EMPLOYEE_BOOKING,
	UPDATE_VALUE_EMPLOYEE,
} from "store/actions/EmployeeBooking/type";

const initialState = {
	employeeList: [],
};

const EmployeeBookingReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SELECT_EMPLOYEE: {
			const employeeListUpdate = [...state.employeeList];
			const initData = {
				id: payload.id,
				employee: payload,
				work: 1,
			};
			if (employeeListUpdate.every((employee) => employee.id !== payload.id)) {
				employeeListUpdate.push(initData);
			}

			return { ...state, employeeList: employeeListUpdate };
		}
		case UPDATE_EMPLOYEE_BOOKING: {
			//payload is booking_consultants
			const employeeListUpdate = [...state.employeeList];
			payload.map((item) => {
				const { employee } = item;
				let initData = {
					id: employee.id,
					employee: employee,
					work: item.work,
				};
				employeeListUpdate.push(initData);
			});

			return { ...state, employeeList: employeeListUpdate };
		}

		case DELETE_EMPLOYEE: {
			let employeeListUpdate = [...state.employeeList];
			employeeListUpdate = employeeListUpdate.filter((employee) => employee.id !== payload.id);
			return { ...state, employeeList: employeeListUpdate };
		}

		case UPDATE_VALUE_EMPLOYEE: {
			let employeeListUpdate = [...state.employeeList];

			const { data, val, name } = payload;
			//INCREASE COUNT
			employeeListUpdate = employeeListUpdate.map((item) => {
				if (item.id === data.id) {
					return {
						...item,
						[name]: val,
					};
				} else {
					return item;
				}
			});
			return { ...state, employeeList: employeeListUpdate };
		}

		case RESET_EMPLOYEE_BOOKING: {
			return initialState;
		}

		default:
			return state;
	}
};
export default EmployeeBookingReducer;
