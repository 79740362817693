import { LocalFireDepartment } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, IconButton, Pagination, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Select, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import branchAPI from "api/branchAPI";
import productAPI from "api/ProductAPI";
import { CustomNoRowsOverlay } from "components/noRows";
import PageSizeSelect from "components/PrintTemplate/components/PageSizeSelect";
import ProductQr from "components/PrintTemplate/ProductQr";
import { ROLE } from "constants/employeeRole";
import { formatVND } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import { Markup } from "interweave";
import _debounce from "lodash/debounce";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { actGetListBranch } from "store/actions/BranchAction";
import { gridSpacing } from "store/constant";
import styled from "styled-components";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import ImageModal from "ui-component/modal-form/ImageModal";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import ProductModal from "ui-component/modal-form/ProductModal";
import { SearchComponent } from "../../components/search";
const { Option } = Select;

const searchList = [
	{
		value: "name",
		title: "Tên chi nhánh",
	},

	// {
	// 	value: "price",
	// 	title: "Giá dịch vụ",
	// },
];

const TextValidation = styled("p")({
	color: "red",
});

const Branch = () => {
	const location = useLocation();

	const stateLoc = location?.state;

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { role } = useCustomAuth();
	const { enqueueSnackbar } = useSnackbar();
	const actionList = [
		{
			value: "edit",
			title: "Cập nhật",
			visible: ROLE.BRANCH_UPDATE === role?.BRANCH_UPDATE,
			icon: <EditIcon />,
			onClick: (params) => {
				handleUpdate(params);
			},
		},
		{
			value: "delete",
			title: "Xóa",
			visible: ROLE.BRANCH_DELETE === role?.BRANCH_DELETE,
			icon: <DeleteIcon />,
			onClick: (params) => handleOpenRemoveModal(params.id),
		},
	];
	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [searchBy, setSearchBy] = useState(searchList[0].value);
	const [searchValue, setSearchValue] = useState("");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const [openModalImage, setOpenModalImage] = useState(false);
	const [printQr, setPrintQr] = useState({
		image: "",
		productName: "",
		priceProduct: 0,
		data,
	});

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate(
			{
				pathname: location.pathname,
			},
			{
				state: stateLoc,
			}
		);
	};

	const handleChangeAccept = async (checked, id, isWithClient) => {
		try {
			await productAPI.update(
				id,

				!isWithClient
					? {
							is_scan_skin: checked,
					  }
					: {
							is_confirm_with_client: checked,
					  }
			);
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListBranch();
		} catch (error) {
			enqueueSnackbar(error?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate(
			{
				pathname: location.pathname,
				search: `?${queryString.stringify(filters)}`,
			},
			{
				state: stateLoc,
			}
		);
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	//Print Func
	const handleUpdate = (params) => {
		setIsUpdate(true);
		setInformation({
			visible: true,
			data: params.row,
		});
	};
	// Table
	const onChangeAction = (value, id, params) => {
		value === "edit" && handleUpdate(params);
		value === "delete" && handleOpenRemoveModal(id, params.row);
		value === "information" && handleOpenInforModal(params.row);
		value === "print_barcode" && handleOpenPrintModal(params.row, "BARCODE");
	};

	const columns = [
		{
			field: "name",
			headerName: "Tên chi nhánh",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 3,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "location",
			headerName: "Địa chỉ",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 3,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value || "Chưa có dữ liệu"}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "is_main",
			headerName: "Loại",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 3,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value ? "Chi nhánh chính" : "Chi nhánh phụ"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value ? "Chi nhánh chính" : "Chi nhánh phụ"}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "actions",
			headerName: t(`actions`),
			flex: 1.5,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			hide: !actionList.some((item) => item.visible),

			renderCell: (params) => (
				<Stack flexDirection={"row"}>
					{actionList.map(
						(item) =>
							item.visible && (
								<Tooltip title={item.title}>
									<IconButton aria-label={item.value} onClick={() => item.onClick(params)}>
										{item.icon}
									</IconButton>
								</Tooltip>
							)
					)}
				</Stack>
			),
		},
	];

	const [information, setInformation] = useState({
		visible: false,
	});
	const [printData, setPrintData] = useState({
		visible: false,
		data: {},
	});
	const handleOpenInforModal = (data) => {
		//	Example DATA
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin sản phẩm",
				gridProps: {},
				children: [
					{
						id: "title",

						title: "Tên sản phẩm",
						value: data.title,
						component: Text,
						childrenProps: {
							//all Props component here
							span: 8,
						},
					},
					{
						id: "category_name",
						title: "Danh mục sản phẩm",
						value: data.category?.title,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},

					{
						id: "hot",
						title: "Hot deal",
						value: "",
						component: data.best_sale && LocalFireDepartment,
						childrenProps: {
							span: 8,
							color: "error",
						},
					},
					{
						id: "price",
						title: "Giá gốc",
						value: formatVND(data.price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "discount",
						title: "Giá giảm",
						value: formatVND(data.sale_price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "real_price",
						title: "Giá thực",
						value: formatVND(data.price - data.sale_price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "description",
						title: "Mô tả sản phẩm",
						value: data.description || "Không có dữ liệu",
						component: Text,
						childrenProps: {
							span: 24,
						},
					},

					{
						id: "sub_description",
						title: "Chi tiết sản phẩm",
						value: "",
						component: Markup,
						childrenProps: {
							span: 24,
							content: data.sub_description || "Không có dữ liệu",
						},
					},
				],
			},
		];
	};
	const handleOpenPrintModal = (data, templateType = "QR_CODE") => {
		setPrintData({
			visible: true,
			data,
			templateType,
		});
	};

	const getListBranch = async (isLastDeletedItem) => {
		setIsLoading(true);
		let filter = {
			[searchBy]:
				searchValue.trim().length === 0
					? undefined
					: {
							$iLike: `%${searchValue.trim()}%`,
					  },
		};

		let order = '[["created_at", "asc"]]';

		try {
			const res = await branchAPI.getList({
				fields: '["$all"]',
				order,
				filter: JSON.stringify(filter),
				limit: pagination.limit,
				page: isLastDeletedItem ? queryParams.page - 1 : queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			if (isLastDeletedItem) {
				navigate(
					{
						pathname: location.pathname,
						search: `?${queryString.stringify({ ...queryParams, page: paginationRes.page })}`,
					},
					{
						state: stateLoc,
					}
				);
			}
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));

			setSelectList([]);
			setRows(resList);

			setPagination(paginationRes);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//Get Services Categories
	const [serviceCategories, setServiceCategories] = useState(null);

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);

	const handleRemoveMulti = async () => {
		let isLast = rows.length === selectList.length && pagination.page !== 1;

		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await productAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListBranch(isLast);
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate(
			{
				pathname: location.pathname,
				search: `?${queryString.stringify(filters)}`,
			},
			{ state: location.state }
		);
	};

	const handleDelete = async (id) => {
		let isLast = rows.length === 1 && pagination.page !== 1;
		try {
			await branchAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListBranch(isLast);
			dispatch(actGetListBranch());
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useEffect(() => {
		if (!role) return;
		if (role?.BRANCH_VIEW !== ROLE.BRANCH_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}

		getListBranch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading, role]);

	const { handleSubmit, control, errors, setValue } = useForm();

	const dispatch = useDispatch();
	const onSubmit = async (data, e) => {
		e.preventDefault();
		setIsLoading(true);
		console.log("data: ", data);
		try {
			if (isUpdate) {
				const res = await branchAPI.update(information?.data?.id, data);
			} else {
				const res = await branchAPI.create(data);
			}

			dispatch(actGetListBranch());
			getListBranch();

			setIsLoading(false);
			enqueueSnackbar("Tạo chi nhánh mới thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			setInformation({
				visible: false,
				data: {},
			});
		} catch (err) {
			setIsLoading(false);
			enqueueSnackbar(err?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
			// setInformation({
			// 	visible: false,
			// 	data: {},
			// });
			console.log(err);
		}
	};
	useEffect(() => {
		if (isUpdate) {
			console.log("information: ", information);
			setValue("name", information?.data?.name || "");
			setValue("location", information?.data?.location || "");
		} else {
			setValue("name", "");
			setValue("location", "");
		}
	}, [information.visible]);

	return (
		<>
			<MainCard>
				<HeaderActions
					title={"Danh sách chi nhánh"}
					setIsUpdate={setIsUpdate}
					setOpen={(value) =>
						setInformation({
							visible: value,
							data: "",
						})
					}
					onCreate={role?.BRANCH_CREATE === ROLE?.BRANCH_CREATE}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{role?.BRANCH_DELETE === ROLE?.BRANCH_DELETE && (
						<Button
							sx={{
								display: stateLoc?.isFromGroup ? "none" : "block",
							}}
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection={role?.BRANCH_DELETE === ROLE?.BRANCH_DELETE}
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<ProductModal
				stateLocation={location.state}
				listSelect={serviceCategories}
				getListTable={getListBranch}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>
			<ImageModal
				title={"Ảnh sản phẩm"}
				multi
				classNameImg={"aspect1-1"}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>
			<ModalAntd
				title={isUpdate ? "Chỉnh sửa chi nhánh" : "Tạo chi nhánh mới"}
				visible={information.visible}
				width={"500px"}
				setVisible={(value) => {
					setInformation((prev) => ({
						data: "",
						visible: value,
					}));
				}}
			>
				<Box my={3}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Box>
							<Controller
								name="name"
								errors={errors}
								control={control}
								defaultValue={isUpdate ? information?.data?.name : ""}
								value={isUpdate ? information?.data?.name : ""}
								as={<TextField fullWidth label={"Tên chi nhánh"} />}
								rules={{
									validate: {
										stringSpace: (v) => !v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
									},
									required: {
										value: true,
										message: "Vui lòng điền tên chi nhánh",
									},
								}}
							/>
							{errors.name && <TextValidation>{errors.name.message}</TextValidation>}
						</Box>

						<Box mt={2}>
							<Controller
								name="location"
								errors={errors}
								control={control}
								defaultValue={isUpdate ? information?.data?.location : ""}
								value={isUpdate ? information?.data?.location : ""}
								as={<TextField fullWidth label={"Địa chỉ"} />}
							/>
						</Box>

						<Box textAlign="right" mt={3}>
							<LoadingButton type="submit" loading={isLoading} variant="contained">
								{isUpdate ? "Chỉnh sửa" : "Tạo"}
							</LoadingButton>
						</Box>
					</form>
				</Box>
			</ModalAntd>
			<ImageModal
				hasFooter
				title="Qr Code sản phẩm"
				openModalZoom={openModalImage}
				handleCloseModal={() => setOpenModalImage(false)}
				imageZoom={printQr.image}
				onOpenPageSelect={() => handleOpenPrintModal(printQr.data)}
				PrintComponent={
					<ProductQr
						qrCode={printQr.image}
						productName={printQr.productName}
						price={printQr.priceProduct}
						barCode={printQr?.data?.barcode}
					/>
				}
			/>

			<ModalAntd
				title={"Chọn mẫu giấy in"}
				visible={printData.visible}
				setVisible={(value) => {
					setPrintData((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<PageSizeSelect data={printData.data} templateType={printData.templateType} />
			</ModalAntd>
		</>
	);
};

export default Branch;
