import styled from "@emotion/styled";
import { CheckBox, Edit } from "@mui/icons-material";
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { IconDownload } from "@tabler/icons";
import { Modal, Select as SelectAntd, Tooltip } from "antd";
import joinCollabAPI from "api/JoinCollabAPI";
import packAPI from "api/PackAPI";
import registerTestAPI from "api/RegisterTestAPI";
import { CustomNoRowsOverlay } from "components/noRows";
import { COLOR } from "constants/common";
import { saveAs } from "file-saver";
import { joinCollabByTypeTranslator, transactionTranslator } from "func";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FacebookIcon, FacebookShareButton } from "react-share";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import TransactionStatusModal from "ui-component/modal-form/DepositWithdraw/StatusModal";
import { showSortStyle } from "views/utilities/CustomStyle";
import { SearchComponent } from "../../components/search";
import { getNumberWithCommas } from "../../utils";

const { Option } = SelectAntd;

const actionList = [
	// {
	// 	value: "preview_pack",
	// 	title: "Xem lại thẻ",
	// 	component: QrCode,
	// },
	{
		value: "update_status",
		title: "Chỉnh sửa trạng thái yêu cầu",
		component: Edit,
	},
];

const filterTypeList = [
	// {
	// 	value: "ALL",
	// 	title: "Tất cả",
	// },
	{
		value: "REQUEST_DEPOSIT",
		title: transactionTranslator("REQUEST_DEPOSIT"),
	},
	{
		value: "REQUEST_WITHDRAW",
		title: transactionTranslator("REQUEST_WITHDRAW"),
	},
];
const filterStatusList = [
	{
		value: "ALL",
		title: "Tất cả",
		color: "#000",
	},
	{
		value: "QR500",
		title: "Mã QR 500K",
		color: "#00c853",
	},
	{
		value: "QR_THE_HAN_MUC_DICH_VU",
		title: "Mã QR thẻ hạn mức dịch vụ",
		// color: "#ffc107",
		color: COLOR.SECONDARY,
	},
	// {
	// 	value: "QR_THE_TACH",
	// 	title: "Thẻ cá nhân tách",
	// 	color: COLOR.NAVI,
	// },
	{
		value: "QR_PERSONAL",
		title: "Mã QR cá nhân",
		// color: COLOR.SECONDARY,
		color: "#ffc107",
	},
	{
		value: "CMS",
		title: "Nhân viên thêm",
		color: "#f44336",
	},
];

const JoinCollab = () => {
	const location = useLocation();

	const [filterType, setFilterType] = useState(
		(location.state?.fromDashboard && location.state?.type) || "REQUEST_DEPOSIT"
	);
	const [filterStatus, setFilterStatus] = useState(
		(location.state?.fromDashboard && location.state?.status) || filterStatusList[0].value
	);
	const searchList = [
		// {
		// 	value: "username",
		// 	title: "username",
		// },
		{
			value: "$user_code_creator.phone$",

			title: "Chủ sở hữu mã",
		},
		{
			value: "$user.phone$",

			title: "Người sử dụng mã",
		},
		// {
		// 	value: "$user.name_admin_note$",
		// 	title: "Tên thân mật",
		// },
	];
	const { enqueueSnackbar } = useSnackbar();
	const modalContext = useContext(ModalContext);

	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState([]);

	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const [statusModal, setStatusModal] = useState(false);
	const [data, setData] = useState("");

	const columns = [
		{
			field: "order",
			headerName: t(`order`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
		},

		{
			field: "code_creator",
			headerName: "Chủ sở hữu mã",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.user_code_creator?.deleted_at ? "Tài khoản bị xóa" : "Xem chi tiết"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.code_creator ? (
								<Link
									className={params?.row?.user_code_creator?.deleted_at && "line__though"}
									target={"_blank"}
									to={`/user/${params.row?.code_creator}`}
								>
									{params?.row?.user_code_creator?.phone}
								</Link>
							) : params?.row?.type === "QR_THE_HAN_MUC_DICH_VU" ? (
								"VBeauty & Spa"
							) : (
								"Không có dữ liệu"
							)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "code_user",
			headerName: "Người sử dụng mã",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.user?.deleted_at ? "Tài khoản bị xóa" : "Xem chi tiết"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.code_user ? (
								<Link
									className={params?.row?.user?.deleted_at && "line__though"}
									target={"_blank"}
									to={`/user/${params.row?.code_user}`}
								>
									{params?.row?.user?.phone}
								</Link>
							) : (
								"Không có dữ liệu"
							)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "type",
			headerName: "Hình thức",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={joinCollabByTypeTranslator(params.value)?.title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								color: joinCollabByTypeTranslator(params.value)?.color,
								fontWeight: "bold",
							}}
						>
							{joinCollabByTypeTranslator(params.value).title}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee_id",
			headerName: "Nhân viên cập nhật",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.employee?.fullname || params.row?.employee?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.employee?.fullname || params.row?.employee?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "amount_received",
			headerName: "Số tiền nhận được",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => getNumberWithCommas(params.value),
		},
		{
			field: "become_ctv",
			headerName: "Trở thành tuyến dưới",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => params.value && <CheckBox color="success" />,
		},

		{
			field: "created_at",
			headerName: "Thời gian tạo",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
	];
	const handleChangeAccept = async (e, params) => {
		let checked = e.target.checked;
		try {
			await joinCollabAPI.update(params?.id, {
				is_checked: checked,
			});
			getListTable();
		} catch (error) {
			console.log(error);
		}
	};
	const getListTable = async () => {
		setIsLoading(true);

		let filter = {
			type: filterStatus === "ALL" ? undefined : filterStatus,
			[searchBy]:
				searchValue.trim().length === 0 ? undefined : { $iLike: `%${searchValue.trim()}%` },
		};

		let order = '[["created_at", "desc"]]';

		if (queryParams?.sortBy) {
			order = `[["${queryParams?.sortBy}", "${queryParams?.sort}"]]`;
		}
		try {
			const res = await joinCollabAPI.getList({
				fields:
					'["$all",{"user":["$all", "$paranoid"]},{"user_code_creator":["$all", "$paranoid"]}, {"employee": ["$all", "$paranoid"]}]',
				order,
				filter: JSON.stringify(filter),
				limit: pagination.limit,
				page: queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	const handleDelete = async (id) => {
		try {
			await registerTestAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			await getListTable();
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenUpdateStatus = (value) => {
		setData(value);
		setStatusModal(true);
	};

	const onChangeAction = (value, data) => {
		value === "edit" && navigate(`/users/edit/${data.id}`);
		value === "delete" && handleOpenRemoveModal(data);
		value === "update_status" && handleOpenUpdateStatus(data);
		value === "preview_pack" && handleOpenShare(data.pack_id);
	};

	const handleRemoveMulti = () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await registerTestAPI.removeMulti({
						items: JSON.stringify(selectList),
					});
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};
	const PreviewImg = styled("img")({
		width: "100%",
		borderRadius: "8px",
		maxWidth: "500px",
		padding: "20px",
	});

	useEffect(() => {
		if (queryParams.hasOwnProperty(searchBy)) {
			setSearchBy(searchBy);
			setSearchValue(searchValue);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		getListTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, filterType, filterStatus]);

	const [shareModal, setShareModal] = useState({
		open: false,
		image: "",
	});

	const handleCloseModal = () => {
		setShareModal({
			open: false,
			image: "",
		});
	};

	const handleOpenShare = async (packId) => {
		try {
			const res = await packAPI.findOne(packId, { fields: '["$all"]' });
			console.log(res);
			setShareModal({
				open: true,
				image: res.results.object?.qr_code_link,
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleSave = (image) => {
		saveAs(image, "qrCodeImage.png");
	};
	const mountRef = useRef(true);

	useEffect(() => {
		//Add zalo Script when open modal

		if (mountRef.current) {
			mountRef.current = false;
			return;
		}
		const script = document.createElement("script");

		script.src = "https://sp.zalo.me/plugins/sdk.js";

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [shareModal.open]);
	return (
		<>
			<MainCard>
				<HeaderActions title={"Danh sách cộng tác viên"} onCreate={false} />
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						// justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					<Box>
						<FormControl sx={{ minWidth: 150, ml: 2, mb: 2 }}>
							<InputLabel id="demo-simple-select-outlined-label">{"Hình thức"}</InputLabel>
							<Select
								defaultValue={filterStatusList[0].value}
								onChange={(e) => {
									setFilterStatus(e.target.value);
									handleChangePage(null, 1);
								}}
								labelId="demo-simple-select-outlined-label"
								label={"Hình thức"}
								value={filterStatus}
							>
								{filterStatusList?.map((item) => (
									<MenuItem
										key={item.value}
										value={item.value}
										sx={{ color: item.color, fontWeight: 700 }}
									>
										{item.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>

					{/* <Button
						variant={selectList.length > 0 ? "contained" : "outlined"}
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							// checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
			<TransactionStatusModal
				getListTable={getListTable}
				data={data}
				open={statusModal}
				setOpen={(value) => setStatusModal(value)}
				handleOpenShare={handleOpenShare}
				filterType={filterType}
			/>

			<Modal
				centered
				visible={shareModal.open}
				onCancel={handleCloseModal}
				footer={null}
				zIndex="10000"
			>
				<Typography id="modal-modal-title" variant="h3" component="h1">
					Chia sẻ
				</Typography>
				<Box
					sx={{
						"& .MuiTextField-root": {
							mt: 3,
						},
					}}
				>
					<Box
						sx={{
							textAlign: "center",
						}}
					>
						<PreviewImg src={shareModal.image} alt="QR Code" />
						{/* <RemoveImg onClick={() => setImageZoom("")}>{t(`remove`)}</RemoveImg> */}
					</Box>
				</Box>
				<Box
					sx={{
						"& .MuiTextField-root": {
							mt: 3,
						},
						mb: 3,
						display: "flex",
						justifyContent: "center",
						gap: "20px",
						alignItems: "center",
					}}
				>
					<div
						class="zalo-share-button"
						data-href={shareModal.image}
						data-oaid="579745863508352884"
						data-layout="4"
						data-color="blue"
						data-share-type="2"
						data-customize="true"
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								background: "#03a5fa",
								borderRadius: "3px",
								width: "50px",
								height: "50px",
								cursor: "pointer",
							}}
						>
							<img
								style={{
									width: "30px",
									height: "30px",
								}}
								src="https://stc.sp.zdn.vn/share/logo_white_s_2x_40.png"
								alt="zaloImg"
							/>
						</Box>
					</div>

					<FacebookShareButton url={shareModal.image} className="Demo__some-network__share-button">
						<FacebookIcon
							size={52}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								background: "#03a5fa",
								borderRadius: "3px",
								width: "50px",
								height: "50px",
							}}
						/>
					</FacebookShareButton>
					<Button
						style={{
							cursor: "pointer",
							color: "black",
						}}
						onClick={() =>
							// saveImg(shareModal.image)
							handleSave(shareModal.image)
						}
					>
						<IconDownload size={50} />
					</Button>
				</Box>
			</Modal>
		</>
	);
};

export default JoinCollab;
