import axios from "axios";
import { ENV } from "config/enviroment";
import queryString from "query-string";
import authAPI from "./AuthAPI";

const getBaseURL = (str = "production") => {
	if (str === "production") {
		return ENV_PRODUCTION;
	} else if (str === "dev") {
		return ENV_DEVELOPMENT;
	} else if (str === "staging") {
		return ENV_STAGING;
	}
};

const ENV_PRODUCTION = process.env.REACT_APP_API_PRODUCTION; // yarn build && firebase deploy --only hosting:abeauty-cms // Build for Production
const ENV_DEVELOPMENT = process.env.REACT_APP_API_DEVELOPMENT; //yarn build && firebase deploy --only hosting:abeauty-cms-dev //Build for development
const ENV_STAGING = process.env.REACT_APP_API_STAGING; //	yarn build && firebase deploy --only hosting:abeauty-cms-staging //Build for Staging

const localhostHeader = {
	"Content-Type": "application/json",
	env: ENV,
	platform: "cms",
	localhost: "cms",
};
const defaultHeader = {
	"Content-Type": "application/json",
	env: ENV,
	platform: "cms",
};
const axiosClient = axios.create({
	baseURL: getBaseURL(ENV),
	headers: process.env.NODE_ENV === "development" ? localhostHeader : defaultHeader,
	paramsSerializer: (params) => queryString.stringify(params),
});
let count = 0;
const countErr = () => {
	if (count !== 10) {
		count++;
	} else {
		count = 0;
		localStorage.removeItem("employeeInfo");
		window.location = "/";
	}
};

// axios.defaults.baseURL = process.env.REACT_APP_API;

axiosClient.interceptors.request.use(async (config) => {
	const employeeInfo = JSON.parse(localStorage.getItem("employeeInfo"));
	if (!!employeeInfo) {
		config.headers.Authorization = `Bearer ${employeeInfo.token}`;
	}

	return config;
});

axiosClient.interceptors.response.use(
	(response) => {
		if (response && response.data) {
			return response.data;
		}

		return response;
	},
	async (error) => {
		//Kiểm tra nếu lỗi token thì trở về trang đâng nhập
		const employeeInfo = JSON.parse(localStorage.getItem("employeeInfo"));
		if (!!employeeInfo) {
			countErr();
			if (error.response?.status === 401) {
				if (!employeeInfo.refreshToken) {
					localStorage.removeItem("employeeInfo");
					window.location = "/";
				} else {
					try {
						const originalRequest = error.config;
						const res = await authAPI.refreshToken({ refresh_token: employeeInfo.refreshToken });
						const newEmployeeInfo = {
							...employeeInfo,
							token: res?.results?.object?.token,
							refreshToken: res?.results?.object?.refreshToken,
						};

						localStorage.setItem("employeeInfo", JSON.stringify(newEmployeeInfo));

						return axiosClient(originalRequest); //Recall API when refresh success
						// window.location.reload(true);
					} catch (error) {
						if (error.code === 409) {
							localStorage.removeItem("employeeInfo");
							window.location = "/";
						}
					}
				}
			}
		}

		// Handle errors
		throw error.response?.data;
	}
);
export { defaultHeader };
export default axiosClient;
