import axiosClient from "api/axiosClient";

const URL = `/post`;
const categoryURL = "/category_post";
const postAPI = {
	async getList(params) {
		const url = `${URL}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async findOne(id, params) {
		const url = `${URL}/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},
	async getCategory(params) {
		const url = categoryURL;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},
	async searchProduct(params) {
		const url = `${URL}/search_product_and_combo`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},
	async create(data) {
		const url = `${URL}`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async update(id, data) {
		const url = `${URL}/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async remove(id) {
		const url = `${URL}/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},

	async removeMulti(params) {
		const url = `${URL}`;
		const resp = await axiosClient.delete(url, { params });
		return resp;
	},
};

export default postAPI;
