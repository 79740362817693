//All Role here

const ROLE_DETAILS = [
	{
		id: "a4858060-6f9b-11ed-b209-118b121e79f2",
		updated_at: "2022-11-29T11:09:39.046Z",
		role_id: "e3d68680-6f99-11ed-b606-33ba61172bf5",
		type: "PRODUCT_VIEW",
	},
	{
		id: "c45e45c0-6f9b-11ed-b209-118b121e79f2",
		updated_at: "2022-11-29T11:10:32.477Z",
		role_id: "e3d68680-6f99-11ed-b606-33ba61172bf5",
		type: "PRODUCT_CREATE",
	},
	{
		id: "e23520a0-6f9b-11ed-b209-118b121e79f2",
		updated_at: "2022-11-29T11:11:22.540Z",
		role_id: "e3d68680-6f99-11ed-b606-33ba61172bf5",
		type: "PRODUCT_UPDATE",
	},
	{
		id: "f069c130-6f9b-11ed-b209-118b121e79f2",
		updated_at: "2022-11-29T11:11:46.375Z",
		role_id: "e3d68680-6f99-11ed-b606-33ba61172bf5",
		type: "PRODUCT_DELETE",
	},
	{
		id: "fbfd9f30-6f9b-11ed-b209-118b121e79f2",
		updated_at: "2022-11-29T11:12:05.799Z",
		role_id: "e3d68680-6f99-11ed-b606-33ba61172bf5",
		type: "PRODUCT_IMPORT_PRICE",
	},
	{
		id: "aef35b80-6fc8-11ed-b8b8-cb910a39d35d",
		updated_at: "2022-11-29T16:32:04.331Z",
		role_id: "b5bb6f80-6f9a-11ed-b606-33ba61172bf5",
		type: "DASHBOARD",
	},
	{
		id: "0f1f4d60-6fca-11ed-a631-319692fe03bf",
		updated_at: "2022-11-29T16:41:54.686Z",
		role_id: "ce9d3030-6f9d-11ed-b209-118b121e79f2",
		type: "ADVISE",
	},
	{
		id: "5a95b130-6fca-11ed-a631-319692fe03bf",
		updated_at: "2022-11-29T16:44:01.281Z",
		role_id: "4b3b5950-6f9e-11ed-b209-118b121e79f2",
		type: "LINK",
	},
	{
		id: "65fa68e0-6fca-11ed-a631-319692fe03bf",
		updated_at: "2022-11-29T16:44:20.399Z",
		role_id: "dc36d3d0-6f9e-11ed-a1c7-55d8dd818c63",
		type: "NOTIFICATION",
	},
	{
		id: "93c3e800-6fca-11ed-a631-319692fe03bf",
		updated_at: "2022-11-29T16:45:37.214Z",
		role_id: "8d3ebf50-6fca-11ed-a631-319692fe03bf",
		type: "OFFER",
	},
	{
		id: "a597f670-6fca-11ed-a631-319692fe03bf",
		updated_at: "2022-11-29T16:46:07.127Z",
		role_id: "32555610-6f9f-11ed-a1c7-55d8dd818c63",
		type: "SETTING",
	},
	{
		id: "92f38660-6fcf-11ed-b837-07c0c322a088",
		updated_at: "2022-11-29T17:21:23.372Z",
		role_id: "874a2490-6fcf-11ed-b837-07c0c322a088",
		type: "ROLE",
	},
	{
		id: "368ff9e0-6f9c-11ed-b209-118b121e79f2",
		updated_at: "2022-11-30T14:43:00.200Z",
		role_id: "25967ee0-6f9a-11ed-b606-33ba61172bf5",
		type: "TRANSACTION_VIEW",
	},
	{
		id: "4238e680-6f9c-11ed-b209-118b121e79f2",
		updated_at: "2022-11-30T14:43:37.243Z",
		role_id: "25967ee0-6f9a-11ed-b606-33ba61172bf5",
		type: "TRANSACTION_CREATE",
	},
	{
		id: "4d20a600-6f9c-11ed-b209-118b121e79f2",
		updated_at: "2022-11-30T14:43:59.653Z",
		role_id: "25967ee0-6f9a-11ed-b606-33ba61172bf5",
		type: "TRANSACTION_UPDATE",
	},
	{
		id: "56469140-6f9c-11ed-b209-118b121e79f2",
		updated_at: "2022-11-30T14:44:20.111Z",
		role_id: "25967ee0-6f9a-11ed-b606-33ba61172bf5",
		type: "TRANSACTION_DELETE",
	},
	{
		id: "badba240-6fa0-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:47:20.041Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_UPDATE",
	},
	{
		id: "befaee80-6fa0-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:47:39.763Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_CREATE",
	},
	{
		id: "c39250f0-6fa0-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:47:56.056Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_VIEW",
	},
	{
		id: "13b37820-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:49:32.826Z",
		role_id: "553d2270-6f9a-11ed-b606-33ba61172bf5",
		type: "CONFIRM_CUSTOMER_VIEW",
	},
	{
		id: "1b1b1190-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:49:58.360Z",
		role_id: "553d2270-6f9a-11ed-b606-33ba61172bf5",
		type: "CONFIRM_CUSTOMER_UPDATE",
	},
	{
		id: "2aa18680-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:51:20.649Z",
		role_id: "6e9c30d0-6f9a-11ed-b606-33ba61172bf5",
		type: "CONFIRM_CHANGE_WALLET_UPDATE",
	},
	{
		id: "30df1ee0-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:52:55.239Z",
		role_id: "6e9c30d0-6f9a-11ed-b606-33ba61172bf5",
		type: "CONFIRM_CHANGE_WALLET_VIEW",
	},
	{
		id: "416884e0-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:54:41.060Z",
		role_id: "7aa6c570-6f9a-11ed-b606-33ba61172bf5",
		type: "VOUCHER_VIEW",
	},
	{
		id: "64229840-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:55:54.133Z",
		role_id: "7aa6c570-6f9a-11ed-b606-33ba61172bf5",
		type: "VOUCHER_UPDATE",
	},
	{
		id: "7c472ad0-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:56:11.855Z",
		role_id: "7aa6c570-6f9a-11ed-b606-33ba61172bf5",
		type: "VOUCHER_CREATE",
	},
	{
		id: "82559ce0-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:56:31.113Z",
		role_id: "7aa6c570-6f9a-11ed-b606-33ba61172bf5",
		type: "VOUCHER_DELETE",
	},
	{
		id: "8eefa040-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:57:31.992Z",
		role_id: "86a720e0-6f9a-11ed-b606-33ba61172bf5",
		type: "BANNER_DELETE",
	},
	{
		id: "94559d50-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:57:59.865Z",
		role_id: "86a720e0-6f9a-11ed-b606-33ba61172bf5",
		type: "BANNER_CREATE",
	},
	{
		id: "9b1250c0-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:58:23.682Z",
		role_id: "86a720e0-6f9a-11ed-b606-33ba61172bf5",
		type: "BANNER_UPDATE",
	},
	{
		id: "9dceb1a0-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T14:58:45.769Z",
		role_id: "86a720e0-6f9a-11ed-b606-33ba61172bf5",
		type: "BANNER_VIEW",
	},
	{
		id: "b8133e00-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T15:02:33.958Z",
		role_id: "ad9a3820-6f9c-11ed-b209-118b121e79f2",
		type: "BOOKING_VIEW",
	},
	{
		id: "c1e9b490-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T15:03:08.632Z",
		role_id: "ad9a3820-6f9c-11ed-b209-118b121e79f2",
		type: "BOOKING_CREATE",
	},
	{
		id: "ca42b2e0-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T15:03:23.972Z",
		role_id: "ad9a3820-6f9c-11ed-b209-118b121e79f2",
		type: "BOOKING_UPDATE",
	},
	{
		id: "cfc845e0-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T15:03:38.597Z",
		role_id: "ad9a3820-6f9c-11ed-b209-118b121e79f2",
		type: "BOOKING_DELETE",
	},
	{
		id: "e676b330-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T15:05:10.457Z",
		role_id: "f8455cb0-6f9c-11ed-b209-118b121e79f2",
		type: "BRANCH_DELETE",
	},
	{
		id: "ea7afd60-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T15:05:31.567Z",
		role_id: "f8455cb0-6f9c-11ed-b209-118b121e79f2",
		type: "BRANCH_UPDATE",
	},
	{
		id: "f0717aa0-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T15:05:55.183Z",
		role_id: "f8455cb0-6f9c-11ed-b209-118b121e79f2",
		type: "BRANCH_CREATE",
	},
	{
		id: "f29aea50-6fa1-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T15:06:14.507Z",
		role_id: "f8455cb0-6f9c-11ed-b209-118b121e79f2",
		type: "BRANCH_VIEW",
	},
	{
		id: "023646d0-6fa2-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-11-30T15:07:31.258Z",
		role_id: "062a56a0-6f9d-11ed-b209-118b121e79f2",
		type: "POST_VIEW",
	},
	{
		id: "b2f2b810-7096-11ed-b4b9-83e28b15fb93",
		updated_at: "2022-11-30T17:06:46.858Z",
		role_id: "35ae88a0-6f9e-11ed-b209-118b121e79f2",
		type: "PACK_VIEW",
	},
	{
		id: "b7e44870-7096-11ed-b4b9-83e28b15fb93",
		updated_at: "2022-11-30T17:06:55.149Z",
		role_id: "35ae88a0-6f9e-11ed-b209-118b121e79f2",
		type: "PACK_CREATE",
	},
	{
		id: "c77c97b0-7096-11ed-b4b9-83e28b15fb93",
		updated_at: "2022-11-30T17:07:21.311Z",
		role_id: "35ae88a0-6f9e-11ed-b209-118b121e79f2",
		type: "PACK_DELETE",
	},
	{
		id: "bad4d8b0-7096-11ed-b4b9-83e28b15fb93",
		updated_at: "2022-11-30T17:08:09.246Z",
		role_id: "35ae88a0-6f9e-11ed-b209-118b121e79f2",
		type: "PACK_UPDATE",
	},
	{
		id: "cfa8de90-6fc3-11ed-87f6-bfd698305cca",
		updated_at: "2022-11-30T17:09:06.538Z",
		role_id: "4921f9c0-6f9f-11ed-a1c7-55d8dd818c63",
		type: "EMPLOYEE_VIEW",
	},
	{
		id: "d75410b0-6fc3-11ed-87f6-bfd698305cca",
		updated_at: "2022-11-30T17:09:27.167Z",
		role_id: "4921f9c0-6f9f-11ed-a1c7-55d8dd818c63",
		type: "EMPLOYEE_CREATE",
	},
	{
		id: "dfa2fce0-6fc3-11ed-87f6-bfd698305cca",
		updated_at: "2022-11-30T17:09:44.255Z",
		role_id: "4921f9c0-6f9f-11ed-a1c7-55d8dd818c63",
		type: "EMPLOYEE_UPDATE",
	},
	{
		id: "e5f4b980-6fc3-11ed-87f6-bfd698305cca",
		updated_at: "2022-11-30T17:10:01.037Z",
		role_id: "4921f9c0-6f9f-11ed-a1c7-55d8dd818c63",
		type: "EMPLOYEE_DELETE",
	},
	{
		id: "f4afd800-709b-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:44:25.775Z",
		role_id: "9077eac0-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_PRODUCT_VIEW",
	},
	{
		id: "f7c136b0-709b-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:44:29.767Z",
		role_id: "9077eac0-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_PRODUCT_CREATE",
	},
	{
		id: "faca30f0-709b-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:44:34.860Z",
		role_id: "9077eac0-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_PRODUCT_UPDATE",
	},
	{
		id: "fd5b3c10-709b-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:44:39.164Z",
		role_id: "9077eac0-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_PRODUCT_DELETE",
	},
	{
		id: "0c953eb0-709c-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:45:04.712Z",
		role_id: "a81b1850-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_SERVICE_VIEW",
	},
	{
		id: "11148540-709c-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:45:12.257Z",
		role_id: "a81b1850-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_SERVICE_CREATE",
	},
	{
		id: "12f4de00-709c-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:45:15.405Z",
		role_id: "a81b1850-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_SERVICE_UPDATE",
	},
	{
		id: "150c2540-709c-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:45:18.911Z",
		role_id: "a81b1850-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_SERVICE_DELETE",
	},
	{
		id: "2beced30-709c-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:45:57.294Z",
		role_id: "bd449350-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_POST_DELETE",
	},
	{
		id: "38832000-709c-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:46:51.717Z",
		role_id: "bd449350-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_POST_UPDATE",
	},
	{
		id: "29a1c4b0-709c-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:47:29.119Z",
		role_id: "bd449350-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_POST_DELETE",
	},
	{
		id: "32d76760-709c-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:47:53.310Z",
		role_id: "bd449350-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_POST_VIEW",
	},
	{
		id: "365f55a0-709c-11ed-b416-b5038dd348f3",
		updated_at: "2022-11-30T17:48:15.141Z",
		role_id: "bd449350-6f9d-11ed-b209-118b121e79f2",
		type: "CATEGORY_POST_CREATE",
	},
	{
		id: "13202290-7132-11ed-87cd-b9ba14f0ef19",
		updated_at: "2022-12-01T11:39:00.158Z",
		role_id: "2b2683f0-7120-11ed-a630-65e084616af5",
		type: "RECHARGE",
	},
	{
		id: "39b6d300-7226-11ed-a5d5-9d5c89bc9925",
		updated_at: "2022-12-02T16:47:17.525Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_DEPOSIT_FIRSTTIME",
	},
	{
		id: "59354360-7226-11ed-a5d5-9d5c89bc9925",
		updated_at: "2022-12-02T16:47:35.068Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_PARENT",
	},
	{
		id: "66e76d80-7226-11ed-a5d5-9d5c89bc9925",
		updated_at: "2022-12-02T16:47:58.039Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_LIST_MEMBER",
	},
	{
		id: "f08a4cd0-7512-11ed-8bde-e3075b8963b8",
		updated_at: "2022-12-06T10:06:12.447Z",
		role_id: "bc612e60-7512-11ed-8bde-e3075b8963b8",
		type: "CATEGORY_SERVICE_VIEW",
	},
	{
		id: "025162f0-7513-11ed-8bde-e3075b8963b8",
		updated_at: "2022-12-06T10:06:42.271Z",
		role_id: "bc612e60-7512-11ed-8bde-e3075b8963b8",
		type: "CATEGORY_SERVICE_CREATE",
	},
	{
		id: "0c2f7aa0-7513-11ed-8bde-e3075b8963b8",
		updated_at: "2022-12-06T10:06:58.825Z",
		role_id: "bc612e60-7512-11ed-8bde-e3075b8963b8",
		type: "CATEGORY_SERVICE_UPDATE",
	},
	{
		id: "12560890-7513-11ed-8bde-e3075b8963b8",
		updated_at: "2022-12-06T10:07:09.144Z",
		role_id: "bc612e60-7512-11ed-8bde-e3075b8963b8",
		type: "CATEGORY_SERVICE_DELETE",
	},
	{
		id: "f868aaa0-7517-11ed-ab92-e115033cfe0f",
		updated_at: "2022-12-06T10:42:13.193Z",
		role_id: "a9a5e070-7131-11ed-87cd-b9ba14f0ef19",
		type: "WITHDRAW",
	},
	{
		id: "83d48000-7518-11ed-8cd0-8ff8134233df",
		updated_at: "2022-12-06T10:46:07.098Z",
		role_id: "70b37f30-7518-11ed-8cd0-8ff8134233df",
		type: "SERVICE_VIEW",
	},
	{
		id: "8d69cee0-7518-11ed-8cd0-8ff8134233df",
		updated_at: "2022-12-06T10:46:23.175Z",
		role_id: "70b37f30-7518-11ed-8cd0-8ff8134233df",
		type: "SERVICE_CREATE",
	},
	{
		id: "96c98ed0-7518-11ed-8cd0-8ff8134233df",
		updated_at: "2022-12-06T10:46:38.902Z",
		role_id: "70b37f30-7518-11ed-8cd0-8ff8134233df",
		type: "SERVICE_UPDATE",
	},
	{
		id: "a0c65210-7518-11ed-8cd0-8ff8134233df",
		updated_at: "2022-12-06T10:46:55.656Z",
		role_id: "70b37f30-7518-11ed-8cd0-8ff8134233df",
		type: "SERVICE_DELETE",
	},
	{
		id: "a8ba7ea0-7519-11ed-a8c5-b90851a8f2ed",
		updated_at: "2022-12-06T10:54:18.505Z",
		role_id: "062a56a0-6f9d-11ed-b209-118b121e79f2",
		type: "POST_CREATE",
	},
	{
		id: "b16df900-7519-11ed-a8c5-b90851a8f2ed",
		updated_at: "2022-12-06T10:54:33.094Z",
		role_id: "062a56a0-6f9d-11ed-b209-118b121e79f2",
		type: "POST_UPDATE",
	},
	{
		id: "b91a8ab0-7519-11ed-a8c5-b90851a8f2ed",
		updated_at: "2022-12-06T10:54:45.971Z",
		role_id: "062a56a0-6f9d-11ed-b209-118b121e79f2",
		type: "POST_DELETE",
	},
	{
		id: "1f6d7f20-6fca-11ed-a631-319692fe03bf",
		updated_at: "2022-12-06T10:56:14.768Z",
		role_id: "22b2c310-6f9e-11ed-b209-118b121e79f2",
		type: "SCAN_SKIN",
	},
	{
		id: "b6ca11a0-6fa0-11ed-a1c7-55d8dd818c63",
		updated_at: "2022-12-06T10:57:35.643Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_DELETE",
	},
	{
		id: "a7bf7240-7518-11ed-8cd0-8ff8134233df",
		updated_at: "2022-12-06T11:16:06.883Z",
		role_id: "70b37f30-7518-11ed-8cd0-8ff8134233df",
		type: "SERVICE_IMPORT_PRICE",
	},
	{
		id: "ac733680-753d-11ed-8e28-e9578aa270ce",
		updated_at: "2022-12-06T15:12:06.613Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_UPDATE_EXPIRED_WALLET",
	},
	{
		id: "bbcc59e0-753d-11ed-8e28-e9578aa270ce",
		updated_at: "2022-12-06T15:12:32.364Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_UPDATE_WALLET",
	},
	{
		id: "c8eb1da0-753d-11ed-8e28-e9578aa270ce",
		updated_at: "2022-12-06T15:12:54.378Z",
		role_id: "335b46a0-6f9a-11ed-b606-33ba61172bf5",
		type: "CUSTOMER_CREATE_QRCODE",
	},
	{
		id: "fb57feb0-84f7-11ed-8334-21b15b83267e",
		updated_at: "2022-12-26T15:33:32.841Z",
		role_id: "d26ef8f0-84f7-11ed-8334-21b15b83267e",
		type: "DEBT",
	},
	{
		id: "3c30cc30-8c1b-11ed-aa9a-ed18b6203d88",
		updated_at: "2023-01-04T17:33:32.117Z",
		role_id: "12182010-8c1b-11ed-aa9a-ed18b6203d88",
		type: "UPDATE_WALLET_ROSE",
	},
	{
		id: "61f4d490-9097-11ed-8d49-f949793bddbb",
		updated_at: "2023-01-10T10:32:19.583Z",
		role_id: "2792b1a0-9097-11ed-9d77-4b05ba0df906",
		type: "REPORT",
	},
];

let ROLE = {};

ROLE_DETAILS.map((it) => {
	ROLE = {
		...ROLE,
		[it.type]: it.type,
	};
});

export { ROLE, ROLE_DETAILS };
