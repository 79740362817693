import dashboard from "menu-items/dashboard";
import general from "menu-items/general";

import notification from "menu-items/notification";
import setting from "menu-items/system_setting";
import booking from "menu-items/booking";
import scanskin from "menu-items/scanskin";
import partner from "menu-items/partner";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import transaction from "menu-items/transaction";
import goods from "menu-items/goods";
import link from "menu-items/link";
import useCustomAuth from "./useCustomAuth";

function useMenuItem() {
	const waitingRequest = useSelector((state) => state.waitingRequest.waitingRequest);
	const { role } = useCustomAuth();
	const DEFAULT_MENU_ITEMS = {
		items: [
			dashboard(role),
			booking(waitingRequest, role),
			transaction(waitingRequest, role),
			partner(waitingRequest, role),
			general(waitingRequest, role),
			goods(waitingRequest, role),
			link(waitingRequest, role),
			scanskin(waitingRequest, role),
			notification(waitingRequest, role),
			setting(waitingRequest, role),
		],
	};
	const [menuItems, setMenuItems] = useState(DEFAULT_MENU_ITEMS);
	useEffect(() => {
		setMenuItems(DEFAULT_MENU_ITEMS);
	}, [waitingRequest, role]);

	return menuItems;
}

export default useMenuItem;
