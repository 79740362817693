import {
	GET_ROLE_EMPLOYEE,
	LOGIN_EMPLOYEE,
	LOGOUT_EMPLOYEE,
	UPDATE_SETTING_EMPLOYEE,
	UPLOAD_AVATAR,
} from "store/actions/EmployeeAction/type";

const initState = {
	employeeInfo: JSON.parse(localStorage.getItem("employeeInfo")) || {},
	role: null,
	role_details: {},
};

const EmployeeReducer = (state = initState, action) => {
	switch (action.type) {
		case LOGIN_EMPLOYEE: {
			const newEmployeeInfo = action.payload;
			return {
				...state,
				employeeInfo: newEmployeeInfo,
			};
		}
		case UPDATE_SETTING_EMPLOYEE: {
			const newEmployeeInfo = action.payload;
			const employeeInfoStore = {
				...state.employeeInfo,
				...newEmployeeInfo,
			};
			localStorage.setItem("employeeInfo", JSON.stringify(employeeInfoStore));

			return {
				...state,
				employeeInfo: employeeInfoStore,
			};
		}

		case LOGOUT_EMPLOYEE: {
			return {
				role: null,
				employeeInfo: {},
			};
		}

		case UPLOAD_AVATAR: {
			const data = action.payload;

			const newEmployeeInfo = {
				...state.employeeInfo,
			};
			newEmployeeInfo.object = data;
			return {
				...state,
				employeeInfo: newEmployeeInfo,
			};
		}

		case GET_ROLE_EMPLOYEE: {
			const employee_role = action?.payload?.map((item) => item.type);
			let roles = {};
			employee_role.map((role) => {
				roles = {
					...roles,
					[role]: role,
				};
			});
			return {
				...state,
				role_details: employee_role,
				role: roles,
			};
		}

		default:
			return state;
	}
};

export default EmployeeReducer;
