import axiosClient from "api/axiosClient";

const employeeAPI = {
	async getList(params) {
		const url = `employee`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async findOne(id, params) {
		const url = `employee/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async create(data) {
		const url = `employee`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async remove(id) {
		const url = `employee/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},

	async removeMulti(params) {
		const url = `employee`;
		const resp = await axiosClient.delete(url, { params });
		return resp;
	},

	async edit(id, data) {
		const url = `employee/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async updateRole(data) {
		const url = `employee_role/create_role_for_employee`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},
	async getStaffRole(params) {
		const url = `employee_role/get_staff_role`;
		const resp = await axiosClient.get(url, {
			params,
		});
		return resp;
	},
};

export default employeeAPI;
