import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ToastProvider } from "providers/ToastProvider";
import { useDispatch, useSelector } from "react-redux";
import NavigationScroll from "./layout/NavigationScroll";
import Routes from "./routes";
import themes from "./themes";
import "./style.scss";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";
import { useEffect } from "react";
import useCustomAuth from "hooks/useCustomAuth";
import { getRoleEmployee } from "store/actions/EmployeeAction";

// ==============================|| APP ||============================== //

const App = () => {
	const customization = useSelector((state) => state.customization);
	const isLoggedIn = useSelector((state) => !!state.employee.employeeInfo?.token);
	// const { role } = useSelector((state) => state.employee?.role);
	const { employeeInfo, role } = useCustomAuth();
	const dispatch = useDispatch();
	const { branchActiveId } = useSelector((state) => state.branchReducer);

	useEffect(() => {
		if (branchActiveId && isLoggedIn) {
			const data = {
				employeeId: employeeInfo.id,
				branchActiveId,
			};
			dispatch(getRoleEmployee(branchActiveId));
			dispatch(actGetWaitingRequest(branchActiveId));
		}
	}, [branchActiveId, isLoggedIn]);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes(customization)}>
				<CssBaseline />
				<NavigationScroll>
					<ToastProvider>
						<Routes isLoggedIn={isLoggedIn} />
					</ToastProvider>
				</NavigationScroll>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default App;
