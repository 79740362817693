// import { unstable_createMuiStrictModeTheme } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
	typography: {
		fontFamily: ["Spoqa Han Sans Neo", "sans-serif"].join(","),
		button: {
			textTransform: "initial",
		},
	},
	overrides: {
		MuiDialog: {
			root: {
				"& .MuiDialog-paper": {
					minWidth: "400",
				},
			},
		},
		MuiPopover: {
			root: {
				"& .MuiPickersBasePicker-pickerView": {
					maxWidth: 360,
					minWidth: 360,
					minHeight: 360,
					justifyContent: "unset",
					"& .MuiPickersCalendar-week": {
						marginBottom: 16,
					},
					"& .MuiPickersDay-day": {
						margin: "0 5px",
					},
					"& .MuiPickersCalendarHeader-switchHeader": {
						display: "flex",
						width: "fit-content",
						margin: "5px auto",
						"& .MuiTypography-alignCenter": {
							position: "unset !important",
						},
						"& .MuiIconButton-label": {
							"& svg": {
								"& path:first-child": {
									fill: "#495057",
								},
							},
						},
						"& .MuiPickersCalendarHeader-transitionContainer": {
							position: "relative",
							top: "-1px",
							color: "#343A40",
						},
					},
					"& .MuiPickersCalendarHeader-daysHeader": {
						"& .MuiPickersCalendarHeader-dayLabel": {
							margin: "0 5px",
							color: "#868E96",
						},
					},
				},
				"& .MuiPickersDay-daySelected": {
					backgroundColor: "#FF6F61",
				},
				"& .MuiPickersDay-current": {
					color: "#fff",
					backgroundColor: "#495057",
				},
				"& .MuiPickersCalendar-transitionContainer": {
					marginTop: 25,
				},
			},
		},
	},
});

theme = responsiveFontSizes(theme);

export default theme;
