import { Edit, Inventory } from "@mui/icons-material";
import { Box, Grid, Icon, IconButton, Pagination, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import inventoryAPI from "api/InventoryAPI";
import UpdateInventory from "components/forms/UpdateInventory";
import { CustomNoRowsOverlay } from "components/noRows";
import { getNumberWithCommas } from "func";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { gridSpacing } from "store/constant";
import DialogMain from "ui-component/modal-form/DialogMui";
import { showSortStyle } from "views/utilities/CustomStyle";

//==========> End lib  <==========/
function CustomFooter(sumInventory) {
	return (
		<GridFooterContainer>
			{/* Add what you want here */}
			<Box padding={2} width="100%" textAlign="right" display="flex" justifyContent={"flex-end"}>
				<Typography variant="h5" mr={"5px"}>
					Tổng số lượng tồn kho:{" "}
				</Typography>
				<Typography variant="span">{sumInventory}</Typography>
			</Box>
			<GridFooter
				sx={{
					border: "none", // To delete double border.
				}}
			/>
		</GridFooterContainer>
	);
}
function ProductInventory({ productId }) {
	const location = useLocation();
	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const [sumInventory, setSumInventory] = useState();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const [inventoryModal, setInventoryModal] = useState({
		open: false,
		data: {},
	});

	const [loading, setLoading] = useState(false);
	const fetchInventoryByProduct = async (id) => {
		setLoading(true);
		const fields = ["$all", { product: ["inventory_amount"] }, { branch: ["name"] }];

		const filterA = {
			product_id: id,
		};

		const order = [["created_at_unix_timestamp", "asc"]];

		const payload = {
			fields: JSON.stringify(fields),
			filter: JSON.stringify(filterA),
			order: JSON.stringify(order),
			page: pagination.page,
			limit: pagination.limit,
		};
		try {
			const res = await inventoryAPI.getList(payload);
			setRows(res.results.objects.rows);
			setSumInventory(res.results.objects.sumInventory);
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			setPagination((prev) => ({
				...prev,
				...paginationRes,
			}));
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		setPagination((prev) => ({
			...prev,
			page,
		}));
		navigate({
			pathname: location.pathname,
			// search: `?${queryString.stringify(filters)}`,
		});
	};

	useEffect(() => {
		fetchInventoryByProduct(productId);
	}, [pagination.page]);

	const columns = [
		{
			field: "branch",
			headerName: "Tên chi nhánh",
			flex: 3,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.branch?.name}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.branch?.name}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "inventory_number",
			headerName: "Số lượng tồn kho",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={getNumberWithCommas(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{getNumberWithCommas(params?.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "actions",
			headerName: "Hành động",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.5,
			renderCell: (params) => {
				console.log(params);
				return (
					<Tooltip
						title={"Cập nhật số lượng"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<IconButton
							onClick={() => {
								setInventoryModal({
									open: true,
									data: params.row,
								});
							}}
						>
							<Edit />
						</IconButton>
					</Tooltip>
				);
			},
		},
	];

	const onUpdateSuccess = () => {
		setInventoryModal({
			open: false,
			data: {},
		});
		fetchInventoryByProduct(productId);
	};

	console.log(inventoryModal);

	return (
		<>
			<Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							// hideFooter={true}
							hideFooterPagination={true}
							hideFooterSelectedRowCount={true}
							rowHeight={70}
							sortingMode="server"
							// sortModel={sortModel}
							// onSortModelChange={handleSortModelChange}
							loading={loading}
							// checkboxSelection

							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
								Footer: () => CustomFooter(sumInventory),
							}}
						/>
					</Grid>
				</Grid>
				{/* {pagination.total !== rows?.length && ( */}
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "20px",
					}}
				>
					<Pagination
						page={pagination.page}
						count={Math.ceil(pagination.total / pagination.limit)}
						onChange={handleChangePage}
						color="primary"
						variant="outlined"
						shape="rounded"
					/>
				</Box>
				{/* )} */}
			</Box>
			<DialogMain
				title={"Cập nhật số lượng tồn kho"}
				open={inventoryModal.open}
				fullWidth
				onClose={() => {
					setInventoryModal({
						open: false,
						data: {},
					});
				}}
			>
				<UpdateInventory formData={inventoryModal?.data} onUpdateSuccess={onUpdateSuccess} />
			</DialogMain>
		</>
	);
}

export default ProductInventory;
