import React from "react";
import classnames from "classnames/bind";
import styles from "./ProductQr.module.scss";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { formatVND } from "func";

const cx = classnames.bind(styles);
function ProductQr({ price, productName, qrCode, barCode }) {
	const checkFontSizeByLength = (nameLength) => {
		if (nameLength > 45) {
			return "8px";
		}
		if (nameLength > 30) {
			return "8px";
		}
		if (nameLength > 15) {
			return "8px";
		}

		return "14px";
	};
	return (
		<Stack className={cx("aspect__ratio")} flexDirection={"row"} gap={"2px"}>
			<Stack className={cx("qr__code")} alignContent="center" justifyContent={"center"}>
				<img src={qrCode} className={cx("qr__img")} alt="qrCode" />
			</Stack>
			<Stack flex={1} justifyContent={"center"} height={"100%"}>
				<Typography
					variant="h5"
					className={cx("product__name")}
					style={{
						fontSize: "8px",
					}}
				>
					{productName}
				</Typography>

				{/* Mã QR code  */}
				<Typography style={{ fontSize: "10px" }}>{barCode}</Typography>

				{/* <Typography
					style={{
						fontSize: "12px",
						fontWeight: "bold",
					}}
				>
					{formatVND(price)}
				</Typography> */}
			</Stack>
		</Stack>
	);
}

export default ProductQr;
