import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Checkbox,
	FormControl,
	FormLabel,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	Switch,
	Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import productAPI from "api/ProductAPI";
import UploadImage from "ui-component/UploadImage";
import uploadAPI from "api/UploadAPI";
import { Close, Watch } from "@mui/icons-material";
import { getStringWithCommasFromNumber } from "utils";
import { typeList } from "./data";
import { productTranslator } from "func";
import { PATTERN, PRODUCT_SCAN_SKIN_TYPE, SKIN_LEVEL } from "constants/common";
import UnitSelect from "ui-component/UnitSelct";
import { useSelector } from "react-redux";
const defaultTypeList = [
	{
		value: "NOTIFICATION",
		title: "Notification",
	},
	{
		value: "TERMS_CONDITIONS",
		title: "Terms & Conditions",
	},
	{
		value: "LOCATION",
		title: "Location",
	},
];
const TextValidation = styled("span")({
	color: "red",
});
const Required = styled("span")({
	color: "red",
});
function ProductModal({ ...props }) {
	const {
		isOpen,
		isClose,
		isLoading,
		formData,
		isUpdate,
		listSelect,
		getListTable,
		stateLocation,
	} = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));
	const {
		register,
		handleSubmit,
		errors,
		control,
		reset,
		setValue: setValueHookForm,

		getValues,
		clearErrors,

		watch,
	} = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
	});
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [editor, setEditor] = useState(null);
	const [type, setType] = useState("");
	const [typeProduct, setTypeProduct] = useState(typeList[0].value);

	// const [image, setImage] = useState(formData.image);
	// useEffect(() => {
	// 	setImage(formData.image);
	// }, [formData]);
	const defaultImageList = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
	const [imageList, setImageList] = useState(defaultImageList);

	const setImageInList = (file, idxImgInList) => {
		const imageListCopy = [...imageList];
		imageListCopy.splice(idxImgInList, 1, file);
		setImageList(imageListCopy);
	};
	useEffect(() => {
		if (isUpdate && formData.image) {
			const copyArrayUndefined = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
			copyArrayUndefined.splice(0, formData.image.length, ...formData.image);
			setImageList(formData.image.length === 0 ? defaultImageList : copyArrayUndefined);
		}
	}, [isOpen]);
	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleClose = () => {
		isClose(false);
	};
	const rx_live = /^\d*$/;
	const handleMaskValue = (value) => {
		const temp = value.split(",").join("");
		console.log(temp);
		if (rx_live.test(temp)) {
			return temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return temp;
		}
	};
	const removeComma = (numberString) => {
		return numberString.toString().split(",").join("");
	};
	const { unitId } = useSelector((state) => state.unitReducer);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	//Group multiple

	const [groupMulti, setGroupMulti] = useState([]);

	// const handleChangeSelectMulti = (event) => {
	// 	const array = event.target.value;
	// 	const unique = [...new Map(array.map((m) => [m.value, m])).values()];
	// 	console.log(unique);
	// 	setGroupMulti(unique);
	// };
	const handleChangeSelectMulti = (event) => {
		const {
			target: { value },
		} = event;
		setGroupMulti(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};

	const onSubmit = async (data) => {
		data.price = data.price === undefined ? "0" : data.price;
		data.sale_price = data.sale_price === undefined ? "0" : data.sale_price;

		try {
			setLoading(true);

			if (!isUpdate) {
				const urlImgList = [];

				for (let i = 0; i < imageList.length; i++) {
					if (imageList[i]) {
						const res = await handleUpload(i);
						urlImgList.push(res);
					}
				}

				data.sub_description = ckData;
				data.category_id = type;
				data.type = typeProduct;
				data.image = urlImgList;
				data.thumbnail = urlImgList.length > 0 ? urlImgList[0] : "";
				data.price = parseInt(data.price.split(",").join(""));
				data.sale_price = parseInt(Number(data.sale_price.split(",").join("")));
				data.inventory_amount = parseInt(data.inventory_amount.split(",").join(""));
				data.best_sale = data.hot;
				// data.level = data.level === "none" ? null : data.level;
				data.unit_id = unitId === "none" ? null : unitId;
				data.branch_id = branchActiveId;
				data.sort = !!data.sort === false ? null : data.sort;
				data.group = groupMulti;

				const response = await productAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			} else {
				const urlImgList = [];

				for (let i = 0; i < 4; i++) {
					if (imageList[i]) {
						if (typeof imageList[i].name === "string") {
							// Check imageList có phải là một file
							const res = await handleUpload(i);
							urlImgList.push(res);
						} else {
							//Không phải là File => là url hình ảnh, push lại vào mảng truyền lên
							urlImgList.push(imageList[i]);
						}
					}
				}

				data.sub_description = ckData;
				data.category_id = type;
				data.type = typeProduct;
				data.branch_id = branchActiveId;
				data.image = urlImgList;
				data.thumbnail = urlImgList.length > 0 ? urlImgList[0] : "";
				data.price = parseInt(data.price.split(",").join(""));
				// data.inventory_amount = parseInt(data.inventory_amount.split(",").join(""));
				data.sale_price = parseInt(Number(data.sale_price.split(",").join("")));
				data.best_sale = data.hot;
				// data.level = data.level === "none" ? null : data.level;
				data.unit_id = unitId === "none" ? null : unitId;
				data.sort = !!data.sort === false ? null : data.sort;
				data.group = groupMulti;

				const response = await productAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};
	// useEffect(() => {
	// 	if (image) {
	// 		setError({ type: "image", message: "" });
	// 	}
	// }, [image]);
	useEffect(() => {
		if (isUpdate) {
			// let groupMultiple = [];
			// if (formData.group.length !== 0 && Array.isArray(formData.group)) {
			// 	PRODUCT_SCAN_SKIN_TYPE.map((it) => {
			// 		if (formData.group.includes(it.value)) {
			// 			groupMultiple.push({
			// 				value: it.value,
			// 				text: it.text,
			// 			});
			// 		}
			// 	});
			// }

			// if (!formData.group) {
			// 	groupMultiple = [];
			// }
			setCkData(formData?.sub_description || "");
			setType(formData?.category_id);
			setTypeProduct(formData?.type);
			setGroupMulti(formData.group || []);
		} else {
			setCkData("");
			setType(
				stateLocation?.isFromCategory ? stateLocation?.id : selectList && selectList[0].value
			);
			setTypeProduct(typeList[0].value);
		}
		return () => {
			setImageList(defaultImageList);
			setGroupMulti([]);
			reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const translator = (text) => {
		return `abeauty.product.dialog_form.${text}`;
	};
	const handleUpload = async (idx) => {
		const formData = new FormData();
		formData.append("image", imageList[idx]);

		try {
			const response = await uploadAPI.uploadImage(formData);
			return response.results.object.url;
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};

	const MenuProps = {
		PaperProps: {
			style: {
				pt: 0,
				pb: 0,
			},
		},
		MenuListProps: {
			style: {
				pt: 0,
				pb: 0,
			},
		},
	};

	return (
		<div>
			<Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle>
					{isUpdate ? t(translator("header_edit")) : t(translator("header_create"))}
				</DialogTitle>
				<form>
					<DialogContent>
						<Grid
							container
							spacing={3}
							style={{
								marginBottom: 20,
							}}
						>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="title"
									label={t(translator("title")) + "*"}
									type="text"
									fullWidth
									defaultValue={isUpdate ? formData?.title : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.toString().startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Vui lòng điền tên dịch vụ",
										},
									})}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								{errors.title && <TextValidation>{errors.title.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="demo-simple-select-autowidth-label">Danh mục</InputLabel>
									<Select
										labelId="demo-simple-select-autowidth-label"
										id="demo-simple-select-autowidth"
										onChange={handleChange}
										defaultValue={
											isUpdate
												? formData?.category_id
												: stateLocation?.isFromCategory
												? stateLocation?.id
												: type
										}
										label="Danh mục sản phẩm"
										name="category_id"
									>
										{Array.isArray(selectList) &&
											selectList.map((item) => (
												<MenuItem key={item.value} value={item.value}>
													{t(item.title)}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="demo-simple-select-autowidth-label">Loại</InputLabel>
									<Select
										labelId="demo-simple-select-autowidth-label"
										id="demo-simple-select-autowidth"
										onChange={(e) => {
											setTypeProduct(e.target.value);

											setValueHookForm("price", "0");
											clearErrors(["price", "sale_price"]);
											setValueHookForm("sale_price", "0");
										}}
										defaultValue={isUpdate ? formData?.type : typeProduct}
										label="Danh mục sản phẩm"
										name="type"
									>
										{Array.isArray(typeList) &&
											typeList.map((item) => (
												<MenuItem key={item.value} value={item.value}>
													{productTranslator(item.value)}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="price"
									label={t(translator("price"))}
									type="text"
									fullWidth
									required
									inputMode="numeric"
									defaultValue={isUpdate ? getStringWithCommasFromNumber(formData?.price) : ""}
									variant="standard"
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									disabled={typeProduct === "GIFT"}
									inputRef={register({
										required: {
											value: true,
											message: "Vui lòng điền giá sản phẩm",
										},
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số dương",
										},
										validate: {
											minValue: (v) => {
												if (typeProduct === "GIFT") {
													return;
												} else {
													return Number(removeComma(v)) > 0 || "Giá sản phẩm phải lớn hơn  0 đ";
												}
											},
										},
									})}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								{errors.price && <TextValidation>{errors.price.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="sale_price"
									label={t(translator("sale_price"))}
									type="text"
									fullWidth
									inputMode="numeric"
									defaultValue={isUpdate ? getStringWithCommasFromNumber(formData?.sale_price) : ""}
									variant="standard"
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									disabled={!watch("price") || typeProduct === "GIFT"}
									inputRef={register({
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số dương",
										},

										validate: {
											lessThanPrice: (v) => {
												const priceValue = getValues("price");
												return (
													Number(removeComma(v)) <= Number(removeComma(priceValue)) ||
													"Giá giảm phải nhỏ hơn giá gốc"
												);
											},
											moreThanZero: (v) => {
												return Number(removeComma(v)) >= 0 || "Giá trị phải lớn hơn hoặc bằng 0";
											},
										},
									})}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								{errors.sale_price && <TextValidation>{errors.sale_price.message}</TextValidation>}
							</Grid>
							{!isUpdate && (
								<Grid item xs={4}>
									<TextField
										margin="dense"
										name="inventory_amount"
										label={t(translator("inventory_amount"))}
										type="text"
										fullWidth
										inputMode="numeric"
										required
										onChange={(e) => {
											e.target.value = handleMaskValue(e.target.value);
										}}
										defaultValue={
											isUpdate ? getStringWithCommasFromNumber(formData?.inventory_amount) : ""
										}
										variant="standard"
										inputRef={register({
											required: {
												value: true,
												message: "Vui lòng nhập số lượng tồn kho",
											},
											pattern: {
												value: /^[0-9,]*$/,
												message: "Giá trị phải là một số",
											},
										})}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									{errors.inventory_amount && (
										<TextValidation>{errors.inventory_amount.message}</TextValidation>
									)}
								</Grid>
							)}
							<Grid item xs={4}>
								<UnitSelect
									isUpdate={isUpdate}
									formData={formData}
									nameField={"unit_id"}
									label={"Đơn vị"}
									labelId={"unit_id"}
								/>
							</Grid>
							{/* <Grid item xs={4}>
								<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="group-label">Nhóm sản phẩm</InputLabel>

									<Select
										variant="standard"
										labelId="group-label"
										name="group"
										id={"group-multi"}
										defaultValue={isUpdate ? formData?.group : "none"}
										multiple
										value={groupMulti}
										onChange={handleChangeSelectMulti}
										renderValue={(selected) => {
											return selected?.map((item) => item.text).join(", ");
										}}
									>
										<MenuItem value={"none"} onClick={() => setGroupMulti([])}>
											Không chọn
										</MenuItem>
										{Array.isArray(PRODUCT_SCAN_SKIN_TYPE) &&
											PRODUCT_SCAN_SKIN_TYPE.map((item) => (
												<MenuItem key={item.value} value={item}>
													<Checkbox checked={groupMulti?.some((it) => it.value === item.value)} />
													<ListItemText primary={item.text} />
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid> */}
							<Grid item xs={4}>
								<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="group-label">Nhóm sản phẩm</InputLabel>

									<Select
										variant="standard"
										labelId="group-label"
										name="group"
										id={"group-multi"}
										defaultValue={isUpdate ? formData?.group : "none"}
										multiple
										MenuProps={MenuProps}
										value={groupMulti}
										onChange={handleChangeSelectMulti}
										renderValue={(selected) => {
											let selectedString = "";
											PRODUCT_SCAN_SKIN_TYPE.map((it) => {
												if (selected.includes(it.value)) {
													selectedString += `${it.text}, `;
												}
											});
											return selectedString;
										}}
										InputLabelProps={{
											shrink: true,
										}}
									>
										{/* <MenuItem value={"none"} onClick={() => setGroupMulti([])}>
											Không chọn
										</MenuItem> */}
										{Array.isArray(PRODUCT_SCAN_SKIN_TYPE) &&
											PRODUCT_SCAN_SKIN_TYPE.map((item) => (
												<MenuItem key={item.value} value={item.value}>
													<Checkbox checked={groupMulti?.some((it) => it === item.value)} />
													<ListItemText primary={item.text} />
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
							{/* <Grid item xs={4}>
								<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="level-label">Sản phẩm dành cho tình trạng da</InputLabel>

									<Controller
										defaultValue={isUpdate ? (formData?.level ? formData?.level : "none") : "none"}
										as={
											<Select labelId="level-label" label="Sản phẩm dành cho tình trạng da">
												<MenuItem value={"none"}>Không chọn</MenuItem>
												{Array.isArray(SKIN_LEVEL) &&
													SKIN_LEVEL.map((item) => (
														<MenuItem key={item.value} value={item.value}>
															{item.text}
														</MenuItem>
													))}
											</Select>
										}
										name="level"
										control={control}
									/>
								</FormControl>
							</Grid> */}
							<Grid item xs={4}>
								<Controller
									name="sort"
									control={control}
									rules={{
										validate: {
											stringSpace: (v) =>
												!v?.toString()?.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
											// biggerZero: (v) => v > 0 || "Số thứ tự phải lớn hơn 0",
										},
										pattern: {
											value: PATTERN.IS_NUMBER,
											message: "Giá trị phải là một số dương",
										},
										// required: {
										// 	value: true,
										// 	message: "Vui lòng điền thứ tự",
										// },
									}}
									defaultValue={isUpdate ? formData.sort : ""}
									as={
										<TextField
											margin="dense"
											label={"Thứ tự"}
											type="text"
											fullWidth
											required
											variant="standard"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									}
								/>

								{errors.sort && <TextValidation>{errors.sort.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<Controller
									name="barcode"
									control={control}
									rules={{
										validate: {
											stringSpace: (v) =>
												!v?.toString()?.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
											// biggerZero: (v) => v > 0 || "Số thứ tự phải lớn hơn 0",
										},
										pattern: {
											value: PATTERN.IS_NUMBER,
											message: "Giá trị phải là một số dương",
										},
										// required: {
										// 	value: true,
										// 	message: "Vui lòng điền mã sản phẩm",
										// },
									}}
									defaultValue={isUpdate ? formData.barcode : ""}
									as={
										<TextField
											margin="dense"
											label={"Mã vạch"}
											type="text"
											fullWidth
											variant="standard"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									}
								/>

								{errors.barcode && <TextValidation>{errors.barcode.message}</TextValidation>}
							</Grid>
							<Grid item xs={12}>
								<FormLabel component="legend">Hot deal</FormLabel>
								<Switch
									name="hot"
									inputRef={register()}
									defaultChecked={isUpdate ? formData.best_sale : false}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="ul" color="warning">
									Hình ảnh
								</Typography>
							</Grid>
							{Array.isArray(imageList) &&
								imageList.map((image, idx) => {
									return (
										<Grid
											item
											xs={6}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												mb: "20px",
												flexDirection: "column",
											}}
										>
											<UploadImage
												image={image}
												setImage={(file) => {
													return setImageInList(file, idx);
												}}
												aspectRatio="1:1"
												levelAspectRatio={6}
												id={idx}
											>
												<ul>
													<li>Định dạng file: [.JPEG, .JPG, .PNG]</li>
													<li>Tỉ lệ hình ảnh: 600x600</li>
												</ul>
											</UploadImage>
										</Grid>
									);
								})}
							{imageList?.length === 0 && <TextValidation> Vui lòng chọn hình ảnh</TextValidation>}
							<Grid item xs={12}>
								<TextField
									margin="dense"
									name="description"
									label={t(translator("description"))}
									type="text"
									defaultValue={isUpdate ? formData.description : ""}
									fullWidth
									multiline
									minRows={4}
									variant="standard"
									inputRef={register()}
								/>
							</Grid>
						</Grid>
						<Box>
							<div className="App">
								<label
									style={{
										marginBottom: "20px",
									}}
								>
									Thông tin thêm
								</label>
								<CKEditor
									onReady={(editor) => {
										// Insert the toolbar before the editable area.
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										setEditor(editor);
									}}
									onError={({ willEditorRestart }) => {
										// If the editor is restarted, the toolbar element will be created once again.
										// The `onReady` callback will be called again and the new toolbar will be added.
										// This is why you need to remove the older toolbar.
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setCkData(data);
									}}
									editor={DecoupledEditor}
									data={ckData}
									config={{
										ckfinder: {
											uploadUrl: `https://hitek-02.hitek.com.vn:6062/api/v1/image/upload_ckeditor`,
										},

										mediaEmbed: {
											previewsInData: true,
											elementName: "iframe",
										},
									}}
								/>
							</div>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => isClose(false)} variant="contained" color="secondary">
							{t(`cancel`)}
						</Button>
						<LoadingButton
							onClick={handleSubmit(onSubmit)}
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="contained"
						>
							{isUpdate ? t(translator("update")) : t(translator("create"))}
						</LoadingButton>
					</DialogActions>

					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showErr.state}
						onClose={() => setShowErr({ state: false })}
						// action={action}
						autoHideDuration={5000}
						severity="error"
					>
						<Alert severity="error">{showErr.message}</Alert>
					</Snackbar>
				</form>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default ProductModal;
