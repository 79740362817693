export const data = [
	{
		title: "Massage giảm béo .....",
		priorityId: null,
		startDate: new Date(2022, 6, 16, 10, 0, 0),
		endDate: new Date(2022, 6, 16, 11, 30, 0),
		activity: "WAITING",
		id: 48,
	},
	{
		title: "Submit Questions Regarding New NDA",
		priorityId: null,
		startDate: new Date(2022, 6, 16, 10, 0, 0),
		endDate: new Date(2022, 6, 16, 12, 30, 0),
		activity: "PROCESSING",

		id: 47,
	},
	{
		title: "Submit Questions Regarding New NDA",
		priorityId: null,
		startDate: new Date(2022, 6, 16, 10, 0, 0),
		endDate: new Date(2022, 6, 16, 11, 30, 0),
		activity: "CONFIRMED",

		id: 47,
	},
	{
		title: "Submit Questions Regarding New NDA",
		priorityId: "11b74070-0268-11ed-9cd9-2b12af73a73e",
		startDate: new Date(2022, 6, 16, 14, 0, 0),
		endDate: new Date(2022, 6, 16, 16, 30, 0),
		activity: "ASSIGNED",

		id: 1,
	},
	{
		title: "Submit Questions Regarding New NDA",
		priorityId: "a76f86f0-03ed-11ed-bb86-e5b013853cf8",
		startDate: new Date(2022, 6, 16, 15, 0, 0),
		endDate: new Date(2022, 6, 16, 16, 30, 0),
		activity: "ASSIGNED",

		id: 1,
	},
	{
		title: "Submit Questions Regarding New NDA",
		priorityId: "984f9c00-03ed-11ed-bb86-e5b013853cf8",
		startDate: new Date(2022, 6, 16, 8, 0, 0),
		endDate: new Date(2022, 6, 16, 12, 30, 0),
		activity: "COMPLETED",

		id: 48,
	},
	{
		title: "Submit Questions Regarding New NDA",
		priorityId: "9c1fdde0-03ed-11ed-bb86-e5b013853cf8",
		startDate: new Date(2022, 6, 16, 11, 0, 0),
		endDate: new Date(2022, 6, 16, 12, 30, 0),
		activity: "CANCELED",

		id: 49,
	},
];

export const priorityData = [
	{
		text: "Chưa xác định",
		id: null,
	},
	{
		text: "Giường 1",
		id: 1,
	},
	{
		text: "Giường 2",
		id: 2,
	},
	{
		text: "Giường 3",
		id: 3,
	},
	{
		text: "Giường 4",
		id: 4,
	},
	{
		text: "Giường 5",
		id: 5,
	},
	{
		text: "Giường 6",
		id: 6,
	},
	{
		text: "Giường 7",
		id: 7,
	},
	{
		text: "Giường 8",
		id: 8,
	},
	{
		text: "Giường 9",
		id: 9,
	},
	{
		text: "Giường 10",
		id: 10,
	},
];

export const activityList = [
	{
		activity: "WAITING",
		title: "Đang chờ",
		color: "#f2f2f2",
	},
	{
		activity: "PROCESSING",
		title: "Đã xác nhận lịch",
		color: "#ffc107",
	},
	{
		activity: "CONFIRMED",
		title: "Đã xác nhận với khách hàng",
		color: "#90caf9",
	},
	// {
	// 	activity: "ASSIGNED",
	// 	title: "Đã gán nhân viên",
	// 	color: "#536dfe",
	// 	isHide: true,
	// 	isDark: true,
	// },
	{
		activity: "COMPLETED",
		title: "Hoàn thành",
		color: "#00c853",
		isDark: true,
	},
	{
		activity: "CANCELED",
		title: "Hủy bỏ",
		color: "#f44336",
		isDark: true,
	},
];
