import { use } from "i18next";
import { useId } from "react";
import {
	GET_EMPLOYEE_INFO,
	RELOAD_BOOKING_PAGE,
	SET_ASSIGN_DATA,
	SET_BOOKING_DETAILS,
	SET_CHECK_LIST,
	UPDATE_CHECK_LIST,
	UPDATE_COUNT_CHECK_LIST,
	UPDATE_NOTE_ADMIN_CHECK_LIST,
	UPDATE_VALUE_CHECK_LIST,
} from "store/actions/BookingActions/types";
import { v4 as uuidv4 } from "uuid";

const initialState = {
	checkBoxList: [],
	assignReloader: 0,
	bookingDetails: [],
	assignData: [],
	bedGroup: [],
};

const bookingReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_CHECK_LIST: {
			let checkListUpdate = [...state.checkBoxList];
			let initialData = {
				id: uuidv4(),
				combo_id: null,
				service_id: payload.service.id,
				note: "Đây không thuộc combo",
				amount: 0,
				count: 1,
				status: false,
				service: payload.service,
			};

			if (payload.checked === true) {
				checkListUpdate.push(initialData);
			} else if (payload.checked === false) {
				checkListUpdate = checkListUpdate.filter((item) => item.service.id !== payload.service?.id);
			} else if (payload.checked === "reset") {
				//Nếu checked là một strign reset thì reset checkList = []
				checkListUpdate = [];
			}

			return { ...state, checkBoxList: checkListUpdate };
		}

		case UPDATE_CHECK_LIST: {
			let checkListUpdate = [...state.checkBoxList];
			let initialData = {
				combo_id: null,
				note: "Đây không thuộc combo",
				amount: 0,
				count: 1,
				status: false,
			};
			//Xử lý payload thành mảng combo_service_item

			payload.booking_details.map((service) => {
				if (!service.combo_service_id) {
					checkListUpdate.push({
						...initialData,
						count: service.count,
						id: service.id,
						service_id: service.service_id,
						service: {
							...service,
							id: service.service_id,
							price_deducted: service?.price_after_discount,
						},
					});
				}
			});

			return { ...state, checkBoxList: checkListUpdate };
		}

		case UPDATE_VALUE_CHECK_LIST: {
			let checkListUpdate = [...state.checkBoxList];
			const { data, val, name } = payload;
			//INCREASE COUNT
			checkListUpdate = checkListUpdate.map((item) => {
				console.log(item);
				if (item.id === data.id) {
					return {
						...item,
						[name]: val,
					};
				} else {
					return item;
				}
			});
			return { ...state, checkBoxList: checkListUpdate };
		}
		case RELOAD_BOOKING_PAGE: {
			const stateIncrsea = state.assignReloader + 1;
			return { ...state, assignReloader: stateIncrsea };
		}

		case SET_BOOKING_DETAILS:
			return { ...state, bookingDetails: payload };
		case GET_EMPLOYEE_INFO:
			const bookingDetailsUpdate = state.bookingDetails.map((item) => {
				const employeeInfo =
					item.id === payload.booking_detail_id ? payload.employee_info : item.employee_info;
				return {
					...item,
					employee_info: employeeInfo,
				};
			});

			return { ...state, bookingDetails: bookingDetailsUpdate };

		case SET_ASSIGN_DATA:
			let assignDataUpdate = [...state.assignData];

			if (payload === "reset") {
				assignDataUpdate = [];
			} else {
				const idx = assignDataUpdate.findIndex(
					(item) => item.booking_detail_id === payload.booking_detail_id
				);
				if (idx > -1) {
					assignDataUpdate.splice(idx, 1, payload);
				} else {
					assignDataUpdate = [...assignDataUpdate, payload];
				}
			}
			return { ...state, assignData: assignDataUpdate };

		default:
			return state;
	}
};

export default bookingReducer;
