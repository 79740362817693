import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Close } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, FormLabel, Grid, Switch, Tab, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import comboServiceAPI from "api/ComboServiceAPI";
import scanSkinAPI from "api/ScanSkinAPI";
import uploadAPI from "api/UploadAPI";
import { PATTERN } from "constants/common";
import { useSnackbar } from "notistack";
import { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UploadImage from "ui-component/UploadImage";
import { getStringWithCommasFromNumber } from "utils";
const defaultTypeList = [
	{
		value: "NOTIFICATION",
		title: "Notification",
	},
	{
		value: "TERMS_CONDITIONS",
		title: "Terms & Conditions",
	},
	{
		value: "LOCATION",
		title: "Location",
	},
];
const TextValidation = styled("span")({
	color: "red",
});
const Required = styled("span")({
	color: "red",
});
function ScanSkinContentModal({ ...props }) {
	const { isOpen, isClose, isLoading, formData, isUpdate, listSelect, getListTable } = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));

	const { register, handleSubmit, errors, control, reset, setValue, setError, getValues, watch } =
		useForm({
			mode: "onChange",
			reValidateMode: "onChange",
			shouldUnregister: false,
		});
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [editor, setEditor] = useState(null);
	const [type, setType] = useState("NOTIFICATION");
	// const [image, setImage] = useState(formData.image);
	// useEffect(() => {
	// 	setImage(formData.image);
	// }, [formData]);
	const defaultImageList = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
	const [imageList, setImageList] = useState(defaultImageList);

	const setImageInList = (file, idxImgInList) => {
		const imageListCopy = [...imageList];
		imageListCopy.splice(idxImgInList, 1, file);
		setImageList(imageListCopy);
	};

	//Multi upload
	// useEffect(() => {
	// 	if (isUpdate) {
	// 		const copyArrayUndefined = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
	// 		copyArrayUndefined.splice(0, formData.image_list.length, ...formData.image_list);
	// 		setImageList(formData.image_list.length === 0 ? defaultImageList : copyArrayUndefined);
	// 	}
	// }, [formData]);

	//Create Combo Items List

	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleClose = () => {
		isClose(false);
	};
	const rx_live = /^\d*$/;
	const handleMaskValue = (value) => {
		const temp = value.split(",").join("");
		if (rx_live.test(temp)) {
			return temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return temp;
		}
	};
	const removeComma = (numberString) => {
		return numberString.toString().split(",").join("");
	};
	const onSubmit = async (data) => {
		if (!img) {
			return;
		}
		try {
			setLoading(true);

			if (!isUpdate) {
				const urlSingle = await handleUploadSingle(img);

				data.image = urlSingle;

				const response = await comboServiceAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
					setLoading(false);
				}
			} else {
				let imgUrl;

				if (typeof img === "string") {
					imgUrl = img;
				} else {
					imgUrl = await handleUploadSingle(img);
				}
				data.image = imgUrl;

				const response = await scanSkinAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};

	useLayoutEffect(() => {
		if (isUpdate) {
			setImg(formData.image);

			setCkData(formData?.note || "");
		} else {
			setCkData("");
			setType(selectList && selectList[0].value);
		}

		return () => {
			// setImageList(defaultImageList);
			reset();
			setImg();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const translator = (text) => {
		return `abeauty.service.dialog_form.${text}`;
	};

	const [img, setImg] = useState();
	const handleUploadSingle = async (img) => {
		const formData = new FormData();
		formData.append("image", img);

		try {
			const response = await uploadAPI.uploadImage(formData);
			return response.results.object.url;
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};

	return (
		<Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
			<DialogTitle>
				<Typography variant="h3" marginBottom={"20px"}>
					{isUpdate ? "Cập nhật lời khuyên" : "Tạo lời khuyên"}
				</Typography>
			</DialogTitle>
			<form style={{ width: "100%", overflow: "auto" }}>
				<DialogContent sx={{ width: "100%", overflowX: "hidden", height: "500px" }}>
					<>
						<Grid
							container
							spacing={3}
							style={{
								marginBottom: 20,
							}}
						>
							<Grid item xs={12}>
								<TextField
									margin="dense"
									name="title"
									label={"Chủ đề*"}
									type="text"
									fullWidth
									defaultValue={isUpdate ? formData?.title : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Vui lòng tên gói",
										},
									})}
								/>
								{errors.title && <TextValidation>{errors.title.message}</TextValidation>}
							</Grid>

							<Grid item xs={12}>
								<TextField
									margin="dense"
									name="des"
									label={"Lời khuyên"}
									type="text"
									defaultValue={isUpdate ? formData.des : ""}
									fullWidth
									multiline
									minRows={4}
									variant="standard"
									inputRef={register()}
								/>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "start",
								mb: "20px",
								flexDirection: "row",
							}}
						>
							<Typography variant="h5" sx={{ color: "gray", fontWeight: 400 }}>
								Hình ảnh
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								mb: "20px",
								flexDirection: "column",
							}}
						>
							<UploadImage
								image={img}
								setImage={(file) => {
									return setImg(file);
								}}
								aspectRatio="1:1"
								levelAspectRatio={8}
								id={1}
							/>
						</Grid>
						{!img && <TextValidation>Vui lòng chọn hình ảnh</TextValidation>}
					</>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose()} variant="contained" color="secondary">
						{t(`cancel`)}
					</Button>
					<LoadingButton
						onClick={handleSubmit(onSubmit)}
						// endIcon={<SaveIcon />}
						loading={loading}
						loadingPosition="end"
						variant="contained"
					>
						{isUpdate ? t(translator("update")) : t(translator("create"))}
					</LoadingButton>
				</DialogActions>

				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={showErr.state}
					onClose={() => setShowErr({ state: false })}
					// action={action}
					autoHideDuration={5000}
					severity="error"
				>
					<Alert severity="error">{showErr.message}</Alert>
				</Snackbar>
			</form>
			<Close
				sx={{
					position: "absolute",
					top: "10px",
					right: "10px",
					cursor: "pointer",
				}}
				onClick={() => handleClose()}
			/>
		</Dialog>
	);
}

export default ScanSkinContentModal;
