import { Box } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { IMAGES } from "assets/images/imagePath";

const PreviewImg = styled("img")({
	width: "100%",
	borderRadius: "8px",
	maxWidth: "600px",
	padding: "20px",
	objectFit: "cover",
	objectPosition: "center",
});
function MultipleImage({ imageZoom }) {
	const [imageActive, setImageActive] = useState(0);
	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
			<Box>
				<PreviewImg
					src={imageZoom[imageActive] || IMAGES.img_default}
					onError={(e) => (e.target.src = IMAGES.img_default)}
					alt="zoom"
					style={{
						borderRadius: "8px",
					}}
					className={"aspect1-1"}

					// className={classNameImg}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					overflow: "auto",
					maxWidth: "100%",
				}}
			>
				{Array.isArray(imageZoom) &&
					imageZoom.map((image, idx) => {
						const activeStyle =
							imageActive === idx
								? {
										border: "1px solid black",
								  }
								: {};
						return (
							<PreviewImg
								onClick={() => {
									setImageActive(idx);
								}}
								style={{
									cursor: "pointer",
									margin: "20px 10px",
									padding: "0",
									width: "25%",
									...activeStyle,
								}}
								className={"aspect1-1"}
								src={image || IMAGES.img_default}
								onError={(e) => (e.target.src = IMAGES.img_default)}
								alt="imageSmall"
							/>
						);
					})}
			</Box>
		</Box>
	);
}

export default MultipleImage;
