import { Grid, Pagination, Button, Chip } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "components/noRows";
import _debounce from "lodash/debounce";
import moment from "moment";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import { showSortStyle, SelectCustom } from "views/utilities/CustomStyle";
import userAPI from "../../api/UserAPI";
import { SearchComponent } from "../../components/search";
import { getNumberWithCommas } from "../../utils";
import { Image, Select as SelectAntd, Tooltip } from "antd";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import UserBooking from "./UserBooking";
import DialogMain from "ui-component/modal-form/DialogMui";
import UserInformation from "ui-component/modal-form/ModalAntd/DetailInformation/UserInformation";
import { useDispatch } from "react-redux";
import { actSetUserInformation } from "store/actions/UserAction";
import { Check, CheckBox, CheckBoxRounded, CheckCircleOutline } from "@mui/icons-material";
import { removeAccents } from "func";
import { IMAGES } from "assets/images/imagePath";
import ImageModal from "ui-component/modal-form/ImageModal";
import UserModal from "ui-component/modal-form/UserModal";
import AccountDeleteConfirm from "components/AccountDeleteConfirm";
import walletAPI from "api/WalletAPI";

const { Option } = SelectAntd;

const actionList = [
	{
		value: "delete",
		title: "delete",
	},
	{
		value: "edit",
		title: "Chỉnh sửa thông tin",
	},
	{
		value: "information",
		title: "Xem chi tiết khách hàng",
	},
	{
		value: "user_booking",
		title: "Xem lịch sử đặt lịch",
	},
];

const searchList = [
	// {
	// 	value: "username",
	// 	title: "username",
	// },
	{
		value: "$user.phone$",
		title: "SĐT",
	},
	{
		value: "$user.fullname$",
		title: "fullname",
	},

	{
		value: "$employee.fullname$",
		title: "Tên nhân viên",
	},
	// {
	// 	value: "login_type",
	// 	title: "login_type",
	// },
];

const WalletHis = () => {
	const { enqueueSnackbar } = useSnackbar();
	const modalContext = useContext(ModalContext);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(0);
	const [data, setData] = useState();
	const [isUpdate, setIsUpdate] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState([]);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});
	const [openModalImage, setOpenModalImage] = useState(false);
	const [imgQr, setImgQr] = useState("");
	//user booking

	const [isOpenUserBooking, setIsOpenUserBooking] = useState(false);

	const columns = [
		// {
		// 	field: "order",
		// 	headerName: t(`order`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },

		// {
		// 	field: "username",
		// 	headerName: t(`username`),
		// 	minWidth: 250,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		{
			field: "fullnameEmploy",
			headerName: "Tên nhân viên thay đổi",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.row?.employee?.fullname}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.row?.employee?.fullname}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "phone",
			headerName: "SĐT khách hàng",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.row?.user?.phone}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.row?.user?.phone}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "fullname",
			headerName: "Tên khách hàng",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.row?.user?.fullname}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.row?.user?.fullname}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "wallet_before",
			headerName: "Hạn mức dịch vụ trước",
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={`${getNumberWithCommas(params?.value)} đ`}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{`${getNumberWithCommas(params?.value)} đ`}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "wallet_after",
			headerName: "Hạn mức dịch vụ sau",
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.value ? `${getNumberWithCommas(params?.value)} đ` : "Không thay đổi"}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.value ? `${getNumberWithCommas(params?.value)} đ` : "Không thay đổi"}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "wallet_rose_before",
			headerName: "Ví hoa hồng trước",
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={`${getNumberWithCommas(params?.value)} đ`}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{`${getNumberWithCommas(params?.value)} đ`}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "wallet_rose_after",
			headerName: "Ví hoa hồng sau",
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.value ? `${getNumberWithCommas(params?.value)} đ` : "Không thay đổi"}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.value ? `${getNumberWithCommas(params?.value)} đ` : "Không thay đổi"}
					</Box>
				</Tooltip>
			),
		},

		// {
		// 	field: "login_type",
		// 	headerName: "Nền tảng đăng nhập",
		// 	minWidth: 250,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian thay đổi",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<Tooltip
					title={moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
					</Box>
				</Tooltip>
			),
		},
		// {
		// 	field: "actions",
		// 	headerName: t(`actions`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	minWidth: 200,
		// 	renderCell: (params) => (
		// 		<>
		// 			<SelectCustom
		// 				value={t(`select_actions`)}
		// 				onChange={(value) => onChangeAction(value, params.id, params)}
		// 				style={{ width: 150 }}
		// 			>
		// 				{actionList?.map((item) => (
		// 					<Option key={item?.value}>{t(`${item.title}`)}</Option>
		// 				))}
		// 			</SelectCustom>
		// 		</>
		// 	),
		// },
	];

	const handleUpdateCollab = async (id) => {
		try {
			const res = await userAPI.update(id, {
				is_collab: true,
			});
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListWalletHis();
		} catch (err) {
			enqueueSnackbar(err.message || "Có lỗi xảy ra", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};
	const handleOpenChangeAccept = (id) => {
		modalContext.showDeleteConfirm(
			id,

			() => handleUpdateCollab(id),
			"Bạn có chắc muốn khách hàng này trở thành cộng tác viên",
			"Chắc chắn"
		);
	};

	const getListWalletHis = async () => {
		setIsLoading(true);
		let filter = "{}";
		let order = '[["created_at", "desc"]]';
		// if (queryParams?.username) {
		// 	filter = `{"username":{"$iLike":"%${queryParams?.username}%"}}`;
		// }
		// if (queryParams?.fullname) {
		// 	filter = `{"fullname":{"$iLike":"%${queryParams?.fullname}%"}}`;
		// }
		// if (queryParams?.phone?.trim()) {
		// 	filter = `{"phone":{"$iLike":"%${queryParams?.phone.trim()}%"}}`;
		// }
		// if (queryParams?.email) {
		// 	filter = `{"email":{"$iLike":"%${queryParams?.email}%"}}`;
		// }

		if (queryParams?.[searchBy]) {
			filter = `{"${searchBy}":{"$iLike":"%${searchValue.trim()}%"}}`;
		}

		if (queryParams?.sortBy) {
			order = `[["created_at_unix_timestamp","desc"]]`;
		}

		try {
			const res = await walletAPI.getList({
				fields:
					'["$all", {"employee": ["fullname", "$paranoid"]}, {"user": ["fullname","phone", "$paranoid"]}]',
				order,
				filter,
				limit: pagination.limit,
				page: queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};

	const [deleteUser, setDeleteUser] = useState({
		open: false,
		username: "",
	});
	const handleOpenRemoveModal = (id, row) => {
		setDeleteUser({
			id: id,
			open: true,
			username: row.username,
		});
	};

	const handleDelete = async (id, username) => {
		if (deleteUser.username !== username) {
			return;
		} else {
			try {
				await userAPI.remove(id);
				enqueueSnackbar(t(`success`), {
					variant: "success",
					autoHideDuration: 2000,
				});
				setDeleteUser("");
				await getListWalletHis();
			} catch (error) {
				enqueueSnackbar(error?.message || t(`error`), {
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		}
	};

	const handleUpdate = (data) => {
		setData(data);
		setOpen(true);
		setIsUpdate(true);
	};

	const onChangeAction = (value, id, params) => {
		value === "edit" && handleUpdate(params.row);
		value === "delete" && handleOpenRemoveModal(id, params.row);
		value === "information" && handleOpenInforModal(params.row);
		value === "user_booking" && handleOpenUserBooking(params.row);
	};
	const [information, setInformation] = useState({
		visible: false,
	});
	const dispatch = useDispatch();
	const handleOpenInforModal = (data) => {
		//	Example DATA
		dispatch(actSetUserInformation(data));
		setInformation((prev) => ({
			data: data,
			visible: true,
			userId: data.id,
		}));

		setIsOpenUserBooking(false);
	};

	const refPage = useRef(1);

	const handleOpenUserBooking = (data) => {
		setSearchBy("");
		setSearchValue("");
		refPage.current = pagination.page;
		handleChangePage(null, 1);
		setInformation((prev) => ({
			...prev,
			visible: true,
			userId: data.id,
		}));

		setIsOpenUserBooking(true);
	};

	useEffect(() => {
		if (information.visible) {
		} else {
			handleChangePage(null, refPage.current);
			refPage.current = 1;
		}
	}, [information.visible]);

	const handleRemoveMulti = () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await userAPI.removeMulti({
						items: JSON.stringify(selectList),
					});
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListWalletHis();
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	useEffect(() => {
		if (queryParams.hasOwnProperty("username")) {
			setSearchBy("username");
			setSearchValue(queryParams?.username);
		}
		if (queryParams.hasOwnProperty("email")) {
			setSearchBy("email");
			setSearchValue(queryParams?.email);
		}
		if (queryParams.hasOwnProperty("phone")) {
			setSearchBy("phone");
			setSearchValue(queryParams?.phone);
		}
		if (queryParams.hasOwnProperty("fullname")) {
			setSearchBy("fullname");
			setSearchValue(queryParams?.fullname);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		getListWalletHis();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams]);

	return (
		<>
			<MainCard>
				<HeaderActions title={"Lịch sử thay đổi ví"} setIsUpdate={setIsUpdate} setOpen={setOpen} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>
					{/* 
					<Button
						variant={selectList.length > 0 ? "contained" : "outlined"}
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							// checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<DialogMain
				maxWidth={"lg"}
				title={isOpenUserBooking ? "Lịch sử lịch hẹn" : "Chi tiết khách hàng"}
				fullWidth
				open={information.visible}
				onClose={() => {
					setInformation((prev) => ({
						...prev,
						visible: false,
					}));
				}}
			>
				{isOpenUserBooking ? (
					<UserBooking userId={information.userId} openStatus={information.visible} />
				) : (
					<UserInformation
						getListTable={getListWalletHis}
						setInformation={(value) => {
							setInformation((prev) => ({
								...prev,
								data: value,
							}));
						}}
					/>
				)}
			</DialogMain>
			<DialogMain
				maxWidth={"md"}
				title={"Xác nhận xóa khách hàng"}
				fullWidth
				open={deleteUser.open}
				onClose={() => {
					setDeleteUser((prev) => ({
						...prev,
						open: false,
					}));
				}}
			>
				<AccountDeleteConfirm
					account={deleteUser.username}
					handleDelete={(username) => handleDelete(deleteUser?.id, username)}
				/>
			</DialogMain>
			<UserModal
				employee={data}
				getListTable={getListWalletHis}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>

			<ImageModal
				title="Qr Code khách hàng"
				openModalZoom={openModalImage}
				handleCloseModal={() => setOpenModalImage(false)}
				imageZoom={imgQr}
			/>
		</>
	);
};

export default WalletHis;
