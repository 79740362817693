import { SimCardDownload } from "@mui/icons-material";
import { Box, Button, Pagination, Stack, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "components/noRows";
import { formatVND, statusTransactionTranslator, transactionTranslator } from "func";
import moment from "moment";
import { useSelector } from "react-redux";
import HeaderActions from "ui-component/Header-actions";
import { getNumberWithCommas } from "utils";
import { excelExportHelper } from "utils/ExportExcel";

function InvoicesDepositTable({ rows, setPagination, pagination, filterData, loading, getData }) {
	const { branchList } = useSelector((state) => state?.branchReducer);
	const handleChangePage = (e, page) => {
		setPagination((prev) => ({
			...prev,
			page,
		}));
	};
	const columns = [
		{
			field: "phone",
			headerName: "SĐT",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.user?.phone}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							className={params?.row?.user?.deleted_at && "line__though"}
						>
							{params?.row?.user?.phone}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee",
			headerName: "Nhân viên gửi yêu cầu",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.employee?.fullname || params?.row?.employee?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							className={params?.row?.employee?.deleted_at && "line__though"}
						>
							{params?.row?.employee?.fullname || params?.row?.employee?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "employee_confirm",
			headerName: "Nhân viên xác nhận",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={
							params?.row?.employee_confirm?.fullname || params?.row?.employee_confirm?.username
						}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							className={params?.row?.employee_confirm?.deleted_at && "line__though"}
						>
							{params?.row?.employee_confirm?.fullname || params?.row?.employee_confirm?.username}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "original_value",
			headerName: "Giá trị giao dịch",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",

			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "endow_value",
			headerName: "Giá trị ưu đãi",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",

			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={!!params.value && `${params.value}%`}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{!!params.value && `${params.value}%`}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "change_amount",
			headerName: "Giá trị nhận được",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			// hide: filterType !== "REQUEST_DEPOSIT",

			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},

		// {
		// 	field: "note_admin",
		// 	headerName: "Nội dung",
		// 	flex: 1,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	hide: filterType !== "REQUEST_DEPOSIT",

		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={params?.value}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.value}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		// {
		// 	field: "note",
		// 	headerName: "Ghi chú",
		// 	flex: 1,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	hide: filterType !== "REQUEST_DEPOSIT",

		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={params?.value}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.value}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },

		{
			field: "status_enum",
			headerName: "Tình trạng",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				const { title, color } = statusTransactionTranslator(params.value, true);
				return (
					<Tooltip
						title={title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								color: color,
								fontWeight: 700,
							}}
						>
							{title}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian tạo",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(+params.value).format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(+params.value).format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "updated_at",
		// 	headerName: "Ngày cập nhật",
		// 	flex: 1,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",

		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip
		// 				title={moment(params.value, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm")}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{moment(params.value, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm")}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		// {
		// 	field: "is_checked",
		// 	headerName: "Tình trạng tư vấn",
		// 	flex: 0.5,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	renderCell: (params) => {
		// 		// params.value = false;

		// 		return (
		// 			<Tooltip
		// 				title={params.value ? "Đã tư vấn" : "Chưa tư vấn"}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<FormControlLabel
		// 					control={
		// 						<IOSSwitch
		// 							sx={{ ml: 4 }}
		// 							checked={params?.value}
		// 							onChange={(e) => handleChangeAccept(e, params?.row)}
		// 						/>
		// 					}
		// 					label=""
		// 				/>
		// 			</Tooltip>
		// 		);
		// 	},
		// },

		// {
		// 	field: "login_type",
		// 	headerName: "Nền tảng đăng nhập",
		// 	minWidth: 250,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		// {
		// 	field: "created_at",
		// 	headerName: t(`created_at`),
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// },
	];

	const handleExportExcel = async (data, date) => {
		const startEndDate = {
			start: moment(date[0]).format("DD/MM/YYYY"),
			end: moment(date[1]).format("DD/MM/YYYY"),
		};

		const fullData = await getData();

		const title = [
			{
				A: `Báo cáo yêu cầu nạp theo thời gian 
						Từ ngày ${startEndDate.start} đến ngày ${startEndDate.end}`,
			},
			{},
		];

		let table1 = [
			{
				A: "SĐT",
				B: "Nhân viên yêu cầu",
				C: "Nhân viên xác nhận",
				D: "Giá trị giao dịch",
				E: "Ưu đãi",
				F: "Giá trị nhận được",
				G: "Tình trạng",
				H: "Ghi chú từ NV xác nhận",
				I: "Loại yêu cầu",
				J: "Thời gian tạo",
			},
		];
		// console.log("data export: ", data);
		fullData.forEach((row) => {
			table1.push({
				A: row?.phone,
				B: row?.employee?.fullname || row?.employee?.username,
				C: row?.employee_confirm?.fullname || row?.employee_confirm?.username,
				D: +row?.amount_need_take,
				E: +row.endow_value + "%",
				F: +row?.change_amount,
				G: statusTransactionTranslator(row?.status_enum).title,
				H: row?.note_admin,
				I: transactionTranslator(row?.type, true),
				J: moment(+row?.created_at_unix_timestamp).format("DD/MM/YYYY HH:mm"),
			});
		});

		table1 = [{ A: `Ngày lập ${moment().format("DD/MM/YYYY HH:mm")}` }].concat(table1).concat([""]);

		const finalData = [...title, ...table1];

		excelExportHelper(fullData, finalData);
	};

	return (
		<Box minHeight={"100vh"}>
			<Stack flexDirection={"row"} justifyContent="space-between">
				<HeaderActions title="Yêu cầu nạp thẻ" />
				<Stack flexDirection={"row"} gap={2} alignItems="center">
					<Button
						sx={{ p: 1, height: "fit-content" }}
						size="small"
						variant="contained"
						startIcon={<SimCardDownload />}
						onClick={() => handleExportExcel(rows, filterData?.date)}
					>
						Xuất file
					</Button>
				</Stack>
			</Stack>
			<Box>
				<DataGrid
					rows={rows}
					columns={columns}
					autoHeight
					autoWidth
					disableColumnMenu
					disableVirtualization={false}
					disableSelectionOnClick={true}
					disableDensitySelector={false}
					hideFooter={true}
					rowHeight={70}
					sortingMode="server"
					loading={loading}
					// checkboxSelection={ROLE.TRANSACTION_DELETE === role?.TRANSACTION_DELETE}
					// onSelectionModelChange={(ids) => {
					// 	setSelectList(ids);
					// }}
					components={{
						NoRowsOverlay: CustomNoRowsOverlay,
					}}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					marginTop: "20px",
				}}
			>
				<Pagination
					page={pagination.page}
					count={Math.ceil(pagination.total / pagination.limit)}
					onChange={handleChangePage}
					color="primary"
					variant="outlined"
					shape="rounded"
				/>
			</Box>
		</Box>
	);
}

export default InvoicesDepositTable;
