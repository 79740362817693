import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

HeaderActions.propTypes = {
	title: PropTypes.string,
};

function HeaderActions(props) {
	const { title, setOpen, onCreate, setIsUpdate, textButton = null } = props;
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				paddingTop: "1rem",
				paddingBottom: "1rem",
				"& .title": {
					margin: 0,
				},
			}}
		>
			<strong>
				<h2 className="title">{title}</h2>
			</strong>
			{onCreate && (
				<div>
					<Button
						onClick={() => {
							setOpen(true);
							setIsUpdate && setIsUpdate(false);
						}}
						variant="contained"
						color="primary"
					>
						{textButton || t(`add`)}
					</Button>
				</div>
			)}
		</Box>
	);
}

export default HeaderActions;
