import { Box, Button, Grid, Typography } from "@mui/material";
import inventoryAPI from "api/InventoryAPI";
import InputField from "components/modules/form/InputField";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import debtAPI from "api/DebtAPI";
import moment from "moment";
import { useSelector } from "react-redux";
import { handleMaskValue, handleParseMask } from "func";

import { PATTERN } from "constants/common";
import { getStringWithCommasFromNumber } from "utils";

// const schema = yup.object().shape({
// 	value: yup.string().required("Vui lòng điền giá trị"),
// });

function DebtForm({ formData, userId, isUpdate, onSuccess }) {
	const { handleSubmit, control, errors } = useForm({
		mode: "onChange",

		defaultValues: {
			debt: formData || 0,
		},

		// resolver: yupResolver(schema, {}),
	});

	const [loading, setLoading] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const onSubmit = async (data) => {
		setLoading(true);

		const body = {
			value: +handleParseMask(data?.value),
			user_id: userId,
			type: "DEBT",
			note: data?.note || "",
			time: +moment(new Date()).format("x"),
			branch_id: branchActiveId,
		};
		try {
			const res = await debtAPI.create(body);
			enqueueSnackbar("Điều chỉnh thành công!", {
				variant: "success",
				autoHideDuration: 2000,
			});
			setLoading(false);
			onSuccess();
		} catch (error) {
			enqueueSnackbar(error?.message || "Có lỗi xảy ra, vui lòng thử lại sau!", {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);

			console.log(error);
		}
	};

	const handleCustomizeMask = (e, onChange) => {
		const value = handleMaskValue(e.target.value);
		onChange(value);
	};

	return (
		<Box component="form" onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<Typography variant="h5" component="p">
						Dư nợ hiện tại:
					</Typography>
				</Grid>
				<Grid item xs={9}>
					<Typography variant="body1" component="p">
						{getStringWithCommasFromNumber(formData)}
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography variant="h5" component="p">
						Giá trị nợ điều chỉnh
					</Typography>
				</Grid>
				<Grid item xs={9}>
					<InputField
						label={""}
						name="value"
						errors={errors}
						control={control}
						rules={{
							required: "Giá trị nợ không để trống",
							pattern: {
								value: PATTERN?.NUMBER_POSITIVE,
								message: "Giá trị phải là một số dương",
							},
						}}
						customizeOnChange={handleCustomizeMask}
					/>
				</Grid>
				<Grid item xs={3}>
					<Typography variant="h5" component="p">
						Mô tả
					</Typography>
				</Grid>
				<Grid item xs={9}>
					<InputField rows={4} multiline label={""} name="note" control={control} />
				</Grid>
			</Grid>
			<Box sx={{ textAlign: "right", my: 2 }}>
				<LoadingButton type="submit" loading={loading} variant="outlined">
					Cập nhật
				</LoadingButton>
			</Box>
		</Box>
	);
}

export default DebtForm;
