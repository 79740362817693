import {
	Button,
	Chip,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Stack,
	Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Image, Select as SelectAntd, Typography } from "antd";
import { CustomNoRowsOverlay } from "components/noRows";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import { SearchComponent } from "../../components/search";

import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { IconCheckbox } from "@tabler/icons";
import Text from "antd/lib/typography/Text";
import bookingAPI from "api/BookingAPI";
import { BOOKING_ACTIVITY, PAYMENT_TYPE } from "constants/common";
import { ROLE } from "constants/employeeRole";
import { activityTranslator, formatVND, paymentTypeOrderTranslator } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import { actSetBookingDetails } from "store/actions/BookingActions/action";
import { gridSpacing } from "store/constant";
import CollapseContent from "ui-component/CollapseContent";
import BookingDetailModalSingle from "ui-component/modal-form/BookingDetailModal/BookingDetailModalSingle";
import BookingModal from "ui-component/modal-form/BookingModal";
import BookingActivityModal from "ui-component/modal-form/BookingModal/BookingActivityModal";
import ImageModal from "ui-component/modal-form/ImageModal";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import DateRange from "ui-component/SearchAvanced/DateRange";
import { IOSSwitch } from "ui-component/switch";
import { SelectCustom } from "views/utilities/CustomStyle";

const { Option } = SelectAntd;

const searchList = [
	{
		value: "phone",
		title: "SĐT",
	},
	{
		value: "booking_code",
		title: "Mã lịch đặt",
	},
	// {
	// 	value: "name",
	// 	title: "abeauty.booking.table_title.name",
	// },
	// {
	// 	value: "email",
	// 	title: "abeauty.booking.table_title.email",
	// },
	// {
	// 	value: "activity",
	// 	title: "abeauty.booking.table_title.activity",
	// },
];
const { WAITING, PROCESSING, CONFIRMED, ASSIGNED, COMPLETED, CANCELED } = BOOKING_ACTIVITY;
const { CASH, WALLET, TRANSFER, CARD } = PAYMENT_TYPE;

const activityList = [
	{
		value: "ALL",
		title: "Tất cả",
	},
	{
		value: "WAITING",
		title: WAITING,
	},
	{
		value: "PROCESSING",
		title: PROCESSING,
	},

	{
		value: "CONFIRMED",
		title: CONFIRMED,
	},
	// {
	// 	value: "ASSIGNED",
	// 	title: ASSIGNED,
	// },
	{
		value: "COMPLETED",
		title: COMPLETED,
	},
	{
		value: "CANCELED",
		title: CANCELED,
	},
];
const paymentList = [
	{
		value: "ALL",
		title: "Tất cả",
	},
	{
		value: "CASH",
		title: CASH,
	},
	{
		value: "WALLET",
		title: WALLET,
	},

	{
		value: "TRANSFER",
		title: TRANSFER,
	},
	// {
	// 	value: "ASSIGNED",
	// 	title: ASSIGNED,
	// },
	{
		value: "CARD",
		title: CARD,
	},
];
const Booking = () => {
	const { role } = useCustomAuth();
	const { enqueueSnackbar } = useSnackbar();
	const modalContext = useContext(ModalContext);
	const translator = (text) => {
		return t(`abeauty.booking.table_title.${text}`);
	};

	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState([]);

	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [openAssign, setOpenAssign] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);

	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});
	const [openModalImage, setOpenModalImage] = useState(false);
	const [activityModal, setActivityModal] = useState(false);
	const [imageZoom, setImageZoom] = useState(false);
	const handleOpenChangeAccept = (e, params, isWithClient = false) => {
		let checked = e.target.checked;
		modalContext.showDeleteConfirm(
			params.id,
			() => handleChangeAccept(checked, params.id, isWithClient),
			`${
				checked
					? `Bạn có chắc chắn xác nhận  ${
							isWithClient ? "đã gọi điện  khách hàng?" : "đã nhận tiền?"
					  } `
					: `Bạn có chắc chắn xác nhận ${
							isWithClient ? "chưa gọi điện  khách hàng?" : "chưa nhận tiền?"
					  }`
			}`,
			"Chắc chắn"
		);
	};
	const handleChangeAccept = async (checked, id, isWithClient) => {
		try {
			await bookingAPI.update(
				id,

				!isWithClient
					? {
							is_paid: checked,
					  }
					: {
							is_confirm_with_client: checked,
					  }
			);
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListBooking();
		} catch (error) {
			enqueueSnackbar(error?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};
	const actionList = [
		{
			value: "edit",
			title: "edit",
			visible: ROLE.BOOKING_UPDATE === role?.BOOKING_UPDATE,
		},

		{
			value: "information",
			title: "Xem chi tiết",
			visible: ROLE.BOOKING_VIEW === role?.BOOKING_VIEW,
		},
		{
			value: "activity",
			title: "Sửa trạng thái lịch hẹn",
			visible: ROLE.BOOKING_UPDATE === role?.BOOKING_UPDATE,
		},
	];

	const columns = [
		// {
		// 	field: "order",
		// 	headerName: t(`order`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// },

		// {
		// 	field: "qr_code_image",
		// 	headerName: translator("qr_code_image"),
		// 	sortable: false,
		// 	flex: 1,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	renderCell: (params) => (
		// 		<img
		// 			height={"80%"}
		// 			src={params.value || IMAGES.avatar_default}
		// 			alt="avatar-img"
		// 			onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
		// 			onClick={() => {
		// 				setOpenModalImage(true);
		// 				setImageZoom(params.value);
		// 			}}
		// 		/>
		// 	),
		// },
		{
			field: "employee_confirm",
			headerName: "Nhân viên xác nhận",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={
							params?.row?.employee_confirm?.fullname || params?.row?.employee_confirm?.username
						}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							className={params?.row?.employee_confirm?.deleted_at && "line__though"}
						>
							{params?.row?.employee_confirm?.fullname || params?.row?.employee_confirm?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "phone",
			headerName: translator("phone"),
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={
						<p>
							{params?.value}(
							{params.row?.user?.name_admin_note ||
								params.row?.user?.fullname ||
								"Không có dữ liệu"}
							)
						</p>
					}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
						className={params?.row?.user?.deleted_at && "line__though"}
					>
						{params.value}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "booking_code",
			headerName: "Mã lịch đặt",
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={params.value}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{params.value}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "activity",
			headerName: translator("activity"),
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<Tooltip
					title={activityTranslator(params.value)?.title}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							color: activityTranslator(params.value).color,
							fontWeight: 700,
						}}
					>
						{activityTranslator(params.value)?.title}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "is_paid",
			headerName: translator("is_confirm"),
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				// params.value = false;

				return (
					<Tooltip
						title={params.value ? "Lịch này đã nhận tiền" : "Chưa nhận"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<FormControlLabel
							control={
								<IOSSwitch
									sx={{ ml: 4 }}
									checked={params?.value}
									onChange={(e) => handleOpenChangeAccept(e, params?.row)}
								/>
							}
							label=""
						/>
					</Tooltip>
				);
			},
		},
		{
			field: "is_confirm_with_client",
			headerName: translator("is_confirm_with_client"),
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				// params.value = false;

				return (
					<Tooltip
						title={params.value ? "Đã gọi" : "Chưa gọi"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<FormControlLabel
							control={
								<IOSSwitch
									sx={{ ml: 4 }}
									checked={params?.value}
									onChange={(e) => handleOpenChangeAccept(e, params?.row, true)}
								/>
							}
							label=""
						/>
					</Tooltip>
				);
			},
		},

		{
			field: "booking_type",
			headerName: "Phương thức thanh toán",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return paymentTypeOrderTranslator(params.value);
			},
		},
		{
			field: "time_start",
			headerName: "Ngày giờ hẹn",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<Tooltip
					title={moment(parseInt(params.value)).format("DD/MM/YYYY HH:mm")}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{moment(parseInt(params.value)).format("DD/MM/YYYY HH:mm")}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian tạo",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<Tooltip
					title={moment(parseInt(params.value)).format("DD/MM/YYYY HH:mm")}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{moment(parseInt(params.value)).format("DD/MM/YYYY HH:mm")}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "actions",
			headerName: t(`actions`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			hide: !actionList.some((item) => item.visible),
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<SelectCustom
							value={t(`select_actions`)}
							onChange={(value) => onChangeAction(value, params.id, params)}
							style={{ width: "80%" }}
						>
							{actionList?.map((item) => {
								return (
									item.visible && (
										<Option
											style={
												//Điều kiện disabled Activity

												(item.value === "activity" &&
													(params.row.activity === "COMPLETED" ||
														params.row.activity === "CANCELED")) ||
												//Điều kiện disabled Asssign

												(item.value === "assign" && params.row.activity === "CANCELED") ||
												//Điều kiện disabled Edit
												(item.value === "edit" &&
													(params.row.activity === "CANCELED" ||
														params.row.activity === "COMPLETED"))
													? { opacity: 0.5, background: "#f2f2f2", pointerEvents: "none" }
													: {}
											}
											key={item?.value}
											value={item.value}
										>
											{t(`${item.title}`)}
										</Option>
									)
								);
							})}
						</SelectCustom>
					</>
				);
			},
		},
	];
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const [filterQuery, setFilterQuery] = useState({
		fields: [
			"$all",
			{ user: ["$all", "$paranoid", "deleted_at"] },
			{ booking_consultants: ["$all", { employee: ["$all"] }] },
			{ employee_sales: ["$all", { employee: ["$all"] }] },

			{
				booking_details: [
					"$all",
					{ service: ["$all", { unit: ["$all"] }] },
					{ order_item: ["$all", { combo_using: ["$all"] }] },
				],
			},
			{ booking_activities: ["$all"] },
			{ voucher: ["$all"] },
			{ employee_confirm: ["fullname", "username", "deleted_at", "$paranoid"] },
		],
		filter: {
			branch_id: branchActiveId,
		},
		order: [["created_at", "desc"]],
	});
	const getListBooking = async (isLastDeletedItem) => {
		setIsLoading(true);

		if (queryParams?.[searchBy]) {
			filterQuery.filter = {
				...filterQuery.filter,
				[searchBy]: { $iLike: `%${searchValue?.trim()}%` },
			};
		} else {
			filterQuery.filter = {};
		}

		filterQuery.filter = {
			...filterQuery.filter,
			branch_id: branchActiveId,
			created_at_unix_timestamp: {
				$between: [+moment(date[0]).utc(true).format("x"), +moment(date[1]).utc(true).format("x")],
			},
			activity: activityFilter,
			booking_type: paymentFilter,
		};

		if (!activityFilter || activityFilter === "ALL") {
			delete filterQuery.filter.activity;
		}
		if (!paymentFilter || paymentFilter === "ALL") {
			delete filterQuery.filter.booking_type;
		}
		if (!collapse || !date[0] || !date[1]) {
			delete filterQuery.filter.created_at_unix_timestamp;
		}

		if (queryParams?.sortBy) {
			filterQuery.order = `[["${queryParams?.sortBy}", "${queryParams?.sort}"]]`;
		}
		try {
			const res = await bookingAPI.getList({
				fields: JSON.stringify(filterQuery.fields),
				order: JSON.stringify(filterQuery.order),
				filter: JSON.stringify(filterQuery.filter),
				limit: pagination.limit,
				page: isLastDeletedItem ? queryParams.page - 1 : queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			if (isLastDeletedItem) {
				navigate({
					pathname: location.pathname,
					search: `?${queryString.stringify({ ...queryParams, page: paginationRes.page })}`,
				});
			}
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setFilterQuery(filterQuery);
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	const handleOpenCancelConfirm = (id) => {
		modalContext.showDeleteConfirm(
			id,
			() => handleCancel(id),
			"Bạn có chắc muốn hủy lịch hẹn này ?",
			"Xác nhận"
		);
	};

	const handleOpenActivity = (data) => {
		setActivityModal(true);
		setData(data.row);
	};
	const handleCancel = async (id) => {
		try {
			await bookingAPI.cancelBooking(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			await getListBooking();
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};
	const handleDelete = async (id) => {
		const isLast = rows.length === 1 && pagination.page !== 1;
		try {
			await bookingAPI.removeMulti({
				items: JSON.stringify([id]),
			});

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			await getListBooking(isLast);
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};
	const dispatch = useDispatch();
	const handleOpenAssignModal = (id, data) => {
		setOpenAssign(true);

		///Dispatch bookingDetails
		dispatch(actSetBookingDetails(data));
	};

	const onChangeAction = (value, id, dataParams) => {
		value === "edit" && handleOpenUpdateModal(dataParams.row);
		value === "delete" && handleOpenRemoveModal(id);
		value === "canceled" && handleOpenCancelConfirm(id);
		value === "activity" && handleOpenActivity(dataParams);
		value === "assign" && handleOpenAssignModal(id, dataParams.row.booking_details);
		value === "information" && handleOpenInforModal(dataParams.row);
	};
	const handleOpenUpdateModal = (data) => {
		setIsUpdate(true);
		setOpen(true);
		setData(data);
	};

	const handleOpenInforModal = (data) => {
		//	Example DATA

		navigate(`/booking-detail/${data.id}`);
	};

	const [collapse, setCollapse] = useState(location.state?.filterDate ? true : false);
	const [date, setDate] = useState(
		location.state?.filterDate
			? [
					Number(moment(location.state?.filterDate[0], "DD/MM/YYYY").startOf("day").format("x")),
					Number(moment(location.state?.filterDate[1], "DD/MM/YYYY").endOf("day").format("x")),
			  ]
			: [null, null]
	);

	const handleRemoveMulti = () => {
		const isLast = rows.length === selectList.length && pagination.page !== 1;

		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await bookingAPI.removeMulti({
						items: JSON.stringify(selectList),
					});
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListBooking(isLast);
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const [activityFilter, setActivityFilter] = useState(
		(location.state?.fromDashboard && location.state?.activity) || activityList[0].value
	);

	const [paymentFilter, setPaymentFilter] = useState(paymentList[0].value);
	//Filter activity

	const handleChangeActivity = (e, activity) => {
		setActivityFilter(e?.target?.value || activity);
		handleChangePage(null, 1);
	};
	const handleChangePaymentFilter = (e) => {
		setPaymentFilter(e?.target?.value);
		handleChangePage(null, 1);
	};

	useEffect(() => {
		if (!branchActiveId || !role) return;
		if (role?.BOOKING_VIEW !== ROLE.BOOKING_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}

		if (queryParams.hasOwnProperty(searchBy)) {
			setSearchBy(searchBy);
			setSearchValue(queryParams?.[searchBy]);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		getListBooking();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, activityFilter, paymentFilter, branchActiveId, role]);

	//Reload khi assign thành công

	const reloadState = useSelector((state) => state.booking.assignReloader);

	useEffect(() => {
		if (!branchActiveId) return;
		getListBooking();
	}, [reloadState, activityFilter, date, branchActiveId, paymentFilter]);

	// useEffect(() => {
	// 	if (location.state?.fromDashboard) {
	// 		setActivityFilter(location?.state?.activity);
	// 	}
	// }, [location?.state?.activity]);
	//Set open information Modal
	const [information, setInformation] = useState({
		visible: false,
	});
	return (
		<>
			<MainCard>
				<HeaderActions
					title={t("abeauty.booking.header_title")}
					onCreate={ROLE.BOOKING_CREATE === role?.BOOKING_CREATE}
					setOpen={setOpen}
					setIsUpdate={setIsUpdate}
					textButton={"Đặt lịch"}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						<SearchComponent
							searchBy={searchBy}
							searchList={searchList}
							searchValue={searchValue}
							handleSearchBy={handleSearchBy}
							handleChangeSearch={handleChangeSearch}
						/>
						<FormControl sx={{ minWidth: 200, ml: 2, mb: "16px" }}>
							<InputLabel id="demo-simple-select-outlined-label">Trạng thái lịch hẹn</InputLabel>
							<Select
								defaultValue={activityList[0].value}
								labelId="demo-simple-select-outlined-label"
								label={"Trạng thái lịch hẹn"}
								onChange={handleChangeActivity}
								value={activityFilter}
							>
								{activityList?.map((item) => (
									<MenuItem
										key={item.value}
										value={item.value}
										sx={{
											color: activityTranslator(item.value)?.color,
											fontWeight: 700,
										}}
									>
										{item.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl sx={{ minWidth: 200, ml: 2, mb: "16px" }}>
							<InputLabel id="label-booking-type">Phương thức thanh toán</InputLabel>
							<Select
								labelId="label-booking-type"
								label={"Phương thức thanh toán"}
								onChange={handleChangePaymentFilter}
								value={paymentFilter}
							>
								{paymentList?.map((item) => (
									<MenuItem
										key={item.value}
										value={item.value}
										sx={{
											color: activityTranslator(item.value)?.color,
											fontWeight: 700,
										}}
									>
										{item.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>

					{/* <Button
						variant={selectList.length > 0 ? "contained" : "outlined"}
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Stack flexDirection={"row"} justifyContent="flex-end">
					<Button
						onClick={() => setCollapse(!collapse)}
						sx={{
							width: "200px",
						}}
						variant="outlined"
						startIcon={collapse ? <RemoveCircleOutline /> : <AddCircleOutline />}
					>
						Tìm kiếm nâng cao
					</Button>
				</Stack>

				<Box my={2}>
					<CollapseContent collapse={collapse}>
						<DateRange
							date={date}
							collapse={collapse}
							setDate={(value) => {
								setDate(value);
								if (!location.state?.fromDashboard) {
									handleChangePage(null, 1);
								}
							}}
						/>
					</CollapseContent>
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableExtendRowFullWidth
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							checkboxSelection={false}
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<BookingModal
				getListTable={getListBooking}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
			/>
			<BookingActivityModal
				getListTable={getListBooking}
				data={data}
				open={activityModal}
				setOpen={(value) => setActivityModal(value)}
			/>

			<BookingDetailModalSingle
				getListTable={getListBooking}
				isOpen={openAssign}
				isClose={(value) => setOpenAssign(value)}
			/>
			<ImageModal
				openModalZoom={openModalImage}
				handleCloseModal={() => setOpenModalImage(false)}
				imageZoom={imageZoom}
			/>

			<ModalAntd
				title={"Chi tiết lịch hẹn"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>

			{/* <ModalAntd
				title={"Xác nhận lịch hẹn với khách hàng"}
				visible={confirmWithClient.visible}
				setVisible={(value) => {
					setConfirmWithClient((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				
			</ModalAntd> */}
		</>
	);
};

export default Booking;
