import { productTranslator } from "func";

export const typeList = [
	{
		value: "PRODUCT",
	},
	{
		value: "GIFT",
	},
];
