export const partnerTree = [
	{
		title: "Khách hàng",
		key: "CUSTOMER",
		children: [
			{
				title: "Xem DS",
				key: "CUSTOMER_VIEW",
			},
			{
				title: "Thêm mới",
				key: "CUSTOMER_CREATE",
			},
			{
				title: "Cập nhật",
				key: "CUSTOMER_UPDATE",
			},
			{
				title: "Xóa",
				key: "CUSTOMER_DELETE",
			},
			{
				title: "Nạp tiền lần đầu",
				key: "CUSTOMER_DEPOSIT_FIRSTTIME",
			},
			{
				title: "Xem tuyến trên",
				key: "CUSTOMER_PARENT",
			},
			{
				title: "Xem tuyến dưới",
				key: "CUSTOMER_LIST_MEMBER",
			},
			{
				title: "Cập nhật thời hạn ví",
				key: "CUSTOMER_UPDATE_EXPIRED_WALLET",
			},
			{
				title: "Cập nhật hạn mức dịch vụ",
				key: "CUSTOMER_UPDATE_WALLET",
			},
			{
				title: "Cập nhật ví hoa hồng",
				key: "UPDATE_WALLET_ROSE",
			},
			{
				title: "Tạo QR",
				key: "CUSTOMER_CREATE_QRCODE",
			},
		],
	},
	{
		title: "Xác thực người dùng",
		key: "CONFIRM_CUSTOMER",
		children: [
			{
				title: "Xem DS",
				key: "CONFIRM_CUSTOMER_VIEW",
			},

			{
				title: "Cập nhật",
				key: "CONFIRM_CUSTOMER_UPDATE",
			},
		],
	},
	{
		title: "Yêu cầu thay đổi ví",
		key: "CONFIRM_CHANGE_WALLET",
		children: [
			{
				title: "Xem DS",
				key: "CONFIRM_CHANGE_WALLET_VIEW",
			},

			{
				title: "Cập nhật",
				key: "CONFIRM_CHANGE_WALLET_UPDATE",
			},
		],
	},
];
