import { Stack } from "@mui/material";
import BranchFilter from "components/FilterAdvance/BranchFIlter";
import CardLayout from "components/FilterAdvance/CardLayout";
import DateFilter from "components/FilterAdvance/DateFilter";
import SearchFilter from "components/FilterAdvance/SearchFilter";
import {
	bookingInvoicesSearchFilter,
	orderInvoicesSearchFilter,
	transactionInvoicesFilter,
} from "components/FilterAdvance/SearchFilter/data";
import {
	bookingPayment,
	bookingStatus,
	orderStatus,
	paymentStatus,
	statusEnum,
	typeTransaction,
} from "components/FilterAdvance/StatusFilter/data";
import InputField from "components/modules/form/InputField";
import MultipleCheckboxField from "components/modules/form/MultipleCheckboxField";
import SideFilter from "components/SideFilter";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

function DepositInvoicesFilter({ setFilterData, filterData }) {
	const { control, handleSubmit, reset } = useForm({
		defaultValues: filterData,
	});
	const onSubmit = (data) => {
		setFilterData(data);
	};

	useEffect(() => {
		reset(filterData);
	}, [filterData]);

	return (
		<SideFilter>
			<Stack
				minHeight={"100vh"}
				gap={2}
				component={"form"}
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						handleSubmit(onSubmit)();
					}
				}}
			>
				<CardLayout
					title={"Tìm kiếm"}
					mainContent={{
						Component: SearchFilter,
						props: {
							data: transactionInvoicesFilter,
							control,
						},
					}}
				/>

				<CardLayout
					title={"Thời gian"}
					hasExpanded={false}
					mainContent={{
						Component: DateFilter,
						props: {
							name: "date",
							control,
						},
					}}
				/>
				<CardLayout
					title={"Trạng thái"}
					mainContent={{
						Component: MultipleCheckboxField,
						props: {
							name: "status_enum",
							control,
							checkArray: statusEnum,
						},
					}}
				/>
				<CardLayout
					title={"Loại yêu cầu"}
					mainContent={{
						Component: MultipleCheckboxField,
						props: {
							name: "type",
							control,
							checkArray: typeTransaction,
						},
					}}
				/>
			</Stack>
		</SideFilter>
	);
}

export default DepositInvoicesFilter;
