import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid, IconButton, Pagination, Stack, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import bannerAPI from "api/BannerAPI";
import { IMAGES } from "assets/images/imagePath";
import { CustomNoRowsOverlay } from "components/noRows";
import { ALERT_PERMISSION } from "constants/constant";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";
import _debounce from "lodash/debounce";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import BannerModal from "ui-component/modal-form/BannerModal";
import ImageModal from "ui-component/modal-form/ImageModal";
import { SearchComponent } from "../../components/search";

const searchList = [
	{
		value: "title",
		title: "title",
	},
];

const Banner = () => {
	const { role } = useCustomAuth();
	const location = useLocation();

	const stateFromCategory = location?.state;
	const filterWithCategory = {
		searchBy: stateFromCategory?.searchBy,
		value: stateFromCategory?.value,
	};

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});
	const actionList = [
		{
			value: "edit",
			title: "Cập nhật",
			visible: ROLE.BANNER_UPDATE === role?.BANNER_UPDATE,
			icon: <EditIcon />,
			onClick: (params) => {
				setData(params.row);
				setOpen(true);
				setIsUpdate(true);
			},
		},
		{
			value: "delete",
			title: "Xóa",
			visible: ROLE.BANNER_DELETE === role?.BANNER_DELETE,
			icon: <DeleteIcon />,

			onClick: (params) => handleOpenRemoveModal(params.id),
		},
	];

	// Search
	const [searchBy, setSearchBy] = useState(filterWithCategory.searchBy || searchList[0].value);
	const [searchValue, setSearchValue] = useState(filterWithCategory.value || "");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	// Table

	const columns = [
		// {
		// 	field: "order",
		// 	headerName: t(`order`),
		// 	minWidth: 100,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		{
			field: "title",
			headerName: t(`abeauty.service.table_title.title`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
		},
		{
			field: "image",
			headerName: "Ảnh chính",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<img
					height={"80%"}
					src={params.value || IMAGES.avatar_default}
					alt="avatar-img"
					onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
					onClick={() => setImageZoom({ open: true, image: params.value, title: "Ảnh bìa chính" })}
				/>
			),
		},
		// {
		// 	field: "image_web",
		// 	headerName: "Ảnh web",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// 	renderCell: (params) => (
		// 		<img
		// 			height={"80%"}
		// 			src={params.value || IMAGES.avatar_default}
		// 			alt="avatar-img"
		// 			onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
		// 			onClick={() => setImageZoom({ open: true, image: params.value, title: "Ảnh bìa web" })}
		// 		/>
		// 	),
		// },
		// {
		// 	field: "square_image",
		// 	headerName: "Ảnh thumbnail",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// 	renderCell: (params) => (
		// 		<img
		// 			height={"80%"}
		// 			src={params.value || IMAGES.avatar_default}
		// 			alt="avatar-img"
		// 			onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
		// 			onClick={() =>
		// 				setImageZoom({ open: true, image: params.value, title: "Ảnh bìa thumbnail" })
		// 			}
		// 		/>
		// 	),
		// },

		// {
		// 	field: "type",
		// 	headerName: "Vị trí hiển thị",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// },
		{
			field: "show_on",
			headerName: "Thiết bị hiển thị",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.8,
		},

		// {
		// 	field: "created_at",
		// 	headerName: t(`created_at`),
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	// flex: 1,
		// 	renderCell: (data) => <div>{moment.utc(data).format("DD/MM/YYYY HH:mm")}</div>,
		// },
		{
			field: "actions",
			headerName: t(`actions`),
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			hide: !actionList.some((item) => item.visible),
			renderCell: (params) => (
				<Stack flexDirection={"row"}>
					{actionList.map(
						(item) =>
							item.visible && (
								<Tooltip title={item.title}>
									<IconButton aria-label={item.value} onClick={() => item.onClick(params)}>
										{item.icon}
									</IconButton>
								</Tooltip>
							)
					)}
				</Stack>
			),
		},
	];

	const getListBanner = async () => {
		setIsLoading(true);
		let filter = "{}";
		let order = '[["show_on", "asc"]]';
		if (queryParams?.title?.trim()) {
			filter = `{"title":{"$iLike":"%${queryParams?.title.trim()}%"}}`;
		}
		if (queryParams?.category_name?.trim()) {
			filter = `{"category_name":{"$iLike":"%${queryParams?.category_name.trim()}%"}}`;
		}
		if (queryParams?.execution_time?.trim()) {
			filter = `{"execution_time":{"$iLike":"%${queryParams?.execution_time.trim()}%"}}`;
		}
		if (queryParams?.price?.trim()) {
			filter = `{"price":{"$iLike":"%${queryParams?.price.trim()}%"}}`;
		}

		try {
			const res = await bannerAPI.getList({
				fields: '["$all"]',
				order,
				filter,
				limit: pagination.limit,
				page: queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));

			setSelectList([]);
			setRows(resList);

			setPagination(paginationRes);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//Get Services Categories
	const [serviceCategories, setServiceCategories] = useState(null);
	const getServicesCategories = async () => {
		setIsLoading(true);

		const filter = {
			status: "true",
		};
		const params = {
			fields: '["$all"]',
			filter: JSON.stringify(filter),
		};
		try {
			const res = await bannerAPI.getCategory(params);
			setServiceCategories(res.results.objects.rows);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);

	const handleRemoveMulti = async () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					const res = await bannerAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListBanner();
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleDelete = useCallback(async (id) => {
		try {
			await bannerAPI.remove(id);

			await getListBanner();
			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	}, []);

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useEffect(() => {
		if (!role) return;
		if (role?.BANNER_VIEW !== ROLE.BANNER_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty("title")) {
			setSearchBy("title");
			setSearchValue(queryParams?.title);
		}
		if (queryParams.hasOwnProperty("price")) {
			setSearchBy("price");
			setSearchValue(queryParams?.price);
		}
		if (queryParams.hasOwnProperty("execution_time")) {
			setSearchBy("execution_time");
			setSearchValue(queryParams?.execution_time);
		}
		if (queryParams.hasOwnProperty("category_name")) {
			setSearchBy("category_name");
			setSearchValue(queryParams?.category_name);
		}
		if (location.state) {
			setSearchBy(filterWithCategory.searchBy);
			setSearchValue(filterWithCategory.value);
		}
		getListBanner();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading, role]);
	useEffect(() => {
		getListBanner();
	}, []);

	return (
		<>
			<MainCard>
				<HeaderActions
					title={"Danh sách ảnh bìa"}
					setIsUpdate={setIsUpdate}
					setOpen={setOpen}
					onCreate={ROLE.BANNER_CREATE === role?.BANNER_CREATE}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{ROLE.BANNER_DELETE === role?.BANNER_DELETE && (
						<Button
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection={ROLE.BANNER_DELETE === role?.BANNER_DELETE}
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<BannerModal
				getListTable={getListBanner}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
			/>
			<ImageModal
				title={imageZoom?.title}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
				modalProps={{
					width: "100%",
				}}
				customImageStyle={{
					maxWidth: "100%",
				}}
			/>
		</>
	);
};

export default Banner;
