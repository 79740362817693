import { Box, Tab, Tabs } from "@mui/material";
import { COLOR } from "constants/common";
import React, { useState } from "react";

function TabsCustomize({ tabList, defaultTab = null, renderPage }) {
	const [tab, setTab] = useState(defaultTab || tabList[0].value);
	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<>
			<Tabs
				centered
				value={tab}
				variant="fullWidth"
				onChange={handleChange}
				TabIndicatorProps={{ sx: { display: "none" } }}
				aria-label="basic tabs example"
			>
				{tabList.map(
					(tab) =>
						tab.visible && (
							<Tab
								sx={{
									fontSize: "12px",
									minHeight: "20px",
									borderBottom: `2px solid ${COLOR.MAIN_COLOR}`,

									"&.Mui-selected": {
										border: `2px solid ${COLOR.MAIN_COLOR}`,
										borderTopLeftRadius: "5px",
										borderTopRightRadius: "5px",
										borderBottom: "0px",
									},
								}}
								label={tab.label}
								value={tab.value}
							/>
						)
				)}
			</Tabs>
			<Box>{renderPage(tab)}</Box>
		</>
	);
}

export default TabsCustomize;
