import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Bar } from "@ant-design/plots";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import LinkIcon from "components/LinkIcon";

const ServiceBestSeller = ({
	data: dataProp = [],
	seriesFieldCustom,
	chartText = {
		title: `Top 10 dịch vụ`,
		revenue: "Doanh thu",
		count: "Số lần đặt lịch",
	},
	defaultFilterVal = "count",
	object = "parent",
	filter = true,
	linkPath,
	propsLink,
	yField = "id",
	slider,
}) => {
	const [filterVal, setFilterVal] = useState(defaultFilterVal);
	let sortData = dataProp.map((item, idx) => ({
		...item,
		revenue: item?.[filterVal] || item.amount || item.revenue,
		id: uuidv4(),
		[seriesFieldCustom]:
			item?.[object]?.[seriesFieldCustom] || item?.[seriesFieldCustom] || `Không xác định ${idx}`,
	}));
	if (filterVal === "count") {
		sortData = sortData?.sort((a, b) => {
			return Number(b?.count) - Number(a?.count);
		});
	} else {
		sortData = sortData?.sort((a, b) => {
			return Number(b?.revenue) - Number(a?.revenue);
		});
	}

	const config = {
		data: sortData,
		xField: filterVal,
		yField: yField,
		xAxis: {
			min: 0,
			label: {
				formatter: (v) => `${v}`.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
			},
		},
		yAxis: false,
		barStyle: {
			radius: [100000, 100000],
		},
		meta: {
			//Các khung card
			id: {
				formatter: (v) => ` `,
			},
			[filterVal]: {
				formatter: (v) => `${v}`.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
			},
		},
		seriesField: seriesFieldCustom || "title",

		legend: {
			position: "top-left",
		},
	};
	useEffect(() => {
		setFilterVal(defaultFilterVal);
	}, [dataProp]);

	return (
		<>
			<div
				style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
			>
				{filter && (
					<div style={{ flex: 1, alignSelf: "flex-end", marginRight: "20px" }}>
						<FormControl>
							<InputLabel id="demo-simple-select-label">Lọc</InputLabel>
							<Select
								sx={{ width: "150px" }}
								labelId="demo-simple-select-label"
								label={"Lọc"}
								id="demo-simple-select"
								size="large"
								onChange={(e) => {
									setFilterVal(e.target.value);
								}}
								value={filterVal}
							>
								<MenuItem value={"count"}>{chartText.count}</MenuItem>
								<MenuItem value={"revenue"}>{chartText.revenue}</MenuItem>
							</Select>
						</FormControl>
					</div>
				)}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
					}}
				>
					<Typography variant="h2" id="header_title" sx={{ margin: "20px 0" }}>
						{chartText.title}
					</Typography>
					{linkPath && <LinkIcon to={linkPath} {...propsLink} />}
				</Box>

				<Bar style={{ width: "100%" }} {...config} />
			</div>
		</>
	);
};

export default ServiceBestSeller;
