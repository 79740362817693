import productAPI from "api/ProductAPI";

export const getListGroupProduct = async (groupScanSkin, pagination) => {
	let filter = {
		group: { $contains: [`${groupScanSkin}`] },
	};
	let order = '[["created_at", "desc"]]';

	// if (debounceVal.trim().length > 0) {
	// 	filter = {
	// 		...filter,
	// 		[searchBy]: {
	// 			$iLike: `%${debounceVal.trim()}%`,
	// 		},
	// 	};
	// }

	try {
		const res = await productAPI.getList({
			fields: '["$all"]',
			order,
			filter: JSON.stringify(filter),
			limit: pagination.limit,
			page: pagination.page,
		});
		return res;

		// setRows(resList);
		// setPagination(paginationRes);
		// setIsLoading(false);
	} catch (error) {
		console.log(error);
		// setIsLoading(false);
	}
};
