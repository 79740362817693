import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { useController } from "react-hook-form";

function MultipleCheckboxField({ name, control, checkArray, ...props }) {
	const {
		field: { onChange, onBlur, value = [], ref },
	} = useController({
		name,
		control,
	});

	return (
		<FormGroup>
			{checkArray.map((checkItem) => (
				<FormControlLabel
					label={checkItem.label}
					key={checkItem.value}
					control={
						<Checkbox
							name={name}
							control={control}
							inputRef={ref}
							value={checkItem.value}
							// For some reason codesandbox doesn't support `field.value.includes(...)`
							checked={value.includes(checkItem.value)}
							onChange={(event, checked) => {
								if (checked) {
									onChange([...value, event.target.value]);
								} else {
									onChange(value.filter((value) => value !== event.target.value));
								}
							}}
							{...props}
						/>
					}
				/>
			))}
		</FormGroup>
	);
}

export default MultipleCheckboxField;
