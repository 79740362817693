import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconNotification } from "@tabler/icons";
import { Grid, IconButton, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "components/noRows";
import { ModalContext } from "providers/ModalProvider";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { SearchComponent } from "../../components/search";
import _debounce from "lodash/debounce";
import ImageModal from "ui-component/modal-form/ImageModal";
import { Check, LocalFireDepartment } from "@mui/icons-material";
import { Image, Tooltip } from "antd";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import Text from "antd/lib/typography/Text";
import orderAPI from "api/OrderAPI";
import DialogMain from "ui-component/modal-form/DialogMui";
import ComboServiceInfo from "ui-component/modal-form/ComboService";
import ComboServiceModal from "ui-component/modal-form/ComboServiceModal";
import { activityOrderTranslator, formatVND, paymentTypeOrderTranslator } from "func";
import OrderModal from "ui-component/modal-form/OrderModal";
import HeaderNavigate from "ui-component/Header-actions/HeaderNavigate";
import { SET_MENU } from "store/actions/CustomizationAction/types";
import OrderActivityModal from "ui-component/modal-form/OrderModal/OrderActivityModal";
import { IOSSwitch } from "ui-component/switch";
import { IMAGES } from "assets/images/imagePath";
import scanSkinAPI from "api/ScanSkinAPI";
import ScanSkinContentModal from "ui-component/modal-form/ScanSkinContentModal";
import useCustomAuth from "hooks/useCustomAuth";
import { ROLE } from "constants/employeeRole";
import { ALERT_PERMISSION } from "constants/constant";

const searchList = [
	{
		value: "$user.fullname$",
		title: "Tên khách hàng",
	},
	{
		value: "fullname",
		title: "Tên khách nhận",
	},
	{
		value: "phone",
		title: "SĐT nhận",
	},
	{
		value: "order_code",
		title: "Mã đơn hàng",
	},
];

const filterToActivity = [
	{
		value: "ALL",
		title: "Tất cả",
	},
	{
		value: "WAITING",
		title: activityOrderTranslator("WAITING")?.value,
	},
	{
		value: "CONFIRMED",
		title: activityOrderTranslator("CONFIRMED")?.value,
	},
	{
		value: "PROCESSING",
		title: activityOrderTranslator("PROCESSING")?.value,
	},
	{
		value: "COMPLETED",
		title: activityOrderTranslator("COMPLETED")?.value,
	},
	{
		value: "CANCELED",
		title: activityOrderTranslator("CANCELED")?.value,
	},
];

const ContentScanSkin = () => {
	const location = useLocation();

	const stateFromCategory = location?.state;
	const filterWithCategory = {
		searchBy: stateFromCategory?.searchBy,
		value: stateFromCategory?.value,
	};

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [filterQuery, setFilterQuery] = useState({
		fields: ["$all", { user: ["$all"] }],
		filter: {},
		order: [["created_at", "desc"]],
	});
	const [filterOption, setFilterOption] = useState(filterToActivity[0].value);
	const [searchBy, setSearchBy] = useState(filterWithCategory.searchBy || searchList[0].value);
	const [searchValue, setSearchValue] = useState(filterWithCategory.value || "");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const filterOptionFunc = (e) => {
		const newSearchBy = e.target.value;
		setFilterOption(newSearchBy);
		// navigate({
		// 	pathname: location.pathname,
		// });
	};
	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};

		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters[searchBy] = value;
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	const [inforCombo, setInforCombo] = useState({
		open: false,
		data: null,
	});
	const [activityModal, setActivityModal] = useState(false);
	const handleOpenActivity = (data) => {
		setActivityModal(true);
		setData(data.row);
	};
	// Table
	const handleChangeAccept = async (checked, id, isWithClient) => {
		try {
			await orderAPI.update(
				id,

				!isWithClient
					? {
							is_paid: checked,
					  }
					: {
							is_confirm_with_client: checked,
					  }
			);
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListScanContent();
		} catch (error) {
			enqueueSnackbar(error?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenChangeAccept = (e, params, isWithClient = false) => {
		let checked = e.target.checked;
		modalContext.showDeleteConfirm(
			params.id,
			() => handleChangeAccept(checked, params.id, isWithClient),
			`${
				checked
					? `Bạn có chắc chắn xác nhận  ${
							isWithClient ? "đã gọi điện  khách hàng?" : "đã thanh toán?"
					  } `
					: `Bạn có chắc chắn xác nhận ${
							isWithClient ? "chưa gọi điện  khách hàng?" : "chưa thanh toán?"
					  }`
			}`,
			"Chắc chắn"
		);
	};

	const columns = [
		{
			field: "title",
			headerName: "Chủ đề",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "des",
			headerName: "Lời khuyên",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "image",
			headerName: "Danh sách ảnh",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.5,
			renderCell: (params) => (
				<img
					height={"80%"}
					className={"aspect1-1"}
					src={params.value || IMAGES.avatar_default}
					alt="avatar-img"
					onError={(e) => (e.target.src = IMAGES.avatar_default)}
					onClick={(e) => setImageZoom({ open: true, image: e.target.src })}
				/>
			),
		},
		{
			field: "actions",
			headerName: t(`actions`),
			flex: 0.5,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<>
					{employeeInfo?.id === params.id
						? null
						: employeeInfo?.role === "SUPERADMIN" && (
								<>
									<Tooltip title={"Thông tin chi tiết"}>
										<IconButton aria-label="edit" onClick={() => handleOpenInfoModal(params.row)}>
											<IconNotification />
										</IconButton>
									</Tooltip>
									<Tooltip title={"Cập nhật"}>
										<IconButton
											aria-label="edit"
											onClick={() => {
												setData(params.row);
												setOpen(true);
												setIsUpdate(true);
											}}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
								</>
						  )}
				</>
			),
		},
	];

	const [information, setInformation] = useState({
		visible: false,
	});
	const handleOpenInfoModal = (data) => {
		//	Example DATA
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin lời khuyên",
				gridProps: {},
				children: [
					{
						id: "title",
						title: "Chủ đề",
						value: data?.title,
						component: Text,
						childrenProps: {
							span: 24,
						},
					},
					{
						id: "des",
						title: "Lời khuyên",
						value: data?.des,
						component: Text,
						childrenProps: {
							span: 24,
						},
					},
				],
			},
		];

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
			orderId: data.id,
		}));
	};

	const getListScanContent = async () => {
		setIsLoading(true);
		if (queryParams?.[searchBy]) {
			if (searchBy == "$user.fullname$") {
				filterQuery.fields = [
					"$all",
					{ user: ["$all", { $filter: { fullname: { $iLike: `%${searchValue?.trim()}%` } } }] },
				];
			} else {
				filterQuery.filter = {
					...filterQuery.filter,
					[searchBy]: { $iLike: `%${searchValue?.trim()}%` },
				};
			}
		} else {
			filterQuery.filter = {};
			filterQuery.fields = ["$all", { user: ["$all"] }];
		}

		filterQuery.filter = {
			...filterQuery.filter,
			activity: filterOption,
		};

		if (!filterOption || filterOption === "ALL") {
			delete filterQuery.filter.activity;
		}
		try {
			const res = await scanSkinAPI.getListContent({
				fields: JSON.stringify(filterQuery.fields),
				order: JSON.stringify(filterQuery.order),
				filter: JSON.stringify(filterQuery.filter),
				limit: pagination.limit,
				page: queryParams.page,
			});
			// const paginationRes = {
			// 	...pagination,
			// 	page: res?.pagination?.current_page,
			// 	total: res?.results?.objects?.count,
			// };
			// const resList = res?.results?.objects?.rows?.map((item, index) => ({
			// 	...item,
			// 	order: `${
			// 		paginationRes.page - 1 === 0
			// 			? index === 9
			// 				? 1
			// 				: ""
			// 			: index === 9
			// 			? paginationRes.page
			// 			: paginationRes.page - 1
			// 	}${index === 9 ? 0 : index + 1}`,
			// }));

			// setFilterQuery(filterQuery);
			setSelectList([]);
			setRows(res.data);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);

	const handleRemoveMulti = async () => {
		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await orderAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListScanContent();
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleDelete = useCallback(async (id) => {
		try {
			await orderAPI.remove(id);

			await getListScanContent();
			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	}, []);

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};
	const { role } = useCustomAuth();

	useEffect(() => {
		if (!role) return;
		if (role?.SCAN_SKIN !== ROLE?.SCAN_SKIN) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}

		getListScanContent();
	}, [queryParams, loading, filterOption, role]);
	useEffect(() => {
		getListScanContent();
	}, []);
	const dispatch = useDispatch();
	return (
		<>
			<MainCard>
				{/* {filterCategory?.isFromCategory && (
					<Button
						variant="contained"
						onClick={() => navigate("/service-category")}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về trang dịch vụ
					</Button>
				)} */}
				<HeaderNavigate
					title={"Danh sách lời khuyên"}
					onCreate={false}
					onNavigate={() => {
						navigate("/create-order", {
							state: {
								path: "/order",
								fromOrder: true,
							},
						});
						dispatch({ type: SET_MENU, opened: false });
					}}
					// onCreate={employeeInfo?.role === "SUPERADMIN" ? true : false}
				/>
				{/* <Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
						}}
					>
						<SearchComponent
							searchBy={searchBy}
							searchList={searchList}
							searchValue={searchValue}
							handleSearchBy={handleSearchBy}
							handleChangeSearch={handleChangeSearch}
						/>

						<FormControl sx={{ minWidth: 150, ml: 5 }}>
							<InputLabel id="demo-simple-select-outlined-label">{"Trạng thái"}</InputLabel>
							<Select
								defaultValue={filterToActivity[0].value}
								onChange={filterOptionFunc}
								labelId="demo-simple-select-outlined-label"
								label={"Tìm kiếm"}
								value={filterOption}
							>
								{filterToActivity?.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>

					{employeeInfo?.role === "SUPERADMIN" && (
						<Button
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box> */}
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							// checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
			<ScanSkinContentModal
				getListTable={getListScanContent}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>

			<ImageModal
				title={"Ảnh"}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>

			<DialogMain
				maxWidth={"lg"}
				title={"Dịch vụ có trong gói"}
				fullWidth
				open={inforCombo.open}
				onClose={() => {
					setInforCombo((prev) => ({
						...prev,
						open: false,
					}));
				}}
			>
				<ComboServiceInfo data={inforCombo.data} />
			</DialogMain>

			<ModalAntd
				title={"Chi tiết lời khuyên"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>
			<OrderActivityModal
				getListTable={getListScanContent}
				data={data}
				open={activityModal}
				setOpen={(value) => setActivityModal(value)}
			/>
		</>
	);
};

export default ContentScanSkin;
