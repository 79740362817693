import moment from "moment";

export const convertToDataSchedulerForm = (data = [], title = "Không có tiêu đề") => {
	const schedulerDataForm = data.map((item) => ({
		...item,
		title: item.title || title,
		priorityId: item?.slot_id,
		startDate: moment(parseInt(item.time_start)).format(),
		endDate: moment(parseInt(item.time_start)).add(item?.execution_time, "minutes").format(),
	}));
	return schedulerDataForm;
};

export function timeConvert(n) {
	var num = parseInt(n);
	var hours = num / 60;
	var rhours = Math.floor(hours);
	var minutes = (hours - rhours) * 60;
	var rminutes = Math.round(minutes);
	if (rminutes < 10) {
		rminutes = ``;
	} else {
		rminutes = ` ${rminutes} phút`;
	}
	if (rhours === 0) {
		rhours = ``;
	} else {
		rhours = `${rhours} tiếng`;
	}
	return rhours + rminutes;
}
