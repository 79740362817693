import { GET_LIST_BRANCH, SELECT_BRANCH } from "store/actions/BranchAction/types";

const initialState = {
	branchList: [],
	branchActiveId: "",
	branchActive: {},
};

export const BranchReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_LIST_BRANCH: {
			const branchList = payload;
			let branchActiveUpdate;
			let branchActiveIdUpdate;
			if (!!state.branchActiveId) {
				branchActiveIdUpdate = state.branchActiveId;
			} else {
				branchActiveUpdate = branchList.find((item) => item.is_main === true);
				branchActiveIdUpdate = branchActiveUpdate.id;
			}
			return {
				...state,
				branchList: payload,
				branchActiveId: branchActiveIdUpdate,
				branchActive: branchActiveUpdate,
			};
		}
		case SELECT_BRANCH: {
			return { ...state, branchActiveId: payload };
		}

		default:
			return state;
	}
};
