import { ROLE } from "constants/employeeRole";

const { IconLink } = require("@tabler/icons");

const link = (waitingRequest, role) => ({
	id: "link",
	title: "Liên kết",
	type: "group",
	visible: role?.LINK === ROLE.LINK,

	children: [
		{
			id: "ShareLink",
			title: "link_share",
			type: "item",
			url: "link-share",
			icon: IconLink,
			breadcrumbs: false,
			badge: false,
			visible: true,
		},
	],
});

export default link;
