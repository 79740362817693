import { AddOutlined, EditOutlined, FileCopyOutlined } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { Button, Col, List, Row, Select } from "antd";
import employeeAPI from "api/EmployeeAPI";
import roleAPI from "api/RoleAPI";
import { ROLE_DETAILS } from "constants/employeeRole";
import { useSnackbar } from "notistack";
import { LoadingContext } from "providers/LoadingProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import DialogMain from "ui-component/modal-form/DialogMui";
import PermissionModal from "ui-component/modal-form/PermissionModal";
import { checkEmptyData } from "./func";
import PermissionList from "./PermissionList";
import { useTheme } from "@mui/material/styles";
import styles from "./Permission.module.scss";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);
function Permission({ rows }) {
	const employee = useLocation().state?.rows;
	const navigate = useNavigate();

	const loader = useContext(LoadingContext);
	const [settingData, setSettingData] = useState({});
	const [roleTemplate, setRoleTemplate] = useState([]);
	const [templateId, setTemplateId] = useState("");
	const { branchActiveId, branchList } = useSelector((state) => state.branchReducer);
	const [branchId, setBranchId] = useState(branchActiveId);

	const [permissionModal, setPermissionModal] = useState({
		open: false,
		isUpdate: false,
		data: {},
	});

	const handleActionPermissionTemplate = (isUpdate) => {
		if (isUpdate) {
			setPermissionModal((prev) => ({
				...prev,
				open: true,
				isUpdate: true,
			}));
		} else {
			setPermissionModal((prev) => ({
				...prev,
				open: true,
				isUpdate: false,
			}));
		}
	};

	const handleSetData = useCallback((data, name) => {
		setSettingData((prev) => ({
			...prev,
			[name]: data,
		}));
	}, []);

	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);

	const fetchRoleTemplate = async () => {
		loader.showLoading();

		const params = {
			fields: '["$all"]',
		};
		try {
			const res = await roleAPI.getTemplateList(params);
			setRoleTemplate(res.results.objects.rows);
			loader.hideLoading();
		} catch (error) {
			console.log(error);
			loader.hideLoading();
		}
	};
	const fetchRoleDetailByTemplate = async (templateId) => {
		loader.showLoading();

		const params = {
			fields: '["$all", {"template_detail": ["$all", {"role_detail" : ["type"]}]}]',
		};
		try {
			const res = await roleAPI.findTemplate(templateId, params);
			const employee_role = res?.results?.object?.template_detail?.map(
				(item) => item.role_detail.type
			);
			setDefaultValue(employee_role);

			loader.hideLoading();
			return res?.results?.object;
		} catch (error) {
			console.log(error);
			loader.hideLoading();
		}
	};

	const handleUpdateRole = async () => {
		setLoading(true);
		//Call api update Role

		const arrRole = Object.values(settingData).flatMap((it) => it);
		const rolesId = ROLE_DETAILS.map((role) => {
			if (arrRole.includes(role.type)) {
				return role.id;
			} else {
				return null;
			}
		}).filter((it) => !!it !== false);
		try {
			const res = await employeeAPI.updateRole({
				employee_id: employee.id,
				arr_role_detail: rolesId,
				branch_id: branchId,
			});
			enqueueSnackbar("Phân quyền thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			await fetchEmployeeRole();
			setLoading(false);
		} catch (error) {
			enqueueSnackbar(error?.message || "Phân quyền thất bại", {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);
		}
	};

	const isFailFetchRole = useRef(false);

	const [defaultValue, setDefaultValue] = useState([]);
	const fetchEmployeeRole = async () => {
		loader.showLoading();
		try {
			const params = {
				employee_id: employee.id,
				branch_id: branchId,
			};
			const res = await employeeAPI.getStaffRole(params);
			const employee_role = res?.results?.objects?.map((item) => item.type);
			setDefaultValue(employee_role);
			isFailFetchRole.current = false;
			loader.hideLoading();
		} catch (error) {
			isFailFetchRole.current = true;
			loader.hideLoading();
		}
	};

	useEffect(() => {
		if (!branchActiveId) return;
		fetchEmployeeRole();
		setTemplateId("");
		setPermissionModal({
			open: false,
			isUpdate: false,
			data: {},
		});
	}, [branchId]);

	useEffect(() => {
		fetchRoleTemplate();
	}, []);

	useEffect(() => {
		if (templateId) {
			fetchRoleDetailByTemplate(templateId);
		}
	}, [templateId]);

	if (!employee || isFailFetchRole.current) {
		enqueueSnackbar("Có lỗi xảy ra, vui lòng thử lại", {
			variant: "error",
			autoHideDuration: 2000,
		});
		navigate("/employee", { replace: true });
	}

	const isEmpty = checkEmptyData(settingData);
	const onClose = (templateName = undefined) => {
		setPermissionModal((prev) => ({
			...prev,
			open: false,
			isUpdate: false,
			templateName: templateName || prev.templateName,
		}));
	};
	const afterUpdate = async (templateId, isDelete) => {
		let res;
		if (templateId) {
			res = await fetchRoleDetailByTemplate(templateId);
		}
		fetchRoleTemplate();
		onClose(res?.title || roleTemplate[0]?.title);
		if (isDelete) {
			setTemplateId(roleTemplate[0]?.id);
		}
	};

	const theme = useTheme();
	return (
		<>
			<MainCard>
				<Row style={{ margin: "10px", marginBottom: "20px" }}>
					<Col span={12}>
						<Stack flexDirection={"row"} alignItems={"center"}>
							<Typography variant={"h4"} mr={2}>
								Vai trò:
							</Typography>
							<Select
								showSearch
								onChange={(value, option) => {
									setTemplateId(value);
									setPermissionModal((prev) => ({
										...prev,
										templateName: option.title,
									}));
								}}
								value={templateId}
								style={{ width: 200, borderBottom: "1px solid black" }}
								bordered={false}
								placeholder="Chọn vai trò"
								optionFilterProp="children"
								filterOption={(input, option) => (option?.title ?? "").includes(input)}
								filterSort={(optionA, optionB) =>
									(optionA?.title ?? "")
										.toLowerCase()
										.localeCompare((optionB?.title ?? "").toLowerCase())
								}
								fieldNames={{ label: "title", value: "id" }}
								options={roleTemplate}
							/>
							<Stack
								alignSelf={"flex-end"}
								flexDirection="row"
								gap={1}
								sx={{ borderBottom: "1px solid black" }}
							>
								<IconButton
									aria-label="edit"
									size="small"
									disabled={!templateId}
									onClick={() => handleActionPermissionTemplate(true)}
								>
									<EditOutlined fontSize="inherit" />
								</IconButton>
								{/* <IconButton disabled={!templateId} aria-label="copy" size="small">
									<FileCopyOutlined fontSize="inherit" />
								</IconButton> */}
								<IconButton
									aria-label="create"
									size="small"
									onClick={() => handleActionPermissionTemplate(false)}
								>
									<AddOutlined fontSize="inherit" />
								</IconButton>
							</Stack>
						</Stack>
					</Col>
				</Row>

				<Row gutter={[32, 32]}>
					<Col span={6}>
						<List
							header={<Typography variant="h4">Chọn chi nhánh</Typography>}
							bordered
							dataSource={branchList}
							renderItem={(item) => {
								return (
									<List.Item
										className={cx("branch__active")}
										style={{
											backgroundColor:
												branchId === item.id ? theme.palette.primary.light : undefined,
										}}
										onClick={() => setBranchId(item.id)}
									>
										{item?.name}
									</List.Item>
								);
							}}
						/>
					</Col>
					<Col span={18}>
						<Row>
							<PermissionList defaultValue={defaultValue} handleSetData={handleSetData} />
						</Row>
					</Col>
				</Row>
				<Row justify="end">
					<Col span={8}>
						<Row justify="end">
							<Col span={12}>
								<Button
									disabled={isEmpty}
									style={{ width: "100%" }}
									loading={loading}
									onClick={handleUpdateRole}
								>
									Cập nhật
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</MainCard>
			<DialogMain
				title={permissionModal.isUpdate ? "Cập nhật vai trò" : "Tạo vai trò"}
				maxWidth={"lg"}
				fullWidth
				open={permissionModal.open}
				onClose={() => {
					setPermissionModal((prev) => ({
						...prev,
						open: false,
						isUpdate: false,
					}));
				}}
			>
				<PermissionModal
					closeModal={(templateId, isDelete) => afterUpdate(templateId, isDelete)}
					getRoleTemplate={fetchRoleTemplate}
					titleTemplate={permissionModal.templateName || ""}
					defaultValue={defaultValue}
					isUpdate={permissionModal.isUpdate}
					open={permissionModal.open}
					templateId={templateId}
				/>
			</DialogMain>
		</>
	);
}

export default Permission;
