import { StorefrontOutlined, UnarchiveOutlined } from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import { COLOR } from "constants/common";
import React, { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import CreateOrder from "../CreateOrder";

function CashierPage() {
	const [tab, setTab] = useState("ORDER");
	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<MainCard>
			<Tabs
				sx={{
					"& .MuiTabs-flexContainer": {
						flexWrap: "wrap",
						gap: 1,
						my: 2,
					},
				}}
				TabIndicatorProps={{ sx: { display: "none" } }}
				value={tab}
				onChange={handleChange}
				centered
				variant="fullWidth"
			>
				<Tab
					icon={<StorefrontOutlined />}
					iconPosition="start"
					label="Tạo đơn hàng"
					value={"ORDER"}
					sx={{
						border: "1px solid black",
						borderRadius: "5000px",
						fontSize: "12px",
						minHeight: "20px",
						"&.Mui-selected": {
							color: "#fff",
							background: COLOR.MAIN_COLOR,
						},
					}}
				/>
				<Tab
					icon={<UnarchiveOutlined />}
					iconPosition="start"
					label="Xuất hàng nội bộ"
					value={"INTERNAL"}
					sx={{
						border: "1px solid black",
						borderRadius: "5000px",
						fontSize: "12px",
						minHeight: "20px",
						"&.Mui-selected": {
							color: "#fff",
							background: COLOR.MAIN_COLOR,
						},
					}}
				/>
			</Tabs>

			<Box>
				<CreateOrder tab={tab} />
			</Box>
		</MainCard>
	);
}

export default CashierPage;
