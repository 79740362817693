import { Box, Button } from "@mui/material";
import inventoryAPI from "api/InventoryAPI";
import InputField from "components/modules/form/InputField";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";

const schema = yup.object().shape({
	inventory_number: yup
		.number()
		.required("Vui lòng điền giá trị")
		.typeError("Giá trị phải là một số")
		.integer("Số phải là một số nguyên dương"),
});

function UpdateInventory({ formData, onUpdateSuccess }) {
	const { handleSubmit, control, errors } = useForm({
		mode: "onChange",
		defaultValues: {
			inventory_number: formData?.inventory_number || 0,
		},

		resolver: yupResolver(schema),
	});

	const [loading, setLoading] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const onSubmit = async (data) => {
		setLoading(true);

		try {
			const res = await inventoryAPI.updateInventory({ ...data, id: formData.id });
			enqueueSnackbar("Cập nhật tồn kho thành công!", {
				variant: "success",
				autoHideDuration: 2000,
			});
			setLoading(false);
			onUpdateSuccess();
		} catch (error) {
			enqueueSnackbar(error.message || "Có lỗi xảy ra, vui lòng thử lại sau!", {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);

			console.log(error);
		}
	};
	return (
		<Box component="form" onSubmit={handleSubmit(onSubmit)}>
			<InputField
				errors={errors}
				name={"inventory_number"}
				label={"Số lượng sản phẩm"}
				control={control}
			/>
			<Box sx={{ textAlign: "right", my: 2 }}>
				<LoadingButton type="submit" loading={loading} variant="outlined">
					Cập nhật
				</LoadingButton>
			</Box>
		</Box>
	);
}

export default UpdateInventory;
