import { LoadingButton } from "@mui/lab";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import debtAPI from "api/DebtAPI";
import InputField from "components/modules/form/InputField";
import { handleMaskValue, handleParseMask } from "func";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import SelectField from "components/modules/form/SelectField";
import { PATTERN } from "constants/common";
import { getStringWithCommasFromNumber } from "utils";

// const schema = yup.object().shape({
// 	value: yup.string().required("Vui lòng điền giá trị"),
// });

function DebtPaymentForm({ formData, userId, isUpdate, onSuccess }) {
	const { handleSubmit, control, errors, watch } = useForm({
		mode: "onChange",

		defaultValues: {
			debt: formData || 0,
			payment_type: "CASH",
		},

		// resolver: yupResolver(schema, {}),
	});

	const paymentVal = watch("value");

	const [loading, setLoading] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const onSubmit = async (data) => {
		setLoading(true);

		const body = {
			value: +handleParseMask(data?.value),
			user_id: userId,
			type: "PAYMENT",
			payment_type: data?.payment_type,

			note: data?.note || "",
			time: +moment(new Date()).format("x"),
			branch_id: branchActiveId,
		};
		try {
			const res = await debtAPI.create(body);
			enqueueSnackbar("Điều chỉnh thành công!", {
				variant: "success",
				autoHideDuration: 2000,
			});
			setLoading(false);
			onSuccess();
		} catch (error) {
			enqueueSnackbar(error?.message || "Có lỗi xảy ra, vui lòng thử lại sau!", {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);

			console.log(error);
		}
	};

	const handleCustomizeMask = (e, onChange) => {
		const value = handleMaskValue(e.target.value);
		onChange(value);
	};

	return (
		<Box component="form" onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Grid container spacing={2} alignItems="center">
								<Grid item xs={4}>
									<Typography variant="h5" component="p">
										Dư nợ hiện tại:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography variant="body1" component="p">
										{getStringWithCommasFromNumber(formData)}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5" component="p">
										Thu từ khách:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<InputField
										label={""}
										name="value"
										errors={errors}
										control={control}
										rules={{
											required: "Giá trị nợ không để trống",
											pattern: {
												value: PATTERN?.NUMBER_POSITIVE,
												message: "Giá trị phải là một số dương",
											},
											validate: {
												minValue: (v) =>
													Number(handleParseMask(v)) <= Number(formData) ||
													"Thu từ khách không thể lớn hơn số tiền khách nợ",
											},
										}}
										customizeOnChange={handleCustomizeMask}
									/>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5" component="p">
										Dư nợ cuối:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									{getStringWithCommasFromNumber(formData - handleParseMask(paymentVal || 0))}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2} alignItems="start">
								<Grid item xs={4}>
									<Typography variant="h5" component="p">
										Phương thức thanh toán:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<SelectField
										variant={"standard"}
										fullWidth
										label={""}
										name="payment_type"
										control={control}
									>
										<MenuItem value="CASH">Tiền mặt</MenuItem>
										<MenuItem value="TRANSFER">Chuyển khoản</MenuItem>
										<MenuItem value="CARD">Quẹt thẻ</MenuItem>
									</SelectField>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} alignItems="center">
					<Grid container spacing={2} alignItems="start">
						<Grid item xs={4}>
							<Typography variant="h5" component="p">
								Mô tả:
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<InputField
								rows={4}
								multiline
								label={""}
								placeholder="Mô tả"
								name="note"
								control={control}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Box sx={{ textAlign: "right", my: 2 }}>
				<LoadingButton type="submit" loading={loading} variant="outlined">
					Thanh toán
				</LoadingButton>
			</Box>
		</Box>
	);
}

export default DebtPaymentForm;
