import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../locales/en/translation";
import translationKR from "../locales/kr/translation";

// the translations
const resources = {
	en: {
		translation: translationEN,
	},
	kr: {
		translation: translationKR,
	},
};

i18n.use(initReactI18next).init({
	resources,
	// fallbackLng: localStorage.getItem("i18nextLng") || "kr",
	fallbackLng: "kr",
	// debug: true,
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
});

export default i18n;
