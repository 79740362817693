import SelectMultipleField from "components/modules/form/SelectMultipleField";
import React from "react";
import { useSelector } from "react-redux";

function BranchFilter({ name, control, ...props }) {
	const { branchList } = useSelector((state) => state.branchReducer);
	return (
		<>
			<SelectMultipleField
				titleOption={"name"}
				optionData={branchList}
				name={name}
				control={control}
				{...props}
			/>
		</>
	);
}

export default BranchFilter;
