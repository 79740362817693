// assets
import {
	IconUser,
	IconUsers,
	IconShieldCheck,
	IconBuildingStore,
	IconQrcode,
	IconCoin,
	IconMassage,
	IconReportMoney,
	IconBookmarks,
	IconMenu,
	IconPolaroid,
	IconNotebook,
	IconStack,
	IconBoxPadding,
	IconNews,
	IconBasket,
	IconReplace,
	IconDisc,
	IconUserCheck,
	IconHistory,
	IconTicket,
	IconLine,
	IconLink,
	IconUserPlus,
	IconReport,
} from "@tabler/icons";
import { ROLE } from "constants/employeeRole";
import { storeRedux } from "store";

// constant
const icons = {
	IconReport,
	IconLine,
	IconUser,
	IconShieldCheck,
	IconBuildingStore,
	IconQrcode,
	IconTicket,
	IconCoin,
	IconUsers,
	IconMassage,
	IconReportMoney,
	IconBookmarks,
	IconMenu,
	IconPolaroid,
	IconNotebook,
	IconStack,
	IconBoxPadding,
	IconBasket,
	IconReplace,
	IconDisc,
	IconUserCheck,
	IconHistory,
	IconLink,
	IconNews,
	IconUserPlus,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const general = (waitingRequest, role) => ({
	id: "utilities",
	title: "Quản lý",
	type: "group",
	visible:
		ROLE.EMPLOYEE_VIEW === role?.EMPLOYEE_VIEW ||
		ROLE.RECHARGE === role?.RECHARGE ||
		ROLE.WITHDRAW === role?.WITHDRAW ||
		ROLE.POST_VIEW === role?.POST_VIEW ||
		role?.ADVISE === ROLE.ADVISE ||
		ROLE.DEBT === role?.DEBT ||
		ROLE.REPORT === role?.REPORT,

	children: [
		{
			id: "Employee",
			title: "employee",
			type: "item",
			url: "employee",
			icon: icons.IconUsers,
			breadcrumbs: false,
			badge: false,
			visible: ROLE.EMPLOYEE_VIEW === role?.EMPLOYEE_VIEW,
		},

		{
			id: "joinCollab",
			title: "join_collab_list",
			type: "item",
			url: "join-collab-list",
			icon: icons.IconUserPlus,
			breadcrumbs: false,
			badge: false,
			visible: ROLE.EMPLOYEE_VIEW === role?.EMPLOYEE_VIEW,
		},

		{
			id: "RechargeWithdraw",
			title: "rechargeWithdraw",
			type: "item",
			url: "recharge-withdraw",
			icon: icons.IconReplace,
			breadcrumbs: false,
			badge: true,
			count: waitingRequest?.count_transaction,
			visible: ROLE.RECHARGE === role?.RECHARGE || ROLE.WITHDRAW === role?.WITHDRAW,
		},

		{
			id: "Invoices",
			title: "invoices",
			type: "item",
			url: "invoices/order",
			icon: icons.IconReport,
			breadcrumbs: false,
			badge: true,
			visible: ROLE.REPORT === role?.REPORT,
		},
		{
			id: "PostCategory",
			title: "postCategory",
			type: "item",
			url: "post-category",
			icon: icons.IconNews,
			breadcrumbs: false,
			badge: true,
			visible: ROLE.POST_VIEW === role?.POST_VIEW,
		},

		{
			id: "RegisterTest",
			title: "registerTest",
			type: "item",
			url: "register-test",
			icon: icons.IconNotebook,
			breadcrumbs: false,
			badge: true,
			count: waitingRequest.count_user_register_testing,
			visible: role?.ADVISE === ROLE.ADVISE,
		},
	],
});

export default general;
