import FilterLayout from "layout/FilterLayout";
import HeaderOnly from "layout/HeaderOnly";
import InvoicesLayout from "layout/InvoicesLayout";
import MainLayout from "layout/MainLayout";
import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loadable from "ui-component/Loadable";
import config from "../config";
import { FilterRoutes } from "./FilterRoutes";
import { HeaderOnlyRoutes } from "./HeaderOnlyRoutes";
// routes
import MainRoutes from "./MainRoutes";

const AuthLogin3 = Loadable(
	lazy(() => import("views/pages/authentication/authentication3/Login3"))
);

export default function ThemeRoutes({ isLoggedIn }) {
	return useRoutes(
		[
			{
				path: "/",
				element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
				...MainRoutes,
			},
			{
				path: "/cashier",
				element: isLoggedIn ? <HeaderOnly /> : <Navigate to="/login" />,
				...HeaderOnlyRoutes,
			},
			{
				path: "/invoices",
				element: isLoggedIn ? <InvoicesLayout /> : <Navigate to="/login" />,
				...FilterRoutes,
			},
			{
				path: "/login",
				element: !isLoggedIn ? <AuthLogin3 /> : <Navigate to="/users" />,
				exact: true,
			},
			{
				path: "/*",
				element: isLoggedIn ? <Navigate to={"/dashboard"} /> : <Navigate to="/login" />,
			},
		],
		config.basename
	);
}
