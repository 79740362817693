import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import SelectedItem from "./SelectedItem";

function SelectedService({ isUpdate = false, ...props }) {
	const selectedServices = useSelector((state) => state.booking.checkBoxList);
	const comboUsing = useSelector((state) => state.userComboService.comboServices);
	return (
		<Box
			sx={{
				padding: "16px 24px",
			}}
			{...props}
		>
			{selectedServices.length !== 0 && (
				<>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Typography variant="h5" sx={{ flex: 2, textAlign: "left" }}>
							Dịch vụ
						</Typography>
						<Typography variant="h5" sx={{ flex: 1, textAlign: "center" }}>
							Số lượng
						</Typography>
						<Typography variant="h5" sx={{ flex: 1, textAlign: "center" }}>
							Giá dịch vụ
						</Typography>
						<Typography variant="h5" sx={{ flex: 1, textAlign: "center" }}>
							Tổng cộng
						</Typography>
						<Typography variant="h5" sx={{ flex: 1, textAlign: "center" }}>
							Ghi chú
						</Typography>
						<Typography variant="h5" sx={{ flex: 0.5, textAlign: "center" }}>
							Xóa
						</Typography>
					</Box>
					<Divider sx={{ mt: 2 }} />
				</>
			)}

			{selectedServices.map(
				(service) =>
					service.combo_id === null && (
						<SelectedItem key={service.id} service={service} isUpdate={isUpdate} />
					)
			)}

			{comboUsing.length !== 0 && <Typography variant="h5">Combo dịch vụ</Typography>}
			{comboUsing.map(
				(service) =>
					service.combo_id !== null && (
						<SelectedItem key={service.id} service={service} isCombo={true} />
					)
			)}
		</Box>
	);
}

export default SelectedService;
