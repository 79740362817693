import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Checkbox,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import UploadImage from "ui-component/UploadImage";
import uploadAPI from "api/UploadAPI";
import { Input } from "antd";
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useSelector } from "react-redux";
import styles from "./ServiceCategoryModal.module.scss";
import classnames from "classnames/bind";
import { remove } from "lodash";
import OtpInput from "react-otp-input";
import moment from "moment";
import ServiceArea from "../BookingModal/ServiceArea";
import serviceCategoryAPI from "api/ServiceCatogeryAPI";
import { Close } from "@mui/icons-material";
import { PATTERN } from "constants/common";

const cx = classnames.bind(styles);

const defaultTypeList = [
	{
		value: "CLASSIC",
		title: "CLASSIC",
	},
	{
		value: "SILVER",
		title: "SILVER",
	},
	{
		value: "GOLD",
		title: "GOLD",
	},
	{
		value: "DIAMOND",
		title: "DIAMOND",
	},
	{
		value: "SIGNATURE",
		title: "SIGNATURE",
	},
];
const TextValidation = styled("span")({
	color: "red",
});
const Required = styled("span")({
	color: "red",
});
function ServiceCategoryModal({ ...props }) {
	const { isOpen, isClose, isLoading, formData, isUpdate, listSelect, getListTable } = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));

	const { register, handleSubmit, errors, control, reset, getValues, watch, setValue } = useForm({
		defaultValues: isUpdate && formData,
		mode: "onChange",
	});
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [type, setType] = useState("NOTIFICATION");
	//ICON

	// const [image, setImage] = useState(formData.icon);
	// useEffect(() => {
	// 	setImage(formData.icon);
	// }, [formData]);

	//END ICON

	const handleClose = () => {
		isClose(false);
	};
	const onSubmit = async (data) => {
		try {
			setLoading(true);
			if (!isUpdate) {
				// const urlImg = await handleUpload();
				// data.icon = urlImg;
				data.order = !!data.order === false ? null : data.order;

				const response = await serviceCategoryAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			} else {
				// const urlImg = await handleUpload();
				// data.icon = urlImg;
				data.order = !!data.order === false ? null : data.order;

				const response = await serviceCategoryAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};
	useEffect(() => {
		if (isUpdate) {
			setCkData(formData?.implementation_process);
			setType(formData?.category_service_id);
		} else {
			setCkData("");
			setType(selectList && selectList[0].value);
		}
		return () => {
			reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const translator = (text) => {
		return `abeauty.categoryService.dialog_form.${text}`;
	};
	//Icon Image
	// const handleUpload = async () => {
	// 	const formData = new FormData();
	// 	formData.append("image", image);

	// 	try {
	// 		const response = await uploadAPI.uploadImage(formData);
	// 		return response.results.object.url;
	// 	} catch (error) {
	// 		console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
	// 	}
	// };
	return (
		<div>
			<Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle
					sx={{
						fontSize: "22px",
						textAlign: "center",
					}}
					variant={"h3"}
				>
					{isUpdate ? "Cập nhật danh mục dịch vụ" : "Tạo mới danh mục dịch vụ"}
				</DialogTitle>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogContent>
						<Grid
							container
							spacing={1}
							style={{
								marginBottom: 20,
							}}
						>
							<Grid item xs={12}>
								<FormLabel component="legend">{t(translator("title"))}</FormLabel>

								<Controller
									name="title"
									control={control}
									rules={{
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Vui lòng điền tên danh mục",
										},
									}}
									defaultValue={isUpdate ? formData.title : ""}
									as={
										<TextField
											hiddenLabel
											margin="dense"
											type="text"
											fullWidth
											size="small"
											variant="filled"
										/>
									}
								/>

								{errors.title && <TextValidation>{errors.title.message}</TextValidation>}
							</Grid>

							<Grid item xs={12}>
								<FormLabel component="legend">Mô tả danh mục</FormLabel>

								<Controller
									name="description"
									control={control}
									// rules={{
									// 	validate: {
									// 		stringSpace: (v) =>
									// 			!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
									// 	},
									// 	required: {
									// 		value: true,
									// 		message: "Vui lòng điền mô tả",
									// 	},
									// }}
									defaultValue={isUpdate ? formData.description : ""}
									as={
										<TextField
											multiline
											minRows={2}
											hiddenLabel
											margin="dense"
											type="text"
											fullWidth
											size="small"
											variant="filled"
										/>
									}
								/>

								{errors.description && (
									<TextValidation>{errors.description.message}</TextValidation>
								)}
							</Grid>

							<Grid item xs={12}>
								<FormLabel component="legend">Thứ tự</FormLabel>

								<Controller
									name="order"
									control={control}
									rules={{
										validate: {
											stringSpace: (v) =>
												!v?.toString()?.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										pattern: {
											value: PATTERN.IS_NUMBER,
											message: "Giá trị phải là một số",
										},
										// required: {
										// 	value: true,
										// 	message: "Vui lòng điền thứ tự",
										// },
									}}
									defaultValue={isUpdate ? formData.order : ""}
									as={
										<TextField
											hiddenLabel
											margin="dense"
											type="text"
											fullWidth
											size="small"
											variant="filled"
										/>
									}
								/>

								{errors.order && <TextValidation>{errors.order.message}</TextValidation>}
							</Grid>

							<Grid item xs={6}>
								<FormLabel component="legend">{t(translator("hot"))}</FormLabel>
								<Switch
									name="hot"
									inputRef={register()}
									defaultChecked={isUpdate ? formData.hot : false}
								/>
							</Grid>
							{/* <Grid item xs={6}>
								<FormLabel component="legend">{t(translator("status"))}</FormLabel>
								<Switch
									name="status"
									inputRef={register()}
									defaultChecked={isUpdate ? formData.status : false}
								/>
							</Grid> */}

							{/* <Grid item xs={2}>
								<FormLabel component="legend">{t(translator("icon"))}</FormLabel>
								<UploadImage
									image={image}
									setImage={setImage}
									aspectRatio={"1:1"}
									levelAspectRatio={2}
								/>
							</Grid> */}
						</Grid>
					</DialogContent>
					<DialogActions
						sx={{
							justifyContent: "center",
						}}
					>
						<LoadingButton
							type="submit"
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="contained"
							className={cx("button__action")}
						>
							{isUpdate ? t(translator("update")) : t(translator("create"))}
						</LoadingButton>
					</DialogActions>

					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showErr.state}
						onClose={() => setShowErr({ state: false })}
						// action={action}
						autoHideDuration={5000}
						severity="error"
					>
						<Alert severity="error">{showErr.message}</Alert>
					</Snackbar>
				</form>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default ServiceCategoryModal;
