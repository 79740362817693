import { Box, Tooltip } from "@mui/material";

export const columnTable = [
	{
		field: "username",
		headerName: "SĐT",
		flex: 1,
		sortable: false,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => (
			<Tooltip
				title={params.value}
				overlayStyle={{ maxWidth: "500px" }}
				placement="right"
				zIndex="10000"
			>
				<Box
					sx={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					{params.value}
				</Box>
			</Tooltip>
		),
	},

	{
		field: "fullname",
		headerName: "Tên khách hàng",
		flex: 1,
		sortable: false,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => (
			<Tooltip
				title={params.value}
				overlayStyle={{ maxWidth: "500px" }}
				placement="right"
				zIndex="10000"
			>
				<Box
					sx={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					{params.value}
				</Box>
			</Tooltip>
		),
	},
	{
		field: "name_admin_note",
		headerName: "Tên thân mật",
		flex: 1,
		sortable: false,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => (
			<Tooltip
				title={params.value}
				overlayStyle={{ maxWidth: "500px" }}
				placement="right"
				zIndex="10000"
			>
				<Box
					sx={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					{params.value}
				</Box>
			</Tooltip>
		),
	},

	{
		field: "parent_collab",
		headerName: "Tuyến trên",
		flex: 1,
		editable: false,
		sortable: false,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => (
			<Tooltip title={params.row?.user?.username}>
				<Box
					sx={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					{params.row?.user?.username}
				</Box>
			</Tooltip>
		),
	},

	{
		field: "is_virtual_account",
		headerName: "Loại khách hàng",
		flex: 1,
		editable: false,
		sortable: false,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => (
			<Tooltip
				title={!params.value ? "Khách hàng đã đăng ký" : "Khách lẻ"}
				overlayStyle={{ maxWidth: "500px" }}
				placement="right"
				zIndex="10000"
			>
				<Box
					sx={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					{!params.value ? "Khách hàng đã đăng ký" : "Khách lẻ"}
				</Box>
			</Tooltip>
		),
	},

	// {
	// 	field: "created_at",
	// 	headerName: t(`created_at`),
	// 	sortable: false,
	// 	minWidth: 280,
	// 	headerAlign: "center",
	// 	align: "center",
	// 	flex: 1,
	// },
];
