import { OutlinedInput } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Select as SelectAntd } from "antd";
import i18n from "i18next";
import "./style.scss";

export const getLanguage = () => {
	return i18n.language || (typeof window !== "undefined" && window.localStorage.i18nextLng) || "en";
};

export const OutlineInputStyle = styled(OutlinedInput)(({ theme }) => ({
	width: 200,
	marginLeft: 16,
	paddingLeft: 16,
	paddingRight: 16,
	"& input": {
		background: "transparent !important",
		paddingLeft: "4px !important",
	},
	"& > fieldset": {
		"& > legend": {
			// display: "none",
		},
	},
	[theme.breakpoints.down("lg")]: {
		width: 250,
	},
	[theme.breakpoints.down("md")]: {
		width: "100%",
		marginLeft: 4,
		background: "#fff",
	},
}));

export const SelectCustom = (props) => {
	return (
		<Box
			sx={{
				"& .ant-select-selector": {
					color: "#fff",
					backgroundColor: "#39f !important",
					borderColor: "#39f !important",
				},

				"& .ant-select-single.ant-select-open .ant-select-selection-item": {
					color: "#fff !important",
				},

				"& .ant-select-arrow": {
					color: "#fff",
				},
			}}
		>
			<SelectAntd {...props}>{props?.children}</SelectAntd>
		</Box>
	);
};

export const Root = styled("div")`
	table {
		font-family: arial, sans-serif;
		border-collapse: collapse;
		width: 100%;
	}

	td,
	th {
		border: 1px solid #ddd;
		text-align: left;
		padding: 8px;
	}

	th {
		background-color: #red;
	}
`;

export const MyStyledGrid = styled(DataGrid, () => ({
	[`& MuiDataGrid-iconButtonContainer > css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root}`]: {
		visibility: "visible",
		width: "auto",
	},
}));

export const showSortStyle = {
	"& .header-sort": {
		"& .MuiDataGrid-iconButtonContainer": {
			width: "auto !important",
			visibility: "visible !important",
			"& .MuiDataGrid-sortIcon": {
				opacity: "0.4 !important",
				color: "blue",
			},
		},
	},
	"& .MuiDataGrid-columnHeader--sorted.header-sort": {
		"& .MuiDataGrid-iconButtonContainer": {
			"& .MuiDataGrid-sortIcon": {
				opacity: "1 !important",
			},
		},
	},
};
