import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	FormControl,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import productAPI from "api/ProductAPI";
import uploadAPI from "api/UploadAPI";
import voucherAPI from "api/VoucherAPI";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UploadImage from "ui-component/UploadImage";
import { getStringWithCommasFromNumber } from "utils";
import { fieldsCanUpdate, typeList } from "./data";
import moment from "moment";
import { handleParseMask } from "func";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import { CKEditor } from "@ckeditor/ckeditor5-react";

const TextValidation = styled("span")({
	color: "red",
});

const ARRAY_LENGTH = 1;

function VoucherModal({ ...props }) {
	const {
		isOpen,
		isClose,
		isLoading,
		formData,
		isUpdate,
		listSelect,
		getListTable,
		stateLocation,
	} = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));
	const {
		register,
		handleSubmit,
		errors,
		control,
		reset,
		setValue: setValueHookForm,

		getValues,
		clearErrors,

		watch,
	} = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
	});
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [editor, setEditor] = useState(null);
	const [type, setType] = useState("");
	const [typeProduct, setTypeProduct] = useState(typeList[0].value);

	// const [image, setImage] = useState(formData.image);
	// useEffect(() => {
	// 	setImage(formData.image);
	// }, [formData]);

	const defaultImageList = Array.apply(null, Array(ARRAY_LENGTH)); //Khởi tạo Array có 4 phần tử
	const [imageList, setImageList] = useState(defaultImageList);

	const setImageInList = (file, idxImgInList) => {
		const imageListCopy = [...imageList];
		imageListCopy.splice(idxImgInList, 1, file);
		setImageList(imageListCopy);
	};
	useEffect(() => {
		if (isUpdate && formData.images) {
			const copyArrayUndefined = Array.apply(null, Array(ARRAY_LENGTH)); //Khởi tạo Array có 4 phần tử
			copyArrayUndefined.splice(0, formData.images.length, ...formData.images);
			setImageList(formData.images.length === 0 ? defaultImageList : copyArrayUndefined);
		}
	}, [isOpen]);
	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleClose = () => {
		isClose(false);
	};
	const rx_live = /^\d*$/;
	const handleMaskValue = (value) => {
		const temp = value.split(",").join("");
		if (rx_live.test(temp)) {
			return temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return temp;
		}
	};
	const removeComma = (numberString) => {
		return numberString.toString().split(",").join("");
	};
	const { unitId } = useSelector((state) => state.unitReducer);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	//Group multiple

	const [groupMulti, setGroupMulti] = useState([]);

	// const handleChangeSelectMulti = (event) => {
	// 	const array = event.target.value;
	// 	const unique = [...new Map(array.map((m) => [m.value, m])).values()];
	// 	console.log(unique);
	// 	setGroupMulti(unique);
	// };
	const handleChangeSelectMulti = (event) => {
		const {
			target: { value },
		} = event;
		setGroupMulti(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	const onSubmit = async (data) => {
		try {
			setLoading(true);

			if (!isUpdate) {
				const urlImgList = [];

				for (let i = 0; i < imageList.length; i++) {
					if (imageList[i]) {
						const res = await handleUpload(i);
						urlImgList.push(res);
					}
				}

				data.images = urlImgList;
				data.thumbnail = urlImgList.length > 0 ? urlImgList[0] : "";
				data.expiry = Number(moment(data.expiry).endOf("day").format("x"));
				data.amount = handleParseMask(data.amount);
				data.discount = handleParseMask(data.discount);
				data.max_discount = handleParseMask(data.max_discount);

				data.minimum_price = handleParseMask(data.minimum_price);
				data.description = "";
				data.unit = "%";
				data.voucher_detail = ckData || "";
				const response = await voucherAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			} else {
				const urlImgList = [];

				for (let i = 0; i < 4; i++) {
					if (imageList[i]) {
						if (typeof imageList[i].name === "string") {
							// Check imageList có phải là một file
							const res = await handleUpload(i);
							urlImgList.push(res);
						} else {
							//Không phải là File => là url hình ảnh, push lại vào mảng truyền lên
							urlImgList.push(imageList[i]);
						}
					}
				}

				data.images = urlImgList;
				data.thumbnail = urlImgList.length > 0 ? urlImgList[0] : "";
				data.expiry = Number(moment(data.expiry).endOf("day").format("x"));
				data.amount = handleParseMask(data.amount);
				data.discount = handleParseMask(data.discount);
				data.max_discount = handleParseMask(data.max_discount);

				data.minimum_price = handleParseMask(data.minimum_price);
				data.description = "";

				data.voucher_detail = ckData || "";

				Object.keys(data).forEach(function (key, index) {
					//Check fields can update
					if (!fieldsCanUpdate.includes(key)) {
						delete data[key];
					}
				});

				const response = await voucherAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};

	useEffect(() => {
		if (isUpdate) {
			setCkData(formData?.voucher_detail || "");
		} else {
			setCkData("");
		}
		return () => {
			setImageList(defaultImageList);
			setGroupMulti([]);
			reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const translator = (text) => {
		return `abeauty.product.dialog_form.${text}`;
	};
	const handleUpload = async (idx) => {
		const formData = new FormData();
		formData.append("image", imageList[idx]);

		try {
			const response = await uploadAPI.uploadImage(formData);
			return response.results.object.url;
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};

	const MenuProps = {
		PaperProps: {
			style: {
				pt: 0,
				pb: 0,
			},
		},
		MenuListProps: {
			style: {
				pt: 0,
				pb: 0,
			},
		},
	};

	return (
		<div>
			<Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle variant="h4">
					{isUpdate ? "Cập nhật mã giảm giá" : "Tạo mới mã giảm giá"}
				</DialogTitle>
				<DialogTitle variant="h5" color="success">
					Lưu ý: Mã giảm giá sẽ được tạo tự động
				</DialogTitle>

				<form>
					<DialogContent>
						<Grid
							container
							spacing={3}
							style={{
								marginBottom: 20,
							}}
						>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="name"
									label={"Tên mã giảm"}
									type="text"
									required
									fullWidth
									defaultValue={isUpdate ? formData?.name : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.toString().startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Vui lòng điền tên mã giảm",
										},
									})}
								/>
								{errors.name && <TextValidation>{errors.name.message}</TextValidation>}
							</Grid>
							{/* <Grid item xs={4}>
								<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="demo-simple-select-autowidth-label">Đơn vị giảm</InputLabel>
									<Controller
										as={
											<Select
												labelId="demo-simple-select-autowidth-label"
												id="demo-simple-select-autowidth"
												disabled
												label="Đơn vị giảm"
											>
												<MenuItem value={"%"}>Phần trăm</MenuItem>
												<MenuItem value={"K"}>Nghìn đồng</MenuItem>
											</Select>
										}
										name="unit"
										defaultValue={isUpdate ? formData?.unit : "%"}
										control={control}
									/>
								</FormControl>
							</Grid> */}
							<Grid item xs={4}>
								<FormControl variant="standard" sx={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="demo-simple-select-autowidth-label">Loại mã giảm</InputLabel>
									<Controller
										as={
											<Select
												labelId="demo-simple-select-autowidth-label"
												id="demo-simple-select-autowidth"
												label="Loại mã giảm"
												disabled={isUpdate}
											>
												<MenuItem value={"ORDER"}>Đơn hàng</MenuItem>
												<MenuItem value={"BOOKING"}>Lịch hẹn</MenuItem>
											</Select>
										}
										name="voucher_type"
										defaultValue={isUpdate ? formData?.voucher_type : "ORDER"}
										control={control}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="amount"
									label={"Số lượt sử dụng"}
									type="text"
									fullWidth
									inputMode="numeric"
									required
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									defaultValue={isUpdate ? getStringWithCommasFromNumber(formData?.amount) : ""}
									variant="standard"
									inputRef={register({
										required: {
											value: true,
											message: "Vui lòng nhập số lượng tồn kho",
										},
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số",
										},
										validate: {
											min: (v) => {
												if (!isUpdate) {
													return Number(removeComma(v)) > 0 || "Số lượt sử dụng phải lớn hơn 0";
												} else {
													return;
												}
											},

											maxValue: (v) =>
												Number(removeComma(v)) <= 2000000000 || "Giá trị lớn nhất là 2 tỉ",
										},
									})}
								/>
								{errors.amount && <TextValidation>{errors.amount.message}</TextValidation>}
							</Grid>

							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="discount"
									label={"Giá trị mã giảm"}
									type="text"
									fullWidth
									required
									inputMode="numeric"
									defaultValue={isUpdate ? getStringWithCommasFromNumber(formData?.discount) : ""}
									variant="standard"
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									inputRef={register({
										required: {
											value: true,
											message: "Vui lòng điền giá sản phẩm",
										},
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số dương",
										},

										validate: {
											minValue: (v) => {
												if (!isUpdate) {
													return Number(removeComma(v)) > 0 || "Giá trị mã giảm phải lớn hơn 0đ";
												} else {
													return;
												}
											},
										},
									})}
								/>
								{errors.discount && <TextValidation>{errors.discount.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="minimum_price"
									label="Giá trị đơn tối thiểu để sử dụng"
									type="text"
									fullWidth
									inputMode="numeric"
									defaultValue={
										isUpdate ? getStringWithCommasFromNumber(formData?.minimum_price) : ""
									}
									variant="standard"
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									inputRef={register({
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số dương",
										},

										validate: {
											minValue: (v) => {
												return (
													Number(removeComma(v)) >= 0 || "Giá trị mã giảm phải lớn hơn hoặc bằng 0đ"
												);
											},
											maxValue: (v) =>
												Number(removeComma(v)) <= 2000000000 || "Giá trị lớn nhất là 2 tỉ đồng",
										},
									})}
								/>
								{errors.minimum_price && (
									<TextValidation>{errors.minimum_price.message}</TextValidation>
								)}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="max_discount"
									label={"Giá trị giảm tối đa"}
									type="text"
									fullWidth
									required
									inputMode="numeric"
									defaultValue={
										isUpdate ? getStringWithCommasFromNumber(formData?.max_discount) : ""
									}
									variant="standard"
									onChange={(e) => {
										e.target.value = handleMaskValue(e.target.value);
									}}
									inputRef={register({
										required: {
											value: true,
											message: "Vui lòng điền giá sản phẩm",
										},
										pattern: {
											value: /^[0-9,]*$/,
											message: "Giá trị phải là một số dương",
										},

										validate: {
											minValue: (v) => {
												if (!isUpdate) {
													return Number(removeComma(v)) > 0 || "Giá trị mã giảm phải lớn hơn 0đ";
												} else {
													return;
												}
											},

											maxValue: (v) =>
												Number(removeComma(v)) <= 2000000000 || "Giá trị lớn nhất là 2 tỉ đồng",
											// lessThanPrice: (v) => {
											// 	const priceValue = getValues("minimum_price");
											// 	return (
											// 		Number(removeComma(v)) <= Number(removeComma(priceValue)) ||
											// 		"Giá trị giảm tối đa phải nhỏ hơn giá trị đơn tối thiểu được áp dụng mã giảm"
											// 	);
											// },
											// maxValue: (v) =>
											// 	Number(removeComma(v)) <= 100 || "Giá trị lớn nhất là 100(%)",
										},
									})}
								/>
								{errors.max_discount && (
									<TextValidation>{errors.max_discount.message}</TextValidation>
								)}
							</Grid>

							<Grid item xs={4}>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<Controller
										control={control}
										name="expiry"
										defaultValue={isUpdate ? moment(Number(formData?.expiry)) : moment(new Date())}
										as={
											<DatePicker
												label="Ngày hết hạn mã giảm"
												renderInput={(params) => (
													<TextField fullWidth margin="dense" variant="standard" {...params} />
												)}
											/>
										}
									/>
								</LocalizationProvider>
							</Grid>
							{/* 
							<Grid item xs={12}>
								<TextField
									margin="dense"
									name="description"
									label={"Mô tả trong liên kết chia sẻ"}
									type="text"
									defaultValue={isUpdate ? formData.description : ""}
									fullWidth
									multiline
									minRows={4}
									variant="standard"
									inputRef={register()}
								/>
							</Grid> */}
							<Grid item xs={12}>
								<Typography variant="ul" color="warning">
									Thông tin hình ảnh
								</Typography>
							</Grid>

							{Array.isArray(imageList) &&
								imageList.map((image, idx) => {
									return (
										<Grid
											item
											xs={12}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												mb: "20px",
												flexDirection: "column",
											}}
										>
											<UploadImage
												image={image}
												setImage={(file) => {
													return setImageInList(file, idx);
												}}
												aspectRatio="1:1"
												levelAspectRatio={6}
												id={idx}
											>
												<ul>
													<li>Định dạng file: [.JPEG, .JPG, .PNG]</li>
													<li>Tỉ lệ hình ảnh: 900x900</li>
												</ul>
											</UploadImage>
										</Grid>
									);
								})}
							{imageList?.length === 0 && <TextValidation> Vui lòng chọn hình ảnh</TextValidation>}
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								mb: "20px",
								flexDirection: "column",
								overflow: "hidden",
							}}
						>
							<FormLabel component="legend">Mô tả chi tiết mã giảm</FormLabel>

							<CKEditor
								onReady={(editor) => {
									// Insert the toolbar before the editable area.
									editor.ui
										.getEditableElement()
										.parentElement.insertBefore(
											editor.ui.view.toolbar.element,
											editor.ui.getEditableElement()
										);
									setEditor(editor);
								}}
								onError={({ willEditorRestart }) => {
									// If the editor is restarted, the toolbar element will be created once again.
									// The `onReady` callback will be called again and the new toolbar will be added.
									// This is why you need to remove the older toolbar.
									if (willEditorRestart) {
										editor.ui.view.toolbar.element.remove();
									}
								}}
								onChange={(event, editor) => {
									const data = editor.getData();
									setCkData(data);
								}}
								editor={DecoupledEditor}
								data={ckData}
								config={{
									ckfinder: {
										uploadUrl: `https://hitek-02.hitek.com.vn:6061/api/v1/image/upload_ckeditor`,
									},

									mediaEmbed: {
										previewsInData: true,
										elementName: "iframe",
									},
								}}
							/>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => isClose(false)} variant="contained" color="secondary">
							{t(`cancel`)}
						</Button>
						<LoadingButton
							onClick={handleSubmit(onSubmit)}
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="contained"
						>
							{isUpdate ? t(translator("update")) : t(translator("create"))}
						</LoadingButton>
					</DialogActions>

					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showErr.state}
						onClose={() => setShowErr({ state: false })}
						// action={action}
						autoHideDuration={5000}
						severity="error"
					>
						<Alert severity="error">{showErr.message}</Alert>
					</Snackbar>
				</form>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default VoucherModal;
