import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import SelectedItem from "./SelectedItem";

function SelectedService() {
	const selectedServices = useSelector((state) => state.booking.checkBoxList);
	const comboUsing = useSelector((state) => state.userComboService.comboServices);
	return (
		<Box
			sx={{
				padding: "16px 24px",
			}}
		>
			{selectedServices.length !== 0 && <Typography variant="h5">Dịch vụ ngoài combo</Typography>}
			{selectedServices.map(
				(service) =>
					service.combo_id === null && <SelectedItem key={service.id} service={service} />
			)}

			{comboUsing.length !== 0 && <Typography variant="h5">Dịch vụ trong combo</Typography>}
			{comboUsing.map(
				(service) =>
					service.combo_id !== null && (
						<SelectedItem key={service.id} service={service} isCombo={true} />
					)
			)}
		</Box>
	);
}

export default SelectedService;
