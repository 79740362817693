import {
	FmdGoodOutlined,
	LocationCityOutlined,
	LocationOnOutlined,
	LocationOnRounded,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { Select } from "antd";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actSelectBranch } from "store/actions/BranchAction";
const { Option } = Select;
function SelectWithSearch() {
	const dispatch = useDispatch();
	let refSelect = useRef(null);

	const onChange = async (value, option) => {
		await dispatch(actSelectBranch(value));
		refSelect.current?.blur();
	};

	const onSearch = (value) => {
		console.log("search:", value);
	};
	// const onBlur = (value) => {
	// 	console.log("blur");
	// };
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);
	const theme = useTheme();
	return (
		<Select
			showSearch
			style={{
				width: 300,
				background: theme.palette.secondary.light,
				marginRight: "20px",
				borderRadius: "8px",
				fontWeight: "regular",
			}}
			size="large"
			bordered={false}
			suffixIcon={<FmdGoodOutlined color="primary" />}
			optionFilterProp="children"
			ref={refSelect}
			value={branchActiveId}
			onChange={onChange}
			onSearch={onSearch}
			filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
		>
			{Array.isArray(branchList) &&
				branchList.map((branch) => <Option value={branch.id}>{branch.name}</Option>)}
		</Select>
	);
}

export default SelectWithSearch;
