import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Checkbox,
	FormControl,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import productAPI from "api/ProductAPI";
import UploadImage from "ui-component/UploadImage";
import uploadAPI from "api/UploadAPI";
import { Close, Watch } from "@mui/icons-material";
import { getStringWithCommasFromNumber } from "utils";
import { productTranslator } from "func";
import { PATTERN, PRODUCT_SCAN_SKIN_TYPE, SKIN_LEVEL } from "constants/common";
import UnitSelect from "ui-component/UnitSelct";
import { useSelector } from "react-redux";
import { addressVN } from "constants/addressVN";
import supplierAPI from "api/SupplierAPI";
const defaultTypeList = [
	{
		value: "NOTIFICATION",
		title: "Notification",
	},
	{
		value: "TERMS_CONDITIONS",
		title: "Terms & Conditions",
	},
	{
		value: "LOCATION",
		title: "Location",
	},
];
const TextValidation = styled("span")({
	color: "red",
});
const Required = styled("span")({
	color: "red",
});
function SupplierModal({ ...props }) {
	const {
		isOpen,
		isClose,
		isLoading,
		formData,
		isUpdate,
		listSelect,
		getListTable,
		stateLocation,
	} = props;
	//Xử lý listSelect thành dạng object Array
	let selectList = listSelect?.map((data) => ({
		value: data.id,
		title: data.title,
	}));
	const {
		register,
		handleSubmit,
		errors,
		control,
		reset,
		setValue: setValueHookForm,

		getValues,
		clearErrors,

		watch,
	} = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
	});
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [editor, setEditor] = useState(null);
	const [type, setType] = useState("");

	// const [image, setImage] = useState(formData.image);
	// useEffect(() => {
	// 	setImage(formData.image);
	// }, [formData]);
	const defaultImageList = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
	const [imageList, setImageList] = useState(defaultImageList);

	const setImageInList = (file, idxImgInList) => {
		const imageListCopy = [...imageList];
		imageListCopy.splice(idxImgInList, 1, file);
		setImageList(imageListCopy);
	};
	useEffect(() => {
		if (isUpdate && formData.image) {
			const copyArrayUndefined = Array.apply(null, Array(4)); //Khởi tạo Array có 4 phần tử
			copyArrayUndefined.splice(0, formData.image.length, ...formData.image);
			setImageList(formData.image.length === 0 ? defaultImageList : copyArrayUndefined);
		}
	}, [formData]);
	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleClose = () => {
		isClose(false);
	};
	const rx_live = /^\d*$/;
	const handleMaskValue = (value) => {
		const temp = value.split(",").join("");
		if (rx_live.test(temp)) {
			return temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return temp;
		}
	};
	const removeComma = (numberString) => {
		return numberString.toString().split(",").join("");
	};
	const { unitId } = useSelector((state) => state.unitReducer);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const onSubmit = async (data) => {
		try {
			setLoading(true);

			if (!isUpdate) {
				const response = await supplierAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			} else {
				const response = await supplierAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					isLoading(1);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};
	// useEffect(() => {
	// 	if (image) {
	// 		setError({ type: "image", message: "" });
	// 	}
	// }, [image]);
	useEffect(() => {
		if (isUpdate) {
			setCkData(formData?.sub_description || "");
			setType(formData?.category_id);
		} else {
			setCkData("");
			setType(
				stateLocation?.isFromCategory ? stateLocation?.id : selectList && selectList[0].value
			);
		}
		return () => {
			setImageList(defaultImageList);
			reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const translator = (text) => {
		return `abeauty.product.dialog_form.${text}`;
	};
	const handleUpload = async (idx) => {
		const formData = new FormData();
		formData.append("image", imageList[idx]);

		try {
			const response = await uploadAPI.uploadImage(formData);
			return response.results.object.url;
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};
	const [cityList, setCityList] = useState(addressVN);
	const [wardList, setWardList] = useState([]);
	const [districtList, setDistrictList] = useState([]);
	return (
		<div>
			<Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle>{isUpdate ? "Cập nhật nhà cung cấp" : "Tạo nhà cung cấp"}</DialogTitle>
				<form>
					<DialogContent>
						<Grid
							container
							spacing={3}
							style={{
								marginBottom: 20,
							}}
						>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="email"
									label={"Email nhà cung cấp"}
									type="text"
									fullWidth
									defaultValue={isUpdate ? formData?.email : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										pattern: {
											value: PATTERN.EMAIL,
											message: "Email không đúng định dạng",
										},
									})}
								/>
								{errors.email && <TextValidation>{errors.email.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="company"
									label={"Công ty"}
									type="text"
									fullWidth
									defaultValue={isUpdate ? formData?.company : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Công ty không để trống",
										},
									})}
								/>
								{errors.company && <TextValidation>{errors.company.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="name"
									label={"Tên nhà cung cấp"}
									type="text"
									fullWidth
									defaultValue={isUpdate ? formData?.name : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Tên nhà cung cấp không được để trống",
										},
									})}
								/>
								{errors.name && <TextValidation>{errors.name.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="phone"
									label={"Số điện thoại"}
									type="text"
									fullWidth
									defaultValue={isUpdate ? formData?.phone : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Số điện thoại không được để trống",
										},
										pattern: {
											value: PATTERN.PHONE_NUMBER_VN,
											mesage: "Số điện thoại không đúng định dạng",
										},
									})}
								/>
								{errors.phone && <TextValidation>{errors.phone.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="tax_code"
									label={"Mã số thuế"}
									type="text"
									fullWidth
									defaultValue={isUpdate ? formData?.tax_code : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Mã số thuế không được để trống",
										},
									})}
								/>
								{errors.tax_code && <TextValidation>{errors.tax_code.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<TextField
									margin="dense"
									name="address"
									label={"Địa chỉ"}
									type="text"
									fullWidth
									defaultValue={isUpdate ? formData?.address : ""}
									variant="standard"
									inputRef={register({
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
									})}
								/>
								{errors.address && <TextValidation>{errors.address.message}</TextValidation>}
							</Grid>
							<Grid item xs={4}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="cityLabel">Thành phố/Tỉnh</InputLabel>
									<Controller
										render={(props) => (
											<Select
												margin="dense"
												variant="standard"
												fullWidth
												labelId="cityLabel"
												id="demo-simple-select-disabled"
												label="Thành phố"
												onChange={(e) => {
													setDistrictList(e.target.value.level2s);
													setWardList([]);
													props.onChange(e);
												}}
											>
												{cityList.map((city) => (
													<MenuItem key={city.level1_id} value={city}>
														{city.name}
													</MenuItem>
												))}
											</Select>
										)}
										// rules={{ required: "Vui lòng chọn thành phố/tỉnh" }}
										control={control}
										name="city"
									/>
								</FormControl>
								<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
									{errors?.city?.message}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<FormControl sx={{ width: "100%" }} disabled={districtList.length === 0}>
									<InputLabel id="districtLabel">Quận/Huyện</InputLabel>
									<Controller
										render={(props) => (
											<Select
												variant="standard"
												fullWidth
												labelId="districtLabel"
												id="districtId"
												label="Quận/Huyện"
												onChange={(e) => {
													setWardList(e.target.value.level3s);
													props.onChange(e);
												}}
											>
												{districtList.map((district) => (
													<MenuItem key={district.level1_id} value={district}>
														{district.name}
													</MenuItem>
												))}
											</Select>
										)}
										control={control}
										name="district"
										// rules={{ required: "Vui lòng chọn quận/huyện" }}
									/>
								</FormControl>
								<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
									{errors?.district?.message}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<FormControl sx={{ width: "100%" }} disabled={wardList.length === 0}>
									<InputLabel id="wardLabel">Phường/Xã</InputLabel>
									<Controller
										as={
											<Select
												variant="standard"
												fullWidth
												labelId="wardLabel"
												id="wardId"
												label="Phường/Xã"
											>
												{wardList.map((ward) => (
													<MenuItem key={ward.level1_id} value={ward}>
														{ward.name}
													</MenuItem>
												))}
											</Select>
										}
										// rules={{ required: "Vui lòng chọn phường/xã" }}
										control={control}
										name="ward"
									/>
								</FormControl>
								<Typography variant="h5" color="error" sx={{ margin: "5px" }}>
									{errors?.ward?.message}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<TextField
									margin="dense"
									name="note"
									label={"Ghi chú"}
									type="text"
									defaultValue={isUpdate ? formData.note : ""}
									fullWidth
									multiline
									minRows={4}
									variant="standard"
									inputRef={register()}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => isClose(false)} variant="contained" color="secondary">
							{t(`cancel`)}
						</Button>
						<LoadingButton
							onClick={handleSubmit(onSubmit)}
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="contained"
						>
							{isUpdate ? t(translator("update")) : t(translator("create"))}
						</LoadingButton>
					</DialogActions>

					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showErr.state}
						onClose={() => setShowErr({ state: false })}
						// action={action}
						autoHideDuration={5000}
						severity="error"
					>
						<Alert severity="error">{showErr.message}</Alert>
					</Snackbar>
				</form>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default SupplierModal;
