import axiosClient from "api/axiosClient";

const userAPI = {
	async getList(params) {
		const url = `/user`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async getQrForShop(params) {
		const url = `/user/get_qr_link_share_for_shop_owner`;
		const res = await axiosClient.get(url, { params });
		return res;
	},
	async depositFirstTime(data) {
		const url = `/user/deposit/first_time`;
		const res = await axiosClient.post(url, data);
		return res;
	},
	async roseDeposit(data) {
		const url = `/user/deposit_rose`;
		const res = await axiosClient.post(url, data);
		return res;
	},

	async findOne(id, params) {
		const url = `user/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async create(data) {
		const url = `/user`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async update(id, data) {
		const url = `/user/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async remove(id) {
		const url = `/user/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},

	async removeMulti(params) {
		const url = `/user`;
		const resp = await axiosClient.delete(url, { params });
		return resp;
	},

	async joinCollab(data) {
		const url = `/user/admin/join_collab`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},
};

export default userAPI;
