// assets
import { IconNotification, IconNotebook, IconBookmarks, IconCalendarTime } from "@tabler/icons";
import { ROLE } from "constants/employeeRole";
import { useSelector } from "react-redux";
import { storeRedux } from "../store";

// constant
const icons = { IconNotification, IconNotebook, IconBookmarks, IconCalendarTime };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = (waitingRequest, role) => ({
	id: "booking",
	title: "Lịch hẹn",
	type: "group",
	badge: true,
	count: waitingRequest?.count_booking,
	visible: role?.BOOKING_VIEW === ROLE.BOOKING_VIEW,
	children: [
		{
			id: "Booking",
			title: "booking",
			type: "item",
			url: "booking",
			icon: icons.IconCalendarTime,
			breadcrumbs: false,
			badge: false,
			visible: true,
		},

		{
			id: "BookingScheduler",
			title: "bookingScheduler",
			type: "item",
			url: "booking-scheduler",
			icon: icons.IconNotebook,
			breadcrumbs: false,
			badge: false,
			visible: true,
		},
	],
});

export default other;
