export const settingTree = [
	{
		title: "Cài đặt tổng quan",
		key: "SETTING",
		// children: [
		// 	{
		// 		title: "Xem DS",
		// 		key: "SETTING_VIEW", // id role
		// 	},
		// 	{
		// 		title: "Thêm mới",
		// 		key: "SETTING_CREATE",
		// 	},
		// 	{
		// 		title: "Cập nhật",
		// 		key: "SETTING_UPDATE",
		// 	},
		// 	{
		// 		title: "Xóa",
		// 		key: "SETTING_DELETE",
		// 	},
		// ],
	},
	{
		title: "Hạn mức dịch vụ",
		key: "OFFER",
	},
	{
		title: "Ảnh bìa",
		key: "BANNER",
		children: [
			{
				title: "Xem DS",
				key: "BANNER_VIEW", // id role
			},
			{
				title: "Thêm mới",
				key: "BANNER_CREATE",
			},
			{
				title: "Cập nhật",
				key: "BANNER_UPDATE",
			},
			{
				title: "Xóa",
				key: "BANNER_DELETE",
			},
		],
	},
	{
		title: "Chi nhánh",
		key: "BRANCH",
		children: [
			{
				title: "Xem DS",
				key: "BRANCH_VIEW", // id role
			},
			{
				title: "Thêm mới",
				key: "BRANCH_CREATE",
			},
			{
				title: "Cập nhật",
				key: "BRANCH_UPDATE",
			},
			{
				title: "Xóa",
				key: "BRANCH_DELETE",
			},
		],
	},
];
