import { FormControlLabel, Grid, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "antd";
import scanSkinAPI from "api/ScanSkinAPI";
import { IMAGES } from "assets/images/imagePath";
import { CustomNoRowsOverlay } from "components/noRows";
import _debounce from "lodash/debounce";
import moment from "moment";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import ImageModal from "ui-component/modal-form/ImageModal";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import { getNumberWithCommas } from "utils";
import { showSortStyle } from "views/utilities/CustomStyle";
import { SearchComponent } from "../../components/search";
import Text from "antd/lib/typography/Text";
import { useSnackbar } from "notistack";
import useCustomAuth from "hooks/useCustomAuth";
import { ROLE } from "constants/employeeRole";
import { ALERT_PERMISSION } from "constants/constant";
import { IOSSwitch } from "ui-component/switch";
import { useDispatch } from "react-redux";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";

// import { useSnackbar } from "notistack";
// import { ModalContext } from "providers/ModalProvider";

// const { Option } = SelectAntd;

// const actionList = [
// 	{
// 		value: "edit",
// 		title: "edit",
// 	},
// 	{
// 		value: "delete",
// 		title: "delete",
// 	},
// ];

const searchList = [
	{
		value: "phoneNumber",
		title: "phone_number",
	},
	{
		value: "name",
		title: "Tên khách hàng",
	},
	{
		value: "email",
		title: "Email khách hàng",
	},
	// {
	// 	value: "$user.name_admin_note$",
	// 	title: "Tên thân mật",
	// },
];

const GiftRegister = () => {
	// const { enqueueSnackbar } = useSnackbar();
	// const modalContext = useContext(ModalContext);

	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search]);

	// data
	const [rows, setRows] = useState([]);

	// search
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchList[0].value);

	// sort
	const [sortModel, setSortModel] = useState([]);

	// other
	// const [selectList, setSelectList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});
	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });
	const [information, setInformation] = useState({
		visible: false,
	});
	const handleOpenInforModal = (data) => {
		//	Example DATA

		const dataNew = data?.data?.map((item, idx) => {
			const children = item?.data?.map((childData) => ({
				id: childData.key,
				title: childData.valueVI,
				value: "",
				component: Text,
				childrenProps: {
					span: 12,
				},
			}));
			return {
				groupId: idx,
				groupTitle: item.title?.vi,
				gridProps: {},
				children: children,
			};
		});
		const dataParams = dataNew;

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
		}));
	};
	const dispatch = useDispatch();
	const handleChangeAccept = async (e, params) => {
		let checked = e.target.checked;
		try {
			await scanSkinAPI.updateStatusGift(params?.id, {
				status: !checked,
			});
			getListGiftRegister();
			dispatch(actGetWaitingRequest());
		} catch (error) {}
	};

	const columns = [
		{
			field: "phoneNumber",
			headerName: "SĐT",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.5,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "name",
			headerName: "Tên khách hàng",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "email",
			headerName: "Email khách hàng",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "status",
			headerName: "Tình trạng tư vấn",
			flex: 0.5,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				// params.value = false;

				return (
					<Tooltip
						title={!params.value ? "Đã tư vấn" : "Chưa tư vấn"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<FormControlLabel
							control={
								<IOSSwitch
									sx={{ ml: 4 }}
									checked={!params?.value}
									onChange={(e) => handleChangeAccept(e, params?.row)}
								/>
							}
							label=""
						/>
					</Tooltip>
				);
			},
		},

		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian tạo",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.5,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(Number(params?.value)).format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(Number(params?.value)).format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
	];

	const getListGiftRegister = async () => {
		setIsLoading(true);
		let filterValue = {};

		let order = '[["created_at", "desc"]]';

		if (queryParams?.[searchBy]) {
			filterValue = {
				...filterValue,
				[searchBy]: {
					$iLike: `%${searchValue.trim()}%`,
				},
			};
		}

		if (queryParams?.sortBy) {
			order = `[["${queryParams?.sortBy}", "${queryParams?.sort}"]]`;
		}
		try {
			const res = await scanSkinAPI.getListGift({
				fields: '["$all"]',
				order,
				filter: JSON.stringify(filterValue),
				limit: pagination.limit,
				page: queryParams.page,
			});

			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
				created_at: moment.utc(item.created_at).format("DD/MM/YYYY HH:mm"),
			}));
			setRows(resList);
			setPagination(paginationRes);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleSortModelChange = (newModel) => {
		setSortModel(newModel);
		if (newModel.length > 0) {
			const sortObj = {
				sortBy: newModel?.[0]?.field,
				sort: newModel?.[0]?.sort,
			};
			navigate({
				pathname: location.pathname,
				search: `?${queryString.stringify(sortObj)}`,
			});
		} else {
			navigate({
				pathname: location.pathname,
			});
		}
	};

	const { enqueueSnackbar } = useSnackbar();

	const { role } = useCustomAuth();
	useEffect(() => {
		if (!role) return;
		if (role?.SCAN_SKIN !== ROLE?.SCAN_SKIN) {
			enqueueSnackbar(ALERT_PERMISSION, {
				autoHideDuration: 2000,
				variant: "warning",
			});
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty("username")) {
			setSearchBy("username");
			setSearchValue(queryParams?.username);
		}
		if (queryParams.hasOwnProperty("email")) {
			setSearchBy("email");
			setSearchValue(queryParams?.email);
		}
		if (queryParams.hasOwnProperty("phone_number")) {
			setSearchBy("phone_number");
			setSearchValue(queryParams?.phone_number);
		}
		if (queryParams.hasOwnProperty("type")) {
			setSearchBy("type");
			setSearchValue(queryParams?.type);
		}

		if (queryParams.hasOwnProperty("sortBy")) {
			setSortModel([{ field: queryParams?.sortBy, sort: queryParams?.sort }]);
		}
		getListGiftRegister();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, role]);

	return (
		<>
			<MainCard>
				<HeaderActions title={"Danh sách đăng ký nhận quà"} onCreate={false} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{/* <Button
						variant="outlined"
						color="error"
						disabled={selectList?.length === 0}
						onClick={handleRemoveMulti}
					>
						{t(`remove`)}
					</Button> */}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
							loading={isLoading}
							// checkboxSelection
							// onSelectionModelChange={(ids) => {
							// 	setSelectList(ids);
							// }}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>
			<ModalAntd
				title={"Chi tiết lịch hẹn"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>

			<ImageModal
				title={"Ảnh"}
				classNameImg="aspect1-1"
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>
		</>
	);
};

export default GiftRegister;
