import { Box, Stack } from "@mui/material";
import { Input, InputNumber } from "antd";
import { Radio } from "antd";
import { handleMaskValue, handleParseMask } from "func";

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useCallback } from "react";

function DiscountByType({
	total = 100000,
	onPopoverHide,
	defaultDiscount = 0,
	resultsPrice,
	setResultPrice,
	setDefaultDiscount,
}) {
	const [radio, setRadio] = useState("cash");

	const [currentVal, setCurrentVal] = useState(defaultDiscount);

	const cleanUp = useRef();
	cleanUp.current = {
		currentVal,
		radio,
	};

	const percentValue = useRef(null);
	const switchValue = (valChange) => {
		switch (valChange) {
			case "percent":
				{
					const results = (currentVal / total) * 100;
					setCurrentVal(results);
				}
				break;

			case "cash":
				{
					const results = (currentVal / 100) * total;
					setCurrentVal(results.toFixed(0));
				}
				break;
			default:
				return;
		}
	};
	const renderInput = useCallback(() => {
		switch (radio) {
			case "cash": {
				if (setDefaultDiscount) {
					setDefaultDiscount(currentVal);
				}
				return (
					<InputNumber
						min={0}
						max={total}
						style={{
							borderBottom: "1px solid #000",
							width: "100%",
						}}
						className={"custom__inputNum right"}
						formatter={(value, info) => {
							return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						}}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						value={currentVal}
						onChange={(e) => {
							setCurrentVal(e);
						}}
						stringMode
						bordered={false}
						controls={false}
					/>
				);
			}

			case "percent": {
				const results = (currentVal / 100) * total;
				if (setDefaultDiscount) {
					setDefaultDiscount(results.toFixed(0));
				}
				return (
					<InputNumber
						className={"custom__inputNum right"}
						id="number"
						defaultValue="0"
						min="0"
						max="100"
						// step="0.01"
						formatter={(value, info) => {
							percentValue.current = value;
							return `${value}`.toString().slice(0, 5);
						}}
						bordered={false}
						controls={false}
						style={{ borderBottom: "1px solid #000", width: "100%" }}
						value={currentVal}
						stringMode
						onChange={(e) => {
							setCurrentVal(Number(e));
						}}
					/>
				);
			}

			default:
				return;
		}
	}, [radio, currentVal, total]);
	useEffect(() => {
		setCurrentVal(0);
	}, [total]);

	useEffect(() => {
		switch (radio) {
			case "percent":
				{
					const results = 100 - (resultsPrice / total) * 100;
					setCurrentVal(results);
				}
				break;

			case "cash":
				{
					const results = total - resultsPrice;
					setCurrentVal(results.toFixed(0));
				}
				break;
			default:
				return;
		}
	}, [resultsPrice]);

	useEffect(() => {
		setCurrentVal(defaultDiscount);
	}, []);

	const handleOnChangeRadio = (value) => {
		setRadio(value);
		switchValue(value);
	};

	useEffect(() => {
		return () => {
			if (onPopoverHide) {
				let discountPrice = cleanUp.current?.currentVal;
				const discountType = cleanUp.current?.radio;

				if (discountType === "percent") {
					discountPrice = (discountPrice / 100) * total;
				}
				onPopoverHide(Number(discountPrice).toFixed(0));
			}
		};
	}, []);

	return (
		<Stack flexDirection="row" flexWrap={"nowrap"}>
			{renderInput()}
			<Radio.Group
				value={radio}
				style={{
					display: "flex",
				}}
				onChange={(e) => handleOnChangeRadio(e.target.value)}
				defaultValue="cash"
				buttonStyle="solid"
			>
				<Radio.Button value="cash">VNĐ</Radio.Button>
				<Radio.Button value="percent">%</Radio.Button>
			</Radio.Group>
		</Stack>
	);
}

export default DiscountByType;
