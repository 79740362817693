export const SET_CHECK_LIST = "SET_CHECK_LIST";

export const UPDATE_CHECK_LIST = "UPDATE_CHECK_LIST";

export const UPDATE_VALUE_CHECK_LIST = "UPDATE_VALUE_CHECK_LIST";

export const RELOAD_BOOKING_PAGE = "RELOAD_BOOKING_PAGE";

export const SET_BOOKING_DETAILS = "SET_BOOKING_DETAILS";

export const GET_EMPLOYEE_INFO = "GET_EMPLOYEE_INFO";

export const SET_ASSIGN_DATA = "SET_ASSIGN_DATA";
