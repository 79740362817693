// assets
import {
	IconNotification,
	IconSettings,
	IconGift,
	IconScan,
	IconBook,
	IconReportAnalytics,
} from "@tabler/icons";
import { ROLE } from "constants/employeeRole";

// constant
const icons = { IconNotification, IconSettings, IconScan, IconGift, IconBook, IconReportAnalytics };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const scanskin = (waitingRequest, role) => ({
	id: "scanskin",
	title: "Soi da",
	type: "group",
	visible: role?.SCAN_SKIN === ROLE.SCAN_SKIN,

	children: [
		{
			id: "contentScanSkin",
			title: "contentScanSkin",
			type: "item",
			url: "content-scan-skin",
			icon: icons.IconScan,
			breadcrumbs: false,
			badge: false,
			visible: true,
		},
		{
			id: "historyScan",
			title: "historyScan",
			type: "item",
			url: "history-scan-skin",
			icon: icons.IconBook,
			breadcrumbs: false,
			badge: false,
			visible: true,
		},
		{
			id: "scanSkinGift",
			title: "scanSkinGift",
			type: "item",
			url: "scan-skin-gift",
			icon: icons.IconGift,
			breadcrumbs: false,
			badge: true,
			count: waitingRequest.count_user_request_voucher,
			visible: true,
		},
		{
			id: "groupProduct",
			title: "groupProduct",
			type: "item",
			url: "group-product",
			icon: icons.IconReportAnalytics,
			breadcrumbs: false,
			badge: false,
			visible: true,
		},
	],
});

export default scanskin;
