import { Box, Tab, Tabs } from "@mui/material";
import { Typography } from "antd";
import transactionAPI from "api/TransactionAPI";
import { COLOR } from "constants/common";
import moment from "moment";
import { formatVND, statusTransactionTranslator } from "func";
import { LoadingContext } from "providers/LoadingProvider";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";

function RechargeWithDrawPage() {
	const { state } = useLocation();

	const [value, setValue] = React.useState(state?.type);

	const { transactionId } = useParams();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [data, setData] = useState([]);

	const { showLoading, hideLoading } = useContext(LoadingContext);
	const fetchTransactionById = async () => {
		showLoading();
		const filter = {
			type: state?.type || undefined,
		};
		const payload = {
			fields: '["$all", {"user":["name_admin_note", "phone", "is_collab", "amount_of_buy_card"]}]',
			filter: JSON.stringify(filter),
		};
		try {
			const res = await transactionAPI.findOne(transactionId, payload);

			const data = res?.results?.object;

			const dataParamsWithdraw = [
				{
					groupId: 1,
					groupTitle: "Thông tin rút tiền",
					gridProps: {},
					children: [
						{
							id: "phone",

							title: "Số điện thoại",
							value: data.phone,
							component: Typography.Text,
							childrenProps: {
								//all Props component here
								span: 8,
							},
						},
						{
							id: "transaction_code",
							title: "Mã định danh",
							value: data.transaction_code,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},

						{
							id: "bank_type",
							title: "Ngân hàng",
							value: data.bank_type,
							childrenProps: {
								span: 8,
								color: "error",
							},
						},
						{
							id: "bank_number",
							title: "Số tài khoản ngân hàng",
							value: data.bank_number,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "bank_name",
							title: "Tên chủ tài khoản",
							value: data?.bank_name,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "original_value",
							title: "Giá trị giao dịch",
							value: formatVND(data.original_value),
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "change_amount",
							title: "Số tiền cần chuyển",
							value: data?.change_amount,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "note_admin",
							title: "Nội dung",
							value: data?.note_admin,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "note",
							title: "Ghi chú",
							value: data?.note,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "status_enum",
							title: "Trạng thái",
							value: statusTransactionTranslator(data.status_enum).title,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "created_at",
							title: "Thời gian cập nhật",
							value: moment(data?.created_at).format("DD/MM/YYYY HH:mm"),
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
					],
				},
			];
			const dataParamsDeposit = [
				{
					groupId: 1,
					groupTitle: "Thông tin nạp tiền",
					gridProps: {},
					children: [
						{
							id: "phone",

							title: "Số điện thoại",
							value: data.phone,
							component: Typography.Text,
							childrenProps: {
								//all Props component here
								span: 8,
							},
						},
						{
							id: "employee",
							title: "Nhân viên gửi yêu cầu",
							value: data.employee?.fullname || data.employee?.username,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},

						{
							id: "employee_confirm",
							title: "Ngân hàng",
							value: data.employee_confirm?.fullname || data?.employee_confirm?.username,
							childrenProps: {
								span: 8,
								color: "error",
							},
						},
						{
							id: "original_value",
							title: "Giá trị giao dịch",
							value: formatVND(data.original_value),
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "endow_value",
							title: "Giá trị ưu đãi",
							value: !!data.endow_value && `${data.endow_value}%`,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},

						{
							id: "change_amount",
							title: "Giá trị nhận được",
							value: formatVND(data?.change_amount),
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "note_admin",
							title: "Nội dung",
							value: data?.note_admin,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "note",
							title: "Ghi chú",
							value: data?.note,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "status_enum",
							title: "Trạng thái",
							value: statusTransactionTranslator(data.status_enum).title,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "created_at",
							title: "Thời gian cập nhật",
							value: moment(data?.created_at).format("DD/MM/YYYY HH:mm"),
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
					],
				},
			];
			setData(state?.type === "REQUEST_WITHDRAW" ? dataParamsWithdraw : dataParamsDeposit);
			hideLoading();
		} catch (error) {
			console.log(error);
			hideLoading();
		}
	};

	useEffect(() => {
		fetchTransactionById();
	}, []);

	return (
		<MainCard>
			{/* <Tabs
				centered
				value={value}
				variant="fullWidth"
				onChange={handleChange}
				TabIndicatorProps={{ sx: { display: "none" } }}
				aria-label="basic tabs example"
			>
				<Tab
					sx={{
						fontSize: "12px",
						minHeight: "20px",
						borderBottom: `2px solid ${COLOR.MAIN_COLOR}`,

						"&.Mui-selected": {
							border: `2px solid ${COLOR.MAIN_COLOR}`,
							borderTopLeftRadius: "5px",
							borderTopRightRadius: "5px",
							borderBottom: "0px",
						},
					}}
					label="Nạp tiền"
					value={"REQUEST_DEPOSIT"}
				/>
				<Tab
					sx={{
						fontSize: "12px",
						minHeight: "20px",
						borderBottom: `2px solid ${COLOR.MAIN_COLOR}`,

						"&.Mui-selected": {
							border: `2px solid ${COLOR.MAIN_COLOR}`,
							borderTopLeftRadius: "5px",
							borderTopRightRadius: "5px",
							borderBottom: "0px",
						},
					}}
					label="Rút tiền"
					value={"REQUEST_WITHDRAW"}
				/>
			</Tabs> */}
			<HeaderActions
				title={state?.type !== "REQUEST_DEPOSIT" ? "Chi tiết rút tiền" : "Chi tiết nạp tiền"}
			/>
			<Box my={2}>
				<DetailInformation data={data} />
			</Box>
		</MainCard>
	);
}

export default RechargeWithDrawPage;
