import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate";

const fileType = "xlsx";

export const exportToExcel = (finalDataDetail) => {
	finalDataDetail.map((item, index) => {
		// console.log("item", item)
		item["json"] = XLSX.utils.json_to_sheet(item.data);
	});

	const obj = {
		Sheets: {},
		SheetNames: [],
	};
	finalDataDetail.map((item, key) => {
		return (obj.Sheets[item.category] = item.json), obj.SheetNames.push(item.category);
	});
	console.log("obj", obj);
	const test = { ...obj };
	const excelBuffer = XLSX.write(test, { bookType: "xlsx", type: "array" });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, "myfile" + ".xlsx");

	//    const product1 = XLSX.utils.json_to_sheet(productDetail);
	//    const user1 = XLSX.utils.json_to_sheet(user);
	//    const wb = {Sheets:{product:product1, user:user1}, SheetNames:["product", "user"]};
	//    const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"array"});
	//    const data = new Blob([excelBuffer], {type:fileType});
	//    FileSaver.saveAs(data, "myfile"+".xlsx")
};

export const excelExportHelper = (data, finalData) => {
	console.log(finalData);
	const createDownLoadData = () => {
		handleExport().then((url) => {
			const downloadAnchorNode = document.createElement("a");
			downloadAnchorNode.setAttribute("href", url);
			downloadAnchorNode.setAttribute("download", "bao_cao_hoa_don.xlsx");
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		});
	};

	const workbook2blob = (workbook) => {
		const wopts = {
			bookType: "xlsx",
			bookSST: false,
			type: "binary",
		};

		const wbout = XLSX.write(workbook, wopts);

		// The application/octet-stream MIME type is used for unknown binary files.
		// It preserves the file contents, but requires the receiver to determine file type,
		// for example, from the filename extension.
		const blob = new Blob([s2ab(wbout)], {
			type: "application/octet-stream",
		});

		return blob;
	};

	const s2ab = (s) => {
		// The ArrayBuffer() constructor is used to create ArrayBuffer objects.
		// create an ArrayBuffer with a size in bytes
		const buf = new ArrayBuffer(s.length);

		//create a 8 bit integer array
		const view = new Uint8Array(buf);

		//charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
		for (let i = 0; i !== s.length; ++i) {
			view[i] = s.charCodeAt(i);
		}

		return buf;
	};

	const handleExport = () => {
		//create a new workbook
		const wb = XLSX.utils.book_new();

		const sheet = XLSX.utils.json_to_sheet(finalData, {
			skipHeader: true,
		});

		XLSX.utils.book_append_sheet(wb, sheet, "student_report");

		// binary large object
		// Since blobs can store binary data, they can be used to store images or other multimedia files.

		const workbookBlob = workbook2blob(wb);

		var headerIndexes = [];
		finalData.forEach((data, index) =>
			data["A"] === finalData[3]["A"] ? headerIndexes.push(index) : null
		);

		const totalRecords = data.length;

		const dataInfo = {
			titleCell: "A2",
			titleRange: `A1:${Object.keys(finalData[3]).pop()}2`,
			tbodyRange: `A3:H${finalData.length}`,
			theadRange:
				headerIndexes?.length >= 1
					? `A${headerIndexes[0] + 1}:${Object.keys(finalData[3]).pop()}${headerIndexes[0] + 1}`
					: null,
			// theadRange1:
			// 	headerIndexes?.length >= 2 ? `A${headerIndexes[1] + 1}:H${headerIndexes[1] + 1}` : null,
			tFirstColumnRange:
				headerIndexes?.length >= 1
					? `A${headerIndexes[0] + 1}:A${totalRecords + headerIndexes[0] + 1}`
					: null,
			tLastColumnRange:
				headerIndexes?.length >= 1
					? `G${headerIndexes[0] + 1}:G${totalRecords + headerIndexes[0] + 1}`
					: null,

			// tFirstColumnRange1:
			// 	headerIndexes?.length >= 1
			// 		? `A${headerIndexes[1] + 1}:A${totalRecords + headerIndexes[1] + 1}`
			// 		: null,
			// tLastColumnRange1:
			// 	headerIndexes?.length >= 1
			// 		? `H${headerIndexes[0] + 1}:H${totalRecords + headerIndexes[1] + 1}`
			// 		: null,
		};

		return addStyle(workbookBlob, dataInfo);
	};

	const addStyle = (workbookBlob, dataInfo) => {
		return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
			workbook.sheets().forEach((sheet) => {
				sheet.usedRange().style({
					fontFamily: "Arial",
					verticalAlignment: "center",
					fontSize: 14,
				});

				sheet.column("A").width(50);
				sheet.row("1").height(70);
				sheet.row("3").height(40);
				sheet.column("B").width(30);
				sheet.column("C").width(30);
				sheet.column("D").width(30);
				sheet.column("E").width(50);
				sheet.column("F").width(40);
				sheet.column("G").width(40);
				sheet.column("H").width(40);
				sheet.column("I").width(40);
				sheet.column("J").width(40);
				sheet.column("K").width(50);
				sheet.column("L").width(30);
				sheet.column("M").width(30);
				sheet.column("N").width(30);
				sheet.column("O").width(30);
				sheet.column("P").width(30);
				console.log(dataInfo);
				sheet.range(dataInfo.titleRange).merged(true).style({
					bold: true,
					horizontalAlignment: "center",
					verticalAlignment: "center",
					wrapText: true,
				});
				if (dataInfo.tbodyRange) {
					sheet.range(dataInfo.tbodyRange).style({
						horizontalAlignment: "center",
					});
				}

				sheet.range(dataInfo.theadRange).style({
					fill: "FFFD04",
					bold: true,
					horizontalAlignment: "center",
				});

				if (dataInfo.tFirstColumnRange) {
					sheet.range(dataInfo.tFirstColumnRange).style({
						bold: true,
					});
				}

				// if (dataInfo.tLastColumnRange) {
				// 	sheet.range(dataInfo.tLastColumnRange).style({
				// 		bold: true,
				// 	});
				// }
			});

			return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
		});
	};

	createDownLoadData();

	// return { createDownLoadData };
};
