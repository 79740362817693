// const Level3Type = "Phường" | "Thị trấn" | "Xã";
// const Level2Type = "Huyện" | "Quận" | "Thị xã" | "Thành phố";

export const addressVN = [
	{
		level1_id: "79",
		name: "Thành phố Hồ Chí Minh",
		type: "Thành phố Trung ương",
		level2s: [
			{
				level2_id: "760",
				name: "Quận 1",
				type: "Quận",
				level3s: [
					{
						level3_id: "26734",
						name: "Phường Tân Định",
						type: "Phường",
					},
					{
						level3_id: "26737",
						name: "Phường Đa Kao",
						type: "Phường",
					},
					{
						level3_id: "26740",
						name: "Phường Bến Nghé",
						type: "Phường",
					},
					{
						level3_id: "26743",
						name: "Phường Bến Thành",
						type: "Phường",
					},
					{
						level3_id: "26746",
						name: "Phường Nguyễn Thái Bình",
						type: "Phường",
					},
					{
						level3_id: "26749",
						name: "Phường Phạm Ngũ Lão",
						type: "Phường",
					},
					{
						level3_id: "26752",
						name: "Phường Cầu Ông Lãnh",
						type: "Phường",
					},
					{
						level3_id: "26755",
						name: "Phường Cô Giang",
						type: "Phường",
					},
					{
						level3_id: "26758",
						name: "Phường Nguyễn Cư Trinh",
						type: "Phường",
					},
					{
						level3_id: "26761",
						name: "Phường Cầu Kho",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "770",
				name: "Quận 3",
				type: "Quận",
				level3s: [
					{
						level3_id: "27127",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "27130",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "27133",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "27136",
						name: "Phường 13",
						type: "Phường",
					},
					{
						level3_id: "27139",
						name: "Phường Võ Thị Sáu",
						type: "Phường",
					},
					{
						level3_id: "27142",
						name: "Phường 09",
						type: "Phường",
					},
					{
						level3_id: "27145",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "27148",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "27151",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "27154",
						name: "Phường 03",
						type: "Phường",
					},
					{
						level3_id: "27157",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "27160",
						name: "Phường 01",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "773",
				name: "Quận 4",
				type: "Quận",
				level3s: [
					{
						level3_id: "27259",
						name: "Phường 13",
						type: "Phường",
					},
					{
						level3_id: "27262",
						name: "Phường 09",
						type: "Phường",
					},
					{
						level3_id: "27265",
						name: "Phường 06",
						type: "Phường",
					},
					{
						level3_id: "27268",
						name: "Phường 08",
						type: "Phường",
					},
					{
						level3_id: "27271",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "27277",
						name: "Phường 18",
						type: "Phường",
					},
					{
						level3_id: "27280",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "27283",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "27286",
						name: "Phường 03",
						type: "Phường",
					},
					{
						level3_id: "27289",
						name: "Phường 16",
						type: "Phường",
					},
					{
						level3_id: "27292",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "27295",
						name: "Phường 15",
						type: "Phường",
					},
					{
						level3_id: "27298",
						name: "Phường 01",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "774",
				name: "Quận 5",
				type: "Quận",
				level3s: [
					{
						level3_id: "27301",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "27304",
						name: "Phường 09",
						type: "Phường",
					},
					{
						level3_id: "27307",
						name: "Phường 03",
						type: "Phường",
					},
					{
						level3_id: "27310",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "27313",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "27316",
						name: "Phường 08",
						type: "Phường",
					},
					{
						level3_id: "27322",
						name: "Phường 07",
						type: "Phường",
					},
					{
						level3_id: "27325",
						name: "Phường 01",
						type: "Phường",
					},
					{
						level3_id: "27328",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "27331",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "27334",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "27337",
						name: "Phường 06",
						type: "Phường",
					},
					{
						level3_id: "27340",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "27343",
						name: "Phường 13",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "775",
				name: "Quận 6",
				type: "Quận",
				level3s: [
					{
						level3_id: "27346",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "27349",
						name: "Phường 13",
						type: "Phường",
					},
					{
						level3_id: "27352",
						name: "Phường 09",
						type: "Phường",
					},
					{
						level3_id: "27355",
						name: "Phường 06",
						type: "Phường",
					},
					{
						level3_id: "27358",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "27361",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "27364",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "27367",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "27370",
						name: "Phường 01",
						type: "Phường",
					},
					{
						level3_id: "27373",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "27376",
						name: "Phường 08",
						type: "Phường",
					},
					{
						level3_id: "27379",
						name: "Phường 03",
						type: "Phường",
					},
					{
						level3_id: "27382",
						name: "Phường 07",
						type: "Phường",
					},
					{
						level3_id: "27385",
						name: "Phường 10",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "778",
				name: "Quận 7",
				type: "Quận",
				level3s: [
					{
						level3_id: "27466",
						name: "Phường Tân Thuận Đông",
						type: "Phường",
					},
					{
						level3_id: "27469",
						name: "Phường Tân Thuận Tây",
						type: "Phường",
					},
					{
						level3_id: "27472",
						name: "Phường Tân Kiểng",
						type: "Phường",
					},
					{
						level3_id: "27475",
						name: "Phường Tân Hưng",
						type: "Phường",
					},
					{
						level3_id: "27478",
						name: "Phường Bình Thuận",
						type: "Phường",
					},
					{
						level3_id: "27481",
						name: "Phường Tân Quy",
						type: "Phường",
					},
					{
						level3_id: "27484",
						name: "Phường Phú Thuận",
						type: "Phường",
					},
					{
						level3_id: "27487",
						name: "Phường Tân Phú",
						type: "Phường",
					},
					{
						level3_id: "27490",
						name: "Phường Tân Phong",
						type: "Phường",
					},
					{
						level3_id: "27493",
						name: "Phường Phú Mỹ",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "776",
				name: "Quận 8",
				type: "Quận",
				level3s: [
					{
						level3_id: "27388",
						name: "Phường 08",
						type: "Phường",
					},
					{
						level3_id: "27391",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "27394",
						name: "Phường 01",
						type: "Phường",
					},
					{
						level3_id: "27397",
						name: "Phường 03",
						type: "Phường",
					},
					{
						level3_id: "27400",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "27403",
						name: "Phường 09",
						type: "Phường",
					},
					{
						level3_id: "27406",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "27409",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "27412",
						name: "Phường 13",
						type: "Phường",
					},
					{
						level3_id: "27415",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "27418",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "27421",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "27424",
						name: "Phường 06",
						type: "Phường",
					},
					{
						level3_id: "27427",
						name: "Phường 15",
						type: "Phường",
					},
					{
						level3_id: "27430",
						name: "Phường 16",
						type: "Phường",
					},
					{
						level3_id: "27433",
						name: "Phường 07",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "771",
				name: "Quận 10",
				type: "Quận",
				level3s: [
					{
						level3_id: "27163",
						name: "Phường 15",
						type: "Phường",
					},
					{
						level3_id: "27166",
						name: "Phường 13",
						type: "Phường",
					},
					{
						level3_id: "27169",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "27172",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "27175",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "27178",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "27181",
						name: "Phường 09",
						type: "Phường",
					},
					{
						level3_id: "27184",
						name: "Phường 01",
						type: "Phường",
					},
					{
						level3_id: "27187",
						name: "Phường 08",
						type: "Phường",
					},
					{
						level3_id: "27190",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "27193",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "27196",
						name: "Phường 07",
						type: "Phường",
					},
					{
						level3_id: "27199",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "27202",
						name: "Phường 06",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "772",
				name: "Quận 11",
				type: "Quận",
				level3s: [
					{
						level3_id: "27208",
						name: "Phường 15",
						type: "Phường",
					},
					{
						level3_id: "27211",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "27214",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "27217",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "27220",
						name: "Phường 03",
						type: "Phường",
					},
					{
						level3_id: "27223",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "27226",
						name: "Phường 13",
						type: "Phường",
					},
					{
						level3_id: "27229",
						name: "Phường 08",
						type: "Phường",
					},
					{
						level3_id: "27232",
						name: "Phường 09",
						type: "Phường",
					},
					{
						level3_id: "27235",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "27238",
						name: "Phường 07",
						type: "Phường",
					},
					{
						level3_id: "27241",
						name: "Phường 06",
						type: "Phường",
					},
					{
						level3_id: "27244",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "27247",
						name: "Phường 01",
						type: "Phường",
					},
					{
						level3_id: "27250",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "27253",
						name: "Phường 16",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "761",
				name: "Quận 12",
				type: "Quận",
				level3s: [
					{
						level3_id: "26764",
						name: "Phường Thạnh Xuân",
						type: "Phường",
					},
					{
						level3_id: "26767",
						name: "Phường Thạnh Lộc",
						type: "Phường",
					},
					{
						level3_id: "26770",
						name: "Phường Hiệp Thành",
						type: "Phường",
					},
					{
						level3_id: "26773",
						name: "Phường Thới An",
						type: "Phường",
					},
					{
						level3_id: "26776",
						name: "Phường Tân Chánh Hiệp",
						type: "Phường",
					},
					{
						level3_id: "26779",
						name: "Phường An Phú Đông",
						type: "Phường",
					},
					{
						level3_id: "26782",
						name: "Phường Tân Thới Hiệp",
						type: "Phường",
					},
					{
						level3_id: "26785",
						name: "Phường Trung Mỹ Tây",
						type: "Phường",
					},
					{
						level3_id: "26787",
						name: "Phường Tân Hưng Thuận",
						type: "Phường",
					},
					{
						level3_id: "26788",
						name: "Phường Đông Hưng Thuận",
						type: "Phường",
					},
					{
						level3_id: "26791",
						name: "Phường Tân Thới Nhất",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "769",
				name: "Thành phố Thủ Đức",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "26794",
						name: "Phường Linh Xuân",
						type: "Phường",
					},
					{
						level3_id: "26797",
						name: "Phường Bình Chiểu",
						type: "Phường",
					},
					{
						level3_id: "26800",
						name: "Phường Linh Trung",
						type: "Phường",
					},
					{
						level3_id: "26803",
						name: "Phường Tam Bình",
						type: "Phường",
					},
					{
						level3_id: "26806",
						name: "Phường Tam Phú",
						type: "Phường",
					},
					{
						level3_id: "26809",
						name: "Phường Hiệp Bình Phước",
						type: "Phường",
					},
					{
						level3_id: "26812",
						name: "Phường Hiệp Bình Chánh",
						type: "Phường",
					},
					{
						level3_id: "26815",
						name: "Phường Linh Chiểu",
						type: "Phường",
					},
					{
						level3_id: "26818",
						name: "Phường Linh Tây",
						type: "Phường",
					},
					{
						level3_id: "26821",
						name: "Phường Linh Đông",
						type: "Phường",
					},
					{
						level3_id: "26824",
						name: "Phường Bình Thọ",
						type: "Phường",
					},
					{
						level3_id: "26827",
						name: "Phường Trường Thọ",
						type: "Phường",
					},
					{
						level3_id: "26830",
						name: "Phường Long Bình",
						type: "Phường",
					},
					{
						level3_id: "26833",
						name: "Phường Long Thạnh Mỹ",
						type: "Phường",
					},
					{
						level3_id: "26836",
						name: "Phường Tân Phú",
						type: "Phường",
					},
					{
						level3_id: "26839",
						name: "Phường Hiệp Phú",
						type: "Phường",
					},
					{
						level3_id: "26842",
						name: "Phường Tăng Nhơn Phú A",
						type: "Phường",
					},
					{
						level3_id: "26845",
						name: "Phường Tăng Nhơn Phú B",
						type: "Phường",
					},
					{
						level3_id: "26848",
						name: "Phường Phước Long B",
						type: "Phường",
					},
					{
						level3_id: "26851",
						name: "Phường Phước Long A",
						type: "Phường",
					},
					{
						level3_id: "26854",
						name: "Phường Trường Thạnh",
						type: "Phường",
					},
					{
						level3_id: "26857",
						name: "Phường Long Phước",
						type: "Phường",
					},
					{
						level3_id: "26860",
						name: "Phường Long Trường",
						type: "Phường",
					},
					{
						level3_id: "26863",
						name: "Phường Phước Bình",
						type: "Phường",
					},
					{
						level3_id: "26866",
						name: "Phường Phú Hữu",
						type: "Phường",
					},
					{
						level3_id: "27088",
						name: "Phường Thảo Điền",
						type: "Phường",
					},
					{
						level3_id: "27091",
						name: "Phường An Phú",
						type: "Phường",
					},
					{
						level3_id: "27094",
						name: "Phường An Khánh",
						type: "Phường",
					},
					{
						level3_id: "27097",
						name: "Phường Bình Trưng Đông",
						type: "Phường",
					},
					{
						level3_id: "27100",
						name: "Phường Bình Trưng Tây",
						type: "Phường",
					},
					{
						level3_id: "27109",
						name: "Phường Cát Lái",
						type: "Phường",
					},
					{
						level3_id: "27112",
						name: "Phường Thạnh Mỹ Lợi",
						type: "Phường",
					},
					{
						level3_id: "27115",
						name: "Phường An Lợi Đông",
						type: "Phường",
					},
					{
						level3_id: "27118",
						name: "Phường Thủ Thiêm",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "764",
				name: "Quận Gò Vấp",
				type: "Quận",
				level3s: [
					{
						level3_id: "26869",
						name: "Phường 15",
						type: "Phường",
					},
					{
						level3_id: "26872",
						name: "Phường 13",
						type: "Phường",
					},
					{
						level3_id: "26875",
						name: "Phường 17",
						type: "Phường",
					},
					{
						level3_id: "26876",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "26878",
						name: "Phường 16",
						type: "Phường",
					},
					{
						level3_id: "26881",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "26882",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "26884",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "26887",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "26890",
						name: "Phường 07",
						type: "Phường",
					},
					{
						level3_id: "26893",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "26896",
						name: "Phường 01",
						type: "Phường",
					},
					{
						level3_id: "26897",
						name: "Phường 9",
						type: "Phường",
					},
					{
						level3_id: "26898",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "26899",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "26902",
						name: "Phường 03",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "765",
				name: "Quận Bình Thạnh",
				type: "Quận",
				level3s: [
					{
						level3_id: "26905",
						name: "Phường 13",
						type: "Phường",
					},
					{
						level3_id: "26908",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "26911",
						name: "Phường 27",
						type: "Phường",
					},
					{
						level3_id: "26914",
						name: "Phường 26",
						type: "Phường",
					},
					{
						level3_id: "26917",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "26920",
						name: "Phường 25",
						type: "Phường",
					},
					{
						level3_id: "26923",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "26926",
						name: "Phường 07",
						type: "Phường",
					},
					{
						level3_id: "26929",
						name: "Phường 24",
						type: "Phường",
					},
					{
						level3_id: "26932",
						name: "Phường 06",
						type: "Phường",
					},
					{
						level3_id: "26935",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "26938",
						name: "Phường 15",
						type: "Phường",
					},
					{
						level3_id: "26941",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "26944",
						name: "Phường 01",
						type: "Phường",
					},
					{
						level3_id: "26947",
						name: "Phường 03",
						type: "Phường",
					},
					{
						level3_id: "26950",
						name: "Phường 17",
						type: "Phường",
					},
					{
						level3_id: "26953",
						name: "Phường 21",
						type: "Phường",
					},
					{
						level3_id: "26956",
						name: "Phường 22",
						type: "Phường",
					},
					{
						level3_id: "26959",
						name: "Phường 19",
						type: "Phường",
					},
					{
						level3_id: "26962",
						name: "Phường 28",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "766",
				name: "Quận Tân Bình",
				type: "Quận",
				level3s: [
					{
						level3_id: "26965",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "26968",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "26971",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "26974",
						name: "Phường 13",
						type: "Phường",
					},
					{
						level3_id: "26977",
						name: "Phường 01",
						type: "Phường",
					},
					{
						level3_id: "26980",
						name: "Phường 03",
						type: "Phường",
					},
					{
						level3_id: "26983",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "26986",
						name: "Phường 07",
						type: "Phường",
					},
					{
						level3_id: "26989",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "26992",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "26995",
						name: "Phường 06",
						type: "Phường",
					},
					{
						level3_id: "26998",
						name: "Phường 08",
						type: "Phường",
					},
					{
						level3_id: "27001",
						name: "Phường 09",
						type: "Phường",
					},
					{
						level3_id: "27004",
						name: "Phường 14",
						type: "Phường",
					},
					{
						level3_id: "27007",
						name: "Phường 15",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "767",
				name: "Quận Tân Phú",
				type: "Quận",
				level3s: [
					{
						level3_id: "27010",
						name: "Phường Tân Sơn Nhì",
						type: "Phường",
					},
					{
						level3_id: "27013",
						name: "Phường Tây Thạnh",
						type: "Phường",
					},
					{
						level3_id: "27016",
						name: "Phường Sơn Kỳ",
						type: "Phường",
					},
					{
						level3_id: "27019",
						name: "Phường Tân Quý",
						type: "Phường",
					},
					{
						level3_id: "27022",
						name: "Phường Tân Thành",
						type: "Phường",
					},
					{
						level3_id: "27025",
						name: "Phường Phú Thọ Hòa",
						type: "Phường",
					},
					{
						level3_id: "27028",
						name: "Phường Phú Thạnh",
						type: "Phường",
					},
					{
						level3_id: "27031",
						name: "Phường Phú Trung",
						type: "Phường",
					},
					{
						level3_id: "27034",
						name: "Phường Hòa Thạnh",
						type: "Phường",
					},
					{
						level3_id: "27037",
						name: "Phường Hiệp Tân",
						type: "Phường",
					},
					{
						level3_id: "27040",
						name: "Phường Tân Thới Hòa",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "768",
				name: "Quận Phú Nhuận",
				type: "Quận",
				level3s: [
					{
						level3_id: "27043",
						name: "Phường 04",
						type: "Phường",
					},
					{
						level3_id: "27046",
						name: "Phường 05",
						type: "Phường",
					},
					{
						level3_id: "27049",
						name: "Phường 09",
						type: "Phường",
					},
					{
						level3_id: "27052",
						name: "Phường 07",
						type: "Phường",
					},
					{
						level3_id: "27055",
						name: "Phường 03",
						type: "Phường",
					},
					{
						level3_id: "27058",
						name: "Phường 01",
						type: "Phường",
					},
					{
						level3_id: "27061",
						name: "Phường 02",
						type: "Phường",
					},
					{
						level3_id: "27064",
						name: "Phường 08",
						type: "Phường",
					},
					{
						level3_id: "27067",
						name: "Phường 15",
						type: "Phường",
					},
					{
						level3_id: "27070",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "27073",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "27076",
						name: "Phường 17",
						type: "Phường",
					},
					{
						level3_id: "27085",
						name: "Phường 13",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "777",
				name: "Quận Bình Tân",
				type: "Quận",
				level3s: [
					{
						level3_id: "27436",
						name: "Phường Bình Hưng Hòa",
						type: "Phường",
					},
					{
						level3_id: "27439",
						name: "Phường Bình Hưng Hoà A",
						type: "Phường",
					},
					{
						level3_id: "27442",
						name: "Phường Bình Hưng Hoà B",
						type: "Phường",
					},
					{
						level3_id: "27445",
						name: "Phường Bình Trị Đông",
						type: "Phường",
					},
					{
						level3_id: "27448",
						name: "Phường Bình Trị Đông A",
						type: "Phường",
					},
					{
						level3_id: "27451",
						name: "Phường Bình Trị Đông B",
						type: "Phường",
					},
					{
						level3_id: "27454",
						name: "Phường Tân Tạo",
						type: "Phường",
					},
					{
						level3_id: "27457",
						name: "Phường Tân Tạo A",
						type: "Phường",
					},
					{
						level3_id: "27460",
						name: "Phường  An Lạc",
						type: "Phường",
					},
					{
						level3_id: "27463",
						name: "Phường An Lạc A",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "783",
				name: "Huyện Củ Chi",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27496",
						name: "Thị trấn Củ Chi",
						type: "Thị trấn",
					},
					{
						level3_id: "27499",
						name: "Xã Phú Mỹ Hưng",
						type: "Xã",
					},
					{
						level3_id: "27502",
						name: "Xã An Phú",
						type: "Xã",
					},
					{
						level3_id: "27505",
						name: "Xã Trung Lập Thượng",
						type: "Xã",
					},
					{
						level3_id: "27508",
						name: "Xã An Nhơn Tây",
						type: "Xã",
					},
					{
						level3_id: "27511",
						name: "Xã Nhuận Đức",
						type: "Xã",
					},
					{
						level3_id: "27514",
						name: "Xã Phạm Văn Cội",
						type: "Xã",
					},
					{
						level3_id: "27517",
						name: "Xã Phú Hòa Đông",
						type: "Xã",
					},
					{
						level3_id: "27520",
						name: "Xã Trung Lập Hạ",
						type: "Xã",
					},
					{
						level3_id: "27523",
						name: "Xã Trung An",
						type: "Xã",
					},
					{
						level3_id: "27526",
						name: "Xã Phước Thạnh",
						type: "Xã",
					},
					{
						level3_id: "27529",
						name: "Xã Phước Hiệp",
						type: "Xã",
					},
					{
						level3_id: "27532",
						name: "Xã Tân An Hội",
						type: "Xã",
					},
					{
						level3_id: "27535",
						name: "Xã Phước Vĩnh An",
						type: "Xã",
					},
					{
						level3_id: "27538",
						name: "Xã Thái Mỹ",
						type: "Xã",
					},
					{
						level3_id: "27541",
						name: "Xã Tân Thạnh Tây",
						type: "Xã",
					},
					{
						level3_id: "27544",
						name: "Xã Hòa Phú",
						type: "Xã",
					},
					{
						level3_id: "27547",
						name: "Xã Tân Thạnh Đông",
						type: "Xã",
					},
					{
						level3_id: "27550",
						name: "Xã Bình Mỹ",
						type: "Xã",
					},
					{
						level3_id: "27553",
						name: "Xã Tân Phú Trung",
						type: "Xã",
					},
					{
						level3_id: "27556",
						name: "Xã Tân Thông Hội",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "784",
				name: "Huyện Hóc Môn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27559",
						name: "Thị trấn Hóc Môn",
						type: "Thị trấn",
					},
					{
						level3_id: "27562",
						name: "Xã Tân Hiệp",
						type: "Xã",
					},
					{
						level3_id: "27565",
						name: "Xã Nhị Bình",
						type: "Xã",
					},
					{
						level3_id: "27568",
						name: "Xã Đông Thạnh",
						type: "Xã",
					},
					{
						level3_id: "27571",
						name: "Xã Tân Thới Nhì",
						type: "Xã",
					},
					{
						level3_id: "27574",
						name: "Xã Thới Tam Thôn",
						type: "Xã",
					},
					{
						level3_id: "27577",
						name: "Xã Xuân Thới Sơn",
						type: "Xã",
					},
					{
						level3_id: "27580",
						name: "Xã Tân Xuân",
						type: "Xã",
					},
					{
						level3_id: "27583",
						name: "Xã Xuân Thới Đông",
						type: "Xã",
					},
					{
						level3_id: "27586",
						name: "Xã Trung Chánh",
						type: "Xã",
					},
					{
						level3_id: "27589",
						name: "Xã Xuân Thới Thượng",
						type: "Xã",
					},
					{
						level3_id: "27592",
						name: "Xã Bà Điểm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "785",
				name: "Huyện Bình Chánh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27595",
						name: "Thị trấn Tân Túc",
						type: "Thị trấn",
					},
					{
						level3_id: "27598",
						name: "Xã Phạm Văn Hai",
						type: "Xã",
					},
					{
						level3_id: "27601",
						name: "Xã Vĩnh Lộc A",
						type: "Xã",
					},
					{
						level3_id: "27604",
						name: "Xã Vĩnh Lộc B",
						type: "Xã",
					},
					{
						level3_id: "27607",
						name: "Xã Bình Lợi",
						type: "Xã",
					},
					{
						level3_id: "27610",
						name: "Xã Lê Minh Xuân",
						type: "Xã",
					},
					{
						level3_id: "27613",
						name: "Xã Tân Nhựt",
						type: "Xã",
					},
					{
						level3_id: "27616",
						name: "Xã Tân Kiên",
						type: "Xã",
					},
					{
						level3_id: "27619",
						name: "Xã Bình Hưng",
						type: "Xã",
					},
					{
						level3_id: "27622",
						name: "Xã Phong Phú",
						type: "Xã",
					},
					{
						level3_id: "27625",
						name: "Xã An Phú Tây",
						type: "Xã",
					},
					{
						level3_id: "27628",
						name: "Xã Hưng Long",
						type: "Xã",
					},
					{
						level3_id: "27631",
						name: "Xã Đa Phước",
						type: "Xã",
					},
					{
						level3_id: "27634",
						name: "Xã Tân Quý Tây",
						type: "Xã",
					},
					{
						level3_id: "27637",
						name: "Xã Bình Chánh",
						type: "Xã",
					},
					{
						level3_id: "27640",
						name: "Xã Quy Đức",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "786",
				name: "Huyện Nhà Bè",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27643",
						name: "Thị trấn Nhà Bè",
						type: "Thị trấn",
					},
					{
						level3_id: "27646",
						name: "Xã Phước Kiển",
						type: "Xã",
					},
					{
						level3_id: "27649",
						name: "Xã Phước Lộc",
						type: "Xã",
					},
					{
						level3_id: "27652",
						name: "Xã Nhơn Đức",
						type: "Xã",
					},
					{
						level3_id: "27655",
						name: "Xã Phú Xuân",
						type: "Xã",
					},
					{
						level3_id: "27658",
						name: "Xã Long Thới",
						type: "Xã",
					},
					{
						level3_id: "27661",
						name: "Xã Hiệp Phước",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "787",
				name: "Huyện Cần Giờ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27664",
						name: "Thị trấn Cần Thạnh",
						type: "Thị trấn",
					},
					{
						level3_id: "27667",
						name: "Xã Bình Khánh",
						type: "Xã",
					},
					{
						level3_id: "27670",
						name: "Xã Tam Thôn Hiệp",
						type: "Xã",
					},
					{
						level3_id: "27673",
						name: "Xã An Thới Đông",
						type: "Xã",
					},
					{
						level3_id: "27676",
						name: "Xã Thạnh An",
						type: "Xã",
					},
					{
						level3_id: "27679",
						name: "Xã Long Hòa",
						type: "Xã",
					},
					{
						level3_id: "27682",
						name: "Xã Lý Nhơn",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "01",
		name: "Thành phố Hà Nội",
		type: "Thành phố Trung ương",
		level2s: [
			{
				level2_id: "001",
				name: "Quận Ba Đình",
				type: "Quận",
				level3s: [
					{
						level3_id: "00001",
						name: "Phường Phúc Xá",
						type: "Phường",
					},
					{
						level3_id: "00004",
						name: "Phường Trúc Bạch",
						type: "Phường",
					},
					{
						level3_id: "00006",
						name: "Phường Vĩnh Phúc",
						type: "Phường",
					},
					{
						level3_id: "00007",
						name: "Phường Cống Vị",
						type: "Phường",
					},
					{
						level3_id: "00008",
						name: "Phường Liễu Giai",
						type: "Phường",
					},
					{
						level3_id: "00010",
						name: "Phường Nguyễn Trung Trực",
						type: "Phường",
					},
					{
						level3_id: "00013",
						name: "Phường Quán Thánh",
						type: "Phường",
					},
					{
						level3_id: "00016",
						name: "Phường Ngọc Hà",
						type: "Phường",
					},
					{
						level3_id: "00019",
						name: "Phường Điện Biên",
						type: "Phường",
					},
					{
						level3_id: "00022",
						name: "Phường Đội Cấn",
						type: "Phường",
					},
					{
						level3_id: "00025",
						name: "Phường Ngọc Khánh",
						type: "Phường",
					},
					{
						level3_id: "00028",
						name: "Phường Kim Mã",
						type: "Phường",
					},
					{
						level3_id: "00031",
						name: "Phường Giảng Võ",
						type: "Phường",
					},
					{
						level3_id: "00034",
						name: "Phường Thành Công",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "002",
				name: "Quận Hoàn Kiếm",
				type: "Quận",
				level3s: [
					{
						level3_id: "00037",
						name: "Phường Phúc Tân",
						type: "Phường",
					},
					{
						level3_id: "00040",
						name: "Phường Đồng Xuân",
						type: "Phường",
					},
					{
						level3_id: "00043",
						name: "Phường Hàng Mã",
						type: "Phường",
					},
					{
						level3_id: "00046",
						name: "Phường Hàng Buồm",
						type: "Phường",
					},
					{
						level3_id: "00049",
						name: "Phường Hàng Đào",
						type: "Phường",
					},
					{
						level3_id: "00052",
						name: "Phường Hàng Bồ",
						type: "Phường",
					},
					{
						level3_id: "00055",
						name: "Phường Cửa Đông",
						type: "Phường",
					},
					{
						level3_id: "00058",
						name: "Phường Lý Thái Tổ",
						type: "Phường",
					},
					{
						level3_id: "00061",
						name: "Phường Hàng Bạc",
						type: "Phường",
					},
					{
						level3_id: "00064",
						name: "Phường Hàng Gai",
						type: "Phường",
					},
					{
						level3_id: "00067",
						name: "Phường Chương Dương",
						type: "Phường",
					},
					{
						level3_id: "00070",
						name: "Phường Hàng Trống",
						type: "Phường",
					},
					{
						level3_id: "00073",
						name: "Phường Cửa Nam",
						type: "Phường",
					},
					{
						level3_id: "00076",
						name: "Phường Hàng Bông",
						type: "Phường",
					},
					{
						level3_id: "00079",
						name: "Phường Tràng Tiền",
						type: "Phường",
					},
					{
						level3_id: "00082",
						name: "Phường Trần Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "00085",
						name: "Phường Phan Chu Trinh",
						type: "Phường",
					},
					{
						level3_id: "00088",
						name: "Phường Hàng Bài",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "003",
				name: "Quận Tây Hồ",
				type: "Quận",
				level3s: [
					{
						level3_id: "00091",
						name: "Phường Phú Thượng",
						type: "Phường",
					},
					{
						level3_id: "00094",
						name: "Phường Nhật Tân",
						type: "Phường",
					},
					{
						level3_id: "00097",
						name: "Phường Tứ Liên",
						type: "Phường",
					},
					{
						level3_id: "00100",
						name: "Phường Quảng An",
						type: "Phường",
					},
					{
						level3_id: "00103",
						name: "Phường Xuân La",
						type: "Phường",
					},
					{
						level3_id: "00106",
						name: "Phường Yên Phụ",
						type: "Phường",
					},
					{
						level3_id: "00109",
						name: "Phường Bưởi",
						type: "Phường",
					},
					{
						level3_id: "00112",
						name: "Phường Thụy Khuê",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "004",
				name: "Quận Long Biên",
				type: "Quận",
				level3s: [
					{
						level3_id: "00115",
						name: "Phường Thượng Thanh",
						type: "Phường",
					},
					{
						level3_id: "00118",
						name: "Phường Ngọc Thụy",
						type: "Phường",
					},
					{
						level3_id: "00121",
						name: "Phường Giang Biên",
						type: "Phường",
					},
					{
						level3_id: "00124",
						name: "Phường Đức Giang",
						type: "Phường",
					},
					{
						level3_id: "00127",
						name: "Phường Việt Hưng",
						type: "Phường",
					},
					{
						level3_id: "00130",
						name: "Phường Gia Thụy",
						type: "Phường",
					},
					{
						level3_id: "00133",
						name: "Phường Ngọc Lâm",
						type: "Phường",
					},
					{
						level3_id: "00136",
						name: "Phường Phúc Lợi",
						type: "Phường",
					},
					{
						level3_id: "00139",
						name: "Phường Bồ Đề",
						type: "Phường",
					},
					{
						level3_id: "00142",
						name: "Phường Sài Đồng",
						type: "Phường",
					},
					{
						level3_id: "00145",
						name: "Phường Long Biên",
						type: "Phường",
					},
					{
						level3_id: "00148",
						name: "Phường Thạch Bàn",
						type: "Phường",
					},
					{
						level3_id: "00151",
						name: "Phường Phúc Đồng",
						type: "Phường",
					},
					{
						level3_id: "00154",
						name: "Phường Cự Khối",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "005",
				name: "Quận Cầu Giấy",
				type: "Quận",
				level3s: [
					{
						level3_id: "00157",
						name: "Phường Nghĩa Đô",
						type: "Phường",
					},
					{
						level3_id: "00160",
						name: "Phường Nghĩa Tân",
						type: "Phường",
					},
					{
						level3_id: "00163",
						name: "Phường Mai Dịch",
						type: "Phường",
					},
					{
						level3_id: "00166",
						name: "Phường Dịch Vọng",
						type: "Phường",
					},
					{
						level3_id: "00167",
						name: "Phường Dịch Vọng Hậu",
						type: "Phường",
					},
					{
						level3_id: "00169",
						name: "Phường Quan Hoa",
						type: "Phường",
					},
					{
						level3_id: "00172",
						name: "Phường Yên Hoà",
						type: "Phường",
					},
					{
						level3_id: "00175",
						name: "Phường Trung Hoà",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "006",
				name: "Quận Đống Đa",
				type: "Quận",
				level3s: [
					{
						level3_id: "00178",
						name: "Phường Cát Linh",
						type: "Phường",
					},
					{
						level3_id: "00181",
						name: "Phường Văn Miếu",
						type: "Phường",
					},
					{
						level3_id: "00184",
						name: "Phường Quốc Tử Giám",
						type: "Phường",
					},
					{
						level3_id: "00187",
						name: "Phường Láng Thượng",
						type: "Phường",
					},
					{
						level3_id: "00190",
						name: "Phường Ô Chợ Dừa",
						type: "Phường",
					},
					{
						level3_id: "00193",
						name: "Phường Văn Chương",
						type: "Phường",
					},
					{
						level3_id: "00196",
						name: "Phường Hàng Bột",
						type: "Phường",
					},
					{
						level3_id: "00199",
						name: "Phường Láng Hạ",
						type: "Phường",
					},
					{
						level3_id: "00202",
						name: "Phường Khâm Thiên",
						type: "Phường",
					},
					{
						level3_id: "00205",
						name: "Phường Thổ Quan",
						type: "Phường",
					},
					{
						level3_id: "00208",
						name: "Phường Nam Đồng",
						type: "Phường",
					},
					{
						level3_id: "00211",
						name: "Phường Trung Phụng",
						type: "Phường",
					},
					{
						level3_id: "00214",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "00217",
						name: "Phường Trung Liệt",
						type: "Phường",
					},
					{
						level3_id: "00220",
						name: "Phường Phương Liên",
						type: "Phường",
					},
					{
						level3_id: "00223",
						name: "Phường Thịnh Quang",
						type: "Phường",
					},
					{
						level3_id: "00226",
						name: "Phường Trung Tự",
						type: "Phường",
					},
					{
						level3_id: "00229",
						name: "Phường Kim Liên",
						type: "Phường",
					},
					{
						level3_id: "00232",
						name: "Phường Phương Mai",
						type: "Phường",
					},
					{
						level3_id: "00235",
						name: "Phường Ngã Tư Sở",
						type: "Phường",
					},
					{
						level3_id: "00238",
						name: "Phường Khương Thượng",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "007",
				name: "Quận Hai Bà Trưng",
				type: "Quận",
				level3s: [
					{
						level3_id: "00241",
						name: "Phường Nguyễn Du",
						type: "Phường",
					},
					{
						level3_id: "00244",
						name: "Phường Bạch Đằng",
						type: "Phường",
					},
					{
						level3_id: "00247",
						name: "Phường Phạm Đình Hổ",
						type: "Phường",
					},
					{
						level3_id: "00256",
						name: "Phường Lê Đại Hành",
						type: "Phường",
					},
					{
						level3_id: "00259",
						name: "Phường Đồng Nhân",
						type: "Phường",
					},
					{
						level3_id: "00262",
						name: "Phường Phố Huế",
						type: "Phường",
					},
					{
						level3_id: "00265",
						name: "Phường Đống Mác",
						type: "Phường",
					},
					{
						level3_id: "00268",
						name: "Phường Thanh Lương",
						type: "Phường",
					},
					{
						level3_id: "00271",
						name: "Phường Thanh Nhàn",
						type: "Phường",
					},
					{
						level3_id: "00274",
						name: "Phường Cầu Dền",
						type: "Phường",
					},
					{
						level3_id: "00277",
						name: "Phường Bách Khoa",
						type: "Phường",
					},
					{
						level3_id: "00280",
						name: "Phường Đồng Tâm",
						type: "Phường",
					},
					{
						level3_id: "00283",
						name: "Phường Vĩnh Tuy",
						type: "Phường",
					},
					{
						level3_id: "00286",
						name: "Phường Bạch Mai",
						type: "Phường",
					},
					{
						level3_id: "00289",
						name: "Phường Quỳnh Mai",
						type: "Phường",
					},
					{
						level3_id: "00292",
						name: "Phường Quỳnh Lôi",
						type: "Phường",
					},
					{
						level3_id: "00295",
						name: "Phường Minh Khai",
						type: "Phường",
					},
					{
						level3_id: "00298",
						name: "Phường Trương Định",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "008",
				name: "Quận Hoàng Mai",
				type: "Quận",
				level3s: [
					{
						level3_id: "00301",
						name: "Phường Thanh Trì",
						type: "Phường",
					},
					{
						level3_id: "00304",
						name: "Phường Vĩnh Hưng",
						type: "Phường",
					},
					{
						level3_id: "00307",
						name: "Phường Định Công",
						type: "Phường",
					},
					{
						level3_id: "00310",
						name: "Phường Mai Động",
						type: "Phường",
					},
					{
						level3_id: "00313",
						name: "Phường Tương Mai",
						type: "Phường",
					},
					{
						level3_id: "00316",
						name: "Phường Đại Kim",
						type: "Phường",
					},
					{
						level3_id: "00319",
						name: "Phường Tân Mai",
						type: "Phường",
					},
					{
						level3_id: "00322",
						name: "Phường Hoàng Văn Thụ",
						type: "Phường",
					},
					{
						level3_id: "00325",
						name: "Phường Giáp Bát",
						type: "Phường",
					},
					{
						level3_id: "00328",
						name: "Phường Lĩnh Nam",
						type: "Phường",
					},
					{
						level3_id: "00331",
						name: "Phường Thịnh Liệt",
						type: "Phường",
					},
					{
						level3_id: "00334",
						name: "Phường Trần Phú",
						type: "Phường",
					},
					{
						level3_id: "00337",
						name: "Phường Hoàng Liệt",
						type: "Phường",
					},
					{
						level3_id: "00340",
						name: "Phường Yên Sở",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "009",
				name: "Quận Thanh Xuân",
				type: "Quận",
				level3s: [
					{
						level3_id: "00343",
						name: "Phường Nhân Chính",
						type: "Phường",
					},
					{
						level3_id: "00346",
						name: "Phường Thượng Đình",
						type: "Phường",
					},
					{
						level3_id: "00349",
						name: "Phường Khương Trung",
						type: "Phường",
					},
					{
						level3_id: "00352",
						name: "Phường Khương Mai",
						type: "Phường",
					},
					{
						level3_id: "00355",
						name: "Phường Thanh Xuân Trung",
						type: "Phường",
					},
					{
						level3_id: "00358",
						name: "Phường Phương Liệt",
						type: "Phường",
					},
					{
						level3_id: "00361",
						name: "Phường Hạ Đình",
						type: "Phường",
					},
					{
						level3_id: "00364",
						name: "Phường Khương Đình",
						type: "Phường",
					},
					{
						level3_id: "00367",
						name: "Phường Thanh Xuân Bắc",
						type: "Phường",
					},
					{
						level3_id: "00370",
						name: "Phường Thanh Xuân Nam",
						type: "Phường",
					},
					{
						level3_id: "00373",
						name: "Phường Kim Giang",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "016",
				name: "Huyện Sóc Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00376",
						name: "Thị trấn Sóc Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "00379",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "00382",
						name: "Xã Minh Trí",
						type: "Xã",
					},
					{
						level3_id: "00385",
						name: "Xã Hồng Kỳ",
						type: "Xã",
					},
					{
						level3_id: "00388",
						name: "Xã Nam Sơn",
						type: "Xã",
					},
					{
						level3_id: "00391",
						name: "Xã Trung Giã",
						type: "Xã",
					},
					{
						level3_id: "00394",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "00397",
						name: "Xã Minh Phú",
						type: "Xã",
					},
					{
						level3_id: "00400",
						name: "Xã Phù Linh",
						type: "Xã",
					},
					{
						level3_id: "00403",
						name: "Xã Bắc Phú",
						type: "Xã",
					},
					{
						level3_id: "00406",
						name: "Xã Tân Minh",
						type: "Xã",
					},
					{
						level3_id: "00409",
						name: "Xã Quang Tiến",
						type: "Xã",
					},
					{
						level3_id: "00412",
						name: "Xã Hiền Ninh",
						type: "Xã",
					},
					{
						level3_id: "00415",
						name: "Xã Tân Dân",
						type: "Xã",
					},
					{
						level3_id: "00418",
						name: "Xã Tiên Dược",
						type: "Xã",
					},
					{
						level3_id: "00421",
						name: "Xã Việt Long",
						type: "Xã",
					},
					{
						level3_id: "00424",
						name: "Xã Xuân Giang",
						type: "Xã",
					},
					{
						level3_id: "00427",
						name: "Xã Mai Đình",
						type: "Xã",
					},
					{
						level3_id: "00430",
						name: "Xã Đức Hoà",
						type: "Xã",
					},
					{
						level3_id: "00433",
						name: "Xã Thanh Xuân",
						type: "Xã",
					},
					{
						level3_id: "00436",
						name: "Xã Đông Xuân",
						type: "Xã",
					},
					{
						level3_id: "00439",
						name: "Xã Kim Lũ",
						type: "Xã",
					},
					{
						level3_id: "00442",
						name: "Xã Phú Cường",
						type: "Xã",
					},
					{
						level3_id: "00445",
						name: "Xã Phú Minh",
						type: "Xã",
					},
					{
						level3_id: "00448",
						name: "Xã Phù Lỗ",
						type: "Xã",
					},
					{
						level3_id: "00451",
						name: "Xã Xuân Thu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "017",
				name: "Huyện Đông Anh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00454",
						name: "Thị trấn Đông Anh",
						type: "Thị trấn",
					},
					{
						level3_id: "00457",
						name: "Xã Xuân Nộn",
						type: "Xã",
					},
					{
						level3_id: "00460",
						name: "Xã Thuỵ Lâm",
						type: "Xã",
					},
					{
						level3_id: "00463",
						name: "Xã Bắc Hồng",
						type: "Xã",
					},
					{
						level3_id: "00466",
						name: "Xã Nguyên Khê",
						type: "Xã",
					},
					{
						level3_id: "00469",
						name: "Xã Nam Hồng",
						type: "Xã",
					},
					{
						level3_id: "00472",
						name: "Xã Tiên Dương",
						type: "Xã",
					},
					{
						level3_id: "00475",
						name: "Xã Vân Hà",
						type: "Xã",
					},
					{
						level3_id: "00478",
						name: "Xã Uy Nỗ",
						type: "Xã",
					},
					{
						level3_id: "00481",
						name: "Xã Vân Nội",
						type: "Xã",
					},
					{
						level3_id: "00484",
						name: "Xã Liên Hà",
						type: "Xã",
					},
					{
						level3_id: "00487",
						name: "Xã Việt Hùng",
						type: "Xã",
					},
					{
						level3_id: "00490",
						name: "Xã Kim Nỗ",
						type: "Xã",
					},
					{
						level3_id: "00493",
						name: "Xã Kim Chung",
						type: "Xã",
					},
					{
						level3_id: "00496",
						name: "Xã Dục Tú",
						type: "Xã",
					},
					{
						level3_id: "00499",
						name: "Xã Đại Mạch",
						type: "Xã",
					},
					{
						level3_id: "00502",
						name: "Xã Vĩnh Ngọc",
						type: "Xã",
					},
					{
						level3_id: "00505",
						name: "Xã Cổ Loa",
						type: "Xã",
					},
					{
						level3_id: "00508",
						name: "Xã Hải Bối",
						type: "Xã",
					},
					{
						level3_id: "00511",
						name: "Xã Xuân Canh",
						type: "Xã",
					},
					{
						level3_id: "00514",
						name: "Xã Võng La",
						type: "Xã",
					},
					{
						level3_id: "00517",
						name: "Xã Tàm Xá",
						type: "Xã",
					},
					{
						level3_id: "00520",
						name: "Xã Mai Lâm",
						type: "Xã",
					},
					{
						level3_id: "00523",
						name: "Xã Đông Hội",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "018",
				name: "Huyện Gia Lâm",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00526",
						name: "Thị trấn Yên Viên",
						type: "Thị trấn",
					},
					{
						level3_id: "00529",
						name: "Xã Yên Thường",
						type: "Xã",
					},
					{
						level3_id: "00532",
						name: "Xã Yên Viên",
						type: "Xã",
					},
					{
						level3_id: "00535",
						name: "Xã Ninh Hiệp",
						type: "Xã",
					},
					{
						level3_id: "00538",
						name: "Xã Đình Xuyên",
						type: "Xã",
					},
					{
						level3_id: "00541",
						name: "Xã Dương Hà",
						type: "Xã",
					},
					{
						level3_id: "00544",
						name: "Xã Phù Đổng",
						type: "Xã",
					},
					{
						level3_id: "00547",
						name: "Xã Trung Mầu",
						type: "Xã",
					},
					{
						level3_id: "00550",
						name: "Xã Lệ Chi",
						type: "Xã",
					},
					{
						level3_id: "00553",
						name: "Xã Cổ Bi",
						type: "Xã",
					},
					{
						level3_id: "00556",
						name: "Xã Đặng Xá",
						type: "Xã",
					},
					{
						level3_id: "00559",
						name: "Xã Phú Thị",
						type: "Xã",
					},
					{
						level3_id: "00562",
						name: "Xã Kim Sơn",
						type: "Xã",
					},
					{
						level3_id: "00565",
						name: "Thị trấn Trâu Quỳ",
						type: "Thị trấn",
					},
					{
						level3_id: "00568",
						name: "Xã Dương Quang",
						type: "Xã",
					},
					{
						level3_id: "00571",
						name: "Xã Dương Xá",
						type: "Xã",
					},
					{
						level3_id: "00574",
						name: "Xã Đông Dư",
						type: "Xã",
					},
					{
						level3_id: "00577",
						name: "Xã Đa Tốn",
						type: "Xã",
					},
					{
						level3_id: "00580",
						name: "Xã Kiêu Kỵ",
						type: "Xã",
					},
					{
						level3_id: "00583",
						name: "Xã Bát Tràng",
						type: "Xã",
					},
					{
						level3_id: "00586",
						name: "Xã Kim Lan",
						type: "Xã",
					},
					{
						level3_id: "00589",
						name: "Xã Văn Đức",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "019",
				name: "Quận Nam Từ Liêm",
				type: "Quận",
				level3s: [
					{
						level3_id: "00592",
						name: "Phường Cầu Diễn",
						type: "Phường",
					},
					{
						level3_id: "00622",
						name: "Phường Xuân Phương",
						type: "Phường",
					},
					{
						level3_id: "00623",
						name: "Phường Phương Canh",
						type: "Phường",
					},
					{
						level3_id: "00625",
						name: "Phường Mỹ Đình 1",
						type: "Phường",
					},
					{
						level3_id: "00626",
						name: "Phường Mỹ Đình 2",
						type: "Phường",
					},
					{
						level3_id: "00628",
						name: "Phường Tây Mỗ",
						type: "Phường",
					},
					{
						level3_id: "00631",
						name: "Phường Mễ Trì",
						type: "Phường",
					},
					{
						level3_id: "00632",
						name: "Phường Phú Đô",
						type: "Phường",
					},
					{
						level3_id: "00634",
						name: "Phường Đại Mỗ",
						type: "Phường",
					},
					{
						level3_id: "00637",
						name: "Phường Trung Văn",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "020",
				name: "Huyện Thanh Trì",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00640",
						name: "Thị trấn Văn Điển",
						type: "Thị trấn",
					},
					{
						level3_id: "00643",
						name: "Xã Tân Triều",
						type: "Xã",
					},
					{
						level3_id: "00646",
						name: "Xã Thanh Liệt",
						type: "Xã",
					},
					{
						level3_id: "00649",
						name: "Xã Tả Thanh Oai",
						type: "Xã",
					},
					{
						level3_id: "00652",
						name: "Xã Hữu Hoà",
						type: "Xã",
					},
					{
						level3_id: "00655",
						name: "Xã Tam Hiệp",
						type: "Xã",
					},
					{
						level3_id: "00658",
						name: "Xã Tứ Hiệp",
						type: "Xã",
					},
					{
						level3_id: "00661",
						name: "Xã Yên Mỹ",
						type: "Xã",
					},
					{
						level3_id: "00664",
						name: "Xã Vĩnh Quỳnh",
						type: "Xã",
					},
					{
						level3_id: "00667",
						name: "Xã Ngũ Hiệp",
						type: "Xã",
					},
					{
						level3_id: "00670",
						name: "Xã Duyên Hà",
						type: "Xã",
					},
					{
						level3_id: "00673",
						name: "Xã Ngọc Hồi",
						type: "Xã",
					},
					{
						level3_id: "00676",
						name: "Xã Vạn Phúc",
						type: "Xã",
					},
					{
						level3_id: "00679",
						name: "Xã Đại áng",
						type: "Xã",
					},
					{
						level3_id: "00682",
						name: "Xã Liên Ninh",
						type: "Xã",
					},
					{
						level3_id: "00685",
						name: "Xã Đông Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "021",
				name: "Quận Bắc Từ Liêm",
				type: "Quận",
				level3s: [
					{
						level3_id: "00595",
						name: "Phường Thượng Cát",
						type: "Phường",
					},
					{
						level3_id: "00598",
						name: "Phường Liên Mạc",
						type: "Phường",
					},
					{
						level3_id: "00601",
						name: "Phường Đông Ngạc",
						type: "Phường",
					},
					{
						level3_id: "00602",
						name: "Phường Đức Thắng",
						type: "Phường",
					},
					{
						level3_id: "00604",
						name: "Phường Thụy Phương",
						type: "Phường",
					},
					{
						level3_id: "00607",
						name: "Phường Tây Tựu",
						type: "Phường",
					},
					{
						level3_id: "00610",
						name: "Phường Xuân Đỉnh",
						type: "Phường",
					},
					{
						level3_id: "00611",
						name: "Phường Xuân Tảo",
						type: "Phường",
					},
					{
						level3_id: "00613",
						name: "Phường Minh Khai",
						type: "Phường",
					},
					{
						level3_id: "00616",
						name: "Phường Cổ Nhuế 1",
						type: "Phường",
					},
					{
						level3_id: "00617",
						name: "Phường Cổ Nhuế 2",
						type: "Phường",
					},
					{
						level3_id: "00619",
						name: "Phường Phú Diễn",
						type: "Phường",
					},
					{
						level3_id: "00620",
						name: "Phường Phúc Diễn",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "250",
				name: "Huyện Mê Linh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08973",
						name: "Thị trấn Chi Đông",
						type: "Thị trấn",
					},
					{
						level3_id: "08974",
						name: "Xã Đại Thịnh",
						type: "Xã",
					},
					{
						level3_id: "08977",
						name: "Xã Kim Hoa",
						type: "Xã",
					},
					{
						level3_id: "08980",
						name: "Xã Thạch Đà",
						type: "Xã",
					},
					{
						level3_id: "08983",
						name: "Xã Tiến Thắng",
						type: "Xã",
					},
					{
						level3_id: "08986",
						name: "Xã Tự Lập",
						type: "Xã",
					},
					{
						level3_id: "08989",
						name: "Thị trấn Quang Minh",
						type: "Thị trấn",
					},
					{
						level3_id: "08992",
						name: "Xã Thanh Lâm",
						type: "Xã",
					},
					{
						level3_id: "08995",
						name: "Xã Tam Đồng",
						type: "Xã",
					},
					{
						level3_id: "08998",
						name: "Xã Liên Mạc",
						type: "Xã",
					},
					{
						level3_id: "09001",
						name: "Xã Vạn Yên",
						type: "Xã",
					},
					{
						level3_id: "09004",
						name: "Xã Chu Phan",
						type: "Xã",
					},
					{
						level3_id: "09007",
						name: "Xã Tiến Thịnh",
						type: "Xã",
					},
					{
						level3_id: "09010",
						name: "Xã Mê Linh",
						type: "Xã",
					},
					{
						level3_id: "09013",
						name: "Xã Văn Khê",
						type: "Xã",
					},
					{
						level3_id: "09016",
						name: "Xã Hoàng Kim",
						type: "Xã",
					},
					{
						level3_id: "09019",
						name: "Xã Tiền Phong",
						type: "Xã",
					},
					{
						level3_id: "09022",
						name: "Xã Tráng Việt",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "268",
				name: "Quận Hà Đông",
				type: "Quận",
				level3s: [
					{
						level3_id: "09538",
						name: "Phường Nguyễn Trãi",
						type: "Phường",
					},
					{
						level3_id: "09541",
						name: "Phường Mộ Lao",
						type: "Phường",
					},
					{
						level3_id: "09542",
						name: "Phường Văn Quán",
						type: "Phường",
					},
					{
						level3_id: "09544",
						name: "Phường Vạn Phúc",
						type: "Phường",
					},
					{
						level3_id: "09547",
						name: "Phường Yết Kiêu",
						type: "Phường",
					},
					{
						level3_id: "09550",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "09551",
						name: "Phường La Khê",
						type: "Phường",
					},
					{
						level3_id: "09552",
						name: "Phường Phú La",
						type: "Phường",
					},
					{
						level3_id: "09553",
						name: "Phường Phúc La",
						type: "Phường",
					},
					{
						level3_id: "09556",
						name: "Phường Hà Cầu",
						type: "Phường",
					},
					{
						level3_id: "09562",
						name: "Phường Yên Nghĩa",
						type: "Phường",
					},
					{
						level3_id: "09565",
						name: "Phường Kiến Hưng",
						type: "Phường",
					},
					{
						level3_id: "09568",
						name: "Phường Phú Lãm",
						type: "Phường",
					},
					{
						level3_id: "09571",
						name: "Phường Phú Lương",
						type: "Phường",
					},
					{
						level3_id: "09886",
						name: "Phường Dương Nội",
						type: "Phường",
					},
					{
						level3_id: "10117",
						name: "Phường Đồng Mai",
						type: "Phường",
					},
					{
						level3_id: "10123",
						name: "Phường Biên Giang",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "269",
				name: "Thị xã Sơn Tây",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "09574",
						name: "Phường Lê Lợi",
						type: "Phường",
					},
					{
						level3_id: "09577",
						name: "Phường Phú Thịnh",
						type: "Phường",
					},
					{
						level3_id: "09580",
						name: "Phường Ngô Quyền",
						type: "Phường",
					},
					{
						level3_id: "09583",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "09586",
						name: "Phường Sơn Lộc",
						type: "Phường",
					},
					{
						level3_id: "09589",
						name: "Phường Xuân Khanh",
						type: "Phường",
					},
					{
						level3_id: "09592",
						name: "Xã Đường Lâm",
						type: "Xã",
					},
					{
						level3_id: "09595",
						name: "Phường Viên Sơn",
						type: "Phường",
					},
					{
						level3_id: "09598",
						name: "Xã Xuân Sơn",
						type: "Xã",
					},
					{
						level3_id: "09601",
						name: "Phường Trung Hưng",
						type: "Phường",
					},
					{
						level3_id: "09604",
						name: "Xã Thanh Mỹ",
						type: "Xã",
					},
					{
						level3_id: "09607",
						name: "Phường Trung Sơn Trầm",
						type: "Phường",
					},
					{
						level3_id: "09610",
						name: "Xã Kim Sơn",
						type: "Xã",
					},
					{
						level3_id: "09613",
						name: "Xã Sơn Đông",
						type: "Xã",
					},
					{
						level3_id: "09616",
						name: "Xã Cổ Đông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "271",
				name: "Huyện Ba Vì",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09619",
						name: "Thị trấn Tây Đằng",
						type: "Thị trấn",
					},
					{
						level3_id: "09625",
						name: "Xã Phú Cường",
						type: "Xã",
					},
					{
						level3_id: "09628",
						name: "Xã Cổ Đô",
						type: "Xã",
					},
					{
						level3_id: "09631",
						name: "Xã Tản Hồng",
						type: "Xã",
					},
					{
						level3_id: "09634",
						name: "Xã Vạn Thắng",
						type: "Xã",
					},
					{
						level3_id: "09637",
						name: "Xã Châu Sơn",
						type: "Xã",
					},
					{
						level3_id: "09640",
						name: "Xã Phong Vân",
						type: "Xã",
					},
					{
						level3_id: "09643",
						name: "Xã Phú Đông",
						type: "Xã",
					},
					{
						level3_id: "09646",
						name: "Xã Phú Phương",
						type: "Xã",
					},
					{
						level3_id: "09649",
						name: "Xã Phú Châu",
						type: "Xã",
					},
					{
						level3_id: "09652",
						name: "Xã Thái Hòa",
						type: "Xã",
					},
					{
						level3_id: "09655",
						name: "Xã Đồng Thái",
						type: "Xã",
					},
					{
						level3_id: "09658",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "09661",
						name: "Xã Minh Châu",
						type: "Xã",
					},
					{
						level3_id: "09664",
						name: "Xã Vật Lại",
						type: "Xã",
					},
					{
						level3_id: "09667",
						name: "Xã Chu Minh",
						type: "Xã",
					},
					{
						level3_id: "09670",
						name: "Xã Tòng Bạt",
						type: "Xã",
					},
					{
						level3_id: "09673",
						name: "Xã Cẩm Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "09676",
						name: "Xã Sơn Đà",
						type: "Xã",
					},
					{
						level3_id: "09679",
						name: "Xã Đông Quang",
						type: "Xã",
					},
					{
						level3_id: "09682",
						name: "Xã Tiên Phong",
						type: "Xã",
					},
					{
						level3_id: "09685",
						name: "Xã Thụy An",
						type: "Xã",
					},
					{
						level3_id: "09688",
						name: "Xã Cam Thượng",
						type: "Xã",
					},
					{
						level3_id: "09691",
						name: "Xã Thuần Mỹ",
						type: "Xã",
					},
					{
						level3_id: "09694",
						name: "Xã Tản Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "09697",
						name: "Xã Ba Trại",
						type: "Xã",
					},
					{
						level3_id: "09700",
						name: "Xã Minh Quang",
						type: "Xã",
					},
					{
						level3_id: "09703",
						name: "Xã Ba Vì",
						type: "Xã",
					},
					{
						level3_id: "09706",
						name: "Xã Vân Hòa",
						type: "Xã",
					},
					{
						level3_id: "09709",
						name: "Xã Yên Bài",
						type: "Xã",
					},
					{
						level3_id: "09712",
						name: "Xã Khánh Thượng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "272",
				name: "Huyện Phúc Thọ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09715",
						name: "Thị trấn Phúc Thọ",
						type: "Thị trấn",
					},
					{
						level3_id: "09718",
						name: "Xã Vân Hà",
						type: "Xã",
					},
					{
						level3_id: "09721",
						name: "Xã Vân Phúc",
						type: "Xã",
					},
					{
						level3_id: "09724",
						name: "Xã Vân Nam",
						type: "Xã",
					},
					{
						level3_id: "09727",
						name: "Xã Xuân Đình",
						type: "Xã",
					},
					{
						level3_id: "09733",
						name: "Xã Sen Phương",
						type: "Xã",
					},
					{
						level3_id: "09739",
						name: "Xã Võng Xuyên",
						type: "Xã",
					},
					{
						level3_id: "09742",
						name: "Xã Thọ Lộc",
						type: "Xã",
					},
					{
						level3_id: "09745",
						name: "Xã Long Xuyên",
						type: "Xã",
					},
					{
						level3_id: "09748",
						name: "Xã Thượng Cốc",
						type: "Xã",
					},
					{
						level3_id: "09751",
						name: "Xã Hát Môn",
						type: "Xã",
					},
					{
						level3_id: "09754",
						name: "Xã Tích Giang",
						type: "Xã",
					},
					{
						level3_id: "09757",
						name: "Xã Thanh Đa",
						type: "Xã",
					},
					{
						level3_id: "09760",
						name: "Xã Trạch Mỹ Lộc",
						type: "Xã",
					},
					{
						level3_id: "09763",
						name: "Xã Phúc Hòa",
						type: "Xã",
					},
					{
						level3_id: "09766",
						name: "Xã Ngọc Tảo",
						type: "Xã",
					},
					{
						level3_id: "09769",
						name: "Xã Phụng Thượng",
						type: "Xã",
					},
					{
						level3_id: "09772",
						name: "Xã Tam Thuấn",
						type: "Xã",
					},
					{
						level3_id: "09775",
						name: "Xã Tam Hiệp",
						type: "Xã",
					},
					{
						level3_id: "09778",
						name: "Xã Hiệp Thuận",
						type: "Xã",
					},
					{
						level3_id: "09781",
						name: "Xã Liên Hiệp",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "273",
				name: "Huyện Đan Phượng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09784",
						name: "Thị trấn Phùng",
						type: "Thị trấn",
					},
					{
						level3_id: "09787",
						name: "Xã Trung Châu",
						type: "Xã",
					},
					{
						level3_id: "09790",
						name: "Xã Thọ An",
						type: "Xã",
					},
					{
						level3_id: "09793",
						name: "Xã Thọ Xuân",
						type: "Xã",
					},
					{
						level3_id: "09796",
						name: "Xã Hồng Hà",
						type: "Xã",
					},
					{
						level3_id: "09799",
						name: "Xã Liên Hồng",
						type: "Xã",
					},
					{
						level3_id: "09802",
						name: "Xã Liên Hà",
						type: "Xã",
					},
					{
						level3_id: "09805",
						name: "Xã Hạ Mỗ",
						type: "Xã",
					},
					{
						level3_id: "09808",
						name: "Xã Liên Trung",
						type: "Xã",
					},
					{
						level3_id: "09811",
						name: "Xã Phương Đình",
						type: "Xã",
					},
					{
						level3_id: "09814",
						name: "Xã Thượng Mỗ",
						type: "Xã",
					},
					{
						level3_id: "09817",
						name: "Xã Tân Hội",
						type: "Xã",
					},
					{
						level3_id: "09820",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "09823",
						name: "Xã Đan Phượng",
						type: "Xã",
					},
					{
						level3_id: "09826",
						name: "Xã Đồng Tháp",
						type: "Xã",
					},
					{
						level3_id: "09829",
						name: "Xã Song Phượng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "274",
				name: "Huyện Hoài Đức",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09832",
						name: "Thị trấn Trạm Trôi",
						type: "Thị trấn",
					},
					{
						level3_id: "09835",
						name: "Xã Đức Thượng",
						type: "Xã",
					},
					{
						level3_id: "09838",
						name: "Xã Minh Khai",
						type: "Xã",
					},
					{
						level3_id: "09841",
						name: "Xã Dương Liễu",
						type: "Xã",
					},
					{
						level3_id: "09844",
						name: "Xã Di Trạch",
						type: "Xã",
					},
					{
						level3_id: "09847",
						name: "Xã Đức Giang",
						type: "Xã",
					},
					{
						level3_id: "09850",
						name: "Xã Cát Quế",
						type: "Xã",
					},
					{
						level3_id: "09853",
						name: "Xã Kim Chung",
						type: "Xã",
					},
					{
						level3_id: "09856",
						name: "Xã Yên Sở",
						type: "Xã",
					},
					{
						level3_id: "09859",
						name: "Xã Sơn Đồng",
						type: "Xã",
					},
					{
						level3_id: "09862",
						name: "Xã Vân Canh",
						type: "Xã",
					},
					{
						level3_id: "09865",
						name: "Xã Đắc Sở",
						type: "Xã",
					},
					{
						level3_id: "09868",
						name: "Xã Lại Yên",
						type: "Xã",
					},
					{
						level3_id: "09871",
						name: "Xã Tiền Yên",
						type: "Xã",
					},
					{
						level3_id: "09874",
						name: "Xã Song Phương",
						type: "Xã",
					},
					{
						level3_id: "09877",
						name: "Xã An Khánh",
						type: "Xã",
					},
					{
						level3_id: "09880",
						name: "Xã An Thượng",
						type: "Xã",
					},
					{
						level3_id: "09883",
						name: "Xã Vân Côn",
						type: "Xã",
					},
					{
						level3_id: "09889",
						name: "Xã La Phù",
						type: "Xã",
					},
					{
						level3_id: "09892",
						name: "Xã Đông La",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "275",
				name: "Huyện Quốc Oai",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04939",
						name: "Xã Đông Xuân",
						type: "Xã",
					},
					{
						level3_id: "09895",
						name: "Thị trấn Quốc Oai",
						type: "Thị trấn",
					},
					{
						level3_id: "09898",
						name: "Xã Sài Sơn",
						type: "Xã",
					},
					{
						level3_id: "09901",
						name: "Xã Phượng Cách",
						type: "Xã",
					},
					{
						level3_id: "09904",
						name: "Xã Yên Sơn",
						type: "Xã",
					},
					{
						level3_id: "09907",
						name: "Xã Ngọc Liệp",
						type: "Xã",
					},
					{
						level3_id: "09910",
						name: "Xã Ngọc Mỹ",
						type: "Xã",
					},
					{
						level3_id: "09913",
						name: "Xã Liệp Tuyết",
						type: "Xã",
					},
					{
						level3_id: "09916",
						name: "Xã Thạch Thán",
						type: "Xã",
					},
					{
						level3_id: "09919",
						name: "Xã Đồng Quang",
						type: "Xã",
					},
					{
						level3_id: "09922",
						name: "Xã Phú Cát",
						type: "Xã",
					},
					{
						level3_id: "09925",
						name: "Xã Tuyết Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "09928",
						name: "Xã Nghĩa Hương",
						type: "Xã",
					},
					{
						level3_id: "09931",
						name: "Xã Cộng Hòa",
						type: "Xã",
					},
					{
						level3_id: "09934",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "09937",
						name: "Xã Đại Thành",
						type: "Xã",
					},
					{
						level3_id: "09940",
						name: "Xã Phú Mãn",
						type: "Xã",
					},
					{
						level3_id: "09943",
						name: "Xã Cấn Hữu",
						type: "Xã",
					},
					{
						level3_id: "09946",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "09949",
						name: "Xã Hòa Thạch",
						type: "Xã",
					},
					{
						level3_id: "09952",
						name: "Xã Đông Yên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "276",
				name: "Huyện Thạch Thất",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04927",
						name: "Xã Yên Trung",
						type: "Xã",
					},
					{
						level3_id: "04930",
						name: "Xã Yên Bình",
						type: "Xã",
					},
					{
						level3_id: "04936",
						name: "Xã Tiến Xuân",
						type: "Xã",
					},
					{
						level3_id: "09955",
						name: "Thị trấn Liên Quan",
						type: "Thị trấn",
					},
					{
						level3_id: "09958",
						name: "Xã Đại Đồng",
						type: "Xã",
					},
					{
						level3_id: "09961",
						name: "Xã Cẩm Yên",
						type: "Xã",
					},
					{
						level3_id: "09964",
						name: "Xã Lại Thượng",
						type: "Xã",
					},
					{
						level3_id: "09967",
						name: "Xã Phú Kim",
						type: "Xã",
					},
					{
						level3_id: "09970",
						name: "Xã Hương Ngải",
						type: "Xã",
					},
					{
						level3_id: "09973",
						name: "Xã Canh Nậu",
						type: "Xã",
					},
					{
						level3_id: "09976",
						name: "Xã Kim Quan",
						type: "Xã",
					},
					{
						level3_id: "09979",
						name: "Xã Dị Nậu",
						type: "Xã",
					},
					{
						level3_id: "09982",
						name: "Xã Bình Yên",
						type: "Xã",
					},
					{
						level3_id: "09985",
						name: "Xã Chàng Sơn",
						type: "Xã",
					},
					{
						level3_id: "09988",
						name: "Xã Thạch Hoà",
						type: "Xã",
					},
					{
						level3_id: "09991",
						name: "Xã Cần Kiệm",
						type: "Xã",
					},
					{
						level3_id: "09994",
						name: "Xã Hữu Bằng",
						type: "Xã",
					},
					{
						level3_id: "09997",
						name: "Xã Phùng Xá",
						type: "Xã",
					},
					{
						level3_id: "10000",
						name: "Xã Tân Xã",
						type: "Xã",
					},
					{
						level3_id: "10003",
						name: "Xã Thạch Xá",
						type: "Xã",
					},
					{
						level3_id: "10006",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "10009",
						name: "Xã Hạ Bằng",
						type: "Xã",
					},
					{
						level3_id: "10012",
						name: "Xã Đồng Trúc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "277",
				name: "Huyện Chương Mỹ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10015",
						name: "Thị trấn Chúc Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "10018",
						name: "Thị trấn Xuân Mai",
						type: "Thị trấn",
					},
					{
						level3_id: "10021",
						name: "Xã Phụng Châu",
						type: "Xã",
					},
					{
						level3_id: "10024",
						name: "Xã Tiên Phương",
						type: "Xã",
					},
					{
						level3_id: "10027",
						name: "Xã Đông Sơn",
						type: "Xã",
					},
					{
						level3_id: "10030",
						name: "Xã Đông Phương Yên",
						type: "Xã",
					},
					{
						level3_id: "10033",
						name: "Xã Phú Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "10039",
						name: "Xã Trường Yên",
						type: "Xã",
					},
					{
						level3_id: "10042",
						name: "Xã Ngọc Hòa",
						type: "Xã",
					},
					{
						level3_id: "10045",
						name: "Xã Thủy Xuân Tiên",
						type: "Xã",
					},
					{
						level3_id: "10048",
						name: "Xã Thanh Bình",
						type: "Xã",
					},
					{
						level3_id: "10051",
						name: "Xã Trung Hòa",
						type: "Xã",
					},
					{
						level3_id: "10054",
						name: "Xã Đại Yên",
						type: "Xã",
					},
					{
						level3_id: "10057",
						name: "Xã Thụy Hương",
						type: "Xã",
					},
					{
						level3_id: "10060",
						name: "Xã Tốt Động",
						type: "Xã",
					},
					{
						level3_id: "10063",
						name: "Xã Lam Điền",
						type: "Xã",
					},
					{
						level3_id: "10066",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "10069",
						name: "Xã Nam Phương Tiến",
						type: "Xã",
					},
					{
						level3_id: "10072",
						name: "Xã Hợp Đồng",
						type: "Xã",
					},
					{
						level3_id: "10075",
						name: "Xã Hoàng Văn Thụ",
						type: "Xã",
					},
					{
						level3_id: "10078",
						name: "Xã Hoàng Diệu",
						type: "Xã",
					},
					{
						level3_id: "10081",
						name: "Xã Hữu Văn",
						type: "Xã",
					},
					{
						level3_id: "10084",
						name: "Xã Quảng Bị",
						type: "Xã",
					},
					{
						level3_id: "10087",
						name: "Xã Mỹ Lương",
						type: "Xã",
					},
					{
						level3_id: "10090",
						name: "Xã Thượng Vực",
						type: "Xã",
					},
					{
						level3_id: "10093",
						name: "Xã Hồng Phong",
						type: "Xã",
					},
					{
						level3_id: "10096",
						name: "Xã Đồng Phú",
						type: "Xã",
					},
					{
						level3_id: "10099",
						name: "Xã Trần Phú",
						type: "Xã",
					},
					{
						level3_id: "10102",
						name: "Xã Văn Võ",
						type: "Xã",
					},
					{
						level3_id: "10105",
						name: "Xã Đồng Lạc",
						type: "Xã",
					},
					{
						level3_id: "10108",
						name: "Xã Hòa Chính",
						type: "Xã",
					},
					{
						level3_id: "10111",
						name: "Xã Phú Nam An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "278",
				name: "Huyện Thanh Oai",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10114",
						name: "Thị trấn Kim Bài",
						type: "Thị trấn",
					},
					{
						level3_id: "10120",
						name: "Xã Cự Khê",
						type: "Xã",
					},
					{
						level3_id: "10126",
						name: "Xã Bích Hòa",
						type: "Xã",
					},
					{
						level3_id: "10129",
						name: "Xã Mỹ Hưng",
						type: "Xã",
					},
					{
						level3_id: "10132",
						name: "Xã Cao Viên",
						type: "Xã",
					},
					{
						level3_id: "10135",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "10138",
						name: "Xã Tam Hưng",
						type: "Xã",
					},
					{
						level3_id: "10141",
						name: "Xã Thanh Cao",
						type: "Xã",
					},
					{
						level3_id: "10144",
						name: "Xã Thanh Thùy",
						type: "Xã",
					},
					{
						level3_id: "10147",
						name: "Xã Thanh Mai",
						type: "Xã",
					},
					{
						level3_id: "10150",
						name: "Xã Thanh Văn",
						type: "Xã",
					},
					{
						level3_id: "10153",
						name: "Xã Đỗ Động",
						type: "Xã",
					},
					{
						level3_id: "10156",
						name: "Xã Kim An",
						type: "Xã",
					},
					{
						level3_id: "10159",
						name: "Xã Kim Thư",
						type: "Xã",
					},
					{
						level3_id: "10162",
						name: "Xã Phương Trung",
						type: "Xã",
					},
					{
						level3_id: "10165",
						name: "Xã Tân Ước",
						type: "Xã",
					},
					{
						level3_id: "10168",
						name: "Xã Dân Hòa",
						type: "Xã",
					},
					{
						level3_id: "10171",
						name: "Xã Liên Châu",
						type: "Xã",
					},
					{
						level3_id: "10174",
						name: "Xã Cao Dương",
						type: "Xã",
					},
					{
						level3_id: "10177",
						name: "Xã Xuân Dương",
						type: "Xã",
					},
					{
						level3_id: "10180",
						name: "Xã Hồng Dương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "279",
				name: "Huyện Thường Tín",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10183",
						name: "Thị trấn Thường Tín",
						type: "Thị trấn",
					},
					{
						level3_id: "10186",
						name: "Xã Ninh Sở",
						type: "Xã",
					},
					{
						level3_id: "10189",
						name: "Xã Nhị Khê",
						type: "Xã",
					},
					{
						level3_id: "10192",
						name: "Xã Duyên Thái",
						type: "Xã",
					},
					{
						level3_id: "10195",
						name: "Xã Khánh Hà",
						type: "Xã",
					},
					{
						level3_id: "10198",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "10201",
						name: "Xã Văn Bình",
						type: "Xã",
					},
					{
						level3_id: "10204",
						name: "Xã Hiền Giang",
						type: "Xã",
					},
					{
						level3_id: "10207",
						name: "Xã Hồng Vân",
						type: "Xã",
					},
					{
						level3_id: "10210",
						name: "Xã Vân Tảo",
						type: "Xã",
					},
					{
						level3_id: "10213",
						name: "Xã Liên Phương",
						type: "Xã",
					},
					{
						level3_id: "10216",
						name: "Xã Văn Phú",
						type: "Xã",
					},
					{
						level3_id: "10219",
						name: "Xã Tự Nhiên",
						type: "Xã",
					},
					{
						level3_id: "10222",
						name: "Xã Tiền Phong",
						type: "Xã",
					},
					{
						level3_id: "10225",
						name: "Xã Hà Hồi",
						type: "Xã",
					},
					{
						level3_id: "10228",
						name: "Xã Thư Phú",
						type: "Xã",
					},
					{
						level3_id: "10231",
						name: "Xã Nguyễn Trãi",
						type: "Xã",
					},
					{
						level3_id: "10234",
						name: "Xã Quất Động",
						type: "Xã",
					},
					{
						level3_id: "10237",
						name: "Xã Chương Dương",
						type: "Xã",
					},
					{
						level3_id: "10240",
						name: "Xã Tân Minh",
						type: "Xã",
					},
					{
						level3_id: "10243",
						name: "Xã Lê Lợi",
						type: "Xã",
					},
					{
						level3_id: "10246",
						name: "Xã Thắng Lợi",
						type: "Xã",
					},
					{
						level3_id: "10249",
						name: "Xã Dũng Tiến",
						type: "Xã",
					},
					{
						level3_id: "10252",
						name: "Xã Thống Nhất",
						type: "Xã",
					},
					{
						level3_id: "10255",
						name: "Xã Nghiêm Xuyên",
						type: "Xã",
					},
					{
						level3_id: "10258",
						name: "Xã Tô Hiệu",
						type: "Xã",
					},
					{
						level3_id: "10261",
						name: "Xã Văn Tự",
						type: "Xã",
					},
					{
						level3_id: "10264",
						name: "Xã Vạn Điểm",
						type: "Xã",
					},
					{
						level3_id: "10267",
						name: "Xã Minh Cường",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "280",
				name: "Huyện Phú Xuyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10270",
						name: "Thị trấn Phú Minh",
						type: "Thị trấn",
					},
					{
						level3_id: "10273",
						name: "Thị trấn Phú Xuyên",
						type: "Thị trấn",
					},
					{
						level3_id: "10276",
						name: "Xã Hồng Minh",
						type: "Xã",
					},
					{
						level3_id: "10279",
						name: "Xã Phượng Dực",
						type: "Xã",
					},
					{
						level3_id: "10282",
						name: "Xã Nam Tiến",
						type: "Xã",
					},
					{
						level3_id: "10288",
						name: "Xã Tri Trung",
						type: "Xã",
					},
					{
						level3_id: "10291",
						name: "Xã Đại Thắng",
						type: "Xã",
					},
					{
						level3_id: "10294",
						name: "Xã Phú Túc",
						type: "Xã",
					},
					{
						level3_id: "10297",
						name: "Xã Văn Hoàng",
						type: "Xã",
					},
					{
						level3_id: "10300",
						name: "Xã Hồng Thái",
						type: "Xã",
					},
					{
						level3_id: "10303",
						name: "Xã Hoàng Long",
						type: "Xã",
					},
					{
						level3_id: "10306",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "10309",
						name: "Xã Nam Phong",
						type: "Xã",
					},
					{
						level3_id: "10312",
						name: "Xã Nam Triều",
						type: "Xã",
					},
					{
						level3_id: "10315",
						name: "Xã Tân Dân",
						type: "Xã",
					},
					{
						level3_id: "10318",
						name: "Xã Sơn Hà",
						type: "Xã",
					},
					{
						level3_id: "10321",
						name: "Xã Chuyên Mỹ",
						type: "Xã",
					},
					{
						level3_id: "10324",
						name: "Xã Khai Thái",
						type: "Xã",
					},
					{
						level3_id: "10327",
						name: "Xã Phúc Tiến",
						type: "Xã",
					},
					{
						level3_id: "10330",
						name: "Xã Vân Từ",
						type: "Xã",
					},
					{
						level3_id: "10333",
						name: "Xã Tri Thủy",
						type: "Xã",
					},
					{
						level3_id: "10336",
						name: "Xã Đại Xuyên",
						type: "Xã",
					},
					{
						level3_id: "10339",
						name: "Xã Phú Yên",
						type: "Xã",
					},
					{
						level3_id: "10342",
						name: "Xã Bạch Hạ",
						type: "Xã",
					},
					{
						level3_id: "10345",
						name: "Xã Quang Lãng",
						type: "Xã",
					},
					{
						level3_id: "10348",
						name: "Xã Châu Can",
						type: "Xã",
					},
					{
						level3_id: "10351",
						name: "Xã Minh Tân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "281",
				name: "Huyện Ứng Hòa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10354",
						name: "Thị trấn Vân Đình",
						type: "Thị trấn",
					},
					{
						level3_id: "10357",
						name: "Xã Viên An",
						type: "Xã",
					},
					{
						level3_id: "10360",
						name: "Xã Viên Nội",
						type: "Xã",
					},
					{
						level3_id: "10363",
						name: "Xã Hoa Sơn",
						type: "Xã",
					},
					{
						level3_id: "10366",
						name: "Xã Quảng Phú Cầu",
						type: "Xã",
					},
					{
						level3_id: "10369",
						name: "Xã Trường Thịnh",
						type: "Xã",
					},
					{
						level3_id: "10372",
						name: "Xã Cao Thành",
						type: "Xã",
					},
					{
						level3_id: "10375",
						name: "Xã Liên Bạt",
						type: "Xã",
					},
					{
						level3_id: "10378",
						name: "Xã Sơn Công",
						type: "Xã",
					},
					{
						level3_id: "10381",
						name: "Xã Đồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "10384",
						name: "Xã Phương Tú",
						type: "Xã",
					},
					{
						level3_id: "10387",
						name: "Xã Trung Tú",
						type: "Xã",
					},
					{
						level3_id: "10390",
						name: "Xã Đồng Tân",
						type: "Xã",
					},
					{
						level3_id: "10393",
						name: "Xã Tảo Dương Văn",
						type: "Xã",
					},
					{
						level3_id: "10396",
						name: "Xã Vạn Thái",
						type: "Xã",
					},
					{
						level3_id: "10399",
						name: "Xã Minh Đức",
						type: "Xã",
					},
					{
						level3_id: "10402",
						name: "Xã Hòa Lâm",
						type: "Xã",
					},
					{
						level3_id: "10405",
						name: "Xã Hòa Xá",
						type: "Xã",
					},
					{
						level3_id: "10408",
						name: "Xã Trầm Lộng",
						type: "Xã",
					},
					{
						level3_id: "10411",
						name: "Xã Kim Đường",
						type: "Xã",
					},
					{
						level3_id: "10414",
						name: "Xã Hòa Nam",
						type: "Xã",
					},
					{
						level3_id: "10417",
						name: "Xã Hòa Phú",
						type: "Xã",
					},
					{
						level3_id: "10420",
						name: "Xã Đội Bình",
						type: "Xã",
					},
					{
						level3_id: "10423",
						name: "Xã Đại Hùng",
						type: "Xã",
					},
					{
						level3_id: "10426",
						name: "Xã Đông Lỗ",
						type: "Xã",
					},
					{
						level3_id: "10429",
						name: "Xã Phù Lưu",
						type: "Xã",
					},
					{
						level3_id: "10432",
						name: "Xã Đại Cường",
						type: "Xã",
					},
					{
						level3_id: "10435",
						name: "Xã Lưu Hoàng",
						type: "Xã",
					},
					{
						level3_id: "10438",
						name: "Xã Hồng Quang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "282",
				name: "Huyện Mỹ Đức",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10441",
						name: "Thị trấn Đại Nghĩa",
						type: "Thị trấn",
					},
					{
						level3_id: "10444",
						name: "Xã Đồng Tâm",
						type: "Xã",
					},
					{
						level3_id: "10447",
						name: "Xã Thượng Lâm",
						type: "Xã",
					},
					{
						level3_id: "10450",
						name: "Xã Tuy Lai",
						type: "Xã",
					},
					{
						level3_id: "10453",
						name: "Xã Phúc Lâm",
						type: "Xã",
					},
					{
						level3_id: "10456",
						name: "Xã Mỹ Thành",
						type: "Xã",
					},
					{
						level3_id: "10459",
						name: "Xã Bột Xuyên",
						type: "Xã",
					},
					{
						level3_id: "10462",
						name: "Xã An Mỹ",
						type: "Xã",
					},
					{
						level3_id: "10465",
						name: "Xã Hồng Sơn",
						type: "Xã",
					},
					{
						level3_id: "10468",
						name: "Xã Lê Thanh",
						type: "Xã",
					},
					{
						level3_id: "10471",
						name: "Xã Xuy Xá",
						type: "Xã",
					},
					{
						level3_id: "10474",
						name: "Xã Phùng Xá",
						type: "Xã",
					},
					{
						level3_id: "10477",
						name: "Xã Phù Lưu Tế",
						type: "Xã",
					},
					{
						level3_id: "10480",
						name: "Xã Đại Hưng",
						type: "Xã",
					},
					{
						level3_id: "10483",
						name: "Xã Vạn Kim",
						type: "Xã",
					},
					{
						level3_id: "10486",
						name: "Xã Đốc Tín",
						type: "Xã",
					},
					{
						level3_id: "10489",
						name: "Xã Hương Sơn",
						type: "Xã",
					},
					{
						level3_id: "10492",
						name: "Xã Hùng Tiến",
						type: "Xã",
					},
					{
						level3_id: "10495",
						name: "Xã An Tiến",
						type: "Xã",
					},
					{
						level3_id: "10498",
						name: "Xã Hợp Tiến",
						type: "Xã",
					},
					{
						level3_id: "10501",
						name: "Xã Hợp Thanh",
						type: "Xã",
					},
					{
						level3_id: "10504",
						name: "Xã An Phú",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "02",
		name: "Tỉnh Hà Giang",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "024",
				name: "Thành phố Hà Giang",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "00688",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "00691",
						name: "Phường Trần Phú",
						type: "Phường",
					},
					{
						level3_id: "00692",
						name: "Phường Ngọc Hà",
						type: "Phường",
					},
					{
						level3_id: "00694",
						name: "Phường Nguyễn Trãi",
						type: "Phường",
					},
					{
						level3_id: "00697",
						name: "Phường Minh Khai",
						type: "Phường",
					},
					{
						level3_id: "00700",
						name: "Xã Ngọc Đường",
						type: "Xã",
					},
					{
						level3_id: "00946",
						name: "Xã Phương Độ",
						type: "Xã",
					},
					{
						level3_id: "00949",
						name: "Xã Phương Thiện",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "026",
				name: "Huyện Đồng Văn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00712",
						name: "Thị trấn Phó Bảng",
						type: "Thị trấn",
					},
					{
						level3_id: "00715",
						name: "Xã Lũng Cú",
						type: "Xã",
					},
					{
						level3_id: "00718",
						name: "Xã Má Lé",
						type: "Xã",
					},
					{
						level3_id: "00721",
						name: "Thị trấn Đồng Văn",
						type: "Thị trấn",
					},
					{
						level3_id: "00724",
						name: "Xã Lũng Táo",
						type: "Xã",
					},
					{
						level3_id: "00727",
						name: "Xã Phố Là",
						type: "Xã",
					},
					{
						level3_id: "00730",
						name: "Xã Thài Phìn Tủng",
						type: "Xã",
					},
					{
						level3_id: "00733",
						name: "Xã Sủng Là",
						type: "Xã",
					},
					{
						level3_id: "00736",
						name: "Xã Xà Phìn",
						type: "Xã",
					},
					{
						level3_id: "00739",
						name: "Xã Tả Phìn",
						type: "Xã",
					},
					{
						level3_id: "00742",
						name: "Xã Tả Lủng",
						type: "Xã",
					},
					{
						level3_id: "00745",
						name: "Xã Phố Cáo",
						type: "Xã",
					},
					{
						level3_id: "00748",
						name: "Xã Sính Lủng",
						type: "Xã",
					},
					{
						level3_id: "00751",
						name: "Xã Sảng Tủng",
						type: "Xã",
					},
					{
						level3_id: "00754",
						name: "Xã Lũng Thầu",
						type: "Xã",
					},
					{
						level3_id: "00757",
						name: "Xã Hố Quáng Phìn",
						type: "Xã",
					},
					{
						level3_id: "00760",
						name: "Xã Vần Chải",
						type: "Xã",
					},
					{
						level3_id: "00763",
						name: "Xã Lũng Phìn",
						type: "Xã",
					},
					{
						level3_id: "00766",
						name: "Xã Sủng Trái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "027",
				name: "Huyện Mèo Vạc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00769",
						name: "Thị trấn Mèo Vạc",
						type: "Thị trấn",
					},
					{
						level3_id: "00772",
						name: "Xã Thượng Phùng",
						type: "Xã",
					},
					{
						level3_id: "00775",
						name: "Xã Pải Lủng",
						type: "Xã",
					},
					{
						level3_id: "00778",
						name: "Xã Xín Cái",
						type: "Xã",
					},
					{
						level3_id: "00781",
						name: "Xã Pả Vi",
						type: "Xã",
					},
					{
						level3_id: "00784",
						name: "Xã Giàng Chu Phìn",
						type: "Xã",
					},
					{
						level3_id: "00787",
						name: "Xã Sủng Trà",
						type: "Xã",
					},
					{
						level3_id: "00790",
						name: "Xã Sủng Máng",
						type: "Xã",
					},
					{
						level3_id: "00793",
						name: "Xã Sơn Vĩ",
						type: "Xã",
					},
					{
						level3_id: "00796",
						name: "Xã Tả Lủng",
						type: "Xã",
					},
					{
						level3_id: "00799",
						name: "Xã Cán Chu Phìn",
						type: "Xã",
					},
					{
						level3_id: "00802",
						name: "Xã Lũng Pù",
						type: "Xã",
					},
					{
						level3_id: "00805",
						name: "Xã Lũng Chinh",
						type: "Xã",
					},
					{
						level3_id: "00808",
						name: "Xã Tát Ngà",
						type: "Xã",
					},
					{
						level3_id: "00811",
						name: "Xã Nậm Ban",
						type: "Xã",
					},
					{
						level3_id: "00814",
						name: "Xã Khâu Vai",
						type: "Xã",
					},
					{
						level3_id: "00815",
						name: "Xã Niêm Tòng",
						type: "Xã",
					},
					{
						level3_id: "00817",
						name: "Xã Niêm Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "028",
				name: "Huyện Yên Minh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00820",
						name: "Thị trấn Yên Minh",
						type: "Thị trấn",
					},
					{
						level3_id: "00823",
						name: "Xã Thắng Mố",
						type: "Xã",
					},
					{
						level3_id: "00826",
						name: "Xã Phú Lũng",
						type: "Xã",
					},
					{
						level3_id: "00829",
						name: "Xã Sủng Tráng",
						type: "Xã",
					},
					{
						level3_id: "00832",
						name: "Xã Bạch Đích",
						type: "Xã",
					},
					{
						level3_id: "00835",
						name: "Xã Na Khê",
						type: "Xã",
					},
					{
						level3_id: "00838",
						name: "Xã Sủng Thài",
						type: "Xã",
					},
					{
						level3_id: "00841",
						name: "Xã Hữu Vinh",
						type: "Xã",
					},
					{
						level3_id: "00844",
						name: "Xã Lao Và Chải",
						type: "Xã",
					},
					{
						level3_id: "00847",
						name: "Xã Mậu Duệ",
						type: "Xã",
					},
					{
						level3_id: "00850",
						name: "Xã Đông Minh",
						type: "Xã",
					},
					{
						level3_id: "00853",
						name: "Xã Mậu Long",
						type: "Xã",
					},
					{
						level3_id: "00856",
						name: "Xã Ngam La",
						type: "Xã",
					},
					{
						level3_id: "00859",
						name: "Xã Ngọc Long",
						type: "Xã",
					},
					{
						level3_id: "00862",
						name: "Xã Đường Thượng",
						type: "Xã",
					},
					{
						level3_id: "00865",
						name: "Xã Lũng Hồ",
						type: "Xã",
					},
					{
						level3_id: "00868",
						name: "Xã Du Tiến",
						type: "Xã",
					},
					{
						level3_id: "00871",
						name: "Xã Du Già",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "029",
				name: "Huyện Quản Bạ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00874",
						name: "Thị trấn Tam Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "00877",
						name: "Xã Bát Đại Sơn",
						type: "Xã",
					},
					{
						level3_id: "00880",
						name: "Xã Nghĩa Thuận",
						type: "Xã",
					},
					{
						level3_id: "00883",
						name: "Xã Cán Tỷ",
						type: "Xã",
					},
					{
						level3_id: "00886",
						name: "Xã Cao Mã Pờ",
						type: "Xã",
					},
					{
						level3_id: "00889",
						name: "Xã Thanh Vân",
						type: "Xã",
					},
					{
						level3_id: "00892",
						name: "Xã Tùng Vài",
						type: "Xã",
					},
					{
						level3_id: "00895",
						name: "Xã Đông Hà",
						type: "Xã",
					},
					{
						level3_id: "00898",
						name: "Xã Quản Bạ",
						type: "Xã",
					},
					{
						level3_id: "00901",
						name: "Xã Lùng Tám",
						type: "Xã",
					},
					{
						level3_id: "00904",
						name: "Xã Quyết Tiến",
						type: "Xã",
					},
					{
						level3_id: "00907",
						name: "Xã Tả Ván",
						type: "Xã",
					},
					{
						level3_id: "00910",
						name: "Xã Thái An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "030",
				name: "Huyện Vị Xuyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00703",
						name: "Xã Kim Thạch",
						type: "Xã",
					},
					{
						level3_id: "00706",
						name: "Xã Phú Linh",
						type: "Xã",
					},
					{
						level3_id: "00709",
						name: "Xã Kim Linh",
						type: "Xã",
					},
					{
						level3_id: "00913",
						name: "Thị trấn Vị Xuyên",
						type: "Thị trấn",
					},
					{
						level3_id: "00916",
						name: "Thị trấn Nông Trường Việt Lâm",
						type: "Thị trấn",
					},
					{
						level3_id: "00919",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "00922",
						name: "Xã Thuận Hoà",
						type: "Xã",
					},
					{
						level3_id: "00925",
						name: "Xã Tùng Bá",
						type: "Xã",
					},
					{
						level3_id: "00928",
						name: "Xã Thanh Thủy",
						type: "Xã",
					},
					{
						level3_id: "00931",
						name: "Xã Thanh Đức",
						type: "Xã",
					},
					{
						level3_id: "00934",
						name: "Xã Phong Quang",
						type: "Xã",
					},
					{
						level3_id: "00937",
						name: "Xã Xín Chải",
						type: "Xã",
					},
					{
						level3_id: "00940",
						name: "Xã Phương Tiến",
						type: "Xã",
					},
					{
						level3_id: "00943",
						name: "Xã Lao Chải",
						type: "Xã",
					},
					{
						level3_id: "00952",
						name: "Xã Cao Bồ",
						type: "Xã",
					},
					{
						level3_id: "00955",
						name: "Xã Đạo Đức",
						type: "Xã",
					},
					{
						level3_id: "00958",
						name: "Xã Thượng Sơn",
						type: "Xã",
					},
					{
						level3_id: "00961",
						name: "Xã Linh Hồ",
						type: "Xã",
					},
					{
						level3_id: "00964",
						name: "Xã Quảng Ngần",
						type: "Xã",
					},
					{
						level3_id: "00967",
						name: "Xã Việt Lâm",
						type: "Xã",
					},
					{
						level3_id: "00970",
						name: "Xã Ngọc Linh",
						type: "Xã",
					},
					{
						level3_id: "00973",
						name: "Xã Ngọc Minh",
						type: "Xã",
					},
					{
						level3_id: "00976",
						name: "Xã Bạch Ngọc",
						type: "Xã",
					},
					{
						level3_id: "00979",
						name: "Xã Trung Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "031",
				name: "Huyện Bắc Mê",
				type: "Huyện",
				level3s: [
					{
						level3_id: "00982",
						name: "Xã Minh Sơn",
						type: "Xã",
					},
					{
						level3_id: "00985",
						name: "Xã Giáp Trung",
						type: "Xã",
					},
					{
						level3_id: "00988",
						name: "Xã Yên Định",
						type: "Xã",
					},
					{
						level3_id: "00991",
						name: "Thị trấn Yên Phú",
						type: "Thị trấn",
					},
					{
						level3_id: "00994",
						name: "Xã Minh Ngọc",
						type: "Xã",
					},
					{
						level3_id: "00997",
						name: "Xã Yên Phong",
						type: "Xã",
					},
					{
						level3_id: "01000",
						name: "Xã Lạc Nông",
						type: "Xã",
					},
					{
						level3_id: "01003",
						name: "Xã Phú Nam",
						type: "Xã",
					},
					{
						level3_id: "01006",
						name: "Xã Yên Cường",
						type: "Xã",
					},
					{
						level3_id: "01009",
						name: "Xã Thượng Tân",
						type: "Xã",
					},
					{
						level3_id: "01012",
						name: "Xã Đường Âm",
						type: "Xã",
					},
					{
						level3_id: "01015",
						name: "Xã Đường Hồng",
						type: "Xã",
					},
					{
						level3_id: "01018",
						name: "Xã Phiêng Luông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "032",
				name: "Huyện Hoàng Su Phì",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01021",
						name: "Thị trấn Vinh Quang",
						type: "Thị trấn",
					},
					{
						level3_id: "01024",
						name: "Xã Bản Máy",
						type: "Xã",
					},
					{
						level3_id: "01027",
						name: "Xã Thàng Tín",
						type: "Xã",
					},
					{
						level3_id: "01030",
						name: "Xã Thèn Chu Phìn",
						type: "Xã",
					},
					{
						level3_id: "01033",
						name: "Xã Pố Lồ",
						type: "Xã",
					},
					{
						level3_id: "01036",
						name: "Xã Bản Phùng",
						type: "Xã",
					},
					{
						level3_id: "01039",
						name: "Xã Túng Sán",
						type: "Xã",
					},
					{
						level3_id: "01042",
						name: "Xã Chiến Phố",
						type: "Xã",
					},
					{
						level3_id: "01045",
						name: "Xã Đản Ván",
						type: "Xã",
					},
					{
						level3_id: "01048",
						name: "Xã Tụ Nhân",
						type: "Xã",
					},
					{
						level3_id: "01051",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "01054",
						name: "Xã Nàng Đôn",
						type: "Xã",
					},
					{
						level3_id: "01057",
						name: "Xã Pờ Ly Ngài",
						type: "Xã",
					},
					{
						level3_id: "01060",
						name: "Xã Sán Xả Hồ",
						type: "Xã",
					},
					{
						level3_id: "01063",
						name: "Xã Bản Luốc",
						type: "Xã",
					},
					{
						level3_id: "01066",
						name: "Xã Ngàm Đăng Vài",
						type: "Xã",
					},
					{
						level3_id: "01069",
						name: "Xã Bản Nhùng",
						type: "Xã",
					},
					{
						level3_id: "01072",
						name: "Xã Tả Sử Choóng",
						type: "Xã",
					},
					{
						level3_id: "01075",
						name: "Xã Nậm Dịch",
						type: "Xã",
					},
					{
						level3_id: "01081",
						name: "Xã Hồ Thầu",
						type: "Xã",
					},
					{
						level3_id: "01084",
						name: "Xã Nam Sơn",
						type: "Xã",
					},
					{
						level3_id: "01087",
						name: "Xã Nậm Tỵ",
						type: "Xã",
					},
					{
						level3_id: "01090",
						name: "Xã Thông Nguyên",
						type: "Xã",
					},
					{
						level3_id: "01093",
						name: "Xã Nậm Khòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "033",
				name: "Huyện Xín Mần",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01096",
						name: "Thị trấn Cốc Pài",
						type: "Thị trấn",
					},
					{
						level3_id: "01099",
						name: "Xã Nàn Xỉn",
						type: "Xã",
					},
					{
						level3_id: "01102",
						name: "Xã Bản Díu",
						type: "Xã",
					},
					{
						level3_id: "01105",
						name: "Xã Chí Cà",
						type: "Xã",
					},
					{
						level3_id: "01108",
						name: "Xã Xín Mần",
						type: "Xã",
					},
					{
						level3_id: "01114",
						name: "Xã Thèn Phàng",
						type: "Xã",
					},
					{
						level3_id: "01117",
						name: "Xã Trung Thịnh",
						type: "Xã",
					},
					{
						level3_id: "01120",
						name: "Xã Pà Vầy Sủ",
						type: "Xã",
					},
					{
						level3_id: "01123",
						name: "Xã Cốc Rế",
						type: "Xã",
					},
					{
						level3_id: "01126",
						name: "Xã Thu Tà",
						type: "Xã",
					},
					{
						level3_id: "01129",
						name: "Xã Nàn Ma",
						type: "Xã",
					},
					{
						level3_id: "01132",
						name: "Xã Tả Nhìu",
						type: "Xã",
					},
					{
						level3_id: "01135",
						name: "Xã Bản Ngò",
						type: "Xã",
					},
					{
						level3_id: "01138",
						name: "Xã Chế Là",
						type: "Xã",
					},
					{
						level3_id: "01141",
						name: "Xã Nấm Dẩn",
						type: "Xã",
					},
					{
						level3_id: "01144",
						name: "Xã Quảng Nguyên",
						type: "Xã",
					},
					{
						level3_id: "01147",
						name: "Xã Nà Chì",
						type: "Xã",
					},
					{
						level3_id: "01150",
						name: "Xã Khuôn Lùng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "034",
				name: "Huyện Bắc Quang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01153",
						name: "Thị trấn Việt Quang",
						type: "Thị trấn",
					},
					{
						level3_id: "01156",
						name: "Thị trấn Vĩnh Tuy",
						type: "Thị trấn",
					},
					{
						level3_id: "01159",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "01162",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "01165",
						name: "Xã Đồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "01168",
						name: "Xã Đồng Tâm",
						type: "Xã",
					},
					{
						level3_id: "01171",
						name: "Xã Tân Quang",
						type: "Xã",
					},
					{
						level3_id: "01174",
						name: "Xã Thượng Bình",
						type: "Xã",
					},
					{
						level3_id: "01177",
						name: "Xã Hữu Sản",
						type: "Xã",
					},
					{
						level3_id: "01180",
						name: "Xã Kim Ngọc",
						type: "Xã",
					},
					{
						level3_id: "01183",
						name: "Xã Việt Vinh",
						type: "Xã",
					},
					{
						level3_id: "01186",
						name: "Xã Bằng Hành",
						type: "Xã",
					},
					{
						level3_id: "01189",
						name: "Xã Quang Minh",
						type: "Xã",
					},
					{
						level3_id: "01192",
						name: "Xã Liên Hiệp",
						type: "Xã",
					},
					{
						level3_id: "01195",
						name: "Xã Vô Điếm",
						type: "Xã",
					},
					{
						level3_id: "01198",
						name: "Xã Việt Hồng",
						type: "Xã",
					},
					{
						level3_id: "01201",
						name: "Xã Hùng An",
						type: "Xã",
					},
					{
						level3_id: "01204",
						name: "Xã Đức Xuân",
						type: "Xã",
					},
					{
						level3_id: "01207",
						name: "Xã Tiên Kiều",
						type: "Xã",
					},
					{
						level3_id: "01210",
						name: "Xã Vĩnh Hảo",
						type: "Xã",
					},
					{
						level3_id: "01213",
						name: "Xã Vĩnh Phúc",
						type: "Xã",
					},
					{
						level3_id: "01216",
						name: "Xã Đồng Yên",
						type: "Xã",
					},
					{
						level3_id: "01219",
						name: "Xã Đông Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "035",
				name: "Huyện Quang Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01222",
						name: "Xã Xuân Minh",
						type: "Xã",
					},
					{
						level3_id: "01225",
						name: "Xã Tiên Nguyên",
						type: "Xã",
					},
					{
						level3_id: "01228",
						name: "Xã Tân Nam",
						type: "Xã",
					},
					{
						level3_id: "01231",
						name: "Xã Bản Rịa",
						type: "Xã",
					},
					{
						level3_id: "01234",
						name: "Xã Yên Thành",
						type: "Xã",
					},
					{
						level3_id: "01237",
						name: "Thị trấn Yên Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "01240",
						name: "Xã Tân Trịnh",
						type: "Xã",
					},
					{
						level3_id: "01243",
						name: "Xã Tân Bắc",
						type: "Xã",
					},
					{
						level3_id: "01246",
						name: "Xã Bằng Lang",
						type: "Xã",
					},
					{
						level3_id: "01249",
						name: "Xã Yên Hà",
						type: "Xã",
					},
					{
						level3_id: "01252",
						name: "Xã Hương Sơn",
						type: "Xã",
					},
					{
						level3_id: "01255",
						name: "Xã Xuân Giang",
						type: "Xã",
					},
					{
						level3_id: "01258",
						name: "Xã Nà Khương",
						type: "Xã",
					},
					{
						level3_id: "01261",
						name: "Xã Tiên Yên",
						type: "Xã",
					},
					{
						level3_id: "01264",
						name: "Xã Vĩ Thượng",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "04",
		name: "Tỉnh Cao Bằng",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "040",
				name: "Thành phố Cao Bằng",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "01267",
						name: "Phường Sông Hiến",
						type: "Phường",
					},
					{
						level3_id: "01270",
						name: "Phường Sông Bằng",
						type: "Phường",
					},
					{
						level3_id: "01273",
						name: "Phường Hợp Giang",
						type: "Phường",
					},
					{
						level3_id: "01276",
						name: "Phường Tân Giang",
						type: "Phường",
					},
					{
						level3_id: "01279",
						name: "Phường Ngọc Xuân",
						type: "Phường",
					},
					{
						level3_id: "01282",
						name: "Phường Đề Thám",
						type: "Phường",
					},
					{
						level3_id: "01285",
						name: "Phường Hoà Chung",
						type: "Phường",
					},
					{
						level3_id: "01288",
						name: "Phường Duyệt Trung",
						type: "Phường",
					},
					{
						level3_id: "01693",
						name: "Xã Vĩnh Quang",
						type: "Xã",
					},
					{
						level3_id: "01705",
						name: "Xã Hưng Đạo",
						type: "Xã",
					},
					{
						level3_id: "01720",
						name: "Xã Chu Trinh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "042",
				name: "Huyện Bảo Lâm",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01290",
						name: "Thị trấn Pác Miầu",
						type: "Thị trấn",
					},
					{
						level3_id: "01291",
						name: "Xã Đức Hạnh",
						type: "Xã",
					},
					{
						level3_id: "01294",
						name: "Xã Lý Bôn",
						type: "Xã",
					},
					{
						level3_id: "01296",
						name: "Xã Nam Cao",
						type: "Xã",
					},
					{
						level3_id: "01297",
						name: "Xã Nam Quang",
						type: "Xã",
					},
					{
						level3_id: "01300",
						name: "Xã Vĩnh Quang",
						type: "Xã",
					},
					{
						level3_id: "01303",
						name: "Xã Quảng Lâm",
						type: "Xã",
					},
					{
						level3_id: "01304",
						name: "Xã Thạch Lâm",
						type: "Xã",
					},
					{
						level3_id: "01309",
						name: "Xã Vĩnh Phong",
						type: "Xã",
					},
					{
						level3_id: "01312",
						name: "Xã Mông Ân",
						type: "Xã",
					},
					{
						level3_id: "01315",
						name: "Xã Thái Học",
						type: "Xã",
					},
					{
						level3_id: "01316",
						name: "Xã Thái Sơn",
						type: "Xã",
					},
					{
						level3_id: "01318",
						name: "Xã Yên Thổ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "043",
				name: "Huyện Bảo Lạc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01321",
						name: "Thị trấn Bảo Lạc",
						type: "Thị trấn",
					},
					{
						level3_id: "01324",
						name: "Xã Cốc Pàng",
						type: "Xã",
					},
					{
						level3_id: "01327",
						name: "Xã Thượng Hà",
						type: "Xã",
					},
					{
						level3_id: "01330",
						name: "Xã Cô Ba",
						type: "Xã",
					},
					{
						level3_id: "01333",
						name: "Xã Bảo Toàn",
						type: "Xã",
					},
					{
						level3_id: "01336",
						name: "Xã Khánh Xuân",
						type: "Xã",
					},
					{
						level3_id: "01339",
						name: "Xã Xuân Trường",
						type: "Xã",
					},
					{
						level3_id: "01342",
						name: "Xã Hồng Trị",
						type: "Xã",
					},
					{
						level3_id: "01343",
						name: "Xã Kim Cúc",
						type: "Xã",
					},
					{
						level3_id: "01345",
						name: "Xã Phan Thanh",
						type: "Xã",
					},
					{
						level3_id: "01348",
						name: "Xã Hồng An",
						type: "Xã",
					},
					{
						level3_id: "01351",
						name: "Xã Hưng Đạo",
						type: "Xã",
					},
					{
						level3_id: "01352",
						name: "Xã Hưng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "01354",
						name: "Xã Huy Giáp",
						type: "Xã",
					},
					{
						level3_id: "01357",
						name: "Xã Đình Phùng",
						type: "Xã",
					},
					{
						level3_id: "01359",
						name: "Xã Sơn Lập",
						type: "Xã",
					},
					{
						level3_id: "01360",
						name: "Xã Sơn Lộ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "045",
				name: "Huyện Hà Quảng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01363",
						name: "Thị trấn Thông Nông",
						type: "Thị trấn",
					},
					{
						level3_id: "01366",
						name: "Xã Cần Yên",
						type: "Xã",
					},
					{
						level3_id: "01367",
						name: "Xã Cần Nông",
						type: "Xã",
					},
					{
						level3_id: "01372",
						name: "Xã Lương Thông",
						type: "Xã",
					},
					{
						level3_id: "01375",
						name: "Xã Đa Thông",
						type: "Xã",
					},
					{
						level3_id: "01378",
						name: "Xã Ngọc Động",
						type: "Xã",
					},
					{
						level3_id: "01381",
						name: "Xã Yên Sơn",
						type: "Xã",
					},
					{
						level3_id: "01384",
						name: "Xã Lương Can",
						type: "Xã",
					},
					{
						level3_id: "01387",
						name: "Xã Thanh Long",
						type: "Xã",
					},
					{
						level3_id: "01392",
						name: "Thị trấn Xuân Hòa",
						type: "Thị trấn",
					},
					{
						level3_id: "01393",
						name: "Xã Lũng Nặm",
						type: "Xã",
					},
					{
						level3_id: "01399",
						name: "Xã Trường Hà",
						type: "Xã",
					},
					{
						level3_id: "01402",
						name: "Xã Cải Viên",
						type: "Xã",
					},
					{
						level3_id: "01411",
						name: "Xã Nội Thôn",
						type: "Xã",
					},
					{
						level3_id: "01414",
						name: "Xã Tổng Cọt",
						type: "Xã",
					},
					{
						level3_id: "01417",
						name: "Xã Sóc Hà",
						type: "Xã",
					},
					{
						level3_id: "01420",
						name: "Xã Thượng Thôn",
						type: "Xã",
					},
					{
						level3_id: "01429",
						name: "Xã Hồng Sỹ",
						type: "Xã",
					},
					{
						level3_id: "01432",
						name: "Xã Quý Quân",
						type: "Xã",
					},
					{
						level3_id: "01435",
						name: "Xã Mã Ba",
						type: "Xã",
					},
					{
						level3_id: "01438",
						name: "Xã Ngọc Đào",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "047",
				name: "Huyện Trùng Khánh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01447",
						name: "Thị trấn Trà Lĩnh",
						type: "Thị trấn",
					},
					{
						level3_id: "01453",
						name: "Xã Tri Phương",
						type: "Xã",
					},
					{
						level3_id: "01456",
						name: "Xã Quang Hán",
						type: "Xã",
					},
					{
						level3_id: "01462",
						name: "Xã Xuân Nội",
						type: "Xã",
					},
					{
						level3_id: "01465",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "01468",
						name: "Xã Quang Vinh",
						type: "Xã",
					},
					{
						level3_id: "01471",
						name: "Xã Cao Chương",
						type: "Xã",
					},
					{
						level3_id: "01477",
						name: "Thị trấn Trùng Khánh",
						type: "Thị trấn",
					},
					{
						level3_id: "01480",
						name: "Xã Ngọc Khê",
						type: "Xã",
					},
					{
						level3_id: "01481",
						name: "Xã Ngọc Côn",
						type: "Xã",
					},
					{
						level3_id: "01483",
						name: "Xã Phong Nậm",
						type: "Xã",
					},
					{
						level3_id: "01489",
						name: "Xã Đình Phong",
						type: "Xã",
					},
					{
						level3_id: "01495",
						name: "Xã Đàm Thuỷ",
						type: "Xã",
					},
					{
						level3_id: "01498",
						name: "Xã Khâm Thành",
						type: "Xã",
					},
					{
						level3_id: "01501",
						name: "Xã Chí Viễn",
						type: "Xã",
					},
					{
						level3_id: "01504",
						name: "Xã Lăng Hiếu",
						type: "Xã",
					},
					{
						level3_id: "01507",
						name: "Xã Phong Châu",
						type: "Xã",
					},
					{
						level3_id: "01516",
						name: "Xã Trung Phúc",
						type: "Xã",
					},
					{
						level3_id: "01519",
						name: "Xã Cao Thăng",
						type: "Xã",
					},
					{
						level3_id: "01522",
						name: "Xã Đức Hồng",
						type: "Xã",
					},
					{
						level3_id: "01525",
						name: "Xã Đoài Dương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "048",
				name: "Huyện Hạ Lang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01534",
						name: "Xã Minh Long",
						type: "Xã",
					},
					{
						level3_id: "01537",
						name: "Xã Lý Quốc",
						type: "Xã",
					},
					{
						level3_id: "01540",
						name: "Xã Thắng Lợi",
						type: "Xã",
					},
					{
						level3_id: "01543",
						name: "Xã Đồng Loan",
						type: "Xã",
					},
					{
						level3_id: "01546",
						name: "Xã Đức Quang",
						type: "Xã",
					},
					{
						level3_id: "01549",
						name: "Xã Kim Loan",
						type: "Xã",
					},
					{
						level3_id: "01552",
						name: "Xã Quang Long",
						type: "Xã",
					},
					{
						level3_id: "01555",
						name: "Xã An Lạc",
						type: "Xã",
					},
					{
						level3_id: "01558",
						name: "Thị trấn Thanh Nhật",
						type: "Thị trấn",
					},
					{
						level3_id: "01561",
						name: "Xã Vinh Quý",
						type: "Xã",
					},
					{
						level3_id: "01564",
						name: "Xã Thống Nhất",
						type: "Xã",
					},
					{
						level3_id: "01567",
						name: "Xã Cô Ngân",
						type: "Xã",
					},
					{
						level3_id: "01573",
						name: "Xã Thị Hoa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "049",
				name: "Huyện Quảng Hòa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01474",
						name: "Xã Quốc Toản",
						type: "Xã",
					},
					{
						level3_id: "01576",
						name: "Thị trấn Quảng Uyên",
						type: "Thị trấn",
					},
					{
						level3_id: "01579",
						name: "Xã Phi Hải",
						type: "Xã",
					},
					{
						level3_id: "01582",
						name: "Xã Quảng Hưng",
						type: "Xã",
					},
					{
						level3_id: "01594",
						name: "Xã Độc Lập",
						type: "Xã",
					},
					{
						level3_id: "01597",
						name: "Xã Cai Bộ",
						type: "Xã",
					},
					{
						level3_id: "01603",
						name: "Xã Phúc Sen",
						type: "Xã",
					},
					{
						level3_id: "01606",
						name: "Xã Chí Thảo",
						type: "Xã",
					},
					{
						level3_id: "01609",
						name: "Xã Tự Do",
						type: "Xã",
					},
					{
						level3_id: "01615",
						name: "Xã Hồng Quang",
						type: "Xã",
					},
					{
						level3_id: "01618",
						name: "Xã Ngọc Động",
						type: "Xã",
					},
					{
						level3_id: "01624",
						name: "Xã Hạnh Phúc",
						type: "Xã",
					},
					{
						level3_id: "01627",
						name: "Thị trấn Tà Lùng",
						type: "Thị trấn",
					},
					{
						level3_id: "01630",
						name: "Xã Bế Văn Đàn",
						type: "Xã",
					},
					{
						level3_id: "01636",
						name: "Xã Cách Linh",
						type: "Xã",
					},
					{
						level3_id: "01639",
						name: "Xã Đại Sơn",
						type: "Xã",
					},
					{
						level3_id: "01645",
						name: "Xã Tiên Thành",
						type: "Xã",
					},
					{
						level3_id: "01648",
						name: "Thị trấn Hoà Thuận",
						type: "Thị trấn",
					},
					{
						level3_id: "01651",
						name: "Xã Mỹ Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "051",
				name: "Huyện Hoà An",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01654",
						name: "Thị trấn Nước Hai",
						type: "Thị trấn",
					},
					{
						level3_id: "01657",
						name: "Xã Dân Chủ",
						type: "Xã",
					},
					{
						level3_id: "01660",
						name: "Xã Nam Tuấn",
						type: "Xã",
					},
					{
						level3_id: "01666",
						name: "Xã Đại Tiến",
						type: "Xã",
					},
					{
						level3_id: "01669",
						name: "Xã Đức Long",
						type: "Xã",
					},
					{
						level3_id: "01672",
						name: "Xã Ngũ Lão",
						type: "Xã",
					},
					{
						level3_id: "01675",
						name: "Xã Trương Lương",
						type: "Xã",
					},
					{
						level3_id: "01687",
						name: "Xã Hồng Việt",
						type: "Xã",
					},
					{
						level3_id: "01696",
						name: "Xã Hoàng Tung",
						type: "Xã",
					},
					{
						level3_id: "01699",
						name: "Xã Nguyễn Huệ",
						type: "Xã",
					},
					{
						level3_id: "01702",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "01708",
						name: "Xã Bạch Đằng",
						type: "Xã",
					},
					{
						level3_id: "01711",
						name: "Xã Bình Dương",
						type: "Xã",
					},
					{
						level3_id: "01714",
						name: "Xã Lê Chung",
						type: "Xã",
					},
					{
						level3_id: "01723",
						name: "Xã Hồng Nam",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "052",
				name: "Huyện Nguyên Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01726",
						name: "Thị trấn Nguyên Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "01729",
						name: "Thị trấn Tĩnh Túc",
						type: "Thị trấn",
					},
					{
						level3_id: "01732",
						name: "Xã Yên Lạc",
						type: "Xã",
					},
					{
						level3_id: "01735",
						name: "Xã Triệu Nguyên",
						type: "Xã",
					},
					{
						level3_id: "01738",
						name: "Xã Ca Thành",
						type: "Xã",
					},
					{
						level3_id: "01744",
						name: "Xã Vũ Nông",
						type: "Xã",
					},
					{
						level3_id: "01747",
						name: "Xã Minh Tâm",
						type: "Xã",
					},
					{
						level3_id: "01750",
						name: "Xã Thể Dục",
						type: "Xã",
					},
					{
						level3_id: "01756",
						name: "Xã Mai Long",
						type: "Xã",
					},
					{
						level3_id: "01762",
						name: "Xã Vũ Minh",
						type: "Xã",
					},
					{
						level3_id: "01765",
						name: "Xã Hoa Thám",
						type: "Xã",
					},
					{
						level3_id: "01768",
						name: "Xã Phan Thanh",
						type: "Xã",
					},
					{
						level3_id: "01771",
						name: "Xã Quang Thành",
						type: "Xã",
					},
					{
						level3_id: "01774",
						name: "Xã Tam Kim",
						type: "Xã",
					},
					{
						level3_id: "01777",
						name: "Xã Thành Công",
						type: "Xã",
					},
					{
						level3_id: "01780",
						name: "Xã Thịnh Vượng",
						type: "Xã",
					},
					{
						level3_id: "01783",
						name: "Xã Hưng Đạo",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "053",
				name: "Huyện Thạch An",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01786",
						name: "Thị trấn Đông Khê",
						type: "Thị trấn",
					},
					{
						level3_id: "01789",
						name: "Xã Canh Tân",
						type: "Xã",
					},
					{
						level3_id: "01792",
						name: "Xã Kim Đồng",
						type: "Xã",
					},
					{
						level3_id: "01795",
						name: "Xã Minh Khai",
						type: "Xã",
					},
					{
						level3_id: "01801",
						name: "Xã Đức Thông",
						type: "Xã",
					},
					{
						level3_id: "01804",
						name: "Xã Thái Cường",
						type: "Xã",
					},
					{
						level3_id: "01807",
						name: "Xã Vân Trình",
						type: "Xã",
					},
					{
						level3_id: "01810",
						name: "Xã Thụy Hùng",
						type: "Xã",
					},
					{
						level3_id: "01813",
						name: "Xã Quang Trọng",
						type: "Xã",
					},
					{
						level3_id: "01816",
						name: "Xã Trọng Con",
						type: "Xã",
					},
					{
						level3_id: "01819",
						name: "Xã Lê Lai",
						type: "Xã",
					},
					{
						level3_id: "01822",
						name: "Xã Đức Long",
						type: "Xã",
					},
					{
						level3_id: "01828",
						name: "Xã Lê Lợi",
						type: "Xã",
					},
					{
						level3_id: "01831",
						name: "Xã Đức Xuân",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "06",
		name: "Tỉnh Bắc Kạn",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "058",
				name: "Thành Phố Bắc Kạn",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "01834",
						name: "Phường Nguyễn Thị Minh Khai",
						type: "Phường",
					},
					{
						level3_id: "01837",
						name: "Phường Sông Cầu",
						type: "Phường",
					},
					{
						level3_id: "01840",
						name: "Phường Đức Xuân",
						type: "Phường",
					},
					{
						level3_id: "01843",
						name: "Phường Phùng Chí Kiên",
						type: "Phường",
					},
					{
						level3_id: "01846",
						name: "Phường Huyền Tụng",
						type: "Phường",
					},
					{
						level3_id: "01849",
						name: "Xã Dương Quang",
						type: "Xã",
					},
					{
						level3_id: "01852",
						name: "Xã Nông Thượng",
						type: "Xã",
					},
					{
						level3_id: "01855",
						name: "Phường Xuất Hóa",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "060",
				name: "Huyện Pác Nặm",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01858",
						name: "Xã Bằng Thành",
						type: "Xã",
					},
					{
						level3_id: "01861",
						name: "Xã Nhạn Môn",
						type: "Xã",
					},
					{
						level3_id: "01864",
						name: "Xã Bộc Bố",
						type: "Xã",
					},
					{
						level3_id: "01867",
						name: "Xã Công Bằng",
						type: "Xã",
					},
					{
						level3_id: "01870",
						name: "Xã Giáo Hiệu",
						type: "Xã",
					},
					{
						level3_id: "01873",
						name: "Xã Xuân La",
						type: "Xã",
					},
					{
						level3_id: "01876",
						name: "Xã An Thắng",
						type: "Xã",
					},
					{
						level3_id: "01879",
						name: "Xã Cổ Linh",
						type: "Xã",
					},
					{
						level3_id: "01882",
						name: "Xã Nghiên Loan",
						type: "Xã",
					},
					{
						level3_id: "01885",
						name: "Xã Cao Tân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "061",
				name: "Huyện Ba Bể",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01888",
						name: "Thị trấn Chợ Rã",
						type: "Thị trấn",
					},
					{
						level3_id: "01891",
						name: "Xã Bành Trạch",
						type: "Xã",
					},
					{
						level3_id: "01894",
						name: "Xã Phúc Lộc",
						type: "Xã",
					},
					{
						level3_id: "01897",
						name: "Xã Hà Hiệu",
						type: "Xã",
					},
					{
						level3_id: "01900",
						name: "Xã Cao Thượng",
						type: "Xã",
					},
					{
						level3_id: "01906",
						name: "Xã Khang Ninh",
						type: "Xã",
					},
					{
						level3_id: "01909",
						name: "Xã Nam Mẫu",
						type: "Xã",
					},
					{
						level3_id: "01912",
						name: "Xã Thượng Giáo",
						type: "Xã",
					},
					{
						level3_id: "01915",
						name: "Xã Địa Linh",
						type: "Xã",
					},
					{
						level3_id: "01918",
						name: "Xã Yến Dương",
						type: "Xã",
					},
					{
						level3_id: "01921",
						name: "Xã Chu Hương",
						type: "Xã",
					},
					{
						level3_id: "01924",
						name: "Xã Quảng Khê",
						type: "Xã",
					},
					{
						level3_id: "01927",
						name: "Xã Mỹ Phương",
						type: "Xã",
					},
					{
						level3_id: "01930",
						name: "Xã Hoàng Trĩ",
						type: "Xã",
					},
					{
						level3_id: "01933",
						name: "Xã Đồng Phúc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "062",
				name: "Huyện Ngân Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01936",
						name: "Thị trấn Nà Phặc",
						type: "Thị trấn",
					},
					{
						level3_id: "01939",
						name: "Xã Thượng Ân",
						type: "Xã",
					},
					{
						level3_id: "01942",
						name: "Xã Bằng Vân",
						type: "Xã",
					},
					{
						level3_id: "01945",
						name: "Xã Cốc Đán",
						type: "Xã",
					},
					{
						level3_id: "01948",
						name: "Xã Trung Hoà",
						type: "Xã",
					},
					{
						level3_id: "01951",
						name: "Xã Đức Vân",
						type: "Xã",
					},
					{
						level3_id: "01954",
						name: "Xã Vân Tùng",
						type: "Xã",
					},
					{
						level3_id: "01957",
						name: "Xã Thượng Quan",
						type: "Xã",
					},
					{
						level3_id: "01960",
						name: "Xã Hiệp Lực",
						type: "Xã",
					},
					{
						level3_id: "01963",
						name: "Xã Thuần Mang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "063",
				name: "Huyện Bạch Thông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "01969",
						name: "Thị trấn Phủ Thông",
						type: "Thị trấn",
					},
					{
						level3_id: "01975",
						name: "Xã Vi Hương",
						type: "Xã",
					},
					{
						level3_id: "01978",
						name: "Xã Sĩ Bình",
						type: "Xã",
					},
					{
						level3_id: "01981",
						name: "Xã Vũ Muộn",
						type: "Xã",
					},
					{
						level3_id: "01984",
						name: "Xã Đôn Phong",
						type: "Xã",
					},
					{
						level3_id: "01990",
						name: "Xã Lục Bình",
						type: "Xã",
					},
					{
						level3_id: "01993",
						name: "Xã Tân Tú",
						type: "Xã",
					},
					{
						level3_id: "01999",
						name: "Xã Nguyên Phúc",
						type: "Xã",
					},
					{
						level3_id: "02002",
						name: "Xã Cao Sơn",
						type: "Xã",
					},
					{
						level3_id: "02005",
						name: "Xã Quân Hà",
						type: "Xã",
					},
					{
						level3_id: "02008",
						name: "Xã Cẩm Giàng",
						type: "Xã",
					},
					{
						level3_id: "02011",
						name: "Xã Mỹ Thanh",
						type: "Xã",
					},
					{
						level3_id: "02014",
						name: "Xã Dương Phong",
						type: "Xã",
					},
					{
						level3_id: "02017",
						name: "Xã Quang Thuận",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "064",
				name: "Huyện Chợ Đồn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02020",
						name: "Thị trấn Bằng Lũng",
						type: "Thị trấn",
					},
					{
						level3_id: "02023",
						name: "Xã Xuân Lạc",
						type: "Xã",
					},
					{
						level3_id: "02026",
						name: "Xã Nam Cường",
						type: "Xã",
					},
					{
						level3_id: "02029",
						name: "Xã Đồng Lạc",
						type: "Xã",
					},
					{
						level3_id: "02032",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "02035",
						name: "Xã Bản Thi",
						type: "Xã",
					},
					{
						level3_id: "02038",
						name: "Xã Quảng Bạch",
						type: "Xã",
					},
					{
						level3_id: "02041",
						name: "Xã Bằng Phúc",
						type: "Xã",
					},
					{
						level3_id: "02044",
						name: "Xã Yên Thịnh",
						type: "Xã",
					},
					{
						level3_id: "02047",
						name: "Xã Yên Thượng",
						type: "Xã",
					},
					{
						level3_id: "02050",
						name: "Xã Phương Viên",
						type: "Xã",
					},
					{
						level3_id: "02053",
						name: "Xã Ngọc Phái",
						type: "Xã",
					},
					{
						level3_id: "02059",
						name: "Xã Đồng Thắng",
						type: "Xã",
					},
					{
						level3_id: "02062",
						name: "Xã Lương Bằng",
						type: "Xã",
					},
					{
						level3_id: "02065",
						name: "Xã Bằng Lãng",
						type: "Xã",
					},
					{
						level3_id: "02068",
						name: "Xã Đại Sảo",
						type: "Xã",
					},
					{
						level3_id: "02071",
						name: "Xã Nghĩa Tá",
						type: "Xã",
					},
					{
						level3_id: "02077",
						name: "Xã Yên Mỹ",
						type: "Xã",
					},
					{
						level3_id: "02080",
						name: "Xã Bình Trung",
						type: "Xã",
					},
					{
						level3_id: "02083",
						name: "Xã Yên Phong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "065",
				name: "Huyện Chợ Mới",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02086",
						name: "Thị trấn Đồng Tâm",
						type: "Thị trấn",
					},
					{
						level3_id: "02089",
						name: "Xã Tân Sơn",
						type: "Xã",
					},
					{
						level3_id: "02092",
						name: "Xã Thanh Vận",
						type: "Xã",
					},
					{
						level3_id: "02095",
						name: "Xã Mai Lạp",
						type: "Xã",
					},
					{
						level3_id: "02098",
						name: "Xã Hoà Mục",
						type: "Xã",
					},
					{
						level3_id: "02101",
						name: "Xã Thanh Mai",
						type: "Xã",
					},
					{
						level3_id: "02104",
						name: "Xã Cao Kỳ",
						type: "Xã",
					},
					{
						level3_id: "02107",
						name: "Xã Nông Hạ",
						type: "Xã",
					},
					{
						level3_id: "02110",
						name: "Xã Yên Cư",
						type: "Xã",
					},
					{
						level3_id: "02113",
						name: "Xã Thanh Thịnh",
						type: "Xã",
					},
					{
						level3_id: "02116",
						name: "Xã Yên Hân",
						type: "Xã",
					},
					{
						level3_id: "02122",
						name: "Xã Như Cố",
						type: "Xã",
					},
					{
						level3_id: "02125",
						name: "Xã Bình Văn",
						type: "Xã",
					},
					{
						level3_id: "02131",
						name: "Xã Quảng Chu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "066",
				name: "Huyện Na Rì",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02137",
						name: "Xã Văn Vũ",
						type: "Xã",
					},
					{
						level3_id: "02140",
						name: "Xã Văn Lang",
						type: "Xã",
					},
					{
						level3_id: "02143",
						name: "Xã Lương Thượng",
						type: "Xã",
					},
					{
						level3_id: "02146",
						name: "Xã Kim Hỷ",
						type: "Xã",
					},
					{
						level3_id: "02152",
						name: "Xã Cường Lợi",
						type: "Xã",
					},
					{
						level3_id: "02155",
						name: "Thị trấn Yến Lạc",
						type: "Thị trấn",
					},
					{
						level3_id: "02158",
						name: "Xã Kim Lư",
						type: "Xã",
					},
					{
						level3_id: "02161",
						name: "Xã Sơn Thành",
						type: "Xã",
					},
					{
						level3_id: "02170",
						name: "Xã Văn Minh",
						type: "Xã",
					},
					{
						level3_id: "02173",
						name: "Xã Côn Minh",
						type: "Xã",
					},
					{
						level3_id: "02176",
						name: "Xã Cư Lễ",
						type: "Xã",
					},
					{
						level3_id: "02179",
						name: "Xã Trần Phú",
						type: "Xã",
					},
					{
						level3_id: "02185",
						name: "Xã Quang Phong",
						type: "Xã",
					},
					{
						level3_id: "02188",
						name: "Xã Dương Sơn",
						type: "Xã",
					},
					{
						level3_id: "02191",
						name: "Xã Xuân Dương",
						type: "Xã",
					},
					{
						level3_id: "02194",
						name: "Xã Đổng Xá",
						type: "Xã",
					},
					{
						level3_id: "02197",
						name: "Xã Liêm Thuỷ",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "08",
		name: "Tỉnh Tuyên Quang",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "070",
				name: "Thành phố Tuyên Quang",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "02200",
						name: "Phường Phan Thiết",
						type: "Phường",
					},
					{
						level3_id: "02203",
						name: "Phường Minh Xuân",
						type: "Phường",
					},
					{
						level3_id: "02206",
						name: "Phường Tân Quang",
						type: "Phường",
					},
					{
						level3_id: "02209",
						name: "Xã Tràng Đà",
						type: "Xã",
					},
					{
						level3_id: "02212",
						name: "Phường Nông Tiến",
						type: "Phường",
					},
					{
						level3_id: "02215",
						name: "Phường Ỷ La",
						type: "Phường",
					},
					{
						level3_id: "02216",
						name: "Phường Tân Hà",
						type: "Phường",
					},
					{
						level3_id: "02218",
						name: "Phường Hưng Thành",
						type: "Phường",
					},
					{
						level3_id: "02497",
						name: "Xã Kim Phú",
						type: "Xã",
					},
					{
						level3_id: "02503",
						name: "Xã An Khang",
						type: "Xã",
					},
					{
						level3_id: "02509",
						name: "Phường Mỹ Lâm",
						type: "Phường",
					},
					{
						level3_id: "02512",
						name: "Phường An Tường",
						type: "Phường",
					},
					{
						level3_id: "02515",
						name: "Xã Lưỡng Vượng",
						type: "Xã",
					},
					{
						level3_id: "02521",
						name: "Xã Thái Long",
						type: "Xã",
					},
					{
						level3_id: "02524",
						name: "Phường Đội Cấn",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "071",
				name: "Huyện Lâm Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02233",
						name: "Xã Phúc Yên",
						type: "Xã",
					},
					{
						level3_id: "02242",
						name: "Xã Xuân Lập",
						type: "Xã",
					},
					{
						level3_id: "02251",
						name: "Xã Khuôn Hà",
						type: "Xã",
					},
					{
						level3_id: "02266",
						name: "Thị trấn Lăng Can",
						type: "Thị trấn",
					},
					{
						level3_id: "02269",
						name: "Xã Thượng Lâm",
						type: "Xã",
					},
					{
						level3_id: "02290",
						name: "Xã Bình An",
						type: "Xã",
					},
					{
						level3_id: "02293",
						name: "Xã Hồng Quang",
						type: "Xã",
					},
					{
						level3_id: "02296",
						name: "Xã Thổ Bình",
						type: "Xã",
					},
					{
						level3_id: "02299",
						name: "Xã Phúc Sơn",
						type: "Xã",
					},
					{
						level3_id: "02302",
						name: "Xã Minh Quang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "072",
				name: "Huyện Na Hang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02221",
						name: "Thị trấn Na Hang",
						type: "Thị trấn",
					},
					{
						level3_id: "02227",
						name: "Xã Sinh Long",
						type: "Xã",
					},
					{
						level3_id: "02230",
						name: "Xã Thượng Giáp",
						type: "Xã",
					},
					{
						level3_id: "02239",
						name: "Xã Thượng Nông",
						type: "Xã",
					},
					{
						level3_id: "02245",
						name: "Xã Côn Lôn",
						type: "Xã",
					},
					{
						level3_id: "02248",
						name: "Xã Yên Hoa",
						type: "Xã",
					},
					{
						level3_id: "02254",
						name: "Xã Hồng Thái",
						type: "Xã",
					},
					{
						level3_id: "02260",
						name: "Xã Đà Vị",
						type: "Xã",
					},
					{
						level3_id: "02263",
						name: "Xã Khau Tinh",
						type: "Xã",
					},
					{
						level3_id: "02275",
						name: "Xã Sơn Phú",
						type: "Xã",
					},
					{
						level3_id: "02281",
						name: "Xã Năng Khả",
						type: "Xã",
					},
					{
						level3_id: "02284",
						name: "Xã Thanh Tương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "073",
				name: "Huyện Chiêm Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02287",
						name: "Thị trấn Vĩnh Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "02305",
						name: "Xã Trung Hà",
						type: "Xã",
					},
					{
						level3_id: "02308",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "02311",
						name: "Xã Hà Lang",
						type: "Xã",
					},
					{
						level3_id: "02314",
						name: "Xã Hùng Mỹ",
						type: "Xã",
					},
					{
						level3_id: "02317",
						name: "Xã Yên Lập",
						type: "Xã",
					},
					{
						level3_id: "02320",
						name: "Xã Tân An",
						type: "Xã",
					},
					{
						level3_id: "02323",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "02326",
						name: "Xã Xuân Quang",
						type: "Xã",
					},
					{
						level3_id: "02329",
						name: "Xã Ngọc Hội",
						type: "Xã",
					},
					{
						level3_id: "02332",
						name: "Xã Phú Bình",
						type: "Xã",
					},
					{
						level3_id: "02335",
						name: "Xã Hòa Phú",
						type: "Xã",
					},
					{
						level3_id: "02338",
						name: "Xã Phúc Thịnh",
						type: "Xã",
					},
					{
						level3_id: "02341",
						name: "Xã Kiên Đài",
						type: "Xã",
					},
					{
						level3_id: "02344",
						name: "Xã Tân Thịnh",
						type: "Xã",
					},
					{
						level3_id: "02347",
						name: "Xã Trung Hòa",
						type: "Xã",
					},
					{
						level3_id: "02350",
						name: "Xã Kim Bình",
						type: "Xã",
					},
					{
						level3_id: "02353",
						name: "Xã Hòa An",
						type: "Xã",
					},
					{
						level3_id: "02356",
						name: "Xã Vinh Quang",
						type: "Xã",
					},
					{
						level3_id: "02359",
						name: "Xã Tri Phú",
						type: "Xã",
					},
					{
						level3_id: "02362",
						name: "Xã Nhân Lý",
						type: "Xã",
					},
					{
						level3_id: "02365",
						name: "Xã Yên Nguyên",
						type: "Xã",
					},
					{
						level3_id: "02368",
						name: "Xã Linh Phú",
						type: "Xã",
					},
					{
						level3_id: "02371",
						name: "Xã Bình Nhân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "074",
				name: "Huyện Hàm Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02374",
						name: "Thị trấn Tân Yên",
						type: "Thị trấn",
					},
					{
						level3_id: "02377",
						name: "Xã Yên Thuận",
						type: "Xã",
					},
					{
						level3_id: "02380",
						name: "Xã Bạch Xa",
						type: "Xã",
					},
					{
						level3_id: "02383",
						name: "Xã Minh Khương",
						type: "Xã",
					},
					{
						level3_id: "02386",
						name: "Xã Yên Lâm",
						type: "Xã",
					},
					{
						level3_id: "02389",
						name: "Xã Minh Dân",
						type: "Xã",
					},
					{
						level3_id: "02392",
						name: "Xã Phù Lưu",
						type: "Xã",
					},
					{
						level3_id: "02395",
						name: "Xã Minh Hương",
						type: "Xã",
					},
					{
						level3_id: "02398",
						name: "Xã Yên Phú",
						type: "Xã",
					},
					{
						level3_id: "02401",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "02404",
						name: "Xã Bình Xa",
						type: "Xã",
					},
					{
						level3_id: "02407",
						name: "Xã Thái Sơn",
						type: "Xã",
					},
					{
						level3_id: "02410",
						name: "Xã Nhân Mục",
						type: "Xã",
					},
					{
						level3_id: "02413",
						name: "Xã Thành Long",
						type: "Xã",
					},
					{
						level3_id: "02416",
						name: "Xã Bằng Cốc",
						type: "Xã",
					},
					{
						level3_id: "02419",
						name: "Xã Thái Hòa",
						type: "Xã",
					},
					{
						level3_id: "02422",
						name: "Xã Đức Ninh",
						type: "Xã",
					},
					{
						level3_id: "02425",
						name: "Xã Hùng Đức",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "075",
				name: "Huyện Yên Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02431",
						name: "Xã Quí Quân",
						type: "Xã",
					},
					{
						level3_id: "02434",
						name: "Xã Lực Hành",
						type: "Xã",
					},
					{
						level3_id: "02437",
						name: "Xã Kiến Thiết",
						type: "Xã",
					},
					{
						level3_id: "02440",
						name: "Xã Trung Minh",
						type: "Xã",
					},
					{
						level3_id: "02443",
						name: "Xã Chiêu Yên",
						type: "Xã",
					},
					{
						level3_id: "02446",
						name: "Xã Trung Trực",
						type: "Xã",
					},
					{
						level3_id: "02449",
						name: "Xã Xuân Vân",
						type: "Xã",
					},
					{
						level3_id: "02452",
						name: "Xã Phúc Ninh",
						type: "Xã",
					},
					{
						level3_id: "02455",
						name: "Xã Hùng Lợi",
						type: "Xã",
					},
					{
						level3_id: "02458",
						name: "Xã Trung Sơn",
						type: "Xã",
					},
					{
						level3_id: "02461",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "02464",
						name: "Xã Tứ Quận",
						type: "Xã",
					},
					{
						level3_id: "02467",
						name: "Xã Đạo Viện",
						type: "Xã",
					},
					{
						level3_id: "02470",
						name: "Xã Tân Long",
						type: "Xã",
					},
					{
						level3_id: "02473",
						name: "Thị trấn Yên Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "02476",
						name: "Xã Kim Quan",
						type: "Xã",
					},
					{
						level3_id: "02479",
						name: "Xã Lang Quán",
						type: "Xã",
					},
					{
						level3_id: "02482",
						name: "Xã Phú Thịnh",
						type: "Xã",
					},
					{
						level3_id: "02485",
						name: "Xã Công Đa",
						type: "Xã",
					},
					{
						level3_id: "02488",
						name: "Xã Trung Môn",
						type: "Xã",
					},
					{
						level3_id: "02491",
						name: "Xã Chân Sơn",
						type: "Xã",
					},
					{
						level3_id: "02494",
						name: "Xã Thái Bình",
						type: "Xã",
					},
					{
						level3_id: "02500",
						name: "Xã Tiến Bộ",
						type: "Xã",
					},
					{
						level3_id: "02506",
						name: "Xã Mỹ Bằng",
						type: "Xã",
					},
					{
						level3_id: "02518",
						name: "Xã Hoàng Khai",
						type: "Xã",
					},
					{
						level3_id: "02527",
						name: "Xã Nhữ Hán",
						type: "Xã",
					},
					{
						level3_id: "02530",
						name: "Xã Nhữ Khê",
						type: "Xã",
					},
					{
						level3_id: "02533",
						name: "Xã Đội Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "076",
				name: "Huyện Sơn Dương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02536",
						name: "Thị trấn Sơn Dương",
						type: "Thị trấn",
					},
					{
						level3_id: "02539",
						name: "Xã Trung Yên",
						type: "Xã",
					},
					{
						level3_id: "02542",
						name: "Xã Minh Thanh",
						type: "Xã",
					},
					{
						level3_id: "02545",
						name: "Xã Tân Trào",
						type: "Xã",
					},
					{
						level3_id: "02548",
						name: "Xã Vĩnh Lợi",
						type: "Xã",
					},
					{
						level3_id: "02551",
						name: "Xã Thượng Ấm",
						type: "Xã",
					},
					{
						level3_id: "02554",
						name: "Xã Bình Yên",
						type: "Xã",
					},
					{
						level3_id: "02557",
						name: "Xã Lương Thiện",
						type: "Xã",
					},
					{
						level3_id: "02560",
						name: "Xã Tú Thịnh",
						type: "Xã",
					},
					{
						level3_id: "02563",
						name: "Xã Cấp Tiến",
						type: "Xã",
					},
					{
						level3_id: "02566",
						name: "Xã Hợp Thành",
						type: "Xã",
					},
					{
						level3_id: "02569",
						name: "Xã Phúc Ứng",
						type: "Xã",
					},
					{
						level3_id: "02572",
						name: "Xã Đông Thọ",
						type: "Xã",
					},
					{
						level3_id: "02575",
						name: "Xã Kháng Nhật",
						type: "Xã",
					},
					{
						level3_id: "02578",
						name: "Xã Hợp Hòa",
						type: "Xã",
					},
					{
						level3_id: "02584",
						name: "Xã Quyết Thắng",
						type: "Xã",
					},
					{
						level3_id: "02587",
						name: "Xã Đồng Quý",
						type: "Xã",
					},
					{
						level3_id: "02590",
						name: "Xã Tân Thanh",
						type: "Xã",
					},
					{
						level3_id: "02593",
						name: "Xã Vân Sơn",
						type: "Xã",
					},
					{
						level3_id: "02596",
						name: "Xã Văn Phú",
						type: "Xã",
					},
					{
						level3_id: "02599",
						name: "Xã Chi Thiết",
						type: "Xã",
					},
					{
						level3_id: "02602",
						name: "Xã Đông Lợi",
						type: "Xã",
					},
					{
						level3_id: "02605",
						name: "Xã Thiện Kế",
						type: "Xã",
					},
					{
						level3_id: "02608",
						name: "Xã Hồng Lạc",
						type: "Xã",
					},
					{
						level3_id: "02611",
						name: "Xã Phú Lương",
						type: "Xã",
					},
					{
						level3_id: "02614",
						name: "Xã Ninh Lai",
						type: "Xã",
					},
					{
						level3_id: "02617",
						name: "Xã Đại Phú",
						type: "Xã",
					},
					{
						level3_id: "02620",
						name: "Xã Sơn Nam",
						type: "Xã",
					},
					{
						level3_id: "02623",
						name: "Xã Hào Phú",
						type: "Xã",
					},
					{
						level3_id: "02626",
						name: "Xã Tam Đa",
						type: "Xã",
					},
					{
						level3_id: "02632",
						name: "Xã Trường Sinh",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "10",
		name: "Tỉnh Lào Cai",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "080",
				name: "Thành phố Lào Cai",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "02635",
						name: "Phường Duyên Hải",
						type: "Phường",
					},
					{
						level3_id: "02641",
						name: "Phường Lào Cai",
						type: "Phường",
					},
					{
						level3_id: "02644",
						name: "Phường Cốc Lếu",
						type: "Phường",
					},
					{
						level3_id: "02647",
						name: "Phường Kim Tân",
						type: "Phường",
					},
					{
						level3_id: "02650",
						name: "Phường Bắc Lệnh",
						type: "Phường",
					},
					{
						level3_id: "02653",
						name: "Phường Pom Hán",
						type: "Phường",
					},
					{
						level3_id: "02656",
						name: "Phường Xuân Tăng",
						type: "Phường",
					},
					{
						level3_id: "02658",
						name: "Phường Bình Minh",
						type: "Phường",
					},
					{
						level3_id: "02659",
						name: "Xã Thống Nhất",
						type: "Xã",
					},
					{
						level3_id: "02662",
						name: "Xã Đồng Tuyển",
						type: "Xã",
					},
					{
						level3_id: "02665",
						name: "Xã Vạn Hoà",
						type: "Xã",
					},
					{
						level3_id: "02668",
						name: "Phường Bắc Cường",
						type: "Phường",
					},
					{
						level3_id: "02671",
						name: "Phường Nam Cường",
						type: "Phường",
					},
					{
						level3_id: "02674",
						name: "Xã Cam Đường",
						type: "Xã",
					},
					{
						level3_id: "02677",
						name: "Xã Tả Phời",
						type: "Xã",
					},
					{
						level3_id: "02680",
						name: "Xã Hợp Thành",
						type: "Xã",
					},
					{
						level3_id: "02746",
						name: "Xã Cốc San",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "082",
				name: "Huyện Bát Xát",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02683",
						name: "Thị trấn Bát Xát",
						type: "Thị trấn",
					},
					{
						level3_id: "02686",
						name: "Xã A Mú Sung",
						type: "Xã",
					},
					{
						level3_id: "02689",
						name: "Xã Nậm Chạc",
						type: "Xã",
					},
					{
						level3_id: "02692",
						name: "Xã A Lù",
						type: "Xã",
					},
					{
						level3_id: "02695",
						name: "Xã Trịnh Tường",
						type: "Xã",
					},
					{
						level3_id: "02701",
						name: "Xã Y Tý",
						type: "Xã",
					},
					{
						level3_id: "02704",
						name: "Xã Cốc Mỳ",
						type: "Xã",
					},
					{
						level3_id: "02707",
						name: "Xã Dền Sáng",
						type: "Xã",
					},
					{
						level3_id: "02710",
						name: "Xã Bản Vược",
						type: "Xã",
					},
					{
						level3_id: "02713",
						name: "Xã Sàng Ma Sáo",
						type: "Xã",
					},
					{
						level3_id: "02716",
						name: "Xã Bản Qua",
						type: "Xã",
					},
					{
						level3_id: "02719",
						name: "Xã Mường Vi",
						type: "Xã",
					},
					{
						level3_id: "02722",
						name: "Xã Dền Thàng",
						type: "Xã",
					},
					{
						level3_id: "02725",
						name: "Xã Bản Xèo",
						type: "Xã",
					},
					{
						level3_id: "02728",
						name: "Xã Mường Hum",
						type: "Xã",
					},
					{
						level3_id: "02731",
						name: "Xã Trung Lèng Hồ",
						type: "Xã",
					},
					{
						level3_id: "02734",
						name: "Xã Quang Kim",
						type: "Xã",
					},
					{
						level3_id: "02737",
						name: "Xã Pa Cheo",
						type: "Xã",
					},
					{
						level3_id: "02740",
						name: "Xã Nậm Pung",
						type: "Xã",
					},
					{
						level3_id: "02743",
						name: "Xã Phìn Ngan",
						type: "Xã",
					},
					{
						level3_id: "02749",
						name: "Xã Tòng Sành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "083",
				name: "Huyện Mường Khương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02752",
						name: "Xã Pha Long",
						type: "Xã",
					},
					{
						level3_id: "02755",
						name: "Xã Tả Ngải Chồ",
						type: "Xã",
					},
					{
						level3_id: "02758",
						name: "Xã Tung Chung Phố",
						type: "Xã",
					},
					{
						level3_id: "02761",
						name: "Thị trấn Mường Khương",
						type: "Thị trấn",
					},
					{
						level3_id: "02764",
						name: "Xã Dìn Chin",
						type: "Xã",
					},
					{
						level3_id: "02767",
						name: "Xã Tả Gia Khâu",
						type: "Xã",
					},
					{
						level3_id: "02770",
						name: "Xã Nậm Chảy",
						type: "Xã",
					},
					{
						level3_id: "02773",
						name: "Xã Nấm Lư",
						type: "Xã",
					},
					{
						level3_id: "02776",
						name: "Xã Lùng Khấu Nhin",
						type: "Xã",
					},
					{
						level3_id: "02779",
						name: "Xã Thanh Bình",
						type: "Xã",
					},
					{
						level3_id: "02782",
						name: "Xã Cao Sơn",
						type: "Xã",
					},
					{
						level3_id: "02785",
						name: "Xã Lùng Vai",
						type: "Xã",
					},
					{
						level3_id: "02788",
						name: "Xã Bản Lầu",
						type: "Xã",
					},
					{
						level3_id: "02791",
						name: "Xã La Pan Tẩn",
						type: "Xã",
					},
					{
						level3_id: "02794",
						name: "Xã Tả Thàng",
						type: "Xã",
					},
					{
						level3_id: "02797",
						name: "Xã Bản Sen",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "084",
				name: "Huyện Si Ma Cai",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02800",
						name: "Xã Nàn Sán",
						type: "Xã",
					},
					{
						level3_id: "02803",
						name: "Xã Thào Chư Phìn",
						type: "Xã",
					},
					{
						level3_id: "02806",
						name: "Xã Bản Mế",
						type: "Xã",
					},
					{
						level3_id: "02809",
						name: "Thị trấn Si Ma Cai",
						type: "Thị trấn",
					},
					{
						level3_id: "02812",
						name: "Xã Sán Chải",
						type: "Xã",
					},
					{
						level3_id: "02818",
						name: "Xã Lùng Thẩn",
						type: "Xã",
					},
					{
						level3_id: "02821",
						name: "Xã Cán Cấu",
						type: "Xã",
					},
					{
						level3_id: "02824",
						name: "Xã Sín Chéng",
						type: "Xã",
					},
					{
						level3_id: "02827",
						name: "Xã Quan Hồ Thẩn",
						type: "Xã",
					},
					{
						level3_id: "02836",
						name: "Xã Nàn Xín",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "085",
				name: "Huyện Bắc Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02839",
						name: "Thị trấn Bắc Hà",
						type: "Thị trấn",
					},
					{
						level3_id: "02842",
						name: "Xã Lùng Cải",
						type: "Xã",
					},
					{
						level3_id: "02848",
						name: "Xã Lùng Phình",
						type: "Xã",
					},
					{
						level3_id: "02851",
						name: "Xã Tả Van Chư",
						type: "Xã",
					},
					{
						level3_id: "02854",
						name: "Xã Tả Củ Tỷ",
						type: "Xã",
					},
					{
						level3_id: "02857",
						name: "Xã Thải Giàng Phố",
						type: "Xã",
					},
					{
						level3_id: "02863",
						name: "Xã Hoàng Thu Phố",
						type: "Xã",
					},
					{
						level3_id: "02866",
						name: "Xã Bản Phố",
						type: "Xã",
					},
					{
						level3_id: "02869",
						name: "Xã Bản Liền",
						type: "Xã",
					},
					{
						level3_id: "02872",
						name: "Xã Tà Chải",
						type: "Xã",
					},
					{
						level3_id: "02875",
						name: "Xã Na Hối",
						type: "Xã",
					},
					{
						level3_id: "02878",
						name: "Xã Cốc Ly",
						type: "Xã",
					},
					{
						level3_id: "02881",
						name: "Xã Nậm Mòn",
						type: "Xã",
					},
					{
						level3_id: "02884",
						name: "Xã Nậm Đét",
						type: "Xã",
					},
					{
						level3_id: "02887",
						name: "Xã Nậm Khánh",
						type: "Xã",
					},
					{
						level3_id: "02890",
						name: "Xã Bảo Nhai",
						type: "Xã",
					},
					{
						level3_id: "02893",
						name: "Xã Nậm Lúc",
						type: "Xã",
					},
					{
						level3_id: "02896",
						name: "Xã Cốc Lầu",
						type: "Xã",
					},
					{
						level3_id: "02899",
						name: "Xã Bản Cái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "086",
				name: "Huyện Bảo Thắng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02902",
						name: "Thị trấn N.T Phong Hải",
						type: "Thị trấn",
					},
					{
						level3_id: "02905",
						name: "Thị trấn Phố Lu",
						type: "Thị trấn",
					},
					{
						level3_id: "02908",
						name: "Thị trấn Tằng Loỏng",
						type: "Thị trấn",
					},
					{
						level3_id: "02911",
						name: "Xã Bản Phiệt",
						type: "Xã",
					},
					{
						level3_id: "02914",
						name: "Xã Bản Cầm",
						type: "Xã",
					},
					{
						level3_id: "02917",
						name: "Xã Thái Niên",
						type: "Xã",
					},
					{
						level3_id: "02920",
						name: "Xã Phong Niên",
						type: "Xã",
					},
					{
						level3_id: "02923",
						name: "Xã Gia Phú",
						type: "Xã",
					},
					{
						level3_id: "02926",
						name: "Xã Xuân Quang",
						type: "Xã",
					},
					{
						level3_id: "02929",
						name: "Xã Sơn Hải",
						type: "Xã",
					},
					{
						level3_id: "02932",
						name: "Xã Xuân Giao",
						type: "Xã",
					},
					{
						level3_id: "02935",
						name: "Xã Trì Quang",
						type: "Xã",
					},
					{
						level3_id: "02938",
						name: "Xã Sơn Hà",
						type: "Xã",
					},
					{
						level3_id: "02944",
						name: "Xã Phú Nhuận",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "087",
				name: "Huyện Bảo Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "02947",
						name: "Thị trấn Phố Ràng",
						type: "Thị trấn",
					},
					{
						level3_id: "02950",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "02953",
						name: "Xã Nghĩa Đô",
						type: "Xã",
					},
					{
						level3_id: "02956",
						name: "Xã Vĩnh Yên",
						type: "Xã",
					},
					{
						level3_id: "02959",
						name: "Xã Điện Quan",
						type: "Xã",
					},
					{
						level3_id: "02962",
						name: "Xã Xuân Hoà",
						type: "Xã",
					},
					{
						level3_id: "02965",
						name: "Xã Tân Dương",
						type: "Xã",
					},
					{
						level3_id: "02968",
						name: "Xã Thượng Hà",
						type: "Xã",
					},
					{
						level3_id: "02971",
						name: "Xã Kim Sơn",
						type: "Xã",
					},
					{
						level3_id: "02974",
						name: "Xã Cam Cọn",
						type: "Xã",
					},
					{
						level3_id: "02977",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "02980",
						name: "Xã Xuân Thượng",
						type: "Xã",
					},
					{
						level3_id: "02983",
						name: "Xã Việt Tiến",
						type: "Xã",
					},
					{
						level3_id: "02986",
						name: "Xã Yên Sơn",
						type: "Xã",
					},
					{
						level3_id: "02989",
						name: "Xã Bảo Hà",
						type: "Xã",
					},
					{
						level3_id: "02992",
						name: "Xã Lương Sơn",
						type: "Xã",
					},
					{
						level3_id: "02998",
						name: "Xã Phúc Khánh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "088",
				name: "Thị xã Sa Pa",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "03001",
						name: "Phường Sa Pa",
						type: "Phường",
					},
					{
						level3_id: "03002",
						name: "Phường Sa Pả",
						type: "Phường",
					},
					{
						level3_id: "03003",
						name: "Phường Ô Quý Hồ",
						type: "Phường",
					},
					{
						level3_id: "03004",
						name: "Xã Ngũ Chỉ Sơn",
						type: "Xã",
					},
					{
						level3_id: "03006",
						name: "Phường Phan Si Păng",
						type: "Phường",
					},
					{
						level3_id: "03010",
						name: "Xã Trung Chải",
						type: "Xã",
					},
					{
						level3_id: "03013",
						name: "Xã Tả Phìn",
						type: "Xã",
					},
					{
						level3_id: "03016",
						name: "Phường Hàm Rồng",
						type: "Phường",
					},
					{
						level3_id: "03019",
						name: "Xã Hoàng Liên",
						type: "Xã",
					},
					{
						level3_id: "03022",
						name: "Xã Thanh Bình",
						type: "Xã",
					},
					{
						level3_id: "03028",
						name: "Phường Cầu Mây",
						type: "Phường",
					},
					{
						level3_id: "03037",
						name: "Xã Mường Hoa",
						type: "Xã",
					},
					{
						level3_id: "03040",
						name: "Xã Tả Van",
						type: "Xã",
					},
					{
						level3_id: "03043",
						name: "Xã Mường Bo",
						type: "Xã",
					},
					{
						level3_id: "03046",
						name: "Xã Bản Hồ",
						type: "Xã",
					},
					{
						level3_id: "03052",
						name: "Xã Liên Minh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "089",
				name: "Huyện Văn Bàn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03055",
						name: "Thị trấn Khánh Yên",
						type: "Thị trấn",
					},
					{
						level3_id: "03061",
						name: "Xã Võ Lao",
						type: "Xã",
					},
					{
						level3_id: "03064",
						name: "Xã Sơn Thuỷ",
						type: "Xã",
					},
					{
						level3_id: "03067",
						name: "Xã Nậm Mả",
						type: "Xã",
					},
					{
						level3_id: "03070",
						name: "Xã Tân Thượng",
						type: "Xã",
					},
					{
						level3_id: "03073",
						name: "Xã Nậm Rạng",
						type: "Xã",
					},
					{
						level3_id: "03076",
						name: "Xã Nậm Chầy",
						type: "Xã",
					},
					{
						level3_id: "03079",
						name: "Xã Tân An",
						type: "Xã",
					},
					{
						level3_id: "03082",
						name: "Xã Khánh Yên Thượng",
						type: "Xã",
					},
					{
						level3_id: "03085",
						name: "Xã Nậm Xé",
						type: "Xã",
					},
					{
						level3_id: "03088",
						name: "Xã Dần Thàng",
						type: "Xã",
					},
					{
						level3_id: "03091",
						name: "Xã Chiềng Ken",
						type: "Xã",
					},
					{
						level3_id: "03094",
						name: "Xã Làng Giàng",
						type: "Xã",
					},
					{
						level3_id: "03097",
						name: "Xã Hoà Mạc",
						type: "Xã",
					},
					{
						level3_id: "03100",
						name: "Xã Khánh Yên Trung",
						type: "Xã",
					},
					{
						level3_id: "03103",
						name: "Xã Khánh Yên Hạ",
						type: "Xã",
					},
					{
						level3_id: "03106",
						name: "Xã Dương Quỳ",
						type: "Xã",
					},
					{
						level3_id: "03109",
						name: "Xã Nậm Tha",
						type: "Xã",
					},
					{
						level3_id: "03112",
						name: "Xã Minh Lương",
						type: "Xã",
					},
					{
						level3_id: "03115",
						name: "Xã Thẩm Dương",
						type: "Xã",
					},
					{
						level3_id: "03118",
						name: "Xã Liêm Phú",
						type: "Xã",
					},
					{
						level3_id: "03121",
						name: "Xã Nậm Xây",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "11",
		name: "Tỉnh Điện Biên",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "094",
				name: "Thành phố Điện Biên Phủ",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "03124",
						name: "Phường Noong Bua",
						type: "Phường",
					},
					{
						level3_id: "03127",
						name: "Phường Him Lam",
						type: "Phường",
					},
					{
						level3_id: "03130",
						name: "Phường Thanh Bình",
						type: "Phường",
					},
					{
						level3_id: "03133",
						name: "Phường Tân Thanh",
						type: "Phường",
					},
					{
						level3_id: "03136",
						name: "Phường Mường Thanh",
						type: "Phường",
					},
					{
						level3_id: "03139",
						name: "Phường Nam Thanh",
						type: "Phường",
					},
					{
						level3_id: "03142",
						name: "Phường Thanh Trường",
						type: "Phường",
					},
					{
						level3_id: "03145",
						name: "Xã Thanh Minh",
						type: "Xã",
					},
					{
						level3_id: "03316",
						name: "Xã Nà Tấu",
						type: "Xã",
					},
					{
						level3_id: "03317",
						name: "Xã Nà Nhạn",
						type: "Xã",
					},
					{
						level3_id: "03325",
						name: "Xã Mường Phăng",
						type: "Xã",
					},
					{
						level3_id: "03326",
						name: "Xã Pá Khoang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "095",
				name: "Thị Xã Mường Lay",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "03148",
						name: "Phường Sông Đà",
						type: "Phường",
					},
					{
						level3_id: "03151",
						name: "Phường Na Lay",
						type: "Phường",
					},
					{
						level3_id: "03184",
						name: "Xã Lay Nưa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "096",
				name: "Huyện Mường Nhé",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03154",
						name: "Xã Sín Thầu",
						type: "Xã",
					},
					{
						level3_id: "03155",
						name: "Xã Sen Thượng",
						type: "Xã",
					},
					{
						level3_id: "03157",
						name: "Xã Chung Chải",
						type: "Xã",
					},
					{
						level3_id: "03158",
						name: "Xã Leng Su Sìn",
						type: "Xã",
					},
					{
						level3_id: "03159",
						name: "Xã Pá Mỳ",
						type: "Xã",
					},
					{
						level3_id: "03160",
						name: "Xã Mường Nhé",
						type: "Xã",
					},
					{
						level3_id: "03161",
						name: "Xã Nậm Vì",
						type: "Xã",
					},
					{
						level3_id: "03162",
						name: "Xã Nậm Kè",
						type: "Xã",
					},
					{
						level3_id: "03163",
						name: "Xã Mường Toong",
						type: "Xã",
					},
					{
						level3_id: "03164",
						name: "Xã Quảng Lâm",
						type: "Xã",
					},
					{
						level3_id: "03177",
						name: "Xã Huổi Lếnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "097",
				name: "Huyện Mường Chà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03172",
						name: "Thị Trấn Mường Chà",
						type: "Thị trấn",
					},
					{
						level3_id: "03178",
						name: "Xã Xá Tổng",
						type: "Xã",
					},
					{
						level3_id: "03181",
						name: "Xã Mường Tùng",
						type: "Xã",
					},
					{
						level3_id: "03190",
						name: "Xã Hừa Ngài",
						type: "Xã",
					},
					{
						level3_id: "03191",
						name: "Xã Huổi Mí",
						type: "Xã",
					},
					{
						level3_id: "03193",
						name: "Xã Pa Ham",
						type: "Xã",
					},
					{
						level3_id: "03194",
						name: "Xã Nậm Nèn",
						type: "Xã",
					},
					{
						level3_id: "03196",
						name: "Xã Huổi Lèng",
						type: "Xã",
					},
					{
						level3_id: "03197",
						name: "Xã Sa Lông",
						type: "Xã",
					},
					{
						level3_id: "03200",
						name: "Xã Ma Thì Hồ",
						type: "Xã",
					},
					{
						level3_id: "03201",
						name: "Xã Na Sang",
						type: "Xã",
					},
					{
						level3_id: "03202",
						name: "Xã Mường Mươn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "098",
				name: "Huyện Tủa Chùa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03217",
						name: "Thị trấn Tủa Chùa",
						type: "Thị trấn",
					},
					{
						level3_id: "03220",
						name: "Xã Huổi Só",
						type: "Xã",
					},
					{
						level3_id: "03223",
						name: "Xã Xín Chải",
						type: "Xã",
					},
					{
						level3_id: "03226",
						name: "Xã Tả Sìn Thàng",
						type: "Xã",
					},
					{
						level3_id: "03229",
						name: "Xã Lao Xả Phình",
						type: "Xã",
					},
					{
						level3_id: "03232",
						name: "Xã Tả Phìn",
						type: "Xã",
					},
					{
						level3_id: "03235",
						name: "Xã Tủa Thàng",
						type: "Xã",
					},
					{
						level3_id: "03238",
						name: "Xã Trung Thu",
						type: "Xã",
					},
					{
						level3_id: "03241",
						name: "Xã Sính Phình",
						type: "Xã",
					},
					{
						level3_id: "03244",
						name: "Xã Sáng Nhè",
						type: "Xã",
					},
					{
						level3_id: "03247",
						name: "Xã Mường Đun",
						type: "Xã",
					},
					{
						level3_id: "03250",
						name: "Xã Mường Báng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "099",
				name: "Huyện Tuần Giáo",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03253",
						name: "Thị trấn Tuần Giáo",
						type: "Thị trấn",
					},
					{
						level3_id: "03259",
						name: "Xã Phình Sáng",
						type: "Xã",
					},
					{
						level3_id: "03260",
						name: "Xã Rạng Đông",
						type: "Xã",
					},
					{
						level3_id: "03262",
						name: "Xã Mùn Chung",
						type: "Xã",
					},
					{
						level3_id: "03263",
						name: "Xã Nà Tòng",
						type: "Xã",
					},
					{
						level3_id: "03265",
						name: "Xã Ta Ma",
						type: "Xã",
					},
					{
						level3_id: "03268",
						name: "Xã Mường Mùn",
						type: "Xã",
					},
					{
						level3_id: "03269",
						name: "Xã Pú Xi",
						type: "Xã",
					},
					{
						level3_id: "03271",
						name: "Xã Pú Nhung",
						type: "Xã",
					},
					{
						level3_id: "03274",
						name: "Xã Quài Nưa",
						type: "Xã",
					},
					{
						level3_id: "03277",
						name: "Xã Mường Thín",
						type: "Xã",
					},
					{
						level3_id: "03280",
						name: "Xã Tỏa Tình",
						type: "Xã",
					},
					{
						level3_id: "03283",
						name: "Xã Nà Sáy",
						type: "Xã",
					},
					{
						level3_id: "03284",
						name: "Xã Mường Khong",
						type: "Xã",
					},
					{
						level3_id: "03289",
						name: "Xã Quài Cang",
						type: "Xã",
					},
					{
						level3_id: "03295",
						name: "Xã Quài Tở",
						type: "Xã",
					},
					{
						level3_id: "03298",
						name: "Xã Chiềng Sinh",
						type: "Xã",
					},
					{
						level3_id: "03299",
						name: "Xã Chiềng Đông",
						type: "Xã",
					},
					{
						level3_id: "03304",
						name: "Xã Tênh Phông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "100",
				name: "Huyện Điện Biên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03319",
						name: "Xã Mường Pồn",
						type: "Xã",
					},
					{
						level3_id: "03322",
						name: "Xã Thanh Nưa",
						type: "Xã",
					},
					{
						level3_id: "03323",
						name: "Xã Hua Thanh",
						type: "Xã",
					},
					{
						level3_id: "03328",
						name: "Xã Thanh Luông",
						type: "Xã",
					},
					{
						level3_id: "03331",
						name: "Xã Thanh Hưng",
						type: "Xã",
					},
					{
						level3_id: "03334",
						name: "Xã Thanh Xương",
						type: "Xã",
					},
					{
						level3_id: "03337",
						name: "Xã Thanh Chăn",
						type: "Xã",
					},
					{
						level3_id: "03340",
						name: "Xã Pa Thơm",
						type: "Xã",
					},
					{
						level3_id: "03343",
						name: "Xã Thanh An",
						type: "Xã",
					},
					{
						level3_id: "03346",
						name: "Xã Thanh Yên",
						type: "Xã",
					},
					{
						level3_id: "03349",
						name: "Xã Noong Luống",
						type: "Xã",
					},
					{
						level3_id: "03352",
						name: "Xã Noọng Hẹt",
						type: "Xã",
					},
					{
						level3_id: "03355",
						name: "Xã Sam Mứn",
						type: "Xã",
					},
					{
						level3_id: "03356",
						name: "Xã Pom Lót",
						type: "Xã",
					},
					{
						level3_id: "03358",
						name: "Xã Núa Ngam",
						type: "Xã",
					},
					{
						level3_id: "03359",
						name: "Xã Hẹ Muông",
						type: "Xã",
					},
					{
						level3_id: "03361",
						name: "Xã Na Ư",
						type: "Xã",
					},
					{
						level3_id: "03364",
						name: "Xã Mường Nhà",
						type: "Xã",
					},
					{
						level3_id: "03365",
						name: "Xã Na Tông",
						type: "Xã",
					},
					{
						level3_id: "03367",
						name: "Xã Mường Lói",
						type: "Xã",
					},
					{
						level3_id: "03368",
						name: "Xã Phu Luông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "101",
				name: "Huyện Điện Biên Đông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03203",
						name: "Thị trấn Điện Biên Đông",
						type: "Thị trấn",
					},
					{
						level3_id: "03205",
						name: "Xã Na Son",
						type: "Xã",
					},
					{
						level3_id: "03208",
						name: "Xã Phì Nhừ",
						type: "Xã",
					},
					{
						level3_id: "03211",
						name: "Xã Chiềng Sơ",
						type: "Xã",
					},
					{
						level3_id: "03214",
						name: "Xã Mường Luân",
						type: "Xã",
					},
					{
						level3_id: "03370",
						name: "Xã Pú Nhi",
						type: "Xã",
					},
					{
						level3_id: "03371",
						name: "Xã Nong U",
						type: "Xã",
					},
					{
						level3_id: "03373",
						name: "Xã Xa Dung",
						type: "Xã",
					},
					{
						level3_id: "03376",
						name: "Xã Keo Lôm",
						type: "Xã",
					},
					{
						level3_id: "03379",
						name: "Xã Luân Giới",
						type: "Xã",
					},
					{
						level3_id: "03382",
						name: "Xã Phình Giàng",
						type: "Xã",
					},
					{
						level3_id: "03383",
						name: "Xã Pú Hồng",
						type: "Xã",
					},
					{
						level3_id: "03384",
						name: "Xã Tìa Dình",
						type: "Xã",
					},
					{
						level3_id: "03385",
						name: "Xã Háng Lìa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "102",
				name: "Huyện Mường Ảng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03256",
						name: "Thị trấn Mường Ảng",
						type: "Thị trấn",
					},
					{
						level3_id: "03286",
						name: "Xã Mường Đăng",
						type: "Xã",
					},
					{
						level3_id: "03287",
						name: "Xã Ngối Cáy",
						type: "Xã",
					},
					{
						level3_id: "03292",
						name: "Xã Ẳng Tở",
						type: "Xã",
					},
					{
						level3_id: "03301",
						name: "Xã Búng Lao",
						type: "Xã",
					},
					{
						level3_id: "03302",
						name: "Xã Xuân Lao",
						type: "Xã",
					},
					{
						level3_id: "03307",
						name: "Xã Ẳng Nưa",
						type: "Xã",
					},
					{
						level3_id: "03310",
						name: "Xã Ẳng Cang",
						type: "Xã",
					},
					{
						level3_id: "03312",
						name: "Xã Nặm Lịch",
						type: "Xã",
					},
					{
						level3_id: "03313",
						name: "Xã Mường Lạn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "103",
				name: "Huyện Nậm Pồ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03156",
						name: "Xã Nậm Tin",
						type: "Xã",
					},
					{
						level3_id: "03165",
						name: "Xã Pa Tần",
						type: "Xã",
					},
					{
						level3_id: "03166",
						name: "Xã Chà Cang",
						type: "Xã",
					},
					{
						level3_id: "03167",
						name: "Xã Na Cô Sa",
						type: "Xã",
					},
					{
						level3_id: "03168",
						name: "Xã Nà Khoa",
						type: "Xã",
					},
					{
						level3_id: "03169",
						name: "Xã Nà Hỳ",
						type: "Xã",
					},
					{
						level3_id: "03170",
						name: "Xã Nà Bủng",
						type: "Xã",
					},
					{
						level3_id: "03171",
						name: "Xã Nậm Nhừ",
						type: "Xã",
					},
					{
						level3_id: "03173",
						name: "Xã Nậm Chua",
						type: "Xã",
					},
					{
						level3_id: "03174",
						name: "Xã Nậm Khăn",
						type: "Xã",
					},
					{
						level3_id: "03175",
						name: "Xã Chà Tở",
						type: "Xã",
					},
					{
						level3_id: "03176",
						name: "Xã Vàng Đán",
						type: "Xã",
					},
					{
						level3_id: "03187",
						name: "Xã Chà Nưa",
						type: "Xã",
					},
					{
						level3_id: "03198",
						name: "Xã Phìn Hồ",
						type: "Xã",
					},
					{
						level3_id: "03199",
						name: "Xã Si Pa Phìn",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "12",
		name: "Tỉnh Lai Châu",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "105",
				name: "Thành phố Lai Châu",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "03386",
						name: "Phường Quyết Thắng",
						type: "Phường",
					},
					{
						level3_id: "03387",
						name: "Phường Tân Phong",
						type: "Phường",
					},
					{
						level3_id: "03388",
						name: "Phường Quyết Tiến",
						type: "Phường",
					},
					{
						level3_id: "03389",
						name: "Phường Đoàn Kết",
						type: "Phường",
					},
					{
						level3_id: "03403",
						name: "Xã Sùng Phài",
						type: "Xã",
					},
					{
						level3_id: "03408",
						name: "Phường Đông Phong",
						type: "Phường",
					},
					{
						level3_id: "03409",
						name: "Xã San Thàng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "106",
				name: "Huyện Tam Đường",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03390",
						name: "Thị trấn Tam Đường",
						type: "Thị trấn",
					},
					{
						level3_id: "03394",
						name: "Xã Thèn Sin",
						type: "Xã",
					},
					{
						level3_id: "03400",
						name: "Xã Tả Lèng",
						type: "Xã",
					},
					{
						level3_id: "03405",
						name: "Xã Giang Ma",
						type: "Xã",
					},
					{
						level3_id: "03406",
						name: "Xã Hồ Thầu",
						type: "Xã",
					},
					{
						level3_id: "03412",
						name: "Xã Bình Lư",
						type: "Xã",
					},
					{
						level3_id: "03413",
						name: "Xã Sơn Bình",
						type: "Xã",
					},
					{
						level3_id: "03415",
						name: "Xã Nùng Nàng",
						type: "Xã",
					},
					{
						level3_id: "03418",
						name: "Xã Bản Giang",
						type: "Xã",
					},
					{
						level3_id: "03421",
						name: "Xã Bản Hon",
						type: "Xã",
					},
					{
						level3_id: "03424",
						name: "Xã Bản Bo",
						type: "Xã",
					},
					{
						level3_id: "03427",
						name: "Xã Nà Tăm",
						type: "Xã",
					},
					{
						level3_id: "03430",
						name: "Xã Khun Há",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "107",
				name: "Huyện Mường Tè",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03433",
						name: "Thị trấn Mường Tè",
						type: "Thị trấn",
					},
					{
						level3_id: "03436",
						name: "Xã Thu Lũm",
						type: "Xã",
					},
					{
						level3_id: "03439",
						name: "Xã Ka Lăng",
						type: "Xã",
					},
					{
						level3_id: "03440",
						name: "Xã Tá Bạ",
						type: "Xã",
					},
					{
						level3_id: "03442",
						name: "Xã Pa ủ",
						type: "Xã",
					},
					{
						level3_id: "03445",
						name: "Xã Mường Tè",
						type: "Xã",
					},
					{
						level3_id: "03448",
						name: "Xã Pa Vệ Sử",
						type: "Xã",
					},
					{
						level3_id: "03451",
						name: "Xã Mù Cả",
						type: "Xã",
					},
					{
						level3_id: "03454",
						name: "Xã Bum Tở",
						type: "Xã",
					},
					{
						level3_id: "03457",
						name: "Xã Nậm Khao",
						type: "Xã",
					},
					{
						level3_id: "03463",
						name: "Xã Tà Tổng",
						type: "Xã",
					},
					{
						level3_id: "03466",
						name: "Xã Bum Nưa",
						type: "Xã",
					},
					{
						level3_id: "03467",
						name: "Xã Vàng San",
						type: "Xã",
					},
					{
						level3_id: "03469",
						name: "Xã Kan Hồ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "108",
				name: "Huyện Sìn Hồ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03478",
						name: "Thị trấn Sìn Hồ",
						type: "Thị trấn",
					},
					{
						level3_id: "03487",
						name: "Xã Chăn Nưa",
						type: "Xã",
					},
					{
						level3_id: "03493",
						name: "Xã Pa Tần",
						type: "Xã",
					},
					{
						level3_id: "03496",
						name: "Xã Phìn Hồ",
						type: "Xã",
					},
					{
						level3_id: "03499",
						name: "Xã Hồng Thu",
						type: "Xã",
					},
					{
						level3_id: "03505",
						name: "Xã Phăng Sô Lin",
						type: "Xã",
					},
					{
						level3_id: "03508",
						name: "Xã Ma Quai",
						type: "Xã",
					},
					{
						level3_id: "03509",
						name: "Xã Lùng Thàng",
						type: "Xã",
					},
					{
						level3_id: "03511",
						name: "Xã Tả Phìn",
						type: "Xã",
					},
					{
						level3_id: "03514",
						name: "Xã Sà Dề Phìn",
						type: "Xã",
					},
					{
						level3_id: "03517",
						name: "Xã Nậm Tăm",
						type: "Xã",
					},
					{
						level3_id: "03520",
						name: "Xã Tả Ngảo",
						type: "Xã",
					},
					{
						level3_id: "03523",
						name: "Xã Pu Sam Cáp",
						type: "Xã",
					},
					{
						level3_id: "03526",
						name: "Xã Nậm Cha",
						type: "Xã",
					},
					{
						level3_id: "03527",
						name: "Xã Pa Khoá",
						type: "Xã",
					},
					{
						level3_id: "03529",
						name: "Xã Làng Mô",
						type: "Xã",
					},
					{
						level3_id: "03532",
						name: "Xã Noong Hẻo",
						type: "Xã",
					},
					{
						level3_id: "03535",
						name: "Xã Nậm Mạ",
						type: "Xã",
					},
					{
						level3_id: "03538",
						name: "Xã Căn Co",
						type: "Xã",
					},
					{
						level3_id: "03541",
						name: "Xã Tủa Sín Chải",
						type: "Xã",
					},
					{
						level3_id: "03544",
						name: "Xã Nậm Cuổi",
						type: "Xã",
					},
					{
						level3_id: "03547",
						name: "Xã Nậm Hăn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "109",
				name: "Huyện Phong Thổ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03391",
						name: "Xã Lả Nhì Thàng",
						type: "Xã",
					},
					{
						level3_id: "03490",
						name: "Xã Huổi Luông",
						type: "Xã",
					},
					{
						level3_id: "03549",
						name: "Thị trấn Phong Thổ",
						type: "Thị trấn",
					},
					{
						level3_id: "03550",
						name: "Xã Sì Lở Lầu",
						type: "Xã",
					},
					{
						level3_id: "03553",
						name: "Xã Mồ Sì San",
						type: "Xã",
					},
					{
						level3_id: "03559",
						name: "Xã Pa Vây Sử",
						type: "Xã",
					},
					{
						level3_id: "03562",
						name: "Xã Vàng Ma Chải",
						type: "Xã",
					},
					{
						level3_id: "03565",
						name: "Xã Tông Qua Lìn",
						type: "Xã",
					},
					{
						level3_id: "03568",
						name: "Xã Mù Sang",
						type: "Xã",
					},
					{
						level3_id: "03571",
						name: "Xã Dào San",
						type: "Xã",
					},
					{
						level3_id: "03574",
						name: "Xã Ma Ly Pho",
						type: "Xã",
					},
					{
						level3_id: "03577",
						name: "Xã Bản Lang",
						type: "Xã",
					},
					{
						level3_id: "03580",
						name: "Xã Hoang Thèn",
						type: "Xã",
					},
					{
						level3_id: "03583",
						name: "Xã Khổng Lào",
						type: "Xã",
					},
					{
						level3_id: "03586",
						name: "Xã Nậm Xe",
						type: "Xã",
					},
					{
						level3_id: "03589",
						name: "Xã Mường So",
						type: "Xã",
					},
					{
						level3_id: "03592",
						name: "Xã Sin Suối Hồ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "110",
				name: "Huyện Than Uyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03595",
						name: "Thị trấn Than Uyên",
						type: "Thị trấn",
					},
					{
						level3_id: "03618",
						name: "Xã Phúc Than",
						type: "Xã",
					},
					{
						level3_id: "03619",
						name: "Xã Mường Than",
						type: "Xã",
					},
					{
						level3_id: "03625",
						name: "Xã Mường Mít",
						type: "Xã",
					},
					{
						level3_id: "03628",
						name: "Xã Pha Mu",
						type: "Xã",
					},
					{
						level3_id: "03631",
						name: "Xã Mường Cang",
						type: "Xã",
					},
					{
						level3_id: "03632",
						name: "Xã Hua Nà",
						type: "Xã",
					},
					{
						level3_id: "03634",
						name: "Xã Tà Hừa",
						type: "Xã",
					},
					{
						level3_id: "03637",
						name: "Xã Mường Kim",
						type: "Xã",
					},
					{
						level3_id: "03638",
						name: "Xã Tà Mung",
						type: "Xã",
					},
					{
						level3_id: "03640",
						name: "Xã Tà Gia",
						type: "Xã",
					},
					{
						level3_id: "03643",
						name: "Xã Khoen On",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "111",
				name: "Huyện Tân Uyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03598",
						name: "Thị trấn Tân Uyên",
						type: "Thị trấn",
					},
					{
						level3_id: "03601",
						name: "Xã Mường Khoa",
						type: "Xã",
					},
					{
						level3_id: "03602",
						name: "Xã Phúc Khoa",
						type: "Xã",
					},
					{
						level3_id: "03604",
						name: "Xã Thân Thuộc",
						type: "Xã",
					},
					{
						level3_id: "03605",
						name: "Xã Trung Đồng",
						type: "Xã",
					},
					{
						level3_id: "03607",
						name: "Xã Hố Mít",
						type: "Xã",
					},
					{
						level3_id: "03610",
						name: "Xã Nậm Cần",
						type: "Xã",
					},
					{
						level3_id: "03613",
						name: "Xã Nậm Sỏ",
						type: "Xã",
					},
					{
						level3_id: "03616",
						name: "Xã Pắc Ta",
						type: "Xã",
					},
					{
						level3_id: "03622",
						name: "Xã Tà Mít",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "112",
				name: "Huyện Nậm Nhùn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03434",
						name: "Thị trấn Nậm Nhùn",
						type: "Thị trấn",
					},
					{
						level3_id: "03460",
						name: "Xã Hua Bun",
						type: "Xã",
					},
					{
						level3_id: "03472",
						name: "Xã Mường Mô",
						type: "Xã",
					},
					{
						level3_id: "03473",
						name: "Xã Nậm Chà",
						type: "Xã",
					},
					{
						level3_id: "03474",
						name: "Xã Nậm Manh",
						type: "Xã",
					},
					{
						level3_id: "03475",
						name: "Xã Nậm Hàng",
						type: "Xã",
					},
					{
						level3_id: "03481",
						name: "Xã Lê Lợi",
						type: "Xã",
					},
					{
						level3_id: "03484",
						name: "Xã Pú Đao",
						type: "Xã",
					},
					{
						level3_id: "03488",
						name: "Xã Nậm Pì",
						type: "Xã",
					},
					{
						level3_id: "03502",
						name: "Xã Nậm Ban",
						type: "Xã",
					},
					{
						level3_id: "03503",
						name: "Xã Trung Chải",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "14",
		name: "Tỉnh Sơn La",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "116",
				name: "Thành phố Sơn La",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "03646",
						name: "Phường Chiềng Lề",
						type: "Phường",
					},
					{
						level3_id: "03649",
						name: "Phường Tô Hiệu",
						type: "Phường",
					},
					{
						level3_id: "03652",
						name: "Phường Quyết Thắng",
						type: "Phường",
					},
					{
						level3_id: "03655",
						name: "Phường Quyết Tâm",
						type: "Phường",
					},
					{
						level3_id: "03658",
						name: "Xã Chiềng Cọ",
						type: "Xã",
					},
					{
						level3_id: "03661",
						name: "Xã Chiềng Đen",
						type: "Xã",
					},
					{
						level3_id: "03664",
						name: "Xã Chiềng Xôm",
						type: "Xã",
					},
					{
						level3_id: "03667",
						name: "Phường Chiềng An",
						type: "Phường",
					},
					{
						level3_id: "03670",
						name: "Phường Chiềng Cơi",
						type: "Phường",
					},
					{
						level3_id: "03673",
						name: "Xã Chiềng Ngần",
						type: "Xã",
					},
					{
						level3_id: "03676",
						name: "Xã Hua La",
						type: "Xã",
					},
					{
						level3_id: "03679",
						name: "Phường Chiềng Sinh",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "118",
				name: "Huyện Quỳnh Nhai",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03682",
						name: "Xã Mường Chiên",
						type: "Xã",
					},
					{
						level3_id: "03685",
						name: "Xã Cà Nàng",
						type: "Xã",
					},
					{
						level3_id: "03688",
						name: "Xã Chiềng Khay",
						type: "Xã",
					},
					{
						level3_id: "03694",
						name: "Xã Mường Giôn",
						type: "Xã",
					},
					{
						level3_id: "03697",
						name: "Xã Pá Ma Pha Khinh",
						type: "Xã",
					},
					{
						level3_id: "03700",
						name: "Xã Chiềng Ơn",
						type: "Xã",
					},
					{
						level3_id: "03703",
						name: "Xã Mường Giàng",
						type: "Xã",
					},
					{
						level3_id: "03706",
						name: "Xã Chiềng Bằng",
						type: "Xã",
					},
					{
						level3_id: "03709",
						name: "Xã Mường Sại",
						type: "Xã",
					},
					{
						level3_id: "03712",
						name: "Xã Nậm ét",
						type: "Xã",
					},
					{
						level3_id: "03718",
						name: "Xã Chiềng Khoang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "119",
				name: "Huyện Thuận Châu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03721",
						name: "Thị trấn Thuận Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "03724",
						name: "Xã Phổng Lái",
						type: "Xã",
					},
					{
						level3_id: "03727",
						name: "Xã Mường é",
						type: "Xã",
					},
					{
						level3_id: "03730",
						name: "Xã Chiềng Pha",
						type: "Xã",
					},
					{
						level3_id: "03733",
						name: "Xã Chiềng La",
						type: "Xã",
					},
					{
						level3_id: "03736",
						name: "Xã Chiềng Ngàm",
						type: "Xã",
					},
					{
						level3_id: "03739",
						name: "Xã Liệp Tè",
						type: "Xã",
					},
					{
						level3_id: "03742",
						name: "Xã é Tòng",
						type: "Xã",
					},
					{
						level3_id: "03745",
						name: "Xã Phổng Lập",
						type: "Xã",
					},
					{
						level3_id: "03748",
						name: "Xã Phổng Lăng",
						type: "Xã",
					},
					{
						level3_id: "03751",
						name: "Xã Chiềng Ly",
						type: "Xã",
					},
					{
						level3_id: "03754",
						name: "Xã Noong Lay",
						type: "Xã",
					},
					{
						level3_id: "03757",
						name: "Xã Mường Khiêng",
						type: "Xã",
					},
					{
						level3_id: "03760",
						name: "Xã Mường Bám",
						type: "Xã",
					},
					{
						level3_id: "03763",
						name: "Xã Long Hẹ",
						type: "Xã",
					},
					{
						level3_id: "03766",
						name: "Xã Chiềng Bôm",
						type: "Xã",
					},
					{
						level3_id: "03769",
						name: "Xã Thôm Mòn",
						type: "Xã",
					},
					{
						level3_id: "03772",
						name: "Xã Tông Lạnh",
						type: "Xã",
					},
					{
						level3_id: "03775",
						name: "Xã Tông Cọ",
						type: "Xã",
					},
					{
						level3_id: "03778",
						name: "Xã Bó Mười",
						type: "Xã",
					},
					{
						level3_id: "03781",
						name: "Xã Co Mạ",
						type: "Xã",
					},
					{
						level3_id: "03784",
						name: "Xã Púng Tra",
						type: "Xã",
					},
					{
						level3_id: "03787",
						name: "Xã Chiềng Pấc",
						type: "Xã",
					},
					{
						level3_id: "03790",
						name: "Xã Nậm Lầu",
						type: "Xã",
					},
					{
						level3_id: "03793",
						name: "Xã Bon Phặng",
						type: "Xã",
					},
					{
						level3_id: "03796",
						name: "Xã Co Tòng",
						type: "Xã",
					},
					{
						level3_id: "03799",
						name: "Xã Muổi Nọi",
						type: "Xã",
					},
					{
						level3_id: "03802",
						name: "Xã Pá Lông",
						type: "Xã",
					},
					{
						level3_id: "03805",
						name: "Xã Bản Lầm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "120",
				name: "Huyện Mường La",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03808",
						name: "Thị trấn Ít Ong",
						type: "Thị trấn",
					},
					{
						level3_id: "03811",
						name: "Xã Nậm Giôn",
						type: "Xã",
					},
					{
						level3_id: "03814",
						name: "Xã Chiềng Lao",
						type: "Xã",
					},
					{
						level3_id: "03817",
						name: "Xã Hua Trai",
						type: "Xã",
					},
					{
						level3_id: "03820",
						name: "Xã Ngọc Chiến",
						type: "Xã",
					},
					{
						level3_id: "03823",
						name: "Xã Mường Trai",
						type: "Xã",
					},
					{
						level3_id: "03826",
						name: "Xã Nậm Păm",
						type: "Xã",
					},
					{
						level3_id: "03829",
						name: "Xã Chiềng Muôn",
						type: "Xã",
					},
					{
						level3_id: "03832",
						name: "Xã Chiềng Ân",
						type: "Xã",
					},
					{
						level3_id: "03835",
						name: "Xã Pi Toong",
						type: "Xã",
					},
					{
						level3_id: "03838",
						name: "Xã Chiềng Công",
						type: "Xã",
					},
					{
						level3_id: "03841",
						name: "Xã Tạ Bú",
						type: "Xã",
					},
					{
						level3_id: "03844",
						name: "Xã Chiềng San",
						type: "Xã",
					},
					{
						level3_id: "03847",
						name: "Xã Mường Bú",
						type: "Xã",
					},
					{
						level3_id: "03850",
						name: "Xã Chiềng Hoa",
						type: "Xã",
					},
					{
						level3_id: "03853",
						name: "Xã Mường Chùm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "121",
				name: "Huyện Bắc Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03856",
						name: "Thị trấn Bắc Yên",
						type: "Thị trấn",
					},
					{
						level3_id: "03859",
						name: "Xã Phiêng Ban",
						type: "Xã",
					},
					{
						level3_id: "03862",
						name: "Xã Hang Chú",
						type: "Xã",
					},
					{
						level3_id: "03865",
						name: "Xã Xím Vàng",
						type: "Xã",
					},
					{
						level3_id: "03868",
						name: "Xã Tà Xùa",
						type: "Xã",
					},
					{
						level3_id: "03869",
						name: "Xã Háng Đồng",
						type: "Xã",
					},
					{
						level3_id: "03871",
						name: "Xã Pắc Ngà",
						type: "Xã",
					},
					{
						level3_id: "03874",
						name: "Xã Làng Chếu",
						type: "Xã",
					},
					{
						level3_id: "03877",
						name: "Xã Chim Vàn",
						type: "Xã",
					},
					{
						level3_id: "03880",
						name: "Xã Mường Khoa",
						type: "Xã",
					},
					{
						level3_id: "03883",
						name: "Xã Song Pe",
						type: "Xã",
					},
					{
						level3_id: "03886",
						name: "Xã Hồng Ngài",
						type: "Xã",
					},
					{
						level3_id: "03889",
						name: "Xã Tạ Khoa",
						type: "Xã",
					},
					{
						level3_id: "03890",
						name: "Xã Hua Nhàn",
						type: "Xã",
					},
					{
						level3_id: "03892",
						name: "Xã Phiêng Côn",
						type: "Xã",
					},
					{
						level3_id: "03895",
						name: "Xã Chiềng Sại",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "122",
				name: "Huyện Phù Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03898",
						name: "Thị trấn Phù Yên",
						type: "Thị trấn",
					},
					{
						level3_id: "03901",
						name: "Xã Suối Tọ",
						type: "Xã",
					},
					{
						level3_id: "03904",
						name: "Xã Mường Thải",
						type: "Xã",
					},
					{
						level3_id: "03907",
						name: "Xã Mường Cơi",
						type: "Xã",
					},
					{
						level3_id: "03910",
						name: "Xã Quang Huy",
						type: "Xã",
					},
					{
						level3_id: "03913",
						name: "Xã Huy Bắc",
						type: "Xã",
					},
					{
						level3_id: "03916",
						name: "Xã Huy Thượng",
						type: "Xã",
					},
					{
						level3_id: "03919",
						name: "Xã Tân Lang",
						type: "Xã",
					},
					{
						level3_id: "03922",
						name: "Xã Gia Phù",
						type: "Xã",
					},
					{
						level3_id: "03925",
						name: "Xã Tường Phù",
						type: "Xã",
					},
					{
						level3_id: "03928",
						name: "Xã Huy Hạ",
						type: "Xã",
					},
					{
						level3_id: "03931",
						name: "Xã Huy Tân",
						type: "Xã",
					},
					{
						level3_id: "03934",
						name: "Xã Mường Lang",
						type: "Xã",
					},
					{
						level3_id: "03937",
						name: "Xã Suối Bau",
						type: "Xã",
					},
					{
						level3_id: "03940",
						name: "Xã Huy Tường",
						type: "Xã",
					},
					{
						level3_id: "03943",
						name: "Xã Mường Do",
						type: "Xã",
					},
					{
						level3_id: "03946",
						name: "Xã Sập Xa",
						type: "Xã",
					},
					{
						level3_id: "03949",
						name: "Xã Tường Thượng",
						type: "Xã",
					},
					{
						level3_id: "03952",
						name: "Xã Tường Tiến",
						type: "Xã",
					},
					{
						level3_id: "03955",
						name: "Xã Tường Phong",
						type: "Xã",
					},
					{
						level3_id: "03958",
						name: "Xã Tường Hạ",
						type: "Xã",
					},
					{
						level3_id: "03961",
						name: "Xã Kim Bon",
						type: "Xã",
					},
					{
						level3_id: "03964",
						name: "Xã Mường Bang",
						type: "Xã",
					},
					{
						level3_id: "03967",
						name: "Xã Đá Đỏ",
						type: "Xã",
					},
					{
						level3_id: "03970",
						name: "Xã Tân Phong",
						type: "Xã",
					},
					{
						level3_id: "03973",
						name: "Xã Nam Phong",
						type: "Xã",
					},
					{
						level3_id: "03976",
						name: "Xã Bắc Phong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "123",
				name: "Huyện Mộc Châu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03979",
						name: "Thị trấn Mộc Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "03982",
						name: "Thị trấn NT Mộc Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "03985",
						name: "Xã Chiềng Sơn",
						type: "Xã",
					},
					{
						level3_id: "03988",
						name: "Xã Tân Hợp",
						type: "Xã",
					},
					{
						level3_id: "03991",
						name: "Xã Qui Hướng",
						type: "Xã",
					},
					{
						level3_id: "03997",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "04000",
						name: "Xã Nà Mường",
						type: "Xã",
					},
					{
						level3_id: "04003",
						name: "Xã Tà Lai",
						type: "Xã",
					},
					{
						level3_id: "04012",
						name: "Xã Chiềng Hắc",
						type: "Xã",
					},
					{
						level3_id: "04015",
						name: "Xã Hua Păng",
						type: "Xã",
					},
					{
						level3_id: "04024",
						name: "Xã Chiềng Khừa",
						type: "Xã",
					},
					{
						level3_id: "04027",
						name: "Xã Mường Sang",
						type: "Xã",
					},
					{
						level3_id: "04030",
						name: "Xã Đông Sang",
						type: "Xã",
					},
					{
						level3_id: "04033",
						name: "Xã Phiêng Luông",
						type: "Xã",
					},
					{
						level3_id: "04045",
						name: "Xã Lóng Sập",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "124",
				name: "Huyện Yên Châu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04060",
						name: "Thị trấn Yên Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "04063",
						name: "Xã Chiềng Đông",
						type: "Xã",
					},
					{
						level3_id: "04066",
						name: "Xã Sập Vạt",
						type: "Xã",
					},
					{
						level3_id: "04069",
						name: "Xã Chiềng Sàng",
						type: "Xã",
					},
					{
						level3_id: "04072",
						name: "Xã Chiềng Pằn",
						type: "Xã",
					},
					{
						level3_id: "04075",
						name: "Xã Viêng Lán",
						type: "Xã",
					},
					{
						level3_id: "04078",
						name: "Xã Chiềng Hặc",
						type: "Xã",
					},
					{
						level3_id: "04081",
						name: "Xã Mường Lựm",
						type: "Xã",
					},
					{
						level3_id: "04084",
						name: "Xã Chiềng On",
						type: "Xã",
					},
					{
						level3_id: "04087",
						name: "Xã Yên Sơn",
						type: "Xã",
					},
					{
						level3_id: "04090",
						name: "Xã Chiềng Khoi",
						type: "Xã",
					},
					{
						level3_id: "04093",
						name: "Xã Tú Nang",
						type: "Xã",
					},
					{
						level3_id: "04096",
						name: "Xã Lóng Phiêng",
						type: "Xã",
					},
					{
						level3_id: "04099",
						name: "Xã Phiêng Khoài",
						type: "Xã",
					},
					{
						level3_id: "04102",
						name: "Xã Chiềng Tương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "125",
				name: "Huyện Mai Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04105",
						name: "Thị trấn Hát Lót",
						type: "Thị trấn",
					},
					{
						level3_id: "04108",
						name: "Xã Chiềng Sung",
						type: "Xã",
					},
					{
						level3_id: "04111",
						name: "Xã Mường Bằng",
						type: "Xã",
					},
					{
						level3_id: "04114",
						name: "Xã Chiềng Chăn",
						type: "Xã",
					},
					{
						level3_id: "04117",
						name: "Xã Mương Chanh",
						type: "Xã",
					},
					{
						level3_id: "04120",
						name: "Xã Chiềng Ban",
						type: "Xã",
					},
					{
						level3_id: "04123",
						name: "Xã Chiềng Mung",
						type: "Xã",
					},
					{
						level3_id: "04126",
						name: "Xã Mường Bon",
						type: "Xã",
					},
					{
						level3_id: "04129",
						name: "Xã Chiềng Chung",
						type: "Xã",
					},
					{
						level3_id: "04132",
						name: "Xã Chiềng Mai",
						type: "Xã",
					},
					{
						level3_id: "04135",
						name: "Xã Hát Lót",
						type: "Xã",
					},
					{
						level3_id: "04136",
						name: "Xã Nà Pó",
						type: "Xã",
					},
					{
						level3_id: "04138",
						name: "Xã Cò  Nòi",
						type: "Xã",
					},
					{
						level3_id: "04141",
						name: "Xã Chiềng Nơi",
						type: "Xã",
					},
					{
						level3_id: "04144",
						name: "Xã Phiêng Cằm",
						type: "Xã",
					},
					{
						level3_id: "04147",
						name: "Xã Chiềng Dong",
						type: "Xã",
					},
					{
						level3_id: "04150",
						name: "Xã Chiềng Kheo",
						type: "Xã",
					},
					{
						level3_id: "04153",
						name: "Xã Chiềng Ve",
						type: "Xã",
					},
					{
						level3_id: "04156",
						name: "Xã Chiềng Lương",
						type: "Xã",
					},
					{
						level3_id: "04159",
						name: "Xã Phiêng Pằn",
						type: "Xã",
					},
					{
						level3_id: "04162",
						name: "Xã Nà Ơt",
						type: "Xã",
					},
					{
						level3_id: "04165",
						name: "Xã Tà Hộc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "126",
				name: "Huyện Sông Mã",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04168",
						name: "Thị trấn Sông Mã",
						type: "Thị trấn",
					},
					{
						level3_id: "04171",
						name: "Xã Bó Sinh",
						type: "Xã",
					},
					{
						level3_id: "04174",
						name: "Xã Pú Pẩu",
						type: "Xã",
					},
					{
						level3_id: "04177",
						name: "Xã Chiềng Phung",
						type: "Xã",
					},
					{
						level3_id: "04180",
						name: "Xã Chiềng En",
						type: "Xã",
					},
					{
						level3_id: "04183",
						name: "Xã Mường Lầm",
						type: "Xã",
					},
					{
						level3_id: "04186",
						name: "Xã Nậm Ty",
						type: "Xã",
					},
					{
						level3_id: "04189",
						name: "Xã Đứa Mòn",
						type: "Xã",
					},
					{
						level3_id: "04192",
						name: "Xã Yên Hưng",
						type: "Xã",
					},
					{
						level3_id: "04195",
						name: "Xã Chiềng Sơ",
						type: "Xã",
					},
					{
						level3_id: "04198",
						name: "Xã Nà Nghịu",
						type: "Xã",
					},
					{
						level3_id: "04201",
						name: "Xã Nậm Mằn",
						type: "Xã",
					},
					{
						level3_id: "04204",
						name: "Xã Chiềng Khoong",
						type: "Xã",
					},
					{
						level3_id: "04207",
						name: "Xã Chiềng Cang",
						type: "Xã",
					},
					{
						level3_id: "04210",
						name: "Xã Huổi Một",
						type: "Xã",
					},
					{
						level3_id: "04213",
						name: "Xã Mường Sai",
						type: "Xã",
					},
					{
						level3_id: "04216",
						name: "Xã Mường Cai",
						type: "Xã",
					},
					{
						level3_id: "04219",
						name: "Xã Mường Hung",
						type: "Xã",
					},
					{
						level3_id: "04222",
						name: "Xã Chiềng Khương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "127",
				name: "Huyện Sốp Cộp",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04225",
						name: "Xã Sam Kha",
						type: "Xã",
					},
					{
						level3_id: "04228",
						name: "Xã Púng Bánh",
						type: "Xã",
					},
					{
						level3_id: "04231",
						name: "Xã Sốp Cộp",
						type: "Xã",
					},
					{
						level3_id: "04234",
						name: "Xã Dồm Cang",
						type: "Xã",
					},
					{
						level3_id: "04237",
						name: "Xã Nậm Lạnh",
						type: "Xã",
					},
					{
						level3_id: "04240",
						name: "Xã Mường Lèo",
						type: "Xã",
					},
					{
						level3_id: "04243",
						name: "Xã Mường Và",
						type: "Xã",
					},
					{
						level3_id: "04246",
						name: "Xã Mường Lạn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "128",
				name: "Huyện Vân Hồ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "03994",
						name: "Xã Suối Bàng",
						type: "Xã",
					},
					{
						level3_id: "04006",
						name: "Xã Song Khủa",
						type: "Xã",
					},
					{
						level3_id: "04009",
						name: "Xã Liên Hoà",
						type: "Xã",
					},
					{
						level3_id: "04018",
						name: "Xã Tô Múa",
						type: "Xã",
					},
					{
						level3_id: "04021",
						name: "Xã Mường Tè",
						type: "Xã",
					},
					{
						level3_id: "04036",
						name: "Xã Chiềng Khoa",
						type: "Xã",
					},
					{
						level3_id: "04039",
						name: "Xã Mường Men",
						type: "Xã",
					},
					{
						level3_id: "04042",
						name: "Xã Quang Minh",
						type: "Xã",
					},
					{
						level3_id: "04048",
						name: "Xã Vân Hồ",
						type: "Xã",
					},
					{
						level3_id: "04051",
						name: "Xã Lóng Luông",
						type: "Xã",
					},
					{
						level3_id: "04054",
						name: "Xã Chiềng Yên",
						type: "Xã",
					},
					{
						level3_id: "04056",
						name: "Xã Chiềng Xuân",
						type: "Xã",
					},
					{
						level3_id: "04057",
						name: "Xã Xuân Nha",
						type: "Xã",
					},
					{
						level3_id: "04058",
						name: "Xã Tân Xuân",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "15",
		name: "Tỉnh Yên Bái",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "132",
				name: "Thành phố Yên Bái",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "04249",
						name: "Phường Yên Thịnh",
						type: "Phường",
					},
					{
						level3_id: "04252",
						name: "Phường Yên Ninh",
						type: "Phường",
					},
					{
						level3_id: "04255",
						name: "Phường Minh Tân",
						type: "Phường",
					},
					{
						level3_id: "04258",
						name: "Phường Nguyễn Thái Học",
						type: "Phường",
					},
					{
						level3_id: "04261",
						name: "Phường Đồng Tâm",
						type: "Phường",
					},
					{
						level3_id: "04264",
						name: "Phường Nguyễn Phúc",
						type: "Phường",
					},
					{
						level3_id: "04267",
						name: "Phường Hồng Hà",
						type: "Phường",
					},
					{
						level3_id: "04270",
						name: "Xã Minh Bảo",
						type: "Xã",
					},
					{
						level3_id: "04273",
						name: "Phường Nam Cường",
						type: "Phường",
					},
					{
						level3_id: "04276",
						name: "Xã Tuy Lộc",
						type: "Xã",
					},
					{
						level3_id: "04279",
						name: "Xã Tân Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04540",
						name: "Xã Âu Lâu",
						type: "Xã",
					},
					{
						level3_id: "04543",
						name: "Xã Giới Phiên",
						type: "Xã",
					},
					{
						level3_id: "04546",
						name: "Phường Hợp Minh",
						type: "Phường",
					},
					{
						level3_id: "04558",
						name: "Xã Văn Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "133",
				name: "Thị xã Nghĩa Lộ",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "04282",
						name: "Phường Pú Trạng",
						type: "Phường",
					},
					{
						level3_id: "04285",
						name: "Phường Trung Tâm",
						type: "Phường",
					},
					{
						level3_id: "04288",
						name: "Phường Tân An",
						type: "Phường",
					},
					{
						level3_id: "04291",
						name: "Phường Cầu Thia",
						type: "Phường",
					},
					{
						level3_id: "04294",
						name: "Xã Nghĩa Lợi",
						type: "Xã",
					},
					{
						level3_id: "04297",
						name: "Xã Nghĩa Phúc",
						type: "Xã",
					},
					{
						level3_id: "04300",
						name: "Xã Nghĩa An",
						type: "Xã",
					},
					{
						level3_id: "04624",
						name: "Xã Nghĩa Lộ",
						type: "Xã",
					},
					{
						level3_id: "04660",
						name: "Xã Sơn A",
						type: "Xã",
					},
					{
						level3_id: "04663",
						name: "Xã Phù Nham",
						type: "Xã",
					},
					{
						level3_id: "04675",
						name: "Xã Thanh Lương",
						type: "Xã",
					},
					{
						level3_id: "04678",
						name: "Xã Hạnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "04681",
						name: "Xã Phúc Sơn",
						type: "Xã",
					},
					{
						level3_id: "04684",
						name: "Xã Thạch Lương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "135",
				name: "Huyện Lục Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04303",
						name: "Thị trấn Yên Thế",
						type: "Thị trấn",
					},
					{
						level3_id: "04306",
						name: "Xã Tân Phượng",
						type: "Xã",
					},
					{
						level3_id: "04309",
						name: "Xã Lâm Thượng",
						type: "Xã",
					},
					{
						level3_id: "04312",
						name: "Xã Khánh Thiện",
						type: "Xã",
					},
					{
						level3_id: "04315",
						name: "Xã Minh Chuẩn",
						type: "Xã",
					},
					{
						level3_id: "04318",
						name: "Xã Mai Sơn",
						type: "Xã",
					},
					{
						level3_id: "04321",
						name: "Xã Khai Trung",
						type: "Xã",
					},
					{
						level3_id: "04324",
						name: "Xã Mường Lai",
						type: "Xã",
					},
					{
						level3_id: "04327",
						name: "Xã An Lạc",
						type: "Xã",
					},
					{
						level3_id: "04330",
						name: "Xã Minh Xuân",
						type: "Xã",
					},
					{
						level3_id: "04333",
						name: "Xã Tô Mậu",
						type: "Xã",
					},
					{
						level3_id: "04336",
						name: "Xã Tân Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "04339",
						name: "Xã Yên Thắng",
						type: "Xã",
					},
					{
						level3_id: "04342",
						name: "Xã Khánh Hoà",
						type: "Xã",
					},
					{
						level3_id: "04345",
						name: "Xã Vĩnh Lạc",
						type: "Xã",
					},
					{
						level3_id: "04348",
						name: "Xã Liễu Đô",
						type: "Xã",
					},
					{
						level3_id: "04351",
						name: "Xã Động Quan",
						type: "Xã",
					},
					{
						level3_id: "04354",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "04357",
						name: "Xã Minh Tiến",
						type: "Xã",
					},
					{
						level3_id: "04360",
						name: "Xã Trúc Lâu",
						type: "Xã",
					},
					{
						level3_id: "04363",
						name: "Xã Phúc Lợi",
						type: "Xã",
					},
					{
						level3_id: "04366",
						name: "Xã Phan Thanh",
						type: "Xã",
					},
					{
						level3_id: "04369",
						name: "Xã An Phú",
						type: "Xã",
					},
					{
						level3_id: "04372",
						name: "Xã Trung Tâm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "136",
				name: "Huyện Văn Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04375",
						name: "Thị trấn Mậu A",
						type: "Thị trấn",
					},
					{
						level3_id: "04378",
						name: "Xã Lang Thíp",
						type: "Xã",
					},
					{
						level3_id: "04381",
						name: "Xã Lâm Giang",
						type: "Xã",
					},
					{
						level3_id: "04384",
						name: "Xã Châu Quế Thượng",
						type: "Xã",
					},
					{
						level3_id: "04387",
						name: "Xã Châu Quế Hạ",
						type: "Xã",
					},
					{
						level3_id: "04390",
						name: "Xã An Bình",
						type: "Xã",
					},
					{
						level3_id: "04393",
						name: "Xã Quang Minh",
						type: "Xã",
					},
					{
						level3_id: "04396",
						name: "Xã Đông An",
						type: "Xã",
					},
					{
						level3_id: "04399",
						name: "Xã Đông Cuông",
						type: "Xã",
					},
					{
						level3_id: "04402",
						name: "Xã Phong Dụ Hạ",
						type: "Xã",
					},
					{
						level3_id: "04405",
						name: "Xã Mậu Đông",
						type: "Xã",
					},
					{
						level3_id: "04408",
						name: "Xã Ngòi A",
						type: "Xã",
					},
					{
						level3_id: "04411",
						name: "Xã Xuân Tầm",
						type: "Xã",
					},
					{
						level3_id: "04414",
						name: "Xã Tân Hợp",
						type: "Xã",
					},
					{
						level3_id: "04417",
						name: "Xã An Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04420",
						name: "Xã Yên Thái",
						type: "Xã",
					},
					{
						level3_id: "04423",
						name: "Xã Phong Dụ Thượng",
						type: "Xã",
					},
					{
						level3_id: "04426",
						name: "Xã Yên Hợp",
						type: "Xã",
					},
					{
						level3_id: "04429",
						name: "Xã Đại Sơn",
						type: "Xã",
					},
					{
						level3_id: "04435",
						name: "Xã Đại Phác",
						type: "Xã",
					},
					{
						level3_id: "04438",
						name: "Xã Yên Phú",
						type: "Xã",
					},
					{
						level3_id: "04441",
						name: "Xã Xuân Ái",
						type: "Xã",
					},
					{
						level3_id: "04447",
						name: "Xã Viễn Sơn",
						type: "Xã",
					},
					{
						level3_id: "04450",
						name: "Xã Mỏ Vàng",
						type: "Xã",
					},
					{
						level3_id: "04453",
						name: "Xã Nà Hẩu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "137",
				name: "Huyện Mù Căng Chải",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04456",
						name: "Thị trấn Mù Căng Chải",
						type: "Thị trấn",
					},
					{
						level3_id: "04459",
						name: "Xã Hồ Bốn",
						type: "Xã",
					},
					{
						level3_id: "04462",
						name: "Xã Nậm Có",
						type: "Xã",
					},
					{
						level3_id: "04465",
						name: "Xã Khao Mang",
						type: "Xã",
					},
					{
						level3_id: "04468",
						name: "Xã Mồ Dề",
						type: "Xã",
					},
					{
						level3_id: "04471",
						name: "Xã Chế Cu Nha",
						type: "Xã",
					},
					{
						level3_id: "04474",
						name: "Xã Lao Chải",
						type: "Xã",
					},
					{
						level3_id: "04477",
						name: "Xã Kim Nọi",
						type: "Xã",
					},
					{
						level3_id: "04480",
						name: "Xã Cao Phạ",
						type: "Xã",
					},
					{
						level3_id: "04483",
						name: "Xã La Pán Tẩn",
						type: "Xã",
					},
					{
						level3_id: "04486",
						name: "Xã Dế Su Phình",
						type: "Xã",
					},
					{
						level3_id: "04489",
						name: "Xã Chế Tạo",
						type: "Xã",
					},
					{
						level3_id: "04492",
						name: "Xã Púng Luông",
						type: "Xã",
					},
					{
						level3_id: "04495",
						name: "Xã Nậm Khắt",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "138",
				name: "Huyện Trấn Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04498",
						name: "Thị trấn Cổ Phúc",
						type: "Thị trấn",
					},
					{
						level3_id: "04501",
						name: "Xã Tân Đồng",
						type: "Xã",
					},
					{
						level3_id: "04504",
						name: "Xã Báo Đáp",
						type: "Xã",
					},
					{
						level3_id: "04507",
						name: "Xã Đào Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04510",
						name: "Xã Việt Thành",
						type: "Xã",
					},
					{
						level3_id: "04513",
						name: "Xã Hòa Cuông",
						type: "Xã",
					},
					{
						level3_id: "04516",
						name: "Xã Minh Quán",
						type: "Xã",
					},
					{
						level3_id: "04519",
						name: "Xã Quy Mông",
						type: "Xã",
					},
					{
						level3_id: "04522",
						name: "Xã Cường Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04525",
						name: "Xã Kiên Thành",
						type: "Xã",
					},
					{
						level3_id: "04528",
						name: "Xã Nga Quán",
						type: "Xã",
					},
					{
						level3_id: "04531",
						name: "Xã Y Can",
						type: "Xã",
					},
					{
						level3_id: "04537",
						name: "Xã Lương Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04561",
						name: "Xã Bảo Hưng",
						type: "Xã",
					},
					{
						level3_id: "04564",
						name: "Xã Việt Cường",
						type: "Xã",
					},
					{
						level3_id: "04567",
						name: "Xã Minh Quân",
						type: "Xã",
					},
					{
						level3_id: "04570",
						name: "Xã Hồng Ca",
						type: "Xã",
					},
					{
						level3_id: "04573",
						name: "Xã Hưng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04576",
						name: "Xã Hưng Khánh",
						type: "Xã",
					},
					{
						level3_id: "04579",
						name: "Xã Việt Hồng",
						type: "Xã",
					},
					{
						level3_id: "04582",
						name: "Xã Vân Hội",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "139",
				name: "Huyện Trạm Tấu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04585",
						name: "Thị trấn Trạm Tấu",
						type: "Thị trấn",
					},
					{
						level3_id: "04588",
						name: "Xã Túc Đán",
						type: "Xã",
					},
					{
						level3_id: "04591",
						name: "Xã Pá Lau",
						type: "Xã",
					},
					{
						level3_id: "04594",
						name: "Xã Xà Hồ",
						type: "Xã",
					},
					{
						level3_id: "04597",
						name: "Xã Phình Hồ",
						type: "Xã",
					},
					{
						level3_id: "04600",
						name: "Xã Trạm Tấu",
						type: "Xã",
					},
					{
						level3_id: "04603",
						name: "Xã Tà Si Láng",
						type: "Xã",
					},
					{
						level3_id: "04606",
						name: "Xã Pá Hu",
						type: "Xã",
					},
					{
						level3_id: "04609",
						name: "Xã Làng Nhì",
						type: "Xã",
					},
					{
						level3_id: "04612",
						name: "Xã Bản Công",
						type: "Xã",
					},
					{
						level3_id: "04615",
						name: "Xã Bản Mù",
						type: "Xã",
					},
					{
						level3_id: "04618",
						name: "Xã Hát Lìu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "140",
				name: "Huyện Văn Chấn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04621",
						name: "Thị trấn NT Liên Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "04627",
						name: "Thị trấn NT Trần Phú",
						type: "Thị trấn",
					},
					{
						level3_id: "04630",
						name: "Xã Tú Lệ",
						type: "Xã",
					},
					{
						level3_id: "04633",
						name: "Xã Nậm Búng",
						type: "Xã",
					},
					{
						level3_id: "04636",
						name: "Xã Gia Hội",
						type: "Xã",
					},
					{
						level3_id: "04639",
						name: "Xã Sùng Đô",
						type: "Xã",
					},
					{
						level3_id: "04642",
						name: "Xã Nậm Mười",
						type: "Xã",
					},
					{
						level3_id: "04645",
						name: "Xã An Lương",
						type: "Xã",
					},
					{
						level3_id: "04648",
						name: "Xã Nậm Lành",
						type: "Xã",
					},
					{
						level3_id: "04651",
						name: "Xã Sơn Lương",
						type: "Xã",
					},
					{
						level3_id: "04654",
						name: "Xã Suối Quyền",
						type: "Xã",
					},
					{
						level3_id: "04657",
						name: "Xã Suối Giàng",
						type: "Xã",
					},
					{
						level3_id: "04666",
						name: "Xã Nghĩa Sơn",
						type: "Xã",
					},
					{
						level3_id: "04669",
						name: "Xã Suối Bu",
						type: "Xã",
					},
					{
						level3_id: "04672",
						name: "Thị trấn Sơn Thịnh",
						type: "Thị trấn",
					},
					{
						level3_id: "04687",
						name: "Xã Đại Lịch",
						type: "Xã",
					},
					{
						level3_id: "04690",
						name: "Xã Đồng Khê",
						type: "Xã",
					},
					{
						level3_id: "04693",
						name: "Xã Cát Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04696",
						name: "Xã Tân Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04699",
						name: "Xã Chấn Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04702",
						name: "Xã Bình Thuận",
						type: "Xã",
					},
					{
						level3_id: "04705",
						name: "Xã Thượng Bằng La",
						type: "Xã",
					},
					{
						level3_id: "04708",
						name: "Xã Minh An",
						type: "Xã",
					},
					{
						level3_id: "04711",
						name: "Xã Nghĩa Tâm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "141",
				name: "Huyện Yên Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04714",
						name: "Thị trấn Yên Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "04717",
						name: "Thị trấn Thác Bà",
						type: "Thị trấn",
					},
					{
						level3_id: "04720",
						name: "Xã Xuân Long",
						type: "Xã",
					},
					{
						level3_id: "04726",
						name: "Xã Cảm Nhân",
						type: "Xã",
					},
					{
						level3_id: "04729",
						name: "Xã Ngọc Chấn",
						type: "Xã",
					},
					{
						level3_id: "04732",
						name: "Xã Tân Nguyên",
						type: "Xã",
					},
					{
						level3_id: "04735",
						name: "Xã Phúc Ninh",
						type: "Xã",
					},
					{
						level3_id: "04738",
						name: "Xã Bảo Ái",
						type: "Xã",
					},
					{
						level3_id: "04741",
						name: "Xã Mỹ Gia",
						type: "Xã",
					},
					{
						level3_id: "04744",
						name: "Xã Xuân Lai",
						type: "Xã",
					},
					{
						level3_id: "04747",
						name: "Xã Mông Sơn",
						type: "Xã",
					},
					{
						level3_id: "04750",
						name: "Xã Cảm Ân",
						type: "Xã",
					},
					{
						level3_id: "04753",
						name: "Xã Yên Thành",
						type: "Xã",
					},
					{
						level3_id: "04756",
						name: "Xã Tân Hương",
						type: "Xã",
					},
					{
						level3_id: "04759",
						name: "Xã Phúc An",
						type: "Xã",
					},
					{
						level3_id: "04762",
						name: "Xã Bạch Hà",
						type: "Xã",
					},
					{
						level3_id: "04765",
						name: "Xã Vũ Linh",
						type: "Xã",
					},
					{
						level3_id: "04768",
						name: "Xã Đại Đồng",
						type: "Xã",
					},
					{
						level3_id: "04771",
						name: "Xã Vĩnh Kiên",
						type: "Xã",
					},
					{
						level3_id: "04774",
						name: "Xã Yên Bình",
						type: "Xã",
					},
					{
						level3_id: "04777",
						name: "Xã Thịnh Hưng",
						type: "Xã",
					},
					{
						level3_id: "04780",
						name: "Xã Hán Đà",
						type: "Xã",
					},
					{
						level3_id: "04783",
						name: "Xã Phú Thịnh",
						type: "Xã",
					},
					{
						level3_id: "04786",
						name: "Xã Đại Minh",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "17",
		name: "Tỉnh Hoà Bình",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "148",
				name: "Thành phố Hòa Bình",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "04789",
						name: "Phường Thái Bình",
						type: "Phường",
					},
					{
						level3_id: "04792",
						name: "Phường Tân Hòa",
						type: "Phường",
					},
					{
						level3_id: "04795",
						name: "Phường Thịnh Lang",
						type: "Phường",
					},
					{
						level3_id: "04798",
						name: "Phường Hữu Nghị",
						type: "Phường",
					},
					{
						level3_id: "04801",
						name: "Phường Tân Thịnh",
						type: "Phường",
					},
					{
						level3_id: "04804",
						name: "Phường Đồng Tiến",
						type: "Phường",
					},
					{
						level3_id: "04807",
						name: "Phường Phương Lâm",
						type: "Phường",
					},
					{
						level3_id: "04813",
						name: "Xã Yên Mông",
						type: "Xã",
					},
					{
						level3_id: "04816",
						name: "Phường Quỳnh Lâm",
						type: "Phường",
					},
					{
						level3_id: "04819",
						name: "Phường Dân Chủ",
						type: "Phường",
					},
					{
						level3_id: "04825",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "04828",
						name: "Phường Thống Nhất",
						type: "Phường",
					},
					{
						level3_id: "04894",
						name: "Phường Kỳ Sơn",
						type: "Phường",
					},
					{
						level3_id: "04897",
						name: "Xã Thịnh Minh",
						type: "Xã",
					},
					{
						level3_id: "04903",
						name: "Xã Hợp Thành",
						type: "Xã",
					},
					{
						level3_id: "04906",
						name: "Xã Quang Tiến",
						type: "Xã",
					},
					{
						level3_id: "04912",
						name: "Xã Mông Hóa",
						type: "Xã",
					},
					{
						level3_id: "04918",
						name: "Phường Trung Minh",
						type: "Phường",
					},
					{
						level3_id: "04921",
						name: "Xã Độc Lập",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "150",
				name: "Huyện Đà Bắc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04831",
						name: "Thị trấn Đà Bắc",
						type: "Thị trấn",
					},
					{
						level3_id: "04834",
						name: "Xã Nánh Nghê",
						type: "Xã",
					},
					{
						level3_id: "04840",
						name: "Xã Giáp Đắt",
						type: "Xã",
					},
					{
						level3_id: "04846",
						name: "Xã Mường Chiềng",
						type: "Xã",
					},
					{
						level3_id: "04849",
						name: "Xã Tân Pheo",
						type: "Xã",
					},
					{
						level3_id: "04852",
						name: "Xã Đồng Chum",
						type: "Xã",
					},
					{
						level3_id: "04855",
						name: "Xã Tân Minh",
						type: "Xã",
					},
					{
						level3_id: "04858",
						name: "Xã Đoàn Kết",
						type: "Xã",
					},
					{
						level3_id: "04861",
						name: "Xã Đồng Ruộng",
						type: "Xã",
					},
					{
						level3_id: "04867",
						name: "Xã Tú Lý",
						type: "Xã",
					},
					{
						level3_id: "04870",
						name: "Xã Trung Thành",
						type: "Xã",
					},
					{
						level3_id: "04873",
						name: "Xã Yên Hòa",
						type: "Xã",
					},
					{
						level3_id: "04876",
						name: "Xã Cao Sơn",
						type: "Xã",
					},
					{
						level3_id: "04879",
						name: "Xã Toàn Sơn",
						type: "Xã",
					},
					{
						level3_id: "04885",
						name: "Xã Hiền Lương",
						type: "Xã",
					},
					{
						level3_id: "04888",
						name: "Xã Tiền Phong",
						type: "Xã",
					},
					{
						level3_id: "04891",
						name: "Xã Vầy Nưa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "152",
				name: "Huyện Lương Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04924",
						name: "Thị trấn Lương Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "04942",
						name: "Xã Lâm Sơn",
						type: "Xã",
					},
					{
						level3_id: "04945",
						name: "Xã Hòa Sơn",
						type: "Xã",
					},
					{
						level3_id: "04951",
						name: "Xã Tân Vinh",
						type: "Xã",
					},
					{
						level3_id: "04954",
						name: "Xã Nhuận Trạch",
						type: "Xã",
					},
					{
						level3_id: "04957",
						name: "Xã Cao Sơn",
						type: "Xã",
					},
					{
						level3_id: "04960",
						name: "Xã Cư Yên",
						type: "Xã",
					},
					{
						level3_id: "04969",
						name: "Xã Liên Sơn",
						type: "Xã",
					},
					{
						level3_id: "05008",
						name: "Xã Cao Dương",
						type: "Xã",
					},
					{
						level3_id: "05041",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "05047",
						name: "Xã Thanh Cao",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "153",
				name: "Huyện Kim Bôi",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04978",
						name: "Thị trấn Bo",
						type: "Thị trấn",
					},
					{
						level3_id: "04984",
						name: "Xã Đú Sáng",
						type: "Xã",
					},
					{
						level3_id: "04987",
						name: "Xã Hùng Sơn",
						type: "Xã",
					},
					{
						level3_id: "04990",
						name: "Xã Bình Sơn",
						type: "Xã",
					},
					{
						level3_id: "04999",
						name: "Xã Tú Sơn",
						type: "Xã",
					},
					{
						level3_id: "05005",
						name: "Xã Vĩnh Tiến",
						type: "Xã",
					},
					{
						level3_id: "05014",
						name: "Xã Đông Bắc",
						type: "Xã",
					},
					{
						level3_id: "05017",
						name: "Xã Xuân Thủy",
						type: "Xã",
					},
					{
						level3_id: "05026",
						name: "Xã Vĩnh Đồng",
						type: "Xã",
					},
					{
						level3_id: "05035",
						name: "Xã Kim Lập",
						type: "Xã",
					},
					{
						level3_id: "05038",
						name: "Xã Hợp Tiến",
						type: "Xã",
					},
					{
						level3_id: "05065",
						name: "Xã Kim Bôi",
						type: "Xã",
					},
					{
						level3_id: "05068",
						name: "Xã Nam Thượng",
						type: "Xã",
					},
					{
						level3_id: "05077",
						name: "Xã Cuối Hạ",
						type: "Xã",
					},
					{
						level3_id: "05080",
						name: "Xã Sào Báy",
						type: "Xã",
					},
					{
						level3_id: "05083",
						name: "Xã Mi Hòa",
						type: "Xã",
					},
					{
						level3_id: "05086",
						name: "Xã Nuông Dăm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "154",
				name: "Huyện Cao Phong",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05089",
						name: "Thị trấn Cao Phong",
						type: "Thị trấn",
					},
					{
						level3_id: "05092",
						name: "Xã Bình Thanh",
						type: "Xã",
					},
					{
						level3_id: "05095",
						name: "Xã Thung Nai",
						type: "Xã",
					},
					{
						level3_id: "05098",
						name: "Xã Bắc Phong",
						type: "Xã",
					},
					{
						level3_id: "05101",
						name: "Xã Thu Phong",
						type: "Xã",
					},
					{
						level3_id: "05104",
						name: "Xã Hợp Phong",
						type: "Xã",
					},
					{
						level3_id: "05110",
						name: "Xã Tây Phong",
						type: "Xã",
					},
					{
						level3_id: "05116",
						name: "Xã Dũng Phong",
						type: "Xã",
					},
					{
						level3_id: "05119",
						name: "Xã Nam Phong",
						type: "Xã",
					},
					{
						level3_id: "05125",
						name: "Xã Thạch Yên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "155",
				name: "Huyện Tân Lạc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05128",
						name: "Thị trấn Mãn Đức",
						type: "Thị trấn",
					},
					{
						level3_id: "05134",
						name: "Xã Suối Hoa",
						type: "Xã",
					},
					{
						level3_id: "05137",
						name: "Xã Phú Vinh",
						type: "Xã",
					},
					{
						level3_id: "05140",
						name: "Xã Phú Cường",
						type: "Xã",
					},
					{
						level3_id: "05143",
						name: "Xã Mỹ Hòa",
						type: "Xã",
					},
					{
						level3_id: "05152",
						name: "Xã Quyết Chiến",
						type: "Xã",
					},
					{
						level3_id: "05158",
						name: "Xã Phong Phú",
						type: "Xã",
					},
					{
						level3_id: "05164",
						name: "Xã Tử Nê",
						type: "Xã",
					},
					{
						level3_id: "05167",
						name: "Xã Thanh Hối",
						type: "Xã",
					},
					{
						level3_id: "05170",
						name: "Xã Ngọc Mỹ",
						type: "Xã",
					},
					{
						level3_id: "05173",
						name: "Xã Đông Lai",
						type: "Xã",
					},
					{
						level3_id: "05176",
						name: "Xã Vân Sơn",
						type: "Xã",
					},
					{
						level3_id: "05182",
						name: "Xã Nhân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "05191",
						name: "Xã Lỗ Sơn",
						type: "Xã",
					},
					{
						level3_id: "05194",
						name: "Xã Ngổ Luông",
						type: "Xã",
					},
					{
						level3_id: "05197",
						name: "Xã Gia Mô",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "156",
				name: "Huyện Mai Châu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04882",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "05200",
						name: "Thị trấn Mai Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "05206",
						name: "Xã Sơn Thủy",
						type: "Xã",
					},
					{
						level3_id: "05209",
						name: "Xã Pà Cò",
						type: "Xã",
					},
					{
						level3_id: "05212",
						name: "Xã Hang Kia",
						type: "Xã",
					},
					{
						level3_id: "05221",
						name: "Xã Đồng Tân",
						type: "Xã",
					},
					{
						level3_id: "05224",
						name: "Xã Cun Pheo",
						type: "Xã",
					},
					{
						level3_id: "05227",
						name: "Xã Bao La",
						type: "Xã",
					},
					{
						level3_id: "05233",
						name: "Xã Tòng Đậu",
						type: "Xã",
					},
					{
						level3_id: "05242",
						name: "Xã Nà Phòn",
						type: "Xã",
					},
					{
						level3_id: "05245",
						name: "Xã Săm Khóe",
						type: "Xã",
					},
					{
						level3_id: "05248",
						name: "Xã Chiềng Châu",
						type: "Xã",
					},
					{
						level3_id: "05251",
						name: "Xã Mai Hạ",
						type: "Xã",
					},
					{
						level3_id: "05254",
						name: "Xã Thành Sơn",
						type: "Xã",
					},
					{
						level3_id: "05257",
						name: "Xã Mai Hịch",
						type: "Xã",
					},
					{
						level3_id: "05263",
						name: "Xã Vạn Mai",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "157",
				name: "Huyện Lạc Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05266",
						name: "Thị trấn Vụ Bản",
						type: "Thị trấn",
					},
					{
						level3_id: "05269",
						name: "Xã Quý Hòa",
						type: "Xã",
					},
					{
						level3_id: "05272",
						name: "Xã Miền Đồi",
						type: "Xã",
					},
					{
						level3_id: "05275",
						name: "Xã Mỹ Thành",
						type: "Xã",
					},
					{
						level3_id: "05278",
						name: "Xã Tuân Đạo",
						type: "Xã",
					},
					{
						level3_id: "05281",
						name: "Xã Văn Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "05284",
						name: "Xã Văn Sơn",
						type: "Xã",
					},
					{
						level3_id: "05287",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "05290",
						name: "Xã Nhân Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "05293",
						name: "Xã Thượng Cốc",
						type: "Xã",
					},
					{
						level3_id: "05299",
						name: "Xã Quyết Thắng",
						type: "Xã",
					},
					{
						level3_id: "05302",
						name: "Xã Xuất Hóa",
						type: "Xã",
					},
					{
						level3_id: "05305",
						name: "Xã Yên Phú",
						type: "Xã",
					},
					{
						level3_id: "05308",
						name: "Xã Bình Hẻm",
						type: "Xã",
					},
					{
						level3_id: "05320",
						name: "Xã Định Cư",
						type: "Xã",
					},
					{
						level3_id: "05323",
						name: "Xã Chí Đạo",
						type: "Xã",
					},
					{
						level3_id: "05329",
						name: "Xã Ngọc Sơn",
						type: "Xã",
					},
					{
						level3_id: "05332",
						name: "Xã Hương Nhượng",
						type: "Xã",
					},
					{
						level3_id: "05335",
						name: "Xã Vũ Bình",
						type: "Xã",
					},
					{
						level3_id: "05338",
						name: "Xã Tự Do",
						type: "Xã",
					},
					{
						level3_id: "05341",
						name: "Xã Yên Nghiệp",
						type: "Xã",
					},
					{
						level3_id: "05344",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "05347",
						name: "Xã Ân Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "05350",
						name: "Xã Ngọc Lâu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "158",
				name: "Huyện Yên Thủy",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05353",
						name: "Thị trấn Hàng Trạm",
						type: "Thị trấn",
					},
					{
						level3_id: "05356",
						name: "Xã Lạc Sỹ",
						type: "Xã",
					},
					{
						level3_id: "05362",
						name: "Xã Lạc Lương",
						type: "Xã",
					},
					{
						level3_id: "05365",
						name: "Xã Bảo Hiệu",
						type: "Xã",
					},
					{
						level3_id: "05368",
						name: "Xã Đa Phúc",
						type: "Xã",
					},
					{
						level3_id: "05371",
						name: "Xã Hữu Lợi",
						type: "Xã",
					},
					{
						level3_id: "05374",
						name: "Xã Lạc Thịnh",
						type: "Xã",
					},
					{
						level3_id: "05380",
						name: "Xã Đoàn Kết",
						type: "Xã",
					},
					{
						level3_id: "05383",
						name: "Xã Phú Lai",
						type: "Xã",
					},
					{
						level3_id: "05386",
						name: "Xã Yên Trị",
						type: "Xã",
					},
					{
						level3_id: "05389",
						name: "Xã Ngọc Lương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "159",
				name: "Huyện Lạc Thủy",
				type: "Huyện",
				level3s: [
					{
						level3_id: "04981",
						name: "Thị trấn Ba Hàng Đồi",
						type: "Thị trấn",
					},
					{
						level3_id: "05392",
						name: "Thị trấn Chi Nê",
						type: "Thị trấn",
					},
					{
						level3_id: "05395",
						name: "Xã Phú Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "05398",
						name: "Xã Phú Thành",
						type: "Xã",
					},
					{
						level3_id: "05404",
						name: "Xã Hưng Thi",
						type: "Xã",
					},
					{
						level3_id: "05413",
						name: "Xã Khoan Dụ",
						type: "Xã",
					},
					{
						level3_id: "05419",
						name: "Xã Đồng Tâm",
						type: "Xã",
					},
					{
						level3_id: "05422",
						name: "Xã Yên Bồng",
						type: "Xã",
					},
					{
						level3_id: "05425",
						name: "Xã Thống Nhất",
						type: "Xã",
					},
					{
						level3_id: "05428",
						name: "Xã An Bình",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "19",
		name: "Tỉnh Thái Nguyên",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "164",
				name: "Thành phố Thái Nguyên",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "05431",
						name: "Phường Quán Triều",
						type: "Phường",
					},
					{
						level3_id: "05434",
						name: "Phường Quang Vinh",
						type: "Phường",
					},
					{
						level3_id: "05437",
						name: "Phường Túc Duyên",
						type: "Phường",
					},
					{
						level3_id: "05440",
						name: "Phường Hoàng Văn Thụ",
						type: "Phường",
					},
					{
						level3_id: "05443",
						name: "Phường Trưng Vương",
						type: "Phường",
					},
					{
						level3_id: "05446",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "05449",
						name: "Phường Phan Đình Phùng",
						type: "Phường",
					},
					{
						level3_id: "05452",
						name: "Phường Tân Thịnh",
						type: "Phường",
					},
					{
						level3_id: "05455",
						name: "Phường Thịnh Đán",
						type: "Phường",
					},
					{
						level3_id: "05458",
						name: "Phường Đồng Quang",
						type: "Phường",
					},
					{
						level3_id: "05461",
						name: "Phường Gia Sàng",
						type: "Phường",
					},
					{
						level3_id: "05464",
						name: "Phường Tân Lập",
						type: "Phường",
					},
					{
						level3_id: "05467",
						name: "Phường Cam Giá",
						type: "Phường",
					},
					{
						level3_id: "05470",
						name: "Phường Phú Xá",
						type: "Phường",
					},
					{
						level3_id: "05473",
						name: "Phường Hương Sơn",
						type: "Phường",
					},
					{
						level3_id: "05476",
						name: "Phường Trung Thành",
						type: "Phường",
					},
					{
						level3_id: "05479",
						name: "Phường Tân Thành",
						type: "Phường",
					},
					{
						level3_id: "05482",
						name: "Phường Tân Long",
						type: "Phường",
					},
					{
						level3_id: "05485",
						name: "Xã Phúc Hà",
						type: "Xã",
					},
					{
						level3_id: "05488",
						name: "Xã Phúc Xuân",
						type: "Xã",
					},
					{
						level3_id: "05491",
						name: "Xã Quyết Thắng",
						type: "Xã",
					},
					{
						level3_id: "05494",
						name: "Xã Phúc Trìu",
						type: "Xã",
					},
					{
						level3_id: "05497",
						name: "Xã Thịnh Đức",
						type: "Xã",
					},
					{
						level3_id: "05500",
						name: "Phường Tích Lương",
						type: "Phường",
					},
					{
						level3_id: "05503",
						name: "Xã Tân Cương",
						type: "Xã",
					},
					{
						level3_id: "05653",
						name: "Xã Sơn Cẩm",
						type: "Xã",
					},
					{
						level3_id: "05659",
						name: "Phường Chùa Hang",
						type: "Phường",
					},
					{
						level3_id: "05695",
						name: "Xã Cao Ngạn",
						type: "Xã",
					},
					{
						level3_id: "05701",
						name: "Xã Linh Sơn",
						type: "Xã",
					},
					{
						level3_id: "05710",
						name: "Phường Đồng Bẩm",
						type: "Phường",
					},
					{
						level3_id: "05713",
						name: "Xã Huống Thượng",
						type: "Xã",
					},
					{
						level3_id: "05914",
						name: "Xã Đồng Liên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "165",
				name: "Thành phố Sông Công",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "05506",
						name: "Phường Lương Sơn",
						type: "Phường",
					},
					{
						level3_id: "05509",
						name: "Phường Châu Sơn",
						type: "Phường",
					},
					{
						level3_id: "05512",
						name: "Phường Mỏ Chè",
						type: "Phường",
					},
					{
						level3_id: "05515",
						name: "Phường Cải Đan",
						type: "Phường",
					},
					{
						level3_id: "05518",
						name: "Phường Thắng Lợi",
						type: "Phường",
					},
					{
						level3_id: "05521",
						name: "Phường Phố Cò",
						type: "Phường",
					},
					{
						level3_id: "05527",
						name: "Xã Tân Quang",
						type: "Xã",
					},
					{
						level3_id: "05528",
						name: "Phường Bách Quang",
						type: "Phường",
					},
					{
						level3_id: "05530",
						name: "Xã Bình Sơn",
						type: "Xã",
					},
					{
						level3_id: "05533",
						name: "Xã Bá Xuyên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "167",
				name: "Huyện Định Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05536",
						name: "Thị trấn Chợ Chu",
						type: "Thị trấn",
					},
					{
						level3_id: "05539",
						name: "Xã Linh Thông",
						type: "Xã",
					},
					{
						level3_id: "05542",
						name: "Xã Lam Vỹ",
						type: "Xã",
					},
					{
						level3_id: "05545",
						name: "Xã Quy Kỳ",
						type: "Xã",
					},
					{
						level3_id: "05548",
						name: "Xã Tân Thịnh",
						type: "Xã",
					},
					{
						level3_id: "05551",
						name: "Xã Kim Phượng",
						type: "Xã",
					},
					{
						level3_id: "05554",
						name: "Xã Bảo Linh",
						type: "Xã",
					},
					{
						level3_id: "05560",
						name: "Xã Phúc Chu",
						type: "Xã",
					},
					{
						level3_id: "05563",
						name: "Xã Tân Dương",
						type: "Xã",
					},
					{
						level3_id: "05566",
						name: "Xã Phượng Tiến",
						type: "Xã",
					},
					{
						level3_id: "05569",
						name: "Xã Bảo Cường",
						type: "Xã",
					},
					{
						level3_id: "05572",
						name: "Xã Đồng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "05575",
						name: "Xã Định Biên",
						type: "Xã",
					},
					{
						level3_id: "05578",
						name: "Xã Thanh Định",
						type: "Xã",
					},
					{
						level3_id: "05581",
						name: "Xã Trung Hội",
						type: "Xã",
					},
					{
						level3_id: "05584",
						name: "Xã Trung Lương",
						type: "Xã",
					},
					{
						level3_id: "05587",
						name: "Xã Bình Yên",
						type: "Xã",
					},
					{
						level3_id: "05590",
						name: "Xã Điềm Mặc",
						type: "Xã",
					},
					{
						level3_id: "05593",
						name: "Xã Phú Tiến",
						type: "Xã",
					},
					{
						level3_id: "05596",
						name: "Xã Bộc Nhiêu",
						type: "Xã",
					},
					{
						level3_id: "05599",
						name: "Xã Sơn Phú",
						type: "Xã",
					},
					{
						level3_id: "05602",
						name: "Xã Phú Đình",
						type: "Xã",
					},
					{
						level3_id: "05605",
						name: "Xã Bình Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "168",
				name: "Huyện Phú Lương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05608",
						name: "Thị trấn Giang Tiên",
						type: "Thị trấn",
					},
					{
						level3_id: "05611",
						name: "Thị trấn Đu",
						type: "Thị trấn",
					},
					{
						level3_id: "05614",
						name: "Xã Yên Ninh",
						type: "Xã",
					},
					{
						level3_id: "05617",
						name: "Xã Yên Trạch",
						type: "Xã",
					},
					{
						level3_id: "05620",
						name: "Xã Yên Đổ",
						type: "Xã",
					},
					{
						level3_id: "05623",
						name: "Xã Yên Lạc",
						type: "Xã",
					},
					{
						level3_id: "05626",
						name: "Xã Ôn Lương",
						type: "Xã",
					},
					{
						level3_id: "05629",
						name: "Xã Động Đạt",
						type: "Xã",
					},
					{
						level3_id: "05632",
						name: "Xã Phủ Lý",
						type: "Xã",
					},
					{
						level3_id: "05635",
						name: "Xã Phú Đô",
						type: "Xã",
					},
					{
						level3_id: "05638",
						name: "Xã Hợp Thành",
						type: "Xã",
					},
					{
						level3_id: "05641",
						name: "Xã Tức Tranh",
						type: "Xã",
					},
					{
						level3_id: "05644",
						name: "Xã Phấn Mễ",
						type: "Xã",
					},
					{
						level3_id: "05647",
						name: "Xã Vô Tranh",
						type: "Xã",
					},
					{
						level3_id: "05650",
						name: "Xã Cổ Lũng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "169",
				name: "Huyện Đồng Hỷ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05656",
						name: "Thị trấn Sông Cầu",
						type: "Thị trấn",
					},
					{
						level3_id: "05662",
						name: "Thị trấn Trại Cau",
						type: "Thị trấn",
					},
					{
						level3_id: "05665",
						name: "Xã Văn Lăng",
						type: "Xã",
					},
					{
						level3_id: "05668",
						name: "Xã Tân Long",
						type: "Xã",
					},
					{
						level3_id: "05671",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "05674",
						name: "Xã Quang Sơn",
						type: "Xã",
					},
					{
						level3_id: "05677",
						name: "Xã Minh Lập",
						type: "Xã",
					},
					{
						level3_id: "05680",
						name: "Xã Văn Hán",
						type: "Xã",
					},
					{
						level3_id: "05683",
						name: "Xã Hóa Trung",
						type: "Xã",
					},
					{
						level3_id: "05686",
						name: "Xã Khe Mo",
						type: "Xã",
					},
					{
						level3_id: "05689",
						name: "Xã Cây Thị",
						type: "Xã",
					},
					{
						level3_id: "05692",
						name: "Xã Hóa Thượng",
						type: "Xã",
					},
					{
						level3_id: "05698",
						name: "Xã Hợp Tiến",
						type: "Xã",
					},
					{
						level3_id: "05704",
						name: "Xã Tân Lợi",
						type: "Xã",
					},
					{
						level3_id: "05707",
						name: "Xã Nam Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "170",
				name: "Huyện Võ Nhai",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05716",
						name: "Thị trấn Đình Cả",
						type: "Thị trấn",
					},
					{
						level3_id: "05719",
						name: "Xã Sảng Mộc",
						type: "Xã",
					},
					{
						level3_id: "05722",
						name: "Xã Nghinh Tường",
						type: "Xã",
					},
					{
						level3_id: "05725",
						name: "Xã Thần Xa",
						type: "Xã",
					},
					{
						level3_id: "05728",
						name: "Xã Vũ Chấn",
						type: "Xã",
					},
					{
						level3_id: "05731",
						name: "Xã Thượng Nung",
						type: "Xã",
					},
					{
						level3_id: "05734",
						name: "Xã Phú Thượng",
						type: "Xã",
					},
					{
						level3_id: "05737",
						name: "Xã Cúc Đường",
						type: "Xã",
					},
					{
						level3_id: "05740",
						name: "Xã La Hiên",
						type: "Xã",
					},
					{
						level3_id: "05743",
						name: "Xã Lâu Thượng",
						type: "Xã",
					},
					{
						level3_id: "05746",
						name: "Xã Tràng Xá",
						type: "Xã",
					},
					{
						level3_id: "05749",
						name: "Xã Phương Giao",
						type: "Xã",
					},
					{
						level3_id: "05752",
						name: "Xã Liên Minh",
						type: "Xã",
					},
					{
						level3_id: "05755",
						name: "Xã Dân Tiến",
						type: "Xã",
					},
					{
						level3_id: "05758",
						name: "Xã Bình Long",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "171",
				name: "Huyện Đại Từ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05761",
						name: "Thị trấn Hùng Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "05764",
						name: "Thị trấn Quân Chu",
						type: "Thị trấn",
					},
					{
						level3_id: "05767",
						name: "Xã Phúc Lương",
						type: "Xã",
					},
					{
						level3_id: "05770",
						name: "Xã Minh Tiến",
						type: "Xã",
					},
					{
						level3_id: "05773",
						name: "Xã Yên Lãng",
						type: "Xã",
					},
					{
						level3_id: "05776",
						name: "Xã Đức Lương",
						type: "Xã",
					},
					{
						level3_id: "05779",
						name: "Xã Phú Cường",
						type: "Xã",
					},
					{
						level3_id: "05782",
						name: "Xã Na Mao",
						type: "Xã",
					},
					{
						level3_id: "05785",
						name: "Xã Phú Lạc",
						type: "Xã",
					},
					{
						level3_id: "05788",
						name: "Xã Tân Linh",
						type: "Xã",
					},
					{
						level3_id: "05791",
						name: "Xã Phú Thịnh",
						type: "Xã",
					},
					{
						level3_id: "05794",
						name: "Xã Phục Linh",
						type: "Xã",
					},
					{
						level3_id: "05797",
						name: "Xã Phú Xuyên",
						type: "Xã",
					},
					{
						level3_id: "05800",
						name: "Xã Bản Ngoại",
						type: "Xã",
					},
					{
						level3_id: "05803",
						name: "Xã Tiên Hội",
						type: "Xã",
					},
					{
						level3_id: "05809",
						name: "Xã Cù Vân",
						type: "Xã",
					},
					{
						level3_id: "05812",
						name: "Xã Hà Thượng",
						type: "Xã",
					},
					{
						level3_id: "05815",
						name: "Xã La Bằng",
						type: "Xã",
					},
					{
						level3_id: "05818",
						name: "Xã Hoàng Nông",
						type: "Xã",
					},
					{
						level3_id: "05821",
						name: "Xã Khôi Kỳ",
						type: "Xã",
					},
					{
						level3_id: "05824",
						name: "Xã An Khánh",
						type: "Xã",
					},
					{
						level3_id: "05827",
						name: "Xã Tân Thái",
						type: "Xã",
					},
					{
						level3_id: "05830",
						name: "Xã Bình Thuận",
						type: "Xã",
					},
					{
						level3_id: "05833",
						name: "Xã Lục Ba",
						type: "Xã",
					},
					{
						level3_id: "05836",
						name: "Xã Mỹ Yên",
						type: "Xã",
					},
					{
						level3_id: "05839",
						name: "Xã Vạn Thọ",
						type: "Xã",
					},
					{
						level3_id: "05842",
						name: "Xã Văn Yên",
						type: "Xã",
					},
					{
						level3_id: "05845",
						name: "Xã Ký Phú",
						type: "Xã",
					},
					{
						level3_id: "05848",
						name: "Xã Cát Nê",
						type: "Xã",
					},
					{
						level3_id: "05851",
						name: "Xã Quân Chu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "172",
				name: "Thị xã Phổ Yên",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "05854",
						name: "Phường Bãi Bông",
						type: "Phường",
					},
					{
						level3_id: "05857",
						name: "Phường Bắc Sơn",
						type: "Phường",
					},
					{
						level3_id: "05860",
						name: "Phường Ba Hàng",
						type: "Phường",
					},
					{
						level3_id: "05863",
						name: "Xã Phúc Tân",
						type: "Xã",
					},
					{
						level3_id: "05866",
						name: "Xã Phúc Thuận",
						type: "Xã",
					},
					{
						level3_id: "05869",
						name: "Xã Hồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "05872",
						name: "Xã Minh Đức",
						type: "Xã",
					},
					{
						level3_id: "05875",
						name: "Xã Đắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "05878",
						name: "Phường Đồng Tiến",
						type: "Phường",
					},
					{
						level3_id: "05881",
						name: "Xã Thành Công",
						type: "Xã",
					},
					{
						level3_id: "05884",
						name: "Xã Tiên Phong",
						type: "Xã",
					},
					{
						level3_id: "05887",
						name: "Xã Vạn Phái",
						type: "Xã",
					},
					{
						level3_id: "05890",
						name: "Xã Nam Tiến",
						type: "Xã",
					},
					{
						level3_id: "05893",
						name: "Xã Tân Hương",
						type: "Xã",
					},
					{
						level3_id: "05896",
						name: "Xã Đông Cao",
						type: "Xã",
					},
					{
						level3_id: "05899",
						name: "Xã Trung Thành",
						type: "Xã",
					},
					{
						level3_id: "05902",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "05905",
						name: "Xã Thuận Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "173",
				name: "Huyện Phú Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05908",
						name: "Thị trấn Hương Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "05911",
						name: "Xã Bàn Đạt",
						type: "Xã",
					},
					{
						level3_id: "05917",
						name: "Xã Tân Khánh",
						type: "Xã",
					},
					{
						level3_id: "05920",
						name: "Xã Tân Kim",
						type: "Xã",
					},
					{
						level3_id: "05923",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "05926",
						name: "Xã Đào Xá",
						type: "Xã",
					},
					{
						level3_id: "05929",
						name: "Xã Bảo Lý",
						type: "Xã",
					},
					{
						level3_id: "05932",
						name: "Xã Thượng Đình",
						type: "Xã",
					},
					{
						level3_id: "05935",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "05938",
						name: "Xã Nhã Lộng",
						type: "Xã",
					},
					{
						level3_id: "05941",
						name: "Xã Điềm Thụy",
						type: "Xã",
					},
					{
						level3_id: "05944",
						name: "Xã Xuân Phương",
						type: "Xã",
					},
					{
						level3_id: "05947",
						name: "Xã Tân Đức",
						type: "Xã",
					},
					{
						level3_id: "05950",
						name: "Xã Úc Kỳ",
						type: "Xã",
					},
					{
						level3_id: "05953",
						name: "Xã Lương Phú",
						type: "Xã",
					},
					{
						level3_id: "05956",
						name: "Xã Nga My",
						type: "Xã",
					},
					{
						level3_id: "05959",
						name: "Xã Kha Sơn",
						type: "Xã",
					},
					{
						level3_id: "05962",
						name: "Xã Thanh Ninh",
						type: "Xã",
					},
					{
						level3_id: "05965",
						name: "Xã Dương Thành",
						type: "Xã",
					},
					{
						level3_id: "05968",
						name: "Xã Hà Châu",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "20",
		name: "Tỉnh Lạng Sơn",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "178",
				name: "Thành phố Lạng Sơn",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "05971",
						name: "Phường Hoàng Văn Thụ",
						type: "Phường",
					},
					{
						level3_id: "05974",
						name: "Phường Tam Thanh",
						type: "Phường",
					},
					{
						level3_id: "05977",
						name: "Phường Vĩnh Trại",
						type: "Phường",
					},
					{
						level3_id: "05980",
						name: "Phường Đông Kinh",
						type: "Phường",
					},
					{
						level3_id: "05983",
						name: "Phường Chi Lăng",
						type: "Phường",
					},
					{
						level3_id: "05986",
						name: "Xã Hoàng Đồng",
						type: "Xã",
					},
					{
						level3_id: "05989",
						name: "Xã Quảng Lạc",
						type: "Xã",
					},
					{
						level3_id: "05992",
						name: "Xã Mai Pha",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "180",
				name: "Huyện Tràng Định",
				type: "Huyện",
				level3s: [
					{
						level3_id: "05995",
						name: "Thị trấn Thất Khê",
						type: "Thị trấn",
					},
					{
						level3_id: "05998",
						name: "Xã Khánh Long",
						type: "Xã",
					},
					{
						level3_id: "06001",
						name: "Xã Đoàn Kết",
						type: "Xã",
					},
					{
						level3_id: "06004",
						name: "Xã Quốc Khánh",
						type: "Xã",
					},
					{
						level3_id: "06007",
						name: "Xã Vĩnh Tiến",
						type: "Xã",
					},
					{
						level3_id: "06010",
						name: "Xã Cao Minh",
						type: "Xã",
					},
					{
						level3_id: "06013",
						name: "Xã Chí Minh",
						type: "Xã",
					},
					{
						level3_id: "06016",
						name: "Xã Tri Phương",
						type: "Xã",
					},
					{
						level3_id: "06019",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "06022",
						name: "Xã Tân Yên",
						type: "Xã",
					},
					{
						level3_id: "06025",
						name: "Xã Đội Cấn",
						type: "Xã",
					},
					{
						level3_id: "06028",
						name: "Xã Tân Minh",
						type: "Xã",
					},
					{
						level3_id: "06031",
						name: "Xã Kim Đồng",
						type: "Xã",
					},
					{
						level3_id: "06034",
						name: "Xã Chi Lăng",
						type: "Xã",
					},
					{
						level3_id: "06037",
						name: "Xã Trung Thành",
						type: "Xã",
					},
					{
						level3_id: "06040",
						name: "Xã Đại Đồng",
						type: "Xã",
					},
					{
						level3_id: "06043",
						name: "Xã Đào Viên",
						type: "Xã",
					},
					{
						level3_id: "06046",
						name: "Xã Đề Thám",
						type: "Xã",
					},
					{
						level3_id: "06049",
						name: "Xã Kháng Chiến",
						type: "Xã",
					},
					{
						level3_id: "06055",
						name: "Xã Hùng Sơn",
						type: "Xã",
					},
					{
						level3_id: "06058",
						name: "Xã Quốc Việt",
						type: "Xã",
					},
					{
						level3_id: "06061",
						name: "Xã Hùng Việt",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "181",
				name: "Huyện Bình Gia",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06067",
						name: "Xã Hưng Đạo",
						type: "Xã",
					},
					{
						level3_id: "06070",
						name: "Xã Vĩnh Yên",
						type: "Xã",
					},
					{
						level3_id: "06073",
						name: "Xã Hoa Thám",
						type: "Xã",
					},
					{
						level3_id: "06076",
						name: "Xã Quý Hòa",
						type: "Xã",
					},
					{
						level3_id: "06079",
						name: "Xã Hồng Phong",
						type: "Xã",
					},
					{
						level3_id: "06082",
						name: "Xã Yên Lỗ",
						type: "Xã",
					},
					{
						level3_id: "06085",
						name: "Xã Thiện Hòa",
						type: "Xã",
					},
					{
						level3_id: "06088",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "06091",
						name: "Xã Thiện Thuật",
						type: "Xã",
					},
					{
						level3_id: "06094",
						name: "Xã Minh Khai",
						type: "Xã",
					},
					{
						level3_id: "06097",
						name: "Xã Thiện Long",
						type: "Xã",
					},
					{
						level3_id: "06100",
						name: "Xã Hoàng Văn Thụ",
						type: "Xã",
					},
					{
						level3_id: "06103",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "06106",
						name: "Xã Mông Ân",
						type: "Xã",
					},
					{
						level3_id: "06109",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "06112",
						name: "Thị trấn Bình Gia",
						type: "Thị trấn",
					},
					{
						level3_id: "06115",
						name: "Xã Hồng Thái",
						type: "Xã",
					},
					{
						level3_id: "06118",
						name: "Xã Bình La",
						type: "Xã",
					},
					{
						level3_id: "06121",
						name: "Xã Tân Văn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "182",
				name: "Huyện Văn Lãng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06124",
						name: "Thị trấn Na Sầm",
						type: "Thị trấn",
					},
					{
						level3_id: "06127",
						name: "Xã Trùng Khánh",
						type: "Xã",
					},
					{
						level3_id: "06133",
						name: "Xã Bắc La",
						type: "Xã",
					},
					{
						level3_id: "06136",
						name: "Xã Thụy Hùng",
						type: "Xã",
					},
					{
						level3_id: "06139",
						name: "Xã Bắc Hùng",
						type: "Xã",
					},
					{
						level3_id: "06142",
						name: "Xã Tân Tác",
						type: "Xã",
					},
					{
						level3_id: "06148",
						name: "Xã Thanh Long",
						type: "Xã",
					},
					{
						level3_id: "06151",
						name: "Xã Hội Hoan",
						type: "Xã",
					},
					{
						level3_id: "06154",
						name: "Xã Bắc Việt",
						type: "Xã",
					},
					{
						level3_id: "06157",
						name: "Xã Hoàng Việt",
						type: "Xã",
					},
					{
						level3_id: "06160",
						name: "Xã Gia Miễn",
						type: "Xã",
					},
					{
						level3_id: "06163",
						name: "Xã Thành Hòa",
						type: "Xã",
					},
					{
						level3_id: "06166",
						name: "Xã Tân Thanh",
						type: "Xã",
					},
					{
						level3_id: "06172",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "06175",
						name: "Xã Hồng Thái",
						type: "Xã",
					},
					{
						level3_id: "06178",
						name: "Xã  Hoàng Văn Thụ",
						type: "Xã",
					},
					{
						level3_id: "06181",
						name: "Xã Nhạc Kỳ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "183",
				name: "Huyện Cao Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06184",
						name: "Thị trấn Đồng Đăng",
						type: "Thị trấn",
					},
					{
						level3_id: "06187",
						name: "Thị trấn Cao Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "06190",
						name: "Xã Bảo Lâm",
						type: "Xã",
					},
					{
						level3_id: "06193",
						name: "Xã Thanh Lòa",
						type: "Xã",
					},
					{
						level3_id: "06196",
						name: "Xã Cao Lâu",
						type: "Xã",
					},
					{
						level3_id: "06199",
						name: "Xã Thạch Đạn",
						type: "Xã",
					},
					{
						level3_id: "06202",
						name: "Xã Xuất Lễ",
						type: "Xã",
					},
					{
						level3_id: "06205",
						name: "Xã Hồng Phong",
						type: "Xã",
					},
					{
						level3_id: "06208",
						name: "Xã Thụy Hùng",
						type: "Xã",
					},
					{
						level3_id: "06211",
						name: "Xã Lộc Yên",
						type: "Xã",
					},
					{
						level3_id: "06214",
						name: "Xã Phú Xá",
						type: "Xã",
					},
					{
						level3_id: "06217",
						name: "Xã Bình Trung",
						type: "Xã",
					},
					{
						level3_id: "06220",
						name: "Xã Hải Yến",
						type: "Xã",
					},
					{
						level3_id: "06223",
						name: "Xã Hòa Cư",
						type: "Xã",
					},
					{
						level3_id: "06226",
						name: "Xã Hợp Thành",
						type: "Xã",
					},
					{
						level3_id: "06232",
						name: "Xã Công Sơn",
						type: "Xã",
					},
					{
						level3_id: "06235",
						name: "Xã Gia Cát",
						type: "Xã",
					},
					{
						level3_id: "06238",
						name: "Xã Mẫu Sơn",
						type: "Xã",
					},
					{
						level3_id: "06241",
						name: "Xã Xuân Long",
						type: "Xã",
					},
					{
						level3_id: "06244",
						name: "Xã Tân Liên",
						type: "Xã",
					},
					{
						level3_id: "06247",
						name: "Xã Yên Trạch",
						type: "Xã",
					},
					{
						level3_id: "06250",
						name: "Xã Tân Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "184",
				name: "Huyện Văn Quan",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06253",
						name: "Thị trấn Văn Quan",
						type: "Thị trấn",
					},
					{
						level3_id: "06256",
						name: "Xã Trấn Ninh",
						type: "Xã",
					},
					{
						level3_id: "06268",
						name: "Xã Liên Hội",
						type: "Xã",
					},
					{
						level3_id: "06274",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "06277",
						name: "Xã Tú Xuyên",
						type: "Xã",
					},
					{
						level3_id: "06280",
						name: "Xã Điềm He",
						type: "Xã",
					},
					{
						level3_id: "06283",
						name: "Xã An Sơn",
						type: "Xã",
					},
					{
						level3_id: "06286",
						name: "Xã Khánh Khê",
						type: "Xã",
					},
					{
						level3_id: "06292",
						name: "Xã Lương Năng",
						type: "Xã",
					},
					{
						level3_id: "06295",
						name: "Xã Đồng Giáp",
						type: "Xã",
					},
					{
						level3_id: "06298",
						name: "Xã Bình Phúc",
						type: "Xã",
					},
					{
						level3_id: "06301",
						name: "Xã Tràng Các",
						type: "Xã",
					},
					{
						level3_id: "06307",
						name: "Xã Tân Đoàn",
						type: "Xã",
					},
					{
						level3_id: "06313",
						name: "Xã Tri Lễ",
						type: "Xã",
					},
					{
						level3_id: "06316",
						name: "Xã Tràng Phái",
						type: "Xã",
					},
					{
						level3_id: "06319",
						name: "Xã Yên Phúc",
						type: "Xã",
					},
					{
						level3_id: "06322",
						name: "Xã Hữu Lễ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "185",
				name: "Huyện Bắc Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06325",
						name: "Thị trấn Bắc Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "06328",
						name: "Xã Long Đống",
						type: "Xã",
					},
					{
						level3_id: "06331",
						name: "Xã Vạn Thủy",
						type: "Xã",
					},
					{
						level3_id: "06337",
						name: "Xã Đồng ý",
						type: "Xã",
					},
					{
						level3_id: "06340",
						name: "Xã Tân Tri",
						type: "Xã",
					},
					{
						level3_id: "06343",
						name: "Xã Bắc Quỳnh",
						type: "Xã",
					},
					{
						level3_id: "06349",
						name: "Xã Hưng Vũ",
						type: "Xã",
					},
					{
						level3_id: "06352",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "06355",
						name: "Xã Vũ Sơn",
						type: "Xã",
					},
					{
						level3_id: "06358",
						name: "Xã Chiêu Vũ",
						type: "Xã",
					},
					{
						level3_id: "06361",
						name: "Xã Tân Hương",
						type: "Xã",
					},
					{
						level3_id: "06364",
						name: "Xã Chiến Thắng",
						type: "Xã",
					},
					{
						level3_id: "06367",
						name: "Xã Vũ Lăng",
						type: "Xã",
					},
					{
						level3_id: "06370",
						name: "Xã Trấn Yên",
						type: "Xã",
					},
					{
						level3_id: "06373",
						name: "Xã Vũ Lễ",
						type: "Xã",
					},
					{
						level3_id: "06376",
						name: "Xã Nhất Hòa",
						type: "Xã",
					},
					{
						level3_id: "06379",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "06382",
						name: "Xã Nhất Tiến",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "186",
				name: "Huyện Hữu Lũng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06385",
						name: "Thị trấn Hữu Lũng",
						type: "Thị trấn",
					},
					{
						level3_id: "06388",
						name: "Xã Hữu Liên",
						type: "Xã",
					},
					{
						level3_id: "06391",
						name: "Xã Yên Bình",
						type: "Xã",
					},
					{
						level3_id: "06394",
						name: "Xã Quyết Thắng",
						type: "Xã",
					},
					{
						level3_id: "06397",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "06400",
						name: "Xã Yên Thịnh",
						type: "Xã",
					},
					{
						level3_id: "06403",
						name: "Xã Yên Sơn",
						type: "Xã",
					},
					{
						level3_id: "06406",
						name: "Xã Thiện Tân",
						type: "Xã",
					},
					{
						level3_id: "06412",
						name: "Xã Yên Vượng",
						type: "Xã",
					},
					{
						level3_id: "06415",
						name: "Xã Minh Tiến",
						type: "Xã",
					},
					{
						level3_id: "06418",
						name: "Xã Nhật Tiến",
						type: "Xã",
					},
					{
						level3_id: "06421",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "06424",
						name: "Xã Đồng Tân",
						type: "Xã",
					},
					{
						level3_id: "06427",
						name: "Xã Cai Kinh",
						type: "Xã",
					},
					{
						level3_id: "06430",
						name: "Xã Hòa Lạc",
						type: "Xã",
					},
					{
						level3_id: "06433",
						name: "Xã Vân Nham",
						type: "Xã",
					},
					{
						level3_id: "06436",
						name: "Xã Đồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "06442",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "06445",
						name: "Xã Hòa Sơn",
						type: "Xã",
					},
					{
						level3_id: "06448",
						name: "Xã Minh Sơn",
						type: "Xã",
					},
					{
						level3_id: "06451",
						name: "Xã Hồ Sơn",
						type: "Xã",
					},
					{
						level3_id: "06454",
						name: "Xã Sơn Hà",
						type: "Xã",
					},
					{
						level3_id: "06457",
						name: "Xã Minh Hòa",
						type: "Xã",
					},
					{
						level3_id: "06460",
						name: "Xã Hòa Thắng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "187",
				name: "Huyện Chi Lăng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06463",
						name: "Thị trấn Đồng Mỏ",
						type: "Thị trấn",
					},
					{
						level3_id: "06466",
						name: "Thị trấn Chi Lăng",
						type: "Thị trấn",
					},
					{
						level3_id: "06469",
						name: "Xã Vân An",
						type: "Xã",
					},
					{
						level3_id: "06472",
						name: "Xã Vân Thủy",
						type: "Xã",
					},
					{
						level3_id: "06475",
						name: "Xã Gia Lộc",
						type: "Xã",
					},
					{
						level3_id: "06478",
						name: "Xã Bắc Thủy",
						type: "Xã",
					},
					{
						level3_id: "06481",
						name: "Xã Chiến Thắng",
						type: "Xã",
					},
					{
						level3_id: "06484",
						name: "Xã Mai Sao",
						type: "Xã",
					},
					{
						level3_id: "06487",
						name: "Xã Bằng Hữu",
						type: "Xã",
					},
					{
						level3_id: "06490",
						name: "Xã Thượng Cường",
						type: "Xã",
					},
					{
						level3_id: "06493",
						name: "Xã Bằng Mạc",
						type: "Xã",
					},
					{
						level3_id: "06496",
						name: "Xã Nhân Lý",
						type: "Xã",
					},
					{
						level3_id: "06499",
						name: "Xã Lâm Sơn",
						type: "Xã",
					},
					{
						level3_id: "06502",
						name: "Xã Liên Sơn",
						type: "Xã",
					},
					{
						level3_id: "06505",
						name: "Xã Vạn Linh",
						type: "Xã",
					},
					{
						level3_id: "06508",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "06514",
						name: "Xã Hữu Kiên",
						type: "Xã",
					},
					{
						level3_id: "06517",
						name: "Xã Quan Sơn",
						type: "Xã",
					},
					{
						level3_id: "06520",
						name: "Xã Y Tịch",
						type: "Xã",
					},
					{
						level3_id: "06523",
						name: "Xã Chi Lăng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "188",
				name: "Huyện Lộc Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06526",
						name: "Thị trấn Na Dương",
						type: "Thị trấn",
					},
					{
						level3_id: "06529",
						name: "Thị trấn Lộc Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "06532",
						name: "Xã Mẫu Sơn",
						type: "Xã",
					},
					{
						level3_id: "06541",
						name: "Xã Yên Khoái",
						type: "Xã",
					},
					{
						level3_id: "06544",
						name: "Xã Khánh Xuân",
						type: "Xã",
					},
					{
						level3_id: "06547",
						name: "Xã Tú Mịch",
						type: "Xã",
					},
					{
						level3_id: "06550",
						name: "Xã Hữu Khánh",
						type: "Xã",
					},
					{
						level3_id: "06553",
						name: "Xã Đồng Bục",
						type: "Xã",
					},
					{
						level3_id: "06559",
						name: "Xã Tam Gia",
						type: "Xã",
					},
					{
						level3_id: "06562",
						name: "Xã Tú Đoạn",
						type: "Xã",
					},
					{
						level3_id: "06565",
						name: "Xã Khuất Xá",
						type: "Xã",
					},
					{
						level3_id: "06574",
						name: "Xã Tĩnh Bắc",
						type: "Xã",
					},
					{
						level3_id: "06577",
						name: "Xã Thống Nhất",
						type: "Xã",
					},
					{
						level3_id: "06589",
						name: "Xã Sàn Viên",
						type: "Xã",
					},
					{
						level3_id: "06592",
						name: "Xã Đông Quan",
						type: "Xã",
					},
					{
						level3_id: "06595",
						name: "Xã Minh Hiệp",
						type: "Xã",
					},
					{
						level3_id: "06598",
						name: "Xã Hữu Lân",
						type: "Xã",
					},
					{
						level3_id: "06601",
						name: "Xã Lợi Bác",
						type: "Xã",
					},
					{
						level3_id: "06604",
						name: "Xã Nam Quan",
						type: "Xã",
					},
					{
						level3_id: "06607",
						name: "Xã Xuân Dương",
						type: "Xã",
					},
					{
						level3_id: "06610",
						name: "Xã Ái Quốc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "189",
				name: "Huyện Đình Lập",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06613",
						name: "Thị trấn Đình Lập",
						type: "Thị trấn",
					},
					{
						level3_id: "06616",
						name: "Thị trấn NT Thái Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "06619",
						name: "Xã Bắc Xa",
						type: "Xã",
					},
					{
						level3_id: "06622",
						name: "Xã Bính Xá",
						type: "Xã",
					},
					{
						level3_id: "06625",
						name: "Xã Kiên Mộc",
						type: "Xã",
					},
					{
						level3_id: "06628",
						name: "Xã Đình Lập",
						type: "Xã",
					},
					{
						level3_id: "06631",
						name: "Xã Thái Bình",
						type: "Xã",
					},
					{
						level3_id: "06634",
						name: "Xã Cường Lợi",
						type: "Xã",
					},
					{
						level3_id: "06637",
						name: "Xã Châu Sơn",
						type: "Xã",
					},
					{
						level3_id: "06640",
						name: "Xã Lâm Ca",
						type: "Xã",
					},
					{
						level3_id: "06643",
						name: "Xã Đồng Thắng",
						type: "Xã",
					},
					{
						level3_id: "06646",
						name: "Xã Bắc Lãng",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "22",
		name: "Tỉnh Quảng Ninh",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "193",
				name: "Thành phố Hạ Long",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "06649",
						name: "Phường Hà Khánh",
						type: "Phường",
					},
					{
						level3_id: "06652",
						name: "Phường Hà Phong",
						type: "Phường",
					},
					{
						level3_id: "06655",
						name: "Phường Hà Khẩu",
						type: "Phường",
					},
					{
						level3_id: "06658",
						name: "Phường Cao Xanh",
						type: "Phường",
					},
					{
						level3_id: "06661",
						name: "Phường Giếng Đáy",
						type: "Phường",
					},
					{
						level3_id: "06664",
						name: "Phường Hà Tu",
						type: "Phường",
					},
					{
						level3_id: "06667",
						name: "Phường Hà Trung",
						type: "Phường",
					},
					{
						level3_id: "06670",
						name: "Phường Hà Lầm",
						type: "Phường",
					},
					{
						level3_id: "06673",
						name: "Phường Bãi Cháy",
						type: "Phường",
					},
					{
						level3_id: "06676",
						name: "Phường Cao Thắng",
						type: "Phường",
					},
					{
						level3_id: "06679",
						name: "Phường Hùng Thắng",
						type: "Phường",
					},
					{
						level3_id: "06682",
						name: "Phường Yết Kiêu",
						type: "Phường",
					},
					{
						level3_id: "06685",
						name: "Phường Trần Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "06688",
						name: "Phường Hồng Hải",
						type: "Phường",
					},
					{
						level3_id: "06691",
						name: "Phường Hồng Gai",
						type: "Phường",
					},
					{
						level3_id: "06694",
						name: "Phường Bạch Đằng",
						type: "Phường",
					},
					{
						level3_id: "06697",
						name: "Phường Hồng Hà",
						type: "Phường",
					},
					{
						level3_id: "06700",
						name: "Phường Tuần Châu",
						type: "Phường",
					},
					{
						level3_id: "06703",
						name: "Phường Việt Hưng",
						type: "Phường",
					},
					{
						level3_id: "06706",
						name: "Phường Đại Yên",
						type: "Phường",
					},
					{
						level3_id: "07030",
						name: "Phường Hoành Bồ",
						type: "Phường",
					},
					{
						level3_id: "07033",
						name: "Xã Kỳ Thượng",
						type: "Xã",
					},
					{
						level3_id: "07036",
						name: "Xã Đồng Sơn",
						type: "Xã",
					},
					{
						level3_id: "07039",
						name: "Xã Tân Dân",
						type: "Xã",
					},
					{
						level3_id: "07042",
						name: "Xã Đồng Lâm",
						type: "Xã",
					},
					{
						level3_id: "07045",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "07048",
						name: "Xã Vũ Oai",
						type: "Xã",
					},
					{
						level3_id: "07051",
						name: "Xã Dân Chủ",
						type: "Xã",
					},
					{
						level3_id: "07054",
						name: "Xã Quảng La",
						type: "Xã",
					},
					{
						level3_id: "07057",
						name: "Xã Bằng Cả",
						type: "Xã",
					},
					{
						level3_id: "07060",
						name: "Xã Thống Nhất",
						type: "Xã",
					},
					{
						level3_id: "07063",
						name: "Xã Sơn Dương",
						type: "Xã",
					},
					{
						level3_id: "07066",
						name: "Xã Lê Lợi",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "194",
				name: "Thành phố Móng Cái",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "06709",
						name: "Phường Ka Long",
						type: "Phường",
					},
					{
						level3_id: "06712",
						name: "Phường Trần Phú",
						type: "Phường",
					},
					{
						level3_id: "06715",
						name: "Phường Ninh Dương",
						type: "Phường",
					},
					{
						level3_id: "06718",
						name: "Phường Hoà Lạc",
						type: "Phường",
					},
					{
						level3_id: "06721",
						name: "Phường Trà Cổ",
						type: "Phường",
					},
					{
						level3_id: "06724",
						name: "Xã Hải Sơn",
						type: "Xã",
					},
					{
						level3_id: "06727",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "06730",
						name: "Xã Hải Đông",
						type: "Xã",
					},
					{
						level3_id: "06733",
						name: "Xã Hải Tiến",
						type: "Xã",
					},
					{
						level3_id: "06736",
						name: "Phường Hải Yên",
						type: "Phường",
					},
					{
						level3_id: "06739",
						name: "Xã Quảng Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "06742",
						name: "Phường Hải Hoà",
						type: "Phường",
					},
					{
						level3_id: "06745",
						name: "Xã Hải Xuân",
						type: "Xã",
					},
					{
						level3_id: "06748",
						name: "Xã Vạn Ninh",
						type: "Xã",
					},
					{
						level3_id: "06751",
						name: "Phường Bình Ngọc",
						type: "Phường",
					},
					{
						level3_id: "06754",
						name: "Xã Vĩnh Trung",
						type: "Xã",
					},
					{
						level3_id: "06757",
						name: "Xã Vĩnh Thực",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "195",
				name: "Thành phố Cẩm Phả",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "06760",
						name: "Phường Mông Dương",
						type: "Phường",
					},
					{
						level3_id: "06763",
						name: "Phường Cửa Ông",
						type: "Phường",
					},
					{
						level3_id: "06766",
						name: "Phường Cẩm Sơn",
						type: "Phường",
					},
					{
						level3_id: "06769",
						name: "Phường Cẩm Đông",
						type: "Phường",
					},
					{
						level3_id: "06772",
						name: "Phường Cẩm Phú",
						type: "Phường",
					},
					{
						level3_id: "06775",
						name: "Phường Cẩm Tây",
						type: "Phường",
					},
					{
						level3_id: "06778",
						name: "Phường Quang Hanh",
						type: "Phường",
					},
					{
						level3_id: "06781",
						name: "Phường Cẩm Thịnh",
						type: "Phường",
					},
					{
						level3_id: "06784",
						name: "Phường Cẩm Thủy",
						type: "Phường",
					},
					{
						level3_id: "06787",
						name: "Phường Cẩm Thạch",
						type: "Phường",
					},
					{
						level3_id: "06790",
						name: "Phường Cẩm Thành",
						type: "Phường",
					},
					{
						level3_id: "06793",
						name: "Phường Cẩm Trung",
						type: "Phường",
					},
					{
						level3_id: "06796",
						name: "Phường Cẩm Bình",
						type: "Phường",
					},
					{
						level3_id: "06799",
						name: "Xã Cộng Hòa",
						type: "Xã",
					},
					{
						level3_id: "06802",
						name: "Xã Cẩm Hải",
						type: "Xã",
					},
					{
						level3_id: "06805",
						name: "Xã Dương Huy",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "196",
				name: "Thành phố Uông Bí",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "06808",
						name: "Phường Vàng Danh",
						type: "Phường",
					},
					{
						level3_id: "06811",
						name: "Phường Thanh Sơn",
						type: "Phường",
					},
					{
						level3_id: "06814",
						name: "Phường Bắc Sơn",
						type: "Phường",
					},
					{
						level3_id: "06817",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "06820",
						name: "Phường Trưng Vương",
						type: "Phường",
					},
					{
						level3_id: "06823",
						name: "Phường Nam Khê",
						type: "Phường",
					},
					{
						level3_id: "06826",
						name: "Phường Yên Thanh",
						type: "Phường",
					},
					{
						level3_id: "06829",
						name: "Xã Thượng Yên Công",
						type: "Xã",
					},
					{
						level3_id: "06832",
						name: "Phường Phương Đông",
						type: "Phường",
					},
					{
						level3_id: "06835",
						name: "Phường Phương Nam",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "198",
				name: "Huyện Bình Liêu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06838",
						name: "Thị trấn Bình Liêu",
						type: "Thị trấn",
					},
					{
						level3_id: "06841",
						name: "Xã Hoành Mô",
						type: "Xã",
					},
					{
						level3_id: "06844",
						name: "Xã Đồng Tâm",
						type: "Xã",
					},
					{
						level3_id: "06847",
						name: "Xã Đồng Văn",
						type: "Xã",
					},
					{
						level3_id: "06853",
						name: "Xã Vô Ngại",
						type: "Xã",
					},
					{
						level3_id: "06856",
						name: "Xã Lục Hồn",
						type: "Xã",
					},
					{
						level3_id: "06859",
						name: "Xã Húc Động",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "199",
				name: "Huyện Tiên Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06862",
						name: "Thị trấn Tiên Yên",
						type: "Thị trấn",
					},
					{
						level3_id: "06865",
						name: "Xã Hà Lâu",
						type: "Xã",
					},
					{
						level3_id: "06868",
						name: "Xã Đại Dực",
						type: "Xã",
					},
					{
						level3_id: "06871",
						name: "Xã Phong Dụ",
						type: "Xã",
					},
					{
						level3_id: "06874",
						name: "Xã Điền Xá",
						type: "Xã",
					},
					{
						level3_id: "06877",
						name: "Xã Đông Ngũ",
						type: "Xã",
					},
					{
						level3_id: "06880",
						name: "Xã Yên Than",
						type: "Xã",
					},
					{
						level3_id: "06883",
						name: "Xã Đông Hải",
						type: "Xã",
					},
					{
						level3_id: "06886",
						name: "Xã Hải Lạng",
						type: "Xã",
					},
					{
						level3_id: "06889",
						name: "Xã Tiên Lãng",
						type: "Xã",
					},
					{
						level3_id: "06892",
						name: "Xã Đồng Rui",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "200",
				name: "Huyện Đầm Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06895",
						name: "Thị trấn Đầm Hà",
						type: "Thị trấn",
					},
					{
						level3_id: "06898",
						name: "Xã Quảng Lâm",
						type: "Xã",
					},
					{
						level3_id: "06901",
						name: "Xã Quảng An",
						type: "Xã",
					},
					{
						level3_id: "06904",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "06910",
						name: "Xã Dực Yên",
						type: "Xã",
					},
					{
						level3_id: "06913",
						name: "Xã Quảng Tân",
						type: "Xã",
					},
					{
						level3_id: "06916",
						name: "Xã Đầm Hà",
						type: "Xã",
					},
					{
						level3_id: "06917",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "06919",
						name: "Xã Đại Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "201",
				name: "Huyện Hải Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06922",
						name: "Thị trấn Quảng Hà",
						type: "Thị trấn",
					},
					{
						level3_id: "06925",
						name: "Xã Quảng Đức",
						type: "Xã",
					},
					{
						level3_id: "06928",
						name: "Xã Quảng Sơn",
						type: "Xã",
					},
					{
						level3_id: "06931",
						name: "Xã Quảng Thành",
						type: "Xã",
					},
					{
						level3_id: "06937",
						name: "Xã Quảng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "06940",
						name: "Xã Quảng Minh",
						type: "Xã",
					},
					{
						level3_id: "06943",
						name: "Xã Quảng Chính",
						type: "Xã",
					},
					{
						level3_id: "06946",
						name: "Xã Quảng Long",
						type: "Xã",
					},
					{
						level3_id: "06949",
						name: "Xã Đường Hoa",
						type: "Xã",
					},
					{
						level3_id: "06952",
						name: "Xã Quảng Phong",
						type: "Xã",
					},
					{
						level3_id: "06967",
						name: "Xã Cái Chiên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "202",
				name: "Huyện Ba Chẽ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06970",
						name: "Thị trấn Ba Chẽ",
						type: "Thị trấn",
					},
					{
						level3_id: "06973",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "06976",
						name: "Xã Thanh Lâm",
						type: "Xã",
					},
					{
						level3_id: "06979",
						name: "Xã Đạp Thanh",
						type: "Xã",
					},
					{
						level3_id: "06982",
						name: "Xã Nam Sơn",
						type: "Xã",
					},
					{
						level3_id: "06985",
						name: "Xã Lương Mông",
						type: "Xã",
					},
					{
						level3_id: "06988",
						name: "Xã Đồn Đạc",
						type: "Xã",
					},
					{
						level3_id: "06991",
						name: "Xã Minh Cầm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "203",
				name: "Huyện Vân Đồn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "06994",
						name: "Thị trấn Cái Rồng",
						type: "Thị trấn",
					},
					{
						level3_id: "06997",
						name: "Xã Đài Xuyên",
						type: "Xã",
					},
					{
						level3_id: "07000",
						name: "Xã Bình Dân",
						type: "Xã",
					},
					{
						level3_id: "07003",
						name: "Xã Vạn Yên",
						type: "Xã",
					},
					{
						level3_id: "07006",
						name: "Xã Minh Châu",
						type: "Xã",
					},
					{
						level3_id: "07009",
						name: "Xã Đoàn Kết",
						type: "Xã",
					},
					{
						level3_id: "07012",
						name: "Xã Hạ Long",
						type: "Xã",
					},
					{
						level3_id: "07015",
						name: "Xã Đông Xá",
						type: "Xã",
					},
					{
						level3_id: "07018",
						name: "Xã Bản Sen",
						type: "Xã",
					},
					{
						level3_id: "07021",
						name: "Xã Thắng Lợi",
						type: "Xã",
					},
					{
						level3_id: "07024",
						name: "Xã Quan Lạn",
						type: "Xã",
					},
					{
						level3_id: "07027",
						name: "Xã Ngọc Vừng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "205",
				name: "Thị xã Đông Triều",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "07069",
						name: "Phường Mạo Khê",
						type: "Phường",
					},
					{
						level3_id: "07072",
						name: "Phường Đông Triều",
						type: "Phường",
					},
					{
						level3_id: "07075",
						name: "Xã An Sinh",
						type: "Xã",
					},
					{
						level3_id: "07078",
						name: "Xã Tràng Lương",
						type: "Xã",
					},
					{
						level3_id: "07081",
						name: "Xã Bình Khê",
						type: "Xã",
					},
					{
						level3_id: "07084",
						name: "Xã Việt Dân",
						type: "Xã",
					},
					{
						level3_id: "07087",
						name: "Xã Tân Việt",
						type: "Xã",
					},
					{
						level3_id: "07090",
						name: "Xã Bình Dương",
						type: "Xã",
					},
					{
						level3_id: "07093",
						name: "Phường Đức Chính",
						type: "Phường",
					},
					{
						level3_id: "07096",
						name: "Phường Tràng An",
						type: "Phường",
					},
					{
						level3_id: "07099",
						name: "Xã Nguyễn Huệ",
						type: "Xã",
					},
					{
						level3_id: "07102",
						name: "Xã Thủy An",
						type: "Xã",
					},
					{
						level3_id: "07105",
						name: "Phường Xuân Sơn",
						type: "Phường",
					},
					{
						level3_id: "07108",
						name: "Xã Hồng Thái Tây",
						type: "Xã",
					},
					{
						level3_id: "07111",
						name: "Xã Hồng Thái Đông",
						type: "Xã",
					},
					{
						level3_id: "07114",
						name: "Phường Hoàng Quế",
						type: "Phường",
					},
					{
						level3_id: "07117",
						name: "Phường Yên Thọ",
						type: "Phường",
					},
					{
						level3_id: "07120",
						name: "Phường Hồng Phong",
						type: "Phường",
					},
					{
						level3_id: "07123",
						name: "Phường Kim Sơn",
						type: "Phường",
					},
					{
						level3_id: "07126",
						name: "Phường Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "07129",
						name: "Xã Yên Đức",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "206",
				name: "Thị xã Quảng Yên",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "07132",
						name: "Phường Quảng Yên",
						type: "Phường",
					},
					{
						level3_id: "07135",
						name: "Phường Đông Mai",
						type: "Phường",
					},
					{
						level3_id: "07138",
						name: "Phường Minh Thành",
						type: "Phường",
					},
					{
						level3_id: "07144",
						name: "Xã Sông Khoai",
						type: "Xã",
					},
					{
						level3_id: "07147",
						name: "Xã Hiệp Hòa",
						type: "Xã",
					},
					{
						level3_id: "07150",
						name: "Phường Cộng Hòa",
						type: "Phường",
					},
					{
						level3_id: "07153",
						name: "Xã Tiền An",
						type: "Xã",
					},
					{
						level3_id: "07156",
						name: "Xã Hoàng Tân",
						type: "Xã",
					},
					{
						level3_id: "07159",
						name: "Phường Tân An",
						type: "Phường",
					},
					{
						level3_id: "07162",
						name: "Phường Yên Giang",
						type: "Phường",
					},
					{
						level3_id: "07165",
						name: "Phường Nam Hoà",
						type: "Phường",
					},
					{
						level3_id: "07168",
						name: "Phường Hà An",
						type: "Phường",
					},
					{
						level3_id: "07171",
						name: "Xã Cẩm La",
						type: "Xã",
					},
					{
						level3_id: "07174",
						name: "Phường Phong Hải",
						type: "Phường",
					},
					{
						level3_id: "07177",
						name: "Phường Yên Hải",
						type: "Phường",
					},
					{
						level3_id: "07180",
						name: "Xã Liên Hòa",
						type: "Xã",
					},
					{
						level3_id: "07183",
						name: "Phường Phong Cốc",
						type: "Phường",
					},
					{
						level3_id: "07186",
						name: "Xã Liên Vị",
						type: "Xã",
					},
					{
						level3_id: "07189",
						name: "Xã Tiền Phong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "207",
				name: "Huyện Cô Tô",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07192",
						name: "Thị trấn Cô Tô",
						type: "Thị trấn",
					},
					{
						level3_id: "07195",
						name: "Xã Đồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "07198",
						name: "Xã Thanh Lân",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "24",
		name: "Tỉnh Bắc Giang",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "213",
				name: "Thành phố Bắc Giang",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "07201",
						name: "Phường Thọ Xương",
						type: "Phường",
					},
					{
						level3_id: "07204",
						name: "Phường Trần Nguyên Hãn",
						type: "Phường",
					},
					{
						level3_id: "07207",
						name: "Phường Ngô Quyền",
						type: "Phường",
					},
					{
						level3_id: "07210",
						name: "Phường Hoàng Văn Thụ",
						type: "Phường",
					},
					{
						level3_id: "07213",
						name: "Phường Trần Phú",
						type: "Phường",
					},
					{
						level3_id: "07216",
						name: "Phường Mỹ Độ",
						type: "Phường",
					},
					{
						level3_id: "07219",
						name: "Phường Lê Lợi",
						type: "Phường",
					},
					{
						level3_id: "07222",
						name: "Xã Song Mai",
						type: "Xã",
					},
					{
						level3_id: "07225",
						name: "Phường Xương Giang",
						type: "Phường",
					},
					{
						level3_id: "07228",
						name: "Phường Đa Mai",
						type: "Phường",
					},
					{
						level3_id: "07231",
						name: "Phường Dĩnh Kế",
						type: "Phường",
					},
					{
						level3_id: "07441",
						name: "Xã Dĩnh Trì",
						type: "Xã",
					},
					{
						level3_id: "07687",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "07696",
						name: "Xã Đồng Sơn",
						type: "Xã",
					},
					{
						level3_id: "07699",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "07705",
						name: "Xã Song Khê",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "215",
				name: "Huyện Yên Thế",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07243",
						name: "Xã Đồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "07246",
						name: "Xã Canh Nậu",
						type: "Xã",
					},
					{
						level3_id: "07249",
						name: "Xã Xuân Lương",
						type: "Xã",
					},
					{
						level3_id: "07252",
						name: "Xã Tam Tiến",
						type: "Xã",
					},
					{
						level3_id: "07255",
						name: "Xã Đồng Vương",
						type: "Xã",
					},
					{
						level3_id: "07258",
						name: "Xã Đồng Hưu",
						type: "Xã",
					},
					{
						level3_id: "07260",
						name: "Xã Đồng Tâm",
						type: "Xã",
					},
					{
						level3_id: "07261",
						name: "Xã Tam Hiệp",
						type: "Xã",
					},
					{
						level3_id: "07264",
						name: "Xã Tiến Thắng",
						type: "Xã",
					},
					{
						level3_id: "07267",
						name: "Xã Hồng Kỳ",
						type: "Xã",
					},
					{
						level3_id: "07270",
						name: "Xã Đồng Lạc",
						type: "Xã",
					},
					{
						level3_id: "07273",
						name: "Xã Đông Sơn",
						type: "Xã",
					},
					{
						level3_id: "07276",
						name: "Xã Tân Hiệp",
						type: "Xã",
					},
					{
						level3_id: "07279",
						name: "Xã Hương Vĩ",
						type: "Xã",
					},
					{
						level3_id: "07282",
						name: "Xã Đồng Kỳ",
						type: "Xã",
					},
					{
						level3_id: "07285",
						name: "Xã An Thượng",
						type: "Xã",
					},
					{
						level3_id: "07288",
						name: "Thị trấn Phồn Xương",
						type: "Thị trấn",
					},
					{
						level3_id: "07291",
						name: "Xã Tân Sỏi",
						type: "Xã",
					},
					{
						level3_id: "07294",
						name: "Thị trấn Bố Hạ",
						type: "Thị trấn",
					},
				],
			},
			{
				level2_id: "216",
				name: "Huyện Tân Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07303",
						name: "Xã Lan Giới",
						type: "Xã",
					},
					{
						level3_id: "07306",
						name: "Thị trấn Nhã Nam",
						type: "Thị trấn",
					},
					{
						level3_id: "07309",
						name: "Xã Tân Trung",
						type: "Xã",
					},
					{
						level3_id: "07312",
						name: "Xã Đại Hóa",
						type: "Xã",
					},
					{
						level3_id: "07315",
						name: "Xã Quang Tiến",
						type: "Xã",
					},
					{
						level3_id: "07318",
						name: "Xã Phúc Sơn",
						type: "Xã",
					},
					{
						level3_id: "07321",
						name: "Xã An Dương",
						type: "Xã",
					},
					{
						level3_id: "07324",
						name: "Xã Phúc Hòa",
						type: "Xã",
					},
					{
						level3_id: "07327",
						name: "Xã Liên Sơn",
						type: "Xã",
					},
					{
						level3_id: "07330",
						name: "Xã Hợp Đức",
						type: "Xã",
					},
					{
						level3_id: "07333",
						name: "Xã Lam Cốt",
						type: "Xã",
					},
					{
						level3_id: "07336",
						name: "Xã Cao Xá",
						type: "Xã",
					},
					{
						level3_id: "07339",
						name: "Thị trấn Cao Thượng",
						type: "Thị trấn",
					},
					{
						level3_id: "07342",
						name: "Xã Việt Ngọc",
						type: "Xã",
					},
					{
						level3_id: "07345",
						name: "Xã Song Vân",
						type: "Xã",
					},
					{
						level3_id: "07348",
						name: "Xã Ngọc Châu",
						type: "Xã",
					},
					{
						level3_id: "07351",
						name: "Xã Ngọc Vân",
						type: "Xã",
					},
					{
						level3_id: "07354",
						name: "Xã Việt Lập",
						type: "Xã",
					},
					{
						level3_id: "07357",
						name: "Xã Liên Chung",
						type: "Xã",
					},
					{
						level3_id: "07360",
						name: "Xã Ngọc Thiện",
						type: "Xã",
					},
					{
						level3_id: "07363",
						name: "Xã Ngọc Lý",
						type: "Xã",
					},
					{
						level3_id: "07366",
						name: "Xã Quế Nham",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "217",
				name: "Huyện Lạng Giang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07375",
						name: "Thị trấn Vôi",
						type: "Thị trấn",
					},
					{
						level3_id: "07378",
						name: "Xã Nghĩa Hòa",
						type: "Xã",
					},
					{
						level3_id: "07381",
						name: "Xã Nghĩa Hưng",
						type: "Xã",
					},
					{
						level3_id: "07384",
						name: "Xã Quang Thịnh",
						type: "Xã",
					},
					{
						level3_id: "07387",
						name: "Xã Hương Sơn",
						type: "Xã",
					},
					{
						level3_id: "07390",
						name: "Xã Đào Mỹ",
						type: "Xã",
					},
					{
						level3_id: "07393",
						name: "Xã Tiên Lục",
						type: "Xã",
					},
					{
						level3_id: "07396",
						name: "Xã An Hà",
						type: "Xã",
					},
					{
						level3_id: "07399",
						name: "Thị trấn Kép",
						type: "Thị trấn",
					},
					{
						level3_id: "07402",
						name: "Xã Mỹ Hà",
						type: "Xã",
					},
					{
						level3_id: "07405",
						name: "Xã Hương Lạc",
						type: "Xã",
					},
					{
						level3_id: "07408",
						name: "Xã Dương Đức",
						type: "Xã",
					},
					{
						level3_id: "07411",
						name: "Xã Tân Thanh",
						type: "Xã",
					},
					{
						level3_id: "07414",
						name: "Xã Yên Mỹ",
						type: "Xã",
					},
					{
						level3_id: "07417",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "07420",
						name: "Xã Mỹ Thái",
						type: "Xã",
					},
					{
						level3_id: "07426",
						name: "Xã Xương Lâm",
						type: "Xã",
					},
					{
						level3_id: "07429",
						name: "Xã Xuân Hương",
						type: "Xã",
					},
					{
						level3_id: "07432",
						name: "Xã Tân Dĩnh",
						type: "Xã",
					},
					{
						level3_id: "07435",
						name: "Xã Đại Lâm",
						type: "Xã",
					},
					{
						level3_id: "07438",
						name: "Xã Thái Đào",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "218",
				name: "Huyện Lục Nam",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07444",
						name: "Thị trấn Đồi Ngô",
						type: "Thị trấn",
					},
					{
						level3_id: "07450",
						name: "Xã Đông Hưng",
						type: "Xã",
					},
					{
						level3_id: "07453",
						name: "Xã Đông Phú",
						type: "Xã",
					},
					{
						level3_id: "07456",
						name: "Xã Tam Dị",
						type: "Xã",
					},
					{
						level3_id: "07459",
						name: "Xã Bảo Sơn",
						type: "Xã",
					},
					{
						level3_id: "07462",
						name: "Xã Bảo Đài",
						type: "Xã",
					},
					{
						level3_id: "07465",
						name: "Xã Thanh Lâm",
						type: "Xã",
					},
					{
						level3_id: "07468",
						name: "Xã Tiên Nha",
						type: "Xã",
					},
					{
						level3_id: "07471",
						name: "Xã Trường Giang",
						type: "Xã",
					},
					{
						level3_id: "07477",
						name: "Xã Phương Sơn",
						type: "Xã",
					},
					{
						level3_id: "07480",
						name: "Xã Chu Điện",
						type: "Xã",
					},
					{
						level3_id: "07483",
						name: "Xã Cương Sơn",
						type: "Xã",
					},
					{
						level3_id: "07486",
						name: "Xã Nghĩa Phương",
						type: "Xã",
					},
					{
						level3_id: "07489",
						name: "Xã Vô Tranh",
						type: "Xã",
					},
					{
						level3_id: "07492",
						name: "Xã Bình Sơn",
						type: "Xã",
					},
					{
						level3_id: "07495",
						name: "Xã Lan Mẫu",
						type: "Xã",
					},
					{
						level3_id: "07498",
						name: "Xã Yên Sơn",
						type: "Xã",
					},
					{
						level3_id: "07501",
						name: "Xã Khám Lạng",
						type: "Xã",
					},
					{
						level3_id: "07504",
						name: "Xã Huyền Sơn",
						type: "Xã",
					},
					{
						level3_id: "07507",
						name: "Xã Trường Sơn",
						type: "Xã",
					},
					{
						level3_id: "07510",
						name: "Xã Lục Sơn",
						type: "Xã",
					},
					{
						level3_id: "07513",
						name: "Xã Bắc Lũng",
						type: "Xã",
					},
					{
						level3_id: "07516",
						name: "Xã Vũ Xá",
						type: "Xã",
					},
					{
						level3_id: "07519",
						name: "Xã Cẩm Lý",
						type: "Xã",
					},
					{
						level3_id: "07522",
						name: "Xã Đan Hội",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "219",
				name: "Huyện Lục Ngạn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07525",
						name: "Thị trấn Chũ",
						type: "Thị trấn",
					},
					{
						level3_id: "07528",
						name: "Xã Cấm Sơn",
						type: "Xã",
					},
					{
						level3_id: "07531",
						name: "Xã Tân Sơn",
						type: "Xã",
					},
					{
						level3_id: "07534",
						name: "Xã Phong Minh",
						type: "Xã",
					},
					{
						level3_id: "07537",
						name: "Xã Phong Vân",
						type: "Xã",
					},
					{
						level3_id: "07540",
						name: "Xã Xa Lý",
						type: "Xã",
					},
					{
						level3_id: "07543",
						name: "Xã Hộ Đáp",
						type: "Xã",
					},
					{
						level3_id: "07546",
						name: "Xã Sơn Hải",
						type: "Xã",
					},
					{
						level3_id: "07549",
						name: "Xã Thanh Hải",
						type: "Xã",
					},
					{
						level3_id: "07552",
						name: "Xã Kiên Lao",
						type: "Xã",
					},
					{
						level3_id: "07555",
						name: "Xã Biên Sơn",
						type: "Xã",
					},
					{
						level3_id: "07558",
						name: "Xã Kiên Thành",
						type: "Xã",
					},
					{
						level3_id: "07561",
						name: "Xã Hồng Giang",
						type: "Xã",
					},
					{
						level3_id: "07564",
						name: "Xã Kim Sơn",
						type: "Xã",
					},
					{
						level3_id: "07567",
						name: "Xã Tân Hoa",
						type: "Xã",
					},
					{
						level3_id: "07570",
						name: "Xã Giáp Sơn",
						type: "Xã",
					},
					{
						level3_id: "07573",
						name: "Xã Biển Động",
						type: "Xã",
					},
					{
						level3_id: "07576",
						name: "Xã Quý Sơn",
						type: "Xã",
					},
					{
						level3_id: "07579",
						name: "Xã Trù Hựu",
						type: "Xã",
					},
					{
						level3_id: "07582",
						name: "Xã Phì Điền",
						type: "Xã",
					},
					{
						level3_id: "07588",
						name: "Xã Tân Quang",
						type: "Xã",
					},
					{
						level3_id: "07591",
						name: "Xã Đồng Cốc",
						type: "Xã",
					},
					{
						level3_id: "07594",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "07597",
						name: "Xã Phú Nhuận",
						type: "Xã",
					},
					{
						level3_id: "07600",
						name: "Xã Mỹ An",
						type: "Xã",
					},
					{
						level3_id: "07603",
						name: "Xã Nam Dương",
						type: "Xã",
					},
					{
						level3_id: "07606",
						name: "Xã Tân Mộc",
						type: "Xã",
					},
					{
						level3_id: "07609",
						name: "Xã Đèo Gia",
						type: "Xã",
					},
					{
						level3_id: "07612",
						name: "Xã Phượng Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "220",
				name: "Huyện Sơn Động",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07615",
						name: "Thị trấn An Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "07616",
						name: "Thị trấn Tây Yên Tử",
						type: "Thị trấn",
					},
					{
						level3_id: "07621",
						name: "Xã Vân Sơn",
						type: "Xã",
					},
					{
						level3_id: "07624",
						name: "Xã Hữu Sản",
						type: "Xã",
					},
					{
						level3_id: "07627",
						name: "Xã Đại Sơn",
						type: "Xã",
					},
					{
						level3_id: "07630",
						name: "Xã Phúc Sơn",
						type: "Xã",
					},
					{
						level3_id: "07636",
						name: "Xã Giáo Liêm",
						type: "Xã",
					},
					{
						level3_id: "07642",
						name: "Xã Cẩm Đàn",
						type: "Xã",
					},
					{
						level3_id: "07645",
						name: "Xã An Lạc",
						type: "Xã",
					},
					{
						level3_id: "07648",
						name: "Xã Vĩnh An",
						type: "Xã",
					},
					{
						level3_id: "07651",
						name: "Xã Yên Định",
						type: "Xã",
					},
					{
						level3_id: "07654",
						name: "Xã Lệ Viễn",
						type: "Xã",
					},
					{
						level3_id: "07660",
						name: "Xã An Bá",
						type: "Xã",
					},
					{
						level3_id: "07663",
						name: "Xã Tuấn Đạo",
						type: "Xã",
					},
					{
						level3_id: "07666",
						name: "Xã Dương Hưu",
						type: "Xã",
					},
					{
						level3_id: "07672",
						name: "Xã Long Sơn",
						type: "Xã",
					},
					{
						level3_id: "07678",
						name: "Xã Thanh Luận",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "221",
				name: "Huyện Yên Dũng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07681",
						name: "Thị trấn Nham Biền",
						type: "Thị trấn",
					},
					{
						level3_id: "07682",
						name: "Thị trấn Tân An",
						type: "Thị trấn",
					},
					{
						level3_id: "07684",
						name: "Xã Lão Hộ",
						type: "Xã",
					},
					{
						level3_id: "07690",
						name: "Xã Hương Gián",
						type: "Xã",
					},
					{
						level3_id: "07702",
						name: "Xã Quỳnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "07708",
						name: "Xã Nội Hoàng",
						type: "Xã",
					},
					{
						level3_id: "07711",
						name: "Xã Tiền Phong",
						type: "Xã",
					},
					{
						level3_id: "07714",
						name: "Xã Xuân Phú",
						type: "Xã",
					},
					{
						level3_id: "07717",
						name: "Xã Tân Liễu",
						type: "Xã",
					},
					{
						level3_id: "07720",
						name: "Xã Trí Yên",
						type: "Xã",
					},
					{
						level3_id: "07723",
						name: "Xã Lãng Sơn",
						type: "Xã",
					},
					{
						level3_id: "07726",
						name: "Xã Yên Lư",
						type: "Xã",
					},
					{
						level3_id: "07729",
						name: "Xã Tiến Dũng",
						type: "Xã",
					},
					{
						level3_id: "07735",
						name: "Xã Đức Giang",
						type: "Xã",
					},
					{
						level3_id: "07738",
						name: "Xã Cảnh Thụy",
						type: "Xã",
					},
					{
						level3_id: "07741",
						name: "Xã Tư Mại",
						type: "Xã",
					},
					{
						level3_id: "07747",
						name: "Xã Đồng Việt",
						type: "Xã",
					},
					{
						level3_id: "07750",
						name: "Xã Đồng Phúc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "222",
				name: "Huyện Việt Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07759",
						name: "Xã Thượng Lan",
						type: "Xã",
					},
					{
						level3_id: "07762",
						name: "Xã Việt Tiến",
						type: "Xã",
					},
					{
						level3_id: "07765",
						name: "Xã Nghĩa Trung",
						type: "Xã",
					},
					{
						level3_id: "07768",
						name: "Xã Minh Đức",
						type: "Xã",
					},
					{
						level3_id: "07771",
						name: "Xã Hương Mai",
						type: "Xã",
					},
					{
						level3_id: "07774",
						name: "Xã Tự Lạn",
						type: "Xã",
					},
					{
						level3_id: "07777",
						name: "Thị trấn Bích Động",
						type: "Thị trấn",
					},
					{
						level3_id: "07780",
						name: "Xã Trung Sơn",
						type: "Xã",
					},
					{
						level3_id: "07783",
						name: "Xã Hồng Thái",
						type: "Xã",
					},
					{
						level3_id: "07786",
						name: "Xã Tiên Sơn",
						type: "Xã",
					},
					{
						level3_id: "07789",
						name: "Xã Tăng Tiến",
						type: "Xã",
					},
					{
						level3_id: "07792",
						name: "Xã Quảng Minh",
						type: "Xã",
					},
					{
						level3_id: "07795",
						name: "Thị trấn Nếnh",
						type: "Thị trấn",
					},
					{
						level3_id: "07798",
						name: "Xã Ninh Sơn",
						type: "Xã",
					},
					{
						level3_id: "07801",
						name: "Xã Vân Trung",
						type: "Xã",
					},
					{
						level3_id: "07804",
						name: "Xã Vân Hà",
						type: "Xã",
					},
					{
						level3_id: "07807",
						name: "Xã Quang Châu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "223",
				name: "Huyện Hiệp Hòa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07813",
						name: "Xã Đồng Tân",
						type: "Xã",
					},
					{
						level3_id: "07816",
						name: "Xã Thanh Vân",
						type: "Xã",
					},
					{
						level3_id: "07819",
						name: "Xã Hoàng Lương",
						type: "Xã",
					},
					{
						level3_id: "07822",
						name: "Xã Hoàng Vân",
						type: "Xã",
					},
					{
						level3_id: "07825",
						name: "Xã Hoàng Thanh",
						type: "Xã",
					},
					{
						level3_id: "07828",
						name: "Xã Hoàng An",
						type: "Xã",
					},
					{
						level3_id: "07831",
						name: "Xã Ngọc Sơn",
						type: "Xã",
					},
					{
						level3_id: "07834",
						name: "Xã Thái Sơn",
						type: "Xã",
					},
					{
						level3_id: "07837",
						name: "Xã Hòa Sơn",
						type: "Xã",
					},
					{
						level3_id: "07840",
						name: "Thị trấn Thắng",
						type: "Thị trấn",
					},
					{
						level3_id: "07843",
						name: "Xã Quang Minh",
						type: "Xã",
					},
					{
						level3_id: "07846",
						name: "Xã Lương Phong",
						type: "Xã",
					},
					{
						level3_id: "07849",
						name: "Xã Hùng Sơn",
						type: "Xã",
					},
					{
						level3_id: "07852",
						name: "Xã Đại Thành",
						type: "Xã",
					},
					{
						level3_id: "07855",
						name: "Xã Thường Thắng",
						type: "Xã",
					},
					{
						level3_id: "07858",
						name: "Xã Hợp Thịnh",
						type: "Xã",
					},
					{
						level3_id: "07861",
						name: "Xã Danh Thắng",
						type: "Xã",
					},
					{
						level3_id: "07864",
						name: "Xã Mai Trung",
						type: "Xã",
					},
					{
						level3_id: "07867",
						name: "Xã Đoan Bái",
						type: "Xã",
					},
					{
						level3_id: "07870",
						name: "Xã Bắc Lý",
						type: "Xã",
					},
					{
						level3_id: "07873",
						name: "Xã Xuân Cẩm",
						type: "Xã",
					},
					{
						level3_id: "07876",
						name: "Xã Hương Lâm",
						type: "Xã",
					},
					{
						level3_id: "07879",
						name: "Xã Đông Lỗ",
						type: "Xã",
					},
					{
						level3_id: "07882",
						name: "Xã Châu Minh",
						type: "Xã",
					},
					{
						level3_id: "07885",
						name: "Xã Mai Đình",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "25",
		name: "Tỉnh Phú Thọ",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "227",
				name: "Thành phố Việt Trì",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "07888",
						name: "Phường Dữu Lâu",
						type: "Phường",
					},
					{
						level3_id: "07891",
						name: "Phường Vân Cơ",
						type: "Phường",
					},
					{
						level3_id: "07894",
						name: "Phường Nông Trang",
						type: "Phường",
					},
					{
						level3_id: "07897",
						name: "Phường Tân Dân",
						type: "Phường",
					},
					{
						level3_id: "07900",
						name: "Phường Gia Cẩm",
						type: "Phường",
					},
					{
						level3_id: "07903",
						name: "Phường Tiên Cát",
						type: "Phường",
					},
					{
						level3_id: "07906",
						name: "Phường Thọ Sơn",
						type: "Phường",
					},
					{
						level3_id: "07909",
						name: "Phường Thanh Miếu",
						type: "Phường",
					},
					{
						level3_id: "07912",
						name: "Phường Bạch Hạc",
						type: "Phường",
					},
					{
						level3_id: "07915",
						name: "Phường Bến Gót",
						type: "Phường",
					},
					{
						level3_id: "07918",
						name: "Phường Vân Phú",
						type: "Phường",
					},
					{
						level3_id: "07921",
						name: "Xã Phượng Lâu",
						type: "Xã",
					},
					{
						level3_id: "07924",
						name: "Xã Thụy Vân",
						type: "Xã",
					},
					{
						level3_id: "07927",
						name: "Phường Minh Phương",
						type: "Phường",
					},
					{
						level3_id: "07930",
						name: "Xã Trưng Vương",
						type: "Xã",
					},
					{
						level3_id: "07933",
						name: "Phường Minh Nông",
						type: "Phường",
					},
					{
						level3_id: "07936",
						name: "Xã Sông Lô",
						type: "Xã",
					},
					{
						level3_id: "08281",
						name: "Xã Kim Đức",
						type: "Xã",
					},
					{
						level3_id: "08287",
						name: "Xã Hùng Lô",
						type: "Xã",
					},
					{
						level3_id: "08503",
						name: "Xã Hy Cương",
						type: "Xã",
					},
					{
						level3_id: "08506",
						name: "Xã Chu Hóa",
						type: "Xã",
					},
					{
						level3_id: "08515",
						name: "Xã Thanh Đình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "228",
				name: "Thị xã Phú Thọ",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "07942",
						name: "Phường Hùng Vương",
						type: "Phường",
					},
					{
						level3_id: "07945",
						name: "Phường Phong Châu",
						type: "Phường",
					},
					{
						level3_id: "07948",
						name: "Phường Âu Cơ",
						type: "Phường",
					},
					{
						level3_id: "07951",
						name: "Xã Hà Lộc",
						type: "Xã",
					},
					{
						level3_id: "07954",
						name: "Xã Phú Hộ",
						type: "Xã",
					},
					{
						level3_id: "07957",
						name: "Xã Văn Lung",
						type: "Xã",
					},
					{
						level3_id: "07960",
						name: "Xã Thanh Minh",
						type: "Xã",
					},
					{
						level3_id: "07963",
						name: "Xã Hà Thạch",
						type: "Xã",
					},
					{
						level3_id: "07966",
						name: "Phường Thanh Vinh",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "230",
				name: "Huyện Đoan Hùng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "07969",
						name: "Thị trấn Đoan Hùng",
						type: "Thị trấn",
					},
					{
						level3_id: "07975",
						name: "Xã Hùng Xuyên",
						type: "Xã",
					},
					{
						level3_id: "07981",
						name: "Xã Bằng Luân",
						type: "Xã",
					},
					{
						level3_id: "07984",
						name: "Xã Vân Du",
						type: "Xã",
					},
					{
						level3_id: "07987",
						name: "Xã Phú Lâm",
						type: "Xã",
					},
					{
						level3_id: "07993",
						name: "Xã Minh Lương",
						type: "Xã",
					},
					{
						level3_id: "07996",
						name: "Xã Bằng Doãn",
						type: "Xã",
					},
					{
						level3_id: "07999",
						name: "Xã Chí Đám",
						type: "Xã",
					},
					{
						level3_id: "08005",
						name: "Xã Phúc Lai",
						type: "Xã",
					},
					{
						level3_id: "08008",
						name: "Xã Ngọc Quan",
						type: "Xã",
					},
					{
						level3_id: "08014",
						name: "Xã Hợp Nhất",
						type: "Xã",
					},
					{
						level3_id: "08017",
						name: "Xã Sóc Đăng",
						type: "Xã",
					},
					{
						level3_id: "08023",
						name: "Xã Tây Cốc",
						type: "Xã",
					},
					{
						level3_id: "08026",
						name: "Xã Yên Kiện",
						type: "Xã",
					},
					{
						level3_id: "08029",
						name: "Xã Hùng Long",
						type: "Xã",
					},
					{
						level3_id: "08032",
						name: "Xã Vụ Quang",
						type: "Xã",
					},
					{
						level3_id: "08035",
						name: "Xã Vân Đồn",
						type: "Xã",
					},
					{
						level3_id: "08038",
						name: "Xã Tiêu Sơn",
						type: "Xã",
					},
					{
						level3_id: "08041",
						name: "Xã Minh Tiến",
						type: "Xã",
					},
					{
						level3_id: "08044",
						name: "Xã Minh Phú",
						type: "Xã",
					},
					{
						level3_id: "08047",
						name: "Xã Chân Mộng",
						type: "Xã",
					},
					{
						level3_id: "08050",
						name: "Xã Ca Đình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "231",
				name: "Huyện Hạ Hoà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08053",
						name: "Thị trấn Hạ Hoà",
						type: "Thị trấn",
					},
					{
						level3_id: "08056",
						name: "Xã Đại Phạm",
						type: "Xã",
					},
					{
						level3_id: "08062",
						name: "Xã Đan Thượng",
						type: "Xã",
					},
					{
						level3_id: "08065",
						name: "Xã Hà Lương",
						type: "Xã",
					},
					{
						level3_id: "08071",
						name: "Xã Tứ Hiệp",
						type: "Xã",
					},
					{
						level3_id: "08080",
						name: "Xã Hiền Lương",
						type: "Xã",
					},
					{
						level3_id: "08089",
						name: "Xã Phương Viên",
						type: "Xã",
					},
					{
						level3_id: "08092",
						name: "Xã Gia Điền",
						type: "Xã",
					},
					{
						level3_id: "08095",
						name: "Xã Ấm Hạ",
						type: "Xã",
					},
					{
						level3_id: "08104",
						name: "Xã Hương Xạ",
						type: "Xã",
					},
					{
						level3_id: "08110",
						name: "Xã Xuân Áng",
						type: "Xã",
					},
					{
						level3_id: "08113",
						name: "Xã Yên Kỳ",
						type: "Xã",
					},
					{
						level3_id: "08119",
						name: "Xã Minh Hạc",
						type: "Xã",
					},
					{
						level3_id: "08122",
						name: "Xã Lang Sơn",
						type: "Xã",
					},
					{
						level3_id: "08125",
						name: "Xã Bằng Giã",
						type: "Xã",
					},
					{
						level3_id: "08128",
						name: "Xã Yên Luật",
						type: "Xã",
					},
					{
						level3_id: "08131",
						name: "Xã Vô Tranh",
						type: "Xã",
					},
					{
						level3_id: "08134",
						name: "Xã Văn Lang",
						type: "Xã",
					},
					{
						level3_id: "08140",
						name: "Xã Minh Côi",
						type: "Xã",
					},
					{
						level3_id: "08143",
						name: "Xã Vĩnh Chân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "232",
				name: "Huyện Thanh Ba",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08152",
						name: "Thị trấn Thanh Ba",
						type: "Thị trấn",
					},
					{
						level3_id: "08156",
						name: "Xã Vân Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "08158",
						name: "Xã Đông Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "08161",
						name: "Xã Đại An",
						type: "Xã",
					},
					{
						level3_id: "08164",
						name: "Xã Hanh Cù",
						type: "Xã",
					},
					{
						level3_id: "08170",
						name: "Xã Đồng Xuân",
						type: "Xã",
					},
					{
						level3_id: "08173",
						name: "Xã Quảng Yên",
						type: "Xã",
					},
					{
						level3_id: "08179",
						name: "Xã Ninh Dân",
						type: "Xã",
					},
					{
						level3_id: "08194",
						name: "Xã Võ Lao",
						type: "Xã",
					},
					{
						level3_id: "08197",
						name: "Xã Khải Xuân",
						type: "Xã",
					},
					{
						level3_id: "08200",
						name: "Xã Mạn Lạn",
						type: "Xã",
					},
					{
						level3_id: "08203",
						name: "Xã Hoàng Cương",
						type: "Xã",
					},
					{
						level3_id: "08206",
						name: "Xã Chí Tiên",
						type: "Xã",
					},
					{
						level3_id: "08209",
						name: "Xã Đông Thành",
						type: "Xã",
					},
					{
						level3_id: "08215",
						name: "Xã Sơn Cương",
						type: "Xã",
					},
					{
						level3_id: "08218",
						name: "Xã Thanh Hà",
						type: "Xã",
					},
					{
						level3_id: "08221",
						name: "Xã Đỗ Sơn",
						type: "Xã",
					},
					{
						level3_id: "08224",
						name: "Xã Đỗ Xuyên",
						type: "Xã",
					},
					{
						level3_id: "08227",
						name: "Xã Lương Lỗ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "233",
				name: "Huyện Phù Ninh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08230",
						name: "Thị trấn Phong Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "08233",
						name: "Xã Phú Mỹ",
						type: "Xã",
					},
					{
						level3_id: "08234",
						name: "Xã Lệ Mỹ",
						type: "Xã",
					},
					{
						level3_id: "08236",
						name: "Xã Liên Hoa",
						type: "Xã",
					},
					{
						level3_id: "08239",
						name: "Xã Trạm Thản",
						type: "Xã",
					},
					{
						level3_id: "08242",
						name: "Xã Trị Quận",
						type: "Xã",
					},
					{
						level3_id: "08245",
						name: "Xã Trung Giáp",
						type: "Xã",
					},
					{
						level3_id: "08248",
						name: "Xã Tiên Phú",
						type: "Xã",
					},
					{
						level3_id: "08251",
						name: "Xã Hạ Giáp",
						type: "Xã",
					},
					{
						level3_id: "08254",
						name: "Xã Bảo Thanh",
						type: "Xã",
					},
					{
						level3_id: "08257",
						name: "Xã Phú Lộc",
						type: "Xã",
					},
					{
						level3_id: "08260",
						name: "Xã Gia Thanh",
						type: "Xã",
					},
					{
						level3_id: "08263",
						name: "Xã Tiên Du",
						type: "Xã",
					},
					{
						level3_id: "08266",
						name: "Xã Phú Nham",
						type: "Xã",
					},
					{
						level3_id: "08272",
						name: "Xã An Đạo",
						type: "Xã",
					},
					{
						level3_id: "08275",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "08278",
						name: "Xã Phù Ninh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "234",
				name: "Huyện Yên Lập",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08290",
						name: "Thị trấn Yên Lập",
						type: "Thị trấn",
					},
					{
						level3_id: "08293",
						name: "Xã Mỹ Lung",
						type: "Xã",
					},
					{
						level3_id: "08296",
						name: "Xã Mỹ Lương",
						type: "Xã",
					},
					{
						level3_id: "08299",
						name: "Xã Lương Sơn",
						type: "Xã",
					},
					{
						level3_id: "08302",
						name: "Xã Xuân An",
						type: "Xã",
					},
					{
						level3_id: "08305",
						name: "Xã Xuân Viên",
						type: "Xã",
					},
					{
						level3_id: "08308",
						name: "Xã Xuân Thủy",
						type: "Xã",
					},
					{
						level3_id: "08311",
						name: "Xã Trung Sơn",
						type: "Xã",
					},
					{
						level3_id: "08314",
						name: "Xã Hưng Long",
						type: "Xã",
					},
					{
						level3_id: "08317",
						name: "Xã Nga Hoàng",
						type: "Xã",
					},
					{
						level3_id: "08320",
						name: "Xã Đồng Lạc",
						type: "Xã",
					},
					{
						level3_id: "08323",
						name: "Xã Thượng Long",
						type: "Xã",
					},
					{
						level3_id: "08326",
						name: "Xã Đồng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "08329",
						name: "Xã Phúc Khánh",
						type: "Xã",
					},
					{
						level3_id: "08332",
						name: "Xã Minh Hòa",
						type: "Xã",
					},
					{
						level3_id: "08335",
						name: "Xã Ngọc Lập",
						type: "Xã",
					},
					{
						level3_id: "08338",
						name: "Xã Ngọc Đồng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "235",
				name: "Huyện Cẩm Khê",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08341",
						name: "Thị trấn Cẩm Khê",
						type: "Thị trấn",
					},
					{
						level3_id: "08344",
						name: "Xã Tiên Lương",
						type: "Xã",
					},
					{
						level3_id: "08347",
						name: "Xã Tuy Lộc",
						type: "Xã",
					},
					{
						level3_id: "08350",
						name: "Xã Ngô Xá",
						type: "Xã",
					},
					{
						level3_id: "08353",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "08356",
						name: "Xã Phượng Vĩ",
						type: "Xã",
					},
					{
						level3_id: "08362",
						name: "Xã Thụy Liễu",
						type: "Xã",
					},
					{
						level3_id: "08374",
						name: "Xã Tùng Khê",
						type: "Xã",
					},
					{
						level3_id: "08377",
						name: "Xã Tam Sơn",
						type: "Xã",
					},
					{
						level3_id: "08380",
						name: "Xã Văn Bán",
						type: "Xã",
					},
					{
						level3_id: "08383",
						name: "Xã Cấp Dẫn",
						type: "Xã",
					},
					{
						level3_id: "08389",
						name: "Xã Xương Thịnh",
						type: "Xã",
					},
					{
						level3_id: "08392",
						name: "Xã Phú Khê",
						type: "Xã",
					},
					{
						level3_id: "08395",
						name: "Xã Sơn Tình",
						type: "Xã",
					},
					{
						level3_id: "08398",
						name: "Xã Yên Tập",
						type: "Xã",
					},
					{
						level3_id: "08401",
						name: "Xã Hương Lung",
						type: "Xã",
					},
					{
						level3_id: "08404",
						name: "Xã Tạ Xá",
						type: "Xã",
					},
					{
						level3_id: "08407",
						name: "Xã Phú Lạc",
						type: "Xã",
					},
					{
						level3_id: "08413",
						name: "Xã Chương Xá",
						type: "Xã",
					},
					{
						level3_id: "08416",
						name: "Xã Hùng Việt",
						type: "Xã",
					},
					{
						level3_id: "08419",
						name: "Xã Văn Khúc",
						type: "Xã",
					},
					{
						level3_id: "08422",
						name: "Xã Yên Dưỡng",
						type: "Xã",
					},
					{
						level3_id: "08428",
						name: "Xã Điêu Lương",
						type: "Xã",
					},
					{
						level3_id: "08431",
						name: "Xã Đồng Lương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "236",
				name: "Huyện Tam Nông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08434",
						name: "Thị trấn Hưng Hoá",
						type: "Thị trấn",
					},
					{
						level3_id: "08440",
						name: "Xã Hiền Quan",
						type: "Xã",
					},
					{
						level3_id: "08443",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "08446",
						name: "Xã Thanh Uyên",
						type: "Xã",
					},
					{
						level3_id: "08461",
						name: "Xã Lam Sơn",
						type: "Xã",
					},
					{
						level3_id: "08467",
						name: "Xã Vạn Xuân",
						type: "Xã",
					},
					{
						level3_id: "08470",
						name: "Xã Quang Húc",
						type: "Xã",
					},
					{
						level3_id: "08473",
						name: "Xã Hương Nộn",
						type: "Xã",
					},
					{
						level3_id: "08476",
						name: "Xã Tề Lễ",
						type: "Xã",
					},
					{
						level3_id: "08479",
						name: "Xã Thọ Văn",
						type: "Xã",
					},
					{
						level3_id: "08482",
						name: "Xã Dị Nậu",
						type: "Xã",
					},
					{
						level3_id: "08491",
						name: "Xã Dân Quyền",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "237",
				name: "Huyện Lâm Thao",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08494",
						name: "Thị trấn Lâm Thao",
						type: "Thị trấn",
					},
					{
						level3_id: "08497",
						name: "Xã Tiên Kiên",
						type: "Xã",
					},
					{
						level3_id: "08498",
						name: "Thị trấn Hùng Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "08500",
						name: "Xã Xuân Lũng",
						type: "Xã",
					},
					{
						level3_id: "08509",
						name: "Xã Xuân Huy",
						type: "Xã",
					},
					{
						level3_id: "08512",
						name: "Xã Thạch Sơn",
						type: "Xã",
					},
					{
						level3_id: "08518",
						name: "Xã Sơn Vi",
						type: "Xã",
					},
					{
						level3_id: "08521",
						name: "Xã Phùng Nguyên",
						type: "Xã",
					},
					{
						level3_id: "08527",
						name: "Xã Cao Xá",
						type: "Xã",
					},
					{
						level3_id: "08533",
						name: "Xã Vĩnh Lại",
						type: "Xã",
					},
					{
						level3_id: "08536",
						name: "Xã Tứ Xã",
						type: "Xã",
					},
					{
						level3_id: "08539",
						name: "Xã Bản Nguyên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "238",
				name: "Huyện Thanh Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08542",
						name: "Thị trấn Thanh Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "08563",
						name: "Xã Sơn Hùng",
						type: "Xã",
					},
					{
						level3_id: "08572",
						name: "Xã Địch Quả",
						type: "Xã",
					},
					{
						level3_id: "08575",
						name: "Xã Giáp Lai",
						type: "Xã",
					},
					{
						level3_id: "08581",
						name: "Xã Thục Luyện",
						type: "Xã",
					},
					{
						level3_id: "08584",
						name: "Xã Võ Miếu",
						type: "Xã",
					},
					{
						level3_id: "08587",
						name: "Xã Thạch Khoán",
						type: "Xã",
					},
					{
						level3_id: "08602",
						name: "Xã Cự Thắng",
						type: "Xã",
					},
					{
						level3_id: "08605",
						name: "Xã Tất Thắng",
						type: "Xã",
					},
					{
						level3_id: "08611",
						name: "Xã Văn Miếu",
						type: "Xã",
					},
					{
						level3_id: "08614",
						name: "Xã Cự Đồng",
						type: "Xã",
					},
					{
						level3_id: "08623",
						name: "Xã Thắng Sơn",
						type: "Xã",
					},
					{
						level3_id: "08629",
						name: "Xã Tân Minh",
						type: "Xã",
					},
					{
						level3_id: "08632",
						name: "Xã Hương Cần",
						type: "Xã",
					},
					{
						level3_id: "08635",
						name: "Xã Khả Cửu",
						type: "Xã",
					},
					{
						level3_id: "08638",
						name: "Xã Đông Cửu",
						type: "Xã",
					},
					{
						level3_id: "08641",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "08644",
						name: "Xã Yên Lãng",
						type: "Xã",
					},
					{
						level3_id: "08647",
						name: "Xã Yên Lương",
						type: "Xã",
					},
					{
						level3_id: "08650",
						name: "Xã Thượng Cửu",
						type: "Xã",
					},
					{
						level3_id: "08653",
						name: "Xã Lương Nha",
						type: "Xã",
					},
					{
						level3_id: "08656",
						name: "Xã Yên Sơn",
						type: "Xã",
					},
					{
						level3_id: "08659",
						name: "Xã Tinh Nhuệ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "239",
				name: "Huyện Thanh Thuỷ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08662",
						name: "Xã Đào Xá",
						type: "Xã",
					},
					{
						level3_id: "08665",
						name: "Xã Thạch Đồng",
						type: "Xã",
					},
					{
						level3_id: "08668",
						name: "Xã Xuân Lộc",
						type: "Xã",
					},
					{
						level3_id: "08671",
						name: "Xã Tân Phương",
						type: "Xã",
					},
					{
						level3_id: "08674",
						name: "Thị trấn Thanh Thủy",
						type: "Thị trấn",
					},
					{
						level3_id: "08677",
						name: "Xã Sơn Thủy",
						type: "Xã",
					},
					{
						level3_id: "08680",
						name: "Xã Bảo Yên",
						type: "Xã",
					},
					{
						level3_id: "08683",
						name: "Xã Đoan Hạ",
						type: "Xã",
					},
					{
						level3_id: "08686",
						name: "Xã Đồng Trung",
						type: "Xã",
					},
					{
						level3_id: "08689",
						name: "Xã Hoàng Xá",
						type: "Xã",
					},
					{
						level3_id: "08701",
						name: "Xã Tu Vũ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "240",
				name: "Huyện Tân Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08545",
						name: "Xã Thu Cúc",
						type: "Xã",
					},
					{
						level3_id: "08548",
						name: "Xã Thạch Kiệt",
						type: "Xã",
					},
					{
						level3_id: "08551",
						name: "Xã Thu Ngạc",
						type: "Xã",
					},
					{
						level3_id: "08554",
						name: "Xã Kiệt Sơn",
						type: "Xã",
					},
					{
						level3_id: "08557",
						name: "Xã Đồng Sơn",
						type: "Xã",
					},
					{
						level3_id: "08560",
						name: "Xã Lai Đồng",
						type: "Xã",
					},
					{
						level3_id: "08566",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "08569",
						name: "Xã Mỹ Thuận",
						type: "Xã",
					},
					{
						level3_id: "08578",
						name: "Xã Tân Sơn",
						type: "Xã",
					},
					{
						level3_id: "08590",
						name: "Xã Xuân Đài",
						type: "Xã",
					},
					{
						level3_id: "08593",
						name: "Xã Minh Đài",
						type: "Xã",
					},
					{
						level3_id: "08596",
						name: "Xã Văn Luông",
						type: "Xã",
					},
					{
						level3_id: "08599",
						name: "Xã Xuân Sơn",
						type: "Xã",
					},
					{
						level3_id: "08608",
						name: "Xã Long Cốc",
						type: "Xã",
					},
					{
						level3_id: "08617",
						name: "Xã Kim Thượng",
						type: "Xã",
					},
					{
						level3_id: "08620",
						name: "Xã Tam Thanh",
						type: "Xã",
					},
					{
						level3_id: "08626",
						name: "Xã Vinh Tiền",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "26",
		name: "Tỉnh Vĩnh Phúc",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "243",
				name: "Thành phố Vĩnh Yên",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "08707",
						name: "Phường Tích Sơn",
						type: "Phường",
					},
					{
						level3_id: "08710",
						name: "Phường Liên Bảo",
						type: "Phường",
					},
					{
						level3_id: "08713",
						name: "Phường Hội Hợp",
						type: "Phường",
					},
					{
						level3_id: "08716",
						name: "Phường Đống Đa",
						type: "Phường",
					},
					{
						level3_id: "08719",
						name: "Phường Ngô Quyền",
						type: "Phường",
					},
					{
						level3_id: "08722",
						name: "Phường Đồng Tâm",
						type: "Phường",
					},
					{
						level3_id: "08725",
						name: "Xã Định Trung",
						type: "Xã",
					},
					{
						level3_id: "08728",
						name: "Phường Khai Quang",
						type: "Phường",
					},
					{
						level3_id: "08731",
						name: "Xã Thanh Trù",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "244",
				name: "Thành phố Phúc Yên",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "08734",
						name: "Phường Trưng Trắc",
						type: "Phường",
					},
					{
						level3_id: "08737",
						name: "Phường Hùng Vương",
						type: "Phường",
					},
					{
						level3_id: "08740",
						name: "Phường Trưng Nhị",
						type: "Phường",
					},
					{
						level3_id: "08743",
						name: "Phường Phúc Thắng",
						type: "Phường",
					},
					{
						level3_id: "08746",
						name: "Phường Xuân Hoà",
						type: "Phường",
					},
					{
						level3_id: "08747",
						name: "Phường Đồng Xuân",
						type: "Phường",
					},
					{
						level3_id: "08749",
						name: "Xã Ngọc Thanh",
						type: "Xã",
					},
					{
						level3_id: "08752",
						name: "Xã Cao Minh",
						type: "Xã",
					},
					{
						level3_id: "08755",
						name: "Phường Nam Viêm",
						type: "Phường",
					},
					{
						level3_id: "08758",
						name: "Phường Tiền Châu",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "246",
				name: "Huyện Lập Thạch",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08761",
						name: "Thị trấn Lập Thạch",
						type: "Thị trấn",
					},
					{
						level3_id: "08764",
						name: "Xã Quang Sơn",
						type: "Xã",
					},
					{
						level3_id: "08767",
						name: "Xã Ngọc Mỹ",
						type: "Xã",
					},
					{
						level3_id: "08770",
						name: "Xã Hợp Lý",
						type: "Xã",
					},
					{
						level3_id: "08785",
						name: "Xã Bắc Bình",
						type: "Xã",
					},
					{
						level3_id: "08788",
						name: "Xã Thái Hòa",
						type: "Xã",
					},
					{
						level3_id: "08789",
						name: "Thị trấn Hoa Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "08791",
						name: "Xã Liễn Sơn",
						type: "Xã",
					},
					{
						level3_id: "08794",
						name: "Xã Xuân Hòa",
						type: "Xã",
					},
					{
						level3_id: "08797",
						name: "Xã Vân Trục",
						type: "Xã",
					},
					{
						level3_id: "08812",
						name: "Xã Liên Hòa",
						type: "Xã",
					},
					{
						level3_id: "08815",
						name: "Xã Tử Du",
						type: "Xã",
					},
					{
						level3_id: "08833",
						name: "Xã Bàn Giản",
						type: "Xã",
					},
					{
						level3_id: "08836",
						name: "Xã Xuân Lôi",
						type: "Xã",
					},
					{
						level3_id: "08839",
						name: "Xã Đồng Ích",
						type: "Xã",
					},
					{
						level3_id: "08842",
						name: "Xã Tiên Lữ",
						type: "Xã",
					},
					{
						level3_id: "08845",
						name: "Xã Văn Quán",
						type: "Xã",
					},
					{
						level3_id: "08857",
						name: "Xã Đình Chu",
						type: "Xã",
					},
					{
						level3_id: "08863",
						name: "Xã Triệu Đề",
						type: "Xã",
					},
					{
						level3_id: "08866",
						name: "Xã Sơn Đông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "247",
				name: "Huyện Tam Dương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08869",
						name: "Thị trấn Hợp Hòa",
						type: "Thị trấn",
					},
					{
						level3_id: "08872",
						name: "Xã Hoàng Hoa",
						type: "Xã",
					},
					{
						level3_id: "08875",
						name: "Xã Đồng Tĩnh",
						type: "Xã",
					},
					{
						level3_id: "08878",
						name: "Xã Kim Long",
						type: "Xã",
					},
					{
						level3_id: "08881",
						name: "Xã Hướng Đạo",
						type: "Xã",
					},
					{
						level3_id: "08884",
						name: "Xã Đạo Tú",
						type: "Xã",
					},
					{
						level3_id: "08887",
						name: "Xã An Hòa",
						type: "Xã",
					},
					{
						level3_id: "08890",
						name: "Xã Thanh Vân",
						type: "Xã",
					},
					{
						level3_id: "08893",
						name: "Xã Duy Phiên",
						type: "Xã",
					},
					{
						level3_id: "08896",
						name: "Xã Hoàng Đan",
						type: "Xã",
					},
					{
						level3_id: "08899",
						name: "Xã Hoàng Lâu",
						type: "Xã",
					},
					{
						level3_id: "08902",
						name: "Xã Vân Hội",
						type: "Xã",
					},
					{
						level3_id: "08905",
						name: "Xã Hợp Thịnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "248",
				name: "Huyện Tam Đảo",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08908",
						name: "Thị trấn Tam Đảo",
						type: "Thị trấn",
					},
					{
						level3_id: "08911",
						name: "Thị trấn Hợp Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "08914",
						name: "Xã Đạo Trù",
						type: "Xã",
					},
					{
						level3_id: "08917",
						name: "Xã Yên Dương",
						type: "Xã",
					},
					{
						level3_id: "08920",
						name: "Xã Bồ Lý",
						type: "Xã",
					},
					{
						level3_id: "08923",
						name: "Thị trấn Đại Đình",
						type: "Thị trấn",
					},
					{
						level3_id: "08926",
						name: "Xã Tam Quan",
						type: "Xã",
					},
					{
						level3_id: "08929",
						name: "Xã Hồ Sơn",
						type: "Xã",
					},
					{
						level3_id: "08932",
						name: "Xã Minh Quang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "249",
				name: "Huyện Bình Xuyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08935",
						name: "Thị trấn Hương Canh",
						type: "Thị trấn",
					},
					{
						level3_id: "08936",
						name: "Thị trấn Gia Khánh",
						type: "Thị trấn",
					},
					{
						level3_id: "08938",
						name: "Xã Trung Mỹ",
						type: "Xã",
					},
					{
						level3_id: "08944",
						name: "Thị trấn Bá Hiến",
						type: "Thị trấn",
					},
					{
						level3_id: "08947",
						name: "Xã Thiện Kế",
						type: "Xã",
					},
					{
						level3_id: "08950",
						name: "Xã Hương Sơn",
						type: "Xã",
					},
					{
						level3_id: "08953",
						name: "Xã Tam Hợp",
						type: "Xã",
					},
					{
						level3_id: "08956",
						name: "Xã Quất Lưu",
						type: "Xã",
					},
					{
						level3_id: "08959",
						name: "Xã Sơn Lôi",
						type: "Xã",
					},
					{
						level3_id: "08962",
						name: "Thị trấn Đạo Đức",
						type: "Thị trấn",
					},
					{
						level3_id: "08965",
						name: "Xã Tân Phong",
						type: "Xã",
					},
					{
						level3_id: "08968",
						name: "Thị trấn Thanh Lãng",
						type: "Thị trấn",
					},
					{
						level3_id: "08971",
						name: "Xã Phú Xuân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "251",
				name: "Huyện Yên Lạc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09025",
						name: "Thị trấn Yên Lạc",
						type: "Thị trấn",
					},
					{
						level3_id: "09028",
						name: "Xã Đồng Cương",
						type: "Xã",
					},
					{
						level3_id: "09031",
						name: "Xã Đồng Văn",
						type: "Xã",
					},
					{
						level3_id: "09034",
						name: "Xã Bình Định",
						type: "Xã",
					},
					{
						level3_id: "09037",
						name: "Xã Trung Nguyên",
						type: "Xã",
					},
					{
						level3_id: "09040",
						name: "Xã Tề Lỗ",
						type: "Xã",
					},
					{
						level3_id: "09043",
						name: "Xã Tam Hồng",
						type: "Xã",
					},
					{
						level3_id: "09046",
						name: "Xã Yên Đồng",
						type: "Xã",
					},
					{
						level3_id: "09049",
						name: "Xã Văn Tiến",
						type: "Xã",
					},
					{
						level3_id: "09052",
						name: "Xã Nguyệt Đức",
						type: "Xã",
					},
					{
						level3_id: "09055",
						name: "Xã Yên Phương",
						type: "Xã",
					},
					{
						level3_id: "09058",
						name: "Xã Hồng Phương",
						type: "Xã",
					},
					{
						level3_id: "09061",
						name: "Xã Trung Kiên",
						type: "Xã",
					},
					{
						level3_id: "09064",
						name: "Xã Liên Châu",
						type: "Xã",
					},
					{
						level3_id: "09067",
						name: "Xã Đại Tự",
						type: "Xã",
					},
					{
						level3_id: "09070",
						name: "Xã Hồng Châu",
						type: "Xã",
					},
					{
						level3_id: "09073",
						name: "Xã Trung Hà",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "252",
				name: "Huyện Vĩnh Tường",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09076",
						name: "Thị trấn Vĩnh Tường",
						type: "Thị trấn",
					},
					{
						level3_id: "09079",
						name: "Xã Kim Xá",
						type: "Xã",
					},
					{
						level3_id: "09082",
						name: "Xã Yên Bình",
						type: "Xã",
					},
					{
						level3_id: "09085",
						name: "Xã Chấn Hưng",
						type: "Xã",
					},
					{
						level3_id: "09088",
						name: "Xã Nghĩa Hưng",
						type: "Xã",
					},
					{
						level3_id: "09091",
						name: "Xã Yên Lập",
						type: "Xã",
					},
					{
						level3_id: "09094",
						name: "Xã Việt Xuân",
						type: "Xã",
					},
					{
						level3_id: "09097",
						name: "Xã Bồ Sao",
						type: "Xã",
					},
					{
						level3_id: "09100",
						name: "Xã Đại Đồng",
						type: "Xã",
					},
					{
						level3_id: "09103",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "09106",
						name: "Xã Lũng Hoà",
						type: "Xã",
					},
					{
						level3_id: "09109",
						name: "Xã Cao Đại",
						type: "Xã",
					},
					{
						level3_id: "09112",
						name: "Thị Trấn Thổ Tang",
						type: "Thị trấn",
					},
					{
						level3_id: "09115",
						name: "Xã Vĩnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "09118",
						name: "Xã Bình Dương",
						type: "Xã",
					},
					{
						level3_id: "09124",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "09127",
						name: "Xã Thượng Trưng",
						type: "Xã",
					},
					{
						level3_id: "09130",
						name: "Xã Vũ Di",
						type: "Xã",
					},
					{
						level3_id: "09133",
						name: "Xã Lý Nhân",
						type: "Xã",
					},
					{
						level3_id: "09136",
						name: "Xã Tuân Chính",
						type: "Xã",
					},
					{
						level3_id: "09139",
						name: "Xã Vân Xuân",
						type: "Xã",
					},
					{
						level3_id: "09142",
						name: "Xã Tam Phúc",
						type: "Xã",
					},
					{
						level3_id: "09145",
						name: "Thị trấn Tứ Trưng",
						type: "Thị trấn",
					},
					{
						level3_id: "09148",
						name: "Xã Ngũ Kiên",
						type: "Xã",
					},
					{
						level3_id: "09151",
						name: "Xã An Tường",
						type: "Xã",
					},
					{
						level3_id: "09154",
						name: "Xã Vĩnh Thịnh",
						type: "Xã",
					},
					{
						level3_id: "09157",
						name: "Xã Phú Đa",
						type: "Xã",
					},
					{
						level3_id: "09160",
						name: "Xã Vĩnh Ninh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "253",
				name: "Huyện Sông Lô",
				type: "Huyện",
				level3s: [
					{
						level3_id: "08773",
						name: "Xã Lãng Công",
						type: "Xã",
					},
					{
						level3_id: "08776",
						name: "Xã Quang Yên",
						type: "Xã",
					},
					{
						level3_id: "08779",
						name: "Xã Bạch Lưu",
						type: "Xã",
					},
					{
						level3_id: "08782",
						name: "Xã Hải Lựu",
						type: "Xã",
					},
					{
						level3_id: "08800",
						name: "Xã Đồng Quế",
						type: "Xã",
					},
					{
						level3_id: "08803",
						name: "Xã Nhân Đạo",
						type: "Xã",
					},
					{
						level3_id: "08806",
						name: "Xã Đôn Nhân",
						type: "Xã",
					},
					{
						level3_id: "08809",
						name: "Xã Phương Khoan",
						type: "Xã",
					},
					{
						level3_id: "08818",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "08821",
						name: "Xã Nhạo Sơn",
						type: "Xã",
					},
					{
						level3_id: "08824",
						name: "Thị trấn Tam Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "08827",
						name: "Xã Như Thụy",
						type: "Xã",
					},
					{
						level3_id: "08830",
						name: "Xã Yên Thạch",
						type: "Xã",
					},
					{
						level3_id: "08848",
						name: "Xã Đồng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "08851",
						name: "Xã Tứ Yên",
						type: "Xã",
					},
					{
						level3_id: "08854",
						name: "Xã Đức Bác",
						type: "Xã",
					},
					{
						level3_id: "08860",
						name: "Xã Cao Phong",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "27",
		name: "Tỉnh Bắc Ninh",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "256",
				name: "Thành phố Bắc Ninh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "09163",
						name: "Phường Vũ Ninh",
						type: "Phường",
					},
					{
						level3_id: "09166",
						name: "Phường Đáp Cầu",
						type: "Phường",
					},
					{
						level3_id: "09169",
						name: "Phường Thị Cầu",
						type: "Phường",
					},
					{
						level3_id: "09172",
						name: "Phường Kinh Bắc",
						type: "Phường",
					},
					{
						level3_id: "09175",
						name: "Phường Vệ An",
						type: "Phường",
					},
					{
						level3_id: "09178",
						name: "Phường Tiền An",
						type: "Phường",
					},
					{
						level3_id: "09181",
						name: "Phường Đại Phúc",
						type: "Phường",
					},
					{
						level3_id: "09184",
						name: "Phường Ninh Xá",
						type: "Phường",
					},
					{
						level3_id: "09187",
						name: "Phường Suối Hoa",
						type: "Phường",
					},
					{
						level3_id: "09190",
						name: "Phường Võ Cường",
						type: "Phường",
					},
					{
						level3_id: "09214",
						name: "Phường Hòa Long",
						type: "Phường",
					},
					{
						level3_id: "09226",
						name: "Phường Vạn An",
						type: "Phường",
					},
					{
						level3_id: "09235",
						name: "Phường Khúc Xuyên",
						type: "Phường",
					},
					{
						level3_id: "09244",
						name: "Phường Phong Khê",
						type: "Phường",
					},
					{
						level3_id: "09256",
						name: "Phường Kim Chân",
						type: "Phường",
					},
					{
						level3_id: "09271",
						name: "Phường Vân Dương",
						type: "Phường",
					},
					{
						level3_id: "09286",
						name: "Phường Nam Sơn",
						type: "Phường",
					},
					{
						level3_id: "09325",
						name: "Phường Khắc Niệm",
						type: "Phường",
					},
					{
						level3_id: "09331",
						name: "Phường Hạp Lĩnh",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "258",
				name: "Huyện Yên Phong",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09193",
						name: "Thị trấn Chờ",
						type: "Thị trấn",
					},
					{
						level3_id: "09196",
						name: "Xã Dũng Liệt",
						type: "Xã",
					},
					{
						level3_id: "09199",
						name: "Xã Tam Đa",
						type: "Xã",
					},
					{
						level3_id: "09202",
						name: "Xã Tam Giang",
						type: "Xã",
					},
					{
						level3_id: "09205",
						name: "Xã Yên Trung",
						type: "Xã",
					},
					{
						level3_id: "09208",
						name: "Xã Thụy Hòa",
						type: "Xã",
					},
					{
						level3_id: "09211",
						name: "Xã Hòa Tiến",
						type: "Xã",
					},
					{
						level3_id: "09217",
						name: "Xã Đông Tiến",
						type: "Xã",
					},
					{
						level3_id: "09220",
						name: "Xã Yên Phụ",
						type: "Xã",
					},
					{
						level3_id: "09223",
						name: "Xã Trung Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "09229",
						name: "Xã Đông Phong",
						type: "Xã",
					},
					{
						level3_id: "09232",
						name: "Xã Long Châu",
						type: "Xã",
					},
					{
						level3_id: "09238",
						name: "Xã Văn Môn",
						type: "Xã",
					},
					{
						level3_id: "09241",
						name: "Xã Đông Thọ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "259",
				name: "Huyện Quế Võ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09247",
						name: "Thị trấn Phố Mới",
						type: "Thị trấn",
					},
					{
						level3_id: "09250",
						name: "Xã Việt Thống",
						type: "Xã",
					},
					{
						level3_id: "09253",
						name: "Xã Đại Xuân",
						type: "Xã",
					},
					{
						level3_id: "09259",
						name: "Xã Nhân Hòa",
						type: "Xã",
					},
					{
						level3_id: "09262",
						name: "Xã Bằng An",
						type: "Xã",
					},
					{
						level3_id: "09265",
						name: "Xã Phương Liễu",
						type: "Xã",
					},
					{
						level3_id: "09268",
						name: "Xã Quế Tân",
						type: "Xã",
					},
					{
						level3_id: "09274",
						name: "Xã Phù Lương",
						type: "Xã",
					},
					{
						level3_id: "09277",
						name: "Xã Phù Lãng",
						type: "Xã",
					},
					{
						level3_id: "09280",
						name: "Xã Phượng Mao",
						type: "Xã",
					},
					{
						level3_id: "09283",
						name: "Xã Việt Hùng",
						type: "Xã",
					},
					{
						level3_id: "09289",
						name: "Xã Ngọc Xá",
						type: "Xã",
					},
					{
						level3_id: "09292",
						name: "Xã Châu Phong",
						type: "Xã",
					},
					{
						level3_id: "09295",
						name: "Xã Bồng Lai",
						type: "Xã",
					},
					{
						level3_id: "09298",
						name: "Xã Cách Bi",
						type: "Xã",
					},
					{
						level3_id: "09301",
						name: "Xã Đào Viên",
						type: "Xã",
					},
					{
						level3_id: "09304",
						name: "Xã Yên Giả",
						type: "Xã",
					},
					{
						level3_id: "09307",
						name: "Xã Mộ Đạo",
						type: "Xã",
					},
					{
						level3_id: "09310",
						name: "Xã Đức Long",
						type: "Xã",
					},
					{
						level3_id: "09313",
						name: "Xã Chi Lăng",
						type: "Xã",
					},
					{
						level3_id: "09316",
						name: "Xã Hán Quảng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "260",
				name: "Huyện Tiên Du",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09319",
						name: "Thị trấn Lim",
						type: "Thị trấn",
					},
					{
						level3_id: "09322",
						name: "Xã Phú Lâm",
						type: "Xã",
					},
					{
						level3_id: "09328",
						name: "Xã Nội Duệ",
						type: "Xã",
					},
					{
						level3_id: "09334",
						name: "Xã Liên Bão",
						type: "Xã",
					},
					{
						level3_id: "09337",
						name: "Xã Hiên Vân",
						type: "Xã",
					},
					{
						level3_id: "09340",
						name: "Xã Hoàn Sơn",
						type: "Xã",
					},
					{
						level3_id: "09343",
						name: "Xã Lạc Vệ",
						type: "Xã",
					},
					{
						level3_id: "09346",
						name: "Xã Việt Đoàn",
						type: "Xã",
					},
					{
						level3_id: "09349",
						name: "Xã Phật Tích",
						type: "Xã",
					},
					{
						level3_id: "09352",
						name: "Xã Tân Chi",
						type: "Xã",
					},
					{
						level3_id: "09355",
						name: "Xã Đại Đồng",
						type: "Xã",
					},
					{
						level3_id: "09358",
						name: "Xã Tri Phương",
						type: "Xã",
					},
					{
						level3_id: "09361",
						name: "Xã Minh Đạo",
						type: "Xã",
					},
					{
						level3_id: "09364",
						name: "Xã Cảnh Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "261",
				name: "Thị xã Từ Sơn",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "09367",
						name: "Phường Đông Ngàn",
						type: "Phường",
					},
					{
						level3_id: "09370",
						name: "Phường Tam Sơn",
						type: "Phường",
					},
					{
						level3_id: "09373",
						name: "Phường Hương Mạc",
						type: "Phường",
					},
					{
						level3_id: "09376",
						name: "Phường Tương Giang",
						type: "Phường",
					},
					{
						level3_id: "09379",
						name: "Phường Phù Khê",
						type: "Phường",
					},
					{
						level3_id: "09382",
						name: "Phường Đồng Kỵ",
						type: "Phường",
					},
					{
						level3_id: "09383",
						name: "Phường Trang Hạ",
						type: "Phường",
					},
					{
						level3_id: "09385",
						name: "Phường Đồng Nguyên",
						type: "Phường",
					},
					{
						level3_id: "09388",
						name: "Phường Châu Khê",
						type: "Phường",
					},
					{
						level3_id: "09391",
						name: "Phường Tân Hồng",
						type: "Phường",
					},
					{
						level3_id: "09394",
						name: "Phường Đình Bảng",
						type: "Phường",
					},
					{
						level3_id: "09397",
						name: "Phường Phù Chẩn",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "262",
				name: "Huyện Thuận Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09400",
						name: "Thị trấn Hồ",
						type: "Thị trấn",
					},
					{
						level3_id: "09403",
						name: "Xã Hoài Thượng",
						type: "Xã",
					},
					{
						level3_id: "09406",
						name: "Xã Đại Đồng Thành",
						type: "Xã",
					},
					{
						level3_id: "09409",
						name: "Xã Mão Điền",
						type: "Xã",
					},
					{
						level3_id: "09412",
						name: "Xã Song Hồ",
						type: "Xã",
					},
					{
						level3_id: "09415",
						name: "Xã Đình Tổ",
						type: "Xã",
					},
					{
						level3_id: "09418",
						name: "Xã An Bình",
						type: "Xã",
					},
					{
						level3_id: "09421",
						name: "Xã Trí Quả",
						type: "Xã",
					},
					{
						level3_id: "09424",
						name: "Xã Gia Đông",
						type: "Xã",
					},
					{
						level3_id: "09427",
						name: "Xã Thanh Khương",
						type: "Xã",
					},
					{
						level3_id: "09430",
						name: "Xã Trạm Lộ",
						type: "Xã",
					},
					{
						level3_id: "09433",
						name: "Xã Xuân Lâm",
						type: "Xã",
					},
					{
						level3_id: "09436",
						name: "Xã Hà Mãn",
						type: "Xã",
					},
					{
						level3_id: "09439",
						name: "Xã Ngũ Thái",
						type: "Xã",
					},
					{
						level3_id: "09442",
						name: "Xã Nguyệt Đức",
						type: "Xã",
					},
					{
						level3_id: "09445",
						name: "Xã Ninh Xá",
						type: "Xã",
					},
					{
						level3_id: "09448",
						name: "Xã Nghĩa Đạo",
						type: "Xã",
					},
					{
						level3_id: "09451",
						name: "Xã Song Liễu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "263",
				name: "Huyện Gia Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09454",
						name: "Thị trấn Gia Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "09457",
						name: "Xã Vạn Ninh",
						type: "Xã",
					},
					{
						level3_id: "09460",
						name: "Xã Thái Bảo",
						type: "Xã",
					},
					{
						level3_id: "09463",
						name: "Xã Giang Sơn",
						type: "Xã",
					},
					{
						level3_id: "09466",
						name: "Xã Cao Đức",
						type: "Xã",
					},
					{
						level3_id: "09469",
						name: "Xã Đại Lai",
						type: "Xã",
					},
					{
						level3_id: "09472",
						name: "Xã Song Giang",
						type: "Xã",
					},
					{
						level3_id: "09475",
						name: "Xã Bình Dương",
						type: "Xã",
					},
					{
						level3_id: "09478",
						name: "Xã Lãng Ngâm",
						type: "Xã",
					},
					{
						level3_id: "09481",
						name: "Xã Nhân Thắng",
						type: "Xã",
					},
					{
						level3_id: "09484",
						name: "Xã Xuân Lai",
						type: "Xã",
					},
					{
						level3_id: "09487",
						name: "Xã Đông Cứu",
						type: "Xã",
					},
					{
						level3_id: "09490",
						name: "Xã Đại Bái",
						type: "Xã",
					},
					{
						level3_id: "09493",
						name: "Xã Quỳnh Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "264",
				name: "Huyện Lương Tài",
				type: "Huyện",
				level3s: [
					{
						level3_id: "09496",
						name: "Thị trấn Thứa",
						type: "Thị trấn",
					},
					{
						level3_id: "09499",
						name: "Xã An Thịnh",
						type: "Xã",
					},
					{
						level3_id: "09502",
						name: "Xã Trung Kênh",
						type: "Xã",
					},
					{
						level3_id: "09505",
						name: "Xã Phú Hòa",
						type: "Xã",
					},
					{
						level3_id: "09508",
						name: "Xã Mỹ Hương",
						type: "Xã",
					},
					{
						level3_id: "09511",
						name: "Xã Tân Lãng",
						type: "Xã",
					},
					{
						level3_id: "09514",
						name: "Xã Quảng Phú",
						type: "Xã",
					},
					{
						level3_id: "09517",
						name: "Xã Trừng Xá",
						type: "Xã",
					},
					{
						level3_id: "09520",
						name: "Xã Lai Hạ",
						type: "Xã",
					},
					{
						level3_id: "09523",
						name: "Xã Trung Chính",
						type: "Xã",
					},
					{
						level3_id: "09526",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "09529",
						name: "Xã Bình Định",
						type: "Xã",
					},
					{
						level3_id: "09532",
						name: "Xã Phú Lương",
						type: "Xã",
					},
					{
						level3_id: "09535",
						name: "Xã Lâm Thao",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "30",
		name: "Tỉnh Hải Dương",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "288",
				name: "Thành phố Hải Dương",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "10507",
						name: "Phường Cẩm Thượng",
						type: "Phường",
					},
					{
						level3_id: "10510",
						name: "Phường Bình Hàn",
						type: "Phường",
					},
					{
						level3_id: "10513",
						name: "Phường Ngọc Châu",
						type: "Phường",
					},
					{
						level3_id: "10514",
						name: "Phường Nhị Châu",
						type: "Phường",
					},
					{
						level3_id: "10516",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "10519",
						name: "Phường Nguyễn Trãi",
						type: "Phường",
					},
					{
						level3_id: "10522",
						name: "Phường Phạm Ngũ Lão",
						type: "Phường",
					},
					{
						level3_id: "10525",
						name: "Phường Trần Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "10528",
						name: "Phường Trần Phú",
						type: "Phường",
					},
					{
						level3_id: "10531",
						name: "Phường Thanh Bình",
						type: "Phường",
					},
					{
						level3_id: "10532",
						name: "Phường Tân Bình",
						type: "Phường",
					},
					{
						level3_id: "10534",
						name: "Phường Lê Thanh Nghị",
						type: "Phường",
					},
					{
						level3_id: "10537",
						name: "Phường Hải Tân",
						type: "Phường",
					},
					{
						level3_id: "10540",
						name: "Phường Tứ Minh",
						type: "Phường",
					},
					{
						level3_id: "10543",
						name: "Phường Việt Hoà",
						type: "Phường",
					},
					{
						level3_id: "10660",
						name: "Phường Ái Quốc",
						type: "Phường",
					},
					{
						level3_id: "10663",
						name: "Xã An Thượng",
						type: "Xã",
					},
					{
						level3_id: "10672",
						name: "Phường Nam Đồng",
						type: "Phường",
					},
					{
						level3_id: "10822",
						name: "Xã Quyết Thắng",
						type: "Xã",
					},
					{
						level3_id: "10837",
						name: "Xã Tiền Tiến",
						type: "Xã",
					},
					{
						level3_id: "11002",
						name: "Phường Thạch Khôi",
						type: "Phường",
					},
					{
						level3_id: "11005",
						name: "Xã Liên Hồng",
						type: "Xã",
					},
					{
						level3_id: "11011",
						name: "Phường Tân Hưng",
						type: "Phường",
					},
					{
						level3_id: "11017",
						name: "Xã Gia Xuyên",
						type: "Xã",
					},
					{
						level3_id: "11077",
						name: "Xã Ngọc Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "290",
				name: "Thành phố Chí Linh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "10546",
						name: "Phường Phả Lại",
						type: "Phường",
					},
					{
						level3_id: "10549",
						name: "Phường Sao Đỏ",
						type: "Phường",
					},
					{
						level3_id: "10552",
						name: "Phường Bến Tắm",
						type: "Phường",
					},
					{
						level3_id: "10555",
						name: "Xã Hoàng Hoa Thám",
						type: "Xã",
					},
					{
						level3_id: "10558",
						name: "Xã Bắc An",
						type: "Xã",
					},
					{
						level3_id: "10561",
						name: "Xã Hưng Đạo",
						type: "Xã",
					},
					{
						level3_id: "10564",
						name: "Xã Lê Lợi",
						type: "Xã",
					},
					{
						level3_id: "10567",
						name: "Phường Hoàng Tiến",
						type: "Phường",
					},
					{
						level3_id: "10570",
						name: "Phường Cộng Hoà",
						type: "Phường",
					},
					{
						level3_id: "10573",
						name: "Phường Hoàng Tân",
						type: "Phường",
					},
					{
						level3_id: "10576",
						name: "Phường Cổ Thành",
						type: "Phường",
					},
					{
						level3_id: "10579",
						name: "Phường Văn An",
						type: "Phường",
					},
					{
						level3_id: "10582",
						name: "Phường Chí Minh",
						type: "Phường",
					},
					{
						level3_id: "10585",
						name: "Phường Văn Đức",
						type: "Phường",
					},
					{
						level3_id: "10588",
						name: "Phường Thái Học",
						type: "Phường",
					},
					{
						level3_id: "10591",
						name: "Xã Nhân Huệ",
						type: "Xã",
					},
					{
						level3_id: "10594",
						name: "Phường An Lạc",
						type: "Phường",
					},
					{
						level3_id: "10600",
						name: "Phường Đồng Lạc",
						type: "Phường",
					},
					{
						level3_id: "10603",
						name: "Phường Tân Dân",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "291",
				name: "Huyện Nam Sách",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10606",
						name: "Thị trấn Nam Sách",
						type: "Thị trấn",
					},
					{
						level3_id: "10609",
						name: "Xã Nam Hưng",
						type: "Xã",
					},
					{
						level3_id: "10612",
						name: "Xã Nam Tân",
						type: "Xã",
					},
					{
						level3_id: "10615",
						name: "Xã Hợp Tiến",
						type: "Xã",
					},
					{
						level3_id: "10618",
						name: "Xã Hiệp Cát",
						type: "Xã",
					},
					{
						level3_id: "10621",
						name: "Xã Thanh Quang",
						type: "Xã",
					},
					{
						level3_id: "10624",
						name: "Xã Quốc Tuấn",
						type: "Xã",
					},
					{
						level3_id: "10627",
						name: "Xã Nam Chính",
						type: "Xã",
					},
					{
						level3_id: "10630",
						name: "Xã An Bình",
						type: "Xã",
					},
					{
						level3_id: "10633",
						name: "Xã Nam Trung",
						type: "Xã",
					},
					{
						level3_id: "10636",
						name: "Xã An Sơn",
						type: "Xã",
					},
					{
						level3_id: "10639",
						name: "Xã Cộng Hòa",
						type: "Xã",
					},
					{
						level3_id: "10642",
						name: "Xã Thái Tân",
						type: "Xã",
					},
					{
						level3_id: "10645",
						name: "Xã An Lâm",
						type: "Xã",
					},
					{
						level3_id: "10648",
						name: "Xã Phú Điền",
						type: "Xã",
					},
					{
						level3_id: "10651",
						name: "Xã Nam Hồng",
						type: "Xã",
					},
					{
						level3_id: "10654",
						name: "Xã Hồng Phong",
						type: "Xã",
					},
					{
						level3_id: "10657",
						name: "Xã Đồng Lạc",
						type: "Xã",
					},
					{
						level3_id: "10666",
						name: "Xã Minh Tân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "292",
				name: "Thị xã Kinh Môn",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "10675",
						name: "Phường An Lưu",
						type: "Phường",
					},
					{
						level3_id: "10678",
						name: "Xã Bạch Đằng",
						type: "Xã",
					},
					{
						level3_id: "10681",
						name: "Phường Thất Hùng",
						type: "Phường",
					},
					{
						level3_id: "10684",
						name: "Xã Lê Ninh",
						type: "Xã",
					},
					{
						level3_id: "10687",
						name: "Xã Hoành Sơn",
						type: "Xã",
					},
					{
						level3_id: "10693",
						name: "Phường Phạm Thái",
						type: "Phường",
					},
					{
						level3_id: "10696",
						name: "Phường Duy Tân",
						type: "Phường",
					},
					{
						level3_id: "10699",
						name: "Phường Tân Dân",
						type: "Phường",
					},
					{
						level3_id: "10702",
						name: "Phường Minh Tân",
						type: "Phường",
					},
					{
						level3_id: "10705",
						name: "Xã Quang Thành",
						type: "Xã",
					},
					{
						level3_id: "10708",
						name: "Xã Hiệp Hòa",
						type: "Xã",
					},
					{
						level3_id: "10714",
						name: "Phường Phú Thứ",
						type: "Phường",
					},
					{
						level3_id: "10717",
						name: "Xã Thăng Long",
						type: "Xã",
					},
					{
						level3_id: "10720",
						name: "Xã Lạc Long",
						type: "Xã",
					},
					{
						level3_id: "10723",
						name: "Phường An Sinh",
						type: "Phường",
					},
					{
						level3_id: "10726",
						name: "Phường Hiệp Sơn",
						type: "Phường",
					},
					{
						level3_id: "10729",
						name: "Xã Thượng Quận",
						type: "Xã",
					},
					{
						level3_id: "10732",
						name: "Phường An Phụ",
						type: "Phường",
					},
					{
						level3_id: "10735",
						name: "Phường Hiệp An",
						type: "Phường",
					},
					{
						level3_id: "10738",
						name: "Phường Long Xuyên",
						type: "Phường",
					},
					{
						level3_id: "10741",
						name: "Phường Thái Thịnh",
						type: "Phường",
					},
					{
						level3_id: "10744",
						name: "Phường Hiến Thành",
						type: "Phường",
					},
					{
						level3_id: "10747",
						name: "Xã Minh Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "293",
				name: "Huyện Kim Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10750",
						name: "Thị trấn Phú Thái",
						type: "Thị trấn",
					},
					{
						level3_id: "10753",
						name: "Xã Lai Vu",
						type: "Xã",
					},
					{
						level3_id: "10756",
						name: "Xã Cộng Hòa",
						type: "Xã",
					},
					{
						level3_id: "10759",
						name: "Xã Thượng Vũ",
						type: "Xã",
					},
					{
						level3_id: "10762",
						name: "Xã Cổ Dũng",
						type: "Xã",
					},
					{
						level3_id: "10768",
						name: "Xã Tuấn Việt",
						type: "Xã",
					},
					{
						level3_id: "10771",
						name: "Xã Kim Xuyên",
						type: "Xã",
					},
					{
						level3_id: "10774",
						name: "Xã Phúc Thành A",
						type: "Xã",
					},
					{
						level3_id: "10777",
						name: "Xã Ngũ Phúc",
						type: "Xã",
					},
					{
						level3_id: "10780",
						name: "Xã Kim Anh",
						type: "Xã",
					},
					{
						level3_id: "10783",
						name: "Xã Kim Liên",
						type: "Xã",
					},
					{
						level3_id: "10786",
						name: "Xã Kim Tân",
						type: "Xã",
					},
					{
						level3_id: "10792",
						name: "Xã Kim Đính",
						type: "Xã",
					},
					{
						level3_id: "10798",
						name: "Xã Bình Dân",
						type: "Xã",
					},
					{
						level3_id: "10801",
						name: "Xã Tam Kỳ",
						type: "Xã",
					},
					{
						level3_id: "10804",
						name: "Xã Đồng Cẩm",
						type: "Xã",
					},
					{
						level3_id: "10807",
						name: "Xã Liên Hòa",
						type: "Xã",
					},
					{
						level3_id: "10810",
						name: "Xã Đại Đức",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "294",
				name: "Huyện Thanh Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10813",
						name: "Thị trấn Thanh Hà",
						type: "Thị trấn",
					},
					{
						level3_id: "10816",
						name: "Xã Hồng Lạc",
						type: "Xã",
					},
					{
						level3_id: "10819",
						name: "Xã Việt Hồng",
						type: "Xã",
					},
					{
						level3_id: "10825",
						name: "Xã Tân Việt",
						type: "Xã",
					},
					{
						level3_id: "10828",
						name: "Xã Cẩm Chế",
						type: "Xã",
					},
					{
						level3_id: "10831",
						name: "Xã Thanh An",
						type: "Xã",
					},
					{
						level3_id: "10834",
						name: "Xã Thanh Lang",
						type: "Xã",
					},
					{
						level3_id: "10840",
						name: "Xã Tân An",
						type: "Xã",
					},
					{
						level3_id: "10843",
						name: "Xã Liên Mạc",
						type: "Xã",
					},
					{
						level3_id: "10846",
						name: "Xã Thanh Hải",
						type: "Xã",
					},
					{
						level3_id: "10849",
						name: "Xã Thanh Khê",
						type: "Xã",
					},
					{
						level3_id: "10852",
						name: "Xã Thanh Xá",
						type: "Xã",
					},
					{
						level3_id: "10855",
						name: "Xã Thanh Xuân",
						type: "Xã",
					},
					{
						level3_id: "10861",
						name: "Xã Thanh Thủy",
						type: "Xã",
					},
					{
						level3_id: "10864",
						name: "Xã An Phượng",
						type: "Xã",
					},
					{
						level3_id: "10867",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "10876",
						name: "Xã Thanh Quang",
						type: "Xã",
					},
					{
						level3_id: "10879",
						name: "Xã Thanh Hồng",
						type: "Xã",
					},
					{
						level3_id: "10882",
						name: "Xã Thanh Cường",
						type: "Xã",
					},
					{
						level3_id: "10885",
						name: "Xã Vĩnh Lập",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "295",
				name: "Huyện Cẩm Giàng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10888",
						name: "Thị trấn Cẩm Giang",
						type: "Thị trấn",
					},
					{
						level3_id: "10891",
						name: "Thị trấn Lai Cách",
						type: "Thị trấn",
					},
					{
						level3_id: "10894",
						name: "Xã Cẩm Hưng",
						type: "Xã",
					},
					{
						level3_id: "10897",
						name: "Xã Cẩm Hoàng",
						type: "Xã",
					},
					{
						level3_id: "10900",
						name: "Xã Cẩm Văn",
						type: "Xã",
					},
					{
						level3_id: "10903",
						name: "Xã Ngọc Liên",
						type: "Xã",
					},
					{
						level3_id: "10906",
						name: "Xã Thạch Lỗi",
						type: "Xã",
					},
					{
						level3_id: "10909",
						name: "Xã Cẩm Vũ",
						type: "Xã",
					},
					{
						level3_id: "10912",
						name: "Xã Đức Chính",
						type: "Xã",
					},
					{
						level3_id: "10918",
						name: "Xã Định Sơn",
						type: "Xã",
					},
					{
						level3_id: "10924",
						name: "Xã Lương Điền",
						type: "Xã",
					},
					{
						level3_id: "10927",
						name: "Xã Cao An",
						type: "Xã",
					},
					{
						level3_id: "10930",
						name: "Xã Tân Trường",
						type: "Xã",
					},
					{
						level3_id: "10933",
						name: "Xã Cẩm Phúc",
						type: "Xã",
					},
					{
						level3_id: "10936",
						name: "Xã Cẩm Điền",
						type: "Xã",
					},
					{
						level3_id: "10939",
						name: "Xã Cẩm Đông",
						type: "Xã",
					},
					{
						level3_id: "10942",
						name: "Xã Cẩm Đoài",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "296",
				name: "Huyện Bình Giang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10945",
						name: "Thị trấn Kẻ Sặt",
						type: "Thị trấn",
					},
					{
						level3_id: "10951",
						name: "Xã Vĩnh Hưng",
						type: "Xã",
					},
					{
						level3_id: "10954",
						name: "Xã Hùng Thắng",
						type: "Xã",
					},
					{
						level3_id: "10960",
						name: "Xã Vĩnh Hồng",
						type: "Xã",
					},
					{
						level3_id: "10963",
						name: "Xã Long Xuyên",
						type: "Xã",
					},
					{
						level3_id: "10966",
						name: "Xã Tân Việt",
						type: "Xã",
					},
					{
						level3_id: "10969",
						name: "Xã Thúc Kháng",
						type: "Xã",
					},
					{
						level3_id: "10972",
						name: "Xã Tân Hồng",
						type: "Xã",
					},
					{
						level3_id: "10975",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "10978",
						name: "Xã Hồng Khê",
						type: "Xã",
					},
					{
						level3_id: "10981",
						name: "Xã Thái Học",
						type: "Xã",
					},
					{
						level3_id: "10984",
						name: "Xã Cổ Bì",
						type: "Xã",
					},
					{
						level3_id: "10987",
						name: "Xã Nhân Quyền",
						type: "Xã",
					},
					{
						level3_id: "10990",
						name: "Xã Thái Dương",
						type: "Xã",
					},
					{
						level3_id: "10993",
						name: "Xã Thái Hòa",
						type: "Xã",
					},
					{
						level3_id: "10996",
						name: "Xã Bình Xuyên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "297",
				name: "Huyện Gia Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "10999",
						name: "Thị trấn Gia Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "11008",
						name: "Xã Thống Nhất",
						type: "Xã",
					},
					{
						level3_id: "11020",
						name: "Xã Yết Kiêu",
						type: "Xã",
					},
					{
						level3_id: "11029",
						name: "Xã Gia Tân",
						type: "Xã",
					},
					{
						level3_id: "11032",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "11035",
						name: "Xã Gia Khánh",
						type: "Xã",
					},
					{
						level3_id: "11038",
						name: "Xã Gia Lương",
						type: "Xã",
					},
					{
						level3_id: "11041",
						name: "Xã Lê Lợi",
						type: "Xã",
					},
					{
						level3_id: "11044",
						name: "Xã Toàn Thắng",
						type: "Xã",
					},
					{
						level3_id: "11047",
						name: "Xã Hoàng Diệu",
						type: "Xã",
					},
					{
						level3_id: "11050",
						name: "Xã Hồng Hưng",
						type: "Xã",
					},
					{
						level3_id: "11053",
						name: "Xã Phạm Trấn",
						type: "Xã",
					},
					{
						level3_id: "11056",
						name: "Xã Đoàn Thượng",
						type: "Xã",
					},
					{
						level3_id: "11059",
						name: "Xã Thống Kênh",
						type: "Xã",
					},
					{
						level3_id: "11062",
						name: "Xã Quang Minh",
						type: "Xã",
					},
					{
						level3_id: "11065",
						name: "Xã Đồng Quang",
						type: "Xã",
					},
					{
						level3_id: "11068",
						name: "Xã Nhật Tân",
						type: "Xã",
					},
					{
						level3_id: "11071",
						name: "Xã Đức Xương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "298",
				name: "Huyện Tứ Kỳ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11074",
						name: "Thị trấn Tứ Kỳ",
						type: "Thị trấn",
					},
					{
						level3_id: "11083",
						name: "Xã Đại Sơn",
						type: "Xã",
					},
					{
						level3_id: "11086",
						name: "Xã Hưng Đạo",
						type: "Xã",
					},
					{
						level3_id: "11089",
						name: "Xã Ngọc Kỳ",
						type: "Xã",
					},
					{
						level3_id: "11092",
						name: "Xã Bình Lăng",
						type: "Xã",
					},
					{
						level3_id: "11095",
						name: "Xã Chí Minh",
						type: "Xã",
					},
					{
						level3_id: "11098",
						name: "Xã Tái Sơn",
						type: "Xã",
					},
					{
						level3_id: "11101",
						name: "Xã Quang Phục",
						type: "Xã",
					},
					{
						level3_id: "11110",
						name: "Xã Dân Chủ",
						type: "Xã",
					},
					{
						level3_id: "11113",
						name: "Xã Tân Kỳ",
						type: "Xã",
					},
					{
						level3_id: "11116",
						name: "Xã Quang Khải",
						type: "Xã",
					},
					{
						level3_id: "11119",
						name: "Xã Đại Hợp",
						type: "Xã",
					},
					{
						level3_id: "11122",
						name: "Xã Quảng Nghiệp",
						type: "Xã",
					},
					{
						level3_id: "11125",
						name: "Xã An Thanh",
						type: "Xã",
					},
					{
						level3_id: "11128",
						name: "Xã Minh Đức",
						type: "Xã",
					},
					{
						level3_id: "11131",
						name: "Xã Văn Tố",
						type: "Xã",
					},
					{
						level3_id: "11134",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "11137",
						name: "Xã Phượng Kỳ",
						type: "Xã",
					},
					{
						level3_id: "11140",
						name: "Xã Cộng Lạc",
						type: "Xã",
					},
					{
						level3_id: "11143",
						name: "Xã Tiên Động",
						type: "Xã",
					},
					{
						level3_id: "11146",
						name: "Xã Nguyên Giáp",
						type: "Xã",
					},
					{
						level3_id: "11149",
						name: "Xã Hà Kỳ",
						type: "Xã",
					},
					{
						level3_id: "11152",
						name: "Xã Hà Thanh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "299",
				name: "Huyện Ninh Giang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11155",
						name: "Thị trấn Ninh Giang",
						type: "Thị trấn",
					},
					{
						level3_id: "11161",
						name: "Xã Ứng Hoè",
						type: "Xã",
					},
					{
						level3_id: "11164",
						name: "Xã Nghĩa An",
						type: "Xã",
					},
					{
						level3_id: "11167",
						name: "Xã Hồng Đức",
						type: "Xã",
					},
					{
						level3_id: "11173",
						name: "Xã An Đức",
						type: "Xã",
					},
					{
						level3_id: "11176",
						name: "Xã Vạn Phúc",
						type: "Xã",
					},
					{
						level3_id: "11179",
						name: "Xã Tân Hương",
						type: "Xã",
					},
					{
						level3_id: "11185",
						name: "Xã Vĩnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "11188",
						name: "Xã Đông Xuyên",
						type: "Xã",
					},
					{
						level3_id: "11197",
						name: "Xã Tân Phong",
						type: "Xã",
					},
					{
						level3_id: "11200",
						name: "Xã Ninh Hải",
						type: "Xã",
					},
					{
						level3_id: "11203",
						name: "Xã Đồng Tâm",
						type: "Xã",
					},
					{
						level3_id: "11206",
						name: "Xã Tân Quang",
						type: "Xã",
					},
					{
						level3_id: "11209",
						name: "Xã Kiến Quốc",
						type: "Xã",
					},
					{
						level3_id: "11215",
						name: "Xã Hồng Dụ",
						type: "Xã",
					},
					{
						level3_id: "11218",
						name: "Xã Văn Hội",
						type: "Xã",
					},
					{
						level3_id: "11224",
						name: "Xã Hồng Phong",
						type: "Xã",
					},
					{
						level3_id: "11227",
						name: "Xã Hiệp Lực",
						type: "Xã",
					},
					{
						level3_id: "11230",
						name: "Xã Hồng Phúc",
						type: "Xã",
					},
					{
						level3_id: "11233",
						name: "Xã Hưng Long",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "300",
				name: "Huyện Thanh Miện",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11239",
						name: "Thị trấn Thanh Miện",
						type: "Thị trấn",
					},
					{
						level3_id: "11242",
						name: "Xã Thanh Tùng",
						type: "Xã",
					},
					{
						level3_id: "11245",
						name: "Xã Phạm Kha",
						type: "Xã",
					},
					{
						level3_id: "11248",
						name: "Xã Ngô Quyền",
						type: "Xã",
					},
					{
						level3_id: "11251",
						name: "Xã Đoàn Tùng",
						type: "Xã",
					},
					{
						level3_id: "11254",
						name: "Xã Hồng Quang",
						type: "Xã",
					},
					{
						level3_id: "11257",
						name: "Xã Tân Trào",
						type: "Xã",
					},
					{
						level3_id: "11260",
						name: "Xã Lam Sơn",
						type: "Xã",
					},
					{
						level3_id: "11263",
						name: "Xã Đoàn Kết",
						type: "Xã",
					},
					{
						level3_id: "11266",
						name: "Xã Lê Hồng",
						type: "Xã",
					},
					{
						level3_id: "11269",
						name: "Xã Tứ Cường",
						type: "Xã",
					},
					{
						level3_id: "11275",
						name: "Xã Ngũ Hùng",
						type: "Xã",
					},
					{
						level3_id: "11278",
						name: "Xã Cao Thắng",
						type: "Xã",
					},
					{
						level3_id: "11281",
						name: "Xã Chi Lăng Bắc",
						type: "Xã",
					},
					{
						level3_id: "11284",
						name: "Xã Chi Lăng Nam",
						type: "Xã",
					},
					{
						level3_id: "11287",
						name: "Xã Thanh Giang",
						type: "Xã",
					},
					{
						level3_id: "11293",
						name: "Xã Hồng Phong",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "31",
		name: "Thành phố Hải Phòng",
		type: "Thành phố Trung ương",
		level2s: [
			{
				level2_id: "303",
				name: "Quận Hồng Bàng",
				type: "Quận",
				level3s: [
					{
						level3_id: "11296",
						name: "Phường Quán Toan",
						type: "Phường",
					},
					{
						level3_id: "11299",
						name: "Phường Hùng Vương",
						type: "Phường",
					},
					{
						level3_id: "11302",
						name: "Phường Sở Dầu",
						type: "Phường",
					},
					{
						level3_id: "11305",
						name: "Phường Thượng Lý",
						type: "Phường",
					},
					{
						level3_id: "11308",
						name: "Phường Hạ Lý",
						type: "Phường",
					},
					{
						level3_id: "11311",
						name: "Phường Minh Khai",
						type: "Phường",
					},
					{
						level3_id: "11314",
						name: "Phường Trại Chuối",
						type: "Phường",
					},
					{
						level3_id: "11320",
						name: "Phường Hoàng Văn Thụ",
						type: "Phường",
					},
					{
						level3_id: "11323",
						name: "Phường Phan Bội Châu",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "304",
				name: "Quận Ngô Quyền",
				type: "Quận",
				level3s: [
					{
						level3_id: "11329",
						name: "Phường Máy Chai",
						type: "Phường",
					},
					{
						level3_id: "11332",
						name: "Phường Máy Tơ",
						type: "Phường",
					},
					{
						level3_id: "11335",
						name: "Phường Vạn Mỹ",
						type: "Phường",
					},
					{
						level3_id: "11338",
						name: "Phường Cầu Tre",
						type: "Phường",
					},
					{
						level3_id: "11341",
						name: "Phường Lạc Viên",
						type: "Phường",
					},
					{
						level3_id: "11347",
						name: "Phường Gia Viên",
						type: "Phường",
					},
					{
						level3_id: "11350",
						name: "Phường Đông Khê",
						type: "Phường",
					},
					{
						level3_id: "11353",
						name: "Phường Cầu Đất",
						type: "Phường",
					},
					{
						level3_id: "11356",
						name: "Phường Lê Lợi",
						type: "Phường",
					},
					{
						level3_id: "11359",
						name: "Phường Đằng Giang",
						type: "Phường",
					},
					{
						level3_id: "11362",
						name: "Phường Lạch Tray",
						type: "Phường",
					},
					{
						level3_id: "11365",
						name: "Phường Đổng Quốc Bình",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "305",
				name: "Quận Lê Chân",
				type: "Quận",
				level3s: [
					{
						level3_id: "11368",
						name: "Phường Cát Dài",
						type: "Phường",
					},
					{
						level3_id: "11371",
						name: "Phường An Biên",
						type: "Phường",
					},
					{
						level3_id: "11374",
						name: "Phường Lam Sơn",
						type: "Phường",
					},
					{
						level3_id: "11377",
						name: "Phường An Dương",
						type: "Phường",
					},
					{
						level3_id: "11380",
						name: "Phường Trần Nguyên Hãn",
						type: "Phường",
					},
					{
						level3_id: "11383",
						name: "Phường Hồ Nam",
						type: "Phường",
					},
					{
						level3_id: "11386",
						name: "Phường Trại Cau",
						type: "Phường",
					},
					{
						level3_id: "11389",
						name: "Phường Dư Hàng",
						type: "Phường",
					},
					{
						level3_id: "11392",
						name: "Phường Hàng Kênh",
						type: "Phường",
					},
					{
						level3_id: "11395",
						name: "Phường Đông Hải",
						type: "Phường",
					},
					{
						level3_id: "11398",
						name: "Phường Niệm Nghĩa",
						type: "Phường",
					},
					{
						level3_id: "11401",
						name: "Phường Nghĩa Xá",
						type: "Phường",
					},
					{
						level3_id: "11404",
						name: "Phường Dư Hàng Kênh",
						type: "Phường",
					},
					{
						level3_id: "11405",
						name: "Phường Kênh Dương",
						type: "Phường",
					},
					{
						level3_id: "11407",
						name: "Phường Vĩnh Niệm",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "306",
				name: "Quận Hải An",
				type: "Quận",
				level3s: [
					{
						level3_id: "11410",
						name: "Phường Đông Hải 1",
						type: "Phường",
					},
					{
						level3_id: "11411",
						name: "Phường Đông Hải 2",
						type: "Phường",
					},
					{
						level3_id: "11413",
						name: "Phường Đằng Lâm",
						type: "Phường",
					},
					{
						level3_id: "11414",
						name: "Phường Thành Tô",
						type: "Phường",
					},
					{
						level3_id: "11416",
						name: "Phường Đằng Hải",
						type: "Phường",
					},
					{
						level3_id: "11419",
						name: "Phường Nam Hải",
						type: "Phường",
					},
					{
						level3_id: "11422",
						name: "Phường Cát Bi",
						type: "Phường",
					},
					{
						level3_id: "11425",
						name: "Phường Tràng Cát",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "307",
				name: "Quận Kiến An",
				type: "Quận",
				level3s: [
					{
						level3_id: "11428",
						name: "Phường Quán Trữ",
						type: "Phường",
					},
					{
						level3_id: "11429",
						name: "Phường Lãm Hà",
						type: "Phường",
					},
					{
						level3_id: "11431",
						name: "Phường Đồng Hoà",
						type: "Phường",
					},
					{
						level3_id: "11434",
						name: "Phường Bắc Sơn",
						type: "Phường",
					},
					{
						level3_id: "11437",
						name: "Phường Nam Sơn",
						type: "Phường",
					},
					{
						level3_id: "11440",
						name: "Phường Ngọc Sơn",
						type: "Phường",
					},
					{
						level3_id: "11443",
						name: "Phường Trần Thành Ngọ",
						type: "Phường",
					},
					{
						level3_id: "11446",
						name: "Phường Văn Đẩu",
						type: "Phường",
					},
					{
						level3_id: "11449",
						name: "Phường Phù Liễn",
						type: "Phường",
					},
					{
						level3_id: "11452",
						name: "Phường Tràng Minh",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "308",
				name: "Quận Đồ Sơn",
				type: "Quận",
				level3s: [
					{
						level3_id: "11455",
						name: "Phường Ngọc Xuyên",
						type: "Phường",
					},
					{
						level3_id: "11458",
						name: "Phường Hải Sơn",
						type: "Phường",
					},
					{
						level3_id: "11461",
						name: "Phường Vạn Hương",
						type: "Phường",
					},
					{
						level3_id: "11465",
						name: "Phường Minh Đức",
						type: "Phường",
					},
					{
						level3_id: "11467",
						name: "Phường Bàng La",
						type: "Phường",
					},
					{
						level3_id: "11737",
						name: "Phường Hợp Đức",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "309",
				name: "Quận Dương Kinh",
				type: "Quận",
				level3s: [
					{
						level3_id: "11683",
						name: "Phường Đa Phúc",
						type: "Phường",
					},
					{
						level3_id: "11686",
						name: "Phường Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "11689",
						name: "Phường Anh Dũng",
						type: "Phường",
					},
					{
						level3_id: "11692",
						name: "Phường Hải Thành",
						type: "Phường",
					},
					{
						level3_id: "11707",
						name: "Phường Hoà Nghĩa",
						type: "Phường",
					},
					{
						level3_id: "11740",
						name: "Phường Tân Thành",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "311",
				name: "Huyện Thuỷ Nguyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11470",
						name: "Thị trấn Núi Đèo",
						type: "Thị trấn",
					},
					{
						level3_id: "11473",
						name: "Thị trấn Minh Đức",
						type: "Thị trấn",
					},
					{
						level3_id: "11476",
						name: "Xã Lại Xuân",
						type: "Xã",
					},
					{
						level3_id: "11479",
						name: "Xã An Sơn",
						type: "Xã",
					},
					{
						level3_id: "11482",
						name: "Xã Kỳ Sơn",
						type: "Xã",
					},
					{
						level3_id: "11485",
						name: "Xã Liên Khê",
						type: "Xã",
					},
					{
						level3_id: "11488",
						name: "Xã Lưu Kiếm",
						type: "Xã",
					},
					{
						level3_id: "11491",
						name: "Xã Lưu Kỳ",
						type: "Xã",
					},
					{
						level3_id: "11494",
						name: "Xã Gia Minh",
						type: "Xã",
					},
					{
						level3_id: "11497",
						name: "Xã Gia Đức",
						type: "Xã",
					},
					{
						level3_id: "11500",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "11503",
						name: "Xã Phù Ninh",
						type: "Xã",
					},
					{
						level3_id: "11506",
						name: "Xã Quảng Thanh",
						type: "Xã",
					},
					{
						level3_id: "11509",
						name: "Xã Chính Mỹ",
						type: "Xã",
					},
					{
						level3_id: "11512",
						name: "Xã Kênh Giang",
						type: "Xã",
					},
					{
						level3_id: "11515",
						name: "Xã Hợp Thành",
						type: "Xã",
					},
					{
						level3_id: "11518",
						name: "Xã Cao Nhân",
						type: "Xã",
					},
					{
						level3_id: "11521",
						name: "Xã Mỹ Đồng",
						type: "Xã",
					},
					{
						level3_id: "11524",
						name: "Xã Đông Sơn",
						type: "Xã",
					},
					{
						level3_id: "11527",
						name: "Xã Hoà Bình",
						type: "Xã",
					},
					{
						level3_id: "11530",
						name: "Xã Trung Hà",
						type: "Xã",
					},
					{
						level3_id: "11533",
						name: "Xã An Lư",
						type: "Xã",
					},
					{
						level3_id: "11536",
						name: "Xã Thuỷ Triều",
						type: "Xã",
					},
					{
						level3_id: "11539",
						name: "Xã Ngũ Lão",
						type: "Xã",
					},
					{
						level3_id: "11542",
						name: "Xã Phục Lễ",
						type: "Xã",
					},
					{
						level3_id: "11545",
						name: "Xã Tam Hưng",
						type: "Xã",
					},
					{
						level3_id: "11548",
						name: "Xã Phả Lễ",
						type: "Xã",
					},
					{
						level3_id: "11551",
						name: "Xã Lập Lễ",
						type: "Xã",
					},
					{
						level3_id: "11554",
						name: "Xã Kiền Bái",
						type: "Xã",
					},
					{
						level3_id: "11557",
						name: "Xã Thiên Hương",
						type: "Xã",
					},
					{
						level3_id: "11560",
						name: "Xã Thuỷ Sơn",
						type: "Xã",
					},
					{
						level3_id: "11563",
						name: "Xã Thuỷ Đường",
						type: "Xã",
					},
					{
						level3_id: "11566",
						name: "Xã Hoàng Động",
						type: "Xã",
					},
					{
						level3_id: "11569",
						name: "Xã Lâm Động",
						type: "Xã",
					},
					{
						level3_id: "11572",
						name: "Xã Hoa Động",
						type: "Xã",
					},
					{
						level3_id: "11575",
						name: "Xã Tân Dương",
						type: "Xã",
					},
					{
						level3_id: "11578",
						name: "Xã Dương Quan",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "312",
				name: "Huyện An Dương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11581",
						name: "Thị trấn An Dương",
						type: "Thị trấn",
					},
					{
						level3_id: "11584",
						name: "Xã Lê Thiện",
						type: "Xã",
					},
					{
						level3_id: "11587",
						name: "Xã Đại Bản",
						type: "Xã",
					},
					{
						level3_id: "11590",
						name: "Xã An Hoà",
						type: "Xã",
					},
					{
						level3_id: "11593",
						name: "Xã Hồng Phong",
						type: "Xã",
					},
					{
						level3_id: "11596",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "11599",
						name: "Xã An Hưng",
						type: "Xã",
					},
					{
						level3_id: "11602",
						name: "Xã An Hồng",
						type: "Xã",
					},
					{
						level3_id: "11605",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "11608",
						name: "Xã Nam Sơn",
						type: "Xã",
					},
					{
						level3_id: "11611",
						name: "Xã Lê Lợi",
						type: "Xã",
					},
					{
						level3_id: "11614",
						name: "Xã Đặng Cương",
						type: "Xã",
					},
					{
						level3_id: "11617",
						name: "Xã Đồng Thái",
						type: "Xã",
					},
					{
						level3_id: "11620",
						name: "Xã Quốc Tuấn",
						type: "Xã",
					},
					{
						level3_id: "11623",
						name: "Xã An Đồng",
						type: "Xã",
					},
					{
						level3_id: "11626",
						name: "Xã Hồng Thái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "313",
				name: "Huyện An Lão",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11629",
						name: "Thị trấn An Lão",
						type: "Thị trấn",
					},
					{
						level3_id: "11632",
						name: "Xã Bát Trang",
						type: "Xã",
					},
					{
						level3_id: "11635",
						name: "Xã Trường Thọ",
						type: "Xã",
					},
					{
						level3_id: "11638",
						name: "Xã Trường Thành",
						type: "Xã",
					},
					{
						level3_id: "11641",
						name: "Xã An Tiến",
						type: "Xã",
					},
					{
						level3_id: "11644",
						name: "Xã Quang Hưng",
						type: "Xã",
					},
					{
						level3_id: "11647",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "11650",
						name: "Xã Quốc Tuấn",
						type: "Xã",
					},
					{
						level3_id: "11653",
						name: "Xã An Thắng",
						type: "Xã",
					},
					{
						level3_id: "11656",
						name: "Thị trấn Trường Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "11659",
						name: "Xã Tân Dân",
						type: "Xã",
					},
					{
						level3_id: "11662",
						name: "Xã Thái Sơn",
						type: "Xã",
					},
					{
						level3_id: "11665",
						name: "Xã Tân Viên",
						type: "Xã",
					},
					{
						level3_id: "11668",
						name: "Xã Mỹ Đức",
						type: "Xã",
					},
					{
						level3_id: "11671",
						name: "Xã Chiến Thắng",
						type: "Xã",
					},
					{
						level3_id: "11674",
						name: "Xã An Thọ",
						type: "Xã",
					},
					{
						level3_id: "11677",
						name: "Xã An Thái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "314",
				name: "Huyện Kiến Thuỵ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11680",
						name: "Thị trấn Núi Đối",
						type: "Thị trấn",
					},
					{
						level3_id: "11695",
						name: "Xã Đông Phương",
						type: "Xã",
					},
					{
						level3_id: "11698",
						name: "Xã Thuận Thiên",
						type: "Xã",
					},
					{
						level3_id: "11701",
						name: "Xã Hữu Bằng",
						type: "Xã",
					},
					{
						level3_id: "11704",
						name: "Xã Đại Đồng",
						type: "Xã",
					},
					{
						level3_id: "11710",
						name: "Xã Ngũ Phúc",
						type: "Xã",
					},
					{
						level3_id: "11713",
						name: "Xã Kiến Quốc",
						type: "Xã",
					},
					{
						level3_id: "11716",
						name: "Xã Du Lễ",
						type: "Xã",
					},
					{
						level3_id: "11719",
						name: "Xã Thuỵ Hương",
						type: "Xã",
					},
					{
						level3_id: "11722",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "11725",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "11728",
						name: "Xã Đại Hà",
						type: "Xã",
					},
					{
						level3_id: "11731",
						name: "Xã Ngũ Đoan",
						type: "Xã",
					},
					{
						level3_id: "11734",
						name: "Xã Tân Phong",
						type: "Xã",
					},
					{
						level3_id: "11743",
						name: "Xã Tân Trào",
						type: "Xã",
					},
					{
						level3_id: "11746",
						name: "Xã Đoàn Xá",
						type: "Xã",
					},
					{
						level3_id: "11749",
						name: "Xã Tú Sơn",
						type: "Xã",
					},
					{
						level3_id: "11752",
						name: "Xã Đại Hợp",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "315",
				name: "Huyện Tiên Lãng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11755",
						name: "Thị trấn Tiên Lãng",
						type: "Thị trấn",
					},
					{
						level3_id: "11758",
						name: "Xã Đại Thắng",
						type: "Xã",
					},
					{
						level3_id: "11761",
						name: "Xã Tiên Cường",
						type: "Xã",
					},
					{
						level3_id: "11764",
						name: "Xã Tự Cường",
						type: "Xã",
					},
					{
						level3_id: "11770",
						name: "Xã Quyết Tiến",
						type: "Xã",
					},
					{
						level3_id: "11773",
						name: "Xã Khởi Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "11776",
						name: "Xã Tiên Thanh",
						type: "Xã",
					},
					{
						level3_id: "11779",
						name: "Xã Cấp Tiến",
						type: "Xã",
					},
					{
						level3_id: "11782",
						name: "Xã Kiến Thiết",
						type: "Xã",
					},
					{
						level3_id: "11785",
						name: "Xã Đoàn Lập",
						type: "Xã",
					},
					{
						level3_id: "11788",
						name: "Xã Bạch Đằng",
						type: "Xã",
					},
					{
						level3_id: "11791",
						name: "Xã Quang Phục",
						type: "Xã",
					},
					{
						level3_id: "11794",
						name: "Xã Toàn Thắng",
						type: "Xã",
					},
					{
						level3_id: "11797",
						name: "Xã Tiên Thắng",
						type: "Xã",
					},
					{
						level3_id: "11800",
						name: "Xã Tiên Minh",
						type: "Xã",
					},
					{
						level3_id: "11803",
						name: "Xã Bắc Hưng",
						type: "Xã",
					},
					{
						level3_id: "11806",
						name: "Xã Nam Hưng",
						type: "Xã",
					},
					{
						level3_id: "11809",
						name: "Xã Hùng Thắng",
						type: "Xã",
					},
					{
						level3_id: "11812",
						name: "Xã Tây Hưng",
						type: "Xã",
					},
					{
						level3_id: "11815",
						name: "Xã Đông Hưng",
						type: "Xã",
					},
					{
						level3_id: "11821",
						name: "Xã Vinh Quang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "316",
				name: "Huyện Vĩnh Bảo",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11824",
						name: "Thị trấn Vĩnh Bảo",
						type: "Thị trấn",
					},
					{
						level3_id: "11827",
						name: "Xã Dũng Tiến",
						type: "Xã",
					},
					{
						level3_id: "11830",
						name: "Xã Giang Biên",
						type: "Xã",
					},
					{
						level3_id: "11833",
						name: "Xã Thắng Thuỷ",
						type: "Xã",
					},
					{
						level3_id: "11836",
						name: "Xã Trung Lập",
						type: "Xã",
					},
					{
						level3_id: "11839",
						name: "Xã Việt Tiến",
						type: "Xã",
					},
					{
						level3_id: "11842",
						name: "Xã Vĩnh An",
						type: "Xã",
					},
					{
						level3_id: "11845",
						name: "Xã Vĩnh Long",
						type: "Xã",
					},
					{
						level3_id: "11848",
						name: "Xã Hiệp Hoà",
						type: "Xã",
					},
					{
						level3_id: "11851",
						name: "Xã Hùng Tiến",
						type: "Xã",
					},
					{
						level3_id: "11854",
						name: "Xã An Hoà",
						type: "Xã",
					},
					{
						level3_id: "11857",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "11860",
						name: "Xã Tân Liên",
						type: "Xã",
					},
					{
						level3_id: "11863",
						name: "Xã Nhân Hoà",
						type: "Xã",
					},
					{
						level3_id: "11866",
						name: "Xã Tam Đa",
						type: "Xã",
					},
					{
						level3_id: "11869",
						name: "Xã Hưng Nhân",
						type: "Xã",
					},
					{
						level3_id: "11872",
						name: "Xã Vinh Quang",
						type: "Xã",
					},
					{
						level3_id: "11875",
						name: "Xã Đồng Minh",
						type: "Xã",
					},
					{
						level3_id: "11878",
						name: "Xã Thanh Lương",
						type: "Xã",
					},
					{
						level3_id: "11881",
						name: "Xã Liên Am",
						type: "Xã",
					},
					{
						level3_id: "11884",
						name: "Xã Lý Học",
						type: "Xã",
					},
					{
						level3_id: "11887",
						name: "Xã Tam Cường",
						type: "Xã",
					},
					{
						level3_id: "11890",
						name: "Xã Hoà Bình",
						type: "Xã",
					},
					{
						level3_id: "11893",
						name: "Xã Tiền Phong",
						type: "Xã",
					},
					{
						level3_id: "11896",
						name: "Xã Vĩnh Phong",
						type: "Xã",
					},
					{
						level3_id: "11899",
						name: "Xã Cộng Hiền",
						type: "Xã",
					},
					{
						level3_id: "11902",
						name: "Xã Cao Minh",
						type: "Xã",
					},
					{
						level3_id: "11905",
						name: "Xã Cổ Am",
						type: "Xã",
					},
					{
						level3_id: "11908",
						name: "Xã Vĩnh Tiến",
						type: "Xã",
					},
					{
						level3_id: "11911",
						name: "Xã Trấn Dương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "317",
				name: "Huyện Cát Hải",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11914",
						name: "Thị trấn Cát Bà",
						type: "Thị trấn",
					},
					{
						level3_id: "11917",
						name: "Thị trấn Cát Hải",
						type: "Thị trấn",
					},
					{
						level3_id: "11920",
						name: "Xã Nghĩa Lộ",
						type: "Xã",
					},
					{
						level3_id: "11923",
						name: "Xã Đồng Bài",
						type: "Xã",
					},
					{
						level3_id: "11926",
						name: "Xã Hoàng Châu",
						type: "Xã",
					},
					{
						level3_id: "11929",
						name: "Xã Văn Phong",
						type: "Xã",
					},
					{
						level3_id: "11932",
						name: "Xã Phù Long",
						type: "Xã",
					},
					{
						level3_id: "11935",
						name: "Xã Gia Luận",
						type: "Xã",
					},
					{
						level3_id: "11938",
						name: "Xã Hiền Hào",
						type: "Xã",
					},
					{
						level3_id: "11941",
						name: "Xã Trân Châu",
						type: "Xã",
					},
					{
						level3_id: "11944",
						name: "Xã Việt Hải",
						type: "Xã",
					},
					{
						level3_id: "11947",
						name: "Xã Xuân Đám",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "318",
				name: "Huyện Bạch Long Vĩ",
				type: "Huyện",
				level3s: [],
			},
		],
	},
	{
		level1_id: "33",
		name: "Tỉnh Hưng Yên",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "323",
				name: "Thành phố Hưng Yên",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "11950",
						name: "Phường Lam Sơn",
						type: "Phường",
					},
					{
						level3_id: "11953",
						name: "Phường Hiến Nam",
						type: "Phường",
					},
					{
						level3_id: "11956",
						name: "Phường An Tảo",
						type: "Phường",
					},
					{
						level3_id: "11959",
						name: "Phường Lê Lợi",
						type: "Phường",
					},
					{
						level3_id: "11962",
						name: "Phường Minh Khai",
						type: "Phường",
					},
					{
						level3_id: "11965",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "11968",
						name: "Phường Hồng Châu",
						type: "Phường",
					},
					{
						level3_id: "11971",
						name: "Xã Trung Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "11974",
						name: "Xã Liên Phương",
						type: "Xã",
					},
					{
						level3_id: "11977",
						name: "Xã Hồng Nam",
						type: "Xã",
					},
					{
						level3_id: "11980",
						name: "Xã Quảng Châu",
						type: "Xã",
					},
					{
						level3_id: "11983",
						name: "Xã Bảo Khê",
						type: "Xã",
					},
					{
						level3_id: "12331",
						name: "Xã Phú Cường",
						type: "Xã",
					},
					{
						level3_id: "12334",
						name: "Xã Hùng Cường",
						type: "Xã",
					},
					{
						level3_id: "12382",
						name: "Xã Phương Chiểu",
						type: "Xã",
					},
					{
						level3_id: "12385",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "12388",
						name: "Xã Hoàng Hanh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "325",
				name: "Huyện Văn Lâm",
				type: "Huyện",
				level3s: [
					{
						level3_id: "11986",
						name: "Thị trấn Như Quỳnh",
						type: "Thị trấn",
					},
					{
						level3_id: "11989",
						name: "Xã Lạc Đạo",
						type: "Xã",
					},
					{
						level3_id: "11992",
						name: "Xã Chỉ Đạo",
						type: "Xã",
					},
					{
						level3_id: "11995",
						name: "Xã Đại Đồng",
						type: "Xã",
					},
					{
						level3_id: "11998",
						name: "Xã Việt Hưng",
						type: "Xã",
					},
					{
						level3_id: "12001",
						name: "Xã Tân Quang",
						type: "Xã",
					},
					{
						level3_id: "12004",
						name: "Xã Đình Dù",
						type: "Xã",
					},
					{
						level3_id: "12007",
						name: "Xã Minh Hải",
						type: "Xã",
					},
					{
						level3_id: "12010",
						name: "Xã Lương Tài",
						type: "Xã",
					},
					{
						level3_id: "12013",
						name: "Xã Trưng Trắc",
						type: "Xã",
					},
					{
						level3_id: "12016",
						name: "Xã Lạc Hồng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "326",
				name: "Huyện Văn Giang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12019",
						name: "Thị trấn Văn Giang",
						type: "Thị trấn",
					},
					{
						level3_id: "12022",
						name: "Xã Xuân Quan",
						type: "Xã",
					},
					{
						level3_id: "12025",
						name: "Xã Cửu Cao",
						type: "Xã",
					},
					{
						level3_id: "12028",
						name: "Xã Phụng Công",
						type: "Xã",
					},
					{
						level3_id: "12031",
						name: "Xã Nghĩa Trụ",
						type: "Xã",
					},
					{
						level3_id: "12034",
						name: "Xã Long Hưng",
						type: "Xã",
					},
					{
						level3_id: "12037",
						name: "Xã Vĩnh Khúc",
						type: "Xã",
					},
					{
						level3_id: "12040",
						name: "Xã Liên Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "12043",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "12046",
						name: "Xã Thắng Lợi",
						type: "Xã",
					},
					{
						level3_id: "12049",
						name: "Xã Mễ Sở",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "327",
				name: "Huyện Yên Mỹ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12052",
						name: "Thị trấn Yên Mỹ",
						type: "Thị trấn",
					},
					{
						level3_id: "12055",
						name: "Xã Giai Phạm",
						type: "Xã",
					},
					{
						level3_id: "12058",
						name: "Xã Nghĩa Hiệp",
						type: "Xã",
					},
					{
						level3_id: "12061",
						name: "Xã Đồng Than",
						type: "Xã",
					},
					{
						level3_id: "12064",
						name: "Xã Ngọc Long",
						type: "Xã",
					},
					{
						level3_id: "12067",
						name: "Xã Liêu Xá",
						type: "Xã",
					},
					{
						level3_id: "12070",
						name: "Xã Hoàn Long",
						type: "Xã",
					},
					{
						level3_id: "12073",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "12076",
						name: "Xã Thanh Long",
						type: "Xã",
					},
					{
						level3_id: "12079",
						name: "Xã Yên Phú",
						type: "Xã",
					},
					{
						level3_id: "12082",
						name: "Xã Việt Cường",
						type: "Xã",
					},
					{
						level3_id: "12085",
						name: "Xã Trung Hòa",
						type: "Xã",
					},
					{
						level3_id: "12088",
						name: "Xã Yên Hòa",
						type: "Xã",
					},
					{
						level3_id: "12091",
						name: "Xã Minh Châu",
						type: "Xã",
					},
					{
						level3_id: "12094",
						name: "Xã Trung Hưng",
						type: "Xã",
					},
					{
						level3_id: "12097",
						name: "Xã Lý Thường Kiệt",
						type: "Xã",
					},
					{
						level3_id: "12100",
						name: "Xã Tân Việt",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "328",
				name: "Thị xã Mỹ Hào",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "12103",
						name: "Phường Bần Yên Nhân",
						type: "Phường",
					},
					{
						level3_id: "12106",
						name: "Phường Phan Đình Phùng",
						type: "Phường",
					},
					{
						level3_id: "12109",
						name: "Xã Cẩm Xá",
						type: "Xã",
					},
					{
						level3_id: "12112",
						name: "Xã Dương Quang",
						type: "Xã",
					},
					{
						level3_id: "12115",
						name: "Xã Hòa Phong",
						type: "Xã",
					},
					{
						level3_id: "12118",
						name: "Phường Nhân Hòa",
						type: "Phường",
					},
					{
						level3_id: "12121",
						name: "Phường Dị Sử",
						type: "Phường",
					},
					{
						level3_id: "12124",
						name: "Phường Bạch Sam",
						type: "Phường",
					},
					{
						level3_id: "12127",
						name: "Phường Minh Đức",
						type: "Phường",
					},
					{
						level3_id: "12130",
						name: "Phường Phùng Chí Kiên",
						type: "Phường",
					},
					{
						level3_id: "12133",
						name: "Xã Xuân Dục",
						type: "Xã",
					},
					{
						level3_id: "12136",
						name: "Xã Ngọc Lâm",
						type: "Xã",
					},
					{
						level3_id: "12139",
						name: "Xã Hưng Long",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "329",
				name: "Huyện Ân Thi",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12142",
						name: "Thị trấn Ân Thi",
						type: "Thị trấn",
					},
					{
						level3_id: "12145",
						name: "Xã Phù Ủng",
						type: "Xã",
					},
					{
						level3_id: "12148",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "12151",
						name: "Xã Bãi Sậy",
						type: "Xã",
					},
					{
						level3_id: "12154",
						name: "Xã Đào Dương",
						type: "Xã",
					},
					{
						level3_id: "12157",
						name: "Xã Tân Phúc",
						type: "Xã",
					},
					{
						level3_id: "12160",
						name: "Xã Vân Du",
						type: "Xã",
					},
					{
						level3_id: "12163",
						name: "Xã Quang Vinh",
						type: "Xã",
					},
					{
						level3_id: "12166",
						name: "Xã Xuân Trúc",
						type: "Xã",
					},
					{
						level3_id: "12169",
						name: "Xã Hoàng Hoa Thám",
						type: "Xã",
					},
					{
						level3_id: "12172",
						name: "Xã Quảng Lãng",
						type: "Xã",
					},
					{
						level3_id: "12175",
						name: "Xã Văn Nhuệ",
						type: "Xã",
					},
					{
						level3_id: "12178",
						name: "Xã Đặng Lễ",
						type: "Xã",
					},
					{
						level3_id: "12181",
						name: "Xã Cẩm Ninh",
						type: "Xã",
					},
					{
						level3_id: "12184",
						name: "Xã Nguyễn Trãi",
						type: "Xã",
					},
					{
						level3_id: "12187",
						name: "Xã Đa Lộc",
						type: "Xã",
					},
					{
						level3_id: "12190",
						name: "Xã Hồ Tùng Mậu",
						type: "Xã",
					},
					{
						level3_id: "12193",
						name: "Xã Tiền Phong",
						type: "Xã",
					},
					{
						level3_id: "12196",
						name: "Xã Hồng Vân",
						type: "Xã",
					},
					{
						level3_id: "12199",
						name: "Xã Hồng Quang",
						type: "Xã",
					},
					{
						level3_id: "12202",
						name: "Xã Hạ Lễ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "330",
				name: "Huyện Khoái Châu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12205",
						name: "Thị trấn Khoái Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "12208",
						name: "Xã Đông Tảo",
						type: "Xã",
					},
					{
						level3_id: "12211",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "12214",
						name: "Xã Dạ Trạch",
						type: "Xã",
					},
					{
						level3_id: "12217",
						name: "Xã Hàm Tử",
						type: "Xã",
					},
					{
						level3_id: "12220",
						name: "Xã Ông Đình",
						type: "Xã",
					},
					{
						level3_id: "12223",
						name: "Xã Tân Dân",
						type: "Xã",
					},
					{
						level3_id: "12226",
						name: "Xã Tứ Dân",
						type: "Xã",
					},
					{
						level3_id: "12229",
						name: "Xã An Vĩ",
						type: "Xã",
					},
					{
						level3_id: "12232",
						name: "Xã Đông Kết",
						type: "Xã",
					},
					{
						level3_id: "12235",
						name: "Xã Bình Kiều",
						type: "Xã",
					},
					{
						level3_id: "12238",
						name: "Xã Dân Tiến",
						type: "Xã",
					},
					{
						level3_id: "12241",
						name: "Xã Đồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "12244",
						name: "Xã Hồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "12247",
						name: "Xã Tân Châu",
						type: "Xã",
					},
					{
						level3_id: "12250",
						name: "Xã Liên Khê",
						type: "Xã",
					},
					{
						level3_id: "12253",
						name: "Xã Phùng Hưng",
						type: "Xã",
					},
					{
						level3_id: "12256",
						name: "Xã Việt Hòa",
						type: "Xã",
					},
					{
						level3_id: "12259",
						name: "Xã Đông Ninh",
						type: "Xã",
					},
					{
						level3_id: "12262",
						name: "Xã Đại Tập",
						type: "Xã",
					},
					{
						level3_id: "12265",
						name: "Xã Chí Tân",
						type: "Xã",
					},
					{
						level3_id: "12268",
						name: "Xã Đại Hưng",
						type: "Xã",
					},
					{
						level3_id: "12271",
						name: "Xã Thuần Hưng",
						type: "Xã",
					},
					{
						level3_id: "12274",
						name: "Xã Thành Công",
						type: "Xã",
					},
					{
						level3_id: "12277",
						name: "Xã Nhuế Dương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "331",
				name: "Huyện Kim Động",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12280",
						name: "Thị trấn Lương Bằng",
						type: "Thị trấn",
					},
					{
						level3_id: "12283",
						name: "Xã Nghĩa Dân",
						type: "Xã",
					},
					{
						level3_id: "12286",
						name: "Xã Toàn Thắng",
						type: "Xã",
					},
					{
						level3_id: "12289",
						name: "Xã Vĩnh Xá",
						type: "Xã",
					},
					{
						level3_id: "12292",
						name: "Xã Phạm Ngũ Lão",
						type: "Xã",
					},
					{
						level3_id: "12295",
						name: "Xã Thọ Vinh",
						type: "Xã",
					},
					{
						level3_id: "12298",
						name: "Xã Đồng Thanh",
						type: "Xã",
					},
					{
						level3_id: "12301",
						name: "Xã Song Mai",
						type: "Xã",
					},
					{
						level3_id: "12304",
						name: "Xã Chính Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "12307",
						name: "Xã Nhân La",
						type: "Xã",
					},
					{
						level3_id: "12310",
						name: "Xã Phú Thịnh",
						type: "Xã",
					},
					{
						level3_id: "12313",
						name: "Xã Mai Động",
						type: "Xã",
					},
					{
						level3_id: "12316",
						name: "Xã Đức Hợp",
						type: "Xã",
					},
					{
						level3_id: "12319",
						name: "Xã Hùng An",
						type: "Xã",
					},
					{
						level3_id: "12322",
						name: "Xã Ngọc Thanh",
						type: "Xã",
					},
					{
						level3_id: "12325",
						name: "Xã Vũ Xá",
						type: "Xã",
					},
					{
						level3_id: "12328",
						name: "Xã Hiệp Cường",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "332",
				name: "Huyện Tiên Lữ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12337",
						name: "Thị trấn Vương",
						type: "Thị trấn",
					},
					{
						level3_id: "12340",
						name: "Xã Hưng Đạo",
						type: "Xã",
					},
					{
						level3_id: "12343",
						name: "Xã Ngô Quyền",
						type: "Xã",
					},
					{
						level3_id: "12346",
						name: "Xã Nhật Tân",
						type: "Xã",
					},
					{
						level3_id: "12349",
						name: "Xã Dị Chế",
						type: "Xã",
					},
					{
						level3_id: "12352",
						name: "Xã Lệ Xá",
						type: "Xã",
					},
					{
						level3_id: "12355",
						name: "Xã An Viên",
						type: "Xã",
					},
					{
						level3_id: "12358",
						name: "Xã Đức Thắng",
						type: "Xã",
					},
					{
						level3_id: "12361",
						name: "Xã Trung Dũng",
						type: "Xã",
					},
					{
						level3_id: "12364",
						name: "Xã Hải Triều",
						type: "Xã",
					},
					{
						level3_id: "12367",
						name: "Xã Thủ Sỹ",
						type: "Xã",
					},
					{
						level3_id: "12370",
						name: "Xã Thiện Phiến",
						type: "Xã",
					},
					{
						level3_id: "12373",
						name: "Xã Thụy Lôi",
						type: "Xã",
					},
					{
						level3_id: "12376",
						name: "Xã Cương Chính",
						type: "Xã",
					},
					{
						level3_id: "12379",
						name: "Xã Minh Phượng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "333",
				name: "Huyện Phù Cừ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12391",
						name: "Thị trấn Trần Cao",
						type: "Thị trấn",
					},
					{
						level3_id: "12394",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "12397",
						name: "Xã Phan Sào Nam",
						type: "Xã",
					},
					{
						level3_id: "12400",
						name: "Xã Quang Hưng",
						type: "Xã",
					},
					{
						level3_id: "12403",
						name: "Xã Minh Hoàng",
						type: "Xã",
					},
					{
						level3_id: "12406",
						name: "Xã Đoàn Đào",
						type: "Xã",
					},
					{
						level3_id: "12409",
						name: "Xã Tống Phan",
						type: "Xã",
					},
					{
						level3_id: "12412",
						name: "Xã Đình Cao",
						type: "Xã",
					},
					{
						level3_id: "12415",
						name: "Xã Nhật Quang",
						type: "Xã",
					},
					{
						level3_id: "12418",
						name: "Xã Tiền Tiến",
						type: "Xã",
					},
					{
						level3_id: "12421",
						name: "Xã Tam Đa",
						type: "Xã",
					},
					{
						level3_id: "12424",
						name: "Xã Minh Tiến",
						type: "Xã",
					},
					{
						level3_id: "12427",
						name: "Xã Nguyên Hòa",
						type: "Xã",
					},
					{
						level3_id: "12430",
						name: "Xã Tống Trân",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "34",
		name: "Tỉnh Thái Bình",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "336",
				name: "Thành phố Thái Bình",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "12433",
						name: "Phường Lê Hồng Phong",
						type: "Phường",
					},
					{
						level3_id: "12436",
						name: "Phường Bồ Xuyên",
						type: "Phường",
					},
					{
						level3_id: "12439",
						name: "Phường Đề Thám",
						type: "Phường",
					},
					{
						level3_id: "12442",
						name: "Phường Kỳ Bá",
						type: "Phường",
					},
					{
						level3_id: "12445",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "12448",
						name: "Phường Phú Khánh",
						type: "Phường",
					},
					{
						level3_id: "12451",
						name: "Phường Tiền Phong",
						type: "Phường",
					},
					{
						level3_id: "12452",
						name: "Phường Trần Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "12454",
						name: "Phường Trần Lãm",
						type: "Phường",
					},
					{
						level3_id: "12457",
						name: "Xã Đông Hòa",
						type: "Xã",
					},
					{
						level3_id: "12460",
						name: "Phường Hoàng Diệu",
						type: "Phường",
					},
					{
						level3_id: "12463",
						name: "Xã Phú Xuân",
						type: "Xã",
					},
					{
						level3_id: "12466",
						name: "Xã Vũ Phúc",
						type: "Xã",
					},
					{
						level3_id: "12469",
						name: "Xã Vũ Chính",
						type: "Xã",
					},
					{
						level3_id: "12817",
						name: "Xã Đông Mỹ",
						type: "Xã",
					},
					{
						level3_id: "12820",
						name: "Xã Đông Thọ",
						type: "Xã",
					},
					{
						level3_id: "13084",
						name: "Xã Vũ Đông",
						type: "Xã",
					},
					{
						level3_id: "13108",
						name: "Xã Vũ Lạc",
						type: "Xã",
					},
					{
						level3_id: "13225",
						name: "Xã Tân Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "338",
				name: "Huyện Quỳnh Phụ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12472",
						name: "Thị trấn Quỳnh Côi",
						type: "Thị trấn",
					},
					{
						level3_id: "12475",
						name: "Xã An Khê",
						type: "Xã",
					},
					{
						level3_id: "12478",
						name: "Xã An Đồng",
						type: "Xã",
					},
					{
						level3_id: "12481",
						name: "Xã Quỳnh Hoa",
						type: "Xã",
					},
					{
						level3_id: "12484",
						name: "Xã Quỳnh Lâm",
						type: "Xã",
					},
					{
						level3_id: "12487",
						name: "Xã Quỳnh Thọ",
						type: "Xã",
					},
					{
						level3_id: "12490",
						name: "Xã An Hiệp",
						type: "Xã",
					},
					{
						level3_id: "12493",
						name: "Xã Quỳnh Hoàng",
						type: "Xã",
					},
					{
						level3_id: "12496",
						name: "Xã Quỳnh Giao",
						type: "Xã",
					},
					{
						level3_id: "12499",
						name: "Xã An Thái",
						type: "Xã",
					},
					{
						level3_id: "12502",
						name: "Xã An Cầu",
						type: "Xã",
					},
					{
						level3_id: "12505",
						name: "Xã Quỳnh Hồng",
						type: "Xã",
					},
					{
						level3_id: "12508",
						name: "Xã Quỳnh Khê",
						type: "Xã",
					},
					{
						level3_id: "12511",
						name: "Xã Quỳnh Minh",
						type: "Xã",
					},
					{
						level3_id: "12514",
						name: "Xã An Ninh",
						type: "Xã",
					},
					{
						level3_id: "12517",
						name: "Xã Quỳnh Ngọc",
						type: "Xã",
					},
					{
						level3_id: "12520",
						name: "Xã Quỳnh Hải",
						type: "Xã",
					},
					{
						level3_id: "12523",
						name: "Thị trấn An Bài",
						type: "Thị trấn",
					},
					{
						level3_id: "12526",
						name: "Xã An Ấp",
						type: "Xã",
					},
					{
						level3_id: "12529",
						name: "Xã Quỳnh Hội",
						type: "Xã",
					},
					{
						level3_id: "12532",
						name: "Xã Châu Sơn",
						type: "Xã",
					},
					{
						level3_id: "12535",
						name: "Xã Quỳnh Mỹ",
						type: "Xã",
					},
					{
						level3_id: "12538",
						name: "Xã An Quí",
						type: "Xã",
					},
					{
						level3_id: "12541",
						name: "Xã An Thanh",
						type: "Xã",
					},
					{
						level3_id: "12547",
						name: "Xã An Vũ",
						type: "Xã",
					},
					{
						level3_id: "12550",
						name: "Xã An Lễ",
						type: "Xã",
					},
					{
						level3_id: "12553",
						name: "Xã Quỳnh Hưng",
						type: "Xã",
					},
					{
						level3_id: "12556",
						name: "Xã Quỳnh Bảo",
						type: "Xã",
					},
					{
						level3_id: "12559",
						name: "Xã An Mỹ",
						type: "Xã",
					},
					{
						level3_id: "12562",
						name: "Xã Quỳnh Nguyên",
						type: "Xã",
					},
					{
						level3_id: "12565",
						name: "Xã An Vinh",
						type: "Xã",
					},
					{
						level3_id: "12568",
						name: "Xã Quỳnh Xá",
						type: "Xã",
					},
					{
						level3_id: "12571",
						name: "Xã An Dục",
						type: "Xã",
					},
					{
						level3_id: "12574",
						name: "Xã Đông Hải",
						type: "Xã",
					},
					{
						level3_id: "12577",
						name: "Xã Quỳnh Trang",
						type: "Xã",
					},
					{
						level3_id: "12580",
						name: "Xã An Tràng",
						type: "Xã",
					},
					{
						level3_id: "12583",
						name: "Xã Đồng Tiến",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "339",
				name: "Huyện Hưng Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12586",
						name: "Thị trấn Hưng Hà",
						type: "Thị trấn",
					},
					{
						level3_id: "12589",
						name: "Xã Điệp Nông",
						type: "Xã",
					},
					{
						level3_id: "12592",
						name: "Xã Tân Lễ",
						type: "Xã",
					},
					{
						level3_id: "12595",
						name: "Xã Cộng Hòa",
						type: "Xã",
					},
					{
						level3_id: "12598",
						name: "Xã Dân Chủ",
						type: "Xã",
					},
					{
						level3_id: "12601",
						name: "Xã Canh Tân",
						type: "Xã",
					},
					{
						level3_id: "12604",
						name: "Xã Hòa Tiến",
						type: "Xã",
					},
					{
						level3_id: "12607",
						name: "Xã Hùng Dũng",
						type: "Xã",
					},
					{
						level3_id: "12610",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "12613",
						name: "Thị trấn Hưng Nhân",
						type: "Thị trấn",
					},
					{
						level3_id: "12616",
						name: "Xã Đoan Hùng",
						type: "Xã",
					},
					{
						level3_id: "12619",
						name: "Xã Duyên Hải",
						type: "Xã",
					},
					{
						level3_id: "12622",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "12625",
						name: "Xã Văn Cẩm",
						type: "Xã",
					},
					{
						level3_id: "12628",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "12631",
						name: "Xã Đông Đô",
						type: "Xã",
					},
					{
						level3_id: "12634",
						name: "Xã Phúc Khánh",
						type: "Xã",
					},
					{
						level3_id: "12637",
						name: "Xã Liên Hiệp",
						type: "Xã",
					},
					{
						level3_id: "12640",
						name: "Xã Tây Đô",
						type: "Xã",
					},
					{
						level3_id: "12643",
						name: "Xã Thống Nhất",
						type: "Xã",
					},
					{
						level3_id: "12646",
						name: "Xã Tiến Đức",
						type: "Xã",
					},
					{
						level3_id: "12649",
						name: "Xã Thái Hưng",
						type: "Xã",
					},
					{
						level3_id: "12652",
						name: "Xã Thái Phương",
						type: "Xã",
					},
					{
						level3_id: "12655",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "12656",
						name: "Xã Chi Lăng",
						type: "Xã",
					},
					{
						level3_id: "12658",
						name: "Xã Minh Khai",
						type: "Xã",
					},
					{
						level3_id: "12661",
						name: "Xã Hồng An",
						type: "Xã",
					},
					{
						level3_id: "12664",
						name: "Xã Kim Chung",
						type: "Xã",
					},
					{
						level3_id: "12667",
						name: "Xã Hồng Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "12670",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "12673",
						name: "Xã Văn Lang",
						type: "Xã",
					},
					{
						level3_id: "12676",
						name: "Xã Độc Lập",
						type: "Xã",
					},
					{
						level3_id: "12679",
						name: "Xã Chí Hòa",
						type: "Xã",
					},
					{
						level3_id: "12682",
						name: "Xã Minh Hòa",
						type: "Xã",
					},
					{
						level3_id: "12685",
						name: "Xã Hồng Minh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "340",
				name: "Huyện Đông Hưng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12688",
						name: "Thị trấn Đông Hưng",
						type: "Thị trấn",
					},
					{
						level3_id: "12691",
						name: "Xã Đô Lương",
						type: "Xã",
					},
					{
						level3_id: "12694",
						name: "Xã Đông Phương",
						type: "Xã",
					},
					{
						level3_id: "12697",
						name: "Xã Liên Giang",
						type: "Xã",
					},
					{
						level3_id: "12700",
						name: "Xã An Châu",
						type: "Xã",
					},
					{
						level3_id: "12703",
						name: "Xã Đông Sơn",
						type: "Xã",
					},
					{
						level3_id: "12706",
						name: "Xã Đông Cường",
						type: "Xã",
					},
					{
						level3_id: "12709",
						name: "Xã Phú Lương",
						type: "Xã",
					},
					{
						level3_id: "12712",
						name: "Xã Mê Linh",
						type: "Xã",
					},
					{
						level3_id: "12715",
						name: "Xã Lô Giang",
						type: "Xã",
					},
					{
						level3_id: "12718",
						name: "Xã Đông La",
						type: "Xã",
					},
					{
						level3_id: "12721",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "12724",
						name: "Xã Đông Xá",
						type: "Xã",
					},
					{
						level3_id: "12727",
						name: "Xã Chương Dương",
						type: "Xã",
					},
					{
						level3_id: "12730",
						name: "Xã Nguyên Xá",
						type: "Xã",
					},
					{
						level3_id: "12733",
						name: "Xã Phong Châu",
						type: "Xã",
					},
					{
						level3_id: "12736",
						name: "Xã Hợp Tiến",
						type: "Xã",
					},
					{
						level3_id: "12739",
						name: "Xã Hồng Việt",
						type: "Xã",
					},
					{
						level3_id: "12745",
						name: "Xã Hà Giang",
						type: "Xã",
					},
					{
						level3_id: "12748",
						name: "Xã Đông Kinh",
						type: "Xã",
					},
					{
						level3_id: "12751",
						name: "Xã Đông Hợp",
						type: "Xã",
					},
					{
						level3_id: "12754",
						name: "Xã Thăng Long",
						type: "Xã",
					},
					{
						level3_id: "12757",
						name: "Xã Đông Các",
						type: "Xã",
					},
					{
						level3_id: "12760",
						name: "Xã Phú Châu",
						type: "Xã",
					},
					{
						level3_id: "12763",
						name: "Xã Liên Hoa",
						type: "Xã",
					},
					{
						level3_id: "12769",
						name: "Xã Đông Tân",
						type: "Xã",
					},
					{
						level3_id: "12772",
						name: "Xã Đông Vinh",
						type: "Xã",
					},
					{
						level3_id: "12775",
						name: "Xã Đông Động",
						type: "Xã",
					},
					{
						level3_id: "12778",
						name: "Xã Hồng Bạch",
						type: "Xã",
					},
					{
						level3_id: "12784",
						name: "Xã Trọng Quan",
						type: "Xã",
					},
					{
						level3_id: "12790",
						name: "Xã Hồng Giang",
						type: "Xã",
					},
					{
						level3_id: "12793",
						name: "Xã Đông Quan",
						type: "Xã",
					},
					{
						level3_id: "12796",
						name: "Xã Đông Quang",
						type: "Xã",
					},
					{
						level3_id: "12799",
						name: "Xã Đông Xuân",
						type: "Xã",
					},
					{
						level3_id: "12802",
						name: "Xã Đông Á",
						type: "Xã",
					},
					{
						level3_id: "12808",
						name: "Xã Đông Hoàng",
						type: "Xã",
					},
					{
						level3_id: "12811",
						name: "Xã Đông Dương",
						type: "Xã",
					},
					{
						level3_id: "12823",
						name: "Xã Minh Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "341",
				name: "Huyện Thái Thụy",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12826",
						name: "Thị trấn Diêm Điền",
						type: "Thị trấn",
					},
					{
						level3_id: "12832",
						name: "Xã Thụy Trường",
						type: "Xã",
					},
					{
						level3_id: "12841",
						name: "Xã Hồng Dũng",
						type: "Xã",
					},
					{
						level3_id: "12844",
						name: "Xã Thụy Quỳnh",
						type: "Xã",
					},
					{
						level3_id: "12847",
						name: "Xã An Tân",
						type: "Xã",
					},
					{
						level3_id: "12850",
						name: "Xã Thụy Ninh",
						type: "Xã",
					},
					{
						level3_id: "12853",
						name: "Xã Thụy Hưng",
						type: "Xã",
					},
					{
						level3_id: "12856",
						name: "Xã Thụy Việt",
						type: "Xã",
					},
					{
						level3_id: "12859",
						name: "Xã Thụy Văn",
						type: "Xã",
					},
					{
						level3_id: "12862",
						name: "Xã Thụy Xuân",
						type: "Xã",
					},
					{
						level3_id: "12865",
						name: "Xã Dương Phúc",
						type: "Xã",
					},
					{
						level3_id: "12868",
						name: "Xã Thụy Trình",
						type: "Xã",
					},
					{
						level3_id: "12871",
						name: "Xã Thụy Bình",
						type: "Xã",
					},
					{
						level3_id: "12874",
						name: "Xã Thụy Chính",
						type: "Xã",
					},
					{
						level3_id: "12877",
						name: "Xã Thụy Dân",
						type: "Xã",
					},
					{
						level3_id: "12880",
						name: "Xã Thụy Hải",
						type: "Xã",
					},
					{
						level3_id: "12889",
						name: "Xã Thụy Liên",
						type: "Xã",
					},
					{
						level3_id: "12892",
						name: "Xã Thụy Duyên",
						type: "Xã",
					},
					{
						level3_id: "12898",
						name: "Xã Thụy Thanh",
						type: "Xã",
					},
					{
						level3_id: "12901",
						name: "Xã Thụy Sơn",
						type: "Xã",
					},
					{
						level3_id: "12904",
						name: "Xã Thụy Phong",
						type: "Xã",
					},
					{
						level3_id: "12907",
						name: "Xã Thái Thượng",
						type: "Xã",
					},
					{
						level3_id: "12910",
						name: "Xã Thái Nguyên",
						type: "Xã",
					},
					{
						level3_id: "12916",
						name: "Xã Dương Hồng  Thủy",
						type: "Xã",
					},
					{
						level3_id: "12919",
						name: "Xã Thái Giang",
						type: "Xã",
					},
					{
						level3_id: "12922",
						name: "Xã Hòa An",
						type: "Xã",
					},
					{
						level3_id: "12925",
						name: "Xã Sơn Hà",
						type: "Xã",
					},
					{
						level3_id: "12934",
						name: "Xã Thái Phúc",
						type: "Xã",
					},
					{
						level3_id: "12937",
						name: "Xã Thái Hưng",
						type: "Xã",
					},
					{
						level3_id: "12940",
						name: "Xã Thái Đô",
						type: "Xã",
					},
					{
						level3_id: "12943",
						name: "Xã Thái Xuyên",
						type: "Xã",
					},
					{
						level3_id: "12949",
						name: "Xã  Mỹ Lộc",
						type: "Xã",
					},
					{
						level3_id: "12958",
						name: "Xã Tân Học",
						type: "Xã",
					},
					{
						level3_id: "12961",
						name: "Xã Thái Thịnh",
						type: "Xã",
					},
					{
						level3_id: "12964",
						name: "Xã Thuần Thành",
						type: "Xã",
					},
					{
						level3_id: "12967",
						name: "Xã Thái Thọ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "342",
				name: "Huyện Tiền Hải",
				type: "Huyện",
				level3s: [
					{
						level3_id: "12970",
						name: "Thị trấn Tiền Hải",
						type: "Thị trấn",
					},
					{
						level3_id: "12976",
						name: "Xã Đông Trà",
						type: "Xã",
					},
					{
						level3_id: "12979",
						name: "Xã Đông Long",
						type: "Xã",
					},
					{
						level3_id: "12982",
						name: "Xã Đông Quí",
						type: "Xã",
					},
					{
						level3_id: "12985",
						name: "Xã Vũ Lăng",
						type: "Xã",
					},
					{
						level3_id: "12988",
						name: "Xã Đông Xuyên",
						type: "Xã",
					},
					{
						level3_id: "12991",
						name: "Xã Tây Lương",
						type: "Xã",
					},
					{
						level3_id: "12994",
						name: "Xã Tây Ninh",
						type: "Xã",
					},
					{
						level3_id: "12997",
						name: "Xã Đông Trung",
						type: "Xã",
					},
					{
						level3_id: "13000",
						name: "Xã Đông Hoàng",
						type: "Xã",
					},
					{
						level3_id: "13003",
						name: "Xã Đông Minh",
						type: "Xã",
					},
					{
						level3_id: "13009",
						name: "Xã Đông Phong",
						type: "Xã",
					},
					{
						level3_id: "13012",
						name: "Xã An Ninh",
						type: "Xã",
					},
					{
						level3_id: "13018",
						name: "Xã Đông Cơ",
						type: "Xã",
					},
					{
						level3_id: "13021",
						name: "Xã Tây Giang",
						type: "Xã",
					},
					{
						level3_id: "13024",
						name: "Xã Đông Lâm",
						type: "Xã",
					},
					{
						level3_id: "13027",
						name: "Xã Phương Công",
						type: "Xã",
					},
					{
						level3_id: "13030",
						name: "Xã Tây Phong",
						type: "Xã",
					},
					{
						level3_id: "13033",
						name: "Xã Tây Tiến",
						type: "Xã",
					},
					{
						level3_id: "13036",
						name: "Xã Nam Cường",
						type: "Xã",
					},
					{
						level3_id: "13039",
						name: "Xã Vân Trường",
						type: "Xã",
					},
					{
						level3_id: "13042",
						name: "Xã Nam Thắng",
						type: "Xã",
					},
					{
						level3_id: "13045",
						name: "Xã Nam Chính",
						type: "Xã",
					},
					{
						level3_id: "13048",
						name: "Xã Bắc Hải",
						type: "Xã",
					},
					{
						level3_id: "13051",
						name: "Xã Nam Thịnh",
						type: "Xã",
					},
					{
						level3_id: "13054",
						name: "Xã Nam Hà",
						type: "Xã",
					},
					{
						level3_id: "13057",
						name: "Xã Nam Thanh",
						type: "Xã",
					},
					{
						level3_id: "13060",
						name: "Xã Nam Trung",
						type: "Xã",
					},
					{
						level3_id: "13063",
						name: "Xã Nam Hồng",
						type: "Xã",
					},
					{
						level3_id: "13066",
						name: "Xã Nam Hưng",
						type: "Xã",
					},
					{
						level3_id: "13069",
						name: "Xã Nam Hải",
						type: "Xã",
					},
					{
						level3_id: "13072",
						name: "Xã Nam Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "343",
				name: "Huyện Kiến Xương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13075",
						name: "Thị trấn Kiến Xương",
						type: "Thị trấn",
					},
					{
						level3_id: "13078",
						name: "Xã Trà Giang",
						type: "Xã",
					},
					{
						level3_id: "13081",
						name: "Xã Quốc Tuấn",
						type: "Xã",
					},
					{
						level3_id: "13087",
						name: "Xã An Bình",
						type: "Xã",
					},
					{
						level3_id: "13090",
						name: "Xã Tây Sơn",
						type: "Xã",
					},
					{
						level3_id: "13093",
						name: "Xã Hồng Thái",
						type: "Xã",
					},
					{
						level3_id: "13096",
						name: "Xã Bình Nguyên",
						type: "Xã",
					},
					{
						level3_id: "13102",
						name: "Xã Lê Lợi",
						type: "Xã",
					},
					{
						level3_id: "13111",
						name: "Xã Vũ Lễ",
						type: "Xã",
					},
					{
						level3_id: "13114",
						name: "Xã Thanh Tân",
						type: "Xã",
					},
					{
						level3_id: "13117",
						name: "Xã Thượng Hiền",
						type: "Xã",
					},
					{
						level3_id: "13120",
						name: "Xã Nam Cao",
						type: "Xã",
					},
					{
						level3_id: "13123",
						name: "Xã Đình Phùng",
						type: "Xã",
					},
					{
						level3_id: "13126",
						name: "Xã Vũ Ninh",
						type: "Xã",
					},
					{
						level3_id: "13129",
						name: "Xã Vũ An",
						type: "Xã",
					},
					{
						level3_id: "13132",
						name: "Xã Quang Lịch",
						type: "Xã",
					},
					{
						level3_id: "13135",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "13138",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "13141",
						name: "Xã Vũ Quí",
						type: "Xã",
					},
					{
						level3_id: "13144",
						name: "Xã Quang Bình",
						type: "Xã",
					},
					{
						level3_id: "13150",
						name: "Xã Vũ Trung",
						type: "Xã",
					},
					{
						level3_id: "13153",
						name: "Xã Vũ Thắng",
						type: "Xã",
					},
					{
						level3_id: "13156",
						name: "Xã Vũ Công",
						type: "Xã",
					},
					{
						level3_id: "13159",
						name: "Xã Vũ Hòa",
						type: "Xã",
					},
					{
						level3_id: "13162",
						name: "Xã Quang Minh",
						type: "Xã",
					},
					{
						level3_id: "13165",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "13171",
						name: "Xã Minh Quang",
						type: "Xã",
					},
					{
						level3_id: "13174",
						name: "Xã Vũ Bình",
						type: "Xã",
					},
					{
						level3_id: "13177",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "13180",
						name: "Xã Nam Bình",
						type: "Xã",
					},
					{
						level3_id: "13183",
						name: "Xã Bình Thanh",
						type: "Xã",
					},
					{
						level3_id: "13186",
						name: "Xã Bình Định",
						type: "Xã",
					},
					{
						level3_id: "13189",
						name: "Xã Hồng Tiến",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "344",
				name: "Huyện Vũ Thư",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13192",
						name: "Thị trấn Vũ Thư",
						type: "Thị trấn",
					},
					{
						level3_id: "13195",
						name: "Xã Hồng Lý",
						type: "Xã",
					},
					{
						level3_id: "13198",
						name: "Xã Đồng Thanh",
						type: "Xã",
					},
					{
						level3_id: "13201",
						name: "Xã Xuân Hòa",
						type: "Xã",
					},
					{
						level3_id: "13204",
						name: "Xã Hiệp Hòa",
						type: "Xã",
					},
					{
						level3_id: "13207",
						name: "Xã Phúc Thành",
						type: "Xã",
					},
					{
						level3_id: "13210",
						name: "Xã Tân Phong",
						type: "Xã",
					},
					{
						level3_id: "13213",
						name: "Xã Song Lãng",
						type: "Xã",
					},
					{
						level3_id: "13216",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "13219",
						name: "Xã Việt Hùng",
						type: "Xã",
					},
					{
						level3_id: "13222",
						name: "Xã Minh Lãng",
						type: "Xã",
					},
					{
						level3_id: "13228",
						name: "Xã Minh Khai",
						type: "Xã",
					},
					{
						level3_id: "13231",
						name: "Xã Dũng Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "13234",
						name: "Xã Minh Quang",
						type: "Xã",
					},
					{
						level3_id: "13237",
						name: "Xã Tam Quang",
						type: "Xã",
					},
					{
						level3_id: "13240",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "13243",
						name: "Xã Bách Thuận",
						type: "Xã",
					},
					{
						level3_id: "13246",
						name: "Xã Tự Tân",
						type: "Xã",
					},
					{
						level3_id: "13249",
						name: "Xã Song An",
						type: "Xã",
					},
					{
						level3_id: "13252",
						name: "Xã Trung An",
						type: "Xã",
					},
					{
						level3_id: "13255",
						name: "Xã Vũ Hội",
						type: "Xã",
					},
					{
						level3_id: "13258",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "13261",
						name: "Xã Nguyên Xá",
						type: "Xã",
					},
					{
						level3_id: "13264",
						name: "Xã Việt Thuận",
						type: "Xã",
					},
					{
						level3_id: "13267",
						name: "Xã Vũ Vinh",
						type: "Xã",
					},
					{
						level3_id: "13270",
						name: "Xã Vũ Đoài",
						type: "Xã",
					},
					{
						level3_id: "13273",
						name: "Xã Vũ Tiến",
						type: "Xã",
					},
					{
						level3_id: "13276",
						name: "Xã Vũ Vân",
						type: "Xã",
					},
					{
						level3_id: "13279",
						name: "Xã Duy Nhất",
						type: "Xã",
					},
					{
						level3_id: "13282",
						name: "Xã Hồng Phong",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "35",
		name: "Tỉnh Hà Nam",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "347",
				name: "Thành phố Phủ Lý",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "13285",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "13288",
						name: "Phường Lương Khánh Thiện",
						type: "Phường",
					},
					{
						level3_id: "13291",
						name: "Phường Lê Hồng Phong",
						type: "Phường",
					},
					{
						level3_id: "13294",
						name: "Phường Minh Khai",
						type: "Phường",
					},
					{
						level3_id: "13297",
						name: "Phường Hai Bà Trưng",
						type: "Phường",
					},
					{
						level3_id: "13300",
						name: "Phường Trần Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "13303",
						name: "Phường Lam Hạ",
						type: "Phường",
					},
					{
						level3_id: "13306",
						name: "Xã Phù Vân",
						type: "Xã",
					},
					{
						level3_id: "13309",
						name: "Phường Liêm Chính",
						type: "Phường",
					},
					{
						level3_id: "13312",
						name: "Xã Liêm Chung",
						type: "Xã",
					},
					{
						level3_id: "13315",
						name: "Phường Thanh Châu",
						type: "Phường",
					},
					{
						level3_id: "13318",
						name: "Phường Châu Sơn",
						type: "Phường",
					},
					{
						level3_id: "13366",
						name: "Xã Tiên Tân",
						type: "Xã",
					},
					{
						level3_id: "13372",
						name: "Xã Tiên Hiệp",
						type: "Xã",
					},
					{
						level3_id: "13381",
						name: "Xã Tiên Hải",
						type: "Xã",
					},
					{
						level3_id: "13426",
						name: "Xã Kim Bình",
						type: "Xã",
					},
					{
						level3_id: "13444",
						name: "Xã Liêm Tuyền",
						type: "Xã",
					},
					{
						level3_id: "13447",
						name: "Xã Liêm Tiết",
						type: "Xã",
					},
					{
						level3_id: "13459",
						name: "Phường Thanh Tuyền",
						type: "Phường",
					},
					{
						level3_id: "13507",
						name: "Xã Đinh Xá",
						type: "Xã",
					},
					{
						level3_id: "13513",
						name: "Xã Trịnh Xá",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "349",
				name: "Thị xã Duy Tiên",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "13321",
						name: "Phường Đồng Văn",
						type: "Phường",
					},
					{
						level3_id: "13324",
						name: "Phường Hòa Mạc",
						type: "Phường",
					},
					{
						level3_id: "13327",
						name: "Xã Mộc Bắc",
						type: "Xã",
					},
					{
						level3_id: "13330",
						name: "Phường Châu Giang",
						type: "Phường",
					},
					{
						level3_id: "13333",
						name: "Phường Bạch Thượng",
						type: "Phường",
					},
					{
						level3_id: "13336",
						name: "Phường Duy Minh",
						type: "Phường",
					},
					{
						level3_id: "13339",
						name: "Xã Mộc Nam",
						type: "Xã",
					},
					{
						level3_id: "13342",
						name: "Phường Duy Hải",
						type: "Phường",
					},
					{
						level3_id: "13345",
						name: "Xã Chuyên Ngoại",
						type: "Xã",
					},
					{
						level3_id: "13348",
						name: "Phường Yên Bắc",
						type: "Phường",
					},
					{
						level3_id: "13351",
						name: "Xã Trác Văn",
						type: "Xã",
					},
					{
						level3_id: "13354",
						name: "Phường Tiên Nội",
						type: "Phường",
					},
					{
						level3_id: "13357",
						name: "Phường Hoàng Đông",
						type: "Phường",
					},
					{
						level3_id: "13360",
						name: "Xã Yên Nam",
						type: "Xã",
					},
					{
						level3_id: "13363",
						name: "Xã Tiên Ngoại",
						type: "Xã",
					},
					{
						level3_id: "13369",
						name: "Xã Tiên Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "350",
				name: "Huyện Kim Bảng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13384",
						name: "Thị trấn Quế",
						type: "Thị trấn",
					},
					{
						level3_id: "13387",
						name: "Xã Nguyễn Úy",
						type: "Xã",
					},
					{
						level3_id: "13390",
						name: "Xã Đại Cương",
						type: "Xã",
					},
					{
						level3_id: "13393",
						name: "Xã Lê Hồ",
						type: "Xã",
					},
					{
						level3_id: "13396",
						name: "Xã Tượng Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "13399",
						name: "Xã Nhật Tựu",
						type: "Xã",
					},
					{
						level3_id: "13402",
						name: "Xã Nhật Tân",
						type: "Xã",
					},
					{
						level3_id: "13405",
						name: "Xã Đồng Hóa",
						type: "Xã",
					},
					{
						level3_id: "13408",
						name: "Xã Hoàng Tây",
						type: "Xã",
					},
					{
						level3_id: "13411",
						name: "Xã Tân Sơn",
						type: "Xã",
					},
					{
						level3_id: "13414",
						name: "Xã Thụy Lôi",
						type: "Xã",
					},
					{
						level3_id: "13417",
						name: "Xã Văn Xá",
						type: "Xã",
					},
					{
						level3_id: "13420",
						name: "Xã Khả Phong",
						type: "Xã",
					},
					{
						level3_id: "13423",
						name: "Xã Ngọc Sơn",
						type: "Xã",
					},
					{
						level3_id: "13429",
						name: "Thị trấn Ba Sao",
						type: "Thị trấn",
					},
					{
						level3_id: "13432",
						name: "Xã Liên Sơn",
						type: "Xã",
					},
					{
						level3_id: "13435",
						name: "Xã Thi Sơn",
						type: "Xã",
					},
					{
						level3_id: "13438",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "351",
				name: "Huyện Thanh Liêm",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13441",
						name: "Thị trấn Kiện Khê",
						type: "Thị trấn",
					},
					{
						level3_id: "13450",
						name: "Xã Liêm Phong",
						type: "Xã",
					},
					{
						level3_id: "13453",
						name: "Xã Thanh Hà",
						type: "Xã",
					},
					{
						level3_id: "13456",
						name: "Xã Liêm Cần",
						type: "Xã",
					},
					{
						level3_id: "13465",
						name: "Xã Liêm Thuận",
						type: "Xã",
					},
					{
						level3_id: "13468",
						name: "Xã Thanh Thủy",
						type: "Xã",
					},
					{
						level3_id: "13471",
						name: "Xã Thanh Phong",
						type: "Xã",
					},
					{
						level3_id: "13474",
						name: "Thị trấn Tân Thanh",
						type: "Thị trấn",
					},
					{
						level3_id: "13477",
						name: "Xã Thanh Tân",
						type: "Xã",
					},
					{
						level3_id: "13480",
						name: "Xã Liêm Túc",
						type: "Xã",
					},
					{
						level3_id: "13483",
						name: "Xã Liêm Sơn",
						type: "Xã",
					},
					{
						level3_id: "13486",
						name: "Xã Thanh Hương",
						type: "Xã",
					},
					{
						level3_id: "13489",
						name: "Xã Thanh Nghị",
						type: "Xã",
					},
					{
						level3_id: "13492",
						name: "Xã Thanh Tâm",
						type: "Xã",
					},
					{
						level3_id: "13495",
						name: "Xã Thanh Nguyên",
						type: "Xã",
					},
					{
						level3_id: "13498",
						name: "Xã Thanh Hải",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "352",
				name: "Huyện Bình Lục",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13501",
						name: "Thị trấn Bình Mỹ",
						type: "Thị trấn",
					},
					{
						level3_id: "13504",
						name: "Xã Bình Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "13510",
						name: "Xã Tràng An",
						type: "Xã",
					},
					{
						level3_id: "13516",
						name: "Xã Đồng Du",
						type: "Xã",
					},
					{
						level3_id: "13519",
						name: "Xã Ngọc Lũ",
						type: "Xã",
					},
					{
						level3_id: "13522",
						name: "Xã Hưng Công",
						type: "Xã",
					},
					{
						level3_id: "13525",
						name: "Xã Đồn Xá",
						type: "Xã",
					},
					{
						level3_id: "13528",
						name: "Xã An Ninh",
						type: "Xã",
					},
					{
						level3_id: "13531",
						name: "Xã Bồ Đề",
						type: "Xã",
					},
					{
						level3_id: "13534",
						name: "Xã Bối Cầu",
						type: "Xã",
					},
					{
						level3_id: "13540",
						name: "Xã An Nội",
						type: "Xã",
					},
					{
						level3_id: "13543",
						name: "Xã Vũ Bản",
						type: "Xã",
					},
					{
						level3_id: "13546",
						name: "Xã Trung Lương",
						type: "Xã",
					},
					{
						level3_id: "13552",
						name: "Xã An Đổ",
						type: "Xã",
					},
					{
						level3_id: "13555",
						name: "Xã La Sơn",
						type: "Xã",
					},
					{
						level3_id: "13558",
						name: "Xã Tiêu Động",
						type: "Xã",
					},
					{
						level3_id: "13561",
						name: "Xã An Lão",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "353",
				name: "Huyện Lý Nhân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13567",
						name: "Xã Hợp Lý",
						type: "Xã",
					},
					{
						level3_id: "13570",
						name: "Xã Nguyên Lý",
						type: "Xã",
					},
					{
						level3_id: "13573",
						name: "Xã Chính Lý",
						type: "Xã",
					},
					{
						level3_id: "13576",
						name: "Xã Chân Lý",
						type: "Xã",
					},
					{
						level3_id: "13579",
						name: "Xã Đạo Lý",
						type: "Xã",
					},
					{
						level3_id: "13582",
						name: "Xã Công Lý",
						type: "Xã",
					},
					{
						level3_id: "13585",
						name: "Xã Văn Lý",
						type: "Xã",
					},
					{
						level3_id: "13588",
						name: "Xã Bắc Lý",
						type: "Xã",
					},
					{
						level3_id: "13591",
						name: "Xã Đức Lý",
						type: "Xã",
					},
					{
						level3_id: "13594",
						name: "Xã Trần Hưng Đạo",
						type: "Xã",
					},
					{
						level3_id: "13597",
						name: "Thị trấn Vĩnh Trụ",
						type: "Thị trấn",
					},
					{
						level3_id: "13600",
						name: "Xã Nhân Thịnh",
						type: "Xã",
					},
					{
						level3_id: "13606",
						name: "Xã Nhân Khang",
						type: "Xã",
					},
					{
						level3_id: "13609",
						name: "Xã Nhân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "13612",
						name: "Xã Nhân Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "13615",
						name: "Xã Nhân Chính",
						type: "Xã",
					},
					{
						level3_id: "13618",
						name: "Xã Nhân Bình",
						type: "Xã",
					},
					{
						level3_id: "13621",
						name: "Xã Phú Phúc",
						type: "Xã",
					},
					{
						level3_id: "13624",
						name: "Xã Xuân Khê",
						type: "Xã",
					},
					{
						level3_id: "13627",
						name: "Xã Tiến Thắng",
						type: "Xã",
					},
					{
						level3_id: "13630",
						name: "Xã Hòa Hậu",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "36",
		name: "Tỉnh Nam Định",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "356",
				name: "Thành phố Nam Định",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "13633",
						name: "Phường Hạ Long",
						type: "Phường",
					},
					{
						level3_id: "13636",
						name: "Phường Trần Tế Xương",
						type: "Phường",
					},
					{
						level3_id: "13639",
						name: "Phường Vị Hoàng",
						type: "Phường",
					},
					{
						level3_id: "13642",
						name: "Phường Vị Xuyên",
						type: "Phường",
					},
					{
						level3_id: "13645",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "13648",
						name: "Phường Cửa Bắc",
						type: "Phường",
					},
					{
						level3_id: "13651",
						name: "Phường Nguyễn Du",
						type: "Phường",
					},
					{
						level3_id: "13654",
						name: "Phường Bà Triệu",
						type: "Phường",
					},
					{
						level3_id: "13657",
						name: "Phường Trường Thi",
						type: "Phường",
					},
					{
						level3_id: "13660",
						name: "Phường Phan Đình Phùng",
						type: "Phường",
					},
					{
						level3_id: "13663",
						name: "Phường Ngô Quyền",
						type: "Phường",
					},
					{
						level3_id: "13666",
						name: "Phường Trần Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "13669",
						name: "Phường Trần Đăng Ninh",
						type: "Phường",
					},
					{
						level3_id: "13672",
						name: "Phường Năng Tĩnh",
						type: "Phường",
					},
					{
						level3_id: "13675",
						name: "Phường Văn Miếu",
						type: "Phường",
					},
					{
						level3_id: "13678",
						name: "Phường Trần Quang Khải",
						type: "Phường",
					},
					{
						level3_id: "13681",
						name: "Phường Thống Nhất",
						type: "Phường",
					},
					{
						level3_id: "13684",
						name: "Phường Lộc Hạ",
						type: "Phường",
					},
					{
						level3_id: "13687",
						name: "Phường Lộc Vượng",
						type: "Phường",
					},
					{
						level3_id: "13690",
						name: "Phường Cửa Nam",
						type: "Phường",
					},
					{
						level3_id: "13693",
						name: "Phường Lộc Hòa",
						type: "Phường",
					},
					{
						level3_id: "13696",
						name: "Xã Nam Phong",
						type: "Xã",
					},
					{
						level3_id: "13699",
						name: "Phường Mỹ Xá",
						type: "Phường",
					},
					{
						level3_id: "13702",
						name: "Xã Lộc An",
						type: "Xã",
					},
					{
						level3_id: "13705",
						name: "Xã Nam Vân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "358",
				name: "Huyện Mỹ Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13708",
						name: "Thị trấn Mỹ Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "13711",
						name: "Xã Mỹ Hà",
						type: "Xã",
					},
					{
						level3_id: "13714",
						name: "Xã Mỹ Tiến",
						type: "Xã",
					},
					{
						level3_id: "13717",
						name: "Xã Mỹ Thắng",
						type: "Xã",
					},
					{
						level3_id: "13720",
						name: "Xã Mỹ Trung",
						type: "Xã",
					},
					{
						level3_id: "13723",
						name: "Xã Mỹ Tân",
						type: "Xã",
					},
					{
						level3_id: "13726",
						name: "Xã Mỹ Phúc",
						type: "Xã",
					},
					{
						level3_id: "13729",
						name: "Xã Mỹ Hưng",
						type: "Xã",
					},
					{
						level3_id: "13732",
						name: "Xã Mỹ Thuận",
						type: "Xã",
					},
					{
						level3_id: "13735",
						name: "Xã Mỹ Thịnh",
						type: "Xã",
					},
					{
						level3_id: "13738",
						name: "Xã Mỹ Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "359",
				name: "Huyện Vụ Bản",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13741",
						name: "Thị trấn Gôi",
						type: "Thị trấn",
					},
					{
						level3_id: "13744",
						name: "Xã Minh Thuận",
						type: "Xã",
					},
					{
						level3_id: "13747",
						name: "Xã Hiển Khánh",
						type: "Xã",
					},
					{
						level3_id: "13750",
						name: "Xã Tân Khánh",
						type: "Xã",
					},
					{
						level3_id: "13753",
						name: "Xã Hợp Hưng",
						type: "Xã",
					},
					{
						level3_id: "13756",
						name: "Xã Đại An",
						type: "Xã",
					},
					{
						level3_id: "13759",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "13762",
						name: "Xã Cộng Hòa",
						type: "Xã",
					},
					{
						level3_id: "13765",
						name: "Xã Trung Thành",
						type: "Xã",
					},
					{
						level3_id: "13768",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "13771",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "13774",
						name: "Xã Liên Bảo",
						type: "Xã",
					},
					{
						level3_id: "13777",
						name: "Xã Thành Lợi",
						type: "Xã",
					},
					{
						level3_id: "13780",
						name: "Xã Kim Thái",
						type: "Xã",
					},
					{
						level3_id: "13783",
						name: "Xã Liên Minh",
						type: "Xã",
					},
					{
						level3_id: "13786",
						name: "Xã Đại Thắng",
						type: "Xã",
					},
					{
						level3_id: "13789",
						name: "Xã Tam Thanh",
						type: "Xã",
					},
					{
						level3_id: "13792",
						name: "Xã Vĩnh Hào",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "360",
				name: "Huyện Ý Yên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13795",
						name: "Thị trấn Lâm",
						type: "Thị trấn",
					},
					{
						level3_id: "13798",
						name: "Xã Yên Trung",
						type: "Xã",
					},
					{
						level3_id: "13801",
						name: "Xã Yên Thành",
						type: "Xã",
					},
					{
						level3_id: "13804",
						name: "Xã Yên Tân",
						type: "Xã",
					},
					{
						level3_id: "13807",
						name: "Xã Yên Lợi",
						type: "Xã",
					},
					{
						level3_id: "13810",
						name: "Xã Yên Thọ",
						type: "Xã",
					},
					{
						level3_id: "13813",
						name: "Xã Yên Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "13816",
						name: "Xã Yên Minh",
						type: "Xã",
					},
					{
						level3_id: "13819",
						name: "Xã Yên Phương",
						type: "Xã",
					},
					{
						level3_id: "13822",
						name: "Xã Yên Chính",
						type: "Xã",
					},
					{
						level3_id: "13825",
						name: "Xã Yên Bình",
						type: "Xã",
					},
					{
						level3_id: "13828",
						name: "Xã Yên Phú",
						type: "Xã",
					},
					{
						level3_id: "13831",
						name: "Xã Yên Mỹ",
						type: "Xã",
					},
					{
						level3_id: "13834",
						name: "Xã Yên Dương",
						type: "Xã",
					},
					{
						level3_id: "13840",
						name: "Xã Yên Hưng",
						type: "Xã",
					},
					{
						level3_id: "13843",
						name: "Xã Yên Khánh",
						type: "Xã",
					},
					{
						level3_id: "13846",
						name: "Xã Yên Phong",
						type: "Xã",
					},
					{
						level3_id: "13849",
						name: "Xã Yên Ninh",
						type: "Xã",
					},
					{
						level3_id: "13852",
						name: "Xã Yên Lương",
						type: "Xã",
					},
					{
						level3_id: "13855",
						name: "Xã Yên Hồng",
						type: "Xã",
					},
					{
						level3_id: "13858",
						name: "Xã Yên Quang",
						type: "Xã",
					},
					{
						level3_id: "13861",
						name: "Xã Yên Tiến",
						type: "Xã",
					},
					{
						level3_id: "13864",
						name: "Xã Yên Thắng",
						type: "Xã",
					},
					{
						level3_id: "13867",
						name: "Xã Yên Phúc",
						type: "Xã",
					},
					{
						level3_id: "13870",
						name: "Xã Yên Cường",
						type: "Xã",
					},
					{
						level3_id: "13873",
						name: "Xã Yên Lộc",
						type: "Xã",
					},
					{
						level3_id: "13876",
						name: "Xã Yên Bằng",
						type: "Xã",
					},
					{
						level3_id: "13879",
						name: "Xã Yên Đồng",
						type: "Xã",
					},
					{
						level3_id: "13882",
						name: "Xã Yên Khang",
						type: "Xã",
					},
					{
						level3_id: "13885",
						name: "Xã Yên Nhân",
						type: "Xã",
					},
					{
						level3_id: "13888",
						name: "Xã Yên Trị",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "361",
				name: "Huyện Nghĩa Hưng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13891",
						name: "Thị trấn Liễu Đề",
						type: "Thị trấn",
					},
					{
						level3_id: "13894",
						name: "Thị trấn Rạng Đông",
						type: "Thị trấn",
					},
					{
						level3_id: "13897",
						name: "Xã Nghĩa Đồng",
						type: "Xã",
					},
					{
						level3_id: "13900",
						name: "Xã Nghĩa Thịnh",
						type: "Xã",
					},
					{
						level3_id: "13903",
						name: "Xã Nghĩa Minh",
						type: "Xã",
					},
					{
						level3_id: "13906",
						name: "Xã Nghĩa Thái",
						type: "Xã",
					},
					{
						level3_id: "13909",
						name: "Xã Hoàng Nam",
						type: "Xã",
					},
					{
						level3_id: "13912",
						name: "Xã Nghĩa Châu",
						type: "Xã",
					},
					{
						level3_id: "13915",
						name: "Xã Nghĩa Trung",
						type: "Xã",
					},
					{
						level3_id: "13918",
						name: "Xã Nghĩa Sơn",
						type: "Xã",
					},
					{
						level3_id: "13921",
						name: "Xã Nghĩa Lạc",
						type: "Xã",
					},
					{
						level3_id: "13924",
						name: "Xã Nghĩa Hồng",
						type: "Xã",
					},
					{
						level3_id: "13927",
						name: "Xã Nghĩa Phong",
						type: "Xã",
					},
					{
						level3_id: "13930",
						name: "Xã Nghĩa Phú",
						type: "Xã",
					},
					{
						level3_id: "13933",
						name: "Xã Nghĩa Bình",
						type: "Xã",
					},
					{
						level3_id: "13936",
						name: "Thị trấn Quỹ Nhất",
						type: "Thị trấn",
					},
					{
						level3_id: "13939",
						name: "Xã Nghĩa Tân",
						type: "Xã",
					},
					{
						level3_id: "13942",
						name: "Xã Nghĩa Hùng",
						type: "Xã",
					},
					{
						level3_id: "13945",
						name: "Xã Nghĩa Lâm",
						type: "Xã",
					},
					{
						level3_id: "13948",
						name: "Xã Nghĩa Thành",
						type: "Xã",
					},
					{
						level3_id: "13951",
						name: "Xã Phúc Thắng",
						type: "Xã",
					},
					{
						level3_id: "13954",
						name: "Xã Nghĩa Lợi",
						type: "Xã",
					},
					{
						level3_id: "13957",
						name: "Xã Nghĩa Hải",
						type: "Xã",
					},
					{
						level3_id: "13963",
						name: "Xã Nam Điền",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "362",
				name: "Huyện Nam Trực",
				type: "Huyện",
				level3s: [
					{
						level3_id: "13966",
						name: "Thị trấn Nam Giang",
						type: "Thị trấn",
					},
					{
						level3_id: "13969",
						name: "Xã Nam Mỹ",
						type: "Xã",
					},
					{
						level3_id: "13972",
						name: "Xã Điền Xá",
						type: "Xã",
					},
					{
						level3_id: "13975",
						name: "Xã Nghĩa An",
						type: "Xã",
					},
					{
						level3_id: "13978",
						name: "Xã Nam Thắng",
						type: "Xã",
					},
					{
						level3_id: "13981",
						name: "Xã Nam Toàn",
						type: "Xã",
					},
					{
						level3_id: "13984",
						name: "Xã Hồng Quang",
						type: "Xã",
					},
					{
						level3_id: "13987",
						name: "Xã Tân Thịnh",
						type: "Xã",
					},
					{
						level3_id: "13990",
						name: "Xã Nam Cường",
						type: "Xã",
					},
					{
						level3_id: "13993",
						name: "Xã Nam Hồng",
						type: "Xã",
					},
					{
						level3_id: "13996",
						name: "Xã Nam Hùng",
						type: "Xã",
					},
					{
						level3_id: "13999",
						name: "Xã Nam Hoa",
						type: "Xã",
					},
					{
						level3_id: "14002",
						name: "Xã Nam Dương",
						type: "Xã",
					},
					{
						level3_id: "14005",
						name: "Xã Nam Thanh",
						type: "Xã",
					},
					{
						level3_id: "14008",
						name: "Xã Nam Lợi",
						type: "Xã",
					},
					{
						level3_id: "14011",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "14014",
						name: "Xã Đồng Sơn",
						type: "Xã",
					},
					{
						level3_id: "14017",
						name: "Xã Nam Tiến",
						type: "Xã",
					},
					{
						level3_id: "14020",
						name: "Xã Nam Hải",
						type: "Xã",
					},
					{
						level3_id: "14023",
						name: "Xã Nam Thái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "363",
				name: "Huyện Trực Ninh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14026",
						name: "Thị trấn Cổ Lễ",
						type: "Thị trấn",
					},
					{
						level3_id: "14029",
						name: "Xã Phương Định",
						type: "Xã",
					},
					{
						level3_id: "14032",
						name: "Xã Trực Chính",
						type: "Xã",
					},
					{
						level3_id: "14035",
						name: "Xã Trung Đông",
						type: "Xã",
					},
					{
						level3_id: "14038",
						name: "Xã Liêm Hải",
						type: "Xã",
					},
					{
						level3_id: "14041",
						name: "Xã Trực Tuấn",
						type: "Xã",
					},
					{
						level3_id: "14044",
						name: "Xã Việt Hùng",
						type: "Xã",
					},
					{
						level3_id: "14047",
						name: "Xã Trực Đạo",
						type: "Xã",
					},
					{
						level3_id: "14050",
						name: "Xã Trực Hưng",
						type: "Xã",
					},
					{
						level3_id: "14053",
						name: "Xã Trực Nội",
						type: "Xã",
					},
					{
						level3_id: "14056",
						name: "Thị trấn Cát Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "14059",
						name: "Xã Trực Thanh",
						type: "Xã",
					},
					{
						level3_id: "14062",
						name: "Xã Trực Khang",
						type: "Xã",
					},
					{
						level3_id: "14065",
						name: "Xã Trực Thuận",
						type: "Xã",
					},
					{
						level3_id: "14068",
						name: "Xã Trực Mỹ",
						type: "Xã",
					},
					{
						level3_id: "14071",
						name: "Xã Trực Đại",
						type: "Xã",
					},
					{
						level3_id: "14074",
						name: "Xã Trực Cường",
						type: "Xã",
					},
					{
						level3_id: "14077",
						name: "Thị trấn Ninh Cường",
						type: "Thị trấn",
					},
					{
						level3_id: "14080",
						name: "Xã Trực Thái",
						type: "Xã",
					},
					{
						level3_id: "14083",
						name: "Xã Trực Hùng",
						type: "Xã",
					},
					{
						level3_id: "14086",
						name: "Xã Trực Thắng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "364",
				name: "Huyện Xuân Trường",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14089",
						name: "Thị trấn Xuân Trường",
						type: "Thị trấn",
					},
					{
						level3_id: "14092",
						name: "Xã Xuân Châu",
						type: "Xã",
					},
					{
						level3_id: "14095",
						name: "Xã Xuân Hồng",
						type: "Xã",
					},
					{
						level3_id: "14098",
						name: "Xã Xuân Thành",
						type: "Xã",
					},
					{
						level3_id: "14101",
						name: "Xã Xuân Thượng",
						type: "Xã",
					},
					{
						level3_id: "14104",
						name: "Xã Xuân Phong",
						type: "Xã",
					},
					{
						level3_id: "14107",
						name: "Xã Xuân Đài",
						type: "Xã",
					},
					{
						level3_id: "14110",
						name: "Xã Xuân Tân",
						type: "Xã",
					},
					{
						level3_id: "14113",
						name: "Xã Xuân Thủy",
						type: "Xã",
					},
					{
						level3_id: "14116",
						name: "Xã Xuân Ngọc",
						type: "Xã",
					},
					{
						level3_id: "14119",
						name: "Xã Xuân Bắc",
						type: "Xã",
					},
					{
						level3_id: "14122",
						name: "Xã Xuân Phương",
						type: "Xã",
					},
					{
						level3_id: "14125",
						name: "Xã Thọ Nghiệp",
						type: "Xã",
					},
					{
						level3_id: "14128",
						name: "Xã Xuân Phú",
						type: "Xã",
					},
					{
						level3_id: "14131",
						name: "Xã Xuân Trung",
						type: "Xã",
					},
					{
						level3_id: "14134",
						name: "Xã Xuân Vinh",
						type: "Xã",
					},
					{
						level3_id: "14137",
						name: "Xã Xuân Kiên",
						type: "Xã",
					},
					{
						level3_id: "14140",
						name: "Xã Xuân Tiến",
						type: "Xã",
					},
					{
						level3_id: "14143",
						name: "Xã Xuân Ninh",
						type: "Xã",
					},
					{
						level3_id: "14146",
						name: "Xã Xuân Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "365",
				name: "Huyện Giao Thủy",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14149",
						name: "Thị trấn Ngô Đồng",
						type: "Thị trấn",
					},
					{
						level3_id: "14152",
						name: "Thị trấn Quất Lâm",
						type: "Thị trấn",
					},
					{
						level3_id: "14155",
						name: "Xã Giao Hương",
						type: "Xã",
					},
					{
						level3_id: "14158",
						name: "Xã Hồng Thuận",
						type: "Xã",
					},
					{
						level3_id: "14161",
						name: "Xã Giao Thiện",
						type: "Xã",
					},
					{
						level3_id: "14164",
						name: "Xã Giao Thanh",
						type: "Xã",
					},
					{
						level3_id: "14167",
						name: "Xã Hoành Sơn",
						type: "Xã",
					},
					{
						level3_id: "14170",
						name: "Xã Bình Hòa",
						type: "Xã",
					},
					{
						level3_id: "14173",
						name: "Xã Giao Tiến",
						type: "Xã",
					},
					{
						level3_id: "14176",
						name: "Xã Giao Hà",
						type: "Xã",
					},
					{
						level3_id: "14179",
						name: "Xã Giao Nhân",
						type: "Xã",
					},
					{
						level3_id: "14182",
						name: "Xã Giao An",
						type: "Xã",
					},
					{
						level3_id: "14185",
						name: "Xã Giao Lạc",
						type: "Xã",
					},
					{
						level3_id: "14188",
						name: "Xã Giao Châu",
						type: "Xã",
					},
					{
						level3_id: "14191",
						name: "Xã Giao Tân",
						type: "Xã",
					},
					{
						level3_id: "14194",
						name: "Xã Giao Yến",
						type: "Xã",
					},
					{
						level3_id: "14197",
						name: "Xã Giao Xuân",
						type: "Xã",
					},
					{
						level3_id: "14200",
						name: "Xã Giao Thịnh",
						type: "Xã",
					},
					{
						level3_id: "14203",
						name: "Xã Giao Hải",
						type: "Xã",
					},
					{
						level3_id: "14206",
						name: "Xã Bạch Long",
						type: "Xã",
					},
					{
						level3_id: "14209",
						name: "Xã Giao Long",
						type: "Xã",
					},
					{
						level3_id: "14212",
						name: "Xã Giao Phong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "366",
				name: "Huyện Hải Hậu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14215",
						name: "Thị trấn Yên Định",
						type: "Thị trấn",
					},
					{
						level3_id: "14218",
						name: "Thị trấn Cồn",
						type: "Thị trấn",
					},
					{
						level3_id: "14221",
						name: "Thị trấn Thịnh Long",
						type: "Thị trấn",
					},
					{
						level3_id: "14224",
						name: "Xã Hải Nam",
						type: "Xã",
					},
					{
						level3_id: "14227",
						name: "Xã Hải Trung",
						type: "Xã",
					},
					{
						level3_id: "14230",
						name: "Xã Hải Vân",
						type: "Xã",
					},
					{
						level3_id: "14233",
						name: "Xã Hải Minh",
						type: "Xã",
					},
					{
						level3_id: "14236",
						name: "Xã Hải Anh",
						type: "Xã",
					},
					{
						level3_id: "14239",
						name: "Xã Hải Hưng",
						type: "Xã",
					},
					{
						level3_id: "14242",
						name: "Xã Hải Bắc",
						type: "Xã",
					},
					{
						level3_id: "14245",
						name: "Xã Hải Phúc",
						type: "Xã",
					},
					{
						level3_id: "14248",
						name: "Xã Hải Thanh",
						type: "Xã",
					},
					{
						level3_id: "14251",
						name: "Xã Hải Hà",
						type: "Xã",
					},
					{
						level3_id: "14254",
						name: "Xã Hải Long",
						type: "Xã",
					},
					{
						level3_id: "14257",
						name: "Xã Hải Phương",
						type: "Xã",
					},
					{
						level3_id: "14260",
						name: "Xã Hải Đường",
						type: "Xã",
					},
					{
						level3_id: "14263",
						name: "Xã Hải Lộc",
						type: "Xã",
					},
					{
						level3_id: "14266",
						name: "Xã Hải Quang",
						type: "Xã",
					},
					{
						level3_id: "14269",
						name: "Xã Hải Đông",
						type: "Xã",
					},
					{
						level3_id: "14272",
						name: "Xã Hải Sơn",
						type: "Xã",
					},
					{
						level3_id: "14275",
						name: "Xã Hải Tân",
						type: "Xã",
					},
					{
						level3_id: "14281",
						name: "Xã Hải Phong",
						type: "Xã",
					},
					{
						level3_id: "14284",
						name: "Xã Hải An",
						type: "Xã",
					},
					{
						level3_id: "14287",
						name: "Xã Hải Tây",
						type: "Xã",
					},
					{
						level3_id: "14290",
						name: "Xã Hải Lý",
						type: "Xã",
					},
					{
						level3_id: "14293",
						name: "Xã Hải Phú",
						type: "Xã",
					},
					{
						level3_id: "14296",
						name: "Xã Hải Giang",
						type: "Xã",
					},
					{
						level3_id: "14299",
						name: "Xã Hải Cường",
						type: "Xã",
					},
					{
						level3_id: "14302",
						name: "Xã Hải Ninh",
						type: "Xã",
					},
					{
						level3_id: "14305",
						name: "Xã Hải Chính",
						type: "Xã",
					},
					{
						level3_id: "14308",
						name: "Xã Hải Xuân",
						type: "Xã",
					},
					{
						level3_id: "14311",
						name: "Xã Hải Châu",
						type: "Xã",
					},
					{
						level3_id: "14314",
						name: "Xã Hải Triều",
						type: "Xã",
					},
					{
						level3_id: "14317",
						name: "Xã Hải Hòa",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "37",
		name: "Tỉnh Ninh Bình",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "369",
				name: "Thành phố Ninh Bình",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "14320",
						name: "Phường Đông Thành",
						type: "Phường",
					},
					{
						level3_id: "14323",
						name: "Phường Tân Thành",
						type: "Phường",
					},
					{
						level3_id: "14326",
						name: "Phường Thanh Bình",
						type: "Phường",
					},
					{
						level3_id: "14329",
						name: "Phường Vân Giang",
						type: "Phường",
					},
					{
						level3_id: "14332",
						name: "Phường Bích Đào",
						type: "Phường",
					},
					{
						level3_id: "14335",
						name: "Phường Phúc Thành",
						type: "Phường",
					},
					{
						level3_id: "14338",
						name: "Phường Nam Bình",
						type: "Phường",
					},
					{
						level3_id: "14341",
						name: "Phường Nam Thành",
						type: "Phường",
					},
					{
						level3_id: "14344",
						name: "Phường Ninh Khánh",
						type: "Phường",
					},
					{
						level3_id: "14347",
						name: "Xã Ninh Nhất",
						type: "Xã",
					},
					{
						level3_id: "14350",
						name: "Xã Ninh Tiến",
						type: "Xã",
					},
					{
						level3_id: "14353",
						name: "Xã Ninh Phúc",
						type: "Xã",
					},
					{
						level3_id: "14356",
						name: "Phường Ninh Sơn",
						type: "Phường",
					},
					{
						level3_id: "14359",
						name: "Phường Ninh Phong",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "370",
				name: "Thành phố Tam Điệp",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "14362",
						name: "Phường Bắc Sơn",
						type: "Phường",
					},
					{
						level3_id: "14365",
						name: "Phường Trung Sơn",
						type: "Phường",
					},
					{
						level3_id: "14368",
						name: "Phường Nam Sơn",
						type: "Phường",
					},
					{
						level3_id: "14369",
						name: "Phường Tây Sơn",
						type: "Phường",
					},
					{
						level3_id: "14371",
						name: "Xã Yên Sơn",
						type: "Xã",
					},
					{
						level3_id: "14374",
						name: "Phường Yên Bình",
						type: "Phường",
					},
					{
						level3_id: "14375",
						name: "Phường Tân Bình",
						type: "Phường",
					},
					{
						level3_id: "14377",
						name: "Xã Quang Sơn",
						type: "Xã",
					},
					{
						level3_id: "14380",
						name: "Xã Đông Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "372",
				name: "Huyện Nho Quan",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14383",
						name: "Thị trấn Nho Quan",
						type: "Thị trấn",
					},
					{
						level3_id: "14386",
						name: "Xã Xích Thổ",
						type: "Xã",
					},
					{
						level3_id: "14389",
						name: "Xã Gia Lâm",
						type: "Xã",
					},
					{
						level3_id: "14392",
						name: "Xã Gia Sơn",
						type: "Xã",
					},
					{
						level3_id: "14395",
						name: "Xã Thạch Bình",
						type: "Xã",
					},
					{
						level3_id: "14398",
						name: "Xã Gia Thủy",
						type: "Xã",
					},
					{
						level3_id: "14401",
						name: "Xã Gia Tường",
						type: "Xã",
					},
					{
						level3_id: "14404",
						name: "Xã Cúc Phương",
						type: "Xã",
					},
					{
						level3_id: "14407",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "14410",
						name: "Xã Đức Long",
						type: "Xã",
					},
					{
						level3_id: "14413",
						name: "Xã Lạc Vân",
						type: "Xã",
					},
					{
						level3_id: "14416",
						name: "Xã Đồng Phong",
						type: "Xã",
					},
					{
						level3_id: "14419",
						name: "Xã Yên Quang",
						type: "Xã",
					},
					{
						level3_id: "14422",
						name: "Xã Lạng Phong",
						type: "Xã",
					},
					{
						level3_id: "14425",
						name: "Xã Thượng Hòa",
						type: "Xã",
					},
					{
						level3_id: "14428",
						name: "Xã Văn Phong",
						type: "Xã",
					},
					{
						level3_id: "14431",
						name: "Xã Văn Phương",
						type: "Xã",
					},
					{
						level3_id: "14434",
						name: "Xã Thanh Lạc",
						type: "Xã",
					},
					{
						level3_id: "14437",
						name: "Xã Sơn Lai",
						type: "Xã",
					},
					{
						level3_id: "14440",
						name: "Xã Sơn Thành",
						type: "Xã",
					},
					{
						level3_id: "14443",
						name: "Xã Văn Phú",
						type: "Xã",
					},
					{
						level3_id: "14446",
						name: "Xã Phú Lộc",
						type: "Xã",
					},
					{
						level3_id: "14449",
						name: "Xã Kỳ Phú",
						type: "Xã",
					},
					{
						level3_id: "14452",
						name: "Xã Quỳnh Lưu",
						type: "Xã",
					},
					{
						level3_id: "14455",
						name: "Xã Sơn Hà",
						type: "Xã",
					},
					{
						level3_id: "14458",
						name: "Xã Phú Long",
						type: "Xã",
					},
					{
						level3_id: "14461",
						name: "Xã Quảng Lạc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "373",
				name: "Huyện Gia Viễn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14464",
						name: "Thị trấn Me",
						type: "Thị trấn",
					},
					{
						level3_id: "14467",
						name: "Xã Gia Hòa",
						type: "Xã",
					},
					{
						level3_id: "14470",
						name: "Xã Gia Hưng",
						type: "Xã",
					},
					{
						level3_id: "14473",
						name: "Xã Liên Sơn",
						type: "Xã",
					},
					{
						level3_id: "14476",
						name: "Xã Gia Thanh",
						type: "Xã",
					},
					{
						level3_id: "14479",
						name: "Xã Gia Vân",
						type: "Xã",
					},
					{
						level3_id: "14482",
						name: "Xã Gia Phú",
						type: "Xã",
					},
					{
						level3_id: "14485",
						name: "Xã Gia Xuân",
						type: "Xã",
					},
					{
						level3_id: "14488",
						name: "Xã Gia Lập",
						type: "Xã",
					},
					{
						level3_id: "14491",
						name: "Xã Gia Vượng",
						type: "Xã",
					},
					{
						level3_id: "14494",
						name: "Xã Gia Trấn",
						type: "Xã",
					},
					{
						level3_id: "14497",
						name: "Xã Gia Thịnh",
						type: "Xã",
					},
					{
						level3_id: "14500",
						name: "Xã Gia Phương",
						type: "Xã",
					},
					{
						level3_id: "14503",
						name: "Xã Gia Tân",
						type: "Xã",
					},
					{
						level3_id: "14506",
						name: "Xã Gia Thắng",
						type: "Xã",
					},
					{
						level3_id: "14509",
						name: "Xã Gia Trung",
						type: "Xã",
					},
					{
						level3_id: "14512",
						name: "Xã Gia Minh",
						type: "Xã",
					},
					{
						level3_id: "14515",
						name: "Xã Gia Lạc",
						type: "Xã",
					},
					{
						level3_id: "14518",
						name: "Xã Gia Tiến",
						type: "Xã",
					},
					{
						level3_id: "14521",
						name: "Xã Gia Sinh",
						type: "Xã",
					},
					{
						level3_id: "14524",
						name: "Xã Gia Phong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "374",
				name: "Huyện Hoa Lư",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14527",
						name: "Thị trấn Thiên Tôn",
						type: "Thị trấn",
					},
					{
						level3_id: "14530",
						name: "Xã Ninh Giang",
						type: "Xã",
					},
					{
						level3_id: "14533",
						name: "Xã Trường Yên",
						type: "Xã",
					},
					{
						level3_id: "14536",
						name: "Xã Ninh Khang",
						type: "Xã",
					},
					{
						level3_id: "14539",
						name: "Xã Ninh Mỹ",
						type: "Xã",
					},
					{
						level3_id: "14542",
						name: "Xã Ninh Hòa",
						type: "Xã",
					},
					{
						level3_id: "14545",
						name: "Xã Ninh Xuân",
						type: "Xã",
					},
					{
						level3_id: "14548",
						name: "Xã Ninh Hải",
						type: "Xã",
					},
					{
						level3_id: "14551",
						name: "Xã Ninh Thắng",
						type: "Xã",
					},
					{
						level3_id: "14554",
						name: "Xã Ninh Vân",
						type: "Xã",
					},
					{
						level3_id: "14557",
						name: "Xã Ninh An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "375",
				name: "Huyện Yên Khánh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14560",
						name: "Thị trấn Yên Ninh",
						type: "Thị trấn",
					},
					{
						level3_id: "14563",
						name: "Xã Khánh Tiên",
						type: "Xã",
					},
					{
						level3_id: "14566",
						name: "Xã Khánh Phú",
						type: "Xã",
					},
					{
						level3_id: "14569",
						name: "Xã Khánh Hòa",
						type: "Xã",
					},
					{
						level3_id: "14572",
						name: "Xã Khánh Lợi",
						type: "Xã",
					},
					{
						level3_id: "14575",
						name: "Xã Khánh An",
						type: "Xã",
					},
					{
						level3_id: "14578",
						name: "Xã Khánh Cường",
						type: "Xã",
					},
					{
						level3_id: "14581",
						name: "Xã Khánh Cư",
						type: "Xã",
					},
					{
						level3_id: "14584",
						name: "Xã Khánh Thiện",
						type: "Xã",
					},
					{
						level3_id: "14587",
						name: "Xã Khánh Hải",
						type: "Xã",
					},
					{
						level3_id: "14590",
						name: "Xã Khánh Trung",
						type: "Xã",
					},
					{
						level3_id: "14593",
						name: "Xã Khánh Mậu",
						type: "Xã",
					},
					{
						level3_id: "14596",
						name: "Xã Khánh Vân",
						type: "Xã",
					},
					{
						level3_id: "14599",
						name: "Xã Khánh Hội",
						type: "Xã",
					},
					{
						level3_id: "14602",
						name: "Xã Khánh Công",
						type: "Xã",
					},
					{
						level3_id: "14608",
						name: "Xã Khánh Thành",
						type: "Xã",
					},
					{
						level3_id: "14611",
						name: "Xã Khánh Nhạc",
						type: "Xã",
					},
					{
						level3_id: "14614",
						name: "Xã Khánh Thủy",
						type: "Xã",
					},
					{
						level3_id: "14617",
						name: "Xã Khánh Hồng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "376",
				name: "Huyện Kim Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14620",
						name: "Thị trấn Phát Diệm",
						type: "Thị trấn",
					},
					{
						level3_id: "14623",
						name: "Thị trấn Bình Minh",
						type: "Thị trấn",
					},
					{
						level3_id: "14629",
						name: "Xã Hồi Ninh",
						type: "Xã",
					},
					{
						level3_id: "14632",
						name: "Xã Xuân Chính",
						type: "Xã",
					},
					{
						level3_id: "14635",
						name: "Xã Kim Định",
						type: "Xã",
					},
					{
						level3_id: "14638",
						name: "Xã Ân Hòa",
						type: "Xã",
					},
					{
						level3_id: "14641",
						name: "Xã Hùng Tiến",
						type: "Xã",
					},
					{
						level3_id: "14647",
						name: "Xã Quang Thiện",
						type: "Xã",
					},
					{
						level3_id: "14650",
						name: "Xã Như Hòa",
						type: "Xã",
					},
					{
						level3_id: "14653",
						name: "Xã Chất Bình",
						type: "Xã",
					},
					{
						level3_id: "14656",
						name: "Xã Đồng Hướng",
						type: "Xã",
					},
					{
						level3_id: "14659",
						name: "Xã Kim Chính",
						type: "Xã",
					},
					{
						level3_id: "14662",
						name: "Xã Thượng Kiệm",
						type: "Xã",
					},
					{
						level3_id: "14665",
						name: "Xã Lưu Phương",
						type: "Xã",
					},
					{
						level3_id: "14668",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "14671",
						name: "Xã Yên Lộc",
						type: "Xã",
					},
					{
						level3_id: "14674",
						name: "Xã Lai Thành",
						type: "Xã",
					},
					{
						level3_id: "14677",
						name: "Xã Định Hóa",
						type: "Xã",
					},
					{
						level3_id: "14680",
						name: "Xã Văn Hải",
						type: "Xã",
					},
					{
						level3_id: "14683",
						name: "Xã Kim Tân",
						type: "Xã",
					},
					{
						level3_id: "14686",
						name: "Xã Kim Mỹ",
						type: "Xã",
					},
					{
						level3_id: "14689",
						name: "Xã Cồn Thoi",
						type: "Xã",
					},
					{
						level3_id: "14692",
						name: "Xã Kim Hải",
						type: "Xã",
					},
					{
						level3_id: "14695",
						name: "Xã Kim Trung",
						type: "Xã",
					},
					{
						level3_id: "14698",
						name: "Xã Kim Đông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "377",
				name: "Huyện Yên Mô",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14701",
						name: "Thị trấn Yên Thịnh",
						type: "Thị trấn",
					},
					{
						level3_id: "14704",
						name: "Xã Khánh Thượng",
						type: "Xã",
					},
					{
						level3_id: "14707",
						name: "Xã Khánh Dương",
						type: "Xã",
					},
					{
						level3_id: "14710",
						name: "Xã Mai Sơn",
						type: "Xã",
					},
					{
						level3_id: "14713",
						name: "Xã Khánh Thịnh",
						type: "Xã",
					},
					{
						level3_id: "14719",
						name: "Xã Yên Phong",
						type: "Xã",
					},
					{
						level3_id: "14722",
						name: "Xã Yên Hòa",
						type: "Xã",
					},
					{
						level3_id: "14725",
						name: "Xã Yên Thắng",
						type: "Xã",
					},
					{
						level3_id: "14728",
						name: "Xã Yên Từ",
						type: "Xã",
					},
					{
						level3_id: "14731",
						name: "Xã Yên Hưng",
						type: "Xã",
					},
					{
						level3_id: "14734",
						name: "Xã Yên Thành",
						type: "Xã",
					},
					{
						level3_id: "14737",
						name: "Xã Yên Nhân",
						type: "Xã",
					},
					{
						level3_id: "14740",
						name: "Xã Yên Mỹ",
						type: "Xã",
					},
					{
						level3_id: "14743",
						name: "Xã Yên Mạc",
						type: "Xã",
					},
					{
						level3_id: "14746",
						name: "Xã Yên Đồng",
						type: "Xã",
					},
					{
						level3_id: "14749",
						name: "Xã Yên Thái",
						type: "Xã",
					},
					{
						level3_id: "14752",
						name: "Xã Yên Lâm",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "38",
		name: "Tỉnh Thanh Hóa",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "380",
				name: "Thành phố Thanh Hóa",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "14755",
						name: "Phường Hàm Rồng",
						type: "Phường",
					},
					{
						level3_id: "14758",
						name: "Phường Đông Thọ",
						type: "Phường",
					},
					{
						level3_id: "14761",
						name: "Phường Nam Ngạn",
						type: "Phường",
					},
					{
						level3_id: "14764",
						name: "Phường Trường Thi",
						type: "Phường",
					},
					{
						level3_id: "14767",
						name: "Phường Điện Biên",
						type: "Phường",
					},
					{
						level3_id: "14770",
						name: "Phường Phú Sơn",
						type: "Phường",
					},
					{
						level3_id: "14773",
						name: "Phường Lam Sơn",
						type: "Phường",
					},
					{
						level3_id: "14776",
						name: "Phường Ba Đình",
						type: "Phường",
					},
					{
						level3_id: "14779",
						name: "Phường Ngọc Trạo",
						type: "Phường",
					},
					{
						level3_id: "14782",
						name: "Phường Đông Vệ",
						type: "Phường",
					},
					{
						level3_id: "14785",
						name: "Phường Đông Sơn",
						type: "Phường",
					},
					{
						level3_id: "14788",
						name: "Phường Tân Sơn",
						type: "Phường",
					},
					{
						level3_id: "14791",
						name: "Phường Đông Cương",
						type: "Phường",
					},
					{
						level3_id: "14794",
						name: "Phường Đông Hương",
						type: "Phường",
					},
					{
						level3_id: "14797",
						name: "Phường Đông Hải",
						type: "Phường",
					},
					{
						level3_id: "14800",
						name: "Phường Quảng Hưng",
						type: "Phường",
					},
					{
						level3_id: "14803",
						name: "Phường Quảng Thắng",
						type: "Phường",
					},
					{
						level3_id: "14806",
						name: "Phường Quảng Thành",
						type: "Phường",
					},
					{
						level3_id: "15850",
						name: "Xã Thiệu Vân",
						type: "Xã",
					},
					{
						level3_id: "15856",
						name: "Phường Thiệu Khánh",
						type: "Phường",
					},
					{
						level3_id: "15859",
						name: "Phường Thiệu Dương",
						type: "Phường",
					},
					{
						level3_id: "15913",
						name: "Phường Tào Xuyên",
						type: "Phường",
					},
					{
						level3_id: "15922",
						name: "Phường Long Anh",
						type: "Phường",
					},
					{
						level3_id: "15925",
						name: "Xã Hoằng Quang",
						type: "Xã",
					},
					{
						level3_id: "15970",
						name: "Xã Hoằng Đại",
						type: "Xã",
					},
					{
						level3_id: "16396",
						name: "Phường Đông Lĩnh",
						type: "Phường",
					},
					{
						level3_id: "16429",
						name: "Xã Đông Vinh",
						type: "Xã",
					},
					{
						level3_id: "16432",
						name: "Phường Đông Tân",
						type: "Phường",
					},
					{
						level3_id: "16435",
						name: "Phường An Hưng",
						type: "Phường",
					},
					{
						level3_id: "16441",
						name: "Phường Quảng Thịnh",
						type: "Phường",
					},
					{
						level3_id: "16459",
						name: "Phường Quảng Đông",
						type: "Phường",
					},
					{
						level3_id: "16507",
						name: "Phường Quảng Cát",
						type: "Phường",
					},
					{
						level3_id: "16522",
						name: "Phường Quảng Phú",
						type: "Phường",
					},
					{
						level3_id: "16525",
						name: "Phường Quảng Tâm",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "381",
				name: "Thị xã Bỉm Sơn",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "14809",
						name: "Phường Bắc Sơn",
						type: "Phường",
					},
					{
						level3_id: "14812",
						name: "Phường Ba Đình",
						type: "Phường",
					},
					{
						level3_id: "14815",
						name: "Phường Lam Sơn",
						type: "Phường",
					},
					{
						level3_id: "14818",
						name: "Phường Ngọc Trạo",
						type: "Phường",
					},
					{
						level3_id: "14821",
						name: "Phường Đông Sơn",
						type: "Phường",
					},
					{
						level3_id: "14823",
						name: "Phường Phú Sơn",
						type: "Phường",
					},
					{
						level3_id: "14824",
						name: "Xã Quang Trung",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "382",
				name: "Thành phố Sầm Sơn",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "14830",
						name: "Phường Trung Sơn",
						type: "Phường",
					},
					{
						level3_id: "14833",
						name: "Phường Bắc Sơn",
						type: "Phường",
					},
					{
						level3_id: "14836",
						name: "Phường Trường Sơn",
						type: "Phường",
					},
					{
						level3_id: "14839",
						name: "Phường Quảng Cư",
						type: "Phường",
					},
					{
						level3_id: "14842",
						name: "Phường Quảng Tiến",
						type: "Phường",
					},
					{
						level3_id: "16513",
						name: "Xã Quảng Minh",
						type: "Xã",
					},
					{
						level3_id: "16516",
						name: "Xã Quảng Hùng",
						type: "Xã",
					},
					{
						level3_id: "16528",
						name: "Phường Quảng Thọ",
						type: "Phường",
					},
					{
						level3_id: "16531",
						name: "Phường Quảng Châu",
						type: "Phường",
					},
					{
						level3_id: "16534",
						name: "Phường Quảng Vinh",
						type: "Phường",
					},
					{
						level3_id: "16537",
						name: "Xã Quảng Đại",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "384",
				name: "Huyện Mường Lát",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14845",
						name: "Thị trấn Mường Lát",
						type: "Thị trấn",
					},
					{
						level3_id: "14848",
						name: "Xã Tam Chung",
						type: "Xã",
					},
					{
						level3_id: "14854",
						name: "Xã Mường Lý",
						type: "Xã",
					},
					{
						level3_id: "14857",
						name: "Xã Trung Lý",
						type: "Xã",
					},
					{
						level3_id: "14860",
						name: "Xã Quang Chiểu",
						type: "Xã",
					},
					{
						level3_id: "14863",
						name: "Xã Pù Nhi",
						type: "Xã",
					},
					{
						level3_id: "14864",
						name: "Xã Nhi Sơn",
						type: "Xã",
					},
					{
						level3_id: "14866",
						name: "Xã Mường Chanh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "385",
				name: "Huyện Quan Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14869",
						name: "Thị trấn Hồi Xuân",
						type: "Thị trấn",
					},
					{
						level3_id: "14872",
						name: "Xã Thành Sơn",
						type: "Xã",
					},
					{
						level3_id: "14875",
						name: "Xã Trung Sơn",
						type: "Xã",
					},
					{
						level3_id: "14878",
						name: "Xã Phú Thanh",
						type: "Xã",
					},
					{
						level3_id: "14881",
						name: "Xã Trung Thành",
						type: "Xã",
					},
					{
						level3_id: "14884",
						name: "Xã Phú Lệ",
						type: "Xã",
					},
					{
						level3_id: "14887",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "14890",
						name: "Xã Phú Xuân",
						type: "Xã",
					},
					{
						level3_id: "14896",
						name: "Xã Hiền Chung",
						type: "Xã",
					},
					{
						level3_id: "14899",
						name: "Xã Hiền Kiệt",
						type: "Xã",
					},
					{
						level3_id: "14902",
						name: "Xã Nam Tiến",
						type: "Xã",
					},
					{
						level3_id: "14908",
						name: "Xã Thiên Phủ",
						type: "Xã",
					},
					{
						level3_id: "14911",
						name: "Xã Phú Nghiêm",
						type: "Xã",
					},
					{
						level3_id: "14914",
						name: "Xã Nam Xuân",
						type: "Xã",
					},
					{
						level3_id: "14917",
						name: "Xã Nam Động",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "386",
				name: "Huyện Bá Thước",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14923",
						name: "Thị trấn Cành Nàng",
						type: "Thị trấn",
					},
					{
						level3_id: "14926",
						name: "Xã Điền Thượng",
						type: "Xã",
					},
					{
						level3_id: "14929",
						name: "Xã Điền Hạ",
						type: "Xã",
					},
					{
						level3_id: "14932",
						name: "Xã Điền Quang",
						type: "Xã",
					},
					{
						level3_id: "14935",
						name: "Xã Điền Trung",
						type: "Xã",
					},
					{
						level3_id: "14938",
						name: "Xã Thành Sơn",
						type: "Xã",
					},
					{
						level3_id: "14941",
						name: "Xã Lương Ngoại",
						type: "Xã",
					},
					{
						level3_id: "14944",
						name: "Xã Ái Thượng",
						type: "Xã",
					},
					{
						level3_id: "14947",
						name: "Xã Lương Nội",
						type: "Xã",
					},
					{
						level3_id: "14950",
						name: "Xã Điền Lư",
						type: "Xã",
					},
					{
						level3_id: "14953",
						name: "Xã Lương Trung",
						type: "Xã",
					},
					{
						level3_id: "14956",
						name: "Xã Lũng Niêm",
						type: "Xã",
					},
					{
						level3_id: "14959",
						name: "Xã Lũng Cao",
						type: "Xã",
					},
					{
						level3_id: "14962",
						name: "Xã Hạ Trung",
						type: "Xã",
					},
					{
						level3_id: "14965",
						name: "Xã Cổ Lũng",
						type: "Xã",
					},
					{
						level3_id: "14968",
						name: "Xã Thành Lâm",
						type: "Xã",
					},
					{
						level3_id: "14971",
						name: "Xã Ban Công",
						type: "Xã",
					},
					{
						level3_id: "14974",
						name: "Xã Kỳ Tân",
						type: "Xã",
					},
					{
						level3_id: "14977",
						name: "Xã Văn Nho",
						type: "Xã",
					},
					{
						level3_id: "14980",
						name: "Xã Thiết Ống",
						type: "Xã",
					},
					{
						level3_id: "14986",
						name: "Xã Thiết Kế",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "387",
				name: "Huyện Quan Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "14995",
						name: "Xã Trung Xuân",
						type: "Xã",
					},
					{
						level3_id: "14998",
						name: "Xã Trung Thượng",
						type: "Xã",
					},
					{
						level3_id: "14999",
						name: "Xã Trung Tiến",
						type: "Xã",
					},
					{
						level3_id: "15001",
						name: "Xã Trung Hạ",
						type: "Xã",
					},
					{
						level3_id: "15004",
						name: "Xã Sơn Hà",
						type: "Xã",
					},
					{
						level3_id: "15007",
						name: "Xã Tam Thanh",
						type: "Xã",
					},
					{
						level3_id: "15010",
						name: "Xã Sơn Thủy",
						type: "Xã",
					},
					{
						level3_id: "15013",
						name: "Xã Na Mèo",
						type: "Xã",
					},
					{
						level3_id: "15016",
						name: "Thị trấn Sơn Lư",
						type: "Thị trấn",
					},
					{
						level3_id: "15019",
						name: "Xã Tam Lư",
						type: "Xã",
					},
					{
						level3_id: "15022",
						name: "Xã Sơn Điện",
						type: "Xã",
					},
					{
						level3_id: "15025",
						name: "Xã Mường Mìn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "388",
				name: "Huyện Lang Chánh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15031",
						name: "Xã Yên Khương",
						type: "Xã",
					},
					{
						level3_id: "15034",
						name: "Xã Yên Thắng",
						type: "Xã",
					},
					{
						level3_id: "15037",
						name: "Xã Trí Nang",
						type: "Xã",
					},
					{
						level3_id: "15040",
						name: "Xã Giao An",
						type: "Xã",
					},
					{
						level3_id: "15043",
						name: "Xã Giao Thiện",
						type: "Xã",
					},
					{
						level3_id: "15046",
						name: "Xã Tân Phúc",
						type: "Xã",
					},
					{
						level3_id: "15049",
						name: "Xã Tam Văn",
						type: "Xã",
					},
					{
						level3_id: "15052",
						name: "Xã Lâm Phú",
						type: "Xã",
					},
					{
						level3_id: "15055",
						name: "Thị trấn Lang Chánh",
						type: "Thị trấn",
					},
					{
						level3_id: "15058",
						name: "Xã Đồng Lương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "389",
				name: "Huyện Ngọc Lặc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15061",
						name: "Thị Trấn Ngọc Lặc",
						type: "Thị trấn",
					},
					{
						level3_id: "15064",
						name: "Xã Lam Sơn",
						type: "Xã",
					},
					{
						level3_id: "15067",
						name: "Xã Mỹ Tân",
						type: "Xã",
					},
					{
						level3_id: "15070",
						name: "Xã Thúy Sơn",
						type: "Xã",
					},
					{
						level3_id: "15073",
						name: "Xã Thạch Lập",
						type: "Xã",
					},
					{
						level3_id: "15076",
						name: "Xã Vân Âm",
						type: "Xã",
					},
					{
						level3_id: "15079",
						name: "Xã Cao Ngọc",
						type: "Xã",
					},
					{
						level3_id: "15085",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "15088",
						name: "Xã Đồng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "15091",
						name: "Xã Ngọc Liên",
						type: "Xã",
					},
					{
						level3_id: "15094",
						name: "Xã Ngọc Sơn",
						type: "Xã",
					},
					{
						level3_id: "15097",
						name: "Xã Lộc Thịnh",
						type: "Xã",
					},
					{
						level3_id: "15100",
						name: "Xã Cao Thịnh",
						type: "Xã",
					},
					{
						level3_id: "15103",
						name: "Xã Ngọc Trung",
						type: "Xã",
					},
					{
						level3_id: "15106",
						name: "Xã Phùng Giáo",
						type: "Xã",
					},
					{
						level3_id: "15109",
						name: "Xã Phùng Minh",
						type: "Xã",
					},
					{
						level3_id: "15112",
						name: "Xã Phúc Thịnh",
						type: "Xã",
					},
					{
						level3_id: "15115",
						name: "Xã Nguyệt Ấn",
						type: "Xã",
					},
					{
						level3_id: "15118",
						name: "Xã Kiên Thọ",
						type: "Xã",
					},
					{
						level3_id: "15121",
						name: "Xã Minh Tiến",
						type: "Xã",
					},
					{
						level3_id: "15124",
						name: "Xã Minh Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "390",
				name: "Huyện Cẩm Thủy",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15127",
						name: "Thị trấn Phong Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "15133",
						name: "Xã Cẩm Thành",
						type: "Xã",
					},
					{
						level3_id: "15136",
						name: "Xã Cẩm Quý",
						type: "Xã",
					},
					{
						level3_id: "15139",
						name: "Xã Cẩm Lương",
						type: "Xã",
					},
					{
						level3_id: "15142",
						name: "Xã Cẩm Thạch",
						type: "Xã",
					},
					{
						level3_id: "15145",
						name: "Xã Cẩm Liên",
						type: "Xã",
					},
					{
						level3_id: "15148",
						name: "Xã Cẩm Giang",
						type: "Xã",
					},
					{
						level3_id: "15151",
						name: "Xã Cẩm Bình",
						type: "Xã",
					},
					{
						level3_id: "15154",
						name: "Xã Cẩm Tú",
						type: "Xã",
					},
					{
						level3_id: "15160",
						name: "Xã Cẩm Châu",
						type: "Xã",
					},
					{
						level3_id: "15163",
						name: "Xã Cẩm Tâm",
						type: "Xã",
					},
					{
						level3_id: "15169",
						name: "Xã Cẩm Ngọc",
						type: "Xã",
					},
					{
						level3_id: "15172",
						name: "Xã Cẩm Long",
						type: "Xã",
					},
					{
						level3_id: "15175",
						name: "Xã Cẩm Yên",
						type: "Xã",
					},
					{
						level3_id: "15178",
						name: "Xã Cẩm Tân",
						type: "Xã",
					},
					{
						level3_id: "15181",
						name: "Xã Cẩm Phú",
						type: "Xã",
					},
					{
						level3_id: "15184",
						name: "Xã Cẩm Vân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "391",
				name: "Huyện Thạch Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15187",
						name: "Thị trấn Kim Tân",
						type: "Thị trấn",
					},
					{
						level3_id: "15190",
						name: "Thị trấn Vân Du",
						type: "Thị trấn",
					},
					{
						level3_id: "15196",
						name: "Xã Thạch Lâm",
						type: "Xã",
					},
					{
						level3_id: "15199",
						name: "Xã Thạch Quảng",
						type: "Xã",
					},
					{
						level3_id: "15202",
						name: "Xã Thạch Tượng",
						type: "Xã",
					},
					{
						level3_id: "15205",
						name: "Xã Thạch Cẩm",
						type: "Xã",
					},
					{
						level3_id: "15208",
						name: "Xã Thạch Sơn",
						type: "Xã",
					},
					{
						level3_id: "15211",
						name: "Xã Thạch Bình",
						type: "Xã",
					},
					{
						level3_id: "15214",
						name: "Xã Thạch Định",
						type: "Xã",
					},
					{
						level3_id: "15217",
						name: "Xã Thạch Đồng",
						type: "Xã",
					},
					{
						level3_id: "15220",
						name: "Xã Thạch Long",
						type: "Xã",
					},
					{
						level3_id: "15223",
						name: "Xã Thành Mỹ",
						type: "Xã",
					},
					{
						level3_id: "15226",
						name: "Xã Thành Yên",
						type: "Xã",
					},
					{
						level3_id: "15229",
						name: "Xã Thành Vinh",
						type: "Xã",
					},
					{
						level3_id: "15232",
						name: "Xã Thành Minh",
						type: "Xã",
					},
					{
						level3_id: "15235",
						name: "Xã Thành Công",
						type: "Xã",
					},
					{
						level3_id: "15238",
						name: "Xã Thành Tân",
						type: "Xã",
					},
					{
						level3_id: "15241",
						name: "Xã Thành Trực",
						type: "Xã",
					},
					{
						level3_id: "15247",
						name: "Xã Thành Tâm",
						type: "Xã",
					},
					{
						level3_id: "15250",
						name: "Xã Thành An",
						type: "Xã",
					},
					{
						level3_id: "15253",
						name: "Xã Thành Thọ",
						type: "Xã",
					},
					{
						level3_id: "15256",
						name: "Xã Thành Tiến",
						type: "Xã",
					},
					{
						level3_id: "15259",
						name: "Xã Thành Long",
						type: "Xã",
					},
					{
						level3_id: "15265",
						name: "Xã Thành Hưng",
						type: "Xã",
					},
					{
						level3_id: "15268",
						name: "Xã Ngọc Trạo",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "392",
				name: "Huyện Hà Trung",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15271",
						name: "Thị trấn Hà Trung",
						type: "Thị trấn",
					},
					{
						level3_id: "15274",
						name: "Xã Hà Long",
						type: "Xã",
					},
					{
						level3_id: "15277",
						name: "Xã Hà Vinh",
						type: "Xã",
					},
					{
						level3_id: "15280",
						name: "Xã Hà Bắc",
						type: "Xã",
					},
					{
						level3_id: "15283",
						name: "Xã Hoạt Giang",
						type: "Xã",
					},
					{
						level3_id: "15286",
						name: "Xã Yên Dương",
						type: "Xã",
					},
					{
						level3_id: "15292",
						name: "Xã Hà Giang",
						type: "Xã",
					},
					{
						level3_id: "15298",
						name: "Xã Lĩnh Toại",
						type: "Xã",
					},
					{
						level3_id: "15304",
						name: "Xã Hà Ngọc",
						type: "Xã",
					},
					{
						level3_id: "15307",
						name: "Xã Yến Sơn",
						type: "Xã",
					},
					{
						level3_id: "15313",
						name: "Xã Hà Sơn",
						type: "Xã",
					},
					{
						level3_id: "15316",
						name: "Xã Hà Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "15319",
						name: "Xã Hà Đông",
						type: "Xã",
					},
					{
						level3_id: "15322",
						name: "Xã Hà Tân",
						type: "Xã",
					},
					{
						level3_id: "15325",
						name: "Xã Hà Tiến",
						type: "Xã",
					},
					{
						level3_id: "15328",
						name: "Xã Hà Bình",
						type: "Xã",
					},
					{
						level3_id: "15331",
						name: "Xã Hà Lai",
						type: "Xã",
					},
					{
						level3_id: "15334",
						name: "Xã Hà Châu",
						type: "Xã",
					},
					{
						level3_id: "15340",
						name: "Xã Hà Thái",
						type: "Xã",
					},
					{
						level3_id: "15343",
						name: "Xã Hà Hải",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "393",
				name: "Huyện Vĩnh Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15349",
						name: "Thị trấn Vĩnh Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "15352",
						name: "Xã Vĩnh Quang",
						type: "Xã",
					},
					{
						level3_id: "15355",
						name: "Xã Vĩnh Yên",
						type: "Xã",
					},
					{
						level3_id: "15358",
						name: "Xã Vĩnh Tiến",
						type: "Xã",
					},
					{
						level3_id: "15361",
						name: "Xã Vĩnh Long",
						type: "Xã",
					},
					{
						level3_id: "15364",
						name: "Xã Vĩnh Phúc",
						type: "Xã",
					},
					{
						level3_id: "15367",
						name: "Xã Vĩnh Hưng",
						type: "Xã",
					},
					{
						level3_id: "15376",
						name: "Xã Vĩnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "15379",
						name: "Xã Vĩnh Hùng",
						type: "Xã",
					},
					{
						level3_id: "15382",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "15385",
						name: "Xã Ninh Khang",
						type: "Xã",
					},
					{
						level3_id: "15388",
						name: "Xã Vĩnh Thịnh",
						type: "Xã",
					},
					{
						level3_id: "15391",
						name: "Xã Vĩnh An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "394",
				name: "Huyện Yên Định",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15397",
						name: "Thị trấn Thống Nhất",
						type: "Thị trấn",
					},
					{
						level3_id: "15403",
						name: "Thị trấn Yên Lâm",
						type: "Thị trấn",
					},
					{
						level3_id: "15406",
						name: "Xã Yên Tâm",
						type: "Xã",
					},
					{
						level3_id: "15409",
						name: "Xã Yên Phú",
						type: "Xã",
					},
					{
						level3_id: "15412",
						name: "Thị trấn Quý Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "15415",
						name: "Xã Yên Thọ",
						type: "Xã",
					},
					{
						level3_id: "15418",
						name: "Xã Yên Trung",
						type: "Xã",
					},
					{
						level3_id: "15421",
						name: "Xã Yên Trường",
						type: "Xã",
					},
					{
						level3_id: "15427",
						name: "Xã Yên Phong",
						type: "Xã",
					},
					{
						level3_id: "15430",
						name: "Xã Yên Thái",
						type: "Xã",
					},
					{
						level3_id: "15433",
						name: "Xã Yên Hùng",
						type: "Xã",
					},
					{
						level3_id: "15436",
						name: "Xã Yên Thịnh",
						type: "Xã",
					},
					{
						level3_id: "15439",
						name: "Xã Yên Ninh",
						type: "Xã",
					},
					{
						level3_id: "15442",
						name: "Xã Yên Lạc",
						type: "Xã",
					},
					{
						level3_id: "15445",
						name: "Xã Định Tăng",
						type: "Xã",
					},
					{
						level3_id: "15448",
						name: "Xã Định Hòa",
						type: "Xã",
					},
					{
						level3_id: "15451",
						name: "Xã Định Thành",
						type: "Xã",
					},
					{
						level3_id: "15454",
						name: "Xã Định Công",
						type: "Xã",
					},
					{
						level3_id: "15457",
						name: "Xã Định Tân",
						type: "Xã",
					},
					{
						level3_id: "15460",
						name: "Xã Định Tiến",
						type: "Xã",
					},
					{
						level3_id: "15463",
						name: "Xã Định Long",
						type: "Xã",
					},
					{
						level3_id: "15466",
						name: "Xã Định Liên",
						type: "Xã",
					},
					{
						level3_id: "15469",
						name: "Thị trấn Quán Lào",
						type: "Thị trấn",
					},
					{
						level3_id: "15472",
						name: "Xã Định Hưng",
						type: "Xã",
					},
					{
						level3_id: "15475",
						name: "Xã Định Hải",
						type: "Xã",
					},
					{
						level3_id: "15478",
						name: "Xã Định Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "395",
				name: "Huyện Thọ Xuân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15493",
						name: "Xã Xuân Hồng",
						type: "Xã",
					},
					{
						level3_id: "15499",
						name: "Thị trấn Thọ Xuân",
						type: "Thị trấn",
					},
					{
						level3_id: "15502",
						name: "Xã Bắc Lương",
						type: "Xã",
					},
					{
						level3_id: "15505",
						name: "Xã Nam Giang",
						type: "Xã",
					},
					{
						level3_id: "15508",
						name: "Xã Xuân Phong",
						type: "Xã",
					},
					{
						level3_id: "15511",
						name: "Xã Thọ Lộc",
						type: "Xã",
					},
					{
						level3_id: "15514",
						name: "Xã Xuân Trường",
						type: "Xã",
					},
					{
						level3_id: "15517",
						name: "Xã Xuân Hòa",
						type: "Xã",
					},
					{
						level3_id: "15520",
						name: "Xã Thọ Hải",
						type: "Xã",
					},
					{
						level3_id: "15523",
						name: "Xã Tây Hồ",
						type: "Xã",
					},
					{
						level3_id: "15526",
						name: "Xã Xuân Giang",
						type: "Xã",
					},
					{
						level3_id: "15532",
						name: "Xã Xuân Sinh",
						type: "Xã",
					},
					{
						level3_id: "15535",
						name: "Xã Xuân Hưng",
						type: "Xã",
					},
					{
						level3_id: "15538",
						name: "Xã Thọ Diên",
						type: "Xã",
					},
					{
						level3_id: "15541",
						name: "Xã Thọ Lâm",
						type: "Xã",
					},
					{
						level3_id: "15544",
						name: "Xã Thọ Xương",
						type: "Xã",
					},
					{
						level3_id: "15547",
						name: "Xã Xuân Bái",
						type: "Xã",
					},
					{
						level3_id: "15550",
						name: "Xã Xuân Phú",
						type: "Xã",
					},
					{
						level3_id: "15553",
						name: "Thị trấn Sao Vàng",
						type: "Thị trấn",
					},
					{
						level3_id: "15556",
						name: "Thị trấn Lam Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "15559",
						name: "Xã Xuân Thiên",
						type: "Xã",
					},
					{
						level3_id: "15565",
						name: "Xã Thuận Minh",
						type: "Xã",
					},
					{
						level3_id: "15568",
						name: "Xã Thọ Lập",
						type: "Xã",
					},
					{
						level3_id: "15571",
						name: "Xã Quảng Phú",
						type: "Xã",
					},
					{
						level3_id: "15574",
						name: "Xã Xuân Tín",
						type: "Xã",
					},
					{
						level3_id: "15577",
						name: "Xã Phú Xuân",
						type: "Xã",
					},
					{
						level3_id: "15583",
						name: "Xã Xuân Lai",
						type: "Xã",
					},
					{
						level3_id: "15586",
						name: "Xã Xuân Lập",
						type: "Xã",
					},
					{
						level3_id: "15592",
						name: "Xã Xuân Minh",
						type: "Xã",
					},
					{
						level3_id: "15598",
						name: "Xã Trường Xuân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "396",
				name: "Huyện Thường Xuân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15607",
						name: "Xã Bát Mọt",
						type: "Xã",
					},
					{
						level3_id: "15610",
						name: "Xã Yên Nhân",
						type: "Xã",
					},
					{
						level3_id: "15619",
						name: "Xã Xuân Lẹ",
						type: "Xã",
					},
					{
						level3_id: "15622",
						name: "Xã Vạn Xuân",
						type: "Xã",
					},
					{
						level3_id: "15628",
						name: "Xã Lương Sơn",
						type: "Xã",
					},
					{
						level3_id: "15631",
						name: "Xã Xuân Cao",
						type: "Xã",
					},
					{
						level3_id: "15634",
						name: "Xã Luận Thành",
						type: "Xã",
					},
					{
						level3_id: "15637",
						name: "Xã Luận Khê",
						type: "Xã",
					},
					{
						level3_id: "15640",
						name: "Xã Xuân Thắng",
						type: "Xã",
					},
					{
						level3_id: "15643",
						name: "Xã Xuân Lộc",
						type: "Xã",
					},
					{
						level3_id: "15646",
						name: "Thị trấn Thường Xuân",
						type: "Thị trấn",
					},
					{
						level3_id: "15649",
						name: "Xã Xuân Dương",
						type: "Xã",
					},
					{
						level3_id: "15652",
						name: "Xã Thọ Thanh",
						type: "Xã",
					},
					{
						level3_id: "15655",
						name: "Xã Ngọc Phụng",
						type: "Xã",
					},
					{
						level3_id: "15658",
						name: "Xã Xuân Chinh",
						type: "Xã",
					},
					{
						level3_id: "15661",
						name: "Xã Tân Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "397",
				name: "Huyện Triệu Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15664",
						name: "Thị trấn Triệu Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "15667",
						name: "Xã Thọ Sơn",
						type: "Xã",
					},
					{
						level3_id: "15670",
						name: "Xã Thọ Bình",
						type: "Xã",
					},
					{
						level3_id: "15673",
						name: "Xã Thọ Tiến",
						type: "Xã",
					},
					{
						level3_id: "15676",
						name: "Xã Hợp Lý",
						type: "Xã",
					},
					{
						level3_id: "15679",
						name: "Xã Hợp Tiến",
						type: "Xã",
					},
					{
						level3_id: "15682",
						name: "Xã Hợp Thành",
						type: "Xã",
					},
					{
						level3_id: "15685",
						name: "Xã Triệu Thành",
						type: "Xã",
					},
					{
						level3_id: "15688",
						name: "Xã Hợp Thắng",
						type: "Xã",
					},
					{
						level3_id: "15691",
						name: "Xã Minh Sơn",
						type: "Xã",
					},
					{
						level3_id: "15700",
						name: "Xã Dân Lực",
						type: "Xã",
					},
					{
						level3_id: "15703",
						name: "Xã Dân Lý",
						type: "Xã",
					},
					{
						level3_id: "15706",
						name: "Xã Dân Quyền",
						type: "Xã",
					},
					{
						level3_id: "15709",
						name: "Xã An Nông",
						type: "Xã",
					},
					{
						level3_id: "15712",
						name: "Xã Văn Sơn",
						type: "Xã",
					},
					{
						level3_id: "15715",
						name: "Xã Thái Hòa",
						type: "Xã",
					},
					{
						level3_id: "15718",
						name: "Thị trấn Nưa",
						type: "Thị trấn",
					},
					{
						level3_id: "15721",
						name: "Xã Đồng Lợi",
						type: "Xã",
					},
					{
						level3_id: "15724",
						name: "Xã Đồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "15727",
						name: "Xã Đồng Thắng",
						type: "Xã",
					},
					{
						level3_id: "15730",
						name: "Xã Tiến Nông",
						type: "Xã",
					},
					{
						level3_id: "15733",
						name: "Xã Khuyến Nông",
						type: "Xã",
					},
					{
						level3_id: "15736",
						name: "Xã Xuân Thịnh",
						type: "Xã",
					},
					{
						level3_id: "15739",
						name: "Xã Xuân Lộc",
						type: "Xã",
					},
					{
						level3_id: "15742",
						name: "Xã Thọ Dân",
						type: "Xã",
					},
					{
						level3_id: "15745",
						name: "Xã Xuân Thọ",
						type: "Xã",
					},
					{
						level3_id: "15748",
						name: "Xã Thọ Tân",
						type: "Xã",
					},
					{
						level3_id: "15751",
						name: "Xã Thọ Ngọc",
						type: "Xã",
					},
					{
						level3_id: "15754",
						name: "Xã Thọ Cường",
						type: "Xã",
					},
					{
						level3_id: "15757",
						name: "Xã Thọ Phú",
						type: "Xã",
					},
					{
						level3_id: "15760",
						name: "Xã Thọ Vực",
						type: "Xã",
					},
					{
						level3_id: "15763",
						name: "Xã Thọ Thế",
						type: "Xã",
					},
					{
						level3_id: "15766",
						name: "Xã Nông Trường",
						type: "Xã",
					},
					{
						level3_id: "15769",
						name: "Xã Bình Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "398",
				name: "Huyện Thiệu Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15772",
						name: "Thị trấn Thiệu Hóa",
						type: "Thị trấn",
					},
					{
						level3_id: "15775",
						name: "Xã Thiệu Ngọc",
						type: "Xã",
					},
					{
						level3_id: "15778",
						name: "Xã Thiệu Vũ",
						type: "Xã",
					},
					{
						level3_id: "15781",
						name: "Xã Thiệu Phúc",
						type: "Xã",
					},
					{
						level3_id: "15784",
						name: "Xã Thiệu Tiến",
						type: "Xã",
					},
					{
						level3_id: "15787",
						name: "Xã Thiệu Công",
						type: "Xã",
					},
					{
						level3_id: "15790",
						name: "Xã Thiệu Phú",
						type: "Xã",
					},
					{
						level3_id: "15793",
						name: "Xã Thiệu Long",
						type: "Xã",
					},
					{
						level3_id: "15796",
						name: "Xã Thiệu Giang",
						type: "Xã",
					},
					{
						level3_id: "15799",
						name: "Xã Thiệu Duy",
						type: "Xã",
					},
					{
						level3_id: "15802",
						name: "Xã Thiệu Nguyên",
						type: "Xã",
					},
					{
						level3_id: "15805",
						name: "Xã Thiệu Hợp",
						type: "Xã",
					},
					{
						level3_id: "15808",
						name: "Xã Thiệu Thịnh",
						type: "Xã",
					},
					{
						level3_id: "15811",
						name: "Xã Thiệu Quang",
						type: "Xã",
					},
					{
						level3_id: "15814",
						name: "Xã Thiệu Thành",
						type: "Xã",
					},
					{
						level3_id: "15817",
						name: "Xã Thiệu Toán",
						type: "Xã",
					},
					{
						level3_id: "15820",
						name: "Xã Thiệu Chính",
						type: "Xã",
					},
					{
						level3_id: "15823",
						name: "Xã Thiệu Hòa",
						type: "Xã",
					},
					{
						level3_id: "15829",
						name: "Xã Minh Tâm",
						type: "Xã",
					},
					{
						level3_id: "15832",
						name: "Xã Thiệu Viên",
						type: "Xã",
					},
					{
						level3_id: "15835",
						name: "Xã Thiệu Lý",
						type: "Xã",
					},
					{
						level3_id: "15838",
						name: "Xã Thiệu Vận",
						type: "Xã",
					},
					{
						level3_id: "15841",
						name: "Xã Thiệu Trung",
						type: "Xã",
					},
					{
						level3_id: "15847",
						name: "Xã Tân Châu",
						type: "Xã",
					},
					{
						level3_id: "15853",
						name: "Xã Thiệu Giao",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "399",
				name: "Huyện Hoằng Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "15865",
						name: "Thị trấn Bút Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "15871",
						name: "Xã Hoằng Giang",
						type: "Xã",
					},
					{
						level3_id: "15877",
						name: "Xã Hoằng Xuân",
						type: "Xã",
					},
					{
						level3_id: "15880",
						name: "Xã Hoằng Phượng",
						type: "Xã",
					},
					{
						level3_id: "15883",
						name: "Xã Hoằng Phú",
						type: "Xã",
					},
					{
						level3_id: "15886",
						name: "Xã Hoằng Quỳ",
						type: "Xã",
					},
					{
						level3_id: "15889",
						name: "Xã Hoằng Kim",
						type: "Xã",
					},
					{
						level3_id: "15892",
						name: "Xã Hoằng Trung",
						type: "Xã",
					},
					{
						level3_id: "15895",
						name: "Xã Hoằng Trinh",
						type: "Xã",
					},
					{
						level3_id: "15901",
						name: "Xã Hoằng Sơn",
						type: "Xã",
					},
					{
						level3_id: "15907",
						name: "Xã Hoằng Cát",
						type: "Xã",
					},
					{
						level3_id: "15910",
						name: "Xã Hoằng Xuyên",
						type: "Xã",
					},
					{
						level3_id: "15916",
						name: "Xã Hoằng Quý",
						type: "Xã",
					},
					{
						level3_id: "15919",
						name: "Xã Hoằng Hợp",
						type: "Xã",
					},
					{
						level3_id: "15928",
						name: "Xã Hoằng Đức",
						type: "Xã",
					},
					{
						level3_id: "15937",
						name: "Xã Hoằng Hà",
						type: "Xã",
					},
					{
						level3_id: "15940",
						name: "Xã Hoằng Đạt",
						type: "Xã",
					},
					{
						level3_id: "15946",
						name: "Xã Hoằng Đạo",
						type: "Xã",
					},
					{
						level3_id: "15949",
						name: "Xã Hoằng Thắng",
						type: "Xã",
					},
					{
						level3_id: "15952",
						name: "Xã Hoằng Đồng",
						type: "Xã",
					},
					{
						level3_id: "15955",
						name: "Xã Hoằng Thái",
						type: "Xã",
					},
					{
						level3_id: "15958",
						name: "Xã Hoằng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "15961",
						name: "Xã Hoằng Thành",
						type: "Xã",
					},
					{
						level3_id: "15964",
						name: "Xã Hoằng Lộc",
						type: "Xã",
					},
					{
						level3_id: "15967",
						name: "Xã Hoằng Trạch",
						type: "Xã",
					},
					{
						level3_id: "15973",
						name: "Xã Hoằng Phong",
						type: "Xã",
					},
					{
						level3_id: "15976",
						name: "Xã Hoằng Lưu",
						type: "Xã",
					},
					{
						level3_id: "15979",
						name: "Xã Hoằng Châu",
						type: "Xã",
					},
					{
						level3_id: "15982",
						name: "Xã Hoằng Tân",
						type: "Xã",
					},
					{
						level3_id: "15985",
						name: "Xã Hoằng Yến",
						type: "Xã",
					},
					{
						level3_id: "15988",
						name: "Xã Hoằng Tiến",
						type: "Xã",
					},
					{
						level3_id: "15991",
						name: "Xã Hoằng Hải",
						type: "Xã",
					},
					{
						level3_id: "15994",
						name: "Xã Hoằng Ngọc",
						type: "Xã",
					},
					{
						level3_id: "15997",
						name: "Xã Hoằng Đông",
						type: "Xã",
					},
					{
						level3_id: "16000",
						name: "Xã Hoằng Thanh",
						type: "Xã",
					},
					{
						level3_id: "16003",
						name: "Xã Hoằng Phụ",
						type: "Xã",
					},
					{
						level3_id: "16006",
						name: "Xã Hoằng Trường",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "400",
				name: "Huyện Hậu Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16012",
						name: "Thị trấn Hậu Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "16015",
						name: "Xã Đồng Lộc",
						type: "Xã",
					},
					{
						level3_id: "16018",
						name: "Xã Đại Lộc",
						type: "Xã",
					},
					{
						level3_id: "16021",
						name: "Xã Triệu Lộc",
						type: "Xã",
					},
					{
						level3_id: "16027",
						name: "Xã Tiến Lộc",
						type: "Xã",
					},
					{
						level3_id: "16030",
						name: "Xã Lộc Sơn",
						type: "Xã",
					},
					{
						level3_id: "16033",
						name: "Xã Cầu Lộc",
						type: "Xã",
					},
					{
						level3_id: "16036",
						name: "Xã Thành Lộc",
						type: "Xã",
					},
					{
						level3_id: "16039",
						name: "Xã Tuy Lộc",
						type: "Xã",
					},
					{
						level3_id: "16042",
						name: "Xã Phong Lộc",
						type: "Xã",
					},
					{
						level3_id: "16045",
						name: "Xã Mỹ Lộc",
						type: "Xã",
					},
					{
						level3_id: "16048",
						name: "Xã Thuần Lộc",
						type: "Xã",
					},
					{
						level3_id: "16057",
						name: "Xã Xuân Lộc",
						type: "Xã",
					},
					{
						level3_id: "16063",
						name: "Xã Hoa Lộc",
						type: "Xã",
					},
					{
						level3_id: "16066",
						name: "Xã Liên Lộc",
						type: "Xã",
					},
					{
						level3_id: "16069",
						name: "Xã Quang Lộc",
						type: "Xã",
					},
					{
						level3_id: "16072",
						name: "Xã Phú Lộc",
						type: "Xã",
					},
					{
						level3_id: "16075",
						name: "Xã Hòa Lộc",
						type: "Xã",
					},
					{
						level3_id: "16078",
						name: "Xã Minh Lộc",
						type: "Xã",
					},
					{
						level3_id: "16081",
						name: "Xã Hưng Lộc",
						type: "Xã",
					},
					{
						level3_id: "16084",
						name: "Xã Hải Lộc",
						type: "Xã",
					},
					{
						level3_id: "16087",
						name: "Xã Đa Lộc",
						type: "Xã",
					},
					{
						level3_id: "16090",
						name: "Xã Ngư Lộc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "401",
				name: "Huyện Nga Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16093",
						name: "Thị trấn Nga Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "16096",
						name: "Xã Ba Đình",
						type: "Xã",
					},
					{
						level3_id: "16099",
						name: "Xã Nga Vịnh",
						type: "Xã",
					},
					{
						level3_id: "16102",
						name: "Xã Nga Văn",
						type: "Xã",
					},
					{
						level3_id: "16105",
						name: "Xã Nga Thiện",
						type: "Xã",
					},
					{
						level3_id: "16108",
						name: "Xã Nga Tiến",
						type: "Xã",
					},
					{
						level3_id: "16114",
						name: "Xã Nga Phượng",
						type: "Xã",
					},
					{
						level3_id: "16117",
						name: "Xã Nga Trung",
						type: "Xã",
					},
					{
						level3_id: "16120",
						name: "Xã Nga Bạch",
						type: "Xã",
					},
					{
						level3_id: "16123",
						name: "Xã Nga Thanh",
						type: "Xã",
					},
					{
						level3_id: "16132",
						name: "Xã Nga Yên",
						type: "Xã",
					},
					{
						level3_id: "16135",
						name: "Xã Nga Giáp",
						type: "Xã",
					},
					{
						level3_id: "16138",
						name: "Xã Nga Hải",
						type: "Xã",
					},
					{
						level3_id: "16141",
						name: "Xã Nga Thành",
						type: "Xã",
					},
					{
						level3_id: "16144",
						name: "Xã Nga An",
						type: "Xã",
					},
					{
						level3_id: "16147",
						name: "Xã Nga Phú",
						type: "Xã",
					},
					{
						level3_id: "16150",
						name: "Xã Nga Điền",
						type: "Xã",
					},
					{
						level3_id: "16153",
						name: "Xã Nga Tân",
						type: "Xã",
					},
					{
						level3_id: "16156",
						name: "Xã Nga Thủy",
						type: "Xã",
					},
					{
						level3_id: "16159",
						name: "Xã Nga Liên",
						type: "Xã",
					},
					{
						level3_id: "16162",
						name: "Xã Nga Thái",
						type: "Xã",
					},
					{
						level3_id: "16165",
						name: "Xã Nga Thạch",
						type: "Xã",
					},
					{
						level3_id: "16168",
						name: "Xã Nga Thắng",
						type: "Xã",
					},
					{
						level3_id: "16171",
						name: "Xã Nga Trường",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "402",
				name: "Huyện Như Xuân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16174",
						name: "Thị trấn Yên Cát",
						type: "Thị trấn",
					},
					{
						level3_id: "16177",
						name: "Xã Bãi Trành",
						type: "Xã",
					},
					{
						level3_id: "16180",
						name: "Xã Xuân Hòa",
						type: "Xã",
					},
					{
						level3_id: "16183",
						name: "Xã Xuân Bình",
						type: "Xã",
					},
					{
						level3_id: "16186",
						name: "Xã Hóa Quỳ",
						type: "Xã",
					},
					{
						level3_id: "16195",
						name: "Xã Cát Vân",
						type: "Xã",
					},
					{
						level3_id: "16198",
						name: "Xã Cát Tân",
						type: "Xã",
					},
					{
						level3_id: "16201",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "16204",
						name: "Xã Bình Lương",
						type: "Xã",
					},
					{
						level3_id: "16207",
						name: "Xã Thanh Quân",
						type: "Xã",
					},
					{
						level3_id: "16210",
						name: "Xã Thanh Xuân",
						type: "Xã",
					},
					{
						level3_id: "16213",
						name: "Xã Thanh Hòa",
						type: "Xã",
					},
					{
						level3_id: "16216",
						name: "Xã Thanh Phong",
						type: "Xã",
					},
					{
						level3_id: "16219",
						name: "Xã Thanh Lâm",
						type: "Xã",
					},
					{
						level3_id: "16222",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "16225",
						name: "Xã Thượng Ninh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "403",
				name: "Huyện Như Thanh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16228",
						name: "Thị trấn Bến Sung",
						type: "Thị trấn",
					},
					{
						level3_id: "16231",
						name: "Xã Cán Khê",
						type: "Xã",
					},
					{
						level3_id: "16234",
						name: "Xã Xuân Du",
						type: "Xã",
					},
					{
						level3_id: "16240",
						name: "Xã Phượng Nghi",
						type: "Xã",
					},
					{
						level3_id: "16243",
						name: "Xã Mậu Lâm",
						type: "Xã",
					},
					{
						level3_id: "16246",
						name: "Xã Xuân Khang",
						type: "Xã",
					},
					{
						level3_id: "16249",
						name: "Xã Phú Nhuận",
						type: "Xã",
					},
					{
						level3_id: "16252",
						name: "Xã Hải Long",
						type: "Xã",
					},
					{
						level3_id: "16258",
						name: "Xã Xuân Thái",
						type: "Xã",
					},
					{
						level3_id: "16261",
						name: "Xã Xuân Phúc",
						type: "Xã",
					},
					{
						level3_id: "16264",
						name: "Xã Yên Thọ",
						type: "Xã",
					},
					{
						level3_id: "16267",
						name: "Xã Yên Lạc",
						type: "Xã",
					},
					{
						level3_id: "16273",
						name: "Xã Thanh Tân",
						type: "Xã",
					},
					{
						level3_id: "16276",
						name: "Xã Thanh Kỳ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "404",
				name: "Huyện Nông Cống",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16279",
						name: "Thị trấn Nông Cống",
						type: "Thị trấn",
					},
					{
						level3_id: "16282",
						name: "Xã Tân Phúc",
						type: "Xã",
					},
					{
						level3_id: "16285",
						name: "Xã Tân Thọ",
						type: "Xã",
					},
					{
						level3_id: "16288",
						name: "Xã Hoàng Sơn",
						type: "Xã",
					},
					{
						level3_id: "16291",
						name: "Xã Tân Khang",
						type: "Xã",
					},
					{
						level3_id: "16294",
						name: "Xã Hoàng Giang",
						type: "Xã",
					},
					{
						level3_id: "16297",
						name: "Xã Trung Chính",
						type: "Xã",
					},
					{
						level3_id: "16303",
						name: "Xã Trung Thành",
						type: "Xã",
					},
					{
						level3_id: "16309",
						name: "Xã Tế Thắng",
						type: "Xã",
					},
					{
						level3_id: "16315",
						name: "Xã Tế Lợi",
						type: "Xã",
					},
					{
						level3_id: "16318",
						name: "Xã Tế Nông",
						type: "Xã",
					},
					{
						level3_id: "16321",
						name: "Xã Minh Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "16324",
						name: "Xã Minh Khôi",
						type: "Xã",
					},
					{
						level3_id: "16327",
						name: "Xã Vạn Hòa",
						type: "Xã",
					},
					{
						level3_id: "16330",
						name: "Xã Trường Trung",
						type: "Xã",
					},
					{
						level3_id: "16333",
						name: "Xã Vạn Thắng",
						type: "Xã",
					},
					{
						level3_id: "16336",
						name: "Xã Trường Giang",
						type: "Xã",
					},
					{
						level3_id: "16339",
						name: "Xã Vạn Thiện",
						type: "Xã",
					},
					{
						level3_id: "16342",
						name: "Xã Thăng Long",
						type: "Xã",
					},
					{
						level3_id: "16345",
						name: "Xã Trường Minh",
						type: "Xã",
					},
					{
						level3_id: "16348",
						name: "Xã Trường Sơn",
						type: "Xã",
					},
					{
						level3_id: "16351",
						name: "Xã Thăng Bình",
						type: "Xã",
					},
					{
						level3_id: "16354",
						name: "Xã Công Liêm",
						type: "Xã",
					},
					{
						level3_id: "16357",
						name: "Xã Tượng Văn",
						type: "Xã",
					},
					{
						level3_id: "16360",
						name: "Xã Thăng Thọ",
						type: "Xã",
					},
					{
						level3_id: "16363",
						name: "Xã Tượng Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "16366",
						name: "Xã Tượng Sơn",
						type: "Xã",
					},
					{
						level3_id: "16369",
						name: "Xã Công Chính",
						type: "Xã",
					},
					{
						level3_id: "16375",
						name: "Xã Yên Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "405",
				name: "Huyện Đông Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16378",
						name: "Thị trấn Rừng Thông",
						type: "Thị trấn",
					},
					{
						level3_id: "16381",
						name: "Xã Đông Hoàng",
						type: "Xã",
					},
					{
						level3_id: "16384",
						name: "Xã Đông Ninh",
						type: "Xã",
					},
					{
						level3_id: "16390",
						name: "Xã Đông Hòa",
						type: "Xã",
					},
					{
						level3_id: "16393",
						name: "Xã Đông Yên",
						type: "Xã",
					},
					{
						level3_id: "16399",
						name: "Xã Đông Minh",
						type: "Xã",
					},
					{
						level3_id: "16402",
						name: "Xã Đông Thanh",
						type: "Xã",
					},
					{
						level3_id: "16405",
						name: "Xã Đông Tiến",
						type: "Xã",
					},
					{
						level3_id: "16408",
						name: "Xã Đông Khê",
						type: "Xã",
					},
					{
						level3_id: "16414",
						name: "Xã Đông Thịnh",
						type: "Xã",
					},
					{
						level3_id: "16417",
						name: "Xã Đông Văn",
						type: "Xã",
					},
					{
						level3_id: "16420",
						name: "Xã Đông Phú",
						type: "Xã",
					},
					{
						level3_id: "16423",
						name: "Xã Đông Nam",
						type: "Xã",
					},
					{
						level3_id: "16426",
						name: "Xã Đông Quang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "406",
				name: "Huyện Quảng Xương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16438",
						name: "Thị trấn Tân Phong",
						type: "Thị trấn",
					},
					{
						level3_id: "16447",
						name: "Xã Quảng Trạch",
						type: "Xã",
					},
					{
						level3_id: "16453",
						name: "Xã Quảng Đức",
						type: "Xã",
					},
					{
						level3_id: "16456",
						name: "Xã Quảng Định",
						type: "Xã",
					},
					{
						level3_id: "16462",
						name: "Xã Quảng Nhân",
						type: "Xã",
					},
					{
						level3_id: "16465",
						name: "Xã Quảng Ninh",
						type: "Xã",
					},
					{
						level3_id: "16468",
						name: "Xã Quảng Bình",
						type: "Xã",
					},
					{
						level3_id: "16471",
						name: "Xã Quảng Hợp",
						type: "Xã",
					},
					{
						level3_id: "16474",
						name: "Xã Quảng Văn",
						type: "Xã",
					},
					{
						level3_id: "16477",
						name: "Xã Quảng Long",
						type: "Xã",
					},
					{
						level3_id: "16480",
						name: "Xã Quảng Yên",
						type: "Xã",
					},
					{
						level3_id: "16483",
						name: "Xã Quảng Hòa",
						type: "Xã",
					},
					{
						level3_id: "16489",
						name: "Xã Quảng Khê",
						type: "Xã",
					},
					{
						level3_id: "16492",
						name: "Xã Quảng Trung",
						type: "Xã",
					},
					{
						level3_id: "16495",
						name: "Xã Quảng Chính",
						type: "Xã",
					},
					{
						level3_id: "16498",
						name: "Xã Quảng Ngọc",
						type: "Xã",
					},
					{
						level3_id: "16501",
						name: "Xã Quảng Trường",
						type: "Xã",
					},
					{
						level3_id: "16510",
						name: "Xã Quảng Phúc",
						type: "Xã",
					},
					{
						level3_id: "16519",
						name: "Xã Quảng Giao",
						type: "Xã",
					},
					{
						level3_id: "16540",
						name: "Xã Quảng Hải",
						type: "Xã",
					},
					{
						level3_id: "16543",
						name: "Xã Quảng Lưu",
						type: "Xã",
					},
					{
						level3_id: "16546",
						name: "Xã Quảng Lộc",
						type: "Xã",
					},
					{
						level3_id: "16549",
						name: "Xã Tiên Trang",
						type: "Xã",
					},
					{
						level3_id: "16552",
						name: "Xã Quảng Nham",
						type: "Xã",
					},
					{
						level3_id: "16555",
						name: "Xã Quảng Thạch",
						type: "Xã",
					},
					{
						level3_id: "16558",
						name: "Xã Quảng Thái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "407",
				name: "Thị xã Nghi Sơn",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "16561",
						name: "Phường Hải Hòa",
						type: "Phường",
					},
					{
						level3_id: "16564",
						name: "Phường Hải Châu",
						type: "Phường",
					},
					{
						level3_id: "16567",
						name: "Xã Thanh Thủy",
						type: "Xã",
					},
					{
						level3_id: "16570",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "16576",
						name: "Phường Hải Ninh",
						type: "Phường",
					},
					{
						level3_id: "16579",
						name: "Xã Anh Sơn",
						type: "Xã",
					},
					{
						level3_id: "16582",
						name: "Xã Ngọc Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "16585",
						name: "Phường Hải An",
						type: "Phường",
					},
					{
						level3_id: "16591",
						name: "Xã Các Sơn",
						type: "Xã",
					},
					{
						level3_id: "16594",
						name: "Phường Tân Dân",
						type: "Phường",
					},
					{
						level3_id: "16597",
						name: "Phường Hải Lĩnh",
						type: "Phường",
					},
					{
						level3_id: "16600",
						name: "Xã Định Hải",
						type: "Xã",
					},
					{
						level3_id: "16603",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "16606",
						name: "Phường Ninh Hải",
						type: "Phường",
					},
					{
						level3_id: "16609",
						name: "Phường Nguyên Bình",
						type: "Phường",
					},
					{
						level3_id: "16612",
						name: "Xã Hải Nhân",
						type: "Xã",
					},
					{
						level3_id: "16618",
						name: "Phường Bình Minh",
						type: "Phường",
					},
					{
						level3_id: "16621",
						name: "Phường Hải Thanh",
						type: "Phường",
					},
					{
						level3_id: "16624",
						name: "Xã Phú Lâm",
						type: "Xã",
					},
					{
						level3_id: "16627",
						name: "Phường Xuân Lâm",
						type: "Phường",
					},
					{
						level3_id: "16630",
						name: "Phường Trúc Lâm",
						type: "Phường",
					},
					{
						level3_id: "16633",
						name: "Phường Hải Bình",
						type: "Phường",
					},
					{
						level3_id: "16636",
						name: "Xã Tân Trường",
						type: "Xã",
					},
					{
						level3_id: "16639",
						name: "Xã Tùng Lâm",
						type: "Xã",
					},
					{
						level3_id: "16642",
						name: "Phường Tĩnh Hải",
						type: "Phường",
					},
					{
						level3_id: "16645",
						name: "Phường Mai Lâm",
						type: "Phường",
					},
					{
						level3_id: "16648",
						name: "Xã Trường Lâm",
						type: "Xã",
					},
					{
						level3_id: "16651",
						name: "Xã Hải Yến",
						type: "Xã",
					},
					{
						level3_id: "16654",
						name: "Phường Hải Thượng",
						type: "Phường",
					},
					{
						level3_id: "16657",
						name: "Xã Nghi Sơn",
						type: "Xã",
					},
					{
						level3_id: "16660",
						name: "Xã Hải Hà",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "40",
		name: "Tỉnh Nghệ An",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "412",
				name: "Thành phố Vinh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "16663",
						name: "Phường Đông Vĩnh",
						type: "Phường",
					},
					{
						level3_id: "16666",
						name: "Phường Hà Huy Tập",
						type: "Phường",
					},
					{
						level3_id: "16669",
						name: "Phường Lê Lợi",
						type: "Phường",
					},
					{
						level3_id: "16670",
						name: "Phường Quán Bàu",
						type: "Phường",
					},
					{
						level3_id: "16672",
						name: "Phường Hưng Bình",
						type: "Phường",
					},
					{
						level3_id: "16673",
						name: "Phường Hưng Phúc",
						type: "Phường",
					},
					{
						level3_id: "16675",
						name: "Phường Hưng Dũng",
						type: "Phường",
					},
					{
						level3_id: "16678",
						name: "Phường Cửa Nam",
						type: "Phường",
					},
					{
						level3_id: "16681",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "16684",
						name: "Phường Đội Cung",
						type: "Phường",
					},
					{
						level3_id: "16687",
						name: "Phường Lê Mao",
						type: "Phường",
					},
					{
						level3_id: "16690",
						name: "Phường Trường Thi",
						type: "Phường",
					},
					{
						level3_id: "16693",
						name: "Phường Bến Thủy",
						type: "Phường",
					},
					{
						level3_id: "16696",
						name: "Phường Hồng Sơn",
						type: "Phường",
					},
					{
						level3_id: "16699",
						name: "Phường Trung Đô",
						type: "Phường",
					},
					{
						level3_id: "16702",
						name: "Xã Nghi Phú",
						type: "Xã",
					},
					{
						level3_id: "16705",
						name: "Xã Hưng Đông",
						type: "Xã",
					},
					{
						level3_id: "16708",
						name: "Xã Hưng Lộc",
						type: "Xã",
					},
					{
						level3_id: "16711",
						name: "Xã Hưng Hòa",
						type: "Xã",
					},
					{
						level3_id: "16714",
						name: "Phường Vinh Tân",
						type: "Phường",
					},
					{
						level3_id: "17908",
						name: "Xã Nghi Liên",
						type: "Xã",
					},
					{
						level3_id: "17914",
						name: "Xã Nghi Ân",
						type: "Xã",
					},
					{
						level3_id: "17920",
						name: "Xã Nghi Kim",
						type: "Xã",
					},
					{
						level3_id: "17923",
						name: "Xã Nghi Đức",
						type: "Xã",
					},
					{
						level3_id: "18013",
						name: "Xã Hưng Chính",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "413",
				name: "Thị xã Cửa Lò",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "16717",
						name: "Phường Nghi Thuỷ",
						type: "Phường",
					},
					{
						level3_id: "16720",
						name: "Phường Nghi Tân",
						type: "Phường",
					},
					{
						level3_id: "16723",
						name: "Phường Thu Thuỷ",
						type: "Phường",
					},
					{
						level3_id: "16726",
						name: "Phường Nghi Hòa",
						type: "Phường",
					},
					{
						level3_id: "16729",
						name: "Phường Nghi Hải",
						type: "Phường",
					},
					{
						level3_id: "16732",
						name: "Phường Nghi Hương",
						type: "Phường",
					},
					{
						level3_id: "16735",
						name: "Phường Nghi Thu",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "414",
				name: "Thị xã Thái Hoà",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "16939",
						name: "Phường Hoà Hiếu",
						type: "Phường",
					},
					{
						level3_id: "16993",
						name: "Phường Quang Phong",
						type: "Phường",
					},
					{
						level3_id: "16994",
						name: "Phường Quang Tiến",
						type: "Phường",
					},
					{
						level3_id: "17003",
						name: "Phường Long Sơn",
						type: "Phường",
					},
					{
						level3_id: "17005",
						name: "Xã Nghĩa Tiến",
						type: "Xã",
					},
					{
						level3_id: "17008",
						name: "Xã Nghĩa Mỹ",
						type: "Xã",
					},
					{
						level3_id: "17011",
						name: "Xã Tây Hiếu",
						type: "Xã",
					},
					{
						level3_id: "17014",
						name: "Xã Nghĩa Thuận",
						type: "Xã",
					},
					{
						level3_id: "17017",
						name: "Xã Đông Hiếu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "415",
				name: "Huyện Quế Phong",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16738",
						name: "Thị trấn Kim Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "16741",
						name: "Xã Thông Thụ",
						type: "Xã",
					},
					{
						level3_id: "16744",
						name: "Xã Đồng Văn",
						type: "Xã",
					},
					{
						level3_id: "16747",
						name: "Xã Hạnh Dịch",
						type: "Xã",
					},
					{
						level3_id: "16750",
						name: "Xã Tiền Phong",
						type: "Xã",
					},
					{
						level3_id: "16753",
						name: "Xã Nậm Giải",
						type: "Xã",
					},
					{
						level3_id: "16756",
						name: "Xã Tri Lễ",
						type: "Xã",
					},
					{
						level3_id: "16759",
						name: "Xã Châu Kim",
						type: "Xã",
					},
					{
						level3_id: "16763",
						name: "Xã Mường Nọc",
						type: "Xã",
					},
					{
						level3_id: "16765",
						name: "Xã Châu Thôn",
						type: "Xã",
					},
					{
						level3_id: "16768",
						name: "Xã Nậm Nhoóng",
						type: "Xã",
					},
					{
						level3_id: "16771",
						name: "Xã Quang Phong",
						type: "Xã",
					},
					{
						level3_id: "16774",
						name: "Xã Căm Muộn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "416",
				name: "Huyện Quỳ Châu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16777",
						name: "Thị trấn Tân Lạc",
						type: "Thị trấn",
					},
					{
						level3_id: "16780",
						name: "Xã Châu Bính",
						type: "Xã",
					},
					{
						level3_id: "16783",
						name: "Xã Châu Thuận",
						type: "Xã",
					},
					{
						level3_id: "16786",
						name: "Xã Châu Hội",
						type: "Xã",
					},
					{
						level3_id: "16789",
						name: "Xã Châu Nga",
						type: "Xã",
					},
					{
						level3_id: "16792",
						name: "Xã Châu Tiến",
						type: "Xã",
					},
					{
						level3_id: "16795",
						name: "Xã Châu Hạnh",
						type: "Xã",
					},
					{
						level3_id: "16798",
						name: "Xã Châu Thắng",
						type: "Xã",
					},
					{
						level3_id: "16801",
						name: "Xã Châu Phong",
						type: "Xã",
					},
					{
						level3_id: "16804",
						name: "Xã Châu Bình",
						type: "Xã",
					},
					{
						level3_id: "16807",
						name: "Xã Châu Hoàn",
						type: "Xã",
					},
					{
						level3_id: "16810",
						name: "Xã Diên Lãm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "417",
				name: "Huyện Kỳ Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16813",
						name: "Thị trấn Mường Xén",
						type: "Thị trấn",
					},
					{
						level3_id: "16816",
						name: "Xã Mỹ Lý",
						type: "Xã",
					},
					{
						level3_id: "16819",
						name: "Xã Bắc Lý",
						type: "Xã",
					},
					{
						level3_id: "16822",
						name: "Xã Keng Đu",
						type: "Xã",
					},
					{
						level3_id: "16825",
						name: "Xã Đoọc Mạy",
						type: "Xã",
					},
					{
						level3_id: "16828",
						name: "Xã Huồi Tụ",
						type: "Xã",
					},
					{
						level3_id: "16831",
						name: "Xã Mường Lống",
						type: "Xã",
					},
					{
						level3_id: "16834",
						name: "Xã Na Loi",
						type: "Xã",
					},
					{
						level3_id: "16837",
						name: "Xã Nậm Cắn",
						type: "Xã",
					},
					{
						level3_id: "16840",
						name: "Xã Bảo Nam",
						type: "Xã",
					},
					{
						level3_id: "16843",
						name: "Xã Phà Đánh",
						type: "Xã",
					},
					{
						level3_id: "16846",
						name: "Xã Bảo Thắng",
						type: "Xã",
					},
					{
						level3_id: "16849",
						name: "Xã Hữu Lập",
						type: "Xã",
					},
					{
						level3_id: "16852",
						name: "Xã Tà Cạ",
						type: "Xã",
					},
					{
						level3_id: "16855",
						name: "Xã Chiêu Lưu",
						type: "Xã",
					},
					{
						level3_id: "16858",
						name: "Xã Mường Típ",
						type: "Xã",
					},
					{
						level3_id: "16861",
						name: "Xã Hữu Kiệm",
						type: "Xã",
					},
					{
						level3_id: "16864",
						name: "Xã Tây Sơn",
						type: "Xã",
					},
					{
						level3_id: "16867",
						name: "Xã Mường Ải",
						type: "Xã",
					},
					{
						level3_id: "16870",
						name: "Xã Na Ngoi",
						type: "Xã",
					},
					{
						level3_id: "16873",
						name: "Xã Nậm Càn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "418",
				name: "Huyện Tương Dương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16876",
						name: "Thị trấn Thạch Giám",
						type: "Thị trấn",
					},
					{
						level3_id: "16879",
						name: "Xã Mai Sơn",
						type: "Xã",
					},
					{
						level3_id: "16882",
						name: "Xã Nhôn Mai",
						type: "Xã",
					},
					{
						level3_id: "16885",
						name: "Xã Hữu Khuông",
						type: "Xã",
					},
					{
						level3_id: "16900",
						name: "Xã Yên Tĩnh",
						type: "Xã",
					},
					{
						level3_id: "16903",
						name: "Xã Nga My",
						type: "Xã",
					},
					{
						level3_id: "16904",
						name: "Xã Xiêng My",
						type: "Xã",
					},
					{
						level3_id: "16906",
						name: "Xã Lưỡng Minh",
						type: "Xã",
					},
					{
						level3_id: "16909",
						name: "Xã Yên Hòa",
						type: "Xã",
					},
					{
						level3_id: "16912",
						name: "Xã Yên Na",
						type: "Xã",
					},
					{
						level3_id: "16915",
						name: "Xã Lưu Kiền",
						type: "Xã",
					},
					{
						level3_id: "16921",
						name: "Xã Xá Lượng",
						type: "Xã",
					},
					{
						level3_id: "16924",
						name: "Xã Tam Thái",
						type: "Xã",
					},
					{
						level3_id: "16927",
						name: "Xã Tam Đình",
						type: "Xã",
					},
					{
						level3_id: "16930",
						name: "Xã Yên Thắng",
						type: "Xã",
					},
					{
						level3_id: "16933",
						name: "Xã Tam Quang",
						type: "Xã",
					},
					{
						level3_id: "16936",
						name: "Xã Tam Hợp",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "419",
				name: "Huyện Nghĩa Đàn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "16941",
						name: "Thị trấn Nghĩa Đàn",
						type: "Thị trấn",
					},
					{
						level3_id: "16942",
						name: "Xã Nghĩa Mai",
						type: "Xã",
					},
					{
						level3_id: "16945",
						name: "Xã Nghĩa Yên",
						type: "Xã",
					},
					{
						level3_id: "16948",
						name: "Xã Nghĩa Lạc",
						type: "Xã",
					},
					{
						level3_id: "16951",
						name: "Xã Nghĩa Lâm",
						type: "Xã",
					},
					{
						level3_id: "16954",
						name: "Xã Nghĩa Sơn",
						type: "Xã",
					},
					{
						level3_id: "16957",
						name: "Xã Nghĩa Lợi",
						type: "Xã",
					},
					{
						level3_id: "16960",
						name: "Xã Nghĩa Bình",
						type: "Xã",
					},
					{
						level3_id: "16963",
						name: "Xã Nghĩa Thọ",
						type: "Xã",
					},
					{
						level3_id: "16966",
						name: "Xã Nghĩa Minh",
						type: "Xã",
					},
					{
						level3_id: "16969",
						name: "Xã Nghĩa Phú",
						type: "Xã",
					},
					{
						level3_id: "16972",
						name: "Xã Nghĩa Hưng",
						type: "Xã",
					},
					{
						level3_id: "16975",
						name: "Xã Nghĩa Hồng",
						type: "Xã",
					},
					{
						level3_id: "16978",
						name: "Xã Nghĩa Thịnh",
						type: "Xã",
					},
					{
						level3_id: "16981",
						name: "Xã Nghĩa Trung",
						type: "Xã",
					},
					{
						level3_id: "16984",
						name: "Xã Nghĩa Hội",
						type: "Xã",
					},
					{
						level3_id: "16987",
						name: "Xã Nghĩa Thành",
						type: "Xã",
					},
					{
						level3_id: "16996",
						name: "Xã Nghĩa Hiếu",
						type: "Xã",
					},
					{
						level3_id: "17020",
						name: "Xã Nghĩa Đức",
						type: "Xã",
					},
					{
						level3_id: "17023",
						name: "Xã Nghĩa An",
						type: "Xã",
					},
					{
						level3_id: "17026",
						name: "Xã Nghĩa Long",
						type: "Xã",
					},
					{
						level3_id: "17029",
						name: "Xã Nghĩa Lộc",
						type: "Xã",
					},
					{
						level3_id: "17032",
						name: "Xã Nghĩa Khánh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "420",
				name: "Huyện Quỳ Hợp",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17035",
						name: "Thị trấn Quỳ Hợp",
						type: "Thị trấn",
					},
					{
						level3_id: "17038",
						name: "Xã Yên Hợp",
						type: "Xã",
					},
					{
						level3_id: "17041",
						name: "Xã Châu Tiến",
						type: "Xã",
					},
					{
						level3_id: "17044",
						name: "Xã Châu Hồng",
						type: "Xã",
					},
					{
						level3_id: "17047",
						name: "Xã Đồng Hợp",
						type: "Xã",
					},
					{
						level3_id: "17050",
						name: "Xã Châu Thành",
						type: "Xã",
					},
					{
						level3_id: "17053",
						name: "Xã Liên Hợp",
						type: "Xã",
					},
					{
						level3_id: "17056",
						name: "Xã Châu Lộc",
						type: "Xã",
					},
					{
						level3_id: "17059",
						name: "Xã Tam Hợp",
						type: "Xã",
					},
					{
						level3_id: "17062",
						name: "Xã Châu Cường",
						type: "Xã",
					},
					{
						level3_id: "17065",
						name: "Xã Châu Quang",
						type: "Xã",
					},
					{
						level3_id: "17068",
						name: "Xã Thọ Hợp",
						type: "Xã",
					},
					{
						level3_id: "17071",
						name: "Xã Minh Hợp",
						type: "Xã",
					},
					{
						level3_id: "17074",
						name: "Xã Nghĩa Xuân",
						type: "Xã",
					},
					{
						level3_id: "17077",
						name: "Xã Châu Thái",
						type: "Xã",
					},
					{
						level3_id: "17080",
						name: "Xã Châu Đình",
						type: "Xã",
					},
					{
						level3_id: "17083",
						name: "Xã Văn Lợi",
						type: "Xã",
					},
					{
						level3_id: "17086",
						name: "Xã Nam Sơn",
						type: "Xã",
					},
					{
						level3_id: "17089",
						name: "Xã Châu Lý",
						type: "Xã",
					},
					{
						level3_id: "17092",
						name: "Xã Hạ Sơn",
						type: "Xã",
					},
					{
						level3_id: "17095",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "421",
				name: "Huyện Quỳnh Lưu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17098",
						name: "Thị trấn Cầu Giát",
						type: "Thị trấn",
					},
					{
						level3_id: "17101",
						name: "Xã Quỳnh Thắng",
						type: "Xã",
					},
					{
						level3_id: "17119",
						name: "Xã Quỳnh Tân",
						type: "Xã",
					},
					{
						level3_id: "17122",
						name: "Xã Quỳnh Châu",
						type: "Xã",
					},
					{
						level3_id: "17140",
						name: "Xã Tân Sơn",
						type: "Xã",
					},
					{
						level3_id: "17143",
						name: "Xã Quỳnh Văn",
						type: "Xã",
					},
					{
						level3_id: "17146",
						name: "Xã Ngọc Sơn",
						type: "Xã",
					},
					{
						level3_id: "17149",
						name: "Xã Quỳnh Tam",
						type: "Xã",
					},
					{
						level3_id: "17152",
						name: "Xã Quỳnh Hoa",
						type: "Xã",
					},
					{
						level3_id: "17155",
						name: "Xã Quỳnh Thạch",
						type: "Xã",
					},
					{
						level3_id: "17158",
						name: "Xã Quỳnh Bảng",
						type: "Xã",
					},
					{
						level3_id: "17161",
						name: "Xã Quỳnh Mỹ",
						type: "Xã",
					},
					{
						level3_id: "17164",
						name: "Xã Quỳnh Thanh",
						type: "Xã",
					},
					{
						level3_id: "17167",
						name: "Xã Quỳnh Hậu",
						type: "Xã",
					},
					{
						level3_id: "17170",
						name: "Xã Quỳnh Lâm",
						type: "Xã",
					},
					{
						level3_id: "17173",
						name: "Xã Quỳnh Đôi",
						type: "Xã",
					},
					{
						level3_id: "17176",
						name: "Xã Quỳnh Lương",
						type: "Xã",
					},
					{
						level3_id: "17179",
						name: "Xã Quỳnh Hồng",
						type: "Xã",
					},
					{
						level3_id: "17182",
						name: "Xã Quỳnh Yên",
						type: "Xã",
					},
					{
						level3_id: "17185",
						name: "Xã Quỳnh Bá",
						type: "Xã",
					},
					{
						level3_id: "17188",
						name: "Xã Quỳnh Minh",
						type: "Xã",
					},
					{
						level3_id: "17191",
						name: "Xã Quỳnh Diễn",
						type: "Xã",
					},
					{
						level3_id: "17194",
						name: "Xã Quỳnh Hưng",
						type: "Xã",
					},
					{
						level3_id: "17197",
						name: "Xã Quỳnh Giang",
						type: "Xã",
					},
					{
						level3_id: "17200",
						name: "Xã Quỳnh Ngọc",
						type: "Xã",
					},
					{
						level3_id: "17203",
						name: "Xã Quỳnh Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "17206",
						name: "Xã An Hòa",
						type: "Xã",
					},
					{
						level3_id: "17209",
						name: "Xã Tiến Thủy",
						type: "Xã",
					},
					{
						level3_id: "17212",
						name: "Xã Sơn Hải",
						type: "Xã",
					},
					{
						level3_id: "17215",
						name: "Xã Quỳnh Thọ",
						type: "Xã",
					},
					{
						level3_id: "17218",
						name: "Xã Quỳnh Thuận",
						type: "Xã",
					},
					{
						level3_id: "17221",
						name: "Xã Quỳnh Long",
						type: "Xã",
					},
					{
						level3_id: "17224",
						name: "Xã Tân Thắng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "422",
				name: "Huyện Con Cuông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17227",
						name: "Thị trấn Con Cuông",
						type: "Thị trấn",
					},
					{
						level3_id: "17230",
						name: "Xã Bình Chuẩn",
						type: "Xã",
					},
					{
						level3_id: "17233",
						name: "Xã Lạng Khê",
						type: "Xã",
					},
					{
						level3_id: "17236",
						name: "Xã Cam Lâm",
						type: "Xã",
					},
					{
						level3_id: "17239",
						name: "Xã Thạch Ngàn",
						type: "Xã",
					},
					{
						level3_id: "17242",
						name: "Xã Đôn Phục",
						type: "Xã",
					},
					{
						level3_id: "17245",
						name: "Xã Mậu Đức",
						type: "Xã",
					},
					{
						level3_id: "17248",
						name: "Xã Châu Khê",
						type: "Xã",
					},
					{
						level3_id: "17251",
						name: "Xã Chi Khê",
						type: "Xã",
					},
					{
						level3_id: "17254",
						name: "Xã Bồng Khê",
						type: "Xã",
					},
					{
						level3_id: "17257",
						name: "Xã Yên Khê",
						type: "Xã",
					},
					{
						level3_id: "17260",
						name: "Xã Lục Dạ",
						type: "Xã",
					},
					{
						level3_id: "17263",
						name: "Xã Môn Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "423",
				name: "Huyện Tân Kỳ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17266",
						name: "Thị trấn Tân Kỳ",
						type: "Thị trấn",
					},
					{
						level3_id: "17269",
						name: "Xã Tân Hợp",
						type: "Xã",
					},
					{
						level3_id: "17272",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "17275",
						name: "Xã Tân Xuân",
						type: "Xã",
					},
					{
						level3_id: "17278",
						name: "Xã Giai Xuân",
						type: "Xã",
					},
					{
						level3_id: "17281",
						name: "Xã Nghĩa Bình",
						type: "Xã",
					},
					{
						level3_id: "17284",
						name: "Xã Nghĩa Đồng",
						type: "Xã",
					},
					{
						level3_id: "17287",
						name: "Xã Đồng Văn",
						type: "Xã",
					},
					{
						level3_id: "17290",
						name: "Xã Nghĩa Thái",
						type: "Xã",
					},
					{
						level3_id: "17293",
						name: "Xã Nghĩa Hợp",
						type: "Xã",
					},
					{
						level3_id: "17296",
						name: "Xã Nghĩa Hoàn",
						type: "Xã",
					},
					{
						level3_id: "17299",
						name: "Xã Nghĩa Phúc",
						type: "Xã",
					},
					{
						level3_id: "17302",
						name: "Xã Tiên Kỳ",
						type: "Xã",
					},
					{
						level3_id: "17305",
						name: "Xã Tân An",
						type: "Xã",
					},
					{
						level3_id: "17308",
						name: "Xã Nghĩa Dũng",
						type: "Xã",
					},
					{
						level3_id: "17311",
						name: "Xã Tân Long",
						type: "Xã",
					},
					{
						level3_id: "17314",
						name: "Xã Kỳ Sơn",
						type: "Xã",
					},
					{
						level3_id: "17317",
						name: "Xã Hương Sơn",
						type: "Xã",
					},
					{
						level3_id: "17320",
						name: "Xã Kỳ Tân",
						type: "Xã",
					},
					{
						level3_id: "17323",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "17325",
						name: "Xã Tân Hương",
						type: "Xã",
					},
					{
						level3_id: "17326",
						name: "Xã Nghĩa Hành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "424",
				name: "Huyện Anh Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17329",
						name: "Thị trấn Anh Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "17332",
						name: "Xã Thọ Sơn",
						type: "Xã",
					},
					{
						level3_id: "17335",
						name: "Xã Thành Sơn",
						type: "Xã",
					},
					{
						level3_id: "17338",
						name: "Xã Bình Sơn",
						type: "Xã",
					},
					{
						level3_id: "17341",
						name: "Xã Tam Sơn",
						type: "Xã",
					},
					{
						level3_id: "17344",
						name: "Xã Đỉnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "17347",
						name: "Xã Hùng Sơn",
						type: "Xã",
					},
					{
						level3_id: "17350",
						name: "Xã Cẩm Sơn",
						type: "Xã",
					},
					{
						level3_id: "17353",
						name: "Xã Đức Sơn",
						type: "Xã",
					},
					{
						level3_id: "17356",
						name: "Xã Tường Sơn",
						type: "Xã",
					},
					{
						level3_id: "17357",
						name: "Xã Hoa Sơn",
						type: "Xã",
					},
					{
						level3_id: "17359",
						name: "Xã Tào Sơn",
						type: "Xã",
					},
					{
						level3_id: "17362",
						name: "Xã Vĩnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "17365",
						name: "Xã Lạng Sơn",
						type: "Xã",
					},
					{
						level3_id: "17368",
						name: "Xã Hội Sơn",
						type: "Xã",
					},
					{
						level3_id: "17371",
						name: "Xã Thạch Sơn",
						type: "Xã",
					},
					{
						level3_id: "17374",
						name: "Xã Phúc Sơn",
						type: "Xã",
					},
					{
						level3_id: "17377",
						name: "Xã Long Sơn",
						type: "Xã",
					},
					{
						level3_id: "17380",
						name: "Xã Khai Sơn",
						type: "Xã",
					},
					{
						level3_id: "17383",
						name: "Xã Lĩnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "17386",
						name: "Xã Cao Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "425",
				name: "Huyện Diễn Châu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17389",
						name: "Thị trấn Diễn Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "17392",
						name: "Xã Diễn Lâm",
						type: "Xã",
					},
					{
						level3_id: "17395",
						name: "Xã Diễn Đoài",
						type: "Xã",
					},
					{
						level3_id: "17398",
						name: "Xã Diễn Trường",
						type: "Xã",
					},
					{
						level3_id: "17401",
						name: "Xã Diễn Yên",
						type: "Xã",
					},
					{
						level3_id: "17404",
						name: "Xã Diễn Hoàng",
						type: "Xã",
					},
					{
						level3_id: "17407",
						name: "Xã Diễn Hùng",
						type: "Xã",
					},
					{
						level3_id: "17410",
						name: "Xã Diễn Mỹ",
						type: "Xã",
					},
					{
						level3_id: "17413",
						name: "Xã Diễn Hồng",
						type: "Xã",
					},
					{
						level3_id: "17416",
						name: "Xã Diễn Phong",
						type: "Xã",
					},
					{
						level3_id: "17419",
						name: "Xã Diễn Hải",
						type: "Xã",
					},
					{
						level3_id: "17422",
						name: "Xã Diễn Tháp",
						type: "Xã",
					},
					{
						level3_id: "17425",
						name: "Xã Diễn Liên",
						type: "Xã",
					},
					{
						level3_id: "17428",
						name: "Xã Diễn Vạn",
						type: "Xã",
					},
					{
						level3_id: "17431",
						name: "Xã Diễn Kim",
						type: "Xã",
					},
					{
						level3_id: "17434",
						name: "Xã Diễn Kỷ",
						type: "Xã",
					},
					{
						level3_id: "17437",
						name: "Xã Diễn Xuân",
						type: "Xã",
					},
					{
						level3_id: "17440",
						name: "Xã Diễn Thái",
						type: "Xã",
					},
					{
						level3_id: "17443",
						name: "Xã Diễn Đồng",
						type: "Xã",
					},
					{
						level3_id: "17446",
						name: "Xã Diễn Bích",
						type: "Xã",
					},
					{
						level3_id: "17449",
						name: "Xã Diễn Hạnh",
						type: "Xã",
					},
					{
						level3_id: "17452",
						name: "Xã Diễn Ngọc",
						type: "Xã",
					},
					{
						level3_id: "17455",
						name: "Xã Diễn Quảng",
						type: "Xã",
					},
					{
						level3_id: "17458",
						name: "Xã Diễn Nguyên",
						type: "Xã",
					},
					{
						level3_id: "17461",
						name: "Xã Diễn Hoa",
						type: "Xã",
					},
					{
						level3_id: "17464",
						name: "Xã Diễn Thành",
						type: "Xã",
					},
					{
						level3_id: "17467",
						name: "Xã Diễn Phúc",
						type: "Xã",
					},
					{
						level3_id: "17476",
						name: "Xã Diễn Cát",
						type: "Xã",
					},
					{
						level3_id: "17479",
						name: "Xã Diễn Thịnh",
						type: "Xã",
					},
					{
						level3_id: "17482",
						name: "Xã Diễn Tân",
						type: "Xã",
					},
					{
						level3_id: "17485",
						name: "Xã Minh Châu",
						type: "Xã",
					},
					{
						level3_id: "17488",
						name: "Xã Diễn Thọ",
						type: "Xã",
					},
					{
						level3_id: "17491",
						name: "Xã Diễn Lợi",
						type: "Xã",
					},
					{
						level3_id: "17494",
						name: "Xã Diễn Lộc",
						type: "Xã",
					},
					{
						level3_id: "17497",
						name: "Xã Diễn Trung",
						type: "Xã",
					},
					{
						level3_id: "17500",
						name: "Xã Diễn An",
						type: "Xã",
					},
					{
						level3_id: "17503",
						name: "Xã Diễn Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "426",
				name: "Huyện Yên Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17506",
						name: "Thị trấn Yên Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "17509",
						name: "Xã Mã Thành",
						type: "Xã",
					},
					{
						level3_id: "17510",
						name: "Xã Tiến Thành",
						type: "Xã",
					},
					{
						level3_id: "17512",
						name: "Xã Lăng Thành",
						type: "Xã",
					},
					{
						level3_id: "17515",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "17518",
						name: "Xã Đức Thành",
						type: "Xã",
					},
					{
						level3_id: "17521",
						name: "Xã Kim Thành",
						type: "Xã",
					},
					{
						level3_id: "17524",
						name: "Xã Hậu Thành",
						type: "Xã",
					},
					{
						level3_id: "17525",
						name: "Xã Hùng Thành",
						type: "Xã",
					},
					{
						level3_id: "17527",
						name: "Xã Đô Thành",
						type: "Xã",
					},
					{
						level3_id: "17530",
						name: "Xã Thọ Thành",
						type: "Xã",
					},
					{
						level3_id: "17533",
						name: "Xã Quang Thành",
						type: "Xã",
					},
					{
						level3_id: "17536",
						name: "Xã Tây Thành",
						type: "Xã",
					},
					{
						level3_id: "17539",
						name: "Xã Phúc Thành",
						type: "Xã",
					},
					{
						level3_id: "17542",
						name: "Xã Hồng Thành",
						type: "Xã",
					},
					{
						level3_id: "17545",
						name: "Xã Đồng Thành",
						type: "Xã",
					},
					{
						level3_id: "17548",
						name: "Xã Phú Thành",
						type: "Xã",
					},
					{
						level3_id: "17551",
						name: "Xã Hoa Thành",
						type: "Xã",
					},
					{
						level3_id: "17554",
						name: "Xã Tăng Thành",
						type: "Xã",
					},
					{
						level3_id: "17557",
						name: "Xã Văn Thành",
						type: "Xã",
					},
					{
						level3_id: "17560",
						name: "Xã Thịnh Thành",
						type: "Xã",
					},
					{
						level3_id: "17563",
						name: "Xã Hợp Thành",
						type: "Xã",
					},
					{
						level3_id: "17566",
						name: "Xã Xuân Thành",
						type: "Xã",
					},
					{
						level3_id: "17569",
						name: "Xã Bắc Thành",
						type: "Xã",
					},
					{
						level3_id: "17572",
						name: "Xã Nhân Thành",
						type: "Xã",
					},
					{
						level3_id: "17575",
						name: "Xã Trung Thành",
						type: "Xã",
					},
					{
						level3_id: "17578",
						name: "Xã Long Thành",
						type: "Xã",
					},
					{
						level3_id: "17581",
						name: "Xã Minh Thành",
						type: "Xã",
					},
					{
						level3_id: "17584",
						name: "Xã Nam Thành",
						type: "Xã",
					},
					{
						level3_id: "17587",
						name: "Xã Vĩnh Thành",
						type: "Xã",
					},
					{
						level3_id: "17590",
						name: "Xã Lý Thành",
						type: "Xã",
					},
					{
						level3_id: "17593",
						name: "Xã Khánh Thành",
						type: "Xã",
					},
					{
						level3_id: "17596",
						name: "Xã Viên Thành",
						type: "Xã",
					},
					{
						level3_id: "17599",
						name: "Xã Đại Thành",
						type: "Xã",
					},
					{
						level3_id: "17602",
						name: "Xã Liên Thành",
						type: "Xã",
					},
					{
						level3_id: "17605",
						name: "Xã Bảo Thành",
						type: "Xã",
					},
					{
						level3_id: "17608",
						name: "Xã Mỹ Thành",
						type: "Xã",
					},
					{
						level3_id: "17611",
						name: "Xã Công Thành",
						type: "Xã",
					},
					{
						level3_id: "17614",
						name: "Xã Sơn Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "427",
				name: "Huyện Đô Lương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17617",
						name: "Thị trấn Đô Lương",
						type: "Thị trấn",
					},
					{
						level3_id: "17619",
						name: "Xã Giang Sơn Đông",
						type: "Xã",
					},
					{
						level3_id: "17620",
						name: "Xã Giang Sơn Tây",
						type: "Xã",
					},
					{
						level3_id: "17623",
						name: "Xã Lam Sơn",
						type: "Xã",
					},
					{
						level3_id: "17626",
						name: "Xã Bồi Sơn",
						type: "Xã",
					},
					{
						level3_id: "17629",
						name: "Xã Hồng Sơn",
						type: "Xã",
					},
					{
						level3_id: "17632",
						name: "Xã Bài Sơn",
						type: "Xã",
					},
					{
						level3_id: "17635",
						name: "Xã Ngọc Sơn",
						type: "Xã",
					},
					{
						level3_id: "17638",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "17641",
						name: "Xã Tràng Sơn",
						type: "Xã",
					},
					{
						level3_id: "17644",
						name: "Xã Thượng Sơn",
						type: "Xã",
					},
					{
						level3_id: "17647",
						name: "Xã Hòa Sơn",
						type: "Xã",
					},
					{
						level3_id: "17650",
						name: "Xã Đặng Sơn",
						type: "Xã",
					},
					{
						level3_id: "17653",
						name: "Xã Đông Sơn",
						type: "Xã",
					},
					{
						level3_id: "17656",
						name: "Xã Nam Sơn",
						type: "Xã",
					},
					{
						level3_id: "17659",
						name: "Xã Lưu Sơn",
						type: "Xã",
					},
					{
						level3_id: "17662",
						name: "Xã Yên Sơn",
						type: "Xã",
					},
					{
						level3_id: "17665",
						name: "Xã Văn Sơn",
						type: "Xã",
					},
					{
						level3_id: "17668",
						name: "Xã Đà Sơn",
						type: "Xã",
					},
					{
						level3_id: "17671",
						name: "Xã Lạc Sơn",
						type: "Xã",
					},
					{
						level3_id: "17674",
						name: "Xã Tân Sơn",
						type: "Xã",
					},
					{
						level3_id: "17677",
						name: "Xã Thái Sơn",
						type: "Xã",
					},
					{
						level3_id: "17680",
						name: "Xã Quang Sơn",
						type: "Xã",
					},
					{
						level3_id: "17683",
						name: "Xã Thịnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "17686",
						name: "Xã Trung Sơn",
						type: "Xã",
					},
					{
						level3_id: "17689",
						name: "Xã Xuân Sơn",
						type: "Xã",
					},
					{
						level3_id: "17692",
						name: "Xã Minh Sơn",
						type: "Xã",
					},
					{
						level3_id: "17695",
						name: "Xã Thuận Sơn",
						type: "Xã",
					},
					{
						level3_id: "17698",
						name: "Xã Nhân Sơn",
						type: "Xã",
					},
					{
						level3_id: "17701",
						name: "Xã Hiến Sơn",
						type: "Xã",
					},
					{
						level3_id: "17704",
						name: "Xã Mỹ Sơn",
						type: "Xã",
					},
					{
						level3_id: "17707",
						name: "Xã Trù Sơn",
						type: "Xã",
					},
					{
						level3_id: "17710",
						name: "Xã Đại Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "428",
				name: "Huyện Thanh Chương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17713",
						name: "Thị trấn Thanh Chương",
						type: "Thị trấn",
					},
					{
						level3_id: "17716",
						name: "Xã Cát Văn",
						type: "Xã",
					},
					{
						level3_id: "17719",
						name: "Xã Thanh Nho",
						type: "Xã",
					},
					{
						level3_id: "17722",
						name: "Xã Hạnh Lâm",
						type: "Xã",
					},
					{
						level3_id: "17723",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "17725",
						name: "Xã Thanh Hòa",
						type: "Xã",
					},
					{
						level3_id: "17728",
						name: "Xã Phong Thịnh",
						type: "Xã",
					},
					{
						level3_id: "17731",
						name: "Xã Thanh Phong",
						type: "Xã",
					},
					{
						level3_id: "17734",
						name: "Xã Thanh Mỹ",
						type: "Xã",
					},
					{
						level3_id: "17737",
						name: "Xã Thanh Tiên",
						type: "Xã",
					},
					{
						level3_id: "17743",
						name: "Xã Thanh Liên",
						type: "Xã",
					},
					{
						level3_id: "17749",
						name: "Xã Đại Đồng",
						type: "Xã",
					},
					{
						level3_id: "17752",
						name: "Xã Thanh Đồng",
						type: "Xã",
					},
					{
						level3_id: "17755",
						name: "Xã Thanh Ngọc",
						type: "Xã",
					},
					{
						level3_id: "17758",
						name: "Xã Thanh Hương",
						type: "Xã",
					},
					{
						level3_id: "17759",
						name: "Xã Ngọc Lâm",
						type: "Xã",
					},
					{
						level3_id: "17761",
						name: "Xã Thanh Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "17764",
						name: "Xã Đồng Văn",
						type: "Xã",
					},
					{
						level3_id: "17767",
						name: "Xã Ngọc Sơn",
						type: "Xã",
					},
					{
						level3_id: "17770",
						name: "Xã Thanh Thịnh",
						type: "Xã",
					},
					{
						level3_id: "17773",
						name: "Xã Thanh An",
						type: "Xã",
					},
					{
						level3_id: "17776",
						name: "Xã Thanh Chi",
						type: "Xã",
					},
					{
						level3_id: "17779",
						name: "Xã Xuân Tường",
						type: "Xã",
					},
					{
						level3_id: "17782",
						name: "Xã Thanh Dương",
						type: "Xã",
					},
					{
						level3_id: "17785",
						name: "Xã Thanh Lương",
						type: "Xã",
					},
					{
						level3_id: "17788",
						name: "Xã Thanh Khê",
						type: "Xã",
					},
					{
						level3_id: "17791",
						name: "Xã Võ Liệt",
						type: "Xã",
					},
					{
						level3_id: "17794",
						name: "Xã Thanh Long",
						type: "Xã",
					},
					{
						level3_id: "17797",
						name: "Xã Thanh Thủy",
						type: "Xã",
					},
					{
						level3_id: "17800",
						name: "Xã Thanh Khai",
						type: "Xã",
					},
					{
						level3_id: "17803",
						name: "Xã Thanh Yên",
						type: "Xã",
					},
					{
						level3_id: "17806",
						name: "Xã Thanh Hà",
						type: "Xã",
					},
					{
						level3_id: "17809",
						name: "Xã Thanh Giang",
						type: "Xã",
					},
					{
						level3_id: "17812",
						name: "Xã Thanh Tùng",
						type: "Xã",
					},
					{
						level3_id: "17815",
						name: "Xã Thanh Lâm",
						type: "Xã",
					},
					{
						level3_id: "17818",
						name: "Xã Thanh Mai",
						type: "Xã",
					},
					{
						level3_id: "17821",
						name: "Xã Thanh Xuân",
						type: "Xã",
					},
					{
						level3_id: "17824",
						name: "Xã Thanh Đức",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "429",
				name: "Huyện Nghi Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17827",
						name: "Thị trấn Quán Hành",
						type: "Thị trấn",
					},
					{
						level3_id: "17830",
						name: "Xã Nghi Văn",
						type: "Xã",
					},
					{
						level3_id: "17833",
						name: "Xã Nghi Yên",
						type: "Xã",
					},
					{
						level3_id: "17836",
						name: "Xã Nghi Tiến",
						type: "Xã",
					},
					{
						level3_id: "17839",
						name: "Xã Nghi Hưng",
						type: "Xã",
					},
					{
						level3_id: "17842",
						name: "Xã Nghi Đồng",
						type: "Xã",
					},
					{
						level3_id: "17845",
						name: "Xã Nghi Thiết",
						type: "Xã",
					},
					{
						level3_id: "17848",
						name: "Xã Nghi Lâm",
						type: "Xã",
					},
					{
						level3_id: "17851",
						name: "Xã Nghi Quang",
						type: "Xã",
					},
					{
						level3_id: "17854",
						name: "Xã Nghi Kiều",
						type: "Xã",
					},
					{
						level3_id: "17857",
						name: "Xã Nghi Mỹ",
						type: "Xã",
					},
					{
						level3_id: "17860",
						name: "Xã Nghi Phương",
						type: "Xã",
					},
					{
						level3_id: "17863",
						name: "Xã Nghi Thuận",
						type: "Xã",
					},
					{
						level3_id: "17866",
						name: "Xã Nghi Long",
						type: "Xã",
					},
					{
						level3_id: "17869",
						name: "Xã Nghi Xá",
						type: "Xã",
					},
					{
						level3_id: "17875",
						name: "Xã Nghi Hoa",
						type: "Xã",
					},
					{
						level3_id: "17878",
						name: "Xã Khánh Hợp",
						type: "Xã",
					},
					{
						level3_id: "17881",
						name: "Xã Nghi Thịnh",
						type: "Xã",
					},
					{
						level3_id: "17884",
						name: "Xã Nghi Công Bắc",
						type: "Xã",
					},
					{
						level3_id: "17887",
						name: "Xã Nghi Công Nam",
						type: "Xã",
					},
					{
						level3_id: "17890",
						name: "Xã Nghi Thạch",
						type: "Xã",
					},
					{
						level3_id: "17893",
						name: "Xã Nghi Trung",
						type: "Xã",
					},
					{
						level3_id: "17896",
						name: "Xã Nghi Trường",
						type: "Xã",
					},
					{
						level3_id: "17899",
						name: "Xã Nghi Diên",
						type: "Xã",
					},
					{
						level3_id: "17902",
						name: "Xã Nghi Phong",
						type: "Xã",
					},
					{
						level3_id: "17905",
						name: "Xã Nghi Xuân",
						type: "Xã",
					},
					{
						level3_id: "17911",
						name: "Xã Nghi Vạn",
						type: "Xã",
					},
					{
						level3_id: "17917",
						name: "Xã Phúc Thọ",
						type: "Xã",
					},
					{
						level3_id: "17926",
						name: "Xã Nghi Thái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "430",
				name: "Huyện Nam Đàn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "17932",
						name: "Xã Nam Hưng",
						type: "Xã",
					},
					{
						level3_id: "17935",
						name: "Xã Nam Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "17938",
						name: "Xã Nam Thanh",
						type: "Xã",
					},
					{
						level3_id: "17941",
						name: "Xã Nam Anh",
						type: "Xã",
					},
					{
						level3_id: "17944",
						name: "Xã Nam Xuân",
						type: "Xã",
					},
					{
						level3_id: "17947",
						name: "Xã Nam Thái",
						type: "Xã",
					},
					{
						level3_id: "17950",
						name: "Thị trấn Nam Đàn",
						type: "Thị trấn",
					},
					{
						level3_id: "17953",
						name: "Xã Nam Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "17956",
						name: "Xã Nam Giang",
						type: "Xã",
					},
					{
						level3_id: "17959",
						name: "Xã Xuân Hòa",
						type: "Xã",
					},
					{
						level3_id: "17962",
						name: "Xã Hùng Tiến",
						type: "Xã",
					},
					{
						level3_id: "17968",
						name: "Xã Thượng Tân Lộc",
						type: "Xã",
					},
					{
						level3_id: "17971",
						name: "Xã Kim Liên",
						type: "Xã",
					},
					{
						level3_id: "17977",
						name: "Xã Hồng Long",
						type: "Xã",
					},
					{
						level3_id: "17980",
						name: "Xã Xuân Lâm",
						type: "Xã",
					},
					{
						level3_id: "17983",
						name: "Xã Nam Cát",
						type: "Xã",
					},
					{
						level3_id: "17986",
						name: "Xã Khánh Sơn",
						type: "Xã",
					},
					{
						level3_id: "17989",
						name: "Xã Trung Phúc Cường",
						type: "Xã",
					},
					{
						level3_id: "17998",
						name: "Xã Nam Kim",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "431",
				name: "Huyện Hưng Nguyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18001",
						name: "Thị trấn Hưng Nguyên",
						type: "Thị trấn",
					},
					{
						level3_id: "18004",
						name: "Xã Hưng Trung",
						type: "Xã",
					},
					{
						level3_id: "18007",
						name: "Xã Hưng Yên",
						type: "Xã",
					},
					{
						level3_id: "18008",
						name: "Xã Hưng Yên Bắc",
						type: "Xã",
					},
					{
						level3_id: "18010",
						name: "Xã Hưng Tây",
						type: "Xã",
					},
					{
						level3_id: "18016",
						name: "Xã Hưng Đạo",
						type: "Xã",
					},
					{
						level3_id: "18019",
						name: "Xã Hưng Mỹ",
						type: "Xã",
					},
					{
						level3_id: "18022",
						name: "Xã Hưng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "18025",
						name: "Xã Hưng Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "18028",
						name: "Xã Hưng Thông",
						type: "Xã",
					},
					{
						level3_id: "18031",
						name: "Xã Hưng Tân",
						type: "Xã",
					},
					{
						level3_id: "18034",
						name: "Xã Hưng Lợi",
						type: "Xã",
					},
					{
						level3_id: "18037",
						name: "Xã Hưng Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "18040",
						name: "Xã Hưng Phúc",
						type: "Xã",
					},
					{
						level3_id: "18043",
						name: "Xã Long Xá",
						type: "Xã",
					},
					{
						level3_id: "18052",
						name: "Xã Châu Nhân",
						type: "Xã",
					},
					{
						level3_id: "18055",
						name: "Xã Xuân Lam",
						type: "Xã",
					},
					{
						level3_id: "18064",
						name: "Xã Hưng Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "432",
				name: "Thị xã Hoàng Mai",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "17104",
						name: "Xã Quỳnh Vinh",
						type: "Xã",
					},
					{
						level3_id: "17107",
						name: "Xã Quỳnh Lộc",
						type: "Xã",
					},
					{
						level3_id: "17110",
						name: "Phường Quỳnh Thiện",
						type: "Phường",
					},
					{
						level3_id: "17113",
						name: "Xã Quỳnh Lập",
						type: "Xã",
					},
					{
						level3_id: "17116",
						name: "Xã Quỳnh Trang",
						type: "Xã",
					},
					{
						level3_id: "17125",
						name: "Phường Mai Hùng",
						type: "Phường",
					},
					{
						level3_id: "17128",
						name: "Phường Quỳnh Dị",
						type: "Phường",
					},
					{
						level3_id: "17131",
						name: "Phường Quỳnh Xuân",
						type: "Phường",
					},
					{
						level3_id: "17134",
						name: "Phường Quỳnh Phương",
						type: "Phường",
					},
					{
						level3_id: "17137",
						name: "Xã Quỳnh Liên",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "42",
		name: "Tỉnh Hà Tĩnh",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "436",
				name: "Thành phố Hà Tĩnh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "18070",
						name: "Phường Trần Phú",
						type: "Phường",
					},
					{
						level3_id: "18073",
						name: "Phường Nam Hà",
						type: "Phường",
					},
					{
						level3_id: "18076",
						name: "Phường Bắc Hà",
						type: "Phường",
					},
					{
						level3_id: "18077",
						name: "Phường Nguyễn Du",
						type: "Phường",
					},
					{
						level3_id: "18079",
						name: "Phường Tân Giang",
						type: "Phường",
					},
					{
						level3_id: "18082",
						name: "Phường Đại Nài",
						type: "Phường",
					},
					{
						level3_id: "18085",
						name: "Phường Hà Huy Tập",
						type: "Phường",
					},
					{
						level3_id: "18088",
						name: "Xã Thạch Trung",
						type: "Xã",
					},
					{
						level3_id: "18091",
						name: "Phường Thạch Quý",
						type: "Phường",
					},
					{
						level3_id: "18094",
						name: "Phường Thạch Linh",
						type: "Phường",
					},
					{
						level3_id: "18097",
						name: "Phường Văn Yên",
						type: "Phường",
					},
					{
						level3_id: "18100",
						name: "Xã Thạch Hạ",
						type: "Xã",
					},
					{
						level3_id: "18103",
						name: "Xã Đồng Môn",
						type: "Xã",
					},
					{
						level3_id: "18109",
						name: "Xã Thạch Hưng",
						type: "Xã",
					},
					{
						level3_id: "18112",
						name: "Xã Thạch Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "437",
				name: "Thị xã Hồng Lĩnh",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "18115",
						name: "Phường Bắc Hồng",
						type: "Phường",
					},
					{
						level3_id: "18118",
						name: "Phường Nam Hồng",
						type: "Phường",
					},
					{
						level3_id: "18121",
						name: "Phường Trung Lương",
						type: "Phường",
					},
					{
						level3_id: "18124",
						name: "Phường Đức Thuận",
						type: "Phường",
					},
					{
						level3_id: "18127",
						name: "Phường Đậu Liêu",
						type: "Phường",
					},
					{
						level3_id: "18130",
						name: "Xã Thuận Lộc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "439",
				name: "Huyện Hương Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18133",
						name: "Thị trấn Phố Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "18136",
						name: "Thị trấn  Tây Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "18139",
						name: "Xã Sơn Hồng",
						type: "Xã",
					},
					{
						level3_id: "18142",
						name: "Xã Sơn Tiến",
						type: "Xã",
					},
					{
						level3_id: "18145",
						name: "Xã Sơn Lâm",
						type: "Xã",
					},
					{
						level3_id: "18148",
						name: "Xã Sơn Lễ",
						type: "Xã",
					},
					{
						level3_id: "18157",
						name: "Xã Sơn Giang",
						type: "Xã",
					},
					{
						level3_id: "18160",
						name: "Xã Sơn Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "18163",
						name: "Xã An Hòa Thịnh",
						type: "Xã",
					},
					{
						level3_id: "18172",
						name: "Xã Sơn Tây",
						type: "Xã",
					},
					{
						level3_id: "18175",
						name: "Xã Sơn Ninh",
						type: "Xã",
					},
					{
						level3_id: "18178",
						name: "Xã Sơn Châu",
						type: "Xã",
					},
					{
						level3_id: "18181",
						name: "Xã Tân Mỹ Hà",
						type: "Xã",
					},
					{
						level3_id: "18184",
						name: "Xã Quang Diệm",
						type: "Xã",
					},
					{
						level3_id: "18187",
						name: "Xã Sơn Trung",
						type: "Xã",
					},
					{
						level3_id: "18190",
						name: "Xã Sơn Bằng",
						type: "Xã",
					},
					{
						level3_id: "18193",
						name: "Xã Sơn Bình",
						type: "Xã",
					},
					{
						level3_id: "18196",
						name: "Xã Sơn Kim 1",
						type: "Xã",
					},
					{
						level3_id: "18199",
						name: "Xã Sơn Kim 2",
						type: "Xã",
					},
					{
						level3_id: "18202",
						name: "Xã Sơn Trà",
						type: "Xã",
					},
					{
						level3_id: "18205",
						name: "Xã Sơn Long",
						type: "Xã",
					},
					{
						level3_id: "18211",
						name: "Xã Kim Hoa",
						type: "Xã",
					},
					{
						level3_id: "18214",
						name: "Xã Sơn Hàm",
						type: "Xã",
					},
					{
						level3_id: "18217",
						name: "Xã Sơn Phú",
						type: "Xã",
					},
					{
						level3_id: "18223",
						name: "Xã Sơn Trường",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "440",
				name: "Huyện Đức Thọ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18229",
						name: "Thị trấn Đức Thọ",
						type: "Thị trấn",
					},
					{
						level3_id: "18235",
						name: "Xã Quang Vĩnh",
						type: "Xã",
					},
					{
						level3_id: "18241",
						name: "Xã Tùng Châu",
						type: "Xã",
					},
					{
						level3_id: "18244",
						name: "Xã Trường Sơn",
						type: "Xã",
					},
					{
						level3_id: "18247",
						name: "Xã Liên Minh",
						type: "Xã",
					},
					{
						level3_id: "18253",
						name: "Xã Yên Hồ",
						type: "Xã",
					},
					{
						level3_id: "18259",
						name: "Xã Tùng Ảnh",
						type: "Xã",
					},
					{
						level3_id: "18262",
						name: "Xã Bùi La Nhân",
						type: "Xã",
					},
					{
						level3_id: "18274",
						name: "Xã Thanh Bình Thịnh",
						type: "Xã",
					},
					{
						level3_id: "18277",
						name: "Xã Lâm Trung Thủy",
						type: "Xã",
					},
					{
						level3_id: "18280",
						name: "Xã Hòa Lạc",
						type: "Xã",
					},
					{
						level3_id: "18283",
						name: "Xã Tân Dân",
						type: "Xã",
					},
					{
						level3_id: "18298",
						name: "Xã An Dũng",
						type: "Xã",
					},
					{
						level3_id: "18304",
						name: "Xã Đức Đồng",
						type: "Xã",
					},
					{
						level3_id: "18307",
						name: "Xã Đức Lạng",
						type: "Xã",
					},
					{
						level3_id: "18310",
						name: "Xã Tân Hương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "441",
				name: "Huyện Vũ Quang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18313",
						name: "Thị trấn Vũ Quang",
						type: "Thị trấn",
					},
					{
						level3_id: "18316",
						name: "Xã Ân Phú",
						type: "Xã",
					},
					{
						level3_id: "18319",
						name: "Xã Đức Giang",
						type: "Xã",
					},
					{
						level3_id: "18322",
						name: "Xã Đức Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "18325",
						name: "Xã Thọ Điền",
						type: "Xã",
					},
					{
						level3_id: "18328",
						name: "Xã Đức Hương",
						type: "Xã",
					},
					{
						level3_id: "18331",
						name: "Xã Đức Bồng",
						type: "Xã",
					},
					{
						level3_id: "18334",
						name: "Xã Đức Liên",
						type: "Xã",
					},
					{
						level3_id: "18340",
						name: "Xã Hương Minh",
						type: "Xã",
					},
					{
						level3_id: "18343",
						name: "Xã Quang Thọ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "442",
				name: "Huyện Nghi Xuân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18352",
						name: "Thị trấn Xuân An",
						type: "Thị trấn",
					},
					{
						level3_id: "18355",
						name: "Xã Xuân Hội",
						type: "Xã",
					},
					{
						level3_id: "18358",
						name: "Xã Đan Trường",
						type: "Xã",
					},
					{
						level3_id: "18364",
						name: "Xã Xuân Phổ",
						type: "Xã",
					},
					{
						level3_id: "18367",
						name: "Xã Xuân Hải",
						type: "Xã",
					},
					{
						level3_id: "18370",
						name: "Xã Xuân Giang",
						type: "Xã",
					},
					{
						level3_id: "18373",
						name: "Thị trấn Tiên Điền",
						type: "Thị trấn",
					},
					{
						level3_id: "18376",
						name: "Xã Xuân Yên",
						type: "Xã",
					},
					{
						level3_id: "18379",
						name: "Xã Xuân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "18382",
						name: "Xã Xuân Thành",
						type: "Xã",
					},
					{
						level3_id: "18385",
						name: "Xã Xuân Viên",
						type: "Xã",
					},
					{
						level3_id: "18388",
						name: "Xã Xuân Hồng",
						type: "Xã",
					},
					{
						level3_id: "18391",
						name: "Xã Cỗ Đạm",
						type: "Xã",
					},
					{
						level3_id: "18394",
						name: "Xã Xuân Liên",
						type: "Xã",
					},
					{
						level3_id: "18397",
						name: "Xã Xuân Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "18400",
						name: "Xã Xuân Lam",
						type: "Xã",
					},
					{
						level3_id: "18403",
						name: "Xã Cương Gián",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "443",
				name: "Huyện Can Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18406",
						name: "Thị trấn Nghèn",
						type: "Thị trấn",
					},
					{
						level3_id: "18415",
						name: "Xã Thiên Lộc",
						type: "Xã",
					},
					{
						level3_id: "18418",
						name: "Xã Thuần Thiện",
						type: "Xã",
					},
					{
						level3_id: "18427",
						name: "Xã Vượng Lộc",
						type: "Xã",
					},
					{
						level3_id: "18433",
						name: "Xã Thanh Lộc",
						type: "Xã",
					},
					{
						level3_id: "18436",
						name: "Xã Kim Song Trường",
						type: "Xã",
					},
					{
						level3_id: "18439",
						name: "Xã Thường Nga",
						type: "Xã",
					},
					{
						level3_id: "18445",
						name: "Xã Tùng Lộc",
						type: "Xã",
					},
					{
						level3_id: "18454",
						name: "Xã Phú Lộc",
						type: "Xã",
					},
					{
						level3_id: "18463",
						name: "Xã Gia Hanh",
						type: "Xã",
					},
					{
						level3_id: "18466",
						name: "Xã Khánh Vĩnh Yên",
						type: "Xã",
					},
					{
						level3_id: "18472",
						name: "Xã Trung Lộc",
						type: "Xã",
					},
					{
						level3_id: "18475",
						name: "Xã Xuân Lộc",
						type: "Xã",
					},
					{
						level3_id: "18478",
						name: "Xã Thượng Lộc",
						type: "Xã",
					},
					{
						level3_id: "18481",
						name: "Xã Quang Lộc",
						type: "Xã",
					},
					{
						level3_id: "18484",
						name: "Thị trấn Đồng Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "18487",
						name: "Xã Mỹ Lộc",
						type: "Xã",
					},
					{
						level3_id: "18490",
						name: "Xã Sơn Lộc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "444",
				name: "Huyện Hương Khê",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18496",
						name: "Thị trấn Hương Khê",
						type: "Thị trấn",
					},
					{
						level3_id: "18499",
						name: "Xã Điền Mỹ",
						type: "Xã",
					},
					{
						level3_id: "18502",
						name: "Xã Hà Linh",
						type: "Xã",
					},
					{
						level3_id: "18505",
						name: "Xã Hương Thủy",
						type: "Xã",
					},
					{
						level3_id: "18508",
						name: "Xã Hòa Hải",
						type: "Xã",
					},
					{
						level3_id: "18514",
						name: "Xã Phúc Đồng",
						type: "Xã",
					},
					{
						level3_id: "18517",
						name: "Xã Hương Giang",
						type: "Xã",
					},
					{
						level3_id: "18520",
						name: "Xã Lộc Yên",
						type: "Xã",
					},
					{
						level3_id: "18523",
						name: "Xã Hương Bình",
						type: "Xã",
					},
					{
						level3_id: "18526",
						name: "Xã Hương Long",
						type: "Xã",
					},
					{
						level3_id: "18529",
						name: "Xã Phú Gia",
						type: "Xã",
					},
					{
						level3_id: "18532",
						name: "Xã Gia Phố",
						type: "Xã",
					},
					{
						level3_id: "18535",
						name: "Xã Phú Phong",
						type: "Xã",
					},
					{
						level3_id: "18538",
						name: "Xã Hương Đô",
						type: "Xã",
					},
					{
						level3_id: "18541",
						name: "Xã Hương Vĩnh",
						type: "Xã",
					},
					{
						level3_id: "18544",
						name: "Xã Hương Xuân",
						type: "Xã",
					},
					{
						level3_id: "18547",
						name: "Xã Phúc Trạch",
						type: "Xã",
					},
					{
						level3_id: "18550",
						name: "Xã Hương Trà",
						type: "Xã",
					},
					{
						level3_id: "18553",
						name: "Xã Hương Trạch",
						type: "Xã",
					},
					{
						level3_id: "18556",
						name: "Xã Hương Lâm",
						type: "Xã",
					},
					{
						level3_id: "18559",
						name: "Xã Hương Liên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "445",
				name: "Huyện Thạch Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18562",
						name: "Thị trấn Thạch Hà",
						type: "Thị trấn",
					},
					{
						level3_id: "18565",
						name: "Xã Ngọc Sơn",
						type: "Xã",
					},
					{
						level3_id: "18571",
						name: "Xã Thạch Hải",
						type: "Xã",
					},
					{
						level3_id: "18586",
						name: "Xã Thạch Kênh",
						type: "Xã",
					},
					{
						level3_id: "18589",
						name: "Xã Thạch Sơn",
						type: "Xã",
					},
					{
						level3_id: "18592",
						name: "Xã Thạch Liên",
						type: "Xã",
					},
					{
						level3_id: "18595",
						name: "Xã Đỉnh Bàn",
						type: "Xã",
					},
					{
						level3_id: "18601",
						name: "Xã Việt Tiến",
						type: "Xã",
					},
					{
						level3_id: "18604",
						name: "Xã Thạch Khê",
						type: "Xã",
					},
					{
						level3_id: "18607",
						name: "Xã Thạch Long",
						type: "Xã",
					},
					{
						level3_id: "18619",
						name: "Xã Thạch Trị",
						type: "Xã",
					},
					{
						level3_id: "18622",
						name: "Xã Thạch Lạc",
						type: "Xã",
					},
					{
						level3_id: "18625",
						name: "Xã Thạch Ngọc",
						type: "Xã",
					},
					{
						level3_id: "18628",
						name: "Xã Tượng Sơn",
						type: "Xã",
					},
					{
						level3_id: "18631",
						name: "Xã Thạch Văn",
						type: "Xã",
					},
					{
						level3_id: "18634",
						name: "Xã Lưu Vĩnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "18637",
						name: "Xã Thạch Thắng",
						type: "Xã",
					},
					{
						level3_id: "18643",
						name: "Xã Thạch Đài",
						type: "Xã",
					},
					{
						level3_id: "18649",
						name: "Xã Thạch Hội",
						type: "Xã",
					},
					{
						level3_id: "18652",
						name: "Xã Tân Lâm Hương",
						type: "Xã",
					},
					{
						level3_id: "18658",
						name: "Xã Thạch Xuân",
						type: "Xã",
					},
					{
						level3_id: "18667",
						name: "Xã Nam Điền",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "446",
				name: "Huyện Cẩm Xuyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18673",
						name: "Thị trấn Cẩm Xuyên",
						type: "Thị trấn",
					},
					{
						level3_id: "18676",
						name: "Thị trấn Thiên Cầm",
						type: "Thị trấn",
					},
					{
						level3_id: "18679",
						name: "Xã Yên Hòa",
						type: "Xã",
					},
					{
						level3_id: "18682",
						name: "Xã Cẩm Dương",
						type: "Xã",
					},
					{
						level3_id: "18685",
						name: "Xã Cẩm Bình",
						type: "Xã",
					},
					{
						level3_id: "18691",
						name: "Xã Cẩm Vĩnh",
						type: "Xã",
					},
					{
						level3_id: "18694",
						name: "Xã Cẩm Thành",
						type: "Xã",
					},
					{
						level3_id: "18697",
						name: "Xã Cẩm Quang",
						type: "Xã",
					},
					{
						level3_id: "18706",
						name: "Xã Cẩm Thạch",
						type: "Xã",
					},
					{
						level3_id: "18709",
						name: "Xã Cẩm Nhượng",
						type: "Xã",
					},
					{
						level3_id: "18712",
						name: "Xã Nam Phúc Thăng",
						type: "Xã",
					},
					{
						level3_id: "18715",
						name: "Xã Cẩm Duệ",
						type: "Xã",
					},
					{
						level3_id: "18721",
						name: "Xã Cẩm Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "18724",
						name: "Xã Cẩm Quan",
						type: "Xã",
					},
					{
						level3_id: "18727",
						name: "Xã Cẩm Hà",
						type: "Xã",
					},
					{
						level3_id: "18730",
						name: "Xã Cẩm Lộc",
						type: "Xã",
					},
					{
						level3_id: "18733",
						name: "Xã Cẩm Hưng",
						type: "Xã",
					},
					{
						level3_id: "18736",
						name: "Xã Cẩm Thịnh",
						type: "Xã",
					},
					{
						level3_id: "18739",
						name: "Xã Cẩm Mỹ",
						type: "Xã",
					},
					{
						level3_id: "18742",
						name: "Xã Cẩm Trung",
						type: "Xã",
					},
					{
						level3_id: "18745",
						name: "Xã Cẩm Sơn",
						type: "Xã",
					},
					{
						level3_id: "18748",
						name: "Xã Cẩm Lạc",
						type: "Xã",
					},
					{
						level3_id: "18751",
						name: "Xã Cẩm Minh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "447",
				name: "Huyện Kỳ Anh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18757",
						name: "Xã Kỳ Xuân",
						type: "Xã",
					},
					{
						level3_id: "18760",
						name: "Xã Kỳ Bắc",
						type: "Xã",
					},
					{
						level3_id: "18763",
						name: "Xã Kỳ Phú",
						type: "Xã",
					},
					{
						level3_id: "18766",
						name: "Xã Kỳ Phong",
						type: "Xã",
					},
					{
						level3_id: "18769",
						name: "Xã Kỳ Tiến",
						type: "Xã",
					},
					{
						level3_id: "18772",
						name: "Xã Kỳ Giang",
						type: "Xã",
					},
					{
						level3_id: "18775",
						name: "Xã Kỳ Đồng",
						type: "Xã",
					},
					{
						level3_id: "18778",
						name: "Xã Kỳ Khang",
						type: "Xã",
					},
					{
						level3_id: "18784",
						name: "Xã Kỳ Văn",
						type: "Xã",
					},
					{
						level3_id: "18787",
						name: "Xã Kỳ Trung",
						type: "Xã",
					},
					{
						level3_id: "18790",
						name: "Xã Kỳ Thọ",
						type: "Xã",
					},
					{
						level3_id: "18793",
						name: "Xã Kỳ Tây",
						type: "Xã",
					},
					{
						level3_id: "18799",
						name: "Xã Kỳ Thượng",
						type: "Xã",
					},
					{
						level3_id: "18802",
						name: "Xã Kỳ Hải",
						type: "Xã",
					},
					{
						level3_id: "18805",
						name: "Xã Kỳ Thư",
						type: "Xã",
					},
					{
						level3_id: "18811",
						name: "Xã Kỳ Châu",
						type: "Xã",
					},
					{
						level3_id: "18814",
						name: "Xã Kỳ Tân",
						type: "Xã",
					},
					{
						level3_id: "18838",
						name: "Xã Lâm Hợp",
						type: "Xã",
					},
					{
						level3_id: "18844",
						name: "Xã Kỳ Sơn",
						type: "Xã",
					},
					{
						level3_id: "18850",
						name: "Xã Kỳ Lạc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "448",
				name: "Huyện Lộc Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18409",
						name: "Xã Tân Lộc",
						type: "Xã",
					},
					{
						level3_id: "18412",
						name: "Xã Hồng Lộc",
						type: "Xã",
					},
					{
						level3_id: "18421",
						name: "Xã Thịnh Lộc",
						type: "Xã",
					},
					{
						level3_id: "18430",
						name: "Xã Bình An",
						type: "Xã",
					},
					{
						level3_id: "18457",
						name: "Xã Ích Hậu",
						type: "Xã",
					},
					{
						level3_id: "18493",
						name: "Xã Phù Lưu",
						type: "Xã",
					},
					{
						level3_id: "18568",
						name: "Thị trấn Lộc Hà",
						type: "Thị trấn",
					},
					{
						level3_id: "18577",
						name: "Xã Thạch Mỹ",
						type: "Xã",
					},
					{
						level3_id: "18580",
						name: "Xã Thạch Kim",
						type: "Xã",
					},
					{
						level3_id: "18583",
						name: "Xã Thạch Châu",
						type: "Xã",
					},
					{
						level3_id: "18598",
						name: "Xã Hộ Độ",
						type: "Xã",
					},
					{
						level3_id: "18670",
						name: "Xã Mai Phụ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "449",
				name: "Thị xã Kỳ Anh",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "18754",
						name: "Phường Hưng Trí",
						type: "Phường",
					},
					{
						level3_id: "18781",
						name: "Xã Kỳ Ninh",
						type: "Xã",
					},
					{
						level3_id: "18796",
						name: "Xã Kỳ Lợi",
						type: "Xã",
					},
					{
						level3_id: "18808",
						name: "Xã Kỳ Hà",
						type: "Xã",
					},
					{
						level3_id: "18820",
						name: "Phường Kỳ Trinh",
						type: "Phường",
					},
					{
						level3_id: "18823",
						name: "Phường Kỳ Thịnh",
						type: "Phường",
					},
					{
						level3_id: "18829",
						name: "Xã Kỳ Hoa",
						type: "Xã",
					},
					{
						level3_id: "18832",
						name: "Phường Kỳ Phương",
						type: "Phường",
					},
					{
						level3_id: "18835",
						name: "Phường Kỳ Long",
						type: "Phường",
					},
					{
						level3_id: "18841",
						name: "Phường Kỳ Liên",
						type: "Phường",
					},
					{
						level3_id: "18847",
						name: "Xã Kỳ Nam",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "44",
		name: "Tỉnh Quảng Bình",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "450",
				name: "Thành Phố Đồng Hới",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "18853",
						name: "Phường Hải Thành",
						type: "Phường",
					},
					{
						level3_id: "18856",
						name: "Phường Đồng Phú",
						type: "Phường",
					},
					{
						level3_id: "18859",
						name: "Phường Bắc Lý",
						type: "Phường",
					},
					{
						level3_id: "18865",
						name: "Phường Nam Lý",
						type: "Phường",
					},
					{
						level3_id: "18868",
						name: "Phường Đồng Hải",
						type: "Phường",
					},
					{
						level3_id: "18871",
						name: "Phường Đồng Sơn",
						type: "Phường",
					},
					{
						level3_id: "18874",
						name: "Phường Phú Hải",
						type: "Phường",
					},
					{
						level3_id: "18877",
						name: "Phường Bắc Nghĩa",
						type: "Phường",
					},
					{
						level3_id: "18880",
						name: "Phường Đức Ninh Đông",
						type: "Phường",
					},
					{
						level3_id: "18883",
						name: "Xã Quang Phú",
						type: "Xã",
					},
					{
						level3_id: "18886",
						name: "Xã Lộc Ninh",
						type: "Xã",
					},
					{
						level3_id: "18889",
						name: "Xã Bảo Ninh",
						type: "Xã",
					},
					{
						level3_id: "18892",
						name: "Xã Nghĩa Ninh",
						type: "Xã",
					},
					{
						level3_id: "18895",
						name: "Xã Thuận Đức",
						type: "Xã",
					},
					{
						level3_id: "18898",
						name: "Xã Đức Ninh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "452",
				name: "Huyện Minh Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18901",
						name: "Thị trấn Quy Đạt",
						type: "Thị trấn",
					},
					{
						level3_id: "18904",
						name: "Xã Dân Hóa",
						type: "Xã",
					},
					{
						level3_id: "18907",
						name: "Xã Trọng Hóa",
						type: "Xã",
					},
					{
						level3_id: "18910",
						name: "Xã Hóa Phúc",
						type: "Xã",
					},
					{
						level3_id: "18913",
						name: "Xã Hồng Hóa",
						type: "Xã",
					},
					{
						level3_id: "18916",
						name: "Xã Hóa Thanh",
						type: "Xã",
					},
					{
						level3_id: "18919",
						name: "Xã Hóa Tiến",
						type: "Xã",
					},
					{
						level3_id: "18922",
						name: "Xã Hóa Hợp",
						type: "Xã",
					},
					{
						level3_id: "18925",
						name: "Xã Xuân Hóa",
						type: "Xã",
					},
					{
						level3_id: "18928",
						name: "Xã Yên Hóa",
						type: "Xã",
					},
					{
						level3_id: "18931",
						name: "Xã Minh Hóa",
						type: "Xã",
					},
					{
						level3_id: "18934",
						name: "Xã Tân Hóa",
						type: "Xã",
					},
					{
						level3_id: "18937",
						name: "Xã Hóa Sơn",
						type: "Xã",
					},
					{
						level3_id: "18943",
						name: "Xã Trung Hóa",
						type: "Xã",
					},
					{
						level3_id: "18946",
						name: "Xã Thượng Hóa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "453",
				name: "Huyện Tuyên Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "18949",
						name: "Thị trấn Đồng Lê",
						type: "Thị trấn",
					},
					{
						level3_id: "18952",
						name: "Xã Hương Hóa",
						type: "Xã",
					},
					{
						level3_id: "18955",
						name: "Xã Kim Hóa",
						type: "Xã",
					},
					{
						level3_id: "18958",
						name: "Xã Thanh Hóa",
						type: "Xã",
					},
					{
						level3_id: "18961",
						name: "Xã Thanh Thạch",
						type: "Xã",
					},
					{
						level3_id: "18964",
						name: "Xã Thuận Hóa",
						type: "Xã",
					},
					{
						level3_id: "18967",
						name: "Xã Lâm Hóa",
						type: "Xã",
					},
					{
						level3_id: "18970",
						name: "Xã Lê Hóa",
						type: "Xã",
					},
					{
						level3_id: "18973",
						name: "Xã Sơn Hóa",
						type: "Xã",
					},
					{
						level3_id: "18976",
						name: "Xã Đồng Hóa",
						type: "Xã",
					},
					{
						level3_id: "18979",
						name: "Xã Ngư Hóa",
						type: "Xã",
					},
					{
						level3_id: "18985",
						name: "Xã Thạch Hóa",
						type: "Xã",
					},
					{
						level3_id: "18988",
						name: "Xã Đức Hóa",
						type: "Xã",
					},
					{
						level3_id: "18991",
						name: "Xã Phong Hóa",
						type: "Xã",
					},
					{
						level3_id: "18994",
						name: "Xã Mai Hóa",
						type: "Xã",
					},
					{
						level3_id: "18997",
						name: "Xã Tiến Hóa",
						type: "Xã",
					},
					{
						level3_id: "19000",
						name: "Xã Châu Hóa",
						type: "Xã",
					},
					{
						level3_id: "19003",
						name: "Xã Cao Quảng",
						type: "Xã",
					},
					{
						level3_id: "19006",
						name: "Xã Văn Hóa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "454",
				name: "Huyện Quảng Trạch",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19012",
						name: "Xã Quảng Hợp",
						type: "Xã",
					},
					{
						level3_id: "19015",
						name: "Xã Quảng Kim",
						type: "Xã",
					},
					{
						level3_id: "19018",
						name: "Xã Quảng Đông",
						type: "Xã",
					},
					{
						level3_id: "19021",
						name: "Xã Quảng Phú",
						type: "Xã",
					},
					{
						level3_id: "19024",
						name: "Xã Quảng Châu",
						type: "Xã",
					},
					{
						level3_id: "19027",
						name: "Xã Quảng Thạch",
						type: "Xã",
					},
					{
						level3_id: "19030",
						name: "Xã Quảng Lưu",
						type: "Xã",
					},
					{
						level3_id: "19033",
						name: "Xã Quảng Tùng",
						type: "Xã",
					},
					{
						level3_id: "19036",
						name: "Xã Cảnh Dương",
						type: "Xã",
					},
					{
						level3_id: "19039",
						name: "Xã Quảng Tiến",
						type: "Xã",
					},
					{
						level3_id: "19042",
						name: "Xã Quảng Hưng",
						type: "Xã",
					},
					{
						level3_id: "19045",
						name: "Xã Quảng Xuân",
						type: "Xã",
					},
					{
						level3_id: "19048",
						name: "Xã Cảnh Hóa",
						type: "Xã",
					},
					{
						level3_id: "19051",
						name: "Xã Liên Trường",
						type: "Xã",
					},
					{
						level3_id: "19057",
						name: "Xã Quảng Phương",
						type: "Xã",
					},
					{
						level3_id: "19063",
						name: "Xã Phù Hóa",
						type: "Xã",
					},
					{
						level3_id: "19072",
						name: "Xã Quảng Thanh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "455",
				name: "Huyện Bố Trạch",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19111",
						name: "Thị trấn Hoàn Lão",
						type: "Thị trấn",
					},
					{
						level3_id: "19114",
						name: "Thị trấn NT Việt Trung",
						type: "Thị trấn",
					},
					{
						level3_id: "19117",
						name: "Xã Xuân Trạch",
						type: "Xã",
					},
					{
						level3_id: "19120",
						name: "Xã Mỹ Trạch",
						type: "Xã",
					},
					{
						level3_id: "19123",
						name: "Xã Hạ Trạch",
						type: "Xã",
					},
					{
						level3_id: "19126",
						name: "Xã Bắc Trạch",
						type: "Xã",
					},
					{
						level3_id: "19129",
						name: "Xã Lâm Trạch",
						type: "Xã",
					},
					{
						level3_id: "19132",
						name: "Xã Thanh Trạch",
						type: "Xã",
					},
					{
						level3_id: "19135",
						name: "Xã Liên Trạch",
						type: "Xã",
					},
					{
						level3_id: "19138",
						name: "Xã Phúc Trạch",
						type: "Xã",
					},
					{
						level3_id: "19141",
						name: "Xã Cự Nẫm",
						type: "Xã",
					},
					{
						level3_id: "19144",
						name: "Xã Hải Phú",
						type: "Xã",
					},
					{
						level3_id: "19147",
						name: "Xã Thượng Trạch",
						type: "Xã",
					},
					{
						level3_id: "19150",
						name: "Xã Sơn Lộc",
						type: "Xã",
					},
					{
						level3_id: "19156",
						name: "Xã Hưng Trạch",
						type: "Xã",
					},
					{
						level3_id: "19159",
						name: "Xã Đồng Trạch",
						type: "Xã",
					},
					{
						level3_id: "19162",
						name: "Xã Đức Trạch",
						type: "Xã",
					},
					{
						level3_id: "19165",
						name: "Thị trấn Phong Nha",
						type: "Thị trấn",
					},
					{
						level3_id: "19168",
						name: "Xã Vạn Trạch",
						type: "Xã",
					},
					{
						level3_id: "19174",
						name: "Xã Phú Định",
						type: "Xã",
					},
					{
						level3_id: "19177",
						name: "Xã Trung Trạch",
						type: "Xã",
					},
					{
						level3_id: "19180",
						name: "Xã Tây Trạch",
						type: "Xã",
					},
					{
						level3_id: "19183",
						name: "Xã Hòa Trạch",
						type: "Xã",
					},
					{
						level3_id: "19186",
						name: "Xã Đại Trạch",
						type: "Xã",
					},
					{
						level3_id: "19189",
						name: "Xã Nhân Trạch",
						type: "Xã",
					},
					{
						level3_id: "19192",
						name: "Xã Tân Trạch",
						type: "Xã",
					},
					{
						level3_id: "19195",
						name: "Xã Nam Trạch",
						type: "Xã",
					},
					{
						level3_id: "19198",
						name: "Xã Lý Trạch",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "456",
				name: "Huyện Quảng Ninh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19201",
						name: "Thị trấn Quán Hàu",
						type: "Thị trấn",
					},
					{
						level3_id: "19204",
						name: "Xã Trường Sơn",
						type: "Xã",
					},
					{
						level3_id: "19207",
						name: "Xã Lương Ninh",
						type: "Xã",
					},
					{
						level3_id: "19210",
						name: "Xã Vĩnh Ninh",
						type: "Xã",
					},
					{
						level3_id: "19213",
						name: "Xã Võ Ninh",
						type: "Xã",
					},
					{
						level3_id: "19216",
						name: "Xã Hải Ninh",
						type: "Xã",
					},
					{
						level3_id: "19219",
						name: "Xã Hàm Ninh",
						type: "Xã",
					},
					{
						level3_id: "19222",
						name: "Xã Duy Ninh",
						type: "Xã",
					},
					{
						level3_id: "19225",
						name: "Xã Gia Ninh",
						type: "Xã",
					},
					{
						level3_id: "19228",
						name: "Xã Trường Xuân",
						type: "Xã",
					},
					{
						level3_id: "19231",
						name: "Xã Hiền Ninh",
						type: "Xã",
					},
					{
						level3_id: "19234",
						name: "Xã Tân Ninh",
						type: "Xã",
					},
					{
						level3_id: "19237",
						name: "Xã Xuân Ninh",
						type: "Xã",
					},
					{
						level3_id: "19240",
						name: "Xã An Ninh",
						type: "Xã",
					},
					{
						level3_id: "19243",
						name: "Xã Vạn Ninh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "457",
				name: "Huyện Lệ Thủy",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19246",
						name: "Thị trấn NT Lệ Ninh",
						type: "Thị trấn",
					},
					{
						level3_id: "19249",
						name: "Thị trấn Kiến Giang",
						type: "Thị trấn",
					},
					{
						level3_id: "19252",
						name: "Xã Hồng Thủy",
						type: "Xã",
					},
					{
						level3_id: "19255",
						name: "Xã Ngư Thủy Bắc",
						type: "Xã",
					},
					{
						level3_id: "19258",
						name: "Xã Hoa Thủy",
						type: "Xã",
					},
					{
						level3_id: "19261",
						name: "Xã Thanh Thủy",
						type: "Xã",
					},
					{
						level3_id: "19264",
						name: "Xã An Thủy",
						type: "Xã",
					},
					{
						level3_id: "19267",
						name: "Xã Phong Thủy",
						type: "Xã",
					},
					{
						level3_id: "19270",
						name: "Xã Cam Thủy",
						type: "Xã",
					},
					{
						level3_id: "19273",
						name: "Xã Ngân Thủy",
						type: "Xã",
					},
					{
						level3_id: "19276",
						name: "Xã Sơn Thủy",
						type: "Xã",
					},
					{
						level3_id: "19279",
						name: "Xã Lộc Thủy",
						type: "Xã",
					},
					{
						level3_id: "19285",
						name: "Xã Liên Thủy",
						type: "Xã",
					},
					{
						level3_id: "19288",
						name: "Xã Hưng Thủy",
						type: "Xã",
					},
					{
						level3_id: "19291",
						name: "Xã Dương Thủy",
						type: "Xã",
					},
					{
						level3_id: "19294",
						name: "Xã Tân Thủy",
						type: "Xã",
					},
					{
						level3_id: "19297",
						name: "Xã Phú Thủy",
						type: "Xã",
					},
					{
						level3_id: "19300",
						name: "Xã Xuân Thủy",
						type: "Xã",
					},
					{
						level3_id: "19303",
						name: "Xã Mỹ Thủy",
						type: "Xã",
					},
					{
						level3_id: "19306",
						name: "Xã Ngư Thủy ",
						type: "Xã",
					},
					{
						level3_id: "19309",
						name: "Xã Mai Thủy",
						type: "Xã",
					},
					{
						level3_id: "19312",
						name: "Xã Sen Thủy",
						type: "Xã",
					},
					{
						level3_id: "19315",
						name: "Xã Thái Thủy",
						type: "Xã",
					},
					{
						level3_id: "19318",
						name: "Xã Kim Thủy",
						type: "Xã",
					},
					{
						level3_id: "19321",
						name: "Xã Trường Thủy",
						type: "Xã",
					},
					{
						level3_id: "19327",
						name: "Xã Lâm Thủy",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "458",
				name: "Thị xã Ba Đồn",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "19009",
						name: "Phường Ba Đồn",
						type: "Phường",
					},
					{
						level3_id: "19060",
						name: "Phường Quảng Long",
						type: "Phường",
					},
					{
						level3_id: "19066",
						name: "Phường Quảng Thọ",
						type: "Phường",
					},
					{
						level3_id: "19069",
						name: "Xã Quảng Tiên",
						type: "Xã",
					},
					{
						level3_id: "19075",
						name: "Xã Quảng Trung",
						type: "Xã",
					},
					{
						level3_id: "19078",
						name: "Phường Quảng Phong",
						type: "Phường",
					},
					{
						level3_id: "19081",
						name: "Phường Quảng Thuận",
						type: "Phường",
					},
					{
						level3_id: "19084",
						name: "Xã Quảng Tân",
						type: "Xã",
					},
					{
						level3_id: "19087",
						name: "Xã Quảng Hải",
						type: "Xã",
					},
					{
						level3_id: "19090",
						name: "Xã Quảng Sơn",
						type: "Xã",
					},
					{
						level3_id: "19093",
						name: "Xã Quảng Lộc",
						type: "Xã",
					},
					{
						level3_id: "19096",
						name: "Xã Quảng Thủy",
						type: "Xã",
					},
					{
						level3_id: "19099",
						name: "Xã Quảng Văn",
						type: "Xã",
					},
					{
						level3_id: "19102",
						name: "Phường Quảng Phúc",
						type: "Phường",
					},
					{
						level3_id: "19105",
						name: "Xã Quảng Hòa",
						type: "Xã",
					},
					{
						level3_id: "19108",
						name: "Xã Quảng Minh",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "45",
		name: "Tỉnh Quảng Trị",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "461",
				name: "Thành phố Đông Hà",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "19330",
						name: "Phường Đông Giang",
						type: "Phường",
					},
					{
						level3_id: "19333",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "19336",
						name: "Phường Đông Lễ",
						type: "Phường",
					},
					{
						level3_id: "19339",
						name: "Phường Đông Thanh",
						type: "Phường",
					},
					{
						level3_id: "19342",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "19345",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "19348",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "19351",
						name: "Phường Đông Lương",
						type: "Phường",
					},
					{
						level3_id: "19354",
						name: "Phường 3",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "462",
				name: "Thị xã Quảng Trị",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "19357",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "19358",
						name: "Phường An Đôn",
						type: "Phường",
					},
					{
						level3_id: "19360",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "19361",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "19705",
						name: "Xã Hải Lệ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "464",
				name: "Huyện Vĩnh Linh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19363",
						name: "Thị trấn Hồ Xá",
						type: "Thị trấn",
					},
					{
						level3_id: "19366",
						name: "Thị trấn Bến Quan",
						type: "Thị trấn",
					},
					{
						level3_id: "19369",
						name: "Xã Vĩnh Thái",
						type: "Xã",
					},
					{
						level3_id: "19372",
						name: "Xã Vĩnh Tú",
						type: "Xã",
					},
					{
						level3_id: "19375",
						name: "Xã Vĩnh Chấp",
						type: "Xã",
					},
					{
						level3_id: "19378",
						name: "Xã Trung Nam",
						type: "Xã",
					},
					{
						level3_id: "19384",
						name: "Xã Kim Thạch",
						type: "Xã",
					},
					{
						level3_id: "19387",
						name: "Xã Vĩnh Long",
						type: "Xã",
					},
					{
						level3_id: "19393",
						name: "Xã Vĩnh Khê",
						type: "Xã",
					},
					{
						level3_id: "19396",
						name: "Xã Vĩnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "19402",
						name: "Xã Vĩnh Thủy",
						type: "Xã",
					},
					{
						level3_id: "19405",
						name: "Xã Vĩnh Lâm",
						type: "Xã",
					},
					{
						level3_id: "19408",
						name: "Xã Hiền Thành",
						type: "Xã",
					},
					{
						level3_id: "19414",
						name: "Thị trấn Cửa Tùng",
						type: "Thị trấn",
					},
					{
						level3_id: "19417",
						name: "Xã Vĩnh Hà",
						type: "Xã",
					},
					{
						level3_id: "19420",
						name: "Xã Vĩnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "19423",
						name: "Xã Vĩnh Giang",
						type: "Xã",
					},
					{
						level3_id: "19426",
						name: "Xã Vĩnh Ô",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "465",
				name: "Huyện Hướng Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19429",
						name: "Thị trấn Khe Sanh",
						type: "Thị trấn",
					},
					{
						level3_id: "19432",
						name: "Thị trấn Lao Bảo",
						type: "Thị trấn",
					},
					{
						level3_id: "19435",
						name: "Xã Hướng Lập",
						type: "Xã",
					},
					{
						level3_id: "19438",
						name: "Xã Hướng Việt",
						type: "Xã",
					},
					{
						level3_id: "19441",
						name: "Xã Hướng Phùng",
						type: "Xã",
					},
					{
						level3_id: "19444",
						name: "Xã Hướng Sơn",
						type: "Xã",
					},
					{
						level3_id: "19447",
						name: "Xã Hướng Linh",
						type: "Xã",
					},
					{
						level3_id: "19450",
						name: "Xã Tân Hợp",
						type: "Xã",
					},
					{
						level3_id: "19453",
						name: "Xã Hướng Tân",
						type: "Xã",
					},
					{
						level3_id: "19456",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "19459",
						name: "Xã Tân Long",
						type: "Xã",
					},
					{
						level3_id: "19462",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "19465",
						name: "Xã Tân Liên",
						type: "Xã",
					},
					{
						level3_id: "19468",
						name: "Xã Húc",
						type: "Xã",
					},
					{
						level3_id: "19471",
						name: "Xã Thuận",
						type: "Xã",
					},
					{
						level3_id: "19474",
						name: "Xã Hướng Lộc",
						type: "Xã",
					},
					{
						level3_id: "19477",
						name: "Xã Ba Tầng",
						type: "Xã",
					},
					{
						level3_id: "19480",
						name: "Xã Thanh",
						type: "Xã",
					},
					{
						level3_id: "19483",
						name: "Xã  A Dơi",
						type: "Xã",
					},
					{
						level3_id: "19489",
						name: "Xã Lìa",
						type: "Xã",
					},
					{
						level3_id: "19492",
						name: "Xã Xy",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "466",
				name: "Huyện Gio Linh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19495",
						name: "Thị trấn Gio Linh",
						type: "Thị trấn",
					},
					{
						level3_id: "19496",
						name: "Thị trấn Cửa Việt",
						type: "Thị trấn",
					},
					{
						level3_id: "19498",
						name: "Xã Trung Giang",
						type: "Xã",
					},
					{
						level3_id: "19501",
						name: "Xã Trung Hải",
						type: "Xã",
					},
					{
						level3_id: "19504",
						name: "Xã Trung Sơn",
						type: "Xã",
					},
					{
						level3_id: "19507",
						name: "Xã Phong Bình",
						type: "Xã",
					},
					{
						level3_id: "19510",
						name: "Xã Gio Mỹ",
						type: "Xã",
					},
					{
						level3_id: "19519",
						name: "Xã Gio Hải",
						type: "Xã",
					},
					{
						level3_id: "19522",
						name: "Xã Gio An",
						type: "Xã",
					},
					{
						level3_id: "19525",
						name: "Xã Gio Châu",
						type: "Xã",
					},
					{
						level3_id: "19531",
						name: "Xã Gio Việt",
						type: "Xã",
					},
					{
						level3_id: "19534",
						name: "Xã Linh Trường",
						type: "Xã",
					},
					{
						level3_id: "19537",
						name: "Xã Gio Sơn",
						type: "Xã",
					},
					{
						level3_id: "19543",
						name: "Xã Gio Mai",
						type: "Xã",
					},
					{
						level3_id: "19546",
						name: "Xã Hải Thái",
						type: "Xã",
					},
					{
						level3_id: "19549",
						name: "Xã Linh Hải",
						type: "Xã",
					},
					{
						level3_id: "19552",
						name: "Xã Gio Quang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "467",
				name: "Huyện Đa Krông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19555",
						name: "Thị trấn Krông Klang",
						type: "Thị trấn",
					},
					{
						level3_id: "19558",
						name: "Xã Mò Ó",
						type: "Xã",
					},
					{
						level3_id: "19561",
						name: "Xã Hướng Hiệp",
						type: "Xã",
					},
					{
						level3_id: "19564",
						name: "Xã Đa Krông",
						type: "Xã",
					},
					{
						level3_id: "19567",
						name: "Xã Triệu Nguyên",
						type: "Xã",
					},
					{
						level3_id: "19570",
						name: "Xã Ba Lòng",
						type: "Xã",
					},
					{
						level3_id: "19576",
						name: "Xã Ba Nang",
						type: "Xã",
					},
					{
						level3_id: "19579",
						name: "Xã Tà Long",
						type: "Xã",
					},
					{
						level3_id: "19582",
						name: "Xã Húc Nghì",
						type: "Xã",
					},
					{
						level3_id: "19585",
						name: "Xã A Vao",
						type: "Xã",
					},
					{
						level3_id: "19588",
						name: "Xã Tà Rụt",
						type: "Xã",
					},
					{
						level3_id: "19591",
						name: "Xã A Bung",
						type: "Xã",
					},
					{
						level3_id: "19594",
						name: "Xã A Ngo",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "468",
				name: "Huyện Cam Lộ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19597",
						name: "Thị trấn Cam Lộ",
						type: "Thị trấn",
					},
					{
						level3_id: "19600",
						name: "Xã Cam Tuyền",
						type: "Xã",
					},
					{
						level3_id: "19603",
						name: "Xã Thanh An",
						type: "Xã",
					},
					{
						level3_id: "19606",
						name: "Xã Cam Thủy",
						type: "Xã",
					},
					{
						level3_id: "19612",
						name: "Xã Cam Thành",
						type: "Xã",
					},
					{
						level3_id: "19615",
						name: "Xã Cam Hiếu",
						type: "Xã",
					},
					{
						level3_id: "19618",
						name: "Xã Cam Chính",
						type: "Xã",
					},
					{
						level3_id: "19621",
						name: "Xã Cam Nghĩa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "469",
				name: "Huyện Triệu Phong",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19624",
						name: "Thị Trấn Ái Tử",
						type: "Thị trấn",
					},
					{
						level3_id: "19627",
						name: "Xã Triệu An",
						type: "Xã",
					},
					{
						level3_id: "19630",
						name: "Xã Triệu Vân",
						type: "Xã",
					},
					{
						level3_id: "19633",
						name: "Xã Triệu Phước",
						type: "Xã",
					},
					{
						level3_id: "19636",
						name: "Xã Triệu Độ",
						type: "Xã",
					},
					{
						level3_id: "19639",
						name: "Xã Triệu Trạch",
						type: "Xã",
					},
					{
						level3_id: "19642",
						name: "Xã Triệu Thuận",
						type: "Xã",
					},
					{
						level3_id: "19645",
						name: "Xã Triệu Đại",
						type: "Xã",
					},
					{
						level3_id: "19648",
						name: "Xã Triệu Hòa",
						type: "Xã",
					},
					{
						level3_id: "19651",
						name: "Xã Triệu Lăng",
						type: "Xã",
					},
					{
						level3_id: "19654",
						name: "Xã Triệu Sơn",
						type: "Xã",
					},
					{
						level3_id: "19657",
						name: "Xã Triệu Long",
						type: "Xã",
					},
					{
						level3_id: "19660",
						name: "Xã Triệu Tài",
						type: "Xã",
					},
					{
						level3_id: "19666",
						name: "Xã Triệu Trung",
						type: "Xã",
					},
					{
						level3_id: "19669",
						name: "Xã Triệu Ái",
						type: "Xã",
					},
					{
						level3_id: "19672",
						name: "Xã Triệu Thượng",
						type: "Xã",
					},
					{
						level3_id: "19675",
						name: "Xã Triệu Giang",
						type: "Xã",
					},
					{
						level3_id: "19678",
						name: "Xã Triệu Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "470",
				name: "Huyện Hải Lăng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19681",
						name: "Thị trấn Diên Sanh",
						type: "Thị trấn",
					},
					{
						level3_id: "19684",
						name: "Xã Hải An",
						type: "Xã",
					},
					{
						level3_id: "19687",
						name: "Xã Hải Ba",
						type: "Xã",
					},
					{
						level3_id: "19693",
						name: "Xã Hải Quy",
						type: "Xã",
					},
					{
						level3_id: "19696",
						name: "Xã Hải Quế",
						type: "Xã",
					},
					{
						level3_id: "19699",
						name: "Xã Hải Hưng",
						type: "Xã",
					},
					{
						level3_id: "19702",
						name: "Xã Hải Phú",
						type: "Xã",
					},
					{
						level3_id: "19708",
						name: "Xã Hải Thượng",
						type: "Xã",
					},
					{
						level3_id: "19711",
						name: "Xã Hải Dương",
						type: "Xã",
					},
					{
						level3_id: "19714",
						name: "Xã Hải Định",
						type: "Xã",
					},
					{
						level3_id: "19717",
						name: "Xã Hải Lâm",
						type: "Xã",
					},
					{
						level3_id: "19726",
						name: "Xã Hải Phong",
						type: "Xã",
					},
					{
						level3_id: "19729",
						name: "Xã Hải Trường",
						type: "Xã",
					},
					{
						level3_id: "19735",
						name: "Xã Hải Sơn",
						type: "Xã",
					},
					{
						level3_id: "19738",
						name: "Xã Hải Chánh",
						type: "Xã",
					},
					{
						level3_id: "19741",
						name: "Xã Hải Khê",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "471",
				name: "Huyện Cồn Cỏ",
				type: "Huyện",
				level3s: [],
			},
		],
	},
	{
		level1_id: "46",
		name: "Tỉnh Thừa Thiên Huế",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "474",
				name: "Thành phố Huế",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "19744",
						name: "Phường Phú Thuận",
						type: "Phường",
					},
					{
						level3_id: "19747",
						name: "Phường Phú Bình",
						type: "Phường",
					},
					{
						level3_id: "19750",
						name: "Phường Tây Lộc",
						type: "Phường",
					},
					{
						level3_id: "19753",
						name: "Phường Thuận Lộc",
						type: "Phường",
					},
					{
						level3_id: "19756",
						name: "Phường Phú Hiệp",
						type: "Phường",
					},
					{
						level3_id: "19759",
						name: "Phường Phú Hậu",
						type: "Phường",
					},
					{
						level3_id: "19762",
						name: "Phường Thuận Hòa",
						type: "Phường",
					},
					{
						level3_id: "19765",
						name: "Phường Thuận Thành",
						type: "Phường",
					},
					{
						level3_id: "19768",
						name: "Phường Phú Hòa",
						type: "Phường",
					},
					{
						level3_id: "19771",
						name: "Phường Phú Cát",
						type: "Phường",
					},
					{
						level3_id: "19774",
						name: "Phường Kim Long",
						type: "Phường",
					},
					{
						level3_id: "19777",
						name: "Phường Vỹ Dạ",
						type: "Phường",
					},
					{
						level3_id: "19780",
						name: "Phường Phường Đúc",
						type: "Phường",
					},
					{
						level3_id: "19783",
						name: "Phường Vĩnh Ninh",
						type: "Phường",
					},
					{
						level3_id: "19786",
						name: "Phường Phú Hội",
						type: "Phường",
					},
					{
						level3_id: "19789",
						name: "Phường Phú Nhuận",
						type: "Phường",
					},
					{
						level3_id: "19792",
						name: "Phường Xuân Phú",
						type: "Phường",
					},
					{
						level3_id: "19795",
						name: "Phường Trường An",
						type: "Phường",
					},
					{
						level3_id: "19798",
						name: "Phường Phước Vĩnh",
						type: "Phường",
					},
					{
						level3_id: "19801",
						name: "Phường An Cựu",
						type: "Phường",
					},
					{
						level3_id: "19803",
						name: "Phường An Hòa",
						type: "Phường",
					},
					{
						level3_id: "19804",
						name: "Phường Hương Sơ",
						type: "Phường",
					},
					{
						level3_id: "19807",
						name: "Phường Thuỷ Biều",
						type: "Phường",
					},
					{
						level3_id: "19810",
						name: "Phường Hương Long",
						type: "Phường",
					},
					{
						level3_id: "19813",
						name: "Phường Thuỷ Xuân",
						type: "Phường",
					},
					{
						level3_id: "19815",
						name: "Phường An Đông",
						type: "Phường",
					},
					{
						level3_id: "19816",
						name: "Phường An Tây",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "476",
				name: "Huyện Phong Điền",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19819",
						name: "Thị trấn Phong Điền",
						type: "Thị trấn",
					},
					{
						level3_id: "19822",
						name: "Xã Điền Hương",
						type: "Xã",
					},
					{
						level3_id: "19825",
						name: "Xã Điền Môn",
						type: "Xã",
					},
					{
						level3_id: "19828",
						name: "Xã Điền Lộc",
						type: "Xã",
					},
					{
						level3_id: "19831",
						name: "Xã Phong Bình",
						type: "Xã",
					},
					{
						level3_id: "19834",
						name: "Xã Điền Hòa",
						type: "Xã",
					},
					{
						level3_id: "19837",
						name: "Xã Phong Chương",
						type: "Xã",
					},
					{
						level3_id: "19840",
						name: "Xã Phong Hải",
						type: "Xã",
					},
					{
						level3_id: "19843",
						name: "Xã Điền Hải",
						type: "Xã",
					},
					{
						level3_id: "19846",
						name: "Xã Phong Hòa",
						type: "Xã",
					},
					{
						level3_id: "19849",
						name: "Xã Phong Thu",
						type: "Xã",
					},
					{
						level3_id: "19852",
						name: "Xã Phong Hiền",
						type: "Xã",
					},
					{
						level3_id: "19855",
						name: "Xã Phong Mỹ",
						type: "Xã",
					},
					{
						level3_id: "19858",
						name: "Xã Phong An",
						type: "Xã",
					},
					{
						level3_id: "19861",
						name: "Xã Phong Xuân",
						type: "Xã",
					},
					{
						level3_id: "19864",
						name: "Xã Phong Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "477",
				name: "Huyện Quảng Điền",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19867",
						name: "Thị trấn Sịa",
						type: "Thị trấn",
					},
					{
						level3_id: "19870",
						name: "Xã Quảng Thái",
						type: "Xã",
					},
					{
						level3_id: "19873",
						name: "Xã Quảng Ngạn",
						type: "Xã",
					},
					{
						level3_id: "19876",
						name: "Xã Quảng Lợi",
						type: "Xã",
					},
					{
						level3_id: "19879",
						name: "Xã Quảng Công",
						type: "Xã",
					},
					{
						level3_id: "19882",
						name: "Xã Quảng Phước",
						type: "Xã",
					},
					{
						level3_id: "19885",
						name: "Xã Quảng Vinh",
						type: "Xã",
					},
					{
						level3_id: "19888",
						name: "Xã Quảng An",
						type: "Xã",
					},
					{
						level3_id: "19891",
						name: "Xã Quảng Thành",
						type: "Xã",
					},
					{
						level3_id: "19894",
						name: "Xã Quảng Thọ",
						type: "Xã",
					},
					{
						level3_id: "19897",
						name: "Xã Quảng Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "478",
				name: "Huyện Phú Vang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "19900",
						name: "Thị trấn Thuận An",
						type: "Thị trấn",
					},
					{
						level3_id: "19903",
						name: "Xã Phú Thuận",
						type: "Xã",
					},
					{
						level3_id: "19906",
						name: "Xã Phú Dương",
						type: "Xã",
					},
					{
						level3_id: "19909",
						name: "Xã Phú Mậu",
						type: "Xã",
					},
					{
						level3_id: "19912",
						name: "Xã Phú An",
						type: "Xã",
					},
					{
						level3_id: "19915",
						name: "Xã Phú Hải",
						type: "Xã",
					},
					{
						level3_id: "19918",
						name: "Xã Phú Xuân",
						type: "Xã",
					},
					{
						level3_id: "19921",
						name: "Xã Phú Diên",
						type: "Xã",
					},
					{
						level3_id: "19924",
						name: "Xã Phú Thanh",
						type: "Xã",
					},
					{
						level3_id: "19927",
						name: "Xã Phú Mỹ",
						type: "Xã",
					},
					{
						level3_id: "19930",
						name: "Xã Phú Thượng",
						type: "Xã",
					},
					{
						level3_id: "19933",
						name: "Xã Phú Hồ",
						type: "Xã",
					},
					{
						level3_id: "19936",
						name: "Xã Vinh Xuân",
						type: "Xã",
					},
					{
						level3_id: "19939",
						name: "Xã Phú Lương",
						type: "Xã",
					},
					{
						level3_id: "19942",
						name: "Thị trấn Phú Đa",
						type: "Thị trấn",
					},
					{
						level3_id: "19945",
						name: "Xã Vinh Thanh",
						type: "Xã",
					},
					{
						level3_id: "19948",
						name: "Xã Vinh An",
						type: "Xã",
					},
					{
						level3_id: "19954",
						name: "Xã Phú Gia",
						type: "Xã",
					},
					{
						level3_id: "19957",
						name: "Xã Vinh Hà",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "479",
				name: "Thị xã Hương Thủy",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "19960",
						name: "Phường Phú Bài",
						type: "Phường",
					},
					{
						level3_id: "19963",
						name: "Xã Thủy Vân",
						type: "Xã",
					},
					{
						level3_id: "19966",
						name: "Xã Thủy Thanh",
						type: "Xã",
					},
					{
						level3_id: "19969",
						name: "Phường Thủy Dương",
						type: "Phường",
					},
					{
						level3_id: "19972",
						name: "Phường Thủy Phương",
						type: "Phường",
					},
					{
						level3_id: "19975",
						name: "Phường Thủy Châu",
						type: "Phường",
					},
					{
						level3_id: "19978",
						name: "Phường Thủy Lương",
						type: "Phường",
					},
					{
						level3_id: "19981",
						name: "Xã Thủy Bằng",
						type: "Xã",
					},
					{
						level3_id: "19984",
						name: "Xã Thủy Tân",
						type: "Xã",
					},
					{
						level3_id: "19987",
						name: "Xã Thủy Phù",
						type: "Xã",
					},
					{
						level3_id: "19990",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "19993",
						name: "Xã Dương Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "480",
				name: "Thị xã Hương Trà",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "19996",
						name: "Phường Tứ Hạ",
						type: "Phường",
					},
					{
						level3_id: "19999",
						name: "Xã Hải Dương",
						type: "Xã",
					},
					{
						level3_id: "20002",
						name: "Xã Hương Phong",
						type: "Xã",
					},
					{
						level3_id: "20005",
						name: "Xã Hương Toàn",
						type: "Xã",
					},
					{
						level3_id: "20008",
						name: "Phường Hương Vân",
						type: "Phường",
					},
					{
						level3_id: "20011",
						name: "Phường Hương Văn",
						type: "Phường",
					},
					{
						level3_id: "20014",
						name: "Xã Hương Vinh",
						type: "Xã",
					},
					{
						level3_id: "20017",
						name: "Phường Hương Xuân",
						type: "Phường",
					},
					{
						level3_id: "20020",
						name: "Phường Hương Chữ",
						type: "Phường",
					},
					{
						level3_id: "20023",
						name: "Phường Hương An",
						type: "Phường",
					},
					{
						level3_id: "20026",
						name: "Xã Hương Bình",
						type: "Xã",
					},
					{
						level3_id: "20029",
						name: "Phường Hương Hồ",
						type: "Phường",
					},
					{
						level3_id: "20032",
						name: "Xã Hương Thọ",
						type: "Xã",
					},
					{
						level3_id: "20035",
						name: "Xã Bình Tiến",
						type: "Xã",
					},
					{
						level3_id: "20041",
						name: "Xã Bình Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "481",
				name: "Huyện A Lưới",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20044",
						name: "Thị trấn A Lưới",
						type: "Thị trấn",
					},
					{
						level3_id: "20047",
						name: "Xã Hồng Vân",
						type: "Xã",
					},
					{
						level3_id: "20050",
						name: "Xã Hồng Hạ",
						type: "Xã",
					},
					{
						level3_id: "20053",
						name: "Xã Hồng Kim",
						type: "Xã",
					},
					{
						level3_id: "20056",
						name: "Xã Trung Sơn",
						type: "Xã",
					},
					{
						level3_id: "20059",
						name: "Xã Hương Nguyên",
						type: "Xã",
					},
					{
						level3_id: "20065",
						name: "Xã Hồng Bắc",
						type: "Xã",
					},
					{
						level3_id: "20068",
						name: "Xã A Ngo",
						type: "Xã",
					},
					{
						level3_id: "20071",
						name: "Xã Sơn Thủy",
						type: "Xã",
					},
					{
						level3_id: "20074",
						name: "Xã Phú Vinh",
						type: "Xã",
					},
					{
						level3_id: "20080",
						name: "Xã Hương Phong",
						type: "Xã",
					},
					{
						level3_id: "20083",
						name: "Xã Quảng Nhâm",
						type: "Xã",
					},
					{
						level3_id: "20086",
						name: "Xã Hồng Thượng",
						type: "Xã",
					},
					{
						level3_id: "20089",
						name: "Xã Hồng Thái",
						type: "Xã",
					},
					{
						level3_id: "20095",
						name: "Xã A Roàng",
						type: "Xã",
					},
					{
						level3_id: "20098",
						name: "Xã Đông Sơn",
						type: "Xã",
					},
					{
						level3_id: "20101",
						name: "Xã Lâm Đớt",
						type: "Xã",
					},
					{
						level3_id: "20104",
						name: "Xã Hồng Thủy",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "482",
				name: "Huyện Phú Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20107",
						name: "Thị trấn Phú Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "20110",
						name: "Thị trấn Lăng Cô",
						type: "Thị trấn",
					},
					{
						level3_id: "20113",
						name: "Xã Vinh Mỹ",
						type: "Xã",
					},
					{
						level3_id: "20116",
						name: "Xã Vinh Hưng",
						type: "Xã",
					},
					{
						level3_id: "20122",
						name: "Xã Giang Hải",
						type: "Xã",
					},
					{
						level3_id: "20125",
						name: "Xã Vinh Hiền",
						type: "Xã",
					},
					{
						level3_id: "20128",
						name: "Xã Lộc Bổn",
						type: "Xã",
					},
					{
						level3_id: "20131",
						name: "Xã Lộc Sơn",
						type: "Xã",
					},
					{
						level3_id: "20134",
						name: "Xã Lộc Bình",
						type: "Xã",
					},
					{
						level3_id: "20137",
						name: "Xã Lộc Vĩnh",
						type: "Xã",
					},
					{
						level3_id: "20140",
						name: "Xã Lộc An",
						type: "Xã",
					},
					{
						level3_id: "20143",
						name: "Xã Lộc Điền",
						type: "Xã",
					},
					{
						level3_id: "20146",
						name: "Xã Lộc Thủy",
						type: "Xã",
					},
					{
						level3_id: "20149",
						name: "Xã Lộc Trì",
						type: "Xã",
					},
					{
						level3_id: "20152",
						name: "Xã Lộc Tiến",
						type: "Xã",
					},
					{
						level3_id: "20155",
						name: "Xã Lộc Hòa",
						type: "Xã",
					},
					{
						level3_id: "20158",
						name: "Xã Xuân Lộc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "483",
				name: "Huyện Nam Đông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20161",
						name: "Thị trấn Khe Tre",
						type: "Thị trấn",
					},
					{
						level3_id: "20164",
						name: "Xã Hương Phú",
						type: "Xã",
					},
					{
						level3_id: "20167",
						name: "Xã Hương Sơn",
						type: "Xã",
					},
					{
						level3_id: "20170",
						name: "Xã Hương Lộc",
						type: "Xã",
					},
					{
						level3_id: "20173",
						name: "Xã Thượng Quảng",
						type: "Xã",
					},
					{
						level3_id: "20179",
						name: "Xã Hương Xuân",
						type: "Xã",
					},
					{
						level3_id: "20182",
						name: "Xã Hương Hữu",
						type: "Xã",
					},
					{
						level3_id: "20185",
						name: "Xã Thượng Lộ",
						type: "Xã",
					},
					{
						level3_id: "20188",
						name: "Xã Thượng Long",
						type: "Xã",
					},
					{
						level3_id: "20191",
						name: "Xã Thượng Nhật",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "48",
		name: "Thành phố Đà Nẵng",
		type: "Thành phố Trung ương",
		level2s: [
			{
				level2_id: "490",
				name: "Quận Liên Chiểu",
				type: "Quận",
				level3s: [
					{
						level3_id: "20194",
						name: "Phường Hòa Hiệp Bắc",
						type: "Phường",
					},
					{
						level3_id: "20195",
						name: "Phường Hòa Hiệp Nam",
						type: "Phường",
					},
					{
						level3_id: "20197",
						name: "Phường Hòa Khánh Bắc",
						type: "Phường",
					},
					{
						level3_id: "20198",
						name: "Phường Hòa Khánh Nam",
						type: "Phường",
					},
					{
						level3_id: "20200",
						name: "Phường Hòa Minh",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "491",
				name: "Quận Thanh Khê",
				type: "Quận",
				level3s: [
					{
						level3_id: "20203",
						name: "Phường Tam Thuận",
						type: "Phường",
					},
					{
						level3_id: "20206",
						name: "Phường Thanh Khê Tây",
						type: "Phường",
					},
					{
						level3_id: "20207",
						name: "Phường Thanh Khê Đông",
						type: "Phường",
					},
					{
						level3_id: "20209",
						name: "Phường Xuân Hà",
						type: "Phường",
					},
					{
						level3_id: "20212",
						name: "Phường Tân Chính",
						type: "Phường",
					},
					{
						level3_id: "20215",
						name: "Phường Chính Gián",
						type: "Phường",
					},
					{
						level3_id: "20218",
						name: "Phường Vĩnh Trung",
						type: "Phường",
					},
					{
						level3_id: "20221",
						name: "Phường Thạc Gián",
						type: "Phường",
					},
					{
						level3_id: "20224",
						name: "Phường An Khê",
						type: "Phường",
					},
					{
						level3_id: "20225",
						name: "Phường Hòa Khê",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "492",
				name: "Quận Hải Châu",
				type: "Quận",
				level3s: [
					{
						level3_id: "20227",
						name: "Phường Thanh Bình",
						type: "Phường",
					},
					{
						level3_id: "20230",
						name: "Phường Thuận Phước",
						type: "Phường",
					},
					{
						level3_id: "20233",
						name: "Phường Thạch Thang",
						type: "Phường",
					},
					{
						level3_id: "20236",
						name: "Phường Hải Châu  I",
						type: "Phường",
					},
					{
						level3_id: "20239",
						name: "Phường Hải Châu II",
						type: "Phường",
					},
					{
						level3_id: "20242",
						name: "Phường Phước Ninh",
						type: "Phường",
					},
					{
						level3_id: "20245",
						name: "Phường Hòa Thuận Tây",
						type: "Phường",
					},
					{
						level3_id: "20246",
						name: "Phường Hòa Thuận Đông",
						type: "Phường",
					},
					{
						level3_id: "20248",
						name: "Phường Nam Dương",
						type: "Phường",
					},
					{
						level3_id: "20251",
						name: "Phường Bình Hiên",
						type: "Phường",
					},
					{
						level3_id: "20254",
						name: "Phường Bình Thuận",
						type: "Phường",
					},
					{
						level3_id: "20257",
						name: "Phường Hòa Cường Bắc",
						type: "Phường",
					},
					{
						level3_id: "20258",
						name: "Phường Hòa Cường Nam",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "493",
				name: "Quận Sơn Trà",
				type: "Quận",
				level3s: [
					{
						level3_id: "20263",
						name: "Phường Thọ Quang",
						type: "Phường",
					},
					{
						level3_id: "20266",
						name: "Phường Nại Hiên Đông",
						type: "Phường",
					},
					{
						level3_id: "20269",
						name: "Phường Mân Thái",
						type: "Phường",
					},
					{
						level3_id: "20272",
						name: "Phường An Hải Bắc",
						type: "Phường",
					},
					{
						level3_id: "20275",
						name: "Phường Phước Mỹ",
						type: "Phường",
					},
					{
						level3_id: "20278",
						name: "Phường An Hải Tây",
						type: "Phường",
					},
					{
						level3_id: "20281",
						name: "Phường An Hải Đông",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "494",
				name: "Quận Ngũ Hành Sơn",
				type: "Quận",
				level3s: [
					{
						level3_id: "20284",
						name: "Phường Mỹ An",
						type: "Phường",
					},
					{
						level3_id: "20285",
						name: "Phường Khuê Mỹ",
						type: "Phường",
					},
					{
						level3_id: "20287",
						name: "Phường Hoà Quý",
						type: "Phường",
					},
					{
						level3_id: "20290",
						name: "Phường Hoà Hải",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "495",
				name: "Quận Cẩm Lệ",
				type: "Quận",
				level3s: [
					{
						level3_id: "20260",
						name: "Phường Khuê Trung",
						type: "Phường",
					},
					{
						level3_id: "20305",
						name: "Phường Hòa Phát",
						type: "Phường",
					},
					{
						level3_id: "20306",
						name: "Phường Hòa An",
						type: "Phường",
					},
					{
						level3_id: "20311",
						name: "Phường Hòa Thọ Tây",
						type: "Phường",
					},
					{
						level3_id: "20312",
						name: "Phường Hòa Thọ Đông",
						type: "Phường",
					},
					{
						level3_id: "20314",
						name: "Phường Hòa Xuân",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "497",
				name: "Huyện Hòa Vang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20293",
						name: "Xã Hòa Bắc",
						type: "Xã",
					},
					{
						level3_id: "20296",
						name: "Xã Hòa Liên",
						type: "Xã",
					},
					{
						level3_id: "20299",
						name: "Xã Hòa Ninh",
						type: "Xã",
					},
					{
						level3_id: "20302",
						name: "Xã Hòa Sơn",
						type: "Xã",
					},
					{
						level3_id: "20308",
						name: "Xã Hòa Nhơn",
						type: "Xã",
					},
					{
						level3_id: "20317",
						name: "Xã Hòa Phú",
						type: "Xã",
					},
					{
						level3_id: "20320",
						name: "Xã Hòa Phong",
						type: "Xã",
					},
					{
						level3_id: "20323",
						name: "Xã Hòa Châu",
						type: "Xã",
					},
					{
						level3_id: "20326",
						name: "Xã Hòa Tiến",
						type: "Xã",
					},
					{
						level3_id: "20329",
						name: "Xã Hòa Phước",
						type: "Xã",
					},
					{
						level3_id: "20332",
						name: "Xã Hòa Khương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "498",
				name: "Huyện Hoàng Sa",
				type: "Huyện",
				level3s: [],
			},
		],
	},
	{
		level1_id: "49",
		name: "Tỉnh Quảng Nam",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "502",
				name: "Thành phố Tam Kỳ",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "20335",
						name: "Phường Tân Thạnh",
						type: "Phường",
					},
					{
						level3_id: "20338",
						name: "Phường Phước Hòa",
						type: "Phường",
					},
					{
						level3_id: "20341",
						name: "Phường An Mỹ",
						type: "Phường",
					},
					{
						level3_id: "20344",
						name: "Phường Hòa Hương",
						type: "Phường",
					},
					{
						level3_id: "20347",
						name: "Phường An Xuân",
						type: "Phường",
					},
					{
						level3_id: "20350",
						name: "Phường An Sơn",
						type: "Phường",
					},
					{
						level3_id: "20353",
						name: "Phường Trường Xuân",
						type: "Phường",
					},
					{
						level3_id: "20356",
						name: "Phường An Phú",
						type: "Phường",
					},
					{
						level3_id: "20359",
						name: "Xã Tam Thanh",
						type: "Xã",
					},
					{
						level3_id: "20362",
						name: "Xã Tam Thăng",
						type: "Xã",
					},
					{
						level3_id: "20371",
						name: "Xã Tam Phú",
						type: "Xã",
					},
					{
						level3_id: "20375",
						name: "Phường Hoà Thuận",
						type: "Phường",
					},
					{
						level3_id: "20389",
						name: "Xã Tam Ngọc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "503",
				name: "Thành phố Hội An",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "20398",
						name: "Phường Minh An",
						type: "Phường",
					},
					{
						level3_id: "20401",
						name: "Phường Tân An",
						type: "Phường",
					},
					{
						level3_id: "20404",
						name: "Phường Cẩm Phô",
						type: "Phường",
					},
					{
						level3_id: "20407",
						name: "Phường Thanh Hà",
						type: "Phường",
					},
					{
						level3_id: "20410",
						name: "Phường Sơn Phong",
						type: "Phường",
					},
					{
						level3_id: "20413",
						name: "Phường Cẩm Châu",
						type: "Phường",
					},
					{
						level3_id: "20416",
						name: "Phường Cửa Đại",
						type: "Phường",
					},
					{
						level3_id: "20419",
						name: "Phường Cẩm An",
						type: "Phường",
					},
					{
						level3_id: "20422",
						name: "Xã Cẩm Hà",
						type: "Xã",
					},
					{
						level3_id: "20425",
						name: "Xã Cẩm Kim",
						type: "Xã",
					},
					{
						level3_id: "20428",
						name: "Phường Cẩm Nam",
						type: "Phường",
					},
					{
						level3_id: "20431",
						name: "Xã Cẩm Thanh",
						type: "Xã",
					},
					{
						level3_id: "20434",
						name: "Xã Tân Hiệp",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "504",
				name: "Huyện Tây Giang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20437",
						name: "Xã Ch'ơm",
						type: "Xã",
					},
					{
						level3_id: "20440",
						name: "Xã Ga Ri",
						type: "Xã",
					},
					{
						level3_id: "20443",
						name: "Xã A Xan",
						type: "Xã",
					},
					{
						level3_id: "20446",
						name: "Xã Tr'Hy",
						type: "Xã",
					},
					{
						level3_id: "20449",
						name: "Xã Lăng",
						type: "Xã",
					},
					{
						level3_id: "20452",
						name: "Xã A Nông",
						type: "Xã",
					},
					{
						level3_id: "20455",
						name: "Xã A Tiêng",
						type: "Xã",
					},
					{
						level3_id: "20458",
						name: "Xã Bha Lê",
						type: "Xã",
					},
					{
						level3_id: "20461",
						name: "Xã A Vương",
						type: "Xã",
					},
					{
						level3_id: "20464",
						name: "Xã Dang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "505",
				name: "Huyện Đông Giang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20467",
						name: "Thị trấn P Rao",
						type: "Thị trấn",
					},
					{
						level3_id: "20470",
						name: "Xã Tà Lu",
						type: "Xã",
					},
					{
						level3_id: "20473",
						name: "Xã Sông Kôn",
						type: "Xã",
					},
					{
						level3_id: "20476",
						name: "Xã Jơ Ngây",
						type: "Xã",
					},
					{
						level3_id: "20479",
						name: "Xã A Ting",
						type: "Xã",
					},
					{
						level3_id: "20482",
						name: "Xã  Tư",
						type: "Xã",
					},
					{
						level3_id: "20485",
						name: "Xã Ba",
						type: "Xã",
					},
					{
						level3_id: "20488",
						name: "Xã A Rooi",
						type: "Xã",
					},
					{
						level3_id: "20491",
						name: "Xã Za Hung",
						type: "Xã",
					},
					{
						level3_id: "20494",
						name: "Xã Mà Cooi",
						type: "Xã",
					},
					{
						level3_id: "20497",
						name: "Xã Ka Dăng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "506",
				name: "Huyện Đại Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20500",
						name: "Thị Trấn Ái Nghĩa",
						type: "Thị trấn",
					},
					{
						level3_id: "20503",
						name: "Xã Đại Sơn",
						type: "Xã",
					},
					{
						level3_id: "20506",
						name: "Xã Đại Lãnh",
						type: "Xã",
					},
					{
						level3_id: "20509",
						name: "Xã Đại Hưng",
						type: "Xã",
					},
					{
						level3_id: "20512",
						name: "Xã Đại Hồng",
						type: "Xã",
					},
					{
						level3_id: "20515",
						name: "Xã Đại Đồng",
						type: "Xã",
					},
					{
						level3_id: "20518",
						name: "Xã Đại Quang",
						type: "Xã",
					},
					{
						level3_id: "20521",
						name: "Xã Đại Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "20524",
						name: "Xã Đại Hiệp",
						type: "Xã",
					},
					{
						level3_id: "20527",
						name: "Xã Đại Thạnh",
						type: "Xã",
					},
					{
						level3_id: "20530",
						name: "Xã Đại Chánh",
						type: "Xã",
					},
					{
						level3_id: "20533",
						name: "Xã Đại Tân",
						type: "Xã",
					},
					{
						level3_id: "20536",
						name: "Xã Đại Phong",
						type: "Xã",
					},
					{
						level3_id: "20539",
						name: "Xã Đại Minh",
						type: "Xã",
					},
					{
						level3_id: "20542",
						name: "Xã Đại Thắng",
						type: "Xã",
					},
					{
						level3_id: "20545",
						name: "Xã Đại Cường",
						type: "Xã",
					},
					{
						level3_id: "20547",
						name: "Xã Đại An",
						type: "Xã",
					},
					{
						level3_id: "20548",
						name: "Xã Đại Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "507",
				name: "Thị xã Điện Bàn",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "20551",
						name: "Phường Vĩnh Điện",
						type: "Phường",
					},
					{
						level3_id: "20554",
						name: "Xã Điện Tiến",
						type: "Xã",
					},
					{
						level3_id: "20557",
						name: "Xã Điện Hòa",
						type: "Xã",
					},
					{
						level3_id: "20560",
						name: "Xã Điện Thắng Bắc",
						type: "Xã",
					},
					{
						level3_id: "20561",
						name: "Xã Điện Thắng Trung",
						type: "Xã",
					},
					{
						level3_id: "20562",
						name: "Xã Điện Thắng Nam",
						type: "Xã",
					},
					{
						level3_id: "20563",
						name: "Phường Điện Ngọc",
						type: "Phường",
					},
					{
						level3_id: "20566",
						name: "Xã Điện Hồng",
						type: "Xã",
					},
					{
						level3_id: "20569",
						name: "Xã Điện Thọ",
						type: "Xã",
					},
					{
						level3_id: "20572",
						name: "Xã Điện Phước",
						type: "Xã",
					},
					{
						level3_id: "20575",
						name: "Phường Điện An",
						type: "Phường",
					},
					{
						level3_id: "20578",
						name: "Phường Điện Nam Bắc",
						type: "Phường",
					},
					{
						level3_id: "20579",
						name: "Phường Điện Nam Trung",
						type: "Phường",
					},
					{
						level3_id: "20580",
						name: "Phường Điện Nam Đông",
						type: "Phường",
					},
					{
						level3_id: "20581",
						name: "Phường Điện Dương",
						type: "Phường",
					},
					{
						level3_id: "20584",
						name: "Xã Điện Quang",
						type: "Xã",
					},
					{
						level3_id: "20587",
						name: "Xã Điện Trung",
						type: "Xã",
					},
					{
						level3_id: "20590",
						name: "Xã Điện Phong",
						type: "Xã",
					},
					{
						level3_id: "20593",
						name: "Xã Điện Minh",
						type: "Xã",
					},
					{
						level3_id: "20596",
						name: "Xã Điện Phương",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "508",
				name: "Huyện Duy Xuyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20599",
						name: "Thị trấn Nam Phước",
						type: "Thị trấn",
					},
					{
						level3_id: "20602",
						name: "Xã Duy Thu",
						type: "Xã",
					},
					{
						level3_id: "20605",
						name: "Xã Duy Phú",
						type: "Xã",
					},
					{
						level3_id: "20608",
						name: "Xã Duy Tân",
						type: "Xã",
					},
					{
						level3_id: "20611",
						name: "Xã Duy Hòa",
						type: "Xã",
					},
					{
						level3_id: "20614",
						name: "Xã Duy Châu",
						type: "Xã",
					},
					{
						level3_id: "20617",
						name: "Xã Duy Trinh",
						type: "Xã",
					},
					{
						level3_id: "20620",
						name: "Xã Duy Sơn",
						type: "Xã",
					},
					{
						level3_id: "20623",
						name: "Xã Duy Trung",
						type: "Xã",
					},
					{
						level3_id: "20626",
						name: "Xã Duy Phước",
						type: "Xã",
					},
					{
						level3_id: "20629",
						name: "Xã Duy Thành",
						type: "Xã",
					},
					{
						level3_id: "20632",
						name: "Xã Duy Vinh",
						type: "Xã",
					},
					{
						level3_id: "20635",
						name: "Xã Duy Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "20638",
						name: "Xã Duy Hải",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "509",
				name: "Huyện Quế Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20641",
						name: "Thị trấn Đông Phú",
						type: "Thị trấn",
					},
					{
						level3_id: "20644",
						name: "Xã Quế Xuân 1",
						type: "Xã",
					},
					{
						level3_id: "20647",
						name: "Xã Quế Xuân 2",
						type: "Xã",
					},
					{
						level3_id: "20650",
						name: "Xã Quế Phú",
						type: "Xã",
					},
					{
						level3_id: "20651",
						name: "Thị trấn Hương An",
						type: "Thị trấn",
					},
					{
						level3_id: "20659",
						name: "Xã Quế Hiệp",
						type: "Xã",
					},
					{
						level3_id: "20662",
						name: "Xã Quế Thuận",
						type: "Xã",
					},
					{
						level3_id: "20665",
						name: "Xã Quế Mỹ",
						type: "Xã",
					},
					{
						level3_id: "20677",
						name: "Xã Quế Long",
						type: "Xã",
					},
					{
						level3_id: "20680",
						name: "Xã Quế Châu",
						type: "Xã",
					},
					{
						level3_id: "20683",
						name: "Xã Quế Phong",
						type: "Xã",
					},
					{
						level3_id: "20686",
						name: "Xã Quế An",
						type: "Xã",
					},
					{
						level3_id: "20689",
						name: "Xã Quế Minh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "510",
				name: "Huyện Nam Giang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20695",
						name: "Thị trấn Thạnh Mỹ",
						type: "Thị trấn",
					},
					{
						level3_id: "20698",
						name: "Xã Laêê",
						type: "Xã",
					},
					{
						level3_id: "20699",
						name: "Xã Chơ Chun",
						type: "Xã",
					},
					{
						level3_id: "20701",
						name: "Xã Zuôich",
						type: "Xã",
					},
					{
						level3_id: "20702",
						name: "Xã Tà Pơơ",
						type: "Xã",
					},
					{
						level3_id: "20704",
						name: "Xã La Dêê",
						type: "Xã",
					},
					{
						level3_id: "20705",
						name: "Xã Đắc Tôi",
						type: "Xã",
					},
					{
						level3_id: "20707",
						name: "Xã Chà Vàl",
						type: "Xã",
					},
					{
						level3_id: "20710",
						name: "Xã Tà Bhinh",
						type: "Xã",
					},
					{
						level3_id: "20713",
						name: "Xã Cà Dy",
						type: "Xã",
					},
					{
						level3_id: "20716",
						name: "Xã Đắc Pre",
						type: "Xã",
					},
					{
						level3_id: "20719",
						name: "Xã Đắc Pring",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "511",
				name: "Huyện Phước Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20722",
						name: "Thị trấn Khâm Đức",
						type: "Thị trấn",
					},
					{
						level3_id: "20725",
						name: "Xã Phước Xuân",
						type: "Xã",
					},
					{
						level3_id: "20728",
						name: "Xã Phước Hiệp",
						type: "Xã",
					},
					{
						level3_id: "20729",
						name: "Xã Phước Hoà",
						type: "Xã",
					},
					{
						level3_id: "20731",
						name: "Xã Phước Đức",
						type: "Xã",
					},
					{
						level3_id: "20734",
						name: "Xã Phước Năng",
						type: "Xã",
					},
					{
						level3_id: "20737",
						name: "Xã Phước Mỹ",
						type: "Xã",
					},
					{
						level3_id: "20740",
						name: "Xã Phước Chánh",
						type: "Xã",
					},
					{
						level3_id: "20743",
						name: "Xã Phước Công",
						type: "Xã",
					},
					{
						level3_id: "20746",
						name: "Xã Phước Kim",
						type: "Xã",
					},
					{
						level3_id: "20749",
						name: "Xã Phước Lộc",
						type: "Xã",
					},
					{
						level3_id: "20752",
						name: "Xã Phước Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "512",
				name: "Huyện Hiệp Đức",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20758",
						name: "Xã Hiệp Hòa",
						type: "Xã",
					},
					{
						level3_id: "20761",
						name: "Xã Hiệp Thuận",
						type: "Xã",
					},
					{
						level3_id: "20764",
						name: "Xã Quế Thọ",
						type: "Xã",
					},
					{
						level3_id: "20767",
						name: "Xã Bình Lâm",
						type: "Xã",
					},
					{
						level3_id: "20770",
						name: "Xã Sông Trà",
						type: "Xã",
					},
					{
						level3_id: "20773",
						name: "Xã Phước Trà",
						type: "Xã",
					},
					{
						level3_id: "20776",
						name: "Xã Phước Gia",
						type: "Xã",
					},
					{
						level3_id: "20779",
						name: "Thị trấn Tân Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "20782",
						name: "Xã Quế Lưu",
						type: "Xã",
					},
					{
						level3_id: "20785",
						name: "Xã Thăng Phước",
						type: "Xã",
					},
					{
						level3_id: "20788",
						name: "Xã Bình Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "513",
				name: "Huyện Thăng Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20791",
						name: "Thị trấn Hà Lam",
						type: "Thị trấn",
					},
					{
						level3_id: "20794",
						name: "Xã Bình Dương",
						type: "Xã",
					},
					{
						level3_id: "20797",
						name: "Xã Bình Giang",
						type: "Xã",
					},
					{
						level3_id: "20800",
						name: "Xã Bình Nguyên",
						type: "Xã",
					},
					{
						level3_id: "20803",
						name: "Xã Bình Phục",
						type: "Xã",
					},
					{
						level3_id: "20806",
						name: "Xã Bình Triều",
						type: "Xã",
					},
					{
						level3_id: "20809",
						name: "Xã Bình Đào",
						type: "Xã",
					},
					{
						level3_id: "20812",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "20815",
						name: "Xã Bình Lãnh",
						type: "Xã",
					},
					{
						level3_id: "20818",
						name: "Xã Bình Trị",
						type: "Xã",
					},
					{
						level3_id: "20821",
						name: "Xã Bình Định Bắc",
						type: "Xã",
					},
					{
						level3_id: "20822",
						name: "Xã Bình Định Nam",
						type: "Xã",
					},
					{
						level3_id: "20824",
						name: "Xã Bình Quý",
						type: "Xã",
					},
					{
						level3_id: "20827",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "20830",
						name: "Xã Bình Chánh",
						type: "Xã",
					},
					{
						level3_id: "20833",
						name: "Xã Bình Tú",
						type: "Xã",
					},
					{
						level3_id: "20836",
						name: "Xã Bình Sa",
						type: "Xã",
					},
					{
						level3_id: "20839",
						name: "Xã Bình Hải",
						type: "Xã",
					},
					{
						level3_id: "20842",
						name: "Xã Bình Quế",
						type: "Xã",
					},
					{
						level3_id: "20845",
						name: "Xã Bình An",
						type: "Xã",
					},
					{
						level3_id: "20848",
						name: "Xã Bình Trung",
						type: "Xã",
					},
					{
						level3_id: "20851",
						name: "Xã Bình Nam",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "514",
				name: "Huyện Tiên Phước",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20854",
						name: "Thị trấn Tiên Kỳ",
						type: "Thị trấn",
					},
					{
						level3_id: "20857",
						name: "Xã Tiên Sơn",
						type: "Xã",
					},
					{
						level3_id: "20860",
						name: "Xã Tiên Hà",
						type: "Xã",
					},
					{
						level3_id: "20863",
						name: "Xã Tiên Cẩm",
						type: "Xã",
					},
					{
						level3_id: "20866",
						name: "Xã Tiên Châu",
						type: "Xã",
					},
					{
						level3_id: "20869",
						name: "Xã Tiên Lãnh",
						type: "Xã",
					},
					{
						level3_id: "20872",
						name: "Xã Tiên Ngọc",
						type: "Xã",
					},
					{
						level3_id: "20875",
						name: "Xã Tiên Hiệp",
						type: "Xã",
					},
					{
						level3_id: "20878",
						name: "Xã Tiên Cảnh",
						type: "Xã",
					},
					{
						level3_id: "20881",
						name: "Xã Tiên Mỹ",
						type: "Xã",
					},
					{
						level3_id: "20884",
						name: "Xã Tiên Phong",
						type: "Xã",
					},
					{
						level3_id: "20887",
						name: "Xã Tiên Thọ",
						type: "Xã",
					},
					{
						level3_id: "20890",
						name: "Xã Tiên An",
						type: "Xã",
					},
					{
						level3_id: "20893",
						name: "Xã Tiên Lộc",
						type: "Xã",
					},
					{
						level3_id: "20896",
						name: "Xã Tiên Lập",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "515",
				name: "Huyện Bắc Trà My",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20899",
						name: "Thị trấn Trà My",
						type: "Thị trấn",
					},
					{
						level3_id: "20900",
						name: "Xã Trà Sơn",
						type: "Xã",
					},
					{
						level3_id: "20902",
						name: "Xã Trà Kót",
						type: "Xã",
					},
					{
						level3_id: "20905",
						name: "Xã Trà Nú",
						type: "Xã",
					},
					{
						level3_id: "20908",
						name: "Xã Trà Đông",
						type: "Xã",
					},
					{
						level3_id: "20911",
						name: "Xã Trà Dương",
						type: "Xã",
					},
					{
						level3_id: "20914",
						name: "Xã Trà Giang",
						type: "Xã",
					},
					{
						level3_id: "20917",
						name: "Xã Trà Bui",
						type: "Xã",
					},
					{
						level3_id: "20920",
						name: "Xã Trà Đốc",
						type: "Xã",
					},
					{
						level3_id: "20923",
						name: "Xã Trà Tân",
						type: "Xã",
					},
					{
						level3_id: "20926",
						name: "Xã Trà Giác",
						type: "Xã",
					},
					{
						level3_id: "20929",
						name: "Xã Trà Giáp",
						type: "Xã",
					},
					{
						level3_id: "20932",
						name: "Xã Trà Ka",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "516",
				name: "Huyện Nam Trà My",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20935",
						name: "Xã Trà Leng",
						type: "Xã",
					},
					{
						level3_id: "20938",
						name: "Xã Trà Dơn",
						type: "Xã",
					},
					{
						level3_id: "20941",
						name: "Xã Trà Tập",
						type: "Xã",
					},
					{
						level3_id: "20944",
						name: "Xã Trà Mai",
						type: "Xã",
					},
					{
						level3_id: "20947",
						name: "Xã Trà Cang",
						type: "Xã",
					},
					{
						level3_id: "20950",
						name: "Xã Trà Linh",
						type: "Xã",
					},
					{
						level3_id: "20953",
						name: "Xã Trà Nam",
						type: "Xã",
					},
					{
						level3_id: "20956",
						name: "Xã Trà Don",
						type: "Xã",
					},
					{
						level3_id: "20959",
						name: "Xã Trà Vân",
						type: "Xã",
					},
					{
						level3_id: "20962",
						name: "Xã Trà Vinh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "517",
				name: "Huyện Núi Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20965",
						name: "Thị trấn Núi Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "20968",
						name: "Xã Tam Xuân I",
						type: "Xã",
					},
					{
						level3_id: "20971",
						name: "Xã Tam Xuân II",
						type: "Xã",
					},
					{
						level3_id: "20974",
						name: "Xã Tam Tiến",
						type: "Xã",
					},
					{
						level3_id: "20977",
						name: "Xã Tam Sơn",
						type: "Xã",
					},
					{
						level3_id: "20980",
						name: "Xã Tam Thạnh",
						type: "Xã",
					},
					{
						level3_id: "20983",
						name: "Xã Tam Anh Bắc",
						type: "Xã",
					},
					{
						level3_id: "20984",
						name: "Xã Tam Anh Nam",
						type: "Xã",
					},
					{
						level3_id: "20986",
						name: "Xã Tam Hòa",
						type: "Xã",
					},
					{
						level3_id: "20989",
						name: "Xã Tam Hiệp",
						type: "Xã",
					},
					{
						level3_id: "20992",
						name: "Xã Tam Hải",
						type: "Xã",
					},
					{
						level3_id: "20995",
						name: "Xã Tam Giang",
						type: "Xã",
					},
					{
						level3_id: "20998",
						name: "Xã Tam Quang",
						type: "Xã",
					},
					{
						level3_id: "21001",
						name: "Xã Tam Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "21004",
						name: "Xã Tam Mỹ Tây",
						type: "Xã",
					},
					{
						level3_id: "21005",
						name: "Xã Tam Mỹ Đông",
						type: "Xã",
					},
					{
						level3_id: "21007",
						name: "Xã Tam Trà",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "518",
				name: "Huyện Phú Ninh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20364",
						name: "Thị trấn Phú Thịnh",
						type: "Thị trấn",
					},
					{
						level3_id: "20365",
						name: "Xã Tam Thành",
						type: "Xã",
					},
					{
						level3_id: "20368",
						name: "Xã Tam An",
						type: "Xã",
					},
					{
						level3_id: "20374",
						name: "Xã Tam Đàn",
						type: "Xã",
					},
					{
						level3_id: "20377",
						name: "Xã Tam Lộc",
						type: "Xã",
					},
					{
						level3_id: "20380",
						name: "Xã Tam Phước",
						type: "Xã",
					},
					{
						level3_id: "20383",
						name: "Xã Tam Vinh",
						type: "Xã",
					},
					{
						level3_id: "20386",
						name: "Xã Tam Thái",
						type: "Xã",
					},
					{
						level3_id: "20387",
						name: "Xã Tam Đại",
						type: "Xã",
					},
					{
						level3_id: "20392",
						name: "Xã Tam Dân",
						type: "Xã",
					},
					{
						level3_id: "20395",
						name: "Xã Tam Lãnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "519",
				name: "Huyện Nông Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "20656",
						name: "Xã Quế Trung",
						type: "Xã",
					},
					{
						level3_id: "20668",
						name: "Xã Ninh Phước",
						type: "Xã",
					},
					{
						level3_id: "20669",
						name: "Xã Phước Ninh",
						type: "Xã",
					},
					{
						level3_id: "20671",
						name: "Xã Quế Lộc",
						type: "Xã",
					},
					{
						level3_id: "20672",
						name: "Xã Sơn Viên",
						type: "Xã",
					},
					{
						level3_id: "20692",
						name: "Xã Quế Lâm",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "51",
		name: "Tỉnh Quảng Ngãi",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "522",
				name: "Thành phố Quảng Ngãi",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "21010",
						name: "Phường Lê Hồng Phong",
						type: "Phường",
					},
					{
						level3_id: "21013",
						name: "Phường Trần Phú",
						type: "Phường",
					},
					{
						level3_id: "21016",
						name: "Phường Quảng Phú",
						type: "Phường",
					},
					{
						level3_id: "21019",
						name: "Phường Nghĩa Chánh",
						type: "Phường",
					},
					{
						level3_id: "21022",
						name: "Phường Trần Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "21025",
						name: "Phường Nguyễn Nghiêm",
						type: "Phường",
					},
					{
						level3_id: "21028",
						name: "Phường Nghĩa Lộ",
						type: "Phường",
					},
					{
						level3_id: "21031",
						name: "Phường Chánh Lộ",
						type: "Phường",
					},
					{
						level3_id: "21034",
						name: "Xã Nghĩa Dũng",
						type: "Xã",
					},
					{
						level3_id: "21037",
						name: "Xã Nghĩa Dõng",
						type: "Xã",
					},
					{
						level3_id: "21172",
						name: "Phường Trương Quang Trọng",
						type: "Phường",
					},
					{
						level3_id: "21187",
						name: "Xã Tịnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "21190",
						name: "Xã Tịnh Kỳ",
						type: "Xã",
					},
					{
						level3_id: "21199",
						name: "Xã Tịnh Thiện",
						type: "Xã",
					},
					{
						level3_id: "21202",
						name: "Xã Tịnh Ấn Đông",
						type: "Xã",
					},
					{
						level3_id: "21208",
						name: "Xã Tịnh Châu",
						type: "Xã",
					},
					{
						level3_id: "21211",
						name: "Xã Tịnh Khê",
						type: "Xã",
					},
					{
						level3_id: "21214",
						name: "Xã Tịnh Long",
						type: "Xã",
					},
					{
						level3_id: "21223",
						name: "Xã Tịnh Ấn Tây",
						type: "Xã",
					},
					{
						level3_id: "21232",
						name: "Xã Tịnh An",
						type: "Xã",
					},
					{
						level3_id: "21253",
						name: "Xã Nghĩa Phú",
						type: "Xã",
					},
					{
						level3_id: "21256",
						name: "Xã Nghĩa Hà",
						type: "Xã",
					},
					{
						level3_id: "21262",
						name: "Xã Nghĩa An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "524",
				name: "Huyện Bình Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21040",
						name: "Thị Trấn Châu Ổ",
						type: "Thị trấn",
					},
					{
						level3_id: "21043",
						name: "Xã Bình Thuận",
						type: "Xã",
					},
					{
						level3_id: "21046",
						name: "Xã Bình Thạnh",
						type: "Xã",
					},
					{
						level3_id: "21049",
						name: "Xã Bình Đông",
						type: "Xã",
					},
					{
						level3_id: "21052",
						name: "Xã Bình Chánh",
						type: "Xã",
					},
					{
						level3_id: "21055",
						name: "Xã Bình Nguyên",
						type: "Xã",
					},
					{
						level3_id: "21058",
						name: "Xã Bình Khương",
						type: "Xã",
					},
					{
						level3_id: "21061",
						name: "Xã Bình Trị",
						type: "Xã",
					},
					{
						level3_id: "21064",
						name: "Xã Bình An",
						type: "Xã",
					},
					{
						level3_id: "21067",
						name: "Xã Bình Hải",
						type: "Xã",
					},
					{
						level3_id: "21070",
						name: "Xã Bình Dương",
						type: "Xã",
					},
					{
						level3_id: "21073",
						name: "Xã Bình Phước",
						type: "Xã",
					},
					{
						level3_id: "21079",
						name: "Xã Bình Hòa",
						type: "Xã",
					},
					{
						level3_id: "21082",
						name: "Xã Bình Trung",
						type: "Xã",
					},
					{
						level3_id: "21085",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "21088",
						name: "Xã Bình Long",
						type: "Xã",
					},
					{
						level3_id: "21091",
						name: "Xã Bình Thanh ",
						type: "Xã",
					},
					{
						level3_id: "21100",
						name: "Xã Bình Chương",
						type: "Xã",
					},
					{
						level3_id: "21103",
						name: "Xã Bình Hiệp",
						type: "Xã",
					},
					{
						level3_id: "21106",
						name: "Xã Bình Mỹ",
						type: "Xã",
					},
					{
						level3_id: "21109",
						name: "Xã Bình Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "21112",
						name: "Xã Bình Châu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "525",
				name: "Huyện Trà Bồng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21115",
						name: "Thị trấn Trà Xuân",
						type: "Thị trấn",
					},
					{
						level3_id: "21118",
						name: "Xã Trà Giang",
						type: "Xã",
					},
					{
						level3_id: "21121",
						name: "Xã Trà Thủy",
						type: "Xã",
					},
					{
						level3_id: "21124",
						name: "Xã Trà Hiệp",
						type: "Xã",
					},
					{
						level3_id: "21127",
						name: "Xã Trà Bình",
						type: "Xã",
					},
					{
						level3_id: "21130",
						name: "Xã Trà Phú",
						type: "Xã",
					},
					{
						level3_id: "21133",
						name: "Xã Trà Lâm",
						type: "Xã",
					},
					{
						level3_id: "21136",
						name: "Xã Trà Tân",
						type: "Xã",
					},
					{
						level3_id: "21139",
						name: "Xã Trà Sơn",
						type: "Xã",
					},
					{
						level3_id: "21142",
						name: "Xã Trà Bùi",
						type: "Xã",
					},
					{
						level3_id: "21145",
						name: "Xã Trà Thanh",
						type: "Xã",
					},
					{
						level3_id: "21148",
						name: "Xã Sơn Trà",
						type: "Xã",
					},
					{
						level3_id: "21154",
						name: "Xã Trà Phong",
						type: "Xã",
					},
					{
						level3_id: "21157",
						name: "Xã Hương Trà",
						type: "Xã",
					},
					{
						level3_id: "21163",
						name: "Xã Trà Xinh",
						type: "Xã",
					},
					{
						level3_id: "21166",
						name: "Xã Trà Tây",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "527",
				name: "Huyện Sơn Tịnh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21175",
						name: "Xã Tịnh Thọ",
						type: "Xã",
					},
					{
						level3_id: "21178",
						name: "Xã Tịnh Trà",
						type: "Xã",
					},
					{
						level3_id: "21181",
						name: "Xã Tịnh Phong",
						type: "Xã",
					},
					{
						level3_id: "21184",
						name: "Xã Tịnh Hiệp",
						type: "Xã",
					},
					{
						level3_id: "21193",
						name: "Xã Tịnh Bình",
						type: "Xã",
					},
					{
						level3_id: "21196",
						name: "Xã Tịnh Đông",
						type: "Xã",
					},
					{
						level3_id: "21205",
						name: "Xã Tịnh Bắc",
						type: "Xã",
					},
					{
						level3_id: "21217",
						name: "Xã Tịnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "21220",
						name: "Xã Tịnh Hà",
						type: "Xã",
					},
					{
						level3_id: "21226",
						name: "Xã Tịnh Giang",
						type: "Xã",
					},
					{
						level3_id: "21229",
						name: "Xã Tịnh Minh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "528",
				name: "Huyện Tư Nghĩa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21235",
						name: "Thị trấn La Hà",
						type: "Thị trấn",
					},
					{
						level3_id: "21238",
						name: "Thị trấn Sông Vệ",
						type: "Thị trấn",
					},
					{
						level3_id: "21241",
						name: "Xã Nghĩa Lâm",
						type: "Xã",
					},
					{
						level3_id: "21244",
						name: "Xã Nghĩa Thắng",
						type: "Xã",
					},
					{
						level3_id: "21247",
						name: "Xã Nghĩa Thuận",
						type: "Xã",
					},
					{
						level3_id: "21250",
						name: "Xã Nghĩa Kỳ",
						type: "Xã",
					},
					{
						level3_id: "21259",
						name: "Xã Nghĩa Sơn",
						type: "Xã",
					},
					{
						level3_id: "21268",
						name: "Xã Nghĩa Hòa",
						type: "Xã",
					},
					{
						level3_id: "21271",
						name: "Xã Nghĩa Điền",
						type: "Xã",
					},
					{
						level3_id: "21274",
						name: "Xã Nghĩa Thương",
						type: "Xã",
					},
					{
						level3_id: "21277",
						name: "Xã Nghĩa Trung",
						type: "Xã",
					},
					{
						level3_id: "21280",
						name: "Xã Nghĩa Hiệp",
						type: "Xã",
					},
					{
						level3_id: "21283",
						name: "Xã Nghĩa Phương",
						type: "Xã",
					},
					{
						level3_id: "21286",
						name: "Xã Nghĩa Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "529",
				name: "Huyện Sơn Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21289",
						name: "Thị trấn Di Lăng",
						type: "Thị trấn",
					},
					{
						level3_id: "21292",
						name: "Xã Sơn Hạ",
						type: "Xã",
					},
					{
						level3_id: "21295",
						name: "Xã Sơn Thành",
						type: "Xã",
					},
					{
						level3_id: "21298",
						name: "Xã Sơn Nham",
						type: "Xã",
					},
					{
						level3_id: "21301",
						name: "Xã Sơn Bao",
						type: "Xã",
					},
					{
						level3_id: "21304",
						name: "Xã Sơn Linh",
						type: "Xã",
					},
					{
						level3_id: "21307",
						name: "Xã Sơn Giang",
						type: "Xã",
					},
					{
						level3_id: "21310",
						name: "Xã Sơn Trung",
						type: "Xã",
					},
					{
						level3_id: "21313",
						name: "Xã Sơn Thượng",
						type: "Xã",
					},
					{
						level3_id: "21316",
						name: "Xã Sơn Cao",
						type: "Xã",
					},
					{
						level3_id: "21319",
						name: "Xã Sơn Hải",
						type: "Xã",
					},
					{
						level3_id: "21322",
						name: "Xã Sơn Thủy",
						type: "Xã",
					},
					{
						level3_id: "21325",
						name: "Xã Sơn Kỳ",
						type: "Xã",
					},
					{
						level3_id: "21328",
						name: "Xã Sơn Ba",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "530",
				name: "Huyện Sơn Tây",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21331",
						name: "Xã Sơn Bua",
						type: "Xã",
					},
					{
						level3_id: "21334",
						name: "Xã Sơn Mùa",
						type: "Xã",
					},
					{
						level3_id: "21335",
						name: "Xã Sơn Liên",
						type: "Xã",
					},
					{
						level3_id: "21337",
						name: "Xã Sơn Tân",
						type: "Xã",
					},
					{
						level3_id: "21338",
						name: "Xã Sơn Màu",
						type: "Xã",
					},
					{
						level3_id: "21340",
						name: "Xã Sơn Dung",
						type: "Xã",
					},
					{
						level3_id: "21341",
						name: "Xã Sơn Long",
						type: "Xã",
					},
					{
						level3_id: "21343",
						name: "Xã Sơn Tinh",
						type: "Xã",
					},
					{
						level3_id: "21346",
						name: "Xã Sơn Lập",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "531",
				name: "Huyện Minh Long",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21349",
						name: "Xã Long Sơn",
						type: "Xã",
					},
					{
						level3_id: "21352",
						name: "Xã Long Mai",
						type: "Xã",
					},
					{
						level3_id: "21355",
						name: "Xã Thanh An",
						type: "Xã",
					},
					{
						level3_id: "21358",
						name: "Xã Long Môn",
						type: "Xã",
					},
					{
						level3_id: "21361",
						name: "Xã Long Hiệp",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "532",
				name: "Huyện Nghĩa Hành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21364",
						name: "Thị trấn Chợ Chùa",
						type: "Thị trấn",
					},
					{
						level3_id: "21367",
						name: "Xã Hành Thuận",
						type: "Xã",
					},
					{
						level3_id: "21370",
						name: "Xã Hành Dũng",
						type: "Xã",
					},
					{
						level3_id: "21373",
						name: "Xã Hành Trung",
						type: "Xã",
					},
					{
						level3_id: "21376",
						name: "Xã Hành Nhân",
						type: "Xã",
					},
					{
						level3_id: "21379",
						name: "Xã Hành Đức",
						type: "Xã",
					},
					{
						level3_id: "21382",
						name: "Xã Hành Minh",
						type: "Xã",
					},
					{
						level3_id: "21385",
						name: "Xã Hành Phước",
						type: "Xã",
					},
					{
						level3_id: "21388",
						name: "Xã Hành Thiện",
						type: "Xã",
					},
					{
						level3_id: "21391",
						name: "Xã Hành Thịnh",
						type: "Xã",
					},
					{
						level3_id: "21394",
						name: "Xã Hành Tín Tây",
						type: "Xã",
					},
					{
						level3_id: "21397",
						name: "Xã Hành Tín  Đông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "533",
				name: "Huyện Mộ Đức",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21400",
						name: "Thị trấn Mộ Đức",
						type: "Thị trấn",
					},
					{
						level3_id: "21403",
						name: "Xã Đức Lợi",
						type: "Xã",
					},
					{
						level3_id: "21406",
						name: "Xã Đức Thắng",
						type: "Xã",
					},
					{
						level3_id: "21409",
						name: "Xã Đức Nhuận",
						type: "Xã",
					},
					{
						level3_id: "21412",
						name: "Xã Đức Chánh",
						type: "Xã",
					},
					{
						level3_id: "21415",
						name: "Xã Đức Hiệp",
						type: "Xã",
					},
					{
						level3_id: "21418",
						name: "Xã Đức Minh",
						type: "Xã",
					},
					{
						level3_id: "21421",
						name: "Xã Đức Thạnh",
						type: "Xã",
					},
					{
						level3_id: "21424",
						name: "Xã Đức Hòa",
						type: "Xã",
					},
					{
						level3_id: "21427",
						name: "Xã Đức Tân",
						type: "Xã",
					},
					{
						level3_id: "21430",
						name: "Xã Đức Phú",
						type: "Xã",
					},
					{
						level3_id: "21433",
						name: "Xã Đức Phong",
						type: "Xã",
					},
					{
						level3_id: "21436",
						name: "Xã Đức Lân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "534",
				name: "Thị xã Đức Phổ",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "21439",
						name: "Phường Nguyễn Nghiêm",
						type: "Phường",
					},
					{
						level3_id: "21442",
						name: "Xã Phổ An",
						type: "Xã",
					},
					{
						level3_id: "21445",
						name: "Xã Phổ Phong",
						type: "Xã",
					},
					{
						level3_id: "21448",
						name: "Xã Phổ Thuận",
						type: "Xã",
					},
					{
						level3_id: "21451",
						name: "Phường Phổ Văn",
						type: "Phường",
					},
					{
						level3_id: "21454",
						name: "Phường Phổ Quang",
						type: "Phường",
					},
					{
						level3_id: "21457",
						name: "Xã Phổ Nhơn",
						type: "Xã",
					},
					{
						level3_id: "21460",
						name: "Phường Phổ Ninh",
						type: "Phường",
					},
					{
						level3_id: "21463",
						name: "Phường Phổ Minh",
						type: "Phường",
					},
					{
						level3_id: "21466",
						name: "Phường Phổ Vinh",
						type: "Phường",
					},
					{
						level3_id: "21469",
						name: "Phường Phổ Hòa",
						type: "Phường",
					},
					{
						level3_id: "21472",
						name: "Xã Phổ Cường",
						type: "Xã",
					},
					{
						level3_id: "21475",
						name: "Xã Phổ Khánh",
						type: "Xã",
					},
					{
						level3_id: "21478",
						name: "Phường Phổ Thạnh",
						type: "Phường",
					},
					{
						level3_id: "21481",
						name: "Xã Phổ Châu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "535",
				name: "Huyện Ba Tơ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21484",
						name: "Thị trấn Ba Tơ",
						type: "Thị trấn",
					},
					{
						level3_id: "21487",
						name: "Xã Ba Điền",
						type: "Xã",
					},
					{
						level3_id: "21490",
						name: "Xã Ba Vinh",
						type: "Xã",
					},
					{
						level3_id: "21493",
						name: "Xã Ba Thành",
						type: "Xã",
					},
					{
						level3_id: "21496",
						name: "Xã Ba Động",
						type: "Xã",
					},
					{
						level3_id: "21499",
						name: "Xã Ba Dinh",
						type: "Xã",
					},
					{
						level3_id: "21500",
						name: "Xã Ba Giang",
						type: "Xã",
					},
					{
						level3_id: "21502",
						name: "Xã Ba Liên",
						type: "Xã",
					},
					{
						level3_id: "21505",
						name: "Xã Ba Ngạc",
						type: "Xã",
					},
					{
						level3_id: "21508",
						name: "Xã Ba Khâm",
						type: "Xã",
					},
					{
						level3_id: "21511",
						name: "Xã Ba Cung",
						type: "Xã",
					},
					{
						level3_id: "21517",
						name: "Xã Ba Tiêu",
						type: "Xã",
					},
					{
						level3_id: "21520",
						name: "Xã Ba Trang",
						type: "Xã",
					},
					{
						level3_id: "21523",
						name: "Xã Ba Tô",
						type: "Xã",
					},
					{
						level3_id: "21526",
						name: "Xã Ba Bích",
						type: "Xã",
					},
					{
						level3_id: "21529",
						name: "Xã Ba Vì",
						type: "Xã",
					},
					{
						level3_id: "21532",
						name: "Xã Ba Lế",
						type: "Xã",
					},
					{
						level3_id: "21535",
						name: "Xã Ba Nam",
						type: "Xã",
					},
					{
						level3_id: "21538",
						name: "Xã Ba Xa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "536",
				name: "Huyện Lý Sơn",
				type: "Huyện",
				level3s: [],
			},
		],
	},
	{
		level1_id: "52",
		name: "Tỉnh Bình Định",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "540",
				name: "Thành phố Quy Nhơn",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "21550",
						name: "Phường Nhơn Bình",
						type: "Phường",
					},
					{
						level3_id: "21553",
						name: "Phường Nhơn Phú",
						type: "Phường",
					},
					{
						level3_id: "21556",
						name: "Phường Đống Đa",
						type: "Phường",
					},
					{
						level3_id: "21559",
						name: "Phường Trần Quang Diệu",
						type: "Phường",
					},
					{
						level3_id: "21562",
						name: "Phường Hải Cảng",
						type: "Phường",
					},
					{
						level3_id: "21565",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "21568",
						name: "Phường Thị Nại",
						type: "Phường",
					},
					{
						level3_id: "21571",
						name: "Phường Lê Hồng Phong",
						type: "Phường",
					},
					{
						level3_id: "21574",
						name: "Phường Trần Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "21577",
						name: "Phường Ngô Mây",
						type: "Phường",
					},
					{
						level3_id: "21580",
						name: "Phường Lý Thường Kiệt",
						type: "Phường",
					},
					{
						level3_id: "21583",
						name: "Phường Lê Lợi",
						type: "Phường",
					},
					{
						level3_id: "21586",
						name: "Phường Trần Phú",
						type: "Phường",
					},
					{
						level3_id: "21589",
						name: "Phường Bùi Thị Xuân",
						type: "Phường",
					},
					{
						level3_id: "21592",
						name: "Phường Nguyễn Văn Cừ",
						type: "Phường",
					},
					{
						level3_id: "21595",
						name: "Phường Ghềnh Ráng",
						type: "Phường",
					},
					{
						level3_id: "21598",
						name: "Xã Nhơn Lý",
						type: "Xã",
					},
					{
						level3_id: "21601",
						name: "Xã Nhơn Hội",
						type: "Xã",
					},
					{
						level3_id: "21604",
						name: "Xã Nhơn Hải",
						type: "Xã",
					},
					{
						level3_id: "21607",
						name: "Xã Nhơn Châu",
						type: "Xã",
					},
					{
						level3_id: "21991",
						name: "Xã Phước Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "542",
				name: "Huyện An Lão",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21609",
						name: "Thị trấn An Lão",
						type: "Thị trấn",
					},
					{
						level3_id: "21610",
						name: "Xã An Hưng",
						type: "Xã",
					},
					{
						level3_id: "21613",
						name: "Xã An Trung",
						type: "Xã",
					},
					{
						level3_id: "21616",
						name: "Xã An Dũng",
						type: "Xã",
					},
					{
						level3_id: "21619",
						name: "Xã An Vinh",
						type: "Xã",
					},
					{
						level3_id: "21622",
						name: "Xã An Toàn",
						type: "Xã",
					},
					{
						level3_id: "21625",
						name: "Xã An Tân",
						type: "Xã",
					},
					{
						level3_id: "21628",
						name: "Xã An Hòa",
						type: "Xã",
					},
					{
						level3_id: "21631",
						name: "Xã An Quang",
						type: "Xã",
					},
					{
						level3_id: "21634",
						name: "Xã An Nghĩa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "543",
				name: "Thị xã Hoài Nhơn",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "21637",
						name: "Phường Tam Quan",
						type: "Phường",
					},
					{
						level3_id: "21640",
						name: "Phường Bồng Sơn",
						type: "Phường",
					},
					{
						level3_id: "21643",
						name: "Xã Hoài Sơn",
						type: "Xã",
					},
					{
						level3_id: "21646",
						name: "Xã Hoài Châu Bắc",
						type: "Xã",
					},
					{
						level3_id: "21649",
						name: "Xã Hoài Châu",
						type: "Xã",
					},
					{
						level3_id: "21652",
						name: "Xã Hoài Phú",
						type: "Xã",
					},
					{
						level3_id: "21655",
						name: "Phường Tam Quan Bắc",
						type: "Phường",
					},
					{
						level3_id: "21658",
						name: "Phường Tam Quan Nam",
						type: "Phường",
					},
					{
						level3_id: "21661",
						name: "Phường Hoài Hảo",
						type: "Phường",
					},
					{
						level3_id: "21664",
						name: "Phường Hoài Thanh Tây",
						type: "Phường",
					},
					{
						level3_id: "21667",
						name: "Phường Hoài Thanh",
						type: "Phường",
					},
					{
						level3_id: "21670",
						name: "Phường Hoài Hương",
						type: "Phường",
					},
					{
						level3_id: "21673",
						name: "Phường Hoài Tân",
						type: "Phường",
					},
					{
						level3_id: "21676",
						name: "Xã Hoài Hải",
						type: "Xã",
					},
					{
						level3_id: "21679",
						name: "Phường Hoài Xuân",
						type: "Phường",
					},
					{
						level3_id: "21682",
						name: "Xã Hoài Mỹ",
						type: "Xã",
					},
					{
						level3_id: "21685",
						name: "Phường Hoài Đức",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "544",
				name: "Huyện Hoài Ân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21688",
						name: "Thị trấn Tăng Bạt Hổ",
						type: "Thị trấn",
					},
					{
						level3_id: "21690",
						name: "Xã Ân Hảo Tây",
						type: "Xã",
					},
					{
						level3_id: "21691",
						name: "Xã Ân Hảo Đông",
						type: "Xã",
					},
					{
						level3_id: "21694",
						name: "Xã Ân Sơn",
						type: "Xã",
					},
					{
						level3_id: "21697",
						name: "Xã Ân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "21700",
						name: "Xã Đak Mang",
						type: "Xã",
					},
					{
						level3_id: "21703",
						name: "Xã Ân Tín",
						type: "Xã",
					},
					{
						level3_id: "21706",
						name: "Xã Ân Thạnh",
						type: "Xã",
					},
					{
						level3_id: "21709",
						name: "Xã Ân Phong",
						type: "Xã",
					},
					{
						level3_id: "21712",
						name: "Xã Ân Đức",
						type: "Xã",
					},
					{
						level3_id: "21715",
						name: "Xã Ân Hữu",
						type: "Xã",
					},
					{
						level3_id: "21718",
						name: "Xã Bok Tới",
						type: "Xã",
					},
					{
						level3_id: "21721",
						name: "Xã Ân Tường Tây",
						type: "Xã",
					},
					{
						level3_id: "21724",
						name: "Xã Ân Tường Đông",
						type: "Xã",
					},
					{
						level3_id: "21727",
						name: "Xã Ân Nghĩa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "545",
				name: "Huyện Phù Mỹ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21730",
						name: "Thị trấn Phù Mỹ",
						type: "Thị trấn",
					},
					{
						level3_id: "21733",
						name: "Thị trấn Bình Dương",
						type: "Thị trấn",
					},
					{
						level3_id: "21736",
						name: "Xã Mỹ Đức",
						type: "Xã",
					},
					{
						level3_id: "21739",
						name: "Xã Mỹ Châu",
						type: "Xã",
					},
					{
						level3_id: "21742",
						name: "Xã Mỹ Thắng",
						type: "Xã",
					},
					{
						level3_id: "21745",
						name: "Xã Mỹ Lộc",
						type: "Xã",
					},
					{
						level3_id: "21748",
						name: "Xã Mỹ Lợi",
						type: "Xã",
					},
					{
						level3_id: "21751",
						name: "Xã Mỹ An",
						type: "Xã",
					},
					{
						level3_id: "21754",
						name: "Xã Mỹ Phong",
						type: "Xã",
					},
					{
						level3_id: "21757",
						name: "Xã Mỹ Trinh",
						type: "Xã",
					},
					{
						level3_id: "21760",
						name: "Xã Mỹ Thọ",
						type: "Xã",
					},
					{
						level3_id: "21763",
						name: "Xã Mỹ Hòa",
						type: "Xã",
					},
					{
						level3_id: "21766",
						name: "Xã Mỹ Thành",
						type: "Xã",
					},
					{
						level3_id: "21769",
						name: "Xã Mỹ Chánh",
						type: "Xã",
					},
					{
						level3_id: "21772",
						name: "Xã Mỹ Quang",
						type: "Xã",
					},
					{
						level3_id: "21775",
						name: "Xã Mỹ Hiệp",
						type: "Xã",
					},
					{
						level3_id: "21778",
						name: "Xã Mỹ Tài",
						type: "Xã",
					},
					{
						level3_id: "21781",
						name: "Xã Mỹ Cát",
						type: "Xã",
					},
					{
						level3_id: "21784",
						name: "Xã Mỹ Chánh Tây",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "546",
				name: "Huyện Vĩnh Thạnh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21786",
						name: "Thị trấn Vĩnh Thạnh",
						type: "Thị trấn",
					},
					{
						level3_id: "21787",
						name: "Xã Vĩnh Sơn",
						type: "Xã",
					},
					{
						level3_id: "21790",
						name: "Xã Vĩnh Kim",
						type: "Xã",
					},
					{
						level3_id: "21796",
						name: "Xã Vĩnh Hiệp",
						type: "Xã",
					},
					{
						level3_id: "21799",
						name: "Xã Vĩnh Hảo",
						type: "Xã",
					},
					{
						level3_id: "21801",
						name: "Xã Vĩnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "21802",
						name: "Xã Vĩnh Thịnh",
						type: "Xã",
					},
					{
						level3_id: "21804",
						name: "Xã Vĩnh Thuận",
						type: "Xã",
					},
					{
						level3_id: "21805",
						name: "Xã Vĩnh Quang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "547",
				name: "Huyện Tây Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21808",
						name: "Thị trấn Phú Phong",
						type: "Thị trấn",
					},
					{
						level3_id: "21811",
						name: "Xã Bình Tân",
						type: "Xã",
					},
					{
						level3_id: "21814",
						name: "Xã Tây Thuận",
						type: "Xã",
					},
					{
						level3_id: "21817",
						name: "Xã Bình Thuận",
						type: "Xã",
					},
					{
						level3_id: "21820",
						name: "Xã Tây Giang",
						type: "Xã",
					},
					{
						level3_id: "21823",
						name: "Xã Bình Thành",
						type: "Xã",
					},
					{
						level3_id: "21826",
						name: "Xã Tây An",
						type: "Xã",
					},
					{
						level3_id: "21829",
						name: "Xã Bình Hòa",
						type: "Xã",
					},
					{
						level3_id: "21832",
						name: "Xã Tây Bình",
						type: "Xã",
					},
					{
						level3_id: "21835",
						name: "Xã Bình Tường",
						type: "Xã",
					},
					{
						level3_id: "21838",
						name: "Xã Tây Vinh",
						type: "Xã",
					},
					{
						level3_id: "21841",
						name: "Xã Vĩnh An",
						type: "Xã",
					},
					{
						level3_id: "21844",
						name: "Xã Tây Xuân",
						type: "Xã",
					},
					{
						level3_id: "21847",
						name: "Xã Bình Nghi",
						type: "Xã",
					},
					{
						level3_id: "21850",
						name: "Xã Tây Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "548",
				name: "Huyện Phù Cát",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21853",
						name: "Thị trấn Ngô Mây",
						type: "Thị trấn",
					},
					{
						level3_id: "21856",
						name: "Xã Cát Sơn",
						type: "Xã",
					},
					{
						level3_id: "21859",
						name: "Xã Cát Minh",
						type: "Xã",
					},
					{
						level3_id: "21862",
						name: "Xã Cát Khánh",
						type: "Xã",
					},
					{
						level3_id: "21865",
						name: "Xã Cát Tài",
						type: "Xã",
					},
					{
						level3_id: "21868",
						name: "Xã Cát Lâm",
						type: "Xã",
					},
					{
						level3_id: "21871",
						name: "Xã Cát Hanh",
						type: "Xã",
					},
					{
						level3_id: "21874",
						name: "Xã Cát Thành",
						type: "Xã",
					},
					{
						level3_id: "21877",
						name: "Xã Cát Trinh",
						type: "Xã",
					},
					{
						level3_id: "21880",
						name: "Xã Cát Hải",
						type: "Xã",
					},
					{
						level3_id: "21883",
						name: "Xã Cát Hiệp",
						type: "Xã",
					},
					{
						level3_id: "21886",
						name: "Xã Cát Nhơn",
						type: "Xã",
					},
					{
						level3_id: "21889",
						name: "Xã Cát Hưng",
						type: "Xã",
					},
					{
						level3_id: "21892",
						name: "Xã Cát Tường",
						type: "Xã",
					},
					{
						level3_id: "21895",
						name: "Xã Cát Tân",
						type: "Xã",
					},
					{
						level3_id: "21898",
						name: "Thị trấn Cát Tiến",
						type: "Thị trấn",
					},
					{
						level3_id: "21901",
						name: "Xã Cát Thắng",
						type: "Xã",
					},
					{
						level3_id: "21904",
						name: "Xã Cát Chánh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "549",
				name: "Thị xã An Nhơn",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "21907",
						name: "Phường Bình Định",
						type: "Phường",
					},
					{
						level3_id: "21910",
						name: "Phường Đập Đá",
						type: "Phường",
					},
					{
						level3_id: "21913",
						name: "Xã Nhơn Mỹ",
						type: "Xã",
					},
					{
						level3_id: "21916",
						name: "Phường Nhơn Thành",
						type: "Phường",
					},
					{
						level3_id: "21919",
						name: "Xã Nhơn Hạnh",
						type: "Xã",
					},
					{
						level3_id: "21922",
						name: "Xã Nhơn Hậu",
						type: "Xã",
					},
					{
						level3_id: "21925",
						name: "Xã Nhơn Phong",
						type: "Xã",
					},
					{
						level3_id: "21928",
						name: "Xã Nhơn An",
						type: "Xã",
					},
					{
						level3_id: "21931",
						name: "Xã Nhơn Phúc",
						type: "Xã",
					},
					{
						level3_id: "21934",
						name: "Phường Nhơn Hưng",
						type: "Phường",
					},
					{
						level3_id: "21937",
						name: "Xã Nhơn Khánh",
						type: "Xã",
					},
					{
						level3_id: "21940",
						name: "Xã Nhơn Lộc",
						type: "Xã",
					},
					{
						level3_id: "21943",
						name: "Phường Nhơn Hoà",
						type: "Phường",
					},
					{
						level3_id: "21946",
						name: "Xã Nhơn Tân",
						type: "Xã",
					},
					{
						level3_id: "21949",
						name: "Xã Nhơn Thọ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "550",
				name: "Huyện Tuy Phước",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21952",
						name: "Thị trấn Tuy Phước",
						type: "Thị trấn",
					},
					{
						level3_id: "21955",
						name: "Thị trấn Diêu Trì",
						type: "Thị trấn",
					},
					{
						level3_id: "21958",
						name: "Xã Phước Thắng",
						type: "Xã",
					},
					{
						level3_id: "21961",
						name: "Xã Phước Hưng",
						type: "Xã",
					},
					{
						level3_id: "21964",
						name: "Xã Phước Quang",
						type: "Xã",
					},
					{
						level3_id: "21967",
						name: "Xã Phước Hòa",
						type: "Xã",
					},
					{
						level3_id: "21970",
						name: "Xã Phước Sơn",
						type: "Xã",
					},
					{
						level3_id: "21973",
						name: "Xã Phước Hiệp",
						type: "Xã",
					},
					{
						level3_id: "21976",
						name: "Xã Phước Lộc",
						type: "Xã",
					},
					{
						level3_id: "21979",
						name: "Xã Phước Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "21982",
						name: "Xã Phước Thuận",
						type: "Xã",
					},
					{
						level3_id: "21985",
						name: "Xã Phước An",
						type: "Xã",
					},
					{
						level3_id: "21988",
						name: "Xã Phước Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "551",
				name: "Huyện Vân Canh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "21994",
						name: "Thị trấn Vân Canh",
						type: "Thị trấn",
					},
					{
						level3_id: "21997",
						name: "Xã Canh Liên",
						type: "Xã",
					},
					{
						level3_id: "22000",
						name: "Xã Canh Hiệp",
						type: "Xã",
					},
					{
						level3_id: "22003",
						name: "Xã Canh Vinh",
						type: "Xã",
					},
					{
						level3_id: "22006",
						name: "Xã Canh Hiển",
						type: "Xã",
					},
					{
						level3_id: "22009",
						name: "Xã Canh Thuận",
						type: "Xã",
					},
					{
						level3_id: "22012",
						name: "Xã Canh Hòa",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "54",
		name: "Tỉnh Phú Yên",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "555",
				name: "Thành phố Tuy Hoà",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "22015",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "22018",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "22021",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "22024",
						name: "Phường 9",
						type: "Phường",
					},
					{
						level3_id: "22027",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "22030",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "22033",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "22036",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "22039",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "22040",
						name: "Phường Phú Thạnh",
						type: "Phường",
					},
					{
						level3_id: "22041",
						name: "Phường Phú Đông",
						type: "Phường",
					},
					{
						level3_id: "22042",
						name: "Xã Hòa Kiến",
						type: "Xã",
					},
					{
						level3_id: "22045",
						name: "Xã Bình Kiến",
						type: "Xã",
					},
					{
						level3_id: "22048",
						name: "Xã Bình Ngọc",
						type: "Xã",
					},
					{
						level3_id: "22162",
						name: "Xã An Phú",
						type: "Xã",
					},
					{
						level3_id: "22240",
						name: "Phường Phú Lâm",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "557",
				name: "Thị xã Sông Cầu",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "22051",
						name: "Phường Xuân Phú",
						type: "Phường",
					},
					{
						level3_id: "22052",
						name: "Xã Xuân Lâm",
						type: "Xã",
					},
					{
						level3_id: "22053",
						name: "Phường Xuân Thành",
						type: "Phường",
					},
					{
						level3_id: "22054",
						name: "Xã Xuân Hải",
						type: "Xã",
					},
					{
						level3_id: "22057",
						name: "Xã Xuân Lộc",
						type: "Xã",
					},
					{
						level3_id: "22060",
						name: "Xã Xuân Bình",
						type: "Xã",
					},
					{
						level3_id: "22066",
						name: "Xã Xuân Cảnh",
						type: "Xã",
					},
					{
						level3_id: "22069",
						name: "Xã Xuân Thịnh",
						type: "Xã",
					},
					{
						level3_id: "22072",
						name: "Xã Xuân Phương",
						type: "Xã",
					},
					{
						level3_id: "22073",
						name: "Phường Xuân Yên",
						type: "Phường",
					},
					{
						level3_id: "22075",
						name: "Xã Xuân Thọ 1",
						type: "Xã",
					},
					{
						level3_id: "22076",
						name: "Phường Xuân Đài",
						type: "Phường",
					},
					{
						level3_id: "22078",
						name: "Xã Xuân Thọ 2",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "558",
				name: "Huyện Đồng Xuân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22081",
						name: "Thị trấn La Hai",
						type: "Thị trấn",
					},
					{
						level3_id: "22084",
						name: "Xã Đa Lộc",
						type: "Xã",
					},
					{
						level3_id: "22087",
						name: "Xã Phú Mỡ",
						type: "Xã",
					},
					{
						level3_id: "22090",
						name: "Xã Xuân Lãnh",
						type: "Xã",
					},
					{
						level3_id: "22093",
						name: "Xã Xuân Long",
						type: "Xã",
					},
					{
						level3_id: "22096",
						name: "Xã Xuân Quang 1",
						type: "Xã",
					},
					{
						level3_id: "22099",
						name: "Xã Xuân Sơn Bắc",
						type: "Xã",
					},
					{
						level3_id: "22102",
						name: "Xã Xuân Quang 2",
						type: "Xã",
					},
					{
						level3_id: "22105",
						name: "Xã Xuân Sơn Nam",
						type: "Xã",
					},
					{
						level3_id: "22108",
						name: "Xã Xuân Quang 3",
						type: "Xã",
					},
					{
						level3_id: "22111",
						name: "Xã Xuân Phước",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "559",
				name: "Huyện Tuy An",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22114",
						name: "Thị trấn Chí Thạnh",
						type: "Thị trấn",
					},
					{
						level3_id: "22117",
						name: "Xã An Dân",
						type: "Xã",
					},
					{
						level3_id: "22120",
						name: "Xã An Ninh Tây",
						type: "Xã",
					},
					{
						level3_id: "22123",
						name: "Xã An Ninh Đông",
						type: "Xã",
					},
					{
						level3_id: "22126",
						name: "Xã An Thạch",
						type: "Xã",
					},
					{
						level3_id: "22129",
						name: "Xã An Định",
						type: "Xã",
					},
					{
						level3_id: "22132",
						name: "Xã An Nghiệp",
						type: "Xã",
					},
					{
						level3_id: "22138",
						name: "Xã An Cư",
						type: "Xã",
					},
					{
						level3_id: "22141",
						name: "Xã An Xuân",
						type: "Xã",
					},
					{
						level3_id: "22144",
						name: "Xã An Lĩnh",
						type: "Xã",
					},
					{
						level3_id: "22147",
						name: "Xã An Hòa Hải",
						type: "Xã",
					},
					{
						level3_id: "22150",
						name: "Xã An Hiệp",
						type: "Xã",
					},
					{
						level3_id: "22153",
						name: "Xã An Mỹ",
						type: "Xã",
					},
					{
						level3_id: "22156",
						name: "Xã An Chấn",
						type: "Xã",
					},
					{
						level3_id: "22159",
						name: "Xã An Thọ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "560",
				name: "Huyện Sơn Hòa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22165",
						name: "Thị trấn Củng Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "22168",
						name: "Xã Phước Tân",
						type: "Xã",
					},
					{
						level3_id: "22171",
						name: "Xã Sơn Hội",
						type: "Xã",
					},
					{
						level3_id: "22174",
						name: "Xã Sơn Định",
						type: "Xã",
					},
					{
						level3_id: "22177",
						name: "Xã Sơn Long",
						type: "Xã",
					},
					{
						level3_id: "22180",
						name: "Xã Cà Lúi",
						type: "Xã",
					},
					{
						level3_id: "22183",
						name: "Xã Sơn Phước",
						type: "Xã",
					},
					{
						level3_id: "22186",
						name: "Xã Sơn Xuân",
						type: "Xã",
					},
					{
						level3_id: "22189",
						name: "Xã Sơn Nguyên",
						type: "Xã",
					},
					{
						level3_id: "22192",
						name: "Xã Eachà Rang",
						type: "Xã",
					},
					{
						level3_id: "22195",
						name: "Xã Krông Pa",
						type: "Xã",
					},
					{
						level3_id: "22198",
						name: "Xã Suối Bạc",
						type: "Xã",
					},
					{
						level3_id: "22201",
						name: "Xã Sơn Hà",
						type: "Xã",
					},
					{
						level3_id: "22204",
						name: "Xã Suối Trai",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "561",
				name: "Huyện Sông Hinh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22207",
						name: "Thị trấn Hai Riêng",
						type: "Thị trấn",
					},
					{
						level3_id: "22210",
						name: "Xã Ea Lâm",
						type: "Xã",
					},
					{
						level3_id: "22213",
						name: "Xã Đức Bình Tây",
						type: "Xã",
					},
					{
						level3_id: "22216",
						name: "Xã Ea Bá",
						type: "Xã",
					},
					{
						level3_id: "22219",
						name: "Xã Sơn Giang",
						type: "Xã",
					},
					{
						level3_id: "22222",
						name: "Xã Đức Bình Đông",
						type: "Xã",
					},
					{
						level3_id: "22225",
						name: "Xã EaBar",
						type: "Xã",
					},
					{
						level3_id: "22228",
						name: "Xã EaBia",
						type: "Xã",
					},
					{
						level3_id: "22231",
						name: "Xã EaTrol",
						type: "Xã",
					},
					{
						level3_id: "22234",
						name: "Xã Sông Hinh",
						type: "Xã",
					},
					{
						level3_id: "22237",
						name: "Xã Ealy",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "562",
				name: "Huyện Tây Hoà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22249",
						name: "Xã Sơn Thành Tây",
						type: "Xã",
					},
					{
						level3_id: "22250",
						name: "Xã Sơn Thành Đông",
						type: "Xã",
					},
					{
						level3_id: "22252",
						name: "Xã Hòa Bình 1",
						type: "Xã",
					},
					{
						level3_id: "22255",
						name: "Thị trấn Phú Thứ",
						type: "Thị trấn",
					},
					{
						level3_id: "22264",
						name: "Xã Hòa Phong",
						type: "Xã",
					},
					{
						level3_id: "22270",
						name: "Xã Hòa Phú",
						type: "Xã",
					},
					{
						level3_id: "22273",
						name: "Xã Hòa Tân Tây",
						type: "Xã",
					},
					{
						level3_id: "22276",
						name: "Xã Hòa Đồng",
						type: "Xã",
					},
					{
						level3_id: "22285",
						name: "Xã Hòa Mỹ Đông",
						type: "Xã",
					},
					{
						level3_id: "22288",
						name: "Xã Hòa Mỹ Tây",
						type: "Xã",
					},
					{
						level3_id: "22294",
						name: "Xã Hòa Thịnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "563",
				name: "Huyện Phú Hoà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22303",
						name: "Xã Hòa Quang Bắc",
						type: "Xã",
					},
					{
						level3_id: "22306",
						name: "Xã Hòa Quang Nam",
						type: "Xã",
					},
					{
						level3_id: "22309",
						name: "Xã Hòa Hội",
						type: "Xã",
					},
					{
						level3_id: "22312",
						name: "Xã Hòa Trị",
						type: "Xã",
					},
					{
						level3_id: "22315",
						name: "Xã Hòa An",
						type: "Xã",
					},
					{
						level3_id: "22318",
						name: "Xã Hòa Định Đông",
						type: "Xã",
					},
					{
						level3_id: "22319",
						name: "Thị Trấn Phú Hoà",
						type: "Thị trấn",
					},
					{
						level3_id: "22321",
						name: "Xã Hòa Định Tây",
						type: "Xã",
					},
					{
						level3_id: "22324",
						name: "Xã Hòa Thắng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "564",
				name: "Thị xã Đông Hòa",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "22243",
						name: "Xã Hòa Thành",
						type: "Xã",
					},
					{
						level3_id: "22246",
						name: "Phường Hòa Hiệp Bắc",
						type: "Phường",
					},
					{
						level3_id: "22258",
						name: "Phường Hoà Vinh",
						type: "Phường",
					},
					{
						level3_id: "22261",
						name: "Phường Hoà Hiệp Trung",
						type: "Phường",
					},
					{
						level3_id: "22267",
						name: "Xã Hòa Tân Đông",
						type: "Xã",
					},
					{
						level3_id: "22279",
						name: "Phường Hòa Xuân Tây",
						type: "Phường",
					},
					{
						level3_id: "22282",
						name: "Phường Hòa Hiệp Nam",
						type: "Phường",
					},
					{
						level3_id: "22291",
						name: "Xã Hòa Xuân Đông",
						type: "Xã",
					},
					{
						level3_id: "22297",
						name: "Xã Hòa Tâm",
						type: "Xã",
					},
					{
						level3_id: "22300",
						name: "Xã Hòa Xuân Nam",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "56",
		name: "Tỉnh Khánh Hòa",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "568",
				name: "Thành phố Nha Trang",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "22327",
						name: "Phường Vĩnh Hòa",
						type: "Phường",
					},
					{
						level3_id: "22330",
						name: "Phường Vĩnh Hải",
						type: "Phường",
					},
					{
						level3_id: "22333",
						name: "Phường Vĩnh Phước",
						type: "Phường",
					},
					{
						level3_id: "22336",
						name: "Phường Ngọc Hiệp",
						type: "Phường",
					},
					{
						level3_id: "22339",
						name: "Phường Vĩnh Thọ",
						type: "Phường",
					},
					{
						level3_id: "22342",
						name: "Phường Xương Huân",
						type: "Phường",
					},
					{
						level3_id: "22345",
						name: "Phường Vạn Thắng",
						type: "Phường",
					},
					{
						level3_id: "22348",
						name: "Phường Vạn Thạnh",
						type: "Phường",
					},
					{
						level3_id: "22351",
						name: "Phường Phương Sài",
						type: "Phường",
					},
					{
						level3_id: "22354",
						name: "Phường Phương Sơn",
						type: "Phường",
					},
					{
						level3_id: "22357",
						name: "Phường Phước Hải",
						type: "Phường",
					},
					{
						level3_id: "22360",
						name: "Phường Phước Tân",
						type: "Phường",
					},
					{
						level3_id: "22363",
						name: "Phường Lộc Thọ",
						type: "Phường",
					},
					{
						level3_id: "22366",
						name: "Phường Phước Tiến",
						type: "Phường",
					},
					{
						level3_id: "22369",
						name: "Phường Tân Lập",
						type: "Phường",
					},
					{
						level3_id: "22372",
						name: "Phường Phước Hòa",
						type: "Phường",
					},
					{
						level3_id: "22375",
						name: "Phường Vĩnh Nguyên",
						type: "Phường",
					},
					{
						level3_id: "22378",
						name: "Phường Phước Long",
						type: "Phường",
					},
					{
						level3_id: "22381",
						name: "Phường Vĩnh Trường",
						type: "Phường",
					},
					{
						level3_id: "22384",
						name: "Xã Vĩnh Lương",
						type: "Xã",
					},
					{
						level3_id: "22387",
						name: "Xã Vĩnh Phương",
						type: "Xã",
					},
					{
						level3_id: "22390",
						name: "Xã Vĩnh Ngọc",
						type: "Xã",
					},
					{
						level3_id: "22393",
						name: "Xã Vĩnh Thạnh",
						type: "Xã",
					},
					{
						level3_id: "22396",
						name: "Xã Vĩnh Trung",
						type: "Xã",
					},
					{
						level3_id: "22399",
						name: "Xã Vĩnh Hiệp",
						type: "Xã",
					},
					{
						level3_id: "22402",
						name: "Xã Vĩnh Thái",
						type: "Xã",
					},
					{
						level3_id: "22405",
						name: "Xã Phước Đồng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "569",
				name: "Thành phố Cam Ranh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "22408",
						name: "Phường Cam Nghĩa",
						type: "Phường",
					},
					{
						level3_id: "22411",
						name: "Phường Cam Phúc Bắc",
						type: "Phường",
					},
					{
						level3_id: "22414",
						name: "Phường Cam Phúc Nam",
						type: "Phường",
					},
					{
						level3_id: "22417",
						name: "Phường Cam Lộc",
						type: "Phường",
					},
					{
						level3_id: "22420",
						name: "Phường Cam Phú",
						type: "Phường",
					},
					{
						level3_id: "22423",
						name: "Phường Ba Ngòi",
						type: "Phường",
					},
					{
						level3_id: "22426",
						name: "Phường Cam Thuận",
						type: "Phường",
					},
					{
						level3_id: "22429",
						name: "Phường Cam Lợi",
						type: "Phường",
					},
					{
						level3_id: "22432",
						name: "Phường Cam Linh",
						type: "Phường",
					},
					{
						level3_id: "22468",
						name: "Xã Cam Thành Nam",
						type: "Xã",
					},
					{
						level3_id: "22474",
						name: "Xã Cam Phước Đông",
						type: "Xã",
					},
					{
						level3_id: "22477",
						name: "Xã Cam Thịnh Tây",
						type: "Xã",
					},
					{
						level3_id: "22480",
						name: "Xã Cam Thịnh Đông",
						type: "Xã",
					},
					{
						level3_id: "22483",
						name: "Xã Cam Lập",
						type: "Xã",
					},
					{
						level3_id: "22486",
						name: "Xã Cam Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "570",
				name: "Huyện Cam Lâm",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22435",
						name: "Xã Cam Tân",
						type: "Xã",
					},
					{
						level3_id: "22438",
						name: "Xã Cam Hòa",
						type: "Xã",
					},
					{
						level3_id: "22441",
						name: "Xã Cam Hải Đông",
						type: "Xã",
					},
					{
						level3_id: "22444",
						name: "Xã Cam Hải Tây",
						type: "Xã",
					},
					{
						level3_id: "22447",
						name: "Xã Sơn Tân",
						type: "Xã",
					},
					{
						level3_id: "22450",
						name: "Xã Cam Hiệp Bắc",
						type: "Xã",
					},
					{
						level3_id: "22453",
						name: "Thị trấn Cam Đức",
						type: "Thị trấn",
					},
					{
						level3_id: "22456",
						name: "Xã Cam Hiệp Nam",
						type: "Xã",
					},
					{
						level3_id: "22459",
						name: "Xã Cam Phước Tây",
						type: "Xã",
					},
					{
						level3_id: "22462",
						name: "Xã Cam Thành Bắc",
						type: "Xã",
					},
					{
						level3_id: "22465",
						name: "Xã Cam An Bắc",
						type: "Xã",
					},
					{
						level3_id: "22471",
						name: "Xã Cam An Nam",
						type: "Xã",
					},
					{
						level3_id: "22708",
						name: "Xã Suối Cát",
						type: "Xã",
					},
					{
						level3_id: "22711",
						name: "Xã Suối Tân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "571",
				name: "Huyện Vạn Ninh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22489",
						name: "Thị trấn Vạn Giã",
						type: "Thị trấn",
					},
					{
						level3_id: "22492",
						name: "Xã Đại Lãnh",
						type: "Xã",
					},
					{
						level3_id: "22495",
						name: "Xã Vạn Phước",
						type: "Xã",
					},
					{
						level3_id: "22498",
						name: "Xã Vạn Long",
						type: "Xã",
					},
					{
						level3_id: "22501",
						name: "Xã Vạn Bình",
						type: "Xã",
					},
					{
						level3_id: "22504",
						name: "Xã Vạn Thọ",
						type: "Xã",
					},
					{
						level3_id: "22507",
						name: "Xã Vạn Khánh",
						type: "Xã",
					},
					{
						level3_id: "22510",
						name: "Xã Vạn Phú",
						type: "Xã",
					},
					{
						level3_id: "22513",
						name: "Xã Vạn Lương",
						type: "Xã",
					},
					{
						level3_id: "22516",
						name: "Xã Vạn Thắng",
						type: "Xã",
					},
					{
						level3_id: "22519",
						name: "Xã Vạn Thạnh",
						type: "Xã",
					},
					{
						level3_id: "22522",
						name: "Xã Xuân Sơn",
						type: "Xã",
					},
					{
						level3_id: "22525",
						name: "Xã Vạn Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "572",
				name: "Thị xã Ninh Hòa",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "22528",
						name: "Phường Ninh Hiệp",
						type: "Phường",
					},
					{
						level3_id: "22531",
						name: "Xã Ninh Sơn",
						type: "Xã",
					},
					{
						level3_id: "22534",
						name: "Xã Ninh Tây",
						type: "Xã",
					},
					{
						level3_id: "22537",
						name: "Xã Ninh Thượng",
						type: "Xã",
					},
					{
						level3_id: "22540",
						name: "Xã Ninh An",
						type: "Xã",
					},
					{
						level3_id: "22543",
						name: "Phường Ninh Hải",
						type: "Phường",
					},
					{
						level3_id: "22546",
						name: "Xã Ninh Thọ",
						type: "Xã",
					},
					{
						level3_id: "22549",
						name: "Xã Ninh Trung",
						type: "Xã",
					},
					{
						level3_id: "22552",
						name: "Xã Ninh Sim",
						type: "Xã",
					},
					{
						level3_id: "22555",
						name: "Xã Ninh Xuân",
						type: "Xã",
					},
					{
						level3_id: "22558",
						name: "Xã Ninh Thân",
						type: "Xã",
					},
					{
						level3_id: "22561",
						name: "Phường Ninh Diêm",
						type: "Phường",
					},
					{
						level3_id: "22564",
						name: "Xã Ninh Đông",
						type: "Xã",
					},
					{
						level3_id: "22567",
						name: "Phường Ninh Thủy",
						type: "Phường",
					},
					{
						level3_id: "22570",
						name: "Phường Ninh Đa",
						type: "Phường",
					},
					{
						level3_id: "22573",
						name: "Xã Ninh Phụng",
						type: "Xã",
					},
					{
						level3_id: "22576",
						name: "Xã Ninh Bình",
						type: "Xã",
					},
					{
						level3_id: "22579",
						name: "Xã Ninh Phước",
						type: "Xã",
					},
					{
						level3_id: "22582",
						name: "Xã Ninh Phú",
						type: "Xã",
					},
					{
						level3_id: "22585",
						name: "Xã Ninh Tân",
						type: "Xã",
					},
					{
						level3_id: "22588",
						name: "Xã Ninh Quang",
						type: "Xã",
					},
					{
						level3_id: "22591",
						name: "Phường Ninh Giang",
						type: "Phường",
					},
					{
						level3_id: "22594",
						name: "Phường Ninh Hà",
						type: "Phường",
					},
					{
						level3_id: "22597",
						name: "Xã Ninh Hưng",
						type: "Xã",
					},
					{
						level3_id: "22600",
						name: "Xã Ninh Lộc",
						type: "Xã",
					},
					{
						level3_id: "22603",
						name: "Xã Ninh Ích",
						type: "Xã",
					},
					{
						level3_id: "22606",
						name: "Xã Ninh Vân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "573",
				name: "Huyện Khánh Vĩnh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22609",
						name: "Thị trấn Khánh Vĩnh",
						type: "Thị trấn",
					},
					{
						level3_id: "22612",
						name: "Xã Khánh Hiệp",
						type: "Xã",
					},
					{
						level3_id: "22615",
						name: "Xã Khánh Bình",
						type: "Xã",
					},
					{
						level3_id: "22618",
						name: "Xã Khánh Trung",
						type: "Xã",
					},
					{
						level3_id: "22621",
						name: "Xã Khánh Đông",
						type: "Xã",
					},
					{
						level3_id: "22624",
						name: "Xã Khánh Thượng",
						type: "Xã",
					},
					{
						level3_id: "22627",
						name: "Xã Khánh Nam",
						type: "Xã",
					},
					{
						level3_id: "22630",
						name: "Xã Sông Cầu",
						type: "Xã",
					},
					{
						level3_id: "22633",
						name: "Xã Giang Ly",
						type: "Xã",
					},
					{
						level3_id: "22636",
						name: "Xã Cầu Bà",
						type: "Xã",
					},
					{
						level3_id: "22639",
						name: "Xã Liên Sang",
						type: "Xã",
					},
					{
						level3_id: "22642",
						name: "Xã Khánh Thành",
						type: "Xã",
					},
					{
						level3_id: "22645",
						name: "Xã Khánh Phú",
						type: "Xã",
					},
					{
						level3_id: "22648",
						name: "Xã Sơn Thái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "574",
				name: "Huyện Diên Khánh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22651",
						name: "Thị trấn Diên Khánh",
						type: "Thị trấn",
					},
					{
						level3_id: "22654",
						name: "Xã Diên Lâm",
						type: "Xã",
					},
					{
						level3_id: "22657",
						name: "Xã Diên Điền",
						type: "Xã",
					},
					{
						level3_id: "22660",
						name: "Xã Diên Xuân",
						type: "Xã",
					},
					{
						level3_id: "22663",
						name: "Xã Diên Sơn",
						type: "Xã",
					},
					{
						level3_id: "22666",
						name: "Xã Diên Đồng",
						type: "Xã",
					},
					{
						level3_id: "22669",
						name: "Xã Diên Phú",
						type: "Xã",
					},
					{
						level3_id: "22672",
						name: "Xã Diên Thọ",
						type: "Xã",
					},
					{
						level3_id: "22675",
						name: "Xã Diên Phước",
						type: "Xã",
					},
					{
						level3_id: "22678",
						name: "Xã Diên Lạc",
						type: "Xã",
					},
					{
						level3_id: "22681",
						name: "Xã Diên Tân",
						type: "Xã",
					},
					{
						level3_id: "22684",
						name: "Xã Diên Hòa",
						type: "Xã",
					},
					{
						level3_id: "22687",
						name: "Xã Diên Thạnh",
						type: "Xã",
					},
					{
						level3_id: "22690",
						name: "Xã Diên Toàn",
						type: "Xã",
					},
					{
						level3_id: "22693",
						name: "Xã Diên An",
						type: "Xã",
					},
					{
						level3_id: "22696",
						name: "Xã Bình Lộc",
						type: "Xã",
					},
					{
						level3_id: "22702",
						name: "Xã Suối Hiệp",
						type: "Xã",
					},
					{
						level3_id: "22705",
						name: "Xã Suối Tiên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "575",
				name: "Huyện Khánh Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22714",
						name: "Thị trấn Tô Hạp",
						type: "Thị trấn",
					},
					{
						level3_id: "22717",
						name: "Xã Thành Sơn",
						type: "Xã",
					},
					{
						level3_id: "22720",
						name: "Xã Sơn Lâm",
						type: "Xã",
					},
					{
						level3_id: "22723",
						name: "Xã Sơn Hiệp",
						type: "Xã",
					},
					{
						level3_id: "22726",
						name: "Xã Sơn Bình",
						type: "Xã",
					},
					{
						level3_id: "22729",
						name: "Xã Sơn Trung",
						type: "Xã",
					},
					{
						level3_id: "22732",
						name: "Xã Ba Cụm Bắc",
						type: "Xã",
					},
					{
						level3_id: "22735",
						name: "Xã Ba Cụm Nam",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "576",
				name: "Huyện Trường Sa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22736",
						name: "Thị trấn Trường Sa",
						type: "Thị trấn",
					},
					{
						level3_id: "22737",
						name: "Xã Song Tử Tây",
						type: "Xã",
					},
					{
						level3_id: "22739",
						name: "Xã Sinh Tồn",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "58",
		name: "Tỉnh Ninh Thuận",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "582",
				name: "Thành phố Phan Rang-Tháp Chàm",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "22738",
						name: "Phường Đô Vinh",
						type: "Phường",
					},
					{
						level3_id: "22741",
						name: "Phường Phước Mỹ",
						type: "Phường",
					},
					{
						level3_id: "22744",
						name: "Phường Bảo An",
						type: "Phường",
					},
					{
						level3_id: "22747",
						name: "Phường Phủ Hà",
						type: "Phường",
					},
					{
						level3_id: "22750",
						name: "Phường Thanh Sơn",
						type: "Phường",
					},
					{
						level3_id: "22753",
						name: "Phường Mỹ Hương",
						type: "Phường",
					},
					{
						level3_id: "22756",
						name: "Phường Tấn Tài",
						type: "Phường",
					},
					{
						level3_id: "22759",
						name: "Phường Kinh Dinh",
						type: "Phường",
					},
					{
						level3_id: "22762",
						name: "Phường Đạo Long",
						type: "Phường",
					},
					{
						level3_id: "22765",
						name: "Phường Đài Sơn",
						type: "Phường",
					},
					{
						level3_id: "22768",
						name: "Phường Đông Hải",
						type: "Phường",
					},
					{
						level3_id: "22771",
						name: "Phường Mỹ Đông",
						type: "Phường",
					},
					{
						level3_id: "22774",
						name: "Xã Thành Hải",
						type: "Xã",
					},
					{
						level3_id: "22777",
						name: "Phường Văn Hải",
						type: "Phường",
					},
					{
						level3_id: "22779",
						name: "Phường Mỹ Bình",
						type: "Phường",
					},
					{
						level3_id: "22780",
						name: "Phường Mỹ Hải",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "584",
				name: "Huyện Bác Ái",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22783",
						name: "Xã Phước Bình",
						type: "Xã",
					},
					{
						level3_id: "22786",
						name: "Xã Phước Hòa",
						type: "Xã",
					},
					{
						level3_id: "22789",
						name: "Xã Phước Tân",
						type: "Xã",
					},
					{
						level3_id: "22792",
						name: "Xã Phước Tiến",
						type: "Xã",
					},
					{
						level3_id: "22795",
						name: "Xã Phước Thắng",
						type: "Xã",
					},
					{
						level3_id: "22798",
						name: "Xã Phước Thành",
						type: "Xã",
					},
					{
						level3_id: "22801",
						name: "Xã Phước Đại",
						type: "Xã",
					},
					{
						level3_id: "22804",
						name: "Xã Phước Chính",
						type: "Xã",
					},
					{
						level3_id: "22807",
						name: "Xã Phước Trung",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "585",
				name: "Huyện Ninh Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22810",
						name: "Thị trấn Tân Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "22813",
						name: "Xã Lâm Sơn",
						type: "Xã",
					},
					{
						level3_id: "22816",
						name: "Xã Lương Sơn",
						type: "Xã",
					},
					{
						level3_id: "22819",
						name: "Xã Quảng Sơn",
						type: "Xã",
					},
					{
						level3_id: "22822",
						name: "Xã Mỹ Sơn",
						type: "Xã",
					},
					{
						level3_id: "22825",
						name: "Xã Hòa Sơn",
						type: "Xã",
					},
					{
						level3_id: "22828",
						name: "Xã Ma Nới",
						type: "Xã",
					},
					{
						level3_id: "22831",
						name: "Xã Nhơn Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "586",
				name: "Huyện Ninh Hải",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22834",
						name: "Thị trấn Khánh Hải",
						type: "Thị trấn",
					},
					{
						level3_id: "22846",
						name: "Xã Vĩnh Hải",
						type: "Xã",
					},
					{
						level3_id: "22852",
						name: "Xã Phương Hải",
						type: "Xã",
					},
					{
						level3_id: "22855",
						name: "Xã Tân Hải",
						type: "Xã",
					},
					{
						level3_id: "22858",
						name: "Xã Xuân Hải",
						type: "Xã",
					},
					{
						level3_id: "22861",
						name: "Xã Hộ Hải",
						type: "Xã",
					},
					{
						level3_id: "22864",
						name: "Xã Tri Hải",
						type: "Xã",
					},
					{
						level3_id: "22867",
						name: "Xã Nhơn Hải",
						type: "Xã",
					},
					{
						level3_id: "22868",
						name: "Xã Thanh Hải",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "587",
				name: "Huyện Ninh Phước",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22870",
						name: "Thị trấn Phước Dân",
						type: "Thị trấn",
					},
					{
						level3_id: "22873",
						name: "Xã Phước Sơn",
						type: "Xã",
					},
					{
						level3_id: "22876",
						name: "Xã Phước Thái",
						type: "Xã",
					},
					{
						level3_id: "22879",
						name: "Xã Phước Hậu",
						type: "Xã",
					},
					{
						level3_id: "22882",
						name: "Xã Phước Thuận",
						type: "Xã",
					},
					{
						level3_id: "22888",
						name: "Xã An Hải",
						type: "Xã",
					},
					{
						level3_id: "22891",
						name: "Xã Phước Hữu",
						type: "Xã",
					},
					{
						level3_id: "22894",
						name: "Xã Phước Hải",
						type: "Xã",
					},
					{
						level3_id: "22912",
						name: "Xã Phước Vinh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "588",
				name: "Huyện Thuận Bắc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22837",
						name: "Xã Phước Chiến",
						type: "Xã",
					},
					{
						level3_id: "22840",
						name: "Xã Công Hải",
						type: "Xã",
					},
					{
						level3_id: "22843",
						name: "Xã Phước Kháng",
						type: "Xã",
					},
					{
						level3_id: "22849",
						name: "Xã Lợi Hải",
						type: "Xã",
					},
					{
						level3_id: "22853",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "22856",
						name: "Xã Bắc Phong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "589",
				name: "Huyện Thuận Nam",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22885",
						name: "Xã Phước Hà",
						type: "Xã",
					},
					{
						level3_id: "22897",
						name: "Xã Phước Nam",
						type: "Xã",
					},
					{
						level3_id: "22898",
						name: "Xã Phước Ninh",
						type: "Xã",
					},
					{
						level3_id: "22900",
						name: "Xã Nhị Hà",
						type: "Xã",
					},
					{
						level3_id: "22903",
						name: "Xã Phước Dinh",
						type: "Xã",
					},
					{
						level3_id: "22906",
						name: "Xã Phước Minh",
						type: "Xã",
					},
					{
						level3_id: "22909",
						name: "Xã Phước Diêm",
						type: "Xã",
					},
					{
						level3_id: "22910",
						name: "Xã Cà Ná",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "60",
		name: "Tỉnh Bình Thuận",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "593",
				name: "Thành phố Phan Thiết",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "22915",
						name: "Phường Mũi Né",
						type: "Phường",
					},
					{
						level3_id: "22918",
						name: "Phường Hàm Tiến",
						type: "Phường",
					},
					{
						level3_id: "22921",
						name: "Phường Phú Hài",
						type: "Phường",
					},
					{
						level3_id: "22924",
						name: "Phường Phú Thủy",
						type: "Phường",
					},
					{
						level3_id: "22927",
						name: "Phường Phú Tài",
						type: "Phường",
					},
					{
						level3_id: "22930",
						name: "Phường Phú Trinh",
						type: "Phường",
					},
					{
						level3_id: "22933",
						name: "Phường Xuân An",
						type: "Phường",
					},
					{
						level3_id: "22936",
						name: "Phường Thanh Hải",
						type: "Phường",
					},
					{
						level3_id: "22939",
						name: "Phường Bình Hưng",
						type: "Phường",
					},
					{
						level3_id: "22942",
						name: "Phường Đức Nghĩa",
						type: "Phường",
					},
					{
						level3_id: "22945",
						name: "Phường Lạc Đạo",
						type: "Phường",
					},
					{
						level3_id: "22948",
						name: "Phường Đức Thắng",
						type: "Phường",
					},
					{
						level3_id: "22951",
						name: "Phường Hưng Long",
						type: "Phường",
					},
					{
						level3_id: "22954",
						name: "Phường Đức Long",
						type: "Phường",
					},
					{
						level3_id: "22957",
						name: "Xã Thiện Nghiệp",
						type: "Xã",
					},
					{
						level3_id: "22960",
						name: "Xã Phong Nẫm",
						type: "Xã",
					},
					{
						level3_id: "22963",
						name: "Xã Tiến Lợi",
						type: "Xã",
					},
					{
						level3_id: "22966",
						name: "Xã Tiến Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "594",
				name: "Thị xã La Gi",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "23231",
						name: "Phường Phước Hội",
						type: "Phường",
					},
					{
						level3_id: "23232",
						name: "Phường Phước Lộc",
						type: "Phường",
					},
					{
						level3_id: "23234",
						name: "Phường Tân Thiện",
						type: "Phường",
					},
					{
						level3_id: "23235",
						name: "Phường Tân An",
						type: "Phường",
					},
					{
						level3_id: "23237",
						name: "Phường Bình Tân",
						type: "Phường",
					},
					{
						level3_id: "23245",
						name: "Xã Tân Hải",
						type: "Xã",
					},
					{
						level3_id: "23246",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "23248",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "23268",
						name: "Xã Tân Phước",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "595",
				name: "Huyện Tuy Phong",
				type: "Huyện",
				level3s: [
					{
						level3_id: "22969",
						name: "Thị trấn Liên Hương",
						type: "Thị trấn",
					},
					{
						level3_id: "22972",
						name: "Thị trấn Phan Rí Cửa",
						type: "Thị trấn",
					},
					{
						level3_id: "22975",
						name: "Xã Phan Dũng",
						type: "Xã",
					},
					{
						level3_id: "22978",
						name: "Xã Phong Phú",
						type: "Xã",
					},
					{
						level3_id: "22981",
						name: "Xã Vĩnh Hảo",
						type: "Xã",
					},
					{
						level3_id: "22984",
						name: "Xã Vĩnh Tân",
						type: "Xã",
					},
					{
						level3_id: "22987",
						name: "Xã Phú Lạc",
						type: "Xã",
					},
					{
						level3_id: "22990",
						name: "Xã Phước Thể",
						type: "Xã",
					},
					{
						level3_id: "22993",
						name: "Xã Hòa Minh",
						type: "Xã",
					},
					{
						level3_id: "22996",
						name: "Xã Chí Công",
						type: "Xã",
					},
					{
						level3_id: "22999",
						name: "Xã Bình Thạnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "596",
				name: "Huyện Bắc Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23005",
						name: "Thị trấn Chợ Lầu",
						type: "Thị trấn",
					},
					{
						level3_id: "23008",
						name: "Xã Phan Sơn",
						type: "Xã",
					},
					{
						level3_id: "23011",
						name: "Xã Phan Lâm",
						type: "Xã",
					},
					{
						level3_id: "23014",
						name: "Xã Bình An",
						type: "Xã",
					},
					{
						level3_id: "23017",
						name: "Xã Phan Điền",
						type: "Xã",
					},
					{
						level3_id: "23020",
						name: "Xã Hải Ninh",
						type: "Xã",
					},
					{
						level3_id: "23023",
						name: "Xã Sông Lũy",
						type: "Xã",
					},
					{
						level3_id: "23026",
						name: "Xã Phan Tiến",
						type: "Xã",
					},
					{
						level3_id: "23029",
						name: "Xã Sông Bình",
						type: "Xã",
					},
					{
						level3_id: "23032",
						name: "Thị trấn Lương Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "23035",
						name: "Xã Phan Hòa",
						type: "Xã",
					},
					{
						level3_id: "23038",
						name: "Xã Phan Thanh",
						type: "Xã",
					},
					{
						level3_id: "23041",
						name: "Xã Hồng Thái",
						type: "Xã",
					},
					{
						level3_id: "23044",
						name: "Xã Phan Hiệp",
						type: "Xã",
					},
					{
						level3_id: "23047",
						name: "Xã Bình Tân",
						type: "Xã",
					},
					{
						level3_id: "23050",
						name: "Xã Phan Rí Thành",
						type: "Xã",
					},
					{
						level3_id: "23053",
						name: "Xã Hòa Thắng",
						type: "Xã",
					},
					{
						level3_id: "23056",
						name: "Xã Hồng Phong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "597",
				name: "Huyện Hàm Thuận Bắc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23059",
						name: "Thị trấn Ma Lâm",
						type: "Thị trấn",
					},
					{
						level3_id: "23062",
						name: "Thị trấn Phú Long",
						type: "Thị trấn",
					},
					{
						level3_id: "23065",
						name: "Xã La Dạ",
						type: "Xã",
					},
					{
						level3_id: "23068",
						name: "Xã Đông Tiến",
						type: "Xã",
					},
					{
						level3_id: "23071",
						name: "Xã Thuận Hòa",
						type: "Xã",
					},
					{
						level3_id: "23074",
						name: "Xã Đông Giang",
						type: "Xã",
					},
					{
						level3_id: "23077",
						name: "Xã Hàm Phú",
						type: "Xã",
					},
					{
						level3_id: "23080",
						name: "Xã Hồng Liêm",
						type: "Xã",
					},
					{
						level3_id: "23083",
						name: "Xã Thuận Minh",
						type: "Xã",
					},
					{
						level3_id: "23086",
						name: "Xã Hồng Sơn",
						type: "Xã",
					},
					{
						level3_id: "23089",
						name: "Xã Hàm Trí",
						type: "Xã",
					},
					{
						level3_id: "23092",
						name: "Xã Hàm Đức",
						type: "Xã",
					},
					{
						level3_id: "23095",
						name: "Xã Hàm Liêm",
						type: "Xã",
					},
					{
						level3_id: "23098",
						name: "Xã Hàm Chính",
						type: "Xã",
					},
					{
						level3_id: "23101",
						name: "Xã Hàm Hiệp",
						type: "Xã",
					},
					{
						level3_id: "23104",
						name: "Xã Hàm Thắng",
						type: "Xã",
					},
					{
						level3_id: "23107",
						name: "Xã Đa Mi",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "598",
				name: "Huyện Hàm Thuận Nam",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23110",
						name: "Thị trấn Thuận Nam",
						type: "Thị trấn",
					},
					{
						level3_id: "23113",
						name: "Xã Mỹ Thạnh",
						type: "Xã",
					},
					{
						level3_id: "23116",
						name: "Xã Hàm Cần",
						type: "Xã",
					},
					{
						level3_id: "23119",
						name: "Xã Mương Mán",
						type: "Xã",
					},
					{
						level3_id: "23122",
						name: "Xã Hàm Thạnh",
						type: "Xã",
					},
					{
						level3_id: "23125",
						name: "Xã Hàm Kiệm",
						type: "Xã",
					},
					{
						level3_id: "23128",
						name: "Xã Hàm Cường",
						type: "Xã",
					},
					{
						level3_id: "23131",
						name: "Xã Hàm Mỹ",
						type: "Xã",
					},
					{
						level3_id: "23134",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "23137",
						name: "Xã Hàm Minh",
						type: "Xã",
					},
					{
						level3_id: "23140",
						name: "Xã Thuận Quí",
						type: "Xã",
					},
					{
						level3_id: "23143",
						name: "Xã Tân Thuận",
						type: "Xã",
					},
					{
						level3_id: "23146",
						name: "Xã Tân Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "599",
				name: "Huyện Tánh Linh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23149",
						name: "Thị trấn Lạc Tánh",
						type: "Thị trấn",
					},
					{
						level3_id: "23152",
						name: "Xã Bắc Ruộng",
						type: "Xã",
					},
					{
						level3_id: "23158",
						name: "Xã Nghị Đức",
						type: "Xã",
					},
					{
						level3_id: "23161",
						name: "Xã La Ngâu",
						type: "Xã",
					},
					{
						level3_id: "23164",
						name: "Xã Huy Khiêm",
						type: "Xã",
					},
					{
						level3_id: "23167",
						name: "Xã Măng Tố",
						type: "Xã",
					},
					{
						level3_id: "23170",
						name: "Xã Đức Phú",
						type: "Xã",
					},
					{
						level3_id: "23173",
						name: "Xã Đồng Kho",
						type: "Xã",
					},
					{
						level3_id: "23176",
						name: "Xã Gia An",
						type: "Xã",
					},
					{
						level3_id: "23179",
						name: "Xã Đức Bình",
						type: "Xã",
					},
					{
						level3_id: "23182",
						name: "Xã Gia Huynh",
						type: "Xã",
					},
					{
						level3_id: "23185",
						name: "Xã Đức Thuận",
						type: "Xã",
					},
					{
						level3_id: "23188",
						name: "Xã Suối Kiết",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "600",
				name: "Huyện Đức Linh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23191",
						name: "Thị trấn Võ Xu",
						type: "Thị trấn",
					},
					{
						level3_id: "23194",
						name: "Thị trấn Đức Tài",
						type: "Thị trấn",
					},
					{
						level3_id: "23197",
						name: "Xã Đa Kai",
						type: "Xã",
					},
					{
						level3_id: "23200",
						name: "Xã Sùng Nhơn",
						type: "Xã",
					},
					{
						level3_id: "23203",
						name: "Xã Mê Pu",
						type: "Xã",
					},
					{
						level3_id: "23206",
						name: "Xã Nam Chính",
						type: "Xã",
					},
					{
						level3_id: "23212",
						name: "Xã Đức Hạnh",
						type: "Xã",
					},
					{
						level3_id: "23215",
						name: "Xã Đức Tín",
						type: "Xã",
					},
					{
						level3_id: "23218",
						name: "Xã Vũ Hoà",
						type: "Xã",
					},
					{
						level3_id: "23221",
						name: "Xã Tân Hà",
						type: "Xã",
					},
					{
						level3_id: "23224",
						name: "Xã Đông Hà",
						type: "Xã",
					},
					{
						level3_id: "23227",
						name: "Xã Trà Tân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "601",
				name: "Huyện Hàm Tân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23230",
						name: "Thị trấn Tân Minh",
						type: "Thị trấn",
					},
					{
						level3_id: "23236",
						name: "Thị trấn Tân Nghĩa",
						type: "Thị trấn",
					},
					{
						level3_id: "23239",
						name: "Xã Sông Phan",
						type: "Xã",
					},
					{
						level3_id: "23242",
						name: "Xã Tân Phúc",
						type: "Xã",
					},
					{
						level3_id: "23251",
						name: "Xã Tân Đức",
						type: "Xã",
					},
					{
						level3_id: "23254",
						name: "Xã Tân Thắng",
						type: "Xã",
					},
					{
						level3_id: "23255",
						name: "Xã Thắng Hải",
						type: "Xã",
					},
					{
						level3_id: "23257",
						name: "Xã Tân Hà",
						type: "Xã",
					},
					{
						level3_id: "23260",
						name: "Xã Tân Xuân",
						type: "Xã",
					},
					{
						level3_id: "23266",
						name: "Xã Sơn Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "602",
				name: "Huyện Phú Quí",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23272",
						name: "Xã Ngũ Phụng",
						type: "Xã",
					},
					{
						level3_id: "23275",
						name: "Xã Long Hải",
						type: "Xã",
					},
					{
						level3_id: "23278",
						name: "Xã Tam Thanh",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "62",
		name: "Tỉnh Kon Tum",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "608",
				name: "Thành phố Kon Tum",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "23281",
						name: "Phường Quang Trung",
						type: "Phường",
					},
					{
						level3_id: "23284",
						name: "Phường Duy Tân",
						type: "Phường",
					},
					{
						level3_id: "23287",
						name: "Phường Quyết Thắng",
						type: "Phường",
					},
					{
						level3_id: "23290",
						name: "Phường Trường Chinh",
						type: "Phường",
					},
					{
						level3_id: "23293",
						name: "Phường Thắng Lợi",
						type: "Phường",
					},
					{
						level3_id: "23296",
						name: "Phường Ngô Mây",
						type: "Phường",
					},
					{
						level3_id: "23299",
						name: "Phường Thống Nhất",
						type: "Phường",
					},
					{
						level3_id: "23302",
						name: "Phường Lê Lợi",
						type: "Phường",
					},
					{
						level3_id: "23305",
						name: "Phường Nguyễn Trãi",
						type: "Phường",
					},
					{
						level3_id: "23308",
						name: "Phường Trần Hưng Đạo",
						type: "Phường",
					},
					{
						level3_id: "23311",
						name: "Xã Đắk Cấm",
						type: "Xã",
					},
					{
						level3_id: "23314",
						name: "Xã Kroong",
						type: "Xã",
					},
					{
						level3_id: "23317",
						name: "Xã Ngọk Bay",
						type: "Xã",
					},
					{
						level3_id: "23320",
						name: "Xã Vinh Quang",
						type: "Xã",
					},
					{
						level3_id: "23323",
						name: "Xã Đắk Blà",
						type: "Xã",
					},
					{
						level3_id: "23326",
						name: "Xã Ia Chim",
						type: "Xã",
					},
					{
						level3_id: "23327",
						name: "Xã Đăk Năng",
						type: "Xã",
					},
					{
						level3_id: "23329",
						name: "Xã Đoàn Kết",
						type: "Xã",
					},
					{
						level3_id: "23332",
						name: "Xã Chư Hreng",
						type: "Xã",
					},
					{
						level3_id: "23335",
						name: "Xã Đắk Rơ Wa",
						type: "Xã",
					},
					{
						level3_id: "23338",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "610",
				name: "Huyện Đắk Glei",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23341",
						name: "Thị trấn Đắk Glei",
						type: "Thị trấn",
					},
					{
						level3_id: "23344",
						name: "Xã Đắk Blô",
						type: "Xã",
					},
					{
						level3_id: "23347",
						name: "Xã Đắk Man",
						type: "Xã",
					},
					{
						level3_id: "23350",
						name: "Xã Đắk Nhoong",
						type: "Xã",
					},
					{
						level3_id: "23353",
						name: "Xã Đắk Pék",
						type: "Xã",
					},
					{
						level3_id: "23356",
						name: "Xã Đắk Choong",
						type: "Xã",
					},
					{
						level3_id: "23359",
						name: "Xã Xốp",
						type: "Xã",
					},
					{
						level3_id: "23362",
						name: "Xã Mường Hoong",
						type: "Xã",
					},
					{
						level3_id: "23365",
						name: "Xã Ngọc Linh",
						type: "Xã",
					},
					{
						level3_id: "23368",
						name: "Xã Đắk Long",
						type: "Xã",
					},
					{
						level3_id: "23371",
						name: "Xã Đắk KRoong",
						type: "Xã",
					},
					{
						level3_id: "23374",
						name: "Xã Đắk Môn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "611",
				name: "Huyện Ngọc Hồi",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23377",
						name: "Thị trấn Plei Kần",
						type: "Thị trấn",
					},
					{
						level3_id: "23380",
						name: "Xã Đắk Ang",
						type: "Xã",
					},
					{
						level3_id: "23383",
						name: "Xã Đắk Dục",
						type: "Xã",
					},
					{
						level3_id: "23386",
						name: "Xã Đắk Nông",
						type: "Xã",
					},
					{
						level3_id: "23389",
						name: "Xã Đắk Xú",
						type: "Xã",
					},
					{
						level3_id: "23392",
						name: "Xã Đắk Kan",
						type: "Xã",
					},
					{
						level3_id: "23395",
						name: "Xã Bờ Y",
						type: "Xã",
					},
					{
						level3_id: "23398",
						name: "Xã Sa Loong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "612",
				name: "Huyện Đắk Tô",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23401",
						name: "Thị trấn Đắk Tô",
						type: "Thị trấn",
					},
					{
						level3_id: "23427",
						name: "Xã Đắk Rơ Nga",
						type: "Xã",
					},
					{
						level3_id: "23428",
						name: "Xã Ngọk Tụ",
						type: "Xã",
					},
					{
						level3_id: "23430",
						name: "Xã Đắk Trăm",
						type: "Xã",
					},
					{
						level3_id: "23431",
						name: "Xã Văn Lem",
						type: "Xã",
					},
					{
						level3_id: "23434",
						name: "Xã Kon Đào",
						type: "Xã",
					},
					{
						level3_id: "23437",
						name: "Xã Tân Cảnh",
						type: "Xã",
					},
					{
						level3_id: "23440",
						name: "Xã Diên Bình",
						type: "Xã",
					},
					{
						level3_id: "23443",
						name: "Xã Pô Kô",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "613",
				name: "Huyện Kon Plông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23452",
						name: "Xã Đắk Nên",
						type: "Xã",
					},
					{
						level3_id: "23455",
						name: "Xã Đắk Ring",
						type: "Xã",
					},
					{
						level3_id: "23458",
						name: "Xã Măng Buk",
						type: "Xã",
					},
					{
						level3_id: "23461",
						name: "Xã Đắk Tăng",
						type: "Xã",
					},
					{
						level3_id: "23464",
						name: "Xã Ngok Tem",
						type: "Xã",
					},
					{
						level3_id: "23467",
						name: "Xã Pờ Ê",
						type: "Xã",
					},
					{
						level3_id: "23470",
						name: "Xã Măng Cành",
						type: "Xã",
					},
					{
						level3_id: "23473",
						name: "Thị trấn Măng Đen",
						type: "Thị trấn",
					},
					{
						level3_id: "23476",
						name: "Xã Hiếu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "614",
				name: "Huyện Kon Rẫy",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23479",
						name: "Thị trấn Đắk Rve",
						type: "Thị trấn",
					},
					{
						level3_id: "23482",
						name: "Xã Đắk Kôi",
						type: "Xã",
					},
					{
						level3_id: "23485",
						name: "Xã Đắk Tơ Lung",
						type: "Xã",
					},
					{
						level3_id: "23488",
						name: "Xã Đắk Ruồng",
						type: "Xã",
					},
					{
						level3_id: "23491",
						name: "Xã Đắk Pne",
						type: "Xã",
					},
					{
						level3_id: "23494",
						name: "Xã Đắk Tờ Re",
						type: "Xã",
					},
					{
						level3_id: "23497",
						name: "Xã Tân Lập",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "615",
				name: "Huyện Đắk Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23500",
						name: "Thị trấn Đắk Hà",
						type: "Thị trấn",
					},
					{
						level3_id: "23503",
						name: "Xã Đắk PXi",
						type: "Xã",
					},
					{
						level3_id: "23504",
						name: "Xã Đăk Long",
						type: "Xã",
					},
					{
						level3_id: "23506",
						name: "Xã Đắk HRing",
						type: "Xã",
					},
					{
						level3_id: "23509",
						name: "Xã Đắk Ui",
						type: "Xã",
					},
					{
						level3_id: "23510",
						name: "Xã Đăk Ngọk",
						type: "Xã",
					},
					{
						level3_id: "23512",
						name: "Xã Đắk Mar",
						type: "Xã",
					},
					{
						level3_id: "23515",
						name: "Xã Ngok Wang",
						type: "Xã",
					},
					{
						level3_id: "23518",
						name: "Xã Ngok Réo",
						type: "Xã",
					},
					{
						level3_id: "23521",
						name: "Xã Hà Mòn",
						type: "Xã",
					},
					{
						level3_id: "23524",
						name: "Xã Đắk La",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "616",
				name: "Huyện Sa Thầy",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23527",
						name: "Thị trấn Sa Thầy",
						type: "Thị trấn",
					},
					{
						level3_id: "23530",
						name: "Xã Rơ Kơi",
						type: "Xã",
					},
					{
						level3_id: "23533",
						name: "Xã Sa Nhơn",
						type: "Xã",
					},
					{
						level3_id: "23534",
						name: "Xã Hơ Moong",
						type: "Xã",
					},
					{
						level3_id: "23536",
						name: "Xã Mô Rai",
						type: "Xã",
					},
					{
						level3_id: "23539",
						name: "Xã Sa Sơn",
						type: "Xã",
					},
					{
						level3_id: "23542",
						name: "Xã Sa Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "23545",
						name: "Xã Sa Bình",
						type: "Xã",
					},
					{
						level3_id: "23548",
						name: "Xã Ya Xiêr",
						type: "Xã",
					},
					{
						level3_id: "23551",
						name: "Xã Ya Tăng",
						type: "Xã",
					},
					{
						level3_id: "23554",
						name: "Xã Ya ly",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "617",
				name: "Huyện Tu Mơ Rông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23404",
						name: "Xã Ngọc Lây",
						type: "Xã",
					},
					{
						level3_id: "23407",
						name: "Xã Đắk Na",
						type: "Xã",
					},
					{
						level3_id: "23410",
						name: "Xã Măng Ri",
						type: "Xã",
					},
					{
						level3_id: "23413",
						name: "Xã Ngọc Yêu",
						type: "Xã",
					},
					{
						level3_id: "23416",
						name: "Xã Đắk Sao",
						type: "Xã",
					},
					{
						level3_id: "23417",
						name: "Xã Đắk Rơ Ông",
						type: "Xã",
					},
					{
						level3_id: "23419",
						name: "Xã Đắk Tờ Kan",
						type: "Xã",
					},
					{
						level3_id: "23422",
						name: "Xã Tu Mơ Rông",
						type: "Xã",
					},
					{
						level3_id: "23425",
						name: "Xã Đắk Hà",
						type: "Xã",
					},
					{
						level3_id: "23446",
						name: "Xã Tê Xăng",
						type: "Xã",
					},
					{
						level3_id: "23449",
						name: "Xã Văn Xuôi",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "618",
				name: "Huyện Ia H' Drai",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23535",
						name: "Xã Ia Đal",
						type: "Xã",
					},
					{
						level3_id: "23537",
						name: "Xã Ia Dom",
						type: "Xã",
					},
					{
						level3_id: "23538",
						name: "Xã Ia Tơi",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "64",
		name: "Tỉnh Gia Lai",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "622",
				name: "Thành phố Pleiku",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "23557",
						name: "Phường Yên Đỗ",
						type: "Phường",
					},
					{
						level3_id: "23560",
						name: "Phường Diên Hồng",
						type: "Phường",
					},
					{
						level3_id: "23563",
						name: "Phường Ia Kring",
						type: "Phường",
					},
					{
						level3_id: "23566",
						name: "Phường Hội Thương",
						type: "Phường",
					},
					{
						level3_id: "23569",
						name: "Phường Hội Phú",
						type: "Phường",
					},
					{
						level3_id: "23570",
						name: "Phường Phù Đổng",
						type: "Phường",
					},
					{
						level3_id: "23572",
						name: "Phường Hoa Lư",
						type: "Phường",
					},
					{
						level3_id: "23575",
						name: "Phường Tây Sơn",
						type: "Phường",
					},
					{
						level3_id: "23578",
						name: "Phường Thống Nhất",
						type: "Phường",
					},
					{
						level3_id: "23579",
						name: "Phường Đống Đa",
						type: "Phường",
					},
					{
						level3_id: "23581",
						name: "Phường Trà Bá",
						type: "Phường",
					},
					{
						level3_id: "23582",
						name: "Phường Thắng Lợi",
						type: "Phường",
					},
					{
						level3_id: "23584",
						name: "Phường Yên Thế",
						type: "Phường",
					},
					{
						level3_id: "23586",
						name: "Phường Chi Lăng",
						type: "Phường",
					},
					{
						level3_id: "23590",
						name: "Xã Biển Hồ",
						type: "Xã",
					},
					{
						level3_id: "23593",
						name: "Xã Tân Sơn",
						type: "Xã",
					},
					{
						level3_id: "23596",
						name: "Xã Trà Đa",
						type: "Xã",
					},
					{
						level3_id: "23599",
						name: "Xã Chư Á",
						type: "Xã",
					},
					{
						level3_id: "23602",
						name: "Xã An Phú",
						type: "Xã",
					},
					{
						level3_id: "23605",
						name: "Xã Diên Phú",
						type: "Xã",
					},
					{
						level3_id: "23608",
						name: "Xã Ia Kênh",
						type: "Xã",
					},
					{
						level3_id: "23611",
						name: "Xã Gào",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "623",
				name: "Thị xã An Khê",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "23614",
						name: "Phường An Bình",
						type: "Phường",
					},
					{
						level3_id: "23617",
						name: "Phường Tây Sơn",
						type: "Phường",
					},
					{
						level3_id: "23620",
						name: "Phường An Phú",
						type: "Phường",
					},
					{
						level3_id: "23623",
						name: "Phường An Tân",
						type: "Phường",
					},
					{
						level3_id: "23626",
						name: "Xã Tú An",
						type: "Xã",
					},
					{
						level3_id: "23627",
						name: "Xã Xuân An",
						type: "Xã",
					},
					{
						level3_id: "23629",
						name: "Xã Cửu An",
						type: "Xã",
					},
					{
						level3_id: "23630",
						name: "Phường An Phước",
						type: "Phường",
					},
					{
						level3_id: "23632",
						name: "Xã Song An",
						type: "Xã",
					},
					{
						level3_id: "23633",
						name: "Phường Ngô Mây",
						type: "Phường",
					},
					{
						level3_id: "23635",
						name: "Xã Thành An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "624",
				name: "Thị xã Ayun Pa",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "24041",
						name: "Phường Cheo Reo",
						type: "Phường",
					},
					{
						level3_id: "24042",
						name: "Phường Hòa Bình",
						type: "Phường",
					},
					{
						level3_id: "24044",
						name: "Phường Đoàn Kết",
						type: "Phường",
					},
					{
						level3_id: "24045",
						name: "Phường Sông Bờ",
						type: "Phường",
					},
					{
						level3_id: "24064",
						name: "Xã Ia RBol",
						type: "Xã",
					},
					{
						level3_id: "24065",
						name: "Xã Chư Băh",
						type: "Xã",
					},
					{
						level3_id: "24070",
						name: "Xã Ia RTô",
						type: "Xã",
					},
					{
						level3_id: "24073",
						name: "Xã Ia Sao",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "625",
				name: "Huyện KBang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23638",
						name: "Thị trấn KBang",
						type: "Thị trấn",
					},
					{
						level3_id: "23641",
						name: "Xã Kon Pne",
						type: "Xã",
					},
					{
						level3_id: "23644",
						name: "Xã Đăk Roong",
						type: "Xã",
					},
					{
						level3_id: "23647",
						name: "Xã Sơn Lang",
						type: "Xã",
					},
					{
						level3_id: "23650",
						name: "Xã KRong",
						type: "Xã",
					},
					{
						level3_id: "23653",
						name: "Xã Sơ Pai",
						type: "Xã",
					},
					{
						level3_id: "23656",
						name: "Xã Lơ Ku",
						type: "Xã",
					},
					{
						level3_id: "23659",
						name: "Xã Đông",
						type: "Xã",
					},
					{
						level3_id: "23660",
						name: "Xã Đak SMar",
						type: "Xã",
					},
					{
						level3_id: "23662",
						name: "Xã Nghĩa An",
						type: "Xã",
					},
					{
						level3_id: "23665",
						name: "Xã Tơ Tung",
						type: "Xã",
					},
					{
						level3_id: "23668",
						name: "Xã Kông Lơng Khơng",
						type: "Xã",
					},
					{
						level3_id: "23671",
						name: "Xã Kông Pla",
						type: "Xã",
					},
					{
						level3_id: "23674",
						name: "Xã Đăk HLơ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "626",
				name: "Huyện Đăk Đoa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23677",
						name: "Thị trấn Đăk Đoa",
						type: "Thị trấn",
					},
					{
						level3_id: "23680",
						name: "Xã Hà Đông",
						type: "Xã",
					},
					{
						level3_id: "23683",
						name: "Xã Đăk Sơmei",
						type: "Xã",
					},
					{
						level3_id: "23684",
						name: "Xã Đăk Krong",
						type: "Xã",
					},
					{
						level3_id: "23686",
						name: "Xã Hải Yang",
						type: "Xã",
					},
					{
						level3_id: "23689",
						name: "Xã Kon Gang",
						type: "Xã",
					},
					{
						level3_id: "23692",
						name: "Xã Hà Bầu",
						type: "Xã",
					},
					{
						level3_id: "23695",
						name: "Xã Nam Yang",
						type: "Xã",
					},
					{
						level3_id: "23698",
						name: "Xã K' Dang",
						type: "Xã",
					},
					{
						level3_id: "23701",
						name: "Xã H' Neng",
						type: "Xã",
					},
					{
						level3_id: "23704",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "23707",
						name: "Xã Glar",
						type: "Xã",
					},
					{
						level3_id: "23710",
						name: "Xã A Dơk",
						type: "Xã",
					},
					{
						level3_id: "23713",
						name: "Xã Trang",
						type: "Xã",
					},
					{
						level3_id: "23714",
						name: "Xã HNol",
						type: "Xã",
					},
					{
						level3_id: "23716",
						name: "Xã Ia Pết",
						type: "Xã",
					},
					{
						level3_id: "23719",
						name: "Xã Ia Băng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "627",
				name: "Huyện Chư Păh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23722",
						name: "Thị trấn Phú Hòa",
						type: "Thị trấn",
					},
					{
						level3_id: "23725",
						name: "Xã Hà Tây",
						type: "Xã",
					},
					{
						level3_id: "23728",
						name: "Xã Ia Khươl",
						type: "Xã",
					},
					{
						level3_id: "23731",
						name: "Xã Ia Phí",
						type: "Xã",
					},
					{
						level3_id: "23734",
						name: "Thị trấn Ia Ly",
						type: "Thị trấn",
					},
					{
						level3_id: "23737",
						name: "Xã Ia Mơ Nông",
						type: "Xã",
					},
					{
						level3_id: "23738",
						name: "Xã Ia Kreng",
						type: "Xã",
					},
					{
						level3_id: "23740",
						name: "Xã Đăk Tơ Ver",
						type: "Xã",
					},
					{
						level3_id: "23743",
						name: "Xã Hòa Phú",
						type: "Xã",
					},
					{
						level3_id: "23746",
						name: "Xã Chư Đăng Ya",
						type: "Xã",
					},
					{
						level3_id: "23749",
						name: "Xã Ia Ka",
						type: "Xã",
					},
					{
						level3_id: "23752",
						name: "Xã Ia Nhin",
						type: "Xã",
					},
					{
						level3_id: "23755",
						name: "Xã Nghĩa Hòa",
						type: "Xã",
					},
					{
						level3_id: "23761",
						name: "Xã Nghĩa Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "628",
				name: "Huyện Ia Grai",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23764",
						name: "Thị trấn Ia Kha",
						type: "Thị trấn",
					},
					{
						level3_id: "23767",
						name: "Xã Ia Sao",
						type: "Xã",
					},
					{
						level3_id: "23768",
						name: "Xã Ia Yok",
						type: "Xã",
					},
					{
						level3_id: "23770",
						name: "Xã Ia Hrung",
						type: "Xã",
					},
					{
						level3_id: "23771",
						name: "Xã Ia Bă",
						type: "Xã",
					},
					{
						level3_id: "23773",
						name: "Xã Ia Khai",
						type: "Xã",
					},
					{
						level3_id: "23776",
						name: "Xã Ia KRai",
						type: "Xã",
					},
					{
						level3_id: "23778",
						name: "Xã Ia Grăng",
						type: "Xã",
					},
					{
						level3_id: "23779",
						name: "Xã Ia Tô",
						type: "Xã",
					},
					{
						level3_id: "23782",
						name: "Xã Ia O",
						type: "Xã",
					},
					{
						level3_id: "23785",
						name: "Xã Ia Dêr",
						type: "Xã",
					},
					{
						level3_id: "23788",
						name: "Xã Ia Chia",
						type: "Xã",
					},
					{
						level3_id: "23791",
						name: "Xã Ia Pếch",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "629",
				name: "Huyện Mang Yang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23794",
						name: "Thị trấn Kon Dơng",
						type: "Thị trấn",
					},
					{
						level3_id: "23797",
						name: "Xã Ayun",
						type: "Xã",
					},
					{
						level3_id: "23798",
						name: "Xã Đak Jơ Ta",
						type: "Xã",
					},
					{
						level3_id: "23799",
						name: "Xã Đak Ta Ley",
						type: "Xã",
					},
					{
						level3_id: "23800",
						name: "Xã Hra",
						type: "Xã",
					},
					{
						level3_id: "23803",
						name: "Xã Đăk Yă",
						type: "Xã",
					},
					{
						level3_id: "23806",
						name: "Xã Đăk Djrăng",
						type: "Xã",
					},
					{
						level3_id: "23809",
						name: "Xã Lơ Pang",
						type: "Xã",
					},
					{
						level3_id: "23812",
						name: "Xã Kon Thụp",
						type: "Xã",
					},
					{
						level3_id: "23815",
						name: "Xã Đê Ar",
						type: "Xã",
					},
					{
						level3_id: "23818",
						name: "Xã Kon Chiêng",
						type: "Xã",
					},
					{
						level3_id: "23821",
						name: "Xã Đăk Trôi",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "630",
				name: "Huyện Kông Chro",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23824",
						name: "Thị trấn Kông Chro",
						type: "Thị trấn",
					},
					{
						level3_id: "23827",
						name: "Xã Chư Krêy",
						type: "Xã",
					},
					{
						level3_id: "23830",
						name: "Xã An Trung",
						type: "Xã",
					},
					{
						level3_id: "23833",
						name: "Xã Kông Yang",
						type: "Xã",
					},
					{
						level3_id: "23836",
						name: "Xã Đăk Tơ Pang",
						type: "Xã",
					},
					{
						level3_id: "23839",
						name: "Xã SRó",
						type: "Xã",
					},
					{
						level3_id: "23840",
						name: "Xã Đắk Kơ Ning",
						type: "Xã",
					},
					{
						level3_id: "23842",
						name: "Xã Đăk Song",
						type: "Xã",
					},
					{
						level3_id: "23843",
						name: "Xã Đăk Pling",
						type: "Xã",
					},
					{
						level3_id: "23845",
						name: "Xã Yang Trung",
						type: "Xã",
					},
					{
						level3_id: "23846",
						name: "Xã Đăk Pơ Pho",
						type: "Xã",
					},
					{
						level3_id: "23848",
						name: "Xã Ya Ma",
						type: "Xã",
					},
					{
						level3_id: "23851",
						name: "Xã Chơ Long",
						type: "Xã",
					},
					{
						level3_id: "23854",
						name: "Xã Yang Nam",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "631",
				name: "Huyện Đức Cơ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23857",
						name: "Thị trấn Chư Ty",
						type: "Thị trấn",
					},
					{
						level3_id: "23860",
						name: "Xã Ia Dơk",
						type: "Xã",
					},
					{
						level3_id: "23863",
						name: "Xã Ia Krêl",
						type: "Xã",
					},
					{
						level3_id: "23866",
						name: "Xã Ia Din",
						type: "Xã",
					},
					{
						level3_id: "23869",
						name: "Xã Ia Kla",
						type: "Xã",
					},
					{
						level3_id: "23872",
						name: "Xã Ia Dom",
						type: "Xã",
					},
					{
						level3_id: "23875",
						name: "Xã Ia Lang",
						type: "Xã",
					},
					{
						level3_id: "23878",
						name: "Xã Ia Kriêng",
						type: "Xã",
					},
					{
						level3_id: "23881",
						name: "Xã Ia Pnôn",
						type: "Xã",
					},
					{
						level3_id: "23884",
						name: "Xã Ia Nan",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "632",
				name: "Huyện Chư Prông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23887",
						name: "Thị trấn Chư Prông",
						type: "Thị trấn",
					},
					{
						level3_id: "23888",
						name: "Xã Ia Kly",
						type: "Xã",
					},
					{
						level3_id: "23890",
						name: "Xã Bình Giáo",
						type: "Xã",
					},
					{
						level3_id: "23893",
						name: "Xã Ia Drăng",
						type: "Xã",
					},
					{
						level3_id: "23896",
						name: "Xã Thăng Hưng",
						type: "Xã",
					},
					{
						level3_id: "23899",
						name: "Xã Bàu Cạn",
						type: "Xã",
					},
					{
						level3_id: "23902",
						name: "Xã Ia Phìn",
						type: "Xã",
					},
					{
						level3_id: "23905",
						name: "Xã Ia Băng",
						type: "Xã",
					},
					{
						level3_id: "23908",
						name: "Xã Ia Tôr",
						type: "Xã",
					},
					{
						level3_id: "23911",
						name: "Xã Ia Boòng",
						type: "Xã",
					},
					{
						level3_id: "23914",
						name: "Xã Ia O",
						type: "Xã",
					},
					{
						level3_id: "23917",
						name: "Xã Ia Púch",
						type: "Xã",
					},
					{
						level3_id: "23920",
						name: "Xã Ia Me",
						type: "Xã",
					},
					{
						level3_id: "23923",
						name: "Xã Ia Vê",
						type: "Xã",
					},
					{
						level3_id: "23924",
						name: "Xã Ia Bang",
						type: "Xã",
					},
					{
						level3_id: "23926",
						name: "Xã Ia Pia",
						type: "Xã",
					},
					{
						level3_id: "23929",
						name: "Xã Ia Ga",
						type: "Xã",
					},
					{
						level3_id: "23932",
						name: "Xã Ia Lâu",
						type: "Xã",
					},
					{
						level3_id: "23935",
						name: "Xã Ia Piơr",
						type: "Xã",
					},
					{
						level3_id: "23938",
						name: "Xã Ia Mơ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "633",
				name: "Huyện Chư Sê",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23941",
						name: "Thị trấn Chư Sê",
						type: "Thị trấn",
					},
					{
						level3_id: "23944",
						name: "Xã Ia Tiêm",
						type: "Xã",
					},
					{
						level3_id: "23945",
						name: "Xã Chư Pơng",
						type: "Xã",
					},
					{
						level3_id: "23946",
						name: "Xã Bar Măih",
						type: "Xã",
					},
					{
						level3_id: "23947",
						name: "Xã Bờ Ngoong",
						type: "Xã",
					},
					{
						level3_id: "23950",
						name: "Xã Ia Glai",
						type: "Xã",
					},
					{
						level3_id: "23953",
						name: "Xã AL Bá",
						type: "Xã",
					},
					{
						level3_id: "23954",
						name: "Xã Kông HTok",
						type: "Xã",
					},
					{
						level3_id: "23956",
						name: "Xã AYun",
						type: "Xã",
					},
					{
						level3_id: "23959",
						name: "Xã Ia HLốp",
						type: "Xã",
					},
					{
						level3_id: "23962",
						name: "Xã Ia Blang",
						type: "Xã",
					},
					{
						level3_id: "23965",
						name: "Xã Dun",
						type: "Xã",
					},
					{
						level3_id: "23966",
						name: "Xã Ia Pal",
						type: "Xã",
					},
					{
						level3_id: "23968",
						name: "Xã H Bông",
						type: "Xã",
					},
					{
						level3_id: "23977",
						name: "Xã Ia Ko",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "634",
				name: "Huyện Đăk Pơ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23989",
						name: "Xã Hà Tam",
						type: "Xã",
					},
					{
						level3_id: "23992",
						name: "Xã An Thành",
						type: "Xã",
					},
					{
						level3_id: "23995",
						name: "Thị trấn Đak Pơ",
						type: "Thị trấn",
					},
					{
						level3_id: "23998",
						name: "Xã Yang Bắc",
						type: "Xã",
					},
					{
						level3_id: "24001",
						name: "Xã Cư An",
						type: "Xã",
					},
					{
						level3_id: "24004",
						name: "Xã Tân An",
						type: "Xã",
					},
					{
						level3_id: "24007",
						name: "Xã Phú An",
						type: "Xã",
					},
					{
						level3_id: "24010",
						name: "Xã Ya Hội",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "635",
				name: "Huyện Ia Pa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24013",
						name: "Xã Pờ Tó",
						type: "Xã",
					},
					{
						level3_id: "24016",
						name: "Xã Chư Răng",
						type: "Xã",
					},
					{
						level3_id: "24019",
						name: "Xã Ia KDăm",
						type: "Xã",
					},
					{
						level3_id: "24022",
						name: "Xã Kim Tân",
						type: "Xã",
					},
					{
						level3_id: "24025",
						name: "Xã Chư Mố",
						type: "Xã",
					},
					{
						level3_id: "24028",
						name: "Xã Ia Tul",
						type: "Xã",
					},
					{
						level3_id: "24031",
						name: "Xã Ia Ma Rơn",
						type: "Xã",
					},
					{
						level3_id: "24034",
						name: "Xã Ia Broăi",
						type: "Xã",
					},
					{
						level3_id: "24037",
						name: "Xã Ia Trok",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "637",
				name: "Huyện Krông Pa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24076",
						name: "Thị trấn Phú Túc",
						type: "Thị trấn",
					},
					{
						level3_id: "24079",
						name: "Xã Ia RSai",
						type: "Xã",
					},
					{
						level3_id: "24082",
						name: "Xã Ia RSươm",
						type: "Xã",
					},
					{
						level3_id: "24085",
						name: "Xã Chư Gu",
						type: "Xã",
					},
					{
						level3_id: "24088",
						name: "Xã Đất Bằng",
						type: "Xã",
					},
					{
						level3_id: "24091",
						name: "Xã Ia Mláh",
						type: "Xã",
					},
					{
						level3_id: "24094",
						name: "Xã Chư Drăng",
						type: "Xã",
					},
					{
						level3_id: "24097",
						name: "Xã Phú Cần",
						type: "Xã",
					},
					{
						level3_id: "24100",
						name: "Xã Ia HDreh",
						type: "Xã",
					},
					{
						level3_id: "24103",
						name: "Xã Ia RMok",
						type: "Xã",
					},
					{
						level3_id: "24106",
						name: "Xã Chư Ngọc",
						type: "Xã",
					},
					{
						level3_id: "24109",
						name: "Xã Uar",
						type: "Xã",
					},
					{
						level3_id: "24112",
						name: "Xã Chư Rcăm",
						type: "Xã",
					},
					{
						level3_id: "24115",
						name: "Xã Krông Năng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "638",
				name: "Huyện Phú Thiện",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24043",
						name: "Thị trấn Phú Thiện",
						type: "Thị trấn",
					},
					{
						level3_id: "24046",
						name: "Xã Chư A Thai",
						type: "Xã",
					},
					{
						level3_id: "24048",
						name: "Xã Ayun Hạ",
						type: "Xã",
					},
					{
						level3_id: "24049",
						name: "Xã Ia Ake",
						type: "Xã",
					},
					{
						level3_id: "24052",
						name: "Xã Ia Sol",
						type: "Xã",
					},
					{
						level3_id: "24055",
						name: "Xã Ia Piar",
						type: "Xã",
					},
					{
						level3_id: "24058",
						name: "Xã Ia Peng",
						type: "Xã",
					},
					{
						level3_id: "24060",
						name: "Xã Chrôh Pơnan",
						type: "Xã",
					},
					{
						level3_id: "24061",
						name: "Xã Ia Hiao",
						type: "Xã",
					},
					{
						level3_id: "24067",
						name: "Xã Ia Yeng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "639",
				name: "Huyện Chư Pưh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "23942",
						name: "Thị trấn Nhơn Hoà",
						type: "Thị trấn",
					},
					{
						level3_id: "23971",
						name: "Xã Ia Hrú",
						type: "Xã",
					},
					{
						level3_id: "23972",
						name: "Xã Ia Rong",
						type: "Xã",
					},
					{
						level3_id: "23974",
						name: "Xã Ia Dreng",
						type: "Xã",
					},
					{
						level3_id: "23978",
						name: "Xã Ia Hla",
						type: "Xã",
					},
					{
						level3_id: "23980",
						name: "Xã Chư Don",
						type: "Xã",
					},
					{
						level3_id: "23983",
						name: "Xã Ia Phang",
						type: "Xã",
					},
					{
						level3_id: "23986",
						name: "Xã Ia Le",
						type: "Xã",
					},
					{
						level3_id: "23987",
						name: "Xã Ia BLứ",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "66",
		name: "Tỉnh Đắk Lắk",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "643",
				name: "Thành phố Buôn Ma Thuột",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "24118",
						name: "Phường Tân Lập",
						type: "Phường",
					},
					{
						level3_id: "24121",
						name: "Phường Tân Hòa",
						type: "Phường",
					},
					{
						level3_id: "24124",
						name: "Phường Tân An",
						type: "Phường",
					},
					{
						level3_id: "24127",
						name: "Phường Thống Nhất",
						type: "Phường",
					},
					{
						level3_id: "24130",
						name: "Phường Thành Nhất",
						type: "Phường",
					},
					{
						level3_id: "24133",
						name: "Phường Thắng Lợi",
						type: "Phường",
					},
					{
						level3_id: "24136",
						name: "Phường Tân Lợi",
						type: "Phường",
					},
					{
						level3_id: "24139",
						name: "Phường Thành Công",
						type: "Phường",
					},
					{
						level3_id: "24142",
						name: "Phường Tân Thành",
						type: "Phường",
					},
					{
						level3_id: "24145",
						name: "Phường Tân Tiến",
						type: "Phường",
					},
					{
						level3_id: "24148",
						name: "Phường Tự An",
						type: "Phường",
					},
					{
						level3_id: "24151",
						name: "Phường Ea Tam",
						type: "Phường",
					},
					{
						level3_id: "24154",
						name: "Phường Khánh Xuân",
						type: "Phường",
					},
					{
						level3_id: "24157",
						name: "Xã Hòa Thuận",
						type: "Xã",
					},
					{
						level3_id: "24160",
						name: "Xã Cư ÊBur",
						type: "Xã",
					},
					{
						level3_id: "24163",
						name: "Xã Ea Tu",
						type: "Xã",
					},
					{
						level3_id: "24166",
						name: "Xã Hòa Thắng",
						type: "Xã",
					},
					{
						level3_id: "24169",
						name: "Xã Ea Kao",
						type: "Xã",
					},
					{
						level3_id: "24172",
						name: "Xã Hòa Phú",
						type: "Xã",
					},
					{
						level3_id: "24175",
						name: "Xã Hòa Khánh",
						type: "Xã",
					},
					{
						level3_id: "24178",
						name: "Xã Hòa Xuân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "644",
				name: "Thị Xã Buôn Hồ",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "24305",
						name: "Phường An Lạc",
						type: "Phường",
					},
					{
						level3_id: "24308",
						name: "Phường An Bình",
						type: "Phường",
					},
					{
						level3_id: "24311",
						name: "Phường Thiện An",
						type: "Phường",
					},
					{
						level3_id: "24318",
						name: "Phường Đạt Hiếu",
						type: "Phường",
					},
					{
						level3_id: "24322",
						name: "Phường Đoàn Kết",
						type: "Phường",
					},
					{
						level3_id: "24325",
						name: "Xã Ea Blang",
						type: "Xã",
					},
					{
						level3_id: "24328",
						name: "Xã Ea Drông",
						type: "Xã",
					},
					{
						level3_id: "24331",
						name: "Phường Thống Nhất",
						type: "Phường",
					},
					{
						level3_id: "24332",
						name: "Phường Bình Tân",
						type: "Phường",
					},
					{
						level3_id: "24334",
						name: "Xã Ea Siên",
						type: "Xã",
					},
					{
						level3_id: "24337",
						name: "Xã Bình Thuận",
						type: "Xã",
					},
					{
						level3_id: "24340",
						name: "Xã Cư Bao",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "645",
				name: "Huyện Ea H'leo",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24181",
						name: "Thị trấn Ea Drăng",
						type: "Thị trấn",
					},
					{
						level3_id: "24184",
						name: "Xã Ea H'leo",
						type: "Xã",
					},
					{
						level3_id: "24187",
						name: "Xã Ea Sol",
						type: "Xã",
					},
					{
						level3_id: "24190",
						name: "Xã Ea Ral",
						type: "Xã",
					},
					{
						level3_id: "24193",
						name: "Xã Ea Wy",
						type: "Xã",
					},
					{
						level3_id: "24194",
						name: "Xã Cư A Mung",
						type: "Xã",
					},
					{
						level3_id: "24196",
						name: "Xã Cư Mốt",
						type: "Xã",
					},
					{
						level3_id: "24199",
						name: "Xã Ea Hiao",
						type: "Xã",
					},
					{
						level3_id: "24202",
						name: "Xã Ea Khal",
						type: "Xã",
					},
					{
						level3_id: "24205",
						name: "Xã Dliê Yang",
						type: "Xã",
					},
					{
						level3_id: "24207",
						name: "Xã Ea Tir",
						type: "Xã",
					},
					{
						level3_id: "24208",
						name: "Xã Ea Nam",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "646",
				name: "Huyện Ea Súp",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24211",
						name: "Thị trấn Ea Súp",
						type: "Thị trấn",
					},
					{
						level3_id: "24214",
						name: "Xã Ia Lốp",
						type: "Xã",
					},
					{
						level3_id: "24215",
						name: "Xã Ia JLơi",
						type: "Xã",
					},
					{
						level3_id: "24217",
						name: "Xã Ea Rốk",
						type: "Xã",
					},
					{
						level3_id: "24220",
						name: "Xã Ya Tờ Mốt",
						type: "Xã",
					},
					{
						level3_id: "24221",
						name: "Xã Ia RVê",
						type: "Xã",
					},
					{
						level3_id: "24223",
						name: "Xã Ea Lê",
						type: "Xã",
					},
					{
						level3_id: "24226",
						name: "Xã Cư KBang",
						type: "Xã",
					},
					{
						level3_id: "24229",
						name: "Xã Ea Bung",
						type: "Xã",
					},
					{
						level3_id: "24232",
						name: "Xã Cư M'Lan",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "647",
				name: "Huyện Buôn Đôn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24235",
						name: "Xã Krông Na",
						type: "Xã",
					},
					{
						level3_id: "24238",
						name: "Xã Ea Huar",
						type: "Xã",
					},
					{
						level3_id: "24241",
						name: "Xã Ea Wer",
						type: "Xã",
					},
					{
						level3_id: "24244",
						name: "Xã Tân Hoà",
						type: "Xã",
					},
					{
						level3_id: "24247",
						name: "Xã Cuôr KNia",
						type: "Xã",
					},
					{
						level3_id: "24250",
						name: "Xã Ea Bar",
						type: "Xã",
					},
					{
						level3_id: "24253",
						name: "Xã Ea Nuôl",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "648",
				name: "Huyện Cư M'gar",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24256",
						name: "Thị trấn Ea Pốk",
						type: "Thị trấn",
					},
					{
						level3_id: "24259",
						name: "Thị trấn Quảng Phú",
						type: "Thị trấn",
					},
					{
						level3_id: "24262",
						name: "Xã Quảng Tiến",
						type: "Xã",
					},
					{
						level3_id: "24264",
						name: "Xã Ea Kuêh",
						type: "Xã",
					},
					{
						level3_id: "24265",
						name: "Xã Ea Kiết",
						type: "Xã",
					},
					{
						level3_id: "24268",
						name: "Xã Ea Tar",
						type: "Xã",
					},
					{
						level3_id: "24271",
						name: "Xã Cư Dliê M'nông",
						type: "Xã",
					},
					{
						level3_id: "24274",
						name: "Xã Ea H'đinh",
						type: "Xã",
					},
					{
						level3_id: "24277",
						name: "Xã Ea Tul",
						type: "Xã",
					},
					{
						level3_id: "24280",
						name: "Xã Ea KPam",
						type: "Xã",
					},
					{
						level3_id: "24283",
						name: "Xã Ea M'DRóh",
						type: "Xã",
					},
					{
						level3_id: "24286",
						name: "Xã Quảng Hiệp",
						type: "Xã",
					},
					{
						level3_id: "24289",
						name: "Xã Cư M'gar",
						type: "Xã",
					},
					{
						level3_id: "24292",
						name: "Xã Ea D'Rơng",
						type: "Xã",
					},
					{
						level3_id: "24295",
						name: "Xã Ea M'nang",
						type: "Xã",
					},
					{
						level3_id: "24298",
						name: "Xã Cư Suê",
						type: "Xã",
					},
					{
						level3_id: "24301",
						name: "Xã Cuor Đăng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "649",
				name: "Huyện Krông Búk",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24307",
						name: "Xã Cư Né",
						type: "Xã",
					},
					{
						level3_id: "24310",
						name: "Xã Chư KBô",
						type: "Xã",
					},
					{
						level3_id: "24313",
						name: "Xã Cư Pơng",
						type: "Xã",
					},
					{
						level3_id: "24314",
						name: "Xã Ea Sin",
						type: "Xã",
					},
					{
						level3_id: "24316",
						name: "Xã Pơng Drang",
						type: "Xã",
					},
					{
						level3_id: "24317",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "24319",
						name: "Xã Ea Ngai",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "650",
				name: "Huyện Krông Năng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24343",
						name: "Thị trấn Krông Năng",
						type: "Thị trấn",
					},
					{
						level3_id: "24346",
						name: "Xã ĐLiê Ya",
						type: "Xã",
					},
					{
						level3_id: "24349",
						name: "Xã Ea Tóh",
						type: "Xã",
					},
					{
						level3_id: "24352",
						name: "Xã Ea Tam",
						type: "Xã",
					},
					{
						level3_id: "24355",
						name: "Xã Phú Lộc",
						type: "Xã",
					},
					{
						level3_id: "24358",
						name: "Xã Tam Giang",
						type: "Xã",
					},
					{
						level3_id: "24359",
						name: "Xã Ea Puk",
						type: "Xã",
					},
					{
						level3_id: "24360",
						name: "Xã Ea Dăh",
						type: "Xã",
					},
					{
						level3_id: "24361",
						name: "Xã Ea Hồ",
						type: "Xã",
					},
					{
						level3_id: "24364",
						name: "Xã Phú Xuân",
						type: "Xã",
					},
					{
						level3_id: "24367",
						name: "Xã Cư Klông",
						type: "Xã",
					},
					{
						level3_id: "24370",
						name: "Xã Ea Tân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "651",
				name: "Huyện Ea Kar",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24373",
						name: "Thị trấn Ea Kar",
						type: "Thị trấn",
					},
					{
						level3_id: "24376",
						name: "Thị trấn Ea Knốp",
						type: "Thị trấn",
					},
					{
						level3_id: "24379",
						name: "Xã Ea Sô",
						type: "Xã",
					},
					{
						level3_id: "24380",
						name: "Xã Ea Sar",
						type: "Xã",
					},
					{
						level3_id: "24382",
						name: "Xã Xuân Phú",
						type: "Xã",
					},
					{
						level3_id: "24385",
						name: "Xã Cư Huê",
						type: "Xã",
					},
					{
						level3_id: "24388",
						name: "Xã Ea Tih",
						type: "Xã",
					},
					{
						level3_id: "24391",
						name: "Xã Ea Đar",
						type: "Xã",
					},
					{
						level3_id: "24394",
						name: "Xã Ea Kmút",
						type: "Xã",
					},
					{
						level3_id: "24397",
						name: "Xã Cư Ni",
						type: "Xã",
					},
					{
						level3_id: "24400",
						name: "Xã Ea Păl",
						type: "Xã",
					},
					{
						level3_id: "24401",
						name: "Xã Cư Prông",
						type: "Xã",
					},
					{
						level3_id: "24403",
						name: "Xã Ea Ô",
						type: "Xã",
					},
					{
						level3_id: "24404",
						name: "Xã Cư ELang",
						type: "Xã",
					},
					{
						level3_id: "24406",
						name: "Xã Cư Bông",
						type: "Xã",
					},
					{
						level3_id: "24409",
						name: "Xã Cư Jang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "652",
				name: "Huyện M'Đrắk",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24412",
						name: "Thị trấn M'Đrắk",
						type: "Thị trấn",
					},
					{
						level3_id: "24415",
						name: "Xã Cư Prao",
						type: "Xã",
					},
					{
						level3_id: "24418",
						name: "Xã Ea Pil",
						type: "Xã",
					},
					{
						level3_id: "24421",
						name: "Xã Ea Lai",
						type: "Xã",
					},
					{
						level3_id: "24424",
						name: "Xã Ea H'MLay",
						type: "Xã",
					},
					{
						level3_id: "24427",
						name: "Xã Krông Jing",
						type: "Xã",
					},
					{
						level3_id: "24430",
						name: "Xã Ea M' Doal",
						type: "Xã",
					},
					{
						level3_id: "24433",
						name: "Xã Ea Riêng",
						type: "Xã",
					},
					{
						level3_id: "24436",
						name: "Xã Cư M'ta",
						type: "Xã",
					},
					{
						level3_id: "24439",
						name: "Xã Cư K Róa",
						type: "Xã",
					},
					{
						level3_id: "24442",
						name: "Xã Krông Á",
						type: "Xã",
					},
					{
						level3_id: "24444",
						name: "Xã Cư San",
						type: "Xã",
					},
					{
						level3_id: "24445",
						name: "Xã Ea Trang",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "653",
				name: "Huyện Krông Bông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24448",
						name: "Thị trấn Krông Kmar",
						type: "Thị trấn",
					},
					{
						level3_id: "24451",
						name: "Xã Dang Kang",
						type: "Xã",
					},
					{
						level3_id: "24454",
						name: "Xã Cư KTy",
						type: "Xã",
					},
					{
						level3_id: "24457",
						name: "Xã Hòa Thành",
						type: "Xã",
					},
					{
						level3_id: "24460",
						name: "Xã Hòa Tân",
						type: "Xã",
					},
					{
						level3_id: "24463",
						name: "Xã Hòa Phong",
						type: "Xã",
					},
					{
						level3_id: "24466",
						name: "Xã Hòa Lễ",
						type: "Xã",
					},
					{
						level3_id: "24469",
						name: "Xã Yang Reh",
						type: "Xã",
					},
					{
						level3_id: "24472",
						name: "Xã Ea Trul",
						type: "Xã",
					},
					{
						level3_id: "24475",
						name: "Xã Khuê Ngọc Điền",
						type: "Xã",
					},
					{
						level3_id: "24478",
						name: "Xã Cư Pui",
						type: "Xã",
					},
					{
						level3_id: "24481",
						name: "Xã Hòa Sơn",
						type: "Xã",
					},
					{
						level3_id: "24484",
						name: "Xã Cư Drăm",
						type: "Xã",
					},
					{
						level3_id: "24487",
						name: "Xã Yang Mao",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "654",
				name: "Huyện Krông Pắc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24490",
						name: "Thị trấn Phước An",
						type: "Thị trấn",
					},
					{
						level3_id: "24493",
						name: "Xã KRông Búk",
						type: "Xã",
					},
					{
						level3_id: "24496",
						name: "Xã Ea Kly",
						type: "Xã",
					},
					{
						level3_id: "24499",
						name: "Xã Ea Kênh",
						type: "Xã",
					},
					{
						level3_id: "24502",
						name: "Xã Ea Phê",
						type: "Xã",
					},
					{
						level3_id: "24505",
						name: "Xã Ea KNuec",
						type: "Xã",
					},
					{
						level3_id: "24508",
						name: "Xã Ea Yông",
						type: "Xã",
					},
					{
						level3_id: "24511",
						name: "Xã Hòa An",
						type: "Xã",
					},
					{
						level3_id: "24514",
						name: "Xã Ea Kuăng",
						type: "Xã",
					},
					{
						level3_id: "24517",
						name: "Xã Hòa Đông",
						type: "Xã",
					},
					{
						level3_id: "24520",
						name: "Xã Ea Hiu",
						type: "Xã",
					},
					{
						level3_id: "24523",
						name: "Xã Hòa Tiến",
						type: "Xã",
					},
					{
						level3_id: "24526",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "24529",
						name: "Xã Vụ Bổn",
						type: "Xã",
					},
					{
						level3_id: "24532",
						name: "Xã Ea Uy",
						type: "Xã",
					},
					{
						level3_id: "24535",
						name: "Xã Ea Yiêng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "655",
				name: "Huyện Krông A Na",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24538",
						name: "Thị trấn Buôn Trấp",
						type: "Thị trấn",
					},
					{
						level3_id: "24556",
						name: "Xã Dray Sáp",
						type: "Xã",
					},
					{
						level3_id: "24559",
						name: "Xã Ea Na",
						type: "Xã",
					},
					{
						level3_id: "24565",
						name: "Xã Ea Bông",
						type: "Xã",
					},
					{
						level3_id: "24568",
						name: "Xã Băng A Drênh",
						type: "Xã",
					},
					{
						level3_id: "24571",
						name: "Xã Dur KMăl",
						type: "Xã",
					},
					{
						level3_id: "24574",
						name: "Xã Bình Hòa",
						type: "Xã",
					},
					{
						level3_id: "24577",
						name: "Xã Quảng Điền",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "656",
				name: "Huyện Lắk",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24580",
						name: "Thị trấn Liên Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "24583",
						name: "Xã Yang Tao",
						type: "Xã",
					},
					{
						level3_id: "24586",
						name: "Xã Bông Krang",
						type: "Xã",
					},
					{
						level3_id: "24589",
						name: "Xã Đắk Liêng",
						type: "Xã",
					},
					{
						level3_id: "24592",
						name: "Xã Buôn Triết",
						type: "Xã",
					},
					{
						level3_id: "24595",
						name: "Xã Buôn Tría",
						type: "Xã",
					},
					{
						level3_id: "24598",
						name: "Xã Đắk Phơi",
						type: "Xã",
					},
					{
						level3_id: "24601",
						name: "Xã Đắk Nuê",
						type: "Xã",
					},
					{
						level3_id: "24604",
						name: "Xã Krông Nô",
						type: "Xã",
					},
					{
						level3_id: "24607",
						name: "Xã Nam Ka",
						type: "Xã",
					},
					{
						level3_id: "24610",
						name: "Xã Ea R'Bin",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "657",
				name: "Huyện Cư Kuin",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24540",
						name: "Xã Ea Ning",
						type: "Xã",
					},
					{
						level3_id: "24541",
						name: "Xã Cư Ê Wi",
						type: "Xã",
					},
					{
						level3_id: "24544",
						name: "Xã Ea Ktur",
						type: "Xã",
					},
					{
						level3_id: "24547",
						name: "Xã Ea Tiêu",
						type: "Xã",
					},
					{
						level3_id: "24550",
						name: "Xã Ea BHốk",
						type: "Xã",
					},
					{
						level3_id: "24553",
						name: "Xã Ea Hu",
						type: "Xã",
					},
					{
						level3_id: "24561",
						name: "Xã Dray Bhăng",
						type: "Xã",
					},
					{
						level3_id: "24562",
						name: "Xã Hòa Hiệp",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "67",
		name: "Tỉnh Đắk Nông",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "660",
				name: "Thành phố Gia Nghĩa",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "24611",
						name: "Phường Nghĩa Đức",
						type: "Phường",
					},
					{
						level3_id: "24612",
						name: "Phường Nghĩa Thành",
						type: "Phường",
					},
					{
						level3_id: "24614",
						name: "Phường Nghĩa Phú",
						type: "Phường",
					},
					{
						level3_id: "24615",
						name: "Phường Nghĩa Tân",
						type: "Phường",
					},
					{
						level3_id: "24617",
						name: "Phường Nghĩa Trung",
						type: "Phường",
					},
					{
						level3_id: "24618",
						name: "Xã Đăk R'Moan",
						type: "Xã",
					},
					{
						level3_id: "24619",
						name: "Phường Quảng Thành",
						type: "Phường",
					},
					{
						level3_id: "24628",
						name: "Xã Đắk Nia",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "661",
				name: "Huyện Đăk Glong",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24616",
						name: "Xã Quảng Sơn",
						type: "Xã",
					},
					{
						level3_id: "24620",
						name: "Xã Quảng Hoà",
						type: "Xã",
					},
					{
						level3_id: "24622",
						name: "Xã Đắk Ha",
						type: "Xã",
					},
					{
						level3_id: "24625",
						name: "Xã Đắk R'Măng",
						type: "Xã",
					},
					{
						level3_id: "24631",
						name: "Xã Quảng Khê",
						type: "Xã",
					},
					{
						level3_id: "24634",
						name: "Xã Đắk Plao",
						type: "Xã",
					},
					{
						level3_id: "24637",
						name: "Xã Đắk Som",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "662",
				name: "Huyện Cư Jút",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24640",
						name: "Thị trấn Ea T'Ling",
						type: "Thị trấn",
					},
					{
						level3_id: "24643",
						name: "Xã Đắk Wil",
						type: "Xã",
					},
					{
						level3_id: "24646",
						name: "Xã Ea Pô",
						type: "Xã",
					},
					{
						level3_id: "24649",
						name: "Xã Nam Dong",
						type: "Xã",
					},
					{
						level3_id: "24652",
						name: "Xã Đắk DRông",
						type: "Xã",
					},
					{
						level3_id: "24655",
						name: "Xã Tâm Thắng",
						type: "Xã",
					},
					{
						level3_id: "24658",
						name: "Xã Cư Knia",
						type: "Xã",
					},
					{
						level3_id: "24661",
						name: "Xã Trúc Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "663",
				name: "Huyện Đắk Mil",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24664",
						name: "Thị trấn Đắk Mil",
						type: "Thị trấn",
					},
					{
						level3_id: "24667",
						name: "Xã  Đắk Lao",
						type: "Xã",
					},
					{
						level3_id: "24670",
						name: "Xã Đắk R'La",
						type: "Xã",
					},
					{
						level3_id: "24673",
						name: "Xã Đắk Gằn",
						type: "Xã",
					},
					{
						level3_id: "24676",
						name: "Xã Đức Mạnh",
						type: "Xã",
					},
					{
						level3_id: "24677",
						name: "Xã Đắk N'Drót",
						type: "Xã",
					},
					{
						level3_id: "24678",
						name: "Xã Long Sơn",
						type: "Xã",
					},
					{
						level3_id: "24679",
						name: "Xã Đắk Sắk",
						type: "Xã",
					},
					{
						level3_id: "24682",
						name: "Xã Thuận An",
						type: "Xã",
					},
					{
						level3_id: "24685",
						name: "Xã Đức Minh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "664",
				name: "Huyện Krông Nô",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24688",
						name: "Thị trấn Đắk Mâm",
						type: "Thị trấn",
					},
					{
						level3_id: "24691",
						name: "Xã Đắk Sôr",
						type: "Xã",
					},
					{
						level3_id: "24692",
						name: "Xã Nam Xuân",
						type: "Xã",
					},
					{
						level3_id: "24694",
						name: "Xã Buôn Choah",
						type: "Xã",
					},
					{
						level3_id: "24697",
						name: "Xã Nam Đà",
						type: "Xã",
					},
					{
						level3_id: "24699",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "24700",
						name: "Xã Đắk Drô",
						type: "Xã",
					},
					{
						level3_id: "24703",
						name: "Xã Nâm Nung",
						type: "Xã",
					},
					{
						level3_id: "24706",
						name: "Xã Đức Xuyên",
						type: "Xã",
					},
					{
						level3_id: "24709",
						name: "Xã Đắk Nang",
						type: "Xã",
					},
					{
						level3_id: "24712",
						name: "Xã Quảng Phú",
						type: "Xã",
					},
					{
						level3_id: "24715",
						name: "Xã Nâm N'Đir",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "665",
				name: "Huyện Đắk Song",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24717",
						name: "Thị trấn Đức An",
						type: "Thị trấn",
					},
					{
						level3_id: "24718",
						name: "Xã Đắk Môl",
						type: "Xã",
					},
					{
						level3_id: "24719",
						name: "Xã Đắk Hòa",
						type: "Xã",
					},
					{
						level3_id: "24721",
						name: "Xã Nam Bình",
						type: "Xã",
					},
					{
						level3_id: "24722",
						name: "Xã Thuận Hà",
						type: "Xã",
					},
					{
						level3_id: "24724",
						name: "Xã Thuận Hạnh",
						type: "Xã",
					},
					{
						level3_id: "24727",
						name: "Xã Đắk N'Dung",
						type: "Xã",
					},
					{
						level3_id: "24728",
						name: "Xã Nâm N'Jang",
						type: "Xã",
					},
					{
						level3_id: "24730",
						name: "Xã Trường Xuân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "666",
				name: "Huyện Đắk R'Lấp",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24733",
						name: "Thị trấn Kiến Đức",
						type: "Thị trấn",
					},
					{
						level3_id: "24745",
						name: "Xã Quảng Tín",
						type: "Xã",
					},
					{
						level3_id: "24750",
						name: "Xã Đắk Wer",
						type: "Xã",
					},
					{
						level3_id: "24751",
						name: "Xã Nhân Cơ",
						type: "Xã",
					},
					{
						level3_id: "24754",
						name: "Xã Kiến Thành",
						type: "Xã",
					},
					{
						level3_id: "24756",
						name: "Xã Nghĩa Thắng",
						type: "Xã",
					},
					{
						level3_id: "24757",
						name: "Xã Đạo Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "24760",
						name: "Xã Đắk Sin",
						type: "Xã",
					},
					{
						level3_id: "24761",
						name: "Xã Hưng Bình",
						type: "Xã",
					},
					{
						level3_id: "24763",
						name: "Xã Đắk Ru",
						type: "Xã",
					},
					{
						level3_id: "24766",
						name: "Xã Nhân Đạo",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "667",
				name: "Huyện Tuy Đức",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24736",
						name: "Xã Quảng Trực",
						type: "Xã",
					},
					{
						level3_id: "24739",
						name: "Xã Đắk Búk So",
						type: "Xã",
					},
					{
						level3_id: "24740",
						name: "Xã Quảng Tâm",
						type: "Xã",
					},
					{
						level3_id: "24742",
						name: "Xã Đắk R'Tíh",
						type: "Xã",
					},
					{
						level3_id: "24746",
						name: "Xã Đắk Ngo",
						type: "Xã",
					},
					{
						level3_id: "24748",
						name: "Xã Quảng Tân",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "68",
		name: "Tỉnh Lâm Đồng",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "672",
				name: "Thành phố Đà Lạt",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "24769",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "24772",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "24775",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "24778",
						name: "Phường 9",
						type: "Phường",
					},
					{
						level3_id: "24781",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "24784",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "24787",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "24790",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "24793",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "24796",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "24799",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "24802",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "24805",
						name: "Xã Xuân Thọ",
						type: "Xã",
					},
					{
						level3_id: "24808",
						name: "Xã Tà Nung",
						type: "Xã",
					},
					{
						level3_id: "24810",
						name: "Xã Trạm Hành",
						type: "Xã",
					},
					{
						level3_id: "24811",
						name: "Xã Xuân Trường",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "673",
				name: "Thành phố Bảo Lộc",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "24814",
						name: "Phường Lộc Phát",
						type: "Phường",
					},
					{
						level3_id: "24817",
						name: "Phường Lộc Tiến",
						type: "Phường",
					},
					{
						level3_id: "24820",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "24823",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "24826",
						name: "Phường B'lao",
						type: "Phường",
					},
					{
						level3_id: "24829",
						name: "Phường Lộc Sơn",
						type: "Phường",
					},
					{
						level3_id: "24832",
						name: "Xã Đạm Bri",
						type: "Xã",
					},
					{
						level3_id: "24835",
						name: "Xã Lộc Thanh",
						type: "Xã",
					},
					{
						level3_id: "24838",
						name: "Xã Lộc Nga",
						type: "Xã",
					},
					{
						level3_id: "24841",
						name: "Xã Lộc Châu",
						type: "Xã",
					},
					{
						level3_id: "24844",
						name: "Xã Đại Lào",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "674",
				name: "Huyện Đam Rông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24853",
						name: "Xã Đạ Tông",
						type: "Xã",
					},
					{
						level3_id: "24856",
						name: "Xã Đạ Long",
						type: "Xã",
					},
					{
						level3_id: "24859",
						name: "Xã Đạ M' Rong",
						type: "Xã",
					},
					{
						level3_id: "24874",
						name: "Xã Liêng Srônh",
						type: "Xã",
					},
					{
						level3_id: "24875",
						name: "Xã Đạ Rsal",
						type: "Xã",
					},
					{
						level3_id: "24877",
						name: "Xã Rô Men",
						type: "Xã",
					},
					{
						level3_id: "24886",
						name: "Xã Phi Liêng",
						type: "Xã",
					},
					{
						level3_id: "24889",
						name: "Xã Đạ K' Nàng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "675",
				name: "Huyện Lạc Dương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24846",
						name: "Thị trấn Lạc Dương",
						type: "Thị trấn",
					},
					{
						level3_id: "24847",
						name: "Xã Đạ Chais",
						type: "Xã",
					},
					{
						level3_id: "24848",
						name: "Xã Đạ Nhim",
						type: "Xã",
					},
					{
						level3_id: "24850",
						name: "Xã Đưng KNớ",
						type: "Xã",
					},
					{
						level3_id: "24862",
						name: "Xã Lát",
						type: "Xã",
					},
					{
						level3_id: "24865",
						name: "Xã Đạ Sar",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "676",
				name: "Huyện Lâm Hà",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24868",
						name: "Thị trấn Nam Ban",
						type: "Thị trấn",
					},
					{
						level3_id: "24871",
						name: "Thị trấn Đinh Văn",
						type: "Thị trấn",
					},
					{
						level3_id: "24880",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "24883",
						name: "Xã Phi Tô",
						type: "Xã",
					},
					{
						level3_id: "24892",
						name: "Xã Mê Linh",
						type: "Xã",
					},
					{
						level3_id: "24895",
						name: "Xã Đạ Đờn",
						type: "Xã",
					},
					{
						level3_id: "24898",
						name: "Xã Phúc Thọ",
						type: "Xã",
					},
					{
						level3_id: "24901",
						name: "Xã Đông Thanh",
						type: "Xã",
					},
					{
						level3_id: "24904",
						name: "Xã Gia Lâm",
						type: "Xã",
					},
					{
						level3_id: "24907",
						name: "Xã Tân Thanh",
						type: "Xã",
					},
					{
						level3_id: "24910",
						name: "Xã Tân Văn",
						type: "Xã",
					},
					{
						level3_id: "24913",
						name: "Xã Hoài Đức",
						type: "Xã",
					},
					{
						level3_id: "24916",
						name: "Xã Tân Hà",
						type: "Xã",
					},
					{
						level3_id: "24919",
						name: "Xã Liên Hà",
						type: "Xã",
					},
					{
						level3_id: "24922",
						name: "Xã Đan Phượng",
						type: "Xã",
					},
					{
						level3_id: "24925",
						name: "Xã Nam Hà",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "677",
				name: "Huyện Đơn Dương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24928",
						name: "Thị trấn D'Ran",
						type: "Thị trấn",
					},
					{
						level3_id: "24931",
						name: "Thị trấn Thạnh Mỹ",
						type: "Thị trấn",
					},
					{
						level3_id: "24934",
						name: "Xã Lạc Xuân",
						type: "Xã",
					},
					{
						level3_id: "24937",
						name: "Xã Đạ Ròn",
						type: "Xã",
					},
					{
						level3_id: "24940",
						name: "Xã Lạc Lâm",
						type: "Xã",
					},
					{
						level3_id: "24943",
						name: "Xã Ka Đô",
						type: "Xã",
					},
					{
						level3_id: "24946",
						name: "Xã Quảng Lập",
						type: "Xã",
					},
					{
						level3_id: "24949",
						name: "Xã Ka Đơn",
						type: "Xã",
					},
					{
						level3_id: "24952",
						name: "Xã Tu Tra",
						type: "Xã",
					},
					{
						level3_id: "24955",
						name: "Xã Pró",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "678",
				name: "Huyện Đức Trọng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "24958",
						name: "Thị trấn Liên Nghĩa",
						type: "Thị trấn",
					},
					{
						level3_id: "24961",
						name: "Xã Hiệp An",
						type: "Xã",
					},
					{
						level3_id: "24964",
						name: "Xã Liên Hiệp",
						type: "Xã",
					},
					{
						level3_id: "24967",
						name: "Xã Hiệp Thạnh",
						type: "Xã",
					},
					{
						level3_id: "24970",
						name: "Xã Bình Thạnh",
						type: "Xã",
					},
					{
						level3_id: "24973",
						name: "Xã N'Thol Hạ",
						type: "Xã",
					},
					{
						level3_id: "24976",
						name: "Xã Tân Hội",
						type: "Xã",
					},
					{
						level3_id: "24979",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "24982",
						name: "Xã Phú Hội",
						type: "Xã",
					},
					{
						level3_id: "24985",
						name: "Xã Ninh Gia",
						type: "Xã",
					},
					{
						level3_id: "24988",
						name: "Xã Tà Năng",
						type: "Xã",
					},
					{
						level3_id: "24989",
						name: "Xã Đa Quyn",
						type: "Xã",
					},
					{
						level3_id: "24991",
						name: "Xã Tà Hine",
						type: "Xã",
					},
					{
						level3_id: "24994",
						name: "Xã Đà Loan",
						type: "Xã",
					},
					{
						level3_id: "24997",
						name: "Xã Ninh Loan",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "679",
				name: "Huyện Di Linh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25000",
						name: "Thị trấn Di Linh",
						type: "Thị trấn",
					},
					{
						level3_id: "25003",
						name: "Xã Đinh Trang Thượng",
						type: "Xã",
					},
					{
						level3_id: "25006",
						name: "Xã Tân Thượng",
						type: "Xã",
					},
					{
						level3_id: "25007",
						name: "Xã Tân Lâm",
						type: "Xã",
					},
					{
						level3_id: "25009",
						name: "Xã Tân Châu",
						type: "Xã",
					},
					{
						level3_id: "25012",
						name: "Xã Tân Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "25015",
						name: "Xã Gia Hiệp",
						type: "Xã",
					},
					{
						level3_id: "25018",
						name: "Xã Đinh Lạc",
						type: "Xã",
					},
					{
						level3_id: "25021",
						name: "Xã Tam Bố",
						type: "Xã",
					},
					{
						level3_id: "25024",
						name: "Xã Đinh Trang Hòa",
						type: "Xã",
					},
					{
						level3_id: "25027",
						name: "Xã Liên Đầm",
						type: "Xã",
					},
					{
						level3_id: "25030",
						name: "Xã Gung Ré",
						type: "Xã",
					},
					{
						level3_id: "25033",
						name: "Xã Bảo Thuận",
						type: "Xã",
					},
					{
						level3_id: "25036",
						name: "Xã Hòa Ninh",
						type: "Xã",
					},
					{
						level3_id: "25039",
						name: "Xã Hòa Trung",
						type: "Xã",
					},
					{
						level3_id: "25042",
						name: "Xã Hòa Nam",
						type: "Xã",
					},
					{
						level3_id: "25045",
						name: "Xã Hòa Bắc",
						type: "Xã",
					},
					{
						level3_id: "25048",
						name: "Xã Sơn Điền",
						type: "Xã",
					},
					{
						level3_id: "25051",
						name: "Xã Gia Bắc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "680",
				name: "Huyện Bảo Lâm",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25054",
						name: "Thị trấn Lộc Thắng",
						type: "Thị trấn",
					},
					{
						level3_id: "25057",
						name: "Xã Lộc Bảo",
						type: "Xã",
					},
					{
						level3_id: "25060",
						name: "Xã Lộc Lâm",
						type: "Xã",
					},
					{
						level3_id: "25063",
						name: "Xã Lộc Phú",
						type: "Xã",
					},
					{
						level3_id: "25066",
						name: "Xã Lộc Bắc",
						type: "Xã",
					},
					{
						level3_id: "25069",
						name: "Xã B' Lá",
						type: "Xã",
					},
					{
						level3_id: "25072",
						name: "Xã Lộc Ngãi",
						type: "Xã",
					},
					{
						level3_id: "25075",
						name: "Xã Lộc Quảng",
						type: "Xã",
					},
					{
						level3_id: "25078",
						name: "Xã Lộc Tân",
						type: "Xã",
					},
					{
						level3_id: "25081",
						name: "Xã Lộc Đức",
						type: "Xã",
					},
					{
						level3_id: "25084",
						name: "Xã Lộc An",
						type: "Xã",
					},
					{
						level3_id: "25087",
						name: "Xã Tân Lạc",
						type: "Xã",
					},
					{
						level3_id: "25090",
						name: "Xã Lộc Thành",
						type: "Xã",
					},
					{
						level3_id: "25093",
						name: "Xã Lộc Nam",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "681",
				name: "Huyện Đạ Huoai",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25096",
						name: "Thị trấn Đạ M'ri",
						type: "Thị trấn",
					},
					{
						level3_id: "25099",
						name: "Thị trấn Ma Đa Guôi",
						type: "Thị trấn",
					},
					{
						level3_id: "25105",
						name: "Xã Hà Lâm",
						type: "Xã",
					},
					{
						level3_id: "25108",
						name: "Xã Đạ Tồn",
						type: "Xã",
					},
					{
						level3_id: "25111",
						name: "Xã Đạ Oai",
						type: "Xã",
					},
					{
						level3_id: "25114",
						name: "Xã Đạ Ploa",
						type: "Xã",
					},
					{
						level3_id: "25117",
						name: "Xã Ma Đa Guôi",
						type: "Xã",
					},
					{
						level3_id: "25120",
						name: "Xã Đoàn Kết",
						type: "Xã",
					},
					{
						level3_id: "25123",
						name: "Xã Phước Lộc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "682",
				name: "Huyện Đạ Tẻh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25126",
						name: "Thị trấn Đạ Tẻh",
						type: "Thị trấn",
					},
					{
						level3_id: "25129",
						name: "Xã An Nhơn",
						type: "Xã",
					},
					{
						level3_id: "25132",
						name: "Xã Quốc Oai",
						type: "Xã",
					},
					{
						level3_id: "25135",
						name: "Xã Mỹ Đức",
						type: "Xã",
					},
					{
						level3_id: "25138",
						name: "Xã Quảng Trị",
						type: "Xã",
					},
					{
						level3_id: "25141",
						name: "Xã Đạ Lây",
						type: "Xã",
					},
					{
						level3_id: "25147",
						name: "Xã Triệu Hải",
						type: "Xã",
					},
					{
						level3_id: "25153",
						name: "Xã Đạ Kho",
						type: "Xã",
					},
					{
						level3_id: "25156",
						name: "Xã Đạ Pal",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "683",
				name: "Huyện Cát Tiên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25159",
						name: "Thị trấn Cát Tiên",
						type: "Thị trấn",
					},
					{
						level3_id: "25162",
						name: "Xã Tiên Hoàng",
						type: "Xã",
					},
					{
						level3_id: "25165",
						name: "Xã Phước Cát 2",
						type: "Xã",
					},
					{
						level3_id: "25168",
						name: "Xã Gia Viễn",
						type: "Xã",
					},
					{
						level3_id: "25171",
						name: "Xã Nam Ninh",
						type: "Xã",
					},
					{
						level3_id: "25180",
						name: "Thị trấn Phước Cát ",
						type: "Thị trấn",
					},
					{
						level3_id: "25183",
						name: "Xã Đức Phổ",
						type: "Xã",
					},
					{
						level3_id: "25189",
						name: "Xã Quảng Ngãi",
						type: "Xã",
					},
					{
						level3_id: "25192",
						name: "Xã Đồng Nai Thượng",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "70",
		name: "Tỉnh Bình Phước",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "688",
				name: "Thị xã Phước Long",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "25216",
						name: "Phường Thác Mơ",
						type: "Phường",
					},
					{
						level3_id: "25217",
						name: "Phường Long Thủy",
						type: "Phường",
					},
					{
						level3_id: "25219",
						name: "Phường Phước Bình",
						type: "Phường",
					},
					{
						level3_id: "25220",
						name: "Phường Long Phước",
						type: "Phường",
					},
					{
						level3_id: "25237",
						name: "Phường Sơn Giang",
						type: "Phường",
					},
					{
						level3_id: "25245",
						name: "Xã Long Giang",
						type: "Xã",
					},
					{
						level3_id: "25249",
						name: "Xã Phước Tín",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "689",
				name: "Thành phố Đồng Xoài",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "25195",
						name: "Phường Tân Phú",
						type: "Phường",
					},
					{
						level3_id: "25198",
						name: "Phường Tân Đồng",
						type: "Phường",
					},
					{
						level3_id: "25201",
						name: "Phường Tân Bình",
						type: "Phường",
					},
					{
						level3_id: "25204",
						name: "Phường Tân Xuân",
						type: "Phường",
					},
					{
						level3_id: "25205",
						name: "Phường Tân Thiện",
						type: "Phường",
					},
					{
						level3_id: "25207",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "25210",
						name: "Phường Tiến Thành",
						type: "Phường",
					},
					{
						level3_id: "25213",
						name: "Xã Tiến Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "690",
				name: "Thị xã Bình Long",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "25320",
						name: "Phường Hưng Chiến",
						type: "Phường",
					},
					{
						level3_id: "25324",
						name: "Phường An Lộc",
						type: "Phường",
					},
					{
						level3_id: "25325",
						name: "Phường Phú Thịnh",
						type: "Phường",
					},
					{
						level3_id: "25326",
						name: "Phường Phú Đức",
						type: "Phường",
					},
					{
						level3_id: "25333",
						name: "Xã Thanh Lương",
						type: "Xã",
					},
					{
						level3_id: "25336",
						name: "Xã Thanh Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "691",
				name: "Huyện Bù Gia Mập",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25222",
						name: "Xã Bù Gia Mập",
						type: "Xã",
					},
					{
						level3_id: "25225",
						name: "Xã Đak Ơ",
						type: "Xã",
					},
					{
						level3_id: "25228",
						name: "Xã Đức Hạnh",
						type: "Xã",
					},
					{
						level3_id: "25229",
						name: "Xã Phú Văn",
						type: "Xã",
					},
					{
						level3_id: "25231",
						name: "Xã Đa Kia",
						type: "Xã",
					},
					{
						level3_id: "25232",
						name: "Xã Phước Minh",
						type: "Xã",
					},
					{
						level3_id: "25234",
						name: "Xã Bình Thắng",
						type: "Xã",
					},
					{
						level3_id: "25267",
						name: "Xã Phú Nghĩa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "692",
				name: "Huyện Lộc Ninh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25270",
						name: "Thị trấn Lộc Ninh",
						type: "Thị trấn",
					},
					{
						level3_id: "25273",
						name: "Xã Lộc Hòa",
						type: "Xã",
					},
					{
						level3_id: "25276",
						name: "Xã Lộc An",
						type: "Xã",
					},
					{
						level3_id: "25279",
						name: "Xã Lộc Tấn",
						type: "Xã",
					},
					{
						level3_id: "25280",
						name: "Xã Lộc Thạnh",
						type: "Xã",
					},
					{
						level3_id: "25282",
						name: "Xã Lộc Hiệp",
						type: "Xã",
					},
					{
						level3_id: "25285",
						name: "Xã Lộc Thiện",
						type: "Xã",
					},
					{
						level3_id: "25288",
						name: "Xã Lộc Thuận",
						type: "Xã",
					},
					{
						level3_id: "25291",
						name: "Xã Lộc Quang",
						type: "Xã",
					},
					{
						level3_id: "25292",
						name: "Xã Lộc Phú",
						type: "Xã",
					},
					{
						level3_id: "25294",
						name: "Xã Lộc Thành",
						type: "Xã",
					},
					{
						level3_id: "25297",
						name: "Xã Lộc Thái",
						type: "Xã",
					},
					{
						level3_id: "25300",
						name: "Xã Lộc Điền",
						type: "Xã",
					},
					{
						level3_id: "25303",
						name: "Xã Lộc Hưng",
						type: "Xã",
					},
					{
						level3_id: "25305",
						name: "Xã Lộc Thịnh",
						type: "Xã",
					},
					{
						level3_id: "25306",
						name: "Xã Lộc Khánh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "693",
				name: "Huyện Bù Đốp",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25308",
						name: "Thị trấn Thanh Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "25309",
						name: "Xã Hưng Phước",
						type: "Xã",
					},
					{
						level3_id: "25310",
						name: "Xã Phước Thiện",
						type: "Xã",
					},
					{
						level3_id: "25312",
						name: "Xã Thiện Hưng",
						type: "Xã",
					},
					{
						level3_id: "25315",
						name: "Xã Thanh Hòa",
						type: "Xã",
					},
					{
						level3_id: "25318",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "25321",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "694",
				name: "Huyện Hớn Quản",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25327",
						name: "Xã Thanh An",
						type: "Xã",
					},
					{
						level3_id: "25330",
						name: "Xã An Khương",
						type: "Xã",
					},
					{
						level3_id: "25339",
						name: "Xã An Phú",
						type: "Xã",
					},
					{
						level3_id: "25342",
						name: "Xã Tân Lợi",
						type: "Xã",
					},
					{
						level3_id: "25345",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "25348",
						name: "Xã Minh Đức",
						type: "Xã",
					},
					{
						level3_id: "25349",
						name: "Xã Minh Tâm",
						type: "Xã",
					},
					{
						level3_id: "25351",
						name: "Xã Phước An",
						type: "Xã",
					},
					{
						level3_id: "25354",
						name: "Xã Thanh Bình",
						type: "Xã",
					},
					{
						level3_id: "25357",
						name: "Thị trấn Tân Khai",
						type: "Thị trấn",
					},
					{
						level3_id: "25360",
						name: "Xã Đồng Nơ",
						type: "Xã",
					},
					{
						level3_id: "25361",
						name: "Xã Tân Hiệp",
						type: "Xã",
					},
					{
						level3_id: "25438",
						name: "Xã Tân Quan",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "695",
				name: "Huyện Đồng Phú",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25363",
						name: "Thị trấn Tân Phú",
						type: "Thị trấn",
					},
					{
						level3_id: "25366",
						name: "Xã Thuận Lợi",
						type: "Xã",
					},
					{
						level3_id: "25369",
						name: "Xã Đồng Tâm",
						type: "Xã",
					},
					{
						level3_id: "25372",
						name: "Xã Tân Phước",
						type: "Xã",
					},
					{
						level3_id: "25375",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "25378",
						name: "Xã Tân Lợi",
						type: "Xã",
					},
					{
						level3_id: "25381",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "25384",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "25387",
						name: "Xã Thuận Phú",
						type: "Xã",
					},
					{
						level3_id: "25390",
						name: "Xã Đồng Tiến",
						type: "Xã",
					},
					{
						level3_id: "25393",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "696",
				name: "Huyện Bù Đăng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25396",
						name: "Thị trấn Đức Phong",
						type: "Thị trấn",
					},
					{
						level3_id: "25398",
						name: "Xã Đường 10",
						type: "Xã",
					},
					{
						level3_id: "25399",
						name: "Xã Đak Nhau",
						type: "Xã",
					},
					{
						level3_id: "25400",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "25402",
						name: "Xã Thọ Sơn",
						type: "Xã",
					},
					{
						level3_id: "25404",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "25405",
						name: "Xã Bom Bo",
						type: "Xã",
					},
					{
						level3_id: "25408",
						name: "Xã Minh Hưng",
						type: "Xã",
					},
					{
						level3_id: "25411",
						name: "Xã Đoàn Kết",
						type: "Xã",
					},
					{
						level3_id: "25414",
						name: "Xã Đồng Nai",
						type: "Xã",
					},
					{
						level3_id: "25417",
						name: "Xã Đức Liễu",
						type: "Xã",
					},
					{
						level3_id: "25420",
						name: "Xã Thống Nhất",
						type: "Xã",
					},
					{
						level3_id: "25423",
						name: "Xã Nghĩa Trung",
						type: "Xã",
					},
					{
						level3_id: "25424",
						name: "Xã Nghĩa Bình",
						type: "Xã",
					},
					{
						level3_id: "25426",
						name: "Xã Đăng Hà",
						type: "Xã",
					},
					{
						level3_id: "25429",
						name: "Xã Phước Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "697",
				name: "Huyện Chơn Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25432",
						name: "Thị trấn Chơn Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "25433",
						name: "Xã Thành Tâm",
						type: "Xã",
					},
					{
						level3_id: "25435",
						name: "Xã Minh Lập",
						type: "Xã",
					},
					{
						level3_id: "25439",
						name: "Xã Quang Minh",
						type: "Xã",
					},
					{
						level3_id: "25441",
						name: "Xã Minh Hưng",
						type: "Xã",
					},
					{
						level3_id: "25444",
						name: "Xã Minh Long",
						type: "Xã",
					},
					{
						level3_id: "25447",
						name: "Xã Minh Thành",
						type: "Xã",
					},
					{
						level3_id: "25450",
						name: "Xã Nha Bích",
						type: "Xã",
					},
					{
						level3_id: "25453",
						name: "Xã Minh Thắng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "698",
				name: "Huyện Phú Riềng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25240",
						name: "Xã Long Bình",
						type: "Xã",
					},
					{
						level3_id: "25243",
						name: "Xã Bình Tân",
						type: "Xã",
					},
					{
						level3_id: "25244",
						name: "Xã Bình Sơn",
						type: "Xã",
					},
					{
						level3_id: "25246",
						name: "Xã Long Hưng",
						type: "Xã",
					},
					{
						level3_id: "25250",
						name: "Xã Phước Tân",
						type: "Xã",
					},
					{
						level3_id: "25252",
						name: "Xã Bù Nho",
						type: "Xã",
					},
					{
						level3_id: "25255",
						name: "Xã Long Hà",
						type: "Xã",
					},
					{
						level3_id: "25258",
						name: "Xã Long Tân",
						type: "Xã",
					},
					{
						level3_id: "25261",
						name: "Xã Phú Trung",
						type: "Xã",
					},
					{
						level3_id: "25264",
						name: "Xã Phú Riềng",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "72",
		name: "Tỉnh Tây Ninh",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "703",
				name: "Thành phố Tây Ninh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "25456",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "25459",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "25462",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "25465",
						name: "Phường Hiệp Ninh",
						type: "Phường",
					},
					{
						level3_id: "25468",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "25471",
						name: "Xã Thạnh Tân",
						type: "Xã",
					},
					{
						level3_id: "25474",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "25477",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "25480",
						name: "Phường Ninh Sơn",
						type: "Phường",
					},
					{
						level3_id: "25483",
						name: "Phường Ninh Thạnh",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "705",
				name: "Huyện Tân Biên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25486",
						name: "Thị trấn Tân Biên",
						type: "Thị trấn",
					},
					{
						level3_id: "25489",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "25492",
						name: "Xã Thạnh Bắc",
						type: "Xã",
					},
					{
						level3_id: "25495",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "25498",
						name: "Xã Thạnh Bình",
						type: "Xã",
					},
					{
						level3_id: "25501",
						name: "Xã Thạnh Tây",
						type: "Xã",
					},
					{
						level3_id: "25504",
						name: "Xã Hòa Hiệp",
						type: "Xã",
					},
					{
						level3_id: "25507",
						name: "Xã Tân Phong",
						type: "Xã",
					},
					{
						level3_id: "25510",
						name: "Xã Mỏ Công",
						type: "Xã",
					},
					{
						level3_id: "25513",
						name: "Xã Trà Vong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "706",
				name: "Huyện Tân Châu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25516",
						name: "Thị trấn Tân Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "25519",
						name: "Xã Tân Hà",
						type: "Xã",
					},
					{
						level3_id: "25522",
						name: "Xã Tân Đông",
						type: "Xã",
					},
					{
						level3_id: "25525",
						name: "Xã Tân Hội",
						type: "Xã",
					},
					{
						level3_id: "25528",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "25531",
						name: "Xã Suối Ngô",
						type: "Xã",
					},
					{
						level3_id: "25534",
						name: "Xã Suối Dây",
						type: "Xã",
					},
					{
						level3_id: "25537",
						name: "Xã Tân Hiệp",
						type: "Xã",
					},
					{
						level3_id: "25540",
						name: "Xã Thạnh Đông",
						type: "Xã",
					},
					{
						level3_id: "25543",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "25546",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "25549",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "707",
				name: "Huyện Dương Minh Châu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25552",
						name: "Thị trấn Dương Minh Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "25555",
						name: "Xã Suối Đá",
						type: "Xã",
					},
					{
						level3_id: "25558",
						name: "Xã Phan",
						type: "Xã",
					},
					{
						level3_id: "25561",
						name: "Xã Phước Ninh",
						type: "Xã",
					},
					{
						level3_id: "25564",
						name: "Xã Phước Minh",
						type: "Xã",
					},
					{
						level3_id: "25567",
						name: "Xã Bàu Năng",
						type: "Xã",
					},
					{
						level3_id: "25570",
						name: "Xã Chà Là",
						type: "Xã",
					},
					{
						level3_id: "25573",
						name: "Xã Cầu Khởi",
						type: "Xã",
					},
					{
						level3_id: "25576",
						name: "Xã Bến Củi",
						type: "Xã",
					},
					{
						level3_id: "25579",
						name: "Xã Lộc Ninh",
						type: "Xã",
					},
					{
						level3_id: "25582",
						name: "Xã Truông Mít",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "708",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25585",
						name: "Thị trấn Châu Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "25588",
						name: "Xã Hảo Đước",
						type: "Xã",
					},
					{
						level3_id: "25591",
						name: "Xã Phước Vinh",
						type: "Xã",
					},
					{
						level3_id: "25594",
						name: "Xã Đồng Khởi",
						type: "Xã",
					},
					{
						level3_id: "25597",
						name: "Xã Thái Bình",
						type: "Xã",
					},
					{
						level3_id: "25600",
						name: "Xã An Cơ",
						type: "Xã",
					},
					{
						level3_id: "25603",
						name: "Xã Biên Giới",
						type: "Xã",
					},
					{
						level3_id: "25606",
						name: "Xã Hòa Thạnh",
						type: "Xã",
					},
					{
						level3_id: "25609",
						name: "Xã Trí Bình",
						type: "Xã",
					},
					{
						level3_id: "25612",
						name: "Xã Hòa Hội",
						type: "Xã",
					},
					{
						level3_id: "25615",
						name: "Xã An Bình",
						type: "Xã",
					},
					{
						level3_id: "25618",
						name: "Xã Thanh Điền",
						type: "Xã",
					},
					{
						level3_id: "25621",
						name: "Xã Thành Long",
						type: "Xã",
					},
					{
						level3_id: "25624",
						name: "Xã Ninh Điền",
						type: "Xã",
					},
					{
						level3_id: "25627",
						name: "Xã Long Vĩnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "709",
				name: "Thị xã Hòa Thành",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "25630",
						name: "Phường Long Hoa",
						type: "Phường",
					},
					{
						level3_id: "25633",
						name: "Phường Hiệp Tân",
						type: "Phường",
					},
					{
						level3_id: "25636",
						name: "Phường Long Thành Bắc",
						type: "Phường",
					},
					{
						level3_id: "25639",
						name: "Xã Trường Hòa",
						type: "Xã",
					},
					{
						level3_id: "25642",
						name: "Xã Trường Đông",
						type: "Xã",
					},
					{
						level3_id: "25645",
						name: "Phường Long Thành Trung",
						type: "Phường",
					},
					{
						level3_id: "25648",
						name: "Xã Trường Tây",
						type: "Xã",
					},
					{
						level3_id: "25651",
						name: "Xã Long Thành Nam",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "710",
				name: "Huyện Gò Dầu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25654",
						name: "Thị trấn Gò Dầu",
						type: "Thị trấn",
					},
					{
						level3_id: "25657",
						name: "Xã Thạnh Đức",
						type: "Xã",
					},
					{
						level3_id: "25660",
						name: "Xã Cẩm Giang",
						type: "Xã",
					},
					{
						level3_id: "25663",
						name: "Xã Hiệp Thạnh",
						type: "Xã",
					},
					{
						level3_id: "25666",
						name: "Xã Bàu Đồn",
						type: "Xã",
					},
					{
						level3_id: "25669",
						name: "Xã Phước Thạnh",
						type: "Xã",
					},
					{
						level3_id: "25672",
						name: "Xã Phước Đông",
						type: "Xã",
					},
					{
						level3_id: "25675",
						name: "Xã Phước Trạch",
						type: "Xã",
					},
					{
						level3_id: "25678",
						name: "Xã Thanh Phước",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "711",
				name: "Huyện Bến Cầu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25681",
						name: "Thị trấn Bến Cầu",
						type: "Thị trấn",
					},
					{
						level3_id: "25684",
						name: "Xã Long Chữ",
						type: "Xã",
					},
					{
						level3_id: "25687",
						name: "Xã Long Phước",
						type: "Xã",
					},
					{
						level3_id: "25690",
						name: "Xã Long Giang",
						type: "Xã",
					},
					{
						level3_id: "25693",
						name: "Xã Tiên Thuận",
						type: "Xã",
					},
					{
						level3_id: "25696",
						name: "Xã Long Khánh",
						type: "Xã",
					},
					{
						level3_id: "25699",
						name: "Xã Lợi Thuận",
						type: "Xã",
					},
					{
						level3_id: "25702",
						name: "Xã Long Thuận",
						type: "Xã",
					},
					{
						level3_id: "25705",
						name: "Xã An Thạnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "712",
				name: "Thị xã Trảng Bàng",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "25708",
						name: "Phường Trảng Bàng",
						type: "Phường",
					},
					{
						level3_id: "25711",
						name: "Xã Đôn Thuận",
						type: "Xã",
					},
					{
						level3_id: "25714",
						name: "Xã Hưng Thuận",
						type: "Xã",
					},
					{
						level3_id: "25717",
						name: "Phường Lộc Hưng",
						type: "Phường",
					},
					{
						level3_id: "25720",
						name: "Phường Gia Lộc",
						type: "Phường",
					},
					{
						level3_id: "25723",
						name: "Phường Gia Bình",
						type: "Phường",
					},
					{
						level3_id: "25729",
						name: "Xã Phước Bình",
						type: "Xã",
					},
					{
						level3_id: "25732",
						name: "Phường An Tịnh",
						type: "Phường",
					},
					{
						level3_id: "25735",
						name: "Phường An Hòa",
						type: "Phường",
					},
					{
						level3_id: "25738",
						name: "Xã Phước Chỉ",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "74",
		name: "Tỉnh Bình Dương",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "718",
				name: "Thành phố Thủ Dầu Một",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "25741",
						name: "Phường Hiệp Thành",
						type: "Phường",
					},
					{
						level3_id: "25744",
						name: "Phường Phú Lợi",
						type: "Phường",
					},
					{
						level3_id: "25747",
						name: "Phường Phú Cường",
						type: "Phường",
					},
					{
						level3_id: "25750",
						name: "Phường Phú Hòa",
						type: "Phường",
					},
					{
						level3_id: "25753",
						name: "Phường Phú Thọ",
						type: "Phường",
					},
					{
						level3_id: "25756",
						name: "Phường Chánh Nghĩa",
						type: "Phường",
					},
					{
						level3_id: "25759",
						name: "Phường Định Hoà",
						type: "Phường",
					},
					{
						level3_id: "25760",
						name: "Phường Hoà Phú",
						type: "Phường",
					},
					{
						level3_id: "25762",
						name: "Phường Phú Mỹ",
						type: "Phường",
					},
					{
						level3_id: "25763",
						name: "Phường Phú Tân",
						type: "Phường",
					},
					{
						level3_id: "25765",
						name: "Phường Tân An",
						type: "Phường",
					},
					{
						level3_id: "25768",
						name: "Phường Hiệp An",
						type: "Phường",
					},
					{
						level3_id: "25771",
						name: "Phường Tương Bình Hiệp",
						type: "Phường",
					},
					{
						level3_id: "25774",
						name: "Phường Chánh Mỹ",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "719",
				name: "Huyện Bàu Bàng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25816",
						name: "Xã Trừ Văn Thố",
						type: "Xã",
					},
					{
						level3_id: "25819",
						name: "Xã Cây Trường II",
						type: "Xã",
					},
					{
						level3_id: "25822",
						name: "Thị trấn Lai Uyên",
						type: "Thị trấn",
					},
					{
						level3_id: "25825",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "25828",
						name: "Xã Long Nguyên",
						type: "Xã",
					},
					{
						level3_id: "25831",
						name: "Xã Hưng Hòa",
						type: "Xã",
					},
					{
						level3_id: "25834",
						name: "Xã Lai Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "720",
				name: "Huyện Dầu Tiếng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25777",
						name: "Thị trấn Dầu Tiếng",
						type: "Thị trấn",
					},
					{
						level3_id: "25780",
						name: "Xã Minh Hoà",
						type: "Xã",
					},
					{
						level3_id: "25783",
						name: "Xã Minh Thạnh",
						type: "Xã",
					},
					{
						level3_id: "25786",
						name: "Xã Minh Tân",
						type: "Xã",
					},
					{
						level3_id: "25789",
						name: "Xã Định An",
						type: "Xã",
					},
					{
						level3_id: "25792",
						name: "Xã Long Hoà",
						type: "Xã",
					},
					{
						level3_id: "25795",
						name: "Xã Định Thành",
						type: "Xã",
					},
					{
						level3_id: "25798",
						name: "Xã Định Hiệp",
						type: "Xã",
					},
					{
						level3_id: "25801",
						name: "Xã An Lập",
						type: "Xã",
					},
					{
						level3_id: "25804",
						name: "Xã Long Tân",
						type: "Xã",
					},
					{
						level3_id: "25807",
						name: "Xã Thanh An",
						type: "Xã",
					},
					{
						level3_id: "25810",
						name: "Xã Thanh Tuyền",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "721",
				name: "Thị xã Bến Cát",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "25813",
						name: "Phường Mỹ Phước",
						type: "Phường",
					},
					{
						level3_id: "25837",
						name: "Phường Chánh Phú Hòa",
						type: "Phường",
					},
					{
						level3_id: "25840",
						name: "Xã An Điền",
						type: "Xã",
					},
					{
						level3_id: "25843",
						name: "Xã An Tây",
						type: "Xã",
					},
					{
						level3_id: "25846",
						name: "Phường Thới Hòa",
						type: "Phường",
					},
					{
						level3_id: "25849",
						name: "Phường Hòa Lợi",
						type: "Phường",
					},
					{
						level3_id: "25852",
						name: "Phường Tân Định",
						type: "Phường",
					},
					{
						level3_id: "25855",
						name: "Xã Phú An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "722",
				name: "Huyện Phú Giáo",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25858",
						name: "Thị trấn Phước Vĩnh",
						type: "Thị trấn",
					},
					{
						level3_id: "25861",
						name: "Xã An Linh",
						type: "Xã",
					},
					{
						level3_id: "25864",
						name: "Xã Phước Sang",
						type: "Xã",
					},
					{
						level3_id: "25865",
						name: "Xã An Thái",
						type: "Xã",
					},
					{
						level3_id: "25867",
						name: "Xã An Long",
						type: "Xã",
					},
					{
						level3_id: "25870",
						name: "Xã An Bình",
						type: "Xã",
					},
					{
						level3_id: "25873",
						name: "Xã Tân Hiệp",
						type: "Xã",
					},
					{
						level3_id: "25876",
						name: "Xã Tam Lập",
						type: "Xã",
					},
					{
						level3_id: "25879",
						name: "Xã Tân Long",
						type: "Xã",
					},
					{
						level3_id: "25882",
						name: "Xã Vĩnh Hoà",
						type: "Xã",
					},
					{
						level3_id: "25885",
						name: "Xã Phước Hoà",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "723",
				name: "Thị xã Tân Uyên",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "25888",
						name: "Phường Uyên Hưng",
						type: "Phường",
					},
					{
						level3_id: "25891",
						name: "Phường Tân Phước Khánh",
						type: "Phường",
					},
					{
						level3_id: "25912",
						name: "Phường Vĩnh Tân",
						type: "Phường",
					},
					{
						level3_id: "25915",
						name: "Phường Hội Nghĩa",
						type: "Phường",
					},
					{
						level3_id: "25920",
						name: "Phường Tân Hiệp",
						type: "Phường",
					},
					{
						level3_id: "25921",
						name: "Phường Khánh Bình",
						type: "Phường",
					},
					{
						level3_id: "25924",
						name: "Phường Phú Chánh",
						type: "Phường",
					},
					{
						level3_id: "25930",
						name: "Xã Bạch Đằng",
						type: "Xã",
					},
					{
						level3_id: "25933",
						name: "Phường Tân Vĩnh Hiệp",
						type: "Phường",
					},
					{
						level3_id: "25936",
						name: "Phường Thạnh Phước",
						type: "Phường",
					},
					{
						level3_id: "25937",
						name: "Xã Thạnh Hội",
						type: "Xã",
					},
					{
						level3_id: "25939",
						name: "Phường Thái Hòa",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "724",
				name: "Thành phố Dĩ An",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "25942",
						name: "Phường Dĩ An",
						type: "Phường",
					},
					{
						level3_id: "25945",
						name: "Phường Tân Bình",
						type: "Phường",
					},
					{
						level3_id: "25948",
						name: "Phường Tân Đông Hiệp",
						type: "Phường",
					},
					{
						level3_id: "25951",
						name: "Phường Bình An",
						type: "Phường",
					},
					{
						level3_id: "25954",
						name: "Phường Bình Thắng",
						type: "Phường",
					},
					{
						level3_id: "25957",
						name: "Phường Đông Hòa",
						type: "Phường",
					},
					{
						level3_id: "25960",
						name: "Phường An Bình",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "725",
				name: "Thành phố Thuận An",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "25963",
						name: "Phường An Thạnh",
						type: "Phường",
					},
					{
						level3_id: "25966",
						name: "Phường Lái Thiêu",
						type: "Phường",
					},
					{
						level3_id: "25969",
						name: "Phường Bình Chuẩn",
						type: "Phường",
					},
					{
						level3_id: "25972",
						name: "Phường Thuận Giao",
						type: "Phường",
					},
					{
						level3_id: "25975",
						name: "Phường An Phú",
						type: "Phường",
					},
					{
						level3_id: "25978",
						name: "Phường Hưng Định",
						type: "Phường",
					},
					{
						level3_id: "25981",
						name: "Xã An Sơn",
						type: "Xã",
					},
					{
						level3_id: "25984",
						name: "Phường Bình Nhâm",
						type: "Phường",
					},
					{
						level3_id: "25987",
						name: "Phường Bình Hòa",
						type: "Phường",
					},
					{
						level3_id: "25990",
						name: "Phường Vĩnh Phú",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "726",
				name: "Huyện Bắc Tân Uyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "25894",
						name: "Xã Tân Định",
						type: "Xã",
					},
					{
						level3_id: "25897",
						name: "Xã Bình Mỹ",
						type: "Xã",
					},
					{
						level3_id: "25900",
						name: "Thị trấn Tân Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "25903",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "25906",
						name: "Thị trấn Tân Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "25907",
						name: "Xã Đất Cuốc",
						type: "Xã",
					},
					{
						level3_id: "25908",
						name: "Xã Hiếu Liêm",
						type: "Xã",
					},
					{
						level3_id: "25909",
						name: "Xã Lạc An",
						type: "Xã",
					},
					{
						level3_id: "25918",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "25927",
						name: "Xã Thường Tân",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "75",
		name: "Tỉnh Đồng Nai",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "731",
				name: "Thành phố Biên Hòa",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "25993",
						name: "Phường Trảng Dài",
						type: "Phường",
					},
					{
						level3_id: "25996",
						name: "Phường Tân Phong",
						type: "Phường",
					},
					{
						level3_id: "25999",
						name: "Phường Tân Biên",
						type: "Phường",
					},
					{
						level3_id: "26002",
						name: "Phường Hố Nai",
						type: "Phường",
					},
					{
						level3_id: "26005",
						name: "Phường Tân Hòa",
						type: "Phường",
					},
					{
						level3_id: "26008",
						name: "Phường Tân Hiệp",
						type: "Phường",
					},
					{
						level3_id: "26011",
						name: "Phường Bửu Long",
						type: "Phường",
					},
					{
						level3_id: "26014",
						name: "Phường Tân Tiến",
						type: "Phường",
					},
					{
						level3_id: "26017",
						name: "Phường Tam Hiệp",
						type: "Phường",
					},
					{
						level3_id: "26020",
						name: "Phường Long Bình",
						type: "Phường",
					},
					{
						level3_id: "26023",
						name: "Phường Quang Vinh",
						type: "Phường",
					},
					{
						level3_id: "26026",
						name: "Phường Tân Mai",
						type: "Phường",
					},
					{
						level3_id: "26029",
						name: "Phường Thống Nhất",
						type: "Phường",
					},
					{
						level3_id: "26032",
						name: "Phường Trung Dũng",
						type: "Phường",
					},
					{
						level3_id: "26035",
						name: "Phường Tam Hòa",
						type: "Phường",
					},
					{
						level3_id: "26038",
						name: "Phường Hòa Bình",
						type: "Phường",
					},
					{
						level3_id: "26041",
						name: "Phường Quyết Thắng",
						type: "Phường",
					},
					{
						level3_id: "26044",
						name: "Phường Thanh Bình",
						type: "Phường",
					},
					{
						level3_id: "26047",
						name: "Phường Bình Đa",
						type: "Phường",
					},
					{
						level3_id: "26050",
						name: "Phường An Bình",
						type: "Phường",
					},
					{
						level3_id: "26053",
						name: "Phường Bửu Hòa",
						type: "Phường",
					},
					{
						level3_id: "26056",
						name: "Phường Long Bình Tân",
						type: "Phường",
					},
					{
						level3_id: "26059",
						name: "Phường Tân Vạn",
						type: "Phường",
					},
					{
						level3_id: "26062",
						name: "Phường Tân Hạnh",
						type: "Phường",
					},
					{
						level3_id: "26065",
						name: "Phường Hiệp Hòa",
						type: "Phường",
					},
					{
						level3_id: "26068",
						name: "Phường Hóa An",
						type: "Phường",
					},
					{
						level3_id: "26371",
						name: "Phường An Hòa",
						type: "Phường",
					},
					{
						level3_id: "26374",
						name: "Phường Tam Phước",
						type: "Phường",
					},
					{
						level3_id: "26377",
						name: "Phường Phước Tân",
						type: "Phường",
					},
					{
						level3_id: "26380",
						name: "Xã Long Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "732",
				name: "Thành phố Long Khánh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "26071",
						name: "Phường Xuân Trung",
						type: "Phường",
					},
					{
						level3_id: "26074",
						name: "Phường Xuân Thanh",
						type: "Phường",
					},
					{
						level3_id: "26077",
						name: "Phường Xuân Bình",
						type: "Phường",
					},
					{
						level3_id: "26080",
						name: "Phường Xuân An",
						type: "Phường",
					},
					{
						level3_id: "26083",
						name: "Phường Xuân Hoà",
						type: "Phường",
					},
					{
						level3_id: "26086",
						name: "Phường Phú Bình",
						type: "Phường",
					},
					{
						level3_id: "26089",
						name: "Xã Bình Lộc",
						type: "Xã",
					},
					{
						level3_id: "26092",
						name: "Xã Bảo Quang",
						type: "Xã",
					},
					{
						level3_id: "26095",
						name: "Phường Suối Tre",
						type: "Phường",
					},
					{
						level3_id: "26098",
						name: "Phường Bảo Vinh",
						type: "Phường",
					},
					{
						level3_id: "26101",
						name: "Phường Xuân Lập",
						type: "Phường",
					},
					{
						level3_id: "26104",
						name: "Phường Bàu Sen",
						type: "Phường",
					},
					{
						level3_id: "26107",
						name: "Xã Bàu Trâm",
						type: "Xã",
					},
					{
						level3_id: "26110",
						name: "Phường Xuân Tân",
						type: "Phường",
					},
					{
						level3_id: "26113",
						name: "Xã Hàng Gòn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "734",
				name: "Huyện Tân Phú",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26116",
						name: "Thị trấn Tân Phú",
						type: "Thị trấn",
					},
					{
						level3_id: "26119",
						name: "Xã Dak Lua",
						type: "Xã",
					},
					{
						level3_id: "26122",
						name: "Xã Nam Cát Tiên",
						type: "Xã",
					},
					{
						level3_id: "26125",
						name: "Xã Phú An",
						type: "Xã",
					},
					{
						level3_id: "26128",
						name: "Xã Núi Tượng",
						type: "Xã",
					},
					{
						level3_id: "26131",
						name: "Xã Tà Lài",
						type: "Xã",
					},
					{
						level3_id: "26134",
						name: "Xã Phú Lập",
						type: "Xã",
					},
					{
						level3_id: "26137",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "26140",
						name: "Xã Phú Thịnh",
						type: "Xã",
					},
					{
						level3_id: "26143",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "26146",
						name: "Xã Phú Trung",
						type: "Xã",
					},
					{
						level3_id: "26149",
						name: "Xã Phú Xuân",
						type: "Xã",
					},
					{
						level3_id: "26152",
						name: "Xã Phú Lộc",
						type: "Xã",
					},
					{
						level3_id: "26155",
						name: "Xã Phú Lâm",
						type: "Xã",
					},
					{
						level3_id: "26158",
						name: "Xã Phú Bình",
						type: "Xã",
					},
					{
						level3_id: "26161",
						name: "Xã Phú Thanh",
						type: "Xã",
					},
					{
						level3_id: "26164",
						name: "Xã Trà Cổ",
						type: "Xã",
					},
					{
						level3_id: "26167",
						name: "Xã Phú Điền",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "735",
				name: "Huyện Vĩnh Cửu",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26170",
						name: "Thị trấn Vĩnh An",
						type: "Thị trấn",
					},
					{
						level3_id: "26173",
						name: "Xã Phú Lý",
						type: "Xã",
					},
					{
						level3_id: "26176",
						name: "Xã Trị An",
						type: "Xã",
					},
					{
						level3_id: "26179",
						name: "Xã Tân An",
						type: "Xã",
					},
					{
						level3_id: "26182",
						name: "Xã Vĩnh Tân",
						type: "Xã",
					},
					{
						level3_id: "26185",
						name: "Xã Bình Lợi",
						type: "Xã",
					},
					{
						level3_id: "26188",
						name: "Xã Thạnh Phú",
						type: "Xã",
					},
					{
						level3_id: "26191",
						name: "Xã Thiện Tân",
						type: "Xã",
					},
					{
						level3_id: "26194",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "26197",
						name: "Xã Bình Hòa",
						type: "Xã",
					},
					{
						level3_id: "26200",
						name: "Xã Mã Đà",
						type: "Xã",
					},
					{
						level3_id: "26203",
						name: "Xã Hiếu Liêm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "736",
				name: "Huyện Định Quán",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26206",
						name: "Thị trấn Định Quán",
						type: "Thị trấn",
					},
					{
						level3_id: "26209",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "26212",
						name: "Xã Phú Tân",
						type: "Xã",
					},
					{
						level3_id: "26215",
						name: "Xã Phú Vinh",
						type: "Xã",
					},
					{
						level3_id: "26218",
						name: "Xã Phú Lợi",
						type: "Xã",
					},
					{
						level3_id: "26221",
						name: "Xã Phú Hòa",
						type: "Xã",
					},
					{
						level3_id: "26224",
						name: "Xã Ngọc Định",
						type: "Xã",
					},
					{
						level3_id: "26227",
						name: "Xã La Ngà",
						type: "Xã",
					},
					{
						level3_id: "26230",
						name: "Xã Gia Canh",
						type: "Xã",
					},
					{
						level3_id: "26233",
						name: "Xã Phú Ngọc",
						type: "Xã",
					},
					{
						level3_id: "26236",
						name: "Xã Phú Cường",
						type: "Xã",
					},
					{
						level3_id: "26239",
						name: "Xã Túc Trưng",
						type: "Xã",
					},
					{
						level3_id: "26242",
						name: "Xã Phú Túc",
						type: "Xã",
					},
					{
						level3_id: "26245",
						name: "Xã Suối Nho",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "737",
				name: "Huyện Trảng Bom",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26248",
						name: "Thị trấn Trảng Bom",
						type: "Thị trấn",
					},
					{
						level3_id: "26251",
						name: "Xã Thanh Bình",
						type: "Xã",
					},
					{
						level3_id: "26254",
						name: "Xã Cây Gáo",
						type: "Xã",
					},
					{
						level3_id: "26257",
						name: "Xã Bàu Hàm",
						type: "Xã",
					},
					{
						level3_id: "26260",
						name: "Xã Sông Thao",
						type: "Xã",
					},
					{
						level3_id: "26263",
						name: "Xã Sông Trầu",
						type: "Xã",
					},
					{
						level3_id: "26266",
						name: "Xã Đông Hoà",
						type: "Xã",
					},
					{
						level3_id: "26269",
						name: "Xã Bắc Sơn",
						type: "Xã",
					},
					{
						level3_id: "26272",
						name: "Xã Hố Nai 3",
						type: "Xã",
					},
					{
						level3_id: "26275",
						name: "Xã Tây Hoà",
						type: "Xã",
					},
					{
						level3_id: "26278",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "26281",
						name: "Xã Trung Hoà",
						type: "Xã",
					},
					{
						level3_id: "26284",
						name: "Xã Đồi 61",
						type: "Xã",
					},
					{
						level3_id: "26287",
						name: "Xã Hưng Thịnh",
						type: "Xã",
					},
					{
						level3_id: "26290",
						name: "Xã Quảng Tiến",
						type: "Xã",
					},
					{
						level3_id: "26293",
						name: "Xã Giang Điền",
						type: "Xã",
					},
					{
						level3_id: "26296",
						name: "Xã An Viễn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "738",
				name: "Huyện Thống Nhất",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26299",
						name: "Xã Gia Tân 1",
						type: "Xã",
					},
					{
						level3_id: "26302",
						name: "Xã Gia Tân 2",
						type: "Xã",
					},
					{
						level3_id: "26305",
						name: "Xã Gia Tân 3",
						type: "Xã",
					},
					{
						level3_id: "26308",
						name: "Xã Gia Kiệm",
						type: "Xã",
					},
					{
						level3_id: "26311",
						name: "Xã Quang Trung",
						type: "Xã",
					},
					{
						level3_id: "26314",
						name: "Xã Bàu Hàm 2",
						type: "Xã",
					},
					{
						level3_id: "26317",
						name: "Xã Hưng Lộc",
						type: "Xã",
					},
					{
						level3_id: "26320",
						name: "Xã Lộ 25",
						type: "Xã",
					},
					{
						level3_id: "26323",
						name: "Xã Xuân Thiện",
						type: "Xã",
					},
					{
						level3_id: "26326",
						name: "Thị trấn Dầu Giây",
						type: "Thị trấn",
					},
				],
			},
			{
				level2_id: "739",
				name: "Huyện Cẩm Mỹ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26329",
						name: "Xã Sông Nhạn",
						type: "Xã",
					},
					{
						level3_id: "26332",
						name: "Xã Xuân Quế",
						type: "Xã",
					},
					{
						level3_id: "26335",
						name: "Xã Nhân Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "26338",
						name: "Xã Xuân Đường",
						type: "Xã",
					},
					{
						level3_id: "26341",
						name: "Thị trấn Long Giao",
						type: "Thị trấn",
					},
					{
						level3_id: "26344",
						name: "Xã Xuân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "26347",
						name: "Xã Thừa Đức",
						type: "Xã",
					},
					{
						level3_id: "26350",
						name: "Xã Bảo Bình",
						type: "Xã",
					},
					{
						level3_id: "26353",
						name: "Xã Xuân Bảo",
						type: "Xã",
					},
					{
						level3_id: "26356",
						name: "Xã Xuân Tây",
						type: "Xã",
					},
					{
						level3_id: "26359",
						name: "Xã Xuân Đông",
						type: "Xã",
					},
					{
						level3_id: "26362",
						name: "Xã Sông Ray",
						type: "Xã",
					},
					{
						level3_id: "26365",
						name: "Xã Lâm San",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "740",
				name: "Huyện Long Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26368",
						name: "Thị trấn Long Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "26383",
						name: "Xã An Phước",
						type: "Xã",
					},
					{
						level3_id: "26386",
						name: "Xã Bình An",
						type: "Xã",
					},
					{
						level3_id: "26389",
						name: "Xã Long Đức",
						type: "Xã",
					},
					{
						level3_id: "26392",
						name: "Xã Lộc An",
						type: "Xã",
					},
					{
						level3_id: "26395",
						name: "Xã Bình Sơn",
						type: "Xã",
					},
					{
						level3_id: "26398",
						name: "Xã Tam An",
						type: "Xã",
					},
					{
						level3_id: "26401",
						name: "Xã Cẩm Đường",
						type: "Xã",
					},
					{
						level3_id: "26404",
						name: "Xã Long An",
						type: "Xã",
					},
					{
						level3_id: "26410",
						name: "Xã Bàu Cạn",
						type: "Xã",
					},
					{
						level3_id: "26413",
						name: "Xã Long Phước",
						type: "Xã",
					},
					{
						level3_id: "26416",
						name: "Xã Phước Bình",
						type: "Xã",
					},
					{
						level3_id: "26419",
						name: "Xã Tân Hiệp",
						type: "Xã",
					},
					{
						level3_id: "26422",
						name: "Xã Phước Thái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "741",
				name: "Huyện Xuân Lộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26425",
						name: "Thị trấn Gia Ray",
						type: "Thị trấn",
					},
					{
						level3_id: "26428",
						name: "Xã Xuân Bắc",
						type: "Xã",
					},
					{
						level3_id: "26431",
						name: "Xã Suối Cao",
						type: "Xã",
					},
					{
						level3_id: "26434",
						name: "Xã Xuân Thành",
						type: "Xã",
					},
					{
						level3_id: "26437",
						name: "Xã Xuân Thọ",
						type: "Xã",
					},
					{
						level3_id: "26440",
						name: "Xã Xuân Trường",
						type: "Xã",
					},
					{
						level3_id: "26443",
						name: "Xã Xuân Hòa",
						type: "Xã",
					},
					{
						level3_id: "26446",
						name: "Xã Xuân Hưng",
						type: "Xã",
					},
					{
						level3_id: "26449",
						name: "Xã Xuân Tâm",
						type: "Xã",
					},
					{
						level3_id: "26452",
						name: "Xã Suối Cát",
						type: "Xã",
					},
					{
						level3_id: "26455",
						name: "Xã Xuân Hiệp",
						type: "Xã",
					},
					{
						level3_id: "26458",
						name: "Xã Xuân Phú",
						type: "Xã",
					},
					{
						level3_id: "26461",
						name: "Xã Xuân Định",
						type: "Xã",
					},
					{
						level3_id: "26464",
						name: "Xã Bảo Hoà",
						type: "Xã",
					},
					{
						level3_id: "26467",
						name: "Xã Lang Minh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "742",
				name: "Huyện Nhơn Trạch",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26470",
						name: "Xã Phước Thiền",
						type: "Xã",
					},
					{
						level3_id: "26473",
						name: "Xã Long Tân",
						type: "Xã",
					},
					{
						level3_id: "26476",
						name: "Xã Đại Phước",
						type: "Xã",
					},
					{
						level3_id: "26479",
						name: "Thị trấn Hiệp Phước",
						type: "Thị trấn",
					},
					{
						level3_id: "26482",
						name: "Xã Phú Hữu",
						type: "Xã",
					},
					{
						level3_id: "26485",
						name: "Xã Phú Hội",
						type: "Xã",
					},
					{
						level3_id: "26488",
						name: "Xã Phú Thạnh",
						type: "Xã",
					},
					{
						level3_id: "26491",
						name: "Xã Phú Đông",
						type: "Xã",
					},
					{
						level3_id: "26494",
						name: "Xã Long Thọ",
						type: "Xã",
					},
					{
						level3_id: "26497",
						name: "Xã Vĩnh Thanh",
						type: "Xã",
					},
					{
						level3_id: "26500",
						name: "Xã Phước Khánh",
						type: "Xã",
					},
					{
						level3_id: "26503",
						name: "Xã Phước An",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "77",
		name: "Tỉnh Bà Rịa - Vũng Tàu",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "747",
				name: "Thành phố Vũng Tàu",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "26506",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "26508",
						name: "Phường Thắng Tam",
						type: "Phường",
					},
					{
						level3_id: "26509",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "26512",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "26515",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "26518",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "26521",
						name: "Phường Thắng Nhì",
						type: "Phường",
					},
					{
						level3_id: "26524",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "26526",
						name: "Phường Nguyễn An Ninh",
						type: "Phường",
					},
					{
						level3_id: "26527",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "26530",
						name: "Phường 9",
						type: "Phường",
					},
					{
						level3_id: "26533",
						name: "Phường Thắng Nhất",
						type: "Phường",
					},
					{
						level3_id: "26535",
						name: "Phường Rạch Dừa",
						type: "Phường",
					},
					{
						level3_id: "26536",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "26539",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "26542",
						name: "Phường 12",
						type: "Phường",
					},
					{
						level3_id: "26545",
						name: "Xã Long Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "748",
				name: "Thành phố Bà Rịa",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "26548",
						name: "Phường Phước Hưng",
						type: "Phường",
					},
					{
						level3_id: "26551",
						name: "Phường Phước Hiệp",
						type: "Phường",
					},
					{
						level3_id: "26554",
						name: "Phường Phước Nguyên",
						type: "Phường",
					},
					{
						level3_id: "26557",
						name: "Phường Long Toàn",
						type: "Phường",
					},
					{
						level3_id: "26558",
						name: "Phường Long Tâm",
						type: "Phường",
					},
					{
						level3_id: "26560",
						name: "Phường Phước Trung",
						type: "Phường",
					},
					{
						level3_id: "26563",
						name: "Phường Long Hương",
						type: "Phường",
					},
					{
						level3_id: "26566",
						name: "Phường Kim Dinh",
						type: "Phường",
					},
					{
						level3_id: "26567",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "26569",
						name: "Xã Long Phước",
						type: "Xã",
					},
					{
						level3_id: "26572",
						name: "Xã Hoà Long",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "750",
				name: "Huyện Châu Đức",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26574",
						name: "Xã Bàu Chinh",
						type: "Xã",
					},
					{
						level3_id: "26575",
						name: "Thị trấn Ngãi Giao",
						type: "Thị trấn",
					},
					{
						level3_id: "26578",
						name: "Xã Bình Ba",
						type: "Xã",
					},
					{
						level3_id: "26581",
						name: "Xã Suối Nghệ",
						type: "Xã",
					},
					{
						level3_id: "26584",
						name: "Xã Xuân Sơn",
						type: "Xã",
					},
					{
						level3_id: "26587",
						name: "Xã Sơn Bình",
						type: "Xã",
					},
					{
						level3_id: "26590",
						name: "Xã Bình Giã",
						type: "Xã",
					},
					{
						level3_id: "26593",
						name: "Xã Bình Trung",
						type: "Xã",
					},
					{
						level3_id: "26596",
						name: "Xã Xà Bang",
						type: "Xã",
					},
					{
						level3_id: "26599",
						name: "Xã Cù Bị",
						type: "Xã",
					},
					{
						level3_id: "26602",
						name: "Xã Láng Lớn",
						type: "Xã",
					},
					{
						level3_id: "26605",
						name: "Xã Quảng Thành",
						type: "Xã",
					},
					{
						level3_id: "26608",
						name: "Xã Kim Long",
						type: "Xã",
					},
					{
						level3_id: "26611",
						name: "Xã Suối Rao",
						type: "Xã",
					},
					{
						level3_id: "26614",
						name: "Xã Đá Bạc",
						type: "Xã",
					},
					{
						level3_id: "26617",
						name: "Xã Nghĩa Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "751",
				name: "Huyện Xuyên Mộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26620",
						name: "Thị trấn Phước Bửu",
						type: "Thị trấn",
					},
					{
						level3_id: "26623",
						name: "Xã Phước Thuận",
						type: "Xã",
					},
					{
						level3_id: "26626",
						name: "Xã Phước Tân",
						type: "Xã",
					},
					{
						level3_id: "26629",
						name: "Xã Xuyên Mộc",
						type: "Xã",
					},
					{
						level3_id: "26632",
						name: "Xã Bông Trang",
						type: "Xã",
					},
					{
						level3_id: "26635",
						name: "Xã Tân Lâm",
						type: "Xã",
					},
					{
						level3_id: "26638",
						name: "Xã Bàu Lâm",
						type: "Xã",
					},
					{
						level3_id: "26641",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "26644",
						name: "Xã Hòa Hưng",
						type: "Xã",
					},
					{
						level3_id: "26647",
						name: "Xã Hòa Hiệp",
						type: "Xã",
					},
					{
						level3_id: "26650",
						name: "Xã Hòa Hội",
						type: "Xã",
					},
					{
						level3_id: "26653",
						name: "Xã Bưng Riềng",
						type: "Xã",
					},
					{
						level3_id: "26656",
						name: "Xã Bình Châu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "752",
				name: "Huyện Long Điền",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26659",
						name: "Thị trấn Long Điền",
						type: "Thị trấn",
					},
					{
						level3_id: "26662",
						name: "Thị trấn Long Hải",
						type: "Thị trấn",
					},
					{
						level3_id: "26665",
						name: "Xã An Ngãi",
						type: "Xã",
					},
					{
						level3_id: "26668",
						name: "Xã Tam Phước",
						type: "Xã",
					},
					{
						level3_id: "26671",
						name: "Xã An Nhứt",
						type: "Xã",
					},
					{
						level3_id: "26674",
						name: "Xã Phước Tỉnh",
						type: "Xã",
					},
					{
						level3_id: "26677",
						name: "Xã Phước Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "753",
				name: "Huyện Đất Đỏ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "26680",
						name: "Thị trấn Đất Đỏ",
						type: "Thị trấn",
					},
					{
						level3_id: "26683",
						name: "Xã Phước Long Thọ",
						type: "Xã",
					},
					{
						level3_id: "26686",
						name: "Xã Phước Hội",
						type: "Xã",
					},
					{
						level3_id: "26689",
						name: "Xã Long Mỹ",
						type: "Xã",
					},
					{
						level3_id: "26692",
						name: "Thị trấn Phước Hải",
						type: "Thị trấn",
					},
					{
						level3_id: "26695",
						name: "Xã Long Tân",
						type: "Xã",
					},
					{
						level3_id: "26698",
						name: "Xã Láng Dài",
						type: "Xã",
					},
					{
						level3_id: "26701",
						name: "Xã Lộc An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "754",
				name: "Thị xã Phú Mỹ",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "26704",
						name: "Phường Phú Mỹ",
						type: "Phường",
					},
					{
						level3_id: "26707",
						name: "Xã Tân Hoà",
						type: "Xã",
					},
					{
						level3_id: "26710",
						name: "Xã Tân Hải",
						type: "Xã",
					},
					{
						level3_id: "26713",
						name: "Phường Phước Hoà",
						type: "Phường",
					},
					{
						level3_id: "26716",
						name: "Phường Tân Phước",
						type: "Phường",
					},
					{
						level3_id: "26719",
						name: "Phường Mỹ Xuân",
						type: "Phường",
					},
					{
						level3_id: "26722",
						name: "Xã Sông Xoài",
						type: "Xã",
					},
					{
						level3_id: "26725",
						name: "Phường Hắc Dịch",
						type: "Phường",
					},
					{
						level3_id: "26728",
						name: "Xã Châu Pha",
						type: "Xã",
					},
					{
						level3_id: "26731",
						name: "Xã Tóc Tiên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "755",
				name: "Huyện Côn Đảo",
				type: "Huyện",
				level3s: [],
			},
		],
	},
	{
		level1_id: "80",
		name: "Tỉnh Long An",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "794",
				name: "Thành phố Tân An",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "27685",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "27688",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "27691",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "27692",
						name: "Phường Tân Khánh",
						type: "Phường",
					},
					{
						level3_id: "27694",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "27697",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "27698",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "27700",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "27703",
						name: "Xã Hướng Thọ Phú",
						type: "Xã",
					},
					{
						level3_id: "27706",
						name: "Xã Nhơn Thạnh Trung",
						type: "Xã",
					},
					{
						level3_id: "27709",
						name: "Xã Lợi Bình Nhơn",
						type: "Xã",
					},
					{
						level3_id: "27712",
						name: "Xã Bình Tâm",
						type: "Xã",
					},
					{
						level3_id: "27715",
						name: "Phường Khánh Hậu",
						type: "Phường",
					},
					{
						level3_id: "27718",
						name: "Xã An Vĩnh Ngãi",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "795",
				name: "Thị xã Kiến Tường",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "27787",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "27788",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "27790",
						name: "Xã Thạnh Trị",
						type: "Xã",
					},
					{
						level3_id: "27793",
						name: "Xã Bình Hiệp",
						type: "Xã",
					},
					{
						level3_id: "27799",
						name: "Xã Bình Tân",
						type: "Xã",
					},
					{
						level3_id: "27805",
						name: "Xã Tuyên Thạnh",
						type: "Xã",
					},
					{
						level3_id: "27806",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "27817",
						name: "Xã Thạnh Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "796",
				name: "Huyện Tân Hưng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27721",
						name: "Thị trấn Tân Hưng",
						type: "Thị trấn",
					},
					{
						level3_id: "27724",
						name: "Xã Hưng Hà",
						type: "Xã",
					},
					{
						level3_id: "27727",
						name: "Xã Hưng Điền B",
						type: "Xã",
					},
					{
						level3_id: "27730",
						name: "Xã Hưng Điền",
						type: "Xã",
					},
					{
						level3_id: "27733",
						name: "Xã Thạnh Hưng",
						type: "Xã",
					},
					{
						level3_id: "27736",
						name: "Xã Hưng Thạnh",
						type: "Xã",
					},
					{
						level3_id: "27739",
						name: "Xã Vĩnh Thạnh",
						type: "Xã",
					},
					{
						level3_id: "27742",
						name: "Xã Vĩnh Châu B",
						type: "Xã",
					},
					{
						level3_id: "27745",
						name: "Xã Vĩnh Lợi",
						type: "Xã",
					},
					{
						level3_id: "27748",
						name: "Xã Vĩnh Đại",
						type: "Xã",
					},
					{
						level3_id: "27751",
						name: "Xã Vĩnh Châu A",
						type: "Xã",
					},
					{
						level3_id: "27754",
						name: "Xã Vĩnh Bửu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "797",
				name: "Huyện Vĩnh Hưng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27757",
						name: "Thị trấn Vĩnh Hưng",
						type: "Thị trấn",
					},
					{
						level3_id: "27760",
						name: "Xã Hưng Điền A",
						type: "Xã",
					},
					{
						level3_id: "27763",
						name: "Xã Khánh Hưng",
						type: "Xã",
					},
					{
						level3_id: "27766",
						name: "Xã Thái Trị",
						type: "Xã",
					},
					{
						level3_id: "27769",
						name: "Xã Vĩnh Trị",
						type: "Xã",
					},
					{
						level3_id: "27772",
						name: "Xã Thái Bình Trung",
						type: "Xã",
					},
					{
						level3_id: "27775",
						name: "Xã Vĩnh Bình",
						type: "Xã",
					},
					{
						level3_id: "27778",
						name: "Xã Vĩnh Thuận",
						type: "Xã",
					},
					{
						level3_id: "27781",
						name: "Xã Tuyên Bình",
						type: "Xã",
					},
					{
						level3_id: "27784",
						name: "Xã Tuyên Bình Tây",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "798",
				name: "Huyện Mộc Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27796",
						name: "Xã Bình Hòa Tây",
						type: "Xã",
					},
					{
						level3_id: "27802",
						name: "Xã Bình Thạnh",
						type: "Xã",
					},
					{
						level3_id: "27808",
						name: "Xã Bình Hòa Trung",
						type: "Xã",
					},
					{
						level3_id: "27811",
						name: "Xã Bình Hòa Đông",
						type: "Xã",
					},
					{
						level3_id: "27814",
						name: "Thị trấn Bình Phong Thạnh",
						type: "Thị trấn",
					},
					{
						level3_id: "27820",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "27823",
						name: "Xã Tân Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "799",
				name: "Huyện Tân Thạnh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27826",
						name: "Thị trấn Tân Thạnh",
						type: "Thị trấn",
					},
					{
						level3_id: "27829",
						name: "Xã Bắc Hòa",
						type: "Xã",
					},
					{
						level3_id: "27832",
						name: "Xã Hậu Thạnh Tây",
						type: "Xã",
					},
					{
						level3_id: "27835",
						name: "Xã Nhơn Hòa Lập",
						type: "Xã",
					},
					{
						level3_id: "27838",
						name: "Xã Tân Lập",
						type: "Xã",
					},
					{
						level3_id: "27841",
						name: "Xã Hậu Thạnh Đông",
						type: "Xã",
					},
					{
						level3_id: "27844",
						name: "Xã Nhơn Hoà",
						type: "Xã",
					},
					{
						level3_id: "27847",
						name: "Xã Kiến Bình",
						type: "Xã",
					},
					{
						level3_id: "27850",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "27853",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "27856",
						name: "Xã Tân Ninh",
						type: "Xã",
					},
					{
						level3_id: "27859",
						name: "Xã Nhơn Ninh",
						type: "Xã",
					},
					{
						level3_id: "27862",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "800",
				name: "Huyện Thạnh Hóa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27865",
						name: "Thị trấn Thạnh Hóa",
						type: "Thị trấn",
					},
					{
						level3_id: "27868",
						name: "Xã Tân Hiệp",
						type: "Xã",
					},
					{
						level3_id: "27871",
						name: "Xã Thuận Bình",
						type: "Xã",
					},
					{
						level3_id: "27874",
						name: "Xã Thạnh Phước",
						type: "Xã",
					},
					{
						level3_id: "27877",
						name: "Xã Thạnh Phú",
						type: "Xã",
					},
					{
						level3_id: "27880",
						name: "Xã Thuận Nghĩa Hòa",
						type: "Xã",
					},
					{
						level3_id: "27883",
						name: "Xã Thủy Đông",
						type: "Xã",
					},
					{
						level3_id: "27886",
						name: "Xã Thủy Tây",
						type: "Xã",
					},
					{
						level3_id: "27889",
						name: "Xã Tân Tây",
						type: "Xã",
					},
					{
						level3_id: "27892",
						name: "Xã Tân Đông",
						type: "Xã",
					},
					{
						level3_id: "27895",
						name: "Xã Thạnh An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "801",
				name: "Huyện Đức Huệ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27898",
						name: "Thị trấn Đông Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "27901",
						name: "Xã Mỹ Quý Đông",
						type: "Xã",
					},
					{
						level3_id: "27904",
						name: "Xã Mỹ Thạnh Bắc",
						type: "Xã",
					},
					{
						level3_id: "27907",
						name: "Xã Mỹ Quý Tây",
						type: "Xã",
					},
					{
						level3_id: "27910",
						name: "Xã Mỹ Thạnh Tây",
						type: "Xã",
					},
					{
						level3_id: "27913",
						name: "Xã Mỹ Thạnh Đông",
						type: "Xã",
					},
					{
						level3_id: "27916",
						name: "Xã Bình Thành",
						type: "Xã",
					},
					{
						level3_id: "27919",
						name: "Xã Bình Hòa Bắc",
						type: "Xã",
					},
					{
						level3_id: "27922",
						name: "Xã Bình Hòa Hưng",
						type: "Xã",
					},
					{
						level3_id: "27925",
						name: "Xã Bình Hòa Nam",
						type: "Xã",
					},
					{
						level3_id: "27928",
						name: "Xã Mỹ Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "802",
				name: "Huyện Đức Hòa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27931",
						name: "Thị trấn Hậu Nghĩa",
						type: "Thị trấn",
					},
					{
						level3_id: "27934",
						name: "Thị trấn Hiệp Hòa",
						type: "Thị trấn",
					},
					{
						level3_id: "27937",
						name: "Thị trấn Đức Hòa",
						type: "Thị trấn",
					},
					{
						level3_id: "27940",
						name: "Xã Lộc Giang",
						type: "Xã",
					},
					{
						level3_id: "27943",
						name: "Xã An Ninh Đông",
						type: "Xã",
					},
					{
						level3_id: "27946",
						name: "Xã An Ninh Tây",
						type: "Xã",
					},
					{
						level3_id: "27949",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "27952",
						name: "Xã Hiệp Hòa",
						type: "Xã",
					},
					{
						level3_id: "27955",
						name: "Xã Đức Lập Thượng",
						type: "Xã",
					},
					{
						level3_id: "27958",
						name: "Xã Đức Lập Hạ",
						type: "Xã",
					},
					{
						level3_id: "27961",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "27964",
						name: "Xã Mỹ Hạnh Bắc",
						type: "Xã",
					},
					{
						level3_id: "27967",
						name: "Xã Đức Hòa Thượng",
						type: "Xã",
					},
					{
						level3_id: "27970",
						name: "Xã Hòa Khánh Tây",
						type: "Xã",
					},
					{
						level3_id: "27973",
						name: "Xã Hòa Khánh Đông",
						type: "Xã",
					},
					{
						level3_id: "27976",
						name: "Xã Mỹ Hạnh Nam",
						type: "Xã",
					},
					{
						level3_id: "27979",
						name: "Xã Hòa Khánh Nam",
						type: "Xã",
					},
					{
						level3_id: "27982",
						name: "Xã Đức Hòa Đông",
						type: "Xã",
					},
					{
						level3_id: "27985",
						name: "Xã Đức Hòa Hạ",
						type: "Xã",
					},
					{
						level3_id: "27988",
						name: "Xã Hựu Thạnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "803",
				name: "Huyện Bến Lức",
				type: "Huyện",
				level3s: [
					{
						level3_id: "27991",
						name: "Thị trấn Bến Lức",
						type: "Thị trấn",
					},
					{
						level3_id: "27994",
						name: "Xã Thạnh Lợi",
						type: "Xã",
					},
					{
						level3_id: "27997",
						name: "Xã Lương Bình",
						type: "Xã",
					},
					{
						level3_id: "28000",
						name: "Xã Thạnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "28003",
						name: "Xã Lương Hòa",
						type: "Xã",
					},
					{
						level3_id: "28006",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "28009",
						name: "Xã Tân Bửu",
						type: "Xã",
					},
					{
						level3_id: "28012",
						name: "Xã An Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28015",
						name: "Xã Bình Đức",
						type: "Xã",
					},
					{
						level3_id: "28018",
						name: "Xã Mỹ Yên",
						type: "Xã",
					},
					{
						level3_id: "28021",
						name: "Xã Thanh Phú",
						type: "Xã",
					},
					{
						level3_id: "28024",
						name: "Xã Long Hiệp",
						type: "Xã",
					},
					{
						level3_id: "28027",
						name: "Xã Thạnh Đức",
						type: "Xã",
					},
					{
						level3_id: "28030",
						name: "Xã Phước Lợi",
						type: "Xã",
					},
					{
						level3_id: "28033",
						name: "Xã Nhựt Chánh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "804",
				name: "Huyện Thủ Thừa",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28036",
						name: "Thị trấn Thủ Thừa",
						type: "Thị trấn",
					},
					{
						level3_id: "28039",
						name: "Xã Long Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28042",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "28045",
						name: "Xã Long Thuận",
						type: "Xã",
					},
					{
						level3_id: "28048",
						name: "Xã Mỹ Lạc",
						type: "Xã",
					},
					{
						level3_id: "28051",
						name: "Xã Mỹ Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28054",
						name: "Xã Bình An",
						type: "Xã",
					},
					{
						level3_id: "28057",
						name: "Xã Nhị Thành",
						type: "Xã",
					},
					{
						level3_id: "28060",
						name: "Xã Mỹ An",
						type: "Xã",
					},
					{
						level3_id: "28063",
						name: "Xã Bình Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28066",
						name: "Xã Mỹ Phú",
						type: "Xã",
					},
					{
						level3_id: "28072",
						name: "Xã Tân Long",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "805",
				name: "Huyện Tân Trụ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28075",
						name: "Thị trấn Tân Trụ",
						type: "Thị trấn",
					},
					{
						level3_id: "28078",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "28084",
						name: "Xã Quê Mỹ Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28087",
						name: "Xã Lạc Tấn",
						type: "Xã",
					},
					{
						level3_id: "28090",
						name: "Xã Bình Trinh Đông",
						type: "Xã",
					},
					{
						level3_id: "28093",
						name: "Xã Tân Phước Tây",
						type: "Xã",
					},
					{
						level3_id: "28096",
						name: "Xã Bình Lãng",
						type: "Xã",
					},
					{
						level3_id: "28099",
						name: "Xã Bình Tịnh",
						type: "Xã",
					},
					{
						level3_id: "28102",
						name: "Xã Đức Tân",
						type: "Xã",
					},
					{
						level3_id: "28105",
						name: "Xã Nhựt Ninh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "806",
				name: "Huyện Cần Đước",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28108",
						name: "Thị trấn Cần Đước",
						type: "Thị trấn",
					},
					{
						level3_id: "28111",
						name: "Xã Long Trạch",
						type: "Xã",
					},
					{
						level3_id: "28114",
						name: "Xã Long Khê",
						type: "Xã",
					},
					{
						level3_id: "28117",
						name: "Xã Long Định",
						type: "Xã",
					},
					{
						level3_id: "28120",
						name: "Xã Phước Vân",
						type: "Xã",
					},
					{
						level3_id: "28123",
						name: "Xã Long Hòa",
						type: "Xã",
					},
					{
						level3_id: "28126",
						name: "Xã Long Cang",
						type: "Xã",
					},
					{
						level3_id: "28129",
						name: "Xã Long Sơn",
						type: "Xã",
					},
					{
						level3_id: "28132",
						name: "Xã Tân Trạch",
						type: "Xã",
					},
					{
						level3_id: "28135",
						name: "Xã Mỹ Lệ",
						type: "Xã",
					},
					{
						level3_id: "28138",
						name: "Xã Tân Lân",
						type: "Xã",
					},
					{
						level3_id: "28141",
						name: "Xã Phước Tuy",
						type: "Xã",
					},
					{
						level3_id: "28144",
						name: "Xã Long Hựu Đông",
						type: "Xã",
					},
					{
						level3_id: "28147",
						name: "Xã Tân Ân",
						type: "Xã",
					},
					{
						level3_id: "28150",
						name: "Xã Phước Đông",
						type: "Xã",
					},
					{
						level3_id: "28153",
						name: "Xã Long Hựu Tây",
						type: "Xã",
					},
					{
						level3_id: "28156",
						name: "Xã Tân Chánh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "807",
				name: "Huyện Cần Giuộc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28159",
						name: "Thị trấn Cần Giuộc",
						type: "Thị trấn",
					},
					{
						level3_id: "28162",
						name: "Xã Phước Lý",
						type: "Xã",
					},
					{
						level3_id: "28165",
						name: "Xã Long Thượng",
						type: "Xã",
					},
					{
						level3_id: "28168",
						name: "Xã Long Hậu",
						type: "Xã",
					},
					{
						level3_id: "28174",
						name: "Xã Phước Hậu",
						type: "Xã",
					},
					{
						level3_id: "28177",
						name: "Xã Mỹ Lộc",
						type: "Xã",
					},
					{
						level3_id: "28180",
						name: "Xã Phước Lại",
						type: "Xã",
					},
					{
						level3_id: "28183",
						name: "Xã Phước Lâm",
						type: "Xã",
					},
					{
						level3_id: "28189",
						name: "Xã Thuận Thành",
						type: "Xã",
					},
					{
						level3_id: "28192",
						name: "Xã Phước Vĩnh Tây",
						type: "Xã",
					},
					{
						level3_id: "28195",
						name: "Xã Phước Vĩnh Đông",
						type: "Xã",
					},
					{
						level3_id: "28198",
						name: "Xã Long An",
						type: "Xã",
					},
					{
						level3_id: "28201",
						name: "Xã Long Phụng",
						type: "Xã",
					},
					{
						level3_id: "28204",
						name: "Xã Đông Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28207",
						name: "Xã Tân Tập",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "808",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28210",
						name: "Thị trấn Tầm Vu",
						type: "Thị trấn",
					},
					{
						level3_id: "28213",
						name: "Xã Bình Quới",
						type: "Xã",
					},
					{
						level3_id: "28216",
						name: "Xã Hòa Phú",
						type: "Xã",
					},
					{
						level3_id: "28219",
						name: "Xã Phú Ngãi Trị",
						type: "Xã",
					},
					{
						level3_id: "28222",
						name: "Xã Vĩnh Công",
						type: "Xã",
					},
					{
						level3_id: "28225",
						name: "Xã Thuận Mỹ",
						type: "Xã",
					},
					{
						level3_id: "28228",
						name: "Xã Hiệp Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28231",
						name: "Xã Phước Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "28234",
						name: "Xã Thanh Phú Long",
						type: "Xã",
					},
					{
						level3_id: "28237",
						name: "Xã Dương Xuân Hội",
						type: "Xã",
					},
					{
						level3_id: "28240",
						name: "Xã An Lục Long",
						type: "Xã",
					},
					{
						level3_id: "28243",
						name: "Xã Long Trì",
						type: "Xã",
					},
					{
						level3_id: "28246",
						name: "Xã Thanh Vĩnh Đông",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "82",
		name: "Tỉnh Tiền Giang",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "815",
				name: "Thành phố Mỹ Tho",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "28249",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "28252",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "28255",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "28258",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "28261",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "28264",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "28267",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "28270",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "28273",
						name: "Phường 9",
						type: "Phường",
					},
					{
						level3_id: "28276",
						name: "Phường 10",
						type: "Phường",
					},
					{
						level3_id: "28279",
						name: "Phường Tân Long",
						type: "Phường",
					},
					{
						level3_id: "28282",
						name: "Xã Đạo Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28285",
						name: "Xã Trung An",
						type: "Xã",
					},
					{
						level3_id: "28288",
						name: "Xã Mỹ Phong",
						type: "Xã",
					},
					{
						level3_id: "28291",
						name: "Xã Tân Mỹ Chánh",
						type: "Xã",
					},
					{
						level3_id: "28567",
						name: "Xã Phước Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28591",
						name: "Xã Thới Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "816",
				name: "Thị xã Gò Công",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "28294",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "28297",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "28300",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "28303",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "28306",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "28309",
						name: "Xã Long Hưng",
						type: "Xã",
					},
					{
						level3_id: "28312",
						name: "Xã Long Thuận",
						type: "Xã",
					},
					{
						level3_id: "28315",
						name: "Xã Long Chánh",
						type: "Xã",
					},
					{
						level3_id: "28318",
						name: "Xã Long Hòa",
						type: "Xã",
					},
					{
						level3_id: "28708",
						name: "Xã Bình Đông",
						type: "Xã",
					},
					{
						level3_id: "28717",
						name: "Xã Bình Xuân",
						type: "Xã",
					},
					{
						level3_id: "28729",
						name: "Xã Tân Trung",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "817",
				name: "Thị xã Cai Lậy",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "28435",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "28436",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "28437",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "28439",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "28440",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "28447",
						name: "Xã Mỹ Phước Tây",
						type: "Xã",
					},
					{
						level3_id: "28450",
						name: "Xã Mỹ Hạnh Đông",
						type: "Xã",
					},
					{
						level3_id: "28453",
						name: "Xã Mỹ Hạnh Trung",
						type: "Xã",
					},
					{
						level3_id: "28459",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "28462",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "28468",
						name: "Xã Tân Hội",
						type: "Xã",
					},
					{
						level3_id: "28474",
						name: "Phường Nhị Mỹ",
						type: "Phường",
					},
					{
						level3_id: "28477",
						name: "Xã Nhị Quý",
						type: "Xã",
					},
					{
						level3_id: "28480",
						name: "Xã Thanh Hòa",
						type: "Xã",
					},
					{
						level3_id: "28483",
						name: "Xã Phú Quý",
						type: "Xã",
					},
					{
						level3_id: "28486",
						name: "Xã Long Khánh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "818",
				name: "Huyện Tân Phước",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28321",
						name: "Thị trấn Mỹ Phước",
						type: "Thị trấn",
					},
					{
						level3_id: "28324",
						name: "Xã Tân Hòa Đông",
						type: "Xã",
					},
					{
						level3_id: "28327",
						name: "Xã Thạnh Tân",
						type: "Xã",
					},
					{
						level3_id: "28330",
						name: "Xã Thạnh Mỹ",
						type: "Xã",
					},
					{
						level3_id: "28333",
						name: "Xã Thạnh Hoà",
						type: "Xã",
					},
					{
						level3_id: "28336",
						name: "Xã Phú Mỹ",
						type: "Xã",
					},
					{
						level3_id: "28339",
						name: "Xã Tân Hòa Thành",
						type: "Xã",
					},
					{
						level3_id: "28342",
						name: "Xã Hưng Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28345",
						name: "Xã Tân Lập 1",
						type: "Xã",
					},
					{
						level3_id: "28348",
						name: "Xã Tân Hòa Tây",
						type: "Xã",
					},
					{
						level3_id: "28354",
						name: "Xã Tân Lập 2",
						type: "Xã",
					},
					{
						level3_id: "28357",
						name: "Xã Phước Lập",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "819",
				name: "Huyện Cái Bè",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28360",
						name: "Thị trấn Cái Bè",
						type: "Thị trấn",
					},
					{
						level3_id: "28363",
						name: "Xã Hậu Mỹ Bắc B",
						type: "Xã",
					},
					{
						level3_id: "28366",
						name: "Xã Hậu Mỹ Bắc A",
						type: "Xã",
					},
					{
						level3_id: "28369",
						name: "Xã Mỹ Trung",
						type: "Xã",
					},
					{
						level3_id: "28372",
						name: "Xã Hậu Mỹ Trinh",
						type: "Xã",
					},
					{
						level3_id: "28375",
						name: "Xã Hậu Mỹ Phú",
						type: "Xã",
					},
					{
						level3_id: "28378",
						name: "Xã Mỹ Tân",
						type: "Xã",
					},
					{
						level3_id: "28381",
						name: "Xã Mỹ Lợi B",
						type: "Xã",
					},
					{
						level3_id: "28384",
						name: "Xã Thiện Trung",
						type: "Xã",
					},
					{
						level3_id: "28387",
						name: "Xã Mỹ Hội",
						type: "Xã",
					},
					{
						level3_id: "28390",
						name: "Xã An Cư",
						type: "Xã",
					},
					{
						level3_id: "28393",
						name: "Xã Hậu Thành",
						type: "Xã",
					},
					{
						level3_id: "28396",
						name: "Xã Mỹ Lợi A",
						type: "Xã",
					},
					{
						level3_id: "28399",
						name: "Xã Hòa Khánh",
						type: "Xã",
					},
					{
						level3_id: "28402",
						name: "Xã Thiện Trí",
						type: "Xã",
					},
					{
						level3_id: "28405",
						name: "Xã Mỹ Đức Đông",
						type: "Xã",
					},
					{
						level3_id: "28408",
						name: "Xã Mỹ Đức Tây",
						type: "Xã",
					},
					{
						level3_id: "28411",
						name: "Xã Đông Hòa Hiệp",
						type: "Xã",
					},
					{
						level3_id: "28414",
						name: "Xã An Thái Đông",
						type: "Xã",
					},
					{
						level3_id: "28417",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "28420",
						name: "Xã Mỹ Lương",
						type: "Xã",
					},
					{
						level3_id: "28423",
						name: "Xã Tân Thanh",
						type: "Xã",
					},
					{
						level3_id: "28426",
						name: "Xã An Thái Trung",
						type: "Xã",
					},
					{
						level3_id: "28429",
						name: "Xã An Hữu",
						type: "Xã",
					},
					{
						level3_id: "28432",
						name: "Xã Hòa Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "820",
				name: "Huyện Cai Lậy",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28438",
						name: "Xã Thạnh Lộc",
						type: "Xã",
					},
					{
						level3_id: "28441",
						name: "Xã Mỹ Thành Bắc",
						type: "Xã",
					},
					{
						level3_id: "28444",
						name: "Xã Phú Cường",
						type: "Xã",
					},
					{
						level3_id: "28456",
						name: "Xã Mỹ Thành Nam",
						type: "Xã",
					},
					{
						level3_id: "28465",
						name: "Xã Phú Nhuận",
						type: "Xã",
					},
					{
						level3_id: "28471",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "28489",
						name: "Xã Cẩm Sơn",
						type: "Xã",
					},
					{
						level3_id: "28492",
						name: "Xã Phú An",
						type: "Xã",
					},
					{
						level3_id: "28495",
						name: "Xã Mỹ Long",
						type: "Xã",
					},
					{
						level3_id: "28498",
						name: "Xã Long Tiên",
						type: "Xã",
					},
					{
						level3_id: "28501",
						name: "Xã Hiệp Đức",
						type: "Xã",
					},
					{
						level3_id: "28504",
						name: "Xã Long Trung",
						type: "Xã",
					},
					{
						level3_id: "28507",
						name: "Xã Hội Xuân",
						type: "Xã",
					},
					{
						level3_id: "28510",
						name: "Xã Tân Phong",
						type: "Xã",
					},
					{
						level3_id: "28513",
						name: "Xã Tam Bình",
						type: "Xã",
					},
					{
						level3_id: "28516",
						name: "Xã Ngũ Hiệp",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "821",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28519",
						name: "Thị trấn Tân Hiệp",
						type: "Thị trấn",
					},
					{
						level3_id: "28522",
						name: "Xã Tân Hội Đông",
						type: "Xã",
					},
					{
						level3_id: "28525",
						name: "Xã Tân Hương",
						type: "Xã",
					},
					{
						level3_id: "28528",
						name: "Xã Tân Lý Đông",
						type: "Xã",
					},
					{
						level3_id: "28531",
						name: "Xã Tân Lý Tây",
						type: "Xã",
					},
					{
						level3_id: "28534",
						name: "Xã Thân Cửu Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "28537",
						name: "Xã Tam Hiệp",
						type: "Xã",
					},
					{
						level3_id: "28540",
						name: "Xã Điềm Hy",
						type: "Xã",
					},
					{
						level3_id: "28543",
						name: "Xã Nhị Bình",
						type: "Xã",
					},
					{
						level3_id: "28546",
						name: "Xã Dưỡng Điềm",
						type: "Xã",
					},
					{
						level3_id: "28549",
						name: "Xã Đông Hòa",
						type: "Xã",
					},
					{
						level3_id: "28552",
						name: "Xã Long Định",
						type: "Xã",
					},
					{
						level3_id: "28555",
						name: "Xã Hữu Đạo",
						type: "Xã",
					},
					{
						level3_id: "28558",
						name: "Xã Long An",
						type: "Xã",
					},
					{
						level3_id: "28561",
						name: "Xã Long Hưng",
						type: "Xã",
					},
					{
						level3_id: "28564",
						name: "Xã Bình Trưng",
						type: "Xã",
					},
					{
						level3_id: "28570",
						name: "Xã Thạnh Phú",
						type: "Xã",
					},
					{
						level3_id: "28573",
						name: "Xã Bàn Long",
						type: "Xã",
					},
					{
						level3_id: "28576",
						name: "Xã Vĩnh Kim",
						type: "Xã",
					},
					{
						level3_id: "28579",
						name: "Xã Bình Đức",
						type: "Xã",
					},
					{
						level3_id: "28582",
						name: "Xã Song Thuận",
						type: "Xã",
					},
					{
						level3_id: "28585",
						name: "Xã Kim Sơn",
						type: "Xã",
					},
					{
						level3_id: "28588",
						name: "Xã Phú Phong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "822",
				name: "Huyện Chợ Gạo",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28594",
						name: "Thị trấn Chợ Gạo",
						type: "Thị trấn",
					},
					{
						level3_id: "28597",
						name: "Xã Trung Hòa",
						type: "Xã",
					},
					{
						level3_id: "28600",
						name: "Xã Hòa Tịnh",
						type: "Xã",
					},
					{
						level3_id: "28603",
						name: "Xã Mỹ Tịnh An",
						type: "Xã",
					},
					{
						level3_id: "28606",
						name: "Xã Tân Bình Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28609",
						name: "Xã Phú Kiết",
						type: "Xã",
					},
					{
						level3_id: "28612",
						name: "Xã Lương Hòa Lạc",
						type: "Xã",
					},
					{
						level3_id: "28615",
						name: "Xã Thanh Bình",
						type: "Xã",
					},
					{
						level3_id: "28618",
						name: "Xã Quơn Long",
						type: "Xã",
					},
					{
						level3_id: "28621",
						name: "Xã Bình Phục Nhứt",
						type: "Xã",
					},
					{
						level3_id: "28624",
						name: "Xã Đăng Hưng Phước",
						type: "Xã",
					},
					{
						level3_id: "28627",
						name: "Xã Tân Thuận Bình",
						type: "Xã",
					},
					{
						level3_id: "28630",
						name: "Xã Song Bình",
						type: "Xã",
					},
					{
						level3_id: "28633",
						name: "Xã Bình Phan",
						type: "Xã",
					},
					{
						level3_id: "28636",
						name: "Xã Long Bình Điền",
						type: "Xã",
					},
					{
						level3_id: "28639",
						name: "Xã An Thạnh Thủy",
						type: "Xã",
					},
					{
						level3_id: "28642",
						name: "Xã Xuân Đông",
						type: "Xã",
					},
					{
						level3_id: "28645",
						name: "Xã Hòa Định",
						type: "Xã",
					},
					{
						level3_id: "28648",
						name: "Xã Bình Ninh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "823",
				name: "Huyện Gò Công Tây",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28651",
						name: "Thị trấn Vĩnh Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "28654",
						name: "Xã Đồng Sơn",
						type: "Xã",
					},
					{
						level3_id: "28657",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "28660",
						name: "Xã Đồng Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28663",
						name: "Xã Thành Công",
						type: "Xã",
					},
					{
						level3_id: "28666",
						name: "Xã Bình Nhì",
						type: "Xã",
					},
					{
						level3_id: "28669",
						name: "Xã Yên Luông",
						type: "Xã",
					},
					{
						level3_id: "28672",
						name: "Xã Thạnh Trị",
						type: "Xã",
					},
					{
						level3_id: "28675",
						name: "Xã Thạnh Nhựt",
						type: "Xã",
					},
					{
						level3_id: "28678",
						name: "Xã Long Vĩnh",
						type: "Xã",
					},
					{
						level3_id: "28681",
						name: "Xã Bình Tân",
						type: "Xã",
					},
					{
						level3_id: "28684",
						name: "Xã Vĩnh Hựu",
						type: "Xã",
					},
					{
						level3_id: "28687",
						name: "Xã Long Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "824",
				name: "Huyện Gò Công Đông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28702",
						name: "Thị trấn Tân Hòa",
						type: "Thị trấn",
					},
					{
						level3_id: "28705",
						name: "Xã Tăng Hoà",
						type: "Xã",
					},
					{
						level3_id: "28711",
						name: "Xã Tân Phước",
						type: "Xã",
					},
					{
						level3_id: "28714",
						name: "Xã Gia Thuận",
						type: "Xã",
					},
					{
						level3_id: "28720",
						name: "Thị trấn Vàm Láng",
						type: "Thị trấn",
					},
					{
						level3_id: "28723",
						name: "Xã Tân Tây",
						type: "Xã",
					},
					{
						level3_id: "28726",
						name: "Xã Kiểng Phước",
						type: "Xã",
					},
					{
						level3_id: "28732",
						name: "Xã Tân Đông",
						type: "Xã",
					},
					{
						level3_id: "28735",
						name: "Xã Bình Ân",
						type: "Xã",
					},
					{
						level3_id: "28738",
						name: "Xã Tân Điền",
						type: "Xã",
					},
					{
						level3_id: "28741",
						name: "Xã Bình Nghị",
						type: "Xã",
					},
					{
						level3_id: "28744",
						name: "Xã Phước Trung",
						type: "Xã",
					},
					{
						level3_id: "28747",
						name: "Xã Tân Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "825",
				name: "Huyện Tân Phú Đông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28690",
						name: "Xã Tân Thới",
						type: "Xã",
					},
					{
						level3_id: "28693",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "28696",
						name: "Xã Phú Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28699",
						name: "Xã Tân Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28750",
						name: "Xã Phú Đông",
						type: "Xã",
					},
					{
						level3_id: "28753",
						name: "Xã Phú Tân",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "83",
		name: "Tỉnh Bến Tre",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "829",
				name: "Thành phố Bến Tre",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "28756",
						name: "Phường Phú Khương",
						type: "Phường",
					},
					{
						level3_id: "28757",
						name: "Phường Phú Tân",
						type: "Phường",
					},
					{
						level3_id: "28759",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "28762",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "28765",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "28768",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "28777",
						name: "Phường An Hội",
						type: "Phường",
					},
					{
						level3_id: "28780",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "28783",
						name: "Xã Sơn Đông",
						type: "Xã",
					},
					{
						level3_id: "28786",
						name: "Xã Phú Hưng",
						type: "Xã",
					},
					{
						level3_id: "28789",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "28792",
						name: "Xã Mỹ Thạnh An",
						type: "Xã",
					},
					{
						level3_id: "28795",
						name: "Xã Nhơn Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28798",
						name: "Xã Phú Nhuận",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "831",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28801",
						name: "Thị trấn Châu Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "28804",
						name: "Xã Tân Thạch",
						type: "Xã",
					},
					{
						level3_id: "28807",
						name: "Xã Qưới Sơn",
						type: "Xã",
					},
					{
						level3_id: "28810",
						name: "Xã An Khánh",
						type: "Xã",
					},
					{
						level3_id: "28813",
						name: "Xã Giao Long",
						type: "Xã",
					},
					{
						level3_id: "28819",
						name: "Xã Phú Túc",
						type: "Xã",
					},
					{
						level3_id: "28822",
						name: "Xã Phú Đức",
						type: "Xã",
					},
					{
						level3_id: "28825",
						name: "Xã Phú An Hòa",
						type: "Xã",
					},
					{
						level3_id: "28828",
						name: "Xã An Phước",
						type: "Xã",
					},
					{
						level3_id: "28831",
						name: "Xã Tam Phước",
						type: "Xã",
					},
					{
						level3_id: "28834",
						name: "Xã Thành Triệu",
						type: "Xã",
					},
					{
						level3_id: "28837",
						name: "Xã Tường Đa",
						type: "Xã",
					},
					{
						level3_id: "28840",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "28843",
						name: "Xã Quới Thành",
						type: "Xã",
					},
					{
						level3_id: "28846",
						name: "Xã Phước Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28849",
						name: "Xã An Hóa",
						type: "Xã",
					},
					{
						level3_id: "28852",
						name: "Xã Tiên Long",
						type: "Xã",
					},
					{
						level3_id: "28855",
						name: "Xã An Hiệp",
						type: "Xã",
					},
					{
						level3_id: "28858",
						name: "Xã Hữu Định",
						type: "Xã",
					},
					{
						level3_id: "28861",
						name: "Xã Tiên Thủy",
						type: "Xã",
					},
					{
						level3_id: "28864",
						name: "Xã Sơn Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "832",
				name: "Huyện Chợ Lách",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28870",
						name: "Thị trấn Chợ Lách",
						type: "Thị trấn",
					},
					{
						level3_id: "28873",
						name: "Xã Phú Phụng",
						type: "Xã",
					},
					{
						level3_id: "28876",
						name: "Xã Sơn Định",
						type: "Xã",
					},
					{
						level3_id: "28879",
						name: "Xã Vĩnh Bình",
						type: "Xã",
					},
					{
						level3_id: "28882",
						name: "Xã Hòa Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "28885",
						name: "Xã Long Thới",
						type: "Xã",
					},
					{
						level3_id: "28888",
						name: "Xã Phú Sơn",
						type: "Xã",
					},
					{
						level3_id: "28891",
						name: "Xã Tân Thiềng",
						type: "Xã",
					},
					{
						level3_id: "28894",
						name: "Xã Vĩnh Thành",
						type: "Xã",
					},
					{
						level3_id: "28897",
						name: "Xã Vĩnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "28900",
						name: "Xã Hưng Khánh Trung B",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "833",
				name: "Huyện Mỏ Cày Nam",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28903",
						name: "Thị trấn Mỏ Cày",
						type: "Thị trấn",
					},
					{
						level3_id: "28930",
						name: "Xã Định Thủy",
						type: "Xã",
					},
					{
						level3_id: "28939",
						name: "Xã Đa Phước Hội",
						type: "Xã",
					},
					{
						level3_id: "28940",
						name: "Xã Tân Hội",
						type: "Xã",
					},
					{
						level3_id: "28942",
						name: "Xã Phước Hiệp",
						type: "Xã",
					},
					{
						level3_id: "28945",
						name: "Xã Bình Khánh ",
						type: "Xã",
					},
					{
						level3_id: "28951",
						name: "Xã An Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28957",
						name: "Xã An Định",
						type: "Xã",
					},
					{
						level3_id: "28960",
						name: "Xã Thành Thới B",
						type: "Xã",
					},
					{
						level3_id: "28963",
						name: "Xã Tân Trung",
						type: "Xã",
					},
					{
						level3_id: "28966",
						name: "Xã An Thới",
						type: "Xã",
					},
					{
						level3_id: "28969",
						name: "Xã Thành Thới A",
						type: "Xã",
					},
					{
						level3_id: "28972",
						name: "Xã Minh Đức",
						type: "Xã",
					},
					{
						level3_id: "28975",
						name: "Xã Ngãi Đăng",
						type: "Xã",
					},
					{
						level3_id: "28978",
						name: "Xã Cẩm Sơn",
						type: "Xã",
					},
					{
						level3_id: "28981",
						name: "Xã Hương Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "834",
				name: "Huyện Giồng Trôm",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28984",
						name: "Thị trấn Giồng Trôm",
						type: "Thị trấn",
					},
					{
						level3_id: "28987",
						name: "Xã Phong Nẫm",
						type: "Xã",
					},
					{
						level3_id: "28993",
						name: "Xã Mỹ Thạnh",
						type: "Xã",
					},
					{
						level3_id: "28996",
						name: "Xã Châu Hòa",
						type: "Xã",
					},
					{
						level3_id: "28999",
						name: "Xã Lương Hòa",
						type: "Xã",
					},
					{
						level3_id: "29002",
						name: "Xã Lương Quới",
						type: "Xã",
					},
					{
						level3_id: "29005",
						name: "Xã Lương Phú",
						type: "Xã",
					},
					{
						level3_id: "29008",
						name: "Xã Châu Bình",
						type: "Xã",
					},
					{
						level3_id: "29011",
						name: "Xã Thuận Điền",
						type: "Xã",
					},
					{
						level3_id: "29014",
						name: "Xã Sơn Phú",
						type: "Xã",
					},
					{
						level3_id: "29017",
						name: "Xã Bình Hoà",
						type: "Xã",
					},
					{
						level3_id: "29020",
						name: "Xã Phước Long",
						type: "Xã",
					},
					{
						level3_id: "29023",
						name: "Xã Hưng Phong",
						type: "Xã",
					},
					{
						level3_id: "29026",
						name: "Xã Long Mỹ",
						type: "Xã",
					},
					{
						level3_id: "29029",
						name: "Xã Tân Hào",
						type: "Xã",
					},
					{
						level3_id: "29032",
						name: "Xã Bình Thành",
						type: "Xã",
					},
					{
						level3_id: "29035",
						name: "Xã Tân Thanh",
						type: "Xã",
					},
					{
						level3_id: "29038",
						name: "Xã Tân Lợi Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29041",
						name: "Xã Thạnh Phú Đông",
						type: "Xã",
					},
					{
						level3_id: "29044",
						name: "Xã Hưng Nhượng",
						type: "Xã",
					},
					{
						level3_id: "29047",
						name: "Xã Hưng Lễ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "835",
				name: "Huyện Bình Đại",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29050",
						name: "Thị trấn Bình Đại",
						type: "Thị trấn",
					},
					{
						level3_id: "29053",
						name: "Xã Tam Hiệp",
						type: "Xã",
					},
					{
						level3_id: "29056",
						name: "Xã Long Định",
						type: "Xã",
					},
					{
						level3_id: "29059",
						name: "Xã Long Hòa",
						type: "Xã",
					},
					{
						level3_id: "29062",
						name: "Xã Phú Thuận",
						type: "Xã",
					},
					{
						level3_id: "29065",
						name: "Xã Vang Quới Tây",
						type: "Xã",
					},
					{
						level3_id: "29068",
						name: "Xã Vang Quới Đông",
						type: "Xã",
					},
					{
						level3_id: "29071",
						name: "Xã Châu Hưng",
						type: "Xã",
					},
					{
						level3_id: "29074",
						name: "Xã Phú Vang",
						type: "Xã",
					},
					{
						level3_id: "29077",
						name: "Xã Lộc Thuận",
						type: "Xã",
					},
					{
						level3_id: "29080",
						name: "Xã Định Trung",
						type: "Xã",
					},
					{
						level3_id: "29083",
						name: "Xã Thới Lai",
						type: "Xã",
					},
					{
						level3_id: "29086",
						name: "Xã Bình Thới",
						type: "Xã",
					},
					{
						level3_id: "29089",
						name: "Xã Phú Long",
						type: "Xã",
					},
					{
						level3_id: "29092",
						name: "Xã Bình Thắng",
						type: "Xã",
					},
					{
						level3_id: "29095",
						name: "Xã Thạnh Trị",
						type: "Xã",
					},
					{
						level3_id: "29098",
						name: "Xã Đại Hòa Lộc",
						type: "Xã",
					},
					{
						level3_id: "29101",
						name: "Xã Thừa Đức",
						type: "Xã",
					},
					{
						level3_id: "29104",
						name: "Xã Thạnh Phước",
						type: "Xã",
					},
					{
						level3_id: "29107",
						name: "Xã Thới Thuận",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "836",
				name: "Huyện Ba Tri",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29110",
						name: "Thị trấn Ba Tri",
						type: "Thị trấn",
					},
					{
						level3_id: "29113",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "29116",
						name: "Xã Mỹ Hòa",
						type: "Xã",
					},
					{
						level3_id: "29119",
						name: "Xã Tân Xuân",
						type: "Xã",
					},
					{
						level3_id: "29122",
						name: "Xã Mỹ Chánh",
						type: "Xã",
					},
					{
						level3_id: "29125",
						name: "Xã Bảo Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29128",
						name: "Xã An Phú Trung",
						type: "Xã",
					},
					{
						level3_id: "29131",
						name: "Xã Mỹ Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29134",
						name: "Xã Mỹ Nhơn",
						type: "Xã",
					},
					{
						level3_id: "29137",
						name: "Xã Phước Ngãi",
						type: "Xã",
					},
					{
						level3_id: "29143",
						name: "Xã An Ngãi Trung",
						type: "Xã",
					},
					{
						level3_id: "29146",
						name: "Xã Phú Lễ",
						type: "Xã",
					},
					{
						level3_id: "29149",
						name: "Xã An Bình Tây",
						type: "Xã",
					},
					{
						level3_id: "29152",
						name: "Xã Bảo Thuận",
						type: "Xã",
					},
					{
						level3_id: "29155",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "29158",
						name: "Xã An Ngãi Tây",
						type: "Xã",
					},
					{
						level3_id: "29161",
						name: "Xã An Hiệp",
						type: "Xã",
					},
					{
						level3_id: "29164",
						name: "Xã Vĩnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "29167",
						name: "Xã Tân Thủy",
						type: "Xã",
					},
					{
						level3_id: "29170",
						name: "Xã Vĩnh An",
						type: "Xã",
					},
					{
						level3_id: "29173",
						name: "Xã An Đức",
						type: "Xã",
					},
					{
						level3_id: "29176",
						name: "Xã An Hòa Tây",
						type: "Xã",
					},
					{
						level3_id: "29179",
						name: "Xã An Thủy",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "837",
				name: "Huyện Thạnh Phú",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29182",
						name: "Thị trấn Thạnh Phú",
						type: "Thị trấn",
					},
					{
						level3_id: "29185",
						name: "Xã Phú Khánh",
						type: "Xã",
					},
					{
						level3_id: "29188",
						name: "Xã Đại Điền",
						type: "Xã",
					},
					{
						level3_id: "29191",
						name: "Xã Quới Điền",
						type: "Xã",
					},
					{
						level3_id: "29194",
						name: "Xã Tân Phong",
						type: "Xã",
					},
					{
						level3_id: "29197",
						name: "Xã Mỹ Hưng",
						type: "Xã",
					},
					{
						level3_id: "29200",
						name: "Xã An Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29203",
						name: "Xã Thới Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29206",
						name: "Xã Hòa Lợi",
						type: "Xã",
					},
					{
						level3_id: "29209",
						name: "Xã An Điền",
						type: "Xã",
					},
					{
						level3_id: "29212",
						name: "Xã Bình Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29215",
						name: "Xã An Thuận",
						type: "Xã",
					},
					{
						level3_id: "29218",
						name: "Xã An Quy",
						type: "Xã",
					},
					{
						level3_id: "29221",
						name: "Xã Thạnh Hải",
						type: "Xã",
					},
					{
						level3_id: "29224",
						name: "Xã An Nhơn",
						type: "Xã",
					},
					{
						level3_id: "29227",
						name: "Xã Giao Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29230",
						name: "Xã Thạnh Phong",
						type: "Xã",
					},
					{
						level3_id: "29233",
						name: "Xã Mỹ An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "838",
				name: "Huyện Mỏ Cày Bắc",
				type: "Huyện",
				level3s: [
					{
						level3_id: "28889",
						name: "Xã Phú Mỹ",
						type: "Xã",
					},
					{
						level3_id: "28901",
						name: "Xã Hưng Khánh Trung A",
						type: "Xã",
					},
					{
						level3_id: "28906",
						name: "Xã Thanh Tân",
						type: "Xã",
					},
					{
						level3_id: "28909",
						name: "Xã Thạnh Ngãi",
						type: "Xã",
					},
					{
						level3_id: "28912",
						name: "Xã Tân Phú Tây",
						type: "Xã",
					},
					{
						level3_id: "28915",
						name: "Xã Phước Mỹ Trung",
						type: "Xã",
					},
					{
						level3_id: "28918",
						name: "Xã Tân Thành Bình",
						type: "Xã",
					},
					{
						level3_id: "28921",
						name: "Xã Thành An",
						type: "Xã",
					},
					{
						level3_id: "28924",
						name: "Xã Hòa Lộc",
						type: "Xã",
					},
					{
						level3_id: "28927",
						name: "Xã Tân Thanh Tây",
						type: "Xã",
					},
					{
						level3_id: "28933",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "28936",
						name: "Xã Nhuận Phú Tân",
						type: "Xã",
					},
					{
						level3_id: "28948",
						name: "Xã Khánh Thạnh Tân",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "84",
		name: "Tỉnh Trà Vinh",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "842",
				name: "Thành phố Trà Vinh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "29236",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "29239",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "29242",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "29245",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "29248",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "29251",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "29254",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "29257",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "29260",
						name: "Phường 9",
						type: "Phường",
					},
					{
						level3_id: "29263",
						name: "Xã Long Đức",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "844",
				name: "Huyện Càng Long",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29266",
						name: "Thị trấn Càng Long",
						type: "Thị trấn",
					},
					{
						level3_id: "29269",
						name: "Xã Mỹ Cẩm",
						type: "Xã",
					},
					{
						level3_id: "29272",
						name: "Xã An Trường A",
						type: "Xã",
					},
					{
						level3_id: "29275",
						name: "Xã An Trường",
						type: "Xã",
					},
					{
						level3_id: "29278",
						name: "Xã Huyền Hội",
						type: "Xã",
					},
					{
						level3_id: "29281",
						name: "Xã Tân An",
						type: "Xã",
					},
					{
						level3_id: "29284",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "29287",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "29290",
						name: "Xã Phương Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29293",
						name: "Xã Đại Phúc",
						type: "Xã",
					},
					{
						level3_id: "29296",
						name: "Xã Đại Phước",
						type: "Xã",
					},
					{
						level3_id: "29299",
						name: "Xã Nhị Long Phú",
						type: "Xã",
					},
					{
						level3_id: "29302",
						name: "Xã Nhị Long",
						type: "Xã",
					},
					{
						level3_id: "29305",
						name: "Xã Đức Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "845",
				name: "Huyện Cầu Kè",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29308",
						name: "Thị trấn Cầu Kè",
						type: "Thị trấn",
					},
					{
						level3_id: "29311",
						name: "Xã Hòa Ân",
						type: "Xã",
					},
					{
						level3_id: "29314",
						name: "Xã Châu Điền",
						type: "Xã",
					},
					{
						level3_id: "29317",
						name: "Xã An Phú Tân",
						type: "Xã",
					},
					{
						level3_id: "29320",
						name: "Xã Hoà Tân",
						type: "Xã",
					},
					{
						level3_id: "29323",
						name: "Xã Ninh Thới",
						type: "Xã",
					},
					{
						level3_id: "29326",
						name: "Xã Phong Phú",
						type: "Xã",
					},
					{
						level3_id: "29329",
						name: "Xã Phong Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29332",
						name: "Xã Tam Ngãi",
						type: "Xã",
					},
					{
						level3_id: "29335",
						name: "Xã Thông Hòa",
						type: "Xã",
					},
					{
						level3_id: "29338",
						name: "Xã Thạnh Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "846",
				name: "Huyện Tiểu Cần",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29341",
						name: "Thị trấn Tiểu Cần",
						type: "Thị trấn",
					},
					{
						level3_id: "29344",
						name: "Thị trấn Cầu Quan",
						type: "Thị trấn",
					},
					{
						level3_id: "29347",
						name: "Xã Phú Cần",
						type: "Xã",
					},
					{
						level3_id: "29350",
						name: "Xã Hiếu Tử",
						type: "Xã",
					},
					{
						level3_id: "29353",
						name: "Xã Hiếu Trung",
						type: "Xã",
					},
					{
						level3_id: "29356",
						name: "Xã Long Thới",
						type: "Xã",
					},
					{
						level3_id: "29359",
						name: "Xã Hùng Hòa",
						type: "Xã",
					},
					{
						level3_id: "29362",
						name: "Xã Tân Hùng",
						type: "Xã",
					},
					{
						level3_id: "29365",
						name: "Xã Tập Ngãi",
						type: "Xã",
					},
					{
						level3_id: "29368",
						name: "Xã Ngãi Hùng",
						type: "Xã",
					},
					{
						level3_id: "29371",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "847",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29374",
						name: "Thị trấn Châu Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "29377",
						name: "Xã Đa Lộc",
						type: "Xã",
					},
					{
						level3_id: "29380",
						name: "Xã Mỹ Chánh",
						type: "Xã",
					},
					{
						level3_id: "29383",
						name: "Xã Thanh Mỹ",
						type: "Xã",
					},
					{
						level3_id: "29386",
						name: "Xã Lương Hoà A",
						type: "Xã",
					},
					{
						level3_id: "29389",
						name: "Xã Lương Hòa",
						type: "Xã",
					},
					{
						level3_id: "29392",
						name: "Xã Song Lộc",
						type: "Xã",
					},
					{
						level3_id: "29395",
						name: "Xã Nguyệt Hóa",
						type: "Xã",
					},
					{
						level3_id: "29398",
						name: "Xã Hòa Thuận",
						type: "Xã",
					},
					{
						level3_id: "29401",
						name: "Xã Hòa Lợi",
						type: "Xã",
					},
					{
						level3_id: "29404",
						name: "Xã Phước Hảo",
						type: "Xã",
					},
					{
						level3_id: "29407",
						name: "Xã Hưng Mỹ",
						type: "Xã",
					},
					{
						level3_id: "29410",
						name: "Xã Hòa Minh",
						type: "Xã",
					},
					{
						level3_id: "29413",
						name: "Xã Long Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "848",
				name: "Huyện Cầu Ngang",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29416",
						name: "Thị trấn Cầu Ngang",
						type: "Thị trấn",
					},
					{
						level3_id: "29419",
						name: "Thị trấn Mỹ Long",
						type: "Thị trấn",
					},
					{
						level3_id: "29422",
						name: "Xã Mỹ Long Bắc",
						type: "Xã",
					},
					{
						level3_id: "29425",
						name: "Xã Mỹ Long Nam",
						type: "Xã",
					},
					{
						level3_id: "29428",
						name: "Xã Mỹ Hòa",
						type: "Xã",
					},
					{
						level3_id: "29431",
						name: "Xã Vĩnh Kim",
						type: "Xã",
					},
					{
						level3_id: "29434",
						name: "Xã Kim Hòa",
						type: "Xã",
					},
					{
						level3_id: "29437",
						name: "Xã Hiệp Hòa",
						type: "Xã",
					},
					{
						level3_id: "29440",
						name: "Xã Thuận Hòa",
						type: "Xã",
					},
					{
						level3_id: "29443",
						name: "Xã Long Sơn",
						type: "Xã",
					},
					{
						level3_id: "29446",
						name: "Xã Nhị Trường",
						type: "Xã",
					},
					{
						level3_id: "29449",
						name: "Xã Trường Thọ",
						type: "Xã",
					},
					{
						level3_id: "29452",
						name: "Xã Hiệp Mỹ Đông",
						type: "Xã",
					},
					{
						level3_id: "29455",
						name: "Xã Hiệp Mỹ Tây",
						type: "Xã",
					},
					{
						level3_id: "29458",
						name: "Xã Thạnh Hòa Sơn",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "849",
				name: "Huyện Trà Cú",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29461",
						name: "Thị trấn Trà Cú",
						type: "Thị trấn",
					},
					{
						level3_id: "29462",
						name: "Thị trấn Định An",
						type: "Thị trấn",
					},
					{
						level3_id: "29464",
						name: "Xã Phước Hưng",
						type: "Xã",
					},
					{
						level3_id: "29467",
						name: "Xã Tập Sơn",
						type: "Xã",
					},
					{
						level3_id: "29470",
						name: "Xã Tân Sơn",
						type: "Xã",
					},
					{
						level3_id: "29473",
						name: "Xã An Quảng Hữu",
						type: "Xã",
					},
					{
						level3_id: "29476",
						name: "Xã Lưu Nghiệp Anh",
						type: "Xã",
					},
					{
						level3_id: "29479",
						name: "Xã Ngãi Xuyên",
						type: "Xã",
					},
					{
						level3_id: "29482",
						name: "Xã Kim Sơn",
						type: "Xã",
					},
					{
						level3_id: "29485",
						name: "Xã Thanh Sơn",
						type: "Xã",
					},
					{
						level3_id: "29488",
						name: "Xã Hàm Giang",
						type: "Xã",
					},
					{
						level3_id: "29489",
						name: "Xã Hàm Tân",
						type: "Xã",
					},
					{
						level3_id: "29491",
						name: "Xã Đại An",
						type: "Xã",
					},
					{
						level3_id: "29494",
						name: "Xã Định An",
						type: "Xã",
					},
					{
						level3_id: "29503",
						name: "Xã Ngọc Biên",
						type: "Xã",
					},
					{
						level3_id: "29506",
						name: "Xã Long Hiệp",
						type: "Xã",
					},
					{
						level3_id: "29509",
						name: "Xã Tân Hiệp",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "850",
				name: "Huyện Duyên Hải",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29497",
						name: "Xã Đôn Xuân",
						type: "Xã",
					},
					{
						level3_id: "29500",
						name: "Xã Đôn Châu",
						type: "Xã",
					},
					{
						level3_id: "29513",
						name: "Thị trấn Long Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "29521",
						name: "Xã Long Khánh",
						type: "Xã",
					},
					{
						level3_id: "29530",
						name: "Xã Ngũ Lạc",
						type: "Xã",
					},
					{
						level3_id: "29533",
						name: "Xã Long Vĩnh",
						type: "Xã",
					},
					{
						level3_id: "29536",
						name: "Xã Đông Hải",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "851",
				name: "Thị xã Duyên Hải",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "29512",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "29515",
						name: "Xã Long Toàn",
						type: "Xã",
					},
					{
						level3_id: "29516",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "29518",
						name: "Xã Long Hữu",
						type: "Xã",
					},
					{
						level3_id: "29524",
						name: "Xã Dân Thành",
						type: "Xã",
					},
					{
						level3_id: "29527",
						name: "Xã Trường Long Hòa",
						type: "Xã",
					},
					{
						level3_id: "29539",
						name: "Xã Hiệp Thạnh",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "86",
		name: "Tỉnh Vĩnh Long",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "855",
				name: "Thành phố Vĩnh Long",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "29542",
						name: "Phường 9",
						type: "Phường",
					},
					{
						level3_id: "29545",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "29548",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "29551",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "29554",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "29557",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "29560",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "29563",
						name: "Phường Tân Ngãi",
						type: "Phường",
					},
					{
						level3_id: "29566",
						name: "Phường Tân Hòa",
						type: "Phường",
					},
					{
						level3_id: "29569",
						name: "Phường Tân Hội",
						type: "Phường",
					},
					{
						level3_id: "29572",
						name: "Phường Trường An",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "857",
				name: "Huyện Long Hồ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29575",
						name: "Thị trấn Long Hồ",
						type: "Thị trấn",
					},
					{
						level3_id: "29578",
						name: "Xã Đồng Phú",
						type: "Xã",
					},
					{
						level3_id: "29581",
						name: "Xã Bình Hòa Phước",
						type: "Xã",
					},
					{
						level3_id: "29584",
						name: "Xã Hòa Ninh",
						type: "Xã",
					},
					{
						level3_id: "29587",
						name: "Xã An Bình",
						type: "Xã",
					},
					{
						level3_id: "29590",
						name: "Xã Thanh Đức",
						type: "Xã",
					},
					{
						level3_id: "29593",
						name: "Xã Tân Hạnh",
						type: "Xã",
					},
					{
						level3_id: "29596",
						name: "Xã Phước Hậu",
						type: "Xã",
					},
					{
						level3_id: "29599",
						name: "Xã Long Phước",
						type: "Xã",
					},
					{
						level3_id: "29602",
						name: "Xã Phú Đức",
						type: "Xã",
					},
					{
						level3_id: "29605",
						name: "Xã Lộc Hòa",
						type: "Xã",
					},
					{
						level3_id: "29608",
						name: "Xã Long An",
						type: "Xã",
					},
					{
						level3_id: "29611",
						name: "Xã Phú Quới",
						type: "Xã",
					},
					{
						level3_id: "29614",
						name: "Xã Thạnh Quới",
						type: "Xã",
					},
					{
						level3_id: "29617",
						name: "Xã Hòa Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "858",
				name: "Huyện Mang Thít",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29623",
						name: "Xã Mỹ An",
						type: "Xã",
					},
					{
						level3_id: "29626",
						name: "Xã Mỹ Phước",
						type: "Xã",
					},
					{
						level3_id: "29629",
						name: "Xã An Phước",
						type: "Xã",
					},
					{
						level3_id: "29632",
						name: "Xã Nhơn Phú",
						type: "Xã",
					},
					{
						level3_id: "29635",
						name: "Xã Long Mỹ",
						type: "Xã",
					},
					{
						level3_id: "29638",
						name: "Xã Hòa Tịnh",
						type: "Xã",
					},
					{
						level3_id: "29641",
						name: "Thị trấn Cái Nhum",
						type: "Thị trấn",
					},
					{
						level3_id: "29644",
						name: "Xã Bình Phước",
						type: "Xã",
					},
					{
						level3_id: "29647",
						name: "Xã Chánh An",
						type: "Xã",
					},
					{
						level3_id: "29650",
						name: "Xã Tân An Hội",
						type: "Xã",
					},
					{
						level3_id: "29653",
						name: "Xã Tân Long",
						type: "Xã",
					},
					{
						level3_id: "29656",
						name: "Xã Tân Long Hội",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "859",
				name: "Huyện  Vũng Liêm",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29659",
						name: "Thị trấn Vũng Liêm",
						type: "Thị trấn",
					},
					{
						level3_id: "29662",
						name: "Xã Tân Quới Trung",
						type: "Xã",
					},
					{
						level3_id: "29665",
						name: "Xã Quới Thiện",
						type: "Xã",
					},
					{
						level3_id: "29668",
						name: "Xã Quới An",
						type: "Xã",
					},
					{
						level3_id: "29671",
						name: "Xã Trung Chánh",
						type: "Xã",
					},
					{
						level3_id: "29674",
						name: "Xã Tân An Luông",
						type: "Xã",
					},
					{
						level3_id: "29677",
						name: "Xã Thanh Bình",
						type: "Xã",
					},
					{
						level3_id: "29680",
						name: "Xã Trung Thành Tây",
						type: "Xã",
					},
					{
						level3_id: "29683",
						name: "Xã Trung Hiệp",
						type: "Xã",
					},
					{
						level3_id: "29686",
						name: "Xã Hiếu Phụng",
						type: "Xã",
					},
					{
						level3_id: "29689",
						name: "Xã Trung Thành Đông",
						type: "Xã",
					},
					{
						level3_id: "29692",
						name: "Xã Trung Thành",
						type: "Xã",
					},
					{
						level3_id: "29695",
						name: "Xã Trung Hiếu",
						type: "Xã",
					},
					{
						level3_id: "29698",
						name: "Xã Trung Ngãi",
						type: "Xã",
					},
					{
						level3_id: "29701",
						name: "Xã Hiếu Thuận",
						type: "Xã",
					},
					{
						level3_id: "29704",
						name: "Xã Trung Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "29707",
						name: "Xã Trung An",
						type: "Xã",
					},
					{
						level3_id: "29710",
						name: "Xã Hiếu Nhơn",
						type: "Xã",
					},
					{
						level3_id: "29713",
						name: "Xã Hiếu Thành",
						type: "Xã",
					},
					{
						level3_id: "29716",
						name: "Xã Hiếu Nghĩa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "860",
				name: "Huyện Tam Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29719",
						name: "Thị trấn Tam Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "29722",
						name: "Xã Tân Lộc",
						type: "Xã",
					},
					{
						level3_id: "29725",
						name: "Xã Phú Thịnh",
						type: "Xã",
					},
					{
						level3_id: "29728",
						name: "Xã Hậu Lộc",
						type: "Xã",
					},
					{
						level3_id: "29731",
						name: "Xã Hòa Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29734",
						name: "Xã Hoà Lộc",
						type: "Xã",
					},
					{
						level3_id: "29737",
						name: "Xã Phú Lộc",
						type: "Xã",
					},
					{
						level3_id: "29740",
						name: "Xã Song Phú",
						type: "Xã",
					},
					{
						level3_id: "29743",
						name: "Xã Hòa Hiệp",
						type: "Xã",
					},
					{
						level3_id: "29746",
						name: "Xã Mỹ Lộc",
						type: "Xã",
					},
					{
						level3_id: "29749",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "29752",
						name: "Xã Long Phú",
						type: "Xã",
					},
					{
						level3_id: "29755",
						name: "Xã Mỹ Thạnh Trung",
						type: "Xã",
					},
					{
						level3_id: "29758",
						name: "Xã Tường Lộc",
						type: "Xã",
					},
					{
						level3_id: "29761",
						name: "Xã Loan Mỹ",
						type: "Xã",
					},
					{
						level3_id: "29764",
						name: "Xã Ngãi Tứ",
						type: "Xã",
					},
					{
						level3_id: "29767",
						name: "Xã Bình Ninh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "861",
				name: "Thị xã Bình Minh",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "29770",
						name: "Phường Cái Vồn",
						type: "Phường",
					},
					{
						level3_id: "29771",
						name: "Phường Thành Phước",
						type: "Phường",
					},
					{
						level3_id: "29806",
						name: "Xã Thuận An",
						type: "Xã",
					},
					{
						level3_id: "29809",
						name: "Xã Đông Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29812",
						name: "Xã Đông Bình",
						type: "Xã",
					},
					{
						level3_id: "29813",
						name: "Phường Đông Thuận",
						type: "Phường",
					},
					{
						level3_id: "29815",
						name: "Xã Mỹ Hòa",
						type: "Xã",
					},
					{
						level3_id: "29818",
						name: "Xã Đông Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "862",
				name: "Huyện Trà Ôn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29821",
						name: "Thị trấn Trà Ôn",
						type: "Thị trấn",
					},
					{
						level3_id: "29824",
						name: "Xã Xuân Hiệp",
						type: "Xã",
					},
					{
						level3_id: "29827",
						name: "Xã Nhơn Bình",
						type: "Xã",
					},
					{
						level3_id: "29830",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "29833",
						name: "Xã Thới Hòa",
						type: "Xã",
					},
					{
						level3_id: "29836",
						name: "Xã Trà Côn",
						type: "Xã",
					},
					{
						level3_id: "29839",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "29842",
						name: "Xã Hựu Thành",
						type: "Xã",
					},
					{
						level3_id: "29845",
						name: "Xã Vĩnh Xuân",
						type: "Xã",
					},
					{
						level3_id: "29848",
						name: "Xã Thuận Thới",
						type: "Xã",
					},
					{
						level3_id: "29851",
						name: "Xã Phú Thành",
						type: "Xã",
					},
					{
						level3_id: "29854",
						name: "Xã Thiện Mỹ",
						type: "Xã",
					},
					{
						level3_id: "29857",
						name: "Xã Lục Sỹ Thành",
						type: "Xã",
					},
					{
						level3_id: "29860",
						name: "Xã Tích Thiện",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "863",
				name: "Huyện Bình Tân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29773",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "29776",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "29779",
						name: "Xã Thành Trung",
						type: "Xã",
					},
					{
						level3_id: "29782",
						name: "Xã Tân An Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29785",
						name: "Xã Tân Lược",
						type: "Xã",
					},
					{
						level3_id: "29788",
						name: "Xã Nguyễn Văn Thảnh",
						type: "Xã",
					},
					{
						level3_id: "29791",
						name: "Xã Thành Lợi",
						type: "Xã",
					},
					{
						level3_id: "29794",
						name: "Xã Mỹ Thuận",
						type: "Xã",
					},
					{
						level3_id: "29797",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "29800",
						name: "Thị trấn Tân Quới",
						type: "Thị trấn",
					},
				],
			},
		],
	},
	{
		level1_id: "87",
		name: "Tỉnh Đồng Tháp",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "866",
				name: "Thành phố Cao Lãnh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "29863",
						name: "Phường 11",
						type: "Phường",
					},
					{
						level3_id: "29866",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "29869",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "29872",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "29875",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "29878",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "29881",
						name: "Xã Mỹ Ngãi",
						type: "Xã",
					},
					{
						level3_id: "29884",
						name: "Xã Mỹ Tân",
						type: "Xã",
					},
					{
						level3_id: "29887",
						name: "Xã Mỹ Trà",
						type: "Xã",
					},
					{
						level3_id: "29888",
						name: "Phường Mỹ Phú",
						type: "Phường",
					},
					{
						level3_id: "29890",
						name: "Xã Tân Thuận Tây",
						type: "Xã",
					},
					{
						level3_id: "29892",
						name: "Phường Hoà Thuận",
						type: "Phường",
					},
					{
						level3_id: "29893",
						name: "Xã Hòa An",
						type: "Xã",
					},
					{
						level3_id: "29896",
						name: "Xã Tân Thuận Đông",
						type: "Xã",
					},
					{
						level3_id: "29899",
						name: "Xã Tịnh Thới",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "867",
				name: "Thành phố Sa Đéc",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "29902",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "29905",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "29908",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "29911",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "29914",
						name: "Xã Tân Khánh Đông",
						type: "Xã",
					},
					{
						level3_id: "29917",
						name: "Phường Tân Quy Đông",
						type: "Phường",
					},
					{
						level3_id: "29919",
						name: "Phường An Hoà",
						type: "Phường",
					},
					{
						level3_id: "29920",
						name: "Xã Tân Quy Tây",
						type: "Xã",
					},
					{
						level3_id: "29923",
						name: "Xã Tân Phú Đông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "868",
				name: "Thành phố Hồng Ngự",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "29954",
						name: "Phường An Lộc",
						type: "Phường",
					},
					{
						level3_id: "29955",
						name: "Phường An Thạnh",
						type: "Phường",
					},
					{
						level3_id: "29959",
						name: "Xã Bình Thạnh",
						type: "Xã",
					},
					{
						level3_id: "29965",
						name: "Xã Tân Hội",
						type: "Xã",
					},
					{
						level3_id: "29978",
						name: "Phường An Lạc",
						type: "Phường",
					},
					{
						level3_id: "29986",
						name: "Phường An Bình B",
						type: "Phường",
					},
					{
						level3_id: "29989",
						name: "Phường An Bình A",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "869",
				name: "Huyện Tân Hồng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29926",
						name: "Thị trấn Sa Rài",
						type: "Thị trấn",
					},
					{
						level3_id: "29929",
						name: "Xã Tân Hộ Cơ",
						type: "Xã",
					},
					{
						level3_id: "29932",
						name: "Xã Thông Bình",
						type: "Xã",
					},
					{
						level3_id: "29935",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "29938",
						name: "Xã Tân Thành A",
						type: "Xã",
					},
					{
						level3_id: "29941",
						name: "Xã Tân Thành B",
						type: "Xã",
					},
					{
						level3_id: "29944",
						name: "Xã Tân Phước",
						type: "Xã",
					},
					{
						level3_id: "29947",
						name: "Xã Tân Công Chí",
						type: "Xã",
					},
					{
						level3_id: "29950",
						name: "Xã An Phước",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "870",
				name: "Huyện Hồng Ngự",
				type: "Huyện",
				level3s: [
					{
						level3_id: "29956",
						name: "Xã Thường Phước 1",
						type: "Xã",
					},
					{
						level3_id: "29962",
						name: "Xã Thường Thới Hậu A",
						type: "Xã",
					},
					{
						level3_id: "29971",
						name: "Thị trấn Thường Thới Tiền",
						type: "Thị trấn",
					},
					{
						level3_id: "29974",
						name: "Xã Thường Phước 2",
						type: "Xã",
					},
					{
						level3_id: "29977",
						name: "Xã Thường Lạc",
						type: "Xã",
					},
					{
						level3_id: "29980",
						name: "Xã Long Khánh A",
						type: "Xã",
					},
					{
						level3_id: "29983",
						name: "Xã Long Khánh B",
						type: "Xã",
					},
					{
						level3_id: "29992",
						name: "Xã Long Thuận",
						type: "Xã",
					},
					{
						level3_id: "29995",
						name: "Xã Phú Thuận B",
						type: "Xã",
					},
					{
						level3_id: "29998",
						name: "Xã Phú Thuận A",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "871",
				name: "Huyện Tam Nông",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30001",
						name: "Thị trấn Tràm Chim",
						type: "Thị trấn",
					},
					{
						level3_id: "30004",
						name: "Xã Hoà Bình",
						type: "Xã",
					},
					{
						level3_id: "30007",
						name: "Xã Tân Công Sính",
						type: "Xã",
					},
					{
						level3_id: "30010",
						name: "Xã Phú Hiệp",
						type: "Xã",
					},
					{
						level3_id: "30013",
						name: "Xã Phú Đức",
						type: "Xã",
					},
					{
						level3_id: "30016",
						name: "Xã Phú Thành B",
						type: "Xã",
					},
					{
						level3_id: "30019",
						name: "Xã An Hòa",
						type: "Xã",
					},
					{
						level3_id: "30022",
						name: "Xã An Long",
						type: "Xã",
					},
					{
						level3_id: "30025",
						name: "Xã Phú Cường",
						type: "Xã",
					},
					{
						level3_id: "30028",
						name: "Xã Phú Ninh",
						type: "Xã",
					},
					{
						level3_id: "30031",
						name: "Xã Phú Thọ",
						type: "Xã",
					},
					{
						level3_id: "30034",
						name: "Xã Phú Thành A",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "872",
				name: "Huyện Tháp Mười",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30037",
						name: "Thị trấn Mỹ An",
						type: "Thị trấn",
					},
					{
						level3_id: "30040",
						name: "Xã Thạnh Lợi",
						type: "Xã",
					},
					{
						level3_id: "30043",
						name: "Xã Hưng Thạnh",
						type: "Xã",
					},
					{
						level3_id: "30046",
						name: "Xã Trường Xuân",
						type: "Xã",
					},
					{
						level3_id: "30049",
						name: "Xã Tân Kiều",
						type: "Xã",
					},
					{
						level3_id: "30052",
						name: "Xã Mỹ Hòa",
						type: "Xã",
					},
					{
						level3_id: "30055",
						name: "Xã Mỹ Quý",
						type: "Xã",
					},
					{
						level3_id: "30058",
						name: "Xã Mỹ Đông",
						type: "Xã",
					},
					{
						level3_id: "30061",
						name: "Xã Đốc Binh Kiều",
						type: "Xã",
					},
					{
						level3_id: "30064",
						name: "Xã Mỹ An",
						type: "Xã",
					},
					{
						level3_id: "30067",
						name: "Xã Phú Điền",
						type: "Xã",
					},
					{
						level3_id: "30070",
						name: "Xã Láng Biển",
						type: "Xã",
					},
					{
						level3_id: "30073",
						name: "Xã Thanh Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "873",
				name: "Huyện Cao Lãnh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30076",
						name: "Thị trấn Mỹ Thọ",
						type: "Thị trấn",
					},
					{
						level3_id: "30079",
						name: "Xã Gáo Giồng",
						type: "Xã",
					},
					{
						level3_id: "30082",
						name: "Xã Phương Thịnh",
						type: "Xã",
					},
					{
						level3_id: "30085",
						name: "Xã Ba Sao",
						type: "Xã",
					},
					{
						level3_id: "30088",
						name: "Xã Phong Mỹ",
						type: "Xã",
					},
					{
						level3_id: "30091",
						name: "Xã Tân Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "30094",
						name: "Xã Phương Trà",
						type: "Xã",
					},
					{
						level3_id: "30097",
						name: "Xã Nhị Mỹ",
						type: "Xã",
					},
					{
						level3_id: "30100",
						name: "Xã Mỹ Thọ",
						type: "Xã",
					},
					{
						level3_id: "30103",
						name: "Xã Tân Hội Trung",
						type: "Xã",
					},
					{
						level3_id: "30106",
						name: "Xã An Bình",
						type: "Xã",
					},
					{
						level3_id: "30109",
						name: "Xã Mỹ Hội",
						type: "Xã",
					},
					{
						level3_id: "30112",
						name: "Xã Mỹ Hiệp",
						type: "Xã",
					},
					{
						level3_id: "30115",
						name: "Xã Mỹ Long",
						type: "Xã",
					},
					{
						level3_id: "30118",
						name: "Xã Bình Hàng Trung",
						type: "Xã",
					},
					{
						level3_id: "30121",
						name: "Xã Mỹ Xương",
						type: "Xã",
					},
					{
						level3_id: "30124",
						name: "Xã Bình Hàng Tây",
						type: "Xã",
					},
					{
						level3_id: "30127",
						name: "Xã Bình Thạnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "874",
				name: "Huyện Thanh Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30130",
						name: "Thị trấn Thanh Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "30133",
						name: "Xã Tân Quới",
						type: "Xã",
					},
					{
						level3_id: "30136",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "30139",
						name: "Xã An Phong",
						type: "Xã",
					},
					{
						level3_id: "30142",
						name: "Xã Phú Lợi",
						type: "Xã",
					},
					{
						level3_id: "30145",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "30148",
						name: "Xã Bình Tấn",
						type: "Xã",
					},
					{
						level3_id: "30151",
						name: "Xã Tân Huề",
						type: "Xã",
					},
					{
						level3_id: "30154",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "30157",
						name: "Xã Tân Thạnh",
						type: "Xã",
					},
					{
						level3_id: "30160",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "30163",
						name: "Xã Bình Thành",
						type: "Xã",
					},
					{
						level3_id: "30166",
						name: "Xã Tân Long",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "875",
				name: "Huyện Lấp Vò",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30169",
						name: "Thị trấn Lấp Vò",
						type: "Thị trấn",
					},
					{
						level3_id: "30172",
						name: "Xã Mỹ An Hưng A",
						type: "Xã",
					},
					{
						level3_id: "30175",
						name: "Xã Tân Mỹ",
						type: "Xã",
					},
					{
						level3_id: "30178",
						name: "Xã Mỹ An Hưng B",
						type: "Xã",
					},
					{
						level3_id: "30181",
						name: "Xã Tân  Khánh Trung",
						type: "Xã",
					},
					{
						level3_id: "30184",
						name: "Xã Long Hưng A",
						type: "Xã",
					},
					{
						level3_id: "30187",
						name: "Xã Vĩnh Thạnh",
						type: "Xã",
					},
					{
						level3_id: "30190",
						name: "Xã Long Hưng B",
						type: "Xã",
					},
					{
						level3_id: "30193",
						name: "Xã Bình Thành",
						type: "Xã",
					},
					{
						level3_id: "30196",
						name: "Xã Định An",
						type: "Xã",
					},
					{
						level3_id: "30199",
						name: "Xã Định Yên",
						type: "Xã",
					},
					{
						level3_id: "30202",
						name: "Xã Hội An Đông",
						type: "Xã",
					},
					{
						level3_id: "30205",
						name: "Xã Bình Thạnh Trung",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "876",
				name: "Huyện Lai Vung",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30208",
						name: "Thị trấn Lai Vung",
						type: "Thị trấn",
					},
					{
						level3_id: "30211",
						name: "Xã Tân Dương",
						type: "Xã",
					},
					{
						level3_id: "30214",
						name: "Xã Hòa Thành",
						type: "Xã",
					},
					{
						level3_id: "30217",
						name: "Xã Long Hậu",
						type: "Xã",
					},
					{
						level3_id: "30220",
						name: "Xã Tân Phước",
						type: "Xã",
					},
					{
						level3_id: "30223",
						name: "Xã Hòa Long",
						type: "Xã",
					},
					{
						level3_id: "30226",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "30229",
						name: "Xã Long Thắng",
						type: "Xã",
					},
					{
						level3_id: "30232",
						name: "Xã Vĩnh Thới",
						type: "Xã",
					},
					{
						level3_id: "30235",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "30238",
						name: "Xã Định Hòa",
						type: "Xã",
					},
					{
						level3_id: "30241",
						name: "Xã Phong Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "877",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30244",
						name: "Thị trấn Cái Tàu Hạ",
						type: "Thị trấn",
					},
					{
						level3_id: "30247",
						name: "Xã An Hiệp",
						type: "Xã",
					},
					{
						level3_id: "30250",
						name: "Xã An Nhơn",
						type: "Xã",
					},
					{
						level3_id: "30253",
						name: "Xã Tân Nhuận Đông",
						type: "Xã",
					},
					{
						level3_id: "30256",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "30259",
						name: "Xã Tân Phú Trung",
						type: "Xã",
					},
					{
						level3_id: "30262",
						name: "Xã Phú Long",
						type: "Xã",
					},
					{
						level3_id: "30265",
						name: "Xã An Phú Thuận",
						type: "Xã",
					},
					{
						level3_id: "30268",
						name: "Xã Phú Hựu",
						type: "Xã",
					},
					{
						level3_id: "30271",
						name: "Xã An Khánh",
						type: "Xã",
					},
					{
						level3_id: "30274",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "30277",
						name: "Xã Hòa Tân",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "89",
		name: "Tỉnh An Giang",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "883",
				name: "Thành phố Long Xuyên",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "30280",
						name: "Phường Mỹ Bình",
						type: "Phường",
					},
					{
						level3_id: "30283",
						name: "Phường Mỹ Long",
						type: "Phường",
					},
					{
						level3_id: "30285",
						name: "Phường Đông Xuyên",
						type: "Phường",
					},
					{
						level3_id: "30286",
						name: "Phường Mỹ Xuyên",
						type: "Phường",
					},
					{
						level3_id: "30289",
						name: "Phường Bình Đức",
						type: "Phường",
					},
					{
						level3_id: "30292",
						name: "Phường Bình Khánh",
						type: "Phường",
					},
					{
						level3_id: "30295",
						name: "Phường Mỹ Phước",
						type: "Phường",
					},
					{
						level3_id: "30298",
						name: "Phường Mỹ Quý",
						type: "Phường",
					},
					{
						level3_id: "30301",
						name: "Phường Mỹ Thới",
						type: "Phường",
					},
					{
						level3_id: "30304",
						name: "Phường Mỹ Thạnh",
						type: "Phường",
					},
					{
						level3_id: "30307",
						name: "Phường Mỹ Hòa",
						type: "Phường",
					},
					{
						level3_id: "30310",
						name: "Xã Mỹ Khánh",
						type: "Xã",
					},
					{
						level3_id: "30313",
						name: "Xã Mỹ Hoà Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "884",
				name: "Thành phố Châu Đốc",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "30316",
						name: "Phường Châu Phú B",
						type: "Phường",
					},
					{
						level3_id: "30319",
						name: "Phường Châu Phú A",
						type: "Phường",
					},
					{
						level3_id: "30322",
						name: "Phường Vĩnh Mỹ",
						type: "Phường",
					},
					{
						level3_id: "30325",
						name: "Phường Núi Sam",
						type: "Phường",
					},
					{
						level3_id: "30328",
						name: "Phường Vĩnh Ngươn",
						type: "Phường",
					},
					{
						level3_id: "30331",
						name: "Xã Vĩnh Tế",
						type: "Xã",
					},
					{
						level3_id: "30334",
						name: "Xã Vĩnh Châu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "886",
				name: "Huyện An Phú",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30337",
						name: "Thị trấn An Phú",
						type: "Thị trấn",
					},
					{
						level3_id: "30340",
						name: "Xã Khánh An",
						type: "Xã",
					},
					{
						level3_id: "30341",
						name: "Thị Trấn Long Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "30343",
						name: "Xã Khánh Bình",
						type: "Xã",
					},
					{
						level3_id: "30346",
						name: "Xã Quốc Thái",
						type: "Xã",
					},
					{
						level3_id: "30349",
						name: "Xã Nhơn Hội",
						type: "Xã",
					},
					{
						level3_id: "30352",
						name: "Xã Phú Hữu",
						type: "Xã",
					},
					{
						level3_id: "30355",
						name: "Xã Phú Hội",
						type: "Xã",
					},
					{
						level3_id: "30358",
						name: "Xã Phước Hưng",
						type: "Xã",
					},
					{
						level3_id: "30361",
						name: "Xã Vĩnh Lộc",
						type: "Xã",
					},
					{
						level3_id: "30364",
						name: "Xã Vĩnh Hậu",
						type: "Xã",
					},
					{
						level3_id: "30367",
						name: "Xã Vĩnh Trường",
						type: "Xã",
					},
					{
						level3_id: "30370",
						name: "Xã Vĩnh Hội Đông",
						type: "Xã",
					},
					{
						level3_id: "30373",
						name: "Xã Đa Phước",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "887",
				name: "Thị xã Tân Châu",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "30376",
						name: "Phường Long Thạnh",
						type: "Phường",
					},
					{
						level3_id: "30377",
						name: "Phường Long Hưng",
						type: "Phường",
					},
					{
						level3_id: "30378",
						name: "Phường Long Châu",
						type: "Phường",
					},
					{
						level3_id: "30379",
						name: "Xã Phú Lộc",
						type: "Xã",
					},
					{
						level3_id: "30382",
						name: "Xã Vĩnh Xương",
						type: "Xã",
					},
					{
						level3_id: "30385",
						name: "Xã Vĩnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "30387",
						name: "Xã Tân Thạnh",
						type: "Xã",
					},
					{
						level3_id: "30388",
						name: "Xã Tân An",
						type: "Xã",
					},
					{
						level3_id: "30391",
						name: "Xã Long An",
						type: "Xã",
					},
					{
						level3_id: "30394",
						name: "Phường Long Phú",
						type: "Phường",
					},
					{
						level3_id: "30397",
						name: "Xã Châu Phong",
						type: "Xã",
					},
					{
						level3_id: "30400",
						name: "Xã Phú Vĩnh",
						type: "Xã",
					},
					{
						level3_id: "30403",
						name: "Xã Lê Chánh",
						type: "Xã",
					},
					{
						level3_id: "30412",
						name: "Phường Long Sơn",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "888",
				name: "Huyện Phú Tân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30406",
						name: "Thị trấn Phú Mỹ",
						type: "Thị trấn",
					},
					{
						level3_id: "30409",
						name: "Thị trấn Chợ Vàm",
						type: "Thị trấn",
					},
					{
						level3_id: "30415",
						name: "Xã Long Hoà",
						type: "Xã",
					},
					{
						level3_id: "30418",
						name: "Xã Phú Long",
						type: "Xã",
					},
					{
						level3_id: "30421",
						name: "Xã Phú Lâm",
						type: "Xã",
					},
					{
						level3_id: "30424",
						name: "Xã Phú Hiệp",
						type: "Xã",
					},
					{
						level3_id: "30427",
						name: "Xã Phú Thạnh",
						type: "Xã",
					},
					{
						level3_id: "30430",
						name: "Xã Hoà Lạc",
						type: "Xã",
					},
					{
						level3_id: "30433",
						name: "Xã Phú Thành",
						type: "Xã",
					},
					{
						level3_id: "30436",
						name: "Xã Phú An",
						type: "Xã",
					},
					{
						level3_id: "30439",
						name: "Xã Phú Xuân",
						type: "Xã",
					},
					{
						level3_id: "30442",
						name: "Xã Hiệp Xương",
						type: "Xã",
					},
					{
						level3_id: "30445",
						name: "Xã Phú Bình",
						type: "Xã",
					},
					{
						level3_id: "30448",
						name: "Xã Phú Thọ",
						type: "Xã",
					},
					{
						level3_id: "30451",
						name: "Xã Phú Hưng",
						type: "Xã",
					},
					{
						level3_id: "30454",
						name: "Xã Bình Thạnh Đông",
						type: "Xã",
					},
					{
						level3_id: "30457",
						name: "Xã Tân Hòa",
						type: "Xã",
					},
					{
						level3_id: "30460",
						name: "Xã Tân Trung",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "889",
				name: "Huyện Châu Phú",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30463",
						name: "Thị trấn Cái Dầu",
						type: "Thị trấn",
					},
					{
						level3_id: "30466",
						name: "Xã Khánh Hòa",
						type: "Xã",
					},
					{
						level3_id: "30469",
						name: "Xã Mỹ Đức",
						type: "Xã",
					},
					{
						level3_id: "30472",
						name: "Xã Mỹ Phú",
						type: "Xã",
					},
					{
						level3_id: "30475",
						name: "Xã Ô Long Vỹ",
						type: "Xã",
					},
					{
						level3_id: "30478",
						name: "Thị trấn Vĩnh Thạnh Trung",
						type: "Thị trấn",
					},
					{
						level3_id: "30481",
						name: "Xã Thạnh Mỹ Tây",
						type: "Xã",
					},
					{
						level3_id: "30484",
						name: "Xã Bình Long",
						type: "Xã",
					},
					{
						level3_id: "30487",
						name: "Xã Bình Mỹ",
						type: "Xã",
					},
					{
						level3_id: "30490",
						name: "Xã Bình Thủy",
						type: "Xã",
					},
					{
						level3_id: "30493",
						name: "Xã Đào Hữu Cảnh",
						type: "Xã",
					},
					{
						level3_id: "30496",
						name: "Xã Bình Phú",
						type: "Xã",
					},
					{
						level3_id: "30499",
						name: "Xã Bình Chánh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "890",
				name: "Huyện Tịnh Biên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30502",
						name: "Thị trấn Nhà Bàng",
						type: "Thị trấn",
					},
					{
						level3_id: "30505",
						name: "Thị trấn Chi Lăng",
						type: "Thị trấn",
					},
					{
						level3_id: "30508",
						name: "Xã Núi Voi",
						type: "Xã",
					},
					{
						level3_id: "30511",
						name: "Xã Nhơn Hưng",
						type: "Xã",
					},
					{
						level3_id: "30514",
						name: "Xã An Phú",
						type: "Xã",
					},
					{
						level3_id: "30517",
						name: "Xã Thới Sơn",
						type: "Xã",
					},
					{
						level3_id: "30520",
						name: "Thị trấn Tịnh Biên",
						type: "Thị trấn",
					},
					{
						level3_id: "30523",
						name: "Xã Văn Giáo",
						type: "Xã",
					},
					{
						level3_id: "30526",
						name: "Xã An Cư",
						type: "Xã",
					},
					{
						level3_id: "30529",
						name: "Xã An Nông",
						type: "Xã",
					},
					{
						level3_id: "30532",
						name: "Xã Vĩnh Trung",
						type: "Xã",
					},
					{
						level3_id: "30535",
						name: "Xã Tân Lợi",
						type: "Xã",
					},
					{
						level3_id: "30538",
						name: "Xã An Hảo",
						type: "Xã",
					},
					{
						level3_id: "30541",
						name: "Xã Tân Lập",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "891",
				name: "Huyện Tri Tôn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30544",
						name: "Thị trấn Tri Tôn",
						type: "Thị trấn",
					},
					{
						level3_id: "30547",
						name: "Thị trấn Ba Chúc",
						type: "Thị trấn",
					},
					{
						level3_id: "30550",
						name: "Xã Lạc Quới",
						type: "Xã",
					},
					{
						level3_id: "30553",
						name: "Xã Lê Trì",
						type: "Xã",
					},
					{
						level3_id: "30556",
						name: "Xã Vĩnh Gia",
						type: "Xã",
					},
					{
						level3_id: "30559",
						name: "Xã Vĩnh Phước",
						type: "Xã",
					},
					{
						level3_id: "30562",
						name: "Xã Châu Lăng",
						type: "Xã",
					},
					{
						level3_id: "30565",
						name: "Xã Lương Phi",
						type: "Xã",
					},
					{
						level3_id: "30568",
						name: "Xã Lương An Trà",
						type: "Xã",
					},
					{
						level3_id: "30571",
						name: "Xã Tà Đảnh",
						type: "Xã",
					},
					{
						level3_id: "30574",
						name: "Xã Núi Tô",
						type: "Xã",
					},
					{
						level3_id: "30577",
						name: "Xã An Tức",
						type: "Xã",
					},
					{
						level3_id: "30580",
						name: "Thị trấn Cô Tô",
						type: "Thị trấn",
					},
					{
						level3_id: "30583",
						name: "Xã Tân Tuyến",
						type: "Xã",
					},
					{
						level3_id: "30586",
						name: "Xã Ô Lâm",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "892",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30589",
						name: "Thị trấn An Châu",
						type: "Thị trấn",
					},
					{
						level3_id: "30592",
						name: "Xã An Hòa",
						type: "Xã",
					},
					{
						level3_id: "30595",
						name: "Xã Cần Đăng",
						type: "Xã",
					},
					{
						level3_id: "30598",
						name: "Xã Vĩnh Hanh",
						type: "Xã",
					},
					{
						level3_id: "30601",
						name: "Xã Bình Thạnh",
						type: "Xã",
					},
					{
						level3_id: "30604",
						name: "Thị trấn Vĩnh Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "30607",
						name: "Xã Bình Hòa",
						type: "Xã",
					},
					{
						level3_id: "30610",
						name: "Xã Vĩnh An",
						type: "Xã",
					},
					{
						level3_id: "30613",
						name: "Xã Hòa Bình Thạnh",
						type: "Xã",
					},
					{
						level3_id: "30616",
						name: "Xã Vĩnh Lợi",
						type: "Xã",
					},
					{
						level3_id: "30619",
						name: "Xã Vĩnh Nhuận",
						type: "Xã",
					},
					{
						level3_id: "30622",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "30625",
						name: "Xã Vĩnh Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "893",
				name: "Huyện Chợ Mới",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30628",
						name: "Thị trấn Chợ Mới",
						type: "Thị trấn",
					},
					{
						level3_id: "30631",
						name: "Thị trấn Mỹ Luông",
						type: "Thị trấn",
					},
					{
						level3_id: "30634",
						name: "Xã Kiến An",
						type: "Xã",
					},
					{
						level3_id: "30637",
						name: "Xã Mỹ Hội Đông",
						type: "Xã",
					},
					{
						level3_id: "30640",
						name: "Xã Long Điền A",
						type: "Xã",
					},
					{
						level3_id: "30643",
						name: "Xã Tấn Mỹ",
						type: "Xã",
					},
					{
						level3_id: "30646",
						name: "Xã Long Điền B",
						type: "Xã",
					},
					{
						level3_id: "30649",
						name: "Xã Kiến Thành",
						type: "Xã",
					},
					{
						level3_id: "30652",
						name: "Xã Mỹ Hiệp",
						type: "Xã",
					},
					{
						level3_id: "30655",
						name: "Xã Mỹ An",
						type: "Xã",
					},
					{
						level3_id: "30658",
						name: "Xã Nhơn Mỹ",
						type: "Xã",
					},
					{
						level3_id: "30661",
						name: "Xã Long Giang",
						type: "Xã",
					},
					{
						level3_id: "30664",
						name: "Xã Long Kiến",
						type: "Xã",
					},
					{
						level3_id: "30667",
						name: "Xã Bình Phước Xuân",
						type: "Xã",
					},
					{
						level3_id: "30670",
						name: "Xã An Thạnh Trung",
						type: "Xã",
					},
					{
						level3_id: "30673",
						name: "Xã Hội An",
						type: "Xã",
					},
					{
						level3_id: "30676",
						name: "Xã Hòa Bình",
						type: "Xã",
					},
					{
						level3_id: "30679",
						name: "Xã Hòa An",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "894",
				name: "Huyện Thoại Sơn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30682",
						name: "Thị trấn Núi Sập",
						type: "Thị trấn",
					},
					{
						level3_id: "30685",
						name: "Thị trấn Phú Hoà",
						type: "Thị trấn",
					},
					{
						level3_id: "30688",
						name: "Thị Trấn Óc Eo",
						type: "Thị trấn",
					},
					{
						level3_id: "30691",
						name: "Xã Tây Phú",
						type: "Xã",
					},
					{
						level3_id: "30692",
						name: "Xã An Bình",
						type: "Xã",
					},
					{
						level3_id: "30694",
						name: "Xã Vĩnh Phú",
						type: "Xã",
					},
					{
						level3_id: "30697",
						name: "Xã Vĩnh Trạch",
						type: "Xã",
					},
					{
						level3_id: "30700",
						name: "Xã Phú Thuận",
						type: "Xã",
					},
					{
						level3_id: "30703",
						name: "Xã Vĩnh Chánh",
						type: "Xã",
					},
					{
						level3_id: "30706",
						name: "Xã Định Mỹ",
						type: "Xã",
					},
					{
						level3_id: "30709",
						name: "Xã Định Thành",
						type: "Xã",
					},
					{
						level3_id: "30712",
						name: "Xã Mỹ Phú Đông",
						type: "Xã",
					},
					{
						level3_id: "30715",
						name: "Xã Vọng Đông",
						type: "Xã",
					},
					{
						level3_id: "30718",
						name: "Xã Vĩnh Khánh",
						type: "Xã",
					},
					{
						level3_id: "30721",
						name: "Xã Thoại Giang",
						type: "Xã",
					},
					{
						level3_id: "30724",
						name: "Xã Bình Thành",
						type: "Xã",
					},
					{
						level3_id: "30727",
						name: "Xã Vọng Thê",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "91",
		name: "Tỉnh Kiên Giang",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "899",
				name: "Thành phố Rạch Giá",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "30730",
						name: "Phường Vĩnh Thanh Vân",
						type: "Phường",
					},
					{
						level3_id: "30733",
						name: "Phường Vĩnh Thanh",
						type: "Phường",
					},
					{
						level3_id: "30736",
						name: "Phường Vĩnh Quang",
						type: "Phường",
					},
					{
						level3_id: "30739",
						name: "Phường Vĩnh Hiệp",
						type: "Phường",
					},
					{
						level3_id: "30742",
						name: "Phường Vĩnh Bảo",
						type: "Phường",
					},
					{
						level3_id: "30745",
						name: "Phường Vĩnh Lạc",
						type: "Phường",
					},
					{
						level3_id: "30748",
						name: "Phường An Hòa",
						type: "Phường",
					},
					{
						level3_id: "30751",
						name: "Phường An Bình",
						type: "Phường",
					},
					{
						level3_id: "30754",
						name: "Phường Rạch Sỏi",
						type: "Phường",
					},
					{
						level3_id: "30757",
						name: "Phường Vĩnh Lợi",
						type: "Phường",
					},
					{
						level3_id: "30760",
						name: "Phường Vĩnh Thông",
						type: "Phường",
					},
					{
						level3_id: "30763",
						name: "Xã Phi Thông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "900",
				name: "Thành phố Hà Tiên",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "30766",
						name: "Phường Tô Châu",
						type: "Phường",
					},
					{
						level3_id: "30769",
						name: "Phường Đông Hồ",
						type: "Phường",
					},
					{
						level3_id: "30772",
						name: "Phường Bình San",
						type: "Phường",
					},
					{
						level3_id: "30775",
						name: "Phường Pháo Đài",
						type: "Phường",
					},
					{
						level3_id: "30778",
						name: "Phường Mỹ Đức",
						type: "Phường",
					},
					{
						level3_id: "30781",
						name: "Xã Tiên Hải",
						type: "Xã",
					},
					{
						level3_id: "30784",
						name: "Xã Thuận Yên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "902",
				name: "Huyện Kiên Lương",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30787",
						name: "Thị trấn Kiên Lương",
						type: "Thị trấn",
					},
					{
						level3_id: "30790",
						name: "Xã Kiên Bình",
						type: "Xã",
					},
					{
						level3_id: "30802",
						name: "Xã Hòa Điền",
						type: "Xã",
					},
					{
						level3_id: "30805",
						name: "Xã Dương Hòa",
						type: "Xã",
					},
					{
						level3_id: "30808",
						name: "Xã Bình An",
						type: "Xã",
					},
					{
						level3_id: "30809",
						name: "Xã Bình Trị",
						type: "Xã",
					},
					{
						level3_id: "30811",
						name: "Xã Sơn Hải",
						type: "Xã",
					},
					{
						level3_id: "30814",
						name: "Xã Hòn Nghệ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "903",
				name: "Huyện Hòn Đất",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30817",
						name: "Thị trấn Hòn Đất",
						type: "Thị trấn",
					},
					{
						level3_id: "30820",
						name: "Thị trấn Sóc Sơn",
						type: "Thị trấn",
					},
					{
						level3_id: "30823",
						name: "Xã Bình Sơn",
						type: "Xã",
					},
					{
						level3_id: "30826",
						name: "Xã Bình Giang",
						type: "Xã",
					},
					{
						level3_id: "30828",
						name: "Xã Mỹ Thái",
						type: "Xã",
					},
					{
						level3_id: "30829",
						name: "Xã Nam Thái Sơn",
						type: "Xã",
					},
					{
						level3_id: "30832",
						name: "Xã Mỹ Hiệp Sơn",
						type: "Xã",
					},
					{
						level3_id: "30835",
						name: "Xã Sơn Kiên",
						type: "Xã",
					},
					{
						level3_id: "30836",
						name: "Xã Sơn Bình",
						type: "Xã",
					},
					{
						level3_id: "30838",
						name: "Xã Mỹ Thuận",
						type: "Xã",
					},
					{
						level3_id: "30840",
						name: "Xã Lình Huỳnh",
						type: "Xã",
					},
					{
						level3_id: "30841",
						name: "Xã Thổ Sơn",
						type: "Xã",
					},
					{
						level3_id: "30844",
						name: "Xã Mỹ Lâm",
						type: "Xã",
					},
					{
						level3_id: "30847",
						name: "Xã Mỹ Phước",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "904",
				name: "Huyện Tân Hiệp",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30850",
						name: "Thị trấn Tân Hiệp",
						type: "Thị trấn",
					},
					{
						level3_id: "30853",
						name: "Xã Tân Hội",
						type: "Xã",
					},
					{
						level3_id: "30856",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "30859",
						name: "Xã Tân Hiệp B",
						type: "Xã",
					},
					{
						level3_id: "30860",
						name: "Xã Tân Hoà",
						type: "Xã",
					},
					{
						level3_id: "30862",
						name: "Xã Thạnh Đông B",
						type: "Xã",
					},
					{
						level3_id: "30865",
						name: "Xã Thạnh Đông",
						type: "Xã",
					},
					{
						level3_id: "30868",
						name: "Xã Tân Hiệp A",
						type: "Xã",
					},
					{
						level3_id: "30871",
						name: "Xã Tân An",
						type: "Xã",
					},
					{
						level3_id: "30874",
						name: "Xã Thạnh Đông A",
						type: "Xã",
					},
					{
						level3_id: "30877",
						name: "Xã Thạnh Trị",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "905",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30880",
						name: "Thị trấn Minh Lương",
						type: "Thị trấn",
					},
					{
						level3_id: "30883",
						name: "Xã Mong Thọ A",
						type: "Xã",
					},
					{
						level3_id: "30886",
						name: "Xã Mong Thọ B",
						type: "Xã",
					},
					{
						level3_id: "30887",
						name: "Xã Mong Thọ",
						type: "Xã",
					},
					{
						level3_id: "30889",
						name: "Xã Giục Tượng",
						type: "Xã",
					},
					{
						level3_id: "30892",
						name: "Xã Vĩnh Hòa Hiệp",
						type: "Xã",
					},
					{
						level3_id: "30893",
						name: "Xã Vĩnh Hoà Phú",
						type: "Xã",
					},
					{
						level3_id: "30895",
						name: "Xã Minh Hòa",
						type: "Xã",
					},
					{
						level3_id: "30898",
						name: "Xã Bình An",
						type: "Xã",
					},
					{
						level3_id: "30901",
						name: "Xã Thạnh Lộc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "906",
				name: "Huyện Giồng Riềng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30904",
						name: "Thị Trấn Giồng Riềng",
						type: "Thị trấn",
					},
					{
						level3_id: "30907",
						name: "Xã Thạnh Hưng",
						type: "Xã",
					},
					{
						level3_id: "30910",
						name: "Xã Thạnh Phước",
						type: "Xã",
					},
					{
						level3_id: "30913",
						name: "Xã Thạnh Lộc",
						type: "Xã",
					},
					{
						level3_id: "30916",
						name: "Xã Thạnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "30917",
						name: "Xã Thạnh Bình",
						type: "Xã",
					},
					{
						level3_id: "30919",
						name: "Xã Bàn Thạch",
						type: "Xã",
					},
					{
						level3_id: "30922",
						name: "Xã Bàn Tân Định",
						type: "Xã",
					},
					{
						level3_id: "30925",
						name: "Xã Ngọc Thành",
						type: "Xã",
					},
					{
						level3_id: "30928",
						name: "Xã Ngọc Chúc",
						type: "Xã",
					},
					{
						level3_id: "30931",
						name: "Xã Ngọc Thuận",
						type: "Xã",
					},
					{
						level3_id: "30934",
						name: "Xã Hòa Hưng",
						type: "Xã",
					},
					{
						level3_id: "30937",
						name: "Xã Hoà Lợi",
						type: "Xã",
					},
					{
						level3_id: "30940",
						name: "Xã Hoà An",
						type: "Xã",
					},
					{
						level3_id: "30943",
						name: "Xã Long Thạnh",
						type: "Xã",
					},
					{
						level3_id: "30946",
						name: "Xã Vĩnh Thạnh",
						type: "Xã",
					},
					{
						level3_id: "30947",
						name: "Xã Vĩnh Phú",
						type: "Xã",
					},
					{
						level3_id: "30949",
						name: "Xã  Hòa Thuận",
						type: "Xã",
					},
					{
						level3_id: "30950",
						name: "Xã Ngọc Hoà",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "907",
				name: "Huyện Gò Quao",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30952",
						name: "Thị trấn Gò Quao",
						type: "Thị trấn",
					},
					{
						level3_id: "30955",
						name: "Xã Vĩnh Hòa Hưng Bắc",
						type: "Xã",
					},
					{
						level3_id: "30958",
						name: "Xã Định Hòa",
						type: "Xã",
					},
					{
						level3_id: "30961",
						name: "Xã Thới Quản",
						type: "Xã",
					},
					{
						level3_id: "30964",
						name: "Xã Định An",
						type: "Xã",
					},
					{
						level3_id: "30967",
						name: "Xã Thủy Liễu",
						type: "Xã",
					},
					{
						level3_id: "30970",
						name: "Xã Vĩnh Hòa Hưng Nam",
						type: "Xã",
					},
					{
						level3_id: "30973",
						name: "Xã Vĩnh Phước A",
						type: "Xã",
					},
					{
						level3_id: "30976",
						name: "Xã Vĩnh Phước B",
						type: "Xã",
					},
					{
						level3_id: "30979",
						name: "Xã Vĩnh Tuy",
						type: "Xã",
					},
					{
						level3_id: "30982",
						name: "Xã Vĩnh Thắng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "908",
				name: "Huyện An Biên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30985",
						name: "Thị trấn Thứ Ba",
						type: "Thị trấn",
					},
					{
						level3_id: "30988",
						name: "Xã Tây Yên",
						type: "Xã",
					},
					{
						level3_id: "30991",
						name: "Xã Tây Yên A",
						type: "Xã",
					},
					{
						level3_id: "30994",
						name: "Xã Nam Yên",
						type: "Xã",
					},
					{
						level3_id: "30997",
						name: "Xã Hưng Yên",
						type: "Xã",
					},
					{
						level3_id: "31000",
						name: "Xã Nam Thái",
						type: "Xã",
					},
					{
						level3_id: "31003",
						name: "Xã Nam Thái A",
						type: "Xã",
					},
					{
						level3_id: "31006",
						name: "Xã Đông Thái",
						type: "Xã",
					},
					{
						level3_id: "31009",
						name: "Xã Đông Yên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "909",
				name: "Huyện An Minh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31018",
						name: "Thị trấn Thứ Mười Một",
						type: "Thị trấn",
					},
					{
						level3_id: "31021",
						name: "Xã Thuận Hoà",
						type: "Xã",
					},
					{
						level3_id: "31024",
						name: "Xã Đông Hòa",
						type: "Xã",
					},
					{
						level3_id: "31030",
						name: "Xã Đông Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31031",
						name: "Xã Tân Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31033",
						name: "Xã Đông Hưng",
						type: "Xã",
					},
					{
						level3_id: "31036",
						name: "Xã Đông Hưng A",
						type: "Xã",
					},
					{
						level3_id: "31039",
						name: "Xã Đông Hưng B",
						type: "Xã",
					},
					{
						level3_id: "31042",
						name: "Xã Vân Khánh",
						type: "Xã",
					},
					{
						level3_id: "31045",
						name: "Xã Vân Khánh Đông",
						type: "Xã",
					},
					{
						level3_id: "31048",
						name: "Xã Vân Khánh Tây",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "910",
				name: "Huyện Vĩnh Thuận",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31051",
						name: "Thị trấn Vĩnh Thuận",
						type: "Thị trấn",
					},
					{
						level3_id: "31060",
						name: "Xã Vĩnh Bình Bắc",
						type: "Xã",
					},
					{
						level3_id: "31063",
						name: "Xã Vĩnh Bình Nam",
						type: "Xã",
					},
					{
						level3_id: "31064",
						name: "Xã Bình Minh",
						type: "Xã",
					},
					{
						level3_id: "31069",
						name: "Xã Vĩnh Thuận",
						type: "Xã",
					},
					{
						level3_id: "31072",
						name: "Xã Tân Thuận",
						type: "Xã",
					},
					{
						level3_id: "31074",
						name: "Xã Phong Đông",
						type: "Xã",
					},
					{
						level3_id: "31075",
						name: "Xã Vĩnh Phong",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "911",
				name: "Thành phố Phú Quốc",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "31078",
						name: "Phường Dương Đông",
						type: "Phường",
					},
					{
						level3_id: "31081",
						name: "Phường An Thới",
						type: "Phường",
					},
					{
						level3_id: "31084",
						name: "Xã Cửa Cạn",
						type: "Xã",
					},
					{
						level3_id: "31087",
						name: "Xã Gành Dầu",
						type: "Xã",
					},
					{
						level3_id: "31090",
						name: "Xã Cửa Dương",
						type: "Xã",
					},
					{
						level3_id: "31093",
						name: "Xã Hàm Ninh",
						type: "Xã",
					},
					{
						level3_id: "31096",
						name: "Xã Dương Tơ",
						type: "Xã",
					},
					{
						level3_id: "31102",
						name: "Xã Bãi Thơm",
						type: "Xã",
					},
					{
						level3_id: "31105",
						name: "Xã Thổ Châu",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "912",
				name: "Huyện Kiên Hải",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31108",
						name: "Xã Hòn Tre",
						type: "Xã",
					},
					{
						level3_id: "31111",
						name: "Xã Lại Sơn",
						type: "Xã",
					},
					{
						level3_id: "31114",
						name: "Xã An Sơn",
						type: "Xã",
					},
					{
						level3_id: "31115",
						name: "Xã Nam Du",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "913",
				name: "Huyện U Minh Thượng",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31012",
						name: "Xã Thạnh Yên",
						type: "Xã",
					},
					{
						level3_id: "31015",
						name: "Xã Thạnh Yên A",
						type: "Xã",
					},
					{
						level3_id: "31027",
						name: "Xã An Minh Bắc",
						type: "Xã",
					},
					{
						level3_id: "31054",
						name: "Xã Vĩnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "31057",
						name: "Xã Hoà Chánh",
						type: "Xã",
					},
					{
						level3_id: "31066",
						name: "Xã Minh Thuận",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "914",
				name: "Huyện Giang Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "30791",
						name: "Xã Vĩnh Phú",
						type: "Xã",
					},
					{
						level3_id: "30793",
						name: "Xã Vĩnh Điều",
						type: "Xã",
					},
					{
						level3_id: "30796",
						name: "Xã Tân Khánh Hòa",
						type: "Xã",
					},
					{
						level3_id: "30797",
						name: "Xã Phú Lợi",
						type: "Xã",
					},
					{
						level3_id: "30799",
						name: "Xã Phú Mỹ",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "92",
		name: "Thành phố Cần Thơ",
		type: "Thành phố Trung ương",
		level2s: [
			{
				level2_id: "916",
				name: "Quận Ninh Kiều",
				type: "Quận",
				level3s: [
					{
						level3_id: "31117",
						name: "Phường Cái Khế",
						type: "Phường",
					},
					{
						level3_id: "31120",
						name: "Phường An Hòa",
						type: "Phường",
					},
					{
						level3_id: "31123",
						name: "Phường Thới Bình",
						type: "Phường",
					},
					{
						level3_id: "31126",
						name: "Phường An Nghiệp",
						type: "Phường",
					},
					{
						level3_id: "31129",
						name: "Phường An Cư",
						type: "Phường",
					},
					{
						level3_id: "31135",
						name: "Phường Tân An",
						type: "Phường",
					},
					{
						level3_id: "31141",
						name: "Phường An Phú",
						type: "Phường",
					},
					{
						level3_id: "31144",
						name: "Phường Xuân Khánh",
						type: "Phường",
					},
					{
						level3_id: "31147",
						name: "Phường Hưng Lợi",
						type: "Phường",
					},
					{
						level3_id: "31149",
						name: "Phường An Khánh",
						type: "Phường",
					},
					{
						level3_id: "31150",
						name: "Phường An Bình",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "917",
				name: "Quận Ô Môn",
				type: "Quận",
				level3s: [
					{
						level3_id: "31153",
						name: "Phường Châu Văn Liêm",
						type: "Phường",
					},
					{
						level3_id: "31154",
						name: "Phường Thới Hòa",
						type: "Phường",
					},
					{
						level3_id: "31156",
						name: "Phường Thới Long",
						type: "Phường",
					},
					{
						level3_id: "31157",
						name: "Phường Long Hưng",
						type: "Phường",
					},
					{
						level3_id: "31159",
						name: "Phường Thới An",
						type: "Phường",
					},
					{
						level3_id: "31162",
						name: "Phường Phước Thới",
						type: "Phường",
					},
					{
						level3_id: "31165",
						name: "Phường Trường Lạc",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "918",
				name: "Quận Bình Thuỷ",
				type: "Quận",
				level3s: [
					{
						level3_id: "31168",
						name: "Phường Bình Thủy",
						type: "Phường",
					},
					{
						level3_id: "31169",
						name: "Phường Trà An",
						type: "Phường",
					},
					{
						level3_id: "31171",
						name: "Phường Trà Nóc",
						type: "Phường",
					},
					{
						level3_id: "31174",
						name: "Phường Thới An Đông",
						type: "Phường",
					},
					{
						level3_id: "31177",
						name: "Phường An Thới",
						type: "Phường",
					},
					{
						level3_id: "31178",
						name: "Phường Bùi Hữu Nghĩa",
						type: "Phường",
					},
					{
						level3_id: "31180",
						name: "Phường Long Hòa",
						type: "Phường",
					},
					{
						level3_id: "31183",
						name: "Phường Long Tuyền",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "919",
				name: "Quận Cái Răng",
				type: "Quận",
				level3s: [
					{
						level3_id: "31186",
						name: "Phường Lê Bình",
						type: "Phường",
					},
					{
						level3_id: "31189",
						name: "Phường Hưng Phú",
						type: "Phường",
					},
					{
						level3_id: "31192",
						name: "Phường Hưng Thạnh",
						type: "Phường",
					},
					{
						level3_id: "31195",
						name: "Phường Ba Láng",
						type: "Phường",
					},
					{
						level3_id: "31198",
						name: "Phường Thường Thạnh",
						type: "Phường",
					},
					{
						level3_id: "31201",
						name: "Phường Phú Thứ",
						type: "Phường",
					},
					{
						level3_id: "31204",
						name: "Phường Tân Phú",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "923",
				name: "Quận Thốt Nốt",
				type: "Quận",
				level3s: [
					{
						level3_id: "31207",
						name: "Phường Thốt Nốt",
						type: "Phường",
					},
					{
						level3_id: "31210",
						name: "Phường Thới Thuận",
						type: "Phường",
					},
					{
						level3_id: "31212",
						name: "Phường Thuận An",
						type: "Phường",
					},
					{
						level3_id: "31213",
						name: "Phường Tân Lộc",
						type: "Phường",
					},
					{
						level3_id: "31216",
						name: "Phường Trung Nhứt",
						type: "Phường",
					},
					{
						level3_id: "31217",
						name: "Phường Thạnh Hoà",
						type: "Phường",
					},
					{
						level3_id: "31219",
						name: "Phường Trung Kiên",
						type: "Phường",
					},
					{
						level3_id: "31227",
						name: "Phường Tân Hưng",
						type: "Phường",
					},
					{
						level3_id: "31228",
						name: "Phường Thuận Hưng",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "924",
				name: "Huyện Vĩnh Thạnh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31211",
						name: "Xã Vĩnh Bình",
						type: "Xã",
					},
					{
						level3_id: "31231",
						name: "Thị trấn Thanh An",
						type: "Thị trấn",
					},
					{
						level3_id: "31232",
						name: "Thị trấn Vĩnh Thạnh",
						type: "Thị trấn",
					},
					{
						level3_id: "31234",
						name: "Xã Thạnh Mỹ",
						type: "Xã",
					},
					{
						level3_id: "31237",
						name: "Xã Vĩnh Trinh",
						type: "Xã",
					},
					{
						level3_id: "31240",
						name: "Xã Thạnh An",
						type: "Xã",
					},
					{
						level3_id: "31241",
						name: "Xã Thạnh Tiến",
						type: "Xã",
					},
					{
						level3_id: "31243",
						name: "Xã Thạnh Thắng",
						type: "Xã",
					},
					{
						level3_id: "31244",
						name: "Xã Thạnh Lợi",
						type: "Xã",
					},
					{
						level3_id: "31246",
						name: "Xã Thạnh Qưới",
						type: "Xã",
					},
					{
						level3_id: "31252",
						name: "Xã Thạnh Lộc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "925",
				name: "Huyện Cờ Đỏ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31222",
						name: "Xã Trung An",
						type: "Xã",
					},
					{
						level3_id: "31225",
						name: "Xã Trung Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31249",
						name: "Xã Thạnh Phú",
						type: "Xã",
					},
					{
						level3_id: "31255",
						name: "Xã Trung Hưng",
						type: "Xã",
					},
					{
						level3_id: "31261",
						name: "Thị trấn Cờ Đỏ",
						type: "Thị trấn",
					},
					{
						level3_id: "31264",
						name: "Xã Thới Hưng",
						type: "Xã",
					},
					{
						level3_id: "31273",
						name: "Xã Đông Hiệp",
						type: "Xã",
					},
					{
						level3_id: "31274",
						name: "Xã Đông Thắng",
						type: "Xã",
					},
					{
						level3_id: "31276",
						name: "Xã Thới Đông",
						type: "Xã",
					},
					{
						level3_id: "31277",
						name: "Xã Thới Xuân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "926",
				name: "Huyện Phong Điền",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31299",
						name: "Thị trấn Phong Điền",
						type: "Thị trấn",
					},
					{
						level3_id: "31300",
						name: "Xã Nhơn Ái",
						type: "Xã",
					},
					{
						level3_id: "31303",
						name: "Xã Giai Xuân",
						type: "Xã",
					},
					{
						level3_id: "31306",
						name: "Xã Tân Thới",
						type: "Xã",
					},
					{
						level3_id: "31309",
						name: "Xã Trường Long",
						type: "Xã",
					},
					{
						level3_id: "31312",
						name: "Xã Mỹ Khánh",
						type: "Xã",
					},
					{
						level3_id: "31315",
						name: "Xã Nhơn Nghĩa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "927",
				name: "Huyện Thới Lai",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31258",
						name: "Thị trấn Thới Lai",
						type: "Thị trấn",
					},
					{
						level3_id: "31267",
						name: "Xã Thới Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31268",
						name: "Xã Tân Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31270",
						name: "Xã Xuân Thắng",
						type: "Xã",
					},
					{
						level3_id: "31279",
						name: "Xã Đông Bình",
						type: "Xã",
					},
					{
						level3_id: "31282",
						name: "Xã Đông Thuận",
						type: "Xã",
					},
					{
						level3_id: "31285",
						name: "Xã Thới Tân",
						type: "Xã",
					},
					{
						level3_id: "31286",
						name: "Xã Trường Thắng",
						type: "Xã",
					},
					{
						level3_id: "31288",
						name: "Xã Định Môn",
						type: "Xã",
					},
					{
						level3_id: "31291",
						name: "Xã Trường Thành",
						type: "Xã",
					},
					{
						level3_id: "31294",
						name: "Xã Trường Xuân",
						type: "Xã",
					},
					{
						level3_id: "31297",
						name: "Xã Trường Xuân A",
						type: "Xã",
					},
					{
						level3_id: "31298",
						name: "Xã Trường Xuân B",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "93",
		name: "Tỉnh Hậu Giang",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "930",
				name: "Thành phố Vị Thanh",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "31318",
						name: "Phường I",
						type: "Phường",
					},
					{
						level3_id: "31321",
						name: "Phường III",
						type: "Phường",
					},
					{
						level3_id: "31324",
						name: "Phường IV",
						type: "Phường",
					},
					{
						level3_id: "31327",
						name: "Phường V",
						type: "Phường",
					},
					{
						level3_id: "31330",
						name: "Phường VII",
						type: "Phường",
					},
					{
						level3_id: "31333",
						name: "Xã Vị Tân",
						type: "Xã",
					},
					{
						level3_id: "31336",
						name: "Xã Hoả Lựu",
						type: "Xã",
					},
					{
						level3_id: "31338",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "31339",
						name: "Xã Hoả Tiến",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "931",
				name: "Thành phố Ngã Bảy",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "31340",
						name: "Phường Ngã Bảy",
						type: "Phường",
					},
					{
						level3_id: "31341",
						name: "Phường Lái Hiếu",
						type: "Phường",
					},
					{
						level3_id: "31343",
						name: "Phường Hiệp Thành",
						type: "Phường",
					},
					{
						level3_id: "31344",
						name: "Phường Hiệp Lợi",
						type: "Phường",
					},
					{
						level3_id: "31411",
						name: "Xã Đại Thành",
						type: "Xã",
					},
					{
						level3_id: "31414",
						name: "Xã Tân Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "932",
				name: "Huyện Châu Thành A",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31342",
						name: "Thị trấn Một Ngàn",
						type: "Thị trấn",
					},
					{
						level3_id: "31345",
						name: "Xã Tân Hoà",
						type: "Xã",
					},
					{
						level3_id: "31346",
						name: "Thị trấn Bảy Ngàn",
						type: "Thị trấn",
					},
					{
						level3_id: "31348",
						name: "Xã Trường Long Tây",
						type: "Xã",
					},
					{
						level3_id: "31351",
						name: "Xã Trường Long A",
						type: "Xã",
					},
					{
						level3_id: "31357",
						name: "Xã Nhơn Nghĩa A",
						type: "Xã",
					},
					{
						level3_id: "31359",
						name: "Thị trấn Rạch Gòi",
						type: "Thị trấn",
					},
					{
						level3_id: "31360",
						name: "Xã Thạnh Xuân",
						type: "Xã",
					},
					{
						level3_id: "31362",
						name: "Thị trấn Cái Tắc",
						type: "Thị trấn",
					},
					{
						level3_id: "31363",
						name: "Xã Tân Phú Thạnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "933",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31366",
						name: "Thị Trấn Ngã Sáu",
						type: "Thị trấn",
					},
					{
						level3_id: "31369",
						name: "Xã Đông Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31375",
						name: "Xã Đông Phú",
						type: "Xã",
					},
					{
						level3_id: "31378",
						name: "Xã Phú Hữu",
						type: "Xã",
					},
					{
						level3_id: "31379",
						name: "Xã Phú Tân",
						type: "Xã",
					},
					{
						level3_id: "31381",
						name: "Thị trấn Mái Dầm",
						type: "Thị trấn",
					},
					{
						level3_id: "31384",
						name: "Xã Đông Phước",
						type: "Xã",
					},
					{
						level3_id: "31387",
						name: "Xã Đông Phước A",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "934",
				name: "Huyện Phụng Hiệp",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31393",
						name: "Thị trấn Kinh Cùng",
						type: "Thị trấn",
					},
					{
						level3_id: "31396",
						name: "Thị trấn Cây Dương",
						type: "Thị trấn",
					},
					{
						level3_id: "31399",
						name: "Xã Tân Bình",
						type: "Xã",
					},
					{
						level3_id: "31402",
						name: "Xã Bình Thành",
						type: "Xã",
					},
					{
						level3_id: "31405",
						name: "Xã Thạnh Hòa",
						type: "Xã",
					},
					{
						level3_id: "31408",
						name: "Xã Long Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31417",
						name: "Xã Phụng Hiệp",
						type: "Xã",
					},
					{
						level3_id: "31420",
						name: "Xã Hòa Mỹ",
						type: "Xã",
					},
					{
						level3_id: "31423",
						name: "Xã Hòa An",
						type: "Xã",
					},
					{
						level3_id: "31426",
						name: "Xã Phương Bình",
						type: "Xã",
					},
					{
						level3_id: "31429",
						name: "Xã Hiệp Hưng",
						type: "Xã",
					},
					{
						level3_id: "31432",
						name: "Xã Tân Phước Hưng",
						type: "Xã",
					},
					{
						level3_id: "31433",
						name: "Thị trấn Búng Tàu",
						type: "Thị trấn",
					},
					{
						level3_id: "31435",
						name: "Xã Phương Phú",
						type: "Xã",
					},
					{
						level3_id: "31438",
						name: "Xã Tân Long",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "935",
				name: "Huyện Vị Thuỷ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31441",
						name: "Thị trấn Nàng Mau",
						type: "Thị trấn",
					},
					{
						level3_id: "31444",
						name: "Xã Vị Trung",
						type: "Xã",
					},
					{
						level3_id: "31447",
						name: "Xã Vị Thuỷ",
						type: "Xã",
					},
					{
						level3_id: "31450",
						name: "Xã Vị Thắng",
						type: "Xã",
					},
					{
						level3_id: "31453",
						name: "Xã Vĩnh Thuận Tây",
						type: "Xã",
					},
					{
						level3_id: "31456",
						name: "Xã Vĩnh Trung",
						type: "Xã",
					},
					{
						level3_id: "31459",
						name: "Xã Vĩnh Tường",
						type: "Xã",
					},
					{
						level3_id: "31462",
						name: "Xã Vị Đông",
						type: "Xã",
					},
					{
						level3_id: "31465",
						name: "Xã Vị Thanh",
						type: "Xã",
					},
					{
						level3_id: "31468",
						name: "Xã Vị Bình",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "936",
				name: "Huyện Long Mỹ",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31483",
						name: "Xã Thuận Hưng",
						type: "Xã",
					},
					{
						level3_id: "31484",
						name: "Xã Thuận Hòa",
						type: "Xã",
					},
					{
						level3_id: "31486",
						name: "Xã Vĩnh Thuận Đông",
						type: "Xã",
					},
					{
						level3_id: "31489",
						name: "Thị trấn Vĩnh Viễn",
						type: "Thị trấn",
					},
					{
						level3_id: "31490",
						name: "Xã Vĩnh Viễn A",
						type: "Xã",
					},
					{
						level3_id: "31492",
						name: "Xã Lương Tâm",
						type: "Xã",
					},
					{
						level3_id: "31493",
						name: "Xã Lương Nghĩa",
						type: "Xã",
					},
					{
						level3_id: "31495",
						name: "Xã Xà Phiên",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "937",
				name: "Thị xã Long Mỹ",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "31471",
						name: "Phường Thuận An",
						type: "Phường",
					},
					{
						level3_id: "31472",
						name: "Phường Trà Lồng",
						type: "Phường",
					},
					{
						level3_id: "31473",
						name: "Phường Bình Thạnh",
						type: "Phường",
					},
					{
						level3_id: "31474",
						name: "Xã Long Bình",
						type: "Xã",
					},
					{
						level3_id: "31475",
						name: "Phường Vĩnh Tường",
						type: "Phường",
					},
					{
						level3_id: "31477",
						name: "Xã Long Trị",
						type: "Xã",
					},
					{
						level3_id: "31478",
						name: "Xã Long Trị A",
						type: "Xã",
					},
					{
						level3_id: "31480",
						name: "Xã Long Phú",
						type: "Xã",
					},
					{
						level3_id: "31481",
						name: "Xã Tân Phú",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "94",
		name: "Tỉnh Sóc Trăng",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "941",
				name: "Thành phố Sóc Trăng",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "31498",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "31501",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "31504",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "31507",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "31510",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "31513",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "31516",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "31519",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "31522",
						name: "Phường 9",
						type: "Phường",
					},
					{
						level3_id: "31525",
						name: "Phường 10",
						type: "Phường",
					},
				],
			},
			{
				level2_id: "942",
				name: "Huyện Châu Thành",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31569",
						name: "Thị trấn Châu Thành",
						type: "Thị trấn",
					},
					{
						level3_id: "31570",
						name: "Xã Hồ Đắc Kiện",
						type: "Xã",
					},
					{
						level3_id: "31573",
						name: "Xã Phú Tâm",
						type: "Xã",
					},
					{
						level3_id: "31576",
						name: "Xã Thuận Hòa",
						type: "Xã",
					},
					{
						level3_id: "31582",
						name: "Xã Phú Tân",
						type: "Xã",
					},
					{
						level3_id: "31585",
						name: "Xã Thiện Mỹ",
						type: "Xã",
					},
					{
						level3_id: "31594",
						name: "Xã An Hiệp",
						type: "Xã",
					},
					{
						level3_id: "31600",
						name: "Xã An Ninh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "943",
				name: "Huyện Kế Sách",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31528",
						name: "Thị trấn Kế Sách",
						type: "Thị trấn",
					},
					{
						level3_id: "31531",
						name: "Thị trấn An Lạc Thôn",
						type: "Thị trấn",
					},
					{
						level3_id: "31534",
						name: "Xã Xuân Hòa",
						type: "Xã",
					},
					{
						level3_id: "31537",
						name: "Xã Phong Nẫm",
						type: "Xã",
					},
					{
						level3_id: "31540",
						name: "Xã An Lạc Tây",
						type: "Xã",
					},
					{
						level3_id: "31543",
						name: "Xã Trinh Phú",
						type: "Xã",
					},
					{
						level3_id: "31546",
						name: "Xã Ba Trinh",
						type: "Xã",
					},
					{
						level3_id: "31549",
						name: "Xã Thới An Hội",
						type: "Xã",
					},
					{
						level3_id: "31552",
						name: "Xã Nhơn Mỹ",
						type: "Xã",
					},
					{
						level3_id: "31555",
						name: "Xã Kế Thành",
						type: "Xã",
					},
					{
						level3_id: "31558",
						name: "Xã Kế An",
						type: "Xã",
					},
					{
						level3_id: "31561",
						name: "Xã Đại Hải",
						type: "Xã",
					},
					{
						level3_id: "31564",
						name: "Xã An Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "944",
				name: "Huyện Mỹ Tú",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31567",
						name: "Thị trấn Huỳnh Hữu Nghĩa",
						type: "Thị trấn",
					},
					{
						level3_id: "31579",
						name: "Xã Long Hưng",
						type: "Xã",
					},
					{
						level3_id: "31588",
						name: "Xã Hưng Phú",
						type: "Xã",
					},
					{
						level3_id: "31591",
						name: "Xã Mỹ Hương",
						type: "Xã",
					},
					{
						level3_id: "31597",
						name: "Xã Mỹ Tú",
						type: "Xã",
					},
					{
						level3_id: "31603",
						name: "Xã Mỹ Phước",
						type: "Xã",
					},
					{
						level3_id: "31606",
						name: "Xã Thuận Hưng",
						type: "Xã",
					},
					{
						level3_id: "31609",
						name: "Xã Mỹ Thuận",
						type: "Xã",
					},
					{
						level3_id: "31612",
						name: "Xã Phú Mỹ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "945",
				name: "Huyện Cù Lao Dung",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31615",
						name: "Thị trấn Cù Lao Dung",
						type: "Thị trấn",
					},
					{
						level3_id: "31618",
						name: "Xã An Thạnh 1",
						type: "Xã",
					},
					{
						level3_id: "31621",
						name: "Xã An Thạnh Tây",
						type: "Xã",
					},
					{
						level3_id: "31624",
						name: "Xã An Thạnh Đông",
						type: "Xã",
					},
					{
						level3_id: "31627",
						name: "Xã Đại Ân 1",
						type: "Xã",
					},
					{
						level3_id: "31630",
						name: "Xã An Thạnh 2",
						type: "Xã",
					},
					{
						level3_id: "31633",
						name: "Xã An Thạnh 3",
						type: "Xã",
					},
					{
						level3_id: "31636",
						name: "Xã An Thạnh Nam",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "946",
				name: "Huyện Long Phú",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31639",
						name: "Thị trấn Long Phú",
						type: "Thị trấn",
					},
					{
						level3_id: "31642",
						name: "Xã Song Phụng",
						type: "Xã",
					},
					{
						level3_id: "31645",
						name: "Thị trấn Đại Ngãi",
						type: "Thị trấn",
					},
					{
						level3_id: "31648",
						name: "Xã Hậu Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31651",
						name: "Xã Long Đức",
						type: "Xã",
					},
					{
						level3_id: "31654",
						name: "Xã Trường Khánh",
						type: "Xã",
					},
					{
						level3_id: "31657",
						name: "Xã Phú Hữu",
						type: "Xã",
					},
					{
						level3_id: "31660",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "31663",
						name: "Xã Châu Khánh",
						type: "Xã",
					},
					{
						level3_id: "31666",
						name: "Xã Tân Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31669",
						name: "Xã Long Phú",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "947",
				name: "Huyện Mỹ Xuyên",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31684",
						name: "Thị trấn Mỹ Xuyên",
						type: "Thị trấn",
					},
					{
						level3_id: "31690",
						name: "Xã Đại Tâm",
						type: "Xã",
					},
					{
						level3_id: "31693",
						name: "Xã Tham Đôn",
						type: "Xã",
					},
					{
						level3_id: "31708",
						name: "Xã Thạnh Phú",
						type: "Xã",
					},
					{
						level3_id: "31711",
						name: "Xã Ngọc Đông",
						type: "Xã",
					},
					{
						level3_id: "31714",
						name: "Xã Thạnh Quới",
						type: "Xã",
					},
					{
						level3_id: "31717",
						name: "Xã Hòa Tú 1",
						type: "Xã",
					},
					{
						level3_id: "31720",
						name: "Xã Gia Hòa 1",
						type: "Xã",
					},
					{
						level3_id: "31723",
						name: "Xã Ngọc Tố",
						type: "Xã",
					},
					{
						level3_id: "31726",
						name: "Xã Gia Hòa 2",
						type: "Xã",
					},
					{
						level3_id: "31729",
						name: "Xã Hòa Tú II",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "948",
				name: "Thị xã Ngã Năm",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "31732",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "31735",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "31738",
						name: "Xã Vĩnh Quới",
						type: "Xã",
					},
					{
						level3_id: "31741",
						name: "Xã Tân Long",
						type: "Xã",
					},
					{
						level3_id: "31744",
						name: "Xã Long Bình",
						type: "Xã",
					},
					{
						level3_id: "31747",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "31750",
						name: "Xã Mỹ Bình",
						type: "Xã",
					},
					{
						level3_id: "31753",
						name: "Xã Mỹ Quới",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "949",
				name: "Huyện Thạnh Trị",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31756",
						name: "Thị trấn Phú Lộc",
						type: "Thị trấn",
					},
					{
						level3_id: "31757",
						name: "Thị trấn Hưng Lợi",
						type: "Thị trấn",
					},
					{
						level3_id: "31759",
						name: "Xã Lâm Tân",
						type: "Xã",
					},
					{
						level3_id: "31762",
						name: "Xã Thạnh Tân",
						type: "Xã",
					},
					{
						level3_id: "31765",
						name: "Xã Lâm Kiết",
						type: "Xã",
					},
					{
						level3_id: "31768",
						name: "Xã Tuân Tức",
						type: "Xã",
					},
					{
						level3_id: "31771",
						name: "Xã Vĩnh Thành",
						type: "Xã",
					},
					{
						level3_id: "31774",
						name: "Xã Thạnh Trị",
						type: "Xã",
					},
					{
						level3_id: "31777",
						name: "Xã Vĩnh Lợi",
						type: "Xã",
					},
					{
						level3_id: "31780",
						name: "Xã Châu Hưng",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "950",
				name: "Thị xã Vĩnh Châu",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "31783",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "31786",
						name: "Xã Hòa Đông",
						type: "Xã",
					},
					{
						level3_id: "31789",
						name: "Phường Khánh Hòa",
						type: "Phường",
					},
					{
						level3_id: "31792",
						name: "Xã Vĩnh Hiệp",
						type: "Xã",
					},
					{
						level3_id: "31795",
						name: "Xã Vĩnh Hải",
						type: "Xã",
					},
					{
						level3_id: "31798",
						name: "Xã Lạc Hòa",
						type: "Xã",
					},
					{
						level3_id: "31801",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "31804",
						name: "Phường Vĩnh Phước",
						type: "Phường",
					},
					{
						level3_id: "31807",
						name: "Xã Vĩnh Tân",
						type: "Xã",
					},
					{
						level3_id: "31810",
						name: "Xã Lai Hòa",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "951",
				name: "Huyện Trần Đề",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31672",
						name: "Xã Đại Ân  2",
						type: "Xã",
					},
					{
						level3_id: "31673",
						name: "Thị trấn Trần Đề",
						type: "Thị trấn",
					},
					{
						level3_id: "31675",
						name: "Xã Liêu Tú",
						type: "Xã",
					},
					{
						level3_id: "31678",
						name: "Xã Lịch Hội Thượng",
						type: "Xã",
					},
					{
						level3_id: "31679",
						name: "Thị trấn Lịch Hội Thượng",
						type: "Thị trấn",
					},
					{
						level3_id: "31681",
						name: "Xã Trung Bình",
						type: "Xã",
					},
					{
						level3_id: "31687",
						name: "Xã Tài Văn",
						type: "Xã",
					},
					{
						level3_id: "31696",
						name: "Xã Viên An",
						type: "Xã",
					},
					{
						level3_id: "31699",
						name: "Xã Thạnh Thới An",
						type: "Xã",
					},
					{
						level3_id: "31702",
						name: "Xã Thạnh Thới Thuận",
						type: "Xã",
					},
					{
						level3_id: "31705",
						name: "Xã Viên Bình",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "95",
		name: "Tỉnh Bạc Liêu",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "954",
				name: "Thành phố Bạc Liêu",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "31813",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "31816",
						name: "Phường 3",
						type: "Phường",
					},
					{
						level3_id: "31819",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "31822",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "31825",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "31828",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "31831",
						name: "Phường Nhà Mát",
						type: "Phường",
					},
					{
						level3_id: "31834",
						name: "Xã Vĩnh Trạch",
						type: "Xã",
					},
					{
						level3_id: "31837",
						name: "Xã Vĩnh Trạch Đông",
						type: "Xã",
					},
					{
						level3_id: "31840",
						name: "Xã Hiệp Thành",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "956",
				name: "Huyện Hồng Dân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31843",
						name: "Thị trấn Ngan Dừa",
						type: "Thị trấn",
					},
					{
						level3_id: "31846",
						name: "Xã Ninh Quới",
						type: "Xã",
					},
					{
						level3_id: "31849",
						name: "Xã Ninh Quới A",
						type: "Xã",
					},
					{
						level3_id: "31852",
						name: "Xã Ninh Hòa",
						type: "Xã",
					},
					{
						level3_id: "31855",
						name: "Xã Lộc Ninh",
						type: "Xã",
					},
					{
						level3_id: "31858",
						name: "Xã Vĩnh Lộc",
						type: "Xã",
					},
					{
						level3_id: "31861",
						name: "Xã Vĩnh Lộc A",
						type: "Xã",
					},
					{
						level3_id: "31863",
						name: "Xã Ninh Thạnh Lợi A",
						type: "Xã",
					},
					{
						level3_id: "31864",
						name: "Xã Ninh Thạnh Lợi",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "957",
				name: "Huyện Phước Long",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31867",
						name: "Thị trấn Phước Long",
						type: "Thị trấn",
					},
					{
						level3_id: "31870",
						name: "Xã Vĩnh Phú Đông",
						type: "Xã",
					},
					{
						level3_id: "31873",
						name: "Xã Vĩnh Phú Tây",
						type: "Xã",
					},
					{
						level3_id: "31876",
						name: "Xã Phước Long",
						type: "Xã",
					},
					{
						level3_id: "31879",
						name: "Xã Hưng Phú",
						type: "Xã",
					},
					{
						level3_id: "31882",
						name: "Xã Vĩnh Thanh",
						type: "Xã",
					},
					{
						level3_id: "31885",
						name: "Xã Phong Thạnh Tây A",
						type: "Xã",
					},
					{
						level3_id: "31888",
						name: "Xã Phong Thạnh Tây B",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "958",
				name: "Huyện Vĩnh Lợi",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31894",
						name: "Xã Vĩnh Hưng",
						type: "Xã",
					},
					{
						level3_id: "31897",
						name: "Xã Vĩnh Hưng A",
						type: "Xã",
					},
					{
						level3_id: "31900",
						name: "Thị trấn Châu Hưng",
						type: "Thị trấn",
					},
					{
						level3_id: "31903",
						name: "Xã Châu Hưng A",
						type: "Xã",
					},
					{
						level3_id: "31906",
						name: "Xã Hưng Thành",
						type: "Xã",
					},
					{
						level3_id: "31909",
						name: "Xã Hưng Hội",
						type: "Xã",
					},
					{
						level3_id: "31912",
						name: "Xã Châu Thới",
						type: "Xã",
					},
					{
						level3_id: "31921",
						name: "Xã Long Thạnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "959",
				name: "Thị xã Giá Rai",
				type: "Thị xã",
				level3s: [
					{
						level3_id: "31942",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "31945",
						name: "Phường Hộ Phòng",
						type: "Phường",
					},
					{
						level3_id: "31948",
						name: "Xã Phong Thạnh Đông",
						type: "Xã",
					},
					{
						level3_id: "31951",
						name: "Phường Láng Tròn",
						type: "Phường",
					},
					{
						level3_id: "31954",
						name: "Xã Phong Tân",
						type: "Xã",
					},
					{
						level3_id: "31957",
						name: "Xã Tân Phong",
						type: "Xã",
					},
					{
						level3_id: "31960",
						name: "Xã Phong Thạnh",
						type: "Xã",
					},
					{
						level3_id: "31963",
						name: "Xã Phong Thạnh A",
						type: "Xã",
					},
					{
						level3_id: "31966",
						name: "Xã Phong Thạnh Tây",
						type: "Xã",
					},
					{
						level3_id: "31969",
						name: "Xã Tân Thạnh",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "960",
				name: "Huyện Đông Hải",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31972",
						name: "Thị trấn Gành Hào",
						type: "Thị trấn",
					},
					{
						level3_id: "31975",
						name: "Xã Long Điền Đông",
						type: "Xã",
					},
					{
						level3_id: "31978",
						name: "Xã Long Điền Đông A",
						type: "Xã",
					},
					{
						level3_id: "31981",
						name: "Xã Long Điền",
						type: "Xã",
					},
					{
						level3_id: "31984",
						name: "Xã Long Điền Tây",
						type: "Xã",
					},
					{
						level3_id: "31985",
						name: "Xã Điền Hải",
						type: "Xã",
					},
					{
						level3_id: "31987",
						name: "Xã An Trạch",
						type: "Xã",
					},
					{
						level3_id: "31988",
						name: "Xã An Trạch A",
						type: "Xã",
					},
					{
						level3_id: "31990",
						name: "Xã An Phúc",
						type: "Xã",
					},
					{
						level3_id: "31993",
						name: "Xã Định Thành",
						type: "Xã",
					},
					{
						level3_id: "31996",
						name: "Xã Định Thành A",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "961",
				name: "Huyện Hoà Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "31891",
						name: "Thị trấn Hòa Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "31915",
						name: "Xã Minh Diệu",
						type: "Xã",
					},
					{
						level3_id: "31918",
						name: "Xã Vĩnh Bình",
						type: "Xã",
					},
					{
						level3_id: "31924",
						name: "Xã Vĩnh Mỹ B",
						type: "Xã",
					},
					{
						level3_id: "31927",
						name: "Xã Vĩnh Hậu",
						type: "Xã",
					},
					{
						level3_id: "31930",
						name: "Xã Vĩnh Hậu A",
						type: "Xã",
					},
					{
						level3_id: "31933",
						name: "Xã Vĩnh Mỹ A",
						type: "Xã",
					},
					{
						level3_id: "31936",
						name: "Xã Vĩnh Thịnh",
						type: "Xã",
					},
				],
			},
		],
	},
	{
		level1_id: "96",
		name: "Tỉnh Cà Mau",
		type: "Tỉnh",
		level2s: [
			{
				level2_id: "964",
				name: "Thành phố Cà Mau",
				type: "Thành phố",
				level3s: [
					{
						level3_id: "31999",
						name: "Phường 9",
						type: "Phường",
					},
					{
						level3_id: "32002",
						name: "Phường 4",
						type: "Phường",
					},
					{
						level3_id: "32005",
						name: "Phường 1",
						type: "Phường",
					},
					{
						level3_id: "32008",
						name: "Phường 5",
						type: "Phường",
					},
					{
						level3_id: "32011",
						name: "Phường 2",
						type: "Phường",
					},
					{
						level3_id: "32014",
						name: "Phường 8",
						type: "Phường",
					},
					{
						level3_id: "32017",
						name: "Phường 6",
						type: "Phường",
					},
					{
						level3_id: "32020",
						name: "Phường 7",
						type: "Phường",
					},
					{
						level3_id: "32022",
						name: "Phường Tân Xuyên",
						type: "Phường",
					},
					{
						level3_id: "32023",
						name: "Xã An Xuyên",
						type: "Xã",
					},
					{
						level3_id: "32025",
						name: "Phường Tân Thành",
						type: "Phường",
					},
					{
						level3_id: "32026",
						name: "Xã Tân Thành",
						type: "Xã",
					},
					{
						level3_id: "32029",
						name: "Xã Tắc Vân",
						type: "Xã",
					},
					{
						level3_id: "32032",
						name: "Xã Lý Văn Lâm",
						type: "Xã",
					},
					{
						level3_id: "32035",
						name: "Xã Định Bình",
						type: "Xã",
					},
					{
						level3_id: "32038",
						name: "Xã Hòa Thành",
						type: "Xã",
					},
					{
						level3_id: "32041",
						name: "Xã Hòa Tân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "966",
				name: "Huyện U Minh",
				type: "Huyện",
				level3s: [
					{
						level3_id: "32044",
						name: "Thị trấn U Minh",
						type: "Thị trấn",
					},
					{
						level3_id: "32047",
						name: "Xã Khánh Hòa",
						type: "Xã",
					},
					{
						level3_id: "32048",
						name: "Xã Khánh Thuận",
						type: "Xã",
					},
					{
						level3_id: "32050",
						name: "Xã Khánh Tiến",
						type: "Xã",
					},
					{
						level3_id: "32053",
						name: "Xã Nguyễn Phích",
						type: "Xã",
					},
					{
						level3_id: "32056",
						name: "Xã Khánh Lâm",
						type: "Xã",
					},
					{
						level3_id: "32059",
						name: "Xã Khánh An",
						type: "Xã",
					},
					{
						level3_id: "32062",
						name: "Xã Khánh Hội",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "967",
				name: "Huyện Thới Bình",
				type: "Huyện",
				level3s: [
					{
						level3_id: "32065",
						name: "Thị trấn Thới Bình",
						type: "Thị trấn",
					},
					{
						level3_id: "32068",
						name: "Xã Biển Bạch",
						type: "Xã",
					},
					{
						level3_id: "32069",
						name: "Xã Tân Bằng",
						type: "Xã",
					},
					{
						level3_id: "32071",
						name: "Xã Trí Phải",
						type: "Xã",
					},
					{
						level3_id: "32072",
						name: "Xã Trí Lực",
						type: "Xã",
					},
					{
						level3_id: "32074",
						name: "Xã Biển Bạch Đông",
						type: "Xã",
					},
					{
						level3_id: "32077",
						name: "Xã Thới Bình",
						type: "Xã",
					},
					{
						level3_id: "32080",
						name: "Xã Tân Phú",
						type: "Xã",
					},
					{
						level3_id: "32083",
						name: "Xã Tân Lộc Bắc",
						type: "Xã",
					},
					{
						level3_id: "32086",
						name: "Xã Tân Lộc",
						type: "Xã",
					},
					{
						level3_id: "32089",
						name: "Xã Tân Lộc Đông",
						type: "Xã",
					},
					{
						level3_id: "32092",
						name: "Xã Hồ Thị Kỷ",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "968",
				name: "Huyện Trần Văn Thời",
				type: "Huyện",
				level3s: [
					{
						level3_id: "32095",
						name: "Thị trấn Trần Văn Thời",
						type: "Thị trấn",
					},
					{
						level3_id: "32098",
						name: "Thị trấn Sông Đốc",
						type: "Thị trấn",
					},
					{
						level3_id: "32101",
						name: "Xã Khánh Bình Tây Bắc",
						type: "Xã",
					},
					{
						level3_id: "32104",
						name: "Xã Khánh Bình Tây",
						type: "Xã",
					},
					{
						level3_id: "32107",
						name: "Xã Trần Hợi",
						type: "Xã",
					},
					{
						level3_id: "32108",
						name: "Xã Khánh Lộc",
						type: "Xã",
					},
					{
						level3_id: "32110",
						name: "Xã Khánh Bình",
						type: "Xã",
					},
					{
						level3_id: "32113",
						name: "Xã Khánh Hưng",
						type: "Xã",
					},
					{
						level3_id: "32116",
						name: "Xã Khánh Bình Đông",
						type: "Xã",
					},
					{
						level3_id: "32119",
						name: "Xã Khánh Hải",
						type: "Xã",
					},
					{
						level3_id: "32122",
						name: "Xã Lợi An",
						type: "Xã",
					},
					{
						level3_id: "32124",
						name: "Xã Phong Điền",
						type: "Xã",
					},
					{
						level3_id: "32125",
						name: "Xã Phong Lạc",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "969",
				name: "Huyện Cái Nước",
				type: "Huyện",
				level3s: [
					{
						level3_id: "32128",
						name: "Thị trấn Cái Nước",
						type: "Thị trấn",
					},
					{
						level3_id: "32130",
						name: "Xã Thạnh Phú",
						type: "Xã",
					},
					{
						level3_id: "32131",
						name: "Xã Lương Thế Trân",
						type: "Xã",
					},
					{
						level3_id: "32134",
						name: "Xã Phú Hưng",
						type: "Xã",
					},
					{
						level3_id: "32137",
						name: "Xã Tân Hưng",
						type: "Xã",
					},
					{
						level3_id: "32140",
						name: "Xã Hưng Mỹ",
						type: "Xã",
					},
					{
						level3_id: "32141",
						name: "Xã Hoà Mỹ",
						type: "Xã",
					},
					{
						level3_id: "32142",
						name: "Xã Đông Hưng",
						type: "Xã",
					},
					{
						level3_id: "32143",
						name: "Xã Đông Thới",
						type: "Xã",
					},
					{
						level3_id: "32146",
						name: "Xã Tân Hưng Đông",
						type: "Xã",
					},
					{
						level3_id: "32149",
						name: "Xã Trần Thới",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "970",
				name: "Huyện Đầm Dơi",
				type: "Huyện",
				level3s: [
					{
						level3_id: "32152",
						name: "Thị trấn Đầm Dơi",
						type: "Thị trấn",
					},
					{
						level3_id: "32155",
						name: "Xã Tạ An Khương",
						type: "Xã",
					},
					{
						level3_id: "32158",
						name: "Xã Tạ An Khương  Đông",
						type: "Xã",
					},
					{
						level3_id: "32161",
						name: "Xã Trần Phán",
						type: "Xã",
					},
					{
						level3_id: "32162",
						name: "Xã Tân Trung",
						type: "Xã",
					},
					{
						level3_id: "32164",
						name: "Xã Tân Đức",
						type: "Xã",
					},
					{
						level3_id: "32167",
						name: "Xã Tân Thuận",
						type: "Xã",
					},
					{
						level3_id: "32170",
						name: "Xã Tạ An Khương  Nam",
						type: "Xã",
					},
					{
						level3_id: "32173",
						name: "Xã Tân Duyệt",
						type: "Xã",
					},
					{
						level3_id: "32174",
						name: "Xã Tân Dân",
						type: "Xã",
					},
					{
						level3_id: "32176",
						name: "Xã Tân Tiến",
						type: "Xã",
					},
					{
						level3_id: "32179",
						name: "Xã Quách Phẩm Bắc",
						type: "Xã",
					},
					{
						level3_id: "32182",
						name: "Xã Quách Phẩm",
						type: "Xã",
					},
					{
						level3_id: "32185",
						name: "Xã Thanh Tùng",
						type: "Xã",
					},
					{
						level3_id: "32186",
						name: "Xã Ngọc Chánh",
						type: "Xã",
					},
					{
						level3_id: "32188",
						name: "Xã Nguyễn Huân",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "971",
				name: "Huyện Năm Căn",
				type: "Huyện",
				level3s: [
					{
						level3_id: "32191",
						name: "Thị Trấn Năm Căn",
						type: "Thị trấn",
					},
					{
						level3_id: "32194",
						name: "Xã Hàm Rồng",
						type: "Xã",
					},
					{
						level3_id: "32197",
						name: "Xã Hiệp Tùng",
						type: "Xã",
					},
					{
						level3_id: "32200",
						name: "Xã Đất Mới",
						type: "Xã",
					},
					{
						level3_id: "32201",
						name: "Xã Lâm Hải",
						type: "Xã",
					},
					{
						level3_id: "32203",
						name: "Xã Hàng Vịnh",
						type: "Xã",
					},
					{
						level3_id: "32206",
						name: "Xã Tam Giang",
						type: "Xã",
					},
					{
						level3_id: "32209",
						name: "Xã Tam Giang Đông",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "972",
				name: "Huyện Phú Tân",
				type: "Huyện",
				level3s: [
					{
						level3_id: "32212",
						name: "Thị trấn Cái Đôi Vàm",
						type: "Thị trấn",
					},
					{
						level3_id: "32214",
						name: "Xã Phú Thuận",
						type: "Xã",
					},
					{
						level3_id: "32215",
						name: "Xã Phú Mỹ",
						type: "Xã",
					},
					{
						level3_id: "32218",
						name: "Xã Phú Tân",
						type: "Xã",
					},
					{
						level3_id: "32221",
						name: "Xã Tân Hải",
						type: "Xã",
					},
					{
						level3_id: "32224",
						name: "Xã Việt Thắng",
						type: "Xã",
					},
					{
						level3_id: "32227",
						name: "Xã Tân Hưng Tây",
						type: "Xã",
					},
					{
						level3_id: "32228",
						name: "Xã Rạch Chèo",
						type: "Xã",
					},
					{
						level3_id: "32230",
						name: "Xã Nguyễn Việt Khái",
						type: "Xã",
					},
				],
			},
			{
				level2_id: "973",
				name: "Huyện Ngọc Hiển",
				type: "Huyện",
				level3s: [
					{
						level3_id: "32233",
						name: "Xã Tam Giang Tây",
						type: "Xã",
					},
					{
						level3_id: "32236",
						name: "Xã Tân Ân Tây",
						type: "Xã",
					},
					{
						level3_id: "32239",
						name: "Xã Viên An Đông",
						type: "Xã",
					},
					{
						level3_id: "32242",
						name: "Xã Viên An",
						type: "Xã",
					},
					{
						level3_id: "32244",
						name: "Thị trấn Rạch Gốc",
						type: "Thị trấn",
					},
					{
						level3_id: "32245",
						name: "Xã Tân Ân",
						type: "Xã",
					},
					{
						level3_id: "32248",
						name: "Xã Đất Mũi",
						type: "Xã",
					},
				],
			},
		],
	},
];
