import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import React, { useRef } from "react";
import { SnackbarUtilsConfigurator } from "./SnackbarUtilsConfigurator";

const useStyles = makeStyles((theme) => ({
	snackbar: {
		zIndex: "100000 !important",
	},
}));

const ToastProvider = (props) => {
	const notistackRef = useRef();
	const classes = useStyles();

	return (
		<SnackbarProvider
			maxSnack={1}
			anchorOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			classes={{ containerRoot: classes.snackbar }}
			ref={notistackRef}
			action={(key) => (
				<Button
					onClick={() => notistackRef.current.closeSnackbar(key)}
					style={{ color: "#fff", fontSize: "20px" }}
				>
					✖
				</Button>
			)}
		>
			<SnackbarUtilsConfigurator />
			{props.children}
		</SnackbarProvider>
	);
};

export { ToastProvider };
