import { Col, Divider, Image, Row, Typography } from "antd";
import React from "react";
import classnames from "classnames/bind";
import styles from "./DetailInformation.module.scss";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

const cx = classnames.bind(styles);
function DetailInformation({ customItem, data = [], dataId = null, setInformation }) {
	//Example DATA
	// const data = [
	// 	{
	// 		groupId: 1,
	// 		groupTitle: "Thông tin chính",
	// 		gridProps: {},
	// 		children: [
	// 			{
	// 				id: "id",
	// 				title: "Qr code",
	// 				value: "value nè",
	// 				component: Text,
	// 				childrenProps: {
	// 					//all Props component here
	// 				},
	// 			},
	// 			{
	// 				id: "id1",
	// 				title: "Hình ảnh",
	// 				value: "",
	// 				component: Image,
	// 				childrenProps: {
	// 					span: 24,
	// 					preview: false,
	// 					zIndex: 10000000,
	// 					width: 200,
	// 					src: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
	// 					//all Props component here
	// 				},
	// 			},
	// 		],
	// 	},
	// ];

	return (
		<div className={cx("wrapper")}>
			<div className={cx("content__container")}>
				{data.map((item) => (
					<React.Fragment key={item.groupId}>
						<Divider orientation={"left"}>
							<Title level={4}>{item.groupTitle}</Title>
						</Divider>
						<Row gutter={[32, 32]}>
							{item.children.map((child) => {
								const Component = child.component || Text;
								const { span = 6, ...props } = child.childrenProps;

								return (
									<Col span={span} key={child.id}>
										<div className={cx("content__item")} style={customItem}>
											<Title level={5}>{child.title}</Title>
											<Component {...props}>
												{<div dangerouslySetInnerHTML={{ __html: child.value }} /> || (child.value === null && "Không có dữ liệu")}
											</Component>
										</div>
									</Col>
								);
							})}
						</Row>
					</React.Fragment>
				))}
			</div>
		</div>
	);
}

export default DetailInformation;
