import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/es";
import { Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormLabel, Grid, MenuItem, Select, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import bannerAPI from "api/BannerAPI";
import uploadAPI from "api/UploadAPI";
import { useSnackbar } from "notistack";
import { useEffect, useLayoutEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UploadImage from "ui-component/UploadImage";

const TextValidation = styled("p")({
	color: "red",
});
const Required = styled("span")({
	color: "red",
});
function BannerModal({ ...props }) {
	const { isOpen, isClose, isLoading, formData, isUpdate, listSelect, getListTable } = props;
	//Xử lý listSelect thành dạng object Array

	const { register, handleSubmit, errors, control, reset, getValues, watch, setValue } = useForm({
		defaultValues: isUpdate && formData,
		mode: "onChange",
	});
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [showErr, setShowErr] = useState({ status: false, message: "" });
	const [ckData, setCkData] = useState("");
	const [type, setType] = useState("NOTIFICATION");

	const handleClose = () => {
		isClose(false);
	};
	const handleUpload = async (file) => {
		const formData = new FormData();
		formData.append("image", file);

		try {
			const response = await uploadAPI.uploadImage(formData, 2000);
			return response.results.object.url;
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};
	const onSubmit = async (data) => {
		if (!imageUrl.image) {
			return;
		}
		try {
			setLoading(true);

			if (!isUpdate) {
				data.square_image = "";
				data.type = "HOME";
				data.image = await handleUpload(imageUrl.image);
				data.image_web = "";
				const response = await bannerAPI.create(data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			} else {
				data.image =
					typeof imageUrl.image === "string" ? imageUrl.image : await handleUpload(imageUrl.image);

				data.type = "HOME";
				data.square_image = "";
				data.type = "HOME";
				data.image_web = "";
				data.image = await handleUpload(imageUrl.image);
				const response = await bannerAPI.update(formData.id, data);
				if (response.code === 200) {
					isClose(false);
					setLoading(false);
					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListTable();
				} else {
					enqueueSnackbar(t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			}
		} catch (error) {
			setShowErr({ state: true, message: error.message });
			setLoading(false);
		}
	};
	useEffect(() => {
		if (isUpdate) {
			setImageUrl({
				image: formData.image,
				square_image: formData.square_image,
				image_web: formData.image_web,
			});
		} else {
			setCkData("");
		}
		return () => {
			setImageUrl({ image: null, square_image: null, image_web: null });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const translator = (text) => {
		return `abeauty.categoryService.dialog_form.${text}`;
	};
	//Icon Image
	// const handleUpload = async () => {
	// 	const formData = new FormData();
	// 	formData.append("image", image);

	// 	try {
	// 		const response = await uploadAPI.uploadImage(formData);
	// 		return response.results.object.url;
	// 	} catch (error) {
	// 		console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
	// 	}
	// };
	const [imageUrl, setImageUrl] = useState({
		image: null,
		square_image: null,
		image_web: null,
	});

	const defaultErrImage = {
		image: {
			message: "",
		},
		image_web: {
			message: "",
		},
	};
	const [errImages, setErrImages] = useState(defaultErrImage);

	useLayoutEffect(() => {
		if (!imageUrl.image) {
			setErrImages((errImages.image.message = "Vui lòng chọn hình ảnh"));
		} else {
			setErrImages((errImages.image.message = ""));
		}
		if (!imageUrl.image_web) {
			setErrImages({
				...errImages,
				image_web: {
					message: "Vui lòng thêm ảnh này",
				},
			});
		} else {
			setErrImages({
				...errImages,
				image_web: {
					message: "",
				},
			});
		}
	}, [imageUrl]);

	//
	return (
		<div>
			<Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle
					sx={{
						fontSize: "18px",
						textAlign: "center",
					}}
					variant={"h3"}
				>
					{isUpdate ? "Cập nhật ảnh bìa" : "Tạo mới ảnh bìa"}
				</DialogTitle>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogContent>
						<Grid
							container
							spacing={1}
							style={{
								marginBottom: 20,
							}}
						>
							<Grid item xs={12}>
								<FormLabel component="legend">Tên banner</FormLabel>

								<Controller
									name="title"
									control={control}
									rules={{
										validate: {
											stringSpace: (v) =>
												!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
										},
										required: {
											value: true,
											message: "Vui lòng điền tên banner",
										},
									}}
									defaultValue={isUpdate ? formData.title : ""}
									as={
										<TextField
											hiddenLabel
											margin="dense"
											type="text"
											fullWidth
											size="small"
											variant="filled"
										/>
									}
								/>

								{errors.title && <TextValidation>{errors.title.message}</TextValidation>}
							</Grid>
							{/* <Grid item xs={12}>
								<FormLabel component="legend">Đường dẫn liên kết</FormLabel>

								<Controller
									name="url"
									control={control}
									// rules={{
									// 	validate: {
									// 		stringSpace: (v) =>
									// 			!v.startsWith(" ") || "Không thể bắt đầu bằng khoảng trắng",
									// 	},
									// 	required: {
									// 		value: true,
									// 		message: "Vui lòng điền tên danh mục",
									// 	},
									// }}
									defaultValue={isUpdate ? formData.url : ""}
									as={
										<TextField
											margin="dense"
											type="text"
											defaultValue={isUpdate ? formData.url : ""}
											fullWidth
											hiddenLabel
											minRows={2}
											variant="filled"
										/>
									}
								/>

								{errors.url && <TextValidation>{errors.url.message}</TextValidation>}
							</Grid> */}

							{/* <Grid item xs={6}>
								<FormLabel component="legend">Hoạt động</FormLabel>
								<Switch
									name="is_show"
									inputRef={register()}
									defaultChecked={isUpdate ? formData.is_show : false}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormLabel component="legend">Tình trạng</FormLabel>
								<Switch
									name="status"
									inputRef={register()}
									defaultChecked={isUpdate ? formData.status : false}
								/>
							</Grid> */}

							{/* <Grid item xs={12}>
								<FormLabel component="legend">Vị trí hiển thị</FormLabel>

								<Controller
									defaultValue={isUpdate ? formData?.type : "HOME"}
									as={
										<Select
											margin="dense"
											label={"Vị trí hiển thị"}
											type="text"
											fullWidth
											size="medium"
											hiddenLabel
											variant="filled"
										>
											<MenuItem value={"HOME"}>Trang chủ</MenuItem>
											<MenuItem value={"SERVICE"}>Trang dịch vụ</MenuItem>
										</Select>
									}
									name="type"
									control={control}
								/>
							</Grid> */}
							<Grid item xs={12}>
								<FormLabel component="legend">Thiết bị hiển thị</FormLabel>

								<Controller
									defaultValue={isUpdate ? formData?.show_on : "DESKTOP"}
									as={
										<Select
											margin="dense"
											label={"Thiết bị hiển thị"}
											type="text"
											fullWidth
											size="medium"
											hiddenLabel
											variant="filled"
										>
											<MenuItem value={"DESKTOP"}>Máy tính</MenuItem>
											{/* <MenuItem value={"TABLE"}>Table</MenuItem> */}
											<MenuItem value={"MOBILE"}>Điện thoại</MenuItem>
										</Select>
									}
									name="show_on"
									control={control}
								/>
							</Grid>

							<Grid item xs={12}>
								<FormLabel component="legend">Ảnh chính</FormLabel>

								<UploadImage
									image={imageUrl.image}
									setImage={(value) => setImageUrl({ ...imageUrl, image: value })}
									id="1"
									aspectRatio="16:9"
									levelAspectRatio={2}
								>
									<ul>
										<li>Định dạng file: [.JPEG, .JPG, .PNG]</li>
										<li>
											Tỉ lệ hình ảnh: {watch("show_on") === "DESKTOP" ? "1500x500" : "1029 × 480"}
										</li>
									</ul>
								</UploadImage>
								{errImages?.image && (
									<TextValidation style={{ margin: "5px", marginTop: "10px" }}>
										{errImages.image.message}
									</TextValidation>
								)}
							</Grid>
							{/* <Grid item xs={12}>
								<FormLabel component="legend">Ảnh web</FormLabel>
								<UploadImage
									image={imageUrl.image_web}
									setImage={(value) => setImageUrl({ ...imageUrl, image_web: value })}
									id="2"
									aspectRatio="16:9"
									levelAspectRatio={2}
								/>
								{errImages?.image_web && (
									<TextValidation style={{ margin: 3 }}>
										{errImages.image_web.message}
									</TextValidation>
								)}
							</Grid>
							<Grid item xs={12}>
								<FormLabel component="legend">Ảnh thumbnail</FormLabel>

								<UploadImage
									image={imageUrl.square_image}
									setImage={(value) => setImageUrl({ ...imageUrl, square_image: value })}
									id="3"
									aspectRatio="1:1"
									levelAspectRatio={4}
								/>
							</Grid> */}

							{/* <Grid item xs={4}>
								<FormLabel component="legend">Ảnh chính</FormLabel>
								<UploadImage
									image={image}
									setImage={setImage}
									aspectRatio={"16:9"}
									levelAspectRatio={1}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormLabel component="legend">Ảnh web</FormLabel>
								<UploadImage
									image={imageWeb}
									setImage={setImageWeb}
									aspectRatio={"16:9"}
									levelAspectRatio={1}
								/>
							</Grid> */}
						</Grid>
					</DialogContent>
					<DialogActions
						sx={{
							justifyContent: "center",
						}}
					>
						<LoadingButton
							type="submit"
							// endIcon={<SaveIcon />}
							loading={loading}
							loadingPosition="end"
							variant="contained"
							className="button__action"
						>
							{isUpdate ? t(translator("update")) : t(translator("create"))}
						</LoadingButton>
					</DialogActions>

					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showErr.state}
						onClose={() => setShowErr({ state: false })}
						// action={action}
						autoHideDuration={5000}
						severity="error"
					>
						<Alert severity="error">{showErr.message}</Alert>
					</Snackbar>
				</form>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default BannerModal;
