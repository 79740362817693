import {
	EditingState,
	GroupingState,
	IntegratedEditing,
	IntegratedGrouping,
	ViewState,
} from "@devexpress/dx-react-scheduler";
import {
	Appointments,
	AppointmentTooltip,
	DayView,
	GroupingPanel,
	Resources,
	Scheduler,
	Toolbar,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Radio } from "antd";
import bookingAPI from "api/BookingAPI";
import schedulerAPI from "api/SchedulerAPI";
import classnames from "classnames/bind";
import { SearchComponent } from "components/search";
import { ALERT_PERMISSION } from "constants/constant";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";
import useDebounce from "hooks/useDebounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { gridSpacing } from "store/constant";
import HeaderActions from "ui-component/Header-actions";
import BookingModal from "ui-component/modal-form/BookingModal";
import AppointmentCardContent from "./AppointmentCardContent";
import AppointmentContent from "./AppointmentContent";
import { activityList, priorityData } from "./fakeData";
import { convertToDataSchedulerForm } from "./func";
import styles from "./Scheduler.module.scss";
import TimeLineArea from "./TimeLineArea";

const cx = classnames.bind(styles);

//Loading Component
const PREFIX = "Demo";

const classes = {
	toolbarRoot: `${PREFIX}-toolbarRoot`,
	progress: `${PREFIX}-progress`,
};

const StyledDiv = styled("div")({
	[`&.${classes.toolbarRoot}`]: {
		position: "relative",
	},
});

const StyledLinearProgress = styled(LinearProgress)(() => ({
	[`&.${classes.progress}`]: {
		position: "absolute",
		width: "100%",
		bottom: 0,
		left: 0,
	},
}));

const ToolbarWithLoading = ({ children, ...restProps }) => (
	<StyledDiv className={classes.toolbarRoot}>
		<Toolbar.Root {...restProps}>{children}</Toolbar.Root>
		<StyledLinearProgress className={classes.progress} />
	</StyledDiv>
);

function MuiScheduler({ props }) {
	const { role } = useCustomAuth();
	const [schedulerState, setSchedulerState] = React.useState({
		data: [],
		resources: [
			{
				fieldName: "priorityId",
				title: "Priority",
				instances: priorityData,
			},
		],
		grouping: [
			{
				resourceName: "priorityId",
			},
		],
		groupByDate: "Day",
		isGroupByDate: true,
	});

	const commitChanges = ({ added, changed, deleted }) => {
		setSchedulerState((state) => {
			let { data } = state;
			if (added) {
				const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
				data = [...data, { id: startingAddedId, ...added }];
			}
			if (changed) {
				data = data.map((appointment) =>
					changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment
				);
			}
			if (deleted !== undefined) {
				data = data.filter((appointment) => appointment.id !== deleted);
			}
			return { data };
		});
	};

	const { data, resources, grouping, groupByDate, isGroupByDate } = schedulerState;

	const modalContext = React.useContext(ModalContext);

	const { enqueueSnackbar } = useSnackbar();

	const { t } = useTranslation();

	const [loading, setLoading] = React.useState(false);

	//State time line
	const [date, setDate] = React.useState(new Date());
	let startOfDay = moment(date).startOf("day").format("x");
	let endOfDay = moment(date).endOf("day").format("x");

	//Search

	const [searchValue, setSearchValue] = React.useState("");

	const handleChangeSearch = (value) => {
		setSearchValue(value);
	};

	const searchList = [
		{
			value: "name",
			title: "Tên khách hàng",
		},
		{
			value: "phone",
			title: "Số điện thoại",
		},
		{
			value: "booking_code",
			title: "Mã lịch",
		},
	];

	//Get list

	const debounceVal = useDebounce(searchValue);

	const [bookingCount, setBookingCount] = React.useState(0);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const getSchedulerList = async () => {
		setLoading(true);
		let filter = `{"time_start" :{"$between": [${startOfDay}, ${endOfDay}]}, "branch_id": "${branchActiveId}"}`;

		if (activity) {
			if (debounceVal) {
				filter = `{"$and": [{ "time_start": { "$between": [${startOfDay}, ${endOfDay}] } }, { "activity": "${activity}" }, {"branch_id": "${branchActiveId}"}, { "$or": [{ "name": { "$iLike": "%${debounceVal}%" } }, { "phone": { "$iLike": "%${debounceVal}%" } }, { "booking_code": { "$iLike": "%${debounceVal}%"}}]}]}`;
			} else {
				filter = `{"time_start" :{"$between": [${startOfDay}, ${endOfDay}]}, "activity": "${activity}", "branch_id": "${branchActiveId}"}`;
			}
		} else {
			if (debounceVal) {
				filter = `{"$and": [{ "time_start": { "$between": [${startOfDay}, ${endOfDay}] } },{"branch_id": "${branchActiveId}"} , { "$or": [{ "name": { "$iLike": "%${debounceVal}%" } }, { "phone": { "$iLike": "%${debounceVal}%" } }, { "booking_code": { "$iLike": "%${debounceVal}%"}}]}]}`;
			} else {
				filter = `{"time_start" :{"$between": [${startOfDay}, ${endOfDay}]}, "branch_id": "${branchActiveId}"}`;
			}
		}
		const fields = [
			"$all",
			{ user: ["name_admin_note", "fullname"] },
			{ booking_consultants: ["$all", { employee: ["$all"] }] },
			{ employee_sales: ["$all", { employee: ["$all"] }] },

			{
				booking_details: [
					"$all",
					{ service: ["$all", { unit: ["$all"] }] },
					{ order_item: ["$all", { combo_using: ["$all"] }] },
				],
			},
			{ booking_activities: ["$all"] },
			{ employee_confirm: ["fullname", "username"] },
		];
		const params = {
			fields: JSON.stringify(fields),
			filter,
			limit: 999,
		};
		try {
			const res = await bookingAPI.getList(params);
			const resData = res.results?.objects?.rows;
			const convertData = convertToDataSchedulerForm(resData);
			setSchedulerState((prev) => ({
				...prev,
				data: convertData,
			}));
			setBookingCount(res.results?.objects?.count);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const [formData, setFormData] = React.useState({});
	const getBedGroup = async () => {
		setLoading(true);

		const params = {
			fields: '["$all"]',
		};
		try {
			const res = await schedulerAPI.getBedList(params);
			const bedGroupConvert = res.results?.objects?.rows?.map((item) => ({
				id: item.id,
				text: item.name,
			}));
			const dataBed = [
				//Thêm giường chưa xác định
				{
					id: null,
					text: "Chưa xác định",
				},
				...bedGroupConvert,
			];

			setSchedulerState((prev) => ({
				...prev,
				resources: [
					{
						fieldName: "priorityId",
						title: "Priority",
						instances: dataBed,
					},
				],
			}));
		} catch (err) {
			console.log(err);
		}
	};
	//DELETE
	const handleOpenRemoveModal = (id, onHide) => {
		modalContext.showConfirmModal(() => handleDelete(id, onHide));
	};

	const handleDelete = async (id, onHide) => {
		try {
			await bookingAPI.removeMulti({
				items: JSON.stringify([id]),
			});
			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			onHide();

			await getSchedulerList();
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	//Create Booking logic
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		getBedGroup();
	}, []);
	//Filter activity

	const [activity, setActivity] = React.useState(null);
	const [isUpdate, setIsUpdate] = React.useState(false);
	const navigate = useNavigate();
	React.useEffect(() => {
		if (!branchActiveId) return;
		if (!role) return;
		if (role?.BOOKING_VIEW !== ROLE.BOOKING_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}

		getSchedulerList();
	}, [date, activity, debounceVal, branchActiveId, role]);

	const onEdit = (data) => {
		setIsUpdate(true);
		setOpen(true);
		setFormData(data);
	};

	return (
		<div className={cx("wrapper")}>
			{/* <GroupOrderSwitcher isGroupByDate={isGroupByDate} onChange={onGroupOrderChange} /> */}
			<Paper>
				<Box sx={{ padding: "20px" }}>
					<HeaderActions title={"Lịch hẹn theo ngày"} setIsUpdate={setIsUpdate} setOpen={setOpen} />
				</Box>
				<Grid
					container
					spacing={gridSpacing}
					sx={{ p: 3 }}
					// sx={{
					// 	display: "flex",
					// 	justifyContent: "space-between",
					// 	alignItems: "center",
					// 	padding: "20px",
					// }}
				>
					<Grid
						item
						xs={12}
						md={6}
						sx={{
							display: "flex",
							justifyContent: "flex-start",
						}}
					>
						<Typography variant="h4" sx={{ padding: "16px" }}>
							Tìm kiếm lịch hẹn:{" "}
						</Typography>

						<SearchComponent
							style={{ paddingLeft: "20px", flex: 1 }}
							styledSearchBox={{ width: "100%", height: "20px" }}
							dropdown={false}
							searchValue={searchValue}
							handleChangeSearch={handleChangeSearch}
							placeholder={"Tìm kiếm theo SĐT/Tên khách hàng/Mã lịch đặt"}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TimeLineArea
							onOpenCreateBooking={() => {
								setOpen(true);
								setIsUpdate(false);
							}}
							date={date}
							setDate={setDate}
						/>
					</Grid>
				</Grid>

				<Box>
					<Typography variant="h4" sx={{ padding: "20px 40px" }}>
						Lọc lịch hẹn theo trạng thái:
					</Typography>
					<Radio.Group
						name="radiogroup"
						value={activity}
						style={{ textAlign: "left", width: "100%", margin: "20px 40px", display: "block" }}
						onChange={(e) => {
							setActivity(e.target.value);
						}}
					>
						<Radio value={null}>Tất cả</Radio>
						{activityList.map((item) => (
							<Radio value={item.activity}>
								<span
									style={{
										display: item.isHide ? "none" : "inline-block",
										width: "10px",
										height: "10px",
										background: item.color,
										border: "1px solid black",
									}}
								></span>{" "}
								<span>{item.title}</span>
							</Radio>
						))}
					</Radio.Group>
				</Box>

				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						flexDirection: "row",
						width: "100%",
					}}
				>
					<Typography variant="h3" sx={{ padding: "10px 24px" }}>
						Tổng số lịch hẹn: {bookingCount}
					</Typography>
				</Box>
				<Scheduler data={data} height={660} className="scheduler__style">
					<ViewState currentDate={date} />
					<EditingState onCommitChanges={commitChanges} />
					<GroupingState grouping={grouping} groupByDate={(view) => view === "Day"} />

					<DayView />

					<Appointments
						appointmentContentComponent={(props) => <AppointmentCardContent propsTooltip={props} />}
					/>

					<Resources data={resources} mainResourceName="priorityId" />
					<IntegratedGrouping />
					<IntegratedEditing />

					<AppointmentTooltip
						contentComponent={(props) => <React.Fragment {...props}></React.Fragment>}
						headerComponent={(props) => (
							<AppointmentContent
								onDeleteConfirm={handleOpenRemoveModal}
								onEdit={onEdit}
								bedGroup={schedulerState.resources[0].instances}
								getSchedulerList={getSchedulerList}
								propsTooltip={props}
							/>
						)}
					/>

					<Toolbar {...(loading ? { rootComponent: ToolbarWithLoading } : null)} />
					{/* <ViewSwitcher /> */}
					<GroupingPanel />
					{/* <DragDropProvider /> */}
				</Scheduler>
			</Paper>
			<BookingModal
				getListTable={getSchedulerList}
				bedGroup={schedulerState.resources[0].instances}
				isUpdate={isUpdate}
				formData={formData}
				isOpen={open}
				isClose={(value) => setOpen(value)}
			/>
		</div>
	);
}

export default MuiScheduler;
