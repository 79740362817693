import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { InputNumber } from "antd";
import userAPI from "api/UserAPI";
import { formatVND, handleMaskValue, handleParseMask } from "func";
import { useSnackbar } from "notistack";
import React, { useContext, useRef, useState } from "react";
import styles from "../BookingModal.module.scss";
import classnames from "classnames/bind";
import { ModalContext } from "providers/ModalProvider";
import { ALERT_MESSAGE } from "constants/common";
import { useDispatch, useSelector } from "react-redux";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";
import { ROLE } from "constants/employeeRole";
import useCustomAuth from "hooks/useCustomAuth";

const cx = classnames.bind(styles);

function UserWallet({ userInfor, setUserInfor, isCanBook, bookingType, setBookingType }) {
	const [isEditWallet, setIsEditWallet] = useState(false);
	const [newWallet, setNewWallet] = useState(userInfor.wallet);
	const [loading, setLoading] = useState(false);
	const modalContext = useContext(ModalContext);

	const isChangeWallet = useRef(false);

	const updateWallet = async (userId) => {
		const res = await userAPI.update(userId, {
			wallet: newWallet,
		});
		return res;
	};

	const handleConfirmModal = () => {
		if (isChangeWallet.current) {
			modalContext.showDeleteConfirm(
				null,
				() => handleUpdateWallet(),
				ALERT_MESSAGE.CHANGE_WALLET,
				"Đồng ý"
			);
		} else {
			setIsEditWallet(false);
		}
	};

	const { enqueueSnackbar } = useSnackbar();
	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const dispatch = useDispatch();
	const handleUpdateWallet = async () => {
		setLoading(true);
		try {
			const res = await updateWallet(userInfor.id);
			enqueueSnackbar("Cập nhật hạn mức thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			setUserInfor(res.results?.object);
			dispatch(actGetWaitingRequest(branchActiveId));
			//Tránh trường hợp khách hàng đang đặt bằng ví thay đổi ví nhỏ hơn số dư dịch vụ vẫn đặt được booking
			if (bookingType === "WALLET") {
				setBookingType("CASH");
			}

			setLoading(false);
			setIsEditWallet(false);
		} catch (err) {
			enqueueSnackbar(err.message || "Có lỗi xảy ra vui lòng thử lại", {
				variant: "error",
				autoHideDuration: 2000,
			});
			setLoading(false);
		}
	};
	const { role } = useCustomAuth();
	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "16px 24px",
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						fontWeight: "bold",
					}}
				>
					Ví hạn mức dịch vụ của khách hàng
				</Typography>

				{isEditWallet ? (
					<InputNumber
						className={cx("custom__inputNum", "right")}
						style={{
							width: "fit-content",
						}}
						name="wallet"
						controls={false}
						formatter={(value) => handleMaskValue(value)}
						parser={(value) => handleParseMask(value)}
						min={0}
						defaultValue={userInfor.wallet}
						onChange={(e) => {
							if (e == userInfor.wallet) {
								isChangeWallet.current = false;
							} else {
								isChangeWallet.current = true;
							}

							setNewWallet(e);
						}}
					/>
				) : (
					<Typography
						sx={{
							fontSize: "16px",
							fontWeight: "bold",
						}}
					>
						{formatVND(userInfor.wallet)}
					</Typography>
				)}
			</Box>
			{!isCanBook && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						padding: "16px 24px",
					}}
				>
					<Typography variant={"h5"} color={"goldenrod"}>
						Hạn mức dịch vụ không đủ để thanh toán
					</Typography>
				</Box>
			)}

			{/* {!isEditWallet ? (
				ROLE.CUSTOMER_UPDATE_WALLET === role?.CUSTOMER_UPDATE_WALLET && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "16px 24px",
						}}
					>
						<Typography variant={"body2"}>
							Ghi chú cho quản trị viên: Nếu bạn thấy ví của khách hàng đang khác so với số tiền
							trong hồ sơ giấy, vui lòng cập nhật{" "}
							<Typography
								sx={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
								variant="span"
								component={"span"}
								onClick={() => setIsEditWallet(true)}
							>
								tại đây
							</Typography>
						</Typography>
					</Box>
				)
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						padding: "16px 24px",
					}}
				>
					<Button sx={{ mr: 2 }} variant="outlined" onClick={() => setIsEditWallet(false)}>
						Hủy
					</Button>
					<LoadingButton
						disabled={newWallet == userInfor.wallet}
						loading={loading}
						variant="contained"
						onClick={() => handleConfirmModal()}
					>
						Cập nhật
					</LoadingButton>
				</Box>
			)} */}
		</>
	);
}

export default UserWallet;
