import {
	Box,
	ClickAwayListener,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
} from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import PerfectScrollbar from "react-perfect-scrollbar";

import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import { useDispatch, useSelector } from "react-redux";
import { IconLogout, IconPlant2, IconHome } from "@tabler/icons";
import { useMatch, useNavigate } from "react-router";
import { logout } from "store/actions/EmployeeAction";
import { useSnackbar } from "notistack";

function ProfileTransitions({ open, handleClose, ...props }) {
	const theme = useTheme();
	const customization = useSelector((state) => state.customization);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const handleLogout = async () => {
		dispatch(
			logout((isSuccess) => {
				if (isSuccess) {
					navigate("/");
				} else {
					enqueueSnackbar("Logout error", {
						variant: "error",
						autoHideDuration: 3000,
					});
				}
			})
		);
	};

	return (
		<Transitions in={open} {...props}>
			<Paper>
				<ClickAwayListener onClickAway={handleClose}>
					<MainCard
						border={false}
						elevation={16}
						content={false}
						boxShadow
						shadow={theme.shadows[16]}
					>
						<Box
							sx={{
								"& .ps__rail-x, & .ps__rail-y": {
									display: "none !important",
								},
							}}
						>
							<PerfectScrollbar
								style={{
									height: "100%",
									maxHeight: "calc(100vh - 250px)",
									overflowX: "hidden",
								}}
							>
								<Box sx={{ p: 2 }}>
									<List
										component="nav"
										sx={{
											width: "100%",
											maxWidth: 350,
											minWidth: 300,
											backgroundColor: theme.palette.background.paper,
											borderRadius: "10px",
											[theme.breakpoints.down("md")]: {
												minWidth: "100%",
											},
											"& .MuiListItemButton-root": {
												mt: 0.5,
											},
										}}
									>
										<ListItemButton
											sx={{
												borderRadius: `${customization.borderRadius}px`,
											}}
											onClick={handleLogout}
										>
											<ListItemIcon>
												<IconLogout stroke={1.5} size="1.3rem" />
											</ListItemIcon>
											<ListItemText primary={<Typography variant="body2">Đăng xuất</Typography>} />
										</ListItemButton>
									</List>
								</Box>
							</PerfectScrollbar>
						</Box>
					</MainCard>
				</ClickAwayListener>
			</Paper>
		</Transitions>
	);
}

export default ProfileTransitions;
