import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames/bind";
import styles from "./Barcode.module.scss";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { formatVND } from "func";
import BarcodeGenerator from "react-barcode";

const cx = classnames.bind(styles);
function Barcode({ price, productName, qrCode, barCode }) {
	const barcodeAreaRef = useRef(null);

	return (
		<Stack
			className={cx("aspect__ratio")}
			style={{ padding: "1px" }}
			flexDirection={"column"}
			justifyContent={"center"}
		>
			<Box>
				<Typography variant="h5" style={{ fontSize: "7px" }} className={cx("product__name")}>
					{productName}
				</Typography>
			</Box>

			<Stack alignItems="center" ref={barcodeAreaRef} flexDirection="column">
				<BarcodeGenerator
					width={1}
					height={15}
					value={barCode}
					renderer={"img"}
					displayValue={false}
				/>
				<Typography
					style={{
						fontSize: "7px",
						fontWeight: "bold",
					}}
				>
					{barCode}
				</Typography>
				<Typography
					flex={1}
					style={{
						fontSize: "7px",
						fontWeight: "bold",
					}}
				>
					{formatVND(price)}
				</Typography>
			</Stack>
		</Stack>
	);
}

export default Barcode;
