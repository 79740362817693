export const orderStatus = [
	{
		label: "Đã giao",
		value: "COMPLETED",
	},
	{
		label: "Đã hủy",
		value: "CANCELED",
	},
];

export const bookingStatus = [
	{
		label: "Hoàn thành",
		value: "COMPLETED",
	},
	{
		label: "Hủy bỏ",
		value: "CANCELED",
	},
];
export const statusEnum = [
	{
		label: "Hoàn thành",
		value: "DONE",
	},
	{
		label: "Từ chối",
		value: "CANCEL",
	},
];
export const ownerStatus = [
	{
		label: "Thẻ cá nhân",
		value: "{ $ne: null }",
	},
	{
		label: "Thẻ Vbeauty & Spa",
		value: "{ $eq: null }",
	},
];

export const paymentStatus = [
	{
		label: "Tiền mặt",
		value: "CASH",
	},
	{
		label: "Quẹt thẻ",
		value: "CARD",
	},
	{
		label: "Chuyển khoản",
		value: "TRANSFER",
	},
	// {
	// 	label: "Hạn mức dịch vụ",
	// 	value: "WALLET",
	// },
];

export const bookingPayment = [
	{
		label: "Tiền mặt",
		value: "CASH",
	},
	{
		label: "Quẹt thẻ",
		value: "CARD",
	},
	{
		label: "Chuyển khoản",
		value: "TRANSFER",
	},
	{
		label: "Hạn mức dịch vụ",
		value: "WALLET",
	},
];

export const sale_date = [
	{
		label: "Tiền mặt",
		value: "CASH",
	},
	{
		label: "Quẹt thẻ",
		value: "CARD",
	},
	{
		label: "Chuyển khoản",
		value: "TRANSFER",
	},
	{
		label: "Hạn mức dịch vụ",
		value: "WALLET",
	},
];

export const typeTransaction = [
	{
		label: "Yêu cầu nạp tiền từ khách hàng",
		value: "REQUEST_DEPOSIT",
	},
	{
		label: "Yêu cầu nạp tiền từ nhân viên",
		value: "REQUEST_DEPOSIT_FOR_FIRST_TIME",
	},
];
