import { Stack } from "@mui/material";
import InputField from "components/modules/form/InputField";
import React from "react";

function SearchFilter({ data = [], ...props }) {
	return (
		<Stack gap={2}>
			{data.map((item) => (
				<InputField label={item.label} name={item.name} {...props} />
			))}
		</Stack>
	);
}

export default SearchFilter;
