import React from "react";
import classnames from "classnames/bind";
import styles from "../Scheduler.module.scss";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { EditOutlined, Schedule } from "@mui/icons-material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { activityList } from "../fakeData";
const cx = classnames.bind(styles);
function AppointmentCardContent({ propsTooltip }) {
	const { data, style, onClick } = propsTooltip;

	const switchColorFollowActivity = (activity) => {
		let color = "#f2f2f2";
		let textColor;
		activityList.map((item) => {
			if (item.activity === activity) {
				color = item.color;
				return color;
			}
		});
		return color;
	};
	const switchTextColorFollowActivity = (activity) => {
		let color = "black";
		activityList.map((item) => {
			if (item.isDark && item.activity === activity) {
				color = "#fff";
				return color;
			}
		});
		return color;
	};
	return (
		<div
			style={{
				background: switchColorFollowActivity(data?.activity),
				height: "100%",
				color: "white",
				overflowY: "auto",
				overflowX: "hidden",
			}}
			{...propsTooltip}
		>
			<Box
				sx={{
					padding: "5px",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					fontSize: "12px",
				}}
			>
				<Box>
					<Typography
						variant="h6"
						component="div"
						sx={{
							width: "100%",
							color: switchTextColorFollowActivity(data?.activity),
						}}
						gutterBottom
					>
						Mã: {data.booking_code}
					</Typography>
					<Typography
						sx={{
							color: switchTextColorFollowActivity(data?.activity),
						}}
						variant="h6"
						component={"div"}
						gutterBottom
					>
						<p>Nhân viên phục vụ:</p>
					</Typography>
					<ul style={{ padding: 0, color: switchTextColorFollowActivity(data?.activity) }}>
						{data.booking_details.map((item) => {
							return (
								<li>
									{item.employee_id ? (
										<p>
											{item.title} - {item.employee_info.fullname}
										</p>
									) : (
										<p>
											{item.title} - {"Chưa giao việc"}
										</p>
									)}
								</li>
							);
						})}
					</ul>
					{/* <Typography
						sx={{
							color: switchTextColorFollowActivity(data?.activity),
						}}
						variant="subtitle2"
						className={cx("content__item")}
						gutterBottom
					>
						<span>Thứ 6, 06/07/2022</span>
					</Typography>
					<Typography
						sx={{
							color: switchTextColorFollowActivity(data?.activity),
						}}
						variant="subtitle2"
						className={cx("content__item")}
						gutterBottom
					>
						<span>Thứ 6, 06/07/2022</span>
					</Typography> */}
				</Box>
			</Box>
		</div>
	);
}

export default AppointmentCardContent;
