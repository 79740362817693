import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { IconNotification } from "@tabler/icons";
import { Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import voucherAPI from "api/VoucherAPI";
import { IMAGES } from "assets/images/imagePath";
import { CustomNoRowsOverlay } from "components/noRows";
import PageSizeSelect from "components/PrintTemplate/components/PageSizeSelect";
import ProductQr from "components/PrintTemplate/ProductQr";
import { ROLE } from "constants/employeeRole";
import { formatVND, getNumberWithCommas } from "func";
import useCustomAuth from "hooks/useCustomAuth";
import { Markup } from "interweave";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import ImageModal from "ui-component/modal-form/ImageModal";
import DetailProductInformation from "ui-component/modal-form/ModalAntd/DetailProductInformation";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import VoucherModal from "ui-component/modal-form/VoucherModal";
import VoucherUsersModal from "ui-component/VoucherUsersModal";
import { SearchComponent } from "../../components/search";

const searchList = [
	{
		value: "code",
		title: "Mã",
	},
	{
		value: "name",
		title: "Tên mã",
	},
];

const Voucher = () => {
	const location = useLocation();

	const stateLoc = location?.state;

	const filterWithCategory = {
		searchBy: stateLoc?.searchBy,
		value: stateLoc?.value,
	};

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { role } = useCustomAuth();
	const [voucherType, setVoucherType] = useState("ALL");
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [searchBy, setSearchBy] = useState(filterWithCategory.searchBy || searchList[0].value);
	const [searchValue, setSearchValue] = useState(filterWithCategory.value || "");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const [openModalImage, setOpenModalImage] = useState(false);
	const [printQr, setPrintQr] = useState({
		image: "",
		productName: "",
		priceVoucher: 0,
		data,
	});

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleOpenChangeAccept = (e, params, isWithClient = false) => {
		let checked = e.target.checked;
		modalContext.showDeleteConfirm(
			params.id,
			() => handleChangeAccept(checked, params.id, isWithClient),
			`${
				checked
					? `Bạn có chắc chắn xác nhận  ${
							!isWithClient ? "sản phẩm này soi da?" : "đã nhận tiền?"
					  } `
					: `Bạn có chắc chắn xác nhận ${
							!isWithClient ? "sản phẩm này không soi da?" : "chưa nhận tiền?"
					  }`
			}`,
			"Chắc chắn"
		);
	};

	const handleChangeAccept = async (checked, id, isWithClient) => {
		try {
			await voucherAPI.update(
				id,

				!isWithClient
					? {
							is_scan_skin: checked,
					  }
					: {
							is_confirm_with_client: checked,
					  }
			);
			enqueueSnackbar("Thành công", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListService();
		} catch (error) {
			enqueueSnackbar(error?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	//Print Func

	// Table

	const actionList = [
		{
			value: "edit",
			title: "Cập nhật",
			visible: ROLE.VOUCHER_UPDATE === role?.VOUCHER_UPDATE,
			icon: <EditIcon />,
			onClick: (params) => {
				setData(params.row);
				setOpen(true);
				setIsUpdate(true);
			},
		},
		{
			value: "delete",
			title: "Xóa",
			visible: ROLE.VOUCHER_DELETE === role?.VOUCHER_DELETE || stateLoc?.isFromGroup,
			icon: <DeleteIcon />,
			onClick: (params) => handleOpenRemoveModal(params.id),
		},
		{
			value: "information",
			title: "Chi tiết",
			visible: ROLE.VOUCHER_VIEW === role?.VOUCHER_VIEW,
			icon: <IconNotification />,
			onClick: (params) => handleOpenInforModal(params.row),
		},
	];

	const columns = [
		{
			field: "thumbnail",
			headerName: "Danh sách ảnh",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<img
					height={"80%"}
					className={"aspect1-1"}
					src={params.value || IMAGES.img_default}
					alt="avatar-img"
					onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.img_default))}
					onClick={() => setImageZoom({ open: true, image: params.row?.images })}
				/>
			),
		},
		{
			field: "minimum_price",
			headerName: "Số tiền tối thiểu để sử dụng",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "code",
			headerName: "Mã giảm giá",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "name",
			headerName: "Tên mã",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "discount",
			headerName: "Giá trị",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={
							params?.row?.unit === "%"
								? `${getNumberWithCommas(params.value)}%`
								: `${getNumberWithCommas(params.value)}K`
						}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.unit === "%"
								? `${getNumberWithCommas(params.value)}%`
								: `${getNumberWithCommas(params.value)}K`}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "amount",
			headerName: "Số lần sử dụng",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "amount_used",
			headerName: "Số lần đã sử dụng",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "voucher_type",
			headerName: "Loại mã giảm",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params.value === "ORDER" ? "Đơn hàng" : "Lịch hẹn"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.value === "ORDER" ? "Đơn hàng" : "Lịch hẹn"}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "expiry",
			headerName: "Ngày hết hạn",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "actions",
			headerName: t(`actions`),
			flex: 2,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			hide: !actionList.some((item) => item.visible),
			renderCell: (params) => (
				<Stack flexDirection={"row"}>
					{actionList.map(
						(item) =>
							item.visible && (
								<Tooltip title={item.title}>
									<IconButton aria-label={item.value} onClick={() => item.onClick(params)}>
										{item.icon}
									</IconButton>
								</Tooltip>
							)
					)}
				</Stack>
			),
		},
	];

	const [information, setInformation] = useState({
		visible: false,
		voucher_users: [],
	});
	const [printData, setPrintData] = useState({
		visible: false,
		data: {},
	});
	const handleOpenInforModal = (data) => {
		//	Example DATA
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin mã giảm giá",
				gridProps: {},
				children: [
					{
						id: "name",

						title: "Tên mã giảm",
						value: data.name,
						component: Text,
						childrenProps: {
							//all Props component here
							span: 8,
						},
					},
					{
						id: "code",
						title: "Mã giảm giá",
						value: data.code,
						component: Text,
						childrenProps: {
							span: 8,
						},
					},

					{
						id: "discount",
						title: "Giá trị",
						value: getNumberWithCommas(data.discount) + `${data?.unit === "%" ? "%" : "K"}`,
						component: Text,
						childrenProps: {
							span: 8,
							color: "error",
						},
					},
					{
						id: "minimum_price",
						title: "Giá trị đơn hàng tối thiểu",
						value: formatVND(data.minimum_price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					// {
					// 	id: "unit",
					// 	title: "Đơn vị",
					// 	value: data?.unit === "K" ? "nghìn đồng" : "%",
					// 	component: Text,
					// 	childrenProps: {
					// 		span: 8,
					// 	},
					// },
					{
						id: "voucher_type",
						title: "Loại mã",
						value: data.voucher_type === "ORDER" ? "Đơn hàng" : "Lịch hẹn",
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "expiry",
						title: "Ngày hết hạn",
						value: moment(Number(data?.expiry)).format("DD/MM/YYYY HH:mm"),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "created_at",
						title: "Thời gian tạo",
						value: moment(data?.created_at).format("DD/MM/YYYY HH:mm"),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "updated_at",
						title: "Thời gian tạo",
						value: moment(data?.updated_at).format("DD/MM/YYYY HH:mm"),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					// {
					// 	id: "description",
					// 	title: "Mô tả trang liên kết chia sẻ",
					// 	value: data.description || "Không có dữ liệu",
					// 	component: Text,
					// 	childrenProps: {
					// 		span: 24,
					// 	},
					// },
					{
						id: "voucher_detail",
						title: "Mô tả chi tiết mã giảm",
						component: Markup,
						childrenProps: {
							span: 24,
							content: data.voucher_detail || "Không có dữ liệu",
						},
					},
				],
			},
		];

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
			voucher_users: data?.voucher_users,
		}));
	};
	const handleOpenPrintModal = (data) => {
		setPrintData({
			visible: true,
			data,
		});
	};
	const [filterCategory, setFilterCategory] = useState();
	useEffect(() => {
		setFilterCategory(location?.state);
	}, []);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const getListService = async (isLastDeleteItem = false) => {
		setIsLoading(true);
		// const hotFilter = `{"hot" : "false"}`;
		let filter = {
			voucher_type: voucherType === "ALL" ? undefined : voucherType,
			[searchBy]:
				searchValue.trim().length === 0
					? undefined
					: {
							$iLike: `%${searchValue?.trim()}%`,
					  },
		};
		let order = '[["created_at", "desc"]]';
		const fields = ["$all", { voucher_users: ["$all", { user: ["$all"] }] }];

		// if (queryParams?.[searchBy]) {
		// 	filter = `{"${searchBy}":{"$iLike":"%${searchValue?.trim()}%"}}`;
		// }

		try {
			const res = await voucherAPI.getList({
				fields: JSON.stringify(fields),
				order,
				filter: JSON.stringify(filter),
				limit: pagination.limit,
				page: isLastDeleteItem ? queryParams.page - 1 : queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			if (isLastDeleteItem) {
				navigate({
					pathname: location.pathname,
					search: `?${queryString.stringify({ ...queryParams, page: paginationRes.page })}`,
				});
			}
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));

			setSelectList([]);
			setRows(resList);

			setPagination(paginationRes);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//Get Services Categories
	const [serviceCategories, setServiceCategories] = useState(null);
	const getServicesCategories = async () => {
		setIsLoading(true);

		const filter = {
			status: "true",
		};
		const params = {
			fields: '["$all"]',
			filter: JSON.stringify(filter),
		};
		try {
			const res = await voucherAPI.getCategory(params);
			setServiceCategories(res.results.objects.rows);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);

	const handleRemoveMulti = async () => {
		let isLastDeleteItem = rows?.length === selectList.length && pagination.page !== 1;

		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					await voucherAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListService(isLastDeleteItem);
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleDelete = async (id) => {
		let isLastDeleteItem = rows?.length === 1 && pagination.page !== 1;
		try {
			await voucherAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListService(isLastDeleteItem);
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useEffect(() => {
		if (!role) return;
		if (role?.VOUCHER_VIEW !== ROLE.VOUCHER_VIEW) {
			navigate("/not-permission", { replace: true });

			return;
		}
		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}

		// if (location.state) {
		// 	setSearchBy(filterWithCategory.searchBy);
		// 	setSearchValue(filterWithCategory.value);
		// }
		getListService();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading, role, voucherType]);

	return (
		<>
			<MainCard>
				{filterCategory?.isFromCategory && (
					<Button
						variant="contained"
						onClick={() => navigate("/product-category")}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về
					</Button>
				)}
				{stateLoc?.isFromGroup && (
					<Button
						variant="contained"
						onClick={() => navigate("/group-product")}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về
					</Button>
				)}
				<HeaderActions
					title={"Danh sách mã giảm giá"}
					setIsUpdate={setIsUpdate}
					setOpen={setOpen}
					onCreate={ROLE.VOUCHER_CREATE === role?.VOUCHER_CREATE}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box display="flex">
						<SearchComponent
							searchBy={searchBy}
							searchList={searchList}
							searchValue={searchValue}
							handleSearchBy={handleSearchBy}
							handleChangeSearch={handleChangeSearch}
						/>
						<FormControl sx={{ minWidth: 200, ml: 2, mb: "16px" }}>
							<InputLabel id="demo-simple-select-outlined-label">Loại mã</InputLabel>
							<Select
								labelId="demo-simple-select-outlined-label"
								label={"Loại mã"}
								onChange={(e) => setVoucherType(e.target.value)}
								value={voucherType}
							>
								<MenuItem
									value={"ALL"}
									sx={{
										fontWeight: 700,
									}}
								>
									Tất cả
								</MenuItem>
								<MenuItem
									value={"ORDER"}
									sx={{
										fontWeight: 700,
									}}
								>
									Đơn hàng
								</MenuItem>
								<MenuItem
									value={"BOOKING"}
									sx={{
										fontWeight: 700,
									}}
								>
									Lịch hẹn
								</MenuItem>
							</Select>
						</FormControl>
					</Box>

					{ROLE.VOUCHER_DELETE === role?.VOUCHER_DELETE && (
						<Button
							sx={{
								display: stateLoc?.isFromGroup ? "none" : "block",
							}}
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection={ROLE.VOUCHER_DELETE === role?.VOUCHER_DELETE}
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<VoucherModal
				stateLocation={location.state}
				listSelect={serviceCategories}
				getListTable={getListService}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>
			<ImageModal
				title={"Ảnh sản phẩm"}
				multi
				classNameImg={"aspect1-1"}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>
			<ModalAntd
				title={"Chi tiết mã giảm"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailProductInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
				<VoucherUsersModal rows={information.voucher_users} open={information.visible} />
			</ModalAntd>
			<ImageModal
				hasFooter
				title="Qr Code sản phẩm"
				openModalZoom={openModalImage}
				handleCloseModal={() => setOpenModalImage(false)}
				imageZoom={printQr.image}
				onOpenPageSelect={() => handleOpenPrintModal(printQr.data)}
				PrintComponent={
					<ProductQr
						qrCode={printQr.image}
						productName={printQr.productName}
						price={printQr.priceProduct}
					/>
				}
			/>

			<ModalAntd
				title={"Chọn mẫu giấy in"}
				visible={printData.visible}
				setVisible={(value) => {
					setPrintData((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<PageSizeSelect data={printData.data} />
			</ModalAntd>
		</>
	);
};

export default Voucher;
