import {
	GET_UPDATE_COMBO_USING,
	RESET_USER_COMBO_USING,
	SET_COUNT_USING,
	SET_USER_COMBO,
} from "./type";

export const actSetUserCombo = (data) => ({
	type: SET_USER_COMBO,
	payload: data,
});

export const actSetCountUsing = (data) => ({
	type: SET_COUNT_USING,
	payload: data,
});

export const actGetIsUpdateComboUsing = (data) => ({
	type: GET_UPDATE_COMBO_USING,
	payload: data,
});

export const actResetUserComboUsing = () => ({
	type: RESET_USER_COMBO_USING,
});
