import { Box, Grid } from "@mui/material";
import packAPI from "api/PackAPI";
import QrInvoicesFilter from "components/FilterSide/QrInvoicesFilter";
import InvoicesQrTable from "components/InvoicesTable/InvoicesQrTable";
import { PAGINATION } from "constants/pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainCard from "ui-component/cards/MainCard";

function InvoicesQrCardPage() {
	// const { showLoading, hideLoading } = useContext(LoadingContext);
	const [loadingTable, setLoadingTable] = useState(false);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	const mainBranchId = branchList.find((item) => item.is_main)?.id;
	const defaultsFilter = {
		branch: [mainBranchId],
		date: [new Date(), new Date()],
	};
	const [filterData, setFilterData] = useState(defaultsFilter);
	const filterQuery = {
		sale_date: { $ne: null },
		$or:
			filterData.owner || filterData.user
				? [
						filterData.owner
							? {
									["$owner.fullname$"]: {
										$iLike: `%${filterData?.owner.trim()}%`,
									},
							  }
							: undefined,
						filterData.owner
							? {
									["$owner.username$"]: {
										$iLike: `%${filterData?.owner.trim()}%`,
									},
							  }
							: undefined,
						filterData.user
							? {
									["$user.username$"]: {
										$iLike: `%${filterData?.user.trim()}%`,
									},
							  }
							: undefined,
						filterData.user
							? {
									["$user.fullname$"]: {
										$iLike: `%${filterData?.user.trim()}%`,
									},
							  }
							: undefined,
				  ].filter((item) => item)
				: undefined,
		sale_date_unix:
			filterData.date[0] && filterData.date[1]
				? {
						$between: [
							+moment(filterData.date[0]).utc(true).startOf("days").format("x"),
							+moment(filterData.date[1]).utc(true).endOf("days").format("x"),
						],
				  }
				: undefined,
	};

	const [pagination, setPagination] = useState({
		limit: PAGINATION.LIMIT,
		page: PAGINATION.PAGE,
	});

	const [rows, setRows] = useState([]);

	const query = {
		fields: [
			"$all",
			{
				user: ["$all", "$paranoid"],
			},
			{
				owner: ["$all", "$paranoid"],
			},
			{
				buyer: ["phone", "fullname", "name_admin_note"],
			},
		],
		filter: filterQuery,
		order: [["created_at", "desc"]],
	};

	const fetchOrderData = async (queryCustom = undefined) => {
		// showLoading();
		setLoadingTable(true);
		try {
			const payload = {
				...query,
				...pagination,
				fields: JSON.stringify(query.fields),
				filter: JSON.stringify(query.filter),
				order: JSON.stringify(query.order),
				...queryCustom,
				total: undefined,
			};

			const res = await packAPI.getList(payload);

			if (queryCustom) {
				setLoadingTable(false);

				return res.results?.objects?.rows;
			} else {
				const paginationRes = {
					...pagination,
					page: res?.pagination?.current_page,
					total: res?.results?.objects?.count,
				};

				setPagination(paginationRes);
				setRows(res.results?.objects?.rows);

				setLoadingTable(false);
			}
			// hideLoading();
		} catch (error) {
			console.log(error);
			// hideLoading();
			setLoadingTable(false);
		}
	};

	useEffect(() => {
		if (!branchActiveId) return;
		fetchOrderData();
	}, [filterData, pagination.page, pagination.limit]);

	useEffect(() => {
		if (!branchActiveId) return;
		setFilterData({ ...defaultsFilter });
	}, [branchActiveId]);

	return !branchActiveId ? (
		<></>
	) : (
		<Box width={"100%"}>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<QrInvoicesFilter filterData={filterData} setFilterData={setFilterData} />
				</Grid>
				<Grid item xs={9}>
					<MainCard>
						<InvoicesQrTable
							rows={rows}
							setPagination={setPagination}
							pagination={pagination}
							filterData={filterData}
							loading={loadingTable}
							getData={() => fetchOrderData({ limit: 99999, page: 1 })}
						/>
					</MainCard>
				</Grid>
			</Grid>
		</Box>
	);
}

export default InvoicesQrCardPage;
