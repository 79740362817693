import { TextField } from "@mui/material";
import React from "react";
import { useController } from "react-hook-form";

function InputField({
	name,
	control,
	label,
	type,
	errors = null,
	rules,
	customizeOnChange = undefined,
	...props
}) {
	const {
		field: { onChange, onBlur, value, ref },
		meta: { invalid },
	} = useController({
		name,
		control,
		rules,
	});

	//Render UI input as MUI Field, Antd or customize input field,....
	return (
		<TextField
			type={type}
			label={label}
			inputRef={ref}
			variant="standard"
			fullWidth
			{...props}
			error={!!invalid}
			helperText={errors && errors[name]?.message}
			name={name}
			value={value}
			onChange={customizeOnChange ? (e) => customizeOnChange(e, onChange) : onChange}
			onBlur={onBlur}
		/>
	);
}

export default InputField;
