import { Box, Tab, Tabs } from "@mui/material";
import { Typography } from "antd";
import transactionAPI from "api/TransactionAPI";
import { COLOR } from "constants/common";
import moment from "moment";
import { formatVND, statusConfirmTrans, statusTransactionTranslator } from "func";
import { LoadingContext } from "providers/LoadingProvider";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import confirmChangeWalletAPI from "api/ConfirmChangeWalletAPI";

function ConfirmChangeWalletDetail() {
	const { changeWalletId } = useParams();

	const [data, setData] = useState([]);

	const { showLoading, hideLoading } = useContext(LoadingContext);
	const fetchConfirmInfoById = async () => {
		showLoading();

		const fields = [
			"$all",
			{ user: ["username", "fullname", "deleted_at", "$paranoid"] },
			{ employee: ["username", "fullname", "deleted_at", "$paranoid"] },
			{ employee_confirm: ["username", "fullname", "deleted_at", "$paranoid"] },
		];
		const payload = {
			fields: JSON.stringify(fields),
		};
		try {
			const res = await confirmChangeWalletAPI.findOne(changeWalletId, payload);

			const data = res?.results?.object;

			const dataParams = [
				{
					groupId: 1,
					groupTitle: "Thông tin",
					gridProps: {},
					children: [
						{
							id: "phone",
							title: "SĐT thay đổi",
							value: data.user?.username,
							component: Typography.Text,
							childrenProps: {
								//all Props component here
								span: 8,
							},
						},
						{
							id: "employee",
							title: "Nhân viên thay đổi",
							value: data.employee?.fullname || data.employee?.username,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},

						{
							id: "employee_confirm",
							title: "Nhân viên xác nhận",
							value: data.employee_confirm?.fullname || data.employee_confirm?.username,
							childrenProps: {
								span: 8,
								color: "error",
							},
						},
						{
							id: "from_change_amount",
							title: "Giá trị ví lúc thay đổi",
							value: formatVND(data?.from_change_amount),
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},

						{
							id: "change_amount",
							title: "Giá trị thay đổi",
							value: `${data?.check ? "+" : "-"}${formatVND(data?.change_amount)}`,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "status_confirm",
							title: "Tình trạng",
							value: statusConfirmTrans(data.status_confirm)?.title,
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "type",
							title: "Loại",
							value: data?.type === "WALLET_ROSE" ? "Ví hoa hồng" : "Ví hạn mức dịch vụ",
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},
						{
							id: "created_at",
							title: "Thời gian tạo",
							value: moment(data?.created_at).format("DD/MM/YYYY HH:mm"),
							component: Typography.Text,
							childrenProps: {
								span: 8,
							},
						},

						{
							id: "note",
							title: "Ghi chú",
							value: data?.note,
							component: Typography.Text,
							childrenProps: {
								span: 24,
							},
						},
					],
				},
			];

			setData(dataParams);
			hideLoading();
		} catch (error) {
			console.log(error);
			hideLoading();
		}
	};

	useEffect(() => {
		fetchConfirmInfoById();
	}, []);

	return (
		<MainCard>
			<HeaderActions title={"Chi tiết yêu cầu thay đổi ví"} />
			<Box my={2}>
				<DetailInformation data={data} />
			</Box>
		</MainCard>
	);
}

export default ConfirmChangeWalletDetail;
