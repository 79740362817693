import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import confirmChangeWalletAPI from "api/ConfirmChangeWalletAPI";
import { STATUS_TRANSACTION } from "constants/common";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actGetWaitingRequest } from "store/actions/WaitingRequestActions";

function ConfirmChangeWalletModal({
	open,
	setOpen,
	data,
	getListTable,
	handleOpenShare,
	filterType,
}) {
	const handleClose = () => {
		setOpen(false);
	};
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [activity, setActivity] = useState(data.status_confirm === "WAITING" ? "WAITING" : null);
	const [noteAdmin, setNoteAdmin] = useState();
	const [packId, setPackId] = useState();

	const bodyRequest = useMemo(
		() => ({
			status_confirm: activity,
			note: noteAdmin,
		}),
		[activity, packId, noteAdmin]
	);

	const handleSetActivity = (event) => {
		setActivity(event.target.value);
		if (event.target.value !== "DONE") {
			setPackId(null);
		} else {
			setPackId(packList[0]?.id);
		}
	};

	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const handleSubmit = async () => {
		setLoading(true);

		try {
			//Lọc dữ liệu null
			Object.keys(bodyRequest).forEach((key) => {
				if (!!bodyRequest[key] === false) {
					delete bodyRequest[key];
				}
			});

			const res = await confirmChangeWalletAPI.update(data.id, bodyRequest);
			dispatch(actGetWaitingRequest(branchActiveId));

			setLoading(false);
			handleClose();

			// if (filterType === "REQUEST_DEPOSIT") {
			// 	handleOpenShare(res.results.object.pack_id);
			// }
			await getListTable();

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
		} catch (err) {
			enqueueSnackbar(err?.message || "Lỗi", {
				variant: "error",
				autoHideDuration: 2000,
			});
			await getListTable();

			setLoading(false);
			handleClose();
		}
	};

	const [packList, setPackList] = useState([]);

	useEffect(() => {
		if (open) {
			setPackList([]);
			setActivity(data.status_confirm === "WAITING" ? "WAITING" : null);
		}
		return () => {
			setNoteAdmin("");
		};
	}, [open]);
	// @ts-expect-error autofill of arbitrary value is not handled.

	const refLabel = useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
	useEffect(() => {
		setLabelWidth(refLabel.current?.offsetWidth);
	}, []);
	const { WAITING, DONE, CANCEL } = STATUS_TRANSACTION;

	const useStyles = makeStyles({
		select: {
			"& ul": {
				overflow: "auto",
				maxHeight: "250px",
			},
		},
	});

	const classes = useStyles();
	return (
		<React.Fragment>
			<Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
				<DialogTitle variant="h4">
					{data.status_confirm === "WAITING" ? "Chỉnh sửa trạng thái yêu cầu" : "Chỉnh sửa ghi chú"}
				</DialogTitle>
				<DialogContent>
					{data.status_confirm === "WAITING" && (
						<Box
							noValidate
							component="form"
							sx={{
								display: "flex",
								flexDirection: "column",
								m: "auto",
							}}
						>
							<FormControl sx={{ mt: 2 }} size={"large"}>
								<InputLabel ref={refLabel} id="labelId" sx={{ fontSize: "18px" }}>
									Trạng thái xử lý
								</InputLabel>
								<Select
									fullWidth
									autoFocus
									defaultValue={data.activity}
									value={activity}
									onChange={handleSetActivity}
									sx={{
										"& .MuiInputLabel-root, fieldset": {
											fontSize: "18px",
										},
										"& .MuiList-root": {
											maxHeight: "250px",
											overflow: "auto",
										},
									}}
									MenuProps={{ classes: { paper: classes.select } }}
									labelId="labelId"
									label={"Trạng thái yêu cầu giao dịch"}
									labelWidth={labelWidth}
									inputProps={{
										name: "activity",
										id: "activity",
									}}
								>
									<MenuItem value="WAITING">Chờ xử lý</MenuItem>
									<MenuItem
										value="DONE"
										//  onClick={() => fetchPackList()}
									>
										Đồng ý
									</MenuItem>
									<MenuItem value="CANCEL">Từ chối</MenuItem>
								</Select>
							</FormControl>
						</Box>
					)}
					{/* <Box
						noValidate
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
							m: "auto",
						}}
					>
						{activity === "DONE" &&
							filterType === "REQUEST_DEPOSIT" &&
							(packList.length !== 0 ? (
								<FormControl sx={{ mt: 2 }} size={"large"}>
									<InputLabel ref={refLabel} id="labelId" sx={{ fontSize: "18px" }}>
										Thẻ hạn mức dịch vụ
									</InputLabel>
									<Select
										fullWidth
										autoFocus
										defaultValue={packList.length !== 0 && packList[0].id}
										value={packId}
										onChange={(e) => setPackId(e.target.value)}
										sx={{
											"& .MuiInputLabel-root, fieldset": {
												fontSize: "18px",
											},
										}}
										MenuProps={{ classes: { paper: classes.select } }}
										labelId="labelId"
										label={"Thẻ hạn mức dịch vụ"}
										labelWidth={labelWidth}
										inputProps={{
											name: "pack_id",
											id: "packId",
										}}
									>
										{packList.map((pack) => (
											<MenuItem value={pack.id}>
												<PackItem pack={pack} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							) : (
								<Typography
									variant="h5"
									sx={{ fontWeight: 700, color: "goldenrod", padding: "10px" }}
								>
									Giá trị thẻ này đã hết, vui lòng tạo thêm
								</Typography>
							))}
					</Box> */}

					{/* NOTE ADMIN HERE */}
					<Box
						noValidate
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
							m: "auto",
						}}
					>
						<FormControl sx={{ mt: 2 }}>
							<TextField
								margin="dense"
								name="note_admin"
								label={"Ghi chú từ Quản trị"}
								type="text"
								fullWidth
								multiline
								minRows={4}
								value={noteAdmin}
								onChange={(e) => setNoteAdmin(e.target.value)}
							/>
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						onClick={() => handleSubmit()}
						// endIcon={<SaveIcon />}
						loading={loading}
						loadingPosition="end"
						variant="contained"
					>
						Cập nhật
					</LoadingButton>
				</DialogActions>

				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</React.Fragment>
	);
}

export default ConfirmChangeWalletModal;
