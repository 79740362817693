import { Box, Grid } from "@mui/material";
import orderAPI from "api/OrderAPI";
import InvoicesOrderTable from "components/InvoicesOrderTable";
import TransactionFilter from "components/TransactionFilter";
import { PAGINATION } from "constants/pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainCard from "ui-component/cards/MainCard";

function InvoicesPage({ type }) {
	// const { showLoading, hideLoading } = useContext(LoadingContext);

	const [loadingTable, setLoadingTable] = useState(false);
	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);
	const mainBranchId = branchList.find((item) => item.is_main)?.id;

	const defaultsFilter = {
		branch: [mainBranchId],
		activity: ["COMPLETED"],
		payment_type: ["CASH", "TRANSFER", "CARD"],
		date: [new Date(), new Date()],
	};

	const [filterData, setFilterData] = useState(defaultsFilter);

	console.log(filterData);
	const [pagination, setPagination] = useState({
		limit: PAGINATION.LIMIT,
		page: PAGINATION.PAGE,
	});

	const [rows, setRows] = useState([]);

	const query = {
		fields: [
			"$all",
			{ branch: ["$all"] },
			{
				employee: [
					"$all",
					filterData.employee
						? {
								$filter: {
									$or: [
										{
											// eslint-disable-next-line no-useless-computed-key
											username: {
												$iLike: `%${filterData.employee.trim()}%`,
											},
										},
										{
											// eslint-disable-next-line no-useless-computed-key
											fullname: {
												$iLike: `%${filterData.employee.trim()}%`,
											},
										},
									],
								},
						  }
						: undefined,
				].filter((item) => item),
			},

			{
				user: [
					"fullname",
					"username",
					"phone",
					"$paranoid",
					filterData.user
						? {
								$filter: {
									$or: [
										{
											// eslint-disable-next-line no-useless-computed-key
											username: {
												$iLike: `%${filterData.user.trim()}%`,
											},
										},
										{
											// eslint-disable-next-line no-useless-computed-key
											fullname: {
												$iLike: `%${filterData.user.trim()}%`,
											},
										},
										{
											// eslint-disable-next-line no-useless-computed-key
											phone: {
												$iLike: `%${filterData.user.trim()}%`,
											},
										},
									],
								},
						  }
						: undefined,
				].filter((item) => item),
			},
			{
				order_items: [
					"product_id",
					filterData?.product
						? {
								product: [
									"title",
									"barcode",
									{
										$filter: {
											$or: [
												{
													title: {
														$iLike: `%${filterData?.product?.trim()}%`,
													},
												},
												{
													barcode: {
														$iLike: `%${filterData?.product?.trim()}%`,
													},
												},
											],
										},
									},
								],
						  }
						: undefined,
				].filter((item) => item),
			},
		],
		filter: {
			order_type: type,
			branch_id: {
				$in: filterData?.branch && filterData?.branch?.length !== 0 ? filterData?.branch : [],
			},
			activity: {
				$in: filterData?.activity && filterData?.activity?.length !== 0 ? filterData?.activity : [],
			},
			payment_type: {
				$in:
					filterData?.payment_type && filterData?.payment_type?.length !== 0
						? filterData?.payment_type
						: [],
			},
			order_code: filterData?.order_code
				? {
						$iLike: `%${filterData?.order_code.trim()}%`,
				  }
				: undefined,
			note: filterData?.note
				? {
						$iLike: `%${filterData?.note.trim()}%`,
				  }
				: undefined,

			// employee: filterData?.employee
			// 	? {
			// 			$or: [
			// 				{
			// 					fullname: {
			// 						$iLike: `%${filterData?.employee.trim()}%`,
			// 					},
			// 				},
			// 				{
			// 					username: {
			// 						$iLike: `%${filterData?.employee.trim()}%`,
			// 					},
			// 				},
			// 			],
			// 	  }
			// 	: undefined,

			created_at_unix_timestamp:
				filterData.date[0] && filterData.date[1]
					? {
							$between: [
								+moment(filterData.date[0]).utc(true).startOf("days").format("x"),
								+moment(filterData.date[1]).utc(true).endOf("days").format("x"),
							],
					  }
					: undefined,
		},
		order: [["created_at", "desc"]],
	};

	const fetchOrderData = async (customQuery = undefined) => {
		// showLoading();
		setLoadingTable(true);
		try {
			const payload = {
				...query,
				...pagination,
				fields: JSON.stringify(query.fields),
				filter: JSON.stringify(query.filter),
				order: JSON.stringify(query.order),
				...customQuery,
				total: undefined,
				report: true,
			};

			const res = await orderAPI.getList(payload);
			if (customQuery) {
				setLoadingTable(false);
				return res;
			} else {
				const paginationRes = {
					...pagination,
					page: res?.pagination?.current_page,
					total: res?.results?.objects?.count,
				};
				setLoadingTable(false);
				setPagination(paginationRes);
				setRows(res.results?.objects?.rows);
			}
			// hideLoading();
		} catch (error) {
			console.log(error);
			// hideLoading();
			setLoadingTable(false);
		}
	};

	useEffect(() => {
		if (!branchActiveId) return;
		fetchOrderData();
	}, [filterData, pagination.page, pagination.limit, type]);

	useEffect(() => {
		if (!branchActiveId) return;
		setFilterData({ ...defaultsFilter });
	}, [branchActiveId]);

	return !branchActiveId ? (
		<></>
	) : (
		<Box width={"100%"}>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<TransactionFilter filterData={filterData} setFilterData={setFilterData} />
				</Grid>
				<Grid item xs={9}>
					<MainCard>
						<InvoicesOrderTable
							rows={rows}
							setPagination={setPagination}
							pagination={pagination}
							filterData={filterData}
							loading={loadingTable}
							type={type}
							getData={() =>
								fetchOrderData({
									page: 1,
									limit: 99999,
								})
							}
						/>
					</MainCard>
				</Grid>
			</Grid>
		</Box>
	);
}

export default InvoicesPage;
