import { Button, Modal } from "antd";
import React, { useState } from "react";

const ModalAntd = ({ children, visible, setVisible, width = "1000px", ...props }) => {
	return (
		<Modal
			zIndex={10000}
			centered
			bodyStyle={{ paddingTop: 0 }}
			visible={visible}
			footer={false}
			onOk={() => setVisible(false)}
			onCancel={() => setVisible(false)}
			width={width}
			{...props}
		>
			{children}
		</Modal>
	);
};

export default ModalAntd;
