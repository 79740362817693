export const switchErrorMessage = (error) => {
	console.log(error.message);

	switch (error.message) {
		case "Firebase: Error (auth/code-expired).":
			return "Mã OTP không đúng vui lòng kiểm tra lại";

		case "FirebaseError: Firebase: Error (auth/invalid-verification-code).":
			return "Mã OTP không đúng vui lòng kiểm tra lại";

		case "Firebase: Error (auth/too-many-requests).":
			return "Bạn gửi OTP quá nhiều lần cho số điện thoại này, vui lòng thử lại sau";

		default:
			console.log(error);
	}
};
