import { Tab, Tabs } from "@mui/material";
import { COLOR } from "constants/common";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { MENU_TABS } from "./menuTab";

function MenuBar() {
	const location = useLocation();
	const [value, setValue] = React.useState(location.pathname);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `full-width-tab-${index}`,
			"aria-controls": `full-width-tabpanel-${index}`,
		};
	}

	return (
		<Tabs
			sx={{
				"& .MuiTabs-flexContainer": {
					flexWrap: "wrap",
					gap: 1,
					my: 2,
				},
			}}
			TabIndicatorProps={{ sx: { display: "none" } }}
			aria-label="full width tabs example"
			value={value}
			onChange={handleChange}
		>
			{MENU_TABS.map((item, idx) => {
				return (
					<Tab
						sx={{
							border: "1px solid black",
							borderRadius: "5000px",
							fontSize: "12px",
							minHeight: "20px",
							"&.Mui-selected": {
								color: "#fff",
								background: COLOR.MAIN_COLOR,
							},
						}}
						value={item.url}
						label={item.title}
						LinkComponent={Link}
						to={item.url}
						state={item.state}
						{...a11yProps(idx)}
					/>
				);
			})}
		</Tabs>
	);
}

export default MenuBar;
