import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import employeeAPI from "api/EmployeeAPI";
import uploadAPI from "api/UploadAPI";
import md5 from "md5";
import { useSnackbar } from "notistack";
import { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateAvatar } from "store/actions/EmployeeAction";
import { useSelector } from "react-redux";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { PATTERN } from "constants/common";

const Input = styled("input")({
	display: "none",
});

const UploadFile = styled("div")({
	width: "100px",
	height: "100px",
	borderRadius: "8px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	border: "1px solid gray",
	cursor: "pointer",
});

const PreviewImg = styled("img")({
	width: "100%",
	borderRadius: "8px",
	maxWidth: "100px",
});
const RemoveImg = styled("div")({
	fontWeight: 500,
	color: "blue",
	textAlign: "center",
	cursor: "pointer",
});
const TextValidation = styled("span")({
	marginLeft: "15px",
	fontSize: "12px",
	color: "red",
});

function FormDialog(props) {
	const { isOpen, isClose, isLoading, employee, isUpdate, updateInfo } = props;
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const { register, handleSubmit, errors, control } = useForm({
		mode: "onChange",
	});
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState();
	const [type, setType] = useState(employee?.role || "ADMIN");

	const [messageErr, setMessageErr] = useState("");

	useEffect(() => {
		if (employee?.avatar && isUpdate) {
			setImage(employee.avatar);
		} else {
			setImage();
		}
		if (employee?.role && isUpdate) {
			setType(employee.role);
		} else {
			setType("ADMIN");
		}
		// eslint-disable-next-line
	}, [employee.avatar, isOpen]);

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const onSubmit = async (data) => {
		setLoading(true);

		if (!isUpdate) {
			try {
				const params = {
					username: data.username,
					password: md5(data.password),
					avatar: image ? image : "",
					email: data.email,
					phone: data.phone,
					fullname: data.fullname,
					role: type,
					// branch_id: data.branch_id === "none" ? null : data.branch_id,
				};

				await employeeAPI.create(params);

				isClose(false);
				setLoading(false);
				isLoading(1);
				setMessageErr("");
				enqueueSnackbar(t(`success`), {
					variant: "success",
					autoHideDuration: 2000,
				});
			} catch (error) {
				setLoading(false);
				// if (error?.message && error?.code === 402) {
				// 	setMessageErr(error?.message);
				// }
				enqueueSnackbar(t(error?.message) || t(`error`), {
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		} else {
			try {
				// const regexExp = /^[a-f0-9]{32}$/gi;
				// const checkIsMd5 = regexExp.test(data.password);
				// data.avatar = image ? image : "";
				// data.role = type;
				// Nếu có thay đổi password thì mới md5
				// if (!checkIsMd5) {
				// 	data.password = md5(data.password);
				// }

				const params = {
					avatar: image ? image : "",
					email: data.email,
					fullname: data.fullname,
					phone: data.phone,
					role: "ADMIN",
					// branch_id: data.branch_id === "none" ? null : data.branch_id,
				};

				if (data.password.length) {
					params.password = md5(data.password);
				}

				const res = await employeeAPI.edit(employee.id, params);

				const employeeInfo = JSON.parse(localStorage.getItem("employeeInfo"));
				if (employeeInfo.object.id === res.results.object.id) {
					const newEmployeeInfo = {
						...employeeInfo,
						object: res?.results?.object,
					};
					dispatch(updateAvatar(res?.results?.object));
					localStorage.setItem("employeeInfo", JSON.stringify(newEmployeeInfo));
				}

				updateInfo && updateInfo(res?.results?.object);
				isClose(false);
				setLoading(false);
				isLoading(1);
				setMessageErr("");
				enqueueSnackbar(t(`success`), {
					variant: "success",
					autoHideDuration: 2000,
				});
			} catch (error) {
				setLoading(false);
				// if (error?.message && error?.code === 402) {
				// 	setMessageErr(error?.message);
				// }
				enqueueSnackbar(t(error?.message) || " t(`error`)", {
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		}
	};

	const handleClose = () => {
		isClose(false);
		setMessageErr("");
	};

	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleUpload = async (e) => {
		const formData = new FormData();
		formData.append("image", e.target.files[0]);

		try {
			const response = await uploadAPI.uploadImage(formData);
			setImage(response?.results?.object?.url);
		} catch (error) {
			console.log("🚀 ~ file: index.js ~ line 56 ~ handleUpload ~ error", error);
		}
	};

	const { branchList, branchActiveId } = useSelector((state) => state.branchReducer);

	return (
		<div>
			<Dialog fullWidth open={isOpen} onClose={handleClose}>
				<DialogTitle style={{ fontSize: "22px", fontWeight: "500" }}>
					{isUpdate ? t(`update_employee`) : t(`create_employee`)}
				</DialogTitle>
				<form>
					<DialogContent>
						<div style={{ color: "red", marginBottom: "20px" }}>{messageErr}</div>
						<Grid container spacing={3}>
							{isUpdate ? (
								<Grid item xs={6}>
									<TextField
										defaultValue={isUpdate ? employee.fullname : ""}
										margin="dense"
										name="fullname"
										label={"Tên nhân viên *"}
										type="text"
										fullWidth
										variant="outlined"
										inputRef={register({ required: t("fullname_is_required") })}
									/>
									{errors.fullname && <TextValidation>{errors.fullname?.message}</TextValidation>}
								</Grid>
							) : (
								<Grid item xs={6}>
									<TextField
										defaultValue={isUpdate ? employee.username : ""}
										margin="dense"
										name="username"
										label={t(`username`) + "*"}
										type="text"
										fullWidth
										variant="outlined"
										inputProps={{
											autoComplete: "off",
										}}
										inputRef={register({
											required: t("username_is_required"),
											pattern: {
												value: PATTERN.NO_SPACES,
												message: "Tên đăng nhập không đúng định dạng",
											},
											validate: {
												hasSpace: (v) => !v.includes(" ") || "Tên đăng nhập không có khoảng trắng",
											},
											minLength: {
												value: 6,
												message: "Tên đăng nhập ít nhất 6 kí tự",
											},
											maxLength: {
												value: 50,
												message: t("id_is_too_long"),
											},
										})}
									/>
									{errors.username && <TextValidation>{errors.username?.message}</TextValidation>}
								</Grid>
							)}

							<Grid item xs={6}>
								<Box sx={{ width: "100%", position: "relative" }}>
									<TextField
										defaultValue={isUpdate ? employee.password : ""}
										margin="dense"
										name="password"
										label={t(`password`) + "*"}
										type={!showPassword ? "password" : "text"}
										fullWidth
										variant="outlined"
										sx={{ position: "relative" }}
										inputRef={
											isUpdate
												? register({
														pattern: {
															value: PATTERN.PASSWORD_SIX_NUMBER,
															message: "Mật khẩu phải là một dãy số 6 ký tự",
														},
														// minLength: {
														// 	value: 6,
														// 	message: t("password_must_be_at_least_6_characters"),
														// },
												  })
												: register({
														pattern: {
															value: PATTERN.PASSWORD_SIX_NUMBER,
															message: "Mật khẩu phải là một dãy số 6 ký tự",
														},
														required: t("password_is_required"),
														// minLength: {
														// 	value: 6,
														// 	message: t("password_must_be_at_least_6_characters"),
														// },
												  })
										}
										inputProps={{
											autoComplete: "new-password",
											onPaste: (e) => e.preventDefault(),
										}}
										autoComplete="new-password"
									/>
									<Box
										sx={{
											position: "absolute",
											right: 20,
											top: "50%",
										}}
									>
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												edge="end"
												size="large"
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									</Box>
								</Box>

								{errors.password && <TextValidation>{errors.password?.message}</TextValidation>}
							</Grid>
						</Grid>
						{/* <Grid container spacing={3}>
							<Grid item xs={6}>
								<TextField
									defaultValue={isUpdate ? employee.email : ""}
									margin="dense"
									name="email"
									label={t(`email`) + "*"}
									type="email"
									fullWidth
									variant="outlined"
									inputRef={register({
										required: t("email_is_required"),
										pattern: {
											value: PATTERN.EMAIL,
											message: t("email_address_is_invalid"),
										},
									})}
								/>
								{errors.email && <TextValidation>{errors.email?.message}</TextValidation>}
							</Grid>
							<Grid item xs={6}>
								<TextField
									defaultValue={isUpdate ? employee.phone : ""}
									margin="dense"
									name="phone"
									label={t(`phone_number`) + "*"}
									type="text"
									fullWidth
									variant="outlined"
									inputRef={register({
										required: t("phone_number_is_required"),
										pattern: {
											value: PATTERN.PHONE_NUMBER_VN,
											message: t("please_input_phone_number_with_number_only"),
										},
									})}
								/>
								{errors.phone && <TextValidation>{errors.phone?.message}</TextValidation>}
							</Grid>
						</Grid> */}
						<Grid container spacing={3}>
							{!isUpdate && (
								<Grid item xs={6}>
									<TextField
										defaultValue={isUpdate ? employee.fullname : ""}
										margin="dense"
										name="fullname"
										label={"Tên nhân viên*"}
										type="text"
										fullWidth
										variant="outlined"
										inputRef={register({ required: t("fullname_is_required") })}
									/>
									{errors.fullname && <TextValidation>{errors.fullname?.message}</TextValidation>}
								</Grid>
							)}
							{/* {isUpdate ? (
								employeeInfo.role === "SUPERADMIN" && (
									<Grid item xs={6}>
										<FormControl variant="outlined" sx={{ width: 270, marginTop: "8px" }}>
											<InputLabel id="demo-simple-select-autowidth-label">{t(`type`)}</InputLabel>
											<Select
												labelId="demo-simple-select-autowidth-label"
												id="demo-simple-select-autowidth"
												value={type}
												onChange={handleChange}
												label="type"
												name="type"
											>
												<MenuItem value={"SUPERADMIN"}>Quản trị</MenuItem>
												<MenuItem value={"ADMIN"}>Nhân viên</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								)
							) : (
								<Grid item xs={6}>
									<FormControl variant="outlined" sx={{ width: 270, marginTop: "8px" }}>
										<InputLabel id="demo-simple-select-autowidth-label">{t(`type`)}</InputLabel>
										<Select
											labelId="demo-simple-select-autowidth-label"
											id="demo-simple-select-autowidth"
											value={type}
											onChange={handleChange}
											label="type"
											name="type"
										>
											<MenuItem value={"SUPERADMIN"}>Quản trị</MenuItem>
											<MenuItem value={"ADMIN"}>Nhân viên</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							)} */}
							{/* <Grid item xs={6}>
								<FormControl
									variant="outlined"
									sx={{ width: "100%", marginTop: isUpdate ? "8px" : 0 }}
								>
									<InputLabel id="branch-label">Chi nhánh</InputLabel>

									<Controller
										defaultValue={
											isUpdate ? (employee?.branch_id ? employee?.branch_id : "none") : "none"
										}
										as={
											<Select labelId="branch-label" label="Chi nhánh">
												<MenuItem value={"none"}>Không chọn</MenuItem>
												{Array.isArray(branchList) &&
													branchList.map((item) => (
														<MenuItem key={item.id} value={item.id}>
															{item.name}
														</MenuItem>
													))}
											</Select>
										}
										name="branch_id"
										control={control}
									/>
								</FormControl>
							</Grid> */}
						</Grid>

						<Grid container spacing={3} sx={{ mt: 1 }}>
							<Grid item sx={{ mt: 3 }} xs={6}>
								{!image ? (
									<label htmlFor="contained-button-file">
										<Input
											onChange={(e) => {
												handleUpload(e);
											}}
											accept="image/*"
											id="contained-button-file"
											multiple
											type="file"
										/>
										<UploadFile>{t(`upload_avatar`)}</UploadFile>
									</label>
								) : (
									<Box
										sx={{
											textAlign: "center",
										}}
									>
										<PreviewImg src={image} alt="Avatar" />
										<RemoveImg onClick={() => setImage("")}>{t(`remove`)}</RemoveImg>
									</Box>
								)}
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions style={{ margin: "0px 20px 20px" }}>
						<Button onClick={handleClose} variant="outlined" style={{ marginRight: "20px" }}>
							{t(`cancel`)}
						</Button>
						<LoadingButton
							onClick={handleSubmit(onSubmit)}
							// endIcon={<SaveIcon />}
							loading={loading}
							// loadingPosition="end"
							variant="outlined"
						>
							{isUpdate ? t(`update`) : t(`add`)}
						</LoadingButton>
					</DialogActions>
				</form>
				<Close
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						cursor: "pointer",
					}}
					onClick={() => handleClose()}
				/>
			</Dialog>
		</div>
	);
}

export default memo(FormDialog);
