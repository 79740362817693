import { AddCircleOutline, InfoOutlined, RemoveCircleOutline } from "@mui/icons-material";
import {
	Box,
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Stack,
	Tooltip,
} from "@mui/material";
import { style } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import inventoryAPI from "api/InventoryAPI";
import { CustomNoRowsOverlay } from "components/noRows";
import { SearchComponent } from "components/search";
import { checkInventoryTranslator, getNumberWithCommas } from "func";
import useDebounce from "hooks/useDebounce";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import CollapseContent from "ui-component/CollapseContent";
import HeaderActions from "ui-component/Header-actions";
import DateRange from "ui-component/SearchAvanced/DateRange";
import { showSortStyle } from "views/utilities/CustomStyle";

const typeList = [
	{
		value: "ALL",
		title: "Tất cả",
		color: "#000",
	},
	{
		value: "CHECK_INVENTORY",
		title: checkInventoryTranslator("CHECK_INVENTORY").title,
		color: checkInventoryTranslator("CHECK_INVENTORY").color,
	},
	{
		value: "ORDER",
		title: checkInventoryTranslator("ORDER").title,
		color: checkInventoryTranslator("ORDER").color,
	},
	{
		value: "INTERNAL",
		title: checkInventoryTranslator("INTERNAL").title,
		color: checkInventoryTranslator("INTERNAL").color,
	},
];

const searchList = [
	{
		value: "code",
		title: "Mã kiểm",
	},
	{
		value: "$product.title$",
		title: "Sản phẩm",
	},
];
function CheckInventory() {
	const location = useLocation();
	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const [typeStatus, setTypeStatus] = useState(typeList[0].value);

	const [searchValue, setSearchValue] = useState("");

	const [searchBy, setSearchBy] = useState([searchList[0].value]);

	const debounceValue = useDebounce(searchValue);

	const [collapse, setCollapse] = useState(false);
	const [date, setDate] = useState([null, null]);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const [loading, setLoading] = useState(false);
	const { branchActiveId } = useSelector((state) => state.branchReducer);

	const fetchCheckInventory = async () => {
		setLoading(true);
		const fields = [
			"$all",
			{ product: ["inventory_amount", "title"] },
			{ inventory: ["id", "branch_id", { branch: ["name"] }] },
			{ employee: ["fullname", "username"] },
		];

		const filterA = {
			type: typeStatus === "ALL" ? undefined : typeStatus,
			// eslint-disable-next-line no-useless-computed-key
			["$inventory.branch_id$"]: branchActiveId,
			[searchBy]: debounceValue
				? {
						$iLike: `%${debounceValue.trim()}%`,
				  }
				: undefined,
			created_at_unix_timestamp:
				collapse && date[0] && date[1]
					? {
							$between: [
								+moment(date[0]).utc(true).format("x"),
								+moment(date[1]).utc(true).format("x"),
							],
					  }
					: undefined,
			total_difference: { $ne: "0" },
		};

		const order = [["updated_at", "desc"]];

		const payload = {
			fields: JSON.stringify(fields),
			filter: JSON.stringify(filterA),
			order: JSON.stringify(order),
			page: pagination.page,
			limit: pagination.limit,
		};
		try {
			const res = await inventoryAPI.getListCheck(payload);
			setRows(res.results.objects.rows);
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			setPagination((prev) => ({
				...prev,
				...paginationRes,
			}));
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const handleChangePage = (e, page) => {
		setPagination((prev) => ({
			...prev,
			page,
		}));
		navigate({
			pathname: location.pathname,
			// search: `?${queryString.stringify(filters)}`,
		});
	};

	useEffect(() => {
		if (!branchActiveId) return;
		fetchCheckInventory();
	}, [pagination.page, typeStatus, branchActiveId, debounceValue, date, collapse]);

	const columns = [
		{
			field: "code",
			headerName: "Mã kiểm",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "product",
			headerName: "Sản phẩm",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.product?.title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Link
							to={`/product-detail/${params?.row?.product_id}`}
							style={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.product?.title}
						</Link>
					</Tooltip>
				);
			},
		},
		{
			field: "employee",
			headerName: "Nhân viên kiểm",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.row?.employee?.fullname || params?.row?.employee?.username}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.row?.employee?.fullname || params?.row?.employee?.username}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "type",
			headerName: "Phương thức",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				const { title, color } = checkInventoryTranslator(params.value);
				return (
					<Tooltip
						title={title}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								color: color,
								fontWeight: "bold",
							}}
						>
							{title}
						</Box>
					</Tooltip>
				);
			},
		},

		{
			field: "total_difference",
			headerName: "Tổng chênh lệch",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				const amount = getNumberWithCommas(params.value);
				return (
					<Tooltip
						title={amount}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								fontWeight: "bold",
							}}
						>
							{amount}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "total_difference_asc",
			headerName: "SL lệch tăng",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				const amount = getNumberWithCommas(params.value);
				return (
					<Tooltip
						title={`${amount}`}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								fontWeight: "bold",
							}}
						>
							{amount}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "total_difference_desc",
			headerName: "SL lệch giảm",
			flex: 1,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				const amount = getNumberWithCommas(params.value);
				return (
					<Tooltip
						title={amount}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								fontWeight: "bold",
							}}
						>
							{amount}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "actions",
			headerName: "Hành động",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 0.8,
			renderCell: (params) => {
				return (
					<Tooltip
						title={"Thông tin chi tiết"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<IconButton
							onClick={() => {
								navigate(`/check-inventory-detail/${params.row?.id}`);
							}}
						>
							<InfoOutlined />
						</IconButton>
					</Tooltip>
				);
			},
		},
	];
	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	return (
		<MainCard>
			<HeaderActions title={"Kiểm kho"} onCreate={false} />
			<Box my={5}>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12}>
						<Stack flexDirection={"row"}>
							<SearchComponent
								searchBy={searchBy}
								searchList={searchList}
								searchValue={searchValue}
								handleSearchBy={handleSearchBy}
								handleChangeSearch={(value) => setSearchValue(value)}
							/>
							<Stack flexDirection={"row"}>
								<FormControl sx={{ minWidth: 150, ml: 2 }}>
									<InputLabel id="demo-simple-select-outlined-label">Phương thức</InputLabel>
									<Select
										defaultValue={typeList[0].value}
										onChange={(e) => {
											setTypeStatus(e.target.value);
											handleChangePage(null, 1);
										}}
										labelId="demo-simple-select-outlined-label"
										label={"Phương thức"}
									>
										{typeList?.map((item) => (
											<MenuItem
												key={item.value}
												value={item.value}
												sx={{ color: item.color, fontWeight: 700 }}
											>
												{item.title}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Stack>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Stack flexDirection={"row"} justifyContent="flex-end">
							<Button
								onClick={() => setCollapse(!collapse)}
								sx={{
									width: "200px",
								}}
								variant="outlined"
								startIcon={collapse ? <RemoveCircleOutline /> : <AddCircleOutline />}
							>
								Tìm kiếm nâng cao
							</Button>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Box>
							<CollapseContent collapse={collapse}>
								<DateRange
									date={date}
									collapse={collapse}
									setDate={(value) => {
										setDate(value);
										if (!location.state?.fromDashboard) {
											handleChangePage(null, 1);
										}
									}}
								/>
							</CollapseContent>
						</Box>
					</Grid>

					<Grid item xs={12} sm={12} sx={showSortStyle}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							hideFooterPagination={true}
							hideFooterSelectedRowCount={true}
							rowHeight={70}
							sortingMode="server"
							// sortModel={sortModel}
							// onSortModelChange={handleSortModelChange}
							loading={loading}
							// checkboxSelection

							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "20px",
					}}
				>
					<Pagination
						page={pagination.page}
						count={Math.ceil(pagination.total / pagination.limit)}
						onChange={handleChangePage}
						color="primary"
						variant="outlined"
						shape="rounded"
					/>
				</Box>
			</Box>
		</MainCard>
	);
}

export default CheckInventory;
