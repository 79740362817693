import { SimCardDownload } from "@mui/icons-material";
import { Box, Button, Pagination, Stack, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "components/noRows";
import moment from "moment";
import { useSelector } from "react-redux";
import HeaderActions from "ui-component/Header-actions";
import { getNumberWithCommas } from "utils";
import { excelExportHelper } from "utils/ExportExcel";

function InvoicesQrTable({ rows, setPagination, pagination, filterData, loading, getData }) {
	const { branchList } = useSelector((state) => state?.branchReducer);
	const handleChangePage = (e, page) => {
		setPagination((prev) => ({
			...prev,
			page,
		}));
	};
	const columns = [
		// {
		// 	field: "order",
		// 	headerName: t(`order`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },
		{
			field: "phone",
			headerName: "Người sử dụng thẻ",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={
							<p>
								{params?.row?.user?.phone}(
								{params.row?.user?.name_admin_note ||
									params.row?.user?.fullname ||
									"Không có dữ liệu"}
								)
							</p>
						}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
							className={params?.row?.user?.deleted_at && "line__though"}
						>
							{params?.row?.phone_used}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "owner",
			headerName: "Chủ sở hữu",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={
							params.row?.owner_id ? (
								params?.row?.owner?.phone ? (
									<p>
										{params?.row?.owner?.phone}(
										{params.row?.owner?.name_admin_note ||
											params.row?.owner?.fullname ||
											"Không có dữ liệu"}
										)
									</p>
								) : (
									"Số điện thoại này đã bị xóa khỏi hệ thống"
								)
							) : (
								"VBeauty & Spa"
							)
						}
						className={params?.row?.owner?.deleted_at && "line__though"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params.row?.owner_id
								? params?.row?.owner?.phone
									? params?.row?.owner?.phone
									: "Số điện thoại này đã bị xóa khỏi hệ thống"
								: "VBeauty & Spa"}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "buyer",
		// 	headerName: "Người mua thẻ",
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// 	renderCell: (params) => {
		// 		const isDisable = params.row.owner_id !== null || params.row.is_used;
		// 		return (
		// 			<Tooltip
		// 				title={
		// 					(params?.row?.buyer?.phone && (
		// 						<p>
		// 							{params?.row?.buyer?.phone}(
		// 							{params.row?.buyer?.name_admin_note ||
		// 								params.row?.buyer?.fullname ||
		// 								"Không có dữ liệu"}
		// 							)
		// 						</p>
		// 					)) ||
		// 					(isDisable ? "Thẻ này không thể bán" : "Bấm vào để bán")
		// 				}
		// 				overlayStyle={{ maxWidth: "500px" }}
		// 				placement="right"
		// 				zIndex="10000"
		// 			>
		// 				<Box
		// 					sx={{
		// 						overflow: "hidden",
		// 						whiteSpace: "nowrap",
		// 						textOverflow: "ellipsis",
		// 					}}
		// 				>
		// 					{params?.row?.buyer?.phone || (
		// 						<IconButton
		// 							sx={{
		// 								opacity: isDisable && 0.5,
		// 							}}
		// 							disabled={isDisable}
		// 							onClick={() =>
		// 								setOpenSellPack({
		// 									open: true,
		// 									packId: params.row.id,
		// 								})
		// 							}
		// 						>
		// 							<CurrencyExchange sx={{ color: "goldenrod", cursor: "pointer" }} />
		// 						</IconButton>
		// 					)}
		// 				</Box>
		// 			</Tooltip>
		// 		);
		// 	},
		// },

		{
			field: "value",
			headerName: "Giá trị thẻ",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",
			// renderCell: (params) => <div onClick={()=> saveAs("https://pngimg.com/uploads/mario/mario_PNG125.png", 'image.png')}>Download</div>,
			// renderCell: (params) => (
			// 	<a
			// 		href="https://images/myw3schoolsimage.jpg"
			// 		download="mario_PNG125.png"
			// 		title="Logo title"
			// 	>
			// 		download
			// 	</a>
			// ),
			renderCell: (params) => (
				<Tooltip
					title={`${getNumberWithCommas(params?.value)} đ`}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{`${getNumberWithCommas(params?.value)} đ`}
					</Box>
				</Tooltip>
			),
		},
		{
			field: "endow",
			headerName: "Ưu đãi",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={`${params?.value}%`}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{`${params?.value}%`}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "value_real",
			headerName: "Nhận được",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<Tooltip
					title={`${getNumberWithCommas((params.row?.value * params.row?.endow) / 100)} đ`}
					overlayStyle={{ maxWidth: "500px" }}
					placement="right"
					zIndex="10000"
				>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{`${getNumberWithCommas((params.row?.value * params.row?.endow) / 100)} đ`}
					</Box>
				</Tooltip>
			),
		},

		{
			field: "is_used",
			headerName: "Trạng thái",
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value ? "Đã sử dụng" : "Chưa sử dụng"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value ? (
								<span style={{ color: "#00c853", fontWeight: 700 }}>Đã sử dụng</span>
							) : (
								<span style={{ color: "#f44336", fontWeight: 700 }}>Chưa sử dụng</span>
							)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "sale_date_unix",
			headerName: "Thời gian bán thẻ",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				return (
					params.value && (
						<Tooltip
							title={moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
							overlayStyle={{ maxWidth: "500px" }}
							placement="right"
							zIndex="10000"
						>
							<Box
								sx={{
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
								}}
							>
								{moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
							</Box>
						</Tooltip>
					)
				);
			},
		},
		{
			field: "created_at_unix_timestamp",
			headerName: "Thời gian tạo thẻ",
			sortable: false,
			flex: 1,
			headerAlign: "center",
			align: "center",

			renderCell: (params) => {
				return (
					params.value && (
						<Tooltip
							title={moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
							overlayStyle={{ maxWidth: "500px" }}
							placement="right"
							zIndex="10000"
						>
							<Box
								sx={{
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
								}}
							>
								{moment(Number(params.value)).format("DD/MM/YYYY HH:mm")}
							</Box>
						</Tooltip>
					)
				);
			},
		},
	];

	const handleExportExcel = async (data, date) => {
		const startEndDate = {
			start: moment(date[0]).format("DD/MM/YYYY"),
			end: moment(date[1]).format("DD/MM/YYYY"),
		};

		const fullData = await getData();

		const title = [
			{
				A: `Báo cáo thẻ đã bán theo thời gian
						Từ ngày ${startEndDate.start} đến ngày ${startEndDate.end}`,
			},
			{},
		];

		let table1 = [
			{
				A: "Người sử dụng thẻ",
				B: "Chủ sở hữu thẻ",
				C: "Giá trị thẻ",
				D: "Ưu đãi",
				E: "Nhận được",
				F: "Xác nhận đã nhận tiền",
				G: "Trạng thái",
				H: "Thời gian bán thẻ",
				I: "Thời gian tạo",
			},
		];
		// console.log("data export: ", data);
		fullData.forEach((row) => {
			table1.push({
				A: row?.user?.fullname || row?.user?.username,
				B: row?.owner?.fullname || row?.owner?.username || "Vbeauty & Spa",
				C: +row?.value,
				D: row?.endow + "%",
				E: (row?.value * row?.endow) / 100,
				F: row?.is_confirm ? "Đã nhận tiền" : "Chưa nhận tiền",
				G: "Đã sử dụng",

				H: moment(+row?.sale_date_unix).format("DD/MM/YYYY HH:mm"),
				I: moment(+row?.created_at_unix_timestamp).format("DD/MM/YYYY HH:mm"),
			});
		});

		table1 = [{ A: `Ngày lập ${moment().format("DD/MM/YYYY HH:mm")}` }].concat(table1).concat([""]);

		const finalData = [...title, ...table1];

		excelExportHelper(fullData, finalData);
	};

	return (
		<Box minHeight={"100vh"}>
			<Stack flexDirection={"row"} justifyContent="space-between">
				<HeaderActions title="Thẻ đã bán" />
				<Stack flexDirection={"row"} gap={2} alignItems="center">
					<Button
						sx={{ p: 1, height: "fit-content" }}
						size="small"
						variant="contained"
						startIcon={<SimCardDownload />}
						onClick={() => handleExportExcel(rows, filterData?.date)}
					>
						Xuất file
					</Button>
				</Stack>
			</Stack>
			<Box>
				<DataGrid
					rows={rows}
					columns={columns}
					autoHeight
					autoWidth
					disableColumnMenu
					disableVirtualization={false}
					disableSelectionOnClick={true}
					disableDensitySelector={false}
					hideFooter={true}
					rowHeight={70}
					sortingMode="server"
					loading={loading}
					// checkboxSelection={ROLE.TRANSACTION_DELETE === role?.TRANSACTION_DELETE}
					// onSelectionModelChange={(ids) => {
					// 	setSelectList(ids);
					// }}
					components={{
						NoRowsOverlay: CustomNoRowsOverlay,
					}}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					marginTop: "20px",
				}}
			>
				<Pagination
					page={pagination.page}
					count={Math.ceil(pagination.total / pagination.limit)}
					onChange={handleChangePage}
					color="primary"
					variant="outlined"
					shape="rounded"
				/>
			</Box>
		</Box>
	);
}

export default InvoicesQrTable;
