import { Button, Col, Divider, Image, Row } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import userAPI from "api/UserAPI";
import classnames from "classnames/bind";
import { ROLE } from "constants/employeeRole";
import { saveAs } from "file-saver";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import useCustomAuth from "hooks/useCustomAuth";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { actUpdateQrShare } from "store/actions/UserAction";
import { getNumberWithCommas } from "utils";
import styles from "./DetailInformation.module.scss";

const cx = classnames.bind(styles);
function UserInformation({ customItem, getListTable }) {
	const { role } = useCustomAuth();
	//Example DATA
	const params = useParams();
	const [data, setData] = useState(useSelector((state) => state.user.userInformation) || []);

	const fetchUserInfo = async () => {
		let filter = {
			id: params.id,
		};
		const payload = {
			fields: '["$all", {"user": ["$all"]}, {"members" : ["$all"]}]',
			filter: JSON.stringify(filter),
		};

		try {
			const res = await userAPI.getList(payload);
			setData(res.results?.objects?.rows[0]);
		} catch (error) {
			console.log(error);
		}
	};
	const [copyText, copy] = useCopyToClipboard();
	const [information, setInformation] = useState({
		visible: false,
		data: null,
	});

	const dispatch = useDispatch();

	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const createQrCodeForShop = async () => {
		setLoading(true);
		const params = {
			phone: data.username,
		};
		try {
			const res = await userAPI.getQrForShop(params);
			setTimeout(() => {
				dispatch(actUpdateQrShare(res.results.object.link));
			}, 1000);
			enqueueSnackbar("Tạo mã thành công", {
				autoHideDuration: 2000,
				variant: "success",
			});

			setTimeout(() => {
				fetchUserInfo();
				setLoading(false);
			}, 1000);
			// getListTable();
		} catch (err) {
			enqueueSnackbar(err.message || "Lỗi", {
				autoHideDuration: 2000,
				variant: "error",
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		if (params.id) {
			fetchUserInfo();
		}
	}, []);

	const dataParams = [
		{
			groupId: 1,
			groupTitle: "Thông tin khách hàng",
			gridProps: {},
			children: [
				{
					id: "fullname",

					title: "Tên khách hàng",
					value: data?.fullname,
					component: Text,
					childrenProps: {
						//all Props component here
						span: 8,
					},
				},
				{
					id: "phone",
					title: "Số điện thoại",
					value: data?.phone,
					component: Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "name_admin_note",
					title: "Tên thân mật",
					value: data?.name_admin_note,
					component: Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "email",
					title: "Email",
					value: data?.email,
					component: Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "birthday",
					title: "Ngày sinh",
					value: !data.birthday
						? "Không có dữ liệu"
						: moment(parseInt(data?.birthday)).format("DD/MM/YYYY"),
					component: Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "gender",
					title: "Giới tính",
					value: (() => {
						switch (data?.gender) {
							case "OTHER":
								return "Khác";
							case "MALE":
								return "Nam";
							case "FEMALE":
								return "Nữ";
							default:
								return "Khác";
						}
					})(), //IFFE
					component: Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "description",
					title: "Ghi chú: ",
					value: data.description || "Không có dữ liệu",

					component: Text,
					childrenProps: {
						span: 24,
					},
				},
			],
		},
		{
			groupId: 2,
			groupTitle: "Thông tin tài khoản",
			gridProps: {},
			children: [
				{
					id: "username",
					title: "Tên đăng nhập",
					value: data?.username,
					component: Text,
					childrenProps: {
						span: 8,
					},
				},

				{
					id: "wallet",
					title: "Số tiền trong hạn mức dịch vụ",
					value: getNumberWithCommas(data?.wallet) + " đ",
					component: Text,
					childrenProps: {
						span: 8,
					},
				},

				{
					id: "wallet_rose",
					title: "Số tiền trong ví hoa hồng",
					value: getNumberWithCommas(data?.wallet_rose) + " đ",
					component: Text,
					childrenProps: {
						span: 8,
					},
				},

				{
					id: "debt",
					title: "Dư nợ",
					value: getNumberWithCommas(data?.debt) + " đ",
					component: Text,
					childrenProps: {
						span: 8,
					},
				},

				{
					id: "created_at",
					title: "Thời gian tạo tài khoản",
					value: moment(+data?.created_at_unix_timestamp).format("DD/MM/YYYY HH:mm"),
					component: Text,
					childrenProps: {
						span: 8,
					},
				},
				{
					id: "updated_at",
					title: "Ngày cập nhật tài khoản gần nhất",
					value: moment(data?.updated_at).format("DD/MM/YYYY HH:mm"),
					component: Text,
					childrenProps: {
						span: 8,
					},
				},
			],
		},
		{
			groupId: 3,
			groupTitle: "QR cá nhân",
			gridProps: {},
			children: [
				{
					id: "qr_code",
					title: " ",
					value: (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<img src={data?.qr_code} alt="qrcode" style={{ width: "200px" }} />
						</div>
					),
					childrenProps: {
						span: 24,
					},
				},
			],
		},
	];

	return (
		<>
			<div className={cx("wrapper")}>
				<div className={cx("content__container")}>
					{dataParams.map((item) => (
						<React.Fragment key={item.groupId}>
							<Divider orientation={"center"}>
								<Title level={4}>{item.groupTitle}</Title>
							</Divider>
							<Row gutter={[32, 32]}>
								{item.children.map((child) => {
									const Component = child.component || Text;
									const { span = 6, ...props } = child.childrenProps;

									return (
										<Col span={span} key={child.id}>
											<div className={cx("content__item")} style={customItem}>
												<Title level={5}>{child.title}</Title>
												<Component {...props}>
													{child.value || (child.value === null && "Không có dữ liệu")}
												</Component>
											</div>
										</Col>
									);
								})}
							</Row>
						</React.Fragment>
					))}
					{data.is_collab &&
						(data.qr_link_share_for_shop_owner ? (
							<React.Fragment>
								<Divider orientation={"center"}>
									<Title level={4}>Mã QR code nhận thưởng 500.000đ</Title>
								</Divider>
								<Row gutter={[32, 32]} justify="center">
									<Col>
										<Row
											gutter={[32, 32]}
											justify="center"
											align="middle"
											style={{ flexDirection: "column" }}
										>
											<Image
												width={200}
												height={200}
												src={data.qr_link_share_for_shop_owner}
												preview={false}
											/>
											<Row gutter={[32, 32]} style={{ gap: "5px" }}>
												<Button
													type="primary"
													onClick={() => copy(data.qr_link_share_for_shop_owner)}
												>
													Sao chép mã
												</Button>
												<Button
													type="primary"
													onClick={() =>
														saveAs(data.qr_link_share_for_shop_owner, "qr_code_share.png")
													}
												>
													Tải ảnh mã
												</Button>
											</Row>
										</Row>
									</Col>
								</Row>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Divider orientation={"center"}>
									<Title level={4}>Mã QR code nhận thưởng 500.000đ</Title>
								</Divider>
								<Row gutter={[32, 32]} style={{ textAlign: "center" }}>
									<Col span={24}>
										<Text>Bạn chưa có mã để chia sẻ</Text>
									</Col>

									{ROLE.CUSTOMER_CREATE_QRCODE === role?.CUSTOMER_CREATE_QRCODE ? (
										<Col span={24}>
											<Button type="primary" onClick={() => createQrCodeForShop()}>
												Tạo mã ngay
											</Button>
										</Col>
									) : (
										<Col span={24}>
											<Button type="primary" disabled onClick={(e) => e.preventDefault()}>
												Bạn không có quyền tạo mã
											</Button>
										</Col>
									)}
								</Row>
							</React.Fragment>
						))}
					{/* <React.Fragment>
						<Divider orientation={"left"}>
							<Title level={4}>Thẻ hiện có</Title>
						</Divider>
						<Row gutter={[32, 32]}>
							{ROLE.CUSTOMER_UPDATE === role?.CUSTOMER_UPDATE && (
								<Col span={24} className={cx("btn__add-card")}>
									<Button
										type="primary"
										onClick={() =>
											setInformation({
												data: data?.wallet,
												visible: true,
											})
										}
									>
										Tạo thẻ
									</Button>
								</Col>
							)}
							<Col span={24}>
								<div className={cx("content__item")} style={customItem}>
									<UserCard userId={data?.id || params.id} />
								</div>
							</Col>
						</Row>
					</React.Fragment> */}
				</div>
			</div>
			{/* <DialogMain
				maxWidth={"md"}
				title={"Tạo thẻ mới bằng hạn mức dịch vụ của khách hàng"}
				fullWidth
				open={information.visible}
				onClose={() => {
					setInformation((prev) => ({
						...prev,
						visible: false,
					}));
				}}
			>
				<ShareQrCode
					getListTable={getListTable}
					wallet={data?.wallet}
					userId={data?.id}
					onCloseModal={() => {
						setInformation((prev) => ({
							...prev,
							visible: false,
						}));
					}}
				/>
			</DialogMain> */}
		</>
	);
}

export default UserInformation;
