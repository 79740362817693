import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { PAYMENT_TYPE } from "constants/common";
import React, { useState } from "react";
import ServiceBestSeller from "views/dashboard/AntdChart/ServiceBestSeller";

function FilterTop10({ chart, dataChart }) {
	const { CASH, WALLET, TRANSFER, CARD } = PAYMENT_TYPE;
	const paymentList = [
		{
			value: "CASH",
			title: CASH,
		},
		{
			value: "WALLET",
			title: WALLET,
		},

		{
			value: "TRANSFER",
			title: TRANSFER,
		},
		// {
		// 	value: "ASSIGNED",
		// 	title: ASSIGNED,
		// },
		{
			value: "CARD",
			title: CARD,
		},
	];

	const [filVal, setFilVal] = useState("revenue");
	const [paymentType, setPaymentType] = useState(paymentList[0].value);

	const renderChart = (value) => {
		switch (value) {
			case "amount": {
				const filterData = dataChart?.[chart?.name]?.filter((item) => {
					return item?.[chart?.type] === paymentType;
				});
				return (
					<ServiceBestSeller
						data={filterData}
						chartText={{
							title: chart?.title,
							revenue: "Doanh thu",
							count: "Số lần đặt hàng",
						}}
						linkPath={chart.path}
						defaultFilterVal={"amount"}
						filter={false}
					/>
				);
			}
			case "revenue": {
				const filterData = dataChart?.[chart?.name]?.filter((item) => {
					return item?.[chart?.type] === paymentType;
				});
				return (
					<ServiceBestSeller
						data={filterData}
						chartText={{
							title: chart?.title,
							revenue: "Doanh thu",
							count: "Số lần đặt hàng",
						}}
						defaultFilterVal={"revenue"}
						filter={false}
						linkPath={chart.path}
					/>
				);
			}

			default:
				break;
		}
	};
	return (
		<>
			<Box display="flex" justifyContent={"flex-end"} gap={2}>
				<FormControl>
					<InputLabel id="demo-simple-select-label">Lọc</InputLabel>
					<Select
						sx={{ width: "auto" }}
						labelId="demo-simple-select-label"
						label={"Lọc"}
						id="demo-simple-select"
						size="large"
						onChange={(e) => {
							setFilVal(e.target.value);
						}}
						value={filVal}
					>
						<MenuItem value={"amount"}>Số lần đặt</MenuItem>
						<MenuItem value={"revenue"}>Doanh thu</MenuItem>
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel id="payment-label">Phương thức thanh toán</InputLabel>
					<Select
						sx={{ width: "200px" }}
						labelId="payment-label"
						label={"Phương thức thanh toán"}
						id="demo-simple-select"
						size="large"
						onChange={(e) => {
							setPaymentType(e.target.value);
						}}
						value={paymentType}
					>
						{paymentList.map((item) => (
							<MenuItem key={item.value} value={item.value}>
								{item.title}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box>{renderChart(filVal)}</Box>
		</>
	);
}

export default FilterTop10;
