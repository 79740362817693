import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, Pagination, Button, Chip } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import serviceAPI from "api/ServiceAPI";
import { IMAGES } from "assets/images/imagePath";
import { CustomNoRowsOverlay } from "components/noRows";
import { ModalContext } from "providers/ModalProvider";
import React, {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";
import Modal from "ui-component/modal-form/employee-modal-create-update/index";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { SearchComponent } from "../../components/search";
import _debounce from "lodash/debounce";
import employeeAPI from "../../api/EmployeeAPI";
import ServiceModal from "ui-component/modal-form/ServiceModal";
import ImageModal from "ui-component/modal-form/ImageModal";
import { Check, LocalFireDepartment } from "@mui/icons-material";
import { Image, Tooltip } from "antd";
import ModalAntd from "ui-component/modal-form/ModalAntd/ModalAntd";
import DetailInformation from "ui-component/modal-form/ModalAntd/DetailInformation";
import { IconEyeCheck, IconEyeTable, IconNotification } from "@tabler/icons";
import Text from "antd/lib/typography/Text";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Markup } from "interweave";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import comboServiceAPI from "api/ComboServiceAPI";
import DialogMain from "ui-component/modal-form/DialogMui";
import ComboServiceInfo from "ui-component/modal-form/ComboService";
import ComboServiceModal from "ui-component/modal-form/ComboServiceModal";
import { formatVND } from "func";
const searchList = [
	{
		value: "title",
		title: "title",
	},
	{
		value: "$category.title$",
		title: "Danh mục",
	},
	// {
	// 	value: "execution_time",
	// 	title: "Thời gian thực hiện",
	// },
	// {
	// 	value: "price",
	// 	title: "Giá dịch vụ",
	// },
];

const ServiceCombo = () => {
	const location = useLocation();

	const stateFromCategory = location?.state;
	const filterWithCategory = {
		searchBy: stateFromCategory?.searchBy,
		value: stateFromCategory?.value,
	};

	const navigate = useNavigate();
	const employeeInfo = useSelector((state) => state.employee.employeeInfo?.object);

	const modalContext = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);
		const filter = location?.state
			? {
					[location?.state?.searchBy]: location?.state?.value,
			  }
			: {};

		return {
			...params,
			...filter,
			page: Number.parseInt(params.page) || 1,
		};
	}, [location.search, location.state]);
	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(0);
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { t } = useTranslation();

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	// Search
	const [searchBy, setSearchBy] = useState(filterWithCategory.searchBy || searchList[0].value);
	const [searchValue, setSearchValue] = useState(filterWithCategory.value || "");
	const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

	const handleSearchBy = (e) => {
		const newSearchBy = e.target.value;
		setSearchBy(newSearchBy);
		setSearchValue("");
		navigate({
			pathname: location.pathname,
		});
	};

	const handleChangeSearch = (value) => {
		setSearchValue(value);
		const filters = {
			...queryParams,
		};
		if (value.length > 0) {
			filters[searchBy] = value;
		} else {
			delete filters[searchBy];
		}
		filters.page = 1;
		debounceFn(filters);
	};

	function handleDebounceFn(filters) {
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	}

	const [imageZoom, setImageZoom] = useState({ open: false, image: "" });

	const [inforCombo, setInforCombo] = useState({
		open: false,
		data: null,
	});
	// Table

	const columns = [
		// {
		// 	field: "order",
		// 	headerName: t(`order`),
		// 	minWidth: 100,
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// },

		{
			field: "image",
			headerName: "Danh sách ảnh",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<img
					height={"80%"}
					className={"aspect1-1"}
					src={params.value || IMAGES.avatar_default}
					alt="avatar-img"
					onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGES.avatar_default))}
					onClick={() => setImageZoom({ open: true, image: params.row.image })}
				/>
			),
		},
		{
			field: "title",
			headerName: t(`abeauty.service.table_title.title`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={params?.value}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{params?.value}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "service_combo_item",
			headerName: "Dịch vụ trong gói",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={"Click để xem"}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								cursor: "pointer",
							}}
							onClick={() =>
								setInforCombo({
									open: true,
									data: [params.row],
								})
							}
						>
							<IconEyeTable />
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "price",
			headerName: t(`abeauty.service.table_title.price`),
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "discount",
			headerName: "Giá giảm (đ)",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "deducted_price",
			headerName: "Giá bán (đ)",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip
						title={formatVND(params.value)}
						overlayStyle={{ maxWidth: "500px" }}
						placement="right"
						zIndex="10000"
					>
						<Box
							sx={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{formatVND(params.value)}
						</Box>
					</Tooltip>
				);
			},
		},
		// {
		// 	field: "realPrice",
		// 	headerName: "Giá cuối (đ)",
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 0.5,
		// 	renderCell: (params) => {
		// 		const result = params.row.price - params.row.discount;
		// 		return formatVND(result);
		// 	},
		// },
		// {
		// 	field: "execution_time",
		// 	headerName: t(`abeauty.service.table_title.execution_time`),
		// 	editable: false,
		// 	sortable: false,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 0.5,
		// 	renderCell: (params) => params.value + " phút",
		// },
		{
			field: "hot",
			headerName: "Dịch vụ Hot",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
			renderCell: (params) => {
				return params.value && <LocalFireDepartment color="error" />;
			},
		},

		// {
		// 	field: "created_at",
		// 	headerName: t(`created_at`),
		// 	sortable: false,
		// 	minWidth: 200,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	// flex: 1,
		// 	renderCell: (data) => <div>{moment.utc(data).format("DD/MM/YYYY HH:mm")}</div>,
		// },
		{
			field: "actions",
			headerName: t(`actions`),
			flex: 1,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<>
					{employeeInfo?.role === "SUPERADMIN" ? (
						<Tooltip title={t(`edit`)}>
							<IconButton
								aria-label="edit"
								onClick={() => {
									setData(params.row);
									setOpen(true);
									setIsUpdate(true);
								}}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
					) : (
						employeeInfo?.id === params.id && (
							<>
								<Tooltip title={t(`edit`)}>
									<IconButton
										aria-label="edit"
										onClick={() => {
											setData(params.row);
											setOpen(true);
											setIsUpdate(true);
										}}
									>
										<EditIcon />
									</IconButton>
								</Tooltip>
							</>
						)
					)}

					{employeeInfo?.id === params.id
						? null
						: employeeInfo?.role === "SUPERADMIN" && (
								<>
									<Tooltip title={t(`delete`)}>
										<IconButton
											aria-label="delete"
											onClick={() => handleOpenRemoveModal(params.id)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title={"Thông tin chi tiết"}>
										<IconButton aria-label="edit" onClick={() => handleOpenInforModal(params.row)}>
											<IconNotification />
										</IconButton>
									</Tooltip>
								</>
						  )}
				</>
			),
		},
	];

	const [information, setInformation] = useState({
		visible: false,
	});
	const handleOpenInforModal = (data) => {
		//	Example DATA
		const dataParams = [
			{
				groupId: 1,
				groupTitle: "Thông tin dịch vụ",
				gridProps: {},
				children: [
					{
						id: "title",

						title: "Tên gói dịch vụ",
						value: data.title,
						component: Text,
						childrenProps: {
							//all Props component here
							span: 8,
						},
					},

					{
						id: "hot",
						title: "Dịch vụ Hot?",
						value: "",
						component: data.hot && LocalFireDepartment,
						childrenProps: {
							offset: 8,
							span: 8,
							color: "error",
						},
					},
					{
						id: "price",
						title: "Giá gốc",
						value: formatVND(data.price),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "discount",
						title: "Giá giảm",
						value: formatVND(data.discount),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},
					{
						id: "real_price",
						title: "Giá thực",
						value: formatVND(data.price - data.discount),
						component: Text,
						childrenProps: {
							span: 8,
						},
					},

					{
						id: "description",
						title: "Mô tả",
						value: data.description || "Không có dữ liệu",
						component: Text,
						childrenProps: {
							span: 24,
						},
					},
					{
						id: "implementation_process",
						title: "Quy trình",
						value: "",
						component: Markup,
						childrenProps: {
							span: 24,
							content: data.implementation_process || "Không có dữ liệu",
						},
					},
				],
			},
			{
				groupId: 2,
				groupTitle: "Dịch vụ có trong gói",
				gridProps: {},
				children: [
					{
						id: "title",
						component: Box,
						childrenProps: {
							span: 24,
						},
						value: (() => {
							return (
								<ul>
									{data.combo_service_item.map((item) => (
										<li>
											{item.service.title} x{item.amount}
										</li>
									))}
								</ul>
							);
						})(),
					},
				],
			},
		];

		setInformation((prev) => ({
			data: dataParams,
			visible: true,
		}));
	};
	const [filterCategory, setFilterCategory] = useState();
	useEffect(() => {
		setFilterCategory(location?.state);
	}, []);
	const getListComboService = async (isLastDeletedItem) => {
		setIsLoading(true);
		// const hotFilter = `{"hot" : "false"}`;
		// let filter = filterCategory;
		let filter;
		let order = '[["updated_at", "desc"]]';
		// if (queryParams?.title?.trim()) {
		// 	filter = `{"title":{"$iLike":"%${queryParams?.title.trim()}%"}}`;
		// }
		// if (queryParams?.$category?.title$) {
		// 	filter = `{"$category.title$":{"$iLike":"%${searchValue.trim()}%"}}`;
		// }
		// if (queryParams?.execution_time?.trim()) {
		// 	filter = `{"execution_time":{"$iLike":"%${queryParams?.execution_time.trim()}%"}}`;
		// }
		// if (queryParams?.price?.trim()) {
		// 	filter = `{"price":{"$iLike":"%${queryParams?.price.trim()}%"}}`;
		// }
		if (queryParams?.[searchBy]) {
			filter = `{"${searchBy}":{"$iLike":"%${searchValue?.trim()}%"}}`;
		}
		if (filterCategory) {
			filter = `{"${searchBy}":{"$iLike":"%${searchValue?.trim()}%"}, "${
				filterCategory.searchBy
			}": "${filterCategory.value}"}`;
		}
		try {
			const res = await comboServiceAPI.getList({
				fields: '["$all",{"combo_service_item":["$all", {"service": ["$all"]}]}]',
				order,
				filter,
				limit: pagination.limit,
				page: isLastDeletedItem ? queryParams.page - 1 : queryParams.page,
			});
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			if (isLastDeletedItem) {
				navigate({
					pathname: location.pathname,
					search: `?${queryString.stringify({ ...queryParams, page: paginationRes.page })}`,
				});
			}
			const resList = res?.results?.objects?.rows?.map((item, index) => ({
				...item,
				order: `${
					paginationRes.page - 1 === 0
						? index === 9
							? 1
							: ""
						: index === 9
						? paginationRes.page
						: paginationRes.page - 1
				}${index === 9 ? 0 : index + 1}`,
			}));

			setSelectList([]);
			setRows(resList);

			setPagination(paginationRes);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	//
	// Remove multi
	const [selectList, setSelectList] = useState([]);
	const handleRemoveMulti = async () => {
		const isLast = rows.length === selectList.length && pagination.page !== 1;

		modalContext.showDeleteConfirm(
			selectList,
			async () => {
				try {
					const res = await comboServiceAPI.removeMulti({
						items: JSON.stringify(selectList),
					});

					enqueueSnackbar(t(`success`), {
						variant: "success",
						autoHideDuration: 2000,
					});
					getListComboService(isLast);
				} catch (error) {
					enqueueSnackbar(error?.message || t(`error`), {
						variant: "error",
						autoHideDuration: 2000,
					});
				}
			},
			t("do_you_want_to_delete_selected_items"),
			t(`confirm`)
		);
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};
		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleDelete = async (id) => {
		const isLast = rows.length === 1 && pagination.page !== 1;

		try {
			await comboServiceAPI.remove(id);

			enqueueSnackbar(t(`success`), {
				variant: "success",
				autoHideDuration: 2000,
			});
			getListComboService(isLast);
		} catch (error) {
			enqueueSnackbar(error?.message || t(`error`), {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleOpenRemoveModal = (id) => {
		modalContext.showDeleteConfirm(id, () => handleDelete(id));
	};

	useEffect(() => {
		if (queryParams.hasOwnProperty([searchBy])) {
			setSearchBy([searchBy]);
			setSearchValue(queryParams?.[searchBy]);
		}

		// if (location.state) {
		// 	setSearchBy(filterWithCategory.searchBy);
		// 	setSearchValue(filterWithCategory.value);
		// }
		getListComboService();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams, loading]);
	useEffect(() => {
		getListComboService();
	}, []);

	return (
		<>
			<MainCard>
				{/* {filterCategory?.isFromCategory && (
					<Button
						variant="contained"
						onClick={() => navigate("/service-category")}
						startIcon={<SettingsBackupRestoreIcon />}
					>
						Trở về trang dịch vụ
					</Button>
				)} */}
				<HeaderActions
					title={"Quản lý gói dịch vụ"}
					setIsUpdate={setIsUpdate}
					setOpen={setOpen}
					onCreate={employeeInfo?.role === "SUPERADMIN" ? true : false}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<SearchComponent
						searchBy={searchBy}
						searchList={searchList}
						searchValue={searchValue}
						handleSearchBy={handleSearchBy}
						handleChangeSearch={handleChangeSearch}
					/>

					{employeeInfo?.role === "SUPERADMIN" && (
						<Button
							variant="outlined"
							color="error"
							disabled={selectList?.length === 0}
							onClick={handleRemoveMulti}
						>
							{t(`remove`)}
						</Button>
					)}
				</Box>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							disableVirtualization={false}
							disableSelectionOnClick={true}
							disableDensitySelector={false}
							hideFooter={true}
							rowHeight={70}
							sortingMode="server"
							loading={isLoading}
							checkboxSelection
							onSelectionModelChange={(ids) => {
								setSelectList(ids);
							}}
							components={{
								NoRowsOverlay: CustomNoRowsOverlay,
							}}
						/>
					</Grid>
				</Grid>
				{pagination.total !== rows?.length && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "20px",
						}}
					>
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				)}
			</MainCard>

			<ComboServiceModal
				getListTable={getListComboService}
				formData={data}
				isOpen={open}
				isUpdate={isUpdate}
				isClose={(value) => setOpen(value)}
				isLoading={(value) => {
					setLoading(loading + 1);
					setState({ ...state, openState: true });
				}}
			/>
			<ImageModal
				classNameImg={"aspect1-1"}
				title={"Ảnh dịch vụ"}
				openModalZoom={imageZoom.open}
				imageZoom={imageZoom.image}
				handleCloseModal={() => setImageZoom({ open: false, image: "" })}
			/>

			<DialogMain
				maxWidth={"md"}
				title={"Dịch vụ có trong gói"}
				fullWidth
				open={inforCombo.open}
				onClose={() => {
					setInforCombo((prev) => ({
						...prev,
						open: false,
					}));
				}}
			>
				<ComboServiceInfo data={inforCombo.data} />
			</DialogMain>

			<ModalAntd
				title={"Chi tiết gói dịch vụ"}
				visible={information.visible}
				setVisible={(value) => {
					setInformation((prev) => ({
						...prev,
						visible: value,
					}));
				}}
			>
				<DetailInformation
					data={information.data}
					setInformation={(value) => {
						setInformation((prev) => ({
							...prev,
							data: value,
						}));
					}}
				/>
			</ModalAntd>
		</>
	);
};

export default ServiceCombo;
